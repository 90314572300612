import { useEffect } from 'react';

function useBodyScroll() {
  const body = document.querySelector('body');

  if (!body) {
    return;
  }

  const disableBodyScroll = () => {
    body.style.overflow = 'hidden';
  }

  const enableBodyScroll = () => {
    body.style.removeProperty('overflow');
  }

  useEffect(() => {
    disableBodyScroll();

    return () => enableBodyScroll()
  }, [])
}

export default useBodyScroll;