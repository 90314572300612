import axios from "./axios";;

let Webhooks = function(config) {
  this.axios = axios(config);
  this.type = "web_hooks";
};

Webhooks.prototype.saveWebhook = function(webhook, success, error) {
  let { id, ...attributes } = webhook;
  let data = {
    id,
    attributes,
    type: this.type,
  };
  if (id) {
    return this.axios
      .patch(`/${this.type}/${id}`, { data })
      .then(success)
      .catch(error);
  }
  return this.axios
    .post(`/${this.type}`, { data })
    .then(success)
    .catch(error);
};

Webhooks.prototype.getWebhook = function(id, success, error) {
  return this.axios
    .get(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

Webhooks.prototype.deleteWebhook = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

Webhooks.prototype.sendTest = function(id, success, error) {
  return this.axios
    .get(`/${this.type}/${id}/send_test`)
    .then(success)
    .catch(error);
};

export default Webhooks;
