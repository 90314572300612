import IdentificationImageWidget from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/IdentificationImageWidget";
import Header from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/Header";
import React, { Fragment } from "react";

const FileHeld = props => {
  const { signature, onSetPDFError, onShowImageModal } = props;

  const { idWidget } = IdentificationImageWidget({
    onSetPDFError,
    onShowImageModal,
    signature,
  });

  return (
    <Fragment>
      <Header title="ID verification" />
      <div className="mb-4">{idWidget}</div>
    </Fragment>
  );
};

export default FileHeld;
