import axios from "./axios";;

const EntitySearch = function(config) {
  config.timeout = 5 * 60 * 1000;

  this.axios = axios(config);
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
};

EntitySearch.prototype.companySearch = function(
  string,
  success,
  error,
  params,
) {
  return this.axios
    .get("/entity_search", { params: { name: string, ...params } })
    .then(success)
    .catch(error);
};

EntitySearch.prototype.emailSearch = function(email, success, error) {
  return this.axios
    .get("/entity_search/search_by_email_domain", { params: { email } })
    .then(success)
    .catch(error);
};

export default EntitySearch;
