import React from "react";

import Position from "./Position";

var TopRight = createClass({
  render: function() {
    const { children, absolute } = this.props;
    return (
      <Position absolute={absolute} position="top_right">
        {children}
      </Position>
    );
  },
});

module.exports = TopRight;
