import styled, { css } from 'styled-components';
import { COLORS } from 'variables/theme';

type ContentWrapper = {
  width?: number,
}

type WrapperProps = {
  zIndex?: number,
}

export const Base = styled.div`
  border: 0;
  background-color: ${COLORS.white};
  flex: none;
`;

export const Header = Base;

export const Footer = Base;

export const ContentWrapper = styled.div<ContentWrapper>`
  max-width: 400px;
  width: 100%;

  ${props => props.width && css`
    max-width: ${props.width}px;
  `}
`;

export const Wrapper = styled.div<WrapperProps>`
  flex-direction: row;
  z-index: 4000;

  ${props => props.zIndex && css`
    z-index: ${props.zIndex};
  `}
`;
