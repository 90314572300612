import isBlank from "utils/isBlank";

const ENTITY_TYPE_WITHOUT_GUARANTORS = [
  "association",
  "club",
  "education",
  "government",
  "other",
  "society",
  "sole_trader",
];

// This function checks if the entity type is valid to have a guarantors.
// This is regardless if the required guarantors rule has been turned on/off.
// For personal account type, the party count needs to be considered.
export default function isGuarantorsRequiredForEntityType(
  entityType,
  partyCount,
) {
  if (ENTITY_TYPE_WITHOUT_GUARANTORS.includes(entityType)) {
    return false;
  }

  if (entityType !== "personal") {
    return true;
  }

  let count = partyCount;
  if (isBlank(count)) {
    count = 0;
  }

  return entityType === "personal" && count > 1;
}
