import {
  COB_PAPERLESS_COMPONENT_COMPLETE,
  COB_PAPERLESS_CLEAR_ANSWER,
  COB_PAPERLESS_LOAD_ANSWER,
  COB_PAPERLESS_SET_ANSWER,
} from "../constants/paperless";

function defaultJointSignatories () {
  return [{ email: null, first_name: null, last_name: null }]
};

function paperlessDefaults() {
  return {
    answers: {
      applicantAuthorised: false,
      jointSignatories: defaultJointSignatories(),
      multiSignature: false,
    },
    completed: {},
  }
};

export function consumerOnboardingPaperlessReducer(
  state = paperlessDefaults(),
  action,
) {
  switch (action.type) {
    case COB_PAPERLESS_SET_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.key]: action.payload.value,
        },
      };
    case COB_PAPERLESS_COMPONENT_COMPLETE: {
      const componentState = {};
      componentState[action.payload.component] = action.payload.state;
      return { ...state, completed: { ...state.completed, ...componentState } };
    }
    case COB_PAPERLESS_LOAD_ANSWER:
      let answers = action.payload.answers;
      if (answers.jointSignatories.length === 0) {
        answers = {
          ...answers,
          jointSignatories: defaultJointSignatories(),
        }
      }

      return {
        ...state,
        ...action.payload,
        answers,
      };
    case COB_PAPERLESS_CLEAR_ANSWER:
      return {
        ...state,
        ...paperlessDefaults(),
      };
    default:
      return { ...state };
  }
}
