// import axios from "./axios";;
import axios from './axios';

export default class ReportingSummary {
  constructor(config) {
    config.timeout = 60000;

    this.axios = axios(config);
    this.type = "reporting_summary";
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      "v1",
      "v2",
    );
    this.path = `/${this.type}`;
  }

  get(attributes) {
    return this.axios.get(`/reports/${attributes.api}`, {
      data: attributes,
      params: attributes.params,
    });
  }
}
