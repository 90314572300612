import get from "lodash.get";
import AddonAnswerModel from "models/AddonAnswerModel";
import BaseModel from "models/BaseModel";
import { formatMoney } from "utils/formatting";

const DEFAULT_DECIMAL_PLACES = 2;

export default class ModuleCardholderSignatoryModel extends BaseModel {
  get answers() {
    return get(this.addonAnswer || {}, "answers.additional_fields", []);
  }

  get formattedCardLimit() {
    return `$${formatMoney(
      parseFloat(this.cardLimit) || 0,
      DEFAULT_DECIMAL_PLACES,
    )}`;
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  /** Private functions */

  assignRelationships() {
    this.assignSingleRelationship({
      key: "addon_answer",
      model: AddonAnswerModel,
    });
  }
}
