import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import SortableTree from "react-sortable-tree";
import { deepCopyArray } from "utils/deepCopy";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import isBlank from "utils/isBlank";

import { loadCurrentEntity } from "modules/user/actions";
import SimpleMultiSelectDropdown from "modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown";

import commonStyles from "./css/CommonAddOns.css";
import styles from "./css/EntityStructure.css";
import "!style-loader!css-loader!./css/ReactSortableTree.css";

import AdminTable from "modules/shared/components/widgets/interactive/AdminTable";
import Button from "modules/shared/components/inputs/Button";
import Modal from "modules/dashboard/containers/components/Modal";
import {
  loadConfig,
  loadHistory,
  updateConfig,
  deleteEntities,
} from "modules/addons/entity_structure/actions";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
import BorderedAutocomplete from "modules/shared/components/inputs/BorderedAutocomplete";
import BorderedTextField from "modules/shared/components/inputs/BorderedTextField";

import { useForm } from "react-hook-form";
import mixpanel from "mixpanel-browser";
import * as yup from "yup";
import get from "lodash.get";
import isEqual from "lodash.isequal";
import { browserHistory } from "react-router";
import ContentWithFooter from "modules/shared/components/containers/ContentWithFooter";
import flattenArray from "utils/flattenArray";
import EntityLabel from "./EnittyLabel";
import { Skeleton } from "@material-ui/lab";
import { Typography } from "@material-ui/core";

const EntityStructure = props => {
  const [showTip, setShowTip] = useState(false);
  const [modal, setModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [listData, setListData] = useState([]);
  const [filterMode, setFilterMode] = useState(false);
  const [filter1, setFilter1] = useState(null);
  const [filter2, setFilter2] = useState(null);
  const [locationF, setLocationF] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newBranch, setNewBranch] = useState("");
  const [error, setError] = useState(null);
  const [height, setHeight] = useState(0);
  const [url, setUrl] = useState(null);
  const [preTreeData, setPreTreeData] = useState([]);
  const [errorRemove, setErrorRemove] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [saveHistories, setSaveHistories] = useState([]);
  const [menuTitle, setMenuTitle] = useState("Add");
  const [tierName, setTierName] = useState(null);

  const { dispatch, loadingConfig, ...rest } = props;

  let { branches } = props;

  useEffect(() => {
    dispatch(loadConfig(setBranchTree));
  }, []);

  useEffect(() => {
    if (filter1 || filter2) {
      setFilterMode(true);

      setListData([...filter()]);
    }
    if (!filter1 && (filter2 || []).length === 0) {
      setFilterMode(false);
    }
  }, [filter2, filter1]);

  useEffect(() => {
    let unlisten = browserHistory.listen(location => {
      const newLocation = `${location.pathname}${location.search}`;
      if (
        menuTitle === "Save" &&
        !modal &&
        newLocation !== "/dashboard/addons?active_tab=1account"
      ) {
        setModal(true);

        setUrl(newLocation);

        browserHistory.replace("/dashboard/addons?active_tab=1account");
      }
    });
    return unlisten;
  }, [menuTitle, modal]);

  useEffect(() => {
    setLocationF(getRegions(treeData));
    if (treeData && treeData[0]) {
      let h = 0;
      treeData[0].children.forEach(item => {
        if (item.expanded && item.children) {
          h += item.children.length;
        }
      });

      setHeight((treeData[0].children.length + h + 1) * 62 + 20);

      // if (isDiffTree(treeData[0], preTreeData[0])) {
      //   setUnsaved(true);
      // }
      // setUnsaved(menuTitle === "Save");
    }
  }, [treeData]);

  const filter = () => {
    let org_tree = [];

    let headquarter = treeData[0];

    const filterFunc = x => {
      if (filter1 && !filter2) {
        return x.title === filter1;
      }
      if (filter1 && filter2) {
        return x.title === filter1 && filter2.some(d => x.d_list.includes(d));
      }
      if (!filter1 && filter2) {
        return filter2.some(d => x.d_list.includes(d));
      }
    };

    org_tree.push({
      title: headquarter.title,
      id: headquarter.id,
      type: "Headquarter",
      expanded: true,
      dirty: false,
      d_list: headquarter.d_list || [],
      subtitle:
        (headquarter.children || []).filter(x => filterFunc(x)).length === 0
          ? null
          : `Total ${get_tier_name(null, "tier_2")}: ${
              (headquarter.children || []).filter(x => filterFunc(x)).length
            }`,
      children: (headquarter.children || [])
        .filter(x => filterFunc(x))
        .map(r => {
          return {
            title: r.title,
            subtitle:
              (r.children || []).length === 0
                ? null
                : `Total ${get_tier_name()}: ${(r.children || []).length}`,
            id: r.id,
            type: "Region",
            dirty: isDirty(r),
            d_list: r.d_list || [],
            expanded: r.expanded,
            children: r.children,
          };
        }),
    });

    return org_tree;
  };

  const isDiffNode = (i1, i2) => {
    const keys = ["title", "subtitle", "id", "type"];
    keys.forEach(key => {
      if (i1[key] !== i2[key]) {
        return true;
      }
    });
    return !isEqual(i1["d_list"], i2["d_list"]);
  };

  const isDiffTree = (tree1, tree2) => {
    if (!tree1 || !tree2) {
      return false;
    }

    if (isDiffNode(tree1, tree2)) {
      return true;
    }

    if ((tree1.children || []).length !== (tree2.children || []).length) {
      return true;
    }

    if ((tree1.children || []).length === 0) {
      return false;
    }

    for (let i = 0; i < tree1.children.length; i++) {
      if (isDiffTree(tree1.children[i], tree2.children[i])) {
        return true;
      }
    }

    return false;
  };

  function deleteNodeCallback(entityId, result) {
    if (result === "ok") {
      let org_tree = updateNodeTree(treeData, node => node.id !== entityId);

      setTreeData([...org_tree]);
      setUnsaved(false);
      setErrorRemove(false);
    } else {
      setErrorRemove(true);
    }
    dispatch(loadHistory(setSaveHistories));
  }

  function deleteNode(node, path) {
    if (node.id === "") {
      let org_tree = updateNodeTree(
        treeData,
        x => x.title !== node.title && x.id !== "",
      );

      setTreeData([...org_tree]);
      setErrorRemove(false);
      setUnsaved(false);
    } else {
      dispatch(
        deleteEntities(
          { data: node.id, title: node.title },
          deleteNodeCallback,
        ),
      );
    }
    setError(null);
  }

  function setBranchTree(entities) {
    let org_tree = updateNodeTree([entities.data], x => true, entities.cfg);

    setSaveHistories(entities.history);
    setTierName(entities.cfg);
    setTreeData([...org_tree]);
    setPreTreeData([...org_tree]);
    setUnsaved(false);
  }

  function addBranch() {
    let org_tree = treeData;
    org_tree[0].children.push({
      title: "",
      id: "",
      type: "Region",
      expanded: true,
      d_list: [],
      dirty: false,
    });
    setTreeData([...org_tree]);
    setError(null);
    dispatch(loadHistory(setSaveHistories));
  }

  function redirectToBranch(id) {
    if (menuTitle === "Save" && !modal) {
      setModal(true);
      setUrl(`BRANCH=${id}`);
      browserHistory.replace("/dashboard/addons?active_tab=1account");
      return;
    }

    dispatch(
      loadCurrentEntity(id, false, () => {
        window.location.href = "/dashboard/home";
      }),
    );
  }

  function hideTip() {
    setShowTip(false);
  }

  function updateNode(node, value) {
    node.dirty = value && value.length > 0;
    node.d_list = value;
    let org_tree = updateNodeTree(treeData);

    setTreeData([...org_tree]);
  }

  function renderGoToButton(node, path, edit = false, readOnly = false) {
    switch (node.type) {
      case "Branch":
      case "Region":
        return (
          <Fragment>
            <EntityLabel node={node} />
            <div className={styles.container}>
              {edit && (
                <div className={styles.edit}>
                  <BorderedTextField
                    name="branch"
                    placeholder="Please enter a name"
                    error={error}
                    onChange={v => (node.title = v.target.value)}
                    onBlur={v => {
                      if (isBlank(v.target.value)) {
                        setMenuTitle("Add");
                        setError("Please enter a name");
                      } else {
                        setMenuTitle("Save");
                        setError(null);
                      }
                      setTreeData([...treeData]);
                    }}
                    autoFocus
                    customProps={{
                      withBottomMargin: false,
                      startAdornment: <span />,
                      outlineError: error,
                    }}
                  />
                </div>
              )}
              {!edit && (
                <div className={styles.dropdown}>
                  <SimpleMultiSelectDropdown
                    onChange={target => {
                      updateNode(node, target.value);
                      setMenuTitle("Save");
                    }}
                    multiple
                    options={FUNCTION_OPTIONS}
                    disabled={node.type === "Branch" || readOnly}
                    value={node.d_list || []}
                    placeholder="Functionality"
                  />
                </div>
              )}
            </div>
          </Fragment>
        );
      case "Regular":
        return <Button grey disabled text="to be approved" />;
      default:
        return "";
    }
  }

  function renderTip(show_tip) {
    return show_tip ? (
      <ToolTip tip_name={"1AccountAddingBranch"} css_style={"adding_branch"} />
    ) : (
      ""
    );
  }

  function getRegions(treeData) {
    if (treeData && treeData[0]) {
      return treeData[0].children.map(x => {
        return { label: x.title, value: x.title };
      });
    } else {
      return [];
    }
  }

  function getBranches(treeData) {
    if (treeData && treeData[0]) {
      return flattenArray(
        treeData[0].children.map(x => x.children.map(y => y.title)),
      );
    } else {
      return [];
    }
  }

  function onSave() {
    setIsLoading(true);
    dispatch(updateConfig({ data: treeData }, [loadConfig(setBranchTree)]));
    setMenuTitle("Add");
    setIsLoading(false);
  }

  function leaveComponent() {
    return modal ? (
      <Modal
        onClose={() => setModal(false)}
        content={
          <React.Fragment>
            You have unsaved changes. Please click save if you’d like to save
            the changes you’ve made.
          </React.Fragment>
        }
        footer={
          <React.Fragment>
            <button
              className="button is-primary is-rounded is-size-5"
              onClick={() => {
                setUnsaved(false);
                setModal(false);
                if (!url.includes("BRANCH"))
                  browserHistory.push(url, { force: true });
                else {
                  let id = url.split("=")[1];
                  dispatch(
                    loadCurrentEntity(id, false, () => {
                      window.location.href = "/dashboard/home";
                    }),
                  );
                }
              }}
            >
              Leave anyway
            </button>
            <button
              className="button is-primary is-rounded is-size-5"
              onClick={() => {
                setModal(false);
                onSave();
              }}
            >
              Save
            </button>
          </React.Fragment>
        }
      />
    ) : null;
  }

  function checkRemoveError() {
    return errorRemove ? (
      <Modal
        onClose={() => setErrorRemove(false)}
        content={
          <span className="has-text-weight-normal">
            As you have applications in this Branch, they need to be reallocated
            to another. Please go to your Sales pipeline tab in this Branch
            click on the application that needs to be reallocated to another
            branch. Once you’re done you can then delete this Branch.
          </span>
        }
        footer={<React.Fragment></React.Fragment>}
      />
    ) : null;
  }

  let desciption =
    "All functions are centralised by default. To decentralise a function for a branch please select from the dropdown located at each row of the branch. You can also unselect the functions to reverse the decentralised status. (Tips: Drag and Drop around the handler to make restructure)";

  const FUNCTION_OPTIONS = [
    {
      label: "Addons - Auto decisioning (1CAD)",
      value: "1CAD",
      brief: " 1CAD",
    },
    {
      label: "Addons - Website button & QR code",
      value: "WebsiteButton",
      brief: " Website/QR",
    },
    {
      label: "Addons - Direct Debit",
      value: "DirectDebit",
      brief: " Direct Debit",
    },
    {
      label: "Addons - Financial",
      value: "Financial",
      brief: " Financial",
    },
    {
      label: "Addons - Additional",
      value: "Additional",
      brief: " Additional",
    },
    {
      label: "Addons - Cards",
      value: "Cards",
      brief: " Card",
    },
    {
      label: "Addons - Internal Use Fields (IUF)",
      value: "IUF",
      brief: " IUF",
    },
    {
      label: "Addons - PPSR",
      value: "PPSR",
      brief: " PPSR",
    },
    {
      label: "Addons - Alerts",
      value: "Alerts",
      brief: " Alerts",
    },
    {
      label: "Profile - Approval hierarchy (1CAH)",
      value: "1CAH",
      brief: " 1CAH",
    },
    {
      label: "Profile - Email customisation",
      value: "Email",
      brief: " Emails",
    },
    {
      label: "Profile - Colour palette",
      value: "Color",
      brief: " Colour",
    },
    {
      label: "Profile - Account rules",
      value: "Credit",
      brief: " Account rules",
    },
    {
      label: "Profile - Supplier terms",
      value: "TC",
      brief: " T&Cs",
    },
    {
      label: "Profile - Business details",
      value: "Business",
      brief: " Business details",
    },
    {
      brief: " Invoice",
      label: "Account - Invoice",
      value: "Invoice",
    },
  ].sort((x, y) => {
    return x.label > y.label ? 1 : -1;
  });

  function isDirty(entity) {
    return (entity.d_list || []).length > 0;
  }

  function updateNodeTree(treeData, filterFunc = x => true, _tierName = null) {
    let org_tree = [];

    let headquarter = treeData[0];

    org_tree.push({
      title: headquarter.title,
      id: headquarter.id,
      type: "Headquarter",
      expanded: true,
      dirty: false,
      d_list: headquarter.d_list || [],
      subtitle:
        (headquarter.children || []).length === 0
          ? null
          : `Total ${get_tier_name(_tierName, "tier_2")}: ${
              (headquarter.children || []).length
            }`,
      children: (headquarter.children || []).filter(filterFunc).map(r => {
        return {
          title: r.title,
          subtitle:
            (r.children || []).length === 0
              ? null
              : `Total ${get_tier_name(_tierName)}: ${
                  (r.children || []).length
                }`,
          id: r.id,
          type: "Region",
          dirty: isDirty(r),
          d_list: r.d_list || [],
          expanded: r.expanded,
          children: (r.children || []).filter(filterFunc).map(b => {
            return {
              title: b.title,
              id: b.id,
              type: "Branch",
              dirty: isDirty(r),
              d_list: r.d_list || [],
            };
          }),
        };
      }),
    });

    return org_tree;
  }

  const get_tier_name = (_tierName = null, level = "tier_3") => {
    const defaults = {
      tier_1: "HQ",
      tier_2: "region",
      tier_3: "branch",
    };

    if (_tierName) {
      return _tierName[level] ? _tierName[level] : defaults[level];
    } else {
      return tierName && tierName[level] ? tierName[level] : defaults[level];
    }
  };

  return (
    <MuiThemeProvider theme={muiTheme()}>
      {leaveComponent()}
      {checkRemoveError()}
      <div className={commonStyles.row}>
        <div className={commonStyles.block_wide}>
          <div className={commonStyles.header_container}>
            <span>
              <span className={commonStyles.header}>1Account</span>
            </span>
          </div>
          <div className={styles.entity_structure_container}>
            <div className="is-size-normal mb-6">
              {desciption}

              <div className="containter columns my-3">
                <span className="pt-5 mx-3">Tier name</span>
                <div className="is-3 column">
                  <BorderedTextField
                    label="Tier 1"
                    InputLabelProps={{ shrink: true }}
                    placeholder="e.g. Support office/HQ"
                    value={tierName ? tierName["tier_1"] : null}
                    onChange={event => {
                      setTierName({
                        ...tierName,
                        tier_1: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="is-3 column">
                  <BorderedTextField
                    label="Tier 2"
                    placeholder="e.g. State/Region"
                    InputLabelProps={{ shrink: true }}
                    value={tierName ? tierName["tier_2"] : null}
                    onChange={event => {
                      setTierName({
                        ...tierName,
                        tier_2: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="is-3 column">
                  <BorderedTextField
                    label="Tier 3"
                    placeholder="e.g. Branch/Store"
                    InputLabelProps={{ shrink: true }}
                    value={tierName ? tierName["tier_3"] : null}
                    onChange={event => {
                      setTierName({
                        ...tierName,
                        tier_3: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="is-1 column">
                  <button
                    className={`mx-5 button is-size-normal has-text-weight-medium is-primary is-rounded ${styles.w_85}`}
                    onClick={() => {
                      setTierName({ ...tierName });
                      dispatch(
                        updateConfig({ tier_name: tierName }, [
                          loadConfig(setBranchTree),
                        ]),
                      );
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>

            <hr className={styles.hr} />
            <div className="containter columns">
              <span className="pt-5 mx-3">Filtered by</span>
              <div className="is-3 column">
                <BorderedAutocomplete
                  textFieldProps={{
                    disabled: false,
                    label: "Tier",
                    name: "filterLocation",
                  }}
                  id="filterLocation"
                  name="filterLocation"
                  options={locationF}
                  onChange={(_, target, reason) => {
                    if (target) {
                      setFilter1(target.value);
                    }
                    if (reason === "clear") {
                      setFilter1(null);
                    }
                  }}
                />
              </div>

              <div className="is-3 column">
                <SimpleMultiSelectDropdown
                  onChange={target => {
                    if (target) {
                      setFilter2(target.value);
                    }
                  }}
                  multiple
                  options={FUNCTION_OPTIONS}
                  disabled={false}
                  value={filter2 || []}
                  clearFunc={() => {
                    setFilter2(null);
                  }}
                  placeholder="Functionality"
                />
              </div>
            </div>
            {filterMode && (
              <div style={{ height: 800 }}>
                <SortableTree
                  treeData={[...listData]}
                  onChange={org_tree => {
                    setListData([...org_tree]);
                  }}
                  generateNodeProps={({ node, path }) => {
                    return {
                      itemClassName: node.dirty
                        ? "rst__moveHandle_another"
                        : "rst__moveHandle",
                      buttons: [renderGoToButton(node, path, false, true)],
                    };
                  }}
                />
              </div>
            )}
            {loadingConfig && (
              <Typography variant="h2">
                <Skeleton animation="wave" />
                <div style={{ paddingLeft: '2rem' }}>
                  <Skeleton animation="wave" />
                </div>
                <div style={{ paddingLeft: '2rem' }}>
                  <Skeleton animation="wave" />
                </div>
              </Typography>
            )}
            {height !== 0 && !filterMode && (
              <div style={{ height: height + "px" }}>
                <SortableTree
                  treeData={[...treeData]}
                  maxDepth={3}
                  onChange={org_tree => {
                    let org_tree1 = updateNodeTree(org_tree);
                    setTreeData([...org_tree1]);
                    // TODO! conditionally save
                    // onSave()
                    setMenuTitle("Save");
                  }}
                  canNodeHaveChildren={node => node.type != "Branch"}
                  canDrag={e => e.node.type != "Headquarter"}
                  canDrop={e => e.nextPath.length > 1}
                  generateNodeProps={({ node, path }) => {
                    if (node.title === "") {
                      return {
                        buttons: [renderGoToButton(node, path, true)],
                        itemClassName: node.dirty
                          ? "rst__moveHandle_another"
                          : "rst__moveHandle",
                        hasEndStaff:
                          showDelete &&
                          (node.type === "Branch" ||
                            (node.type === "Region" &&
                              (node.children || []).length === 0)) ? (
                            <a
                              className={`delete is-medium ${styles.delete_20}`}
                              onClick={() => {
                                setShowDelete(false);
                                deleteNode(node, path);
                              }}
                            />
                          ) : null,
                      };
                    } else {
                      return {
                        buttons: [renderGoToButton(node, path)],
                        itemClassName: node.dirty
                          ? "rst__moveHandle_another"
                          : "rst__moveHandle",
                        hasEndStaff:
                          showDelete &&
                          (node.type === "Branch" ||
                            (node.type === "Region" &&
                              (node.children || []).length === 0)) ? (
                            <React.Fragment>
                              <a
                                className={`delete is-medium ${styles.delete_20}`}
                                onClick={() => {
                                  setShowDelete(false);
                                  deleteNode(node, path);
                                }}
                              />
                            </React.Fragment>
                          ) : null,
                        onClick: e => {
                          if (e._dispatchListeners.length === 1) {
                            redirectToBranch(node.id);
                          }
                        },
                      };
                    }
                  }}
                />
              </div>
            )}
            {!filterMode && (
              <div className="container pl-6 ">
                <button
                  className={`ml-6 button is-size-normal has-text-weight-medium is-rounded is-primary ${styles.w_85}`}
                  onClick={menuTitle === "Add" ? addBranch : onSave}
                  disabled={error}
                >
                  {menuTitle}
                </button>

                <button
                  className={`mx-5 button is-size-normal has-text-weight-medium is-danger is-outlined is-rounded ${styles.w_85}`}
                  onClick={() => {
                    setShowDelete(true);
                  }}
                >
                  Remove
                </button>

                {renderTip(showTip)}
              </div>
            )}
            {saveHistories && saveHistories.length > 0 && (
              <div className="mt-6">
                <ContentWithFooter
                  footer={saveHistories.map((item, index) => (
                    <div key={`h-${index}`}>{item}</div>
                  ))}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default connect((state, ownProps) => {
  const current_entity_id = state.current_user.current_entity.id;
  let branches = state.add_ons.branches;
  const loadingConfig = state.entity_structure.loadingConfig;

  return {
    loading_branches: state.add_ons.loading_branches,
    loadingConfig,
    branches: state.add_ons.branches,
    currentUser: state.current_user,
  };
})(EntityStructure);
