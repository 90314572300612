import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#cards-creating-credit-card
 */
let Transactions = function(config) {
  this.axios = axios(config);
  this.type = "transactions";
};

Transactions.prototype.getTransactions = function(
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

Transactions.prototype.getInvoice = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

export default Transactions;
