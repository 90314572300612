import mixpanel from "mixpanel-browser";
import { failedApplicationStart } from "modules/dashboard/actions";
import VideoModal from "modules/shared/components/widgets/interactive/VideoModal";
import Card from "modules/shared/components/widgets/static/Card";
import { isRegular } from "modules/shared/helpers/headquarterDetect";
import TradingNameList from "modules/websitebutton/components/TradingNameList";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import {
  getCurrentPricingPlan,
  showPricingPlan,
} from "../../pricing-plans/actions";
import styles from "./css/styles.css";
import WebsitebuttonList from "./WebsitebuttonList";

class Websitebutton extends React.Component {
  componentWillMount() {
    const { dispatch, current_user } = this.props;
    if (current_user) {
      mixpanel.track("Website button Click", {
        "Entity ID": current_user.current_entity.id,
        distinct_id: current_user.data.data.id,
      });
    }

    if (this.onboarding_completed()) {
      dispatch(
        getCurrentPricingPlan(null, function(data) {
          if (!data.attributes.website_button) {
            dispatch(showPricingPlan(true, "others"));
          }
        }),
      );
    } else {
      // toggle pulse animation
      dispatch(failedApplicationStart());
      browserHistory.push("/dashboard/home");
    }
  }

  onboarding_completed() {
    const { current_user } = this.props;
    if (current_user.current_entity) {
      return !current_user.current_entity.attributes
        .supplier_onboarding_checklist;
    }
    return false;
  }

  blurb() {
    return (
      <div className={styles.content}>
        <p>
          To enable your prospects to apply for a Trade Credit Application via
          your website please follow the below instructions.
        </p>
        <ul>
          <li>Copy the embed link (in the boxes below)</li>
          <li>Log into your website editor.</li>
          <li>Find the section where you can edit your site's HTML.</li>
          <li>
            Paste the website button link in the relevant section for your
            website.
          </li>
          <li>Save and publish your changes</li>
          <li>Refresh your website and the widget should be visible.</li>
        </ul>
        <p>
          Note that enabling this function creates a new user called ‘Website
          Button’. In addition you can customise your location selector name
          tailored to your business. So if you have a ‘Branch’ network, simply
          list all of your Branches below and your prospect will be prompted to
          choose the Branch they would like to trade with when they start.
        </p>
      </div>
    );
  }

  render() {
    const { title, current_pricing_plan, location } = this.props;
    let showButtons = true;

    if (current_pricing_plan) {
      if (!current_pricing_plan.attributes.website_button) {
        showButtons = false;
      }
    }
    return (
      <div>
        <div className={styles.header_container}>
          <span>
            <span className={styles.header}>{title}</span>
            <VideoModal videoName={"website_button"} />
          </span>
        </div>
        <Card>
          {this.blurb()}
          {showButtons && <WebsitebuttonList />}
        </Card>
        {showButtons && isRegular() && (
          <Card>
            <TradingNameList location={location} />
          </Card>
        )}
      </div>
    );
  }
}

module.exports = connect((state, ownProps) => {
  return {
    current_pricing_plan: state.pricing_plans.current_pricing_plan,
    current_user: state.current_user,
    location: ownProps.location,
    title: "Website button",
  };
})(Websitebutton);
