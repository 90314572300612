import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment } from "@material-ui/core";
import React from "react";
import MaskedInput from "react-text-mask";
import { NEW_ZEALAND_ACCOUNT_NUMBER_FORMAT } from "utils/direct-debit/accountNumberFormat";

const TextMaskCustom = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask={false}
    />
  );
};

const getNewZealandAttributes = ({
  bankAccountNumber,
  setBankAccountNumber,
  onChangeBankAccountNumber,
}) => {
  const formatBankNumber = value => {
    const valueArray = value.split("-");

    if (valueArray.every(group => !group.split("_").some(s => s))) {
      return setBankAccountNumber("");
    }

    const replacedValue = valueArray
      .map((group, index) => {
        if (index !== 0 && group.includes("_")) {
          const replacedString = group.match(/_/g || []).map(() => "0");
          return replacedString.join("") + group.replace(/_/g, "");
        }

        return group;
      })
      .join("-");

    if (replacedValue === value) {
      return;
    }

    setBankAccountNumber(replacedValue);
  };

  return [
    [
      {
        label: "Bank account name to be debited",
        name: "bank_account_name",
        required: true,
        styleClass: "half_col",
      },
      {
        defaultValue: bankAccountNumber,
        endAdornment: bankAccountNumber && bankAccountNumber !== "" && (
          <InputAdornment position="end">
            <button
              aria-label="delete bank number"
              onClick={() => setBankAccountNumber("")}
              style={{
                background: "rgba(0,0,0,0.0)",
                border: "none",
                borderRadius: "1rem",
                color: "rgba(0,0,0,0.54)",
                height: "21px",
                width: "21px",
              }}
            >
              <FontAwesomeIcon icon={["fas", "times"]} />
            </button>
          </InputAdornment>
        ),
        label: "Bank account number",
        mask: NEW_ZEALAND_ACCOUNT_NUMBER_FORMAT,
        maskInput: TextMaskCustom,
        name: "bank_account_number",
        onBlur: e => formatBankNumber(e.target.value),
        onChange: onChangeBankAccountNumber,
        placeholder: "xx-xxxx-xxxxxxxx-xxx",
        required: true,
        styleClass: "half_col",
        value: bankAccountNumber,
      },
    ],
  ];
};

export default getNewZealandAttributes;
