import axios from './axios';

const AddonConfigs = function(config) {
  this.axios = axios(config);
  this.type = "addon_configs";
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
};

//
// Await-able functions
// The following functions allows the use of async/await
//
AddonConfigs.prototype.getAddonConfigs = function(options = {}) {
  return this.axios.get(`/${this.type}`, options);
};

AddonConfigs.prototype.updateRuleSet = function(id, attributes) {
  return this.axios.patch(`/${this.type}/${id}`, attributes);
};

export default AddonConfigs;
