import styled, { css } from "styled-components";
import { SPACING } from "variables/spacing";
import { COLORS } from "variables/theme";
import { FONT_SIZES, FONT_WEIGHTS } from "variables/typography";
import { ReactNode } from "react";

type HeadingProps = {
  header: ReactNode;
  listType: "none" | "number";
}

export const Description = styled.div`
  //
`;

export const Header = styled.div`
  margin-bottom: 20px;
`;

export const Heading = styled.div<HeadingProps>`
  font-weight: ${FONT_WEIGHTS.normal};
  font-size: ${FONT_SIZES.title};
  margin-bottom: ${SPACING.sm};

  ${({ header, listType }) => header && listType === "number" && css`
    &::before {
      content: "Step " counter(header) ": ";
    }
  `}
`;

export const Wrapper = styled.div`
  counter-increment: header;
  line-height: 1.75;
  margin-bottom: 20px;
  padding-bottom: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.mediumGrey};
  }
`;
