import axios from './axios';

const AddonVersions = function(config) {
  this.axios = axios(config);
  this.type = "addon_versions";
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
};

//
// Await-able functions
// The following functions allows the use of async/await
//
AddonVersions.prototype.createAddonVersion = function(attributes) {
  return this.axios.post(`/${this.type}`, attributes);
};

export default AddonVersions;
