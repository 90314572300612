export const PRICING_PLAN_LIST_LOAD_START = "PRICING_PLAN_LIST_LOAD_START";
export const PRICING_PLAN_LIST_LOAD_SUCCESS = "PRICING_PLAN_LIST_LOAD_SUCCESS";
export const PRICING_PLAN_LIST_LOAD_ERROR = "PRICING_PLAN_LIST_LOAD_ERROR";
export const PRICING_PLAN_SET_SELECTED_PLAN = "PRICING_PLAN_SET_SELECTED_PLAN";
export const PRICING_PLAN_LOAD_START = "PRICING_PLAN_LOAD_START";
export const PRICING_PLAN_LOAD_SUCCESS = "PRICING_PLAN_LOAD_SUCCESS";
export const PRICING_PLAN_LOAD_ERROR = "PRICING_PLAN_LOAD_ERROR";
export const PRICING_PLAN_UPDATE_CURRENT_PLAN_START =
  "PRICING_PLAN_UPDATE_CURRENT_PLAN_START";
export const PRICING_PLAN_UPDATE_CURRENT_PLAN_SUCCESS =
  "PRICING_PLAN_UPDATE_CURRENT_PLAN_SUCCESS";
export const PRICING_PLAN_UPDATE_CURRENT_PLAN_ERROR =
  "PRICING_PLAN_UPDATE_CURRENT_PLAN_ERROR";
export const PRICING_PLAN_SHOW = "PRICING_PLAN_SHOW";
