import React, { Fragment } from "react";
import styles from "modules/new-applications/css/IdentificationChecks.css";
import isBlank from "utils/isBlank";

const VedaCheckErrors = props => {
  const { identificationCheck, isErrorsVisible } = props;

  if (!isErrorsVisible) {
    return null;
  }

  const { identificationCheckErrorFields } = identificationCheck;

  if (isBlank(identificationCheckErrorFields)) {
    return null;
  }

  const contents = identificationCheckErrorFields.map((errorField, i) => (
    <div key={`veda-check-error-${i + 1}`}>{errorField}</div>
  ));

  return (
    <Fragment>
      <div className={`${styles.e_signature_header} ${styles.top_gap}`}>
        <span className="underlined">
          Identification check (Equifax) errors
        </span>
      </div>
      {contents}
    </Fragment>
  );
};

export default VedaCheckErrors;
