import {
  IP_GEOLOCATION_LOAD_START,
  IP_GEOLOCATION_LOAD_SUCCESS,
  IP_GEOLOCATION_LOAD_ERROR,
} from "./constants";

var initialState = {
  ip_geolocation_loading: false,
};

export function ipGeolocationReducer(state = initialState, action) {
  switch (action.type) {
    case IP_GEOLOCATION_LOAD_START:
      return { ...state, ip_geolocation_loading: true };
    case IP_GEOLOCATION_LOAD_SUCCESS:
      return {
        ...state,
        ip_geolocation_loading: false,
        ip_geolocation: action.payload,
      };
    case IP_GEOLOCATION_LOAD_ERROR:
      return { ...state, ip_geolocation_loading: false };

    default:
      return { ...state };
  }
}
