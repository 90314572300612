import IdentificationImageWidget from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/IdentificationImageWidget";
import PDFWidget from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/PDFWidget";
import styles from "modules/new-applications/css/IdentificationChecks.css";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import FileWidgetButton from "modules/shared/components/widgets/interactive/FileWidgetButton";
import React, { Fragment, ReactElement } from "react";

const V1 = props => {
  const {
    authorisation,
    currentUser,
    hasPDFError,
    isViewOnly,
    isVisibleWhenEmpty,
    signature,
    onSetPDFError,
    onShowImageModal,
    onFetchApplicationRecord,
  } = props;

  const identificationImageAttributes = IdentificationImageWidget({
    onSetPDFError,
    onShowImageModal,
    signature,
  });

  const { isEmpty } = identificationImageAttributes;
  let idWidget: ReactElement | null = null;

  if (hasPDFError) {
    idWidget = (
      <PDFWidget identificationImageUrl={signature.identificationImageUrl} />
    );
  } else {
    idWidget = identificationImageAttributes.idWidget;
  }

  if (!isVisibleWhenEmpty && isEmpty) {
    return null;
  }

  const { isLoading, setIsLoading } = useIsLoadingState();

  const onSuccessCallback = newSignature => {
    authorisation.assignSignature = newSignature;
    onFetchApplicationRecord();
    setIsLoading(false);
  };

  const onClickUpload = data => {
    setIsLoading(true);

    authorisation.uploadBase64IdentificationImage({
      currentUser,
      file: data,
      onSuccessCallback,
    });
  };

  let actionButtons: ReactElement | null = null;
  if (!isViewOnly) {
    actionButtons = (
      <div className={styles.e_signature_buttons}>
        <FileWidgetButton
          disabled={isViewOnly || isLoading}
          inputId={`change-id-image-${signature.id}`}
          isCompact={true}
          handleFileCallback={onClickUpload}
          edit_text="Change"
          text="Change"
          accept=".jpg,.jpeg,.png,.pdf"
          accept_single_page={true}
        />
      </div>
    );
  }

  return (
    <Fragment>
      <div className={`${styles.e_signature_header} ${styles.text_centered}`}>
        <span className="underlined">Identification</span>
      </div>
      <div className={styles.e_signature_attachment_content}>
        {idWidget}
        {actionButtons}
      </div>
    </Fragment>
  );
};

export default V1;
