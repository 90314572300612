/* eslint-disable max-classes-per-file */
export function termsDocuments(array, type, termsDocType) {
  let item = null;

  if (!array) return item;

  array.forEach(element => {
    if (element.type === type) {
      if (element.attributes.terms_doc_type === termsDocType) item = element;
    }
  });

  return item;
}
/* eslint-enable max-classes-per-file */
