import PropTypes from "prop-types"
import React from "react"

import {
  Description,
  Header,
  Wrapper,
} from "./styles";

function PageHeader({
  children,
  title,
}) {
  return (
    <Wrapper>
      <Header>{title}</Header>
      <Description>
        {children}
      </Description>
    </Wrapper>
  )
}

PageHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

PageHeader.defaultProps = {
  children: null,
};

export default PageHeader

