import Button from "modules/shared/components/inputs/Button";
import React from "react";
import { connect } from "react-redux";
import { capitalize } from "utils/formatting";

import styles from "../css/Integrations.css";
import * as IntegrationComponents from "./items";

class IntegrationItem extends React.Component {
  connectButton() {
    const { integration, loading, connecting } = this.props;
    const connected = integration.connected();

    if (loading) return null;
    const buttonStyle = connected ? styles.text_button_red : styles.text_button;

    return (
      <Button
        className={buttonStyle}
        text={connected ? "disconnect" : "connect"}
        handleClick={integration.connect.bind(integration)}
        loading={connecting[integration.attributes.service]}
        loading_text="connecting"
      />
    );
  }

  render() {
    const { integration } = this.props;

    //
    // Third-party Integration component is dynamically selected from './items/index.js'
    // except for Xero
    //
    // TODO: Move Xero integration to a separate component
    //
    if (integration.attributes.service !== "xero") {
      const componentKey = `${capitalize(
        integration.attributes.service,
      )}Component`;
      const IntegrationComponent = IntegrationComponents[componentKey];

      return <IntegrationComponent />;
    }

    let connectedDate; let desc;

    if (!integration.loading && integration.connected()) {
      connectedDate = (
        <p className={styles.metadata}>
          Connected {integration.connectedDate()}
        </p>
      );
    }

    if (!integration.connected()) {
      desc = <p className={styles.metadata}>
        Automatically create your new customer in Xero by connecting
        your account here. Click on the ‘connect’ link below and follow the steps.
      </p>
    }

    return (
      <div className={styles.card}>
        <div className={`${styles.card_header} ${styles.xero_color}`}>
          <img
            src={integration.logoSrc()}
            alt={integration.attributes.service}
            className={styles.logo}
          />
        </div>
        <div className={styles.card_body}>
          <div className={styles.service_name}>
            {integration.attributes.service}
          </div>
          {connectedDate}
          {desc}
          <div className={styles.card_action}>{this.connectButton()}</div>
        </div>
      </div>
    );
  }
}

module.exports = connect((state, ownProps) => {
  return {
    connecting: state.models.integrations.connecting,
    integration: ownProps.integration,
    loading: state.models.integrations.loading,
  };
})(IntegrationItem);
