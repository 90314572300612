import get from "lodash.get";
import isBlank from "utils/isBlank";

/**
 * There is a difference in the logo payload in Rails 5 and Rails 6
 * Rails 5 retains the intermediate attribute.
 * Rails 5 for example returns the following payload:
 * {
 *   logo: {
 *     logo: {
 *       url: "https://..."
 *     }
 *   }
 * }
 * while Rails 6 returns the following logo payload:
 * {
 *   logo: {
 *     url: "https://..."
 *   }
 * }
 */

interface AttachmentUrl {
  url?: string;
}

// This function returns { url: string } | {}
export default function extractAttachmentUrl(
  attachmentAttribute: { [key: string]: any },
  intermediateKey?: string,
): AttachmentUrl {
  if (isBlank(attachmentAttribute)) {
    return {};
  }

  if (Object.keys(attachmentAttribute).includes("url")) {
    return attachmentAttribute;
  }

  if (
    intermediateKey &&
    Object.keys(attachmentAttribute).includes(intermediateKey)
  ) {
    return { url: get(attachmentAttribute, `${intermediateKey}.url`) };
  }

  return {};
}

export function customTermsUrl(customTermsAttribute: {
  [key: string]: any;
}): AttachmentUrl {
  return extractAttachmentUrl(customTermsAttribute, "file");
}
