import {
  collateralTypeHasDescription,
  collateralTypeHasItems,
} from "models/PpsrFinancingStatementCollateralModel";
import {
  COMMON_COLLATERAL_VALIDATION_SCHEMA,
  COMMON_DEBTOR_VALIDATION_SCHEMA,
  COMMON_ORGANISATION_VALIDATION_SCHEMA,
  COMMON_PERSON_VALIDATION_SCHEMA,
} from "modules/new-applications/components/application-actions/PPSRRegister/validationSchema/commonValidationSchema";
import moment from "moment";
import isBlank from "utils/isBlank";
import * as yup from "yup";

const DEFAULT_EXPIRY_YEARS = 5;

export function getDateFiveYearsFromNow() {
  return new Date(
    new Date().setFullYear(new Date().getFullYear() + DEFAULT_EXPIRY_YEARS),
  );
}

export function validateExpiryDate(value) {
  const { autoRenew, status } = this.parent;

  if (autoRenew || status === "draft") {
    return true;
  }

  if (isBlank(value)) {
    return false;
  }

  const currentDate = new Date();
  const fiveYearsFromNow = getDateFiveYearsFromNow();
  const selectedDate = moment(value, "DD/MM/YYYY").toDate();

  return selectedDate > currentDate && selectedDate <= fiveYearsFromNow;
}

const FINANCING_STATEMENT_VALIDATION_SCHEMA = {
  autoRenew: yup.boolean(),
  expiryDate: yup
    .string()
    .test("expiry-date", "Please enter expiry date.", validateExpiryDate),
  expiryDuration: yup.string(),
};

const DEBTOR_VALIDATION_SCHEMA = {
  debtorAddress: COMMON_DEBTOR_VALIDATION_SCHEMA.debtorAddress,
  debtorEmail: COMMON_DEBTOR_VALIDATION_SCHEMA.debtorEmail,
  debtorPhone: COMMON_DEBTOR_VALIDATION_SCHEMA.debtorPhone,
  debtorReference: COMMON_DEBTOR_VALIDATION_SCHEMA.debtorReference,
  personFirstName: COMMON_PERSON_VALIDATION_SCHEMA.personFirstName,
  personLastName: COMMON_PERSON_VALIDATION_SCHEMA.personLastName,
};

const ORGANISATION_VALIDATION_SCHEMA = {
  organisationName: COMMON_ORGANISATION_VALIDATION_SCHEMA.organisationName,
  ...DEBTOR_VALIDATION_SCHEMA,
};

const PERSON_VALIDATION_SCHEMA = {
  personDateOfBirth: COMMON_PERSON_VALIDATION_SCHEMA.personDateOfBirth,
  ...DEBTOR_VALIDATION_SCHEMA,
};

const validateCollateralItemString = ({ maxCharacters, value }) => {
  if (isBlank(value)) {
    return false;
  }

  return value.length <= maxCharacters;
};

const validateCollateralItemYear = value => {
  if (isNaN(value)) {
    return false;
  }

  const minYear = 1700;
  const year = parseInt(value);
  const currentYear = new Date().getFullYear() + 1;

  return year >= minYear && year <= currentYear;
};

export default class NZValidationSchema {
  static getFinancingStatementValidationSchema() {
    return FINANCING_STATEMENT_VALIDATION_SCHEMA;
  }

  static getDebtorDetailsValidationSchema(debtorType) {
    if (debtorType === "organisation") {
      return ORGANISATION_VALIDATION_SCHEMA;
    }

    return PERSON_VALIDATION_SCHEMA;
  }

  static getCollateralDetailsValidationSchema() {
    return {
      additionalDetails: yup.object().when("collateralType", {
        is: collateralType => collateralTypeHasItems(collateralType),
        then: yup.object().shape({
          chassis: yup
            .string()
            .trim()
            .max(
              17,
              "Chassis Number must not be more than 17 characters long.",
            ),
          colour: yup
            .string()
            .trim()
            .max(20, "Colour must not be more than 20 characters long."),
          description: yup
            .string()
            .trim()
            .max(400, "Colour must not be more than 20 characters long."),
          itemId: yup.string(),
          make: yup
            .string()
            .trim()
            .test(
              "item-make",
              "Make is required and must not be more than 30 characters long.",
              value =>
                validateCollateralItemString({
                  maxCharacters: 30,
                  value,
                }),
            ),
          model: yup
            .string()
            .trim()
            .test(
              "item-model",
              "Model is required and must not be more than 30 characters long.",
              value =>
                validateCollateralItemString({
                  maxCharacters: 30,
                  value,
                }),
            ),
          registrationPlate: yup
            .string()
            .trim()
            .max(
              6,
              "Registration Number must not be more than 6 characters long.",
            ),
          vin: yup
            .string()
            .trim()
            .test(
              "item-vin",
              "Vehicle Identification Number (VIN) must be 17 characters long.",
              value =>
                validateCollateralItemString({
                  maxCharacters: 17,
                  value,
                }),
            ),
          year: yup
            .number()
            .typeError(
              "Year must be a value between 1700 and the current year plus one year",
            )
            .integer(
              "Year must be a value between 1700 and the current year plus one year",
            )
            .required("Year is required.")
            .test(
              "item-year",
              "Year must be a value between 1700 and the current year plus one year",
              validateCollateralItemYear,
            ),
        }),
      }),
      collateralDescription: yup
        .string()
        .trim()
        .when("collateralType", (collateralType, schema) => {
          if (collateralTypeHasDescription(collateralType)) {
            return schema.required("Please enter a collateral description.");
          }

          return schema;
        }),
      collateralType: COMMON_COLLATERAL_VALIDATION_SCHEMA.collateralType,
    };
  }

  static getRenewFinancingStatementValidationSchema() {
    return {
      expiryDate: yup
        .string()
        .test(
          "expiry-date",
          "Expiry date must be within 5 years from the current date.",
          validateExpiryDate,
        ),
      financingStatementPin: yup
        .string()
        .required("Please provide financing statement pin to renew."),
    };
  }

  static getModifyFinancingStatementValidationSchema() {
    return {
      autoRenew: yup.boolean(),
      expiryDate: yup
        .string()
        .test("expiry-date", "Please enter expiry date.", validateExpiryDate),
    };
  }
}
