import get from "lodash.get";
import commonStyles from "modules/addons/components/css/PPSRRegistration/EditComponent.css";
import { useCollateralOptions } from "modules/addons/components/digital-onboarding/PPSRRegistration/forms/CollateralPresets/CollateralPreset";
import { ContentContainer } from "modules/addons/components/DigitalOnboarding";
import React from "react";

export default function AUCollateralPresets(props) {
  const {
    clearError,
    collateralTypes,
    errors,
    register,
    setValue,
    versionConfig,
  } = props;

  const contents = useCollateralOptions({
    clearError,
    collateralTypes,
    register,
    setValue,
    versionConfig,
  });

  return (
    <ContentContainer header="Step 3: Pre-set your collateral details.">
      <div className={commonStyles.sub_header}>
        When registering a financing statement you can define the type of
        collateral your business wants to use, and pre-set a standard
        description for you to choose from.
      </div>
      <div className="has-text-danger">
        {get(errors, "collateralPreset.message", " ")}
      </div>
      {contents}
    </ContentContainer>
  );
}
