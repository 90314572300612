import get from "lodash.get";
import BaseModel from "models/BaseModel";
import { formatMoney } from "utils/formatting";
import isPresent from "utils/isPresent";

const DEFAULT_DECIMAL_PLACES = 2;

export default class CardholderModel extends BaseModel {
  get formattedName() {
    return [this.firstName, this.middleName, this.lastName]
      .filter(name => isPresent(name))
      .join(" ");
  }

  get answers() {
    return get(this.addonAnswer || {}, "answers", []);
  }

  get formattedCardLimit() {
    if (isPresent(this.cardLimit)) {
      return `$${formatMoney(
        parseFloat(this.cardLimit) || 0,
        DEFAULT_DECIMAL_PLACES,
      )}`;
    }

    return null;
  }
}
