export const DASHBOARD_GET_REPORT_START = "DASHBOARD_GET_REPORT_START";
export const DASHBOARD_GET_REPORT_ERROR = "DASHBOARD_GET_REPORT_ERROR";
export const DASHBOARD_GET_REPORT_SUCCESS = "DASHBOARD_GET_REPORT_SUCCESS";

export const DASHBOARD_GET_MONTHLY_REPORT_START =
  "DASHBOARD_GET_MONTHLY_REPORT_START";
export const DASHBOARD_GET_MONTHLY_REPORT_ERROR =
  "DASHBOARD_GET_MONTHLY_REPORT_ERROR";
export const DASHBOARD_GET_MONTHLY_REPORT_SUCCESS =
  "DASHBOARD_GET_MONTHLY_REPORT_SUCCESS";

export const DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_START =
  "DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_START";
export const DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_RESET =
  "DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_RESET";

export const DASHBOARD_LOAD_CURRENT_ENTITY_START =
  "DASHBOARD_LOAD_CURRENT_ENTITY_START";
export const DASHBOARD_LOAD_CURRENT_ENTITY_ERROR =
  "DASHBOARD_LOAD_CURRENT_ENTITY_ERROR";
export const DASHBOARD_LOAD_CURRENT_ENTITY_SUCCESS =
  "DASHBOARD_LOAD_CURRENT_ENTITY_SUCCESS";

export const DASHBOARD_SET_CONSUMER_AS_SUPPLIER_START =
  "DASHBOARD_SET_CONSUMER_AS_SUPPLIER_START";
export const DASHBOARD_SET_CONSUMER_AS_SUPPLIER_ERROR =
  "DASHBOARD_SET_CONSUMER_AS_SUPPLIER_ERROR";
export const DASHBOARD_SET_CONSUMER_AS_SUPPLIER_SUCCESS =
  "DASHBOARD_SET_CONSUMER_AS_SUPPLIER_SUCCESS";
export const DASHBOARD_SET_PERIOD = 'DASHBOARD_SET_PERIOD';
export const DASHBOARD_SET_TIER = 'DASHBOARD_SET_TIER';
