/* Import libraries */
/* Import components */
import SubSectionContainer from "modules/shared/components/containers/SubSectionContainer";
import Button from "modules/shared/components/inputs/Button";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import PlusButton from "modules/shared/components/inputs/PlusButton";
import TextInput from "modules/shared/components/inputs/TextInput";
import { isFeatureEditEnabled } from "modules/shared/helpers/headquarterDetect";
import React from "react";
import { connect } from "react-redux";
import { getObjectFromArray } from "utils/getObjectFromArray";

/* Import actions */
import {
  addExcludedReferee,
  removeExcludedSource,
  setExcludedSources,
  setExcludedSourcesIndex,
  updateExcludedSources,
} from "../actions.js";
/* Import CSS */
import styles from "./css/CommonEditProfile.css";

const ExcludedSources = createClass({
  addExcludedReferee() {
    const { dispatch, excluded_application_sources } = this.props;
    dispatch(addExcludedReferee(excluded_application_sources));

    const attributes = {
      excluded_application_sources,
    };
    dispatch(updateExcludedSources(attributes));
  },

  formFieldHandleChange(index, event) {
    const { dispatch, excluded_application_sources } = this.props;
    dispatch(
      setExcludedSources(
        excluded_application_sources,
        index,
        event.target.value,
      ),
    );
  },

  isValid() {
    const { excluded_application_sources } = this.props;
    return excluded_application_sources.every(v => {
      if (v.length < 1) {
        return false;
      }
      return true;
    });
  },

  removeExcludedSource() {
    const {
      dispatch,
      container_index,
      excluded_application_sources,
    } = this.props;
    dispatch(
      removeExcludedSource(excluded_application_sources, container_index),
    );

    this.updateContainerIndex(Math.max(0, container_index - 1));

    const attributes = {
      excluded_application_sources: excluded_application_sources
        .slice(0, container_index)
        .concat(excluded_application_sources.slice(container_index + 1)),
    };
    dispatch(updateExcludedSources(attributes));
  },

  render() {
    const {
      title,
      container_index,
      error,
      label,
      excluded_application_sources,
      changes_made,
      update_loading,
    } = this.props;
    const sub_pages = [];

    let loader = null;
    let update_button_text = "update excluded referees";
    let update_click = this.submit;
    const disable_btn = false;
    if (update_loading) {
      loader = <span className={styles.loading}></span>;
      update_button_text = "saving";
      update_click = event => {};
    }

    let update_button = null;
    if (changes_made) {
      update_button = (
        <div className={styles.button}>
          <Button
            handleClick={update_click}
            text={update_button_text}
            disabled={disable_btn}
            grey={disable_btn}
          />
          {loader}
        </div>
      );
    }

    if (excluded_application_sources.length == 0) {
      var value = "";
      sub_pages.push(
        <div className={styles.items_col}>
          <TextInput
            key={"excluded_application_sources-0"}
            id={"excluded_application_sources-0"}
            error={error}
            handleChange={this.formFieldHandleChange.bind(null, 0)}
            handleBlur={this.submit}
            label={label}
            value={value}
            disabled={!isFeatureEditEnabled("Credit")}
            required={true}
          />
        </div>,
      );
    } else {
      for (let i = 0; i <= excluded_application_sources.length - 1; i++) {
        var value = "";
        if (excluded_application_sources[i] !== undefined) {
          value = excluded_application_sources[i];
        }
        sub_pages.push(
          <div className={styles.items_col}>
            <TextInput
              key={`excluded_application_sources-${i}`}
              id={`excluded_application_sources-${i}`}
              error={error}
              handleChange={this.formFieldHandleChange.bind(null, i)}
              handleBlur={this.submit}
              label={label}
              value={value}
              disabled={!isFeatureEditEnabled("Credit")}
              required={true}
            />
          </div>,
        );
      }
    }

    return (
      <div className={styles.row}>
        <div className={styles.block_wide}>
          <div className={styles.liner}>
            <h2 className={styles.header}>{title}</h2>
            {update_button}
            <div className={styles.row}>
              <SubSectionContainer
                inactiveButtons={true}
                optional={true}
                subPages={sub_pages}
                subsection_index={container_index}
                handleClick={this.updateContainerIndex}
              >
                {this.showAddSourceButton()}
                {this.showRemoveButton()}
              </SubSectionContainer>
            </div>
          </div>
        </div>
      </div>
    );
  },

  showAddSourceButton() {
    if (this.isValid() && isFeatureEditEnabled("Credit")) {
      return (
        <div className={styles.remove_button}>
          <PlusButton text="+" handleClick={this.addExcludedReferee} />
        </div>
      );
    }
    return null;
  },

  showRemoveButton() {
    const { excluded_application_sources, container_index } = this.props;
    if (excluded_application_sources.length > 1) {
      return (
        <div className={styles.remove_button}>
          <CloseButton
            theme={"relative"}
            text="x"
            handleClick={this.removeExcludedSource}
          />
        </div>
      );
    }
  },

  submit() {
    const { dispatch, excluded_application_sources } = this.props;
    const attributes = {
      excluded_application_sources,
    };
    dispatch(updateExcludedSources(attributes));
  },

  updateContainerIndex(index) {
    const { dispatch } = this.props;
    dispatch(setExcludedSourcesIndex(index));
  },
});

const defaults = {
  form_label: "Enter referees here",
  title: "Add trade references that you do not accept",
};

module.exports = connect((state, ownProps) => {
  const ent = state.manage_profile.current_entity.attributes;
  const s = state.manage_profile;

  const container_index =
    s.settings_excluded_application_sources_container_index || 0;
  const excluded_application_sources =
    s.settings_excluded_application_sources != null
      ? s.settings_excluded_application_sources
      : ent.excluded_application_sources;

  const changes_made =
    s.settings_section_edited != null
      ? s.settings_section_edited["excluded_application_sources"]
      : false;

  return {
    changes_made,
    container_index,
    excluded_application_sources: excluded_application_sources || [],
    label: defaults.form_label,
    title: defaults.title,
    update_loading: s.settings_excluded_application_sources_updating,
  };
})(ExcludedSources);
