import axios from './axios';

let AddressSearch = function(config) {
  config.timeout = 20000;
  this.axios = axios(config);
};

AddressSearch.prototype.addressSearch = function(success, error, options) {
  return this.axios
    .get("/address_search", options)
    .then(success)
    .catch(error);
};

export default AddressSearch;
