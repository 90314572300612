import React, { ReactElement, useState } from "react";
import WebsiteButtonLink from "modules/addons/components/WebsiteButtonLink";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
import { connect } from "react-redux";
import extractAttachmentUrl from "utils/extractAttachmentUrl";

import styles from "../css/styles.css";
import WebsitebuttonCheckboxes from "../WebsitebuttonCheckboxes";

type Props = {
  current_user: any,
  entityId: string,
  logo: string,
}

function WebsitebuttonList({
  current_user,
  entityId,
  logo,
}: Props): ReactElement {
  const [showToolTip, setShowToolTip] = useState(!logo);
  const [showTextbox, setShowTextbox] = useState(false);

  const dismissToolTip = () => {
    setShowToolTip(false);
  }

  const websiteButtonLink = `${window.location.origin}/websitebutton/${entityId}/start`;
  return (
    <div className={styles.row}>
      <WebsitebuttonCheckboxes
       currentUser={current_user}
       setShowTextbox={setShowTextbox}
      />
      {
        showTextbox && (
          <WebsiteButtonLink.Component
            currentUser={current_user}
            link={websiteButtonLink}
            logo={logo}
            text="Start trade application"
          />
        )
      }
      {
       showToolTip && (
        <ToolTip
          tip_name="IntegrationsWebsiteButton"
          css_style="integrations_website_button"
          dismissHandler={dismissToolTip}
        />
       )
      }
    </div>
  );
}

export default connect((state) => {
  const current_user = state.current_user;

  const logo = extractAttachmentUrl(
    state.current_user.current_entity.attributes.logo,
    "logo",
  );

  return {
    current_user,
    entityId: state.current_user.current_entity.id,
    logo,
  };
})(WebsitebuttonList);
