import {
  COB_FINANCIALS_COMPONENT_COMPLETE,
  COB_FINANCIALS_LOAD_ANSWERS,
  COB_FINANCIALS_REMOVE_ADDITIONAL_FIELDS,
  COB_FINANCIALS_RESET_DATA,
  COB_FINANCIALS_SET_ADDITIONAL_FIELDS,
  COB_FINANCIALS_SET_ANSWER,
  COB_FINANCIALS_SET_FILE,
} from "../constants/financials";

const financialsDefaults = {
  answers: {
    assets: [],
    expenses: [],
    income: [],
    liabilities: [],
    net_worth: 0,
    surplus: 0,
    total_assets: 0,
    total_expenses: 0,
    total_income: 0,
    total_liabilities: 0,
  },
  completed: {},
  file: null,
};

export function consumerOnboardingFinancialsReducer(
  state = financialsDefaults,
  action,
) {
  switch (action.type) {
    case COB_FINANCIALS_SET_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.name]: action.payload.value,
        },
      };
    case COB_FINANCIALS_REMOVE_ADDITIONAL_FIELDS: {
      const answer = state.answers[action.payload.name];
      const index = answer.findIndex(i => i.description_1 === action.payload.value.description_1);
      if (index > -1) {
        answer.splice(index, 1);
        return { ...state, answers: { ...state.answers, [action.payload.name]: answer } };
      }
      return { ...state }
    }
    case COB_FINANCIALS_SET_ADDITIONAL_FIELDS: {
      const index = state.answers[action.payload.name].findIndex(
        i => i.description_1 === action.payload.value.description_1,
      );
      if (index > -1) {
        const formValues = state.answers[action.payload.name].slice();
        formValues[index] = action.payload.value;

        return {
          ...state,
          answers: { ...state.answers, [action.payload.name]: formValues },
        };
      }
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.name]: [
            ...state.answers[action.payload.name],
            action.payload.value,
          ],
        },
      };
    }
    case COB_FINANCIALS_COMPONENT_COMPLETE: {
      const componentState = {};
      componentState[action.payload.component] = action.payload.state;
      return { ...state, completed: { ...state.completed, ...componentState } };
    }
    case COB_FINANCIALS_SET_FILE:
      return {
        ...state,
        file: action.payload,
      };
    case COB_FINANCIALS_RESET_DATA:
      return { ...financialsDefaults };
    case COB_FINANCIALS_LOAD_ANSWERS:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}
