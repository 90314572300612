import { businessComponentComplete, showWarning } from "../actions/onboarding";
import {
  getAppColorPalette,
  loadApplicationBusiness,
  loadApplicationSections,
  loadApplicationSignatureFromPartyDetails,
  loadApplicationSupplier,
  loadClientPeopleDetails,
  setToInprogress,
} from "../actions/section";
import {
  saveCardholder,
  saveCardholders,
  setCardholdersCountCompletedState,
  updateCardholdersCount,
} from "../actions/cards";
import {
  updateAddonAnswerData,
  updateApplicationAddonRules,
  updateApplicationData,
  updateApplicationExtraData,
  updateBusinessSection,
  updateGuarantorsData,
  updateIdentificationData,
  updateTradeReferencesData,
} from "../actions/review";

import ApplicationOverlay from "modules/shared/components/top/ApplicationOverlay";
import ApplicationProgressDots from "modules/shared/components/widgets/static/ApplicationProgressDots";
import ApplicationStart from "./ApplicationStart.js";
import ArrowButton from "modules/shared/components/widgets/interactive/ArrowButton";
import Button from "modules/shared/components/inputs/Button";
import Loader from "modules/shared/components/widgets/static/Loader";
/* eslint-disable no-debugger */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable camelcase */
import MaterialButton from "@material-ui/core/Button";
import React, { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import ReactTimeout from "react-timeout";
import SectionCircle from "modules/shared/components/widgets/static/SectionCircle";
import SectionDescription from "modules/shared/components/widgets/static/SectionDescription";
import SquareModal from "modules/shared/components/widgets/static/SquareModal";
import { TERMS_AND_CONDITIONS } from "../../../constants";
import { addtionalComponentComplete } from "../actions/additional";
import { browserHistory } from "react-router";
import { componentFactory } from "../components";
import { connect } from "react-redux";
import { customTermsUrl } from "utils/extractAttachmentUrl";
import { debounce } from "throttle-debounce";
import { extrasComponentComplete } from "../actions/extras";
import { financialsComponentComplete } from "../actions/financials";
import { guarantorComponentComplete } from "../actions/guarantors";
import { identityComponentComplete } from "../../identity/actions";
import { isMobile } from "modules/shared/helpers/mobileDetect";
import isPresent from "utils/isPresent";
import { loadingLiveChat } from "utils/liveChat.js";
import { paginate } from "modules/shared/helpers/additionalSectionHelper";
import { paperlessComponentComplete } from "../actions/paperless";
import styles from "./css/ConsumerApplication.css";
import { traderefComponentComplete } from "../actions/trade-reference";
import get from "lodash.get";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import SearchIcon from "@material-ui/icons/Search";

const BusinessOverviewButtons = props => {
  const { component, section } = props;

  if (section !== "business" || component !== "overview") {
    return null;
  }

  return (
    <Fragment>
      <Button
        white
        text={
          <div className="is-flex is-align-items-center">
            <SearchIcon />
            <span className="ml-2">ABN Lookup</span>
          </div>
        }
        link="https://abr.business.gov.au/"
        target="_blank"
      />
      <Button
        white
        text={
          <div className="is-flex is-align-items-center">
            <SearchIcon />
            <span className="ml-2">ASIC Lookup</span>
          </div>
        }
        link="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx"
        target="_blank"
      />
    </Fragment>
  );
};

// eslint-disable-next-line no-undef
const ConsumerOnBoarding = createClass({
  UNSAFE_componentWillMount() {
    const { params, current_user } = this.props;
    const userAttributes = {
      email: current_user.data.data.attributes.email,
      firstName: current_user.data.data.attributes.first_name,
      id: current_user.data.data.id,
      lastName: current_user.data.data.attributes.last_name,
      restoreId: current_user.data.data.attributes.freshchat_restore_id,
    };
    loadingLiveChat("consumer", userAttributes);

    if (!(params.section === "start")) {
      const link = `/register/consumer/${params.application_id}/start`;
      browserHistory.push(link);
    }
  },

  /**
   * Helper function used to format the path to this section/component
   */
  confirmReview() {
    const { dispatch, application, consumer_account } = this.props;
    dispatch(loadClientPeopleDetails());
    dispatch(
      updateApplicationData(
        application.id,
        `/register/consumer/${application.id}/review/supplier-terms`,
        consumer_account,
      ),
    );
  },

  formatLink(section, component, component_index) {
    const { application } = this.props;
    if (!application) {
      return null;
    }

    let link = `/register/consumer/${application.id}/${section}`;
    if (component) {
      link = `${link}/${component}`;
    }
    if (component_index) {
      link = `${link}/${component_index}`;
    }
    return link;
  },

  getArrowLinks() {
    const {
      additionalTotalPage,
      application_submitting,
      application,
      cardholdersCount,
      component,
      component_index,
      entity_type,
      financialsAddon,
      party_count,
      review_complete,
      section,
      submitting,
    } = this.props;
    const { page_complete } = this.state;

    const links = [];
    const flow = this.getSectionLinks(section);
    const index = flow.findIndex(i => i.component === component);
    let component_indexes;

    switch (component) {
      case "referees":
        component_indexes = application.attributes.minimum_trade_references;
        break;
      case "party-details":
        component_indexes = party_count;
        break;
      case "card-details":
        component_indexes = cardholdersCount;
        break;
      case "additional-details":
        component_indexes = additionalTotalPage;
        break;
    }

    if (!application) {
      return links;
    }

    if (section === "review") {
      if (!component) {
        let checks = review_complete.checks;
        let extras_check = review_complete.extras;
        const financials = financialsAddon ? review_complete.financials : true;
        const no_required_gtors = entity_type !== "company";
        if (application.attributes.application_type === "cash") {
          checks = true;
          if (
            application.attributes.additional_questions_applies === "credit" ||
            !application.attributes.additional_application_questions ||
            application.attributes.additional_application_questions.length === 0
          ) {
            extras_check = true;
          }
        } else if (
          (application.attributes.minimum_trade_references === 0 ||
            entity_type === "personal") &&
          !application.attributes.requires_guarantees
        ) {
          checks = true;
        } else if (
          (application.attributes.minimum_trade_references === 0 ||
            entity_type === "personal") &&
          no_required_gtors
        ) {
          checks = true;
        }
        if (
          review_complete.business &&
          checks &&
          extras_check &&
          financials &&
          this.isCardsReviewSectionComplete()
        ) {
          links.push(
            <ArrowButton
              css_style="button_small_font"
              key="next"
              text="Next"
              down={false}
              next={false}
              handleClick={this.confirmReview}
              icon="&darr;"
              loading_text="loading"
              disableOnLoading={true}
              loading={submitting || application_submitting}
            />,
          );
        } else {
          links.push(
            <ArrowButton
              css_style="button_small_font"
              key="Next"
              down={true}
              next={false}
              text="Next"
              handleClick={() => {
                this.setState({ page_validation_start: true });
              }}
            />,
          );
        }
      }
      return links;
    }
    if (component) {
      // Lower level component pages.
      if (
        section === "business" &&
        (component === "type" || component === "other-type")
      ) {
        return links;
      }

      if (page_complete === false) {
        links.push(
          <ArrowButton
            key="next"
            css_style="button_small_font"
            down={false}
            next={false}
            text="Next"
            handleClick={() => {
              this.setState({ page_validation_start: true });
            }}
          />,
        );
      } else {
        if (
          isPresent(component_index) &&
          component_index + 1 !== component_indexes
        ) {
          const link = this.getNextLink(flow, component_index);

          if (section === "cards") {
            links.push(
              <ArrowButton
                key="next"
                css_style="button_small_font"
                down={false}
                next={false}
                text="Next"
                handleClick={() => {
                  this.saveState(link);
                }}
                disableOnLoading={true}
                loading={submitting}
                loading_text="loading"
              />,
            );
          } else {
            links.push(
              <ArrowButton
                key="next"
                css_style="button_small_font"
                down={false}
                next={false}
                text="Next"
                link={link}
              />,
            );
          }
        } else {
          if (index === flow.length - 1) {
            if (section === "identity" || section === "extras") {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  handleClick={() => {
                    this.saveState(this.getNextLink(flow));
                  }}
                  disableOnLoading={true}
                  loading={submitting}
                  loading_text="loading"
                />,
              );
            } else {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  handleClick={() => {
                    this.saveState(this.getNextLink(flow));
                  }}
                  disableOnLoading={true}
                  loading={submitting}
                />,
              );
            }
          } else {
            if (section === "guarantors" || section === "trade-reference") {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  link={this.getNextLink(flow)}
                  handleClick={() => {
                    this.saveState(this.getNextLink(flow));
                  }}
                />,
              );
            } else if (section === "cards" && component === "card-order-form") {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  handleClick={() => {
                    this.saveState(this.getNextLink(flow));
                  }}
                  disableOnLoading={true}
                  loading={submitting}
                  loading_text="loading"
                />,
              );
            } else {
              links.push(
                <ArrowButton
                  key="next"
                  css_style="button_small_font"
                  down={false}
                  next={false}
                  text="Next"
                  link={this.getNextLink(flow)}
                />,
              );
            }
          }
        }
      }
    }

    return links;
  },

  /**
   * Save current information of the onboarding flow to API.
   */
  getBackLinks() {
    const {
      application_submitting,
      component_index,
      component,
      consumer_account,
      entity_region,
      hasOneApplicableLegalTypes,
      section,
      submitting,
    } = this.props;
    const links = [];
    const flow = this.getSectionLinks(section);

    if (section === "business" && component === "overview") {
      return;
    }

    if (
      (!(consumer_account || hasOneApplicableLegalTypes) &&
        section === "business" &&
        component === "type") ||
      ((consumer_account || hasOneApplicableLegalTypes) &&
        section === "business" &&
        component === "other-details" &&
        entity_region === "NZ") ||
      submitting ||
      application_submitting ||
      (section === "review" && component)
    ) {
      return;
    }

    let link;
    if (isPresent(component_index) && component_index - 1 >= 0) {
      link = this.getPrevLink(flow, component_index);
    } else {
      link = this.getPrevLink(flow, null);
    }

    links.push(
      <ArrowButton
        key="back"
        css_style="button_small_font"
        prev={false}
        text="Back"
        link={link}
      />,
    );

    return links;
  },

  /**
   * Persist cardholders in the back-end every page to avoid problems when users
   * encounters issues at the middle of the section, i.e. when filling out 20
   * cardholders and they get stuck at 10, we don't want to user to re-do the
   * prior records
   */
  getComponentProps() {
    const {
      application,
      completed,
      component_index,
      component,
      consumerName,
      consumer_account,
      hasOneApplicableLegalTypes,
      isProofOfAddressVisible,
      requiresID,
      section,
      sections,
      supplier_trading_name,
    } = this.props;
    const { page_validation_start } = this.state;
    let complete = false;

    if (
      Object.keys(completed).includes(section) &&
      Object.keys(completed[section]).includes(component)
    ) {
      complete = completed[section][component];
    }
    return {
      ...sections[section],
      application,
      complete,
      component,
      component_index,
      consumerName,
      consumer_account,
      handleComplete: this.updateCompleteState,
      hasOneApplicableLegalTypes,
      isProofOfAddressVisible,
      page_validation_start,
      requiresID,
      section,
      setPageValidationStartFinish: this.setPageValidationStartFinish,
      supplierName: supplier_trading_name,
    };
  },

  /**
   * Returns the properties for the current section and component.
   */
  getNextLink(flow, component_index) {
    const { sections, section, component } = this.props;
    const section_links = Object.keys(sections);
    const index = flow.findIndex(i => i.component === component);

    // if (section === "identity" && index + 1 === flow.length) {
    //   return this.formatLink(section_links[section_links.indexOf(section) + 1]);
    // }

    if (isPresent(component_index)) {
      return this.formatLink(
        section,
        flow[index].component,
        component_index + 1,
      );
    }

    if (index === flow.length - 1) {
      const nextSectionComponents = this.getSectionLinks(
        section_links[section_links.indexOf(section) + 1],
      );
      return this.formatLink(
        section_links[section_links.indexOf(section) + 1],
        nextSectionComponents[0].component,
      );
    }
    return this.formatLink(section, flow[index + 1].component);
  },

  /**
   * Helper function that returns links and buttons for the given
   * section/component.
   */

  getPrevLink(flow, component_index) {
    const { sections, section, component } = this.props;
    const section_links = Object.keys(sections);
    const index = flow.findIndex(i => i.component === component);

    if (index < 0) return "#";

    const sectionLink = section_links[section_links.indexOf(section) - 1];
    const previousSectionComponents = this.getSectionLinks(sectionLink);

    // When in the guarantors/confirm section, skip the alert path a go straight
    // to the previous section.
    if (section === "guarantors") {
      let previousComponent;

      // Skip the alert section of trade references
      if (sectionLink === "trade-reference") {
        previousComponent = previousSectionComponents[0].component;
      } else {
        // eslint-disable-next-line max-len
        previousComponent =
          previousSectionComponents[previousSectionComponents.length - 1]
            .component;
      }

      return this.formatLink(sectionLink, previousComponent);
    }

    if (isPresent(component_index)) {
      return this.formatLink(
        section,
        flow[index].component,
        component_index - 1,
      );
    }

    if (index > 0) {
      return this.formatLink(section, flow[index - 1].component);
    }

    if (previousSectionComponents.length > 0) {
      let previousComponentIndex = previousSectionComponents.length - 1;

      if (
        // Skip the alert section of trade references
        (component === "confirm" || component === "questions") &&
        sectionLink === "trade-reference"
      ) {
        previousComponentIndex = 0;
      }

      return this.formatLink(
        sectionLink,
        previousSectionComponents[previousComponentIndex].component,
      );
    }
  },

  getMobileIndex() {
    const {
      section,
      component,
      component_index,
      minimum_trade_references,
      required_guarentees,
      requiresID,
      party_count,
      selected_type,
      entity_region,
      cardholdersCount,
      cardsAddon,
      financialsAddon,
      paperlessAddon,
      additionalTotalPage,
    } = this.props;
    const guarantee_count = required_guarentees > 0 ? 1 : 0;
    const identification_count = requiresID ? 2 : 1;
    const share_percentage_count =
      party_count > 0 && selected_type === "partnership" ? 1 : 0;
    const applied_party_count =
      selected_type === "partnership" ||
      selected_type === "trust" ||
      (entity_region === "AU" && selected_type === "company") ||
      selected_type === "personal"
        ? party_count
        : 0;

    const business_index =
      entity_region === "AU" && selected_type !== "personal" ? 3 : 2;
    const trade_reference_count =
      selected_type === "personal" ? 0 : minimum_trade_references;

    const cardsSectionCount = cardsAddon ? 1 + (cardholdersCount || 0) : 0;

    const financialsSectionCount = financialsAddon ? 1 : 0;

    const additionalSectionCount = additionalTotalPage;

    const paperlessSectionCount = paperlessAddon ? 1 : 0;

    switch (section) {
      case "business":
        if (component === "other-details") {
          return 1;
        }
        // if (component === "company-details") {
        //   // AU Company
        //   return 2;
        // }
        if (component === "party-details") {
          if (!component_index) {
            return business_index;
          }
          return business_index + component_index;
        }
        if (component === "share") {
          return business_index + applied_party_count;
        }

        break;
      case "trade-reference":
        if (component === "referees") {
          if (!component_index) {
            return (
              business_index + applied_party_count + share_percentage_count
            );
          }
          if (component_index === 1) {
            return (
              business_index + 1 + applied_party_count + share_percentage_count
            );
          }
          if (component_index === 2) {
            return (
              business_index + 2 + applied_party_count + share_percentage_count
            );
          }
        }

        break;
      case "guarantors":
        if (component === "confirm") {
          if (!component_index) {
            return (
              business_index +
              trade_reference_count +
              applied_party_count +
              share_percentage_count
            );
          }
          if (component_index === 1) {
            return (
              business_index +
              1 +
              trade_reference_count +
              applied_party_count +
              share_percentage_count
            );
          }
        }

        break;
      case "extras":
        if (component === "questions") {
          return (
            business_index +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count
          );
        }

        break;
      case "cards": {
        let cardsCount = 0;
        if (component === "card-order-form") {
          cardsCount = 1;
          return (
            business_index +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsCount
          );
        }
        if (component === "card-details") {
          cardsCount = component_index ? 2 + component_index : 2;
          return (
            business_index +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsCount
          );
        }
        break;
      }
      case "financials": {
        return (
          business_index +
          guarantee_count +
          trade_reference_count +
          applied_party_count +
          share_percentage_count +
          cardsSectionCount +
          1
        );
      }
      case "additional": {
        return (
          business_index +
          guarantee_count +
          trade_reference_count +
          applied_party_count +
          share_percentage_count +
          cardsSectionCount +
          financialsSectionCount +
          1
        );
      }
      case "paperless": {
        return (
          business_index +
          guarantee_count +
          trade_reference_count +
          applied_party_count +
          share_percentage_count +
          cardsSectionCount +
          financialsSectionCount +
          additionalSectionCount +
          1
        );
      }
      case "identity":
        if (component === "confirm") {
          return (
            business_index +
            1 +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsSectionCount +
            financialsSectionCount +
            additionalSectionCount +
            paperlessSectionCount
          );
        }

        break;
      case "review":
        if (!component) {
          return (
            business_index +
            1 +
            identification_count +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsSectionCount +
            financialsSectionCount +
            additionalSectionCount +
            paperlessSectionCount
          );
        }
        if (component === "supplier-terms") {
          return (
            business_index +
            2 +
            identification_count +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsSectionCount +
            financialsSectionCount +
            additionalSectionCount +
            paperlessSectionCount
          );
        }
        if (component === "terms") {
          return (
            business_index +
            3 +
            identification_count +
            guarantee_count +
            trade_reference_count +
            applied_party_count +
            share_percentage_count +
            cardsSectionCount +
            financialsSectionCount +
            additionalSectionCount +
            paperlessSectionCount
          );
        }

        break;
      default:
        return "";
    }
    return "";
  },

  goToNextLink(section) {
    const flow = this.getSectionLinks(section);
    browserHistory.push(this.getNextLink(flow));
  },

  // eslint-disable-next-line complexity
  componentGetDots() {
    const { sections, section } = this.props;
    let section_index = 0;
    const links = [];
    const sort_sections = { ...sections };

    if (sections["trade-reference"] && sections["guarantors"]) {
      delete sort_sections.guarantors;
    }

    // Not in a component so look at sections
    section_index = Object.keys(sort_sections).indexOf(section);
    if (section === "guarantors") {
      section_index = 1;
    }
    Object.keys(sort_sections).forEach(item => {
      if (item !== "review") {
        const complete = this.isSectionComplete(item);
        if (this.state.page_complete) {
          links.push({
            complete,
            label: sort_sections[item].title,
            path: this.formatLink(item, sort_sections[item].default),
          });
        } else {
          links.push({
            complete,
            label: sort_sections[item].title,
          });
        }
      }
    });
    if (links.length > 1) {
      return (
        <ApplicationProgressDots
          type="horizontal"
          index={section_index}
          links={links}
          mobile_pages={this.getMobilePages()}
          mobile_index={this.getMobileIndex()}
        />
      );
    }
  },

  redirect(redirectUrl) {
    browserHistory.push(redirectUrl);
  },

  UNSAFE_componentWillReceiveProps(newProps) {
    const { sections, component, location, component_index } = this.props;

    if (location.pathname !== newProps.location.pathname) {
      const current_location = location.pathname;
      const new_location = newProps.location.pathname;
      const links = this.getSectionLinkArray();
      const section_links = Object.keys(sections);
      if (component) {
        if (isPresent(component_index) && isPresent(newProps.component_index)) {
          if (this.props.section === newProps.section) {
            // eslint-disable-next-line max-depth
            if (component_index > newProps.component_index) {
              this.setState({ animation: "scroll-left" });
            } else {
              this.setState({ animation: "scroll-right" });
            }
          } else {
            // eslint-disable-next-line max-depth
            if (
              section_links.indexOf(this.props.section) >
              section_links.indexOf(newProps.section)
            ) {
              this.setState({ animation: "scroll-left" });
            } else {
              this.setState({ animation: "scroll-right" });
            }
          }
        } else {
          if (this.props.section === newProps.section) {
            // eslint-disable-next-line max-depth
            if (links.indexOf(current_location) > links.indexOf(new_location)) {
              this.setState({ animation: "scroll-left" });
            } else {
              this.setState({ animation: "scroll-right" });
            }
          } else {
            // eslint-disable-next-line max-depth
            if (
              section_links.indexOf(this.props.section) >
              section_links.indexOf(newProps.section)
            ) {
              this.setState({ animation: "scroll-left" });
            } else {
              this.setState({ animation: "scroll-right" });
            }
          }
        }
      }
    }
  },

  saveCardholdersState(redirectUrl) {
    const { application, component, component_index, dispatch } = this.props;

    dispatch(
      saveCardholders(() => {
        this.setPageValidationStartFinish();
        browserHistory.push(redirectUrl);
      }),
    );
  },

  getInitialState() {
    return {
      animation: "scroll-right",
      loading: true,
      page_complete: false,
      mobile_min_height: document.documentElement.clientHeight - 56,
      page_validation_start: false,
      showLoader: true,
    };
  },

  /**
   * Get the button links for this section and flow.
   */
  // eslint-disable-next-line complexity
  saveState(redirectUrl) {
    const {
      dispatch,
      section,
      application,
      current_entity,
      consumer_account,
      requires_applicant_identity_check,
      identity_image_64,
      entity_type,
      financialsAddon,
      additionalAddon,
      paperlessAddon,
      requiresApplicantAmlCheck,
    } = this.props;

    switch (section) {
      case "business":
        const isCashApplication =
          application.attributes.application_type === "cash";

        dispatch(
          updateBusinessSection({
            applicationId: application.id,
            consumerAccount: consumer_account,
            entityId: current_entity.id,
            entityType: entity_type,
            successCallback: () => {
              if (!isCashApplication) {
                this.goToNextLink(section);
              }
            },
          }),
        );

        if (isCashApplication) {
          dispatch(
            updateApplicationAddonRules(current_entity.id, () => {
              this.goToNextLink(section);
            }),
          );
        }

        dispatch(loadClientPeopleDetails());
        if (entity_type === "personal") {
          dispatch(loadApplicationSignatureFromPartyDetails());
        }

        break;
      case "trade-reference":
        dispatch(
          updateTradeReferencesData(current_entity.id, () => {
            browserHistory.push(redirectUrl);
          }),
        );
        break;
      case "guarantors":
        dispatch(
          updateGuarantorsData(current_entity.id, () => {
            browserHistory.push(redirectUrl);
          }),
        );
        break;
      case "extras":
        dispatch(
          updateApplicationExtraData(current_entity.id, () => {
            this.goToNextLink(section);
          }),
        );
        break;
      case "identity":
        dispatch(
          updateIdentificationData(current_entity.id, () => {
            browserHistory.push(redirectUrl);
          }),
        );
        break;
      case "financials":
        dispatch(
          updateAddonAnswerData(
            application.id,
            financialsAddon.id,
            "financials",
            () => {
              browserHistory.push(redirectUrl);
            },
          ),
        );
        break;
      case "cards":
        this.saveCardholdersState(redirectUrl);
        break;
      case "additional":
        dispatch(
          updateAddonAnswerData(
            application.id,
            additionalAddon.id,
            "additional",
            () => {
              browserHistory.push(redirectUrl);
            },
          ),
        );
        break;
      case "paperless":
        dispatch(
          updateAddonAnswerData(
            application.id,
            paperlessAddon.id,
            "paperless",
            () => {
              browserHistory.push(redirectUrl);
            },
          ),
        );
        break;
    }
  },

  componentDidMount() {
    const {
      params: { application_id },
      dispatch,
    } = this.props;
    const that = this;
    this.scrollToRoute = debounce(200, true, this.scrollToRoute);

    dispatch(setToInprogress(application_id));

    setTimeout(() => {
      dispatch(
        loadApplicationSupplier(application_id, () => {
          dispatch(loadApplicationSections(application_id));
          dispatch(
            loadApplicationBusiness(() => that.setState({ loading: false })),
          );
        }),
      );
      dispatch(loadClientPeopleDetails());
      dispatch(getAppColorPalette(application_id));
    }, 1000);
    window.scrollTo(0, 0);
  },

  // eslint-disable-next-line complexity
  getMobilePages() {
    const {
      required_guarentees,
      minimum_trade_references,
      requiresID,
      party_count,
      selected_type,
      entity_region,
      cardholdersCount,
      cardsAddon,
      financialsAddon,
      paperlessAddon,
      additionalTotalPage,
    } = this.props;
    const guarantee_count = required_guarentees > 0 ? 1 : 0;
    const identification_count = requiresID ? 2 : 1;
    const share_percentage_count =
      party_count > 0 && selected_type === "partnership" ? 1 : 0;
    const applied_party_count =
      selected_type === "partnership" ||
      selected_type === "trust" ||
      (entity_region === "AU" && selected_type === "company") ||
      selected_type === "personal"
        ? party_count
        : 0;
    // const au_company_details_count =
    //   entity_region === "AU" && selected_type !== "personal" ? 1 : 0;
    const trade_reference_count =
      selected_type === "personal" ? 0 : minimum_trade_references;

    const cardsSectionCount = cardsAddon ? 1 + (cardholdersCount || 0) : 0;
    const financialsSectionCount = financialsAddon ? 1 : 0;

    const additionalSectionCount = additionalTotalPage;

    const paperlessSectionCount = paperlessAddon ? 1 : 0;

    return (
      5 +
      identification_count +
      guarantee_count +
      trade_reference_count +
      applied_party_count +
      share_percentage_count +
      // au_company_details_count +
      cardsSectionCount +
      financialsSectionCount +
      additionalSectionCount +
      paperlessSectionCount
    );
  },

  getSectionLinkArray() {
    const { section } = this.props;
    const links = this.getSectionLinks(section);
    const link_array = [];
    links.forEach(item => {
      link_array.push(this.formatLink(item.section, item.component));
    });
    return link_array;
  },

  /**
   * Helper function to get the dots along the top or the right.
   *
   * TODO: Make this check for completion.
   */
  downloadPdfSupplierTnc() {
    const { application, supplier } = this.props;
    const hasCustomTerms =
      application.attributes.uses_custom_supplier_terms &&
      isPresent(get(application, "attributes.custom_terms.url"));
    let pdf_url;

    if (hasCustomTerms) {
      pdf_url = customTermsUrl(application.attributes.custom_terms).url;
    } else {
      if (supplier) {
        pdf_url = TERMS_AND_CONDITIONS[supplier.attributes.region].supplier;
      }
    }

    const win = window.open("", "pdf_window");
    win.open(pdf_url, "pdf_window");
  },

  getSectionLinks(section) {
    const {
      application,
      selected_type,
      entity_region,
      consumer_account,
      requiresID,
      hasOneApplicableLegalTypes,
    } = this.props;
    const links = [];

    if (!application) {
      return links;
    }
    switch (section) {
      case "identity": {
        links.push({
          component: "confirm",
          required: true,
          complete: this.isComponentComplete(section, "confirm"),
          section,
        });
        break;
      }
      case "business": {
        // TODO: refactor this part. This is no longer scalable.
        if (entity_region === "AU") {
          links.push({
            component: "overview",
            required: true,
            complete: this.isComponentComplete(section, "overview"),
            section,
          });
        } else {
          if (!consumer_account && !hasOneApplicableLegalTypes) {
            links.push({
              component: "type",
              required: true,
              complete: this.isComponentComplete(section, "type"),
              section,
            });
          }
          if (!selected_type) {
            links.push({
              component: "other-type",
              required: true,
              complete: this.isComponentComplete(section, "other-type"),
              section,
            });
          }
        }
        links.push({
          component: "other-details",
          required: true,
          complete: this.isComponentComplete(section, "other-details"),
          section,
        });
        if (selected_type === "company") {
          links.push({
            component: "child",
            required: true,
            complete: this.isComponentComplete(section, "child"),
            section,
          });
        }
        if (entity_region === "AU") {
          // if (selected_type !== "personal" && selected_type !== "company") {
          //   links.push({
          //     component: "company-details",
          //     required: true,
          //     complete: this.isComponentComplete(section, "company-details"),
          //     section,
          //   });
          // }
          if (
            selected_type === "company" &&
            application.attributes.application_type !== "cash"
          ) {
            links.push({
              component: "party-details",
              required: true,
              complete: this.isComponentComplete(section, "party-details"),
              section,
            });
          }
        }
        switch (selected_type) {
          case "partnership":
          case "trust":
            if (application.attributes.application_type === "credit") {
              links.push({
                component: "party-details",
                required: true,
                complete: this.isComponentComplete(section, "party-details"),
                section,
              });
              if (selected_type === "partnership") {
                links.push({
                  component: "share",
                  required: true,
                  complete: this.isComponentComplete(section, "share"),
                  section,
                });
              }
            }
            break;
          case "personal":
            links.push({
              component: "party-details",
              required: true,
              complete: this.isComponentComplete(section, "party-details"),
              section,
            });
            if (selected_type === "partnership") {
              links.push({
                component: "share",
                required: true,
                complete: this.isComponentComplete(section, "share"),
                section,
              });
            }
            break;
        }
        break;
      }
      case "trade-reference": {
        links.push({
          component: "referees",
          required: true,
          complete: this.isComponentComplete(section, "referees"),
          section,
        });
        break;
      }
      case "guarantors": {
        links.push({
          component: "confirm",
          required: true,
          complete: this.isComponentComplete(section, "confirm"),
          section,
        });
        break;
      }
      case "extras": {
        links.push({
          component: "questions",
          required: true,
          complete: this.isComponentComplete(section, "questions"),
          section,
        });
        break;
      }
      case "review": {
        links.push({ component: null, section });
        break;
      }
      case "cards":
        links.push({
          complete: this.isComponentComplete(section, "card-order-form"),
          component: "card-order-form",
          required: true,
          section,
        });
        break;
      case "additional":
        links.push({
          component: "additional-details",
          required: true,
          complete: this.isComponentComplete(section, "additional-details"),
          section,
        });
        break;
      case "financials":
      case "paperless": {
        links.push({
          component: "details",
          required: true,
          complete: this.isComponentComplete(section, "details"),
          section,
        });
        break;
      }
    }

    return links;
  },

  getSupplierTnc() {
    const { component, section } = this.props;

    return (
      <div className={styles.pdfTnc}>
        <Button
          handleClick={this.downloadPdfSupplierTnc}
          text={
            <div className="is-flex is-align-items-center">
              <SaveAltIcon />
              <span className="ml-2">Download T&amp;C&apos;s</span>
            </div>
          }
        />
        <BusinessOverviewButtons component={component} section={section} />
      </div>
    );
  },

  isCardsReviewSectionComplete() {
    const { cardholdersCount, review_complete: reviewComplete } = this.props;
    let isComplete = true;

    // TODO: review this section. This may no longer be applicable as the
    // cardholders in the review section is no longer editable
    // for (let i = 0; i < cardholdersCount; i++) {
    //   if (!reviewComplete[`cards-${i}`]) {
    //     isComplete = false;
    //     break;
    //   }
    // }

    return isComplete;
  },

  isComponentComplete(section, component) {
    const { completed } = this.props;

    if (
      Object.keys(completed).includes(section) &&
      Object.keys(completed[section]).includes(component)
    ) {
      return completed[section][component];
    }

    return false;
  },

  isSectionComplete(section) {
    const links = this.getSectionLinks(section);
    let complete = true;

    links.forEach(item => {
      if (item.required === true && item.complete !== true) {
        complete = false;
      }
    });

    return complete;
  },

  loadingComplete() {
    this.setState({ showLoader: false });
  },

  render() {
    const {
      application,
      component,
      config,
      consumer_account,
      hasOneApplicableLegalTypes,
      loading_error,
      location,
      section,
      sections,
      start_complete,
      supplier_logo_url,
      supplier_trading_name,
      warning,
    } = this.props;
    const { animation, showLoader, loading, mobile_min_height } = this.state;

    const props = this.getComponentProps();
    const child_component = componentFactory(section, component, props);
    const dots = this.componentGetDots();
    let sectionFooter;

    let color;
    const download_supplier_tnc = this.getSupplierTnc();

    let enter;
    let enterActive;
    let leave;
    let leaveActive;
    const flow_wrap_styles = styles.flow_wrap;

    if (showLoader) {
      return (
        <Loader
          message="Please wait while we load your progress."
          loading={loading}
          handleComplete={this.loadingComplete}
        />
      );
    }

    switch (animation) {
      case "scroll-left":
        enter = "enterScrollLeft";
        enterActive = "enterActiveScrollLeft";
        leave = "leaveScrollLeft";
        leaveActive = "leaveActiveScrollLeft";
        break;
      case "scroll-right":
      default:
        enter = "enterScrollRight";
        enterActive = "enterActiveScrollRight";
        leave = "leaveScrollRight";
        leaveActive = "leaveActiveScrollRight";
        break;
    }

    const links = (
      <div className={styles.bottom_buttons}>
        <div>{this.getArrowLinks()}</div>
        <div>{this.getBackLinks()}</div>
      </div>
    );

    if (section === "start" && component === null) {
      return (
        <React.Fragment>
          <ApplicationStart
            consumer_account={consumer_account}
            hasOneApplicableLegalTypes={hasOneApplicableLegalTypes}
            loading_error={loading_error}
            location={location}
            params={this.props.params}
            start_complete={start_complete}
            supplier_logo_url={supplier_logo_url}
            supplier_trading_name={supplier_trading_name}
          />

          {isMobile() && sectionFooter}
        </React.Fragment>
      );
    }

    if (section === "review" && component === "complete") {
      color = "white";
    }

    const overlay = (
      <ApplicationOverlay
        section_title={sections[section].title}
        trading_name={supplier_trading_name}
        logo_url={supplier_logo_url}
        label={config.title}
        color={color}
      />
    );

    const sectionHeadings = <div>{overlay}</div>;

    let warning_modal;

    if (warning) {
      warning_modal = (
        <SquareModal title={warning.title} size={warning.size}>
          <div>
            <p>{warning.details}</p>
            <div>
              <MaterialButton color="primary" onClick={warning.yesHandler}>
                Yes
              </MaterialButton>
              <MaterialButton color="primary" onClick={warning.noHandler}>
                No
              </MaterialButton>
            </div>
          </div>
        </SquareModal>
      );
    }

    let content = null;

    if (section === "review") {
      /* review page has to be able to scroll !location.pathname.includes('review/') */
      content = (
        <div className={styles.review}>
          <div
            className={styles.container}
            style={{
              minHeight: isMobile()
                ? `${mobile_min_height}px`
                : "calc(100vh - 30px)",
            }}
          >
            <div className={styles.application_content}>
              {child_component}
              {warning_modal}
              {links}
            </div>
          </div>
          {isMobile() && sectionFooter}
          <div className={styles.controls}>
            {sectionHeadings}
            {isMobile() && dots}
          </div>
        </div>
      );
    } else {
      // eslint-disable-next-line no-negated-condition
      if (!isMobile()) {
        content = (
          <div className={styles.outer}>
            <div className={styles.flow}>
              <div className={flow_wrap_styles}>
                <ReactCSSTransitionGroup
                  transitionName={{
                    enter: styles[enter],
                    enterActive: styles[enterActive],
                    leave: styles[leave],
                    leaveActive: styles[leaveActive],
                  }}
                  transitionEnterTimeout={600}
                  transitionLeaveTimeout={600}
                >
                  <div
                    className={styles.page}
                    key={this.props.location.pathname}
                  >
                    <div className={styles.container}>
                      <div className={styles.application_content}>
                        {child_component}
                        {warning_modal}
                        {links}
                      </div>
                    </div>
                  </div>
                </ReactCSSTransitionGroup>
              </div>
            </div>
            <div className={styles.controls}>
              {sectionHeadings}
              {dots}
              {download_supplier_tnc}
            </div>
          </div>
        );
      } else {
        content = (
          <div>
            <div className={styles.page} key={this.props.location.pathname}>
              <div
                className={styles.container}
                style={{ minHeight: `${mobile_min_height}px` }}
              >
                <div className={styles.application_content}>
                  {child_component}
                  {warning_modal}
                  {links}
                </div>
              </div>
              {sectionFooter}
            </div>
            <div className={styles.controls}>
              {sectionHeadings}
              {dots}
            </div>
          </div>
        );
      }
    }

    if (application && application.attributes.status === "deleted") {
      content = (
        <div className={styles.content}>
          <SectionCircle key="1" title="">
            <SectionDescription text="oops - looks like this application invite has been deleted. Please contact your supplier for more information." />
          </SectionCircle>
        </div>
      );
    }

    if (application && application.attributes.status === "declined") {
      content = (
        <div className={styles.content}>
          <SectionCircle key="2" title="">
            <SectionDescription
              text={`oops - looks like this application invite has already been
                     declined. Please contact your supplier for more
                     information.`}
            />
          </SectionCircle>
        </div>
      );
    }

    if (application && application.attributes.status === "accepted") {
      content = (
        <div className={styles.content}>
          <SectionCircle key="2" title="">
            <SectionDescription text="looks like this application invite has already been accepted." />
          </SectionCircle>
        </div>
      );
    }

    if (application && get(application, "attributes.lock_down_flag", false)) {
      content = (
        <div className={styles.content}>
          <SectionCircle key="3" title="">
            <SectionDescription text="Looks like this application has been reviewed. Please contact your supplier for more information." />
          </SectionCircle>
        </div>
      );
    }

    if (application && application.attributes.archived === true) {
      content = (
        <div className={styles.content}>
          <SectionCircle key="3" title="">
            <SectionDescription text="Looks like this application has been archived. Please contact your supplier for more information." />
          </SectionCircle>
        </div>
      );
    }

    return <div>{content}</div>;
  },

  scroll(e) {
    const {
      application,
      section,
      party_count,
      component,
      component_index,
    } = this.props;
    const { page_complete } = this.state;

    if (section === "review") {
      return;
    }

    // Reduce sensitiviity for scroll on mac.
    if (Math.abs(e.deltaY) < 50.0) {
      return;
    }

    const flow = this.getSectionLinks(section);
    const index = flow.findIndex(i => i.component === component);

    let component_indexes;

    switch (component) {
      case "referees":
        component_indexes = application.attributes.minimum_trade_references;
        break;
      case "party-details":
        component_indexes = party_count;
        break;
    }

    if (component) {
      if (e.deltaY > 0) {
        if (page_complete === false) {
          return;
        }

        // scrolldown
        if (index === flow.length - 1) {
          this.saveState();
        }

        if (
          isPresent(component_index) &&
          component_index + 1 !== component_indexes
        ) {
          this.scrollToRoute(this.getNextLink(flow, component_index));
        } else {
          this.scrollToRoute(this.getNextLink(flow));
        }
      } else {
        // scrollup
        if (section === "business" && component === "type") {
          return;
        }

        if (isPresent(component_index) && component_index - 1 >= 0) {
          this.scrollToRoute(this.getPrevLink(flow, component_index));
        } else {
          this.scrollToRoute(this.getPrevLink(flow));
        }
      }
    }
  },

  scrollToRoute(route) {
    browserHistory.push(route);
  },

  setPageValidationStartFinish() {
    this.setState({ page_validation_start: false });
  },

  /**
   * React render
   */
  updateCompleteState(state, sectionNameFromChild) {
    const {
      section: sectionFromProps,
      component,
      completed,
      dispatch,
    } = this.props;
    const section = sectionNameFromChild || sectionFromProps;
    if (completed[section][component] !== state) {
      switch (section) {
        case "business":
          dispatch(businessComponentComplete(component, state));
          break;
        case "trade-reference":
          dispatch(traderefComponentComplete(component, state));
          break;
        case "guarantors":
          dispatch(guarantorComponentComplete(component, state));
          break;
        case "extras":
          dispatch(extrasComponentComplete(component, state));
          break;
        case "identity":
          dispatch(identityComponentComplete(component, state));
          break;
        case "additional":
          dispatch(addtionalComponentComplete(component, state));
          break;
        case "cards":
          dispatch(setCardholdersCountCompletedState(component, state));
          break;
        case "financials":
          dispatch(financialsComponentComplete(component, state));
          break;
        case "paperless":
          dispatch(paperlessComponentComplete(component, state));
          break;
      }
    }
    if (section === sectionFromProps) {
      this.setState({ page_complete: state });
    }
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */
const config = {
  title: "Application",
  sections: {
    business: {
      title: "Details",
      default: "other-details",
    },
    "trade-reference": {
      title: "Checks",
      default: "referees",
    },
    guarantors: {
      title: "Checks",
      default: "confirm",
    },
    extras: {
      title: "Limit",
      default: "questions",
    },
    cards: {
      default: "card-order-form",
      title: "Cards",
      addon: true,
    },
    financials: {
      title: "Financials",
      default: "financials",
      addon: true,
    },
    additional: {
      title: "Additional",
      default: "additional",
      addon: true,
    },
    paperless: {
      title: "Direct Debit",
      default: "paperless",
      addon: true,
    },
    identity: {
      title: "Authorised applicant",
      default: "details",
    },
    review: { title: "review" },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

// eslint-disable-next-line complexity
export default connect((state, ownProps) => {
  let component_index = null;
  let component = null;
  let selected_type = null;
  let party_count = null;
  let application = null;
  let people = [];
  let minimum_guarantees = 0;
  let logo_url = null;
  let trading_name = null;
  let minimum_trade_references = 0;
  let requires_applicant_identity_check = false;
  let cardholdersCount = 0;
  const addonRules = state.cob_section.addonRules || null;
  const sections = Object.assign({}, config.sections);

  let requiresApplicantAmlCheck = false;
  let requiresGuarantorAmlCheck = false;
  let requiresCardholderAmlCheck = false;
  let requiresSignatoryAmlCheck = false;

  let requiresID = false;
  let isProofOfAddressVisible = false;
  let hasOneApplicableLegalTypes = false;

  if (state.cob_business.entity_type !== null) {
    selected_type = state.cob_business.entity_type;
  }

  if (state.cob_business.entity_party_count) {
    party_count = parseInt(state.cob_business.entity_party_count);
  }

  if (typeof ownProps.params.splat !== "undefined") {
    if (ownProps.params.splat.indexOf("/") > -1) {
      component = ownProps.params.splat.split("/")[0];
      component_index = parseInt(ownProps.params.splat.split("/")[1]);
    } else {
      component = ownProps.params.splat.trim();
      if (
        [
          "referees",
          "party-details",
          "card-details",
          "additional-details",
        ].includes(component) &&
        component_index === null
      ) {
        component_index = 0;
      }
    }
  }

  if (state.cob_section.people) {
    people = state.cob_section.people;
  }
  if (state.cob_section.application) {
    application = state.cob_section.application;
    if (state.cob_section.application.attributes.application_type === "cash") {
      delete sections["trade-reference"];
      delete sections.guarantors;
      if (
        application.attributes.additional_questions_applies === "credit" ||
        !application.attributes.additional_application_questions ||
        application.attributes.additional_application_questions.length === 0
      ) {
        delete sections.extras;
      }
    } else {
      if (application.attributes.minimum_trade_references === 0) {
        delete sections["trade-reference"];
      }

      if (
        application.attributes.requires_guarantees &&
        state.cob_business.entity_type === "company"
        // !["partnership", "trust", "personal", "sole_trader", "education", "society", "association", "club", "government", "other"].includes(state.cob_business.entity_type)
      ) {
        minimum_guarantees = application.attributes.minimum_guarantees;
      } else {
        delete sections.guarantors;
        if (state.cob_business.entity_type === "personal") {
          delete sections["trade-reference"];
          // eslint-disable-next-line max-depth
        }
      }
    }

    const addonModuleNames = [];
    addonRules.forEach(rule => {
      addonModuleNames.push(rule.attributes.addon_module_name);
    });
    Object.keys(sections).forEach(section => {
      if (!sections[section].addon) return;
      const hasAddonRule = addonModuleNames.includes(section);
      if (!hasAddonRule) {
        delete sections[section];
      }
    });

    minimum_trade_references = application.attributes.minimum_trade_references;
    requires_applicant_identity_check =
      application.attributes.requires_applicant_identification_check;
    cardholdersCount = application.attributes.cardholders_count;

    if (state.cob_section.amlCheck) {
      const personToCheck =
        state.cob_section.amlCheck.attributes.addon_version.config
          .person_to_check;
      if (personToCheck.includes("applicant")) requiresApplicantAmlCheck = true;
      if (personToCheck.includes("guarantor")) requiresGuarantorAmlCheck = true;
      if (personToCheck.includes("cardholder")) {
        requiresCardholderAmlCheck = true;
      }
      if (personToCheck.includes("signatory")) requiresSignatoryAmlCheck = true;
    }

    const hasApplicantInGuarantors = state.cob_guarantors.form_values.some(
      g => g && g.is_applicant,
    );
    const hasApplicantInCardholders = state.cob_cards.cardholders.some(
      c => c.attributes && c.attributes.isApplicant,
    );
    const hasApplicantInSignatories = state.cob_business.entity_party_details_values.some(
      s => s && s.is_applicant,
    );

    const requiresAmlCheck =
      requiresApplicantAmlCheck ||
      (hasApplicantInGuarantors && requiresGuarantorAmlCheck) ||
      (hasApplicantInCardholders && requiresCardholderAmlCheck) ||
      (hasApplicantInSignatories && requiresSignatoryAmlCheck);

    // Proof of address is always required if AML check is turned on
    isProofOfAddressVisible = requiresAmlCheck;

    requiresID =
      requires_applicant_identity_check ||
      requiresApplicantAmlCheck ||
      (hasApplicantInGuarantors &&
        (application.attributes.requires_guarantees_identification_check ||
          requiresGuarantorAmlCheck)) ||
      (hasApplicantInCardholders &&
        (application.attributes.requires_cardholder_identification_check ||
          requiresCardholderAmlCheck)) ||
      (hasApplicantInSignatories &&
        (application.attributes.requires_signatories_identification_check ||
          requiresSignatoryAmlCheck));

    const applicableLegalTypes = state.cob_section.applicableLegalTypes;
    if (applicableLegalTypes) {
      hasOneApplicableLegalTypes =
        applicableLegalTypes.attributes.addon_version.legal_types.length === 1;
    }
  }

  let supplier = null;

  if (state.cob_section.supplier) {
    supplier = state.cob_section.supplier;
    trading_name = supplier.attributes.trading_name;
    if (supplier.attributes.logo) {
      logo_url = supplier.attributes.logo.url;
    }
  }

  let minimumGuarantees = minimum_guarantees;
  if (people.length < minimum_guarantees) {
    minimumGuarantees = people.length;
  }

  // Arrays of components that are completed or not.
  const completed = {
    additional: state.cob_additional.completed,
    business: state.cob_business.completed,
    cards: state.cob_cards.completed,
    extras: state.cob_extras.completed,
    financials: state.cob_financials.completed,
    guarantors: state.cob_guarantors.completed,
    identity: state.identity.completed,
    paperless: state.cob_paperless.completed,
    "trade-reference": state.cob_traderef.completed,
  };

  const consumerAccount =
    (state.current_user.current_entity &&
      state.current_user.current_entity.attributes.consumer_account) ||
    false;

  const cardsAddon = addonRules.find(
    rule => rule.attributes.addon_module_name === "cards",
  );
  const financialsAddon = addonRules.find(
    rule => rule.attributes.addon_module_name === "financials",
  );
  const additionalAddon = addonRules.find(
    rule => rule.attributes.addon_module_name === "additional",
  );
  const paperlessAddon = addonRules.find(
    rule => rule.attributes.addon_module_name === "paperless",
  );

  let additionalTotalPage = 0;
  if (additionalAddon) {
    additionalTotalPage = paginate(
      additionalAddon.attributes.addon_version.data.attributes.config
        .components,
    ).totalPage;
  }

  let consumerName = state.cob_business.entity_name;
  if (
    state.cob_business.entity_type === "company" &&
    state.cob_business.company_details
  ) {
    consumerName = state.cob_business.company_details.name;
  }

  return {
    additionalAddon,
    additionalTotalPage,
    addonRules,
    application,
    application_submitting: state.cob_review.application_submitting,
    cardholdersCount,
    cardsAddon,
    completed,
    component,
    component_index,
    config,
    consumerName,
    consumer_account: consumerAccount,
    current_entity: state.current_user.current_entity,
    current_user: state.current_user,
    entity: ownProps.params.entity,
    entity_region: state.cob_business.entity_region,
    entity_type: state.cob_business.entity_type,
    financialsAddon,
    hasOneApplicableLegalTypes,
    identity_image_64: state.identity.image_64,
    isProofOfAddressVisible,
    loading_error: state.cob_section.loading_error,
    minimum_guarantees: minimumGuarantees,
    minimum_trade_references,
    no_identification: state.identity.noIdentification,
    paperlessAddon,
    party_count,
    people,
    required_guarentees: minimum_guarantees,
    requiresApplicantAmlCheck,
    requiresCardholderAmlCheck,
    requiresGuarantorAmlCheck,
    requiresID,
    requires_applicant_identity_check,
    review_complete: state.cob_review.completed,
    section: ownProps.params.section,
    sections,
    selected_type,
    start_complete: state.cob_section.start_complete,
    state_loading: state.state.loading,
    submitting: state.cob_review.submitting,
    supplier,
    supplier_logo_url: logo_url,
    supplier_trading_name: trading_name,
    warning: state.cob_business.warning,
  };
})(ReactTimeout(ConsumerOnBoarding));
