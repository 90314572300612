import get from 'lodash.get';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import SupplierFormRender from 'modules/shared/components/widgets/interactive/form_builder/SupplierFormRender';
import React, { ReactElement } from 'react'

type Props = {
  answers: any;
  children?: ReactElement;
  data: any;
  label: string;
  onChange: (params: any) => void;
  reduxKey: string;
}

function SupplierForm({
  children,
  label,
  data,
  ...otherProps
}: Props): ReactElement|null {
  const components = get(data, "components", []);

  if (components.length === 0) {
    return null;
  }

  return (
    <FixedContent
      header={label}
      bottomSeparatorOffset={1}
      noOverflowX
    >
      <SupplierFormRender
        components={components}
        {...otherProps}
      />
      {children}
    </FixedContent>
  )
}

export default SupplierForm