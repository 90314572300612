import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import {
  DigitalOnboardingContext,
  DigitalOnboardingHeader,
} from "modules/addons/components//DigitalOnboarding";
import styles from "modules/addons/components/css/DirectDebitModuleComponents.css";
import BankNumber from "modules/addons/components/digital-onboarding/DirectDebitModuleComponents/BankNumber";
import {
  ContentContainer,
  DigitalOnboardingItem,
  DirtyPopup,
} from "modules/addons/components/DigitalOnboarding";
import WebsiteButtonLink from "modules/addons/components/WebsiteButtonLink";
import { getDirectDebitModuleLink } from "modules/addons/helpers";
import Button from "modules/shared/components/inputs/Button";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import UncontrolledTextInput from "modules/shared/components/inputs/UncontrolledTextInput";
import React, { Fragment, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { browserHistory, Link } from "react-router";
import extractAttachmentUrl from "utils/extractAttachmentUrl";
import * as yup from "yup";

const EMAIL_PATH = "/dashboard/profile?active_tab=email_customisation";

const formSchema = yup.object().shape({
  accountName: yup.string().required("Please enter account name label"),
  accountNumber: yup.string().required("Please enter account number label"),
});

function Item(props) {
  const {
    addonConfig,
    currentUser,
    isPaperlessAddonConfigured,
    onUpdateModuleItem,
  } = props;

  const { onSetAddonToEdit } = useContext(DigitalOnboardingContext);
  const onClickEdit = () => onSetAddonToEdit(addonConfig);

  const onSuccessCallback = updatedAddonConfig => {
    mixpanel.track("Update Direct debit module ruleset", {
      "Entity ID": get(currentUser, "currentEntity.id"),
      Ruleset: updatedAddonConfig.data,
      "Ruleset ID": updatedAddonConfig.id,
      distinct_id: currentUser.id,
    });

    onUpdateModuleItem(updatedAddonConfig);
  };

  const onClickToggleState = () => {
    addonConfig.setAttribute("active", !addonConfig.active);
    addonConfig.update({ currentUser, onSuccessCallback });
  };

  return (
    <DigitalOnboardingItem
      activeState={addonConfig.active}
      areActionButtonsVisible={isPaperlessAddonConfigured}
      body="Set up the capturing of paperless direct debits for your existing customers."
      formattedState={addonConfig.formattedActiveState}
      header="Direct debit module"
      isConfigured={addonConfig.isConfigured}
      isEnabled={isPaperlessAddonConfigured}
      onClickEdit={isPaperlessAddonConfigured ? onClickEdit : null}
      onClickToggleState={onClickToggleState}
    />
  );
}

function getNewVersionAttribute(addonConfig, data) {
  const attributes = {
    config: {
      account_name: data.accountName,
      account_number: data.accountNumber,
    },
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = "Direct debit module";
    attributes.addon_module_name = "direct_debit_module";
  }

  return attributes;
}

function Edit(props) {
  const { addonConfig, currentUser, onRefreshDigitalOnboardingModules } = props;
  const [isDirtyPopupVisible, setIsDirtyPopupVisible] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const { onClickBack } = useContext(DigitalOnboardingContext);
  const { errors, formState, handleSubmit, register } = useForm({
    mode: "onBlur",
    validationSchema: formSchema,
  });

  const currentEntity = currentUser.currentEntity || {};
  const logo = extractAttachmentUrl(currentEntity.logo, "logo");
  const newVersion = addonConfig.generateNewVersion();
  const versionConfig = newVersion.config || {};
  const { dirty } = formState;

  const onSuccessCallback = addonVersion => {
    mixpanel.track("Configure Direct debit module", {
      "Entity ID": get(currentUser, "currentEntity.id"),
      Ruleset: addonVersion.data,
      distinct_id: currentUser.id,
    });

    onRefreshDigitalOnboardingModules();
    onClickBack();
  };
  const onSubmit = data => {
    const attributes = getNewVersionAttribute(addonConfig, data);
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser,
      onSuccessCallback,
    });
  };

  const onClickCloseButton = () => {
    if (dirty) {
      setRedirectUrl(null);
      setIsDirtyPopupVisible(true);
    } else {
      onClickBack();
    }
  };

  const onClickEmailLink = () => {
    if (dirty) {
      setRedirectUrl(EMAIL_PATH);
      setIsDirtyPopupVisible(true);
    } else {
      browserHistory.push(EMAIL_PATH);
    }
  };

  const websiteButtonLink = getDirectDebitModuleLink(currentEntity.id);

  return (
    <Fragment>
      <DirtyPopup
        isVisible={isDirtyPopupVisible}
        redirectUrl={redirectUrl}
        onHidePopup={() => setIsDirtyPopupVisible(false)}
        onLeave={onClickBack}
        onSave={() => handleSubmit(onSubmit)()}
      />
      <div className={styles.container}>
        <DigitalOnboardingHeader>
          Direct debit authorisation
        </DigitalOnboardingHeader>
        <CloseButton handleClick={onClickCloseButton} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContentContainer header="Mandatory fields (* indicates field name can be customised)">
            <div className={styles.input_fields}>
              <UncontrolledTextInput
                id="accountName"
                label={`${currentEntity.attributes.trading_name} account name*`}
                error={get(errors, "accountName.message")}
                inputRef={register}
                defaultValue={versionConfig.account_name}
              />
              <UncontrolledTextInput
                id="accountNumber"
                label={`${currentEntity.attributes.trading_name} account number*`}
                error={get(errors, "accountNumber.message")}
                inputRef={register}
                defaultValue={versionConfig.account_number}
              />
            </div>
            <div>First name</div>
            <div>Last name</div>
            <div>Bank account name to be debited</div>
            <BankNumber region={get(currentEntity, "attributes.region")} />
            <div>Bank account number</div>
          </ContentContainer>
          <ContentContainer header="Terms">
            Your Direct Debit T&amp;C&apos;s and disclaimers will be used from
            your activated direct debit rule. These will be presented to your
            customer upon completing their digital direct debit authorisation.
          </ContentContainer>
          <ContentContainer header="Email customisations">
            Customise the content of the email that the account holder will
            receive <Link onClick={onClickEmailLink}>here</Link>.
          </ContentContainer>
          <ContentContainer header="Website button">
            <ul>
              <li>Copy the embed link (in the boxes below)</li>
              <li>Log into your website editor.</li>
              <li>
                Find the section where you can edit your site&apos;s HTML.
              </li>
              <li>
                Paste the website button link in the relevant section for your
                website.
              </li>
              <li>Save and publish your changes</li>
              <li>Refresh your website and the widget should be visible.</li>
            </ul>
            <WebsiteButtonLink.Component
              currentUser={currentUser}
              link={websiteButtonLink}
              logo={logo}
              text="Start direct debit authority"
            />
          </ContentContainer>
          <div>
            <Button text="save" type="submit" />
          </div>
        </form>
      </div>
    </Fragment>
  );
}

const DirectDebitModuleComponents = {
  Edit,
  Item,
};

export default DirectDebitModuleComponents;
