import styles from "modules/new-applications/css/IdentificationChecks.css";
import React, { ReactElement, ReactNode } from "react";

const Header = ({ title }: { title: string|ReactNode }): ReactElement => (
  <div className={styles.e_signature_header}>
    {title}
  </div>
);

export default Header;
