import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import React from "react";
import { Controller } from "react-hook-form";

import styles from "./css/FormCheckBox.css";
import commonStyles from "./css/TextInput.css";

function formControlLabelRootClass(props) {
  const { isCompact } = props;

  if (isCompact) {
    return styles.label_root_compact;
  }
}

export default function FormCheckBox(props) {
  const {
    control,
    defaultValue,
    disabled,
    error,
    hideHelperText,
    id,
    label,
    register,
    value,
  } = props;

  const displayHelperText = hideHelperText ? !!error : true;

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div>
        <FormControlLabel
          classes={{
            label: styles.label,
            root: formControlLabelRootClass(props),
          }}
          disabled={disabled}
          control={
            <Controller
              as={Checkbox}
              name={id}
              type="checkbox"
              value={value}
              control={control}
              defaultValue={defaultValue}
            />
          }
          inputRef={register}
          label={label}
        />
        {
          displayHelperText && (
            <FormHelperText
              error={!!error}
              className={commonStyles.material_helper_text}
            >
              {error}
            </FormHelperText>
          )
        }
      </div>
    </MuiThemeProvider>
  );
}

FormCheckBox.defaultProps = {
  disabled: false,
  error: "",
  isCompact: false,
};
