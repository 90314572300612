import React, { Component } from "react";
import IdentityCapture from "modules/identity/components/IdentityCapture/IdentityCapture";
// import IdentityConfirm from "modules/identity/components/IdentityConfirm";
import { connect } from "react-redux";
import FileUpload from "modules/FileUpload";
import PageHeader from "modules/shared/components/v2/PageHeader";
import { IdentityConfirm } from "./styles";
import { get } from "lodash";

class Identity extends Component {
  constructor() {
    super();
    this.state = {
      detailsComplete: false,
      photoComplete: false,
    };
  }

  componentDidMount() {
    const { detailsComplete, photoComplete } = this.state;
    const { handleComplete } = this.props;
    handleComplete(detailsComplete && photoComplete, "identity");
  }

  idPhotoUploadComplete(photoComplete) {
    const { handleComplete } = this.props;
    if (photoComplete !== this.state.photoComplete) {
      this.setState({ photoComplete });
    }
    handleComplete(photoComplete && this.state.detailsComplete, "identity");
  }

  idDetailsFormComplete(detailsComplete) {
    const { handleComplete } = this.props;
    if (detailsComplete !== this.state.detailsComplete) {
      this.setState({ detailsComplete });
    }
    handleComplete(this.state.photoComplete && detailsComplete, "identity");
  }

  render() {
    const { consumerName, supplierName } = this.props;

    return (
      <div>
        <PageHeader title="Authorisation">
          <p>
            You are certifying that you are an authorised party for the
            application between {consumerName} and {supplierName}.
          </p>
        </PageHeader>
        <IdentityCapture
          {...this.props}
          handleComplete={this.idPhotoUploadComplete.bind(this)}
        />
        <IdentityConfirm
          {...this.props}
          handleComplete={this.idDetailsFormComplete.bind(this)}
        />
      </div>
    );
  }
}

export default connect(state => {
  const {
    authorisation,
    cob_cards,
    cob_guarantors,
    cob_section,
  } = state;
  const applicantGuarantor = cob_guarantors.form_values.find(g => g && g.is_applicant);
  const people = get(cob_section, 'people') || [];
  const cardholders = cob_cards.cardholders;
  const applicantPeople = people.find(p => p && p.is_applicant);
  const processedAuthorisation = authorisation.firstName ? {
    first_name: authorisation.firstName,
  } : null;
  const applicantCardholder = cardholders.find(cardholder => get(cardholder, 'attributes.isApplicant'));
  const processedApplicantCardholder = applicantCardholder ? {
    first_name: get(applicantCardholder, 'attributes.firstName'),
  } : null;

  const applicantSignatory = applicantGuarantor || applicantPeople || processedApplicantCardholder || processedAuthorisation;

  return {
    applicantSignatory,
    applicant_guarantor: applicantGuarantor,
  };
})(Identity);
