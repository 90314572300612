import historyStyles from "modules/applications/components/credit-checks/credit-works/css/History.css";
import styles from "modules/applications/components/css/CommonReviewBusiness.css";
import React, { ReactElement } from "react";
import { SubHeading } from "modules/applications/components/credit-checks/credit-works/v2/Container/styles";

type Appointee = {
  fullName: string,
  organisationName: string,
  appointmentDate: string,
  vacationDate: string|null,
  email: string,
  address: {
    address: string,
    startDate: string|null,
    endDate: null,
  },
  phoneNumbers: string[],
}

type Report = {
  name: string,
  filed: boolean,
  date: string,
}

type Insolvency = {
  insolvencyType: string,
  commenced: string,
  nzbnInsolvencyAppointee: Appointee[],
  nzbnInsolvencyReport: Report[],
}

type Props = {
  data: Insolvency[],
}

function Insolvencies(props: Props): ReactElement {
  const { data } = props;

  const getInsolvencies = nzbnInsolvencyAppointee => nzbnInsolvencyAppointee.map(({
    organisationName,
  }) => (
    <div key={`${organisationName}`}>
      <h4>Insolvency</h4>
      <table className={styles.table_w20}>
        <tbody>
          <tr>
            <th>Company Name</th>
            <td>{organisationName}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ))

  return (
    <div className={historyStyles.container}>
      <SubHeading>Insolvencies</SubHeading>
      <div>
        <em>
          This information is retrieved from the New Zealand Government&apos;s
          Ministry of Economic Development, Insolvency and Trustee Service and
          includes all insolvencies administered by the Official Assignee.
          This excludes information on liquidations where the Official
          Assignee is not the liquidator.
        </em>
      </div>
      {
        data.length === 0 && (
          <p>There are no insolvency records filed with the Official Assignee for
          this company.</p>
        )
      }
      {data.length > 0 && data.map(({ nzbnInsolvencyAppointee }) => getInsolvencies(nzbnInsolvencyAppointee))}
    </div>
  );
}

export default Insolvencies;
