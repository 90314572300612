/* Import libraries */
import React from "react";
import { connect } from "react-redux";

/* Import CSS */
import styles from "./css/Business.css";

/* Import actions */
import {
  selectEntityPartyCount,
  componentComplete,
} from "../../actions/onboarding";

/* Import components */
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import CircleIconButton from "modules/shared/components/widgets/interactive/CircleIconButton";

var BusinessParties = createClass({
  componentWillReceiveProps: function(nextProps) {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete(nextProps.entity_party_count));
  },

  componentDidMount: function() {
    const { handleComplete, entity_party_count } = this.props;
    handleComplete(this.isComplete(entity_party_count));
  },

  isComplete: function(entity_party_count) {
    if (entity_party_count) {
      return true;
    }
    return false;
  },

  /**
   * Sets the selected entity state.
   */
  selectOption: function(count) {
    const { dispatch } = this.props;
    dispatch(selectEntityPartyCount(parseInt(count)));
  },

  render: function() {
    const { title, entity_party_count, options } = this.props;

    // Create the button list
    var button_components = new Array();

    options.forEach((option, index) => {
      var selected = false;

      if (option.value == entity_party_count) {
        selected = true;
      }

      button_components.push(
        <CircleIconButton
          key={index}
          value={option.value}
          type="number"
          selected={selected}
          text={option.title}
          handleClick={this.selectOption}
        />,
      );
    });

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.full_col}>
            <PanelTitle text={title} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.options_parties}>{button_components}</div>
        </div>
      </section>
    );
  },
});

var defaults = {
  trust_title: "How many trustees are there?",
  partnership_title: "How many partners are there?",
  options: [
    {
      title: "2",
      value: 2,
    },
    {
      title: "3",
      value: 3,
    },
    {
      title: "4",
      value: 4,
    },
    {
      title: "5",
      value: 5,
    },
    {
      title: "6",
      value: 6,
    },
    {
      title: "7",
      value: 7,
    },
    {
      title: "8",
      value: 8,
    },
    {
      title: "9+",
      value: 9,
    },
  ],
};

module.exports = connect((state, ownProps) => {
  var entity_party_count = null,
    title = "How many parties?";

  if (state.cob_business.entity_party_count !== null) {
    entity_party_count = state.cob_business.entity_party_count;
  }

  if (state.cob_business.entity_type !== null) {
    if (state.cob_business.entity_type == "trust") {
      title = defaults.trust_title;
    } else {
      title = defaults.partnership_title;
    }
  }

  return {
    options: defaults.options,
    title: title,
    entity_party_count: entity_party_count,
  };
})(BusinessParties);
