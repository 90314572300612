import React, { ReactElement } from "react";
import FixedContent from "modules/shared/components/containers/FixedContent";
import styles from "modules/new-applications/css/LimitAndApprovals.css";
import * as Sentry from "@sentry/browser";

const getReviewMessage = (review: any): string => {
  const {
    approvalHierarchyVersion,
    decision,
    formattedReviewedAt,
    id,
    level,
  } = review;

  if (decision === "escalated") {
    return `Escalated to L${level +
      1} on ${formattedReviewedAt} (approval hierarchy version ${approvalHierarchyVersion}).`;
  }

  if (decision === "reviewed") {
    return `Reviewed on ${formattedReviewedAt} (approval hierarchy version ${approvalHierarchyVersion}).`;
  }

  console.error("Invalid review decision");
  Sentry.captureException(`Invalid review decision for review ${id}`);
  return "";
};

const ReviewerContent = ({
  review,
}: {
  review: { [key: string]: any };
}): ReactElement => (
  <FixedContent withBottomSeparator={false}>
    <p className={styles.approver_name}>
      {`Reviewer name: ${review.reviewerName}`}
    </p>
    <p>{getReviewMessage(review)}</p>
    <p>{review.notes || "No notes."}</p>
  </FixedContent>
);

export default ReviewerContent;
