import * as Sentry from "@sentry/browser";
import { getPermissionsAccountRules } from "modules/profile/actions";
import { MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_STATE } from "modules/profile/constants";
import { sendSurvey } from "utils/askNicely";
import { setChurnZeroAttributes } from "utils/churnZero";
import isPresent from "utils/isPresent";
import get from "lodash.get"

import api from "../../../api";
import {
  CNS_INVITE_CLEAR_STATE,
  CNS_INVITE_COMPANY_EMAIL_SELECT,
  CNS_INVITE_COMPANY_NAME_LOOKUP_CLEAR,
  CNS_INVITE_COMPANY_NAME_LOOKUP_ERROR,
  CNS_INVITE_COMPANY_NAME_LOOKUP_START,
  CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS,
  CNS_INVITE_COMPANY_NAME_SELECT,
  CNS_INVITE_COMPANY_SET_NAME,
  CNS_INVITE_COMPONENT_COMPLETE,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS,
  CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_START,
  CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_SUCCESS,
  CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START,
  CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS,
  CNS_INVITE_GET_USER_PERMISSSION_ERROR,
  CNS_INVITE_GET_USER_PERMISSSION_START,
  CNS_INVITE_GET_USER_PERMISSSION_SUCCESS,
  CNS_INVITE_NZ_COMPANY_DETAILS_ERROR,
  CNS_INVITE_NZ_COMPANY_DETAILS_START,
  CNS_INVITE_NZ_COMPANY_DETAILS_SUCCESS,
  CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_CLEAR,
  CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_ERROR,
  CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_START,
  CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_SUCCESS,
  CNS_INVITE_NZ_COMPANY_NAME_SELECT,
  CNS_INVITE_NZ_COMPANY_SET_NAME,
  CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
  CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_ERROR,
  CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_START,
  CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
  CNS_INVITE_POSTAL_ADDRESS_LOOKUP_CLEAR,
  CNS_INVITE_POSTAL_ADDRESS_LOOKUP_ERROR,
  CNS_INVITE_POSTAL_ADDRESS_LOOKUP_START,
  CNS_INVITE_POSTAL_ADDRESS_LOOKUP_SUCCESS,
  CNS_INVITE_RESEND_APPLICATION_ERROR,
  CNS_INVITE_RESEND_APPLICATION_START,
  CNS_INVITE_RESEND_APPLICATION_SUCCESS,
  CNS_INVITE_RESEND_AUTHORISATION_ERROR,
  CNS_INVITE_RESEND_AUTHORISATION_START,
  CNS_INVITE_RESEND_AUTHORISATION_SUCCESS,
  CNS_INVITE_RESET_COMPANY_NAME_LOOKUP,
  CNS_INVITE_SECTION_COMPLETE,
  CNS_INVITE_SELECT_ENTITY_PARTY_COUNT,
  CNS_INVITE_SELECT_ENTITY_TYPE,
  CNS_INVITE_SELECT_PHYSICAL_ADDRESS,
  CNS_INVITE_SELECT_POSTAL_ADDRESS,
  CNS_INVITE_SET_ADDRESS,
  CNS_INVITE_SET_DIRECTORS_CONTAINER_INDEX,
  CNS_INVITE_SET_ENTITY_LOGO,
  CNS_INVITE_SET_ENTITY_NAME,
  CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL,
  CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_NAME,
  CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE,
  CNS_INVITE_SET_ENTITY_PARTY_DETAILS_INDEX,
  CNS_INVITE_SET_KEY_CONTACT_EMAIL,
  CNS_INVITE_SET_KEY_CONTACT_FIRST_NAME,
  CNS_INVITE_SET_KEY_CONTACT_LAST_NAME,
  CNS_INVITE_SET_KEY_CONTACT_PHONE,
  CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME,
  CNS_INVITE_SET_NEW_CONSUMER_EMAIL,
  CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME,
  CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME,
  CNS_INVITE_SET_IUF_ANSWERS,
  CNS_INVITE_SET_PHONE,
  CNS_INVITE_SET_PHYSICAL_ADDRESS,
  CNS_INVITE_SET_POSTAL,
  CNS_INVITE_SET_POSTAL_ADDRESS,
  CNS_INVITE_SET_RETURN_ROUTE,
  CNS_INVITE_SET_SECTION_INDEX,
  CNS_INVITE_SET_STAFF_COUNT,
  CNS_INVITE_SET_TYPE,
  CNS_INVITE_SET_WEBSITE,
  CNS_INVITE_UPDATE_KEY_CONTACT_PERCENTAGE,
  CNS_VALIDATE_UNIQUE_EMAIL_ERROR,
  CNS_VALIDATE_UNIQUE_EMAIL_START,
  CNS_VALIDATE_UNIQUE_EMAIL_SUCCESS,
} from "../constants/invite";

export function inviteComplete(complete) {
  return (dispatch, getState) => {
    const section_state = getState().cns_invite;

    const data = {
      entity: new Object(),
    };

    data.entity.trading_name = section_state.entity_name;
    data.entity.legal_type = section_state.entity_type;
    data.entity.contact_phone_number = section_state.entity_phone;
    data.entity.staff_count = section_state.entity_staff_count;

    data.entity.physical_address = section_state.physical_address;
    data.entity.postal_address = section_state.postal_address;
    data.entity.physical_dpid = section_state.physical_dpid;
    data.entity.postal_dpid = section_state.postal_dpid;

    data.entity.key_contact_first_name = section_state.key_contact_first_name;
    data.entity.key_contact_last_name = section_state.key_contact_last_name;
    data.entity.key_contact_phone_number = section_state.key_contact_phone;
    data.entity.key_contact_email = section_state.key_contact_email;

    // We keep this in a separate PATCH request
    data.entity_logo = section_state.logo;

    data.people = [];

    switch (section_state.entity_type) {
      case "company":
        data.entity.company_number =
          section_state.nz_company_nzbn_details.company_number;
        data.entity.nzbn = section_state.nz_company_nzbn_details.nzbn;
        data.entity.registered_at =
          section_state.nz_company_nzbn_details.registration_date;
        data.entity.company_name =
          section_state.nz_company_autosuggest_selected_name;
        data.entity.age = section_state.nz_company_nzbn_details.age;

        for (
          var i = 0;
          i < section_state.nz_company_nzbn_details.people.length;
          i++
        ) {
          if (
            section_state.nz_company_nzbn_details.people[i].status === "ACTIVE"
          ) {
            data.people.push({
              appointed_at:
                section_state.nz_company_nzbn_details.people[i].valid_from,
              email: section_state.nz_company_nzbn_details.people[i].email,
              legal_type: "director",
              name: section_state.nz_company_nzbn_details.people[i].name,
            });
          }
        }
        break;
      case "trust":
      case "partnership":
        for (var i = 0; i < parseInt(section_state.entity_party_count); i++) {
          data.people.push({
            email: section_state.entity_party_details_values[i].email,
            legal_type:
              section_state.entity_type == "trust" ? "trustee" : "partner",
            name: section_state.entity_party_details_values[i].name,
            percent: parseInt(
              section_state.entity_party_details_values[i].percent,
            ),
          });
        }
        break;
      case "sole_trader":
        break;
    }

    dispatch({
      payload: {
        complete,
        data,
      },
      type: CNS_INVITE_SECTION_COMPLETE,
    });
  };
}

export function checkEmailisUnique(email_address, success_callback) {
  return (dispatch, getState) => {
    const email = api(
      "user_exists_email",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    dispatch({
      type: CNS_VALIDATE_UNIQUE_EMAIL_START,
    });

    email.checkEmailValid(
      email_address,
      success => {
        dispatch({
          payload: success.data.data.attributes,
          type: CNS_VALIDATE_UNIQUE_EMAIL_SUCCESS,
        });

        if (success_callback) {
          success_callback();
        }
      },
      error => {
        dispatch({
          payload: error,
          type: CNS_VALIDATE_UNIQUE_EMAIL_ERROR,
        });
      },
    );
  };
}

export function createApplicationForNewConsumer({
  first_name,
  last_name,
  email,
  company_name,
  application_type,
  permissions,
  iufAnswers,
}) {
  return (dispatch, getState) => {
    let data = {
      application_type,
      company_name,
      email,
      first_name,
      last_name,
      iuf_answers: iufAnswers,
      supplier_id: getState().current_user.current_entity.id,
    };

    if (application_type === "credit" && permissions === "m") {
      data = getPermissionsAccountRules(getState, data);
    }
    dispatch({
      payload: data,
      type: CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START,
    });

    dispatch({
      type: MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_STATE,
    });

    const invites = api(
      "invites",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    invites.createForNonConsumer(
      data,
      success => {
        const applications = get(success, "data.data", [])
        const application = applications[0] || {}
        const applicationId = application.id
        const consumerId = application.consumer_id
        const name =
          getState().current_user.data.data.attributes.full_name || "";
        const email = getState().current_user.data.data.attributes.email || "";
        const supplier_name =
          getState().current_user.current_entity.attributes.company_name || "";

        sendSurvey({
          name,
          email,
          supplier: true,
          event_flow: "Invite new consumer to apply",
          supplier_name,
          access_token: getState().current_user.access_token,
          supplier_id: getState().current_user.current_entity.id,
          application_id: applicationId,
          consumer_id: consumerId,
          consumer_name: null,
        });

        dispatch({
          meta: {
            mixpanel: {
              event: `Sent Application - New customer - ${application_type}`,
              props: {
                "Entity ID": getState().current_user.current_entity.id,
                "New consumer email": data.email,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS,
        });

        // ChurnZero
        if (getState().current_user.current_entity.attributes.supplier) {
          setChurnZeroAttributes(
            getState().current_user.current_entity.id,
            getState,
          );
        }
      },
      error => {
        dispatch({
          payload: error,
          type: CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START,
        });
      },
    );
  };
}

export function createApplicationForExistingConsumer({
  consumer_id,
  application_type,
  permissions,
  consumer_user,
  iufAnswers,
}) {
  return (dispatch, getState) => {
    dispatch({
      payload: consumer_id,
      type: CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_START,
    });

    let data = {
      application_type,
      consumer_target: consumer_id,
      consumer_user_target: consumer_user.id,
      supplier_id: getState().current_user.current_entity.id,
      iuf_answers: iufAnswers,
    };

    if (application_type === "credit" && permissions === "m") {
      data = getPermissionsAccountRules(getState, data);
    }

    const invites = api(
      "invites",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    invites.createForConsumerAndUser(
      data,
      success => {
        const applications = get(success, "data.data", [])
        const application = applications[0] || {}
        const applicationId = application.id
        const consumerId = application.consumer_id
          getState().current_user.data.data.attributes.full_name || "";
        const email = getState().current_user.data.data.attributes.email || "";
        const supplier_name =
          getState().current_user.current_entity.attributes.company_name || "";

        sendSurvey({
          name,
          email,
          supplier: true,
          event_flow: "Invite existing consumer to apply",
          supplier_name,
          access_token: getState().current_user.access_token,
          supplier_id: getState().current_user.current_entity.id,
          application_id: applicationId,
          consumer_id: consumerId,
          consumer_name: null,
        });

        dispatch({
          meta: {
            mixpanel: {
              event: `Sent Application - Existing customer - ${application_type}`,
              props: {
                "Consumer ID": consumer_id,
                "Entity ID": getState().current_user.current_entity.id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_SUCCESS,
        });

        // ChurnZero
        if (getState().current_user.current_entity.attributes.supplier) {
          setChurnZeroAttributes(
            getState().current_user.current_entity.id,
            getState,
          );
        }
      },
      error => {
        dispatch({
          payload: error,
          type: CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_START,
        });
      },
    );
  };
}

export function createApplicationForExistingConsumerWithNewEmail({
  consumer_id,
  application_type,
  permissions,
  consumer_user,
  iufAnswers,
}) {
  return (dispatch, getState) => {
    dispatch({
      payload: consumer_id,
      type: CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_START,
    });

    let data = {
      application_type,
      consumer_target: consumer_id,
      email: consumer_user.email,
      supplier_id: getState().current_user.current_entity.id,
      iuf_answers: iufAnswers,
    };

    if (application_type === "credit" && permissions === "m") {
      data = getPermissionsAccountRules(getState, data);
    }

    const invites = api(
      "invites",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    invites.createForConsumerAndNewUser(
      data,
      success => {
        const applications = get(success, "data.data", [])
        const application = applications[0] || {}
        const applicationId = application.id
        const consumerId = application.consumer_id
        const name =
          getState().current_user.data.data.attributes.full_name || "";
        const email = getState().current_user.data.data.attributes.email || "";
        const supplier_name =
          getState().current_user.current_entity.attributes.company_name || "";

        sendSurvey({
          name,
          email,
          supplier: true,
          event_flow: "Invite existing consumer to apply",
          supplier_name,
          access_token: getState().current_user.access_token,
          supplier_id: getState().current_user.current_entity.id,
          application_id: applicationId,
          consumer_id: consumerId,
          consumer_name: null,
        });

        dispatch({
          meta: {
            mixpanel: {
              event: `Sent Application - Existing customer with new recipient - ${application_type}`,
              props: {
                "Consumer ID": consumer_id,
                "Entity ID": getState().current_user.current_entity.id,
                "New consumer email": data.email,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_SUCCESS,
        });
      },
      error => {
        dispatch({
          payload: error,
          type: CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_START,
        });
      },
    );
  };
}

export function clearState() {
  return dispatch => {
    dispatch({
      type: MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_STATE,
    });
    dispatch({
      type: CNS_INVITE_CLEAR_STATE,
    });
  };
}

export function componentComplete(component, state) {
  return dispatch => {
    dispatch({
      payload: {
        component,
        state,
      },
      type: CNS_INVITE_COMPONENT_COMPLETE,
    });
  };
}

export function setInviteType(type) {
  return (dispatch, getState) => {
    dispatch({
      payload: type,
      type: CNS_INVITE_SET_TYPE,
    });
  };
}

export function setNewConsumerInviteFormValue(key, value) {
  let type;
  switch (key) {
    case "consumer_first_name":
      type = CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME;
      break;
    case "consumer_last_name":
      type = CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME;
      break;
    case "consumer_email":
      type = CNS_INVITE_SET_NEW_CONSUMER_EMAIL;
      break;
    case "consumer_company_name":
      type = CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME;
      break;
    case "consumer_iuf_answers":
      type = CNS_INVITE_SET_IUF_ANSWERS;
      break;
  }

  return {
    payload: value,
    type,
  };
}

export function selectCompanyName(values) {
  return dispatch => {
    dispatch({
      payload: values,
      type: CNS_INVITE_COMPANY_NAME_SELECT,
    });
  };
}

export function setCompanyName(string) {
  return {
    payload: string,
    type: CNS_INVITE_COMPANY_SET_NAME,
  };
}

function formatSearchResponse({ dispatch, getState, result }) {
  const data = {};

  for (let i = 0; i < result.data.data.length; i++) {
    const entity = result.data.data[i];

    if (entity) {
      const entityId = entity.id;
      let full_physical_address = null;
      if (entity.physical_address) {
        full_physical_address = entity.physical_address;
      }

      let consumer_application = null;
      let application_type = null;
      let sent_application = null;

      if (isPresent(entity.supplier_connections)) {
        consumer_application = entity.supplier_connections.find(
          item =>
            item.supplier_id === getState().current_user.current_entity.id,
        );

        if (consumer_application) {
          application_type = consumer_application.type;
        }
      }

      const supplier = entity.supplier;
      const company_users_email = [];

      if (isPresent(entity.users)) {
        for (let j = 0; j < entity.users.length; j++) {
          if (isPresent(entity.supplier_applications)) {
            sent_application = entity.supplier_applications.find(
              item =>
                item.supplier_id ===
                  getState().current_user.current_entity.id &&
                item.consumer_contact_id === result.data.data[i].users[j].id,
            );
          }

          company_users_email.push({
            email: entity.users[j].email,
            exisiting_connection:
              consumer_application &&
              consumer_application.consumer_contact_id === entity.users[j].id
                ? consumer_application
                : null,
            full_name: [
              entity.users[j].first_name,
              entity.users[j].last_name,
            ].join(" "),
            id: entity.users[j].id,
            sent_application,
          });
        }
      }

      const first_admin = entity.first_admin ? entity.first_admin : null;
      if (first_admin) {
        first_admin.full_name = [
          first_admin.first_name,
          first_admin.last_name,
        ].join(" ");
      }
      const trading_names = entity.trading_names || [];

      data[`${entityId}`] = {
        address: full_physical_address,
        application_type,
        company_name: entity.company_name,
        first_admin,
        id: entityId,
        is_registered: entity.is_registered,
        region: entity.region,
        subsidiaries: entity.subsidiaries,
        supplier,
        trading_names,
        user: company_users_email,
      };
    }
  }

  dispatch({
    payload: data,
    type: CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS,
  });
}

export function lookupCompanyName(string, params) {
  return (dispatch, getState) => {
    // If the string is empty, then zero out the lookup list.
    if (string.length === 0) {
      dispatch({ type: CNS_INVITE_COMPANY_NAME_LOOKUP_CLEAR });
    } else {
      dispatch({
        payload: string,
        type: CNS_INVITE_COMPANY_NAME_LOOKUP_START,
      });

      // Get the NZBN API Handler, NOTE: auth.
      const nameSearch = api(
        "entity_search",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
        { headers: { "Content-Type": "application/json" }, version: "/v2" },
      );

      nameSearch.companySearch(
        string,
        result => formatSearchResponse({ dispatch, getState, result }),
        error => {
          console.error({ error });
          dispatch({
            payload: error,
            type: CNS_INVITE_COMPANY_NAME_LOOKUP_ERROR,
          });
        },
        params,
      );
    }
  };
}

export function lookupByEmail(email) {
  return (dispatch, getState) => {
    if (email.length === 0) {
      dispatch({ type: CNS_INVITE_COMPANY_NAME_LOOKUP_CLEAR });
    } else {
      dispatch({
        payload: email,
        type: CNS_INVITE_COMPANY_NAME_LOOKUP_START,
      });

      const entitySearchAPI = api(
        "entity_search",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
        { headers: { "Content-Type": "application/json" }, version: "/v2" },
      );

      entitySearchAPI.emailSearch(
        email,
        result => formatSearchResponse({ dispatch, getState, result }),
        error => {
          // dispatch({
          //   payload: error,
          //   type: CNS_INVITE_COMPANY_NAME_LOOKUP_ERROR,
          // });
          console.error(error);
          Sentry.captureException(error);
        },
      );
    }
  };
}

export function resetCompanyNameLookup() {
  return dispatch => {
    dispatch({ type: CNS_INVITE_RESET_COMPANY_NAME_LOOKUP });
  };
}

export function getUserPermission() {
  return (dispatch, getState) => {
    const user_entity_links = api(
      "user_entity_links",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    let data = {};

    dispatch({
      type: CNS_INVITE_GET_USER_PERMISSSION_START,
    });

    user_entity_links.get(
      getState().current_user.current_user_entity_link.id,
      result => {
        if (result.data.data.attributes) {
          data = result.data.data.attributes.permissions;
        }

        dispatch({
          payload: data,
          type: CNS_INVITE_GET_USER_PERMISSSION_SUCCESS,
        });
      },
      error => {
        dispatch({
          payload: error,
          type: CNS_INVITE_GET_USER_PERMISSSION_ERROR,
        });
      },
    );
  };
}

export function resendApplication(applicationId, callback) {
  return (dispatch, getState) => {
    const data = {
      application_id: applicationId,
      supplier_id: getState().current_user.current_entity.id,
    };

    dispatch({
      type: CNS_INVITE_RESEND_APPLICATION_START,
    });

    const invites = api(
      "invites",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    invites.resendInvite(
      data,
      success => {
        dispatch({
          meta: {
            mixpanel: {
              event: "Resend the application",
              props: {
                "Application ID": applicationId,
                "Entity ID": getState().current_user.current_entity.id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: CNS_INVITE_RESEND_APPLICATION_SUCCESS,
        });

        if (callback) {
          callback();
        }
      },
      error => {
        dispatch({
          payload: error,
          type: CNS_INVITE_RESEND_APPLICATION_ERROR,
        });
      },
    );
  };
}

export function resendAuthorisation(applicationId, callback) {
  return (dispatch, getState) => {
    const data = {
      application_id: applicationId,
      supplier_id: getState().current_user.current_entity.id,
    };

    dispatch({
      type: CNS_INVITE_RESEND_AUTHORISATION_START,
    });

    const invites = api(
      "invites",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    invites.resendAuthorisationInvite(
      data,
      success => {
        dispatch({
          meta: {
            mixpanel: {
              event: "Resend the authorisation request",
              props: {
                "Application ID": applicationId,
                "Entity ID": getState().current_user.current_entity.id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: CNS_INVITE_RESEND_AUTHORISATION_SUCCESS,
        });

        if (callback) {
          const results = success.data.data;
          callback(results);
        }
      },
      error => {
        dispatch({
          payload: error,
          type: CNS_INVITE_RESEND_AUTHORISATION_ERROR,
        });
      },
    );
  };
}

export function createCreditApplicationForExistingCashConsumer(
  consumer_id,
  consumer_legal_type,
  callback,
) {
  return (dispatch, getState) => {
    dispatch({
      payload: consumer_id,
      type: CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START,
    });

    let data = {
      application_type: "credit",
      consumer_target: consumer_id,
      supplier_id: getState().current_user.current_entity.id,
    };

    data = getCashToCreditAccountRules(data, consumer_legal_type);

    const invites = api(
      "invites",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    invites.createForConsumer(
      data,
      success => {
        const applications = get(success, "data.data", [])
        const application = applications[0] || {}
        const applicationId = application.id
        const consumerId = application.consumer_id
        const name =
          getState().current_user.data.data.attributes.full_name || "";
        const email = getState().current_user.data.data.attributes.email || "";
        const supplier_name =
          getState().current_user.current_entity.attributes.company_name || "";

        sendSurvey({
          name,
          email,
          supplier: true,
          event_flow: "Invite existing cash consumer to apply",
          supplier_name,
          access_token: getState().current_user.access_token,
          supplier_id: getState().current_user.current_entity.id,
          application_id: applicationId,
          consumer_id: consumerId,
          consumer_name: null,
        });

        dispatch({
          meta: {
            mixpanel: {
              event: "Invite existing cash consumer to apply",
              props: {
                "Consumer ID": consumer_id,
                "Entity ID": getState().current_user.current_entity.id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS,
        });

        if (callback) {
          callback();
        }
      },
      error => {
        dispatch({
          payload: error,
          type: CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR,
        });
      },
    );
  };
}

export function getCashToCreditAccountRules(attributes, consumer_legal_type) {
  if (!attributes) {
    attributes = new Object();
  }
  /* Add Credit Check */
  attributes["requires_credit_check"] = false;

  /* Add Identification Check */
  attributes["requires_identity_check"] = false;
  attributes["requires_applicant_identification_check"] = false;
  attributes["requires_guarantees_identification_check"] = false;

  /* Add Guarantor */
  // create it depend on supplier account rules in backend if consumer_legal_type is not 'sole_trader'
  if (consumer_legal_type === "sole_trader") {
    attributes["requires_guarantees"] = false;
    attributes["minimum_guarantees"] = 0;
  }

  /* Add Trade Reference */
  attributes["requires_trade_reference_check"] = false;
  attributes["minimum_trade_references"] = 0;

  return attributes;
}
