import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import { browserHistory } from "react-router";

import Button from "modules/shared/components/inputs/Button";
import EditCardInputs from "modules/payment/components/EditCardInputs";

import BlueModal from "./BlueModal";

import styles from "./css/InvalidAccount.css";

import * as iaActions from "../actions";
import { getUserData } from "modules/user/actions";
import isPseudoRole from "modules/shared/helpers/user/isPseudoRole";

class InvalidAccount extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(iaActions.getCard());
  }
  _saveCard(response) {
    const { dispatch } = this.props;
    const attributes = _.pick(
      response.card,
      "brand",
      "country",
      "exp_month",
      "exp_year",
      "funding",
      "id",
      "last4",
    );
    attributes.stripe_card_id = attributes.id;
    attributes.stripe_source_token = response.id;
    delete attributes.id;
    if (this.props.ia.cards.length) {
      attributes.stripe_customer_id = this.props.ia.cards[0].attributes.stripe_customer_id;
      dispatch(iaActions.updateCard(this.props.ia.cards[0].id, attributes));
    } else {
      dispatch(iaActions.addCard(attributes));
    }
  }
  _onSubmit() {
    this.props.iaActions.set({ addCardLoading: true });
    this._editCardInputs
      ._onSubmit()
      .then(response => {
        this._saveCard(response);
      })
      .catch(err => {
        this.props.iaActions.set({ addCardLoading: false, cardDetails: null });
        console.error("err", err);
      });
  }
  _hideAndFinish() {
    const { dispatch, redirect } = this.props;
    dispatch(getUserData(null, redirect || "/dashboard"));
  }
  render() {
    const { payment, cards } = this.props.ia;
    const { setPayment, setPaymentCard } = this.props.iaActions;
    const paymentActions = { set: setPayment, setCard: setPaymentCard };
    const buttonText = this.props.ia.addCardLoading ? "Saving..." : "Save";
    const currentCard = cards.length
      ? "xxxx.xxxx.xxxx." + cards[0].attributes.last4
      : null;
    const description =
      this.props.entity.subscription_state === "trial_ended"
        ? "Your trial has ended, please enter in your credit card to continue using our service"
        : this.props.entity.subscription_state === "expired"
        ? this.props.isAdmin
          ? "Sorry, your entity's last credit card payment failed, please enter a valid credit card to continue using our service"
          : "Please contact your 1Centre administrator who can update your subscription payment method."
        : this.props.entity.subscription_state === "card_required"
        ? "Looks like you've requested a mandatory check. We need your credit card to perform these checks, please enter your credit card in below"
        : "Sorry, looks like you've reached this page in error, please contact your admin about getting your account sorted out, apologies for any inconvenience caused";
    const title =
      this.props.entity.subscription_state === "trial_ended"
        ? "Trial ended"
        : this.props.entity.subscription_state === "expired"
        ? "Issue with payment"
        : this.props.entity.subscription_state === "card_required"
        ? "Card Required"
        : "Unknown issue";

    return (
      <div className={styles.section}>
        <div className={styles.row}>
          <main className={styles.content}>
            <h1 className={styles.heading}>{title}</h1>
            <hr />
            <div className={styles.main_text}>
              <p>{description}</p>
              {!!currentCard && (
                <p>
                  {"Last card: "}
                  <span className={styles.blue}>{currentCard}</span>
                </p>
              )}
            </div>
            {this.props.isAdmin && (
              <div className={styles.card_input}>
                <EditCardInputs
                  canEdit={!isPseudoRole()}
                  ref={r => (this._editCardInputs = r)}
                  payment={payment}
                  paymentActions={paymentActions}
                />
              </div>
            )}
            {this.props.isAdmin && (
              <div className={styles.button}>
                <Button
                  disabled={this.props.ia.addCardLoading}
                  handleClick={this._onSubmit.bind(this)}
                  text={buttonText}
                />
              </div>
            )}
            {this.props.ia.showSuccessModal && (
              <BlueModal
                section_title={"Credit card updated"}
                section_description={
                  "You credit card has been added and will be billed monthly"
                }
                icon="credit_card"
                hide={this._hideAndFinish.bind(this)}
              />
            )}
          </main>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    ia: state.invalid_account,
    entityId: state.current_user.current_entity.id,
    entity: state.current_user.current_entity.attributes,
    isAdmin:
      state.current_user.current_user_entity_link.attributes.role_types.includes("admin"),
  }),
  dispatch => ({
    iaActions: bindActionCreators(iaActions, dispatch),
    dispatch,
  }),
)(InvalidAccount);
