import api from "api";
import capitalize from "lodash.capitalize";
import get from "lodash.get";
import BaseModel from "models/BaseModel";
import PpsrFinancingStatementCollateralModel from "models/PpsrFinancingStatementCollateralModel";
import PpsrFinancingStatementDebtorModel from "models/PpsrFinancingStatementDebtorModel";
import RecordHistoryModel from "models/RecordHistoryModel";
import { formatLocalTime } from "utils/dateFormatter";
import isPresent from "utils/isPresent";

export const COLLATERAL_TYPE_OPTIONS = [
  // NZ collateral types
  { label: "Goods - Livestock", region: "NZ", value: "LS" },
  { label: "Goods - Crops", region: "NZ", value: "CROPS" },
  { label: "Goods - Other", region: "NZ", value: "OTH" },
  { label: "Negotiable Instruments", region: "NZ", value: "NI" },
  { label: "Money", region: "NZ", value: "CASH" },
  { label: "Motor Vehicle", region: "NZ", value: "MV" },
  { label: "Intangibles", region: "NZ", value: "INT" },
  {
    label: "All present and after acquired personal property",
    region: "NZ",
    value: "AAPP",
  },
  {
    label: "All present and after acquired personal property except...",
    region: "NZ",
    value: "AAPPE",
  },
  // AU collateral types (known as Collateral Class)
  { label: "Agriculture", region: "AU", value: "Agriculture" },
  { label: "Agriculture / Crops", region: "AU", value: "Crops" },
  { label: "Agriculture / Livestock", region: "AU", value: "Livestock" },
  { label: "Other goods", region: "AU", value: "OtherGoods" },
  { label: "Financial property / Currency", region: "AU", value: "Currency" },
  {
    label: "Financial property / Intermediated security",
    region: "AU",
    value: "IntermediatedSecurity",
  },
  {
    label: "Financial property / Investment instrument",
    region: "AU",
    value: "InvestmentInstrument",
  },
  {
    label: "Financial property / Negotiable instrument",
    region: "AU",
    value: "NegotiableInstrument",
  },
  {
    label: "All present and after-acquired property",
    region: "AU",
    value: "AllPapNoExcept",
  },
  {
    label: "All present and after-acquired property except...",
    region: "AU",
    value: "AllPapWithExcept",
  },
];

export const COLLATERAL_PROPERTY_TYPE_OPTIONS = [
  { label: "Commercial property", value: "Commercial" },
  { label: "Consumer property", value: "Consumer" },
];

export const MULTIPLE_REGISTRATION_SUPPORTED_REGION = ["AU"];

const STATUS_COLOR_MAPPING = {
  discharged: "grey_medium",
  draft: "light_blue",
  registered: "blue",
};

export default class PpsrFinancingStatementModel extends BaseModel {
  static async loadPPSRFilterOptions({ accessToken, entityId }) {
    const reportPPSRAPI = api(
      "ppsr_financing_statements",
      accessToken,
      entityId
    );
    try {
      const result = await reportPPSRAPI.fetchBranchOptions();
      return get(result, "data", {});
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  static async fetchModulePaperlessApplications({
    accessToken,
    entityId,
    params,
  }) {
    const ppsrFinancingStatementAPI = api(
      "ppsr_financing_statements",
      accessToken,
      entityId,
    );

    try {
      const result = await ppsrFinancingStatementAPI.fetchAll(params);

      const applications = get(result, "data.data", []);
      const included = get(result, "data.included", []);
      const meta = get(result, "data.meta", {});

      const ppsrFinancingStatements = applications.map(
        application => new PpsrFinancingStatementModel(application, included),
      );

      return { meta, ppsrFinancingStatements };
    } catch (error) {
      console.error(error);
    }
  }

  get formattedExpiryDate() {
    if (isPresent(this.expiryDate)) {
      return formatLocalTime(this.expiryDate, "DD/MM/YYYY");
    }

    return null;
  }

  get formCollaterals() {
    const collaterals = this.ppsrFinancingStatementCollaterals.map(
      collateral => collateral.formValues,
    );

    if (isPresent(collaterals)) {
      return collaterals;
    }

    return [{ collateralDescription: " " }];
  }

  get formDebtors() {
    return this.ppsrFinancingStatementDebtors.map(debtor => debtor.formValues);
  }

  get formValues() {
    return {
      autoRenew: this.autoRenew,
      collaterals: this.formCollaterals,
      debtors: this.formDebtors,
      expiryDate: this.expiryDate,
      expiryDuration: this.expiryDuration,
      registrationReference: this.registrationReference,
      status: this.status,
    };
  }

  get formattedStatus() {
    if (this.expired && this.status === "registered") {
      return "Expired";
    }

    return capitalize(this.status);
  }

  get statusColor() {
    if (this.expired && this.status === "registered") {
      return "red";
    }

    return STATUS_COLOR_MAPPING[this.status];
  }

  get formattedFirstCollateralType() {
    const collateralOption =
      COLLATERAL_TYPE_OPTIONS.find(
        option => option.value === this.firstCollateralType,
      ) || {};

    return collateralOption.label;
  }

  get isDraft() {
    return this.status === "draft";
  }

  get isPinPresent() {
    return isPresent(this.financingStatementPin);
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  /** Save is to "create" or "update" non-registered financing statement */
  async save({
    application,
    attributes,
    currentUser,
    onErrorCallback,
    onSuccessCallback,
  }) {
    this.setAttributes({ ...attributes, application_id: application.id });

    const ppsrFinancingStatementAPI = api(
      "ppsr_financing_statements",
      currentUser.accessToken,
      get(currentUser, "currentEntity.id"),
    );

    try {
      let result;
      if (isPresent(this.id)) {
        result = await ppsrFinancingStatementAPI.update(
          this.id,
          this.attributes,
        );
      } else {
        result = await ppsrFinancingStatementAPI.create(this.attributes);
      }

      const newPpsrFinancingStatement = new PpsrFinancingStatementModel(
        get(result, "data.data"),
        get(result, "data.included"),
      );
      onSuccessCallback(newPpsrFinancingStatement);
    } catch (e) {
      if (onErrorCallback) {
        console.error(e);
        onErrorCallback(e);
      }
    }
  }

  async updateFinancingStatementPin({
    currentUser,
    financingStatementPin,
    onErrorCallback,
    onSuccessCallback,
  }) {
    this.setAttributes({ financingStatementPin });

    const ppsrFinancingStatementAPI = api(
      "ppsr_financing_statements",
      currentUser.accessToken,
      get(currentUser, "currentEntity.id"),
    );

    try {
      const result = await ppsrFinancingStatementAPI.updatePin(
        this.id,
        financingStatementPin,
      );

      const newPpsrFinancingStatement = new PpsrFinancingStatementModel(
        get(result, "data.data"),
        get(result, "data.included"),
      );
      onSuccessCallback(newPpsrFinancingStatement);
    } catch (e) {
      if (onErrorCallback) {
        console.error(e);
        onErrorCallback(e);
      }
    }
  }

  /** Modify is to "update" a registered financing statement */
  async modify({
    attributes,
    currentUser,
    financingStatementPin,
    onErrorCallback,
    onSuccessCallback,
  }) {
    this.setAttributes({ ...attributes });

    const ppsrFinancingStatementAPI = api(
      "ppsr_financing_statements",
      currentUser.accessToken,
      get(currentUser, "currentEntity.id"),
    );

    try {
      const result = await ppsrFinancingStatementAPI.modify({
        attributes: this.attributes,
        financingStatementPin,
        id: this.id,
      });

      const newPpsrFinancingStatement = new PpsrFinancingStatementModel(
        get(result, "data.data"),
        get(result, "data.included"),
      );
      onSuccessCallback(newPpsrFinancingStatement);
    } catch (e) {
      if (onErrorCallback) {
        console.error(e);
        onErrorCallback(e);
      }
    }
  }

  async renew({
    currentUser,
    attributes,
    financingStatementPin,
    onErrorCallback,
    onSuccessCallback,
  }) {
    const { expiryDate, expiryDuration } = attributes;
    this.setAttributes({ expiryDate, expiryDuration });

    const ppsrFinancingStatementAPI = api(
      "ppsr_financing_statements",
      currentUser.accessToken,
      get(currentUser, "currentEntity.id"),
    );

    try {
      const result = await ppsrFinancingStatementAPI.renew(this.id, {
        expiry_date: expiryDate,
        expiry_duration: expiryDuration,
        financing_statement_pin: financingStatementPin,
      });

      const newPpsrFinancingStatement = new PpsrFinancingStatementModel(
        get(result, "data.data"),
        get(result, "data.included"),
      );
      onSuccessCallback(newPpsrFinancingStatement);
    } catch (e) {
      if (onErrorCallback) {
        console.error(e);
        onErrorCallback(e);
      }
    }
  }

  async discharge({
    currentUser,
    financingStatementPin,
    onErrorCallback,
    onSuccessCallback,
  }) {
    const ppsrFinancingStatementAPI = api(
      "ppsr_financing_statements",
      currentUser.accessToken,
      get(currentUser, "currentEntity.id"),
    );

    try {
      const result = await ppsrFinancingStatementAPI.discharge(this.id, {
        financing_statement_pin: financingStatementPin,
      });

      const newPpsrFinancingStatement = new PpsrFinancingStatementModel(
        get(result, "data.data"),
        get(result, "data.included"),
      );
      onSuccessCallback(newPpsrFinancingStatement);
    } catch (e) {
      if (onErrorCallback) {
        console.error(e);
        onErrorCallback(e);
      }
    }
  }

  /** Private functions */

  assignRelationships() {
    this.assignManyRelationship({
      key: "ppsr_financing_statement_collaterals",
      model: PpsrFinancingStatementCollateralModel,
    });
    this.assignManyRelationship({
      included: this.included,
      key: "ppsr_financing_statement_debtors",
      model: PpsrFinancingStatementDebtorModel,
    });
    this.assignManyRelationship({
      key: "ppsr_financing_statement_histories",
      model: RecordHistoryModel,
    });
  }
}
