/* Import libraries */
import React, { Component } from "react";
import PaperlessDetails from "../paperless/PaperlessDetails";
import { connect } from "react-redux";
import styles from "./css/Review.css";

const ReviewPaperless = (props) => {
  return (
    <section className={styles.expandable}>
      <span className={styles.shadow} />
      <PaperlessDetails {...props} reviewMode={true} />
    </section>
  );
}

export default connect()(ReviewPaperless);
