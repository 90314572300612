import { combineReducers } from "redux";

import { integrationReducer } from "./integrations/reducers";
import { webhookReducer } from "./webhooks/reducers";

const ModelReducer = combineReducers({
  integrations: integrationReducer,
  webhooks: webhookReducer,
});

export default ModelReducer;
