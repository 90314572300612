import React, { ReactElement, useState } from 'react'
import FormBuilderComponentRender from '../FormBuilderComponentRender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Actions, Handler, More, Wrapper } from './styles';

interface Props {
  borderedStyle?: boolean,
  formParams: any,
  editable: boolean,
  handleEdit: () => void,
  handleDelete: () => void,
}

function FormBuilderComponentDraggableWrapper(props: Props): ReactElement {
  const {
    borderedStyle,
    editable,
    formParams,
    handleDelete,
    handleEdit,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMoreOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMoreClose = () => {
    setAnchorEl(null);
  }

  return (
    <Wrapper active={Boolean(anchorEl)}>
      <Handler>
        <FontAwesomeIcon icon={['fas', 'arrows-alt']} />
      </Handler>
      <FormBuilderComponentRender
        borderedStyle={borderedStyle}
        formParams={formParams}
      />
      {editable && (
        <Actions>
          <More type="button" onClick={handleMoreOpen}>
            <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
          </More>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMoreClose}
          >
            <MenuItem
              onClick={handleEdit}
            >Edit</MenuItem>
            <MenuItem
              onClick={handleDelete}
            >Delete</MenuItem>
          </Menu>
        </Actions>
      )}
    </Wrapper>
  )
}

export default FormBuilderComponentDraggableWrapper