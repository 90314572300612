/* tslint:disable */
import React, { ReactElement, useEffect, useState } from 'react'
import AccountTypeCheckbox from "modules/addons/addons_form/components/AccountTypeCheckbox/index.tsx";
import AddonConfigModel from 'models/AddonConfigModel';
import get from "lodash.get";
import UserModel from 'models/UserModel';
import isPresent from 'utils/isPresent';

interface Props {
  currentUser: any,
  setShowTextbox: (bool: boolean) => void,
}

type ApplicationTypes = string[];

type AddonAttributes = {
  addon_config_id?: string,
  addon_module_name?: string,
  application_types: ApplicationTypes,
  config: {},
  name?: string,
}

const addon_module_name = 'website_button';
const errorText = 'Ops! Something is wrong, please try again later or contact us if the problem still persists.';

function initializeAddonModule() {
  return new AddonConfigModel({
    attributes: { addon_module_name },
  });
}

async function getOrInitializeAddonModule(currentUser) {
  const addonConfigs = await AddonConfigModel.fetchAddonConfigByAddonType({
    accessToken: currentUser.accessToken,
    addonType: [addon_module_name],
    entityId: get(currentUser, "currentEntity.id"),
  });

  if (!isPresent(addonConfigs)) {
    return;
  }

  return addonConfigs;
}

function getNewVersionAttribute(addonConfig, data: string[]) {
  const attributes: AddonAttributes = {
    application_types: data,
    config: {},
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = "Website button";
    attributes.addon_module_name = addon_module_name;
  }

  return attributes;
}

function WebsitebuttonCheckboxes({
  currentUser,
  setShowTextbox,
}: Props): ReactElement {
  const processedCurrentUser = UserModel.fromCurrentUser(currentUser);
  const [ addonConfig, setAddonConfig ] = useState(initializeAddonModule());
  const [ selected, setSelected ] = useState<ApplicationTypes|null>(null);
  const [ error, setError ] = useState<string|null>(null);

  const onGettingAddons = () => {
    (async () => {
      const result = await getOrInitializeAddonModule(processedCurrentUser);

      if (result) {
        setShowTextbox(true);
        setAddonConfig(result[0]);

        const latestConfig = get(result[0], 'attributes.history_version.data')[0];
        const latestConfigTypes = get(latestConfig, 'attributes.application_types');
        setSelected(latestConfigTypes.length > 0 ? latestConfigTypes : ['credit']);
      }
    })()
  }

  const onSuccessCallback = (selection: ApplicationTypes) => {
    setShowTextbox(true);
    setSelected(selection);
    setError(null);
  }

  const onErrorCallback = () => {
    setError(errorText);
  }

  useEffect(() => {
    if (!selected) {
      onGettingAddons();
    }
  }, [selected])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.name;
    const currentSelected = selected ? selected : [];
    const selection = currentSelected.includes(value)
      ? currentSelected.filter(selectedValue => selectedValue !== value)
      : [...currentSelected, value];

    const newVersion = addonConfig.generateNewVersion();
    const attributes = getNewVersionAttribute(addonConfig, selection);
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser: processedCurrentUser,
      onSuccessCallback: () => onSuccessCallback(selection),
      onErrorCallback,
    });
  }

  return (
    <AccountTypeCheckbox
      label="Apply website button to the forms below:"
      description={`By selecting the application types you need
      in your business, your custom website button and QR code will generate.
      You can select one or both application types which will automatically
      populate when your customer completes their application.`}
      selected={selected || []}
      handleChange={handleChange}
      error={error}
    />
  )
}

export default WebsitebuttonCheckboxes
