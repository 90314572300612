import React from "react";

import styles from "./css/extraDescription.css";
import TermsScroller from "./TermsScroller";

class ExtraDescription extends React.Component {
  render() {
    const { hintName, show } = this.props;
    return <p className={styles.text}>{show ? texts[hintName] : ""}</p>;
  }
}

const texts = {
  headquarterSettingHint:
    "*These settings will be the initial settings for all tiers, unless they set their own.",
};

export default ExtraDescription;
