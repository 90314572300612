import styles from "modules/shared/components/widgets/static/css/LabeledContent.css";
import React from "react";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

function Content(props) {
  const { children, content } = props;

  if (isPresent(content)) {
    return content;
  }

  if (isPresent(children)) {
    return children;
  }

  return "-";
}

function containerClassName(props) {
  const { withBottomMargin } = props;
  const defaultClassNames = [];

  if (withBottomMargin) {
    defaultClassNames.push(styles.with_bottom_margin);
  }

  return defaultClassNames.join(" ");
}

export default function LabeledContent(props) {
  const { content, children, isVisibleWhenEmpty, label } = props;

  if (isBlank(content) && isBlank(children) && !isVisibleWhenEmpty) {
    return null;
  }

  return (
    <div className={containerClassName(props)}>
      <div className={styles.label}>{label}</div>
      <div>
        <Content {...props} />
      </div>
    </div>
  );
}

LabeledContent.defaultProps = {
  isVisibleWhenEmpty: true,
  withBottomMargin: true,
};
