import React from "react";
import ScrollArea from "react-scrollbar";
import onClickOutside from "react-onclickoutside";
import FocusLock from "react-focus-lock";
import { debounce } from "throttle-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { isMobile } from "modules/shared/helpers/mobileDetect";
import CloseButton from "modules/shared/components/inputs/CloseButton";

/* Import CSS */
import styles from "./css/AutoSuggest.css";

import TextInput from "modules/shared/components/inputs/TextInput";
import BorderedTextField from "../../inputs/BorderedTextField";
import { InputAdornment } from "@material-ui/core";

/**
 * Creates the dropdown list.
 */
function DropDown(props) {
  const {
    items,
    classes,
    selected,
    scroll,
    handleClick,
    value,
    hideBottom,
  } = props;
  const data = [];

  Object.keys(items).forEach(key => {
    var style = styles.item;
    if (selected == items[key]) {
      style = styles.item_selected;
    }

    var item = items[key];
    if (
      typeof items[key] === "object" &&
      items[key].hasOwnProperty("id") &&
      items[key].id === "company_name_search"
    ) {
      let name = items[key].name;
      let first_email = null;
      if (name === "") {
        let searched_email = items[key].user.find(v => v.email === value);
        let first_admin = items[key].first_admin;
        if (searched_email) {
          name = searched_email.full_name;
          first_email = searched_email.email;
        } else if (first_admin) {
          name = first_admin.first_name
            ? first_admin.first_name +
              (first_admin.last_name ? " " + first_admin.last_name : "")
            : "";
          first_email = first_admin.email;
        }
      }

      item = (
        <div>
          <div className={styles.company_info}>
            <span className={styles.company_name}>{name}</span>
            {items[key].hasOwnProperty("application_type") &&
              items[key].application_type !== null && (
                <span className={styles.application_type}>
                  Existing customer ({items[key].application_type})
                </span>
              )}
          </div>
          {first_email && (
            <div className={styles.company_first_admin_email}>
              {first_email}
            </div>
          )}
          {items[key].hasOwnProperty("address") &&
            items[key].address !== null && (
              <div className={styles.company_address}>
                {items[key].address}
              </div>
            )}
        </div>
      );
    }

    data.push(
      <li
        key={key}
        className={style}
        onMouseDown={handleClick.bind(null, key)}
      >
        {item}
      </li>,
    );
  });

  if (
    items[Object.keys(items)[0]].hasOwnProperty("id") &&
    items[Object.keys(items)[0]].id === "company_name_search" &&
    !hideBottom
  ) {
    data.push(
      <li
        key={"no_result"}
        className={styles.last_lisit}
        onMouseDown={handleClick.bind(null, "no_result")}
      >
        Can't find the business / person
      </li>,
    );
  }

  if (scroll) {
    var verticalContainerCSS = {
      backgroundColor: "#c9c9c9",
      opacity: 1,
      width: 6,
      zIndex: 1,
      borderRadius: 3,
    };

    var verticalScrollbarCSS = {
      borderRadius: 3,
      width: 6,
      margin: 0,
      zIndex: 2,
    };

    return (
      <ScrollArea
        speed={0.8}
        className={styles.scroll_section}
        contentClassName="options"
        horizontal={false}
        verticalContainerStyle={verticalContainerCSS}
        verticalScrollbarStyle={verticalScrollbarCSS}
      >
        <ul
          className={classes}
          onWheel={e => {
            e.stopPropagation();
          }}
        >
          {data}
        </ul>
      </ScrollArea>
    );
  } else {
    return (
      <ul
        className={classes}
        onWheel={e => {
          e.stopPropagation();
        }}
      >
        {data}
      </ul>
    );
  }
};

/**
 * The full autocomplete
 */
class AutoSuggest extends React.Component {
  state = { focus: false, inputFocus: false, typing: false };

  UNSAFE_componentWillMount() {
    this.setTypingToFalse = debounce(500, false, this.setTypingToFalse);
  }

  onChange = (event) => {
    const { handleChange } = this.props;
    this.setTypingToFalse();
    this.setState({ typing: true });
    if (handleChange) handleChange(event);
  }

  setTypingToFalse = () => {
    this.setState({ typing: false });
  }

  onFocus = (event) => {
    const { handleFocus } = this.props;
    this.setState({ focus: true, inputFocus: true });
    if (isMobile()) {
      var rectPosition = event.target.getBoundingClientRect();
      window.scrollTo(0, rectPosition.top + window.pageYOffset - 120);
    }
    if (handleFocus) handleFocus(true);
  }

  onBlur = (event) => {
    const { handleBlur, handleFocus } = this.props;
    this.setState({ focus: false });
    if (handleBlur) {
      handleBlur(event);
    }
    if (handleFocus) handleFocus(false);
  }

  getClass = () => {
    if (this.state.focus === true) return styles.autosuggest_focus;
    else if (this.state.inputFocus === true) return styles.autosuggest_focus;
    else return styles.autosuggest;
  }

  clearInputField = (e) => {
    const { handleChange } = this.props;
    var return_event = { target: { value: "" } };
    handleChange(return_event);
  }

  render() {
    const {
      borderedStyle,
      loading,
      label_class,
      label_error_class,
      input_class,
      input_error_class,
      css_class,
      dropdown_class,
      scroll,
      id,
      label,
      selected_item,
      value,
      error,
      handleChange,
      handleClick,
      suggest_items,
      admin,
      force_focus,
      show_help,
      help_text,
      required,
      isClearInputField,
      helper_text,
      hideBottom,
      placeholder,
      disabled,
    } = this.props;

    let items = null;
    let label_style = styles.label;
    let label_error_style = styles.label_error;
    let input_style = styles.input;
    let input_error_style = styles.input_error;
    let dropdown_style = styles.dropdown;

    if (label_class) {
      label_style = styles[label_class];
    }

    if (input_class) {
      input_style = styles[input_class];
    }

    if (input_error_class) {
      input_error_style = styles[input_error_class];
    }

    if (label_error_class) {
      label_error_style = styles[label_error_class];
    }

    let label_text = label;

    if (error) {
      label_text = error;
      label_style = label_error_style;
      input_style = input_error_style;
    }

    if(borderedStyle) {
      dropdown_style = styles.bordered_dropdown;
    }

    if (dropdown_class) {
      dropdown_style = styles[dropdown_class];
    }
    // focus_while_loading = true;
    let isItemSelected = selected_item == value && !this.state.typing;
    let forceFocus = !!force_focus;
    let focusLock = forceFocus && !isItemSelected;
    if (focusLock || this.state.focus) {
      if (Object.keys(suggest_items).length > 0) {
        items = (
          <DropDown
            classes={dropdown_style}
            handleClick={handleClick}
            scroll={scroll}
            items={suggest_items}
            selected={selected_item}
            value={value}
            hideBottom={hideBottom}
          />
        );
      } else if (
        forceFocus &&
        value !== "" &&
        !this.state.typing &&
        !loading &&
        ![
          "physical_address",
          "residential_address",
          "personal_address",
        ].includes(id)
      ) {
        items = (
          <ul className={dropdown_style}>
            <li className={styles.no_result}>No results.</li>
            <li className={styles.no_result_hint}>
              Please make sure all words are spelled correctly or try different
              keywords
            </li>
          </ul>
        );
      }
    }

    let container_class = `${this.getClass()} ${styles[css_class]}`;
    let clear_input_field = null;

    if (value) {
      container_class = `${styles.autosuggest_focus} ${styles[css_class]}`;
      if (isClearInputField)
        clear_input_field = (
          <CloseButton
            css_class="clear_input_button"
            text="x"
            onMouseDown={this.clearInputField}
          />
        );
    }

    if (!required) {
      if (error) {
        label_text = error;
        label_style = styles.label_not_required + " " + label_error_style;
        input_style = styles.input_not_required + " " + input_error_style;
      } else {
        label_style = styles.label_not_required;
        input_style = styles.input_not_required;
      }
    }

    if (admin) {
      container_class = `${styles.admin} ${container_class}`;
    }

    let help;
    if (show_help && !items) {
      help = (
        <p className={styles.help_text}>
          {help_text} Please email support at{" "}
          <a href={"mailto:support@1centre.com"}>{"support@1centre.com"}</a>
        </p>
      );
    }

    const Input = borderedStyle ? BorderedTextField : TextInput;
    const endAdornment =  loading && (
      <InputAdornment>
        <FontAwesomeIcon icon="spinner" spin />
      </InputAdornment>
    )

    const handleEvents = {
      onBlur: this.onBlur,
      onChange: this.onChange,
      onFocus: this.onFocus,
      handleBlur: this.onBlur,
      handleChange: this.onChange,
      handleFocus: this.onFocus,
    };

    if (borderedStyle) {
      // TODO - Clean up passing unnecessary
      // props to input component, prevent
      // warnings in the console
      delete handleEvents['handleChange'];
      delete handleEvents['handleBlur'];
      delete handleEvents['handleFocus'];
    }

    return (
      <FocusLock disabled={!focusLock}>
        <div className={container_class}>
          <Input
            id={id}
            name={id}
            value={value || ""}
            loading={loading}
            label={label}
            helper_text={helper_text}
            helperText={error || helper_text}
            required={required}
            error={error}
            autoComplete="off"
            placeholder={placeholder}
            disabled={disabled}
            customProps={{ endAdornment }}
            {...handleEvents}
          />
          {help}
          {items}
        </div>
      </FocusLock>
    );
  }
}

export default AutoSuggest;
