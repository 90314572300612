import isEmpty from "lodash.isempty";
import GuarantorModel from "models/GuarantorModel";
import FixedContent from "modules/shared/components/containers/FixedContent";
import GridContent from "modules/shared/components/containers/GridContent";
import LabeledContent from "modules/shared/components/widgets/static/LabeledContent";
import React from "react";

function Guarantor(props) {
  const { guarantor, index } = props;

  return (
    <FixedContent header={`Guarantor ${index}`}>
      <GridContent>
        <div>
          <LabeledContent label="Name" content={guarantor.name} />
          <LabeledContent
            label="Agreed to guarantee account"
            content={guarantor.approvedString}
          />
        </div>
        <div>
          <LabeledContent label="Email" content={guarantor.email} />
          <LabeledContent
            label="Position"
            content={guarantor.formattedPosition}
          />
        </div>
      </GridContent>
    </FixedContent>
  );
}

export default function Guarantors(props) {
  const { application } = props;
  if (!application && !application.id) {
    return;
  }
  let guarantors = application.guarantors;
  if (isEmpty(guarantors)) {
    guarantors = Array.from(
      { length: application.minimumGuarantees },
      () => new GuarantorModel(),
    );
  }
  return guarantors.map((guarantor, index) => (
    <Guarantor
      key={`guarantor-${index + 1}`}
      index={index + 1}
      guarantor={guarantor}
    />
  ));
}
