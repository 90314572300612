import { Collapse } from "@material-ui/core"
import styled, { css } from "styled-components";
import { COLORS, THEME_COLORS } from "variables/theme";
import { FONT_WEIGHTS } from "variables/typography";

const gridStyles = `
  display: grid;
  grid-template-columns: 3fr repeat(4, 1fr);
  grid-column-gap: 5px;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  * {
    font-weight: ${FONT_WEIGHTS.normal} !important;
  }
`;

export const EmailLogRowContainer = styled.div`
  border-top: 1px solid ${COLORS.mecury};
  padding: 1.5rem 0 0.5rem;
`;

export const EventCol = styled.div<{event?: string}>`
  text-transform: capitalize;

  ${props => props.event && props.event === "bounce" && css`
    color: ${THEME_COLORS.danger};
  `}
`;

export const SubjectCol = styled.div`
  display: flex;
`;

export const Subject = styled.div`
  color: ${THEME_COLORS.primary};
  cursor: pointer;
`;

export const TableHeader = styled.div`
  ${gridStyles}
  font-weight: ${FONT_WEIGHTS.normal};
  margin-bottom: 0;
  padding-bottom: 10px;
`;

export const TableRow = styled.div`
  ${gridStyles}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TableRowDetails = styled(Collapse)`
  background-color: ${COLORS.mecury};
  margin-bottom: 0.5rem;

  > div {
    padding: 1rem 0;
  }
`;

export const Icon = styled.span`
  color: ${COLORS.mediumGrey};
`;
