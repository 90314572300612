import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

/* Import CSS */
import styles from "./css/CommonReviewBusiness.css";

import Button from "modules/shared/components/inputs/Button";
import TableCell from "modules/shared/components/widgets/static/TableCell";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import logo from "../../../images/logo-equifax.png";

import { ynToYesNo, formatMoney, displayEmpty } from "utils/formatting";
import { formatDate } from "utils/dateFormatter";
import { humanize } from "../../../utils/humanize";

const DEFAULT_PAGE_SIZE = 10;

const COMPANY_STATUS_MAPPING = {
  "50": "REGISTERED",
  "55": "VOLUNTARY ADMINISTRATION",
  "56": "VA & IN REC",
  "57": "IN REC & IN VA",
  "60": "IN LIQUIDATION",
  "61": "LIQ & REC",
  "62": "STRUCK OFF & IN LIQ",
  "63": "IN REC & IN LIQ & IN VA",
  "64": "IN LIQ & IN REC & IN VA",
  "65": "IN LIQ & VA",
  "66": "IN LIQ & IN VA & IN REC",
  "70": "IN RECEIVERSHIP",
  "71": "IN REC & IN LIQ",
  "72": "IN STATUTORY MANAGEMENT",
  "73": "IN ADMINISTRATION",
  "80": "STRUCK OFF",
};

const COLLATERAL_TYPE_MAPPING = {
  LS: "Goods- Livestock",
  MV: "Goods – Motor Vehicles",
  ACFT: "Goods - Aircraft",
  CROPS: "Goods - Crops",
  OTH: "Goods - Other",
  AAPP: "All present and after acquired personal property",
  AAPPE: "All present and after acquired personal property except...",
  CASH: "Money",
  CP: "Chattel Paper",
  DT: "Documents of Title",
  INT: "Intangibles",
  IS: "Investment Securities",
  NI: "Negotiable Instruments",
};

var VedaCommercialCheck = createClass({
  extraDefaultData: function(row) {
    let extraData = [],
      officersMarkup,
      officers = row["officers"],
      extraDataFields = [
        "date_account_opened",
        "terms",
        "address_supplied",
        "notes",
      ];

    for (var prop in row) {
      if (extraDataFields.indexOf(prop) > -1) {
        extraData.push(
          <TableCell
            key={prop}
            label={prop}
            data={row[prop]}
            showEmpty={true}
            table_layout="col_1"
            th_position="left"
          />,
        );
      }
    }

    if (officers !== null) {
      const officersColumns = [
        {
          header: "Director",
          accessor: "director",
        },
        {
          header: "Joint",
          accessor: "joint",
        },
        {
          header: "Director Address",
          accessor: "director_address",
        },
      ];

      officersMarkup = (
        <div>
          <ReactTable
            data={officers}
            columns={officersColumns}
            minRows={officers.length}
            showPagination={false}
            showPageSizeOptions={false}
          />
        </div>
      );
    }

    return (
      <div>
        {extraData}
        {officersMarkup}
      </div>
    );
  },

  extraPPSRData: function(row) {
    let collateralsData = [],
      collaterals = row.collaterals;

    if (collaterals.length > 0) {
      collaterals.forEach((collateral, i) => {
        let collateralItems =
            collateral.collateral_items != undefined
              ? collateral.collateral_items
              : null,
          collateralTypeMarkup,
          collateralDescriptionMarkup,
          collateralItemsData = [],
          collateralItemsDataOutput;

        if (collateral.type != undefined) {
          collateralTypeMarkup = <p>{collateral.type}</p>;
        }

        if (collateral.collateral_description != undefined) {
          collateralDescriptionMarkup = (
            <p>{collateral.collateral_description}</p>
          );
        }

        // collateral item details
        if (collateralItems.length > 0) {
          collateralItems.forEach((collateralItem, collateralIndex) => {
            collateralItemsData.push(
              <TableCell
                key={collateralIndex}
                label={collateralItem.name}
                data={collateralItem.value}
                showEmpty={true}
                table_layout="col_1"
                th_position="left"
              />,
            );
          });
        }

        if (collateralItemsData.length) {
          collateralItemsDataOutput = (
            <div className={styles.table_wrapper}>
              <div className={styles.table}>{collateralItemsData}</div>
            </div>
          );
        }

        collateralsData.push(
          <div key={i}>
            {collateralTypeMarkup}
            {collateralDescriptionMarkup}
            {collateralItemsDataOutput}
          </div>,
        );
      });
    }

    return <div>{collateralsData}</div>;
  },

  parseNewlines: function(text) {
    let items = [];

    text.split("\n").forEach((v, i) => {
      items.push(<p key={i}>{v}</p>);
    });

    return items;
  },

  getDefaultsColumns: function() {
    return [
      {
        header: "Default Date",
        accessor: "default_date",
      },
      {
        header: "Creditor",
        accessor: "creditor",
      },
      {
        header: "Amount",
        accessor: "amount",
      },
      {
        header: "Balance",
        accessor: "balance",
      },
      {
        header: "Status",
        accessor: "status",
      },
      {
        header: "Action Date",
        accessor: "action_date",
      },
      {
        header: "Date Paid",
        accessor: "date_paid",
      },
    ];
  },

  renderPpsrDetailsMarkup: function() {
    const { data } = this.props;
    if (!data.ppsr.ppsr_financial_statements) {
      return null;
    }

    let paginate = false;
    const ppsrColumns = [
      {
        header: "Statement ID",
        accessor: "statement_id",
      },
      {
        header: "Date Registered",
        accessor: "date_registered",
        render: rowInfo => (
          <div>
            <p>{rowInfo.row.date_registered}</p>
            <p>{rowInfo.row.time_registered}</p>
          </div>
        ),
      },
      {
        header: "Secured Party",
        accessor: "secured_party",
      },
      {
        header: "Subordinate Statements",
        accessor: "subordinate_statements",
      },
      {
        header: "Collateral Type",
        accessor: "collateral_type",
        render: props => <div>{this.parseNewlines(props.value)}</div>,
      },
    ];

    if (data.ppsr.ppsr_financial_statements.length > DEFAULT_PAGE_SIZE) {
      paginate = true;
    }

    return (
      <div className={styles.table_wrapper}>
        <ReactTable
          data={data.ppsr.ppsr_financial_statements}
          columns={ppsrColumns}
          minRows={data.ppsr.ppsr_financial_statements.length}
          showPagination={paginate}
          showPageSizeOptions={paginate}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          SubComponent={row => {
            return (
              <div className={styles.table_output}>
                {this.extraPPSRData(row.row)}
              </div>
            );
          }}
        />
      </div>
    );
  },

  renderPpsrSummaryMarkup: function() {
    const { data } = this.props;
    let ppsrCollateralTotalsMarkup,
      ppsrCollateralTotalsData = [],
      ppsrTotals = data.ppsr.ppsr_financial_statement_totals;

    if (!ppsrTotals) {
      return null;
    }

    let searchID = ppsrTotals.find(item => item.name === "search-id-number"),
      searchDate = ppsrTotals.find(item => item.name === "search-date"),
      searchTime = ppsrTotals.find(item => item.name === "search-time"),
      totalFinancingStatements = ppsrTotals.find(
        item => item.name === "total-financing-statements",
      ),
      totalFinancingStatementsLast6m = ppsrTotals.find(
        item => item.name === "total-financing-statements-last-6m",
      ),
      totalFinancingStatementsLast7m = ppsrTotals.find(
        item => item.name === "total-financing-statements-last-7to12m",
      ),
      totalFinancingStatementsLast13m = ppsrTotals.find(
        item => item.name === "total-financing-statements-last-13to24m",
      ),
      totalFinancingStatementsLast24m = ppsrTotals.find(
        item => item.name === "total-financing-statements-24plusm",
      );

    if (data.ppsr.ppsr_collateral_totals) {
      data.ppsr.ppsr_collateral_totals.forEach((v, i) => {
        ppsrCollateralTotalsData.push(
          <TableCell
            key={i}
            label={v.name}
            data={v.value}
            showEmpty={true}
            table_layout="col_1"
            th_position="left"
          />,
        );
      });

      ppsrCollateralTotalsMarkup = (
        <div className={styles.table_wrapper_no_margin_top}>
          {ppsrCollateralTotalsData}
        </div>
      );
    }

    return (
      <div>
        <p>
          Some Financing Statements may have been discharged or expired at/after
          the date this report was generated. These statements could be restored
          by a Court Order. It should also be noted that there may be a pending
          Financing Statement that is in the process of being registered.
          Consider conducting another search at a later date.
        </p>
        <p>
          <span className={styles.space_right}>
            <strong>Search ID:</strong>{" "}
            {searchID != undefined ? searchID.value : "No data returned"}
          </span>
          <span className={styles.space_right}>
            <strong>Date and Time of Search:</strong>{" "}
            {searchDate != undefined ? searchDate.value : "No data returned"}{" "}
            {searchTime != undefined ? searchTime.value : "No data returned"}
          </span>
        </p>
        <div className={styles.table}>
          <TableCell
            label="Total Financing Statements Found"
            data={
              totalFinancingStatements != undefined
                ? totalFinancingStatements.value
                : "No data returned"
            }
            showEmpty={true}
            table_layout="col_1"
            th_position="left"
          />
          <TableCell
            label="Total Collaterals by Collateral Type"
            label_only={true}
            showEmpty={true}
            table_layout="col_1"
          />
          {ppsrCollateralTotalsMarkup}
        </div>

        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <thead>
              <tr>
                <th className={styles.th_left}>
                  Total Financing Statements Registered in the Last
                </th>
                <th className={styles.th_left}>6 Months</th>
                <th className={styles.th_left}>7-12 Months</th>
                <th className={styles.th_left}>13-24 Months</th>
                <th className={styles.th_left}>24 Months+</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td className={styles.td_left}>
                  {totalFinancingStatementsLast6m != undefined
                    ? totalFinancingStatementsLast6m.value
                    : "No data returned"}
                </td>
                <td className={styles.td_left}>
                  {totalFinancingStatementsLast7m != undefined
                    ? totalFinancingStatementsLast7m.value
                    : "No data returned"}
                </td>
                <td className={styles.td_left}>
                  {totalFinancingStatementsLast13m != undefined
                    ? totalFinancingStatementsLast13m.value
                    : "No data returned"}
                </td>
                <td className={styles.td_left}>
                  {totalFinancingStatementsLast24m != undefined
                    ? totalFinancingStatementsLast24m.value
                    : "No data returned"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  },

  renderEnquiries: function() {
    const { data } = this.props;
    if (!data.hasOwnProperty("company-credit-file")) return null;

    let inquiries = data["company-credit-file"].inquiry;
    if (inquiries[0].constructor !== Array) inquiries = [inquiries];

    const columns = [
      {
        header: "No.",
        acccessor: "inquiryDate",
        render: props => <span className="number">{props.index + 1}</span>,
      },
      { header: "Date", accessor: "inquiryDate" },
      { header: "Inquirer", accessor: "inquirer" },
      { header: "Account Type", accessor: "accountType" },
      { header: "Inquiry Amount", accessor: "amount" },
    ];

    const contents = inquiries.map(inquiry => {
      let _inquiry = inquiry;
      if (_inquiry.constructor !== Array) _inquiry = [_inquiry];

      const parsedInquiry = _inquiry.reduce((accumulator, current) => {
        accumulator[Object.keys(current)[0]] = current[Object.keys(current)[0]];
        return accumulator;
      }, {});

      let inquiryAmount = parsedInquiry["inquiry-amount"];
      if (inquiryAmount) formatMoney(parseFloat(inquiryAmount));

      return {
        inquiryDate: formatDate(parsedInquiry["inquiry-date"], "DD/MM/YYYY"),
        inquirer: parsedInquiry["company-credit-inquirer"],
        accountType: displayEmpty(parsedInquiry["account-type"], ""),
        amount: inquiryAmount,
      };
    });

    return (
      <ReviewContainer
        subHeading="Inquiries"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
      >
        <ReactTable
          data={contents}
          columns={columns}
          showPagination={true}
          showPageSizeOptions={true}
          defaultPageSize={DEFAULT_PAGE_SIZE}
        />
      </ReviewContainer>
    );
  },

  renderPPSRFinanceStatement() {
    const { data } = this.props;
    if (!data.hasOwnProperty("ppsr-financing-statements")) return null;

    let financeStatements = (data["ppsr-financing-statements"] || {})[
      "financing-statement"
    ];
    if (financeStatements[0].constructor !== Array)
      financeStatements = [financeStatements];
    const contents = financeStatements.map(statement => {
      const parsedStatement = statement.reduce((accumulator, current) => {
        accumulator[Object.keys(current)[0]] = current[Object.keys(current)[0]];
        return accumulator;
      }, {});
      let collaterals = (parsedStatement.collaterals || {}).collateral || [{}];
      if (collaterals[0].constructor !== Array) collaterals = [collaterals];

      const collateralTypes = collaterals.map(collateral => {
        if (collateral.constructor !== Array) collateral = [collateral];
        const collateralType =
          COLLATERAL_TYPE_MAPPING[collateral[0].type] || null;

        return <p>{collateralType}</p>;
      });

      const collateralDetails = collaterals.map(collateral => {
        if (collateral.constructor !== Array) collateral = [collateral];

        const parsedCollateralType = collateral.reduce(
          (accumulator, current) => {
            if (Object.keys(current)[0] === "collateral-item") {
              let value = accumulator[Object.keys(current)[0]] || [];
              value.push(current[Object.keys(current)[0]]);

              accumulator[Object.keys(current)[0]] = value;
            } else {
              accumulator[Object.keys(current)[0]] =
                current[Object.keys(current)[0]];
            }

            return accumulator;
          },
          {},
        );

        if (parsedCollateralType.hasOwnProperty("collateral-item")) {
          const collateralItems = parsedCollateralType["collateral-item"];

          const contents = collateralItems.map(item => {
            return Object.keys(item).map(key => {
              return (
                <tr>
                  <td>{humanize(key, false)}</td>
                  <td>{item[key]}</td>
                </tr>
              );
            });
          });

          return (
            <table className={styles.table_w20}>
              <tr>
                <th colSpan={2}>
                  {COLLATERAL_TYPE_MAPPING[parsedCollateralType.type]}
                </th>
              </tr>
              {contents}
            </table>
          );
        }

        if (parsedCollateralType.hasOwnProperty("collateral-description")) {
          return (
            <table className={styles.table_w20}>
              <tr>
                <th>{COLLATERAL_TYPE_MAPPING[parsedCollateralType.type]}</th>
              </tr>
              <tr>
                <td>{parsedCollateralType["collateral-description"]}</td>
              </tr>
            </table>
          );
        }
      });

      return (
        <table className={styles.table_w20}>
          <thead>
            <tr>
              <th>Statement ID</th>
              <th>Date Registered</th>
              <th>Secured Party</th>
              <th>Subordinate Statements</th>
              <th>Collateral Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{parsedStatement.id}</td>
              <td>
                {`${parsedStatement["date-registered"] || ""} ${parsedStatement[
                  "time-registered"
                ] || ""}`}
              </td>
              <td>
                {
                  (parsedStatement["secured-parties"] || {})[
                    "secured-party-name"
                  ]
                }
              </td>
              <td>
                {
                  (parsedStatement["subordinate-statements"] || {})[
                    "subordinate-statement-id"
                  ]
                }
              </td>
              <td>{collateralTypes}</td>
            </tr>
            <tr>
              <td colSpan={5}>{collateralDetails}</td>
            </tr>
          </tbody>
        </table>
      );
    });

    return <div className={styles.table_wrapper}>{contents}</div>;
  },

  renderPPSRFinanceTotal() {
    const { data } = this.props;

    const financingStatementTotals =
      data["ppsr-financing-statement-totals"] || {};

    return (
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <thead>
            <tr>
              <th>Total Financing Statements Registered in the Last</th>
              <th>6 Months</th>
              <th>7-12 Months</th>
              <th>13-24 Months</th>
              <th>24 Months+</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                {financingStatementTotals["total-financing-statements-last-6m"]}
              </td>
              <td>
                {
                  financingStatementTotals[
                    "total-financing-statements-last-7to12m"
                  ]
                }
              </td>
              <td>
                {
                  financingStatementTotals[
                    "total-financing-statements-last-13to24m"
                  ]
                }
              </td>
              <td>
                {financingStatementTotals["total-financing-statements-24plusm"]}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },

  renderPPSRCollateralTotal() {
    const { data } = this.props;

    const financingStatementTotals =
      data["ppsr-financing-statement-totals"] || {};
    let collateralTotals =
      (data["ppsr-collateral-totals"] || {})["collateral-total"] || [];

    if (collateralTotals.constructor !== Array) {
      collateralTotals = [collateralTotals];
    }

    const contents = collateralTotals.map(collateral => {
      if (collateral.constructor !== Array) collateral = [collateral];
      const label = COLLATERAL_TYPE_MAPPING[collateral[0].type];

      return (
        <tr>
          <th>{label}</th>
          <td>{collateral[1]}</td>
        </tr>
      );
    });

    return (
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <th>Total Financing Statements Found</th>
              <td>{financingStatementTotals["total-financing-statements"]}</td>
            </tr>
            {contents}
          </tbody>
        </table>
      </div>
    );
  },

  renderPPSRHeader() {
    const { data } = this.props;

    if (!data.hasOwnProperty("ppsr-financing-statement-totals")) return null;

    const financingStatementTotals = data["ppsr-financing-statement-totals"];

    return (
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <td colSpan={4}>
                Some Financing Statements may have been discharged or expired
                at/after the date this report was generated. These statements
                could be restored by a Court Order. It should also be noted that
                there may be a pending Financing Statement that is in the
                process of being registered. Consider conducting another search
                at a later date.
              </td>
            </tr>
            <tr>
              <th>Search ID</th>
              <td>{financingStatementTotals["search-id-number"]}</td>
              <th>Date and Time of Search</th>
              <td>{`${financingStatementTotals["search-date"]} ${
                financingStatementTotals["search-time"]
              }`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },

  renderPPSR: function() {
    const { data } = this.props;

    return (
      <ReviewContainer
        subHeading="Personal Property Securities Register"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
      >
        {this.renderPPSRHeader()}
        {this.renderPPSRCollateralTotal()}
        {this.renderPPSRFinanceTotal()}
        {this.renderPPSRFinanceStatement()}
      </ReviewContainer>
    );
  },

  renderDocumentsRegistered() {
    const { data } = this.props;
    if (!data.hasOwnProperty("documents-registered-full")) return null;

    let documents = data["documents-registered-full"].document;

    if (documents.constructor !== Array) documents = [documents];

    const columns = [
      { header: "Date Registered", accessor: "dateRegistered" },
      { header: "Barcode", accessor: "barcode" },
      { header: "Description", accessor: "description" },
    ];

    const contents = documents.map(doc => {
      return {
        dateRegistered: formatDate(doc["date-registered"], "DD/MM/YYYY"),
        barcode: doc.barcode,
        description: doc["document-description"],
      };
    });

    return (
      <ReviewContainer
        subHeading="Documents Registered"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
      >
        <ReactTable
          data={contents}
          columns={columns}
          showPagination={true}
          showPageSizeOptions={true}
          defaultPageSize={DEFAULT_PAGE_SIZE}
        />
      </ReviewContainer>
    );
  },

  renderShareholders() {
    const { data } = this.props;
    if (!data.hasOwnProperty("company-shareholders")) return null;

    let companyShareholders = data["company-shareholders"].shareholder;

    if (companyShareholders.constructor !== Array) {
      companyShareholders = [companyShareholders];
    }

    const contents = companyShareholders.map(shareholder => {
      let shareholderAddress =
        shareholder["free-format-address"]["address-line"];
      if (shareholderAddress.constructor !== Array) {
        shareholderAddress = [shareholderAddress];
      }

      return (
        <tr>
          <td>{shareholder["shareholder-name"]}</td>
          <td>{shareholder["shares-held"]}</td>
          <td>{shareholderAddress.join(" ")}</td>
        </tr>
      );
    });

    return (
      <ReviewContainer
        subHeading="Shareholders"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Name</th>
                <th>Share Held</th>
                <th>Address</th>
              </tr>
              {contents}
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },

  renderDirectors() {
    const { data } = this.props;
    if (!data.hasOwnProperty("company-officers")) return null;

    let companyOfficers = data["company-officers"].officer;

    if (companyOfficers.constructor !== Array) {
      companyOfficers = [companyOfficers];
    }

    const contents = companyOfficers.map(officer => {
      const individualName = officer["individual-name"],
        officerName = `${individualName["family-name"]}, ${
          individualName["first-given-name"]
        } ${individualName["other-given-name"]}`;

      let officerAddress = officer["free-format-address"]["address-line"];
      if (officerAddress.constructor !== Array) {
        officerAddress = [officerAddress];
      }

      return (
        <tr>
          <td>{officerName}</td>
          <td>{formatDate(officer["date-appointed"], "DD/MM/YYYY")}</td>
          <td>{officerAddress.join(" ")}</td>
        </tr>
      );
    });

    return (
      <ReviewContainer
        subHeading="Directors / Officers / Trustees"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Name</th>
                <th>Date Appointed</th>
                <th>Address</th>
              </tr>
              {contents}
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },

  renderAddresses() {
    const { data } = this.props;

    if (!data.hasOwnProperty("company-addresses")) return null;

    let companyAddresses = data["company-addresses"].address;

    if (companyAddresses[0].constructor !== Array) {
      companyAddresses = [companyAddresses];
    }

    const contents = companyAddresses.map(address => {
      const fullAddress = address.slice(1).map(line => {
        return <p>{line["address-line"]}</p>;
      });

      return (
        <tr>
          <td>{address[0].type}</td>
          <td>{fullAddress}</td>
        </tr>
      );
    });

    return (
      <ReviewContainer
        subHeading="Addresses"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Type</th>
                <th>Address</th>
              </tr>
              {contents}
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },

  renderTradingNames: function() {
    const { data } = this.props;

    if (!data.hasOwnProperty("company-trading-names")) return null;

    let tradingNames = data["company-trading-names"]["trading-name"];

    if (tradingNames.constructor !== Array) {
      if (tradingNames === "-") return null;

      tradingNames = [tradingNames];
    }

    const contents = tradingNames.map(name => {
      return (
        <tr>
          <td>{name}</td>
        </tr>
      );
    });

    return (
      <ReviewContainer
        subHeading="Trading names"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Name</th>
              </tr>
              {contents}
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },

  renderPreviousNames: function() {
    const { data } = this.props;

    if (!data.hasOwnProperty("company-previous-names")) return null;

    let previousNames = data["company-previous-names"]["previous-name"];

    if (previousNames[0].constructor !== Array) {
      previousNames = [previousNames];
    }

    const contents = previousNames.map(name => {
      return (
        <tr>
          <td>{formatDate(name[0]["date-changed"], "DD/MM/YYYY")}</td>
          <td>{name[1]}</td>
        </tr>
      );
    });

    return (
      <ReviewContainer
        subHeading="Previous Names"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Date</th>
                <th>Detail</th>
              </tr>
              {contents}
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },

  renderStatusPrevious: function() {
    const { data } = this.props;

    if (!data.hasOwnProperty("company-previous-status")) return null;

    let previousStatus = data["company-previous-status"]["company-status-code"];

    if (previousStatus[0].constructor !== Array) {
      previousStatus = [previousStatus];
    }

    const contents = previousStatus.map(status => {
      return (
        <tr>
          <td>{formatDate(status[0]["date-changed"], "DD/MM/YYYY")}</td>
          <td>{COMPANY_STATUS_MAPPING[status[1]]}</td>
        </tr>
      );
    });

    return (
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <th>Date</th>
              <th>Previous</th>
            </tr>
            {contents}
          </tbody>
        </table>
      </div>
    );
  },

  renderCurrentStatus() {
    const { data } = this.props;

    if (!data.hasOwnProperty("company-detail-full")) return null;

    const organisationDetails = data["company-detail-full"],
      companyStatus =
        COMPANY_STATUS_MAPPING[
          (organisationDetails["company-status"] || {}).code
        ],
      statusDate = formatDate(
        (organisationDetails["company-status"] || {})["current-status-date"],
        "DD/MM/YYYY",
      );

    return (
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <th>Date</th>
              <th>Current</th>
            </tr>
            <tr>
              <td>{statusDate}</td>
              <td>{companyStatus}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },

  renderStatus: function() {
    return (
      <ReviewContainer
        subHeading="Status"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
      >
        {this.renderCurrentStatus()}
        {this.renderStatusPrevious()}
      </ReviewContainer>
    );
  },

  renderSummary: function() {
    const { data } = this.props;
    if (!data.hasOwnProperty("summary-data-full")) return null;
    if (!data["summary-data-full"].hasOwnProperty("summary")) return null;

    const summary = data["summary-data-full"].summary;

    return (
      <ReviewContainer
        subHeading="Summary"
        content_class="content_wide"
        css_class="block_noborder"
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>{summary[0][0].name}</th>
                <td>{summary[0][1]}</td>
                <th>{summary[8][0].name}</th>
                <td>{summary[8][1]}</td>
              </tr>
              <tr>
                <th>{summary[1][0].name}</th>
                <td>{summary[1][1]}</td>
                <th>{summary[9][0].name}</th>
                <td>{summary[9][1]}</td>
              </tr>
              <tr>
                <th>{summary[2][0].name}</th>
                <td>{summary[2][1]}</td>
                <th>{summary[10][0].name}</th>
                <td>{summary[10][1]}</td>
              </tr>
              <tr>
                <th>{summary[3][0].name}</th>
                <td>{summary[3][1]}</td>
                <th>{summary[11][0].name}</th>
                <td>{summary[11][1]}</td>
              </tr>
              <tr>
                <th>{summary[4][0].name}</th>
                <td>{summary[4][1]}</td>
                <th>{summary[12][0].name}</th>
                <td>{summary[12][1]}</td>
              </tr>
              <tr>
                <th>{summary[5][0].name}</th>
                <td>{summary[5][1]}</td>
                <th>{summary[13][0].name}</th>
                <td>{summary[13][1]}</td>
              </tr>
              <tr>
                <th>{summary[6][0].name}</th>
                <td>{summary[6][1]}</td>
                <th>{summary[14][0].name}</th>
                <td>{summary[14][1]}</td>
              </tr>
              <tr>
                <th>{summary[7][0].name}</th>
                <td>{summary[7][1]}</td>
                <th></th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },

  renderOrganisationalDetails: function() {
    const { data, application } = this.props;

    if (!data.hasOwnProperty("company-detail-full")) return null;

    const organisationDetails = data["company-detail-full"],
      companyStatus =
        COMPANY_STATUS_MAPPING[
          (organisationDetails["company-status"] || {}).code
        ];

    let tradingName = "-";
    if (data.hasOwnProperty("company-trading-names")) {
      let companyTradingName = data["company-trading-names"]["trading-name"];

      if (companyTradingName.constructor !== Array) {
        companyTradingName = [companyTradingName];
      }

      tradingName = companyTradingName[0];
    }

    return (
      <ReviewContainer
        subHeading="Organisation Details"
        content_class="content_wide"
        css_class="block_noborder"
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Registered Name</th>
                <td>{organisationDetails["registered-name"]}</td>
                <th>Consitution Field</th>
                <td>{ynToYesNo(organisationDetails["constitution-filed"])}</td>
              </tr>
              <tr>
                <th>Trading Name</th>
                <td>{tradingName}</td>
                <th>Annual Return Filing</th>
                <td>{organisationDetails["annual-return-filing"]}</td>
              </tr>
              <tr>
                <th>Registration Number</th>
                <td>{organisationDetails["registration-number"]}</td>
                <th>Accounts Filed</th>
                <td>{ynToYesNo(organisationDetails["accounts-filed"])}</td>
              </tr>
              <tr>
                <th>NZ Business Number</th>
                <td>{application.business_number}</td>
                <th>Business Classification</th>
                <td>-</td>
              </tr>
              <tr>
                <th>Date Incorporated</th>
                <td>
                  {formatDate(
                    organisationDetails["incorporation-date"],
                    "DD/MM/YYYY",
                  )}
                </td>
                <th>Number of Shares</th>
                <td>
                  {formatMoney(
                    parseFloat(organisationDetails["number-of-shares"]),
                  )}
                </td>
              </tr>
              <tr>
                <th>Entity Type</th>
                <td>{organisationDetails["entity-type"]}</td>
                <th>Extensive Shareholding</th>
                <td>
                  {ynToYesNo(organisationDetails["extensive-shareholding"])}
                </td>
              </tr>
              <tr>
                <th>Registration</th>
                <td>{organisationDetails["registration"]}</td>
                <th>Current Status</th>
                <td>{displayEmpty(companyStatus)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },

  getIndustryExposure: function() {
    const { data } = this.props;

    if (data.hasOwnProperty("industry-exposure-noncptb")) {
      return data["industry-exposure-noncptb"];
    }

    if (data.hasOwnProperty("industry-exposure")) {
      return data["industry-exposure"];
    }

    return {};
  },

  renderIndustryExposure: function() {
    let industryExposure = this.getIndustryExposure();
    if (!industryExposure && industryExposure.length > 0) {
      return null;
    }

    return (
      <ReviewContainer
        subHeading="Industry Exposure"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
      >
        <p className={styles.remorting_month}>
          <strong>Reporting Month:</strong> {this.getReportingMonthValue()}
        </p>
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <thead>
              <tr>
                <th className={styles.th_left}>CURRENT</th>
                <th className={styles.th_left}>OVERDUE</th>
                <th className={styles.th_left}>TOTAL</th>
                <th className={styles.th_left}>TRADE PAYMENT</th>
                <th className={styles.th_left}>MOVING AVERAGE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.td_center}>
                  {displayEmpty(industryExposure["total-current-amount"])}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(industryExposure["total-overdue-amount"])}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(industryExposure["total-current-plus-overdue"])}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(industryExposure["trade-payment-days"])}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(industryExposure["moving-average-days"])}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h4>Weekly Outstanding Debt</h4>

        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <thead>
              <tr>
                <th className={styles.th_left}>Current</th>
                <th className={styles.th_left}>7 days</th>
                <th className={styles.th_left}>14 days</th>
                <th className={styles.th_left}>21+ days</th>
                <th className={styles.th_left}>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.td_center}>
                  {displayEmpty(
                    industryExposure["weekly-total-current-amount"],
                  )}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(industryExposure["weekly-total-7-days-amount"])}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(
                    industryExposure["weekly-total-14-days-amount"],
                  )}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(
                    industryExposure["weekly-total-21plus-days-amount"],
                  )}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(industryExposure["weekly-total-amount"])}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h4>Monthly Outstanding Debt</h4>

        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <thead>
              <tr>
                <th className={styles.th_left}>Current</th>
                <th className={styles.th_left}>30 days</th>
                <th className={styles.th_left}>60 days</th>
                <th className={styles.th_left}>90+ days</th>
                <th className={styles.th_left}>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={styles.td_center}>
                  {displayEmpty(
                    industryExposure["monthly-total-current-amount"],
                  )}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(
                    industryExposure["monthly-total-30-days-amount"],
                  )}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(
                    industryExposure["monthly-total-60-days-amount"],
                  )}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(
                    industryExposure["monthly-total-90plus-days-amount"],
                  )}
                </td>
                <td className={styles.td_center}>
                  {displayEmpty(industryExposure["monthly-total-amount"])}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className={styles.footnote}>
          The Industry Exposure reflects the incorporated organisation's current
          credit trading with Subscribers reported by them as Trade Suppliers to
          the Commercial Positive Trade Bureau for the previous period
          (Reporting Month) at the date this report was generated. It may not
          reflect the total trading of the organisation. Trade suppliers are not
          specific to the organisation's industry classification. Outstanding
          debt represents the organisation's accounts payable as and when due
          resulting from credit purchases in the Reporting Month. Credit terms
          of Trade Suppliers may vary. Trade Payment is based on a weighted
          calculation reducing the outstanding debt to percentages with the
          amount of credit days taken and previous calendar months of trading
          with a higher penalty placed for balances outstanding in 21+ and 90+
          days than balances outstanding for 7 and 30 days respectively. The
          Payment Trend (Moving Average) is calculated as a running total of the
          Trade Payment for the previous three or more months disclosing the
          trade Payment Trend.
        </p>
      </ReviewContainer>
    );
  },

  getReportingMonthValue: function() {
    let industryExposure = this.getIndustryExposure();

    if (industryExposure.hasOwnProperty("reporting-month")) {
      return industryExposure["reporting-month"];
    }

    return null;
  },

  renderReportingMonth: function() {
    let reportingMonthValue = this.getReportingMonthValue();
    if (reportingMonthValue != "-") {
      return (
        <div className={styles.reporting_month}>
          <p>The average time taken to make payment in {reportingMonthValue}</p>
        </div>
      );
    }
    return null;
  },

  getMovingAverageDays: function() {
    let industryExposure = this.getIndustryExposure();

    if (industryExposure.hasOwnProperty("moving-average-days")) {
      return industryExposure["moving-average-days"];
    }

    return null;
  },

  renderMovingAverageDays: function() {
    let movingAverageDaysValue = this.getMovingAverageDays();
    if (movingAverageDaysValue !== "-") {
      return (
        <div className={styles.moving_average_days}>
          {movingAverageDaysValue}
        </div>
      );
    }
    return null;
  },

  getTradePaymentValue: function() {
    let industryExposure = this.getIndustryExposure();

    if (industryExposure.hasOwnProperty("trade-payment-days")) {
      return industryExposure["trade-payment-days"];
    }

    return null;
  },

  renderTradePayment: function() {
    let tradePaymentValue = this.getTradePaymentValue();
    if (!tradePaymentValue) {
      return null;
    }
    return <div className={styles.trade_payment_days}>{tradePaymentValue}</div>;
  },

  getInitialState() {
    return {
      isAllExpanded: false,
    };
  },

  _expandAll() {
    this.setState({ isAllExpanded: !this.state.isAllExpanded });
  },

  _expandAllButton() {
    let message = "+ expand all";
    if (this.state.isAllExpanded) message = "- collapse all";

    return (
      <Button
        small
        text={message}
        style={{ margin: "1em 0 1em 66px" }}
        handleClick={this._expandAll}
      />
    );
  },

  render: function() {
    const { data, check_attributes } = this.props;
    if (!data && ["completed", "errored"].includes(check_attributes.status)) {
      const errorMessage =
        check_attributes.raw_data ||
        "Oops! Something went wrong, please contact 1Centre for more details.";
      return (
        <div>
          <ReviewContainer
            subHeading={"Equifax"}
            content_class="content_wide"
            css_class="block_noborder"
          >
            <p>{errorMessage}</p>
            <p>
              Contact{" "}
              <a href="mailto:support@1centre.com">support@1centre.com</a> or
              live chat with us.
            </p>
          </ReviewContainer>
        </div>
      );
    }

    return (
      <div>
        <img
          className={styles.veda_logo}
          src={logo}
          alt="Equifax"
          width="277.9"
          height="79.1"
        />
        <h2 className={styles.heading_large}>COMMERCIAL CHECK</h2>
        <ReviewContainer
          content_class="content_wide"
          css_class="block_noborder"
        >
          <div className={styles.overview}>
            <div className={styles.overview_details}>
              <div className={styles.liner}>
                <div className={styles.overview_copy}>
                  <p>This business is taking</p>
                </div>
                <div className={styles.overview_value}>
                  {this.renderMovingAverageDays()}
                </div>
                <div className={styles.overview_copy}>
                  <p>to make a payment</p>
                </div>
              </div>
            </div>
            <div className={styles.overview_rating}>
              <div className={styles.liner}>
                {this.renderReportingMonth()}
                <div className={styles.overview_rating_graph}>
                  <div
                    className={styles.overview_rating_indicator}
                    style={{
                      left: (100 / 120) * this.getTradePaymentValue() + "%",
                    }}
                  >
                    {this.renderTradePayment()}&nbsp;days
                  </div>
                  <div className={styles.overview_rating_graph_bg} />
                  <span className={styles.overview_rating_graph_0}>0</span>
                  <span className={styles.overview_rating_graph_30}>30</span>
                  <span className={styles.overview_rating_graph_60}>60</span>
                  <span className={styles.overview_rating_graph_90}>90</span>
                  <span className={styles.overview_rating_graph_120}>120+</span>
                </div>
              </div>
            </div>
          </div>
        </ReviewContainer>

        {this.renderOrganisationalDetails()}
        {this.renderSummary()}
        {this._expandAllButton()}
        {this.renderIndustryExposure()}
        {this.renderStatus()}
        {this.renderTradingNames()}
        {this.renderPreviousNames()}
        {this.renderAddresses()}
        {this.renderDirectors()}
        {this.renderShareholders()}
        {this.renderDocumentsRegistered()}
        {this.renderPPSR()}
        {this.renderEnquiries()}
        {this._expandAllButton()}
      </div>
    );
  },
});

export default VedaCommercialCheck;
