import get from "lodash.get";
import historyStyles from "modules/applications/components/credit-checks/credit-works/css/History.css";
import { SubHeading } from "modules/applications/components/credit-checks/credit-works/v2/Container/styles";
import styles from "modules/applications/components/css/CommonReviewBusiness.css";
import moment from "moment";
import React, { ReactElement } from "react";

type CompanyRegistration = {
  companyName: string,
  companyNumber: string,
  dateIncorparated: string,
  entityTypeDescription: string,
  hasConstitutionFiled: boolean,
  numberOfShares: string,
  nzbn: string,
  status: {
    description: string,
  },
}

type Props = {
  data: CompanyRegistration,
}

function CompanyRegistration(props: Props): ReactElement|null {
  const { data } = props;

  if (!data) {
    return null;
  }

  const {
    companyName,
    companyNumber,
    dateIncorparated,
    entityTypeDescription,
    hasConstitutionFiled,
    numberOfShares,
    nzbn,
    status,
  } = data;

  return (
    <div className={historyStyles.container}>
      <SubHeading>Company Registration</SubHeading>
      <table className={styles.table_w20}>
        <tbody>
          <tr>
            <th>Company Number</th>
            <td>{companyNumber}</td>
          </tr>
          <tr>
            <th>Company Name</th>
            <td>{companyName}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>
              {get(status, "description")}
            </td>
          </tr>
          <tr>
            <th>Date Incorporated</th>
            <td>
              {moment(dateIncorparated).format(
                "DD-MMM-YYYY",
              )}
            </td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{entityTypeDescription}</td>
          </tr>
          <tr>
            <th>Constitution Filed</th>
            <td>{hasConstitutionFiled ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <th>NZBN</th>
            <td>{nzbn}</td>
          </tr>
          <tr>
            <th>Number of Shares</th>
            <td>
              {parseInt(
                numberOfShares,
              ).toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CompanyRegistration;
