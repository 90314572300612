import AutoSuggest from "modules/shared/components/widgets/interactive/AutoSuggest";
import CountryCodeDropdown from "modules/shared/components/widgets/static/CountryCodeDropdown";
import EmailInput from "../../../shared/components/inputs/EmailInput";
import Radiobox from "modules/shared/components/inputs/Radiobox";
import React from "react";
import TextInput from "../../../shared/components/inputs/TextInput";
import isBlank from "utils/isBlank";
import styles from "./css/Business.css";
import getIsThisYouLabel from "utils/getIsThisYouLabel";
import get from "lodash.get";

class BusinessPartyDetailsForm extends React.Component {
  isEmailValid = () => {
    const { values } = this.props;
    return this.refs.email._validate(values["email"]);
  };

  isApplicantText = () => {
    const isApplicant = this.props.values.is_applicant;
    if (isBlank(isApplicant)) {
      return "";
    }

    return isApplicant ? "Yes" : "No";
  };

  renderSignatoryApplicant = () => {
    const {
      application,
      canSelectSignatoryApplicant,
      errors,
      handleIsApplicantChange,
      values,
    } = this.props;
    if (!canSelectSignatoryApplicant) return;

    const { first_name: firstName, last_name: lastName } = values;
    const isThisYouLabel = getIsThisYouLabel({ firstName, lastName });

    return (
      <div className={styles.is_this_you_radio_container}>
        <Radiobox
          id="is_applicant"
          name="is_applicant"
          error={errors["is_applicant"]}
          handleChange={handleIsApplicantChange}
          label={isThisYouLabel}
          value={this.isApplicantText()}
          radioList={["Yes", "No"]}
          disabled={
            get(application, "attributes.submission_status", "") === "complete"
          }
        />
      </div>
    );
  };

  render() {
    const {
      emailChangeHandler,
      entity_type,
      errors,
      handleBlur,
      handleChange,
      handlePostalAddressChange,
      handlePostalAddressClick,
      handleResidentialChange,
      handleResidentialClick,
      handleResidentialTypeChange,
      onCountryCodeChangeHandler,
      values,
    } = this.props;

    return (
      <div>
        <div className={styles.party_details_col}>
          <TextInput
            id="first_name"
            name="first_name"
            error={errors["first_name"]}
            handleChange={handleChange}
            handleBlur={handleBlur}
            label="Legal first name"
            required={true}
            value={values["first_name"]}
          />
          <TextInput
            id="middle_name"
            name="middle_name"
            error={errors["middle_name"]}
            handleChange={handleChange}
            handleBlur={handleBlur}
            label="Middle name"
            required={false}
            value={values["middle_name"]}
          />
          <TextInput
            id="last_name"
            name="last_name"
            error={errors["last_name"]}
            handleChange={handleChange}
            handleBlur={handleBlur}
            label="Last name"
            required={true}
            value={values["last_name"]}
          />
          {entity_type !== "company" && (
            <EmailInput
              id="email"
              name="email"
              type="email"
              ref="email"
              handleChange={emailChangeHandler}
              handleBlur={emailChangeHandler}
              label="Email"
              required={true}
              value={values["email"]}
              disabled={values.is_applicant}
            />
          )}

          {entity_type === "personal" && (
            <div className={styles.row}>
              <div className={styles.contact_country_code}>
                <CountryCodeDropdown
                  key="contact_country_code"
                  countryCodeId={"contact_country_code"}
                  label={"Country"}
                  countryCodeError={errors["contact_country_code"]}
                  countryCodeValue={values["contact_country_code"]}
                  required={true}
                  handleChange={onCountryCodeChangeHandler}
                />
              </div>
              <div className={styles.contact_phone_number}>
                <TextInput
                  key="contact_phone_number"
                  id="contact_phone_number"
                  name="contact_phone_number"
                  type="tel"
                  error={errors["contact_phone_number"]}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  label="Phone number"
                  required={true}
                  value={values["contact_phone_number"]}
                />
              </div>
            </div>
          )}
        </div>
        {entity_type === "personal" && (
          <div className={styles.party_details_col}>
            <TextInput
              id="previous_last_name"
              name="previous_last_name"
              error={errors["previous_last_name"]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label="Previous Last name"
              required={false}
              helper_text={"If married"}
              value={values["previous_last_name"]}
            />
            <div className={styles.row}>
              <div className={styles.party_details_col}>
                <TextInput
                  id="employer"
                  name="employer"
                  error={errors["employer"]}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  label="Employer"
                  required={false}
                  helper_text={"If applicable"}
                  value={values["employer"]}
                />
              </div>
              <div className={styles.party_details_col}>
                <TextInput
                  id="position"
                  name="position"
                  error={errors["position"]}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  label="Position"
                  required={false}
                  helper_text={"If applicable"}
                  value={values["position"]}
                />
              </div>
            </div>
            <AutoSuggest
              id="residential_address"
              label="Residential Address"
              value={
                (values["residential_address"] || { full_address: "" })
                  .full_address
              }
              loading={values["residential_address_loading"]}
              suggest_items={values["residential_address_list"] || {}}
              error={errors["residential_address"]}
              handleChange={handleResidentialChange}
              handleClick={handleResidentialClick}
              handleBlur={handleBlur}
              required={true}
              css_class="address_lookup"
              selected_item={
                values.residential_api_id
                  ? values["residential_address"].full_address
                  : ""
              }
              force_focus={true}
            />
            <div className={styles.residential_type}>
              <Radiobox
                id="residential_type"
                name="residential_type"
                error={errors["residential_type"]}
                handleChange={handleResidentialTypeChange}
                label="Residental Type"
                value={values["residential_type"]}
                radioList={["rented", "owned"]}
              />
            </div>
            <AutoSuggest
              id="person_postal_address"
              label="Postal Address"
              value={
                (values["person_postal_address"] || { full_address: "" })
                  .full_address
              }
              loading={values["person_postal_address_loading"]}
              suggest_items={values["person_postal_address_list"] || {}}
              error={errors["person_postal_address"]}
              handleChange={handlePostalAddressChange}
              handleClick={handlePostalAddressClick}
              handleBlur={handleBlur}
              required={false}
              css_class="address_lookup"
              selected_item={
                values.person_postal_address_api_id
                  ? values["person_postal_address"].full_address
                  : ""
              }
            />
          </div>
        )}
        {this.renderSignatoryApplicant()}
      </div>
    );
  }
}

export default BusinessPartyDetailsForm;
