import { amountTypeValue, countTypeValue } from "./helpers/summary";

import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { displayEmpty } from "utils/formatting";
import { enquiryResponseData } from "./helpers/reportHelpers";
import { organisationStatusType } from "./helpers/codeTable";
import { stringToSimpleDate } from "utils/dateFormatter";
import styles from "../../../css/CommonReviewBusiness.css";

var CompanyIdentity = createClass({
  _displayAddress(data) {
    if (data === null || data === undefined) return displayEmpty("");

    let address = data.address_lines || {};

    return [
      address.address_prefix,
      address.street_details,
      address.locality_details,
      address.state,
      address.postcode,
    ].join(" ");
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      companyIdentity = response.company_identity,
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Company Identity"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>ACN</th>
                <td>
                  {displayEmpty(companyIdentity.australian_company_number)}
                </td>
                <th>Principal Activity</th>
                <td>{displayEmpty(companyIdentity.nature_of_business)}</td>
              </tr>
              <tr>
                <th>ABN</th>
                <td>
                  {displayEmpty(companyIdentity.australian_business_number)}
                </td>
                <th>Registered Office</th>
                <td>
                  {this._displayAddress(companyIdentity.registered_office)}
                </td>
              </tr>
              <tr>
                <th>Status</th>
                <td>
                  {organisationStatusType(
                    (companyIdentity.organisation_status || {}).type,
                  )}
                </td>
                <th>Start Date</th>
                <td>
                  {stringToSimpleDate(
                    companyIdentity.registered_office.first_reported_date,
                  )}
                </td>
              </tr>
              <tr>
                <th>Incorporation Date</th>
                <td>
                  {stringToSimpleDate(
                    (companyIdentity.incorporation || {}).incorporation_date,
                  )}
                </td>
                <th>ASIC Document No</th>
                <td>
                  {displayEmpty(
                    (companyIdentity.registered_office.document_details || {})
                      .document_number,
                  )}
                </td>
              </tr>
              <tr>
                <th>Incorporation State</th>
                <td>
                  {displayEmpty(
                    (companyIdentity.incorporation || {}).incorporation_state,
                  )}
                </td>
                <th>Principal Place of Business</th>
                <td>
                  {this._displayAddress(
                    companyIdentity.principal_place_of_business,
                  )}
                </td>
              </tr>
              <tr>
                <th>ASIC Document No (Company)</th>
                <td>
                  {displayEmpty(
                    (
                      companyIdentity.asic_company_details.document_details ||
                      {}
                    ).document_number,
                  )}
                </td>
                <th>Start Date</th>
                <td>
                  {stringToSimpleDate(
                    companyIdentity.principal_place_of_business
                      .first_reported_date,
                  )}
                </td>
              </tr>
              <tr>
                <th>Name Start Date</th>
                <td>
                  {stringToSimpleDate(
                    companyIdentity.organisation_name_start_date,
                  )}
                </td>
                <th>ASIC Document No</th>
                <td>
                  {displayEmpty(
                    (
                      companyIdentity.principal_place_of_business
                        .document_details || {}
                    ).document_number,
                  )}
                </td>
              </tr>
              <tr>
                <th>Last ASIC Search Date</th>
                <td>{stringToSimpleDate(companyIdentity.last_search_date)}</td>
                <th>Company Type</th>
                <td>{displayEmpty(companyIdentity.organisation_type)}</td>
              </tr>
              <tr>
                <th>File Number</th>
                <td>
                  {displayEmpty(companyIdentity.bureau_info.bureau_reference)}
                </td>
                <th>Class</th>
                <td>
                  {displayEmpty(
                    companyIdentity.asic_company_details.organisation_class,
                  )}
                </td>
              </tr>
              <tr>
                <th>File Create Date</th>
                <td>
                  {stringToSimpleDate(
                    companyIdentity.bureau_info.file_creation_date,
                  )}
                </td>
                <th>Sub-Class</th>
                <td>
                  {displayEmpty(
                    companyIdentity.asic_company_details.organisation_subclass,
                  )}
                </td>
              </tr>
              <tr>
                <th>ACN Review Date</th>
                <td>
                  {stringToSimpleDate(
                    companyIdentity.asic_company_details
                      .australian_company_number_review_date,
                  )}
                </td>
                <th>Previous State Number</th>
                <td>
                  {displayEmpty(
                    companyIdentity.asic_company_details.previous_state_number,
                  )}
                </td>
              </tr>
              <tr>
                <th>Number of Employees</th>
                <td>-</td>
              </tr>
              <tr>
                <th>Annual Sales (AUD'000)</th>
                <td>{countTypeValue(summaryEntry, "Annual_Returns")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },
});

export default CompanyIdentity;
