/* Components */
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { Link } from "react-router";
import { forgotPasswordUpdateUserPassword } from "../actions";
import { checkPassStrength } from "utils/password";
import get from "lodash.get";
import isBlank from "utils/isBlank";
import { Header } from "modules/new-applications/components/NewLogin/index.js";
import { useForm } from "react-hook-form";
import * as yup from "yup";

function validatePasswordMatch(value) {
  const { password } = this.parent;

  return value === password;
}

const SCHEMA = yup.object().shape({
  password: yup.string().min(8, "Password must be at least 8 characters long"),
  confirmPassword: yup
    .string()
    .test("confirm-password", "Passwords must match", validatePasswordMatch),
});

function getRedirectPath({ email, location }) {
  const redirect = get(location, "query.redirect");
  const defaultRedirectPath = `user/logout?e=${email}`;

  if (isBlank(redirect)) {
    return defaultRedirectPath;
  }

  if (redirect.startsWith("/websitebutton")) {
    return redirect;
  }

  return `${defaultRedirectPath}&redirect=${redirect}`;
}

function PasswordForm(props) {
  const { email, dispatch, location } = props;
  const { errors, handleSubmit, register } = useForm({
    mode: "onBlur",
    validationSchema: SCHEMA,
  });

  const onSubmit = data => {
    const { password } = data;
    const path = getRedirectPath({ email, location });

    dispatch(
      forgotPasswordUpdateUserPassword(password, () => {
        browserHistory.push(path);
      }),
    );
  };

  return (
    <div className="container has-text-centered mt-100">
      <div className="has-text-centered columns pt-1">
        <div className="column container has-text-centered box mb-5 is-two-thirds-tablet is-one-quarter-desktop">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field px-4">
              <div className="has-text-grey-darker mt-4 is-size-large has-text-weight-normal m5-4">
                Pick a password
              </div>

              <div className="control mt-6 has-icons-left has-icons-right">
                <span className="icon is-small is-left ">
                  <i className="fa fa-lock"></i>
                </span>
                <input
                  className="input is-primary is-focused has-text-weight-normal"
                  name="password"
                  type="password"
                  placeholder="password"
                  required
                  ref={register}
                />
                <p className="has-text-left mt-4 has-text-weight-normal has-text-danger is-size-small">
                  {get(errors, "password.message", <span>&nbsp;</span>)}
                </p>
              </div>

              <div className="control mt-3 has-icons-left has-icons-right">
                <span className="icon is-small is-left ">
                  <i className="fa fa-lock"></i>
                </span>
                <input
                  className="input is-primary is-focused has-text-weight-normal"
                  name="confirmPassword"
                  type="password"
                  placeholder="confirm password"
                  required
                  ref={register}
                />
                <p className="has-text-left mt-4 has-text-weight-normal has-text-danger is-size-small">
                  {get(errors, "confirmPassword.message", <span>&nbsp;</span>)}
                </p>
              </div>
            </div>
            <div className="field mt-4">
              <div className="control">
                <button className="button is-primary is-rounded" type="submit">
                  Set password
                </button>
              </div>
            </div>
          </form>
          <div className="field is-vcentered">
            <div className="column">
              <Link
                to="/"
                className="is-primary has-text-centered has-text-weight-normal"
              >
                Go Back
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SetPassword(props) {
  return (
    <div className="hero">
      <div className="hero-body">
        <div className="container is-widescreen">
          <Header />
          <PasswordForm {...props} />
        </div>
      </div>
    </div>
  );
}

export default connect((state, ownProps) => {
  var strength = 0;
  if (state.register.password.length > 0) {
    strength = checkPassStrength(state.register.password);
  }
  let email;
  if (state.current_user.data.data) {
    email = state.current_user.data.data.attributes.email;
  }

  return {
    password: state.register.password,
    confirm_password: state.register.confirm_password,
    strength: strength,
    email: email,
    missing_user: !(
      state.current_user.data.data && state.current_user.data.data.id
    ),
  };
})(SetPassword);
