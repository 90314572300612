import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import React from "react";

export default class PopperList extends React.Component {
  constructor() {
    super();
    this.state = {
      showlist: false,
    };
  }

  renderListItems() {
    const { options, handleSelect } = this.props;
    const items = [];
    options.forEach(option => {
      items.push(
        <ListItem button onClick={() => handleSelect(option.value)}>
          <ListItemText primary={option.label} />
        </ListItem>,
      );
    });
    return items;
  }

  render() {
    const { anchorEl, open, handleBlur } = this.props;

    return (
      <Popover open={open} anchorEl={anchorEl} onClose={handleBlur}>
        <List component="nav" aria-label="secondary mailbox folders">
          {this.renderListItems()}
        </List>
      </Popover>
    );
  }
}
