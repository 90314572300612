import { FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import styles from "modules/shared/components/inputs/css/BorderedSelect.css";
import React from "react";

function formControlRootClasses(props) {
  const { disabled, withBottomMargin } = props;
  const defaultClasses = [styles.form_control];

  if (withBottomMargin) {
    defaultClasses.push(styles.with_bottom_margin);
  }

  if (disabled) {
    defaultClasses.push(styles.form_control_disabled);
  }

  return defaultClasses.join(" ");
}

function selectRootClasses(props) {
  const { isCompact } = props;

  if (isCompact) {
    return styles.select_compact;
  }
}

export default function BorderedSelect(props) {
  const {
    error,
    formControlCustomProps,
    helperText,
    label,
    options,
    placeholder,
    selectCustomProps,
    variant,
    required,
    rawOptions,
    ...selectProps
  } = props;

  const processedLabel = required ? `${label}*` : label;

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      error={error}
      className={formControlRootClasses({
        ...defaultFormControlCustomProps,
        ...formControlCustomProps,
        disabled: props.disabled,
      })}
    >
      <InputLabel
        classes={{
          root: styles.label_root,
          shrink: styles.label_shrink,
        }}
      >
        {processedLabel}
      </InputLabel>
      <Select
        variant={variant}
        label={processedLabel}
        displayEmpty
        classes={{
          root: selectRootClasses({
            ...defaultSelectCustomProps,
            ...selectCustomProps,
          }),
        }}
        {...selectProps}
        renderValue={selected => {
          if (placeholder && !selected) {
            return placeholder;
          }

          const selectedOption = options.find(({ value }) => value === selected);

          return selectedOption && selectedOption.label;
        }}
      >
        {placeholder ? (
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
        ) : []}
        {!rawOptions && options.map(option => (
          <MenuItem
            className={styles.item}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
        {rawOptions ? rawOptions : []}
      </Select>
      <FormHelperText
        classes={{
          root: error ? styles.form_error_text_root : styles.form_helper_text_root,
        }}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}

const defaultSelectCustomProps = {
  isCompact: false,
};

const defaultFormControlCustomProps = {
  withBottomMargin: true,
};

BorderedSelect.defaultProps = {
  error: false,
  formControlCustomProps: defaultFormControlCustomProps,
  selectCustomProps: defaultSelectCustomProps,
};
