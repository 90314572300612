// Renamed from legacy ManageUserKeyContacts.js
/* Import libraries */
import React from "react";
import { connect } from "react-redux";

/* Import actions */
import {
  loadUserApplicationContacts,
  loadUserConnectionContacts,
  loadUsersByEntity,
  updateConnectionKeyContact,
} from "../actions";
import { publishMessage } from "../../notifications/actions";

/* Import CSS */
import styles from "./css/ManageUserKeyContacts.css";

/* Import Components */
import AdminTitle from "modules/shared/components/widgets/static/AdminTitle";
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import ReassignKeyContactForm from "modules/manage-users/forms/ReassignKeyContactForm";
import Pagination from "modules/shared/components/widgets/interactive/Pagination";
import Button from "modules/shared/components/inputs/Button";
import InformBusinessModal from "modules/manage-users/components/InformBusinessModal";

class ManageNonUserKeyContacts extends React.Component {
  state = {
    search: "",
    tab: "applications",
    applications_page_number: 1,
    connections_page_number: 1,
    applications_per_page: 25,
    connections_per_page: 25,
    contact_changes: {},
    showModal: false,
    notify: false,
  };

  UNSAFE_componentWillMount() {
    const { user_id, dispatch } = this.props;
    dispatch(loadUserApplicationContacts(user_id));
    dispatch(loadUserConnectionContacts(user_id));
    dispatch(loadUsersByEntity());
  };

  fetchData = (page, per_page, key_word) => {
    const { tab } = this.state;
    const { user_id, dispatch } = this.props;
    const options = { page: page, per_page: per_page, key_word: key_word };

    if (tab === "applications") {
      dispatch(loadUserApplicationContacts(user_id, options));
    } else {
      dispatch(loadUserConnectionContacts(user_id, options));
    }
  }

  changeCurrentPage = (page) => {
    const { tab, per_page, search } = this.state;
    if (tab === "applications") {
      this.setState({
        applications_page_number: page,
      });
    } else if (tab === "connections") {
      this.setState({
        connections_page_number: page,
      });
    }
    this.fetchData(page, per_page, search);
  };

  changePerPage = (per_page) => {
    const { tab, page, search } = this.state;
    if (tab === "applications") {
      this.setState({
        applications_per_page: per_page,
      });
    } else if (tab === "connections") {
      this.setState({
        connections_per_page: per_page,
      });
    }
    this.fetchData(page, per_page, search);
  }

  searchHandleChange = (e) => {
    const { page, per_page } = this.state;
    this.setState({ search: e.target.value });
    this.fetchData(page, per_page, e.target.value);
  };

  notifyCounterParty = (k, details) => {
    const { tab } = this.state;
    const { applications, connections, users, dispatch } = this.props;

    let record =
      tab === "applications"
        ? applications.find(app => {
            return app.id === k;
          })
        : connections.find(conn => {
            return conn.id === k;
          });

    let user = users.find(v => {
      return v.value === details.userId;
    });

    dispatch(
      publishMessage({
        text: `<strong>${user.label}</strong> has been assigned as the new key contact for your trade account with <strong>${record.attributes.trading_name}</strong>`,
        style: "connection",
        channel: "entity",
        channel_target: record.attributes.entity_id,
        intent: `view_${record.attributes.role}_connection`,
        intent_target: record.id,
      }),
    );
  };

  updateAssignKeyContact = (notify) => {
    const { user_id, dispatch } = this.props;
    const { contact_changes, tab } = this.state;

    let finished = 0;
    Object.keys(contact_changes).forEach((k, index, array) => {
      dispatch(
        updateConnectionKeyContact(
          tab,
          contact_changes[k].role,
          k,
          contact_changes[k].userId,
          () => {
            finished++;
            if (notify) {
              this.notifyCounterParty(k, contact_changes[k]);
            }

            if (finished === array.length) {
              dispatch(loadUserApplicationContacts(user_id));
              dispatch(loadUserConnectionContacts(user_id));
              this.setState({ contact_changes: {} });
            }
          },
        ),
      );
    });
  }

  tryUpdate = () => {
    const { contact_changes } = this.state;
    if (Object.keys(contact_changes).length > 0) {
      this.setState({ showModal: true });
    }
  }

  assignKeyContact = (recordId, userId, role) => {
    this.setState({
      contact_changes: Object.assign(this.state.contact_changes, {
        [recordId]: { userId: userId, role: role },
      }),
    });
  }

  handleModalDismiss = () => {
    this.setState({ showModal: false });
  }

  handleModalYes = () => {
    this.setState({ showModal: false });
    this.updateAssignKeyContact(true);
  }

  handleModalNo = () => {
    this.setState({ showModal: false });
    this.updateAssignKeyContact(false);
  }

  contactMarkUp = () => {
    const { tab } = this.state;
    const { applications, connections, users } = this.props;
    let records = [];
    if (tab === "applications") {
      records = applications;
    } else {
      records = connections;
    }

    let contactElms = [];
    records.forEach((v, i) => {
      let tradingName = v.attributes.trading_name;
      if (v.attributes.archived) {
        tradingName = (
          <span>
            {tradingName} - <em>archived</em>
          </span>
        );
      }

      contactElms.push(
        <div key={`contact-${v.id}`}>
          <div className={styles.trading_name}>
            <span className={styles.name}>{tradingName}</span>
          </div>
          <div className={styles.dropdown}>
            <ReassignKeyContactForm
              users={users}
              assignKeyContact={this.assignKeyContact}
              recordId={v.id}
              role={v.attributes.role}
            />
          </div>
        </div>,
      );
    });
    return contactElms;
  }

  render() {
    const {
      tab,
      applications_page_number,
      connections_page_number,
      applications_per_page,
      connections_per_page,
      showModal,
      contact_changes,
    } = this.state;
    const {
      saving,
      loading,
      total_applications,
      total_connections,
    } = this.props;
    const contact = this.contactMarkUp();
    const page_setting =
      tab === "applications"
        ? {
            total: total_applications,
            page: applications_page_number,
            per_page: applications_per_page,
          }
        : {
            total: total_connections,
            page: connections_page_number,
            per_page: connections_per_page,
          };
    let informModal = showModal ? (
      <InformBusinessModal
        handleDismiss={this.handleModalDismiss}
        handleYes={this.handleModalYes}
        handleNo={this.handleModalNo}
        count={Object.keys(contact_changes).length}
      />
    ) : (
      ""
    );

    let loadingElement = loading ? (
      <SimpleLoader css_class={"loader_relative"} />
    ) : (
      ""
    );

    return (
      <div className={styles.row}>
        {informModal}
        <div className={styles.columns}>
          <AdminTitle text="Assign Team Members">
            <span className={[styles.controls, 'ml-4'].join(' ')}>
              <Button
                small={true}
                text="update"
                handleClick={this.tryUpdate}
                disableOnLoading={true}
                loading_text={"Saving"}
                loading={saving}
              />
            </span>
          </AdminTitle>
          <div className={styles.contacts_row}>
            <div className={styles.contact_items}>
              <section className={styles.row}>
                <div className={styles.full_col}>
                  <div className={styles.search}>
                    <div className={styles.search_input}>
                      <label className={styles.search_label}>
                        Search business name
                      </label>
                      <input
                        name="search"
                        onChange={this.searchHandleChange}
                        value={this.state.search}
                      />
                    </div>
                    <div>
                      <Button
                        text="applications"
                        small={true}
                        grey={tab !== "applications"}
                        style={{ marginRight: "1em" }}
                        handleClick={() =>
                          this.setState({ tab: "applications" })
                        }
                      />
                      <Button
                        text="connections"
                        small={true}
                        grey={tab !== "connections"}
                        handleClick={() =>
                          this.setState({ tab: "connections" })
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.full_col}>
                      <div className={styles.trading_name}>
                        <h3 className={styles.title}>Key contact for:</h3>
                      </div>
                      <div className={styles.dropdown}>
                        <h3 className={styles.title}>Reassign to</h3>
                      </div>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.full_col}>
                      <span className={styles.line} />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.list}>{contact}</div>
                  </div>
                </div>
                <Pagination
                  dataLength={page_setting.total}
                  currentPage={page_setting.page}
                  todosPerPage={page_setting.per_page}
                  optionViewPage={defaults.viewPageOptions}
                  handleClick={this.changeCurrentPage}
                  resourceName={tab}
                  handleClickTodosPerPage={this.changePerPage}
                />
                {loadingElement}
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const defaults = {
  viewPageOptions: [{ label: "25", value: 25 }, { label: "50", value: 50 }],
};

export default connect((state) => {
  const users = state.manage_users.user_list_for_assign_key_contact.map(
    user => {
      return { value: user.id, label: user.attributes.full_name };
    },
  );
  return {
    loading:
      state.manage_users.user_applications_loading ||
      state.manage_users.user_connections_loading,
    users,
    applications: state.manage_users.user_applications,
    total_applications: state.manage_users.total_user_applications,
    connections: state.manage_users.user_connections,
    total_connections: state.manage_users.total_user_connections,
    saving: state.manage_users.saving,
  };
})(ManageNonUserKeyContacts);
