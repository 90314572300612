import isEmpty from "lodash.isempty";
import SignatoryModel from "models/SignatoryModel";
import FixedContent from "modules/shared/components/containers/FixedContent";
import GridContent from "modules/shared/components/containers/GridContent";
import LabeledContent from "modules/shared/components/widgets/static/LabeledContent";
import React from "react";

function Signatory(props) {
  const { index, signatory } = props;

  return (
    <FixedContent header={`Signatory ${index}`}>
      <GridContent>
        <div>
          <LabeledContent label="Name" content={signatory.name} />
          <LabeledContent
            label="Agreed to be a signatory"
            content={signatory.approvedString}
          />
        </div>
        <div>
          <LabeledContent label="Email" content={signatory.email} />
        </div>
      </GridContent>
    </FixedContent>
  );
}

export default function Signatories(props) {
  const { application } = props;
  if (!application && !application.id) {
    return;
  }

  let signatories = application.signatories;
  if (isEmpty(signatories)) {
    signatories = [new SignatoryModel()];
  }

  return signatories.map((signatory, index) => (
    <Signatory
      key={`signatory-${index + 1}`}
      signatory={signatory}
      index={index + 1}
    />
  ));
}
