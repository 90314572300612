import React, { Component } from "react";

import IdentityConfirm from "modules/identity/components/IdentityConfirm";
import SignatureContainer from "./SignatureContainer";
import styles from "./css/SignatureContainer.css";

export default class SignatureIdentityConfirm extends Component {
  render() {
    const {
      backButton,
      handleComplete,
      isCardholder,
      nextButton,
      setPageValidationStartFinish,
      supplierLogo,
      supplierTradingName,
    } = this.props;

    return (
      <SignatureContainer
        supplierLogo={supplierLogo}
        supplierTradingName={supplierTradingName}
      >
        <div className={styles.sectionContainer}>
          <div className={styles.content}>
            <IdentityConfirm
              isCardholder={isCardholder}
              handleComplete={handleComplete}
              setPageValidationStartFinish={setPageValidationStartFinish}
            />
          </div>
          <div className={styles.buttonsContainer}>
            {backButton}
            {nextButton}
          </div>
        </div>
      </SignatureContainer>
    );
  }
}
