import axios from "./axios";;

let Websitebuttons = function(config) {
  config.timeout = 90000;
  this.axios = axios(config);
  this.type = "website_buttons";
};

Websitebuttons.prototype.createForConsumerFromWebsitebutton = function(
  qr_mode,
  attributes,
  supplier_id,
  supplier_trading_name_id,
  success,
  error,
) {
  return this.create(
    {
      ...attributes,
      action_type: qr_mode
        ? "application_websitebutton_qr"
        : "application_websitebutton",
      supplier_id: supplier_id,
      supplier_trading_name_id: supplier_trading_name_id,
    },
    success,
    error,
  );
};

Websitebuttons.prototype.create = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

Websitebuttons.prototype.updateApplication = function(
  id,
  attributes,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: {
          ...attributes,
          action_type: "send_application_websitebutton",
        },
      },
    })
    .then(success)
    .catch(error);
};

Websitebuttons.prototype.getSupplier = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

export default Websitebuttons;
