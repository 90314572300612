/* Import libraries */
import React, { useState, useEffect, ReactNode } from "react";
import styles from "./css/FormBuilder.css";
import { connect } from "react-redux";

import {
  removeFormBuilderValidationResults,
  setFormBuilderValidationResults,
} from "modules/shared/actions";

import FormBuilderComponentGridWrapper from "./FormBuilderComponentGridWrapper";

type Props = {
  answers: any[],
  borderedStyle: boolean,
  components: any[],
  dispatch: (dispatchFunction: () => void) => void,
  isDraft?: boolean,
  labelShrink: boolean,
  onChange: (params: any) => void,
  mode?: "onBlur" | "onChange",
  pageIndex: number,
  reduxKey: string,
  validationTrigger?: boolean,
}

function SupplierFormRender(props: Props) {
  const {
    answers = [],
    borderedStyle,
    components,
    dispatch,
    labelShrink,
    onChange,
    pageIndex,
    reduxKey,
    mode = "onBlur",
    // TODO - Review if this is needed for Supplier Form
    // validationTrigger,
    isDraft,
  } = props;
  const [validationFlags, setValidationFlags] = useState<boolean[]>([]);

  function componentVaildate(index, boolean) {
    validationFlags[index] = boolean;
    setValidationFlags([...validationFlags]);
    dispatch(setFormBuilderValidationResults(reduxKey, validationFlags));
  }

  useEffect(() => {
    return () => {
      dispatch(removeFormBuilderValidationResults(reduxKey));
    }
  }, [])

  function getElements() {
    return components.map((component, index) => {
      const answer = (answers || []).find(answer => {
        if (component.field_description) {
          return answer.description === component.field_description;
        }
        return (
          answer.description_1 === component.field_description_1 &&
          answer.description_2 === component.field_description_2
        );
      });

      return (
        <FormBuilderComponentGridWrapper
          answer={answer}
          borderedStyle={borderedStyle}
          labelShrink={labelShrink}
          key={`component-${pageIndex}-${index}`}
          name={`component-${pageIndex}-${index}`}
          formParams={component}
          onChange={params => {
            onChange && onChange(params);
          }}
          isDraft={isDraft}
          isValid={boolean => componentVaildate(index, boolean)}
          showError={!validationFlags[index]}
          mode={mode}
        />
      );
    });
  }

  return <div className={styles.row}>{getElements()}</div>;
}

export default connect((state, ownProps) => {
  return {
    pageIndex: ownProps.pageIndex || 0,
  };
})(SupplierFormRender);
