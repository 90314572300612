import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#password-resets-creating-a-password-reset
 */
let PasswordResets = function(config) {
  this.axios = axios(config);
  this.type = "password_resets";
};

PasswordResets.prototype.createReset = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

export default PasswordResets;
