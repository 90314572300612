import Button from "modules/shared/components/inputs/Button";
import React, { useState } from "react";
import { connect } from "react-redux";

import styles from "../../components/css/AddOnsDetails.css";
import PreviewModal from "./PreviewModal";

function PreviewSaveButtons({
  dismissHandler,
  handleSubmit,
  loading,
  readOnly,
  moduleName,
  previewAddon,
  previewCallback,
  totalPage,
}) {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previews, setPreviews] = useState(previewAddon);

  const openPreviewModal = () => {
    setShowPreviewModal(true);
  }

  const dismissPreviewModal = () => {
    setShowPreviewModal(false);
    if (dismissHandler) {
      dismissHandler();
    }
  }

  let modalMarkup;
  if (showPreviewModal) {
    modalMarkup = (
      <PreviewModal
        dismissHandler={dismissPreviewModal}
        moduleName={moduleName}
        previewAddon={previewCallback ? previews : previewAddon}
        totalPage={totalPage}
      />
    );
  }

  return (
    <div>
      <div className={styles.button_container}>
        <Button
          type="button"
          text={"preview"}
          handleClick={() => {
            if (previewCallback) {
              return previewCallback(setPreviews, openPreviewModal);
            }

            openPreviewModal();
          }}
        />
        <Button
          text={"save"}
          handleClick={handleSubmit}
          loading_text={"saving"}
          loading={loading}
          disableOnLoading={true}
          disabled={readOnly}
          type="submit"
        />
      </div>
      {modalMarkup}
    </div>
  );
}

export default connect(() => {
  return {};
})(PreviewSaveButtons);
