import api from "api";
import BaseModel from "models/BaseModel";

export default class ModulePaperlessApplicationFlowModel extends BaseModel {
  async sendVerificationCodeEmail() {
    const userInformationsAPI = api("user_informations");
    const params = {
      email: this.attributes.email.toLowerCase(),
    };

    try {
      await userInformationsAPI.sendVerificationCode(params);
    } catch (error) {
      console.error(error);
    }
  }

  async validateCode() {
    const userInformationsAPI = api("user_informations");
    const params = {
      code: this.attributes.verification_code,
      email: this.attributes.email.toLowerCase(),
    };
    try {
      const result = await userInformationsAPI.validateVerificationCode({
        params,
      });
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  async load(supplierId) {
    const paperlessApplicationAPI = api("module_paperless_application_flows");
    const params = { email: this.attributes.email.toLowerCase() };
    try {
      this.isLoading = true;
      const result = await paperlessApplicationAPI.getDetails(supplierId, {
        params,
      });
      this.setAttributes(result.data);
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  }

  get details() {
    return {
      account_name: this.attributes.account_name,
      account_number: this.attributes.account_number,
      bank_account_name: this.attributes.bank_account_name,
      bank_account_number: this.attributes.bank_account_number,
      bank_number: this.attributes.bank_number,
      code: this.attributes.verification_code,
      email: this.attributes.email.toLowerCase(),
      first_name: this.attributes.first_name,
      last_name: this.attributes.last_name,
      signatories: (this.attributes.signatories || []).map(sign => {
        sign.email = sign.email.toLowerCase();
        return sign;
      }),
    };
  }

  async saveDetails(supplierId) {
    const paperlessApplicationAPI = api("module_paperless_application_flows");
    try {
      this.isLoading = true;
      const result = await paperlessApplicationAPI.update(
        supplierId,
        this.details,
      );
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  get IDPhoto() {
    return {
      code: this.attributes.verification_code,
      email: this.attributes.email.toLowerCase(),
      image_64: this.attributes.image_64,
    };
  }

  async saveIDphoto(supplierId) {
    const paperlessApplicationAPI = api("module_paperless_application_flows");
    try {
      this.isLoading = true;
      const result = await paperlessApplicationAPI.update(
        supplierId,
        this.IDPhoto,
      );
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }
}
