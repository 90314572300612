import api from "api";
import get from "lodash.get";
import BaseModel from "models/BaseModel";
import sortByCreatedAt from "utils/sortByCreatedAt";
import AddonAnswerHistoryModel from "./AddonAnswerHistoryModel";
import * as Sentry from "@sentry/browser";

export type AddonAnswerAttributes = {
  addon_rule_id: string;
  answers: { [key:string]: any };
  owner_type:
    "Application" |
    "Cardholder" |
    "ModuleCardholderSignatory" |
    "ApplicationTradingNameLink" |
    "ModuleCardholderApplication" |
    "Lead";
  owner_id: string;
  file?: any;
  status: "completed" | "draft";
}

export interface IAddonAnswer {
  attributes: AddonAnswerAttributes;
  id: string,
}

export default class AddonAnswerModel extends BaseModel {
  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  addonAnswersAPI() {
    return api("addon_answers");
  }

  async create({ attributes, onSuccessCallback }) {
    try {
      await this.addonAnswersAPI().createAddonAnswer(attributes);

      onSuccessCallback();
    } catch (error) {
      console.error(error);
    }
  }

  async update({ attributes, onSuccessCallback }) {
    try {
      await this.addonAnswersAPI().updateAddonAnswer(this.id, attributes);

      onSuccessCallback();
    } catch (error) {
      console.error(error);
    }
  }

  get reallocateReassignHistories() {
    // @ts-ignore-next-line
    const histories = this.addonAnswerHistories.filter(
      history =>
      // @ts-ignore-next-line
        isPresent(history.content) &&
        ["reallocate", "reassign"].includes(history.historyType),
    );

    return sortByCreatedAt(histories);
  }

  async load() {
    try {
      const result = await this.addonAnswersAPI().get(this.id);

      const data = get(result, 'data', {});
      this.setAttributes(data);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  }

  /** Private functions */

  assignRelationships() {
    /** Many relationships */
    // @ts-ignore-next-line
    this.assignManyRelationship({
      key: "addon_answer_histories",
      model: AddonAnswerHistoryModel,
    });
  }
}
