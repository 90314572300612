//
// Reference from Equifax:
// https://www.equifax.com.au/vedaconnect/home/webframe.html#VedaConnect%20Getting%20Started%20Guide.html
//

export const ACCOUNT_TYPE_CODES = {
  BRIDGEFINANCE: "Bridging Finance",
  "Bill of Sale": "Bill of Sale",
  CASHONDELIVERY: "Cash on Delivery",
  Cheque: "Cheque",
  CREDITCARD: "Credit Card",
  COMMERCIALRENTAL: "Commercial Rental",
  DEALERSHIP: "Dealership",
  DIRECTORSACCESS: "Director's Access",
  DEFERREDPAYMENTDEBITCARD: "Deferred Payment Debit Card",
  FACTOREDACCOUNT: "Factored Account",
  HIREPURCHASE: "Hire Purchase",
  LEASING: "Leasing",
  LENDINGPROPOSAL: "Lending Proposal",
  OVERDRAFT: "Bank Overdraft",
  PREMIUMFINANCE: "Premium Finance",
  PERSONALLOAN: "Personal Loan",
  REALESTATEMORTGAGE: "Real Estate Mortgage",
  SEARCHREQUEST: "Search Request",
  TELECOMMUNICATIONS: "Telecommunication Services",
  TRADEFINANCE: "Trade Finance",
  UTILITIES: "Utilities",
  WHOLESALEFINANCE: "Wholesale Finance",
  DAYACCOUNT7: "7 Day Account",
  DAYACCOUNT30: "30 Day Account",
  DAYACCOUNT60: "60 Day Account",
  DAYACCOUNT90: "90 Day Account",
};

export const COURT_TYPE_CODES = {
  COUNTYCOURTJUDGEMENT: "County Court",
  DISTRICTCOURT: "District Court",
  DISTRICTCOURTJUDGEMENT: "District Court",
  LOCALCOURT: "Local Court",
  LOCALCOURTJUDGEMENT: "Local Court",
  MAGISTRATESCOURT: "Magistrates Court",
  MAGISTRATESCOURTJUDGEMENT: "Magistrates Court",
  SMALLCLAIMSCOURT: "Small Court",
  SUPREMECOURT: "Supreme Court",
};

export const ORGANISATION_STATUS_TYPE_CODES = {
  APPROVED: "Approved",
  ARCHIVED: "Archived",
  BUSINESSNAMEEXPIRED: "Business name expired",
  BUSINESSNAMEREMOVED: "Business name removed",
  CANCELLED: "Cancelled",
  CONVERTED: "Converted",
  DEREGISTERED: "De-Registered",
  DISSOLVED: "Dissolved",
  EXEMPT: "Exempt",
  EXTADMIN: "External Administrations",
  EXTADMINASSOC: "External Administration Association",
  INLIQUIDATION: "In Liquidation",
  NOTACTIVE: "Not Active",
  NOTREGISTERED: "Not Registered",
  PENDING: "Pending",
  REGISTERED: "Registered",
  REGISTRATIONTRANSFERRED: "Registration Transferred",
  STRIKEOFF: "Strike off",
  STRIKEOFFINPROGRESS: "Strike off in progress",
  WINDINGUPMANAGED: "Winding up managed",
  WINDINGUPPRESCRIBED: "Winding up prescribed",
};

export const ORGANISATION_TYPE_CODES = {
  INC: "Incorporated",
  LTD: "Limited Liability",
  "N/L": "No Liability",
  "P/L": "Proprietary Limited",
  PTY: "Proprietary",
  "": "Registered Body",
  "*": "Business",
};

export const PAYMENT_STATUS_CODES = {
  OUTSTANDING: "Outstanding",
  CURRENT: "Current",
  DISPUTED: "Disputed",
  PAID: "Paid",
  SETTLED: "Settled",
  CHANGEDTERMOFLOAN: "New Arrangement - Changed Terms of Loan",
  NEWACCOUNT: "New Arrangement - New Account",
};

export const REASON_TO_REPORT_CODES = {
  CLEAROUTWATCHED: "Clearout (Watched)",
  DISHONOUREDCHEQUE: "Dishonoured Cheque",
  PAYMENTDEFAULT: "Payment Default",
  ARRANGEMENTSCHEME: "Scheme of Arrangement",
  CLEAROUTNOTWATCHED: "Clearout (Not watched)",
  ACCOUNTOPENEDINCORRECTINFORMATIONSUPPLIED:
    "Account opened with incorrect information supplied by customer",
  INSURANCESHORTFALL: "Insurance Shortfall",
  JUDGEMENTDEBTOUTSTANDING: "Judgement Debt Outstanding",
  LEGAL: "Legal (where court action commenced)",
  OVERDRAWN: "Overdrawn Account",
  REPOSSESSION: "Repossession (Loss not yet established)",
  REFERREDTOCOLLECTIONAGENCY: "Referred to Collection or Collection Agency",
  REPOSSESSIONAFTERSALE: "Repossession Loss (After sale of the item)",
  VOLUNTARYSURRENDER: "Voluntary Surrender",
  BADDEBTWRITTENOFF: "Bad Debt Written Off",
  WRITEOFF: "Write Off (Monitoring Complete)",
};

export const ROLE_TYPE_CODES = {
  PRINCIPAL: "Principal",
  COBORROWER: "Co-Borrower",
  JOINT: "Joint",
  GUARANTOR: "Guarantor",
  DIRECTOR: "Director",
};
