export const GUARANTOR_LOGIN_BEGIN = "GUARANTOR_LOGIN_BEGIN";
export const GUARANTOR_LOGIN_SUCCESS = "GUARANTOR_LOGIN_SUCCESS";
export const GUARANTOR_LOGIN_ERROR = "GUARANTOR_LOGIN_ERROR";

export const GUARANTOR_INFO_LOAD_START = "GUARANTOR_INFO_LOAD_START";
export const GUARANTOR_INFO_LOAD_SUCCESS = "GUARANTOR_INFO_LOAD_SUCCESS";
export const GUARANTOR_INFO_LOAD_ERROR = "GUARANTOR_INFO_LOAD_ERROR";

export const GUARANTOR_SET_REVIEW_INDEX = "GUARANTOR_SET_REVIEW_INDEX";

export const GUARANTOR_UPDATE_START = "GUARANTOR_UPDATE_START";
export const GUARANTOR_UPDATE_SUCCESS = "GUARANTOR_UPDATE_SUCCESS";
export const GUARANTOR_UPDATE_ERROR = "GUARANTOR_UPDATE_ERROR";

export const GUARANTOR_SET_APPROVAL = "GUARANTOR_SET_APPROVAL";
export const GUARANTOR_SET_GUARANTORFIRSTNAME =
  "GUARANTOR_SET_GUARANTORFIRSTNAME";
export const GUARANTOR_SET_GUARANTORLASTNAME =
  "GUARANTOR_SET_GUARANTORLASTNAME";

export const GUARANTOR_IDENTIFICATION_SUBMIT_START =
  "GUARANTOR_IDENTIFICATION_SUBMIT_START";
export const GUARANTOR_IDENTIFICATION_SUBMIT_SUCCESS =
  "GUARANTOR_IDENTIFICATION_SUBMIT_SUCCESS";
export const GUARANTOR_IDENTIFICATION_SUBMIT_ERROR =
  "GUARANTOR_IDENTIFICATION_SUBMIT_ERROR";
export const GUARANTOR_SHOW_WARNING = "GUARANTOR_SHOW_WARNING";
