import styles from "modules/new-applications/css/LimitAndApprovals.css";
import FixedContent from "modules/shared/components/containers/FixedContent";
import React, { ReactElement } from "react";

const ReadOnlyTaggedApprover = ({
  review,
}: {
  [key: string]: any;
}): ReactElement | null => {
  if (review.isNewRecord) {
    return null;
  }

  return (
    <FixedContent withBottomSeparator={false}>
      <p className={styles.approver_name}>
        {`Approver name: ${review.reviewerName}`}
      </p>
      <p>
        Another reviewer has escalated and this approver can no longer action
        this review.
      </p>
    </FixedContent>
  );
};

export default ReadOnlyTaggedApprover;
