import get from "lodash.get";
import Buttons from "modules/shared/components/containers/Buttons";
import Button from "modules/shared/components/inputs/Button";
import FileWidgetSmall from "modules/shared/components/widgets/interactive/FileWidgetSmall";
import React, { ReactElement } from "react";
import { connect } from "react-redux";

import { CompleteWidget } from "../Additional";
import { IIUFProps } from ".";
import ApplicationIUFInformation from "./ApplicationIUFInformation";
import useIUFApproverState from "./hooks/useIUFApproverState";
import useIUFState from "./hooks/useIUFState";
import IUFApprover from "./IUFApprover";
import TradingNamesIUFInformation from "./TradingNamesIUFInformation";
import ApplicationModel from "models/ApplicationModel";
import LeadModel from "models/LeadModel";

function IUFView(props: IIUFProps & {
  editOn: () => void;
  owner: ApplicationModel | LeadModel;
}): ReactElement {
  const {
    owner,
    editOn,
  } = props;
  const { isIUFCompleted } = useIUFState(owner);
  const displayApprover = isIUFCompleted && owner.isIUFApprovalEnabled;

  const iufApproverState = useIUFApproverState(props);
  const {
    currentUserIsSelectedApprover,
    handleSubmit,
    isApproving,
    isIUFApproved,
  } = iufApproverState;

  const file = get(owner, "iufAddonAnswers.file");
  const attachmentConfig = get(owner, "iufAddonRule.config.account_level", {});

  return (
    <>
      <ApplicationIUFInformation
        answersKey="account_level"
        owner={owner}
        header="Internal fields"
      >
        { file && (
          <>
            <p>{attachmentConfig.question || "Attachment"}</p>
            <FileWidgetSmall
              completeWidget={
                <CompleteWidget file={file} />
              }
              disableChangeButton
              file={file}
              viewSrc={file}
            />
          </>
        )}
      </ApplicationIUFInformation>
      <TradingNamesIUFInformation owner={owner} />
      <ApplicationIUFInformation
        answersKey="pricing"
        owner={owner}
        header="Pricing"
      >
        { displayApprover && (
          <IUFApprover owner={owner} {...iufApproverState} />
        )}
      </ApplicationIUFInformation>
      { !isIUFApproved && (
        <Buttons spacingDirection="right">
          <Button
            type="button"
            text="Edit"
            onClick={editOn}
          />
          { currentUserIsSelectedApprover && (
            <Button
              disabled={isApproving}
              loading={isApproving}
              type="button"
              text="Approve"
              onClick={() => handleSubmit("approve")}
              white
            />
          )}
        </Buttons>
      )}
    </>
  )
}

export default connect()(IUFView);
