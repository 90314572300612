import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { countTypeValue } from "./helpers/summary";
import { displayEmpty } from "utils/formatting";
import { enquiryResponseData } from "./helpers/reportHelpers";
import { stringToSimpleDate } from "utils/dateFormatter";
import styles from "../../../css/CommonReviewBusiness.css";

var BusinessAddresses = createClass({
  _principalPlaceOfBusiness(address) {
    if (address === null || address === undefined) return displayEmpty("");

    let addressComponents = [
      address.property,
      address.unit_number,
      address.street_number,
      address.street_name,
      address.street_type,
      address.suburb,
      address.state,
      address.postcode,
    ].filter(field => {
      return field !== undefined && field !== null && field !== "";
    });

    return addressComponents.join(" ");
  },

  _serviceOfNoticeAddress(address) {
    if (address === null || address === undefined) return displayEmpty("");

    let addressComponents = [
      address.street_number,
      address.street_name,
      address.street_type,
      address.suburb,
      address.state,
      address.postcode,
      address.country,
    ].filter(field => {
      return field !== undefined && field !== null && field !== "";
    });

    return addressComponents.join(", ");
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    let businessIdentity = response.business_identity,
      principalBusinessAddress =
        businessIdentity.principal_business_address || {},
      serviceOfNoticeAddress = businessIdentity.service_of_notice_address || {};

    return (
      <ReviewContainer
        subHeading="Business Addresses"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>
            Total: {countTypeValue(summaryEntry, "Business_Addresses")}
          </strong>
        </p>
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th colSpan={2}>Principal Place of Business</th>
                <th colSpan={2}>Service of Notice Address</th>
              </tr>
              <tr>
                <th>Address</th>
                <td>
                  {this._principalPlaceOfBusiness(principalBusinessAddress)}
                </td>
                <th>Address</th>
                <td>{this._serviceOfNoticeAddress(serviceOfNoticeAddress)}</td>
              </tr>
              <tr>
                <th>From</th>
                <td>
                  {stringToSimpleDate(
                    principalBusinessAddress.address_start_date,
                  )}
                </td>
                <th>From</th>
                <td>
                  {stringToSimpleDate(
                    serviceOfNoticeAddress.address_start_date,
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },
});

export default BusinessAddresses;
