import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import moment from "moment";
import styles from "../../css/CommonReviewBusiness.css";

var Notifications = createClass({
  _notifications() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      notificationsCollection = enquiryResult.notifications_collection || {},
      notifications =
        notificationsCollection.debtor_notifications_report_ws_dto || [];

    if (notifications.constructor === Object) notifications = [notifications];

    return notifications.map((notification, index) => {
      return (
        <tr key={index}>
          <th>{notification.notification_type_description}</th>
          <td>{moment(notification.date_time_stamp).format("DD-MMM-YYYY")}</td>
          <td>{notification.vendor_name}</td>
        </tr>
      );
    });
  },

  render() {
    const { isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="Notifications"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <thead>
              <tr>
                <th>Description</th>
                <th>Date/Time</th>
                <th>Notified by</th>
              </tr>
            </thead>
            <tbody>{this._notifications()}</tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },
});

export default Notifications;
