import get from "lodash.get";
import Container from "modules/applications/components/credit-checks/credit-works/v2/Container";
import React, { ReactElement } from "react";

import Addresses from "./Addresses";
import Affiliations from "./Affiliations";
import CompanyRegistration from "./CompanyRegistration";
import Defaults from "./Defaults";
import Directors from "./Directors";
import HistoricNames from "./HistoricNames";
import Insolvencies from "./Insolvencies";
import Judgments from "./Judgments";
import Notifications from "./Notifications";
import PPSRFinanceStatement from "./PPSRFinanceStatement";
import PPSRFinanceStatementsCollateralSummary from "./PPSRFinanceStatementsCollateralSummary";
import PreviousEnquiries from "./PreviousEnquiries";
import Shareholders from "./Shareholders";
import StatusHistory from "./StatusHistory";

type Props = {
  data: {
    companyDetails: any,
    executiveSummary: any,
    financingStatements: any,
    previousEnquiries: any,
  },
}

function History(props: Props): ReactElement {
  const { data } = props;

  const {
    companyDetails,
    executiveSummary,
    previousEnquiries,
    financingStatements,
  } = data;

  return (
    <Container>
      <PPSRFinanceStatementsCollateralSummary data={financingStatements} />
      <Notifications data={get(executiveSummary, "debtorNotifications") || []} />
      <Defaults data={get(executiveSummary, "defaultList") || []} />
      <Judgments data={get(executiveSummary, "judgmentList") || []} />
      <Insolvencies data={get(companyDetails, "nzbnInsolvencyReport") || []} />
      <PreviousEnquiries data={get(previousEnquiries, "previousEnquiries") || []} />
      <CompanyRegistration
        data={{
          ...companyDetails,
          companyNumber: get(executiveSummary, "companyNumber"),
        }}
      />
      <Addresses data={get(companyDetails, "addresses")} />
      <HistoricNames data={get(companyDetails, "historicNames", [])} />
      <StatusHistory data={get(companyDetails, "statusHistory", [])} />
      <Directors data={get(companyDetails, "directors", [])} />
      <Shareholders data={get(companyDetails, "shareAllocations", [])} />
      <Affiliations data={get(companyDetails, "associationsAffiliations", [])} />
      <PPSRFinanceStatement data={get(financingStatements, "items", [])} />
    </Container>
  );
}

export default History;
