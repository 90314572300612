import { enquiryResponseData, isBusiness } from "./helpers/reportHelpers";

import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { displayEmpty } from "utils/formatting";
import styles from "../../../css/CommonReviewBusiness.css";

var PPSRRegistrations = createClass({
  _searchResultsFor(ppsr) {
    let report_information = ppsr.report_information || {},
      grantor_search_criteria =
        report_information.grantor_search_criteria || {};
    return (
      grantor_search_criteria.organisation_number_type +
      " " +
      grantor_search_criteria.organisation_number
    );
  },

  _ppsr() {
    const { data, entityType } = this.props;

    if (isBusiness(entityType))
      return (
        <p>
          PPSR information is not available. You have not been charged for the
          PPSR component of this report. To access PPSR information having
          previously opted out, please contact your Account Manager or call
          Equifax on 13 8332.
        </p>
      );

    let response = enquiryResponseData(data, entityType),
      ppsrRegistrations = response.ppsr_registrations || {},
      registration_summary = ppsrRegistrations.registration_summary || {};

    return (
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <th>
                <strong>Total PPSR Registrations</strong>
              </th>
              <td>
                <strong>
                  {displayEmpty(registration_summary.total_registrations, "0")}
                </strong>
              </td>
            </tr>
            <tr>
              <th>PPSR Registrations &lt; 12 months</th>
              <td>
                {displayEmpty(
                  registration_summary.total_registrations_under_twelve_months,
                  "0",
                )}
              </td>
            </tr>
            <tr>
              <th>PPSR Registrations &gt; 12 months</th>
              <td>
                {displayEmpty(
                  registration_summary.total_registrations_over_twelve_months,
                  "0",
                )}
              </td>
            </tr>
            <tr>
              <th>
                PPSR Purchase Money Security Interest (PMSI) registrations
                recorded
              </th>
              <td>
                {displayEmpty(
                  registration_summary.total_pmsi_registrations,
                  "0",
                )}
              </td>
            </tr>
            <tr>
              <th>
                PPSR Registrations Recorded for your Organisation's Designated
                Secured Parties
              </th>
              <td>
                {displayEmpty(
                  registration_summary.total_registrations_designated_secured_parties,
                  "0",
                )}
              </td>
            </tr>
            <tr>
              <th>
                PPSR Registrations Recorded for Other Financier's Designated
                Secured Parties
              </th>
              <td>
                {displayEmpty(
                  registration_summary.total_registrations_other_financier_secured_parties,
                  "0",
                )}
              </td>
            </tr>
            <tr>
              <th>Grantor Search Results for</th>
              <td>{this._searchResultsFor(ppsrRegistrations)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },

  render() {
    const { isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="PPSR Registrations"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        {this._ppsr()}
      </ReviewContainer>
    );
  },
});

export default PPSRRegistrations;
