/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { hideLiveChat } from "utils/liveChat.js";

/* Import CSS */
import styles from "./css/Review.css";

/* Actions */
import { updateUser } from "../../actions/review";

import { userLogin, loadCurrentEntity } from "modules/user/actions";

/* Import components */
import Button from "modules/shared/components/inputs/Button";
import SectionTitle from "modules/shared/components/widgets/static/SectionTitle";
import SectionBadge from "modules/shared/components/widgets/static/SectionBadge";
import SectionDescription from "modules/shared/components/widgets/static/SectionDescription";
import WorkflowOverlay from "modules/shared/components/top/WorkflowOverlay";

var ReviewCompleted = createClass({
  componentDidMount: function() {
    const { dispatch, user, isNewUser } = this.props;
    if (isNewUser) {
      dispatch(updateUser(user));
    }
    dispatch(loadCurrentEntity());
  },
  submit: function(redirectUrl) {
    const { dispatch, user, isNewUser } = this.props;
    hideLiveChat();
    if (isNewUser) {
      dispatch(userLogin(user.email, user.password, redirectUrl));
    } else {
      browserHistory.push({
        pathname: redirectUrl,
        state: { history: document.location.pathname },
      });
    }
  },
  render: function() {
    const {
      supplier,
      section_title,
      section_number,
      section_state,
    } = this.props;

    return (
      <section className={styles.blue_section}>
        <div className={styles.row}>
          <div className={styles.blue_panel}>
            <SectionTitle white={true} text={section_title} />
            <SectionBadge icon="glasses" state="section_complete" />
          </div>
        </div>
        <div className={styles.description_row}>
          <div className={styles.description}>
            <SectionDescription
              text={`Your completed application has been sent to ${supplier.attributes.trading_name}`}
            />
            <div className={styles.button_outer}>
              <Button
                css_style="button_white_outer"
                text="track application"
                handleClick={this.submit.bind(null, "/dashboard/reporting")}
              />
            </div>
          </div>
        </div>
      </section>
    );
  },
});

var defaults = {
  section_title: "Application sent",
};

module.exports = connect((state, ownProps) => {
  let user = {
    first_name: state.cob_business.key_contact_first_name,
    last_name: state.cob_business.key_contact_last_name,
    email: state.cob_business.key_contact_email,
    password: state.cob_business.key_contact_password,
  };
  return {
    supplier: state.cob_section.supplier,
    section_title: defaults.section_title,
    user: user,
    isNewUser: state.current_user.data.data.attributes.new_user,
  };
})(ReviewCompleted);
