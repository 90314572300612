import React, { Fragment } from "react";
import getIdentificationCheckOutcomeDescription from "modules/new-applications/components/application-sections/IdentificationCheck/utils/getIdentificationCheckOutcomeDescription";
import styles from "modules/new-applications/css/IdentificationChecks.css";
import SignatureDetails from "modules/new-applications/components/application-sections/IdentificationCheck/SignatureDetails";

const ManualCheckOutcome = props => {
  const { signature } = props;

  if (!signature.isManuallyApproved) {
    return null;
  }

  return (
    <Fragment>
      <div className={styles.e_signature_header}>
        <span className="underlined">Identification check (Manual)</span>
      </div>
      <SignatureDetails
        label="Outcome"
        content={getIdentificationCheckOutcomeDescription(
          signature.isManuallyApproved,
        )}
      />
    </Fragment>
  );
};

export default ManualCheckOutcome;
