import { useEffect, useState } from "react";

export const MEDIA_QUERIES = {
  max_width_991: "(max-width: 991px)",
};

function isMediaQueryMatch(mediaQuery) {
  return window.matchMedia(mediaQuery).matches;
}

export default function useIsMobileScreenState(mediaQuery) {
  const [isMobileScreen, setIsMobileScreen] = useState(
    isMediaQueryMatch(mediaQuery),
  );

  const onWindowResize = () => {
    setIsMobileScreen(isMediaQueryMatch(mediaQuery));
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
  }, []);

  return { isMobileScreen };
}
