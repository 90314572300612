import styled, { css } from "styled-components";
import { COLORS, THEME_COLORS } from "variables/theme";
import { FONT_SIZES } from "variables/typography";
import { GLOBAL_TRANSITION } from "variables/utils";

type SectionProps = {
  noBorder?: boolean,
}

export const Section = styled.div<SectionProps>`
  margin-bottom: 2rem;

  > p {
    max-width: 90%;
  }

  ${props => !props.noBorder && css`
    border-bottom: 1px solid ${THEME_COLORS.sectionBorder};
    padding-bottom: 2rem;
  `}
`;

export const Title = styled.h1`
  font-size: ${FONT_SIZES.heading};
  margin-bottom: 0.5rem;
`;

export const Wrapper = styled.div`
  background: ${THEME_COLORS.bodyBackground};
  bottom: 0;
  display: block;
  left: 0;
  height: auto;
  overflow-y: scroll;
  padding: 8.5rem 0 3rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000 !important;
`;
