import React, { Component } from "react";

import ApplicationOverlay from "modules/shared/components/top/ApplicationOverlay";
import { isMobile } from "modules/shared/helpers/mobileDetect";
import styles from "./css/SignatureContainer.css";

export default class SignatureComponent extends Component {
  applicationOverlay() {
    const { supplierLogo, supplierTradingName } = this.props;

    return (
      <header>
        <ApplicationOverlay
          logo_url={supplierLogo}
          trading_name={supplierTradingName}
          label="Cardholder"
          color={isMobile() ? "white" : "grey"}
        />
      </header>
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        {this.applicationOverlay()}
        <div className={styles.container}>{this.props.children}</div>
      </div>
    );
  }
}
