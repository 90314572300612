import { loadCard } from "modules/profile/actions";
import CreditCardModal from "modules/profile/components/CreditCardModal";
import Button from "modules/shared/components/inputs/Button";
import CircleModal from "modules/shared/components/widgets/static/CircleModal";
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import { formatMoney } from "utils/formatting";
import AdminInnerTitle from "modules/shared/components/widgets/static/AdminInnerTitle";
import * as ppActions from "../actions";
import styles from "./css/PricingPlans.css";
import Plan from "./Plan";

export class PricingPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      showCreditInfoModal: false,
      showNoticeModal: false,
    };
    this.saveCard = this.saveCard.bind(this);
  }

  componentWillMount() {
    const { dispatch, current_entity } = this.props;

    dispatch(ppActions.getPricingPlans());
    dispatch(loadCard());

    if (!current_entity.attributes.supplier) {
      browserHistory.push("/dashboard/home");
    }
  }

  setSelectedPlan(plan) {
    const { dispatch, pricingPlansList, currentPricingPlan } = this.props;
    const currentPricingPlanNo = currentPricingPlan
      ? currentPricingPlan.attributes.plan_no
      : null;
    dispatch(ppActions.setSelectedPlan(plan));

    const newPlan = pricingPlansList.find(
      v => v.attributes.plan_no === plan.no,
    );

    if (currentPricingPlanNo === plan.no) return;
    if (
      plan.no === 0 || // selecting Enterprise plan
      currentPricingPlanNo === 0 || // current Enterprise plan
      (currentPricingPlanNo !== 0 && currentPricingPlanNo < plan.no) || // upgrade plan
      (currentPricingPlanNo !== 0 && // downgrade plan
        currentPricingPlanNo > plan.no &&
        currentPricingPlan.attributes.application_tally <
          newPlan.attributes.application_max &&
        currentPricingPlan.attributes.connection_tally <
          newPlan.attributes.connection_max)
    ) {
      this.setState({ showConfirmModal: true });
    } else {
      // notice - cannot downgrade plan
      this.setState({ showNoticeModal: true });
    }
  }

  updatePlan(plan) {
    const { dispatch, pricingPlansList, currentPricingPlan, card } = this.props;
    if (!card) {
      this.setState({ showCreditInfoModal: true });
      return;
    }

    const currentPricingPlanNo = currentPricingPlan
      ? currentPricingPlan.attributes.plan_no
      : null;

    const newPlan = pricingPlansList.find(
      v => v.attributes.plan_no === plan.no,
    );

    if (
      plan.no === 0 ||
      (currentPricingPlanNo !== 0 &&
        currentPricingPlan.attributes.plan_no < newPlan.attributes.plan_no)
    ) {
      // upgrade plan
      dispatch(ppActions.upgradeCurrentPlan(newPlan));
    } else {
      // downgrade plan
      dispatch(ppActions.downgradeCurrentPlan(newPlan));
    }

    this.setState({ showConfirmModal: false });
  }

  saveCard(response) {
    const { dispatch } = this.props;
    this.setState({ showCreditInfoModal: false });
    if (response) {
      dispatch(loadCard());
      this.setState({ showConfirmModal: true });
    }
  }

  render() {
    const {
      plans,
      selectedPlan,
      currentPricingPlan,
      pricingPlansList,
      loading,
    } = this.props;
    const {
      showConfirmModal,
      showCreditInfoModal,
      showNoticeModal,
    } = this.state;
    const plan_components = [];
    const currentPricingPlanNo = currentPricingPlan
      ? currentPricingPlan.attributes.plan_no
      : null;

    if (loading) {
      return <SimpleLoader />;
    }

    Object.keys(plans).forEach((item, index) => {
      const plan = plans[item];

      if (pricingPlansList) {
        const defaultPlan = pricingPlansList.find(
          v => v.attributes.plan_no === plan.no,
        );

        if (defaultPlan) {
          plan.name = defaultPlan.attributes.name;
          plan.description = defaultPlan.attributes.description;
        }

        if (currentPricingPlanNo !== 0 && currentPricingPlanNo === plan.no) {
          plan.price = `$${formatMoney(
            parseFloat(currentPricingPlan.attributes.per_user_charge),
            2,
          )}`;
          plan.features[0] = `${currentPricingPlan.attributes.application_max} Applications sent p.a`;
          plan.features[1] = `${currentPricingPlan.attributes.connection_max} Customer connections stored p.a`;
        } else if (defaultPlan) {
          plan.price = `$${formatMoney(
            parseFloat(defaultPlan.attributes.per_user_charge),
            2,
          )}`;
          plan.features[0] = `${defaultPlan.attributes.application_max} Applications sent p.a`;
          plan.features[1] = `${defaultPlan.attributes.connection_max} Customer connections stored p.a`;
        }
      }
      plan_components.push(
        <Plan
          key={index}
          plan={plan}
          currentPricingPlanNo={currentPricingPlanNo}
          selectedPlan={selectedPlan}
          handleClick={() => this.setSelectedPlan(plan)}
        />,
      );
    });
    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.content}>
            <div className={styles.header_title}>
              <AdminInnerTitle text="Pricing Plans" isNormalFont={true} />
            </div>
            <div className={styles.pricing_plans}>{plan_components}</div>
          </div>
        </div>

        {!showCreditInfoModal && showConfirmModal && (
          <CircleModal
            title={
              selectedPlan.no === 0 || currentPricingPlanNo === 0
                ? `Thank you for selecting our '${selectedPlan.name}' plan. The 1Centre team will be in contact shortly.`
                : `Please confirm your plan '${selectedPlan.name}'`
            }
            dismissHandler={() => this.setState({ showConfirmModal: false })}
          >
            <Button
              text={
                selectedPlan.no === 0 || currentPricingPlanNo === 0
                  ? "Send"
                  : "Yes"
              }
              style={{ width: "100px" }}
              handleClick={() => this.updatePlan(selectedPlan)}
            />
            <Button
              text="No"
              white
              style={{ marginLeft: "10px", width: "100px" }}
              handleClick={() => this.setState({ showConfirmModal: false })}
            />
          </CircleModal>
        )}

        {!showCreditInfoModal && showNoticeModal && (
          <CircleModal
            title=""
            dismissHandler={() => this.setState({ showNoticeModal: false })}
          >
            <p>
              Oops - you have exceeded your connections and/or applications sent
              number so are unable to downgrade your plan. Please email
              support@1centre.com if you have any queries.
            </p>
            <Button
              text="Ok"
              style={{ marginLeft: "10px", width: "100px" }}
              handleClick={() => this.setState({ showNoticeModal: false })}
            />
          </CircleModal>
        )}
        {showCreditInfoModal && (
          <CreditCardModal
            onSave={this.saveCard}
            onCancel={() => this.setState({ showCreditInfoModal: false })}
          />
        )}
      </section>
    );
  }
}

export const defaults = {
  pricing_plans: {
    plan_1: {
      description: "Ideal for the sole operator or those starting out",
      features: [
        "36 Applications sent p.a",
        "18 Customer connections p.a",
        "Dynamic application system",
        "Lead management system",
        "Email notifications suite",
        "Email support",
      ],
      name: "Freemium",
      no: 1,
      price: "$0.00",
    },
    Plan_4: {
      description:
        "Ideal for those who need that extra functionality and growing",
      features: [
        "1200 Applications sent p.a",
        "600 Customer connections p.a",
        "Dynamic application system",
        "Lead management system",
        "Multi business set up",
        "Website button & QR code",
        "Email notifications suite",
        "Personalised branding",
        "Chat & Email support",
      ],
      name: "Pro Plan",
      no: 4,
      price: "$9.95",
    },
    Plan_0: {
      description:
        "All the bells and whistles for the enterprise scale business",
      features: [
        "Unlimited Applications sent",
        "Unlimited Customer connections",
        "Dynamic application system",
        "Lead management system",
        "Omni channels framework",
        "Website button & QR code",
        "Decisioning tools",
        "Workflow optimisation system",
        "Card management module",
        "Dynamic financials collection module",
        "Paperless direct debit module",
        "Integration solutions",
        "Email notifications suite",
        "Brand management solutions",
        "User experience monitoring",
        "Reporting & Analytics",
        "Chat & Email support",
      ],
      name: "Enterprise Unlimited",
      no: 0,
      price: "Let's talk",
    },
  },
};

export default connect(
  (state, _) => {
    return {
      card: state.manage_profile.current_entity_card,
      currentPricingPlan: state.pricing_plans.current_pricing_plan,
      current_entity: state.current_user.current_entity,
      loading:
        state.pricing_plans.pricingPlansLoading ||
        state.pricing_plans.curentPrcingPlanUpdating,
      plans: defaults.pricing_plans,
      pricingPlansList: state.pricing_plans.pricingPlansList,
      selectedPlan: state.pricing_plans.selectedPlan || {},
    };
  },
  dispatch => {
    return {
      dispatch,
      ppActions: bindActionCreators(ppActions, dispatch),
    };
  },
)(PricingPlans);
