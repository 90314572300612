import styles from "modules/shared/components/containers/css/GridContent.css";
import PropTypes from "prop-types";
import React from "react";

function gridStyle(props) {
  const { gridColumnTemplate } = props;

  if (gridColumnTemplate === "one_third") {
    return styles.one_third;
  }

  if (gridColumnTemplate === "two_thirds") {
    return styles.two_thirds;
  }

  return styles.half;
}

function mobileGridStyle(props) {
  const { mobileGridColumnTemplate } = props;

  if (mobileGridColumnTemplate === "half") {
    return styles.mobile_half;
  }

  if (mobileGridColumnTemplate === "two_thirds") {
    return styles.mobile_two_thirds;
  }

  return styles.mobile_full;
}

export default function GridSectionContent(props) {
  const { children, className } = props;

  return (
    <div className={`${gridStyle(props)} ${mobileGridStyle(props)} ${className}`}>
      {children}
    </div>
  );
}

GridSectionContent.defaultProps = {
  gridColumnTemplate: "half",
  mobileGridColumnTemplate: "full",
};

GridSectionContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  gridColumnTemplate: PropTypes.oneOf(["half", "one_third", "two_thirds"]),
  mobileGridColumnTemplate: PropTypes.oneOf(["half", "full", "two_thirds"]),
};
