import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import * as yup from "yup";
import { Address } from "./type";

function validateAddress(value: Address): boolean {
  if (isBlank(value)) {
    return false;
  }

  const {
    api_id: apiId,
    api_provider: apiProvider,
    full_address: fullAddress,
    raw_data: rawData,
  } = value;

  return (
    isPresent(apiId) &&
    isPresent(apiProvider) &&
    isPresent(fullAddress) &&
    isPresent(rawData)
  );
}

export function getAddressFinderSchema({ testName = "address", value }) {
  const ERROR = "Please select an address.";
  return yup.object()
    .typeError(ERROR)
    .test(testName, ERROR, () => validateAddress(value));
};
