import React from "react";
import styles from "./css/SectionRect.css";

var SectionRect = createClass({
  render: function() {
    const { title, css_class } = this.props;
    let rect_title = null,
      style = styles.default;

    if (title) {
      rect_title = (
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        ></h1>
      );
    }

    if (css_class) {
      style = styles[css_class];
    }

    return (
      <div className={style}>
        <div className={styles.body}>
          {rect_title}
          {this.props.children}
        </div>
      </div>
    );
  },
});

export default SectionRect;
