import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import React from "react";

export default function FormCheckbox(props) {
  const {
    checkboxProps,
    formControlLabelProps,
    formControlProps,
    formHelperTextProps,
    helperText,
  } = props;

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <FormControl {...formControlProps}>
        <FormControlLabel
          control={<Checkbox {...checkboxProps} />}
          {...formControlLabelProps}
        />
      </FormControl>
      <FormHelperText className="is-size-5" {...formHelperTextProps}>
        {helperText}
      </FormHelperText>
    </MuiThemeProvider>
  );
}

FormCheckbox.defaultProps = {
  checkboxProps: {},
  formControlLabelProps: {},
  formControlProps: {},
};
