import get from "lodash.get";
import AddonAnswer from "modules/new-applications/components/AddonAnswer";
import styles from "modules/new-applications/css/Financials.css";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import ContentWithFooter from "modules/shared/components/containers/ContentWithFooter";
import FixedContent from "modules/shared/components/containers/FixedContent";
import GridContent from "modules/shared/components/containers/GridContent";
import FileWidgetSmall from "modules/shared/components/widgets/interactive/FileWidgetSmall";
import LabeledContent from "modules/shared/components/widgets/static/LabeledContent";
import PDFLogo from "modules/shared/components/widgets/static/PDFLogo";
import React from "react";
import { formatMoney } from "utils/formatting";
import isBlank from "utils/isBlank";

function Total(props) {
  const { label, content } = props;

  return (
    <GridContent mobileGridColumnTemplate="half">
      <div className={styles.total}>
        <LabeledContent label={label} content={`$${content}`} />
      </div>
    </GridContent>
  );
}

function FinancialAnswers(props) {
  const { header, answers, components, totalLabel, totalValue } = props;

  return (
    <FixedContent
      header={header}
      withBottomMargin={false}
      withBottomSeparator={false}
    >
      <AddonAnswer answers={answers} components={components} />
      <Total label={totalLabel} content={totalValue} />
    </FixedContent>
  );
}

function Assets(props) {
  const { answers, addonRule } = props;

  const totalAssets = formatMoney(
    parseInt(get(answers, "answers.total_assets", 0)),
  );

  return (
    <FinancialAnswers
      header="Assets"
      answers={get(answers, "answers.assets", [])}
      components={get(addonRule, "additionalFields.assets", [])}
      totalLabel="Total assets"
      totalValue={totalAssets}
    />
  );
}

function Liabilities(props) {
  const { answers, addonRule } = props;

  const totalLiabilities = formatMoney(
    parseInt(get(answers, "answers.total_liabilities", 0)),
  );

  return (
    <FinancialAnswers
      header="Liabilities"
      answers={get(answers, "answers.liabilities", [])}
      components={get(addonRule, "additionalFields.liabilities", [])}
      totalLabel="Total liabilities"
      totalValue={totalLiabilities}
    />
  );
}

function Income(props) {
  const { answers, addonRule } = props;

  const totaIncome = formatMoney(
    parseInt(get(answers, "answers.total_income", 0)),
  );

  return (
    <FinancialAnswers
      header="Income"
      answers={get(answers, "answers.income", [])}
      components={get(addonRule, "additionalFields.income", [])}
      totalLabel="Total income"
      totalValue={totaIncome}
    />
  );
}

function Expenses(props) {
  const { answers, addonRule } = props;

  const totaExpenses = formatMoney(
    parseInt(get(answers, "answers.total_expenses", 0)),
  );

  return (
    <FinancialAnswers
      header="Expenses"
      answers={get(answers, "answers.expenses", [])}
      components={get(addonRule, "additionalFields.expenses", [])}
      totalLabel="Total expenses"
      totalValue={totaExpenses}
    />
  );
}

function AssetsLiabilities(props) {
  const { addonRule, answers } = props;
  const mandatoryFields = get(addonRule, "mandatoryFields", []);

  if (!mandatoryFields.includes("assets_liabilities")) {
    return null;
  }

  const netWorth = formatMoney(parseInt(get(answers, "answers.net_worth", 0)));

  return (
    <FixedContent header="Assets & Liabilities">
      <Assets {...props} />
      <Liabilities {...props} />
      <Total label="Net worth (Assets - Liabilities)" content={netWorth} />
    </FixedContent>
  );
}

function IncomeExpenses(props) {
  const { addonRule, answers } = props;
  const mandatoryFields = get(addonRule, "mandatoryFields", []);

  if (!mandatoryFields.includes("income_expenses")) {
    return null;
  }

  const surplus = formatMoney(parseInt(get(answers, "answers.surplus", 0)));

  return (
    <FixedContent header="Income & Expenses">
      <Income {...props} />
      <Expenses {...props} />
      <Total label="Surplus (Income - Expenses)" content={surplus} />
    </FixedContent>
  );
}

function CompleteWdidget(props) {
  const { file } = props;
  const url = (file || {}).url;

  if (isBlank(url)) {
    return null;
  }

  return (
    <PDFLogo alt="PDF Upload" key="financial-statement" logoStyle="small" />
  );
}

function FinancialStatement(props) {
  const {
    application,
    addonRule,
    answers,
    currentUser,
    onFetchApplicationRecord,
  } = props;
  const { file } = answers;
  const mandatoryFields = addonRule.mandatoryFields || [];
  const { isLoading, setIsLoading } = useIsLoadingState();

  if (!mandatoryFields.includes("file")) {
    return null;
  }

  const onSuccessCallback = () => {
    onFetchApplicationRecord(() => setIsLoading(false));
  };

  const onUpload = data => {
    setIsLoading(true);

    answers.update({
      attributes: {
        file: data,
      },
      currentUser,
      onSuccessCallback,
    });
  };

  return (
    <FixedContent header="Financial statement">
      <FileWidgetSmall
        accept=".pdf"
        completeWidget={<CompleteWdidget file={file} />}
        filename="financials.pdf"
        id="financial-statement"
        key="financial-statement"
        loading={isLoading}
        text="Drag and drop"
        viewSrc={file}
        handleFileCallback={onUpload}
        disabled={application.isFated}
      />
    </FixedContent>
  );
}

export default function Financials(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;
  const addonRule = application.financialsAddonRule;
  const answers = application.financialsAddonAnswers;

  return (
    <ContentWithFooter footer={get(addonRule, "formattedRuleLabel")}>
      <AssetsLiabilities addonRule={addonRule} answers={answers} />
      <IncomeExpenses addonRule={addonRule} answers={answers} />
      <FinancialStatement
        addonRule={addonRule}
        answers={answers}
        application={application}
        currentUser={currentUser}
        onFetchApplicationRecord={onFetchApplicationRecord}
      />
    </ContentWithFooter>
  );
}
