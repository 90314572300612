/* Import libraries */
import React from "react";
import { Link } from "react-router";

/* Import styles */
import styles from "./css/LeftArrowButton.css";

import Icon from "modules/shared/components/svg/Icon";

var LeftArrowButton = createClass({
  render: function() {
    const { link, type, handleClick, ...rest } = this.props;
    var style = styles.button;

    if (handleClick) {
      return (
        <button className={style} onClick={handleClick} {...rest}>
          <Icon icon="left" size="10x17" />
        </button>
      );
    } else if (type === "submit" || type === "button") {
      return (
        <button className={style} type={type} {...rest}>
          <Icon icon="left" size="10x17" />
        </button>
      );
    } else {
      return (
        <Link className={style} to={link} {...rest}>
          <Icon icon="left" size="10x17" />
        </Link>
      );
    }
  },
});

module.exports = LeftArrowButton;
