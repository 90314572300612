import React, { ReactElement } from 'react'
import { Wrapper } from './styles'
import PopperTooltip from "modules/shared/components/widgets/interactive/PopperToolTip"

type Props = {
  node: any,
}

function EntityLabel({
  node,
}: Props): ReactElement {
  return (
    <PopperTooltip
      title={node.title}
      noArrow
    >
      <Wrapper>
        {node.title}
      </Wrapper>
    </PopperTooltip>
  )
}

export default EntityLabel
