import { get } from "lodash";
import { STATE_LOAD_STATE_SUCCESS } from "modules/state/constants.js";
import moment from "moment";

import { COB_BUSINESS_SELECT_ENTITY_TYPE } from "../consumer-onboarding/constants/onboarding";
import {
  IDENTITY_ADDRESS_LOOKUP_CLEAR,
  IDENTITY_ADDRESS_LOOKUP_ERROR,
  IDENTITY_ADDRESS_LOOKUP_START,
  IDENTITY_ADDRESS_LOOKUP_SUCCESS,
  IDENTITY_CLEAR_STATE,
  IDENTITY_CLICK_EVENT,
  IDENTITY_COMPONENT_COMPLETE,
  IDENTITY_SCRAPE_IMAGE_END,
  IDENTITY_SCRAPE_IMAGE_START,
  IDENTITY_SECTION_COMPLETE,
  IDENTITY_SELECT_ADDRESS,
  IDENTITY_SELECT_NO_IMAGE_FILE,
  IDENTITY_SET_ADDRESS,
  IDENTITY_SET_ANTI_FRAUD_RESULT,
  IDENTITY_SET_CAMERA,
  IDENTITY_SET_DOB,
  IDENTITY_SET_DRIVER_LICENCE_VERSION,
  IDENTITY_SET_IDENTIFICATION_EXP_DATE,
  IDENTITY_SET_DRIVER_LICENCE_STATE,
  IDENTITY_SET_FIRSTNAME,
  IDENTITY_SET_FRONT_FACE_IMAGE,
  IDENTITY_SET_IMAGE_64,
  IDENTITY_SET_IMAGE_FILE_DETAILS,
  IDENTITY_SET_LASTNAME,
  IDENTITY_SET_MIDDLENAME,
  IDENTITY_SET_MOCK_PERCENTAGE,
  IDENTITY_SET_NAME,
  IDENTITY_SET_NAMES_LOCKED,
  IDENTITY_SET_NOIDENTIFICATION,
  IDENTITY_SET_NOIDENTIFICATION_REASON,
  IDENTITY_SET_NUMBER,
  IDENTITY_SET_OTHER_REGION,
  IDENTITY_SET_PROOF_OF_ADDRESS,
  IDENTITY_SET_REGION,
  IDENTITY_SET_SCRAPE_DETAILS_MODAL_VISIBILITY,
  IDENTITY_SET_TYPE,
  SET_IDENTITY,
} from "./constants";

/* Default state for identity */
const identity_default = {
  address: {
    full_address: "",
  },
  address_api_id: null,
  address_list: {},
  address_loading: false,
  address_raw_list: {},
  completed: {},
  dob: null,
  driver_licence_version: "",
  identification_exp_date: null,
  file_name: "",
  file_size: 0,
  first_name: "",
  front_face_image: null,
  image_64: null,
  last_name: "",
  mock_percentage: 0,
  names_locked: false,
  noCamera: false,
  noIdentification: false,
  isScrapeDetailsModalVisible: false,
  no_identification_reason: null,
  number: "",
  other_region: false,
  identification_exp_date: null,
  proof_of_address: null,
  region: "",
  section_complete: false,
  section_data: {},
  section_index: 0,
  type: "",
};

export function identityReducer(state = identity_default, action) {
  switch (action.type) {
    case IDENTITY_CLEAR_STATE:
      return { ...identity_default };
    case IDENTITY_COMPONENT_COMPLETE:
      var component_state = new Object();
      component_state[action.payload.component] = action.payload.state;
      return {
        ...state,
        completed: { ...state.completed, ...component_state },
      };

    case IDENTITY_SECTION_COMPLETE:
      return {
        ...state,
        section_complete: action.payload.complete,
        section_data: action.payload.data,
      };

    case IDENTITY_SET_NAMES_LOCKED:
      return { ...state, names_locked: action.payload };

    case IDENTITY_SET_NAME:
      return { ...state, name: action.payload };

    case IDENTITY_SET_FIRSTNAME:
      return { ...state, first_name: action.payload };

    case IDENTITY_SET_LASTNAME:
      return { ...state, last_name: action.payload };

    case IDENTITY_SET_MIDDLENAME:
      return { ...state, middle_name: action.payload };

    case IDENTITY_SET_DOB:
      return { ...state, dob: action.payload };

    case IDENTITY_SET_ADDRESS:
      return {
        ...state,
        address: action.payload,
        address_api_id: action.payload.api_id,
      };

    case IDENTITY_SELECT_ADDRESS:
      return {
        ...state,
        address: state.address_raw_list[action.payload],
        address_api_id: action.payload,
        address_loading: false,
      };

    case IDENTITY_ADDRESS_LOOKUP_CLEAR:
      return {
        ...state,
        address_api_id: null,
        address_list: {},
        address_raw_list: {},
      };
    case IDENTITY_ADDRESS_LOOKUP_START:
      return { ...state, address_api_id: null, address_loading: true };
    case IDENTITY_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        address_list: action.payload.dropdownData,
        address_loading: false,
        address_raw_list: action.payload.rawData,
      };
    case IDENTITY_ADDRESS_LOOKUP_ERROR:
      return { ...state, address_loading: false };

    case IDENTITY_SET_TYPE:
      return { ...state, type: action.payload };

    case IDENTITY_SET_NUMBER:
      return { ...state, number: action.payload };

    case IDENTITY_SET_DRIVER_LICENCE_VERSION:
      return { ...state, driver_licence_version: action.payload };

    case IDENTITY_SET_IDENTIFICATION_EXP_DATE:
      return { ...state, identification_exp_date: action.payload };

    case IDENTITY_SET_DRIVER_LICENCE_STATE:
      return { ...state, driver_licence_state: action.payload };

    case IDENTITY_SET_IMAGE_64:
      return { ...state, image_64: action.payload };

    case IDENTITY_SET_NOIDENTIFICATION:
      return { ...state, noIdentification: action.payload };

    case IDENTITY_SET_NOIDENTIFICATION_REASON:
      return { ...state, no_identification_reason: action.payload };

    case IDENTITY_SET_CAMERA:
      return { ...state, noCamera: action.payload };

    case IDENTITY_SET_REGION:
      return { ...state, region: action.payload };

    case IDENTITY_SET_OTHER_REGION:
      return { ...state, other_region: action.payload };

    case IDENTITY_SET_MOCK_PERCENTAGE:
      return { ...state, mock_percentage: action.payload };

    case STATE_LOAD_STATE_SUCCESS:
      if (action.payload["identity"]) {
        return { ...state, ...action.payload.identity };
      }

    case IDENTITY_SCRAPE_IMAGE_START:
      return { ...state, image_64: action.payload, scraping: true };

    case IDENTITY_SET_IMAGE_FILE_DETAILS:
      return {
        ...state,
        file_name: action.payload.file_name,
        file_size: action.payload.file_size,
      };

    case IDENTITY_SCRAPE_IMAGE_END:
      const scraped_data = action.payload;
      let new_data = {
        is_valid_identification: scraped_data.is_valid_identification,
      };

      if (scraped_data && Object.keys(scraped_data).length > 0) {
        if (!state.names_locked) {
          new_data = {
            ...new_data,
            first_name: scraped_data.first_name,
            last_name: scraped_data.last_name,
            middle_name: scraped_data.middle_name,
          };
        }

        if (scraped_data.dob) {
          new_data = {
            ...new_data,
            dob: moment(scraped_data.dob, "DD-MM-YYYY").toDate(),
          };
        }
        if (scraped_data.identification_expiry_date) {
          new_data = {
            ...new_data,
            identification_exp_date: moment(
              scraped_data.identification_expiry_date,
              "DD-MM-YYYY",
            ).toDate(),
          };
        }
        new_data = {
          ...new_data,
          driver_licence_version: scraped_data.identification_version,
          driver_licence_state: scraped_data.identification_state,
          number: scraped_data.identification_number,
          region: scraped_data.region,
          type: scraped_data.identification_type,
        };
      }
      return {
        ...state,
        ...new_data,
        scraping: false,
        isScrapeDetailsModalVisible: true,
      };

    case COB_BUSINESS_SELECT_ENTITY_TYPE:
      return { ...state, names_locked: false };

    case SET_IDENTITY:
      return { ...state, ...action.payload };

    case IDENTITY_SET_PROOF_OF_ADDRESS:
      return { ...state, proof_of_address: action.payload };
    case IDENTITY_CLICK_EVENT:
      return { ...state };
    case IDENTITY_SET_FRONT_FACE_IMAGE:
      return { ...state, front_face_image: action.payload };
    case IDENTITY_SET_SCRAPE_DETAILS_MODAL_VISIBILITY:
      return { ...state, isScrapeDetailsModalVisible: action.payload };
    case IDENTITY_SET_ANTI_FRAUD_RESULT:
      const { error, pass } = action.payload;

      return {
        ...state,
        antiFraudCheckPassed: pass || (typeof error === "boolean" && error && false),
      };
    default:
      return { ...state };
  }
}
