import ExpandableSection from "./ExpandableSection";
import { Link } from "react-router";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";
import React from "react";
import { connect } from "react-redux";
import { setValue } from "../actions";
import styles from "./css/AutoDecisioning.css";

class Signatory extends React.Component {
  handleChange(e) {
    const { dispatch } = this.props;
    dispatch(setValue("signatories_count", e.value));
  }

  render() {
    const {
      selectedOption,
      error,
      count_error: countError,
      read_only: readOnly,
    } = this.props;

    return (
      <div className={styles.section}>
        <ExpandableSection
          id={"signatories"}
          title={"Signatory/ies"}
          info_text={
            "For Trust applications, Partnership applications or Joint applications do you require all trustee/s, partner/s and joint applicant/s to sign?  \
          <br/>*Please note: The nominated signatories have to agree \
          to sign the account first. Delays will occur."
          }
          disabled={readOnly}
          account_rule_on={true}
          hide_setting_message={readOnly}
          error={error}
        >
          {countError && <div className={styles.error}>{countError}</div>}
          <OptionsDropdown
            id={"signatories_count"}
            name={"signatories_count"}
            label={"Number of Signatory"}
            value={selectedOption}
            handleChange={this.handleChange.bind(this)}
            required={true}
            options={defaults.options}
            disabled={readOnly}
          />
        </ExpandableSection>
      </div>
    );
  }
}

const defaults = {
  options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
    return { label: i, value: i };
  }),
};

module.exports = connect((state, ownProps) => {
  return {
    rule_set: ownProps.rule_set,
    selectedOption:
      state.auto_decisions.current_rule_set.attributes.signatories_count || "",
  };
})(Signatory);
