/* Import libraries */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router";

/* Import styles */
import styles from "./css/Button.css";

function Button(props) {
  const {
    link,
    white,
    red,
    grey,
    small,
    arrow,
    css_style,
    text,
    type,
    handleClick,
    loading,
    loading_text,
    disableOnLoading,
    disableThemeOverride,
    target,
    disabled,
    ...rest
  } = props;
  let style = styles.button;
  const loader = loading ? <FontAwesomeIcon icon="spinner" spin /> : null;
  let button_text = text;
  let click_handler = handleClick;

  if (white) {
    style = styles.button_white;
  }

  if (red) {
    style = styles.button_red;
  }

  if (grey) {
    style = styles.button_grey;
  }

  if (grey && small) {
    style = styles.button_grey_small;
  }

  if (disableThemeOverride) {
    style = styles.button_default;
  }

  if (arrow) {
    style = styles[`button_icon_${arrow}`];
  }

  if (css_style) {
    style = styles[css_style];
  }

  if (small) {
    style = `${style} ${styles.small} `;
  }

  if (disabled) {
    style = `${styles.disabled} ${style}`;
  }

  if (loading) {
    style = `${styles.loading} ${style}`;
    button_text = loading_text || text;
    click_handler = disableOnLoading ? event => {} : handleClick;
  }

  if (handleClick) {
    return (
      <button
        className={style}
        disabled={disabled}
        onClick={click_handler}
        type={type}
        {...rest}
      >
        {button_text}
        {' '}
        {loader}
      </button>
    );
  }
  if (type === "submit" || type === "button") {
    return (
      <button className={style} type={type} {...rest}>
        {button_text}
        {' '}
        {loader}
      </button>
    );
  }
  return (
    <Link className={style} to={link} target={target} {...rest}>
      {button_text}
      {' '}
      {loader}
    </Link>
  );
}

export default Button;
