import moment from "moment";

class Signature {
  constructor(data) {
    this.attributes = data.attributes;
    this.id = data.id;
    this.full_name = this.attributes.full_name;
    this.dob = moment(this.attributes.dob);
    this.id_type = this.attributes.identification_type;
    this.id_number = this.attributes.identification_number;
    this.id_version = this.attributes.identification_version;
    this.id_expiry = moment(this.attributes.identification_version);
    this.ip = this.attributes.ip;
    this.country = this.attributes.country;
    this.city = this.attributes.city;
    this.region = this.attributes.region;
    this.id_image = this.attributes.identification_image || {};
    this.proof_of_address = this.attributes.proof_of_address || {};
    this.nation_from_address = this.attributes.nation_from_address;
    this.require_proof_of_address = this.attributes.require_proof_of_address;
  }

  idImageUrl() {
    const url = this.id_image.url;

    if (url) {
      return url;
    }
  }

  dobDisplay() {
    return this.dob.format("DD/MM/YYYY");
  }

  idNumberLabel() {
    return this.versionSwitch([
      "Drivers licence number",
      "Passport number",
      "Identification number",
    ]);
  }

  idSupportingLabel() {
    return this.versionSwitch(["Version number", "Expiry date", null]);
  }

  idSupportingData() {
    return this.versionSwitch([
      this.id_version,
      this.id_expiry.format("DD/MM/YY"),
      null,
    ]);
  }

  location() {
    return [this.region, this.city, this.country];
  }

  versionSwitch(options) {
    switch (this.id_type) {
      case "drivers_licence":
        return options[0];
      case "passport":
        return options[1];
      default:
        return options[2];
    }
  }

  outcome() {
    if (this.aml) {
      return this.amlOutcome();
    }

    if (this.identification_check) {
      return this.identificationCheckOutcome();
    }

    if (this.veda_check) {
      return this.vedaCheckOutcome();
    }

    if (this.id_type === "no_identification") {
      return "No identification provided";
    }

    return "Pending";
  }

  amlOutcome() {
    if (this.aml.attributes.status === "errored") {
      return "Errored";
    }
    if (this.aml.attributes.status === "completed") {
      // return this.failedFields().length > 0 ? "Failed" : "Passed";
      if (this.aml.attributes.data.data_sets.smart_id.is_verified) {
        return "Passed";
      }

      return "Failed";
    }
    if (this.aml.attributes.status === "deferred") {
      if (this.id_type === "passport") {
        return "Cannot verify the passport.";
      }

      return "Cannot verify other identification types";
    }
  }

  /**
   * TODO: At the moment, identification check handles "deferred" passport
   * checks. Expand this method later on to include other status.
   */
  identificationCheckOutcome() {
    const attributes = this.identification_check.attributes;

    if (attributes.status === "errored") {
      return "Errored";
    }

    if (attributes.status === "deferred") {
      if (this.id_type === "passport") {
        return "Cannot verify the passport.";
      }

      return "Cannot verify other identification types";
    }

    return "Pending";
  }

  vedaCheckOutcome() {
    if (this.veda_check.attributes.failed) {
      return "Errored";
    }

    return this.failedFields().length > 0 ? "Failed" : "Passed";
  }

  failedFields() {
    if (this.aml) {
      const failedFields = [];
      if (this.aml.attributes.data) {
        const data = this.aml.attributes.data || {};
        const dataSets = data.data_sets || {};
        const smartId = dataSets.smart_id || {};
        const isVerified = smartId.is_verified;

        const dataSourceMatchResults = smartId.data_source_match_results || {};
        const dataSourceMatchResult =
          dataSourceMatchResults.data_source_match_result || [];
        const NZTADriverLicence =
          dataSourceMatchResult.find(
            result => result["data_source_name"] === "NZTADriverLicence",
          ) || {};
        const DIAPassport =
          dataSourceMatchResult.find(
            result => result["data_source_name"] === "DIAPassport",
          ) || {};

        const result =
          this.id_type === "passport" ? DIAPassport : NZTADriverLicence;

        if (!isVerified) {
          if (!smartId.is_name_verified) {
            failedFields.push(`name: ${result.name_match_status}`);
          }

          if (!smartId.is_date_of_birth_verified) {
            failedFields.push(
              `date of birth: ${result.date_of_birth_match_status}`,
            );
          }

          if (!smartId.is_address_verified) {
            failedFields.push(`address: ${result.address_match_status}`);
          }

          if (
            typeof dataSets.dia_passport !== "undefined" &&
            typeof dataSets.dia_passport.dia_passport_verified !==
              "undefined" &&
            !dataSets.dia_passport.dia_passport_verified
          ) {
            failedFields.push(
              `passport: ${dataSets.dia_passport.error_message}`,
            );
          }

          return failedFields;
        }
      }
    }
    if (this.veda_check) {
      if (this.veda_check.attributes.data) {
        const verifications =
          this.veda_check.attributes.data.verifications || {};

        return Object.keys(verifications).filter(
          key => !(verifications[key] === "yes" || verifications[key] === "Y"),
        );
      }

      return [
        this.veda_check.attributes.service_type,
        "first_name",
        "family_name",
        "dob",
      ];
    }

    return [];
  }

  extraOutcome() {
    if (this.aml) {
      const data = this.aml.attributes.data || {};
      const dataSets = data.data_sets || {};
      const pepWatchlistData = dataSets.pep_watchlist_data || {};
      const internationalWatchlistIsClear =
        pepWatchlistData.international_watchlist_is_clear;

      if (internationalWatchlistIsClear === true) {
        return "Passed";
      }

      if (internationalWatchlistIsClear === false) {
        return "Failed";
      }
    }
    return "";
  }

  extraOutcomeFile() {
    if (this.aml) {
      const data = this.aml.attributes.data || {};
      const dataSets = data.data_sets || {};
      const pepWatchlistData = dataSets.pep_watchlist_data || {};
      const watchlistData = pepWatchlistData.watchlist_data || {};
      const watchlistDataItem = watchlistData.watchlist_data_item || {};
      const dataItemValue = watchlistDataItem.data_item_value || "";

      return dataItemValue;
    }

    return "";
  }
}

module.exports = Signature;
