/* Import libraries */
import React from "react";
import ScrollArea from "react-scrollbar";

import styles from "./css/GuarantorTermsAndConditionsText.css";
import humanize from "utils/humanize";

var GuarantorTermsAndConditionsText = createClass({
  onScroll: function(scrollData) {
    if (
      scrollData.containerHeight + scrollData.topPosition >=
      scrollData.realHeight
    ) {
      this.props.onScrollToBottom();
    }
  },

  render: function() {
    const {
      css_class,
      handleScroll,
      consumerName,
      supplierName,
      guarantor_first_name,
      guarantor_last_name,
      noScroll,
    } = this.props;
    let style = styles.terms;

    if (css_class) {
      style = styles[css_class];
    }

    var verticalContainerCSS = {
      backgroundColor: "#c9c9c9",
      opacity: 1,
      width: 15,
      zIndex: 1,
      borderRadius: 3,
    };

    var verticalScrollbarCSS = {
      borderRadius: 3,
      width: 15,
      margin: 0,
      zIndex: 2,
    };

    let tnc = (
      <div>
        <h2 className={styles.copy_heading}>Personal Guarantee</h2>
        <div className={styles.copy}>
          <p className={styles.list_type_1}>
            <strong className={styles.copy_number}>1.</strong>
            <strong>Guarantee</strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.1</span> In consideration of{" "}
            <strong>{supplierName}</strong> (the Supplier) providing credit and
            goods and/or services to <strong>{consumerName}</strong> (the
            Business Customer) or agreeing for a period of one day not to sue
            the Business Customer for moneys owed to the Supplier,{" "}
            <strong>{guarantor_first_name}</strong>{" "}
            <strong>{guarantor_last_name}</strong> (the Guarantor) and any other
            director(s), partner(s), trustee(s) and/or any other person who
            provides a similar guarantee to this guarantee hereby jointly and
            severally guarantees the due and prompt payment by the Business
            Customer to the Supplier (&lsquo;<strong>Guarantee</strong>&rsquo;),
            in the manner and at the times agreed upon between the Supplier and
            the Business Customer, or in the absence of any such agreement then
            upon demand, of all moneys which are owing now or in the future to
            the Supplier by the Business Customer, in relation to the goods,
            services or credit given by the Supplier, or which may otherwise
            become payable by the Business Customer to the Supplier and
            including costs which are set out in clause 1.3 of this Guarantee.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.2</span> The
            Guarantor&rsquo;s liability under this Application is as a principal
            debtor also and not solely as a surety.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.3</span> The Guarantor agrees
            that the Guarantee is a continuing guarantee and will operate until
            either full payment of all amounts owing by the Business Customer to
            the Supplier (including costs and interest) or a written discharge
            is given despite any:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> material alteration
            to the agreement in relation to the provision of credit, goods or
            services by the Supplier to the Business Customer (&lsquo;
            <strong>Agreement</strong>&rsquo;);
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> release of the
            Business Customer from any debt or liability under the Agreement;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>c.</span> change in
            circumstance in either the Business Customer or the Guarantor
            (including for example, and without limitation, a change of
            shareholding, change in directorship, death, or insolvency);
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>d.</span> release of a
            co-guarantor(s);
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>e.</span> prejudicing of any
            securities held by the Supplier in respect of the Business Customer;
            or
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>f.</span> other action which at
            law would have the consequence of releasing the Guarantor.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.4</span> The Guarantor agrees
            that:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> the Guarantee shall
            bind the Guarantor&rsquo;s respective personal representatives;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> if any amounts paid
            by the Guarantor are clawed-back for any reason, the parties will
            then be restored to the position as if that payment was never made;
            and
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>c.</span> while any amounts
            under the Agreement are owing, or may be owing, the Guarantor will
            not compete with the Supplier or enforce any right in law that would
            affect payment to the Supplier by the Business Customer or a
            Guarantor (including but not limited to rights of subrogation and
            contribution).
          </p>
          <p>&nbsp;</p>
          <p className={styles.list_type_1}>
            <strong className={styles.copy_number}>2.</strong>{" "}
            <strong>Privacy Statement</strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.1</span> 1Centre Limited
            (&lsquo;<strong>1Centre</strong>&rsquo;) and the Supplier are
            collecting information about the Business Customer and the Guarantor
            to determine whether to open a credit account in the name of the
            Business Customer and thereafter from time to time to determine
            whether or not to continue offering credit to the Business Customer.
            The Business Customer may not be able to open a credit account if
            all the requested information is not provided.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.2</span> Information
            collected and held about the Business Customer and the Guarantor may
            be used from time to time for credit assessment and control and debt
            recovery purposes of the Supplier and other businesses that use the
            1Centre platform for credit applications (&lsquo;
            <strong>Other 1Centre Users</strong>&rsquo;), to register any
            security interest granted to the Supplier or Other 1Centre Users,
            for marketing and promotional purposes and generally to do business
            with the Supplier, 1Centre and Other 1Centre Users.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.3</span> Information
            (including default information) may from time to time be disclosed
            to, and collected from, trade references, credit reporting agencies
            (such as{" "}
            <strong>
              Equifax New Zealand Information Services and Solutions Limited and
              Total Risk Management Limited
            </strong>
            ), debt collection agencies and related companies of the Supplier
            (&ldquo;<strong>Group</strong>&rdquo;) for credit assessment and
            control and debt recovery.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.4</span> Information
            disclosed to credit reporting agencies (including default
            information) will be held by each agency on its system, accessed by
            the customers of the credit reporting database and used to provide
            its credit reporting services (including the maintenance of credit
            information files and supplying the information to other customers
            of the relevant credit reporting agency).
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.5</span> Under the Privacy
            Act 1993, individuals have rights of access to, and correction of,
            their personal information.
          </p>
          <p>&nbsp;</p>
          <p className={styles.list_type_1}>
            <strong className={styles.copy_number}>3.</strong>{" "}
            <strong>Authorisation</strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>3.1</span> The Guarantor
            confirms and acknowledges that:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> no information has
            been withheld which the Supplier or 1Centre should be aware of in
            considering this application for credit;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> the guarantee in
            clause 1 above has been read, understood and agreed by the
            Guarantor;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>c.</span> the Supplier and
            1Centre may use subcontractors to perform their rights and
            obligations including with respect to the collection and use of
            information;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>d.</span> he or she has read
            and agrees to the privacy statement in clause 2 above and authorises
            the Supplier and 1Centre to:
          </p>
          <p className={styles.list_type_3}>
            <span className={styles.copy_number}>&middot;</span> collect
            information about the Guarantor and Business Customer from any
            person, including trade references, other third parties that 1Centre
            or the Supplier consider relevant as a result of such trade
            references and credit checking processes, credit reporting agencies,
            debt collection agencies and other companies in the Group for any of
            the purposes set out in the privacy statement above and for such
            entities to provide the Supplier and 1Centre with the information it
            may require;
          </p>
          <p className={styles.list_type_3}>
            <span className={styles.copy_number}>&middot;</span> disclose
            information between them and to any person, including trade
            references, credit reporting agencies, debt collection agencies and
            other companies in the Group for any of the purposes set out in the
            privacy statement above and for such entities to collect information
            from the Supplier and 1Centre; and
          </p>
          <p className={styles.list_type_3}>
            <span className={styles.copy_number}>&middot;</span> release to any
            person any information to enable the Supplier to sell or assign to
            any person any obligation the Business Customer may have to the
            Supplier; and
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>e.</span> it consents to
            receive notices given pursuant to this Guarantee and other
            communications from the Supplier and/or 1Centre electronically.
          </p>
        </div>
      </div>
    );

    let content = null;

    if (noScroll) {
      content = tnc;
    } else {
      content = (
        <ScrollArea
          speed={0.8}
          className={styles.scroll_section}
          contentClassName="options"
          horizontal={false}
          verticalContainerStyle={verticalContainerCSS}
          verticalScrollbarStyle={verticalScrollbarCSS}
          onScroll={this.onScroll}
        >
          {tnc}
        </ScrollArea>
      );
    }

    return <div className={style}>{content}</div>;
  },
});

module.exports = GuarantorTermsAndConditionsText;
