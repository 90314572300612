import Checkbox from "@material-ui/core/Checkbox";
import React, { useState } from "react";
import selfDefineStyles from "./css/MulitSelectDropdown.css";
import styles from "../css/material-select.css";
import toggleArrayValue from "utils/toggleArrayValue";
import Popover from "@material-ui/core/Popover";
import { VariableSizeList as List } from "react-window";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextInput from "modules/shared/components/inputs/TextInput";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Radiobox from "../../inputs/Radiobox";
import get from "lodash.get";
import OptionsDropdown from "./OptionsDropdown";
import Switch2 from "./Switch2";
import BorderedTextField from "../../inputs/BorderedTextField";
import { InputAdornment } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function getInitialValues(value, multiple, hasMainOption) {
  if (value && hasMainOption) {
    return {
      main: value.main,
      other: value.other || [],
    };
  }
  if (value && multiple) {
    return { main: null, other: value || [] };
  }
  if (value) {
    return { main: null, other: [value] };
  }
  return { main: null, other: [] };
}

function useSelectedOptionsState(props) {
  const { multiple, hasMainOption, onChange, value } = props;
  const initialValues = getInitialValues(value, multiple, hasMainOption);

  const [showOptions, setShowOptions] = useState(false);
  const [mainValue, setMainValue] = useState(initialValues.main);
  const [otherValue, setOtherValue] = useState(initialValues.other);

  const handleSingleClick = value => {
    let newMainValue;
    let newOtherValue;

    // single option
    if (!multiple) {
      onChange({ value });
      setOtherValue([value]);
      setShowOptions(false);
      return;
    }

    // multiple option
    if (!hasMainOption) {
      newOtherValue = toggleArrayValue(otherValue, value);
      onChange({ value: newOtherValue });
      setOtherValue(newOtherValue);
      return;
    }

    // multiple option with main
    if (value === mainValue) {
      newMainValue = null;
      newOtherValue = otherValue;
    } else {
      newMainValue = mainValue;
      newOtherValue = toggleArrayValue(otherValue, value);
    }
    onChange({
      value: {
        main: newMainValue,
        other: newOtherValue,
      },
    });
    setMainValue(newMainValue);
    setOtherValue(newOtherValue);
  };

  const handleDoubleClicks = (toggle, value) => {
    if (!value) {
      return;
    }
    const oldMainValue = mainValue;
    let newOtherValue = otherValue;
    if (oldMainValue && !newOtherValue.includes(oldMainValue)) {
      newOtherValue.push(oldMainValue);
    }
    newOtherValue = newOtherValue.filter(e => e !== value);
    if (toggle) {
      onChange({
        value: { main: value, other: newOtherValue },
      });
      setMainValue(value);
      setOtherValue(newOtherValue);
    } else {
      onChange({
        value: { main: null, other: newOtherValue },
      });
      setMainValue(null);
      if (value) {
        newOtherValue.push(value);
      }
      setOtherValue(newOtherValue);
    }
  };

  const renderValues = (options) => {
    if (hasMainOption) {
      return `${mainValue ? mainValue + ", " : ""}${otherValue.join(", ")}`;
    }

    return otherValue.map(value => {
      const targetOption = options.find(option => Object.values(option).includes(value)) || {};
      return targetOption.label;
    }).join(", ");

    // return otherValue.join(', ');
  };

  return {
    mainValue,
    otherValue,
    showOptions,
    setShowOptions,
    handleSingleClick,
    handleDoubleClicks,
    renderValues,
  };
}

function OptionList(props) {
  const {
    mainValue,
    otherValue,
    multiple,
    options,
    onClick,
    onDoubleClick,
    hasMainOption,
    width,
  } = props;
  const optionCount = options.length;
  let totalHeight = 0;
  const rows = [];
  const rowHeights = [];
  options.forEach(option => {
    const optionHeight = option.secondary_text ? 96 : 35;
    totalHeight = totalHeight + optionHeight;
    if (option.value === mainValue) {
      rows.push({
        ...option,
        selected: true,
        main: true,
        label: `${option.label} (main option)`,
      });
    } else if (otherValue.includes(option.value)) {
      rows.push({ ...option, selected: true });
    } else {
      rows.push(option);
    }
    rowHeights.push(optionHeight);
  });
  const getItemSize = index => rowHeights[index];
  const height = totalHeight > 500 ? 500 : totalHeight;

  return (
    <List
      height={height}
      width={width ? parseInt(width) : window.innerWidth * 0.85}
      itemSize={getItemSize}
      itemCount={options.length}
      itemData={{
        multiple,
        rows,
        onClick,
        onDoubleClick,
        mainValue,
        otherValue,
        hasMainOption,
      }}
    >
      {Options}
    </List>
  );
}

function Options(props) {
  const { data, index, style } = props;
  const {
    multiple,
    rows,
    onClick,
    onDoubleClick,
    mainValue,
    otherValue,
    hasMainOption,
  } = data;

  return (
    <div style={style}>
      <ListItem
        button
        onClick={() => {
          onClick(rows[index].value);
        }}
        // onDoubleClick={() => {
        //   onDoubleClick(rows[index].value);
        // }}
        style={{ height: `${style.height}px` }}
      >
        {multiple && <Checkbox checked={!!rows[index].selected} />}
        <ListItemText
          className={!!rows[index].main ? selfDefineStyles.main_option : ""}
          primary={rows[index].label}
          // key={rows[index].label}
          secondary={rows[index].secondary_text}
        />

        {rows[index].selected && hasMainOption && (
          <div>
            <Switch2
              label="Main Option"
              checked={mainValue === rows[index].value}
              callback={e => onDoubleClick(e, rows[index].value)}
              prevent="true"
            />
          </div>
        )}
      </ListItem>
    </div>
  );
}

export default function MultiSelectDropdown(props) {
  const {
    borderedStyle,
    id,
    options,
    label,
    error,
    required,
    disabled,
    placeholder,
    multiple,
    hasMainOption,
    width,
    variant,
    labelShrink,
  } = props;

  const {
    mainValue,
    otherValue,
    showOptions,
    setShowOptions,
    handleSingleClick,
    handleDoubleClicks,
    renderValues,
  } = useSelectedOptionsState(props);

  const [anchorEl, setAnchorEl] = useState(null);

  const getValues = () => {
    let values = Array.from(otherValue);
    if (mainValue) {
      values.push(mainValue);
    }

    // values.sort()
    let res = [];
    values.forEach(i => {
      res.push({ label: i, value: i });
    });
    return res;
  };

  const setMaxWidth = (width) => {
    return width < 768 ? width : 768;
  }

  const helperMessage = [];
  if (!required) helperMessage.push("This field is optional.");
  if (hasMainOption)
    helperMessage.push(
      "Please select your main option using the slider on the right hand side of dropdown menu",
    );

  const Input = borderedStyle ? BorderedTextField : TextInput;

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <Input
        id={id}
        label={label}
        required={required}
        type="dropdown"
        onClick={event => {
          setShowOptions(true);
          setAnchorEl(event.target);
        }}
        value={renderValues(options)}
        mentions={hasMainOption ? [mainValue] : null}
        error={error}
        helper_text={helperMessage.join(" ")}
        helperText={error ? error : helperMessage.join(" ")}
        customProps={{
          endAdornment: (
            <InputAdornment disablePointerEvents>
              <FontAwesomeIcon icon="chevron-down" />
            </InputAdornment>
          )
        }}
        labelShrink={labelShrink}
      />
      <Popover
        id={id}
        open={showOptions}
        onClose={() => {
          setShowOptions(false);
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <OptionList
          mainValue={mainValue}
          otherValue={otherValue}
          multiple={multiple}
          options={options}
          onClick={handleSingleClick}
          onDoubleClick={handleDoubleClicks}
          hasMainOption={hasMainOption}
          width={width || (anchorEl && setMaxWidth(anchorEl.clientWidth))}
        />
      </Popover>

      {/* {multiple && getValues().length > 0 && (
        <OptionsDropdown
          id="main_option_1"
          name="main_option_1"
          label="Please select one as main option"
          value={mainValue}
          handleChange={e => handleDoubleClicks(e.value)}
          required={true}
          options={getValues()}
        />
      )} */}
    </MuiThemeProvider>
  );
}
