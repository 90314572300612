/* Import libraries */
import { updateEntityProfileStatus } from "modules/profile/actions";
import Icon from "modules/shared/components/svg/Icon";
import Clickable from "modules/shared/components/widgets/interactive/Clickable";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
import VideoModal from "modules/shared/components/widgets/interactive/VideoModal";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

import styles from "./css/SetupCard.css";

const setupText = [
  "1Centre enables you to generate Trade Applications for your customers digitally. You and your customer will have full visibility of the application going through the process.",
  "Before you get started we need to know some basic information about your business, and how you want us to help you process your trade account applications.",
].map(function(text, index) {
  return (
    <div key={index} className={styles.text}>
      {text}
    </div>
  );
});

//  "At some point you may also get sent an application through 1Centre—we will house both applications you send to your customers and applications you receive from your suppliers on your 1Centre account. This will be clearly managed for you when it comes time."

const targets = {
  "Account rules": [
    "/dashboard/profile?active_tab=account_rules",
    "admin_updated_at",
    "admin",
  ],
  "Approval hierarchy": [
    "/dashboard/profile?active_tab=your_team",
    null,
    "approval_hierarchy",
  ],
  "Business details": ["/dashboard/profile", null, "profile"],
  "Supplier terms": [
    "/dashboard/profile?active_tab=supplier_terms",
    "terms_updated_at",
    "terms",
  ],
  "Billing details": [
    "/dashboard/account-settings",
    null,
    "account_settings"
  ]
};

function SetupCard(props) {
  const { animate, current_entity, current_user, dispatch, list } = props;
  const [isTooltipActive, setIsTooltipActive] = useState(true)

  function hideTooltip() {
    setIsTooltipActive(false);
  }

  function handleClickLink(params) {
    const [url, status_name, checklist_name] = params;
    const list = current_entity.attributes.supplier_onboarding_checklist;

    if (status_name && !list[checklist_name]) {
      const attributes = {};
      attributes[status_name] = moment();
      dispatch(updateEntityProfileStatus(attributes));
    }

    browserHistory.push(url);
  }

  function renderSetupChecklist(list) {
    const listComponents = Object.keys(list).map(function(key) {
      const bullet_icon = list[key] ? "check_checked" : "check_unchecked";
      const classNames =
        `${styles.listItem
        } ${
          list[key] ? styles.completedText : styles.incompleteText}`;
      return (
        <li key={key} className={classNames}>
          <Icon icon={bullet_icon} />
          <Clickable target={() => handleClickLink(targets[key])}>
            {key}
          </Clickable>
        </li>
      );
    });

    return <ul className={styles.list}>{listComponents}</ul>;
  }

  const liner_classes = animate ?
    `${styles.liner} ${styles.pulse_animation}` :
    styles.liner;

  let tool_tip;
  if (current_user.data.included) {
    if (current_user.data.included.length > 1 && isTooltipActive) {
      tool_tip = (
        <ToolTip
          tip_name="Welcome1Centre"
          css_style="weclome_1centre"
          dismissHandler={hideTooltip}
        />
      );
    }
  }
  return (
    <div className={styles.section}>
      <div className={liner_classes}>
        <div className={styles.half}>
          <h2 className={styles.header}>Welcome to 1Centre</h2>
          {tool_tip}
          {setupText}
        </div>

        <div className={styles.half}>
          <h2 className={styles.header}>
            Let&apos;s get started
            <VideoModal videoName="setup_supplier_profile" />
          </h2>
          <div className={styles.text}>
            Follow the links below to create your supplier profile:{" "}
          </div>
          <div className={styles.listSet}>
            {renderSetupChecklist(list)}
          </div>
        </div>
      </div>
    </div>
  );
}

// export default SetupCard;
export default connect((state, ownProps) => {
  return {
    animate: state.dashboard.failed_application,
    current_entity: state.current_user.current_entity,
    current_user: state.current_user,
  };
})(SetupCard);
