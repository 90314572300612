import { get, isEmpty } from "lodash";
import {
  prePopulateCardholderDetails,
  removeCurrentCardholderApplicant,
  setCardholderAddonAnswer,
  setCardholderDetails,
} from "modules/consumer-onboarding/actions/cards";
import areCardholdersEqual from "modules/consumer-onboarding/components/cards/helpers/areCardholdersEqual";
import React, { Component } from "react";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

const SIGNATURE_FIELDS = {
  email: "Email is invalid",
  firstName: "Please enter first name",
  lastName: "Please enter last name",
  mobile: "Please enter phone/mobile number",
};

const NO_SIGNATURE_FIELDS = {
  firstName: "Please enter the name on card",
};

const CARD_LIMIT_FIELD = {
  cardLimit: "Please enter card limit",
};

export function getCardholderAttributes(config, attributes = {}) {
  const { cardLimitEnabled, signatureRequired } = config;

  const signatureFields = {
    email: "",
    lastName: "",
    mobile: "",
  };

  const cardLimitField = {
    cardLimit: "",
  };

  const fields = {
    firstName: get(attributes, "firstName", ""),
    ...(signatureRequired && signatureFields),
    ...(cardLimitEnabled && cardLimitField),
  };

  if (!isEmpty(attributes)) {
    Object.keys(fields).forEach(fieldKey => {
      fields[fieldKey] = attributes[fieldKey];
    });
  }

  return fields;
}

export function getCardholderDetailsErrorMessages(
  cardholder,
  cardholders,
  config,
) {
  const { signatureRequired } = config;
  const errors = getCardholderAttributesErrorMessages(
    cardholder.attributes,
    config,
  );
  if (Object.keys(errors).length > 0) {
    return errors;
  }

  if (signatureRequired) {
    const isDetailsTaken = isCardholderDetailsTaken(cardholder, cardholders);

    if (isDetailsTaken) {
      return {
        email: "Email has already been taken",
        firstName: "First name has already been taken",
        lastName: "Last name has already been taken",
        middleName: "Middle name has already been taken",
      };
    }
  }

  return getCardholderAttributes(config);
}

export function getCardholderAttributesErrorMessages(attributes, config) {
  const { signatureRequired, cardLimitEnabled } = config;
  const keysObj = {
    ...(signatureRequired ? SIGNATURE_FIELDS : NO_SIGNATURE_FIELDS),
    ...(cardLimitEnabled && CARD_LIMIT_FIELD),
  };
  const keys = Object.keys(keysObj);

  const errors = {};

  if (keys) {
    for (const key of keys) {
      const error = getFieldErrorMessage(key, attributes[key], config);
      if (error.length > 0) {
        errors[key] = error;
      }
    }
  }

  return errors;
}

export function isComplete(formErrors, validationFlags) {
  const hasErrors = Object.values(formErrors).some(error => error.length > 0);
  const addonAnswersValid = (validationFlags || []).every(flag => flag);

  return !hasErrors && addonAnswersValid;
}

export function getCardholderApplicant(cobCardsState, index) {
  let cardholderApplicant;

  const cardholders = cobCardsState.cardholders;
  const cardholderApplicantIndex = cardholders.findIndex(
    cardholder => cardholder.attributes.isApplicant,
  );

  if (cardholderApplicantIndex >= 0 && cardholderApplicantIndex !== index) {
    cardholderApplicant = cardholders[cardholderApplicantIndex];
  }

  return cardholderApplicant;
}

const buildPersonAttributes = (person, guarantor) => {
  const email = guarantor.email;
  const attributes = {
    email,
    firstName: person.first_name,
    lastName: person.last_name,
    middleName: person.middle_name,
    mobile: "",
  };

  if (Object.keys(person).includes("contact_phone_number")) {
    attributes.mobile = person.contact_phone_number;
  }

  return attributes;
};

export function getPeopleAutosuggestList(people, guarantors) {
  const list = [];

  for (const person of people) {
    const guarantor = getMatchedGuarantor(person, guarantors);

    if (!guarantor.is_applicant) {
      const attributes = buildPersonAttributes(person, guarantor);
      list.push(attributes);
    }
  }

  return list;
}

function getMatchedGuarantor(person, guarantors) {
  if (isPresent(person.email)) {
    return { email: person.email };
  }

  const matchedGuarantor = guarantors.find(
    guarantor =>
      sanitizeName(guarantor.first_name) === sanitizeName(person.first_name) &&
      sanitizeName(guarantor.last_name) === sanitizeName(person.last_name) &&
      sanitizeName(guarantor.middle_name) === sanitizeName(person.middle_name),
  );

  return matchedGuarantor || { is_applicant: false };
}

// Sanitize the names so it is consistent particularly for empty string, null,
// or undefined
const sanitizeName = name => {
  if (isPresent(name)) {
    return name;
  }

  return "";
};

export default function withCardholderForm(
  WrappedComponent,
  getFormErrorState,
) {
  return class CardholderFormWrapper extends Component {
    // static getDerivedStateFromProps(nextProps, prevState) {
    //   if (getFormErrorState) {
    //     return getFormErrorState(nextProps, prevState);
    //   }

    //   return null;
    // }

    constructor(props) {
      super(props);

      this.state = {
        formErrors: {
          email: "",
          firstName: "",
          lastName: "",
          mobile: "",
        },
        triggerValidation: false,
      };
    }

    onHandleBlur(key, value) {
      this.validateFields(key, value);
    }

    onHandleChangeAddonAnswer(answer) {
      const { dispatch, index } = this.props;

      dispatch(setCardholderAddonAnswer(index, answer));
    }

    onHandleChangeEmail(value, isValid) {
      const { dispatch, index } = this.props;
      dispatch(setCardholderDetails(index, "email", value));

      // TODO: This stuff is breaking in the UI - needs review
      // this.validateEmail(isValid);
    }

    onHandleChangeValue(key, value) {
      const { dispatch, index } = this.props;

      if (key === "isApplicant") {
        this.resetCardholderDetails(value);
        this.toggleCurrentIsApplicant(value);
      }

      dispatch(setCardholderDetails(index, key, value));

      this.validateFields(key, value);
    }

    onHandleSelectSuggestion(suggestion) {
      const { config, dispatch, index } = this.props;

      const attributes = {
        firstName: suggestion.firstName,
        lastName: suggestion.lastName,
        middleName: suggestion.middleName,
      };

      if (isPresent(suggestion.mobile) && suggestion.mobile.length > 0) {
        attributes.mobile = suggestion.mobile;
      }

      if (isPresent(suggestion.email) && suggestion.email.length > 0) {
        attributes.email = suggestion.email;
      }

      dispatch(prePopulateCardholderDetails(index, attributes));

      let errors = getCardholderAttributesErrorMessages(attributes, config);

      if (Object.keys(errors).length === 0) {
        errors = {
          cardLimit: "",
          email: "",
          firstName: "",
          lastName: "",
          middleName: "",
          mobile: "",
        };
      }

      this.setState({ formErrors: { ...errors } });
    }

    toggleCurrentIsApplicant(isApplicant) {
      if (!isApplicant) {
        return;
      }

      const { dispatch, index } = this.props;

      dispatch(removeCurrentCardholderApplicant(index));
    }

    /**
     * If guarantor is applicant:
     *   1. cardholder ticks "no" to "yes" in is applicant field, reset the
     *      first name, last name and email address to match the cardholder.
     *   2. cardholder ticks "yes" to "no" in is applicant field, clear the
     *      first name, last name and email address
     */
    resetCardholderDetails(isApplicant) {
      const {
        dispatch,
        guarantorApplicant,
        index,
        signatoryApplicant,
      } = this.props;

      if (isBlank(guarantorApplicant) && isBlank(signatoryApplicant)) {
        return;
      }

      let attributes = {
        email: "",
        firstName: "",
        lastName: "",
        middleName: "",
        mobile: "",
      };

      if (isPresent(guarantorApplicant) && isApplicant) {
        attributes = this.assignCardholderAttributeFromApplicant(
          guarantorApplicant,
        );
      }

      if (isPresent(signatoryApplicant) && isApplicant) {
        attributes = this.assignCardholderAttributeFromApplicant(
          signatoryApplicant,
        );
      }

      dispatch(prePopulateCardholderDetails(index, attributes));
    }

    assignCardholderAttributeFromApplicant(applicant) {
      const attributes = {
        email: applicant.email,
        firstName: applicant.first_name,
        lastName: applicant.last_name,
        middleName: applicant.middle_name,
      };

      if (Object.keys(applicant).includes("contact_phone_number")) {
        attributes.mobile = applicant.contact_phone_number;
      }

      return attributes;
    }

    validateFields(key, value) {
      const { config } = this.props;
      const errorMessage = getFieldErrorMessage(key, value, config);
      const formErrors = { ...this.state.formErrors, [key]: errorMessage };

      this.setState({ formErrors });
    }

    validateEmail(isValid) {
      // The error message for email does not actually matter as this is handled
      // by the EmailInput component but setting the error to a string makes it
      // consistent with the rest of the validations
      let errorMessage = "";
      if (!isValid) {
        errorMessage = "Email is invalid";
      }

      this.setState({
        formErrors: { ...this.state.formErrors, email: errorMessage },
      });
    }

    render() {
      const { formErrors, triggerValidation } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          formErrors={formErrors}
          triggerValidation={triggerValidation}
          onHandleBlur={this.onHandleBlur.bind(this)}
          onHandleChangeAddonAnswer={this.onHandleChangeAddonAnswer.bind(this)}
          onHandleChangeEmail={this.onHandleChangeEmail.bind(this)}
          onHandleChangeValue={this.onHandleChangeValue.bind(this)}
          onHandleSelectSuggestion={this.onHandleSelectSuggestion.bind(this)}
        />
      );
    }
  };
}

export function getFieldErrorMessage(key, value, config) {
  const { cardLimitEnabled, signatureRequired } = config;
  const errorMessages = {
    ...(signatureRequired ? SIGNATURE_FIELDS : NO_SIGNATURE_FIELDS),
    ...(cardLimitEnabled && CARD_LIMIT_FIELD),
  };

  if (key === "cardLimit" && parseInt(value) === 0) {
    return "Card limit must be greater than 0";
  }

  if (
    Object.keys(errorMessages).includes(key) &&
    (isBlank(value) || value.length < 1)
  ) {
    return errorMessages[key];
  }

  return "";
}

function isCardholderDetailsTaken(currentCardholder, cardholders) {
  if (!isCardholderDetailsComplete(currentCardholder)) {
    return false;
  }

  const currentCardholderAttributes = currentCardholder.attributes;
  const cardholderRecords = cardholders.filter(cardholder => {
    if (!isCardholderDetailsComplete(cardholder)) {
      return false;
    }

    const attributes = cardholder.attributes;

    return areCardholdersEqual(attributes, currentCardholderAttributes);
  });

  return cardholderRecords.length > 1;
}

function isCardholderDetailsComplete(cardholder) {
  const attributes = cardholder.attributes;

  return (
    isPresent(attributes.firstName) &&
    attributes.firstName.length > 0 &&
    isPresent(attributes.lastName) &&
    attributes.lastName.length > 0 &&
    isPresent(attributes.email) &&
    attributes.email.length > 0
  );
}
