import { amountTypeValue, countTypeValue } from "./helpers/summary";
import { enquiryResponseData, isCompany } from "./helpers/reportHelpers";

import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import styles from "../../../css/CommonReviewBusiness.css";

var PublicRecordInformation = createClass({
  _companyInformation(summaryEntry) {
    return (
      <tbody>
        <tr>
          <th>External Administrations</th>
          <td colSpan={2}>{countTypeValue(summaryEntry, "External_Admin")}</td>
          <th>
            <strong>Current Officeholders</strong>
          </th>
          <td>
            <strong>{countTypeValue(summaryEntry, "Officeholders")}</strong>
          </td>
        </tr>
        <tr>
          <th>Previous Company Names</th>
          <td colSpan={2}>{countTypeValue(summaryEntry, "Previous_Names")}</td>
          <th>Current Directors</th>
          <td>{countTypeValue(summaryEntry, "Directors")}</td>
        </tr>
        <tr>
          <th>
            <strong>Court Writs</strong>
          </th>
          <td>
            <strong>{countTypeValue(summaryEntry, "Writs_and_Summons")}</strong>
          </td>
          <td>
            <strong>
              {amountTypeValue(summaryEntry, "Writs_and_Summons_Value")}
            </strong>
          </td>
          <th>Current Secretaries</th>
          <td>{countTypeValue(summaryEntry, "Secretaries")}</td>
        </tr>
        <tr>
          <th>Writs &lt; 12 Months</th>
          <td>
            {countTypeValue(
              summaryEntry,
              "Writs_and_Summons_Less_Than_12_Months",
            )}
          </td>
          <td>
            {amountTypeValue(
              summaryEntry,
              "Writs_and_Summons_Less_Than_12_Months_Value",
            )}
          </td>
          <th>Other Current Officers / Roles</th>
          <td>{countTypeValue(summaryEntry, "Other_Officers")}</td>
        </tr>
        <tr>
          <th>Writs &gt; 12 Months</th>
          <td>
            {countTypeValue(
              summaryEntry,
              "Writs_and_Summons_More_Than_12_Months",
            )}
          </td>
          <td>
            {amountTypeValue(
              summaryEntry,
              "Writs_and_Summons_More_Than_12_Months_Value",
            )}
          </td>
          <th>Business Proprietorships</th>
          <td>{countTypeValue(summaryEntry, "Proprietorships")}</td>
        </tr>
        <tr>
          <th>
            <strong>Court Actions</strong>
          </th>
          <td>
            <strong>{countTypeValue(summaryEntry, "Judgements")}</strong>
          </td>
          <td>
            <strong>{amountTypeValue(summaryEntry, "Judgements_Value")}</strong>
          </td>
          <th>
            <strong>Previous Directors</strong>
          </th>
          <td>
            <strong>
              {countTypeValue(summaryEntry, "Previous_Directors")}
            </strong>
          </td>
        </tr>
        <tr>
          <th>Actions &lt; 12 Months</th>
          <td>
            {countTypeValue(summaryEntry, "Judgements_Less_Than_12_Months")}
          </td>
          <td>
            {amountTypeValue(
              summaryEntry,
              "Judgements_Less_Than_12_Months_Value",
            )}
          </td>
          <th>Changes &lt; 12 Months</th>
          <td>
            <strong>
              {countTypeValue(
                summaryEntry,
                "Previous_Directors_Less_Than_12_Months",
              )}
            </strong>
          </td>
        </tr>
        <tr>
          <th>Actions &gt; 12 Months</th>
          <td>
            {countTypeValue(summaryEntry, "Judgements_More_Than_12_Months")}
          </td>
          <td>
            {amountTypeValue(
              summaryEntry,
              "Judgements_More_Than_12_Months_Value",
            )}
          </td>
          <th>Changes &lt; 12 Months</th>
          <td>
            <strong>
              {countTypeValue(
                summaryEntry,
                "Previous_Directors_More_Than_12_Months",
              )}
            </strong>
          </td>
        </tr>
        <tr>
          <th>
            <strong>Petitions</strong>
          </th>
          <td colSpan={2}>
            <strong>{countTypeValue(summaryEntry, "Petitions")}</strong>
          </td>
          <th>
            <strong>Shares Issued</strong>
          </th>
          <td>
            <strong>{countTypeValue(summaryEntry, "Shares_Issued")}</strong>
          </td>
        </tr>
        <tr>
          <th>Petitions &lt; 12 Months</th>
          <td colSpan={2}>
            {countTypeValue(summaryEntry, "Petitions_Less_Than_12_Months")}
          </td>
          <th>Paid Capital</th>
          <td>{amountTypeValue(summaryEntry, "Paid_Capital")}</td>
        </tr>
        <tr>
          <th>Petitions &gt; 12 Months</th>
          <td colSpan={2}>
            {countTypeValue(summaryEntry, "Petitions_More_Than_12_Months")}
          </td>
          <th>Unpaid Capital</th>
          <td>{amountTypeValue(summaryEntry, "Unpaid_Capital")}</td>
        </tr>
        <tr>
          <th colSpan={3}>
            <strong>File Notes</strong>
          </th>
          <th>
            <strong>Shareholders</strong>
          </th>
          <td>
            <strong>{countTypeValue(summaryEntry, "Shareholders")}</strong>
          </td>
        </tr>
        <tr>
          <th>File Notes</th>
          <td colSpan={2}>{countTypeValue(summaryEntry, "File_Notes")}</td>
          <th>Individual Shareholders</th>
          <td>{countTypeValue(summaryEntry, "Individual_Officers")}</td>
        </tr>
        <tr>
          <th>File Messages</th>
          <td colSpan={2}>{countTypeValue(summaryEntry, "File_Messages")}</td>
          <th>Organisation Shareholders</th>
          <td>{countTypeValue(summaryEntry, "Organisation_Officers")}</td>
        </tr>
      </tbody>
    );
  },

  _businessInformation(summaryEntry) {
    return (
      <tbody>
        <tr>
          <th>
            <strong>Court Writs</strong>
          </th>
          <td>
            <strong>{countTypeValue(summaryEntry, "Writs_and_Summons")}</strong>
          </td>
          <td>
            <strong>
              {amountTypeValue(summaryEntry, "Writs_and_Summons_Value")}
            </strong>
          </td>
          <th>
            <strong>Business Proprietors</strong>
          </th>
          <td>
            <strong>{countTypeValue(summaryEntry, "Proprietors")}</strong>
          </td>
        </tr>
        <tr>
          <th>Writs &lt; 12 Months</th>
          <td>
            {countTypeValue(
              summaryEntry,
              "Writs_and_Summons_Less_Than_12_Months",
            )}
          </td>
          <td>
            {amountTypeValue(
              summaryEntry,
              "Writs_and_Summons_Less_Than_12_Months_Value",
            )}
          </td>
          <th>Individual Proprietors</th>
          <td>{countTypeValue(summaryEntry, "Individual_Proprietors")}</td>
        </tr>
        <tr>
          <th>Writs &gt; 12 Months</th>
          <td>
            {countTypeValue(
              summaryEntry,
              "Writs_and_Summons_More_Than_12_Months",
            )}
          </td>
          <td>
            {amountTypeValue(
              summaryEntry,
              "Writs_and_Summons_More_Than_12_Months_Value",
            )}
          </td>
          <th>Organisation Proprietors</th>
          <td>{countTypeValue(summaryEntry, "Organisation_Proprietors")}</td>
        </tr>
        <tr>
          <th>
            <strong>Court Actions</strong>
          </th>
          <td>
            <strong>{countTypeValue(summaryEntry, "Judgements")}</strong>
          </td>
          <td>
            <strong>{amountTypeValue(summaryEntry, "Judgements_Value")}</strong>
          </td>
          <th colSpan={2}>
            <strong>File Notes</strong>
          </th>
        </tr>
        <tr>
          <th>Actions &lt; 12 Months</th>
          <td>
            {countTypeValue(summaryEntry, "Judgements_Less_Than_12_Months")}
          </td>
          <td>
            {amountTypeValue(
              summaryEntry,
              "Judgements_Less_Than_12_Months_Value",
            )}
          </td>
          <th>File Notes</th>
          <td>{countTypeValue(summaryEntry, "File_Notes")}</td>
        </tr>
        <tr>
          <th>Actions &gt; 12 Months</th>
          <td>
            {countTypeValue(summaryEntry, "Judgements_More_Than_12_Months")}
          </td>
          <td>
            {amountTypeValue(
              summaryEntry,
              "Judgements_More_Than_12_Months_Value",
            )}
          </td>
          <th>File Messages</th>
          <td>{countTypeValue(summaryEntry, "File_Messages")}</td>
        </tr>
      </tbody>
    );
  },

  _information() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    if (isCompany(entityType)) return this._companyInformation(summaryEntry);

    return this._businessInformation(summaryEntry);
  },

  render() {
    const { isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="Public Record Information"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>{this._information()}</table>
        </div>
      </ReviewContainer>
    );
  },
});

export default PublicRecordInformation;
