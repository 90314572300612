import get from "lodash.get";
import moment from "moment-timezone";
import store from "stores/store";
import isBlank from "utils/isBlank";

import { displayEmpty } from "./formatting";

export function stringToSimpleDate(strDate) {
  if (strDate === null || typeof strDate === "undefined") {
    return displayEmpty("");
  }

  return moment(strDate).format("DD MMM YYYY");
}

export function formatDate(strDate, format) {
  if (strDate === null || typeof strDate === "undefined") {
    return displayEmpty("");
  }
  return moment(strDate).format(format);
}
/* eslint-enable max-classes-per-file */

export function formatDateFromObject(dateObject, format = "DD MMMM YYYY") {
  if (isBlank(dateObject)) {
    return displayEmpty("");
  }

  const { day, month, year, hour, minute, second } = dateObject;

  return moment({
    day,
    hour,
    minute,
    month: month - 1,
    second,
    year,
  }).format(format);
}

export function defaultTimeZone() {
  const currentEntityId = get(
    store.getState(),
    "current_user.current_entity.id",
  );
  const currentUserPreference = get(
    store.getState(),
    "current_user.data.data.attributes.preference",
    {},
  );
  const currentEntityTimezone = get(
    store.getState(),
    "current_user.current_entity.attributes.timezone",
    "Pacific/Auckland",
  );
  const timezonePreferenceExist = currentUserPreference[currentEntityId];

  let timezone = currentEntityTimezone;
  if (timezonePreferenceExist) {
    timezone = currentUserPreference[currentEntityId].timezone;
  }

  return timezone || currentEntityTimezone;
}

export function formatLocalTime(utcTimeStamp, timeFormat = "date") {
  const formatMap = {
    date: "DD/MM/YY",
    minute: "DD/MM/YYYY HH:mm",
  };
  let format = timeFormat;
  if (Object.keys(formatMap).includes(timeFormat)) {
    format = formatMap[timeFormat];
  }
  return moment(utcTimeStamp)
    .tz(defaultTimeZone())
    .format(format);
}
