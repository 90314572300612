import React from "react";
import isBlank from "utils/isBlank";
import { pdfjs } from "react-pdf";
import prettyBytes from "pretty-bytes";
import styles from "./css/FileWidget.css";

class FileWidget extends React.Component {
  state = {
    isLoading: false,
    isLoaded: false,
    isDrag: false,
    btnText: "or browse",
    text: this.props.text || "",
    isAcceptType: false,
    isFileSizeInvalid: false,
    pdf_too_long: false,
  }

  setDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isDrag: true,
      text: "Let it go, let it go",
    });
  }

  setDragOut = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState(this.getDefaultState());
  }

  fileDrop = (event) => {
    const { showPricingPlan, HandlePricingPlan } = this.props;
    event.preventDefault();
    event.stopPropagation();
    if (showPricingPlan) {
      HandlePricingPlan();
      this.setState({
        isDrag: false,
        isLoading: false,
        text: "Drag logo here",
      });
    } else {
      this.setState({
        isDrag: false,
        isLoading: true,
        text: "Let it go, let it go",
      });
      this.processFile(event.dataTransfer.files);
    }
  }

  fileAdded = (event) => {
    const { showPricingPlan, HandlePricingPlan } = this.props;
    event.preventDefault();
    event.stopPropagation();
    if (showPricingPlan) {
      HandlePricingPlan();
      event.target.value = null;
    } else {
      this.setState({
        isLoading: true,
      });
      this.processFile(event.target.files);
    }
  }

  fileReady = (data) => {
    const { handleFileCallback } = this.props;
    this.setState({
      isLoading: false,
      isLoaded: true,
      btnText: "Change",
      pdf_too_long: false,
    });
    handleFileCallback(data.target.result);
  }

  pdfFileReady = (e) => {
    const { handleFileCallback, accept_single_page } = this.props;

    let result = e.target.result;
    pdfjs
      .getDocument({ data: atob(result.split(",")[1]) })
      .promise.then(doc => {
        var numPages = doc.numPages;
        if (accept_single_page && numPages > 1) {
          this.setState({
            pdf_too_long: true,
          });
        } else {
          this.fileReady(e);
        }
      });
  }

  processFile = (files) => {
    if (files && files[0]) {
      let file = files[0];
      if (this.checkValid(file)) {
        var reader = new FileReader();
        if (file.type === "application/pdf") {
          reader.onload = this.pdfFileReady;
        } else {
          reader.onload = this.fileReady;
        }
        reader.readAsDataURL(file);
      }
    }
  }

  checkValid = (file) => {
    const { accept } = this.props;

    if (accept) {
      let isValid =
        accept.indexOf(
          file.name
            .split(".")
            .pop()
            .toLowerCase(),
        ) > 0;
      this.setState({ isAcceptType: !isValid });

      if (!isValid) {
        return false;
      }
    }

    if (!this.validateMaxFileSize(file)) {
      return false;
    }

    return true;
  }

  validateMaxFileSize = (file) => {
    const { maxFileSize } = this.props;

    if (isBlank(maxFileSize)) {
      return true;
    }

    const isValid = file.size <= maxFileSize;

    this.setState({ isFileSizeInvalid: !isValid });

    return isValid;
  }

  renderInvalidFileSizeError = () => {
    const { isFileSizeInvalid } = this.state;

    if (!isFileSizeInvalid) {
      return;
    }

    const { maxFileSize } = this.props;

    return (
      <p className={styles.error}>
        Please upload file with a file size of {prettyBytes(maxFileSize)}
      </p>
    );
  }

  render() {
    const {
      completeWidget,
      theme,
      accept,
      id,
      loading,
      disabled,
      disableChangeButton,
      viewSrc,
    } = this.props;
    const {
      isDrag,
      isLoading,
      isLoaded,
      btnText,
      text,
      isAcceptType,
      pdf_too_long,
      accept_single_page,
    } = this.state;
    let theme_style = styles;
    let label_text = btnText;
    let input_id = "uploadFile";

    if (id) {
      input_id = id;
    }

    if (theme) {
      theme_style = theme;
    }

    // Allows for drag hover styles.
    var containerStyles = theme_style.container;

    if (isDrag) {
      containerStyles = theme_style.dragover;
    }

    if (isLoaded) {
      containerStyles = theme_style.completed;
    }

    // Allows for is loading styles
    var innerStyles = theme_style.inner;

    // Allows a parent to define the completed Widget, ie logo image or file icon etc.
    var widget = <p className={theme_style.text}>{text}</p>;
    if (completeWidget) {
      widget = completeWidget;
      containerStyles = theme_style.completed;
      label_text = "change";
    }

    var label = theme_style.label;
    if (loading) {
      label = `${styles.loading} ${label}`;
      label_text = "saving";
    }
    return (
      <div
        className={containerStyles}
        onDragLeave={this.setDragOut}
        onDragExit={this.setDragOut}
        onDragOver={this.setDragOver}
        onDragEnter={this.setDragOver}
        onDrop={this.fileDrop}
      >
        <div className={innerStyles}>
          <div className={theme_style.dots}>
            {widget}
            {disabled || (
              <div>
                <input
                  onChange={this.fileAdded}
                  type="file"
                  name="logoFile"
                  id={input_id}
                  className={theme_style.file}
                  accept={accept}
                />
                {!disableChangeButton && (
                  <label className={label} htmlFor={input_id}>
                    {label_text}
                  </label>
                )}
                {isAcceptType && (
                  <p className={viewSrc ? styles.loaded_error : styles.error}>Please select a correct file type.</p>
                )}
                {accept_single_page && pdf_too_long && (
                  <div style={{ color: "red", marginTop: "0.5em" }}>
                    Please upload a 1-paged PDF.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {this.renderInvalidFileSizeError()}
      </div>
    );
  }
}

export default FileWidget;
