/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

/* Import CSS */
import styles from "./css/ReviewRejectTerms.css";

//import { saveSupplierOnboardingStates } from '../../../state/actions';
import { setTermsRejectReasonFormValue } from "../../actions/review";

/* Import components */
import CircleModal from "../../../shared/components/widgets/static/CircleModal";
import Button from "../../../shared/components/inputs/Button";
import TextArea from "modules/shared/components/inputs/TextArea";

var ReviewRejectTerms = createClass({
  getInitialState: function() {
    return { canceling: false };
  },

  handleChange: function(event) {
    const { dispatch } = this.props;
    dispatch(setTermsRejectReasonFormValue(event.target.value));
  },

  skip: function() {
    browserHistory.push("/user/login");
  },

  send: function() {
    // Send a notification here...
    // dispatch()
    browserHistory.push("/user/login");
  },

  render: function() {
    const { title, handleDismiss, field_value } = this.props;

    return (
      <CircleModal
        title={title}
        title_css="title_small"
        dismissHandler={handleDismiss}
      >
        <div className={styles.fieldset}>
          <TextArea
            handleChange={this.handleChange}
            label="Feedback"
            value={field_value}
          />
        </div>
        <div className={styles.button}>
          <Button white={true} text="skip" handleClick={this.skip} />
          <Button text="send" handleClick={this.send} />
        </div>
      </CircleModal>
    );
  },
});

var defaults = {
  title: "We're sorry it didn't work out",
};

module.exports = connect((state, ownProps) => {
  return {
    title: defaults.title,
    field_value: state.cob_review.terms_reject_reason_text,
  };
})(ReviewRejectTerms);
