import styles from "modules/shared/components/containers/css/FixedContent.css";
import PopperTooltip from "modules/shared/components/widgets/interactive/PopperToolTip.js";
import React from "react";

function containerClassName(props) {
  const {
    bottomSeparatorOffset,
    noOverflowX,
    withBottomMargin,
    withBottomSeparator,
    withSideMargin,
    withSidePadding,
    withBottomPadding,
    withWhiteBackgroundColor,
  } = props;
  const defaultClassNames = [styles.container];

  if (withBottomMargin) {
    defaultClassNames.push(styles.with_bottom_margin);
  }

  if (withBottomSeparator) {
    defaultClassNames.push(styles.with_bottom_separator);
  }

  if (withSideMargin) {
    defaultClassNames.push(styles.with_side_margin);
  }

  if (withSidePadding) {
    defaultClassNames.push(styles.with_side_padding);
  }

  if (withBottomPadding) {
    defaultClassNames.push(styles.with_bottom_padding);
  }

  if (withWhiteBackgroundColor) {
    defaultClassNames.push(styles.with_white_background_color);
  }

  if (bottomSeparatorOffset) {
    defaultClassNames.push(styles[`bottom_separator_offset_${bottomSeparatorOffset}`])
  }

  if (noOverflowX) {
    defaultClassNames.push(styles.no_overflow_x);
  }

  return defaultClassNames.join(" ");
}

export default function FixedContent(props) {
  const { children, header, toolTip } = props;

  let headerContent = <div className={styles.header}>{header}</div>;
  if (toolTip) {
    headerContent = (
      <div className={styles.header}>
        <PopperTooltip
          title={toolTip}
          placement="bottom"
          isVisibleOnMobile={false}
        >
          <span>{header}</span>
        </PopperTooltip>
      </div>
    );
  }

  return (
    <div className={containerClassName(props)}>
      {headerContent}
      {children}
    </div>
  );
}

FixedContent.defaultProps = {
  bottomSeparatorOffset: 0,
  header: "",
  noOverflowX: false,
  withBottomMargin: true,
  withBottomPadding: false,
  withBottomSeparator: true,
  withSideMargin: false,
  withSidePadding: false,
  withWhiteBackgroundColor: false,
};
