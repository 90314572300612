import styled, { css } from "styled-components";
import { SPACING } from "variables/spacing";
import { THEME_COLORS } from "variables/theme";
import { GLOBAL_ANIMATION } from "variables/utils";

export const InnerWrapper = styled.div`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: ${SPACING.sm};
  }
`;

export const Status = styled.div`
  text-align: center;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50rem;
  min-height: 25rem;
  text-align: center;

  > *:not(:last-child) {
    margin-bottom: ${SPACING.sm};
  }

  ${props => !props.hasFile && css`
    ${InnerWrapper} {
      outline: 2px dashed grey;
      outline-offset: -1rem;
      padding: 4rem;
    }
  `}

  ${props => props.hasError && css`
    ${InnerWrapper} {
      outline-color: ${THEME_COLORS.danger};
    }
  `}
`;
