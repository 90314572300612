import React from "react";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

import styles from "../css/material-select.css";

class CountryCodeDropdown extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      countryCodeValue,
      label,
      countryCodeError,
      handleChange,
      countryCodeId,
      hidden,
      countryCodes,
    } = this.props;

    return (
      <div className={styles.material_container}>
        <FormControl
          className={`${styles.material_form_control}`}
          error={Boolean(countryCodeError)}
        >
          <InputLabel
            htmlFor="country-code-select"
            classes={{
              root: styles.material_label,
              shrink: styles.material_label_shrink,
            }}
          >
            {label}
          </InputLabel>
          <Select
            name={countryCodeId}
            value={countryCodeValue}
            id="country-code-select"
            className={styles.material_select}
            startAdornment={<span />}
            inputProps={{
              onChange: e => handleChange(e.target),
            }}
            classes={{
              root: styles.material_menuitem,
            }}
          >
            {countryCodes.map(code => (
              <MenuItem key={code.value} name={code.name} value={code.value}>
                {code.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormHelperText
          id="weight-helper-text"
          error={Boolean(countryCodeError)}
          className={styles.material_helper_text}
          style={
            Boolean(countryCodeError)
              ? { fontSize: 10, fontWeight: 800 }
              : { fontSize: 10, fontWeight: 400 }
          }
        ></FormHelperText>
      </div>
    );
  }
}

CountryCodeDropdown.defaultProps = {
  countryCodeValue: "",
};

module.exports = connect((state, ownProps) => {
  const countryCodes = [
    {
      id: ownProps.countryCodeId,
      name: ownProps.countryCodeId,
      label: "+64",
      value: "+64",
    },
    {
      id: ownProps.countryCodeId,
      name: ownProps.countryCodeId,
      label: "+61",
      value: "+61",
    },
  ];

  return {
    countryCodes,
  };
})(CountryCodeDropdown);
