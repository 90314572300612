/* eslint-disable no-debugger */
import {
  MANAGE_PROFILE_ADD_ANOTHER_PERSON,
  MANAGE_PROFILE_ADD_EXCLUDED_SOURCE,
  MANAGE_PROFILE_ANZSIC_CLASSES_ERROR,
  MANAGE_PROFILE_ANZSIC_CLASSES_START,
  MANAGE_PROFILE_ANZSIC_CLASSES_SUCCESS,
  MANAGE_PROFILE_ANZSIC_DIVISIONS_ERROR,
  MANAGE_PROFILE_ANZSIC_DIVISIONS_START,
  MANAGE_PROFILE_ANZSIC_DIVISIONS_SUCCESS,
  MANAGE_PROFILE_ANZSIC_GROUPS_ERROR,
  MANAGE_PROFILE_ANZSIC_GROUPS_START,
  MANAGE_PROFILE_ANZSIC_GROUPS_SUCCESS,
  MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_ERROR,
  MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_START,
  MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_SUCCESS,
  MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_ERROR,
  MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_START,
  MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_SUCCESS,
  MANAGE_PROFILE_CLEAR_SETTINGS_BUSINESS_INFO,
  MANAGE_PROFILE_CLEAR_SETTINGS_INVOICE,
  MANAGE_PROFILE_CLEAR_SETTINGS_KEY_CONTACT,
  MANAGE_PROFILE_CLEAR_STATE,
  MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_ERROR,
  MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_START,
  MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_SUCCESS,
  MANAGE_PROFILE_CREATE_USER_ERROR,
  MANAGE_PROFILE_CREATE_USER_START,
  MANAGE_PROFILE_CREATE_USER_SUCCESS,
  MANAGE_PROFILE_GET_COLOR_PALETTE_ERROR,
  MANAGE_PROFILE_GET_COLOR_PALETTE_START,
  MANAGE_PROFILE_GET_COLOR_PALETTE_SUCCESS,
  MANAGE_PROFILE_GET_EMAIL_TEMPLATE_ERROR,
  MANAGE_PROFILE_GET_EMAIL_TEMPLATE_START,
  MANAGE_PROFILE_GET_EMAIL_TEMPLATE_SUCCESS,
  MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_ERROR,
  MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_START,
  MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_SUCCESS,
  MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_ERROR,
  MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_START,
  MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_SUCCESS,
  MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_ERROR,
  MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_START,
  MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_SUCCESS,
  MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_ERROR,
  MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_START,
  MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_SUCCESS,
  MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_ERROR,
  MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_START,
  MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_SUCCESS,
  MANAGE_PROFILE_LOAD_CARD_ERROR,
  MANAGE_PROFILE_LOAD_CARD_START,
  MANAGE_PROFILE_LOAD_CARD_SUCCESS,
  MANAGE_PROFILE_LOAD_CURRENT_ENTITY_START,
  MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_ERROR,
  MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_START,
  MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_SUCCESS,
  MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_ERROR,
  MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_START,
  MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_SUCCESS,
  MANAGE_PROFILE_LOAD_PEOPLE_ERROR,
  MANAGE_PROFILE_LOAD_PEOPLE_START,
  MANAGE_PROFILE_LOAD_PEOPLE_SUCCESS,
  MANAGE_PROFILE_LOAD_TIMEZONE_ERROR,
  MANAGE_PROFILE_LOAD_TIMEZONE_START,
  MANAGE_PROFILE_LOAD_TIMEZONE_SUCCESS,
  MANAGE_PROFILE_LOAD_USERS_ERROR,
  MANAGE_PROFILE_LOAD_USERS_START,
  MANAGE_PROFILE_LOAD_USERS_SUCCESS,
  MANAGE_PROFILE_LOAD_USER_PROFILE_ERROR,
  MANAGE_PROFILE_LOAD_USER_PROFILE_START,
  MANAGE_PROFILE_LOAD_USER_PROFILE_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_CREDIT_CHECK_RULES,
  MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_IDENTIFICATION_CHECK_RULES,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_AML_CHECK_LEVEL,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_INITIAL_VALUES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_PASSWORD,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_SHOW_AUTH_FIELDS,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_TYPE,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_USERNAME,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_GUARENTEES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK_LEVEL,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_MINIMUM_GUARANTEES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_AML_CHECK_PROVIDER,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_CREDIT_CHECK_RULES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_IDENTIFICATION_CHECK_RULES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_SUCCESS,
  MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_ERROR,
  MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_START,
  MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_SUCCESS,
  MANAGE_PROFILE_PREVIEW_EMAIL_ERROR,
  MANAGE_PROFILE_PREVIEW_EMAIL_START,
  MANAGE_PROFILE_PREVIEW_EMAIL_SUCCESS,
  MANAGE_PROFILE_REMOVE_EXCLUDED_SOURCE,
  MANAGE_PROFILE_REMOVE_PERSON,
  MANAGE_PROFILE_REMOVE_USER_ERROR,
  MANAGE_PROFILE_REMOVE_USER_START,
  MANAGE_PROFILE_REMOVE_USER_SUCCESS,
  MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_ERROR,
  MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_START,
  MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_SUCCESS,
  MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_ERROR,
  MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_START,
  MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_SUCCESS,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_COMPANY_NAME,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_CONTACT_PHONE_NUMBER,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_PHYSICAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_POSTAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_REGION,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_START,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_STATE,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_TRADING_NAME,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_EMAIL,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_FIRST_NAME,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_LAST_NAME,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_PHONE_NUMBER,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_START,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_ERROR,
  MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_START,
  MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_SUCCESS,
  MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT,
  MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_START,
  MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_ERROR,
  MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_START,
  MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_SUCCESS,
  MANAGE_PROFILE_SETTINGS_INVOICE_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_INVOICE_SET_START,
  MANAGE_PROFILE_SETTINGS_INVOICE_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_LOGO_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_LOGO_SET_START,
  MANAGE_PROFILE_SETTINGS_LOGO_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_START,
  MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
  MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_ERROR,
  MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_START,
  MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
  MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_CLEAR,
  MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_ERROR,
  MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_START,
  MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_SUCCESS,
  MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_ERROR,
  MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_START,
  MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_SUCCESS,
  MANAGE_PROFILE_SETTINGS_REQUIRES_TRUST_DEED,
  MANAGE_PROFILE_SETTINGS_SELECT_PHYSICAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_SELECT_POSTAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_SET_CREDIT_WORKS_STATUS,
  MANAGE_PROFILE_SETTINGS_SET_INVOICE_DAY,
  MANAGE_PROFILE_SETTINGS_SET_INVOICE_DUE,
  MANAGE_PROFILE_SETTINGS_SET_INVOICE_FREQUENCY,
  MANAGE_PROFILE_SETTINGS_SET_MIN_APPROVERS,
  MANAGE_PROFILE_SETTINGS_SET_PARTNERSHIP_AGREEMENT_UPLOAD_VISIBLE,
  MANAGE_PROFILE_SETTINGS_SET_PHYSICAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_SET_POSTAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_SET_TRUST_DEED_UPLOAD_VISIBLE,
  MANAGE_PROFILE_SETTINGS_TEMP_LOGO,
  MANAGE_PROFILE_SET_ANZSIC_CLASS,
  MANAGE_PROFILE_SET_ANZSIC_DIVISION,
  MANAGE_PROFILE_SET_ANZSIC_GROUP,
  MANAGE_PROFILE_SET_ANZSIC_SUBDIVISION,
  MANAGE_PROFILE_SET_APPROVAL_LEVELS_ERROR,
  MANAGE_PROFILE_SET_APPROVAL_LEVELS_START,
  MANAGE_PROFILE_SET_APPROVAL_LEVELS_SUCCESS,
  MANAGE_PROFILE_SET_COLOR_PALETTE,
  MANAGE_PROFILE_SET_CUSTOM_TERMS_INTENT,
  MANAGE_PROFILE_SET_EMAIL_TEMPLATE,
  MANAGE_PROFILE_SET_EMAIL_TEMPLATE_VALUE,
  MANAGE_PROFILE_SET_EXCLUDED_SOURCES,
  MANAGE_PROFILE_SET_EXCLUDED_SOURCES_ERROR,
  MANAGE_PROFILE_SET_EXCLUDED_SOURCES_INDEX,
  MANAGE_PROFILE_SET_EXCLUDED_SOURCES_START,
  MANAGE_PROFILE_SET_EXCLUDED_SOURCES_SUCCESS,
  MANAGE_PROFILE_SET_GUARANTOR_CUSTOM_TERMS_INTENT,
  MANAGE_PROFILE_SET_GUARANTOR_TERMS_DOCUMENT,
  MANAGE_PROFILE_SET_GUARANTOR_VIEWED_TERMS,
  MANAGE_PROFILE_SET_PAPERLESS_CUSTOM_TERMS_INTENT,
  MANAGE_PROFILE_SET_PAPERLESS_TERMS_DOCUMENT,
  MANAGE_PROFILE_SET_PAPERLESS_VIEWED_TERMS,
  MANAGE_PROFILE_SET_PARTY_DETAILS,
  MANAGE_PROFILE_SET_PARTY_DETAILS_ERROR,
  MANAGE_PROFILE_SET_PARTY_DETAILS_INDEX,
  MANAGE_PROFILE_SET_PARTY_DETAILS_START,
  MANAGE_PROFILE_SET_PARTY_DETAILS_SUCCESS,
  MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_CREDIT_CHECK,
  MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_GUARANTEES,
  MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_IDENTIFICATION_CHECK,
  MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_TRADE_REFERENCE_CHECK,
  MANAGE_PROFILE_SET_QUESTION,
  MANAGE_PROFILE_SET_QUESTIONS_APPLIES_ERROR,
  MANAGE_PROFILE_SET_QUESTIONS_APPLIES_START,
  MANAGE_PROFILE_SET_QUESTIONS_APPLIES_SUCCESS,
  MANAGE_PROFILE_SET_QUESTIONS_ERROR,
  MANAGE_PROFILE_SET_QUESTIONS_START,
  MANAGE_PROFILE_SET_QUESTIONS_SUCCESS,
  MANAGE_PROFILE_SET_QUESTION_INDEX,
  MANAGE_PROFILE_SET_TERMS_DOCUMENT,
  MANAGE_PROFILE_SET_VIEWED_TERMS,
  MANAGE_PROFILE_TERMS_DOCUMENT_SET_ERROR,
  MANAGE_PROFILE_TERMS_DOCUMENT_SET_START,
  MANAGE_PROFILE_TERMS_DOCUMENT_SET_SUCCESS,
  MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_ERROR,
  MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_MINIMUM_TRADE_REFERENCES,
  MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_START,
  MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_SUCCESS,
  MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_TRADE_REFERENCE_CHECK,
  MANAGE_PROFILE_UPDATE_COLOR_PALETTE_ERROR,
  MANAGE_PROFILE_UPDATE_COLOR_PALETTE_START,
  MANAGE_PROFILE_UPDATE_COLOR_PALETTE_SUCCESS,
  MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_ERROR,
  MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_START,
  MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_SUCCESS,
  MANAGE_PROFILE_UPDATE_ENTITY_STATUS_ERROR,
  MANAGE_PROFILE_UPDATE_ENTITY_STATUS_START,
  MANAGE_PROFILE_UPDATE_ENTITY_STATUS_SUCCESS,
} from "./constants";
import {
  getUserData,
  loadCurrentEntity as userLoadCurrentEntity,
} from "modules/user/actions";

import * as Sentry from "@sentry/browser";
import api from "../../api";
import isBlank from "utils/isBlank";
import { setChurnZeroAttributes } from "utils/churnZero";
import { setTheme } from "modules/shared/helpers/colorPalettes";
import { termsDocuments } from "utils/termsDocuments";
import AddonConfigModel from "models/AddonConfigModel";
import UserModel from "models/UserModel";
import get from "lodash.get";

export function loadCurrentEntity() {
  return dispatch => {
    // This fires off a saga. See rootSaga for details.
    dispatch({
      type: MANAGE_PROFILE_LOAD_CURRENT_ENTITY_START,
    });
  };
}

export function getColorPalette() {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_PROFILE_GET_COLOR_PALETTE_START });

    const accessToken = getState().current_user.access_token;
    const entityId = getState().current_user.current_entity.id;
    const colorPaletteAPI = api("color_palettes", accessToken, entityId);

    try {
      const response = await colorPaletteAPI.getColorPalette(entityId);

      dispatch({
        payload: response.data,
        type: MANAGE_PROFILE_GET_COLOR_PALETTE_SUCCESS,
      });
      setTheme(response.data.theme);
    } catch (error) {
      dispatch({
        payload: error,
        type: MANAGE_PROFILE_GET_COLOR_PALETTE_ERROR,
      });
    }
  };
}

export function setColorPalette(data) {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_PROFILE_UPDATE_COLOR_PALETTE_START });

    const accessToken = getState().current_user.access_token;
    const entityId = getState().current_user.current_entity.id;
    const colorPaletteAPI = api("color_palettes", accessToken, entityId);

    try {
      const response = await colorPaletteAPI.updateColorPalette(entityId, data);

      dispatch({
        payload: response.data,
        type: MANAGE_PROFILE_UPDATE_COLOR_PALETTE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: MANAGE_PROFILE_UPDATE_COLOR_PALETTE_ERROR,
      });
    }
  };
}

export function loadPeopleDetails() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_LOAD_PEOPLE_START,
    });

    const entities = api(
      "entities",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    entities.getEntityPeople(
      getState().current_user.current_entity.id,
      data => {
        const people = new Array();
        data.data.data.forEach(person => {
          if (person.type === "people") {
            people.push({
              name: person.attributes.name,
              type: person.attributes.legal_type,
              email: person.attributes.email,
              percentage_share: person.attributes.percentage_share,
              id: person.id,
            });
          }
        });
        dispatch({
          type: MANAGE_PROFILE_LOAD_PEOPLE_SUCCESS,
          payload: people,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_PEOPLE_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function loadUserProfile(user_id, entityId = null) {
  return (dispatch, getState) => {
    dispatch({ type: MANAGE_PROFILE_LOAD_USER_PROFILE_START });

    const users = api(
      "users",
      getState().current_user.access_token,
      entityId || getState().current_user.current_entity.id,
    );

    users.getUserInfo(
      user_id,
      result => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_USER_PROFILE_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_USER_PROFILE_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function loadUsers() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_LOAD_USERS_START,
    });

    const entities = api(
      "entities",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    entities.getEntityUsers(
      getState().current_user.current_entity.id,
      data => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_USERS_SUCCESS,
          payload: data.data.data,
        });

        // ChurnZero
        if (getState().current_user.current_entity.attributes.supplier) {
          setChurnZeroAttributes(
            getState().current_user.current_entity.id,
            getState,
          );
        }
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_USERS_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function updateEntityLogoData(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_SETTINGS_LOGO_SET_START,
    MANAGE_PROFILE_SETTINGS_LOGO_SET_SUCCESS,
    MANAGE_PROFILE_SETTINGS_LOGO_SET_ERROR,
  );
}

export function updateEntityBasicInfo(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_SETTINGS_BUSINESS_SET_START,
    MANAGE_PROFILE_SETTINGS_BUSINESS_SET_SUCCESS,
    MANAGE_PROFILE_SETTINGS_BUSINESS_SET_ERROR,
  );
}

export function updateEntityBillingDetails(attributes, successCallback, errorCallback) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_START,
      payload: attributes,
    });

    const entity = api("entities", getState().current_user.access_token);
    const id = getState().current_user.current_entity.id;

    entity.assignBillingDetails(
      id,
      attributes,
      result => {
        dispatch(getUserData(getState().current_user.access_token));
        // dispatch(loadCurrentEntity());

        dispatch({
          type: MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_SUCCESS,
          payload: {
            id,
            attributes: {
              ...result.data.data.attributes,
            },
          },
          meta: {
            mixpanel: {
              event: "Update billing details",
              props: {
                attributes,
                distinct_id: getState().current_user.data.data.id,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });

        if (successCallback) {
          successCallback();
        }
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_ERROR,
          payload: error,
        });

        if (errorCallback) {
          errorCallback()
        }
      },
    );
  };
}

export function updateEntityProfileStatus(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_UPDATE_ENTITY_STATUS_START,
    MANAGE_PROFILE_UPDATE_ENTITY_STATUS_SUCCESS,
    MANAGE_PROFILE_UPDATE_ENTITY_STATUS_ERROR,
  );
}

export function updatePartyDetails(people, remove_people) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_SET_PARTY_DETAILS_START,
    });

    const people_api = api("people", getState().current_user.access_token);
    const peoplePromises = [];

    people.forEach(item => {
      if (item.id) {
        const attributes = {
          email: item.email,
          name: item.name,
          percentage_share: item.percentage_share,
        };
        peoplePromises.push(people_api.updatePerson(item.id, attributes));
      } else {
        let attributes = item;
        if (
          getState().manage_profile.current_entity.attributes.legal_type ===
          "partnership"
        ) {
          attributes = { ...attributes, legal_type: "partner" };
        } else {
          attributes = { ...attributes, legal_type: "trustee" };
        }
        peoplePromises.push(people_api.createPerson(attributes));
      }
    });

    remove_people.forEach(item => {
      peoplePromises.push(people_api.deletePerson(item));
    });

    return Promise.all(peoplePromises)
      .then(success => {
        dispatch({
          type: MANAGE_PROFILE_SET_PARTY_DETAILS_SUCCESS,
        });
        dispatch(loadPeopleDetails());
      })
      .catch(error => {
        dispatch({
          type: MANAGE_PROFILE_SET_PARTY_DETAILS_ERROR,
          payload: error,
        });
      });
  };
}

export function updateTermsDoc(use_custom, src, terms_type) {
  return (dispatch, getState) => {
    const dispatchStartTypes = {
      guarantor: MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_START,
      paperless: MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_START,
      supplier: MANAGE_PROFILE_TERMS_DOCUMENT_SET_START,
    };

    dispatch({ type: dispatchStartTypes[terms_type] });

    const terms_documents = api(
      "terms_documents",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    const entities = api("entities", getState().current_user.access_token);
    const entity = getState().manage_profile.current_entity;
    const entityId = entity.id;
    let terms_document = null;

    terms_document = termsDocuments(
      getState().manage_profile.entity_includes,
      "terms_documents",
      terms_type,
    );

    let documentFromHeadquarter = false;
    if (terms_document) {
      documentFromHeadquarter = terms_document.attributes.entity_id != entityId;
    }

    // Delete current terms document
    if (terms_document && !use_custom && !documentFromHeadquarter) {
      terms_documents.deleteTermsDocument(
        terms_document.id,
        () => {
          dispatch(loadCurrentEntity());

          const dispatchSuccessTypes = {
            guarantor: MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_SUCCESS,
            paperless: MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_SUCCESS,
            supplier: MANAGE_PROFILE_TERMS_DOCUMENT_SET_SUCCESS,
          };
          dispatch({
            payload: entity.id,
            type: dispatchSuccessTypes[terms_type],
          });
          dispatch(loadCurrentEntity());
        },
        error => {
          const dispatchErrorTypes = {
            guarantor: MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_ERROR,
            paperless: MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_ERROR,
            supplier: MANAGE_PROFILE_TERMS_DOCUMENT_SET_ERROR,
          };

          dispatch({
            payload: error,
            type: dispatchErrorTypes[terms_type],
          });
        },
      );
    }

    // If using defaults and don't have a doc
    if (!use_custom) {
      if (terms_type === "supplier") {
        dispatch(updateSupplierTermsDecision());
      } else if (terms_type === "guarantor") {
        dispatch(updateGuarantorTermsDecision());
      } else if (terms_type === "paperless") {
        dispatch(updatePaperlessTermsDecision());
      }
    }

    const dispatchSetSuccessTypes = {
      guarantor: MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_SUCCESS,
      paperless: MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_SUCCESS,
      supplier: MANAGE_PROFILE_TERMS_DOCUMENT_SET_SUCCESS,
    };

    const dispatchSetErrorTypes = {
      guarantor: MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_ERROR,
      paperless: MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_ERROR,
      supplier: MANAGE_PROFILE_TERMS_DOCUMENT_SET_ERROR,
    };

    // Update the existing terms document
    if (terms_document && use_custom && !documentFromHeadquarter) {
      terms_documents.updateTermsDocument(
        terms_document.id,
        { file: src },
        () => {
          dispatch(loadCurrentEntity());
          dispatch({
            payload: entity.id,
            type: dispatchSetSuccessTypes[terms_type],
          });
          dispatch(loadCurrentEntity());
        },
        error => {
          dispatch({
            payload: error,
            type: dispatchSetErrorTypes[terms_type],
          });
        },
      );
    }

    // Create new terms document
    if (
      (!terms_document && use_custom) ||
      (use_custom && documentFromHeadquarter)
    ) {
      terms_documents.createTermsDocument(
        entityId,
        { file: src, terms_doc_type: terms_type },
        success => {
          dispatch(loadCurrentEntity());
          dispatch({
            payload: entityId,
            type: dispatchSetSuccessTypes[terms_type],
          });
        },
        error => {
          dispatch({
            payload: error,
            type: dispatchSetErrorTypes[terms_type],
          });
        },
      );
    }
  };
}

export function updateEntityContactInfo(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_SETTINGS_CONTACT_SET_START,
    MANAGE_PROFILE_SETTINGS_CONTACT_SET_SUCCESS,
    MANAGE_PROFILE_SETTINGS_CONTACT_SET_ERROR,
  );
}

export function updateCreditLimit(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_START,
    MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_SUCCESS,
    MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_ERROR,
  );
}

export function updateMinimumApprovers(value) {
  const attributes = { minimum_approvers: value };
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_START,
    MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_SUCCESS,
    MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_ERROR,
  );
}

export function updateInvoiceTerms(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_SETTINGS_INVOICE_SET_START,
    MANAGE_PROFILE_SETTINGS_INVOICE_SET_SUCCESS,
    MANAGE_PROFILE_SETTINGS_INVOICE_SET_ERROR,
  );
}

export function updateMandatoryChecks(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_MANDATORY_CHECKS_SET_START,
    MANAGE_PROFILE_MANDATORY_CHECKS_SET_SUCCESS,
    MANAGE_PROFILE_MANDATORY_CHECKS_SET_ERROR,
  );
}

export function updateTradeRefChecks(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_START,
    MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_SUCCESS,
    MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_ERROR,
  );
}

export function updateSupplierTermsDecision() {
  return updateEntitySettings(
    { has_selected_supplier_terms: true },
    MANAGE_PROFILE_TERMS_DOCUMENT_SET_START,
    MANAGE_PROFILE_TERMS_DOCUMENT_SET_SUCCESS,
    MANAGE_PROFILE_TERMS_DOCUMENT_SET_ERROR,
  );
}

export function updateGuarantorTermsDecision() {
  return updateEntitySettings(
    { has_selected_supplier_terms: true },
    MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_START,
    MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_SUCCESS,
    MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_ERROR,
  );
}

export function updatePaperlessTermsDecision() {
  return updateEntitySettings(
    { has_selected_supplier_terms: true },
    MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_START,
    MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_SUCCESS,
    MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_ERROR,
  );
}

export function updateQuestions(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_SET_QUESTIONS_START,
    MANAGE_PROFILE_SET_QUESTIONS_SUCCESS,
    MANAGE_PROFILE_SET_QUESTIONS_ERROR,
  );
}

export function updateQuestionsApplies(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_SET_QUESTIONS_APPLIES_START,
    MANAGE_PROFILE_SET_QUESTIONS_APPLIES_SUCCESS,
    MANAGE_PROFILE_SET_QUESTIONS_APPLIES_ERROR,
  );
}

export function updateExcludedSources(attributes) {
  return updateEntitySettings(
    attributes,
    MANAGE_PROFILE_SET_EXCLUDED_SOURCES_START,
    MANAGE_PROFILE_SET_EXCLUDED_SOURCES_SUCCESS,
    MANAGE_PROFILE_SET_EXCLUDED_SOURCES_ERROR,
  );
}

export function setExcludedSources(excluded_application_sources, index, value) {
  return {
    type: MANAGE_PROFILE_SET_EXCLUDED_SOURCES,
    payload: {
      excluded_application_sources,
      index,
      value,
    },
  };
}

export function setExcludedSourcesIndex(index) {
  return {
    type: MANAGE_PROFILE_SET_EXCLUDED_SOURCES_INDEX,
    payload: index,
  };
}

export function removeExcludedSource(excluded_application_sources, index) {
  return {
    type: MANAGE_PROFILE_REMOVE_EXCLUDED_SOURCE,
    payload: {
      excluded_application_sources,
      index,
    },
  };
}

export function addExcludedReferee(excluded_application_sources) {
  return {
    type: MANAGE_PROFILE_ADD_EXCLUDED_SOURCE,
    payload: {
      excluded_application_sources,
    },
  };
}

export function setQuestionsIndex(index) {
  return {
    type: MANAGE_PROFILE_SET_QUESTION_INDEX,
    payload: index,
  };
}

export function setQuestions(questions, index, value) {
  return {
    type: MANAGE_PROFILE_SET_QUESTION,
    payload: {
      questions,
      index,
      value,
    },
  };
}

export function setInvoiceFrquency(value) {
  return {
    type: MANAGE_PROFILE_SETTINGS_SET_INVOICE_FREQUENCY,
    payload: value,
  };
}

export function setMinimumApprovers(value) {
  return {
    type: MANAGE_PROFILE_SETTINGS_SET_MIN_APPROVERS,
    payload: value,
  };
}

export function setInvoiceDue(value) {
  return {
    type: MANAGE_PROFILE_SETTINGS_SET_INVOICE_DUE,
    payload: value,
  };
}

export function setInvoiceDay(value) {
  return {
    type: MANAGE_PROFILE_SETTINGS_SET_INVOICE_DAY,
    payload: value,
  };
}

export function updateEntityBusinessDescription(descriptionId) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_START,
    });

    const entities = api(
      "entities",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    const id = getState().current_user.current_entity.id;

    entities.setBusinessDescription(
      id,
      descriptionId,
      result => {
        dispatch(loadBusinessDescription());
        dispatch({
          type: MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_SUCCESS,
          payload: id,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_ERROR,
          payload: error,
        });
      },
    );
  };
}

function updateEntitySettings(
  attributes,
  start_action,
  success_action,
  error_action,
  successCallback = () => null,
) {
  return (dispatch, getState) => {
    dispatch({
      type: start_action,
      payload: attributes,
    });

    const entity = api("entities", getState().current_user.access_token);
    const id = getState().current_user.current_entity.id;

    entity.updateEntity(
      id,
      attributes,
      result => {
        dispatch(getUserData(getState().current_user.access_token));
        // dispatch(loadCurrentEntity());

        const requiresApplicantIdentificationCheck =
          result.data.data.attributes.requires_applicant_identification_check ||
          false;
        const requiresGuaranteesIdentificationCheck =
          result.data.data.attributes
            .requires_guarantees_identification_check || false;

        dispatch({
          type: success_action,
          payload: {
            id,
            attributes: {
              ...result.data.data.attributes,
              requires_applicant_identification_check: requiresApplicantIdentificationCheck,
              requires_guarantees_identification_check: requiresGuaranteesIdentificationCheck,
            },
          },
          meta: {
            mixpanel: {
              event: "Update account rules",
              props: {
                attributes,
                distinct_id: getState().current_user.data.data.id,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });

        if (successCallback) {
          successCallback();
        }
      },
      error => {
        dispatch({
          type: error_action,
          payload: error,
        });
      },
    );
  };
}

export function setEntitySettingsTempLogo(value) {
  return {
    type: MANAGE_PROFILE_SETTINGS_TEMP_LOGO,
    payload: value,
  };
}

export function setEntitySettingsCreditLimit(value) {
  return {
    type: MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT,
    payload: value,
  };
}

export function selectPhysicalAddress(dpid) {
  return {
    type: MANAGE_PROFILE_SETTINGS_SELECT_PHYSICAL_ADDRESS,
    payload: dpid,
  };
}

export function selectPostalAddress(dpid) {
  return {
    type: MANAGE_PROFILE_SETTINGS_SELECT_POSTAL_ADDRESS,
    payload: dpid,
  };
}

export function setEntitySettingsFormValue(key, value) {
  let type;
  switch (key) {
    case "company_name":
      type = MANAGE_PROFILE_SETTINGS_BUSINESS_SET_COMPANY_NAME;
      break;
    case "trading_name":
      type = MANAGE_PROFILE_SETTINGS_BUSINESS_SET_TRADING_NAME;
      break;
    case "contact_phone_number":
      type = MANAGE_PROFILE_SETTINGS_BUSINESS_SET_CONTACT_PHONE_NUMBER;
      break;
    case "physical_address":
      type = MANAGE_PROFILE_SETTINGS_BUSINESS_SET_PHYSICAL_ADDRESS;
      break;
    case "postal_address":
      type = MANAGE_PROFILE_SETTINGS_BUSINESS_SET_POSTAL_ADDRESS;
      break;

    case "key_contact_phone_number":
      type = MANAGE_PROFILE_SETTINGS_CONTACT_SET_PHONE_NUMBER;
      break;
    case "key_contact_first_name":
      type = MANAGE_PROFILE_SETTINGS_CONTACT_SET_FIRST_NAME;
      break;
    case "key_contact_last_name":
      type = MANAGE_PROFILE_SETTINGS_CONTACT_SET_LAST_NAME;
      break;
    case "key_contact_email":
      type = MANAGE_PROFILE_SETTINGS_CONTACT_SET_EMAIL;
      break;

    case "region":
      type = MANAGE_PROFILE_SETTINGS_BUSINESS_SET_REGION;
      break;
    case "state":
      type = MANAGE_PROFILE_SETTINGS_BUSINESS_SET_STATE;
      break;
  }

  return {
    type,
    payload: value,
  };
}

export function setPostalAddress(dpid) {
  return {
    type: MANAGE_PROFILE_SETTINGS_SET_POSTAL_ADDRESS,
    payload: dpid,
  };
}

export function setPhysicalAddress(dpid) {
  return {
    type: MANAGE_PROFILE_SETTINGS_SET_PHYSICAL_ADDRESS,
    payload: dpid,
  };
}

export function lookupAddress(string, type) {
  return (dispatch, getState) => {
    // If the string is empty, then zero out the lookup list.
    if (string.length === 0) {
      if (type === "Postal") {
        dispatch({
          type: MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_CLEAR,
        });
      } else {
        dispatch({
          type: MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
        });
      }
    } else {
      if (type === "Postal") {
        dispatch({
          type: MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_START,
          payload: string,
        });
      } else {
        dispatch({
          type: MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_START,
          payload: string,
        });
      }

      const addressSearch = api(
        "address_search",
        getState().current_user.address_token,
      );
      const data = {};

      addressSearch.addressSearch(
        result => {
          for (let i = 0; i < result.data.addresses.length; i++) {
            data[result.data.addresses[i].DPID] =
              result.data.addresses[i].FullAddress;
          }

          if (type === "Postal") {
            dispatch({
              type: MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
              payload: data,
            });
          }
        },
        error => {
          if (type === "Postal") {
            dispatch({
              type: MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_ERROR,
              payload: error,
            });
          } else {
            dispatch({
              type: MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_ERROR,
              payload: error,
            });
          }
        },
        {
          params: {
            address: string,
            type,
            region: "New Zealand", // TODO: Replace with data from region drop down
          },
        },
      );
    }
  };
}

//Getters and Setters for anzsicSubitems

export function loadBusinessDescription() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_START,
    });

    const entity = api(
      "entities",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    const anzsic = api(
      "business_descriptions",
      getState().current_user.access_token,
    );
    const id = getState().current_user.current_entity.id;

    entity.getEntityBusinessDescription(
      id,
      result => {
        if (result.data.data === null) {
          dispatch({
            type: MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_SUCCESS,
            payload: null,
          });
          return;
        }

        let payload = {
          ...payload,
          class: {
            title: result.data.data.attributes.name,
            code: result.data.data.attributes.code,
            key: result.data.data.id,
          },
        };

        anzsic.getParentBusinessDescription(
          payload.class.key,
          group => {
            payload = {
              ...payload,
              group: {
                title: group.data.data.attributes.name,
                code: group.data.data.attributes.code,
                key: group.data.data.id,
              },
            };
            dispatch(getAnzsicClasses(group.data.data.id));
            anzsic.getParentBusinessDescription(
              payload.group.key,
              subdivision => {
                payload = {
                  ...payload,
                  subdivision: {
                    title: subdivision.data.data.attributes.name,
                    code: subdivision.data.data.attributes.code,
                    key: subdivision.data.data.id,
                  },
                };
                dispatch(getAnzsicGroups(subdivision.data.data.id));
                anzsic.getParentBusinessDescription(
                  payload.subdivision.key,
                  division => {
                    payload = {
                      ...payload,
                      division: {
                        title: division.data.data.attributes.name,
                        code: division.data.data.attributes.code,
                        key: division.data.data.id,
                      },
                    };
                    dispatch(getAnzsicSubdivisions(division.data.data.id));
                    dispatch({
                      type: MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_SUCCESS,
                      payload,
                    });
                  },
                  error => {
                    dispatch({
                      type: MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_ERROR,
                      payload: error,
                    });
                  },
                );
              },
              error => {
                dispatch({
                  type: MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_ERROR,
                  payload: error,
                });
              },
            );
          },
          error => {
            dispatch({
              type: MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_ERROR,
              payload: error,
            });
          },
        );
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_ERROR,
          payload: error,
        });
      },
    );
  };
}

function anzsicSubitems(id, startAction, successAction, errorAction) {
  return (dispatch, getState) => {
    dispatch({
      type: startAction,
    });

    const anzsic = api(
      "business_descriptions",
      getState().current_user.access_token,
    );
    let data = {};

    anzsic.getBusinessDescription(
      id,
      result => {
        if (result.data.data) {
          data = result.data.included;
        }

        data = data.map(v => {
          return {
            title: v.attributes.name,
            code: v.attributes.code,
            key: v.id,
          };
        });

        data = data.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });

        dispatch({
          type: successAction,
          payload: data,
        });
      },
      error => {
        dispatch({
          type: errorAction,
          payload: error,
        });
      },
      { params: { include: "children" } },
    );
  };
}

export function getAnzsicDivisions() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_ANZSIC_DIVISIONS_START,
    });

    const anzsic = api(
      "business_descriptions",
      getState().current_user.access_token,
    );
    let data = {};

    anzsic.getBusinessDescriptions(
      result => {
        if (result.data.data) {
          data = result.data.data;
        }

        data = data.map(v => {
          return {
            title: v.attributes.name,
            code: v.attributes.code,
            key: v.id,
          };
        });

        data = data.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });

        dispatch({
          type: MANAGE_PROFILE_ANZSIC_DIVISIONS_SUCCESS,
          payload: data,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_ANZSIC_DIVISIONS_ERROR,
          payload: error,
        });
      },
      { params: { "filter[level]": "divisions" } },
    );
  };
}

export function getAnzsicSubdivisions(divisionId) {
  return anzsicSubitems(
    divisionId,
    MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_START,
    MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_SUCCESS,
    MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_ERROR,
  );
}

export function getAnzsicGroups(subdivisionId) {
  return anzsicSubitems(
    subdivisionId,
    MANAGE_PROFILE_ANZSIC_GROUPS_START,
    MANAGE_PROFILE_ANZSIC_GROUPS_SUCCESS,
    MANAGE_PROFILE_ANZSIC_GROUPS_ERROR,
  );
}

export function getAnzsicClasses(groupId) {
  return anzsicSubitems(
    groupId,
    MANAGE_PROFILE_ANZSIC_CLASSES_START,
    MANAGE_PROFILE_ANZSIC_CLASSES_SUCCESS,
    MANAGE_PROFILE_ANZSIC_CLASSES_ERROR,
  );
}

export function setAnzsicDivision(value) {
  return {
    type: MANAGE_PROFILE_SET_ANZSIC_DIVISION,
    payload: value,
  };
}

export function setAnzsicSubdivision(value) {
  return {
    type: MANAGE_PROFILE_SET_ANZSIC_SUBDIVISION,
    payload: value,
  };
}

export function setAnzsicGroup(value) {
  return {
    type: MANAGE_PROFILE_SET_ANZSIC_GROUP,
    payload: value,
  };
}

export function setAnzsicClass(value) {
  return {
    type: MANAGE_PROFILE_SET_ANZSIC_CLASS,
    payload: value,
  };
}

export function setTermsAndConditionsDoc(dataUrl) {
  return {
    type: MANAGE_PROFILE_SET_TERMS_DOCUMENT,
    payload: dataUrl,
  };
}

export function setGuarantorTermsAndConditionsDoc(dataUrl) {
  return {
    type: MANAGE_PROFILE_SET_GUARANTOR_TERMS_DOCUMENT,
    payload: dataUrl,
  };
}

export function setPaperlessTermsAndConditionsDoc(dataUrl) {
  return {
    payload: dataUrl,
    type: MANAGE_PROFILE_SET_PAPERLESS_TERMS_DOCUMENT,
  };
}

export function setCustomTermsIntent(bool) {
  return {
    type: MANAGE_PROFILE_SET_CUSTOM_TERMS_INTENT,
    payload: bool,
  };
}

export function setGuarantorCustomTermsIntent(bool) {
  return {
    type: MANAGE_PROFILE_SET_GUARANTOR_CUSTOM_TERMS_INTENT,
    payload: bool,
  };
}

export function setPaperlessCustomTermsIntent(bool) {
  return {
    payload: bool,
    type: MANAGE_PROFILE_SET_PAPERLESS_CUSTOM_TERMS_INTENT,
  };
}

export function setViewedTerms(bool) {
  return {
    type: MANAGE_PROFILE_SET_VIEWED_TERMS,
    payload: bool,
  };
}

export function setViewedGuarantorTerms(bool) {
  return {
    type: MANAGE_PROFILE_SET_GUARANTOR_VIEWED_TERMS,
    payload: bool,
  };
}

export function setViewedPaperlessTerms(bool) {
  return {
    type: MANAGE_PROFILE_SET_PAPERLESS_VIEWED_TERMS,
    payload: bool,
  };
}

export function setMandatoryCheck(value, payload) {
  let type = null;
  switch (value) {
    case "requires_guarantees":
      type = MANAGE_PROFILE_MANDATORY_CHECKS_SET_GUARENTEES;
      break;
    case "requires_trade_reference_check":
      type = MANAGE_PROFILE_MANDATORY_CHECKS_SET_TRADE_REFERENCE_CHECKS;
      break;
    case "requires_credit_check":
      type = MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK;
      break;
    case "requires_identification_check":
      type = MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK;
      break;
  }
  return {
    type,
    payload,
  };
}

export function setTradeReferenceCheck(value) {
  return {
    type: MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_TRADE_REFERENCE_CHECK,
    payload: value,
  };
}

export function setCreditCheckInitialValues() {
  return {
    type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_INITIAL_VALUES,
  };
}

export function setCreditCheckType(value) {
  return {
    type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_TYPE,
    payload: value,
  };
}

export function setCreditCheckUsername(value) {
  return {
    type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_USERNAME,
    payload: value,
  };
}

export function setCreditCheckPassword(value) {
  return {
    type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_PASSWORD,
    payload: value,
  };
}

export function setCreditCheckShowAuthFields(value) {
  return {
    type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_SHOW_AUTH_FIELDS,
    payload: value,
  };
}

export function validateCreditCheckCredentials(
  credit_check_type,
  username,
  password,
) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_START,
    });

    const creditCheckCredentials = api(
      "credit_check_credentials",
      getState().current_user.access_token,
    );

    creditCheckCredentials.test(
      {
        credit_check_type,
        username,
        password,
      },
      success => {
        dispatch({
          type: MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_SUCCESS,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_ERROR,
          payload: error.data.errors[0].detail,
        });
      },
    );
  };
}

export function setIdentificationCheckLevels(value) {
  return {
    type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK_LEVEL,
    payload: value,
  };
}

export function setMinimumGuarantees(value) {
  return {
    type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_MINIMUM_GUARANTEES,
    payload: value,
  };
}

export function setMinimumTradeReferences(value) {
  return {
    type: MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_MINIMUM_TRADE_REFERENCES,
    payload: value,
  };
}

export function setPartyDetailsIndex(index) {
  return {
    type: MANAGE_PROFILE_SET_PARTY_DETAILS_INDEX,
    payload: index,
  };
}

export function setPartyDetails(index, form_values, form, value) {
  const new_values = form_values.slice();
  new_values[index] = { ...form_values[index], [form]: value };
  return {
    type: MANAGE_PROFILE_SET_PARTY_DETAILS,
    payload: new_values,
  };
}

export function addAnotherPerson(form_values) {
  return (dispatch, getState) => {
    const tmp_form_values = form_values.slice();
    if (
      getState().manage_profile.current_entity.attributes.legal_type ===
      "partnership"
    ) {
      tmp_form_values[tmp_form_values.length] = {
        name: "",
        email: "",
        percentage_share: 50,
      };
    } else {
      tmp_form_values[tmp_form_values.length] = { name: "", email: "" };
    }
    dispatch({
      type: MANAGE_PROFILE_ADD_ANOTHER_PERSON,
      payload: tmp_form_values,
    });
  };
}

export function removePartyDetailsPerson(form_values, index) {
  return (dispatch, getState) => {
    const tmp_form_values = form_values.slice();
    const removed_person = tmp_form_values.splice(index, 1);
    const new_length = Object.keys(tmp_form_values).length;
    const removed_ids = getState().manage_profile.settings_removed_people_ids;
    if (removed_person[0].hasOwnProperty("id")) {
      removed_ids.push(removed_person[0].id);
    }
    dispatch({
      type: MANAGE_PROFILE_REMOVE_PERSON,
      payload: {
        form_values: tmp_form_values,
        removed_ids,
      },
    });
  };
}
export function loadCard() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_LOAD_CARD_START,
    });

    const entityId = getState().current_user.current_entity.id;
    const entities = api(
      "entities",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    entities.getEntityCards(
      entityId,
      result => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_CARD_SUCCESS,
          payload: (result.data.data || [])[0],
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_CARD_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function createUser(attributes, success) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_CREATE_USER_START,
      payload: attributes,
    });

    const users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    users.createUser(
      attributes,
      result => {
        dispatch({
          type: MANAGE_PROFILE_CREATE_USER_SUCCESS,
          payload: attributes,
          meta: {
            mixpanel: {
              event: "Add user",
              props: {
                distinct_id: getState().current_user.data.data.id,
                User: result.data.data.id,
                "User email": attributes.email,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });

        success(result.data.data.id);
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_CREATE_USER_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function createUserEntityLink(attributes, success) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_START,
      payload: attributes,
    });
    const user_entity_links = api(
      "user_entity_links",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    user_entity_links.create(
      attributes,
      result => {
        dispatch({
          type: MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_SUCCESS,
          payload: attributes,
          meta: {
            mixpanel: {
              event: "Create user entity link",
              props: {
                distinct_id: getState().current_user.data.data.id,
                "User entity link": result.data.data.id,
                "User email": attributes.email,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });

        success(result.data.data.id);
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_CREATE_USER_ENTITY_LINK_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function removeUser(id, success) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_REMOVE_USER_START,
      payload: id,
    });

    const users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    users.deleteUser(
      id,
      result => {
        dispatch({
          type: MANAGE_PROFILE_REMOVE_USER_SUCCESS,
          payload: id,
          meta: {
            mixpanel: {
              event: "Delete user",
              props: {
                distinct_id: getState().current_user.data.data.id,
                User: id,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });
        success();
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_REMOVE_USER_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function loadCreditCheckLookup() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_START,
    });

    const creditCheckLookup = api(
      "credit_check_lookup",
      getState().current_user.access_token,
    );

    creditCheckLookup.getCreditCheckLookup(
      result => {
        const creditCheckList = new Array();
        result.data.data.forEach(creditCheck => {
          creditCheckList.push({
            name: creditCheck.attributes.name,
            description: creditCheck.attributes.description,
            region: creditCheck.attributes.region,
            active: creditCheck.attributes.active || false,
            requires_login: creditCheck.attributes.requires_login || false,
            slug: creditCheck.attributes.slug,
            personal_check: creditCheck.attributes.personal_check || false,
            business_check: creditCheck.attributes.business_check || false,
            id: creditCheck.id,
          });
        });

        dispatch({
          type: MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_SUCCESS,
          payload: creditCheckList,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function setSeletedCreditCheckRules(creditCheck, type) {
  return (dispatch, getState) => {
    const creditCheckLookup = getState().manage_profile
      .settings_credit_check_lookup;
    const newSelectedCreditCheckRules = getState().manage_profile
      .settings_selected_credit_check_rules;
    if (creditCheck.region) {
      const region = creditCheck.region.toLowerCase();
      if (type === "business") {
        if (
          newSelectedCreditCheckRules[`${region}_rule_selection_id`] ==
          creditCheck.id
        ) {
          newSelectedCreditCheckRules[`${region}_rule_selection_id`] = null;
        } else {
          newSelectedCreditCheckRules[`${region}_rule_selection_id`] =
            creditCheck.id;
        }
      } else if (type === "personal") {
        if (
          newSelectedCreditCheckRules[`${region}_personal_rule_selection_id`] ==
          creditCheck.id
        ) {
          newSelectedCreditCheckRules[
            `${region}_personal_rule_selection_id`
          ] = null;
        } else {
          newSelectedCreditCheckRules[`${region}_personal_rule_selection_id`] =
            creditCheck.id;
        }
      }
    }

    dispatch({
      type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_CREDIT_CHECK_RULES,
      payload: newSelectedCreditCheckRules,
    });
  };
}

export function loadIdentificationCheckLookup() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_START,
    });

    const identificationCheckLookup = api(
      "identification_check_lookup",
      getState().current_user.access_token,
    );

    identificationCheckLookup.getIdentificationCheckLookup(
      result => {
        const identificationCheckList = new Array();
        result.data.data.forEach(identificationCheck => {
          identificationCheckList.push({
            name: identificationCheck.attributes.name,
            description: identificationCheck.attributes.description,
            region: identificationCheck.attributes.region,
            active: identificationCheck.attributes.active || false,
            manual: identificationCheck.attributes.manual || false,
            id: identificationCheck.id,
          });
        });

        dispatch({
          type: MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_SUCCESS,
          payload: identificationCheckList,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function setSeletedIdentificationCheckRules(identificationCheck) {
  return (dispatch, getState) => {
    const newSelectedIdentificationCheckRules = getState().manage_profile
      .settings_selected_identification_check_rules;
    let requiresIdentificationCheck = getState().manage_profile
      .settings_requires_identification_check;

    if (identificationCheck.region === "NZ") {
      if (
        newSelectedIdentificationCheckRules.nz_rule_selection_id ==
        identificationCheck.id
      ) {
        newSelectedIdentificationCheckRules.nz_rule_selection_id = null;
      } else {
        newSelectedIdentificationCheckRules.nz_rule_selection_id =
          identificationCheck.id;
      }
    } else if (identificationCheck.region === "AU") {
      if (
        newSelectedIdentificationCheckRules.au_rule_selection_id ==
        identificationCheck.id
      ) {
        newSelectedIdentificationCheckRules.au_rule_selection_id = null;
      } else {
        newSelectedIdentificationCheckRules.au_rule_selection_id =
          identificationCheck.id;
      }
    }

    requiresIdentificationCheck =
      newSelectedIdentificationCheckRules.nz_rule_selection_id !== null ||
      newSelectedIdentificationCheckRules.au_rule_selection_id !== null;

    dispatch({
      type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_IDENTIFICATION_CHECK_RULES,
      payload: {
        newSelectedIdentificationCheckRules,
        requiresIdentificationCheck,
      },
    });
  };
}

export function setCreditWorksStatus(value) {
  return {
    type: MANAGE_PROFILE_SETTINGS_SET_CREDIT_WORKS_STATUS,
    payload: value,
  };
}

export function clearManageProfileState() {
  return {
    type: MANAGE_PROFILE_CLEAR_STATE,
  };
}

export function clearSeletedCreditCheckRules() {
  return {
    type: MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_CREDIT_CHECK_RULES,
  };
}

export function clearSeletedIdentificationCheckRules() {
  return {
    type: MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_IDENTIFICATION_CHECK_RULES,
  };
}

export function updateMandatoryChecksCreditWorks(attributes) {
  return updateEntityCreditWorksStatus(
    attributes,
    MANAGE_PROFILE_MANDATORY_CHECKS_SET_START,
    MANAGE_PROFILE_MANDATORY_CHECKS_SET_SUCCESS,
    MANAGE_PROFILE_MANDATORY_CHECKS_SET_ERROR,
  );
}

function updateEntityCreditWorksStatus(
  attributes,
  start_action,
  success_action,
  error_action,
) {
  return (dispatch, getState) => {
    dispatch({
      type: start_action,
      payload: attributes,
    });

    const entity = api("entities", getState().current_user.access_token);
    const id = getState().current_user.current_entity.id;

    entity.updateEntity(
      id,
      attributes,
      result => {
        dispatch({
          type: success_action,
          payload: id,
        });
      },
      error => {
        dispatch({
          type: error_action,
          payload: error,
        });
      },
    );
  };
}

export function getLatestApprovalLevels() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_START,
    });

    const approval_hierarchies = api(
      "approval_hierarchies",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    approval_hierarchies.getLatestApprovalHierarchy(
      result => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function getApprovalLevelByVersion(version) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_START,
    });
    const approval_hierarchies = api(
      "approval_hierarchies",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    approval_hierarchies.getApprovalHierarchy(
      version,
      result => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function getApprovalHierarchyHistory() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_START,
    });

    const approval_hierarchies = api(
      "approval_hierarchies",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    approval_hierarchies.getApprovalHierarchies(
      result => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function updateApprovalLevels({
  attributes,
  callback,
  version,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_SET_APPROVAL_LEVELS_START,
    });

    const approvalHierarchies = api(
      "approval_hierarchies",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    approvalHierarchies.updateApprovalHierarchy(
      attributes,
      () => {
        dispatch({
          meta: {
            mixpanel: {
              event: "Update approval Hierarchies",
              props: {
                "Approval Hierarchy Version": version,
                "Entity ID": getState().current_user.current_entity.id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          type: MANAGE_PROFILE_SET_APPROVAL_LEVELS_SUCCESS,
        });
        dispatch(userLoadCurrentEntity());
        dispatch(getLatestApprovalLevels());
        dispatch(getApprovalHierarchyHistory());
        callback.success();
      },
      (error) => {
        dispatch({ type: MANAGE_PROFILE_SET_APPROVAL_LEVELS_ERROR });
        callback.error(error);
      },
    );
  };
}

export function getApprovalInProgressApplicationsCount() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_START,
    });

    const applications = api(
      "applications",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    applications.getApprovalInProgressCount(
      result => {
        dispatch({
          type: MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_ERROR,
        });
      },
    );
  };
}

export function setPermissionsAccountRules(key, value) {
  let type;
  switch (key) {
    case "permissions_requires_credit_check":
      type = MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_CREDIT_CHECK;
      break;
    case "permissions_requires_identification_check":
      type = MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_IDENTIFICATION_CHECK;
      break;
    case "permissions_requires_guarantees":
      type = MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_GUARANTEES;
      break;
    case "permissions_requires_trade_reference_check":
      type = MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_TRADE_REFERENCE_CHECK;
      break;
  }

  return {
    type,
    payload: value,
  };
}

export function getPermissionsAccountRules(getState, attributes) {
  const s = getState().manage_profile;
  const ent = getState().manage_profile.current_entity.attributes;

  if (!attributes) {
    attributes = new Object();
  }

  /* Add Credit Check */
  attributes["requires_credit_check"] = s.permissions_requires_credit_check;
  attributes["selected_credit_check_rules"] =
    ent.selected_credit_check_rule_ids;

  /* Add Identification Check */
  attributes["requires_identity_check"] =
    s.permissions_requires_identification_check;
  attributes["selected_identification_check_rules"] =
    ent.selected_identification_check_rules;

  if (s.permissions_requires_identification_check) {
    attributes["requires_applicant_identification_check"] =
      ent.requires_applicant_identification_check || false;
    attributes["requires_guarantees_identification_check"] =
      ent.requires_guarantees_identification_check || false;
  } else {
    attributes["requires_applicant_identification_check"] = false;
    attributes["requires_guarantees_identification_check"] = false;
  }

  /* Add Guarantor */
  attributes["requires_guarantees"] = s.permissions_requires_guarantees;

  if (s.permissions_requires_guarantees) {
    attributes["minimum_guarantees"] = ent.minimum_guarantees || 2;
  } else {
    attributes["minimum_guarantees"] = 0;
  }

  /* Add Trade Reference */
  if (s.permissions_requires_trade_reference_check) {
    attributes["requires_trade_reference_check"] =
      ent.requires_trade_reference_check || false;
    attributes["minimum_trade_references"] = ent.minimum_trade_references || 0;
  } else {
    attributes["requires_trade_reference_check"] = false;
    attributes["minimum_trade_references"] = 0;
  }

  return attributes;
}

export function clearSettingsState(key) {
  let type;
  switch (key) {
    case "business_info":
      type = MANAGE_PROFILE_CLEAR_SETTINGS_BUSINESS_INFO;
      break;
    case "key_contact":
      type = MANAGE_PROFILE_CLEAR_SETTINGS_KEY_CONTACT;
      break;
    case "invoice":
      type = MANAGE_PROFILE_CLEAR_SETTINGS_INVOICE;
      break;
  }

  return {
    type,
  };
}

export function setEmailTemplate(template) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_SET_EMAIL_TEMPLATE,
      payload: template,
    });
  };
}

export function setEmailTemplateValue(key, value) {
  return {
    type: MANAGE_PROFILE_SET_EMAIL_TEMPLATE_VALUE,
    payload: { key, value },
  };
}

export function getEmailTemplate(template_no) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_GET_EMAIL_TEMPLATE_START,
    });

    const email_template = api(
      "email_templates",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    email_template.getEmailTemplate(
      template_no,
      result => {
        dispatch({
          type: MANAGE_PROFILE_GET_EMAIL_TEMPLATE_SUCCESS,
          payload: result.data.data.attributes,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_GET_EMAIL_TEMPLATE_ERROR,
        });
      },
    );
  };
}

export function updateEmailTemplate() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_START,
    });

    const data = getState().manage_profile.settings_email_template;
    const attributes = getEmailTemplateAttributes(getState);

    const entity_email_template = api(
      "entity_email_templates",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    entity_email_template.updateEntityEmailTemplate(
      data.id,
      attributes,
      result => {
        dispatch({
          type: MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_SUCCESS,
          payload: result.data.data,
        });
        dispatch(loadCurrentEntity());
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_ERROR,
        });
      },
    );
  };
}

export function previewEmail() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_PREVIEW_EMAIL_START,
    });

    const attributes = getEmailTemplateAttributes(getState);

    const email_preview = api(
      "email_previews",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    email_preview.getEmailPreview(
      attributes,
      result => {
        dispatch({
          type: MANAGE_PROFILE_PREVIEW_EMAIL_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: MANAGE_PROFILE_PREVIEW_EMAIL_ERROR,
        });
      },
    );
  };
}

function getEmailTemplateAttributes(getState) {
  const settings_email_template = getState().manage_profile
    .settings_email_template.attributes;

  const data = {
    email_template: new Object(),
  };

  if (settings_email_template.template_no) {
    data.email_template.template_no = settings_email_template.template_no;
  }
  data.email_template.default = settings_email_template.default || false;
  data.email_template.notifications =
    settings_email_template.notifications || false;
  data.email_template.is_delay = settings_email_template.is_delay || false;
  data.email_template.delay_time =
    settings_email_template.delay_time ||
    Object.keys(settings_email_template.delay_time_options)[0];
  data.email_template.subject = settings_email_template.subject || "";
  data.email_template.greeting = settings_email_template.greeting || "";
  data.email_template.body = settings_email_template.body || "";
  data.email_template.link = settings_email_template.link || "";
  data.email_template.link_text = settings_email_template.link_text || "";
  data.email_template.signature = settings_email_template.signature || "";
  data.email_template.video_url = settings_email_template.video_url || "";
  data.email_template.fixed_email = settings_email_template.fixed_email || "";
  if (settings_email_template.attachment) {
    data.email_template.attachment = settings_email_template.attachment;
  }
  data.email_template.filename = settings_email_template.filename || "";

  return data.email_template;
}

/**
 * Card Addon Actions
 */
export function loadCardsAddons() {
  return async (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_START,
    });

    const currentUser = getState().current_user;
    const accessToken = currentUser.access_token;
    const entityId = currentUser.current_entity.id;

    const addonConfigsAPI = api("addon_configs", accessToken, entityId);

    try {
      const response = await addonConfigsAPI.getAddonConfigs({
        params: { addon_module_name: "cards" },
      });

      dispatch({
        payload: response.data.data,
        type: MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_ERROR,
      });
    }
  };
}

/**
 * AML Actions
 */
export function loadAmlCheckLookup() {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_START });

    const amlCheckLookupAPI = api(
      "aml_check_lookup",
      getState().current_user.access_token,
    );

    try {
      const response = await amlCheckLookupAPI.getAmlCheckLookup();

      dispatch({
        payload: response.data.data,
        type: MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_ERROR,
      });
    }
  };
}

export function loadAmlCheckRules() {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_START });

    const currentUser = getState().current_user;
    const accessToken = currentUser.access_token;
    const entityId = currentUser.current_entity.id;

    const addonConfigsAPI = api("addon_configs", accessToken, entityId);

    try {
      const response = await addonConfigsAPI.getAddonConfigs({
        params: { addon_module_name: "aml_check" },
      });

      const addonConfig = response.data.data.sort(
        (a, b) => b.attributes.serial_number - a.attributes.serial_number,
      )[0];

      const payload = getPayloadFromConfig(addonConfig);

      dispatch({
        payload,
        type: MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_ERROR,
      });
    }
  };
}

function getPayloadFromConfig(addonConfig) {
  const defaultPayload = {
    attributes: {
      addon_config_id: null,
      config: {},
      person_to_check: [],
    },
  };

  if (isBlank(addonConfig)) {
    return defaultPayload;
  }

  const version = addonConfig.attributes.history_version.data.sort(
    (a, b) => b.attributes.version - a.attributes.version,
  )[0];

  if (isBlank(version)) {
    return defaultPayload;
  }

  const config = version.attributes.config;
  const personToCheck = config.person_to_check;
  delete config.person_to_check;

  return {
    addonConfigActive: addonConfig.attributes.active,
    addonConfigId: addonConfig.id,
    personToCheck,
    selectedProviders: config,
  };
}

export function setSelectedAmlCheckRules(selectedRegionProvider) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_AML_CHECK_PROVIDER,
      payload: {
        selectedProviders: {
          ...getState().manage_profile.settingsAmlCheckRules.selectedProviders,
          ...selectedRegionProvider,
        },
      },
    });
  };
}

export function setAmlCheckLevels(selectedLevels) {
  return {
    type: MANAGE_PROFILE_MANDATORY_CHECKS_SET_AML_CHECK_LEVEL,
    payload: selectedLevels,
  };
}

export function persistAmlCheckConfig() {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_START });

    const manageProfileState = getState().manage_profile;
    const currentUser = getState().current_user;

    const amlCheckRules = manageProfileState.settingsAmlCheckRules;
    const accessToken = currentUser.access_token;
    const entityId = currentUser.current_entity.id;

    const attributes = {
      active: true,
      addon_config_id: amlCheckRules.addonConfigId,
      addon_module_name: "aml_check",
      config: {
        ...amlCheckRules.selectedProviders,
        person_to_check: amlCheckRules.selectedLevels,
      },
      name: "AML Check",
    };

    const addonVersionsAPI = api("addon_versions", accessToken, entityId);

    try {
      const response = await addonVersionsAPI.createAddonVersion(attributes);

      dispatch({
        meta: {
          mixpanel: {
            event: "Set AML Check rules",
            props: { "Entity ID": entityId },
          },
        },
        payload: response.data.data,
        type: MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_ERROR,
      });
    }
  };
}

export function setRequiresTrustDeed(value) {
  return {
    payload: value,
    type: MANAGE_PROFILE_SETTINGS_REQUIRES_TRUST_DEED,
  };
}

export function setPartnershipAgreementUploadVisible(value) {
  return {
    payload: value,
    type: MANAGE_PROFILE_SETTINGS_SET_PARTNERSHIP_AGREEMENT_UPLOAD_VISIBLE,
  };
}

export function setTrustDeedUploadVisible(value) {
  return {
    payload: value,
    type: MANAGE_PROFILE_SETTINGS_SET_TRUST_DEED_UPLOAD_VISIBLE,
  };
}

export function updateRequiredAdditionalDocuments() {
  return (dispatch, getState) => {
    const profileState = getState().manage_profile;

    const attributes = {
      partnership_agreement_upload_visible:
        profileState.partnership_agreement_upload_visible,
      requires_trust_deed: profileState.requires_trust_deed,
      trust_deed_upload_visible: profileState.trust_deed_upload_visible,
    };

    dispatch(
      updateEntitySettings(
        attributes,
        MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_START,
        MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_SUCCESS,
        MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_ERROR,
      ),
    );
  };
}

export function loadTimezones() {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_PROFILE_LOAD_TIMEZONE_START });

    const currentUser = getState().current_user;
    const accessToken = currentUser.access_token;
    const entityId = currentUser.current_entity.id;

    const timezoneAPI = api("timezones", accessToken, entityId);

    try {
      const response = await timezoneAPI.getTimezones();
      dispatch({
        payload: response.data,
        type: MANAGE_PROFILE_LOAD_TIMEZONE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: MANAGE_PROFILE_LOAD_TIMEZONE_ERROR,
      });
    }
  };
}

export function loadAntiFraud() {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_START });

    try {
      const currentUser = UserModel.fromCurrentUser(getState().current_user);

      const addonConfigs = await AddonConfigModel.fetchAddonConfigByAddonType({
        accessToken: currentUser.accessToken,
        addonType: ["anti_fraud_website", "anti_fraud_qr", "anti_fraud_sales"],
        entityId: get(currentUser, "currentEntity.id"),
      });

      dispatch({
        payload: addonConfigs,
        type: MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_SUCCESS,
      });
    } catch (error) {
      // console.log({ loadAntiFraudError: error });
      dispatch({
        payload: error,
        type: MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_ERROR,
      });
    }
  };
}

export function loadCustomerChecklist() {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_START });

    try {
      const currentUser = UserModel.fromCurrentUser(getState().current_user);

      const addonConfig = await AddonConfigModel.fetchAddonConfigByAddonType({
        accessToken: currentUser.accessToken,
        addonType: "customer_checklist",
        entityId: get(currentUser, "currentEntity.id"),
      });

      dispatch({
        payload: addonConfig,
        type: MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_SUCCESS,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch({
        payload: error,
        type: MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_ERROR,
      });
    }
  };
}
