import React, { useEffect, useState } from "react";
import ReactTimeout from "react-timeout";

import { connect } from "react-redux";
import { loadingLiveChat } from "utils/liveChat.js";

import { clearApplicationStates, loadSupplier } from "../actions/section";

import ConsumerWebsitebuttonStart from "./ConsumerWebsitebuttonStart.js";
import ApplicationOverlay from "modules/shared/components/top/ApplicationOverlay";
import Loader from "modules/shared/components/widgets/static/Loader";
import { isMobile } from "modules/shared/helpers/mobileDetect";
import CircleModal from "modules/shared/components/widgets/static/CircleModal";
import AddonConfigModel from 'models/AddonConfigModel';
import { get } from "lodash";

import styles from "./css/ConsumerApplication.css";

function ConsumerWebsitebuttonApplication(props) {
  const {
    config,
    current_pricing_plan_website_button,
    dispatch,
    is_branch_supplier,
    is_regular_supplier,
    is_region_supplier,
    is_hq_supplier,
    loading,
    location,
    params,
    supplier_logo_url,
    supplier_store_name_alias,
    supplier_trading_name,
    supplier_users,
    supplierWebsitebuttonMode,
  } = props;
  const [isApplicationTypesLoading, setIsApplicationTypesLoading] = useState(true);
  const [applicationTypes, setApplicationTypes] = useState(null);
  const pathname = location.pathname;
  const mobileMinHeight = document.documentElement.clientHeight - 56;

  const fetchApplicationTypes = () => {
    (async () => {
      const result = await AddonConfigModel.fetchAddonConfigByAddonType({
        addonType: ['website_button'],
        entityId: params.entity_id,
      });

      const latestConfig = get(result[0], 'attributes.history_version.data')[0];
      setApplicationTypes(get(latestConfig, 'attributes.application_types'));
      setIsApplicationTypesLoading(false);
    })();
  }

  useEffect(() => {
    dispatch(clearApplicationStates());
    dispatch(loadSupplier(`${params.entity_id}`));

    if (!applicationTypes) {
      fetchApplicationTypes();
    }
  }, [])

  if (loading || isApplicationTypesLoading) {
    return (
      <Loader
        message="Please wait while we load your progress."
        loading={loading}
      />
    );
  }

  if (!current_pricing_plan_website_button) {
    return (
      <CircleModal>
        <div>
          <p>
            oops - looks like the supplier does not have this service. Please
            contact them for more information.
          </p>
        </div>
      </CircleModal>
    );
  }

  return (
    <div className={styles.page}>
      <div className="is-full-height has-background-white">
        <ConsumerWebsitebuttonStart
          {...props}
          applicationTypes={applicationTypes || []}
          forgotPasswordRedirectPath={pathname}
        />
      </div>
    </div>
  );
};

const config = {
  title: "Application",
};

export default connect((state, ownProps) => {
  let logo_url = null;
  let trading_name = null;
  let current_pricing_plan_website_button = null;
  let supplier_users = null;
  let supplier_store_name_alias = null;
  let supplier = null;
  let is_regular_supplier = null;
  let is_branch_supplier = null;
  let is_region_supplier = null;
  let is_hq_supplier = null;
  let supplierWebsitebuttonMode = null;

  if (state.cob_section.supplier) {
    supplier = state.cob_section.supplier;
    trading_name = supplier.attributes.trading_name;
    current_pricing_plan_website_button =
      supplier.attributes.entity_plan_website_button;
    supplier_users = supplier.attributes.users;
    if (supplier.attributes.logo) {
      logo_url = supplier.attributes.logo.url;
    }
    if (supplier.attributes.store_name_alias) {
      supplier_store_name_alias = supplier.attributes.store_name_alias;
    }
    if (supplier.attributes.type) {
      is_regular_supplier = supplier.attributes.type === "Regular";
      is_branch_supplier = supplier.attributes.type === "Branch";
      is_region_supplier = supplier.attributes.type === "Region";
      is_hq_supplier = supplier.attributes.type === "Headquarter";
    }
    if (supplier.attributes.websitebutton_mode) {
      supplierWebsitebuttonMode = supplier.attributes.websitebutton_mode;
    }
  }

  return {
    config,
    loading: state.cob_section.loading,
    supplier,
    supplier_trading_name: trading_name,
    supplier_logo_url: logo_url,
    supplier_users,
    current_pricing_plan_website_button,
    supplier_store_name_alias,
    is_branch_supplier,
    is_region_supplier,
    is_hq_supplier,
    is_regular_supplier,
    supplierWebsitebuttonMode,
  };
})(ReactTimeout(ConsumerWebsitebuttonApplication));
