import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Paginator from "./Paginator";

//import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader'
import Button from "modules/shared/components/inputs/Button";

import { formatMoney } from "utils/formatting";
import { isDownloadPDFWithIE, iePDFDownloader } from "utils/iePDF";

import styles from "./css/AccountInvoices.css";
import * as paymentActions from "../actions";

class AccountInvoices extends Component {
  _downloadPdf(invoice) {
    const { dispatch } = this.props;

    let invoiceNumber = invoice.number;

    dispatch(
      paymentActions.getInvoice(invoice.id, data => {
        let filename = `invoice-${invoiceNumber}.pdf`;

        if (isDownloadPDFWithIE()) {
          iePDFDownloader(data, filename);
          return;
        }

        const link = document.createElement("a");
        link.href = "data:application/pdf;base64," + data;
        link.target = "_blank";
        link.download = filename;
        document.body.appendChild(link);
        link.click();
      }),
    );
  }
  render() {
    return (
      <div>
        <article className={styles.block}>
          <div className={styles.liner}>
            <h2 className={styles.header}>Account invoices</h2>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Invoice #</th>
                  <th>Amount</th>
                  <th>Paid</th>
                  <th>PDF</th>
                </tr>
              </thead>
              <tbody>
                {this.props.transactions.map((t, i) => (
                  <tr key={"trow-" + i}>
                    <td>
                      {moment((t.date || t.period_end) * 1000).format(
                        "MMM DD YYYY",
                      )}
                    </td>
                    <td>{t.number}</td>
                    <td>{"$" + formatMoney(t.total / 100.0, 2)}</td>
                    <td>
                      {t.paid ? "Yes" : <span className={styles.red}>No</span>}
                    </td>
                    <td>
                      <div
                        className={styles.download}
                        onClick={
                          this.props.payment.invoiceLoading
                            ? null
                            : () => this._downloadPdf(t)
                        }
                      >
                        Download
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5"></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </article>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    payment: state.payment,
    entityId: state.current_user.current_entity.id,
  }),
  dispatch => ({
    paymentActions: bindActionCreators(paymentActions, dispatch),
    dispatch,
  }),
)(AccountInvoices);
