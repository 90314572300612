import FileWidget from "./FileWidget";
import React from "react";
import styles from "./css/FileWidgetSmall.css";

function FileWidgetSmall(props) {
  const { error, filename, title, viewSrc } = props;

  let viewButton;
  const buttonStyle = title ? styles.link_with_title : styles.link;

  if (viewSrc && viewSrc.url) {
    const src = viewSrc.url;

    if (viewSrc.url.startsWith("data:application/pdf;")) {
      viewButton = (
        <a download className={buttonStyle} href={src}>
          View
        </a>
      );
    } else {
      viewButton = (
        <a
          className={buttonStyle}
          target="_blank"
          href={src}
          rel="noopener noreferrer"
          download
        >
          View
        </a>
      );
    }
  }

  return (
    <div className={styles.section}>
      {title && <div className={styles.title}>{title}</div>}
      <FileWidget theme={styles} {...props} />
      {viewButton}
      { error && <span className={styles.error}>{error}</span> }
    </div>
  );
}

export default FileWidgetSmall;
