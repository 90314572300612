import React, { ReactElement, useState } from 'react'
import Panel from '../Panel'
import { Props } from '../Panel/model';
import Switch from "modules/shared/components/widgets/interactive/Switch";
import ConversionRate from './ConversionRate';
import RealTimeLagTime from './RealTimeLagTime';



function ConversionRateTime(props: Props): ReactElement {
  const tabOptions = {
    conversion: {
      title: 'Conversion Rate',
      component: <ConversionRate />,
    },
    timings: {
      title: 'Real time / lag time',
      component: <RealTimeLagTime />,
    }
  };

  const [ current, setCurrent ] = useState('conversion');

  const handleChange = (value: string) => {
    setCurrent(value);
  }

  return (
    <Panel
      {...props}
      action={
        <Switch
          leftLabel="conversion"
          rightLabel="timings"
          leftOption="conversion"
          rightOption="timings"
          current={current}
          handleChange={handleChange}
          cssOverrides={{
            marginRight: 0,
            marginTop: "-0.5rem",
          }}
          buttonCssOverrides={{
            display: "inline-block",
            width: "100px",
          }}
        />
      }
      beta
      error={false}
      loading={false}
      title={tabOptions[current].title}
      // refresh={() => forceUpdate()}
    >
      {tabOptions[current].component}
    </Panel>
  )
}

export default ConversionRateTime
