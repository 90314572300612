import get from "lodash.get";
import ApplicationModel from "models/ApplicationModel";
import LeadModel from "models/LeadModel";
import UserModel from "models/UserModel";
import ContentWithFooter from "modules/shared/components/containers/ContentWithFooter";
import ScrollableContent from "modules/shared/components/containers/ScrollableContent";
import React, { ReactElement, ReactNode, useState } from "react"
import sortByCreatedAt from "utils/sortByCreatedAt";

import RecordHistory from "../../RecordHistory";
import useIUFState from "./hooks/useIUFState";
import IUFEdit from "./IUFEdit";
import IUFView from "./IUFView";

export interface IIUFProps {
  lead: LeadModel;
  application: ApplicationModel;
  currentUser: UserModel;
  onFetchApplicationRecord: () => void;
}

type WrapperProps = {
  children: ReactNode;
  owner: ApplicationModel | LeadModel;
}

function Wrapper({
  children,
  owner,
}: WrapperProps): ReactElement {
  const addonAnswerHistories = get(owner, "iufAddonAnswers.addonAnswerHistories", []);
  const iufApprovalHistories = get(owner, "iufApproval.iufApprovalHistories", []);
  const histories = sortByCreatedAt([...addonAnswerHistories, ...iufApprovalHistories]);

  if (histories.length > 0) {
    return (
      <ContentWithFooter
        footer={<RecordHistory histories={histories} />}
        withBodyBottomPadding
      >
        {children}
      </ContentWithFooter>
    )
  }

  return <>{children}</>;
}

function IUF(props: IIUFProps): ReactElement|null {
  const { application, lead } = props;
  const [edit, setEdit] = useState(false);
  const owner = application || lead;
  const { noAnswer, isCompany } = useIUFState(owner);

  function editOn() {
    setEdit(true);
  }

  function editOff() {
    setEdit(false);
  }

  return (
    <ScrollableContent>
      <Wrapper {...props} owner={owner}>
        {(edit || noAnswer) ? (
          <IUFEdit
            handleComplete={editOff}
            isCompany={isCompany}
            owner={owner}
            {...props}
          />
        ) : (
          <IUFView {...props} owner={owner} editOn={editOn} />
        )}
      </Wrapper>
    </ScrollableContent>
  )
}

export default IUF;
