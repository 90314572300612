import axios from "./axios";;

export default class LoginDisposition {
  constructor(config) {
    this.axios = axios(config);
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  }

  get(email_address) {
    return this.axios.get("/login_disposition", { params: { email: email_address } })
  }
}
