import React, { ReactElement } from "react";
import ApproverContent from "modules/new-applications/components/application-sections/LimitAndApprovals/ReviewContent/ApproverContent";
import ReviewerContent from "modules/new-applications/components/application-sections/LimitAndApprovals/ReviewContent/ReviewerContent";

const ReviewContent = ({
  application,
  review,
}: {
  application: { [key: string]: any };
  review: { [key: string]: any };
}): ReactElement => {
  if (review.isReviewerLevel) {
    return <ReviewerContent review={review} />;
  }

  return <ApproverContent application={application} review={review} />;
};

export default ReviewContent;
