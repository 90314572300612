import get from "lodash.get";
import moment from "moment";
import store from "stores/store";
import isPresent from "utils/isPresent";

export function signatureDataFormatter() {
  const identityState = store.getState().identity;

  const dob = identityState.dob;
  let formattedDate = "";
  if (typeof dob === "string") {
    formattedDate = dob;
  } else {
    formattedDate = moment(dob, "DD/MM/YYYY").format("YYYY-MM-DD");
  }

  const expiryDate = identityState.identification_exp_date;
  const state = identityState.driver_licence_state;

  let formattedIdentificationExpDate = "";
  if (typeof expiryDate === "string") {
    formattedIdentificationExpDate = expiryDate;
  } else {
    formattedIdentificationExpDate = moment(expiryDate, "DD/MM/YYYY").format(
      "YYYY-MM-DD",
    );
  }

  let proofOfAddress = null;

  if (identityState.proof_of_address) {
    proofOfAddress = identityState.proof_of_address.url;
  }

  const signature = {
    first_name: identityState.first_name,
    identification_expiry_date: !identityState.noIdentification
      ? formattedIdentificationExpDate
      : null,
    // identification_image: identityState.image_64,
    identification_number: identityState.noIdentification
      ? null
      : identityState.number,
    identification_state: identityState.noIdentification ? null : state,
    identification_type: identityState.noIdentification
      ? "no_identification"
      : identityState.type,
    identification_version:
      !identityState.noIdentification && identityState.type === "driver_licence"
        ? identityState.driver_licence_version
        : null,
    last_name: identityState.last_name,
    middle_name: identityState.middle_name,
    no_identification_reason: identityState.image_64
      ? null
      : identityState.no_identification_reason,
    other_region: identityState.other_region,
    proof_of_address: proofOfAddress,
    region: identityState.region,
  };

  if (formattedDate) signature.dob = formattedDate;

  const image64 = identityState.image_64;
  if (isPresent(image64) && image64.startsWith("https://")) {
    signature.identification_image = image64;
  }

  return signature;
}

export function signatureLoaderFormatter(signature) {
  const identity = {};
  const signatureAttributes = get(signature, "attributes", {});
  const {
    address,
    dob,
    front_face_image,
    identification_image,
    identification_expiry_date,
  } = signatureAttributes;

  if (isPresent(dob)) {
    identity.dob = moment(dob).toDate();
  }

  const attributesToAssign = {
    anti_fraud_check_passed: "antiFraudCheckPassed",
    first_name: "first_name",
    identification_number: "number",
    identification_type: "type",
    identification_state: "driver_licence_state",
    identification_version: "driver_licence_version",
    last_name: "last_name",
    middle_name: "middle_name",
    no_identification_reason: "noIdentificationReason",
    other_region: "other_region",
    region: "region",
  };

  if (isPresent(identification_expiry_date)) {
    identity.identification_exp_date = moment(
      identification_expiry_date,
    ).toDate();
  }

  Object.keys(attributesToAssign).forEach(key => {
    identity[attributesToAssign[key]] = signatureAttributes[key];
  });

  if (address && address.data) {
    identity.address = address.data.attributes;
    identity.address_api_id = address.data.attributes.api_id;
  }

  if (identification_image) {
    identity.image_64 = identification_image.url;
  }

  identity.proof_of_address = extractProofOfAddressURL(signature);

  identity.front_face_image = get(front_face_image, "url");
  identity.is_valid_identification =
    signatureAttributes.is_valid_identification;

  return identity;
}

// Unfortunately the API returns two different responses for the proof of
// address and we have handle both responses:
// 1. { signature: { proof_of_address: { url: "https://..." } }
// 2. { signature: { proof_of_address: { proof_of_address: { url: "https://..." } } } }
function extractProofOfAddressURL(signature) {
  const proofOfAddressAttribute = signature.attributes.proof_of_address || {};

  if (Object.keys(proofOfAddressAttribute).includes("url")) {
    return { url: proofOfAddressAttribute.url };
  }

  if (Object.keys(proofOfAddressAttribute).includes("proof_of_address")) {
    return { url: proofOfAddressAttribute.proof_of_address.url };
  }
}
