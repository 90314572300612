import axios from "./axios";;

const ModulePaperlessApplicationFlows = function(config) {
  config.timeout = 5 * 60 * 1000;
  this.axios = axios(config);
  this.type = "module_paperless_application_flows";
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
};

//
// Await-able functions
// The following functions allows the use of async/await
//
ModulePaperlessApplicationFlows.prototype.getDetails = function(id, options) {
  return this.axios.get(`/${this.type}/${id}`, options);
};

ModulePaperlessApplicationFlows.prototype.update = function(id, options) {
  return this.axios.patch(`/${this.type}/${id}`, options);
};

export default ModulePaperlessApplicationFlows;
