import { isEmpty } from "lodash";
import { updateUser } from "modules/manage-users/actions";
import { loadUserProfile } from "modules/profile/actions";
import BorderedTextField from "modules/shared/components/inputs/BorderedTextField";
import Checkbox from "modules/shared/components/inputs/Checkbox";
import React, { ReactElement, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form-latest";
import isPresent from "utils/isPresent";
// @ts-ignore
import { browserHistory } from "react-router";
import * as yup from "yup";

import { Props } from "./types";
import useYupValidationResolver from "modules/shared/hooks/useYupValidationResolver";

function Input(props) {
  const {
    control,
    error,
    disabled,
    label,
    name,
    handleBlur,
    type,
    value: passedValue,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onBlur, ...fieldProps } }) => (
        <BorderedTextField
          {...fieldProps}
          type={type}
          label={label}
          disabled={disabled}
          helperText={error && error.message}
          onBlur={() => {
            handleBlur && handleBlur();
            onBlur();
          }}
          error={!!error}
          value={passedValue ? passedValue : value}
          labelShrink
        />
      )}
    />
  )
}

function getFormSchema() {
  return yup.object().shape({
    contact_phone_number: yup.string().required("Contact number is required"),
  })
}

function ProfileForm({
  dispatch,
  setIsLoading,
  userId,
  userData,
}: Props): ReactElement {
  const [receiveEmailNotifications, setReceiveEmailNotifications] = useState(false);

  function loadCurrentUser() {
    if (isEmpty(userData)) {
      dispatch(loadUserProfile(userId));
    }
  }

  useEffect(loadCurrentUser, [])

  const {
    control,
    reset,
    formState: {
      isDirty,
      errors,
    },
    watch,
  } = useForm<{
    contact_phone_number: string,
  }>({
    defaultValues: {
      contact_phone_number: "",
    },
    mode: "onBlur",
    resolver: useYupValidationResolver(getFormSchema),
  })

  const values = watch();

  function submitCallback() {
    setIsLoading(false);
    reset({
      ...userData,
      contact_phone_number: values.contact_phone_number,
    });
  }

  function handleSubmit(formData = {}) {
    setIsLoading(true);

    const processedData = {
      ...userData,
      ...formData,
      contact_phone_number: values.contact_phone_number,
    };

    if (processedData.page_permissions) {
      delete processedData["page_permissions"];
    }

    dispatch(updateUser(userId, processedData, submitCallback));
  }

  function handleReceiveEmailChange(e) {
    const value = e.target.checked;
    setReceiveEmailNotifications(value);
    handleSubmit({ receive_email_notifications: value });
  }

  function handleContactNumberBlur() {
    if (!values.contact_phone_number) {
      return;
    }

    if (isDirty) {
      handleSubmit();
    }
  }

  useEffect(() => {
    if (userData && isPresent(userData)) {
      reset(userData);
      setReceiveEmailNotifications(userData.receive_email_notifications)
    }

    if (userData && userData.noData) {
      browserHistory.push('/dashboard/home');
    }
  }, [userData])

  return (
    <div className="columns">
      <div className="column is-12 is-6-desktop">
        <div className="columns is-multiline">
          <div className="column is-6">
            <Input
              control={control}
              label="First name"
              name="first_name"
              value={userData && userData.first_name}
              disabled
            />
          </div>
          <div className="column is-6">
            <Input
              control={control}
              label="Last name"
              name="last_name"
              value={userData && userData.last_name}
              disabled
            />
          </div>
          <div className="column is-6">
            <Input
              control={control}
              label="Email"
              type="email"
              name="email"
              value={userData && userData.email}
              disabled
            />
          </div>
          <div className="column is-6">
            <Input
              control={control}
              name="contact_phone_number"
              label="Phone"
              handleBlur={handleContactNumberBlur}
              error={errors['contact_phone_number']}
            />
          </div>
          <div className="column is-12">
            <Checkbox
              checkboxId="receive_email_notifications"
              label="Receive email notifications"
              checked={receiveEmailNotifications}
              handleChange={handleReceiveEmailChange}
              noFloat
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileForm;
