import BaseModel from "models/BaseModel";
import moment from "moment";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

const IDENTIFICATION_TYPE_LABEL = {
  driver_licence: "Driver licence",
  other: "ID number",
  passport: "Passport",
};

export default class SignatoryModel extends BaseModel {
  get formattedDOB() {
    if (isPresent(this.dob)) {
      return moment(this.dob).format("DD/MM/YYYY");
    }

    return null;
  }

  get formattedIdentificationExpiryDate() {
    if (isPresent(this.identificationExpiryDate)) {
      return moment(this.identificationExpiryDate).format("DD/MM/YYYY");
    }

    return null;
  }

  get timestampParts() {
    if (isBlank(this.localTimeStamp)) {
      return {};
    }

    const timestamp = moment(this.localTimeStamp);

    return {
      date: timestamp.format("DD/MM/YYYY"),
      time: timestamp.format("HH:mm"),
    };
  }

  get identificationTypeLabel() {
    return IDENTIFICATION_TYPE_LABEL[this.identificationType];
  }
}
