interface ISetCollateralTypeDetailsOptions {
  collateralDescriptionFieldName: string;
  collateralTypeFieldName: string;
  selectedOption: { [key: string]: any } | null | undefined;
  setCollateralDescription: (string) => void;
  setSelectedCollateralType: (string) => void;
  setValue: (name: string, value: any, config?: any) => void;
}

const setCollateralTypeDetails = ({
  collateralDescriptionFieldName,
  collateralTypeFieldName,
  selectedOption,
  setCollateralDescription,
  setSelectedCollateralType,
  setValue,
}: ISetCollateralTypeDetailsOptions): void => {
  const selectedValue = (selectedOption || {}).value;
  const presetDescription = (
    (selectedOption || {}).presetDescription || ""
  ).trim();

  setSelectedCollateralType(selectedValue);
  setCollateralDescription(presetDescription);
  setValue(collateralTypeFieldName, selectedValue);
  setValue(collateralDescriptionFieldName, presetDescription, {
    shouldDirty: true,
  });
};

export default setCollateralTypeDetails;
