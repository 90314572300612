/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";

/* Import actions */
import { loadConnection } from "../actions";

/* Import API */
import api from "../../../api";

/* Import components */
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import Icon from "modules/shared/components/svg/Icon";
import Clickable from "modules/shared/components/widgets/interactive/Clickable";

import ConnectionDetails from "./ConnectionDetails";
import StopCredit from "./StopCredit";
import Notes from "./NotesWrapper";

import styles from "./css/Connection.css";
import { isMobile } from "modules/shared/helpers/mobileDetect";

import { downloadApplicationCSV } from "modules/applications/actions";

var Connection = createClass({
  componentWillMount: function() {
    // This route is already deprecated. Rerouting all request to go the the
    // home page
    browserHistory.push("/");

    const { dispatch, params } = this.props;
    dispatch(loadConnection(params.connectionId, params.relationType));
  },

  changeTab: function(value) {
    const { location } = this.props;

    browserHistory.push({
      pathname: location.pathname,
      query: Object.assign(location.query, { active_tab: value }),
    });
  },

  getInitialState: function() {
    return {
      activeTab: "account",
    };
  },

  downloadPdfApplication: function() {
    var applications = api(
      "applications",
      this.props.userToken,
      this.props.current_entity.id,
    );
    var win = window.open("", "pdf_window");
    applications.getApplicationPDF(
      this.props.connection.application.id,
      result => {
        win.open(result.data.url, "pdf_window");
      },
    );
  },

  downloadCSV: function() {
    const { dispatch, connection } = this.props;
    dispatch(downloadApplicationCSV(connection.application.id));
  },

  render: function() {
    const {
      title,
      loading,
      connection,
      location,
      tabs,
      active_tab,
      userId,
      roles,
      isSupplier,
      consumerRelationType,
      notes,
      ...rest
    } = this.props;
    let tabLinks = [],
      tabContent = null,
      filter_class,
      stopCreditElm;

    if (isMobile()) {
      tabs.splice(1, 1);
    }

    tabs.forEach((v, k) => {
      if (active_tab === v.key) {
        filter_class = "filter_active";
      } else {
        filter_class = "filter_default";
      }

      if (v.key === "notes" && !consumerRelationType) {
        return;
      }

      tabLinks.push(
        <Clickable key={k} target={this.changeTab.bind(null, v.key)}>
          <span className={styles[filter_class]}>{v.label}</span>
        </Clickable>,
      );
    });

    switch (active_tab) {
      case "notes":
        tabContent = <Notes notable_id={connection.id} location={location} />;
        break;
      default:
        tabContent = (
          <ConnectionDetails connection={connection} isSupplier={isSupplier} />
        );
        break;
    }

    if (loading) {
      return <SimpleLoader />;
    }

    if (consumerRelationType && roles.includes("standard")) {
      stopCreditElm = <StopCredit connection={connection} />;
    }

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.content}>
            <Link className={styles.back} to={`/dashboard/connections/all`}>
              <Icon icon="left" size="10x17" /> My connections
            </Link>

            <div className={styles.stopcredit}>{stopCreditElm}</div>

            <h1 className={styles.heading}>{title}</h1>

            <div className={styles.filters}>
              {tabLinks}
              {!isMobile() && (
                <Clickable target={this.downloadPdfApplication}>
                  <span className={styles["filter_default"]}>
                    pdf application
                  </span>
                </Clickable>
              )}
              <Clickable target={this.downloadCSV}>
                <span className={styles["filter_default"]}>export csv</span>
              </Clickable>
            </div>

            {tabContent}
          </div>
          {this.props.children}
        </div>
      </section>
    );
  },
});

var defaults = {
  tabs: [
    {
      label: "account",
      key: "account",
    },
    {
      label: "notes",
      key: "notes",
    },
  ],
  active_tab: "account",
};

module.exports = connect((state, ownProps) => {
  let loading =
      state.connections.connection_loading ||
      !state.connections.connection ||
      state.applications.download_csv_loading,
    connection = state.connections.connection || {},
    title,
    isSupplier,
    consumerRelationType,
    tabs = defaults.tabs;

  if (!loading) {
    title = connection.attributes.consumer_name;
    if (connection.application.attributes.archived)
      title = title + " - archived application";
    isSupplier = connection.ourparty.attributes.supplier;
    if (connection.relation_type === "consumer") {
      consumerRelationType = true;
    }
  }

  return {
    title,
    loading,
    connection,
    tabs,
    isSupplier,
    consumerRelationType,
    roles: state.current_user.current_user_entity_link.attributes.role_types,
    userId: state.current_user.data.data.id,
    userToken: state.current_user.access_token,
    current_entity: state.current_user.current_entity,
    active_tab: ownProps.location.query.active_tab || defaults.active_tab,
  };
})(Connection);
