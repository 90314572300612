import { getDateFiveYearsFromNow } from "modules/new-applications/components/application-actions/PPSRRegister/validationSchema/NZValidationSchema";
import { getTwentyFiveYearsFromNow } from "modules/new-applications/components/application-actions/PPSRRegister/validationSchema/AUValidationSchema";

export default function defaultExpiryDateByRegion(region) {
  if (region === "NZ") {
    return getDateFiveYearsFromNow();
  }

  return null;
}

export function defaultMaxDateByRegion(region) {
  if (region === "NZ") {
    return getDateFiveYearsFromNow();
  }

  if (region === "AU") {
    return getTwentyFiveYearsFromNow();
  }

  return null
}
