import {
  COB_EXTRAS_SET_QUESTION_DATA,
  COB_EXTRAS_SECTION_COMPLETE,
  COB_EXTRAS_COMPONENT_COMPLETE,
} from "../constants/extras";

export function extrasComplete(complete) {
  return (dispatch, getState) => {
    var section_state = getState().cob_extras;
    var questions = getState().cob_section.application.attributes.questions;

    var data = {
      questions: questions,
      answers: section_state.answers,
    };

    dispatch({
      type: COB_EXTRAS_SECTION_COMPLETE,
      payload: {
        complete: complete,
        data: data,
      },
    });
  };
}

export function setQuestionData(index, field, value) {
  if (value == "") {
    value = null;
  }
  return (dispatch, getState) => {
    dispatch({
      type: COB_EXTRAS_SET_QUESTION_DATA,
      payload: {
        index: index,
        field: field,
        value: value,
      },
    });
  };
}

export function extrasComponentComplete(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state === true) {
      meta = {
        mixpanel: {
          event: "Consumer onboarding",
          props: {
            distinct_id: getState().current_user.data.data.id,
            Component: component || "start",
            Section: "extras",
          },
        },
      };
    }

    dispatch({
      type: COB_EXTRAS_COMPONENT_COMPLETE,
      payload: {
        component: component,
        state: state,
      },
      meta: meta,
    });
  };
}
