import Checkbox from "modules/shared/components/inputs/Checkbox";
import React, { ReactElement } from "react";

type handleArgs = {
  value: boolean,
}

interface Props {
  handleCheck: (args: handleArgs) => void,
  isChecked: boolean,
  userType?: string,
}

function ConsumerType({
  handleCheck,
  isChecked,
}: Props): ReactElement | null {
  const item = {
    description: "This user can apply for credit/cash applications on behalf of the business",
    id: "user_type_consumer_manager",
    label: "Consumer",
    type: "user_type_consumer_manager",
  };

  const onClick = () => handleCheck({ value: !isChecked, ...item });

  return (
    <Checkbox
      checkboxId={item.id}
      checked={isChecked}
      handleChange={onClick}
      label={item.label}
      description={item.description}
    />
  );
}

export default ConsumerType;
