import React, { Component } from "react";

import Button from "modules/shared/components/inputs/Button";
import SignatureIdentityConfirm from "modules/shared/signature/SignatureIdentityConfirm";
import { connect } from "react-redux";

class CardholderIdentityConfirm extends Component {
  backButton() {
    return <Button text="Back" link="/cardholder/identity/capture" />;
  }

  nextButton() {
    return <Button text="Next" link="/cardholder/terms" />;
  }

  render() {
    return (
      <SignatureIdentityConfirm
        isCardholder={true}
        supplierLogo=""
        supplierTradingName="ACME Corp"
        backButton={this.backButton()}
        nextButton={this.nextButton()}
        handleComplete={() => ""}
        setPageValidationStartFinish={() => ""}
      />
    );
  }
}

export default connect((state, ownProps) => {
  return {};
})(CardholderIdentityConfirm);
