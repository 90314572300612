import { loadCustomerChecklist } from 'modules/profile/actions';
import React, { useEffect, useState } from 'react'
import styles from "../css/CommonEditProfile.css";
import { getOrInitializeAddonModule } from './helper';
// @ts-ignore-next-line
import SupplierChecklistSetup from './SupplierChecklistSetup';
import { connect } from 'react-redux';
import UserModel from 'models/UserModel';
import AddonConfigModel from 'models/AddonConfigModel';
import get from 'lodash.get';

function CustomerChecklist({
  addonConfig,
  currentUser,
  dispatch,
}: {
  addonConfig: AddonConfigModel | {};
  currentUser: UserModel;
  dispatch: (dispatchFunction: () => void) => void;
}) {
  const [showModal, setShowModal] = useState(false);

  function onClickSetupLink() {
    setShowModal(true);
  }

  useEffect(() => {
    dispatch(loadCustomerChecklist());
  }, [])

  if (showModal) {
    return (
      <SupplierChecklistSetup
        addonConfig={getOrInitializeAddonModule(addonConfig)}
        closeModal={() => setShowModal(false)}
        currentUser={currentUser}
        dispatch={dispatch}
      />
    )
  }

  return (
    <div className={styles.row}>
      <div className={styles.block}>
        <div className={styles.liner}>
          <h2 className={styles.header}>Customer checklist</h2>
          <div>
            Add a customised message and checklist in the
            beginning of the credit applications. Set it up
            {" "}
            <a onClick={onClickSetupLink}>here</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect((state) => {
  const currentUser =  UserModel.fromCurrentUser(state.current_user);
  const addonConfig = get(state, 'manage_profile.settingsCustomerChecklist[0]', {});

  return {
    addonConfig,
    currentUser,
  }
})(CustomerChecklist);