import styles from "modules/paperless-onboarding/css/Complete.css";
import SectionBadge from "modules/shared/components/widgets/static/SectionBadge";
import SectionDescription from "modules/shared/components/widgets/static/SectionDescription";
import SectionTitle from "modules/shared/components/widgets/static/SectionTitle";
import React from "react";

export default function Complete(props) {
  const { paperlessAuthorisation } = props;
  const supplierName = paperlessAuthorisation.attributes.supplier_name;
  const approved = paperlessAuthorisation.attributes.approved;

  let title = "Authorisation complete!";
  let secondaryTitle = null;
  let subtext = "";
  let icon = "thumbsup";
  if (!approved) {
    title = "Thanks for letting us know.";
    secondaryTitle = `We will notify ${supplierName} of your decision.`;
    subtext =
      "If required, you can click back on your original link to change the outcome.";
    icon = "hands";
  }

  return (
    <div className={styles.containerFlex}>
      <section className={styles.blue_section}>
        <div className={styles.row}>
          <div className={styles.panel}>
            <SectionTitle white={true} text={title} />
            {secondaryTitle && (
              <SectionTitle white={true} text={secondaryTitle} />
            )}
            <SectionBadge icon={icon} />
          </div>
        </div>
        <div className={styles.description_row}>
          <div className={styles.description}>
            <SectionDescription text={subtext} />
          </div>
        </div>
      </section>
    </div>
  );
}
