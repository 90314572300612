import BorderedSelect from "modules/shared/components/inputs/BorderedSelect";
import React, { ReactElement } from "react";
import { Controller } from "react-hook-form-latest";

import { Error } from "./ReactHookFormInputs/types";

type Props = {
  count: number;
  control: any;
  disabled: boolean;
  error?: any;
  handleChange?: () => void;
  id: string;
  name: string;
  placeholder: string;
  value?: number;
};

function NumberDropdown(props: Props): ReactElement {
  const { control, count, error, handleChange } = props;

  const options = [...Array(count)].map((_, index) => {
    const value = index + 1;

    return { brief: value, label: value, value };
  });

  const SelectElement = props => (
    <BorderedSelect options={options} {...props} />
  );

  if (!control) {
    return <SelectElement {...props} onChange={handleChange} />;
  }

  return (
    <Controller
      {...props}
      render={({ field, fieldState: { error: fieldError } }) => {
        const processedError = error || fieldError;
        return (
          <SelectElement
            {...props}
            {...field}
            value={field.value ? field.value : ""}
            error={!!processedError}
            helperText={processedError && processedError.message}
          />
        );
      }}
    />
  );
}

export default NumberDropdown;
