import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import styles from "../../../css/CommonReviewBusiness.css";

var IndustryClassifications = createClass({
  render() {
    const { isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="Industry Classifications (ANZSIC 2006)"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>Information Unavailable</p>
      </ReviewContainer>
    );
  },
});

export default IndustryClassifications;
