import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import styles from "../../css/CommonReviewBusiness.css";

var AgedBalances = createClass({
  _agedBalances() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      agedBalances = enquiryResult.aged_balances || {},
      summary = agedBalances.aged_balances_web_summary_ws_dto || [];

    if (summary.constructor === Object) summary = [summary];

    return summary.map((balance, index) => {
      return (
        <tr key={index}>
          <td>{balance.month_name}</td>
          <td>
            {parseFloat(balance.billed_this_month).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </td>
          <td>
            {parseFloat(balance.due_this_month).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </td>
          <td>
            {parseFloat(balance.overdue1_month).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </td>
          <td>
            {parseFloat(balance.overdue2_months).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </td>
          <td>
            {parseFloat(balance.overdue3_months).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </td>
          <td>
            {parseFloat(balance.unallocated).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </td>
          <td>
            {parseFloat(balance.total_debt).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </td>
          <td>
            {parseFloat(balance.retentions).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </td>
          <td>{balance.average_dso}</td>
          <td>{balance.vendor_count}</td>
          <td>
            {parseFloat(balance.cost_to_industry).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </td>
        </tr>
      );
    });
  },

  render() {
    const { isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="Aged Balances Monthly View"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>Combined credit limit</strong>
        </p>
        <table className={styles.table_w20}>
          <thead>
            <tr>
              <th>Month</th>
              <th>This months purchases</th>
              <th>Due this month</th>
              <th>At 1 month</th>
              <th>At 2 months</th>
              <th>At 3+ months</th>
              <th>Unalloc.</th>
              <th>Total</th>
              <th>Resentions</th>
              <th>Avg DSO</th>
              <th>No. Suppliers</th>
              <th>Cost to Industry</th>
            </tr>
          </thead>
          <tbody>{this._agedBalances()}</tbody>
        </table>
      </ReviewContainer>
    );
  },
});

export default AgedBalances;
