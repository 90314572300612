import { Document, Page } from "react-pdf/dist/entry.webpack";
import React from "react";

import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import { isMobile } from "modules/shared/helpers/mobileDetect";

/* Import CSS */
import styles from "./css/PdfReader.css";

var PdfReader = createClass({
  getInitialState: function() {
    return {
      isLoading: true,
      pageNumber: 1,
      num_pages: [0, 0],
      rendered_pages: 0,
    };
  },

  loadListener: function() {
    this.setState({
      isLoading: false,
    });
  },

  onScroll: function() {
    const pdf_container = this.refs.pdf_container;
    if (
      pdf_container.scrollTop >=
      pdf_container.scrollHeight - pdf_container.clientHeight
    ) {
      if (this.props.onScrollToBottom) {
        this.props.onScrollToBottom();
      }
    }
  },

  componentWillMount: function() {
    const { onScrollToBottom } = this.props;
    if (isMobile() && onScrollToBottom) {
      onScrollToBottom();
    }
  },

  componentDidMount: function() {
    const container_width = this.refs.pdf_container.clientWidth;
    this.setState({ container_width });
  },

  onDocumentLoad: function(index, { numPages }) {
    const { num_pages } = this.state;
    let new_num = num_pages.slice(0);
    new_num[index] = numPages;
    this.setState({ num_pages: new_num });
  },

  onPageRender: function() {
    this.setState({
      rendered_pages: this.state.rendered_pages + 1,
    });
  },

  render: function() {
    const {
      url,
      urls,
      pre_pdf_content,
      post_pdf_content,
      no_wrapper,
      height,
      onClick,
      onError,
    } = this.props;
    const { num_pages, container_width, rendered_pages } = this.state;
    let is_loading = true;
    if (urls) {
      is_loading =
        num_pages.some((v, i) => i < urls.length && v === 0) ||
        rendered_pages !== num_pages.reduce((acc, v) => acc + (v ? v : 0), 0);
    } else if (url) {
      is_loading = num_pages[0] === 0 || rendered_pages !== num_pages[0];
    } else {
      // Used to combine two ordinary T&Cs
      is_loading = false;
    }

    const onPageRenderError = () => {
      if (onError) {
        onError();
      }
    };

    return (
      <div
        className={isMobile() || no_wrapper ? "" : styles.wrapper}
        onClick={onClick}
      >
        {
          <div
            ref="pdf_container"
            onScroll={this.onScroll}
            className={
              isMobile() || no_wrapper
                ? styles.pdf_container_no_wrapper
                : styles.pdf_container
            }
          >
            {is_loading && <SimpleLoader css_class="loader_relative" />}
            {is_loading || pre_pdf_content}
            {url && (
              <Document
                file={url}
                onLoadSuccess={this.onDocumentLoad.bind(this, 0)}
                loading=" "
              >
                {Array.from({ length: num_pages[0] }).map((x, i) => (
                  <Page
                    pageNumber={i + 1}
                    width={height ? null : container_width}
                    height={height}
                    renderTextLayer={false}
                    onRenderSuccess={this.onPageRender}
                    onRenderError={onPageRenderError}
                  />
                ))}
              </Document>
            )}
            {urls &&
              urls.map((current_url, i) => (
                <Document
                  file={current_url}
                  onLoadSuccess={this.onDocumentLoad.bind(this, i)}
                  loading=" "
                >
                  {Array.from({ length: num_pages[i] }).map((x, i) => (
                    <Page
                      pageNumber={i + 1}
                      width={height ? null : container_width}
                      height={height}
                      renderTextLayer={false}
                      onRenderSuccess={this.onPageRender}
                      onRenderError={onPageRenderError}
                    />
                  ))}
                </Document>
              ))}
            {is_loading || post_pdf_content}
          </div>
        }
      </div>
    );
  },
});

export default PdfReader;
