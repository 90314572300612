import get from "lodash.get";
import styles from "modules/new-applications/css/AddonAnswer.css";
import GridContent from "modules/shared/components/containers/GridContent";
import LabeledContent from "modules/shared/components/widgets/static/LabeledContent";
import moment from "moment";
import React, { Fragment } from "react";
import { formatMoney } from "utils/formatting";
import isBlank from "utils/isBlank";

const COMPONENTS = {
  check_boxes: MultipleAnswers,
  dropdown_menu: MultipleAnswers,
  pair_question: PairQuestion,
  section_title: SectionTitle,
};

function PairQuestion(props) {
  const { answer, component } = props;
  const value2 = formatMoney(parseInt(get(answer, "value_2", 0)));

  return (
    <Fragment>
      <LabeledContent
        label={component.field_description_1}
        content={answer.value_1}
      />
      <LabeledContent
        label={component.field_description_2}
        content={`$${value2}`}
      />
    </Fragment>
  );
}

function SectionTitle(props) {
  const { component } = props;

  return (
    <div className={styles.section_title}>{component.field_description}</div>
  );
}

function getMultipleAnswers(answers) {
  if (isBlank(answers)) {
    return "-";
  }

  if (Array.isArray(answers)) {
    return answers.join(", ");
  }

  if (Object.keys(answers).includes("main")) {
    const { other, main } = answers;

    if (!main && other.length === 0) {
      return "-"
    }

    return [`${main ? main : "-"} (main option)`, ...other].join(", ");
  }

  return answers;
}

function MultipleAnswers(props) {
  const { answer, component } = props;

  return (
    <LabeledContent
      label={component.field_description}
      content={getMultipleAnswers(answer.value)}
    />
  );
}

function TextAnswer(props) {
  const { answer, component } = props;
  const { value } = answer;
  let content = value;

  if (value && component.accepted_value === "date") {
    content = moment(answer.value).format("DD/MM/YYYY");
  }

  return (
    <LabeledContent
      label={component.field_description}
      content={content}
    />
  );
}

function AnswerComponent(props) {
  const { component } = props;
  const RenderComponent = COMPONENTS[component.component_type] || TextAnswer;

  return <RenderComponent {...props} />;
}

function getAnswerFromComponent(component, answers) {
  return (
    answers.find(answer => {
      if (component.field_description) {
        return answer.description === component.field_description;
      }

      return (
        answer.description_1 === component.field_description_1 &&
        answer.description_2 === component.field_description_2
      );
    }) || {}
  );
}

export default function AddonAnswers(props) {
  const { isContained } = props;
  const answers = props.answers || [];
  const components = props.components || [];

  const answerComponents = components.map((component, index) => {
    const answer = getAnswerFromComponent(component, answers);

    return (
      <AnswerComponent
        key={`answer-component-${index + 1}`}
        component={component}
        answer={answer}
      />
    );
  });

  if (isContained) {
    return (
      <GridContent mobileGridColumnTemplate="half">
        {answerComponents}
      </GridContent>
    );
  }

  return answerComponents;
}

AddonAnswers.defaultProps = {
  isContained: true,
};
