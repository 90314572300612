import Application from "modules/new-applications/components/Application";
import CreditControl from "modules/new-applications/components/application-actions/CreditControl";
import DeleteArchive from "modules/new-applications/components/application-actions/DeleteArchive";
import EmailLog from "modules/new-applications/components/application-actions/EmailLog";
import Notes from "modules/new-applications/components/application-actions/Notes";
import PPSRRegister from "modules/new-applications/components/application-actions/PPSRRegister";
import ReallocateReassign from "modules/new-applications/components/application-actions/ReallocateReassign";
import Additional from "modules/new-applications/components/application-sections/Additional";
import AuthorisedApplicant from "modules/new-applications/components/application-sections/AuthorisedApplicant";
import BusinessDetails from "modules/new-applications/components/application-sections/BusinessDetails";
import Cards from "modules/new-applications/components/application-sections/Cards";
import CreditChecks from "modules/new-applications/components/application-sections/CreditChecks";
import DirectDebit from "modules/new-applications/components/application-sections/DirectDebit";
import Financials from "modules/new-applications/components/application-sections/Financials";
import Guarantors from "modules/new-applications/components/application-sections/Guarantors";
import IdentificationChecks from "modules/new-applications/components/application-sections/IdentificationChecks";
import IUF from "modules/new-applications/components/application-sections/IUF";
import LimitAndApprovals from "modules/new-applications/components/application-sections/LimitAndApprovals";
import LoadedInSystem from "modules/new-applications/components/application-sections/LoadedInSystem";
import Signatories from "modules/new-applications/components/application-sections/Signatories";
import TradeReferences from "modules/new-applications/components/application-sections/TradeReferences";
import TradingDetails from "modules/new-applications/components/application-sections/TradingDetails";

const APPLICATION_SECTIONS = [
  {
    component: Additional,
    path: "additional",
  },
  {
    component: AuthorisedApplicant,
    path: "authorised_applicant",
  },
  {
    component: Cards,
    path: "cards",
  },
  {
    component: CreditChecks,
    path: "credit_checks",
  },
  {
    component: DirectDebit,
    path: "direct_debit",
  },
  {
    component: Financials,
    path: "financials",
  },
  {
    component: Guarantors,
    path: "guarantors",
  },
  {
    component: Signatories,
    path: "signatories",
  },
  {
    component: LimitAndApprovals,
    path: "limit_and_approvals",
  },
  {
    component: LoadedInSystem,
    path: "loaded_in_system",
  },
  {
    component: TradeReferences,
    path: "trade_references",
  },
  {
    component: TradingDetails,
    path: "trading_details",
  },
  {
    component: IdentificationChecks,
    path: "identification_checks",
  },
  {
    component: IUF,
    path: "internal_use_fields",
  },
];

const TOPBAR_ACTIONS = [
  {
    component: DeleteArchive,
    path: "delete_archive",
  },
  {
    component: Notes,
    path: "notes",
  },
  {
    component: ReallocateReassign,
    path: "reallocate_reassign",
  },
  {
    component: CreditControl,
    path: "credit_control",
  },
  {
    component: PPSRRegister,
    path: "ppsr_register",
  },
  {
    component: EmailLog,
    path: "email_log",
  },
];

const ApplicationRoute = {
  childRoutes: [...APPLICATION_SECTIONS, ...TOPBAR_ACTIONS],
  component: Application,
  indexRoute: {
    component: BusinessDetails,
  },
  path: "applications/:application_id",
};

export default ApplicationRoute;
