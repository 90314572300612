import { get } from "lodash";

export const isFaceMatchEnabled = ({
  cardManagementApplication,
  cardholderAuthorisation,
}) => {
  const flow = cardManagementApplication || cardholderAuthorisation;
  const antiFraudCheckConfig = get(flow, "antiFraudCheckConfig.config", {});
  const { authorisation_types, category } = antiFraudCheckConfig;

  if (category !== "3") {
    return false;
  }

  if (cardManagementApplication) {
    const cardholderApplicant = get(flow, "attributes.cardholders", [])
      .find(({ is_applicant }) => is_applicant === "Yes");

    if (authorisation_types.includes("applicant")) {
      return true;
    }

    if (
      cardholderApplicant &&
      authorisation_types.includes("cardholder")
    ) {
      return true
    }
  }

  if (cardholderAuthorisation) {
    if (authorisation_types.includes("cardholder")) {
      return true;
    }
  }

  return false;
}

export const signatureRequired = cardManagementApplication => {
  const {
    antiFraudCheckConfig,
    requireAmlCheck,
    requireIdCheck,
  } = cardManagementApplication;

  let signatureRequired = cardManagementApplication.signatureRequired === "on"

  if (antiFraudCheckConfig && !requireAmlCheck && !requireIdCheck) {
    signatureRequired = antiFraudCheckConfig && signatureRequired;
  }

  return signatureRequired;
}

export const idUploadSignatureRequired = cardManagementApplication => {
  const {
    antiFraudCheckConfig,
    requireAmlCheck,
    requireIdCheck,
  } = cardManagementApplication;

  let signatureRequired = cardManagementApplication.signatureRequired === "on"

  if (antiFraudCheckConfig && !requireAmlCheck && !requireIdCheck) {
    signatureRequired = true
  }

  return signatureRequired;
}
