/* Import libraries */
import React from "react";

import styles from "./css/Header.css";

class Card extends React.Component {
  render() {
    let headerStyle = styles.header;

    if (this.props.borderBottom) {
      headerStyle = styles.bordered_header;
    }

    return <h2 className={headerStyle}>{this.props.children}</h2>;
  }
}

module.exports = Card;
