import api from "api";
import get from "lodash.get"
import isEmpty from "lodash.isempty";
import UserModel from "models/UserModel";
import Buttons from "modules/shared/components/containers/Buttons";
import Button from "modules/shared/components/inputs/Button";
import { isIUFCompleted } from "modules/shared/helpers/internalUseFieldsHelper";
import React, { ReactElement, useState } from "react"
import { connect } from "react-redux";

import AccountLevelForm from "./AccountLevelForm";
import SupplierForm from "./SupplierForm";
import TradingNamesForm from "./TradingNamesForm";
import useProcessedAddonAnswers, { Owner } from "./useProcessedAddonAnswers";

interface Props {
  currentUser: UserModel;
  handleComplete: () => void;
  isCompany: boolean;
  onFetchApplicationRecord: () => void;
  dispatch: any;
  owner: Owner & {
    preview?: boolean,
  };
}

async function createOrUpdateAddonAnswers({
  addonAnswers,
  draft,
  successCallback = (v:any = "") => { v },
  errorCallback = (v:any = "") => { v },
}) {
  const addonAnswersApi = api("addon_answers");

  try {
    for (const addonAnswer of addonAnswers) {
      const { id, ...addonAnswerData } = addonAnswer;

      if (draft) {
        addonAnswerData["status"] = "draft";
      } else {
        addonAnswerData["status"] = "completed";
      }

      if (id) {
        await addonAnswersApi.updateAddonAnswer(id, addonAnswerData);
      } else {
        await addonAnswersApi.createAddonAnswer(addonAnswerData);
      }
    }

    successCallback();
  } catch (e) {
    errorCallback(e);
  }
}

function IUFEdit({
  currentUser,
  dispatch,
  handleComplete,
  isCompany,
  onFetchApplicationRecord,
  owner,
}: Props): ReactElement|null {
  const iufRule = get(owner, "iufAddonRule", {});

  if (isEmpty(iufRule)) {
    return null;
  }

  const iufConfig = iufRule.config;
  const {
    ownerIUFAnswers,
    handleOwnerChange,
    handleFileChange,
    isDraft,
    tradingNameAnswers,
  } = useProcessedAddonAnswers(owner);
  const accountLevelAnswers = get(ownerIUFAnswers, "answers.account_level.answers", []);
  const pricingAnswers = get(ownerIUFAnswers, "answers.pricing.answers", []);
  const file = get(ownerIUFAnswers, "file");
  const [mode, setMode] = useState<"onChange"|"onBlur">("onBlur");
  const [loading, setLoading] = useState<"draft"|"submit"|"">("");
  const [fileError, setFileError] = useState(false);

  if (!iufConfig) {
    return null;
  }

  const { account_level, trading_entity, pricing } = iufConfig;
  const attachmentConfig = get(account_level, "attachment", {});
  const fileSrc = (get(file, "url") && file) || { url: file };

  function handleFileCallback(data) {
    setFileError(false);
    handleFileChange(data);
  }

  function handleSubmit({ draft = false }) {
    const fileHasError = attachmentConfig.active &&
      attachmentConfig.mandatory &&
      !file;

    if (fileHasError) {
      setFileError(true);
    }

    const canSubmit = !owner.preview &&
      (isIUFCompleted() || draft) &&
      !fileHasError;

    setMode("onChange");

    if (!canSubmit) {
      return;
    }

    setLoading(draft ? "draft" : "submit");
    createOrUpdateAddonAnswers({
      addonAnswers: [
        ...tradingNameAnswers,
        ownerIUFAnswers,
      ],
      draft,
      errorCallback: () => {
        setLoading("");
      },
      successCallback: () => {
        setLoading("");
        handleComplete();
        onFetchApplicationRecord();
        if (!draft) {
          dispatch({
            meta: {
              mixpanel: {
                event: "IUF submitted",
                props: {
                  "Entity ID": get(currentUser, "current_entity.id"),
                  Ruleset: iufRule,
                  distinct_id: currentUser.id,
                },
              },
            },
          });
        }
      },
    });
  }

  const formProps = {
    borderedStyle: true,
    mode,
    validationTrigger: true,
  }

  return (
    <>
      <AccountLevelForm
        answers={accountLevelAnswers}
        attachmentConfig={attachmentConfig}
        data={account_level}
        handleFileCallback={handleFileCallback}
        onChange={params => handleOwnerChange("account_level", params)}
        file={file}
        fileError={fileError}
        fileSrc={fileSrc}
        {...formProps}
      />
      <TradingNamesForm
        data={trading_entity}
        formProps={formProps}
        isCompany={isCompany}
        owner={owner}
      />
      <SupplierForm
        answers={pricingAnswers}
        label="Pricing"
        data={pricing}
        onChange={params => handleOwnerChange("pricing", params)}
        reduxKey="iufPricing"
        {...formProps}
      />
      <Buttons spacingDirection="right">
        { isDraft && (
          <Button
            type="button"
            text="Save draft"
            white
            onClick={() => handleSubmit({ draft: true })}
            loading={loading === "draft"}
          />
        )}
        <Button
          type="button"
          text="Submit"
          onClick={handleSubmit}
          loading={loading === "submit"}
        />
      </Buttons>
    </>
  )
}

export default connect((state, ownProps) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  }
})(IUFEdit);
