import {
  COB_TRADE_REFERENCE_SET_REFEREE_DATA,
  COB_TRADE_REFERENCE_PRESET_REFEREE_DATA,
  COB_TRADE_REFERENCE_SET_REFEREE_DETAILS_INDEX,
  COB_TRADE_REFERENCE_SECTION_COMPLETE,
  COB_TRADE_REFERENCE_COMPONENT_COMPLETE,
} from "../constants/trade-reference";

export function tradeRefComplete(complete) {
  return (dispatch, getState) => {
    var section_state = getState().cob_traderef;

    var data = {
      referees: new Array(),
    };

    section_state.referees.forEach((item, index) => {
      data.referees[index] = item;
    });

    dispatch({
      type: COB_TRADE_REFERENCE_SECTION_COMPLETE,
      payload: {
        complete: complete,
        data: data,
      },
    });
  };
}

export function preSetRefereeData(value) {
  return (dispatch, getState) => {
    dispatch({
      type: COB_TRADE_REFERENCE_PRESET_REFEREE_DATA,
      payload: value,
    });
  };
}

export function setRefereeData(index, field, value) {
  return (dispatch, getState) => {
    dispatch({
      type: COB_TRADE_REFERENCE_SET_REFEREE_DATA,
      payload: {
        index: index,
        field: field,
        value: value,
      },
    });
  };
}

export function setRefereeDetailsIndex(index) {
  return {
    type: COB_TRADE_REFERENCE_SET_REFEREE_DETAILS_INDEX,
    payload: index,
  };
}

export function traderefComponentComplete(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state === true) {
      meta = {
        mixpanel: {
          event: "Consumer onboarding",
          props: {
            distinct_id: getState().current_user.data.data.id,
            Component: component,
            Section: "trade references",
          },
        },
      };
    }

    dispatch({
      type: COB_TRADE_REFERENCE_COMPONENT_COMPLETE,
      payload: {
        component: component,
        state: state,
      },
      meta: meta,
    });
  };
}
