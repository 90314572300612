import EntityModel from "models/BaseModel";
import isPresent from "utils/isPresent";

export default class SupplierModel extends EntityModel {
  get formattedTradingName() {
    if (isPresent(this.tradingName)) {
      return this.tradingName;
    }

    return "(No trading name)";
  }
}
