export default function sortByCreatedAt(records) {
  return records.sort((a, b) => {
    const createdAtA = new Date(a.createdAt).getTime();
    const createdAtB = new Date(b.createdAt).getTime();

    if (createdAtA < createdAtB) {
      return 1;
    }

    if (createdAtA > createdAtB) {
      return -1;
    }

    return 0;
  });
}
