/* Import libraries */
import React from "react";
import styles from "./css/CheckBoxes.css";

import toggleArrayValue from "utils/toggleArrayValue";

import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import Switch2 from "./Switch2";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { muiTheme } from "modules/shared/helpers/colorPalettes";

class CheckBoxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialSelectedValue(props);
  }

  initialSelectedValue(props) {
    const { value } = props;
    if (value && value.hasOwnProperty("main")) {
      return {
        mainOption: value.main,
        selectedValue: (value.other || []).concat([value.main]),
      };
    }
    if (value && Array.isArray(value)) {
      return { mainOption: null, selectedValue: value };
    }
    return { mainOption: null, selectedValue: [] };
  }

  handleCheckBoxeClick(option) {
    const { onChange, hasMainOption } = this.props;
    let currentValue = this.state.selectedValue;
    let currentMain = this.state.mainOption;
    // without main option
    if (!hasMainOption) {
      currentValue = toggleArrayValue(currentValue, option);
      onChange({ value: currentValue });
      this.setState({ selectedValue: currentValue });
      return;
    }

    // with main option
    currentValue = toggleArrayValue(currentValue, option);
    if (currentMain === option) currentMain = null;
    onChange({
      value: {
        main: currentMain,
        other: currentValue.filter(e => e !== currentMain),
      },
    });
    this.setState({ mainOption: currentMain, selectedValue: currentValue });
  }

  handleCheckBoxeDoubleClick(option) {
    const { onChange } = this.props;
    const currentValue = this.state.selectedValue;

    onChange({
      value: { main: option, other: currentValue.filter(e => e !== option) },
    });
    this.setState({ mainOption: option });

    if (!currentValue.includes(option) && option) {
      this.setState({ selectedValue: currentValue.concat([option]) });
    }
    return true;
  }

  renderOptions(items, category, options) {
    const { optionsOneRow, hasMainOption } = this.props;
    const { selectedValue } = this.state;

    options.forEach(option => {
      const key = category ? `${category} - ${option}` : option;
      const optionStyle = optionStyleMapper[optionsOneRow];
      const isMainOption = this.state.mainOption === key;

      const callback = (e, key) => {
        //true,false
        if (e) {
          this.handleCheckBoxeDoubleClick(key);
        } else {
          this.handleCheckBoxeDoubleClick(null);
        }
      };

      items.push(
        <div
          className={styles[optionStyle]}
          key={key}
          onClick={() => this.handleCheckBoxeClick(key)}
          // onDoubleClick={() => {
          //   hasMainOption && this.handleCheckBoxeDoubleClick(key);
          // }}
        >
          <div className={isMainOption ? styles.main_option : styles.options}>
            <SquareCheckbox
              label={option}
              checked={selectedValue.includes(key)}
              handleChange={() => this.handleCheckBoxeClick(key)}
            >
              {selectedValue.includes(key) && hasMainOption && (
                <span className={styles.switch2_margin}>
                  <Switch2
                    label="Main Option"
                    checked={
                      this.state.mainOption && this.state.mainOption === key
                    }
                    callback={e => callback(e, key)}
                    prevent="true"
                  />
                </span>
              )}
            </SquareCheckbox>
          </div>
        </div>,
      );
    });
  }

  renderMessage() {
    const { required, hasMainOption, error } = this.props;

    const message = ["("];
    if (hasMainOption && !error)
      message.push(
        "Please select your main option using the slider on the right hand side of the option",
      );
    if (!required) message.push("This is optional");
    message.push(")");

    switch (message.length) {
      case 4:
        return ` ${message.slice(0, 2).join("")}. ${message.slice(2).join("")}`;
      case 3:
        return ` ${message.join("")}`;
      default:
        return "";
    }
  }

  getValues(arr) {
    let res = [];
    arr.forEach(v => res.push({ label: v, value: v }));
    return res;
  }

  render() {
    const { borderedStyle, label, options, error, required } = this.props;

    const items = [];
    if (Array.isArray(options)) {
      this.renderOptions(items, "", options);
    } else {
      Object.keys(options).forEach(category => {
        items.push(
          <div key={category} className={styles.full_width}>
            <div className={styles.category}>{category}</div>
          </div>,
        );
        this.renderOptions(items, category, options[category]);
      });
    }

    const requiredMark = required ? (
      <span className={error ? 'has-text-danger' : undefined}>*</span>
    ) : '';

    return (
      <MuiThemeProvider theme={muiTheme()}>
        <div>
          {label}{requiredMark}
          {this.renderMessage()}
          <span className={styles.error}>{error}</span>
        </div>
        <div className={styles.row}>
          {items}
          {/* <br />
        {this.state.selectedValue.length > 0 && (
          <OptionsDropdown
            id="main_option_1"
            name="main_option_1"
            label="Please select one as main option"
            value={this.state.mainOption}
            handleChange={e => this.handleCheckBoxeDoubleClick(e.value)}
            required={true}
            options={this.getValues(this.state.selectedValue)}
          />
        )} */}
        </div>
      </MuiThemeProvider>
    );
  }
}

const optionStyleMapper = {
  1: "full_width",
  2: "half_width",
  3: "one_third_width",
  4: "quarter_width",
  6: "one_sixth_width",
};

export default CheckBoxes;
