import { FormControlLabel, FormHelperText, Radio, RadioGroup } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { get } from "lodash";
import {
  createAddonVersion,
  loadAddons,
  resetCurrentAddonRuleset,
  setAddonEntityType,
  setAddonValue,
} from "modules/addons/actions";
import AddOnsNotSavePopup from "modules/addons/addons_form/components/AddOnsNotSavePopup";
import LegalTypeCheckbox from "modules/addons/addons_form/components/LegalTypeCheckbox";
import LimitRange from "modules/addons/addons_form/components/LimitRange";
import PreviewSaveButtons from "modules/addons/addons_form/components/PreviewSaveButtons";
import styles from "modules/addons/components/css/AddOnsDetails.css";
import {
  createLimitConflictMessage,
  findLimitBreakpoints,
  findLimitConflicts,
} from "modules/addons/helpers";
import { loadAmlCheckRules } from "modules/profile/actions";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import FormBuilder from "modules/shared/components/widgets/interactive/form_builder/FormBuilder";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";
import isBlank from "utils/isBlank";

import { getCreditLimitError, getLegalTypesError } from "../../helpers";

const ADDON_NAME = "cards";
const SIGNATURE_PATH = "/dashboard/profile?active_tab=account_rules";

const Togglers = [{ label: "On", value: "on" }, { label: "Off", value: "off" }];
const MandatoryOptions = [
  { label: "Mandatory", value: true},
  { label: "Optional", value: false }
];

class CardsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      notSaved: false,
      notSavedPopup: false,
    };
  }

  get addonName() {
    const { currentCard, readOnly } = this.props;
    const attributes = currentCard.attributes;
    const name = attributes.name;

    if (readOnly) {
      return `${name} (Version ${attributes.version})`;
    }

    return name;
  }

  get creditLimitRangeConflictError() {
    const { cards, currentCard } = this.props;
    const limitBreakpoints = findLimitBreakpoints(cards, currentCard);
    const limitConflicts = findLimitConflicts(limitBreakpoints, currentCard);

    if (limitConflicts) {
      return createLimitConflictMessage(limitConflicts);
    }

    return null;
  }

  addonContentChanged() {
    this.setState({ notSaved: true });
  }

  showNotSavedPopup() {
    this.setState({ notSavedPopup: true });
  }

  hideNotSavedPopup() {
    this.setState({ notSavedPopup: false });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetCurrentAddonRuleset(ADDON_NAME));
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadAmlCheckRules());
  }

  onHandleChangeCardName(event) {
    const { dispatch } = this.props;
    dispatch(setAddonValue(event.target.id, event.target.value, ADDON_NAME));
    this.addonContentChanged();
  }

  onHandleChangeCardLimit(event) {
    const {
      currentCard: { attributes },
      dispatch,
    } = this.props;
    const { value } = event.target;
    const { config } = attributes;

    dispatch(
      setAddonValue(
        "config",
        { ...config, card_limit_enabled: value },
        ADDON_NAME,
      ),
    );
  }

  onHandleSignature(event) {
    const {
      currentCard: { attributes },
      dispatch,
    } = this.props;
    const { value } = event.target;
    const { config } = attributes;

    dispatch(
      setAddonValue(
        "config",
        { ...config, signature_required: value },
        ADDON_NAME,
      ),
    );
  }

  onSelectEntityType(event) {
    const { errors } = this.state;
    delete errors.legal_types;
    this.setState({ errors });

    const { dispatch } = this.props;
    dispatch(setAddonEntityType(event.target.id, ADDON_NAME));
    this.addonContentChanged();
  }

  onHandleChangeLimitRange(name, value) {
    const { errors } = this.state;
    delete errors.credit_limit;
    this.setState({ errors });

    const { dispatch } = this.props;
    dispatch(setAddonValue(name, value, ADDON_NAME));
    this.addonContentChanged();
  }

  onHandleChangeMandatory(event) {
    const {
      currentCard: { attributes },
      dispatch,
    } = this.props;
    const { value } = event.target;
    const { config } = attributes;

    dispatch(
      setAddonValue(
        "config",
        { ...config, mandatory: value === 'true' },
        ADDON_NAME,
      ),
    );
  }

  onHandleFormBuilderChanges(forms) {
    const {
      currentCard: { attributes },
      dispatch,
    } = this.props;
    const { config } = attributes;

    dispatch(
      setAddonValue(
        "config",
        { ...config, additional_fields: forms },
        ADDON_NAME,
      ),
    );
    this.addonContentChanged();
  }

  onSubmit() {
    const { currentCard, dispatch, handleDiscard } = this.props;
    if (this.validateFields()) {
      dispatch(
        createAddonVersion(currentCard.attributes, ADDON_NAME, () => {
          handleDiscard();
          dispatch(loadAddons(ADDON_NAME));
        }),
      );
    }
  }

  onDiscard() {
    const { handleDiscard } = this.props;
    if (this.state.notSaved) {
      this.showNotSavedPopup();
    } else {
      handleDiscard();
    }
  }

  validateFields() {
    const { currentCard } = this.props;
    const attributes = currentCard.attributes;

    const legalTypesError = getLegalTypesError(attributes.legal_types);
    const creditLimitError = getCreditLimitError(
      attributes.min_credit_value,
      attributes.max_credit_value,
    );

    const configFields = {
      card_limit_enabled: 'Please select one of the option',
      signature_required: 'Please select one of the option',
    }

    const errors = {
      ...legalTypesError,
      ...creditLimitError,
    };

    Object.keys(configFields).forEach(key => {
      if (attributes.config[key]) {
        return;
      }

      errors[key] = configFields[key];
    });

    this.setState({ errors });

    return (
      Object.keys(errors).length === 0 &&
      isBlank(this.creditLimitRangeConflictError)
    );
  }

  onClickSignatureLink() {
    browserHistory.push(SIGNATURE_PATH);
  }

  additionalFields() {
    const { currentCard, readOnly } = this.props;

    return (
      <div className={styles.section}>
        <h3>Step 6: Additional Fields</h3>
        <div className={styles.info}>
          Add other information you want to collect from the cardholders
        </div>
        <div className={styles.row}>
          <FormBuilder
            module="cards"
            components={currentCard.attributes.config.additional_fields}
            editable={!readOnly}
            onChange={this.onHandleFormBuilderChanges.bind(this)}
          />
        </div>
      </div>
    );
  }

  fieldError = (fieldName) => {
    const { errors } = this.state;

    if (!errors[fieldName]) {
      return;
    }

    return <FormHelperText error style={{ fontSize: '10px' }}>{errors[fieldName]}</FormHelperText>
  }

  render() {
    const { errors, notSavedPopup } = this.state;
    const { currentCard, readOnly, handleDiscard, signatureRequired } = this.props;
    const attributes = currentCard.attributes;

    return (
      <MuiThemeProvider theme={muiTheme()}>
        <section className={styles.container}>
          <div>
            <Input
              id="name"
              classes={{ input: styles.name_text, root: styles.input_parent }}
              value={this.addonName}
              placeholder="Name your ruleset here - eg: Company"
              onChange={this.onHandleChangeCardName.bind(this)}
              required
              disabled={readOnly}
            />
            <CloseButton handleClick={this.onDiscard.bind(this)} />
          </div>
          <div className={styles.section}>
            <div className={styles.row}>
              <div className={styles.info}>{description}</div>
            </div>
          </div>
          <LegalTypeCheckbox
            selected={attributes.legal_types}
            handleChange={this.onSelectEntityType.bind(this)}
            error={errors.legal_types}
            readOnly={readOnly}
            title="Step 1: Entity types"
            description="Select the entity types eligible to apply for cards with your business."
          />
          <LimitRange
            addOn={{
              max_credit_value: attributes.max_credit_value,
              min_credit_value: attributes.min_credit_value,
            }}
            handleChange={(name, value) => {
              this.onHandleChangeLimitRange(name, value);
            }}
            error={this.creditLimitRangeConflictError || errors.credit_limit}
            readOnly={readOnly}
            title="Step 2: Limit Range"
          />

          <div className={styles.section}>
            <h3>Step 3: Monthly card limit</h3>
            <div className={styles.info}>
              Turning on &apos;monthly card limit&apos; will allow the applicant to add a
              monthly card limit onto each individual card applied for. If you set this to
              off, this question will not be populated.
            </div>
            <div className={styles.row}>
              <RadioGroup
                defaultValue={attributes.config.card_limit_enabled}
                defaultChecked={attributes.config.card_limit_enabled}
              >
                {Togglers.map(({ label, value }) => (
                  <FormControlLabel
                    name={"card-limit"}
                    key={`radio-${label}`}
                    value={value}
                    control={<Radio />}
                    label={<div>{label}</div>}
                    onChange={this.onHandleChangeCardLimit.bind(this)}
                  />
                ))}
              </RadioGroup>
              {this.fieldError('card_limit_enabled')}
            </div>
          </div>

          <div className={styles.section}>
            <h3>Step 4: Signature</h3>
            <div className={styles.info}>
              Turning on the &apos;signature&apos; function will require each individual
              cardholder to sign for their own card. Click <Link onClick={this.onClickSignatureLink}>here</Link>
              {' '}
              to go through to your identification options - profile tab &gt; account rules &gt; antifraud
              or AML. Our intelligent workflows will reach out to your cardholders for agreement and
              signing. If turned off, the applicant is signing for all cards to be issued.
            </div>
            <div className={styles.row}>
              <RadioGroup
                defaultValue={attributes.config.signature_required}
                defaultChecked={attributes.config.signature_required}
              >
                {Togglers.map(({ label, value }) => (
                  <FormControlLabel
                    name={"signature"}
                    key={`radio-${label}`}
                    value={value}
                    control={<Radio />}
                    label={<div>{label}</div>}
                    onChange={this.onHandleSignature.bind(this)}
                  />
                ))}
              </RadioGroup>
              {this.fieldError('signature_required')}
            </div>
          </div>

          <div className={styles.section}>
            <h3>Step 5: Card details</h3>
            <div className={styles.info}>
              Card details collected here are influenced by whether or not you you require identity
              verification of each individual cardholder. If you do require identification from each
              individual cardholder, the system will reach out to these cardholders to action, you
              will see this in the customers virtual credit file. Click here to go through to your
              identification options - profile tab &gt; account rules &gt; antifraud or AML.
            </div>
            <div className={styles.row}>
              {
                !signatureRequired && (
                  <p>Name to appear on card</p>
                )
              }
              {
                signatureRequired && (
                  <Fragment>
                    <p>First name</p>
                    <p>Middle name</p>
                    <p>Last name</p>
                    <p>Email</p>
                    <p>Phone</p>
                    <p>Identification</p>
                  </Fragment>
                )
              }
            </div>
          </div>
          {this.additionalFields()}

          <div className={styles.section}>
            <h3>Step 7: No. of cards</h3>
            <div className={styles.info}>
              Selecting &apos;optional&apos; will give the customer the option to skip the card section.
            </div>
            <div className={styles.row}>
              <RadioGroup>
                {MandatoryOptions.map(({ label, value }) => (
                  <FormControlLabel
                    name={"mandatory"}
                    key={`radio-${label}`}
                    value={value}
                    control={<Radio />}
                    label={<div>{label}</div>}
                    checked={attributes.config.mandatory === value}
                    onChange={this.onHandleChangeMandatory.bind(this)}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>

          <PreviewSaveButtons
            moduleName={"cards"}
            previewAddon={attributes}
            handleSubmit={this.onSubmit.bind(this)}
            loading={this.props.saving}
            readOnly={readOnly}
          />
          {notSavedPopup && (
            <AddOnsNotSavePopup
              handleSave={this.onSubmit.bind(this)}
              handleLeave={handleDiscard}
              hidePopup={this.hideNotSavedPopup.bind(this)}
            />
          )}
        </section>
      </MuiThemeProvider>
    );
  }
}

const description = (
  <Fragment>
    <p>
      The card module allows you to manage the card requirements of your new customers.
      Firstly, follow the below steps to dynamically curate requirements and questions you need to set up your cardholder/s.
      The card module will automatically integrate into your customers application experience - allowing for a seamless process.
    </p>
    <br />
    <p>
      The card module also enables you to manage the card requirements of your existing customers,
      simply go to your credit control tab where you will find your card management module set up.
    </p>
  </Fragment>
);

export default connect(state => {
  const addOns = state.add_ons;
  const currentUser = state.current_user;
  const manageProfile = state.manage_profile;

  const amlRulesArray = get(manageProfile, "settingsAmlCheckRules.selectedLevels") || [];
  const cardholderIdCheckOn = get(currentUser, "current_entity.attributes.requires_cardholder_identification_check");
  const amlCheckOn = amlRulesArray.includes("cardholder");
  const signatureRequired = cardholderIdCheckOn || amlCheckOn;

  return {
    cards: addOns.cards,
    currentCard: addOns.current_card,
    signatureRequired,
  };
})(CardsDetails);
