import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import get from "lodash.get";
import { DURATION_OPTIONS } from "modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/AUPPSRForm";
import styles from "modules/new-applications/css/PPSRRegister.css";
import Button from "modules/shared/components/inputs/Button";
import LabeledContent from "modules/shared/components/widgets/static/LabeledContent";
import React, { useState } from "react";
import isBlank from "utils/isBlank";

function DisabledNavigationButton(props) {
  const { buttonText, red, tooltip, white } = props;

  return (
    <Tooltip
      title={<span className="is-size-normal">{tooltip}</span>}
      placement="bottom"
      arrow
    >
      <div className={styles.navigation_action}>
        <Button red={red} disabled={true} text={buttonText} white={white} />
      </div>
    </Tooltip>
  );
}

DisabledNavigationButton.defaultProps = {
  red: false,
  white: false,
};

function isRenewEnabled(application) {
  const { ppsrAddonConfig } = application;

  return get(ppsrAddonConfig, "functions.renew", false);
}

function getRenewTooltipMessage({
  isAutoRenew,
  isExpired,
  isIndefiniteDuration,
  isPinAvailable,
  isRenewFunctionEnabled,
}) {
  if (isAutoRenew) {
    return "This financing statement is on a 5-year auto-renew";
  }

  if (isExpired) {
    return "This financing statement has already expired and cannot be renewed.";
  }

  if (isIndefiniteDuration) {
    return "This financing statement has an indefinite duration and cannot be renewed.";
  }

  if (!isRenewFunctionEnabled) {
    return "Renew function is not enabled. Enable renew under Add ons - credit control - PPSR module.";
  }

  if (!isPinAvailable) {
    return "Please provide the registration token to enable the renew function";
  }

  return "";
}

function RenewNavigation(props) {
  const { application, ppsrFinancingStatement, setCurrentPage } = props;
  const isAutoRenew = ppsrFinancingStatement.autoRenew;
  const isExpired = ppsrFinancingStatement.expired;
  const isIndefiniteDuration =
    ppsrFinancingStatement.expiryDuration === "indefinite";
  const isPinAvailable = ppsrFinancingStatement.isPinPresent;
  const isRenewFunctionEnabled = isRenewEnabled(application);

  // An expired PPSR financing statement can no longer be renewed.
  const isDisabled =
    !isRenewFunctionEnabled ||
    !isPinAvailable ||
    isAutoRenew ||
    isExpired ||
    isIndefiniteDuration;

  const buttonText = "Renew";

  if (isDisabled) {
    const tooltip = getRenewTooltipMessage({
      isAutoRenew,
      isExpired,
      isIndefiniteDuration,
      isPinAvailable,
      isRenewFunctionEnabled,
    });

    return (
      <DisabledNavigationButton buttonText={buttonText} tooltip={tooltip} />
    );
  }

  return (
    <div className={styles.navigation_action}>
      <Button
        handleClick={() => setCurrentPage("renew")}
        text={buttonText}
        type="button"
      />
    </div>
  );
}

function getModifyTooltipMessage({ isModifyFunctionEnabled, isPinAvailable }) {
  if (!isModifyFunctionEnabled) {
    return "Modify function is not enabled. Enable modify under Add ons - credit control - PPSR module.";
  }

  if (!isPinAvailable) {
    return "Please provide the registration token to enable the modify function";
  }

  return "";
}

function ModifyNavigation(props) {
  const { application, ppsrFinancingStatement, setCurrentPage } = props;
  const isModifyFunctionEnabled = get(
    application,
    "ppsrAddonConfig.functions.modify",
    false,
  );
  const isPinAvailable = ppsrFinancingStatement.isPinPresent;
  const isEnabled = isModifyFunctionEnabled && isPinAvailable;

  const buttonText = "Modify";

  if (isEnabled) {
    return (
      <div className={styles.navigation_action}>
        <Button
          white
          handleClick={() => setCurrentPage("modify")}
          text={buttonText}
          type="button"
        />
      </div>
    );
  }

  const tooltip = getModifyTooltipMessage({
    isModifyFunctionEnabled,
    isPinAvailable,
  });

  return (
    <DisabledNavigationButton white buttonText={buttonText} tooltip={tooltip} />
  );
}

function getDischargeTooltipMessage({
  isDischargeFunctionEnabled,
  isPinAvailable,
}) {
  if (!isDischargeFunctionEnabled) {
    return "Discharge function is not enabled. Enable discharge under Add ons - credit control - PPSR module.";
  }

  if (!isPinAvailable) {
    return "Please provide the registration token to enable the discharge function";
  }

  return "";
}

function DischargeNavigation(props) {
  const { application, ppsrFinancingStatement, setCurrentPage } = props;
  const isDischargeFunctionEnabled = get(
    application,
    "ppsrAddonConfig.functions.discharge",
    false,
  );
  const isPinAvailable = ppsrFinancingStatement.isPinPresent;
  const isEnabled = isDischargeFunctionEnabled && isPinAvailable;

  const buttonText = "Discharge";

  if (isEnabled) {
    return (
      <div className={styles.navigation_action}>
        <Button
          red
          handleClick={() => setCurrentPage("discharge")}
          text={buttonText}
          type="button"
        />
      </div>
    );
  }

  const tooltip = getDischargeTooltipMessage({
    isDischargeFunctionEnabled,
    isEnabled,
  });

  return (
    <DisabledNavigationButton red buttonText={buttonText} tooltip={tooltip} />
  );
}

export function Navigation(props) {
  const { ppsrFinancingStatement } = props;

  if (ppsrFinancingStatement.isDraft) {
    return null;
  }

  return (
    <div className={`${styles.navigation_action_container} is-flex`}>
      <RenewNavigation {...props} />
      <ModifyNavigation {...props} />
      <DischargeNavigation {...props} />
    </div>
  );
}

export function useFinancingStatementPinVisibleState(props) {
  const { financingStatementPin = "" } = props;
  const [isVisible, setIsVisible] = useState(false);

  const onToggleVisibility = () => setIsVisible(!isVisible);

  let Icon = VisibilityOffOutlinedIcon;
  if (isVisible) {
    Icon = VisibilityOutlinedIcon;
  }

  let content = "*".repeat(financingStatementPin.length);
  if (isVisible) {
    content = financingStatementPin;
  }

  return { Icon, content, onToggleVisibility };
}

export function OrganisationName(props) {
  const { debtor } = props;

  if (debtor.debtorType !== "organisation") {
    return null;
  }

  return (
    <LabeledContent
      label="Organisation name"
      content={debtor.organisationName}
    />
  );
}

export function DateOfBirth(props) {
  const { debtor } = props;

  if (debtor.debtorType !== "person") {
    return null;
  }

  return (
    <LabeledContent
      label="Date of birth"
      content={debtor.formattedDateOfBirth}
    />
  );
}

function ExpiryDateAnnotation(props) {
  const {
    expiryDuration,
    isAutoRenew,
    isExpired,
    isExpiryDurationVisible,
  } = props;

  if (isAutoRenew) {
    return <span className="has-text-primary">(auto-renew)</span>;
  }

  if (isExpired) {
    return <span className="has-text-danger">(expired)</span>;
  }

  if (isExpiryDurationVisible) {
    const label = (
      DURATION_OPTIONS.find(option => option.value === expiryDuration) || {}
    ).label;

    if (isBlank(label)) {
      return null;
    }

    return (
      <span className="has-text-primary">{`(Expiry duration: ${label})`}</span>
    );
  }

  return null;
}

export function ExpiryDate(props) {
  const { isExpiryDurationVisible, ppsrFinancingStatement } = props;
  const {
    autoRenew,
    expired,
    formattedExpiryDate,
    expiryDuration,
  } = ppsrFinancingStatement;

  let content = null;
  if (expiryDuration === "indefinite") {
    content = <div>No stated end time</div>;
  } else {
    content = (
      <div>
        {formattedExpiryDate}&nbsp;
        <ExpiryDateAnnotation
          expiryDuration={expiryDuration}
          isAutoRenew={autoRenew}
          isExpired={expired}
          isExpiryDurationVisible={isExpiryDurationVisible}
        />
      </div>
    );
  }

  return (
    <LabeledContent
      label="Expiry date"
      content={content}
      withBottomMargin={false}
    />
  );
}

ExpiryDate.defaultProps = {
  isExpiryDurationVisible: false,
};

export function PDFFile(props) {
  const { currentUser, pdf } = props;

  if (isBlank(pdf) || isBlank(pdf.url) || currentUser.isStandard) {
    return null;
  }

  const onClick = () => {
    window.open(pdf.url, "ppsr_financing_statement_pdf_window");
  };

  return <Button white text="PDF financing statement" handleClick={onClick} />;
}

function EditFinancingStatementPinAction(props) {
  const { isVisible, onShowEditForm } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <EditIcon
      className="cursor-pointer ml-2"
      color="primary"
      onClick={onShowEditForm}
    />
  );
}

export function FinancingStatementPin(props) {
  const { isEditable, label, onShowEditForm, ppsrFinancingStatement } = props;
  const { financingStatementPin } = ppsrFinancingStatement;

  const {
    content,
    Icon,
    onToggleVisibility,
  } = useFinancingStatementPinVisibleState({ financingStatementPin });

  return (
    <div className={`${styles.financing_statement_pin_container} is-flex`}>
      <div className="is-flex mr-2">
        <div className="has-text-weight-normal mr-2">{label}:</div>
        <div className={styles.financing_statement_pin_content}>{content}</div>
      </div>
      <Icon className="cursor-pointer" onClick={onToggleVisibility} />
      <EditFinancingStatementPinAction
        isVisible={isEditable}
        onShowEditForm={onShowEditForm}
      />
    </div>
  );
}

FinancingStatementPin.defaultProps = {
  isEditable: false,
  label: "PIN",
};
