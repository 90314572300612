import React from "react";
import ScrollArea from "react-scrollbar";

var TermsScroller = createClass({
  onScroll: function(scrollData) {
    if (!this.props.onScrollToBottom) {
      return;
    }
    if (
      scrollData.containerHeight + scrollData.topPosition >=
      scrollData.realHeight
    ) {
      this.props.onScrollToBottom();
    }
  },

  render: function() {
    return (
      <ScrollArea {...this.props} onScroll={this.onScroll}>
        {this.props.children}
      </ScrollArea>
    );
  },
});

module.exports = TermsScroller;
