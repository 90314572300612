import styled from "styled-components";
import { COLORS } from "variables/theme";
import { FONT_SIZES } from "variables/typography";

export const Wrapper = styled.div`
  background: ${COLORS.white};
  max-width: 500px;
  padding: 2rem;

  h2 {
    font-size: ${FONT_SIZES.heading};
    margin-bottom: 1rem;
  }

  p {
    overflow-wrap: anywhere;
  }

  a {
    overflow-wrap: break-word;
  }

  > div > div:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`
