import get from "lodash.get";
import CircleIconButton from "modules/shared/components/widgets/interactive/CircleIconButton";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import { isMobile } from "modules/shared/helpers/mobileDetect";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import mixpanel from "mixpanel-browser";
import { selectEntityType, setEntityFormValue } from "../../actions/onboarding";
import styles from "./css/Business.css";

// TODO: Farmlands has enabled personal account but with custom message
const FARMLANDS_IDS_TO_CUSTOMISE = [
  "78f591f9-67aa-4057-a0c9-7297ae365a4b",
  "071b13f7-f556-4d92-9bad-cd6f365946c6",
  "13c99538-39e3-4858-a616-c1e0ff6021f7",
];

// TODO: Temporarily disable Personal accounts for Farmlands.
// Remove once Farmlands enables personal accounts again.
const FARMLAND_IDS_TO_DISABLE = [
  "07343f97-e888-4fca-a5a0-8d7f5d276579",
  "b0a8b737-2a80-4509-ac2b-14f788be4a6a",
  "168fd11a-c05c-42f0-9bfe-7fd0061933ae",
  "547bb378-4efc-4495-8365-f015509d3d60",
  "66285d41-5ba9-4bd5-9926-118d33971060",
  "a697d2f4-9e9d-4955-8016-19ca1557d0af",
  "f8c45be0-5f87-47e6-ba04-1593654099b4",
  "c68ea14d-a567-49ed-9bd0-85dfbfd03fa2",
  "67d50609-6435-4061-be94-4737926b5c95",
  "42f34a2b-b8ae-4c8a-b8c8-7239a878c640",
  "27176573-b346-4380-a5e9-990c14c598a0",
];

// TODO: Temporarily disable Personal accounts for Higgins.
// Remove once Higgins enables personal accounts again.
const HIGGINS_IDS = [
  "3f37d54b-c4f0-41ce-bc7f-cd55778d9b8d",
  "682d52ad-be4e-4d63-8611-c7d2081838e5",
  "a1ce59d7-7150-47ff-bb4a-b4f917118244",
  "03b4e9ed-a3ec-4db4-afaa-43f1b331a743",
  "91804b32-5b58-4f7c-ba85-757778f023c8",
  "eec2146d-ac9f-4c1a-a8a0-a66feeabc318",
  "94625c9b-7e02-4d4a-b880-bab706ab909b",
  "599657bb-3819-4153-b6cc-f5171df98675",
  "afcd8e05-4f19-4cec-a50c-488510f5e4c9",
  "e52843c2-c050-474b-b13a-305effd21a3e",
  "b9733cea-5418-46e3-90a1-417130d3bdad",
  "7d98aecd-753f-4769-afc4-335725a0b472",
  "8d2b73c5-dfde-46a6-bc01-d974dfb26230",
  "9e50fbf8-1945-402f-a592-acd4f45155c3",
  "84706c0d-a218-4184-946e-93b6a9d18817",
  "e05fd683-d69c-4b75-80e7-29a44374e4c8",
  "3f9c58eb-73d2-4e1c-a50a-0fa35e8ab4b3",
  "122c0975-2d27-40c5-b44f-57b56a973a04",
  "000cb352-82a5-4712-afbb-6a27c02c91f7",
];

const isEntityIconDisabled = (supplierId, entityType) => {
  if (entityType !== "personal") {
    return;
  }

  const disabledIds = {
    Farmlands: FARMLAND_IDS_TO_DISABLE,
    Higgins: HIGGINS_IDS,
  };

  return Object.keys(disabledIds).find(key =>
    disabledIds[key].includes(supplierId),
  );
};

const getCustomEntityIconMessage = (supplierId, entityType) => {
  if (entityType !== "personal") {
    return;
  }

  const idsWithCustomeMessage = { Farmlands: FARMLANDS_IDS_TO_CUSTOMISE };

  return Object.keys(idsWithCustomeMessage).find(key =>
    idsWithCustomeMessage[key].includes(supplierId),
  );
};

const getTipName = ({ customMessageKey, defaultTipName, disabledKey }) => {
  if (disabledKey) {
    return `${disabledKey}${defaultTipName}Disabled`;
  }

  if (customMessageKey) {
    return `${customMessageKey}${defaultTipName}Custom`;
  }

  return defaultTipName;
};

class BusinessEntityType extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete(nextProps.selected_type));
  }

  componentDidMount() {
    const {
      application,
      currentUser,
      dispatch,
      handleComplete,
      selected_type,
      entity_region,
    } = this.props;

    mixpanel.track("Consumer Onboarding: Business Entity Type page loaded", {
      Application: application.id,
      Component: "BusinessEntityType",
      "Entity ID": get(currentUser, "currentEntity.id"),
      distinct_id: currentUser.id,
    });

    if (!entity_region) {
      dispatch(setEntityFormValue("entity_region", "NZ"));
    }
    handleComplete(this.isComplete(selected_type));
  }

  isComplete = entity_legal_type => {
    const { setPageValidationStartFinish } = this.props;
    if (entity_legal_type) {
      setPageValidationStartFinish();
      return true;
    }
    return false;
  };

  /**
   * Sets the selected entity state.
   */
  selectItem = type => {
    const { dispatch, options, application } = this.props;
    let link = `/register/consumer/${application.id}/business/other-details`;

    if (type === "other") {
      dispatch(selectEntityType(null, null));
      link = `/register/consumer/${application.id}/business/other-type`;
      browserHistory.push(link);
      return;
    }
    dispatch(selectEntityType(type, options[type].title));
    if (application) {
      browserHistory.push(link);
    }
  };

  render() {
    const { options, supplierId, title } = this.props;

    // Create the button list
    const button_components = Object.keys(options).map((item, index) => {
      const current_button = options[item];

      const disabledKey = isEntityIconDisabled(supplierId, item);
      const customMessageKey = getCustomEntityIconMessage(supplierId, item);

      const tipName = getTipName({
        customMessageKey,
        defaultTipName: current_button.title,
        disabledKey,
      });

      return (
        <CircleIconButton
          key={`${item}-${index}`}
          value={item}
          type={item}
          text={current_button.title}
          icon={current_button.icon}
          handleClick={this.selectItem}
          small={!!isMobile()}
          // tip_name={current_button.title}
          tip_name={tipName}
          disabled={!!disabledKey}
        />
      );
    });

    return (
      <section className={styles.section}>
        <div className={`${styles.row} ${styles.mobile_margin}`}>
          <div className={styles.full_col}>
            <PanelTitle text={title} margin_top={isMobile() ? "-40px" : null} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.options}>{button_components}</div>
        </div>
      </section>
    );
  }
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  options: {
    company: {
      icon: "entity_x3plus",
      title: "Company",
    },
    partnership: {
      icon: "entity_x2",
      title: "Partnership",
    },
    trust: {
      icon: "entity_x3",
      title: "Trust",
    },
    sole_trader: {
      icon: "entity_x1",
      title: "Sole trader",
    },
    personal: {
      icon: "entity_x2",
      title: "Personal",
    },
    other: {
      icon: "entity_x3",
      title: "Other",
    },
  },
  title: "Select your entity type",
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export default connect(state => {
  let selected_type = null;

  if (state.cob_business.entity_type !== null) {
    selected_type = state.cob_business.entity_type;
  }

  return {
    currentUser: state.current_user,
    entity_region: state.cob_business.entity_region,
    options: defaults.options,
    selected_type,
    supplierId: get(state, "cob_section.supplier.id"),
    title: defaults.title,
  };
})(BusinessEntityType);
