import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#stop-credits-creating-a-stop-credit-for-a-connection
 */
const StopCredits = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = "stop_credits";
};

StopCredits.prototype.createStopCredit = function(
  applicationId,
  attributes,
  success,
  error,
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        attributes,
        relationships: {
          application: {
            data: {
              id: applicationId,
              type: "applications",
            },
          },
        },
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

StopCredits.prototype.updateStopCredit = function(
  id,
  attributes,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        attributes,
        id,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

StopCredits.prototype.getStopCredit = function(
  connectionId,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/connections/${connectionId}/stop_credit`, options)
    .then(success)
    .catch(error);
};

export default StopCredits;
