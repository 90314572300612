import EditAccountRules from "./invite/EditAccountRules";
import InviteCompanyName from "./invite/InviteCompanyName";
import InviteExistingSend from "./invite/InviteExistingSend";
import InviteNew from "./invite/InviteNew";
import InviteNewSend from "./invite/InviteNewSend";
import InternalFields from "./invite/InternalFields";
import React from "react";

export function componentFactory(section, component, props = {}) {
  // Load the correct API wrapper
  switch (section) {
    case "invite":
      switch (component) {
        case "new":
          return <InviteNew {...props} />;
        case "company":
          return <InviteCompanyName {...props} />;
        case "invite-new":
          return <InviteNewSend {...props} />;
        case "invite-existing":
          return <InviteExistingSend {...props} />;
        case "account-rules":
          return <EditAccountRules {...props} />;
        case "internal-fields":
          return <InternalFields {...props} />;
      }
      break;
  }
}
