import AgingSummaryGraph from "modules/applications/components/credit-checks/credit-works/v2/AgingSummaryGraph/graph";
import Container from "modules/applications/components/credit-checks/credit-works/v2/Container";
import styles from "modules/applications/components/css/CommonReviewBusiness.css";
import moment from "moment";
import React, { ReactElement, ReactNode } from "react";

import { SubHeading } from "./Container/styles";

type AgedBalance = {
  costToIndustry: number,
  currentPurchases: number,
  dso: number,
  dueThisMonth: number,
  month: number,
  overdue1Month: number,
  overdue2Months: number,
  overdue3Months: number,
  retentions: number,
  totalDebt: number,
  unallocated: number,
  vendorCount: number,
  year: number,
}

type AgedBalances = AgedBalance[];

type Props = {
 data: AgedBalances,
}

function AgedBalances(props: Props): ReactElement|null {
  const { data } = props;

  if (data.length === 0) {
    return null;
  }

  const formattedNumber = (number: number): string => parseFloat(number.toString()).toLocaleString("en", {
    maximumFractionDigits: 2,
  })

  const getAgedBalances = (agedBalances: AgedBalances): ReactNode => agedBalances.reverse().map(({
    costToIndustry,
    currentPurchases,
    dso,
    dueThisMonth,
    month,
    overdue1Month,
    overdue2Months,
    overdue3Months,
    retentions,
    totalDebt,
    unallocated,
    vendorCount,
    year,
  }) => {
    const date = moment().month(month)
      .year(year)
      .format("MMM-YYYY");
    return (
      <tr key={date}>
        <td>{date}</td>
        <td>{formattedNumber(currentPurchases)}</td>
        <td>{formattedNumber(dueThisMonth)}</td>
        <td>{formattedNumber(overdue1Month)}</td>
        <td>{formattedNumber(overdue2Months)}</td>
        <td>{formattedNumber(overdue3Months)}</td>
        <td>{formattedNumber(unallocated)}</td>
        <td>{formattedNumber(totalDebt)}</td>
        <td>{formattedNumber(retentions)}</td>
        <td>{dso}</td>
        <td>{vendorCount}</td>
        <td>{formattedNumber(costToIndustry)}</td>
      </tr>
    );
  })

  const getTable = (agedBalances: AgedBalances): ReactNode => (
    <div className={styles.table_wrapper}>
      <table className={styles.table_w20}>
        <thead>
          <tr>
            <th>Month</th>
            <th>Current</th>
            <th>DueNow</th>
            <th>+30</th>
            <th>+60</th>
            <th>+90</th>
            <th>Unalloc.</th>
            <th>Total</th>
            <th>Ret</th>
            <th>DSO</th>
            <th>Suppliers</th>
            <th>CTI</th>
          </tr>
        </thead>
        <tbody>{getAgedBalances([...agedBalances])}</tbody>
      </table>
    </div>
  )

  const currentMonthData = data.pop();

  return (
    <Container>
      <div className="mb-4">
        <SubHeading>Aged Balances Monthly View</SubHeading>
        <AgingSummaryGraph data={data} />
      </div>

      <div className="mb-4">
        <SubHeading>Current Month to Date</SubHeading>
        {getTable(currentMonthData ? [currentMonthData] : [])}
      </div>

      <div className="mb-4">
        <SubHeading>Previous Months</SubHeading>
        {getTable(data)}
      </div>
    </Container>
  );
}

export default AgedBalances;
