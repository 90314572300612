import React, { Component } from "react";
import TextInput from "modules/shared/components/inputs/TextInput";
import { connect } from "react-redux";
import { formatMoney } from "utils/formatting";
import { setMoneyLimit } from "../../actions/money";
import styles from "./css/Extras.css";
import mixpanel from "mixpanel-browser";
import PageHeader from "modules/shared/components/v2/PageHeader";

class ExtrasQuestion extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromProps(props) {
    const {
      handleComplete,
      page_validation_start: pageValidationStart,
      setPageValidationStartFinish,
      requestedLimit,
      min,
      max,
    } = props;
    const validResult = ExtrasQuestion.isValid(requestedLimit, min, max);
    if (handleComplete) handleComplete(validResult.result, "extras");
    if (pageValidationStart) {
      setPageValidationStartFinish();
      return { error: validResult.error };
    }
    return {};
  }

  static isValid(creditLimit, min, max) {
    if (!creditLimit) {
      const processedMin = min === 0 ? 1 : min;

      return {
        result: false,
        error: `Please input a value. Minimum credit limit is ${formatMoney(
          processedMin,
        )} and maximum credit limit is ${formatMoney(max)}`,
      };
    }
    if (creditLimit > max)
      return {
        result: false,
        error: `Maximum credit limit is ${formatMoney(max)}`,
      };
    if (creditLimit < min)
      return {
        result: false,
        error: `Minimum credit limit is ${formatMoney(min)}`,
      };
    return { result: true, error: "" };
  }

  updateValue(event) {
    const { application, dispatch, currentUser, currentEntity } = this.props;
    const value = event.target.value;
    const moneyLimit = parseInt(
      value.toString().replace(/[^0-9]/g, "") || 0,
      10,
    );

    mixpanel.track("Apply limit", {
      Application: application.id,
      "Entity ID": currentEntity.id,
      distinct_id: currentUser.id,
      requested_limit: moneyLimit,
    });
    dispatch(setMoneyLimit(moneyLimit));
  }

  render() {
    const {
      title,
      form_inputs,
      requestedLimit,
      isCashApplication,
      supplierName,
    } = this.props;

    return (
      <section className={styles.section}>
        <PageHeader title={title}>
          <p>
            The credit limit is the maximum amount of credit you would like
            available with {supplierName} under this application. The credit limit
            granted may be lower than you have asked for and is reviewable at the
            discretion of {supplierName}.
          </p>
        </PageHeader>
        {!isCashApplication && (
          <div className={styles.row}>
            <div className={styles.left_col}>
              {
                <TextInput
                  key={"credit_limit"}
                  id={"credit_limit"}
                  type="tel"
                  error={this.state.error}
                  value={formatMoney(requestedLimit || 0)}
                  label={form_inputs["credit_limit"].label}
                  required={form_inputs["credit_limit"].required}
                  handleChange={e => this.updateValue(e)}
                />
              }
            </div>
          </div>
        )}
      </section>
    );
  }
}

const defaults = {
  title: "Limit",
  form_inputs: {
    credit_limit: {
      label: "What is your preferred credit limit?",
      required: true,
      step: 100,
      max: 1000000,
      min: 0,
    },
  },
};

export default connect((state, ownProps) => {
  const attributes = ownProps.application.attributes;
  const { approval_hierarchy, supplier_max_trade_account_limit } = attributes;

  const supplierLimit = supplier_max_trade_account_limit;
  const minCreditLimit = approval_hierarchy.find(({ level }) => level === 1).min_credit_limit;

  if (minCreditLimit) {
    defaults['form_inputs']['credit_limit']['min'] = minCreditLimit;
  }

  return {
    application: ownProps.application,
    complete: state.cob_extras.completed[`question`],
    isCashApplication: attributes.application_type == "cash",
    title: defaults.title,
    form_inputs: defaults.form_inputs,
    requestedLimit: state.cob_money.requested_limit,
    supplierName: state.cob_section.application.attributes.supplier_name,
    supplierLimit: supplierLimit,
    max: supplierLimit || defaults.form_inputs.credit_limit.max,
    min: defaults.form_inputs.credit_limit.min,
    currentUser: state.current_user,
    currentEntity: state.current_user.current_entity,
  };
})(ExtrasQuestion);
