/* eslint-disable sort-keys-fix/sort-keys-fix */
import RangedNumberInput from "modules/shared/components/inputs/RangedNumberInput";
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import PopperTooltip from "modules/shared/components/widgets/interactive/PopperToolTip.js";
import React from "react";
import { connect } from "react-redux";

import { setValue } from "../actions";
import styles from "./css/AutoDecisioning.css";
import creditChecksStyles from "./css/CreditChecks.css";
import financialsStyles from "./css/Financials.css";
import ExpandableSection from "./ExpandableSection";

class Financials extends React.Component {
  constructor(props) {
    super(props);
    this.state = this._buildInitialState(props.indicators);
  }

  _buildInitialState(indicators) {
    const state = {};
    Object.keys(indicators).forEach(indicatorGroupType => {
      state[indicatorGroupType] = {};
      indicatorTypes.forEach(indicatorType => {
        state[indicatorGroupType][indicatorType] = !!indicators[
          indicatorGroupType
        ][indicatorType];
      });
    });
    return state;
  }

  handleToggleDynamicIndicator(indicatorGroupKey, indicatorName) {
    const indicatorStatus = this.state[indicatorGroupKey];
    const newStatus = !indicatorStatus[indicatorName];
    indicatorStatus[indicatorName] = newStatus;
    this.setState({ [indicatorGroupKey]: indicatorStatus });

    const { dispatch, indicators } = this.props;
    const values = indicators[indicatorGroupKey];
    if (newStatus) {
      values[indicatorName] = 0;
    } else {
      delete values[indicatorName];
    }
    dispatch(setValue(indicatorGroupKey, values));
  }

  handleIndicatorChange(indicatorGroupKey, indicatorName, target) {
    const { dispatch, indicators } = this.props;
    const values = indicators[indicatorGroupKey];
    values[indicatorName] = target.value;
    dispatch(setValue(indicatorGroupKey, values));
  }

  _isDynamicIndicator(indicatorName) {
    return indicatorName.split("_")[0] === "dynamic";
  }

  renderInformation(indicatorGroupKey, indicatorName) {
    const { description } = defaults[indicatorGroupKey][indicatorName];

    return <div className={styles.row_info}>{description}</div>;
  }

  renderIndicatorInput(indicatorGroupKey, indicatorName) {
    const { readOnly, indicators } = this.props;
    const { min, max } = defaults[indicatorGroupKey][indicatorName];
    const checked = this.state[indicatorGroupKey][indicatorName];
    const value =
      indicators[indicatorGroupKey][indicatorName] || (checked ? 0 : "");
    const isDynamic = this._isDynamicIndicator(indicatorName);

    return (
      <div className={financialsStyles.input}>
        <RangedNumberInput
          id={indicatorName}
          key={indicatorName}
          handleChange={(_, event) =>
            this.handleIndicatorChange(
              indicatorGroupKey,
              indicatorName,
              event.target,
            )
          }
          value={value.toString() || "0"}
          min={min}
          max={max}
          digits={isDynamic ? 2 : 0}
          moneyFormat={!isDynamic}
          disabled={readOnly}
        />
        {isDynamic ? (
          <div className={financialsStyles.side_text}>
            {"times "}
            <b>credit limit</b>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  renderSingleIndicatorInput(indicatorGroupKey, indicatorName) {
    return (
      <div>
        {this.renderInformation(indicatorGroupKey, indicatorName)}
        <div className={creditChecksStyles.row_number_input}>
          <div className={financialsStyles.side_text}>
            {this.renderIndicatorInput(indicatorGroupKey, indicatorName)}
          </div>
        </div>
      </div>
    );
  }

  renderRangeIndicatorInputs(indicatorGroupKey, indicatorType) {
    if (!indicatorType) return;

    const minIndicatorName = `${indicatorType}_min`;
    const maxIndicatorName = `${indicatorType}_max`;

    return (
      <div>
        {this.renderInformation(indicatorGroupKey, `${indicatorType}_both`)}
        <div className={creditChecksStyles.row_number_input}>
          {this.renderIndicatorInput(indicatorGroupKey, minIndicatorName)}
          <div className={financialsStyles.side_text}>{" -  "}</div>
          {this.renderIndicatorInput(indicatorGroupKey, maxIndicatorName)}
        </div>
      </div>
    );
  }

  renderIndicatorInputs(indicatorGroupKey, indicatorType) {
    if (!indicatorType) return;

    const minIndicatorName = `${indicatorType}_min`;
    const maxIndicatorName = `${indicatorType}_max`;
    const checkedMin = this.state[indicatorGroupKey][minIndicatorName];
    const checkedMax = this.state[indicatorGroupKey][maxIndicatorName];

    if (checkedMin && checkedMax) {
      return this.renderRangeIndicatorInputs(indicatorGroupKey, indicatorType);
    }
    if (checkedMin) {
      return this.renderSingleIndicatorInput(
        indicatorGroupKey,
        minIndicatorName,
      );
    }
    if (checkedMax) {
      return this.renderSingleIndicatorInput(
        indicatorGroupKey,
        maxIndicatorName,
      );
    }
  }

  renderMinAndMaxIndicators(indicatorGroupKey, indicatorType) {
    if (!indicatorType) return;

    const minIndicatorName = `${indicatorType}_min`;
    const maxIndicatorName = `${indicatorType}_max`;
    const checkedMin = this.state[indicatorGroupKey][minIndicatorName];
    const checkedMax = this.state[indicatorGroupKey][maxIndicatorName];
    return (
      <div>
        <div className={styles.row_check_box}>
          <div
            onClick={() =>
              this.handleToggleDynamicIndicator(
                indicatorGroupKey,
                minIndicatorName,
              )
            }
          >
            <SquareCheckbox
              label={defaults[indicatorGroupKey][minIndicatorName].title}
              checked={checkedMin}
              no_float
            />
          </div>
        </div>
        <div className={styles.row_check_box}>
          <div
            onClick={() =>
              this.handleToggleDynamicIndicator(
                indicatorGroupKey,
                maxIndicatorName,
              )
            }
          >
            <SquareCheckbox
              label={defaults[indicatorGroupKey][maxIndicatorName].title}
              checked={checkedMax}
              no_float
            />
          </div>
        </div>
        {(checkedMin || checkedMax) &&
          this.renderIndicatorInputs(indicatorGroupKey, indicatorType)}
      </div>
    );
  }

  renderIndicators(indicatorGroupKey) {
    return (
      <div className={styles.inner_section}>
        <PopperTooltip
          title={defaults[indicatorGroupKey].tooltip}
          placement={"bottom"}
        >
          <h3 style={{ display: "inline" }}>
            {defaults[indicatorGroupKey].title}
          </h3>
        </PopperTooltip>
        {this.renderMinAndMaxIndicators(indicatorGroupKey, "fixed")}
        {this.renderMinAndMaxIndicators(indicatorGroupKey, "dynamic")}
      </div>
    );
  }

  renderSimpleIndicators(indicatorGroupKey) {
    return (
      <React.Fragment>
        <PopperTooltip
          title={defaults[indicatorGroupKey].tooltip}
          placement={"bottom"}
        >
          <h3 className="mt-3">{defaults[indicatorGroupKey].title}</h3>
        </PopperTooltip>
        <h3 className="mt-3">{defaults[indicatorGroupKey].para}</h3>
        <h3 className="mt-3">{defaults[indicatorGroupKey].para2}</h3>
        {this.renderInformation(indicatorGroupKey, "fixed_min")}
        <div className={creditChecksStyles.row_number_input}>
          {this.renderIndicatorInput(indicatorGroupKey, "fixed_min")}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      error,
      readOnly,
      financialsCheck,
      requiresFinancialsCheck,
    } = this.props;
    return (
      <div className={styles.section}>
        <ExpandableSection
          id={"financials_check"}
          title={defaults.title}
          info_text={"Do you require financials for auto decisioning?"}
          disabled={readOnly}
          account_rule_on={requiresFinancialsCheck}
          hide_setting_message={readOnly}
          error={error}
        />
        {financialsCheck && (
          <div>
            {" "}
            {this.renderIndicators("net_worth_indicators")}
            {this.renderIndicators("surplus_indicators")}
            <div className={styles.inner_section}>
              {this.renderSimpleIndicators("property_ownership_indicator")}
              {this.renderSimpleIndicators("net_property_value_indicator")}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const indicatorTypes = ["dynamic_max", "dynamic_min", "fixed_max", "fixed_min"];

const defaults = {
  net_worth_indicators: {
    dynamic_both: {
      description: "",
    },
    dynamic_max: {
      description:
        "The maximum amount of times the net worth can be over and above the credit limit being applied for.",
      title: "Dynamic maximum",
    },
    dynamic_min: {
      description:
        "The minimum amount of times the net worth needs to be over and above the credit limit being applied for.",
      title: "Dynamic minimum",
    },
    fixed_both: {
      description: "Please set the minimum and maximum net worth required.",
    },
    fixed_max: {
      description: "Please set the maximum net worth required.",
      title: "Fixed maximum",
    },
    fixed_min: {
      description: "Please set the minimum net worth required.",
      title: "Fixed minimum",
    },
    name: "net worth",
    title: "Net worth indicator",
    tooltip:
      "Net worth calculates the total assets less total liabilities." +
      " If you would like to ensure this is always a multiple above the net worth, please set your dynamic indicator.",
  },
  surplus_indicators: {
    dynamic_both: {
      description: "",
    },
    dynamic_max: {
      description:
        "The maximum amount of times the surplus can be over and above the credit limit being applied for.",
      title: "Dynamic maximum",
    },
    dynamic_min: {
      description:
        "The minimum amount of times (eg:  3x) the surplus needs to be, over and above the credit limit being applied for.",
      title: "Dynamic minimum",
    },
    fixed_both: {
      description: "Please set the minimum and maximum surplus required.",
    },
    fixed_max: {
      description: "Please set the maximum surplus allowed.",
      title: "Fixed maximum",
    },
    fixed_min: {
      description: "Please set the minimum surplus required.",
      title: "Fixed minimum",
    },
    name: "surplus",
    title: "Surplus indicator",
    tooltip:
      "Surplus calculates the total income less total expenditure." +
      " You are able to dynamically set this calculation by only including income or expenses that matter to your business." +
      " This is controlled through your financials page. ",
  },
  property_ownership_indicator: {
    fixed_min: {
      description: "Value of property must be greater than.",
      title: "Fixed minimum",
    },
    name: "property_ownership",
    title: "Property ownership",
    para1: "Property value",
    tooltip: "",
  },
  net_property_value_indicator: {
    fixed_min: {
      description: "Value of net property must be greater than.",
      title: "Fixed minimum",
    },
    name: "net_property_value",
    title: "Net property value",
    para1:
      "If you require a surplus property ownership over outstanding mortgage, please indicate your requirements here. ",
    para2: "Property value - mortgage owing = net property value",
    tooltip: "",
  },
  title: "Financials",
};

export default connect((state, ownProps) => {
  return {
    financialsCheck: ownProps.rule_set.financials_check,
    indicators: {
      net_worth_indicators: ownProps.rule_set.net_worth_indicators || {},
      surplus_indicators: ownProps.rule_set.surplus_indicators || {},
      property_ownership_indicator:
        ownProps.rule_set.property_ownership_indicator || {},
      net_property_value_indicator:
        ownProps.rule_set.net_property_value_indicator || {},
    },
    readOnly: ownProps.read_only,
    requiresFinancialsCheck: ownProps.current_entity.requires_financials_check,
  };
})(Financials);
