import React from "react";

import styles from "../../centrix.css";

class PaymentHistory extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  compareDates(date_a, date_b) {
    const date_a_year = parseInt(date_a.year);
    const date_b_year = parseInt(date_b.year);

    const date_a_month = parseInt(date_a.month);
    const date_b_month = parseInt(date_b.month);

    if (date_a_year > date_b_year) {
      return 1;
    } else if (date_a_year < date_b_year) {
      return -1;
    } else {
      if (date_a_month > date_b_month) {
        return 1;
      } else if (date_a_month < date_b_month) {
        return -1;
      } else {
        return 0;
      }
    }
  }

  getEnglishMonth(month) {
    const months = {
      "1": "Jan",
      "2": "Feb",
      "3": "Mar",
      "4": "Apr",
      "5": "May",
      "6": "Jun",
      "7": "Jul",
      "8": "Aug",
      "9": "Sept",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec",
    };
    return months[month];
  }

  render() {
    const { data, start_date, end_date } = this.props;

    let reversed_data = [...data].reverse();
    reversed_data.forEach(time => {
      time.month = time.month.replace(/^0+/, "");
    });

    const start_date_object = start_date
      ? new Date(start_date)
      : new Date("1970-01-01");
    const end_date_object = end_date
      ? new Date(end_date)
      : new Date("2070-01-01");

    const data_length = reversed_data.length;
    return (
      <div>
        {reversed_data.map((date, i) => {
          let class_name = styles.payment_status_default;
          let status = date.status;
          let year_render = "";

          if (
            this.compareDates(date, {
              year: start_date_object.getFullYear(),
              month: start_date_object.getMonth() + 1,
            }) <= 0 ||
            this.compareDates(date, {
              year: end_date_object.getFullYear(),
              month: end_date_object.getMonth() + 1,
            }) > 0
          ) {
            status = "";
          }
          if (date.month == "1" || data_length === i + 1) {
            year_render = date.year;
          }

          if (status === "U") {
            class_name = styles.payment_status_u;
          } else if (status === "0") {
            class_name = styles.payment_status_green;
          } else if (status === "1") {
            class_name = styles.payment_status_dark_green;
          } else if (status === "2") {
            class_name = styles.payment_status_blue;
          } else if (["3", "4", "5", "6", "X"].includes(status)) {
            class_name = styles.payment_status_red;
          }

          return (
            <div className={styles.payment_status_container}>
              <div className={styles.payment_status_year}>{year_render}</div>
              <div className={styles.payment_status_month}>
                {this.getEnglishMonth(date.month)}
              </div>
              <div className={class_name}>{status}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

PaymentHistory.defaultProps = {
  start_date: "1970-01-01T14:17:25.360+00:00",
  end_date: "2070-01-01T14:17:25.360+00:00",
};

export default PaymentHistory;
