import get from "lodash.get";
import styles from "modules/applications/components/css/CommonReviewBusiness.css";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import React, { Component } from "react";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

import scoreStyles from "./css/Score.css";
import { enquiryResponseData } from "./helpers/reportHelpers";

const getScoringError = score => {
  const errorCode = get(
    score,
    "scoring_error_list.scoring_error.scoring_error_code",
  );

  return {
    errorDescription: get(
      score,
      "scoring_error_list.scoring_error.scoring_error_description",
    ),
    hasError: isPresent(errorCode),
  };
};
export default class Score extends Component {
  _probabilityAdverse(score) {
    if (Object.keys(score).includes("probability_adverse")) {
      const probabilityAdverse = score.probability_adverse * 100;

      return (
        <span className={scoreStyles.overview_adverse_label}>
          <strong>{probabilityAdverse.toFixed(2)}%</strong> chance of adverse in
          the next 12 months
        </span>
      );
    }

    return null;
  }

  _scoreGraph(score) {
    const maxValue = 1200;
    const bureauScore = Math.max(score.bureau_score, 0);
    const valuePosition = `${(bureauScore / maxValue) * 100}%`;

    return (
      <div className={styles.overview}>
        <div className={styles.overview_rating}>
          <div className={styles.liner}>
            <div className={styles.overview_rating_graph}>
              <div
                className={styles.overview_rating_indicator}
                style={{ left: valuePosition }}
              >
                {bureauScore}
              </div>
              <div className={scoreStyles.overview_rating_graph_bg_reverse} />
              <span className={styles.overview_rating_graph_0}>0</span>
              <span className={styles.overview_rating_graph_30}>300</span>
              <span className={styles.overview_rating_graph_60}>600</span>
              <span className={styles.overview_rating_graph_90}>900</span>
              <span className={styles.overview_rating_graph_120}>1200</span>

              <span className={scoreStyles.overview_rating_graph_0_label}>
                <strong>Increased Risk</strong>
              </span>
              <span className={scoreStyles.overview_rating_graph_120_label}>
                <strong>Reduced Risk</strong>
              </span>
            </div>
          </div>
        </div>
        {this._probabilityAdverse(score)}
      </div>
    );
  }

  _scoreCheck() {
    return <span className={scoreStyles.icon_check}></span>;
  }

  _scoreCross() {
    return <span className={scoreStyles.icon_cross}></span>;
  }

  _scoreImpactor(impactor) {
    let box1 = null;
    let box2 = null;
    let box3 = null;

    switch (impactor) {
      case "MARGINALLYINCREASES":
        box1 = this._scoreCheck();
        break;
      case "MODERATELYINCREASES":
        box1 = this._scoreCheck();
        box2 = this._scoreCheck();
        break;
      case "SIGNIFICANTLYINCREASES":
        box1 = this._scoreCheck();
        box2 = this._scoreCheck();
        box3 = this._scoreCheck();
        break;
      case "MARGINALLYDECREASES":
        box1 = this._scoreCross();
        break;
      case "MODERATELYDECREASES":
        box1 = this._scoreCross();
        box2 = this._scoreCross();
        break;
      case "SIGNICANTLYDECREASES": // This is the value from API. Catching both spelling if Equifax will fix this in the future
      case "SIGNIFICANTLYDECREASES":
        box1 = this._scoreCross();
        box2 = this._scoreCross();
        box3 = this._scoreCross();
        break;
    }

    return (
      <div className={scoreStyles.contributing_factor_box_container}>
        <div className={scoreStyles.contributing_factor_box}>{box1}</div>
        <div className={scoreStyles.contributing_factor_box}>{box2}</div>
        <div className={scoreStyles.contributing_factor_box}>{box3}</div>
      </div>
    );
  }

  _keyContributingFactors(score) {
    const scoringError = getScoringError(score);

    if (scoringError.hasError) {
      return (
        <div className={scoreStyles.contributing_factors_container}>
          {scoringError.errorDescription}
        </div>
      );
    }

    const factorList = score.contributing_factor_list || {};
    let factors = factorList.contributing_factor || [];

    if (factors.constructor === Object) {
      factors = [factors];
    }

    const contents = factors.map((factor, index) => (
      <div key={index} className={scoreStyles.contributing_factor}>
        <div className={scoreStyles.contributing_factor_header_container}>
          {this._scoreImpactor(factor.score_impactor)}
          <span className={scoreStyles.contributing_factor_label}>
            <strong>{factor.score_factor}</strong>
          </span>
        </div>
        <span className={scoreStyles.contributing_factor_description}>
          {factor.contributing_factor_description}
        </span>
      </div>
    ));

    return (
      <div className={scoreStyles.contributing_factors_container}>
        <h3>Key Contributing Factors</h3>
        {contents}
      </div>
    );
  }

  _score() {
    const { data, entityType } = this.props;
    const score = enquiryResponseData(data, entityType).score;

    if (isBlank(score)) {
      return <p>Information Unavailable</p>;
    }

    return (
      <div>
        {this._scoreGraph(score)}
        {this._keyContributingFactors(score)}
      </div>
    );
  }

  render() {
    const { isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="Equifax Score"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        {this._score()}
      </ReviewContainer>
    );
  }
}
