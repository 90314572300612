import Details from "modules/card-management-onboarding/components/Details";
import EmailVerification from "modules/shared/components/v2/Modules/EmailVerification";

import Authorisation from "../modules/card-management-onboarding/components/Authorisation";
import CardManagementOnBoarding from "../modules/card-management-onboarding/components/CardManagementOnBoarding";
import Complete from "../modules/card-management-onboarding/components/Complete";
import Terms from "../modules/card-management-onboarding/components/Terms";
import Layout from "../modules/card-management-onboarding/container/Layout";

const CardManagementOnBoardingRoutes = [
  {
    component: Authorisation,
    path: "authorisation",
  },
  {
    component: Complete,
    path: "complete",
  },
  {
    component: EmailVerification,
    path: "email-verification",
  },
  {
    component: Details,
    path: "details",
  },
  {
    component: Terms,
    path: "terms",
  },
  {
    component: CardManagementOnBoarding,
    path: ":supplier_id/:component(/**)",
  },
];

module.exports = {
  childRoutes: CardManagementOnBoardingRoutes,
  component: Layout,
  path: "card-management",
};
