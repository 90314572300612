import mixpanel from "mixpanel-browser";

export function mixpanelLocationTracking(location, store) {
  let p = location.pathname;
  let props = {};
  let userSet;
  let eventName;
  let userEntitySet;

  const eventMap = {
    "/dashboard/home": "Dashboard page",
    "/dashboard/reporting": "Applications page",
    "/dashboard/map": "Mapping page",
    "/dashboard/connections/all": "Connections page",
    "/dashboard/users": "Manage my team",
    "/dashboard/settings/business": "Business information",
    "/dashboard/account-settings": "Settings page",
    "/dashboard/profile": "Profile page",
    "/dashboard/leads/list": "Leads page",
  };

  eventName = eventMap[location.pathname];

  if (!eventName) {
    return;
  }

  userSet =
    store.getState().current_user.data &&
    store.getState().current_user.data.data;
  userEntitySet = store.getState().current_user.current_entity;

  if (userSet) {
    if (userEntitySet) {
      props = {
        distinct_id: store.getState().current_user.data.data.id,
        "Entity ID": store.getState().current_user.current_entity.id,
      };
    } else {
      props = { distinct_id: store.getState().current_user.data.data.id };
    }
  }

  mixpanel.track(eventName, props);
}
