/* Import libraries */
import React from "react";

/* Import CSS */
import styles from "./css/CheckForm.css";

/* Import components */
import TextInput from "modules/shared/components/inputs/TextInput";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";
import TextArea from "modules/shared/components/inputs/TextArea";

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  form_inputs: {
    confirm_referee_name: {
      label: "Confirm referee name",
      required: true,
      error_text: "You need to check the referees' name",
    },
    relationship_type: {
      guidance: "Relationship to applicant",
      default_title: "",
      required: true,
      error_text: "You need to check the referees' relationship with applicant",
      options: [
        {
          label: "Customer",
          value: "customer",
          id: "relationship_type",
        },
        {
          label: "Colleague",
          value: "colleague",
          id: "relationship_type",
        },
        {
          label: "Friend",
          value: "friend",
          id: "relationship_type",
        },
        {
          label: "Family member",
          value: "family_member",
          id: "relationship_type",
        },
        {
          label: "Other",
          value: "other",
          id: "relationship_type",
        },
        {
          label: "N/A",
          value: "not_applicable",
          id: "relationship_type",
        },
      ],
    },
    relationship_length: {
      guidance: "How long have you had a relationship with applicant?",
      default_title: "",
      required: true,
      error_text:
        "You need to check the length of the referees' relationship with the applicant",
      options: [
        {
          label: "Less than 1 year",
          value: "less_than_1_year",
          id: "relationship_length",
        },
        {
          label: "1-2 Years",
          value: "years_1_2",
          id: "relationship_length",
        },
        {
          label: "2-5 Years",
          value: "years_2_5",
          id: "relationship_length",
        },
        {
          label: "5+ Years",
          value: "years_5_plus",
          id: "relationship_length",
        },
        {
          label: "N/A",
          value: "not_applicable",
          id: "relationship_length",
        },
      ],
    },
    credit_limit: {
      label: "$ enter credit limit",
      guidance: "What credit limit did you extend to applicant",
      type: "number",
      required: false,
    },
    last_purchase: {
      label: "DD / MM / YYYY",
      guidance: "What date was the last purchase made by the applicant",
      required: true,
      error_text: "You need to check the last purchase made by the applicant",
      mask: "99 / 99 / 9999",
      placeholder: " ",
    },
    ammount_owed: {
      label: "$ enter amount owed",
      guidance: "How much is currently owed to you by the applicant",
      required: false,
      type: "number",
      error_text: "You need to check the credit owed by the applicant",
    },
    ammount_overdue: {
      label: "$ enter amount overdue",
      guidance: "Of the amount owed, how much is overdue?",
      required: false,
      type: "number",
      error_text: "You need to check the credit overdue by the applicant",
    },
    ammount_overdue_for: {
      guidance: "Age of overdue amount",
      default_title: "",
      required: true,
      error_text: "You need to check how long this credit has been overdue",
      options: [
        {
          label: "less than 60 days",
          value: "less_than_60_days",
          id: "ammount_overdue_for",
        },
        {
          label: "60-90 days",
          value: "greater_than_60_days",
          id: "ammount_overdue_for",
        },
        {
          label: "90-120 days",
          value: "greater_than_90_days",
          id: "ammount_overdue_for",
        },
        {
          label: "120-240 days",
          value: "greater_than_120_days",
          id: "ammount_overdue_for",
        },
        {
          label: "over 240 days",
          value: "greater_than_240_days",
          id: "ammount_overdue_for",
        },
        {
          label: "N/A",
          value: "not_applicable",
          id: "ammount_overdue_for",
        },
      ],
    },
    payment_habits: {
      guidance: "What are the applicant payment habits?",
      default_title: "",
      required: true,
      error_text: "You need to check applicants payment habits",
      options: [
        {
          label: "Excellent",
          value: "excellent",
          id: "payment_habits",
        },
        {
          label: "Satisfactory",
          value: "satisfactory",
          id: "payment_habits",
        },
        {
          label: "Unsatisfactory",
          value: "unsatisfactory",
          id: "payment_habits",
        },
        {
          label: "N/A",
          value: "not_applicable",
          id: "payment_habits",
        },
      ],
    },
    notes: {
      guidance: "Add any notes here",
      title_only: true,
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

function CheckForm(props) {
  const {
    form_values,
    form_errors,
    handleChange,
    handleBlur,
    formatPreviewText,
  } = props;

  const fields_theme = "default_noborder";

  const form_elements = [];
  const form_inputs = defaults.form_inputs;
  let input;
  const style = styles.list;

  Object.keys(form_inputs).forEach((value, index) => {
    let guidance,
      optional,
      item_style = styles.item;

    if (form_inputs[value].guidance) {
      guidance = (
        <span className={styles.guidance}>{form_inputs[value].guidance}</span>
      );
    } else {
      item_style = styles.item_nolabel;
      guidance = <span className={styles.guidance} />;
    }

    let error;
    if (form_errors.hasOwnProperty(value) && form_inputs[value].required) {
      error = form_inputs[value].error_text;
    }

    if (form_inputs[value].hasOwnProperty("text")) {
      input = (
        <li className={item_style} key={index}>
          {guidance}
          <div className={styles.textarea}>
            <TextArea
              id={value}
              key={index}
              error={error}
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={form_values[value]}
              required={form_inputs[value].required}
            />
          </div>
        </li>
      );
    } else if (form_inputs[value].hasOwnProperty("options")) {
      input = (
        <li className={item_style} key={index} style={{ zIndex: 10 - index }}>
          {guidance}
          <OptionsDropdown
            key={index}
            id={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={error}
            default_title={form_inputs[value].default_title}
            value={form_values[value] || null}
            label={form_inputs[value].label}
            required={form_inputs[value].required}
            options={form_inputs[value].options}
            theme={fields_theme}
          />
        </li>
      );
    } else if (form_inputs[value].title_only) {
      input = (
        <li className={item_style} key={index}>
          {guidance}
        </li>
      );
    } else {
      input = (
        <li className={item_style} key={index}>
          {guidance}
          {optional}
          <TextInput
            key={index}
            displayHelperText={false}
            id={value}
            error={error}
            handleChange={handleChange}
            handleBlur={handleBlur}
            label={form_inputs[value].label}
            required={form_inputs[value].required}
            mask={form_inputs[value].mask}
            placeholder={form_inputs[value].placeholder}
            value={
              form_inputs[value].type === "number"
                ? formatPreviewText(form_values[value])
                : form_values[value]
            }
          />
        </li>
      );
    }
    form_elements.push(input);
  });

  return <ol className={style}>{form_elements}</ol>;
}

export default CheckForm;
