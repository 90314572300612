import BaseModel from "models/BaseModel";

import AddonAnswerModel from "./AddonAnswerModel";
import TradingNameModel from "./TradingNameModel";

export default class ApplicationTradingNameLinkModel extends BaseModel {
  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  /** Private functions */

  assignRelationships() {
    this.assignSingleRelationship({
      key: "addon_answer",
      model: AddonAnswerModel,
    });
    this.assignSingleRelationship({
      key: "trading_name",
      model: TradingNameModel,
    });
  }
}
