import styled from 'styled-components';
import { FONT_SIZES, FONT_WEIGHTS } from 'variables/typography';

export const Error = styled.p`
  border: 2px solid orangered;
  color: orangered;
  font-size: ${FONT_SIZES.title};
  line-height: 18px;
  margin-top: 10px;
  padding: 5px;
  opacity: 1;
  text-align: center;
`;

export const Title = styled.h3`
  color: var(--main-color);
  font-size: ${FONT_SIZES.title};
  font-weight: ${FONT_WEIGHTS.normal};
`;

export const Wrapper = styled.div`
  border-bottom: 1px solid greylight;
  padding-top: 25px;
`;