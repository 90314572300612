import get from "lodash.get";

import {
  MANAGE_USERS_CHECK_EMAIL_ERROR,
  MANAGE_USERS_CHECK_EMAIL_START,
  MANAGE_USERS_CHECK_EMAIL_SUCCESS,
  MANAGE_USERS_CLEAR_EMAIL_VALIDATION,
  MANAGE_USERS_CLEAR_USER_CONTACTS,
  MANAGE_USERS_CREATE_USER_ERROR,
  MANAGE_USERS_CREATE_USER_START,
  MANAGE_USERS_CREATE_USER_SUCCESS,
  MANAGE_USERS_LOAD_USERS_BY_ENTITY_ERROR,
  MANAGE_USERS_LOAD_USERS_BY_ENTITY_START,
  MANAGE_USERS_LOAD_USERS_BY_ENTITY_SUCCESS,
  MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_ERROR,
  MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_START,
  MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_SUCCESS,
  MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_ERROR,
  MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_START,
  MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_SUCCESS,
  MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_ERROR,
  MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_START,
  MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_SUCCESS,
  MANAGE_USERS_LOAD_USER_ERROR,
  MANAGE_USERS_LOAD_USER_START,
  MANAGE_USERS_LOAD_USER_SUCCESS,
  MANAGE_USERS_REMOVE_USER_ERROR,
  MANAGE_USERS_REMOVE_USER_START,
  MANAGE_USERS_REMOVE_USER_SUCCESS,
  MANAGE_USERS_TEAM_LOAD_HEADQUARTER_KEY_CONTACT,
  MANAGE_USERS_TEAM_LOAD_TEAM_ERROR,
  MANAGE_USERS_TEAM_LOAD_TEAM_START,
  MANAGE_USERS_TEAM_LOAD_TEAM_SUCCESS,
  MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_ERROR,
  MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_START,
  MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_SUCCESS,
  MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_ERROR,
  MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_START,
  MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS,
  MANAGE_USERS_UPDATE_USER_LAST_ADMIN_ERROR,
  MANAGE_USERS_UPDATE_USER_ERROR,
  MANAGE_USERS_UPDATE_USER_START,
  MANAGE_USERS_UPDATE_USER_SUCCESS,
} from "./constants";

import api from "../../api";

export function clearUserContacts() {
  return {
    type: MANAGE_USERS_CLEAR_USER_CONTACTS,
  };
}

export function clearEmailValidation() {
  return {
    type: MANAGE_USERS_CLEAR_EMAIL_VALIDATION,
  };
}

export function checkIfEmailExists(email_address) {
  return (dispatch, getState) => {
    var email = api(
      "user_exists_email",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    dispatch({
      type: MANAGE_USERS_CHECK_EMAIL_START,
    });

    email.checkEmailValid(
      email_address,
      success => {
        dispatch({
          type: MANAGE_USERS_CHECK_EMAIL_SUCCESS,
          payload: success.data.data.attributes,
        });
      },
      error => {
        dispatch({
          type: MANAGE_USERS_CHECK_EMAIL_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function updateConnectionKeyContact(
  resourceType,
  connectionType,
  connectionId,
  userId,
  success,
) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_START,
      payload: connectionType,
    });

    var resource, apiAction;
    resource = api(
      resourceType,
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    if (connectionType === "consumer") {
      apiAction = "setSupplierContact";
    } else if (connectionType === "supplier") {
      apiAction = "setConsumerContact";
    }

    resource[apiAction](
      connectionId,
      userId,
      result => {
        dispatch({
          type: MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS,
          payload: connectionId,
          meta: {
            mixpanel: {
              event: "Change connection key contact",
              props: {
                distinct_id: getState().current_user.data.data.id,
                Connection: connectionId,
                "Connection type": connectionType,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });
        success(result.data.data);
      },
      error => {
        dispatch({
          type: MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function bulkReassignKeyContacts({
  oldUserId,
  newUserId,
  newSupplierId,
  initiatorId,
  successCallback,
  errorCallback,
}) {
  return (dispatch, getState) => {
    const users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    users.bulkReassignKeyContacts(
      oldUserId,
      {
        new_user_id: newUserId,
        new_supplier_id: newSupplierId,
        initiator_id: initiatorId,
      },
      result => {
        successCallback(result);
      },
      error => {
        errorCallback(error);
      }
    )
  }
}

export function loadUserApplicationContacts(userId, options) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_START,
      payload: userId,
    });

    var users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    users.getApplicationsByKeyContact(
      userId,
      result => {
        dispatch({
          type: MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_SUCCESS,
          payload: {
            data: result.data.data,
            total: result.data.total_applications,
          },
        });
      },
      error => {
        dispatch({
          type: MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_ERROR,
          payload: error,
        });
      },
      { params: options },
    );
  };
}

export function loadUserConnectionContacts(userId, options) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_START,
      payload: userId,
    });

    var users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    users.getConnectionsByKeyContact(
      userId,
      result => {
        dispatch({
          type: MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_SUCCESS,
          payload: {
            data: result.data.data,
            total: result.data.total_connections,
          },
        });
      },
      error => {
        dispatch({
          type: MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_ERROR,
          payload: error,
        });
      },
      { params: options },
    );
  };
}

export function loadUserApplicationsCount(userId, setProcessing) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_START,
      payload: userId,
    });

    const users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    users.getUserApplicationsCount(
      userId,
      result => {
        dispatch({
          type: MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_SUCCESS,
          payload: {
            total: result.data.total_applications,
            processing: result.data.processing,
          },
        });
      },
      error => {
        dispatch({
          type: MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_ERROR,
          payload: error,
        });
      }
    );
  }
}

export function updateUser(id, attributes, success) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_UPDATE_USER_START,
      payload: id,
    });

    var users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    users.updateUser(
      id,
      attributes,
      result => {
        dispatch({
          type: MANAGE_USERS_UPDATE_USER_SUCCESS,
          payload: id,
          meta: {
            mixpanel: {
              event: "Update user",
              props: {
                distinct_id: getState().current_user.data.data.id,
                User: id,
                "User email": attributes.email,
                Role: attributes.role_types && attributes.role_types.join(), // TODO: Think what's going on here. For now will get the first
                "Approval level": attributes.level,
                Permission: attributes.permissions,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });
        success(result.data.data.id);
      },
      error => {
        if (get(error, "response.data.detail", "") === "This entity requires at least one admin.") {
          dispatch({
            type: MANAGE_USERS_UPDATE_USER_LAST_ADMIN_ERROR,
            payload: error,
          });
        } else {
          dispatch({
            type: MANAGE_USERS_UPDATE_USER_ERROR,
            payload: error,
          });
        }
      },
    );
  };
}

export function createUser(attributes, success) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_CREATE_USER_START,
      payload: attributes,
    });

    var users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    users.createUser(
      attributes,
      result => {
        dispatch({
          type: MANAGE_USERS_CREATE_USER_SUCCESS,
          payload: attributes,
          meta: {
            mixpanel: {
              event: "Add user",
              props: {
                distinct_id: getState().current_user.data.data.id,
                User: result.data.data.id,
                "User email": attributes.email,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });

        success(result.data.data.id);
      },
      error => {
        dispatch({
          type: MANAGE_USERS_CREATE_USER_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function removeUser(id, success) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_REMOVE_USER_START,
      payload: id,
    });

    var users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    users.deleteUser(
      id,
      result => {
        dispatch({
          type: MANAGE_USERS_REMOVE_USER_SUCCESS,
          payload: id,
          meta: {
            mixpanel: {
              event: "Delete user",
              props: {
                distinct_id: getState().current_user.data.data.id,
                User: id,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });
        success();
      },
      error => {
        dispatch({
          type: MANAGE_USERS_REMOVE_USER_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function loadTeam(options) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_TEAM_LOAD_TEAM_START,
    });

    var user_entity_links = api(
      "user_entity_links",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    user_entity_links.getUserList(
      result => {
        dispatch({
          type: MANAGE_USERS_TEAM_LOAD_TEAM_SUCCESS,
          payload: {
            num_users: result.data.total_users,
            team_list: result.data.data,
          },
        });
      },
      error => {
        dispatch({
          type: MANAGE_USERS_TEAM_LOAD_TEAM_ERROR,
          payload: error,
        });
      },
      { params: options },
    );
  };
}

export function loadHeadquarterKeyContact() {
  return (dispatch, getState) => {
    const entity_id = getState().current_user.current_entity.id;
    var user_entity_links = api(
      "entities",
      getState().current_user.access_token,
      entity_id,
    );

    user_entity_links.getHeadquarterKeyContact(entity_id, result => {
      dispatch({
        type: MANAGE_USERS_TEAM_LOAD_HEADQUARTER_KEY_CONTACT,
        payload: result.data.data,
      });
    });
  };
}

export function loadWebsiteButtons() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_START,
    });

    var user_entity_links = api(
      "user_entity_links",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    user_entity_links.getWebsiteButtonList(
      result => {
        dispatch({
          type: MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function loadUser(user_id) {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_LOAD_USER_START,
    });

    var users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    users.getUserInfo(
      user_id,
      result => {
        dispatch({
          type: MANAGE_USERS_LOAD_USER_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: MANAGE_USERS_LOAD_USER_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function loadUsersByEntity() {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGE_USERS_LOAD_USERS_BY_ENTITY_START,
    });

    var users = api(
      "users",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    users.getIndex(
      result => {
        dispatch({
          type: MANAGE_USERS_LOAD_USERS_BY_ENTITY_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: MANAGE_USERS_LOAD_USERS_BY_ENTITY_ERROR,
          payload: error,
        });
      },
    );
  };
}
