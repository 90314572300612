import get from "lodash.get";
import { isCentraPassAllVerified } from "models/IdentificationCheckModel";
import AntiFraudCategoryRule from "utils/AntiFraudCategoryRule";

const getIsGovernmentVerificationSatisfied = ({
  antiFraudCategoryRules,
  identificationCheck,
}) => {
  const { formattedDetails } = identificationCheck;

  return (
    antiFraudCategoryRules.isGovernmentVerificationRequired &&
    identificationCheck.status === "completed" &&
    isCentraPassAllVerified({
      dobVerified: formattedDetails.dob_verified,
      idVerified: formattedDetails.identification_verification_string,
      nameVerified: formattedDetails.name_verified,
    })
  );
};

const getIsFaceMatchVerificationSatisfied = ({
  antiFraudCategoryRules,
  antiFraudCheck,
}) => {
  if (!antiFraudCategoryRules.isFrontFaceImageRequired) {
    return true;
  }

  return antiFraudCheck.pass;
};

export default function getIsAntiFraudVerified({
  application,
  authorisation,
  identificationCheck,
}): boolean {
  const antiFraudConfig = get(application, "antiFraudAddonRule.config", {});
  const applicableCategory = authorisation.getApplicableAntiFraudCategory(
    antiFraudConfig,
  );
  const antiFraudCategoryRules = new AntiFraudCategoryRule(applicableCategory);

  const isGovernmentVerificationSatisfied = getIsGovernmentVerificationSatisfied(
    {
      antiFraudCategoryRules,
      identificationCheck,
    },
  );

  const isFaceMatchVerificationSatisfied = getIsFaceMatchVerificationSatisfied({
    antiFraudCategoryRules,
    antiFraudCheck: authorisation.antiFraudCheck || {},
  });

  return isGovernmentVerificationSatisfied && isFaceMatchVerificationSatisfied;
}
