/* Import libraries */
import React from "react";
import moment from "moment";

import styles from "./css/TrendChart.css";

import { STYLE_COLOR_BASE_GREY_MEDIUM, STYLE_FONTS_FAMILY } from "constants";

var TrendChart = createClass({
  componentDidMount: function() {
    window.Plotly.newPlot("plot", this.props.chartData, this.getChartLayout(), {
      displayModeBar: false,
    });
    window.addEventListener("resize", this.resize);
  },

  resize: function() {
    Plotly.Plots.resize(document.getElementById("plot"));
  },

  componentDidUpdate: function() {
    var result = window.Plotly.newPlot(
      "plot",
      this.props.chartData,
      this.getChartLayout(),
      {
        displayModeBar: false,
      },
    );
  },

  componentWillUnmount: function() {
    window.removeEventListener("resize", this.resize);
  },

  getChartLayout: function() {
    var today = new Date();
    var tickvals = [
        new Date(today.getFullYear(), today.getMonth() - 1, 20),
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth() - 2, 10),
      ],
      ticktext = [
        moment(today).format("MMM YYYY"),
        moment(today)
          .subtract(1, "months")
          .format("MMM YYYY"),
        moment(today)
          .subtract(2, "months")
          .format("MMM YYYY"),
      ];
    return {
      margin: {
        t: 0,
        r: 0,
        l: 0,
        b: 50,
      },
      showlegend: false,
      plot_bgcolor: "#f6f6f6",
      height: 250,
      hovermode: "closest",
      xaxis: {
        fixedrange: true,
        type: "date",
        gridcolor: "transparent",
        tickvals: tickvals,
        ticktext: ticktext,
        ticks: "outside",
        tickcolor: "transparent",
        ticklen: 20,
        tickfont: {
          color: STYLE_COLOR_BASE_GREY_MEDIUM,
          size: 15,
          family: STYLE_FONTS_FAMILY,
        },
      },
      yaxis: {
        rangemode: "tozero",
        fixedrange: true,
        autorange: true,
        showgrid: false,
        zeroline: false,
        showline: false,
        ticks: "",
        showticklabels: false,
      },
    };
  },

  render: function() {
    return (
      <div className={styles.chart}>
        <div id="plot"></div>
      </div>
    );
  },
});

export default TrendChart;
