import getCommonAttributes from "modules/addons/components/digital-onboarding/PPSRRegistration/forms/versionAttributes/commonAttributes";
import isPresent from "utils/isPresent";

export default class NZVersionAttributes {
  static getNewVersionConfigAttribute(addonConfig, data) {
    const attributes = getCommonAttributes(addonConfig, data);

    return {
      ...attributes,
      functions: {
        discharge: data.functionDischarge,
        modify: data.functionModify,
        register: data.functionRegister,
        renew: data.functionRenew,
      },
    };
  }

  static getDefaultConfigValues(currentEntity, newVersion) {
    const config = newVersion.config;

    if (isPresent(config)) {
      return config;
    }

    return {
      collateral_preset: {
        AAPP: { active: false, description: "" },
        AAPPE: { active: false, description: "" },
        CASH: { active: false, description: "" },
        CROPS: { active: false, description: "" },
        INT: { active: false, description: "" },
        LS: { active: false, description: "" },
        NI: { active: false, description: "" },
        OTH: { active: false, description: "" },
      },
      financing: {
        auto_renew: false,
      },
      functions: {
        discharge: false,
        modify: false,
        register: false,
        renew: false,
      },
      secured_party_group: {
        organisation: {
          email: currentEntity.keyContactEmail,
          name: currentEntity.tradingName,
          phone: currentEntity.keyContactPhoneNumber,
        },
        person: {
          first_name: currentEntity.keyContactFirstName,
          last_name: currentEntity.keyContactLastName,
        },
      },
    };
  }
}
