import React, { Component } from "react";

import Button from "modules/shared/components/inputs/Button";
import SignatureIdentityCapture from "modules/shared/signature/SignatureIdentityCapture";
import { connect } from "react-redux";

class CardholderIdentityCapture extends Component {
  backButton() {
    return <Button text="Back" link="/cardholder/alert" />;
  }

  nextButton() {
    return <Button text="Next" link="/cardholder/identity/confirm" />;
  }

  render() {
    return (
      <SignatureIdentityCapture
        supplierLogo=""
        supplierTradingName="ACME Corp"
        backButton={this.backButton()}
        nextButton={this.nextButton()}
        handleComplete={() => ""}
      />
    );
  }
}

export default connect((state, ownProps) => {
  return {};
})(CardholderIdentityCapture);
