import { loadAddons, setCurrentAddonRuleset } from "modules/addons/actions";
import AddonSkeleton from "modules/addons/components/AddonSkeleton";
import AddOnsListItem from "modules/addons/components/AddOnsListItem";
import commonStyles from "modules/addons/components/css/CommonAddOns.css";
import { sortedAddonList } from "modules/addons/helpers";
import InternalUseFieldsDetails from "modules/addons/internal_use_fields/components/InternalUseFieldsDetails";
import Button from "modules/shared/components/inputs/Button";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import PopperList from "modules/shared/components/widgets/interactive/PopperList";
import { isFeatureEditEnabled } from "modules/shared/helpers/headquarterDetect";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import { DigitalOnboardingContext } from "../../DigitalOnboarding";
import Header from "../PPSRRegistration/forms/Header";
import { InnerWrapper } from "./styles";

const ADDON_NAME = "internal_use_fields";

type InternalUseField = {
  attributes: {
    active: boolean,
    addon_module_name: string,
    history_version: { data: any[]},
    owner_id: string,
    owner_type: "Entity",
    serial_number: number,
  },
  id: string,
  type: "addon_config",
}

type InternalUseFields = InternalUseField[];

function InternalUseFieldsList(props) {
  const {
    configs,
    dispatch,
    loading,
    internalUseFieldIdToUpdate,
    onClickCloseButton,
  } = props;

  const { onClickBack } = useContext(DigitalOnboardingContext);

  const [showDetailPage, setShowDetailPage] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [showAddList, setShowAddList] = useState(false);
  const [addListAnchorEl, setAddListAnchorEl] = useState(null);

  function showNewTemplateCreateList(target) {
    setAddListAnchorEl(target);
    setReadOnly(false);
    setShowAddList(true);
  }

  function hideNewTemplateCreateList() {
    setAddListAnchorEl(null);
    setReadOnly(false);
    setShowAddList(false);
  }

  function buildNewTemplate(configId = null) {
    hideNewTemplateCreateList();
    if (configId) {
      const config = configs.find(c => c.id === configId);
      const currentVersion = config.attributes.history_version.data[0];
      const versionDup = {
        attributes: {
          addon_module_name: "internal_use_fields",
          application_types: [],
          config: currentVersion.attributes.config,
          legal_types: [],
          max_credit_value: 0,
          min_credit_value: 0,
          name: null,
        },
      };
      dispatch(setCurrentAddonRuleset(null, versionDup, ADDON_NAME));
    }
    setShowDetailPage(true);
  }

  function openDetailPageForExistingRuleset(serialNumber, config) {
    if (config) {
      dispatch(setCurrentAddonRuleset(serialNumber, config, ADDON_NAME));
    }
    setShowDetailPage(true);
  }

  function loadAddon() {
    dispatch(loadAddons(ADDON_NAME));
  }

  useEffect(loadAddon, []);

  function setCurrentRuleSet(serialNumber, version) {
    if (!version) {
      return;
    }

    const processedVersion = { ...version };
    processedVersion.serial_number = serialNumber;
    setCurrentAddonRuleset(processedVersion);
  }

  function handleDiscard() {
    setShowDetailPage(false);
  }

  function clickAddButton(target) {
    if (configs.length === 0) {
      return buildNewTemplate();
    }

    return showNewTemplateCreateList(target);
  }

  function renderList() {
    const addOptions: {
      id?: string,
      label: string,
      value?: string
    }[] = [{ label: "New" }];

    const items = configs.map((config, index) => {
      const attributes = config.attributes;
      const versionData = attributes.history_version.data;
      const latestConfig = versionData[0].attributes;

      addOptions.push({
        label: `Base on #${attributes.serial_number} ${latestConfig.name}`,
        value: config.id,
      })

      return (
        <AddOnsListItem
          key={`${attributes.addon_module_name}_${index}`}
          addonType={ADDON_NAME}
          addonVersions={attributes.history_version.data}
          currentAddonData={config}
          onEdit={(serialNumber, currentConfig) => {
            setReadOnly(false);
            openDetailPageForExistingRuleset(serialNumber, currentConfig);
          }}
          viewHistory={(serialNumber, version) => {
            setReadOnly(true);
            openDetailPageForExistingRuleset(serialNumber, version);
          }}
          // listUpdateCallback={loadAddon}
          updating={config.id === internalUseFieldIdToUpdate}
        />
      );
    });

    return (
      <div className="column">
        <div className={commonStyles.header_container}>
          <span>
            <span className={commonStyles.header}>Set your rules here</span>
            {/* <VideoModal videoName={"cards"} /> */}
          </span>
          <Button
            style={{ fontWeight: "700" }}
            disabled={false}
            text="+ Add"
            handleClick={e => clickAddButton(e.target)}
          />
        </div>
        <PopperList
          open={showAddList}
          anchorEl={addListAnchorEl}
          options={addOptions}
          handleBlur={hideNewTemplateCreateList}
          handleSelect={value => buildNewTemplate(value)}
        />
        <div className={commonStyles.container}>
          <ul className={commonStyles.list}>{items}</ul>
        </div>
      </div>
    );
  }

  function renderDetails() {
    return (
      <InternalUseFieldsDetails
        handleDiscard={handleDiscard}
        readOnly={readOnly || !isFeatureEditEnabled("IUF")}
        reloadAddon={loadAddon}
      />
    );
  }

  const contents = showDetailPage ? renderDetails() : renderList();

  return (
    <div className={commonStyles.row}>
      <div className={commonStyles.block_wide}>
        <InnerWrapper className="column">
          <Header addonModuleName="internal_use_fields" />
          { showDetailPage ? "" : <CloseButton handleClick={onClickBack} /> }
        </InnerWrapper>
        {loading ? <AddonSkeleton /> : contents }
      </div>
    </div>
  );
}

export default connect(state => {
  const configs = sortedAddonList(state, ADDON_NAME);

  return {
    configs,
    internalUseFieldIdToUpdate: state.add_ons.internal_use_fields_active_updating_id,
    loading: state.add_ons.internal_use_fields_loading,
  };
})(InternalUseFieldsList);
