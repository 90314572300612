import React, { ReactElement } from 'react';
import Container from "modules/applications/components/credit-checks/credit-works/v2/Container";
import { Line } from '@ant-design/charts';
import moment from 'moment';
import { CREDITWORKS_COLORS } from 'variables/theme';

type DSOSummary = {
  balanceMonth: number,
  balanceYear: number,
  dso: number,
  industryDso: number,
}

type ChartData = {
  name: string,
  month: string,
  value: number,
}

interface Props {
  data: DSOSummary[],
}

function DSOSalesOutstandingTrend(props: Props): ReactElement|null {
  const { data } = props;

  if (data.length === 0) {
    return null;
  }

  const processedData: ChartData[] = [];

  data.forEach(({
    balanceMonth,
    // balanceYear,
    dso,
    industryDso,
  }) => {
    const baseData = {
      month: moment().month(balanceMonth).format('MMMM'),
    };
    processedData.push({
      ...baseData,
      name: 'Current Target',
      value: dso,
    });
    processedData.push({
      ...baseData,
      name: 'DSO Industry',
      value: industryDso,
    })
  })

  const config = {
    data: processedData,
    xField: 'month',
    yField: 'value',
    seriesField: 'name',
    label: {},
    point: {
      size: 7,
      shape: 'circle',
      style: {
        fill: CREDITWORKS_COLORS.blue,
        stroke: CREDITWORKS_COLORS.blue,
        lineWidth: 2,
      },
    },
    tooltip: { showMarkers: false },
    theme: {
      geometries: {
        point: {
          diamond: {
            active: {
              style: {
                shadowColor: '#FCEBB9',
                shadowBlur: 2,
                stroke: '#F6BD16',
              },
            },
          },
        },
      },
    },
    interactions: [{ type: 'marker-active' }],
  };
  return (
    <Container
      subHeading="Days Sales Outstanding (DSO) Trend"
    >
      <Line
        {...config}
        legend={{
          position: 'bottom',
        }}
      />
    </Container>
  );
}

export default DSOSalesOutstandingTrend
