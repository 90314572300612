/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";

/* Import actions */
import { setValue } from "../actions";

/* Import CSS */
import styles from "./css/AutoDecisioning.css";
/* Import components */
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";
import ExpandableSection from "./ExpandableSection";

var TradeReferences = createClass({
  handleChange: function(e) {
    const { dispatch } = this.props;
    dispatch(setValue("trade_reference_count", e.value));
  },

  render: function() {
    const {
      loading,
      rule_set,
      current_entity,
      options,
      selectedOption,
      read_only,
      error,
      count_error,
    } = this.props;

    let minimum_trade_references = read_only
      ? selectedOption
      : current_entity.minimum_trade_references;
    let invalid_value =
      selectedOption > minimum_trade_references || selectedOption < 1;

    return (
      <div className={styles.section}>
        <ExpandableSection
          id={"trade_reference"}
          title={"Trade Reference Checks"}
          info_text={
            "Do you require Trade Reference Checks for auto decisioning? \
          <br/>*Please note: This is a manual process. Once the checks have passed through an \
          authorised agent they will be fated and will continue through the decisioning process. \
          If this is manual you are required to pass the check before it can move through."
          }
          disabled={read_only}
          account_rule_on={current_entity.minimum_trade_references}
          hide_setting_message={read_only}
          error={error}
        >
          {invalid_value && current_entity.requires_guarantees && (
            <div className={styles.disabled_info}>
              Please resolve this setting to be inline with your{" "}
              <Link to="/dashboard/profile?active_tab=account_rules">
                account settings
              </Link>
            </div>
          )}
          {count_error && invalid_value && (
            <div className={styles.error}>{count_error}</div>
          )}
          <OptionsDropdown
            id={"trade_reference_count"}
            name={"trade_reference_count"}
            label={"Number of trade references"}
            value={selectedOption}
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            required={true}
            options={options.slice(0, minimum_trade_references)}
            disabled={read_only}
          />
        </ExpandableSection>
      </div>
    );
  },
});

var defaults = {
  title: "Number of trade references",
  options: [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
    {
      label: 4,
      value: 4,
    },
  ],
};

module.exports = connect((state, ownProps) => {
  return {
    ...defaults,
    rule_set: ownProps.rule_set,
    options: defaults.options,
    selectedOption:
      state.auto_decisions.current_rule_set.attributes.trade_reference_count ||
      "",
    current_entity: state.current_user.current_entity
      ? state.current_user.current_entity.attributes
      : null,
  };
})(TradeReferences);
