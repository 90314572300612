import React, { Component } from "react";
import { connect } from "react-redux";
import { setEmailTemplate } from "../actions";
import {
  isFeatureEditEnabled,
  isBranch,
} from "modules/shared/helpers/headquarterDetect";
import styles from "./css/CommonEditProfile.css";
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import Button from "modules/shared/components/inputs/Button";
import EmailTemplateModal from "./EmailTemplateModal";
import VideoModal from "modules/shared/components/widgets/interactive/VideoModal";
import Dot from "modules/shared/components/widgets/static/Dot";

const ActiveState = props => {
  const { isActive, isVisible } = props;

  if (!isVisible) {
    return null;
  }

  const color = isActive ? "blue" : "red";
  const text = isActive ? "Active" : "Inactive";

  return <Dot color={color} text={text} />;
};

const EmailTemplateItem = props => {
  const { item, isActiveStateVisible, openEmailTemplateModal } = props;

  if (!isBranch() || item.attributes.template_no !== 14) {
    let buttonText = "edit";
    if (!isFeatureEditEnabled("Email")) {
      buttonText = "view";
    }

    const classNames = [
      styles.email_template_item,
      "mr-6",
      "is-flex",
      "is-align-items-center",
    ];

    if (isActiveStateVisible) {
      classNames.push("is-justify-content-space-between");
    } else {
      classNames.push("is-justify-content-flex-end");
    }

    return (
      <li className={styles.item}>
        <div>{item.attributes.custom_name}</div>
        <div className={classNames.join(" ")}>
          <ActiveState
            isActive={item.attributes.notifications}
            isVisible={isActiveStateVisible}
          />
          <Button
            text={buttonText}
            handleClick={() => openEmailTemplateModal(item)}
          />
        </div>
      </li>
    );
  }

  return null;
};

EmailTemplateItem.defaultProps = {
  isActiveStateVisible: false,
};

class EntityEmailTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = { showEmailTemplateModal: false };
  }

  openEmailTemplateModal = item => {
    const { dispatch } = this.props;

    dispatch(setEmailTemplate(item));
    this.setState({ showEmailTemplateModal: true });
  };

  dismissEmailTemplateModal = () => {
    this.setState({ showEmailTemplateModal: false });
  };

  render() {
    const {
      title,
      loading,
      mandatoryEmailTemplates,
      optionalEmailTemplates,
    } = this.props;
    if (loading) return <SimpleLoader />;

    const mandatoryTemplates = mandatoryEmailTemplates.map((item, i) => (
      <EmailTemplateItem
        key={`mandatory-email-${i}`}
        item={item}
        openEmailTemplateModal={this.openEmailTemplateModal}
      />
    ));

    const optionalTemplates = optionalEmailTemplates.map((item, i) => (
      <EmailTemplateItem
        key={`optional-email-${i}`}
        item={item}
        isActiveStateVisible={true}
        openEmailTemplateModal={this.openEmailTemplateModal}
      />
    ));

    let modalMarkup;
    if (this.state.showEmailTemplateModal) {
      modalMarkup = (
        <EmailTemplateModal dismissHandler={this.dismissEmailTemplateModal} />
      );
    }

    return (
      <div>
        <div className={styles.row}>
          <div className={styles.block_wide}>
            <div className={styles.liner}>
              <h2 className={styles.header}>
                Mandatory email templates{" "}
                <VideoModal videoName="entity_email_templates" />
              </h2>
              <ul className={styles.list}>{mandatoryTemplates}</ul>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.block_wide}>
            <div className={styles.liner}>
              <h2 className={styles.header}>Optional email templates</h2>
              <ul className={styles.list}>{optionalTemplates}</ul>
            </div>
          </div>
        </div>
        {modalMarkup}
      </div>
    );
  }
}

function sortAlphabetically(emailTemplates) {
  return emailTemplates.sort((a, b) => {
    if (a.attributes.custom_name < b.attributes.custom_name) {
      return -1;
    }

    if (a.attributes.custom_name > b.attributes.custom_name) {
      return 1;
    }

    return 0;
  });
}

export default connect((state, ownProps) => {
  let ent = state.manage_profile.current_entity.attributes;
  let s = state.manage_profile;
  const mandatoryEmailTemplates = [];
  const optionalEmailTemplates = [];

  s.entity_includes
    .filter(
      element =>
        element.type === "entity_email_templates" &&
        !element.attributes.discontinued,
    )
    .forEach(element => {
      if (element.attributes.mandatory) {
        mandatoryEmailTemplates.push(element);
      } else {
        optionalEmailTemplates.push(element);
      }
    });

  return {
    title: "Email templates",
    loading: s.current_entity_loading,
    mandatoryEmailTemplates: sortAlphabetically(mandatoryEmailTemplates),
    optionalEmailTemplates: sortAlphabetically(optionalEmailTemplates),
  };
})(EntityEmailTemplates);
