import axios from "./axios";

export default class IUFApprovals {
  constructor(config) {
    config.timeout = 60000;

    this.axios = axios(config);
    this.type = "iuf_approvals";
    this.path = `/${this.type}`;
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      "v1",
      "v2",
    );
  }

  createIUFApproval(attributes) {
    return this.axios.post(this.path, {
      data: { attributes, type: this.type },
    });
  }

  updateIUFApproval(id, attributes) {
    return this.axios.patch(`${this.path}/${id}`, {
      data: { attributes, type: this.type },
    });
  }
}
