import historyStyles from "modules/applications/components/credit-checks/credit-works/css/History.css";
import moment from "moment";
import React, { ReactElement } from "react";
import { SubHeading } from "modules/applications/components/credit-checks/credit-works/v2/Container/styles";

type HistoricName = {
  endDate: string,
  name: string,
  startDate: string,
}

type Props = {
  data: HistoricName[],
}

function HistoricNames(props: Props): ReactElement|null {
  const { data } = props;

  const formattedDate = (date: string) => moment(date).format("dd-MMM-YYYY")

  const getNames = () => data.map(({
    name,
    startDate,
    endDate,
  }) => (
    <div key={name}>
      <p>
        {name}
            (from {formattedDate(startDate)}{endDate && ` ${formattedDate(endDate)}`})
      </p>
    </div>
  ))

  if (data.length === 0) {
    return null;
  }

  return (
    <div className={historyStyles.container}>
      <SubHeading>Historic Names</SubHeading>
      {getNames()}
    </div>
  );
}

export default HistoricNames;
