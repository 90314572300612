import React, { Component } from "react";

import styles from "modules/shared/components/text/css/StandardTermsAndConditions.css";

export default class AgreementSection12 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>12.</span> General
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>12.1</span>{" "}
          <strong>Notices</strong>: Any notice to be given under these Terms
          must be made in writing, or by email sent to the registered office or
          principal place of business of the other party or to such other
          address as may be notified by either party to the other from time to
          time. Any communication by email will be deemed to be received when
          transmitted to the correct email address of the recipient. Any other
          communication in writing will be deemed to be received when left at
          the specified address of the recipient or on the third day following
          the date of posting. The initial email and physical addresses of the
          Supplier is as provided by the Supplier at the time of registration
          and of 1Centre is as follows:
          <br />
          Address: 117 St Georges Bay Road, Parnell, Auckland
          <br />
          Phone: <a href="tel:021705060">021705060</a>
          <br />
          Email: <a href="mailto:support@1centre.com">support@1centre.com</a>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>12.2</span>{" "}
          <strong>Electronic Transactions Act 2002</strong>: The Supplier
          consents to receive notices given pursuant to these terms and other
          communications from 1Centre electronically.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>12.3</span>{" "}
          <strong>Assignment</strong>: The Supplier may not assign or transfer
          any of its rights or obligations under these Terms without the prior
          written consent of 1Centre.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>12.4</span>{" "}
          <strong>Independent contractors</strong>: The parties are independent
          contractors, not employees, agents or representatives of each other.
          Neither party has the right to bind the other party or any other party
          to any agreement.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>12.5</span>{" "}
          <strong>No benefit to third party</strong>: These Terms do not confer
          any benefit on any person that is not a party to this agreement, and
          nothing contained in these Terms shall be construed as creating any
          right, claim or cause of action in favour of any such third party
          against either of the parties to this agreement.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>12.6</span>{" "}
          <strong>Entire agreement</strong>: These Terms constitute the entire
          agreement between the parties and supersedes all prior communications,
          representations, agreements or understandings, either verbal or
          written, between the parties with respect to the subject matter of
          this Agreement.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>12.7</span>{" "}
          <strong>Severability</strong>: If any term is found to be invalid or
          unenforceable, the remaining provisions will continue in effect.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>12.8</span>{" "}
          <strong>Governing law</strong>: These Terms shall be governed by and
          construed under the laws of New Zealand. The parties submit to the
          non-exclusive jurisdiction of the courts of New Zealand in respect of
          all disputes arising under or in relation to this agreement.
        </p>
      </div>
    );
  }
}
