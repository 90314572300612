import Button from "modules/shared/components/inputs/Button";
import CircleModal from "modules/shared/components/widgets/static/CircleModal";
import React, { Fragment, ReactElement } from "react";
import { connect } from "react-redux";

import { Buttons } from "./styles";

type Props = {
  count: number,
  handleDismiss: () => void,
  handleNo: () => void,
  handleYes: () => void,
  selectedSupplier: string,
  selectedUser: string,
  title: string,
}

function InformBusinessModal(props: Props): ReactElement {
  const {
    count,
    handleDismiss,
    handleNo,
    handleYes,
    selectedSupplier,
    selectedUser,
    title,
  } = props;

  return (
    <CircleModal title={title} dismissHandler={handleDismiss}>
      <p>
        <strong>{count}</strong> applications will be reassigned to
        {" "}
        <strong>{selectedUser}</strong>
        { selectedSupplier && (
          <Fragment>
            {" "}
            under <strong>{selectedSupplier}</strong>.
          </Fragment>
        )}
        .
      </p>
      <p>Are you sure you want to proceed with this change?</p>
      <Buttons>
        <Button text="yes" white={true} handleClick={handleYes} />
        <Button text="no" handleClick={handleNo} />
      </Buttons>
    </CircleModal>
  );
}

const defaults = {
  title: "Confirm reassign?",
};

export default connect(() => {
  return {
    title: defaults.title,
  };
})(InformBusinessModal);
