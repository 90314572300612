import styled from "styled-components";
import { THEME_COLORS } from "variables/theme";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-areas: "sidebar content";
  grid-gap: 20px;
`;

export const GridSidebar = styled.div`
  grid-area: sidebar;
`;

export const GridContent = styled.div`
  grid-content: content;
  border-left: 1px solid ${THEME_COLORS.borderGray};
  padding-left: 20px;
`;
