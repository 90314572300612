import api from "../../api";
import _ from "lodash";
import { SET, RESET } from "./constants";

export function set(payload) {
  return {
    type: SET,
    payload,
  };
}
export function reset() {
  return {
    type: RESET,
  };
}

export function getEntities() {
  return (dispatch, getState) => {
    dispatch({
      type: SET,
      payload: { entitiesLoading: true },
    });
    const entities = api(
      "entities",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    entities.getEntities(
      result => {
        const entities = result.data.data;

        const users = result.data.included;
        dispatch({
          type: SET,
          payload: { entities, users, entitiesLoading: false },
        });
      },
      error => {
        dispatch({
          type: SET,
          payload: { entitiesError: error },
        });
      },
      { params: { include: "users" } },
    );
  };
}

export function loadConsumerApplications() {
  return (dispatch, getState) => {
    dispatch({
      type: SET,
      payload: { applicationsLoading: true },
    });

    var applications = api(
        "applications",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
        { version: "/v2", headers: { "Content-Type": "application/json" } },
      ),
      entityId = getState().current_user.current_entity.id,
      data = [];

    applications.getApplications(
      result => {
        if (result.data.data.length) {
          data = result.data.data;
        }

        const included = result.data.included || [];
        const applicationEntities = included.filter(i => i.type === "entities");
        const applicationUsers = included.filter(i => i.type === "users");

        dispatch({
          type: SET,
          payload: {
            applications: data,
            applicationEntities,
            applicationUsers,
            applicationsLoading: false,
          },
        });
      },
      error => {
        dispatch({
          type: SET,
          payload: { applicationsError: error },
        });
      },
      {
        params: {
          "filters[supplier_id]": entityId,
          include: "supplier,consumer,supplier_contact,consumer_contact",
        },
      },
    );
  };
}

export function loadConnections() {
  return (dispatch, getState) => {
    dispatch({
      type: SET,
      payload: { connectionsLoading: true },
    });

    var connections = api(
        "connections",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
        { version: "/v2", headers: { "Content-Type": "application/json" } },
      ),
      entityId = getState().current_user.current_entity.id;

    connections.getConnections(
      result => {
        const connections = result.data.data;
        const included = result.data.included || [];
        const connectionEntities = included.filter(i => i.type === "entities");
        const connectionApplications = included.filter(
          i => i.type === "applications",
        );
        const connectionUsers = included.filter(i => i.type === "users");
        dispatch({
          type: SET,
          payload: {
            connectionsLoading: false,
            connections,
            connectionEntities,
            connectionApplications,
            connectionUsers,
          },
        });
      },
      error => {
        dispatch({
          type: SET,
          payload: { connectionsLoading: false, connectionsError: error },
        });
      },
      {
        params: {
          "filters[supplier_id]": entityId,
          include:
            "supplier,consumer,supplier_contact,consumer_contact,application",
        },
      },
    );
  };
}
export function updateSupplierApplicationKeyContact(
  applicationId,
  userId,
  success,
) {
  return (dispatch, getState) => {
    dispatch({
      type: SET,
      payload: { supplierContactLoading: true },
    });

    var applications = api(
        "applications",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      apiAction;

    applications.setSupplierContact(
      applicationId,
      userId,
      result => {
        dispatch({
          type: SET,
          payload: {
            supplierContact: applicationId,
            supplierContactLoading: false,
          },
        });
        success(result.data.data);
      },
      error => {
        dispatch({
          type: SET,
          payload: {
            supplierContactError: error,
            supplierContactLoading: false,
          },
        });
      },
    );
  };
}
export function updateSupplierConnectionKeyContact(
  connectionId,
  userId,
  success,
) {
  return (dispatch, getState) => {
    dispatch({
      type: SET,
      payload: { supplierContactLoading: true },
    });

    var connections = api(
        "connections",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      apiAction;

    connections.setSupplierContact(
      connectionId,
      userId,
      result => {
        dispatch({
          type: SET,
          payload: {
            supplierContact: connectionId,
            supplierContactLoading: false,
          },
        });
        success(result.data.data);
      },
      error => {
        dispatch({
          type: SET,
          payload: {
            supplierContactError: error,
            supplierContactLoading: false,
          },
        });
      },
    );
  };
}
export function loadContactCandidates() {
  return (dispatch, getState) => {
    dispatch({
      type: SET,
      payload: { contactCandidatesLoading: false },
    });

    var users = api(
        "users",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = [];

    users.getUsers(
      result => {
        if (result.data.data.length > 0) {
          data = result.data.data;
          dispatch({
            type: SET,
            payload: {
              contactCandidates: data,
              contactCandidatesLoading: true,
            },
          });
        }
      },
      error => {
        dispatch({
          type: SET,
          payload: {
            contactCandidatesError: error,
            contactCandidatesLoading: false,
          },
        });
      },
    );
  };
}
