/* Import libraries */
import React from "react";
import styles from "modules/shared/components/widgets/static/css/ApprovalLevelCircle.css";

import Icon from "modules/shared/components/svg/Icon";

import { formatMoney as utilFormatMoney } from "utils/formatting";
import { ReactElement } from "react";

type Props = {
  approvers: number,
  theme: string,
  max_amount: number,
  min_amount: number,
  title: string,
}

function ApprovalLevelCircle({
  approvers,
  theme,
  max_amount,
  min_amount,
  title,
}: Props): ReactElement {
  const color = theme == "activate" ? "blue" : "grey";

  const formatMoney = (amount = 0): string => {
    return utilFormatMoney(amount, null, null, null, null);
  }

  return (
    <div className={styles[`circle_${color}`]}>
      <div className={styles[`amount_${color}`]}>
        ${formatMoney(min_amount)}
        <br />
        to
        <br />${formatMoney(max_amount)}
      </div>
      <div className={styles[`title_${color}`]}>{title}</div>
      <div className={styles[`approver_${color}`]}>
        <div>
          <Icon color={color} icon={"profile"} />
          {!!approvers && `X${approvers}`}
        </div>
      </div>
    </div>
  );
};

export default ApprovalLevelCircle;
