import { isEmpty } from "lodash";

import {
  COB_CARDS_LOAD_CARDHOLDERS_ERROR,
  COB_CARDS_LOAD_CARDHOLDERS_START,
  COB_CARDS_LOAD_CARDHOLDERS_SUCCESS,
  COB_CARDS_PRE_POPULATE_DETAILS,
  COB_CARDS_REMOVE_CARDHOLDER,
  COB_CARDS_SAVE_CARDHOLDER_ERROR,
  COB_CARDS_SAVE_CARDHOLDER_START,
  COB_CARDS_SAVE_CARDHOLDER_SUCCESS,
  COB_CARDS_SAVE_CARDHOLDERS_SUCCESS,
  COB_CARDS_SET_CARDHOLDER_ADDON_ANSWER,
  COB_CARDS_SET_CARDHOLDER_DETAIL,
  COB_CARDS_SET_CARDHOLDERS_COUNT,
  COB_CARDS_SET_CARDHOLDERS_COUNT_COMPLETED,
  COD_CARDS_REMOVE_APPLICANT_CARDHOLDER,
  COD_CARDS_SET_APPLICANT_CARDHOLDER,
} from "../constants/cards";

export const CARDHOLDER_DEFAULT_FIELDS = {
  addonRuleId: null,
  answers: [],
  cardLimit: "",
  email: "",
  firstName: "",
  isApplicant: false,
  lastName: "",
  middleName: "",
  mobile: "",
};

export function filterCardholderFields(config) {
  if (isEmpty(config)) {
    return CARDHOLDER_DEFAULT_FIELDS;
  }

  const { cardLimitEnabled, signatureRequired } = config;
  const cardholderDefaultFields = { ...CARDHOLDER_DEFAULT_FIELDS };

  if (!cardLimitEnabled) {
    delete cardholderDefaultFields["cardLimit"];
  }

  if (!signatureRequired) {
    const fieldsToDelete = ["lastName", "middleName", "mobile", "email"];
    fieldsToDelete.forEach(
      fieldKey => delete cardholderDefaultFields[fieldKey],
    );
  }

  return cardholderDefaultFields;
}

const cardsDefaults = {
  cardholders: [],
  completed: {},
  error: null,
  isLoading: false,
  isAffected: false,
};

export function consumerOnboardingCardsReducer(state = cardsDefaults, action) {
  const cardholders = state.cardholders;

  let cardholder;

  switch (action.type) {
    case COD_CARDS_SET_APPLICANT_CARDHOLDER:
      const { attributes } = action.payload;
      return {
        ...state,
        cardholders: [
          {
            attributes: {
              isApplicant: true,
              ...attributes,
            },
          },
          ...cardholders,
        ],
      };
    case COD_CARDS_REMOVE_APPLICANT_CARDHOLDER: {
      const otherCardholders = cardholders.filter(
        ({ attributes }) => !attributes.isApplicant,
      );

      return {
        ...state,
        cardholders: otherCardholders,
      };
    }
    case COB_CARDS_SET_CARDHOLDERS_COUNT: {
      const maxCards = 20;
      const { count } = action.payload;

      const cardholderApplicant = cardholders.find(
        ({ attributes }) => attributes.isApplicant,
      );
      const filledCardholders = cardholders.filter(
        cardholder => !isEmpty(cardholder.attributes),
      );
      let newCardholders = [];
      if (!isNaN(count)) {
        const numCardsToAdd =
          (count > maxCards ? maxCards : count) -
          filledCardholders.length +
          (cardholderApplicant ? 1 : 0);
        newCardholders = [...Array(numCardsToAdd).keys()].map(_ => {
          return { attributes: {} };
        });
      }

      return {
        ...state,
        cardholders: [...filledCardholders, ...newCardholders],
      };
    }
    case COB_CARDS_SAVE_CARDHOLDER_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case COB_CARDS_SAVE_CARDHOLDER_START:
      return { ...state, isLoading: true };
    case COB_CARDS_SAVE_CARDHOLDERS_SUCCESS:
      return { ...state, cardholders: action.payload.value };
    case COB_CARDS_SAVE_CARDHOLDER_SUCCESS:
      cardholders[action.payload.index] = action.payload.value;
      return { ...state, cardholders };
    case COB_CARDS_SET_CARDHOLDERS_COUNT_COMPLETED:
      return {
        ...state,
        completed: {
          ...state.completed,
          [action.payload.component]: action.payload.state,
        },
      };
    case COB_CARDS_SET_CARDHOLDER_DETAIL: {
      // TODO: This is not ideal but the way the ConsumerOnBoarding has been
      // designed makes it difficult to do this properly.
      // It would have been better if the details of the cardholder is
      // contained as an internal state in the CardsDetail component and
      // persisted into the database when click "next" button
      const { config, index } = action.payload;
      cardholder = cardholders[index] || {
        attributes: filterCardholderFields(config),
      };

      cardholders[action.payload.index] = {
        ...cardholder,
        attributes: {
          ...cardholder.attributes,
          [action.payload.key]: action.payload.value,
        },
      };

      return { ...state, cardholders };
    }
    case COB_CARDS_SET_CARDHOLDER_ADDON_ANSWER:
      cardholder = cardholders[action.payload.index] || {
        attributes: CARDHOLDER_DEFAULT_FIELDS,
      };

      cardholders[action.payload.index] = {
        ...cardholder,
        attributes: {
          ...cardholder.attributes,
          answers: setCardholderAddonAnswer(cardholder, action.payload.answer),
        },
      };

      return { ...state, cardholders };
    case COB_CARDS_LOAD_CARDHOLDERS_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    case COB_CARDS_LOAD_CARDHOLDERS_START:
      return { ...state, isLoading: true };
    case COB_CARDS_LOAD_CARDHOLDERS_SUCCESS:
      return { ...state, cardholders: action.payload };
    case COB_CARDS_REMOVE_CARDHOLDER:
      // eslint-disable-next-line no-case-declarations
      cardholders.splice(action.payload.index, 1);
      return { ...state, cardholders };
    case COB_CARDS_PRE_POPULATE_DETAILS:
      cardholder = cardholders[action.payload.index] || {
        attributes: CARDHOLDER_DEFAULT_FIELDS,
      };

      cardholders[action.payload.index] = {
        ...cardholder,
        attributes: {
          ...cardholder.attributes,
          ...action.payload.attributes,
        },
      };

      return { ...state, cardholders };
    default:
      return { ...state };
  }
}

function setCardholderAddonAnswer(cardholder, answer) {
  const answers = cardholder.attributes.answers || [];
  // Remove existing answer with the same description and replace it with the
  // new answer
  const filteredAnswers = answers.filter(
    filteredAnswer => filteredAnswer.description !== answer.description,
  );

  filteredAnswers.push(answer);

  return filteredAnswers;
}
