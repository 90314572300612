const prefix = "LEADS/";
export const SET = `${prefix}SET`;
export const RESET = `${prefix}RESET`;
export const LEADS_ALL_LEADS_LIST_START = "LEADS_ALL_LEADS_LIST_START";
export const LEADS_ALL_LEADS_LIST_SUCCESS = "LEADS_ALL_LEADS_LIST_SUCCESS";
export const LEADS_ALL_LEADS_LIST_ERROR = "LEADS_ALL_LEADS_LIST_ERROR";
export const LEAD_SET_LEAD_NAME = "LEAD_SET_LEAD_NAME";
export const ENTITY_LEAD_NAME_UPDATE_START = "ENTITY_LEAD_NAME_UPDATE_START";
export const ENTITY_LEAD_NAME_UPDATE_SUCCESS =
  "ENTITY_LEAD_NAME_UPDATE_SUCCESS";
export const ENTITY_LEAD_NAME_UPDATE_ERROR = "ENTITY_LEAD_NAME_UPDATE_ERROR";
export const LEADS_LOAD_REPORT_START = "LEADS_LOAD_REPORT_START";
export const LEADS_LOAD_REPORT_SUCCESS = "LEADS_LOAD_REPORT_SUCCESS";
export const LEADS_LOAD_REPORT_ERROR = "LEADS_LOAD_REPORT_ERROR";
export const LEADS_SET_REGION = "LEAD_SET_REGION";
export const LEADS_SET_STATE = "LEAD_SET_STATE";
export const LEADS_UPDATE_LEAD_ARCHIVE_START =
  "LEADS_UPDATE_LEAD_ARCHIVE_START";
export const LEADS_UPDATE_LEAD_ARCHIVE_SUCCESS =
  "LEADS_UPDATE_LEAD_ARCHIVE_SUCCESS";
export const LEADS_UPDATE_LEAD_ARCHIVE_ERROR =
  "LEADS_UPDATE_LEAD_ARCHIVE_ERROR";
export const LEADS_CREATE_NOTES_START = "LEADS_CREATE_NOTES_START";
export const LEADS_CREATE_NOTES_SUCCESS = "LEADS_CREATE_NOTES_SUCCESS";
export const LEADS_CREATE_NOTES_ERROR = "LEADS_CREATE_NOTES_ERROR";
export const LEADS_LOAD_NOTES_START = "LEADS_LOAD_NOTES_START";
export const LEADS_LOAD_NOTES_SUCCESS = "LEADS_LOAD_NOTES_SUCCESS";
export const LEADS_LOAD_NOTES_ERROR = "LEADS_LOAD_NOTES_ERROR";
export const LEADS_REVIEW_LOAD_START = "LEADS_REVIEW_LOAD_START";
export const LEADS_REVIEW_LOAD_SUCCESS = "LEADS_REVIEW_LOAD_SUCCESS";
export const LEADS_REVIEW_LOAD_ERROR = "LEADS_REVIEW_LOAD_ERROR";
export const LEADS_CLEAR_REVIEW_DATA = "LEADS_CLEAR_REVIEW_DATA";
export const LEADS_SET_REVIEW_INDEX = "LEADS_SET_REVIEW_INDEX";

export const NEW_LEAD_SET = "NEW_LEAD_SET";
export const SET_NEW_LEAD_FIELDS = "SET_NEW_LEAD_FIELDS";
export const NEW_LEAD_SET_ADDRESS = "NEW_LEAD_SET_ADDRESS";
export const NEW_LEAD_COMPANY_NAME_LOOKUP_CLEAR =
  "NEW_LEAD_COMPANY_NAME_LOOKUP_CLEAR";
export const NEW_LEAD_COMPANY_NAME_LOOKUP_START =
  "NEW_LEAD_COMPANY_NAME_LOOKUP_START";
export const NEW_LEAD_COMPANY_NAME_LOOKUP_SUCCESS =
  "NEW_LEAD_COMPANY_NAME_LOOKUP_SUCCESS";
export const NEW_LEAD_COMPANY_NAME_LOOKUP_ERROR =
  "NEW_LEAD_COMPANY_NAME_LOOKUP_ERROR";
export const NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_CLEAR =
  "NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_CLEAR";
export const NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_START =
  "NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_START";
export const NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_SUCCESS =
  "NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_SUCCESS";
export const NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_ERROR =
  "NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_ERROR";
export const NEW_LEAD_CREATE_START = "NEW_LEAD_CREATE_START";
export const NEW_LEAD_CREATE_SUCESS = "NEW_LEAD_CREATE_SUCESS";
export const NEW_LEAD_CREATE_ERROR = "NEW_LEAD_CREATE_ERROR";

export const LEADS_REVIEW_REFRESH = "LEADS_REVIEW_REFRESH";
export const LEADS_UPDATE_START = "LEADS_UPDATE_START";
export const LEADS_UPDATE_SUCCESS = "LEADS_UPDATE_SUCCESS";
export const LEADS_UPDATE_ERROR = "LEADS_UPDATE_ERROR";
export const LEADS_UPDATE_SET_DEFAULT_VALUES =
  "LEADS_UPDATE_SET_DEFAULT_VALUES";
export const NEW_LEAD_RESET = "NEW_LEAD_RESET";
