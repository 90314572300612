import api from "api";
import get from "lodash.get";
import Review from "modules/authorisation/components/Review";
import { getIsFaceMatchEnabled } from "modules/identity/components/utils";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import React, { Component } from "react";
import { connect } from "react-redux";

import styles from "./css/Section.css";

class Abstract extends Component {
  get isApplicationLocked() {
    const attributes = this.props.application.attributes;
    const lockedStates = ["accepted", "declined"];

    return lockedStates.includes(attributes.status);
  }

  componentDidMount() {
    this.setAuthorisationToInProgress();
  }

  setAuthorisationToInProgress() {
    const { authorisation, currentUser } = this.props;
    const authorisationId = get(authorisation, "data.authorisation.id");
    const authorisationsAPI = api("authorisations", currentUser.access_token);

    authorisationsAPI.updateInProgressState(authorisationId);
  }

  renderAuthorisationItems() {
    const {
      consumerName,
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      hasSignatory,
    } = this.props;
    const items = [];
    if (hasSignatory) {
      items.push(<li key="authorisation-signatory">Signatory of application</li>);
    }
    if (hasGuarantor) {
      items.push(<li key="authorisation-guarantor">Personal guarantor</li>);
    }
    if (hasCardholder) {
      items.push(<li key="authorisation-cardholder">Cardholder</li>);
    }
    if (hasPaperless) {
      items.push(<li key="authorisation-direct-debit">Signatory on {consumerName} bank account</li>);
    }
    return items;
  }

  renderNoticeMessage(message) {
    return (
      <div className={styles.container}>
        <section className={styles.section}>
          <div>{message}</div>
        </section>
      </div>
    );
  }

  renderButtons() {
    const { application } = this.props;

    if (this.isApplicationLocked) {
      return this.renderNoticeMessage(
        `A decision has been made for this application and can no longer be
         accepted or declined.`,
      );
    }

    if (application.attributes.archived) {
      return this.renderNoticeMessage(
        `This application has already been archived and can no longer be
         accepted or declined.`,
      );
    }

    if (application.attributes.status === "deleted") {
      return this.renderNoticeMessage(
        `This application has already been deleted and can no longer be
         accepted or declined.`,
      );
    }

    return null;
  }

  render() {
    const {
      consumerContactName,
      consumerName,
      fullName,
      supplierName,
    } = this.props;

    return (
      <div className="is-size-medium">
        <div className={styles.container}>
          <section className={styles.section}>
            <div className={`${styles.row} mb-5`}>
              <div className={styles.panel}>
                <PanelTitle text="Authorisation" />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.content}>
                <p>
                  Welcome{" "}
                  <span className="has-text-weight-normal">{fullName}</span>,
                </p>
                <p className="mb-4">
                  You are nominated by{" "}
                  <span className="has-text-weight-normal">{consumerName}</span>{" "}
                  to act in the below capacity for an application for credit
                  being submitted by{" "}
                  <span className="has-text-weight-normal">
                    {consumerContactName}
                  </span>{" "}
                  to{" "}
                  <span className="has-text-weight-normal">{supplierName}</span>
                  .
                </p>
                <ol className={styles.authorisation_item_list}>
                  {this.renderAuthorisationItems()}
                </ol>
                <p className="mt-4">
                  As such we require your authority to act in this capacity. To
                  do so, we will provide you with the relevant information you
                  need to make a decision, we will need a copy of your
                  identification and we&lsquo;ll be capturing your e-signature.
                </p>
              </div>
            </div>
          </section>
        </div>
        <Review
          isApplicationLocked={this.isApplicationLocked}
          {...this.props}
        />
        {this.renderButtons()}
      </div>
    );
  }
}

export default connect(state => {
  return {
    application: state.authorisation.data.application,
    authorisation: state.authorisation,
    consumerContactName:
      state.authorisation.data.application.attributes.consumer_contact_name,
    currentUser: state.current_user,
    firstName: state.authorisation.firstName,
    fullName: state.authorisation.fullName,
    lastName: state.authorisation.lastName,
    middleName: state.authorisation.middleName,
    isFaceMatchEnabled: getIsFaceMatchEnabled(state),
  };
})(Abstract);
