import PDFWidget from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/PDFWidget";
import FileHeld from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/FileHeld";
import Header from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/Header";
import Tokenised from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/Tokenised";
import React, { Fragment } from "react";

const ImageWidget = props => {
  const { application, hasPDFError, isVisibleWhenEmpty, signature } = props;
  const { antiFraudAddonRule } = application;

  const isIdentificationTokenised =
    antiFraudAddonRule.config.tokenised || !application.isSubmitted;

  if (!isVisibleWhenEmpty && !signature.identificationImageUrl) {
    return null;
  }

  if (hasPDFError) {
    return (
      <Fragment>
        <Header title="ID verification" />
        <PDFWidget identificationImageUrl={signature.identificationImageUrl} />
      </Fragment>
    );
  }

  if (isIdentificationTokenised) {
    return <Tokenised {...props} />;
  }

  return <FileHeld {...props} />;
};

export default ImageWidget;
