import api from 'api';
import React, { ReactElement, useEffect } from 'react'
import Panel from '../Panel'
import { Props } from '../Panel/model';
import useRequestQuery from '../../hooks/useRequestQuery';
import useParamsCompare from '../../hooks/useParamsCompare';
import Message from '../Message';

function ConversionRateMobile(props: Props): ReactElement {
  const {
    accessToken,
    entityId,
    filterState,
  } = props;
  const params = {
    ...filterState,
  }

  const apiAction = () => api(
    'reporting_trading_hours',
    accessToken,
    entityId,
  ).get({ params });

  const { error, forceUpdate, loading } = useRequestQuery(apiAction);

  const paramString = JSON.stringify(params);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
    }
  }, [filterState])

  return (
    <Panel
      {...props}
      beta
      error={error}
      loading={loading}
      title="Conversion Rate"
      refresh={() => forceUpdate()}
    >
      <Message
        faProps={{
          icon: ['fas', 'smile-wink'],
        }}
        message="This widget is coming soon."
      />
    </Panel>
  )
}

export default ConversionRateMobile
