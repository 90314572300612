import React, { Fragment } from "react";
import ImageWidget from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/ImageWidget";
import FrontFaceImageWidget from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/FrontFaceImageWidget";

const V2 = props => (
  <Fragment>
    <ImageWidget {...props} />
    <FrontFaceImageWidget {...props} />
  </Fragment>
);

export default V2;
