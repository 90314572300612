import styled from "styled-components";
import { THEME_COLORS } from "variables/theme";
import { FONT_SETS } from "variables/typography";

export const TopBar = styled.div`
  background-color: ${THEME_COLORS.primary};
  display: flex;
  height: 66px;
`;

export const Logo = styled.img`
  margin-left: 26px;
`;

export const Header = styled.h1`
  ${FONT_SETS.pageHeader};
  margin: 30px 0 !important;
`;
