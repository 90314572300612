import React, { ReactElement } from "react";
import { DualAxes, G2 } from '@ant-design/charts';
import { CREDITWORKS_COLORS } from "variables/theme";

export type DataProps = {
  costToIndustry: number,
  currentPurchases: number,
  dso: number,
  dueThisMonth: number,
  month: number,
  overdue1Month: number,
  overdue2Months: number,
  overdue3Months: number,
  retentions: number,
  totalDebt: number,
  unallocated: number,
  vendorCount: number,
  year: number,
}

function Graph(props): ReactElement {
  const { data } = props;
  const registerTheme = G2.registerTheme;

  const sortedCreditWorksColors = [
    CREDITWORKS_COLORS.redOrange,
    CREDITWORKS_COLORS.yellowOrange,
    CREDITWORKS_COLORS.gorse,
    CREDITWORKS_COLORS.pastelGreen,
    CREDITWORKS_COLORS.camerone,
    CREDITWORKS_COLORS.blue,
  ];

  registerTheme('credit-works', {
    colors10: sortedCreditWorksColors,
  });

  const keyFields = [
    'overdue3Months',
    'overdue2Months',
    'overdue1Month',
    'dueThisMonth',
    'currentPurchases',
  ];

  const fieldLabels = {
    overdue3Months: '+90',
    overdue2Months: '+60',
    overdue1Month: '+30',
    dueThisMonth: 'DueNow',
    currentPurchases: 'Current',
    dso: 'DSO',
  }

  const dueData = data.map((dataObj: DataProps) => keyFields.map(key => ({
    type: key,
    value: dataObj[key],
    time: `${dataObj.year}-${dataObj.month}`,
  })));

  const dsoData = data.map(({
    dso,
    month,
    year,
  }: DataProps) => ({
    type: 'dso',
    dso: dso,
    time: `${year}-${month}`,
  }))

  const config = {
    data: [dueData.flat(), dsoData],
    xField: 'time',
    yField: ['value', 'dso'],
    geometryOptions: [
      {
        geometry: 'column',
        isStack: true,
        seriesField: 'type',
      },
      {
        geometry: 'line',
      }
    ],
    theme: 'credit-works',
  };

  return (
    <DualAxes
      {...config}
      legend={{
        reversed: true,
        position: 'bottom',
        itemName: {
          formatter: function formatter(text) {
            return fieldLabels[text];
          },
        },
      }}
      yAxis={{
        value: {
          title: {
            text: 'Total Debt',
            style: {
              fontSize: 18,
            },
          },
        },
        dso: {
          title: {
            text: 'DSO',
            style: {
              fontSize: 18,
            },
          }
        }
      }}
    />
  );
};

export default Graph;
