import {
  COB_FINANCIALS_COMPONENT_COMPLETE,
  COB_FINANCIALS_LOAD_ANSWERS,
  COB_FINANCIALS_REMOVE_ADDITIONAL_FIELDS,
  COB_FINANCIALS_RESET_DATA,
  COB_FINANCIALS_SET_ADDITIONAL_FIELDS,
  COB_FINANCIALS_SET_ANSWER,
  COB_FINANCIALS_SET_FILE,
} from "../constants/financials";

import extractAttachmentUrl from "utils/extractAttachmentUrl";
import get from "lodash.get";

export function setAdditionalFields(name, value) {
  return dispatch => {
    dispatch({
      payload: {
        name,
        value,
      },
      type: COB_FINANCIALS_SET_ADDITIONAL_FIELDS,
    });

    return Promise.resolve();
  };
}

export function removeAdditonalFiels(name, value) {
  return dispatch => {
    dispatch({
      payload: {
        name,
        value
      },
      type: COB_FINANCIALS_REMOVE_ADDITIONAL_FIELDS
    })

    return Promise.resolve();
  }
}

export function setAnswer(name, value) {
  return dispatch => {
    dispatch({
      payload: {
        name,
        value,
      },
      type: COB_FINANCIALS_SET_ANSWER,
    });

    return Promise.resolve();
  };
}

export function financialsComponentComplete(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state === true) {
      meta = {
        mixpanel: {
          event: "Consumer onboarding",
          props: {
            Component: component,
            Section: "financials",
            distinct_id: getState().current_user.data.data.id,
          },
        },
      };
    }

    dispatch({
      meta,
      payload: {
        component,
        state,
      },
      type: COB_FINANCIALS_COMPONENT_COMPLETE,
    });
  };
}

export function setFinancialsFile(data) {
  return {
    payload: data,
    type: COB_FINANCIALS_SET_FILE,
  };
}

export function resetFinancials() {
  return dispatch => {
    dispatch({
      type: COB_FINANCIALS_RESET_DATA,
    });
  };
}

export function loadFinancialsAnswers(params) {
  const file = extractAttachmentUrl(get(params, "attributes.file"), "file");
  const data = {
    addon_rule_id: params.attributes.addon_rule_id,
    answers: params.attributes.answers,
    file,
    id: params.id,
  };

  return dispatch => {
    dispatch({
      payload: data,
      type: COB_FINANCIALS_LOAD_ANSWERS,
    });
  };
}
