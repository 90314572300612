import PropTypes from "prop-types"
import React from "react"

import { TextAreaElement } from "./styles";

const TextAreaBulma = ({
  customError,
  defaultValue,
  disabled,
  disableThemeOverride,
  error,
  name,
  placeholder,
  required,
  rows,
  inputRef,
}) => (
  <div className="field">
    <div className="control">
      <TextAreaElement
        className="textarea"
        defaultValue={defaultValue}
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        required={required}
        ref={inputRef}
        rows={rows}
      />
      { error && <p className="help is-danger">{error}</p> }
      { customError }
    </div>
  </div>
)

TextAreaBulma.propTypes = {
  defaultValue: PropTypes.string,
  disableThemeOverride: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  rows: PropTypes.number,
}

TextAreaBulma.defaultProps = {
  defaultValue: null,
  disableThemeOverride: false,
  disabled: false,
  error: null,
  rows: 3,
}

export default TextAreaBulma

