import React from "react";

import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import CloseButton from "modules/shared/components/inputs/CloseButton";

/* Import CSS */
import styles from "./css/PdfReaderModal.css";

var PdfReaderModal = createClass({
  getInitialState: function() {
    return {
      isLoading: true,
    };
  },

  loadListener: function() {
    this.setState({
      isLoading: false,
    });
    if (this.props.onScrollToBottom) {
      this.props.onScrollToBottom();
    }
  },

  render: function() {
    const { url, title, dismissHandler } = this.props;

    return (
      <div className={styles.modal}>
        {this.state.isLoading && <SimpleLoader />}
        <div className={styles.header}>
          <span className={styles.button} onClick={dismissHandler}></span>
          {title}
        </div>
        <div className={styles.fade}>
          <div className={styles.content}>
            <div className={styles.body}>
              <div ref="pdfReader" className={styles.wrapper}>
                <iframe
                  src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
                  onLoad={this.loadListener}
                  style={{
                    height: document.documentElement.clientHeight - 60 + "px",
                  }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

export default PdfReaderModal;
