import { loadAddons, setCurrentAddonRuleset } from "modules/addons/actions";
import CardsDetails from "modules/addons/cards/components/CardsDetails";
import Button from "modules/shared/components/inputs/Button";
import VideoModal from "modules/shared/components/widgets/interactive/VideoModal";
import { isFeatureEditEnabled } from "modules/shared/helpers/headquarterDetect";
import React, { Component } from "react";
import { connect } from "react-redux";

import { sortedAddonList } from "./../helpers";
import AddonSkeleton from "./AddonSkeleton";
import AddOnsListItem from "./AddOnsListItem";
import commonStyles from "./css/CommonAddOns.css";

const ADDON_NAME = "cards";

class CardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewing: false,
      showDetailPage: false,
    };
    this.handleDiscard = this.handleDiscard.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(loadAddons(ADDON_NAME));
  }

  onCreateCardAddon(serialNumber, version) {
    const { dispatch } = this.props;

    if (version) {
      dispatch(setCurrentAddonRuleset(serialNumber, version, ADDON_NAME));
    }

    this.setState({ showDetailPage: true });
  }

  onViewAddon(serialNumber, version) {
    const { dispatch } = this.props;

    if (version) {
      dispatch(setCurrentAddonRuleset(serialNumber, version, ADDON_NAME));
    }

    this.setState({
      isViewing: true,
      showDetailPage: true,
    });
  }

  handleDiscard() {
    this.setState({ isViewing: false, showDetailPage: false });
  }

  createAddonButton() {
    if (!isFeatureEditEnabled("Cards")) {
      return null;
    }

    return (
      <Button
        style={{ fontWeight: "700" }}
        disabled={false}
        text="+ Add"
        handleClick={this.onCreateCardAddon.bind(this)}
      />
    );
  }

  renderList() {
    const { cardIdToUpdate, cards } = this.props;
    const items = cards.map((card, index) => {
      const attributes = card.attributes;

      return (
        <AddOnsListItem
          key={`${attributes.addon_module_name}_${index}`}
          addonType={ADDON_NAME}
          addonVersions={attributes.history_version.data}
          currentAddonData={card}
          onEdit={(serialNumber, currentConfig) => {
            this.onCreateCardAddon(serialNumber, currentConfig);
          }}
          viewHistory={(serialNumber, version) => {
            this.onViewAddon(serialNumber, version);
          }}
          updating={card.id === cardIdToUpdate}
          disabled={!isFeatureEditEnabled("Cards")}
        />
      );
    });

    return (
      <div>
        <div className={commonStyles.header_container}>
          <span>
            <span className={commonStyles.header}>Set your rules here</span>
            <VideoModal videoName={"cards"} />
          </span>
          {this.createAddonButton()}
        </div>
        <div className={commonStyles.container}>
          <ul className={commonStyles.list}>{items}</ul>
        </div>
      </div>
    );
  }

  renderDetails() {
    return (
      <CardsDetails
        handleDiscard={this.handleDiscard}
        readOnly={this.state.isViewing || !isFeatureEditEnabled("Cards")}
      />
    );
  }

  render() {
    const { showDetailPage } = this.state;
    const contents = showDetailPage ? this.renderDetails() : this.renderList();

    if (this.props.isLoading) {
      return <AddonSkeleton />;
    }

    return (
      <div className={commonStyles.row}>
        <div className={commonStyles.block_wide}>{contents}</div>
      </div>
    );
  }
}

export default connect(state => {
  const addOns = state.add_ons;
  const cards = sortedAddonList(state, ADDON_NAME);

  return {
    cardIdToUpdate: addOns.cardIdToUpdate,
    cards,
    isLoading: addOns.isCardsLoading,
  };
})(CardList);
