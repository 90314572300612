import Button from "modules/shared/components/inputs/Button";
import EnquiryDetails from "./EnquiryDetails";
import ErrorBoundary from "modules/shared/components/containers/ErrorBoundary";
import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import logo from "images/centrix-logo.png";
import sections from "./sections";
import styles from "./centrix.css";

class CentrixComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      isAllExpanded: false,
    };
  }

  _expandAll() {
    this.setState({ isAllExpanded: !this.state.isAllExpanded });
  }

  _expandAllButton() {
    let message = "+ expand all";
    if (this.state.isAllExpanded) message = "- collapse all";

    return (
      <Button
        small
        text={message}
        style={{ fontSize: "10px", margin: "1em 0 1em 66px" }}
        handleClick={this._expandAll.bind(this)}
      />
    );
  }

  _statusMessage() {
    const { data } = this.props;

    let statusMessages = data.status_messages || {},
      statusMessage = statusMessages.status_message || [],
      errorMessage = [];

    if (statusMessage.length < 1) return null;

    statusMessage.map((m, index) => {
      if (m.message_text && m.message_type == "Error") {
        errorMessage.push(<div key={index}>{m.message_text}</div>);
      }
    });

    return (
      <div>
        <ReviewContainer
          content_class="content_wide"
          css_class="block_noborder"
        >
          {errorMessage}
          <p>
            Contact <a href="mailto:support@1centre.com">support@1centre.com</a>{" "}
            or live chat with us.
          </p>
        </ReviewContainer>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const { isAllExpanded } = this.state;

    let statusMessages = data.status_messages || {},
      statusMessage = statusMessages.status_message || [],
      isError = statusMessage.find(
        m => m.message_text && m.message_type == "Error",
      );

    return (
      <div>
        {isError && (
          <div>
            <div className={styles.header_container}>
              <img src={logo} alt="Centrix" className={styles.logo} />
            </div>
            {this._statusMessage()}
          </div>
        )}
        {!isError && (
          <div>
            <div className={styles.header_container}>
              <ErrorBoundary>
                <EnquiryDetails data={data} />
              </ErrorBoundary>
              <img src={logo} alt="Centrix" className={styles.logo} />
            </div>
            {this._expandAllButton()}
            <div>
              {sections.map((section, i) => (
                <ErrorBoundary key={i}>
                  <ReviewContainer
                    subHeading={section.title}
                    content_class="content_wide"
                    css_class="block_noborder"
                    collapsible={true}
                    isAllExpanded={isAllExpanded}
                    fontSize="medium"
                  >
                    {React.createElement(section.component, { data })}
                  </ReviewContainer>
                </ErrorBoundary>
              ))}
            </div>
            {this._expandAllButton()}
          </div>
        )}
      </div>
    );
  }
}

export default CentrixComponent;
