import { TERMS_AND_CONDITIONS } from "constants";
import {
  readyForSupplierTerms,
  updateAuthorisation,
} from "modules/consumer-onboarding/actions/review";
import Button from "modules/shared/components/inputs/Button";
import Radiobox from "modules/shared/components/inputs/Radiobox";
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import TextInput from "modules/shared/components/inputs/TextInput";
import PopperTooltip from "modules/shared/components/widgets/interactive/PopperToolTip";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import PdfReader from "modules/shared/components/widgets/static/pdfReader";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { customTermsUrl } from "utils/extractAttachmentUrl";
import flattenArray from "utils/flattenArray";
import isPresent from "utils/isPresent";
import capitalize from "lodash.capitalize";
import styles from "./css/Review.css";
import { isSanitizedStringEqual } from "utils/sanitizeName";
import * as yup from "yup";
import { isTokenised } from "../utils";

const CHECKBOX_TERMS_BY_SIGNATORY = {
  antiFraud: ["antiFraudIdHoldInformation"],
  guarantor: ["guarantor"],
  paperless: ["paperless", "paperlessDiscloseInformation"],
  supplier: ["supplier", "supplierCorrectInformation", "supplierLegalAge"],
};

const VALIDATION_SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .required("You must enter your first name")
    .test("compare-first-name", "Incorrect first name", function test(
      firstName,
    ) {
      if (isPresent(firstName)) {
        return isSanitizedStringEqual(
          this.options.context.referenceFirstName,
          firstName,
        );
      }

      return true;
    }),
  lastName: yup
    .string()
    .required("You must enter your last name")
    .test("compare-first-name", "Incorrect last name", function test(lastName) {
      if (isPresent(lastName)) {
        return isSanitizedStringEqual(
          this.options.context.referenceLastName,
          lastName,
        );
      }

      return true;
    }),
  guarantorApproved: yup
    .string()
    .nullable()
    .test(
      "guarantor-actioned",
      "You must choose whether to guarantee this account or not.",
      function test(guarantorAction) {
        if (!this.options.context.isGuarantor) {
          return true;
        }

        return isPresent(guarantorAction);
      },
    ),
});

const ReviewSupplierTerms = createClass({
  agree() {
    const { application, combine, dispatch, authorisation_id } = this.props;
    const { guarantorApproved } = this.state;
    if (this.completed()) {
      if (combine) {
        const attributes = {
          cardholder_approved: true,
          guarantor_approved: guarantorApproved === "Yes",
          payment_approved: true,
          signatory_approved: true,
        };

        dispatch(
          updateAuthorisation(authorisation_id, attributes, () => {
            browserHistory.push(
              `/register/consumer/${application.id}/review/terms`,
            );
          }),
        );
      } else {
        browserHistory.push(
          `/register/consumer/${application.id}/review/terms`,
        );
      }
    } else {
      this.checkValids();
    }
  },

  checkValid(id, value) {
    const {
      applicant_guarantor_first_name: referenceFirstName,
      applicant_guarantor_last_name: referenceLastName,
      applicant_guarantor: isGuarantor,
    } = this.props;
    const { errors } = this.state;

    try {
      VALIDATION_SCHEMA.validateSyncAt(
        id,
        { [id]: value },
        { context: { isGuarantor, referenceFirstName, referenceLastName } },
      );

      this.setState({ errors: { ...errors, [id]: "" } });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        this.setState({ errors: { ...errors, [error.path]: error.message } });
      }
    }
  },

  checkValids() {
    if (!this.state.hasScrolled) {
      this.setState({ isTsCsTooltipActive: true });
    }

    if (!this.state.hasCheckedAllBoxes) {
      this.setState({ showBoxesHint: true });
    }

    const {
      first_name: firstName,
      guarantorApproved,
      last_name: lastName,
    } = this.state;
    const {
      applicant_guarantor: isGuarantor,
      applicant_guarantor_first_name: referenceFirstName,
      applicant_guarantor_last_name: referenceLastName,
    } = this.props;

    try {
      VALIDATION_SCHEMA.validateSync(
        {
          firstName,
          guarantorApproved,
          lastName,
        },
        {
          abortEarly: false,
          context: {
            isGuarantor,
            referenceFirstName,
            referenceLastName,
          },
        },
      );
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const { errors } = this.state;

        for (const innerError of error.inner) {
          errors[innerError.path] = innerError.message;
        }

        this.setState({ errors });
      }
    }
  },

  completed() {
    const { applicant_guarantor, combine } = this.props;
    const {
      hasScrolled,
      first_name,
      last_name,
      errors,
      guarantorApproved,
      hasCheckedAllBoxes,
    } = this.state;

    if (!hasScrolled) {
      return false;
    }

    if (!hasCheckedAllBoxes) {
      return false;
    }

    if (applicant_guarantor && !guarantorApproved) {
      return false;
    }

    return (
      first_name && last_name && !errors["firstName"] && !errors["lastName"]
    );
  },

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(readyForSupplierTerms());
  },

  getInitialState() {
    return {
      antiFraudIdHoldInformation: false,
      errors: {},
      first_name: "",
      guarantor: false,
      guarantorApproved: null,
      hasCheckedAllBoxes: false,
      hasScrolled: true,
      isTsCsTooltipActive: false,
      last_name: "",
      paperless: false,
      paperlessDiscloseInformation: false,
      showBoxesHint: false,
      supplier: false,
      supplierCorrectInformation: false,
      supplierLegalAge: false,
    };
  },

  handleBlur(event) {
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target.id, event.target.value, event.target);
    }
  },

  handleChange(event) {
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target.id, event.target.value);

      switch (event.target.id) {
        case "firstName":
          this.setState({ first_name: event.target.value });
          break;
        case "lastName":
          this.setState({ last_name: event.target.value });
          break;
      }
    }
  },

  handleGuaranteed(event) {
    this.setState({ guarantorApproved: event.target.value });
    this.checkValid("guarantorApproved", event.target.value);
  },

  hasCustomGuarantorTerms() {
    const { application } = this.props;
    return (
      application.attributes.uses_custom_guarantor_terms &&
      customTermsUrl(application.attributes.custom_guarantor_terms).url
    );
  },

  hasCustomPaperlessTerms() {
    const { application } = this.props;
    return (
      application.attributes.uses_custom_paperless_terms &&
      customTermsUrl(application.attributes.custom_paperless_terms).url
    );
  },

  hasCustomSupplierTerms() {
    const { application } = this.props;
    return (
      application.attributes.uses_custom_supplier_terms &&
      customTermsUrl(application.attributes.custom_terms).url
    );
  },

  onScrollToBottom() {
    if (!this.state.hasScrolled) {
      this.setState({ hasScrolled: true });
    }

    if (this.state.isTsCsTooltipActive) {
      this.setState({ isTsCsTooltipActive: false });
    }
    setTimeout(this.checkValids, 100);
  },

  renderCheckBoxes() {
    return [
      ...this.renderSupplierCheckboxes(),
      ...this.renderGuarantorCheckboxes(),
      ...this.renderPaperlessCheckboxes(),
      ...this.renderAntiFraudCheckboxes(),
    ];
  },

  checkBox(name) {
    if (this.validateAllBoxes(name, !this.state[name])) {
      this.setState({ hasCheckedAllBoxes: true });
      this.setState({ showBoxesHint: false });
    } else {
      this.setState({ hasCheckedAllBoxes: false });
    }
    this.setState({ [name]: !this.state[name] });
  },

  renderGuarantorCheckboxes() {
    if (this.props.termList.includes("guarantor")) {
      return [this.renderGuarantorTermsCheckbox()];
    }

    return [];
  },

  isSignatoryTermsAccepted(signatoryTerm, boxName, value) {
    if (!this.props.termList.includes(signatoryTerm)) {
      return [];
    }

    return CHECKBOX_TERMS_BY_SIGNATORY[signatoryTerm].map(checkbox =>
      checkbox === boxName ? value : this.state[checkbox],
    );
  },

  renderGuarantorTermsCheckbox() {
    const supplierName = this.props.application.attributes.supplier_name;
    const label = `I have read and understood the guarantor T&C’s and I
                   acknowledge that ${supplierName} recommend that I obtain
                   independent legal advice as to the effect of this guarantee
                   and my potential liability as guarantor, and I confirm that
                   I have elected not to obtain such independent advice and
                   have agreed to provide the guarantee in favour of the
                   applicant.`;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, "guarantor")}
      >
        <SquareCheckbox label={label} checked={this.state.guarantor} />
      </div>
    );
  },

  renderAcknowledge() {
    const {
      applicant_guarantor,
      applicantIsCardholder,
      applicantIsDirectDebit,
      application,
    } = this.props;
    const supplierName = application.attributes.supplier_name;
    const items = [];
    items.push(
      <li>
        You have read and understood this application and the information
        supplied by you is true and complete.
      </li>,
    );
    if (applicant_guarantor) {
      items.push(
        <li>
          In electronically signing the guarantee, you should assume that this
          is a liability you will be called upon to honor, and you should
          satisfy yourself that you have the financial means to meet this
          liability, and are willing to do so. If you are not comfortable with
          that assumption, you should not consent to the guarantee.
        </li>,
      );
    }
    if (applicantIsCardholder) {
      items.push(
        <li>
          Once you use the {supplierName} card you are bound by the conditions
          of use set out in the {supplierName} Terms and Conditions (as amended
          form time to time).
        </li>,
      );
      items.push(
        <li>
          In the event the applicant does not pay for the purchase incurred by
          the card signatory using the {supplierName} card, the card signatory
          shall pay {supplierName} for such purchase.
        </li>,
      );
    }
    if (applicantIsDirectDebit) {
      items.push(
        <li>
          I authorise {supplierName}, until further notice in writing to debit
          my/our account.
        </li>,
      );
    }
    return (
      <div className={`${styles.row} mt-3`}>
        <div className="mb-3">
          By signing this section you confirm and acknowledge that:
        </div>
        <ul>{items}</ul>
      </div>
    );
  },

  renderAntiFraudCheckboxes() {
    if (this.props.termList.includes("antiFraud")) {
      return [
        this.renderAntiFraudIdHoldInformation(),
      ];
    }

    return [];
  },

  renderPaperlessCheckboxes() {
    if (this.props.termList.includes("paperless")) {
      return [
        this.renderPaperlessDisclaimer(),
        this.renderPaperlessDiscloseInformation(),
      ];
    }

    return [];
  },

  guarantorTerms() {
    const { applicant_guarantor, application, supplier } = this.props;

    if (!applicant_guarantor) {
      return;
    }

    if (this.hasCustomGuarantorTerms()) {
      return customTermsUrl(application.attributes.custom_guarantor_terms).url;
    }

    return TERMS_AND_CONDITIONS[supplier.attributes.region].guarantor;
  },

  renderSupplierCheckboxes() {
    if (this.props.termList.includes("supplier")) {
      return [
        this.renderSupplierTermsCheckbox(),
        this.renderSupplierLegalAgeDeclarationCheckbox(),
        this.renderSupplierCorrectInformationCheckbox(),
      ];
    }

    return [];
  },

  paperlessTerms() {
    const { applicantIsDirectDebit, application, supplier } = this.props;

    if (!applicantIsDirectDebit) {
      return;
    }

    if (this.hasCustomPaperlessTerms()) {
      return customTermsUrl(application.attributes.custom_paperless_terms).url;
    }

    return TERMS_AND_CONDITIONS[supplier.attributes.region].paperless;
  },

  renderSupplierLegalAgeDeclarationCheckbox() {
    const label = "I am not less than 18 years of age";

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, "supplierLegalAge")}
      >
        <SquareCheckbox label={label} checked={this.state.supplierLegalAge} />
      </div>
    );
  },

  render() {
    const { applicant_guarantor, combine, submitting, title } = this.props;

    const {
      errors,
      first_name,
      guarantorApproved,
      isTsCsTooltipActive,
      last_name,
    } = this.state;

    const renderAgreeRadio = [];

    let modal;
    let terms;
    let terms_modal;
    let info;
    let no_top_border;

    const electronic_signature = (
      <div className={`${styles.row} mt-4`}>
        <div className="mb-4">
          If you agree please fill out your first and last name in the fields
          below and press confirm.
        </div>
        <div className={`${styles.inputs_container} mt-3`}>
          <div className={styles.input_container}>
            <TextInput
              id="firstName"
              name="firstName"
              error={errors["firstName"]}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="First name"
              value={first_name}
              required={true}
            />
          </div>
          <div className={styles.input_container}>
            <TextInput
              id="lastName"
              name="lastName"
              error={errors["lastName"]}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="Last name"
              value={last_name}
              required={true}
            />
          </div>
        </div>
      </div>
    );

    terms = this.renderPdfs();

    if (combine && applicant_guarantor) {
      renderAgreeRadio.push(
        <div className={styles.row}>
          <div className={styles.guaranteed_container}>
            <Radiobox
              id="g-radio"
              name="g-radio"
              error={errors["guarantorApproved"] && " "}
              handleChange={this.handleGuaranteed}
              label="Do you agree to guarantee this application?"
              radioList={["Yes", "No"]}
            />
          </div>
        </div>,
      );
    }

    return (
      <div>
        <section className={styles.section}>
          <div className={styles.row}>
            <div className={styles.panel}>
              <PanelTitle text={title} />
              <h3 className={styles.info}>{info}</h3>
            </div>
          </div>
          <div className={styles.row_terms}>
            <div className={styles.terms} style={no_top_border}>
              {terms}
            </div>
          </div>
          <div className={styles.row_controls}>
            <PopperTooltip
              title={"Please agree to all the declarations."}
              placement="bottom-start"
              open={this.state.showBoxesHint}
              noArrow={true}
            >
              <div className={styles.checkbox_container}>
                {this.renderCheckBoxes()}
              </div>
            </PopperTooltip>

            {renderAgreeRadio}

            {this.renderAcknowledge()}

            {electronic_signature}

            <div className={styles.buttons}>
              <Button
                text="confirm"
                loading_text="submitting"
                disableOnLoading={true}
                loading={submitting}
                handleClick={this.agree}
              />
              {isTsCsTooltipActive && (
                <ToolTip
                  tip_name="ReviewSupplierTermsScroll"
                  css_style="review_supplier_terms_scroll"
                />
              )}
            </div>
          </div>
        </section>
        {modal}
        {terms_modal}
      </div>
    );
  },

  // This function returns an array of boolean values wherein all items in the
  // array should be true if the user accepted all the terms for a specific
  // signatory, i.e. applicant, guarantor or paperless
  renderSupplierTermsCheckbox() {
    const supplierName = this.props.application.attributes.supplier_name;
    const label = `I have read ${supplierName} T&C's and will abide by the rules
                   of ${supplierName}`;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, "supplier")}
      >
        <SquareCheckbox label={label} checked={this.state.supplier} />
      </div>
    );
  },

  renderAntiFraudIdHoldInformation() {
    const supplierName = this.props.supplierName;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, "antiFraudIdHoldInformation")}
      >
        <SquareCheckbox
          label={`I confirm that ${supplierName} has the right to hold my ID documents on file until my identity has been confirmed`}
          checked={this.state.antiFraudIdHoldInformation}
        />
      </div>
    );
  },

  renderPaperlessDisclaimer() {
    const supplierName = this.props.application.attributes.supplier_name;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, "paperless")}
      >
        <SquareCheckbox
          label={`I have read and understood the terms and conditions of the direct debit authority for ${supplierName}`}
          checked={this.state.paperless}
        />
      </div>
    );
  },

  renderSupplierCorrectInformationCheckbox() {
    const supplierName = this.props.application.attributes.supplier_name;
    const label = `I confirm that the information supplied by me is true and
                   complete and may be relied upon by ${supplierName} and their
                   subsidiaries in considering this application`;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, "supplierCorrectInformation")}
      >
        <SquareCheckbox
          label={label}
          checked={this.state.supplierCorrectInformation}
        />
      </div>
    );
  },

  renderPaperlessDiscloseInformation() {
    const supplierName = this.props.application.attributes.supplier_name;

    return (
      <div
        className={styles.row}
        onClick={this.checkBox.bind(this, "paperlessDiscloseInformation")}
      >
        <SquareCheckbox
          label={`I understand that ${supplierName} will not use, store or disclose my information except as authorised by the terms and conditions`}
          checked={this.state.paperlessDiscloseInformation}
        />
      </div>
    );
  },

  renderPdfs() {
    const pdfs = [
      this.supplierTerms(),
      this.guarantorTerms(),
      this.paperlessTerms(),
    ].filter(pdf => isPresent(pdf));

    return <PdfReader urls={pdfs} onScrollToBottom={this.onScrollToBottom} />;
  },

  supplierTerms() {
    const { application, supplier } = this.props;

    if (this.hasCustomSupplierTerms()) {
      return customTermsUrl(application.attributes.custom_terms).url;
    }

    return TERMS_AND_CONDITIONS[supplier.attributes.region].supplier;
  },

  validateAllBoxes(boxName, value) {
    const { termList } = this.props;
    const isAllTermsAccepted = termList.map(term =>
      this.isSignatoryTermsAccepted(term, boxName, value),
    );

    return flattenArray(isAllTermsAccepted).every(isAccepted => isAccepted);
  },
});

const defaults = {
  title: "T&Cs",
};

module.exports = connect(state => {
  const guarantors = state.cob_guarantors;
  const applicant_guarantor = guarantors.form_values.find(
    g => g && g.is_applicant,
  );
  const applicantIsDirectDebit =
    state.cob_paperless.answers.applicantAuthorised;
  const applicantIsCardholder = state.cob_cards.cardholders.some(
    c => c.attributes && c.attributes.isApplicant,
  );
  const applicantSignatory = state.cob_section.current_people.find(
    p => (p.attributes || {}).is_applicant,
  );

  const termList = ["supplier"];
  if (applicant_guarantor) termList.push("guarantor");
  if (applicantIsDirectDebit) termList.push("paperless");

  if (isTokenised(state)) {
    termList.push("antiFraud");
  }

  const termTitleKeys = termList.filter(list => list !== 'antiFraud');

  return {
    applicantIsCardholder,
    applicantIsDirectDebit,
    applicant_guarantor,
    applicant_guarantor_first_name: applicant_guarantor
      ? applicant_guarantor.first_name
      : state.identity.first_name,
    applicant_guarantor_last_name: applicant_guarantor
      ? applicant_guarantor.last_name
      : state.identity.last_name,
    authorisation_id: state.cob_section.authorisation.id,
    combine:
      !!applicant_guarantor ||
      !!applicantSignatory ||
      !!applicantIsCardholder ||
      !!applicantIsDirectDebit,
    entity: state.cob_business,
    query_params: state.current_user.query_params,
    submitting: state.cob_review.submitting,
    supplier: state.cob_section.supplier,
    termList,
    title: `${capitalize(termTitleKeys.join(", "))} ${defaults.title}`,
  };
})(ReviewSupplierTerms);
