export function getBrowser(usr_ag) {
  let browser = "unknown";

  if (!usr_ag) usr_ag = navigator.userAgent;

  if (usr_ag.indexOf("Chrome") > -1) {
    browser = "Google Chrome";
  } else if (usr_ag.indexOf("Safari") > -1) {
    browser = "Apple Safari";
  } else if (usr_ag.indexOf("Opera") > -1) {
    browser = "Opera";
  } else if (usr_ag.indexOf("Firefox") > -1) {
    browser = "Mozilla Firefox";
  } else if (usr_ag.indexOf("MSIE") > -1 || usr_ag.indexOf("Trident") > -1) {
    browser = "Microsoft Internet Explorer";
  }

  return browser;
}
