import styled, { css } from 'styled-components';
import { COLORS } from 'variables/theme';

type WrapperProps = {
  active: boolean,
}

export const Actions = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;

  > * {
    cursor: pointer;
  }
`;

export const More = styled.button`
  border: 0;
  height: 2rem;
  padding: 0.5rem;
  width: 2rem;
`;

export const Handler = styled.div`
  align-items: center;
  background: var(--main-color);
  color: ${COLORS.white};
  cursor: grab;
  display: none;
  left: -1px;
  justify-content: center;
  height: 2rem;
  position: absolute;
  top: -2rem;
  width: 2rem;
  z-index: 1;
`;

const activeWrapper = `
  outline-color: var(--main-color);

  ${Actions} {
    display: flex;
  }

  ${Handler} {
    display: flex;
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  outline: 1px solid transparent;
  position: relative;

  ${props => props.active && css`${activeWrapper}`}

  &:hover {
    ${activeWrapper};
  }

  &:active {
    ${Handler} {
      cursor: grabbing;
    }
  }
`;
