import Button from "modules/shared/components/inputs/Button";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import FormBuilderComponentGridWrapper from "./FormBuilderComponentGridWrapper";
import MultiSelectDropdown from "modules/shared/components/widgets/interactive/MultiSelectDropdown";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";
import React from "react";
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import TextArea from "modules/shared/components/inputs/TextArea";
import TextInput from "modules/shared/components/inputs/TextInput";
import PopperTooltip from "modules/shared/components/widgets/interactive/PopperToolTip.js";
import styles from "./css/FormBuilder.css";
import BorderedTextField from "modules/shared/components/inputs/BorderedTextField";
import BorderedTextArea from "modules/shared/components/inputs/BorderedTextArea";

class FormBuilderComponentEditor extends React.Component {
  constructor(props) {
    super(props);
    const componentType = this.componentTypeOptions()[0];
    if (props.componentParams && props.componentParams.editing) {
      const { editing, ...rest } = props.componentParams;
      this.state = rest;
    } else {
      const componetSettings = this.defaultSetting(componentType);
      this.state = { component_type: componentType, ...componetSettings };
    }
  }

  defaultSetting(componentType) {
    const defaultComponetSettings = [];
    const settings = componentSettings[componentType];
    Object.keys(settings).forEach(key => {
      const valueInState = this.state && this.state[key];
      if (Array.isArray(settings[key])) {
        defaultComponetSettings[key] = valueInState || settings[key][0];
      } else {
        defaultComponetSettings[key] = valueInState || settings[key];
      }
    });
    return defaultComponetSettings;
  }

  componentTypeChange(componentType) {
    this.setState({
      component_type: componentType,
      ...this.defaultSetting(componentType),
    });
  }

  componentStateChange(key, value) {
    if (key === "mulit_select" && value === false) {
      this.setState({ main_option: false });
    }
    this.setState({ [key]: value });
  }

  componentTypeOptions() {
    const { module } = this.props;
    return componentTypeOptions[module] || componentTypeOptions.default;
  }

  submit() {
    const { onSubmit } = this.props;
    const config = {};
    const settings = componentSettings[this.state.component_type];
    if (this.valid()) {
      this.currentComponentFields().forEach(field => {
        config[field] = this.state[field] || settings[field];
      });
      onSubmit(config);
    }
  }

  currentComponentSettings() {
    const currentComponentType = this.state.component_type;
    const { extraFields = {} } = this.props;
    const defaultComponentSettings = componentSettings[currentComponentType];

    if (currentComponentType === "section_title") {
      delete extraFields['search_send_mandatory'];

      return {
        ...defaultComponentSettings,
        ...extraFields,
      };
    }

    return {
      ...defaultComponentSettings,
      ...extraFields,
    };
  }

  currentComponentFields() {
    return ["component_type"].concat(
      Object.keys(this.currentComponentSettings()),
    );
  }

  valid() {
    let result =
      !this.duplicateDescriptionWithExsiting(this.state.field_description) &&
      !this.duplicateDescriptionWithExsiting(
        `${this.state.field_description_1}-${this.state.field_description_2}`,
      ) &&
      !this.duplicateDescription();
    this.currentComponentFields().forEach(field => {
      result = result && this.state[field] !== "";
    });
    return result;
  }

  duplicateDescriptionWithExsiting(description) {
    const { existingDescriptions } = this.props;
    return existingDescriptions.includes(description);
  }

  duplicateDescription() {
    return (
      this.state.field_description_1 &&
      this.state.field_description_2 &&
      this.state.field_description_1 === this.state.field_description_2
    );
  }

  duplicateMessage(description) {
    if (this.duplicateDescriptionWithExsiting(description)) {
      return duplicateDescriptionWithExsitingError;
    }
    if (this.duplicateDescription()) {
      return duplicateDescriptionError;
    }
  }

  isCheckBoxes() {
    return this.state.component_type === "check_boxes";
  }

  showOptionHintText() {
    this.setState({ showOptionHintText: true });
  }

  hideOptionHintText() {
    this.setState({ showOptionHintText: false });
  }

  editorField(fieldName, options) {
    const { borderedStyle, formLabelOverrides } = this.props;
    const label = (formLabelOverrides && formLabelOverrides[fieldName]) || settingLabels[fieldName];
    const TextField = borderedStyle ? BorderedTextField : TextInput;
    const MultilineTextField = borderedStyle ? BorderedTextArea : TextArea;
    const Dropdown = borderedStyle ? MultiSelectDropdown : OptionsDropdown;

    switch (fieldName) {
      case "accepted_value":
      case "width":
      case "display":
        return (
          <Dropdown
            id={fieldName}
            key={fieldName}
            handleChange={target => {
              this.componentStateChange(fieldName, target.value);
            }}
            onChange={e => {
              this.componentStateChange(fieldName, e.value);
            }}
            label={label}
            value={this.state[fieldName]}
            required={true}
            options={options.map(option => {
              return { value: option, label: option };
            })}
            variant={borderedStyle ? "outlined" : "filled"}
            borderedStyle
          />
        );
      case "search_send_mandatory":
      case "search_send_applied":
      case "mandatory":
      case "mulit_select":
        if (fieldName === "search_send_mandatory" && !this.state['search_send_applied']) {
          return;
        }

        return (
          <div
            id={fieldName}
            key={fieldName}
            className={styles.full_width}
            onClick={() => {
              this.componentStateChange(fieldName, !this.state[fieldName]);
            }}
          >
            <SquareCheckbox label={label} checked={this.state[fieldName]} />
          </div>
        );
      case "indicator":
        return (
          this.props.showIndicatorOption && (
            <div
              id={fieldName}
              key={fieldName}
              className={styles.full_width}
              onClick={() => {
                this.componentStateChange(fieldName, !this.state[fieldName]);
              }}
            >
              <SquareCheckbox label={label} checked={this.state[fieldName]} />
            </div>
          )
        );
      case "main_option":
        return (
          (this.isCheckBoxes() || this.state.mulit_select) && (
            <div
              id={fieldName}
              key={fieldName}
              className={styles.full_width}
              onClick={() => {
                this.componentStateChange(fieldName, !this.state[fieldName]);
              }}
            >
              <SquareCheckbox label={label} checked={this.state[fieldName]} />
            </div>
          )
        );
      case "options":
        return (
          <PopperTooltip
            title={optionPlaceholder}
            placement="right"
            open={!!this.state.showOptionHintText}
            noArrow={true}
          >
            <MultilineTextField
              id={fieldName}
              key={fieldName}
              label={label}
              handleChange={event => {
                this.componentStateChange(fieldName, event.target.value);
              }}
              onChange={event => this.componentStateChange(fieldName, event.target.value)}
              placeholder={optionPlaceholder}
              value={this.state[fieldName]}
              required={true}
              handleSelect={this.showOptionHintText.bind(this)}
              handleBlur={this.hideOptionHintText.bind(this)}
            />
          </PopperTooltip>
        );
      case "field_description":
        return (
          <TextField
            id={fieldName}
            key={fieldName}
            label={label}
            onChange={event => {
              this.componentStateChange(fieldName, event.target.value);
            }}
            value={this.state[fieldName]}
            required={true}
            error={this.duplicateMessage(this.state[fieldName])}
          />
        );
      case "field_description_1":
      case "field_description_2":
        return (
          <TextField
            id={fieldName}
            key={fieldName}
            label={label}
            onChange={event => {
              this.componentStateChange(fieldName, event.target.value);
            }}
            value={this.state[fieldName]}
            required={true}
            error={this.duplicateMessage(
              `${this.state.field_description_1}-${this.state.field_description_2}`,
            )}
          />
        );
      default:
        return (
          <TextField
            id={fieldName}
            key={fieldName}
            label={label}
            onChange={event => {
              this.componentStateChange(fieldName, event.target.value);
            }}
            value={this.state[fieldName]}
            required={true}
          />
        );
    }
  }

  renderEditor() {
    const { borderedStyle, onCancel, showFormTitle = true } = this.props;
    const formParams = { ...this.state };
    const { component_type } = formParams;
    const elements = [];

    // Preview window is too narrow
    // force setting width to full
    if (component_type === "check_boxes") {
      formParams['width'] = 'full';
    }

    const formattedComponentOptions = this.componentTypeOptions().map(
      componentType => {
        return {
          label: componentLabelsAndDescriptions[componentType].label,
          secondary_text:
            componentLabelsAndDescriptions[componentType].description,
          value: componentType,
        };
      },
    );

    const attributes = this.currentComponentSettings();
    Object.keys(attributes).forEach(key => {
      elements.push(this.editorField(key, attributes[key]));
    });

    return (
      <div className={styles.component_editor_panel}>
        <div className={styles.row}>
          <CloseButton
            handleClick={() => {
              onCancel();
            }}
            css_class="float_right_button"
          />
        </div>
        <div className={styles.component_editor_panel_content}>
          <div className={styles.editor_column}>
            <div className={styles.column_title}>
              { showFormTitle ? `Additional questions settings` : ' ' }
            </div>
            {formattedComponentOptions.length > 1 && (
              <MultiSelectDropdown
                id="component_type"
                key="component_typ"
                onChange={target => {
                  this.componentTypeChange(target.target ? target.target.value : target.value);
                }}
                label="Pick question type"
                value={
                  componentLabelsAndDescriptions[this.state.component_type]
                    .label
                }
                required={true}
                options={formattedComponentOptions}
                borderedStyle={borderedStyle}
              />
            )}
            {elements}
          </div>
          <div className={styles.preview_column}>
            <div className={styles.column_title}>
              { showFormTitle ? `Preview` : ' '}
            </div>
            <div className={styles.preview_canvas}>
              <div className={styles.preview_content}>
                <FormBuilderComponentGridWrapper
                  formParams={formParams}
                  preview
                  borderedStyle={borderedStyle}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.full_width}>
            <div className={styles.button}>
              <Button
                handleClick={this.submit.bind(this)}
                disabled={!this.valid()}
                text="Submit"
                css_style="button_float_right"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderEditor();
  }
}

const optionPlaceholder =
  "use '|' to separate choices e.g.\r\n\
choice1|choice2|choice3\r\n\
\r\n\
use '()' to includes description e.g.\r\n\
choice1(description1)|choice2(description2)\r\n\
\r\n\
use 'return' to separate categories\r\n\
category1:choice1|choice2\r\n\
category2:choice1|choice2";

const componentSettings = {
  section_title: { field_description: "" },
  short_question: {
    accepted_value: ["text", "number", "amount", "email", "date"],
    width: ["full", "half", "quarter"],
    field_description: "",
    mandatory: false,
  },
  long_question: {
    field_description: "",
    mandatory: false,
  },
  check_boxes: {
    width: ["full", "half"],
    display: ["column", "row"],
    field_description: "",
    options: "",
    mandatory: false,
    main_option: false,
  },
  dropdown_menu: {
    width: ["full", "half"],
    field_description: "",
    options: "",
    mandatory: false,
    mulit_select: false,
    main_option: false,
  },
  pair_question: {
    field_description_1: "",
    field_description_2: "",
    mandatory: false,
    indicator: false,
  },
};

const componentLabelsAndDescriptions = {
  check_boxes: {
    description: "Give your customers options to choose from.",
    label: "Multichoice row layout",
  },
  dropdown_menu: {
    description: "Better suited for longer list options, condensed view.",
    label: "Dropdown layout",
  },
  long_question: {
    description: "Good for long answers where free format is required.",
    label: "Long question",
  },
  pair_question: {
    description: "",
    label: "Pair question",
  },
  section_title: {
    description: "Group your questions in themes eg: marketing",
    label: "Section title",
  },
  short_question: {
    description:
      "Good for short answers, like contacts for accountants or lawyers.",
    label: "Short question",
  },
};

const settingLabels = {
  accepted_value: "Value type",
  field_description: "Type your question/section name here",
  field_description_1: "Field description",
  field_description_2: "Value description",
  indicator: "Yes, include in surplus calculation",
  main_option: "Allow main option?",
  mandatory: "Mandatory?",
  search_send_mandatory: "Make this question mandatory before sending application",
  search_send_applied: "Apply this question before sending application",
  mulit_select: "Allow multi-select?",
  options: "Type your options here",
  width: "Width",
  display: "Display",
};

const componentTypeOptions = {
  default: [
    "section_title",
    "short_question",
    "long_question",
    "check_boxes",
    "dropdown_menu",
  ],
  financials: ["pair_question"],
};

const duplicateDescriptionWithExsitingError =
  "This description is already existed, please use another one.";
const duplicateDescriptionError =
  "Field description and value description can not be the same.";

export default FormBuilderComponentEditor;
