import React, { Component } from "react";

import SignatureTerms from "../../shared/signature/SignatureTerms";
import SuppliersStandardTermsAndConditions from "modules/shared/components/text/SuppliersStandardTermsAndConditions";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import { isMobile } from "modules/shared/helpers/mobileDetect";

class Terms extends Component {
  constructor() {
    super();
    this.state = {
      hasScrolled: true,
      isComplete: false,
      isScrollToTermsTooltipVisible: false,
    };
  }

  onScrollToBottom() {
    this.setState({ hasScrolled: true, isScrollToTermsTooltipVisible: false });
  }

  onShowScrollToTermsTooltip() {
    this.setState({ isScrollToTermsTooltipVisible: true });
  }

  onConfirm() {
    browserHistory.push({ pathname: "/cardholder/complete" });
  }

  termsAndConditions() {
    // TODO: Replace with actual value
    return (
      <SuppliersStandardTermsAndConditions
        noScroll={isMobile()}
        onScrollToBottom={this.onScrollToBottom.bind(this)}
        css_class="side_padding"
      />
    );
  }

  render() {
    const { cardholder } = this.props;

    return (
      <SignatureTerms
        cardholder={cardholder}
        onConfirm={this.onConfirm.bind(this)}
        onShowScrollToTermsTooltip={this.onShowScrollToTermsTooltip.bind(this)}
        hasScrolled={this.state.hasScrolled}
        isScrollToTermsTooltipVisible={this.state.isScrollToTermsTooltipVisible}
        supplierLogo=""
        supplierTradingName="ACME Corp"
        termsAndConditions={this.termsAndConditions()}
      />
    );
  }
}

export default connect((state, ownProps) => {
  // TODO: Replace with actual value
  return {
    cardholder: {
      firstName: "Joe",
      lastName: "Blogs",
    },
  };
})(Terms);
