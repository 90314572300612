import { amountTypeValue, countTypeValue } from "./helpers/summary";

import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { displayEmpty } from "utils/formatting";
import { enquiryResponseData } from "./helpers/reportHelpers";
import { organisationStatusType } from "./helpers/codeTable";
import { stringToSimpleDate } from "utils/dateFormatter";
import styles from "../../../css/CommonReviewBusiness.css";

var BusinessIdentity = createClass({
  _principalPlaceOfBusiness(address) {
    if (address === null || address === undefined) return displayEmpty("");

    let addressComponents = [
      address.property,
      address.unit_number,
      address.street_number,
      address.street_name,
      address.street_type,
      address.suburb,
      address.state,
      address.postcode,
    ].filter(field => {
      return field !== undefined && field !== null && field !== "";
    });

    return addressComponents.join(" ");
  },

  _serviceOfNoticeAddress(address) {
    if (address === null || address === undefined) return displayEmpty("");

    let addressComponents = [
      address.street_number,
      address.street_name,
      address.street_type,
      address.suburb,
      address.state,
      address.postcode,
      address.country,
    ].filter(field => {
      return field !== undefined && field !== null && field !== "";
    });

    return addressComponents.join(" ");
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      businessIdentity = response.business_identity;

    return (
      <ReviewContainer
        subHeading="Business Identity"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Business Registration Number</th>
                <td>
                  {displayEmpty(businessIdentity.australian_company_number)}
                </td>
                <th>Principal Place of Business</th>
                <td>
                  {this._principalPlaceOfBusiness(
                    businessIdentity.principal_business_address,
                  )}
                </td>
              </tr>
              <tr>
                <th>Registered State</th>
                <td>
                  {displayEmpty(
                    (businessIdentity.principal_place_of_business || {}).state,
                  )}
                </td>
                <th>Start Date</th>
                <td>
                  {stringToSimpleDate(
                    (businessIdentity.principal_business_address || {})
                      .address_start_date,
                  )}
                </td>
              </tr>
              <tr>
                <th>ABN</th>
                <td>
                  {displayEmpty(businessIdentity.australian_business_number)}
                </td>
                <th>Preferred Service of Notice Address</th>
                <td>
                  {this._serviceOfNoticeAddress(
                    businessIdentity.service_of_notice_address,
                  )}
                </td>
              </tr>
              <tr>
                <th>Business Status</th>
                <td>{displayEmpty(businessIdentity.organisation_status)}</td>
                <th>Start Date</th>
                <td>
                  {stringToSimpleDate(
                    (businessIdentity.service_of_notice_address || {})
                      .address_start_date,
                  )}
                </td>
              </tr>
              <tr>
                <th>Nature of Business</th>
                <td>{displayEmpty(businessIdentity.nature_of_business)}</td>
                <th>File Number</th>
                <td>
                  {displayEmpty(
                    (businessIdentity.bureau_info || {}).bureau_reference,
                  )}
                </td>
              </tr>
              <tr>
                <th>Business start date</th>
                <td>
                  {stringToSimpleDate(businessIdentity.registration_date)}
                </td>
                <th>File Create Date</th>
                <td>
                  {stringToSimpleDate(
                    (businessIdentity.bureau_info || {}).file_creation_date,
                  )}
                </td>
              </tr>
              <tr>
                <th>Renewal Date</th>
                <td>{stringToSimpleDate(businessIdentity.renewal_date)}</td>
                <th>ABN Status</th>
                <td>
                  {organisationStatusType(
                    (businessIdentity.organisation_status || {}).type,
                  )}
                </td>
              </tr>
              <tr>
                <th>Last Registry Search Date</th>
                <td>{stringToSimpleDate(businessIdentity.last_search_date)}</td>
              </tr>
              <tr>
                <th>Date Commenced</th>
                <td>
                  {stringToSimpleDate(
                    businessIdentity.organisation_name_start_date,
                  )}
                </td>
              </tr>
              <tr>
                <th>Entity Type</th>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },
});

export default BusinessIdentity;
