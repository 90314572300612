/* Import libraries */
/* Import components */
import PermissionsAccountRules from "modules/profile/components/PermissionsAccountRules";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
import React, { useState } from "react";
import { connect } from "react-redux";
import { checkPopupExist, getPopup } from "utils/tipHelper.js";

import { deleteUserPopup } from "../../../user/actions";
/* Import CSS */
import styles from "./css/EditAccountRules.css";

function EditAccountRules(props) {
  const { dispatch, isAdmin, current_user_popups } = props;

  const [isTooltipActive, setIsTooltipActive] = useState(true);

  function hideTooltip() {
    const popup = getPopup(
      current_user_popups,
      "new_application_confirm_mandatory_checks_permissions",
    );
    if (popup) {
      dispatch(deleteUserPopup(popup.id));
    }
    setIsTooltipActive(false);
  }

  let tool_tip;

  if (
    isTooltipActive &&
    isAdmin &&
    checkPopupExist(
      current_user_popups,
      "new_application_confirm_mandatory_checks_permissions",
    )
  ) {
    tool_tip = (
      <ToolTip
        tip_name="ConfirmMandatoryChecks"
        css_style="confirm_mandatory_checks"
        dismissHandler={hideTooltip}
      />
    );
  }
  return (
    <section className={styles.section}>
      <PermissionsAccountRules tool_tip={tool_tip} />
    </section>
  );
}

export default connect((state, ownProps) => {
  return {
    current_user_popups: state.current_user.current_user_popups,
    ent: state.manage_profile.current_entity,
    isAdmin: state.current_user.current_user_entity_link.attributes.role_types.includes(
      "admin",
    ),
  };
})(EditAccountRules);
