const config = require("./_application");

const local = require("./_local");
const development = require("./_development");
const uat = require("./_uat");
const production = require("./_production");
const staging = require("./_staging");

const overridesConfigs = {
  local,
  development,
  uat,
  production,
  staging
};
const overrides = overridesConfigs[config.env] || {};
module.exports = Object.assign(config, overrides);
