/* eslint-disable max-classes-per-file */
export function getTipText(name) {
  const NAME_TO_TEXT = {
    Company:
      "A business entity that is registered through the appropriate government agency (Eg:  NZ Companies office therefore has a company number, NZBN and 'Limited' in the trading name, or ASIC therefore has a ABN / ACN).",
    Partnership:
      "Where two or more people form a business. May or may not have a formal partnership agreement outlining how they’ll share profits, debts & work.",
    Trust:
      "Where trustees are appointed to carry out business on behalf of the trust or beneficiaries and the trustee(s) are liable for the debts of the trust.",
    "Sole trader":
      "An Individual who is in business or contracting on their own.",
    StatusOverView:
      "Track your application as it makes its way through the checks, live! Once approved you'll receive an email notification.",
    AddNewCompanyPopup:
      "Enter the second entity Supplier details and follow the steps.",
    Welcome1Centre:
      "Like your first business, complete your profile. Follow the steps provided here as an easy guide.",
    AddNewCompany:
      "The Toggle button enables admin users to set up separate account categories e.g. regions, states, separate legal entities, campaigns, branches or stores. Setting up accounts toggle enables users to switch between all the accounts they are part of. All entities using this feature will automatically default to the 'Serious Business' plan.",
    ShowPermissionToAdmin:
      "You can now allow users to tailor checks (eg:Credit Checks) down to an application level. Check out our new Permissions",
    AddNewTeamMember:
      "Your new team member will be notified that they have been added to this entity.",
    MandatoryCheck:
      "When this user sends an application they will be prompted to alter or confirm the checks you have preset before they send the application.",
    YourTeamPermission:
      "Add new team members here. Also, check out our new Permissions Feature which allows users to tailor checks down to an application level - click <edit> to view.",
    YourTeamToggle:
      "You can add new or existing users to this business. Once added they will be able to toggle between both with the one login.",
    TradeReferenceCheck:
      "Please note that unless otherwise preset at yes, if you select yes, 1Centre will conduct these checks for you. This does come at a cost. If you are unsure please contact your Manager.",
    FirstApplicationWithPermission:
      "You have recently been given permission to alter the Checks conducted at an application level. Please look out for the handy tips along the way.",
    ConfirmMandatoryChecks:
      "You have recently been given the ability to tailor checks to an application level. Turn things on and off based on the risk you have determined for this prospect. If you're unsure contact your Manager.",
    SettingsWebsiteButton:
      "We have a new Website Button! Now you can add a link so your customer can start an application via your own website. Go to <Integrations> to find our more.",
    DashboardApplicationListWebsiteButton:
      "We notice you've added your website button to your own website. When an application comes through this channel you will see action that needs taking here.",
    ApplicationListWebsiteButton:
      "When you receive an application via your website button, you can filter here by Website Button to narrow your view.",
    ApplicationListReviewWebsiteButton:
      "Review this website application request and turn on the checks you would like completed - we will notify your prospect.",
    ReassignAccount:
      "You have account/s that can be reassigned to a team member if appropriate.",
    ReviewSupplierTermsAgree: "Please agree to the declaration.",
    ReviewTermsAgree: "Please agree to all the declarations.",
    ReviewSupplierTermsScroll:
      "You must scroll through the Ts & Cs before you can agree to them.",
    IntegrationsWebsiteButton:
      "Load your logo in profile page for it to appear on your website button.",
    DashboardApplicationListReview:
      "You have application/s ready for approval. Click to review.",
    ApplicationsListReview:
      "Some checks are still in progress, you can however start reviewing the application.",
    ReviewApplicationAccept:
      "You can approve an application at any stage regardless of whether checks have been completed.",
    ConnectionListRelationship:
      "Your new customer lives in your Connections. Click view relationship to find pdf application and more.",
    ConnectionExportAll:
      "You can use business name, business location, start date and end date to narrow down the connections you want to export.",
    admin:
      "This function has overall responsibility for the Supplier account and can view all sales reps information up to a National level. This role can also edit the details.",
    approver:
      "This person has similar functionality to the admin person and is responsible for approving trade applications, but can not set up trade accounts.",
    standard:
      "This function is typically your sales person who can create applications and view their own customers.",
    Personal:
      "Includes an individual or joint person/s wanting to open an account for non-business or domestic purposes.",
    AutoDecisioningFreeUsers:
      "Sorry, this function is only available to paying users.",
    AutoDecisioningInfo:
      "Auto Decisioning helps your business save time by automating your approval process.",
    "1AccountAddingBranch":
      "Admin users are able to set up seperate entities at a head office level - eg: legal entities, branches, stores, countries etc. Click here to set up a new 1Centre account for the seperate entities. Once added you will be able to access the individual accounts from here, including having an aggregated view of all activity.",
    EmailDefaultToggle: "Untick to customise your customer email",
    EmailNotificationsToggle:
      "Untick to stop this email from being sent to your customers",
    EmailNotificationsMandatory:
      "This email is mandatory and cannot be turned off",
    EmailIsDelayToggle:
      "Tick to delay when your email will be sent to your customer",
    LatePaymentRisk:
      "Measures the likelihood of a company having a payment overdue by more than 60 days within the next 12 months.",
    DefaultRisk:
      "Measures the probability of a company defaulting on its payment obligations within the next 12 months.",
    BusinessFailureRisk:
      "Measures the probability of a company going into external administartion within the next 12 months.",
    DashboardConsumerTodoApplicationList:
      "Please click on the application you would like to continue completing",
    Education: "Place that provides learning eg school, college, university.",
    Society:
      "Organisation that has a stated purpose either in regards to public or to the common interest of the members.",
    Association:
      "Organisation that has a stated purpose in regards to the common interest of the members.",
    Club:
      "Organisation that has a stated purpose in regards to the common interest of the members.",
    Government: "Department of local/state or central/federal government.",
    Other:
      "Choose this option if none of the other categories describe your entity type (Such as Incorporated Societies, Education, Sports Clubs etc).",
    // TODO: Temporary message for Farmlands. Remove once Farmlands enables personal accounts again.
    FarmlandsPersonalCustom:
      "For an individual or joint persons opening an account for non-business or domestic purposes. NB: New CCCFA regulations came into effect 1st December 2021, which affects how we process personal accounts. We now require 3 months' bank and credit statements to accompany all requests. Given this legislation, Farmlands may not approve an application that has total after tax income of less than $5,000 per month or savings of less than $80,000.",
    FarmlandsPersonalDisabled:
      "Farmlands is not currently accepting new applications for personal accounts. For more info, contact Farmlands on 0800 200 600.",
    // TODO: Temporary message for Higgins. Remove once Higgins enables personal accounts again.
    HigginsPersonalDisabled:
      "Higgins Concrete / Hirock / Lake Taupo Redimix T/A Higgins Concrete do not accept personal credit accounts.  You can however purchase on a prepaid cash account basis – please contact your local branch for information on how to do this.",
  };

  return NAME_TO_TEXT.hasOwnProperty(name) ? NAME_TO_TEXT[name] : null;
}

export function checkPopupExist(popups, slug) {
  let check = false;

  if (popups) {
    popups.forEach(v => {
      if (v.attributes.slug === slug) check = true;
    });
  }

  return check;
}

export function getPopup(popups, slug) {
  let popup = null;

  if (popups) {
    popups.forEach(v => {
      if (v.attributes.slug === slug) popup = v;
    });
  }

  return popup;
}
/* eslint-enable max-classes-per-file */
