import BankAccountDetailsByRegion from "modules/paperless-authorisation/components/Abstract/BankAccountDetailsByRegion";
import styles from "modules/paperless-authorisation/css/Abstract.css";
import commonStyles from "modules/paperless-authorisation/css/Section.css";
import Button from "modules/shared/components/inputs/Button";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import React from "react";

export default function Abstract(props) {
  const { paperlessAuthorisation, paperlessAuthorisationId } = props;
  const toDecline = () => {
    paperlessAuthorisation.setAttributes({ approved: false });
    (async() => {
      const result = await paperlessAuthorisation.saveDecision(
        paperlessAuthorisationId,
      );
      if (result.status === 200) props.toLastSection();
    })();
  };

  if (paperlessAuthorisation.attributes.reviewed) {
    return <div>This direct debit authorisation is already completed.</div>;
  }

  return (
    <div className={commonStyles.container}>
      <section className={commonStyles.section}>
        <div className={commonStyles.row}>
          <div className={commonStyles.panel}>
            <PanelTitle text={"Direct debit authorisation"} />
          </div>
        </div>
        <div className={commonStyles.content}>
          <div className="mb-5">
            Welcome{" "}
            <strong>{paperlessAuthorisation.attributes.full_name}</strong>
          </div>
          <p>
            You are nominated by{" "}
            <strong>{paperlessAuthorisation.attributes.applicant_name}</strong>{" "}
            to authorise&nbsp;
            <strong>
              {paperlessAuthorisation.attributes.supplier_name}
            </strong>{" "}
            to debit account
            <BankAccountDetailsByRegion
              bankAccountName={paperlessAuthorisation.bankAccountName}
              bankAccountNumber={paperlessAuthorisation.bankAccountNumber}
              bankNumber={paperlessAuthorisation.bankNumber}
              region={paperlessAuthorisation.supplierRegion}
            />
          </p>
        </div>
        <div className={`${styles.terms_block} mt-5`}>
          <span className={styles.terms}>
            {paperlessAuthorisation.attributes.terms}
          </span>
        </div>
        <div className={commonStyles.flow_buttons}>
          <Button text="Decline" onClick={toDecline} />
          <Button
            text="Next"
            onClick={props.toNextSection}
            loading={paperlessAuthorisation.isLoading}
          />
        </div>
      </section>
    </div>
  );
}
