/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

/* Import components */
import CircleModal from "modules/shared/components/widgets/static/CircleModal";
import Button from "modules/shared/components/inputs/Button";

var BusinessCompanyExists = createClass({
  render: function() {
    const { title, handleDismiss } = this.props;

    return (
      <CircleModal title={title} dismissHandler={handleDismiss}>
        <p>
          You may have selected the wrong company in the previous step, or
          somebody from your company has already registered as a supplier and
          simply needs to add you to their team.
        </p>
        <Button text="Go back" handleClick={handleDismiss} />
      </CircleModal>
    );
  },
});

var defaults = {
  title: "This company is already registered with 1Centre",
};

module.exports = connect((state, ownProps) => {
  return {
    title: defaults.title,
  };
})(BusinessCompanyExists);
