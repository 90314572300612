import api from "api";
import get from "lodash.get";
import AccountTypeCheckbox from "modules/addons/addons_form/components/AccountTypeCheckbox/index.tsx";
import styles from "modules/addons/components/css/WebsiteButtonLink.css";
import TextArea from "modules/shared/components/inputs/TextArea";
import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import isBlank from "utils/isBlank";

function rawHTMLButton(props) {
  return renderToString(<Button {...props} />);
}

function rawHTMLQRCode(props) {
  return renderToString(<QRCodeImage {...props} />);
}

function Button(props) {
  const { link, logo, text } = props;

  return (
    <a
      id="1Centre-website-button"
      href={link}
      style={{
        color: "black",
        fontFamily: "Atlas Grotesk Web, sans-serif",
        fontSize: 14,
        fontWeight: 400,
        textDecoration: "none",
      }}
    >
      <div
        style={{
          alignItems: "center",
          borderRadius: 20,
          boxShadow: "2px 4px 5px -2px #c9c9c9",
          display: "flex",
          paddingRight: 20,
          width: "fit-content",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${logo.url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "70%",
            borderRadius: "50%",
            boxShadow: "2px 4px 5px -2px #c9c9c9",
            height: 40,
            width: 40,
          }}
        ></div>
        <div
          style={{
            marginLeft: 10,
          }}
        >
          {text}
        </div>
      </div>
    </a>
  );
}

function QRCodeImage(props) {
  const { qrCode } = props;

  return <img src={qrCode} alt="qr-code" />;
}

function QRCode(props) {
  const { currentUser, link } = props;
  const [qrCode, setQrCode] = useState(null);

  const qrCodeAPI = api(
    "qr_code",
    currentUser.accessToken,
    get(currentUser, "currentEntity.id"),
  );

  useEffect(() => {
    (async() => {
      const result = await qrCodeAPI.generate(link);
      setQrCode(get(result, "data.data"));
    })();
  }, []);

  if (isBlank(qrCode)) {
    return null;
  }

  const rawHTML = rawHTMLQRCode({ qrCode });

  return (
    <div>
      <div className={styles.qr_code_content}>
        <QRCodeImage qrCode={qrCode} />
      </div>
      <div className={styles.text_area}>
        <TextArea value={rawHTML} readOnly={true} />
      </div>
    </div>
  );
}

function Component(props) {
  const { link } = props;
  const websiteLink = `${link}?channel=website`;
  const rawHTML = rawHTMLButton({ ...props, link: websiteLink });
  const qrLink = `${link}?channel=qr`;

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.button_content}>
          <Button {...props} link={websiteLink} />
        </div>
        <div className={styles.text_area}>
          <TextArea value={rawHTML} readOnly={true} />
        </div>
      </div>

      <QRCode {...props} link={qrLink} />
    </div>
  );
}

const WebsiteButtonLink = {
  Button,
  Component,
  QRCode,
  rawHTMLButton,
  rawHTMLQRCode,
};

export default WebsiteButtonLink;
