
import axios from "./axios";;

export default class PresignedUrl {
  constructor(config) {
    this.axios = axios(config);
    this.type = "presigned_url";
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      "v1",
      "v2",
    );
    this.path = `/${this.type}`;
  }

  get(attributes) {
    return this.axios.get(`${this.path}`, {
      params: attributes,
    });
  }
}
