/* Import libraries */
import React, { Component } from "react";

import AUStandardTermsAndConditions from "modules/shared/components/text/standard_terms_and_conditions/australia/StandardTermsAndConditions";
import NZStandardTermsAndConditions from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/StandardTermsAndConditions";
import isBlank from "utils/isBlank";

const TermsAndConditions = {
  AU: AUStandardTermsAndConditions,
  NZ: NZStandardTermsAndConditions,
};

export const verticalContainerCSS = {
  backgroundColor: "#c9c9c9",
  borderRadius: 3,
  opacity: 1,
  width: 6,
  zIndex: 1,
};

export const verticalScrollbarCSS = {
  borderRadius: 3,
  margin: 0,
  width: 6,
  zIndex: 2,
};

export default class StandardTermsAndConditions extends Component {
  render() {
    const { onScrollToBottom, region } = this.props;

    if (isBlank(region)) {
      return null;
    }
    const TermsAndConditionsComponent = TermsAndConditions[region];

    return (
      <TermsAndConditionsComponent
        region={region}
        onScrollToBottom={onScrollToBottom}
      />
    );
  }
}
