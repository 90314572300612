import React from "react";
import FileWidget from "./FileWidget";

import styles from "./css/FileWidgetModal.css";

var FileWidgetModal = createClass({
  render: function() {
    return <FileWidget theme={styles} {...this.props} />;
  },
});

export default FileWidgetModal;
