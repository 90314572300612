import axios from "./axios";;

let Nzbn = function(config) {
  // Increase timeout for NZBN (It's slow)
  config.timeout = 20000;
  this.axios = axios(config);
};

Nzbn.prototype.companySearch = function(string, success, error) {
  return this.axios
    .get("/nzbn_company_search", { params: { name: string } })
    .then(success)
    .catch(error);
};

Nzbn.prototype.entityDetails = function(nzbn, success, error) {
  return this.axios
    .get(`/nzbn_entity_details/${nzbn}`)
    .then(success)
    .catch(error);
};

export default Nzbn;
