import { SectionDescription } from "modules/webhooks/v2/components/V2Documentation/Content/styles";
import React, { ReactElement } from "react";

const PayloadStructureDescription = ({
  fileName,
  filePath,
}: {
  fileName: string;
  filePath: string;
}): ReactElement => (
  <div>
    <SectionDescription>
      The schema is documented using Swagger. You can download the{" "}
      <a href={filePath} download={fileName}>
        schema file
      </a>{" "}
      or copy and paste the contents below. The schema file or the copied
      content can be imported to{" "}
      <a
        href="https://editor.swagger.io/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Swagger editor
      </a>{" "}
      to see a formatted view and a generated example.
    </SectionDescription>
  </div>
);

export default PayloadStructureDescription;
