import {
  PRICING_PLAN_LIST_LOAD_START,
  PRICING_PLAN_LIST_LOAD_SUCCESS,
  PRICING_PLAN_LIST_LOAD_ERROR,
  PRICING_PLAN_SET_SELECTED_PLAN,
  PRICING_PLAN_LOAD_START,
  PRICING_PLAN_LOAD_SUCCESS,
  PRICING_PLAN_LOAD_ERROR,
  PRICING_PLAN_UPDATE_CURRENT_PLAN_START,
  PRICING_PLAN_UPDATE_CURRENT_PLAN_SUCCESS,
  PRICING_PLAN_UPDATE_CURRENT_PLAN_ERROR,
  PRICING_PLAN_SHOW,
} from "./constants";

var initialState = {
  pricingPlansList: null,
  pricingPlansLoading: false,
  selectedPlan: null,
  current_pricing_plan: null,
  show_pricing_plan: false,
  show_pricing_plan_type: null,
  show_pricing_plan_redirect: null,
  curentPrcingPlanUpdating: false,
};

export function pricingPlansReducer(state = initialState, action) {
  switch (action.type) {
    case PRICING_PLAN_LIST_LOAD_START:
      return { ...state, pricingPlansLoading: true };
    case PRICING_PLAN_LIST_LOAD_SUCCESS:
      return {
        ...state,
        pricingPlansLoading: false,
        pricingPlansList: action.payload,
      };
    case PRICING_PLAN_LIST_LOAD_ERROR:
      return { ...state, pricingPlansLoading: false };
    case PRICING_PLAN_SET_SELECTED_PLAN:
      return { ...state, selectedPlan: action.payload };
    case PRICING_PLAN_LOAD_START:
      return { ...state };
    case PRICING_PLAN_LOAD_SUCCESS:
      return { ...state, current_pricing_plan: action.payload };
    case PRICING_PLAN_LOAD_ERROR:
      return { ...state };
    case PRICING_PLAN_UPDATE_CURRENT_PLAN_START:
      return { ...state, curentPrcingPlanUpdating: true };
    case PRICING_PLAN_UPDATE_CURRENT_PLAN_SUCCESS:
      return { ...state, curentPrcingPlanUpdating: false };
    case PRICING_PLAN_UPDATE_CURRENT_PLAN_ERROR:
      return { ...state, curentPrcingPlanUpdating: false };
    case PRICING_PLAN_SHOW:
      return {
        ...state,
        show_pricing_plan: action.payload.show,
        show_pricing_plan_type: action.payload.type,
        show_pricing_plan_redirect: action.payload.redirect,
      };
    default:
      return { ...state };
  }
}
