import isEmpty from "lodash.isempty";

export default function isBlank(value: any): boolean {
  if (typeof value === "undefined" || value === null) {
    return true;
  }

  if (typeof value === "number" && !isNaN(value)) {
    return false;
  }

  if (typeof value === "boolean") {
    return false;
  }

  return isEmpty(value);
}
