import React, { useState, useEffect } from "react";
import FileUpload from "modules/FileUpload";
import SectionHeader from "modules/shared/components/v2/SectionHeader";
import { IDENTITY_SET_ANTI_FRAUD_RESULT } from "modules/identity/constants";
import { getErrorMessage, trackMixPanelSimilarityScore } from "../utils";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import config from "../../../../../config";

function BiometricCapture(props) {
  const {
    apiType,
    application,
    authorisationID,
    currentUser,
    frontFaceImage,
    dispatch,
    distinctId,
    hasError,
    isAntiFraudEnabled,
    isAntiFraudPassing,
    isFaceMatchEnabled,
    resourceType,
  } = props;

  const fileId = "front_face_image";

  const callback = data => {
    dispatch({
      type: IDENTITY_SET_ANTI_FRAUD_RESULT,
      payload: data,
    });

    if (isAntiFraudEnabled) {
      const attributes = {
        "Entity ID": get(currentUser, "currentEntity.id"),
        distinct_id: currentUser.id,
      };

      if (application) {
        attributes["Application ID"] = application.id;
      }

      mixpanel.track("1CAF selfie uploaded", attributes);

      trackMixPanelSimilarityScore({ attributes, data });
    }
  };

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setErrorMessage(
      getErrorMessage({
        frontFaceImage,
        isAntiFraudPassing,
        isFaceMatchEnabled,
        type: fileId,
      }),
    );
  }, [frontFaceImage, hasError, isAntiFraudPassing]);

  return (
    <div>
      <SectionHeader title="Front-facing selfie">
        <p>
          Please take a front-facing selfie using your device camera. This step
          is to verify your identity is whom you claim to be.
        </p>
      </SectionHeader>
      <FileUpload
        apiType={apiType}
        authorisationID={authorisationID}
        attachmentType="front_face_image"
        callback={callback}
        distinctId={distinctId}
        errorMessage={errorMessage}
        fileId={fileId}
        hasError={hasError}
        resourceType={resourceType}
        uploadedFile={frontFaceImage}
        cameraOnly={!config.development}
      />
      <span />
    </div>
  );
}

BiometricCapture.propTypes = {};

export default BiometricCapture;
