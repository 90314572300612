/* Import libraries */
import React from "react";
import Helmet from "react-helmet";
import DOMPurify from "dompurify";

/* Import CSS */
import styles from "./css/SectionTitle.css";

var SectionTitle = createClass({
  render: function() {
    const { text, white, title } = this.props;
    let title_style = styles.title;

    if (white) {
      title_style = styles.title_white;
    }

    return (
      <div className={styles.outer}>
        <Helmet title={title || text} />
        <h1
          className={title_style}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      </div>
    );
  },
});

export default SectionTitle;
