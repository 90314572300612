import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

export function getCardsAddonRule(addonRules) {
  return addonRules.find(rule => rule.attributes.addon_module_name === "cards");
}

export function getCardsAddonConfig(addonRule) {
  if (typeof addonRule === "undefined") {
    return {};
  }

  return addonRule.attributes.addon_version.data.attributes.config;
}

export function isCardholderEmpty(cardholder) {
  if (isBlank(cardholder)) {
    return true;
  }

  if (isPresent(cardholder.id)) {
    return false;
  }

  const attributes = cardholder.attributes;
  if (isBlank(attributes)) {
    return true;
  }

  return (
    isBlank(attributes.firstName) &&
    isBlank(attributes.lastName) &&
    isBlank(attributes.email)
  );
}
