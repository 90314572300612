import moment from "moment";
import React from "react";
import { browserHistory } from "react-router";

import styles from "./css/NotificationPanel.css";

const getRangeNotifications = (notifications, start, length = 1) => {
  let res = [];

  if (length === -1) {
    const day = moment()
      .add(start, "days")
      .format("YYYY-MM-DD");

    res = notifications
      .filter(item =>
        moment(item.attributes.created_at_with_timezone).isBefore(moment(day)),
      )
      .map(item => item.attributes);

    return res;
  }

  for (let i = 0; i < length; i++) {
    const day = moment()
      .add(start + i, "days")
      .format("YYYY-MM-DD");

    const filtered = notifications
      .filter(
        item =>
          moment(item.attributes.created_at_with_timezone).format(
            "YYYY-MM-DD",
          ) === day,
      )
      .map(item => item.attributes);
    res.push(...filtered);
  }
  return res;
};

const getOnClickAction = notification => {
  if (notification.intent === "hard_refresh") {
    return () => window.location.reload(true);
  }

  if (notification.intent_target) {
    return () =>
      browserHistory.push(
        `/dashboard/applications/${notification.intent_target}`,
      );
  }

  return () => browserHistory.push("/dashboard/notifications");
};

const NotificationItem = ({ notifications, title, detail }) => {
  const items = [];

  notifications.forEach(item =>
    items.push(
      <tr className={styles.item_pointer} onClick={getOnClickAction(item)}>
        {detail ? (
          <td>
            <span
              className="pl-3 has-text-weight-normal"
              dangerouslySetInnerHTML={{
                __html: item.text || "",
              }}
            />
            <span className="has-text-weight-normal pl-3 has-text-grey">
              {moment(item.created_at_with_timezone).format(
                "h:mm A MM/DD/YYYY",
              )}
            </span>
          </td>
        ) : (
          <td>
            <div
              className={`${styles.truncated_content} pl-3 has-text-weight-normal`}
            >
              {item.text || ""}
            </div>
          </td>
        )}
      </tr>,
    ),
  );

  return (
    <React.Fragment>
      <div className="block mb-0 has-background-grey-lightest ">
        <table className="table is-bordered is-hoverable is-fullwidth has-background-grey-lightest has-text-weight-normal">
          <th
            className={`${styles.item} has-background-grey-lightest content is-size-normal has-text-weight-normal is-vcentered`}
          >
            <span className="is-size-medium has-text-weight-normal">
              {title}
            </span>
          </th>
          <tbody>{items}</tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export { getRangeNotifications, NotificationItem };
