import styled from "styled-components";
import { THEME_COLORS } from "variables/theme";

export const DeleteBtn = styled.button`
  margin-top: 0.5rem;
`;

export const CustomisedMessageWrapper = styled.div`
  position: relative;
`;

export const Buttons = styled.div`
  button + button {
    margin-left: 20px;
  }
`;

export const CharacterCount = styled.div`
  bottom: 2rem;
  color: ${THEME_COLORS.disabled};
  pointer-events: none;
  position: absolute;
  right: 0.75rem;
`;