import { amountTypeValue, countTypeValue } from "./helpers/summary";

import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { displayEmpty } from "utils/formatting";
import { enquiryResponseData } from "./helpers/reportHelpers";
import { stringToSimpleDate } from "utils/dateFormatter";
import styles from "../../../css/CommonReviewBusiness.css";

var CompanyDocumentListing = createClass({
  _documents() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      asicDocuments = response.asic_documents || {},
      documentList = asicDocuments.document_list || {},
      documentItem = documentList.document_item || [];

    if (documentItem.constructor === Object) documentItem = [documentItem];

    return documentItem.map((document, index) => {
      return (
        <tr key={index}>
          <td>
            {document.form_code} {document.description}
          </td>
          <td>
            {displayEmpty((document.document_details || {}).document_number)}
          </td>
          <td>{stringToSimpleDate(document.received_date)}</td>
          <td>{stringToSimpleDate(document.processed_date)}</td>
          <td>{displayEmpty(document.pages)}</td>
          <td>{stringToSimpleDate(document.effective_date)}</td>
        </tr>
      );
    });
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Company Document Listing"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>
            Total: {countTypeValue(summaryEntry, "ASIC_Documents")}
          </strong>
        </p>
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <thead>
              <tr>
                <th>Code and Description</th>
                <th>Document</th>
                <th>Date Received</th>
                <th>Date Processed</th>
                <th>Pages</th>
                <th>Date Effective</th>
              </tr>
            </thead>
            <tbody>{this._documents()}</tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },
});

export default CompanyDocumentListing;
