import axios from "./axios";;

let TradingNames = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = "trading_names";
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
};

TradingNames.prototype.getTradingNames = function(success, error, options) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

TradingNames.prototype.getTradingName = function(id, success, error) {
  return this.axios
    .get(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

TradingNames.prototype.updateTradingName = function(
  id,
  success,
  error,
  options,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

TradingNames.prototype.deleteTradingName = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

TradingNames.prototype.batchCreateTradingName = function(
  success,
  error,
  options,
) {
  return this.axios
    .post(`/${this.type}/batch_create`, options)
    .then(success)
    .catch(error);
};

TradingNames.prototype.isTradingNameExist = function(success, error, options) {
  return this.axios
    .get(`/${this.type}/trading_name_exist`, options)
    .then(success)
    .catch(error);
};

export default TradingNames;
