import { StylesProvider } from "@material-ui/core";
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import React, { ReactElement, useState } from "react"

import { FormValues } from "../types";
import { Wrapper } from "./styles";

function CustomerChecklist({
  checklist,
  customisedMessage,
  links,
}: FormValues): ReactElement {
  const [selection, setSelection] = useState<string[]>([]);

  function toggleSelection(label: string) {
    if (selection.includes(label)) {
      return setSelection(selection.filter(item => item !== label));
    }

    setSelection([...selection, label]);
  }

  return (
    <Wrapper>
      <div className="columns is-multiline">
        <div className="column is-12">
          <h2>Application checklist</h2>
          <p>
            { customisedMessage ? customisedMessage : "Welcome! Please make sure you have the documents below." }
          </p>
        </div>
        { checklist.length > 0 && (
          <div className="column is-12">
            <div className="columns is-multiline">
              { checklist.map(({ label }, index) => (
                <div
                  className="column is-12"
                  key={`checklist-checkbox-render-${index}`}
                >
                  <p>
                    <SquareCheckbox
                    key={`checklist-item-${index}`}
                    label={label}
                    checkboxId={`checklist-item-${index}`}
                    handleChange={() => toggleSelection(label!)}
                    checked={selection.includes(label!)}
                    />
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        { links.length > 0 && (
          <div className="column is-12">
            <h3>Links</h3>
            { links.map(({ label, value }, index) => (
              <div
                key={`link-render-${index}`}>
                <a
                  href={value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {label || value}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default CustomerChecklist
