import React from "react";
import Button from "modules/shared/components/inputs/Button";
import SquareModal from "modules/shared/components/widgets/static/SquareModal";
// @ts-ignore-next-line
import { browserHistory } from "react-router";

type Props = {
  handleLeave: () => void,
  hidePopup: () => void,
  handleSave: () => void,
  redirectUrl?: string,
}

function AddOnsNotSavePopup(props: Props) {
  const { handleLeave, hidePopup, handleSave, redirectUrl } = props;

  function redirect() {
    if (!redirectUrl) {
      return;
    }

    browserHistory.push(redirectUrl);
  }

  async function clickSave() {
    hidePopup();
    try {
      await handleSave();
      redirect();
    } catch (error) {
      // console.log(error);
    }
  }

  function clickLeave() {
    handleLeave();
    redirect();
  }

  return (
    <SquareModal title={"Not saved content"} size={"small"}>
      <div>
        <p>
          You have unsaved changes on this page.
          Do you want to save before you leave this page?
        </p>
        <div>
          <Button
            text={"Save and leave"}
            handleClick={clickSave}
          />
          <Button
            text={"Leave anyway"}
            white={true}
            handleClick={clickLeave}
          />
        </div>
      </div>
    </SquareModal>
  );
}

export default AddOnsNotSavePopup;
