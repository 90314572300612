import React, { Fragment } from "react";
import styles from "modules/new-applications/css/IdentificationChecks.css";
import SignatureDetails from "modules/new-applications/components/application-sections/IdentificationCheck/SignatureDetails";

const IdentificationCheckOutcome = props => {
  const { identificationCheck } = props;

  return (
    <Fragment>
      <div className={styles.e_signature_header}>
        <span className="underlined">Identification check</span>
      </div>
      <SignatureDetails
        label="Name verification"
        content={identificationCheck.identificationCheckOutcome}
      />
    </Fragment>
  );
};

export default IdentificationCheckOutcome;
