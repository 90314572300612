import ConsumerOnBoarding from "../modules/consumer-onboarding/containers/ConsumerOnBoarding";
import { ApplicationLoggedIn } from "../modules/shared/auth";
import Layout from "../modules/user/containers/Layout";

const ConsumerOnBoardingRoute = {
  path: "consumer/:application_id/:section(/**)",
  component: ConsumerOnBoarding,
};

module.exports = {
  path: "register",
  component: ApplicationLoggedIn(Layout),
  childRoutes: [ConsumerOnBoardingRoute],
};
