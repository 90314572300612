import styled from "styled-components";
import { FONT_SIZES } from "variables/typography";

export const ButtonWrapper = styled.div`
  margin-top: 2rem;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const List = styled.ul`
  list-style-type: disc;
  padding: 1rem;
`;

export const Title = styled.h3`
  font-size: ${FONT_SIZES.heading};
`;

