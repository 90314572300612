export const AUTHORISATION_SET_VALUE = "AUTHORISATION_SET_VALUE";
export const AUTHORISATION_UPDATE_DATA_ERROR =
  "AUTHORISATION_UPDATE_DATA_ERROR";
export const AUTHORISATION_UPDATE_DATA_START =
  "AUTHORISATION_UPDATE_DATA_START";
export const AUTHORISATION_UPDATE_DATA_SUCCESS =
  "AUTHORISATION_UPDATE_DATA_SUCCESS";
export const AUTHORISATION_LOAD_DATA_ERROR = "AUTHORISATION_LOAD_DATA_ERROR";
export const AUTHORISATION_LOAD_DATA_START = "AUTHORISATION_LOAD_DATA_START";
export const AUTHORISATION_LOAD_DATA_SUCCESS =
  "AUTHORISATION_LOAD_DATA_SUCCESS";
export const AUTHORISATION_LOGIN_ERROR = "AUTHORISATION_LOGIN_ERROR";
export const AUTHORISATION_LOGIN_START = "AUTHORISATION_LOGIN_START";
export const AUTHORISATION_LOGIN_SUCCESS = "AUTHORISATION_LOGIN_SUCCESS";
export const AUTHORISATION_UPDATE_FORMATTED_SCRAPED_DETAILS = "AUTHORISATION_UPDATE_FORMATTED_SCRAPED_DETAILS";
