/* eslint-disable max-classes-per-file */
export function intentText(item) {
  const intent = item.intent;
  const intentToText = {
    view_consumer_applications: "View applications",
    view_supplier_applications: "View applications",
    view_applications: "View applications",
    view_application: "View application",
    view_supplier_application: "View application",
    view_consumer_application: "View application",
    view_connections: "View connections",
    view_connection: "View connection",
    view_supplier_connection: "View connection",
    view_consumer_connection: "View connection",
    review_application: "Review application",
    view_user: "View details",
    view_subscription: "Review credit card",
    set_mandatory_checks: "Set mandatory checks",
    application_sent: "Application sent",
  };

  if (intentToText.hasOwnProperty(intent)) return intentToText[intent];

  return "Click to acknowledge";
}
/* eslint-enable max-classes-per-file */
