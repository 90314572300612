import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment } from "@material-ui/core";
import React from "react";
import MaskedInput from "react-text-mask";
import { AUSTRALIA_ACCOUNT_NUMBER_FORMAT } from "utils/direct-debit/accountNumberFormat";
import { AUSTRALIA_BANK_NUMBER_FORMAT } from "utils/direct-debit/bankNumberFormat";
import bankNumberLabel from "utils/direct-debit/bankNumberLabel";

const AccountNumberTextMask = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      showMask={false}
      guide={false}
    />
  );
};

const BankNumberTextMask = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      showMask={false}
      guide={false}
    />
  );
};

const getAustraliaAttributes = ({
  bankAccountNumber,
  bankNumber,
  setBankAccountNumber,
  setBankNumber,
  onChangeBankAccountNumber,
  onChangeBankNumber,
}) => [
  [
    {
      label: "Bank account name to be debited",
      name: "bank_account_name",
      required: true,
      styleClass: "half_col",
    },
    {
      defaultValue: bankNumber,
      endAdornment: bankNumber && bankNumber !== "" && (
        <InputAdornment position="end">
          <button
            aria-label="delete bank number"
            onClick={() => setBankNumber("")}
            style={{
              background: "rgba(0,0,0,0.0)",
              border: "none",
              borderRadius: "1rem",
              color: "rgba(0,0,0,0.54)",
              height: "21px",
              width: "21px",
            }}
          >
            <FontAwesomeIcon icon={["fas", "times"]} />
          </button>
        </InputAdornment>
      ),
      label: bankNumberLabel.AU,
      mask: AUSTRALIA_BANK_NUMBER_FORMAT,
      maskInput: BankNumberTextMask,
      name: "bank_number",
      onChange: onChangeBankNumber,
      placeholder: "xxxxxx",
      required: true,
      styleClass: "quarter_col",
      value: bankNumber,
    },
    {
      defaultValue: bankAccountNumber,
      endAdornment: bankAccountNumber && bankAccountNumber !== "" && (
        <InputAdornment position="end">
          <button
            aria-label="delete bank account number"
            onClick={() => setBankAccountNumber("")}
            style={{
              background: "rgba(0,0,0,0.0)",
              border: "none",
              borderRadius: "1rem",
              color: "rgba(0,0,0,0.54)",
              height: "21px",
              width: "21px",
            }}
          >
            <FontAwesomeIcon icon={["fas", "times"]} />
          </button>
        </InputAdornment>
      ),
      label: "Bank account number",
      mask: AUSTRALIA_ACCOUNT_NUMBER_FORMAT,
      maskInput: AccountNumberTextMask,
      name: "bank_account_number",
      onChange: onChangeBankAccountNumber,
      placeholder: "xxxxxxxxxx",
      required: true,
      styleClass: "quarter_col",
      value: bankAccountNumber,
    },
  ],
];

export default getAustraliaAttributes;
