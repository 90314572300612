import FileWidgetSmall from 'modules/shared/components/widgets/interactive/FileWidgetSmall';
import React from 'react'
import { CompleteWidget } from '../../Additional';
import SupplierForm from './SupplierForm';

type Props = {
  answers: any;
  borderedStyle?: boolean;
  attachmentConfig: any;
  data: any;
  file: any;
  fileError: boolean;
  fileSrc: any;
  handleFileCallback: (data: any) => void;
  mode: "onBlur" | "onChange";
  onChange: (params: any) => void;
  validationTrigger: boolean;
}

function AccountLevelForm({
  answers,
  attachmentConfig,
  data,
  file,
  fileError,
  fileSrc,
  handleFileCallback,
  onChange,
  ...formProps
}: Props) {

  return (
    <SupplierForm
      answers={answers}
      label="Internal fields"
      data={data}
      onChange={onChange}
      reduxKey="iufAccountLevel"
      {...formProps}
    >
      { attachmentConfig.active && (
        <div className="mb-4">
          <p>
            {`${attachmentConfig.question || "Please upload an attachment."}`}
            {attachmentConfig.mandatory ? "*" : ""}
            {" "}
            (PDF, PNG, JPG and JPEG){attachmentConfig.mandatory ? "" : " (This is optional)"}
          </p>
          <FileWidgetSmall
            accept={[".pdf", "image/jpeg", "image/png", "image/jpg"].join(",")}
            completeWidget={file && (
              <CompleteWidget
                file={fileSrc}
              />
            )}
            filename="iuf-attach-document.pdf"
            handleFileCallback={handleFileCallback}
            id="iuf-attach-document"
            error={fileError && "Please upload an attachment."}
            text="Drag and drop"
            viewSrc={fileSrc}
          />
        </div>
      )}
    </SupplierForm>
  )
}

export default AccountLevelForm