import React, { ReactNode } from "react";

import { Wrapper } from "./styles";

function Buttons({
  absolute,
  className,
  children,
  spacingDirection = "left",
}: {
  absolute?: boolean,
  className?: string,
  children: ReactNode,
  spacingDirection?: "left" | "right",
}) {
  return (
    <Wrapper className={className} spacingDirection={spacingDirection} absolute={absolute}>
      {children}
    </Wrapper>
  );
}

export default Buttons;
