/* Import libraries */
import React from "react";
import styles from "./css/SimpleDropdown.css";

function SimpleDropdown(props) {
  const { children, pullLeft, type } = props;

  let leftFix;

  let style = styles.dropdown;

  if (type) {
    style = styles[type];
  }

  if (pullLeft) {
    leftFix = styles.left;
  }

  return (
    <div className={[style, leftFix].join(" ")}>{children}</div>
  );
}

export default SimpleDropdown;
