import api from "../../api";

import {
  SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_START,
  SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_SUCCESS,
  SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_ERROR,
  SHARED_DATA_REMOVE_FORM_BUILDER_VALIDATION_RESULTS,
  SHARED_DATA_SET_FORM_BUILDER_VALIDATION_RESULTS,
} from "./constants";

export function getEntityTradingNames(entityId, params, autoSelect) {
  return (dispatch, getState) => {
    const id = entityId || getState().current_user.current_entity.id;

    const entities = api("entities", getState().current_user.access_token);

    dispatch({ type: SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_START });

    entities.getTradingNames(
      id,
      success => {
        dispatch({
          type: SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_SUCCESS,
          payload: success.data.data,
        });
        if (autoSelect && success.data.data.length === 1) {
          autoSelect(success.data.data[0].id);
        }
      },
      error => {
        dispatch({
          type: SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_ERROR,
          payload: error,
        });
      },
      { params },
    );
  };
}

export function setFormBuilderValidationResults(reduxKey, vaildationResults) {
  return dispatch => {
    dispatch({
      type: SHARED_DATA_SET_FORM_BUILDER_VALIDATION_RESULTS,
      payload: { key: reduxKey, value: vaildationResults },
    });
  };
}

export function removeFormBuilderValidationResults(reduxKey) {
  return dispatch => {
    dispatch({
      type: SHARED_DATA_REMOVE_FORM_BUILDER_VALIDATION_RESULTS,
      payload: { key: reduxKey }
    });
  };
}

