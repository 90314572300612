import _ from "lodash";
//import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader'
import Button from "modules/shared/components/inputs/Button";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formatMoney } from "utils/formatting";

import * as paymentActions from "../actions";
import styles from "./css/CreditCardInfo.css";
import EditCardInputs from "./EditCardInputs";
import isPseudoRole from "modules/shared/helpers/user/isPseudoRole";

class CreditCardInfo extends Component {
  _saveCard(response) {
    const { dispatch, new_company_id } = this.props;
    const attributes = _.pick(
      response.card,
      "brand",
      "country",
      "exp_month",
      "exp_year",
      "funding",
      "id",
      "last4",
    );
    attributes.stripe_card_id = attributes.id;
    attributes.stripe_source_token = response.id;
    delete attributes.id;
    if (new_company_id) {
      dispatch(paymentActions.addCard(attributes, new_company_id));
    } else {
      if (this.props.payment.cards.length) {
        attributes.stripe_customer_id = this.props.payment.cards[0].attributes.stripe_customer_id;
        dispatch(
          paymentActions.updateCard(this.props.payment.cards[0].id, attributes),
        );
      } else {
        dispatch(paymentActions.addCard(attributes, new_company_id));
      }
    }
  }

  _onSubmit() {
    const { dispatch } = this.props;
    const { cards } = this.props.payment;
    if (!this.props.payment.updateCard && cards.length > 0) {
      this.props.paymentActions.set({ updateCard: true });
      return;
    }

    this.props.paymentActions.set({ cardLoading: true });
    this._editCardInputs
      ._onSubmit()
      .then(response => {
        this._saveCard(response);
      })
      .catch(err => {
        this.props.paymentActions.set({
          cardLoading: false,
          errorText: err.stripeMessage || "Error saving credit card",
          errorType: err.errorType,
          submitDisabled: false,
        });
      });
  }

  _renderUpdated(onSave) {
    const { cards, subscription } = this.props.payment;
    if (onSave) onSave(true);
    this.props.paymentActions.set({ showSuccessCard: false });

    // dont show the popup # fix/4574
    return <React.Fragment />;
    // return (
    //   <PaymentUpdated
    //     hide={() => this.props.paymentActions.set({ showSuccessCard: false })}
    //     last4={cards[0].attributes.last4}
    //     billingDate={
    //       subscription &&
    //       subscription.attributes &&
    //       subscription.attributes.stripe_current_period_end
    //     }
    //     onSave={onSave}
    //   />
    // );
  }

  render() {
    const { canEdit, new_company_id, onSave } = this.props;

    if (this.props.payment.showSuccessCard) {
      return this._renderUpdated(onSave);
    }
    const { totalCost, updateCard, cards, subscription } = this.props.payment;
    const numUsers =
      this.props.payment.numUsers !== null ? this.props.payment.numUsers : "~";
    const bill =
      totalCost !== null &&
      subscription &&
      subscription.attributes &&
      subscription.attributes.plan === "pu-monthly"
        ? `$${formatMoney(totalCost, 2)}`
        : `$${formatMoney(0, 2)}`;
    let buttonText =
      cards.length > 0 && !new_company_id
        ? updateCard
          ? "update card"
          : "edit card"
        : "add card";
    const { noAboutText } = this.props;

    let loader = null;
    if (this.props.payment.cardLoading) {
      loader = <span className={styles.loading}></span>;
      buttonText = "saving";
    }
    return (
      <div>
        <article className={styles.block}>
          <div className={styles.liner}>
            <h2 className={styles.header}>Credit card information</h2>
            { canEdit && (
              <div className={styles.button}>
                <Button
                  disabled={this.props.payment.cardLoading}
                  handleClick={() => this._onSubmit()}
                  text={buttonText}
                />
                {loader}
              </div>
            )}
            {!cards.length > 0 || updateCard || new_company_id ? (
              <EditCardInputs
                payment={this.props.payment}
                paymentActions={this.props.paymentActions}
                ref={r => (this._editCardInputs = r)}
                canEdit={canEdit}
              />
            ) : (
              <div className={styles.existing_cards}>
                {cards.length > 0 ? (
                  cards.map((c, i) => (
                    <div key={`card-${i}`}>
                      {`xxxx xxxx xxxx ${c.attributes.last4}`}
                    </div>
                  ))
                ) : (
                  <div>No Credit Card found</div>
                )}
              </div>
            )}
          </div>
        </article>
        { canEdit && (
          <aside className={styles.help}>
            <div className={styles.help_info}>
              <p>
                *Mandatory Checks that incur additional costs will be charged when
                you use them. For example any charges incurred through third party
                providers such as Equifax.
              </p>
            </div>
          </aside>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    const canEdit = !isPseudoRole();

    return {
      canEdit,
      entityId: state.current_user.current_entity.id,
      payment: state.payment,
    };
  },
  dispatch => {
    return {
      dispatch,
      paymentActions: bindActionCreators(paymentActions, dispatch),
    };
  },
)(CreditCardInfo);
