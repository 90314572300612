import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#supplier-terms-creating-a-supplier-terms-ruleset
 */
let SupplierTerms = function(config) {
  this.axios = axios(config);
  this.type = "supplier_terms";
};

SupplierTerms.prototype.createSupplierTerms = function(
  attributes,
  success,
  error,
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

SupplierTerms.prototype.updateSupplierTerms = function(
  id,
  attributes,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

export default SupplierTerms;
