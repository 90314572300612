import BusinessOverview from "modules/consumer-onboarding/components/onboarding/australia/BusinessOverview";
import Identity from "modules/identity/containers/Identity";
import React from "react";

import AdditionalDetails from "./additional/AdditionalDetails";
import CardholdersCount from "./cards/CardholdersCount";
import CardOrderForm from "./cards/CardOrderForm";
import CardDetails from "./cards/CardsDetails";
import ExtrasQuestion from "./extras/ExtrasQuestion";
import FinancialsDetails from "./financials/FinancialsDetails";
import GuarantorsConfirm from "./guarantors/GuarantorsConfirm";
import BusinessChildTradingName from "./onboarding/BusinessChildTradingName";
import BusinessCompanyDetails from "./onboarding/BusinessCompanyDetails";
import BusinessEntityOtherType from "./onboarding/BusinessEntityOtherType";
import BusinessEntityType from "./onboarding/BusinessEntityType";
import BusinessOtherDetails from "./onboarding/BusinessOtherDetails";
import BusinessParties from "./onboarding/BusinessParties";
import BusinessPartyDetails from "./onboarding/BusinessPartyDetails";
import BusinessPartyShare from "./onboarding/BusinessPartyShare";
import PaperlessDetails from "./paperless/PaperlessDetails";
import ReviewCompleted from "./review/ReviewCompleted";
import ReviewSummary from "./review/ReviewSummary";
import ReviewSupplierTerms from "./review/ReviewSupplierTerms";
import ReviewTerms from "./review/ReviewTerms";
import Referees from "./trade-reference/Referees";

// eslint-disable-next-line complexity
export function componentFactory(section, component, props = {}) {
  // Load the correct API wrapper
  switch (section) {
    case "identity":
      switch (component) {
        case "confirm":
          return <Identity {...props} />;
      }
      break;
    case "business":
      switch (component) {
        case "other-details":
          return <BusinessOtherDetails {...props} />;
        case "company-details":
          return <BusinessCompanyDetails {...props} />;
        case "parties":
          return <BusinessParties {...props} />;
        case "party-details":
          return <BusinessPartyDetails {...props} />;
        case "share":
          return <BusinessPartyShare {...props} />;
        case "type":
          return <BusinessEntityType {...props} />;
        case "other-type":
          return <BusinessEntityOtherType {...props} />;
        case "child":
          return <BusinessChildTradingName {...props} />;
        case "overview":
          return <BusinessOverview {...props} />;
      }
      break;
    case "trade-reference":
      switch (component) {
        case "referees":
          return <Referees {...props} />;
      }
      break;
    case "guarantors":
      switch (component) {
        case "confirm":
          return <GuarantorsConfirm {...props} />;
      }
      break;
    case "extras":
      switch (component) {
        case "questions":
          return <ExtrasQuestion {...props} />;
      }
      break;
    case "cards":
      switch (component) {
        case "card-details":
          return <CardDetails {...props} />;
        // case "cardholders-count":
        //   return <CardholdersCount {...props} />;
        case "card-order-form":
          return <CardOrderForm {...props} />;
      }
      break;
    case "financials":
      switch (component) {
        case "details":
          return <FinancialsDetails {...props} />;
      }
      break;
    case "additional":
      switch (component) {
        case "additional-details":
          return <AdditionalDetails {...props} />;
      }
      break;
    case "paperless":
      switch (component) {
        case "details":
          return <PaperlessDetails {...props} />;
      }
      break;

    case "review":
      switch (component) {
        case "terms":
          return <ReviewTerms {...props} />;
        case "supplier-terms":
          return <ReviewSupplierTerms {...props} />;
        case "complete":
          return <ReviewCompleted {...props} />;
        default:
          return <ReviewSummary {...props} />;
      }
  }
}
