import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { Component } from "react";
import { capitalizeSentence } from "utils/formatting";

import styles from "./css/ConsumerNameSearch.css";
import NewRecipientModal from "./NewRecipientModal";
import TradingNameList from "./TradingNameList";

export default class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    const companyData = props.companyData;
    const currentContact = companyData.first_admin || {};
    const currentTradingNameId =
      companyData.trading_names.length > 0 && companyData.trading_names[0].id;

    this.state = {
      currentContact,
      currentTradingNameId,
      defaultContact: currentContact,
      showAddNewUser: false,
    };
  }

  showSelectModal = stateName => {
    this.setState({ [stateName]: true });
  };

  closeSelectModal = stateName => {
    const { onCloseModal } = this.props;
    this.setState({ [stateName]: false });
    onCloseModal({
      currentContact: this.state.defaultContact,
      name: this.companyName(),
    });
  };

  setValue = keyValues => {
    const { onSelect } = this.props;
    this.setState({ ...keyValues });
    onSelect({ ...keyValues, name: this.companyName() });
  };

  selectCompany = () => {
    const { onSelect } = this.props;
    onSelect({
      currentContact: this.state.currentContact,
      name: this.companyName(),
    });
  };

  address() {
    const { companyData } = this.props;
    return companyData.address;
  }

  companyName() {
    const { companyData } = this.props;
    const { currentTradingNameId } = this.state;
    const tradingNameObj =
      (companyData.trading_names || []).find(
        t => t.id === currentTradingNameId,
      ) || {};
    const tradingName = tradingNameObj.trading_name;
    const companyName = companyData.company_name;
    const name = companyName || tradingName;
    return capitalizeSentence(name);
  }

  companyContact() {
    const { companyData } = this.props;
    const user = companyData.first_admin || {};
    return user.email || "";
  }

  renderAddNewRecipient() {
    return (
      <div
        className={styles.add_new_recipient}
        onClick={() => this.showSelectModal("showAddNewUser")}
      >
        Select/add recipient
      </div>
    );
  }

  renderNewRecipientModal() {
    const { nextPath, companyData } = this.props;
    const { showAddNewUser } = this.state;
    return (
      <NewRecipientModal
        open={showAddNewUser}
        companyName={this.companyName()}
        onClose={() => this.closeSelectModal("showAddNewUser")}
        onConfirm={kv => this.setValue(kv)}
        users={companyData.user}
        nextPath={nextPath}
      />
    );
  }

  renderTradingNameList() {
    const { companyData, selected, expend } = this.props;

    return (
      <TradingNameList
        open={selected || expend}
        tradingNames={companyData.trading_names}
        firstAdmin={companyData.first_admin}
        defaultAddress={this.address()}
      />
    );
  }

  renderDivider() {
    const { index } = this.props;
    if (index === 0) {
      return null;
    }
    return <Divider component="li" />;
  }

  render() {
    const { selected, companyData } = this.props;

    const isRegistered = companyData.is_registered;

    return (
      <div>
        {this.renderDivider()}
        <ListItem
          onClick={this.selectCompany}
          className={selected ? styles.selected_item : ""}
        >
          <ListItemText
            primary={
              <div className={styles.company_text}>
                {`${this.companyName()}`}
                {isRegistered ? ''
                  : (
                    <span className='has-text-danger'>
                      &nbsp;(desregistered)
                    </span>
                  )
                }
              </div>
            }
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  <span className={styles.company_list_user}>
                    {this.companyContact()}
                  </span>
                </Typography>
                {<div className={styles.company_text}>{this.address()}</div>}
              </React.Fragment>
            }
          />
          {isRegistered ? this.renderAddNewRecipient() : ''}
          {selected ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {this.renderTradingNameList()}
        {this.renderNewRecipientModal()}
      </div>
    );
  }
}
