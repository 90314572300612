import {
  COB_MONEY_SET_REQUESTED_LIMIT,
  COB_MONEY_SECTION_COMPLETE,
  COB_MONEY_COMPONENT_COMPLETE,
} from "../constants/money";

export function moneyComplete(complete) {
  return (dispatch, getState) => {
    var section_state = getState().cob_money;

    var data = {};

    if (section_state.requested_limit > 0) {
      data.requested_limit = section_state.requested_limit;
    }

    dispatch({
      type: COB_MONEY_SECTION_COMPLETE,
      payload: {
        complete: complete,
        data: data,
      },
    });
  };
}

export function setMoneyLimit(limit) {
  return (dispatch, getState) => {
    dispatch({
      type: COB_MONEY_SET_REQUESTED_LIMIT,
      payload: limit,
    });
  };
}

export function moneyComponentComplete(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state === true) {
      meta = {
        mixpanel: {
          event: "Consumer onboarding",
          props: {
            distinct_id: getState().current_user.data.data.id,
            Component: component || "start",
            Section: "money",
          },
        },
      };
    }

    dispatch({
      type: COB_MONEY_COMPONENT_COMPLETE,
      payload: {
        component: component,
        state: state,
      },
      meta: meta,
    });
  };
}
