import IAdditionalFields from "modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/NZPPSRCollateralAdditionalForms/CollateralsAdditionalForms/MVCollateralDetailsForm/Types/IAdditionalFields";
import BorderedTextArea from "modules/shared/components/inputs/BorderedTextArea";
import React, { ReactElement } from "react";

const CollateralItemTextAreaField = (
  props: IAdditionalFields,
): ReactElement => {
  const { error, field, fieldName, label, placeholder, register } = props;

  return (
    <BorderedTextArea
      defaultValue={field[fieldName]}
      error={Boolean(error)}
      helperText={(error || { message: " " }).message}
      inputRef={register()}
      label={label}
      name={fieldName}
      placeholder={placeholder}
    />
  );
};

export default CollateralItemTextAreaField;
