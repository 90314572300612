import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { AddonAnswerAttributes } from "models/AddonAnswerModel";
import ApplicationModel from "models/ApplicationModel";
import LeadModel from "models/LeadModel";
import { FormBuilderRenderParams } from "modules/shared/components/widgets/interactive/form_builder/types";
import { updatedAnswers, updatedIUFAnswers } from "modules/shared/helpers/internalUseFieldsHelper";
import { useState } from "react";

export type Owner = ApplicationModel | LeadModel;

export function ownerType(owner: Owner) {
  return owner instanceof ApplicationModel ?
    "Application" :
    "Lead";
}

function loadOrInitTradingNameAnswers(application): AddonAnswerAttributes[] {
  if (ownerType(application) !== "Application") {
    return [];
  }

  const { applicationTradingNameLinks, iufAddonRule } = application;
  const addonRuleId = iufAddonRule.id

  return applicationTradingNameLinks.map(
    ({ addonAnswer, id }) => {
      if (isEmpty(addonAnswer.attributes)) {
        const attributes = {
          addon_rule_id: addonRuleId,
          answers: {
            results: [],
          },
          owner_id: id,
          owner_type: "ApplicationTradingNameLink",
        }

        addonAnswer.setAttributes({ ...attributes });
        return attributes;
      }

      return {
        ...addonAnswer.attributes,
        id: addonAnswer.id,
      };
    }
  );
}

function loadOrInitOwnerAnswers(owner): AddonAnswerAttributes {
  const { iufAddonRule, iufAddonAnswers } = owner;
  const addonRuleId = iufAddonRule.id

  if (isEmpty(iufAddonAnswers.attributes)) {
    return {
      addon_rule_id: addonRuleId,
      answers: {},
      owner_id: owner.id,
      owner_type: ownerType(owner),
      status: "draft",
    }
  }

  return {
    ...iufAddonAnswers.attributes,
    id: iufAddonAnswers.id,
  };
}

function useProcessedAddonAnswers(owner) {
  const [
    tradingNameAnswers,
    setTradingNameAnswers,
  ] = useState(loadOrInitTradingNameAnswers(owner));

  const [
    ownerIUFAnswers,
    setOwnerIUFAnswers,
  ] = useState(loadOrInitOwnerAnswers(owner));

  function handleTradingNameChange(ownerId: string, params: FormBuilderRenderParams) {
    const targetTradingNameAnswers = tradingNameAnswers.find(({ owner_id }) => owner_id === ownerId);
    const targetTradingNameIndex = tradingNameAnswers.findIndex(({ owner_id }) => owner_id === ownerId);

    if (targetTradingNameIndex === -1) {
      return;
    }

    const updatedParams = updatedAnswers(
      get(targetTradingNameAnswers, "answers.results", []),
      params,
    );

    const updatedTradingNameAnswers = [...tradingNameAnswers];
    updatedTradingNameAnswers[targetTradingNameIndex] = {
      ...targetTradingNameAnswers,
      answers: {
        results: updatedParams,
      },
    } as AddonAnswerAttributes;

    return setTradingNameAnswers(updatedTradingNameAnswers);
  }

  function handleOwnerChange(key: string, params: FormBuilderRenderParams) {
    const updatedParams = updatedIUFAnswers({
      iufAnswers: get(ownerIUFAnswers, "answers", {}),
      key,
      params,
    });

    return setOwnerIUFAnswers({
      ...ownerIUFAnswers,
      answers: updatedParams,
    });
  }

  function handleFileChange(file) {
    return setOwnerIUFAnswers({
      ...ownerIUFAnswers,
      file,
    })
  }

  const isDraft = [
    ...tradingNameAnswers,
    ownerIUFAnswers,
  ].some(({ status }) => status !== "completed")

  return {
    handleFileChange,
    handleOwnerChange,
    handleTradingNameChange,
    isDraft,
    ownerIUFAnswers,
    tradingNameAnswers,
  }
}

export default useProcessedAddonAnswers;
