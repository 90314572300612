/* eslint-disable sort-keys */
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import React from "react";
import { connect } from "react-redux";

import styles from "../../components/css/AddOnsDetails.css";

function LegalTypeCheckbox({
  defaults,
  description,
  error,
  handleChange,
  readOnly,
  selected,
  title,
}) {
  const options = defaults.options;
  const items = [];
  Object.keys(defaults.options).forEach((item, index) => {
    items.push(
      <div key={`checkbox-${index}`} className={styles.col}>
        <SquareCheckbox
          key={item}
          checkboxId={item}
          checkboxName={item}
          checked={selected.includes(item)}
          handleChange={e => {
            handleChange(e);
          }}
          label={options[item].label}
          disabled={readOnly}
        />
      </div>,
    );
  });

  return (
    <div className={styles.section}>
      <h3>{title || defaults.title}</h3>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.info}>{description || defaults.description}</div>
      <div className={styles.row}>{items}</div>
    </div>
  );
}

const defaults = {
  description: "Select the entity type(s) you wish to apply to this add-on",
  options: {
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    company: { label: "Company" },
    partnership: { label: "Partnership" },
    trust: { label: "Trust" },
    sole_trader: { label: "Sole trader" },
    personal: { label: "Personal" },
    education: { label: "Education" },
    society: { label: "Society" },
    association: { label: "Association" },
    club: { label: "Club" },
    government: { label: "Government" },
    other: { label: "Other" },
  },
  title: "Entity Types",
};

export default connect(() => {
  return { defaults };
})(LegalTypeCheckbox);
