import Input from "@material-ui/core/Input";
import get from "lodash.get";
import AddOnsNotSavePopup from "modules/addons/addons_form/components/AddOnsNotSavePopup";
import BankNumber from "modules/addons/paperless/components/PaperlessDetails/BankNumber";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import TextArea from "modules/shared/components/inputs/TextArea";
import React from "react";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";
import isBlank from "utils/isBlank";

import LegalTypeCheckbox from "../../addons_form/components/LegalTypeCheckbox";
import LimitRange from "../../addons_form/components/LimitRange";
import PreviewSaveButtons from "../../addons_form/components/PreviewSaveButtons";
import styles from "../../components/css/AddOnsDetails.css";
import {
  createAddonVersion,
  loadAddons,
  resetCurrentAddonRuleset,
  setAddonConfigValue,
  setAddonEntityType,
  setAddonValue,
} from "./../../actions";
import {
  createLimitConflictMessage,
  findLimitBreakpoints,
  findLimitConflicts,
  getCreditLimitError,
  getLegalTypesError,
} from "./../../helpers";

class PaperlessDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      notSaved: false,
      notSavedPopup: false,
      redirectUrl: null,
    };
  }

  get addonName() {
    const { data, readOnly } = this.props;
    const attributes = data.attributes;
    const name = attributes.name;

    if (readOnly) {
      return `${name} (Version ${attributes.version})`;
    }

    return name;
  }

  get creditLimitRangeConflictError() {
    const { paperless, data } = this.props;
    const limitBreakpoints = findLimitBreakpoints(paperless, data);
    const limitConflicts = findLimitConflicts(limitBreakpoints, data);

    if (limitConflicts) {
      return createLimitConflictMessage(limitConflicts);
    }

    return null;
  }

  addonContentChanged() {
    this.setState({ notSaved: true });
  }

  showNotSavedPopup() {
    this.setState({ notSavedPopup: true });
  }

  hideNotSavedPopup() {
    this.setState({ notSavedPopup: false });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetCurrentAddonRuleset(moduleName));
  }

  discard() {
    const { handleDiscard } = this.props;
    if (this.state.notSaved) {
      this.showNotSavedPopup();
    } else {
      handleDiscard();
    }
  }

  submit() {
    const { data, dispatch, handleDiscard } = this.props;
    if (this.checkValidAll()) {
      dispatch(
        createAddonVersion(data.attributes, moduleName, () => {
          handleDiscard();
          dispatch(loadAddons(moduleName));
        }),
      );
    } else {
      throw "Paramter is invalid";
    }
  }

  checkValidAll() {
    const { data } = this.props;
    const att = data.attributes;

    const legalTypesError = getLegalTypesError(att.legal_types);
    const creditLimitError = getCreditLimitError(
      att.min_credit_value,
      att.max_credit_value,
    );

    const errors = { ...legalTypesError, ...creditLimitError };
    this.setState({ errors });

    return (
      Object.keys(errors).length === 0 &&
      isBlank(this.creditLimitRangeConflictError)
    );
  }

  redirect(url) {
    this.setState({ redirectUrl: url });
    if (this.state.notSaved) {
      this.showNotSavedPopup();
    } else {
      browserHistory.push(url);
    }
  }

  mandatory() {
    const { data, readOnly, region } = this.props;

    return (
      <div className={styles.section}>
        <h3>Mandatory Fields</h3>
        <div className={styles.info}>
          This is the information we collect from the applicant
        </div>
        <div className={styles.row}>
          <SquareCheckbox
            key={"mandatory"}
            checkboxId={"mandatory"}
            checkboxName={"mandatory"}
            checked={data.attributes.config.mandatory}
            handleChange={() => {
              this.onHandleMandatoryChanges();
            }}
            label={"Are direct debits mandatory?"}
            disabled={readOnly}
          />
        </div>
        <div className={styles.row}>
          <p>First name</p>
          <p>Last name</p>
          <p>Email</p>
          <p>Name of bank account to be debited</p>
          <BankNumber region={region} />
          <p>Account number</p>
        </div>
      </div>
    );
  }

  onHandleMandatoryChanges() {
    const { data, dispatch } = this.props;
    const value = !data.attributes.config.mandatory;
    dispatch(setAddonConfigValue("mandatory", value, moduleName));
    this.addonContentChanged();
  }

  onHandleTermsChanges(value) {
    const { dispatch } = this.props;
    dispatch(setAddonConfigValue("terms", value, moduleName));
    this.addonContentChanged();
  }

  terms() {
    const { data, readOnly } = this.props;
    return (
      <div className={styles.section}>
        <h3>{"Terms"}</h3>
        <div className={styles.row}>
          <TextArea
            id={"terms"}
            key={"terms"}
            handleChange={e => {
              this.onHandleTermsChanges(e.target.value);
            }}
            label={"Special conditions related to notices and disputes"}
            value={data.attributes.config.terms}
            required={false}
            rows={12}
            disabled={readOnly}
          />
        </div>
        <div className={styles.row}>
          Upload t&amp;c&apos;s&nbsp;
          <Link
            onClick={() =>
              this.redirect("/dashboard/profile?active_tab=supplier_terms")
            }
          >
            here
          </Link>
          .
        </div>
      </div>
    );
  }

  emailCustomisation() {
    return (
      <div className={styles.section}>
        <h3>{"Email Customisation"}</h3>
        <div className={styles.row}>
          Customise the content of the email that the account holder will
          receive&nbsp;
          <Link
            onClick={() =>
              this.redirect("/dashboard/profile?active_tab=email_customisation")
            }
          >
            here
          </Link>
          .
        </div>
      </div>
    );
  }

  renderNotSavedPopup() {
    const { handleDiscard } = this.props;

    return (
      <AddOnsNotSavePopup
        handleSave={this.submit.bind(this)}
        handleLeave={handleDiscard}
        hidePopup={this.hideNotSavedPopup.bind(this)}
        redirectUrl={this.state.redirectUrl}
      />
    );
  }

  selectType(event) {
    const { errors } = this.state;
    delete errors.legal_types;
    this.setState({ errors });

    const { dispatch } = this.props;
    dispatch(setAddonEntityType(event.target.id, moduleName));
    this.addonContentChanged();
  }

  setValue(name, value) {
    this.resetCreditLimitError(name);

    const { dispatch } = this.props;
    dispatch(setAddonValue(name, value, moduleName));
    this.addonContentChanged();
  }

  resetCreditLimitError(name) {
    if (!["max_credit_value", "min_credit_value"].includes(name)) {
      return;
    }

    const { errors } = this.state;
    delete errors.credit_limit;
    this.setState({ errors });
  }

  render() {
    const { readOnly, data, updating } = this.props;
    const { errors, notSavedPopup } = this.state;

    return (
      <section className={styles.container}>
        <div>
          {data.serial_number && (
            <span className={styles.serial}>#{data.serial_number}</span>
          )}
          <Input
            classes={{ input: styles.name_text, root: styles.input_parent }}
            id={"name"}
            value={this.addonName}
            placeholder={"Name your ruleset here - eg: Company"}
            onChange={e => {
              this.setValue(e.target.id, e.target.value);
            }}
            required
            disabled={readOnly}
          />
          <CloseButton handleClick={this.discard.bind(this)} />
        </div>
        <div className={styles.section}>
          <div className={styles.row}>
            <div className={styles.info}>{description}</div>
          </div>
        </div>
        <LegalTypeCheckbox
          selected={data.attributes.legal_types}
          handleChange={e => {
            this.selectType(e);
          }}
          error={errors.legal_types}
          readOnly={readOnly}
        />
        <LimitRange
          addOn={{
            max_credit_value: data.attributes.max_credit_value,
            min_credit_value: data.attributes.min_credit_value,
          }}
          handleChange={(name, value) => {
            this.setValue(name, value);
          }}
          error={this.creditLimitRangeConflictError || errors.credit_limit}
          readOnly={readOnly}
          moduleName={moduleName}
        />
        {this.mandatory()}
        {this.terms()}
        {this.emailCustomisation()}
        <PreviewSaveButtons
          moduleName={moduleName}
          previewAddon={data.attributes}
          handleSubmit={() => {
            this.submit();
          }}
          loading={updating}
          readOnly={readOnly}
        />
        {notSavedPopup && this.renderNotSavedPopup()}
      </section>
    );
  }
}

const moduleName = "paperless";
const description =
  "You must be an authorised Paperless Direct Debit agent, signed off by your bank. \
This capability is usually available to Enterprise level business only.  When setting this up consider \
entity types - eg:  unlikely government departments accept, and limits, so lower limits generally suit \
direct debits.  Your prospect experience will be dynamically changed based on how you set this up.  \
Once set up click on the ‘view’ tab at bottom of the screen for your customers experience.";

module.exports = connect(state => {
  const data = state.add_ons.current_paperless;
  const paperless = state.add_ons.paperless;
  const updating = state.add_ons.paperless_updating;

  const region = get(state, "current_user.current_entity.attributes.region");

  return {
    data,
    paperless,
    region,
    updating,
  };
})(PaperlessDetails);
