import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#supplier-terms-creating-a-supplier-terms-ruleset
 */
let TermsDocuments = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = "terms_documents";
};

TermsDocuments.prototype.getTermsDocument = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

TermsDocuments.prototype.deleteTermsDocument = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .delete(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

TermsDocuments.prototype.createTermsDocument = function(
  entityId,
  attributes,
  success,
  error,
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
        relationships: {
          entity: {
            data: {
              type: "entities",
              id: entityId,
            },
          },
        },
      },
    })
    .then(success)
    .catch(error);
};

TermsDocuments.prototype.updateTermsDocument = function(
  id,
  attributes,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

export default TermsDocuments;
