import LabeledContent from "modules/shared/components/widgets/static/LabeledContent";
import React, { Fragment, ReactElement } from "react";

interface IAdditionalDetails {
  chassis: string | null;
  colour: string | null;
  description: string | null;
  make: string | null;
  model: string | null;
  // eslint-disable-next-line camelcase
  registration_plate: string | null;
  vin: string | null;
  year: string | null;
}

interface Collateral {
  additionalDetails: IAdditionalDetails;
  hasCollateralItems: boolean;
}

const CollateralItems = (props: {
  collateral: Collateral;
}): ReactElement | null => {
  const { collateral } = props;

  if (!collateral.hasCollateralItems) {
    return null;
  }

  const { additionalDetails } = collateral;

  return (
    <Fragment>
      <LabeledContent
        label="Vehicle Identification Number (VIN)"
        content={additionalDetails.vin}
      />
      <LabeledContent
        label="Registration Number"
        content={additionalDetails.registration_plate}
      />
      <LabeledContent
        label="Chassis Number"
        content={additionalDetails.chassis}
      />
      <LabeledContent label="Make" content={additionalDetails.make} />
      <LabeledContent label="Model" content={additionalDetails.model} />
      <LabeledContent label="Year" content={additionalDetails.year} />
      <LabeledContent label="Colour" content={additionalDetails.colour} />
      <LabeledContent
        label="Item description"
        content={additionalDetails.description}
      />
    </Fragment>
  );
};

export default CollateralItems;
