/* eslint-disable max-len */
import store from "stores/store";

export function isTrust() {
  return _entityType() === "trust";
}

export function isPartnership() {
  return _entityType() === "partnership";
}

export function isJoint() {
  return (
    _entityType() === "personal" &&
    store.getState().cob_business.entity_party_count > 1
  );
}

export function requiresSignatories() {
  return isTrust() || isPartnership() || isJoint();
}

function _entityType() {
  return store.getState().cob_business.entity_type;
}
