import axios from "./axios";

export default class AddonAnswers {
  constructor(config) {
    config.timeout = 60000;

    this.axios = axios(config);
    this.type = "addon_answers";
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      "v1",
      "v2",
    );

    this.path = `/${this.type}`;
  }

  createAddonAnswer(attributes) {
    return this.axios.post(this.path, {
      data: { attributes, type: this.type },
    });
  }

  updateAddonAnswer(id, attributes) {
    return this.axios.patch(`${this.path}/${id}`, {
      data: { attributes, type: this.type },
    });
  }

  get(id) {
    return this.axios.get(`${this.path}/${id}`);
  }
}
