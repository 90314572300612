import get from "lodash.get";
import {
  CollateralsForm,
  GrantorsForm,
  ReferenceForm,
  useSelectedCollateralPropertyTypeState,
} from "modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/AUPPSRForm";
import { FinancingStatementPin } from "modules/new-applications/components/application-actions/PPSRRegister/PPSRRegisterForm";
import { ExpiryDate } from "modules/new-applications/components/application-actions/PPSRRegister/shared/RegisterDetails";
import styles from "modules/new-applications/css/PPSRRegister.css";
import FixedContent from "modules/shared/components/containers/FixedContent";
import GridContent from "modules/shared/components/containers/GridContent";
import BorderedCalendarPicker from "modules/shared/components/inputs/BorderedCalendarPicker";
import FormCheckBox from "modules/shared/components/inputs/FormCheckBox";
import moment from "moment";
import React, { Fragment, useState } from "react";

// TODO: Deduplicate this component which is similar to the Renew Form and NZ
// Forms
function ExpiryDateCalendarPicker(props) {
  const {
    errors,
    isVisible,
    maxDate,
    onSelectDate,
    register,
    selectedDate,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <GridContent>
      <BorderedCalendarPicker
        textFieldProps={{
          error: Boolean(errors.expiryDate),
          helperText: get(errors, "expiryDate.message", " "),
          id: "modify-expiry-date-datepicker",
          inputRef: register,
          name: "expiryDate",
        }}
        minDate={new Date()}
        maxDate={maxDate}
        value={selectedDate}
        onChange={onSelectDate}
        withBottomMargin={false}
      />
    </GridContent>
  );
}

function AutoRenewForm(props) {
  const { control, errors, ppsrFinancingStatement, register } = props;

  if (ppsrFinancingStatement.expiryDuration === "indefinite") {
    return null;
  }

  return (
    <GridContent>
      <div className={styles.checkbox_container}>
        <FormCheckBox
          id="autoRenew"
          label="Auto renewal"
          control={control}
          value={true}
          register={register}
          defaultValue={ppsrFinancingStatement.autoRenew || false}
          error={get(errors, "autoRenew.message", "")}
          isCompact={true}
        />
      </div>
    </GridContent>
  );
}

function ExpiryDateForm(props) {
  const {
    control,
    errors,
    ppsrFinancingStatement,
    register,
    setValue,
    watch,
  } = props;

  const defaultExpiryDate = moment(ppsrFinancingStatement.expiryDate).toDate();

  const [selectedDate, setSelectedDate] = useState(defaultExpiryDate);
  const onSelectDate = date => {
    setSelectedDate(date);
    setValue("expiryDate", date);
  };

  const isAutoRenew = watch(
    "autoRenew",
    ppsrFinancingStatement.autoRenew || false,
  );

  return (
    <FixedContent>
      <ExpiryDate
        isExpiryDurationVisible={true}
        ppsrFinancingStatement={ppsrFinancingStatement}
      />
      <div className="mt-4">
        <AutoRenewForm
          control={control}
          errors={errors}
          ppsrFinancingStatement={ppsrFinancingStatement}
          register={register}
        />
        <ExpiryDateCalendarPicker
          errors={errors}
          isVisible={!isAutoRenew}
          maxDate={defaultExpiryDate}
          onSelectDate={onSelectDate}
          register={register}
          selectedDate={selectedDate}
        />
      </div>
    </FixedContent>
  );
}

export default function AUPPSRModifyForm(props) {
  const {
    application,
    clearError,
    collateralFieldArray,
    control,
    currentUser,
    debtorFieldArray,
    errors,
    ppsrFinancingStatement,
    register,
    setValue,
    watch,
  } = props;

  const selectedCollateralPropertyTypeState = useSelectedCollateralPropertyTypeState(
    collateralFieldArray.fields[0].collateralPropertyType,
  );

  return (
    <Fragment>
      <ExpiryDateForm
        control={control}
        errors={errors}
        ppsrFinancingStatement={ppsrFinancingStatement}
        register={register}
        setValue={setValue}
        watch={watch}
      />
      <GrantorsForm
        application={application}
        currentUser={currentUser}
        debtorFieldArray={debtorFieldArray}
        errors={errors}
        isModifyMode={true}
        register={register}
        setValue={setValue}
      />
      <ReferenceForm errors={errors} register={register} />
      <CollateralsForm
        application={application}
        clearError={clearError}
        collateralFieldArray={collateralFieldArray}
        control={control}
        errors={errors}
        isCollateralInventoryEnabled={false}
        isCollateralPropertyTypeFieldEnabled={false}
        isCollateralPurchaseMoneyEnabled={false}
        isCollateralTypeFieldEnabled={false}
        ppsrAddonConfig={application.ppsrAddonConfig || {}}
        register={register}
        selectedCollateralPropertyTypeState={
          selectedCollateralPropertyTypeState
        }
        setValue={setValue}
        watch={watch}
      />
      <FinancingStatementPin
        actionText="modification"
        errors={errors}
        region="AU"
        register={register}
      />
    </Fragment>
  );
}
