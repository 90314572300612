/* Import components */
import LabeledInformation from "modules/shared/components/widgets/static/LabeledInformation";
import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import TextArea from "modules/shared/components/inputs/TextArea";
import TextInput from "modules/shared/components/inputs/TextInput";
import { reviewComponentComplete } from "../../actions/review";
import { setMoneyLimit } from "../../actions/money";
import { setQuestionData } from "../../actions/extras";
/* Import CSS */
import styles from "./css/Review.css";

var ReviewExtras = createClass({
  componentWillReceiveProps: function(nextProps) {
    const { dispatch, review_complete } = this.props;
    let complete = this.isComplete(
      nextProps.data,
      nextProps.application,
      nextProps.people,
    );
    if (review_complete.extras !== complete) {
      dispatch(reviewComponentComplete("extras", complete));
    }

    if (nextProps.summary_validation_start) {
      this.checkValidAll();
    }
  },

  checkValidAll: function() {
    const { data, application, setPageValidationStartFinish } = this.props;

    var forms = new Array();
    forms.push("credit_limit");

    for (
      var i = 0;
      i <
      (application.attributes.additional_application_questions || []).length;
      i++
    ) {
      forms.push(i);
    }

    forms.forEach((value, index) => {
      setTimeout(() => {
        if (value === "credit_limit") {
          var target = {
            id: value,
            value: this.formatPreviewText(data.requested_limit),
          };
        } else {
          var target = {
            id: value,
            value: data.answers[value],
          };
        }
        this.checkValid(target);
      }, 500);
    });

    setPageValidationStartFinish();
  },

  isComplete: function(data, application, people) {
    if (!data) {
      var { data } = this.props;
    }
    if (!application) {
      var { application } = this.props;
    }
    if (!people) {
      var { people } = this.props;
    }

    var need_questions =
      application.attributes.additional_questions_applies == "both" ||
      application.attributes.additional_questions_applies ==
        application.attributes.application_type;
    var values = data.answers;
    var requested_limit = data.requested_limit;
    var supplier_limit =
      application.attributes.supplier_max_trade_account_limit;

    if (
      need_questions &&
      application.attributes.additional_application_questions
    ) {
      var questions = application.attributes.additional_application_questions;
    } else {
      var questions = [];
    }

    return this.isValid(values, requested_limit, supplier_limit, questions);
  },

  isValid: function(values, requested_limit, supplier_limit, questions) {
    let isValuesValid = true;
    let questionsRequired = false;

    questions.forEach(item => {
      if (item.length > 0) {
        questionsRequired = true;
      }
    });
    if (questionsRequired) {
      for (let i = 0, l = questions.length; i < l; i++) {
        if (questions[i] === "") {
          continue;
        }

        if (
          !values[i] ||
          !values[i].hasOwnProperty("answer_text") ||
          values[i].answer_text.length <= 0
        ) {
          isValuesValid = false;
        }
      }
    }
    if (
      isValuesValid &&
      this.props.application.attributes.application_type == "cash"
    ) {
      return true;
    }
    if (
      isValuesValid &&
      requested_limit > 0 &&
      requested_limit <= supplier_limit
    ) {
      return true;
    }
    return false;
  },

  componentDidMount: function() {
    const { dispatch } = this.props;
    dispatch(reviewComponentComplete("extras", this.isComplete()));
  },

  updateValue: function(name, event) {
    const { dispatch } = this.props;
    var val = this.integerise(event.target.value);
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
    }
    this.isComplete();
    dispatch(setMoneyLimit(val));
  },

  integerise: function(value) {
    const { prefix, suffix } = this.state;
    if (!value || value == prefix) {
      value = 0;
    }
    return parseInt(value.toString().replace(/[^0-9]/g, ""), 10);
  },

  formatCommaInteger: function(number) {
    let integer = this.integerise(number);
    return integer.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },

  formatPreviewText: function(value) {
    const { prefix, suffix } = this.state;
    //regex puts commas between groups of 3 numbers
    return prefix + this.formatCommaInteger(value) + suffix;
  },

  handleChange: function(field, event) {
    const { dispatch, handleComplete } = this.props;

    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
    }
    this.isComplete();
    dispatch(setQuestionData(event.target.name, field, event.target.value));
  },

  handleBlur: function(field, event) {
    const { handleComplete } = this.props;
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
    }
    this.isComplete();
  },

  checkValid: function(target) {
    const { form_errors } = this.state;
    const { application } = this.props;

    var supplier_limit =
      application.attributes.supplier_max_trade_account_limit;
    switch (target.id) {
      case "credit_limit":
        var val = this.integerise(target.value);
        if (val > supplier_limit || val == 0) {
          this.setState({
            form_errors: {
              ...form_errors,
              credit_limit:
                "Max credit limit is: " +
                this.formatPreviewText(supplier_limit),
            },
          });
        } else {
          const { credit_limit, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      default:
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: "You must answer this question",
            },
          });
        } else {
          const { [target.id]: error, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
    }
  },

  getInitialState: function() {
    return {
      error: null,
      form_errors: {},
      prefix: "$",
      suffix: "",
    };
  },

  render: function() {
    const { data, application } = this.props;
    const { error, form_errors } = this.state;

    var leftQuestions = new Array();
    var rightQuestions = new Array();
    var show_questions =
      application.attributes.additional_questions_applies == "both" ||
      application.attributes.additional_questions_applies ==
        application.attributes.application_type;
    var is_cash_application =
      this.props.application.attributes.application_type == "cash";

    if (show_questions) {
      if (application.attributes.additional_application_questions) {
        application.attributes.additional_application_questions.forEach(
          (question, index) => {
            if (question.trim().length > 0) {
              var questions_value = "";
              if (
                data.answers[index] &&
                data.answers[index].hasOwnProperty("answer_text")
              ) {
                questions_value = data.answers[index].answer_text;
              }

              var questions = new Array();

              questions.push(
                <TextArea
                  error={form_errors[index]}
                  label={question}
                  value={questions_value}
                  id={index}
                  name={index}
                  handleBlur={this.handleBlur.bind(null, "answer_text")}
                  handleChange={this.handleChange.bind(null, "answer_text")}
                />,
              );

              if (index % 2) {
                rightQuestions.push(questions);
              } else {
                leftQuestions.push(questions);
              }
            }
          },
        );
      }
    }

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        {!is_cash_application && (
          <ReviewContainer key="credit_limit" css_class={`block_noborder`}>
            <div className={styles.col}>
              <TextInput
                id={"credit_limit"}
                disabled
                type="tel"
                error={form_errors["credit_limit"]}
                value={this.formatPreviewText(data.requested_limit)}
                label="Trade account limit"
                required={true}
                handleBlur={this.handleBlur.bind(null, "credit_limit")}
                handleChange={this.updateValue.bind(null, "credit_limit")}
              />
            </div>
            <div className={styles.col}></div>
          </ReviewContainer>
        )}

        <ReviewContainer css_class={`block_noborder`}>
          <div className={styles.col}>{leftQuestions}</div>
          <div className={styles.col}>{rightQuestions}</div>
        </ReviewContainer>
      </section>
    );
  },
});

module.exports = ReviewExtras;
