import axios from "./axios";;

const FreshchatIds = function(config) {
  this.axios = axios(config);
  this.type = "freshchat_ids";
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
};

//
// Await-able functions
// The following functions allows the use of async/await
//
FreshchatIds.prototype.createFreshchatId = function(attributes) {
  return this.axios.post(`/${this.type}`, attributes);
};

export default FreshchatIds;
