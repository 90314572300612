import historyStyles from "modules/applications/components/credit-checks/credit-works/css/History.css";
import styles from "modules/applications/components/css/CommonReviewBusiness.css";
import moment from "moment";
import React, { ReactElement } from "react";
import { SubHeading } from "modules/applications/components/credit-checks/credit-works/v2/Container/styles";

type Notification = {
  dateCreated: string,
  notificationTypeDescription: string,
  dateSent: string,
  additionalComments: string,
  vendorName: string,
}

type Props = {
  data: Notification[],
}

function Notifications(props: Props): ReactElement {
  const { data } = props;

  const getNotifications = () => data.map(({
    notificationTypeDescription,
    dateCreated,
    vendorName,
  }) => (
    <tr key={`notification-${vendorName}-${dateCreated}`}>
      <th>{notificationTypeDescription}</th>
      <td>{moment(dateCreated).format("DD-MMM-YYYY")}</td>
      <td>{vendorName}</td>
    </tr>
  ))

  return (
    <div className={historyStyles.container}>
      <SubHeading>Notifications</SubHeading>
      {
        data.length === 0 && (
          <p>There have been no notifications filed against this company.</p>
        )
      }
      {
        data.length > 0 && (
          <div className={styles.table_wrapper}>
            <table className={styles.table_w20}>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Date/Time</th>
                  <th>Notified by</th>
                </tr>
              </thead>
              <tbody>{getNotifications()}</tbody>
            </table>
          </div>
        )
      }
    </div>
  );
}

export default Notifications;
