import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/styles";
import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import * as CreditCheckComponents from "modules/applications/components/credit-checks";
import VedaCommercialCheck from "modules/applications/components/VedaCommercialCheck";
import VedaConsumerCheck from "modules/applications/components/VedaConsumerCheck";
import VedaLegacyCheck from "modules/applications/components/VedaLegacyCheck";
import VedaV2CommercialCheck from "modules/applications/components/VedaV2CommercialCheck";
import styles from "modules/new-applications/css/CreditCheck.css";
import FixedContent from "modules/shared/components/containers/FixedContent";
import ScrollableContent from "modules/shared/components/containers/ScrollableContent";
import { canUserReviewCreditChecks } from "modules/shared/helpers/currentUserHelper";
import { TabScrollButton } from "mui-latest";
import Tab from "mui-latest/Tab";
import Tabs from "mui-latest/Tabs";
import React, { Fragment, useState } from "react";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

const VEDA_DEFAULT_PAGE_SIZE = 10;

const CustomTabScrollButton = withStyles(theme => {
  return {
    root: {
      "&.Mui-disabled": {
        width: 0,
      },
      overflow: "hidden",
      transition: "width 0.5s",
      width: 28,
    },
  }
})(TabScrollButton)

function InitiatingCreditCheck() {
  return (
    <div className={styles.initiating_credit_check}>
      <CircularProgress size={20} style={{ marginRight: "10px" }} />
      Initiating credit check
    </div>
  );
}

function mixPanelType(application, creditCheckType) {
  if (creditCheckType === "company") {
    return "company";
  }

  if (
    application.legalType === "sole_trader" ||
    application.isPersonalIndividual
  ) {
    return "applicant";
  }

  return "person";
}

function trackMixpanel({ application, creditCheckType, currentUser, person }) {
  const type = mixPanelType(application, creditCheckType);

  const creditCheckRule = application.getSelectedCreditCheckNameByType(
    creditCheckType,
  );

  mixpanel.track(`Apply Credit Check - ${type} - ${creditCheckRule}`, {
    Application: application.id,
    "Entity ID": get(currentUser, "currentEntity.id"),
    "Signature ID": (person || {}).signatureId,
    distinct_id: currentUser.id,
  });
}

function onClickCheckbox({
  application,
  creditCheck,
  creditCheckType,
  currentUser,
  person,
  onUpdateApplicationState,
}) {
  trackMixpanel({ application, creditCheckType, currentUser, person });
  application.runCreditCheck({
    creditCheck,
    creditCheckType,
    currentUser,
    person,
  });
  onUpdateApplicationState();
}

function CreditCheckCheckbox(props) {
  const {
    application,
    currentUser,
    creditCheck,
    creditCheckType,
    label,
    person,
    onUpdateApplicationState,
  } = props;
  const isChecked = isPresent(creditCheck) && creditCheck.canBeRendered;
  const isDisabled =
    isChecked ||
    application.status !== "in_progress" ||
    get(currentUser, "currentUserEntityLink.roleTypes").includes("standard");

  if (creditCheck.isLoading) {
    return <InitiatingCreditCheck />;
  }

  const onChange = () =>
    onClickCheckbox({
      application,
      creditCheck,
      creditCheckType,
      currentUser,
      onUpdateApplicationState,
      person,
    });

  return (
    <FormControlLabel
      classes={{
        label: styles.checkbox_label,
      }}
      control={
        <Checkbox
          className={styles.checkbox}
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
}

function CompanyCheckbox(props) {
  const { application, companyCreditCheck } = props;

  if (
    application.legalType !== "company" ||
    !application.isCompanyCreditCheckRequired
  ) {
    return null;
  }

  return (
    <CreditCheckCheckbox
      creditCheck={companyCreditCheck}
      creditCheckType="company"
      label={application.formattedBusinessName}
      {...props}
    />
  );
}

function PersonCheckbox(props) {
  const { application, creditCheck, person } = props;

  if (!application.isPersonalCreditCheckRequired) {
    return null;
  }

  return (
    <CreditCheckCheckbox
      creditCheck={creditCheck}
      label={person.name}
      person={person}
      creditCheckType="person"
      {...props}
    />
  );
}

function VedaCommercialReport(props) {
  const { application, creditCheck } = props;

  if (creditCheck.isVersion2) {
    return (
      <VedaV2CommercialCheck
        data={creditCheck.attributes.data}
        application={application.attributes}
        default_page_size={VEDA_DEFAULT_PAGE_SIZE}
        check_attributes={creditCheck.attributes}
      />
    );
  }

  return (
    <VedaCommercialCheck
      data={creditCheck.attributes.data}
      application={application.attributes}
      default_page_size={VEDA_DEFAULT_PAGE_SIZE}
      check_attributes={creditCheck.attributes}
    />
  );
}

function VedaCheckReport(props) {
  const { creditCheck, person } = props;

  if (Array.isArray(creditCheck.data)) {
    return (
      <VedaLegacyCheck
        data={creditCheck.attributes.data}
        default_page_size={VEDA_DEFAULT_PAGE_SIZE}
      />
    );
  }

  if (creditCheck.serviceType === "commercial") {
    return <VedaCommercialReport {...props} />;
  }

  return (
    <VedaConsumerCheck
      check_attributes={creditCheck.attributes}
      data={creditCheck.attributes.data}
      default_page_size={VEDA_DEFAULT_PAGE_SIZE}
      person={person}
    />
  );
}

function Report(props) {
  const { application, creditCheck, isVisible } = props;

  if (!isVisible || isBlank(creditCheck) || !creditCheck.canBeRendered) {
    return null;
  }

  if (creditCheck.modelType === "veda_checks") {
    return (
      <FixedContent>
        <VedaCheckReport {...props} />
      </FixedContent>
    );
  }

  const CreditCheckComponent =
    CreditCheckComponents[`${creditCheck.type}Component`];

  return (
    <FixedContent>
      <CreditCheckComponent
        data={creditCheck.data}
        consumer={application.consumer}
        sequence_date={creditCheck.sequenceDate}
        pdf={creditCheck.pdf}
        version={creditCheck.version}
        status={creditCheck.status}
      />
    </FixedContent>
  );
}

function CompanyReport(props) {
  const { application, isVisible } = props;

  if (!application.isCompanyCreditCheckRequired || !isVisible) {
    return null;
  }

  const creditCheck = application.companyCreditCheck;

  return (
    <Fragment>
      <CompanyCheckbox creditCheck={creditCheck} {...props} />
      <Report creditCheck={creditCheck} {...props} />
    </Fragment>
  );
}

function PersonalReport(props) {
  const { isVisible } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <Fragment>
      <PersonCheckbox {...props} />
      <Report {...props} />
    </Fragment>
  );
}

function PersonalCreditChecks(props) {
  const { application, currentTab } = props;
  const creditChecks = application.creditChecks;

  let offset = 0;
  if (application.isCompanyCreditCheckRequired) {
    offset = 1;
  }

  return application.peopleToCreditCheck.map((person, index) => {
    const tabIndex = index + offset;
    const creditCheck =
      creditChecks.find(
        check => check.authorisationId === person.authorisationId,
      ) || {};
    const isVisible = currentTab === tabIndex;

    return (
      <PersonalReport
        key={`personal-report-${index + 1}`}
        isVisible={isVisible}
        creditCheck={creditCheck}
        person={person}
        {...props}
      />
    );
  });
}

function CreditCheckTab(props) {
  return (
    <Tab
      classes={{
        root: styles.tab_root,
        wrapper: styles.tab_wrapper,
      }}
      {...props}
    />
  );
}

function CreditCheckTabs(props) {
  const { application, currentTab, onChangeTab } = props;
  const tabs = [];
  let tabIndex = 0;

  if (application.isCompanyCreditCheckRequired) {
    tabs.push(
      <CreditCheckTab
        key="credit-check-tab-company"
        value={0}
        label="Company"
      />,
    );
    tabIndex += 1;
  }

  application.peopleToCreditCheck.forEach((person, index) => {
    const personaalCreditCheckTab = tabIndex + index;

    tabs.push(
      <CreditCheckTab
        key={`credit-check-tab-${personaalCreditCheckTab}`}
        value={personaalCreditCheckTab}
        label={person.name}
      />,
    );
  });

  return (
    <Paper square elevation={0}>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        onChange={onChangeTab}
        scrollButtons="auto"
        variant="scrollable"
        aria-label="credit check tabs"
        ScrollButtonComponent={CustomTabScrollButton}
      >
        {tabs}
      </Tabs>
    </Paper>
  );
}

function useTabState() {
  const [currentTab, setCurrentTab] = useState(0);
  const onChangeTab = (_, newTab) => setCurrentTab(newTab);

  return { currentTab, onChangeTab };
}

export default function CreditChecks(props) {
  const { currentUser } = props;
  const { currentTab, onChangeTab } = useTabState();
  const canReviewCreditChecks = canUserReviewCreditChecks(currentUser);

  return (
    <ScrollableContent>
      <FixedContent header="Credit checks">
        {
          !canReviewCreditChecks && (
            <div>
              Please contact your team admin for authorising access to credit checks function.
            </div>
          )
        }
        {
          canReviewCreditChecks && (
            <Fragment>
              <div>
                Once a company or Individuals sign and accept your terms of trade you
                will be able to complete a credit check - simply check the tick box.
                *Applicable charges will be applied.
              </div>
              <CreditCheckTabs
                currentTab={currentTab}
                onChangeTab={onChangeTab}
                {...props}
              />
              <CompanyReport isVisible={currentTab === 0} {...props} />
              <PersonalCreditChecks currentTab={currentTab} {...props} />
            </Fragment>
          )
        }
      </FixedContent>
    </ScrollableContent>
  );
}
