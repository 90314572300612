import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { EMAIL_REGEX } from "constants";
import get from "lodash.get";
import Button from "modules/shared/components/inputs/Button";
import styles from "modules/shared/components/v2/Modules/EmailVerification.css";
import CircleLogo from "modules/shared/components/widgets/static/CircleLogo";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const emailVerificationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Please input an email.")
    .matches(EMAIL_REGEX, {
      excludeEmptyString: true,
      message: "Please input a valid email.",
    }),
  verificationCode: yup
    .string()
    .required("Please input verification code")
    .matches(/^[0-9]{6}$/, {
      excludeEmptyString: true,
      message: "Please input a valid verification code.",
    }),
});

function Instruction(props) {
  return <div className={styles.instruction}>{props.content}</div>;
}

function VerificationInputCell(props) {
  return <div className={styles.verification_code_cell}>{props.value}</div>;
}

function VerificationCodeInput(props) {
  const CODE_LENGTH = 6;
  const inputRef = React.createRef();
  const [verificationCode, setVerificationCode] = useState("");
  const [focus, setFocus] = useState(false);

  const selectedIndex =
    verificationCode.length < CODE_LENGTH ?
      verificationCode.length :
      CODE_LENGTH - 1;

  const handleChange = event => {
    const value = event.target.value;
    const newValue = (verificationCode + value).slice(0, CODE_LENGTH);
    setVerificationCode(newValue);
    props.onChange(newValue);
  };

  const handleKeyUp = e => {
    if (e.key === "Backspace") {
      const newValue = verificationCode.slice(0, verificationCode.length - 1);
      setVerificationCode(newValue);
      props.onChange(newValue);
    }
  };

  return (
    <div>
      <div className={styles.row}>
        <Instruction content="Please enter below:" />
      </div>
      <div
        className={styles.verification_code}
        onClick={() => {
          inputRef.current.focus();
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
      >
        {new Array(CODE_LENGTH).fill(0)
          .map((v, index) => (
            <VerificationInputCell
              value={verificationCode[index]}
              key={`vc-${index}`}
            />
          ))}
        <input
          value=""
          className={`${styles.verification_code_input} ${focus &&
            styles.verification_code_input_shadow}`}
          ref={inputRef}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          style={{ left: `${16.66666666 * selectedIndex}%` }}
        />
      </div>
      <div className={styles.verification_code_error}>{props.helperText}</div>
      <div className={styles.row}>
        <Instruction
          content={
            <div>
              If you don’t receive the verification code please click{" "}
              <a href="mailto:support@1centre.com">here</a>.
            </div>
          }
        />
      </div>
    </div>
  );
}

function SentButton(props) {
  const [disableSeconds, setDisableSeconds] = useState(0);

  const disableSecondsDecrease = () => {
    setDisableSeconds(disableSeconds - 1);
  };

  const disableSentButton = async() => {
    const result = await props.validEmail();
    if (result) {
      setDisableSeconds(10);
      props.sendEmail();
    }
  };

  useEffect(() => {
    if (disableSeconds > 0) setTimeout(disableSecondsDecrease, 1000);
  });

  const buttonText = disableSeconds <= 0 ? "Send" : disableSeconds;
  const disabled = props.disabled || disableSeconds > 0;

  return (
    <InputAdornment onClick={!disabled && disableSentButton} position="end">
      <Button style={{ marginBottom: "20px" }} text={buttonText} />
    </InputAdornment>
  );
}

export default function EmailVerification(props) {
  const { application, location } = props;

  const {
    errors,
    handleSubmit,
    getValues,
    setError,
    setValue,
    register,
    triggerValidation,
  } = useForm({
    mode: "onBlur",
    validationSchema: emailVerificationSchema,
  });

  const [verificationCode, setVerificationCode] = useState("");
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(
    true,
  );

  useEffect(() => {
    register({ name: "verificationCode" });
  }, [register]);

  const onSubmit = data => {
    application.setAttributes(data);
    (async() => {
      const result = await application.validateCode();
      if (result.data.valid) {
        await application.load(props.supplierId);
        props.toNextSection();
      } else {
        setError(
          "verificationCode",
          "notMatch",
          "Verification code is incorrect.",
        );
      }
    })();
  };

  const verificationCodeOnChange = value => {
    setVerificationCode(value);
    setValue("verificationCode", value);
  };

  const sendEmail = () => {
    const values = getValues();
    application.setAttributes({
      ...values,
      email: values.email.trim(),
      channel: location.query.channel,
    });
    setShowVerificationCodeInput(true);
    application.sendVerificationCodeEmail();
  };

  // there is a bug about react-hook-form
  // if the validation of verificationCode was false
  // triggerValidation("email") will also return false
  // even the email may be a valid value
  const validEmail = async() => {
    const yupResult = await triggerValidation("email");
    const email = getValues().email.trim();
    return yupResult || email.match(EMAIL_REGEX);
  };

  const verificationCodeElement = (
    <VerificationCodeInput
      onChange={verificationCodeOnChange}
      error={Boolean(errors.verificationCode)}
      helperText={get(errors, "verificationCode.message", " ")}
    />
  );

  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <CircleLogo
            layout="application"
            src={props.supplierLogoUrl}
            alt="Your logo"
          />
        </div>
        <div className={styles.title}>Verification</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <TextField
              name="email"
              inputRef={register}
              error={Boolean(errors.email)}
              helperText={get(errors, "email.message", " ")}
              classes={{
                root: styles.email_root,
              }}
              placeholder="Please input your email here"
              InputProps={{
                endAdornment: (
                  <SentButton
                    getValues={getValues}
                    validEmail={validEmail}
                    sendEmail={sendEmail}
                  />
                ),
              }}
            />
          </div>
          <Instruction
            content="A verification code will be sent to your email address as an extra security measure."
          />
          {showVerificationCodeInput && verificationCodeElement}
          <div className={styles.button}>
            <Button
              text="Next"
              type="submit"
              loading={application.isLoading}
            />
          </div>
        </form>
      </div>
    </section>
  );
}
