import React from "react";
import moment from "moment";

import styles from "../centrix.css";

class FileAccess extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getArrayifiedData(data) {
    if (
      !data.data_sets.previous_enquiries ||
      !data.data_sets.previous_enquiries.previous_enquiry
    ) {
      return [];
    }
    let previous_enquiries = data.data_sets.previous_enquiries.previous_enquiry;
    if (previous_enquiries.constructor !== Array) {
      return [previous_enquiries];
    }
    return previous_enquiries;
  }

  render() {
    const { data } = this.props;
    let arrayfied_data = this.getArrayifiedData(data);

    return (
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Date of Enquiry</th>
              <th>Enquirer</th>
              <th>Enquiry Reason</th>
              <th>Reason</th>
              <th>Product Type</th>
              <th>Amount Sought</th>
              <th>Applicant Type</th>
            </tr>
          </thead>
          <tbody>
            {arrayfied_data.map(item => (
              <tr>
                <td>{moment(item.date_of_access).format("DD/MM/YYYY")}</td>
                <td>{item.enquirer_name}</td>
                <td>{item.enquiry_reason_description}</td>
                <td>{item.enquiry_reference}</td>
                <td>
                  {item.product_type_description &&
                    item.product_type_description.constructor === String &&
                    item.product_type_description}
                </td>
                <td>
                  {item.amount_sought &&
                    item.amount_sought.constructor === String &&
                    "$" + item.amount_sought}
                </td>
                <td>{item.applicant_type_description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default FileAccess;
