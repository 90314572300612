import React, { useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import { FormControlLabel, Typography } from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/core/styles";
import styles from "./css/Switch.css";
import get from "lodash.get";

const _style = (colorGray, colorRed) => ({
  switchBase: {
    color: `${colorGray}`,
    "&$checked": {
      color: `${colorRed}`,
    },
    "&$checked + $track": {
      backgroundColor: `${colorRed}`,
    },
  },
  checked: {},
  track: {},
});

export default function Switch2(props) {
  const [state, setState] = React.useState(props.checked);
  const theme = useTheme();
  const color = get(theme, "palette.primary.main", "blue");
  const BlueSwitch = withStyles(_style("#a5a5a5", color))(Switch);

  useEffect(() => {
    setState(props.checked);
  });

  const handleChange = event => {
    setState(event.target.checked);
    props.prevent && event.stopPropagation();
    props.callback(event.target.checked);
  };

  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <BlueSwitch
            checked={state}
            edge="end"
            onChange={handleChange}
            onClick={event => props.prevent && event.stopPropagation()}
            name={props.name}
            inputRef={props.register}
          />
        }
        label={<Typography style={{ fontSize: 10 }}>{props.label}</Typography>}
        onClick={event => props.prevent && event.stopPropagation()}
      />
      {props.children}
    </React.Fragment>
  );
}
