import axios from "./axios";;

let Deactivate = function(config) {
  config.timeout = 10000;
  this.axios = axios(config);
  this.type = "deactivate";
};

Deactivate.prototype.deactivate = function(success, error, options = {}) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

export default Deactivate;
