import api from "api";
import get from "lodash.get";
import { getCardsAddonRule } from "utils/cardsAddon";
import isPresent from "utils/isPresent";

import {
  COB_CARDS_LOAD_CARDHOLDERS_ERROR,
  COB_CARDS_LOAD_CARDHOLDERS_START,
  COB_CARDS_LOAD_CARDHOLDERS_SUCCESS,
  COB_CARDS_PRE_POPULATE_DETAILS,
  COB_CARDS_REMOVE_CARDHOLDER,
  COB_CARDS_REMOVE_CARDHOLDER_DEPRECATED,
  COB_CARDS_SAVE_CARDHOLDER_ERROR,
  COB_CARDS_SAVE_CARDHOLDER_START,
  COB_CARDS_SAVE_CARDHOLDER_SUCCESS,
  COB_CARDS_SAVE_CARDHOLDERS_SUCCESS,
  COB_CARDS_SET_CARDHOLDER_ADDON_ANSWER,
  COB_CARDS_SET_CARDHOLDER_DETAIL,
  COB_CARDS_SET_CARDHOLDERS_COUNT,
  COB_CARDS_SET_CARDHOLDERS_COUNT_COMPLETED,
  COD_CARDS_REMOVE_APPLICANT_CARDHOLDER,
  COD_CARDS_SET_APPLICANT_CARDHOLDER,
} from "../constants/cards";
import {
  COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_ERROR,
  COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_START,
  COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_SUCCESS,
  COB_TRADEACCOUNT_SET_CARDHOLDERS_COUNT,
} from "../constants/section";

export function loadCardholders(applicationId) {
  return async(dispatch, getState) => {
    dispatch({ type: COB_CARDS_LOAD_CARDHOLDERS_START });

    const accessToken = getState().current_user.access_token;
    try {
      const response = await api("applications", accessToken).getCardholders(
        applicationId,
      );
      const data = sortCardholders(response.data.data || []);

      dispatch(setCardholdersData(data));
    } catch (error) {
      dispatch({
        payload: error,
        type: COB_CARDS_LOAD_CARDHOLDERS_ERROR,
      });
    }
  };
}

export function setCardholdersData(cardholders) {
  return dispatch => {
    dispatch({
      payload: cardholders,
      type: COB_CARDS_LOAD_CARDHOLDERS_SUCCESS,
    });
  };
}

/**
 * Sort cardholders wherein the applicant cardholder goes first
 */
function sortCardholders(cardholders) {
  return cardholders.sort((a, b) => {
    if (a.attributes.isApplicant && !b.attributes.isApplicant) {
      return -1;
    }

    if (!a.attributes.isApplicant && b.attributes.isApplicant) {
      return 1;
    }

    return 0;
  });
}

export function setCardholdersCount(count) {
  return dispatch => {
    dispatch({
      payload: count,
      type: COB_TRADEACCOUNT_SET_CARDHOLDERS_COUNT,
    });
  };
}

export function setCardholdersCountCompletedState(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state) {
      meta = {
        mixpanel: {
          event: "Consumer onboarding",
          props: {
            Component: component || "start",
            Section: "cardholders",
            distinct_id: getState().current_user.data.data.id,
          },
        },
      };
    }

    dispatch({
      meta,
      payload: { component, state },
      type: COB_CARDS_SET_CARDHOLDERS_COUNT_COMPLETED,
    });
  };
}

export function updateCardholdersCount(application, successCallback) {
  return (dispatch, getState) => {
    dispatch({
      type: COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_START,
    });

    const accessToken = getState().current_user.access_token;
    api("applications", accessToken).updateApplication(
      application.id,
      { cardholders_count: application.attributes.cardholders_count },
      () => {
        dispatch(
          updateCardholdersCountCallback(
            application.attributes.cardholders_count,
          ),
        );
        if (successCallback) {
          dispatch(successCallback);
        }
      },
      error => {
        dispatch({
          payload: error,
          type: COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_ERROR,
        });
      },
    );
  };
}

function updateCardholdersCountCallback(cardholdersCount) {
  return (dispatch, getState) => {
    dispatch({ type: COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_SUCCESS });

    const cardholders = getState().cob_cards.cardholders;

    // This is for an instance where the user set number of cardholders,
    // fills out the details of the cardholders (records are now persisted in the
    // database) then decided to reduce the number of cardholders leaving us
    // with more cardholder records that the actual cardholder count.
    // We will just remove the excess records when this happens.
    if (cardholders.length <= cardholdersCount) {
      return;
    }

    for (let i = cardholders.length - 1; i >= cardholdersCount; i--) {
      dispatch(deleteCardholderDeprecated(cardholders[i], i));
    }
  };
}

export function deleteCardholder(index) {
  return dispatch => {
    dispatch({
      payload: { index },
      type: COB_CARDS_REMOVE_CARDHOLDER,
    });
  };
}

export function deleteCardholderDeprecated(cardholder, index) {
  return async(dispatch, getState) => {
    if (isPresent(cardholder.id)) {
      const accessToken = getState().current_user.access_token;
      const cardholderAPI = api("cardholders", accessToken);

      await cardholderAPI.deleteCardholderDeprecated(cardholder.id);
    }

    dispatch({
      payload: { index },
      type: COB_CARDS_REMOVE_CARDHOLDER_DEPRECATED,
    });
  };
}

export function prePopulateCardholderDetails(index, attributes) {
  return dispatch => {
    dispatch({
      payload: { attributes, index },
      type: COB_CARDS_PRE_POPULATE_DETAILS,
    });
  };
}

// When a cardholder has already been ticked as an applicant,
// set it to false when a new cardholder has been set as an applicant
export function removeCurrentCardholderApplicant(currentIndex) {
  return (dispatch, getState) => {
    const cardholders = getState().cob_cards.cardholders;
    const index = cardholders.findIndex(
      cardholder => cardholder.attributes.isApplicant,
    );

    if (index === -1 || index === currentIndex) {
      return;
    }

    dispatch(setCardholderDetails(index, "isApplicant", false));
    dispatch(saveCardholder(index));
  };
}

export function addCardholders(cardholdersCount, config) {
  return dispatch => {
    dispatch({
      payload: {
        config,
        count: cardholdersCount,
      },
      type: COB_CARDS_SET_CARDHOLDERS_COUNT,
    });
  };
}

export function addApplicantCardholder(attributes = {}) {
  return dispatch => {
    dispatch({
      payload: { attributes },
      type: COD_CARDS_SET_APPLICANT_CARDHOLDER,
    });
  };
}

export function removeApplicantCardholder() {
  return dispatch => {
    dispatch({
      type: COD_CARDS_REMOVE_APPLICANT_CARDHOLDER,
    });
  };
}

export function setCardholderDetails(index, key, value, config = {}) {
  return dispatch => {
    dispatch({
      payload: { config, index, key, value },
      type: COB_CARDS_SET_CARDHOLDER_DETAIL,
    });
  };
}

export function setCardholderAddonAnswer(index, answer) {
  return dispatch => {
    dispatch({
      payload: { answer, index },
      type: COB_CARDS_SET_CARDHOLDER_ADDON_ANSWER,
    });
  };
}

export function saveCardholder(index, successCallback) {
  return async(dispatch, getState) => {
    dispatch({ type: COB_CARDS_SAVE_CARDHOLDER_START });

    const accessToken = getState().current_user.access_token;
    const cardholder = getState().cob_cards.cardholders[index];
    const { id, attributes } = cardholder;

    try {
      const cardholderAPI = api("cardholders", accessToken);

      let response;

      if (id) {
        response = await cardholderAPI.updateCardholder(id, attributes);
        if (successCallback) {
          dispatch(successCallback);
        }
      } else {
        response = await cardholderAPI.createCardholder(attributes);
        if (successCallback) {
          dispatch(successCallback);
        }
      }

      dispatch({
        payload: {
          index,
          value: response.data.data,
        },
        type: COB_CARDS_SAVE_CARDHOLDER_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: COB_CARDS_SAVE_CARDHOLDER_ERROR,
      });
    }
  };
}

export function saveCardholders(successCallback) {
  return async(dispatch, getState) => {
    dispatch({ type: COB_CARDS_SAVE_CARDHOLDER_START });

    const accessToken = getState().current_user.access_token;
    const addon_rule_id = getCardsAddonRule(getState().cob_section.addonRules)
      .id;
    const cardholders = getState().cob_cards.cardholders.map(cardholder => {
      delete cardholder.attributes.addonRuleId;
      delete cardholder.attributes.fullName;
      return cardholder;
    });
    const application_id = getState().cob_section.application.id;

    try {
      const cardholderAPI = api("cardholders", accessToken);

      if (application_id) {
        const response = await cardholderAPI.updateAndCreateCardholders({
          addon_rule_id,
          application_id,
          data: cardholders,
        });
        if (successCallback) {
          dispatch(successCallback);
        }

        dispatch({
          payload: { value: get(response, "data.data", []) },
          type: COB_CARDS_SAVE_CARDHOLDERS_SUCCESS,
        });
      }
    } catch (error) {
      dispatch({
        payload: error,
        type: COB_CARDS_SAVE_CARDHOLDER_ERROR,
      });
    }
  };
}

export function clearCardholders() {
  return async(dispatch, getState) => {
    dispatch({ type: COB_CARDS_SAVE_CARDHOLDER_START });
    const accessToken = getState().current_user.access_token;
    const addon_rule_id = getCardsAddonRule(getState().cob_section.addonRules).id;
    const application_id = getState().cob_section.application.id;

    try {
      const cardholderAPI = api("cardholders", accessToken);

      if (application_id) {
        const response = await cardholderAPI.updateAndCreateCardholders({
          addon_rule_id,
          application_id,
          data: [],
        });

        dispatch({
          payload: { value: get(response, "data.data", []) },
          type: COB_CARDS_SAVE_CARDHOLDERS_SUCCESS,
        });
      }
    } catch (error) {
      dispatch({
        payload: error,
        type: COB_CARDS_SAVE_CARDHOLDER_ERROR,
      });
    }
  }
}
