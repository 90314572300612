import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Button from "modules/shared/components/inputs/Button";
import TextInput from "modules/shared/components/inputs/TextInput";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import React, { Component } from "react";
import { browserHistory } from "react-router";
import { isValidEmail } from "utils/validators";

import styles from "./css/ConsumerNameSearch.css";

export default class NewRecipientModal extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      emailValid: false,
      selectedUser: null,
    };
  }

  _isValid() {
    return this.state.emailValid || !!this.state.selectedUser;
  }

  setValue = (k, v) => {
    const { onConfirm } = this.props;
    const value = v.toLowerCase();
    this.setState({
      email: v,
      emailValid: isValidEmail(value),
      selectedUser: null,
    });
    onConfirm({ currentContact: null, newContact: { email: v } });
  };

  handleCancel = () => {
    const { onClose, onConfirm } = this.props;
    this.setState({ email: "", emailValid: false });
    onConfirm({ newContact: { email: "" } });
    onClose();
  };

  handleOk = () => {
    const { nextPath } = this.props;
    browserHistory.push(nextPath);
  };

  handleSelect = event => {
    const { users, onConfirm } = this.props;
    const selectedUser = users.find(user => user.id === event.target.value);
    this.setState({ email: "", emailValid: false, selectedUser });
    onConfirm({ currentContact: { ...selectedUser }, newContact: null });
  };

  renderInputs(inputName, index) {
    return (
      <TextInput
        key={`text-inpute-${index}`}
        type={formInputs[inputName].type}
        label={formInputs[inputName].label}
        required={formInputs[inputName].required}
        value={this.state[inputName]}
        onChange={event => this.setValue(inputName, event.target.value)}
      />
    );
  }

  renderNextButton() {
    const { nextPath } = this.props;
    let button = (
      <Button
        text={"Next"}
        onClick={this.handleOk}
        disabled={!this._isValid()}
      />
    );
    if (nextPath.includes("invite-existing")) {
      button = (
        <Button
          text={"Send application"}
          onClick={this.handleOk}
          disabled={!this._isValid()}
        />
      );
    }
    return button;
  }

  render() {
    const { companyName, open, users } = this.props;
    const InputList = Object.keys(formInputs).map((inputName, index) =>
      this.renderInputs(inputName, index),
    );
    const options = users.map(user => {
      return { label: user.email, value: user.id };
    });

    return (
      <MuiThemeProvider theme={muiTheme()}>
        <Dialog open={open} classes={{ paper: styles.new_recipient_modal }}>
          <DialogTitle className={styles.modal_title}>
            {"Select/add recipient"}
            <HighlightOffIcon
              className={styles.modal_close_button}
              onClick={this.handleCancel}
            />
          </DialogTitle>
          <DialogContent>
            <div className={styles.new_recipient_company_name}>
              {companyName}
            </div>
            {InputList}
            <RadioGroup
              value={this.state.selectedUser && this.state.selectedUser.id}
              onChange={event => this.handleSelect(event)}
            >
              {options.map(option => (
                <FormControlLabel
                  classes={{
                    label: styles.radio_control_label,
                  }}
                  value={option.value}
                  key={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </DialogContent>
          <div className={styles.modal_button_container}>
            {this.renderNextButton()}
          </div>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

const formInputs = {
  email: {
    label: "New recipient email",
    required: true,
    type: "text",
  },
};
