import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { countTypeValue } from "./helpers/summary";
import { displayEmpty } from "utils/formatting";
import { enquiryResponseData } from "./helpers/reportHelpers";
import { stringToSimpleDate } from "utils/dateFormatter";
import styles from "../../../css/CommonReviewBusiness.css";

var CompanyAddresses = createClass({
  _addresses() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      asicDocuments = response.asic_documents || {},
      companyAddressList = asicDocuments.company_address_list || {},
      currentCompanyAddresses =
        companyAddressList.current_company_addresses || [];

    if (currentCompanyAddresses.constructor === Object)
      currentCompanyAddresses = [currentCompanyAddresses];

    return currentCompanyAddresses;
  },

  _fullAddress(address) {
    if (address === null || address === undefined) return displayEmpty("");

    let addressComponents = [
      address.address_prefix,
      address.street_details,
      address.locality_details,
      address.state,
      address.postcode,
      address.country,
    ].filter(field => {
      return field !== undefined && field !== null && field !== "";
    });

    return addressComponents.join(", ");
  },

  _addressSummary() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      companyIdentity = response.company_identity || {},
      principalPlaceOfBusiness =
        companyIdentity.principal_place_of_business || {},
      registeredOffice = companyIdentity.registered_office || {};

    return (
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <th colSpan={2}>Current Registered Office</th>
              <th colSpan={2}>Principal Place of Business</th>
            </tr>
            <tr>
              <th>Address</th>
              <td>
                {this._fullAddress(
                  principalPlaceOfBusiness.address_lines || {},
                )}
              </td>
              <th>Address</th>
              <td>{this._fullAddress(registeredOffice.address_lines || {})}</td>
            </tr>
            <tr>
              <th>From</th>
              <td>
                {stringToSimpleDate(
                  principalPlaceOfBusiness.first_reported_date,
                )}
              </td>
              <th>From</th>
              <td>
                {stringToSimpleDate(registeredOffice.first_reported_date)}
              </td>
            </tr>
            <tr>
              <th>Document</th>
              <td>
                {displayEmpty(
                  (principalPlaceOfBusiness.document_details || {})
                    .document_number,
                )}
              </td>
              <th>Document</th>
              <td>
                {displayEmpty(
                  (registeredOffice.document_details || {}).document_number,
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },

  _companyAddresses() {
    return this._addresses().map((address, index) => {
      let addressPlus = address.address_plus;

      return (
        <div className={styles.table_wrapper} key={index}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Address</th>
                <td>{this._fullAddress(addressPlus)}</td>
                <th>Type</th>
                <td>{displayEmpty(addressPlus.address_flag)}</td>
              </tr>
              <tr>
                <th>From</th>
                <td>{stringToSimpleDate(addressPlus.address_start_date)}</td>
                <th>Document</th>
                <td>
                  {displayEmpty(
                    (address.document_details || {}).document_number,
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Company Addresses"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>
            Total: {countTypeValue(summaryEntry, "Company_Addresses")}
          </strong>
        </p>
        {this._addressSummary()}
        <hr />
        {this._companyAddresses()}
      </ReviewContainer>
    );
  },
});

export default CompanyAddresses;
