import React, { ReactElement } from 'react'
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import { SelectDropdownProps } from './types';

const accountOptions = [
  { label: 'Credit', value: 'credit', brief: 'Credit' },
  { label: 'Cash', value: 'cash', brief: 'Cash' },
  { label: 'Leads', value: 'leads', brief: 'Leads' },
];


function AccountTypeSelectDropdown(props: SelectDropdownProps): ReactElement {
  const { handleChange } = props;

  return (
    <SimpleMultiSelectDropdown
      options={accountOptions}
      onChange={handleChange}
      id="entity-type-select"
      {...props}
    />
  )
}

export default AccountTypeSelectDropdown
