import { EMAIL_REGEX } from "constants";
import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import australiaValidationSchema from "modules/paperless-onboarding/components/Details/Australia/australiaValidationSchema";
import getAustraliaAttributes from "modules/paperless-onboarding/components/Details/Australia/getAustraliaAttributes";
import getNewZealandAttributes from "modules/paperless-onboarding/components/Details/NewZealand/getNewZealandAttributes";
import newZealandValidationSchema from "modules/paperless-onboarding/components/Details/NewZealand/newZealandValidationSchema";
import styles from "modules/paperless-onboarding/css/Details.css";
import commonStyles from "modules/paperless-onboarding/css/Section.css";
import Button from "modules/shared/components/inputs/Button";
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import UncontrolledTextInput from "modules/shared/components/inputs/UncontrolledTextInput";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const commonValidationSchema = {
  account_name: yup
    .string()
    .required("Please input an account name.")
    .trim(),
  account_number: yup
    .string()
    .required("Please input an account number.")
    .trim(),
  bank_account_name: yup
    .string()
    .required("Please input bank account name.")
    .trim(),
  first_name: yup
    .string()
    .required("Please input a first name.")
    .trim(),
  last_name: yup
    .string()
    .required("Please input a last name.")
    .trim(),
  require_signatories: yup.boolean(),
  signatories: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .required("Please input an email.")
        .matches(EMAIL_REGEX, {
          excludeEmptyString: true,
          message: "Please input a valid email.",
        })
        .trim(),
      first_name: yup
        .string()
        .required("Please input a first name.")
        .trim(),
      last_name: yup
        .string()
        .required("Please input a last name.")
        .trim(),
    }),
  ),
};

function ApplicantForm(props) {
  const { application, errors, inputRef } = props;
  const { supplierRegion } = application;

  const [bankAccountNumber, setBankAccountNumber] = useState(
    application.bankAccountNumber || "",
  );

  const [bankNumber, setBankNumber] = useState(application.bankNumber || "");

  const onChangeBankAccountNumber = e => {
    const value = e.target.value;
    setBankAccountNumber(value);
  };

  const onChangeBankNumber = e => {
    const value = e.target.value;
    setBankNumber(value);
  };

  const commonFields = [
    [
      {
        label: application.attributes.account_name_label || "Account Name",
        name: "account_name",
        required: true,
        styleClass: "half_col",
      },
      {
        label: application.attributes.account_number_label || "Account Number",
        name: "account_number",
        required: true,
        styleClass: "half_col",
      },
    ],
    [
      {
        label: "First name",
        name: "first_name",
        required: true,
        styleClass: "half_col",
      },
      {
        label: "Last name",
        name: "last_name",
        required: true,
        styleClass: "half_col",
      },
    ],
  ];

  let regionSpecificFields = [];

  switch (supplierRegion) {
    case "AU":
      regionSpecificFields = getAustraliaAttributes({
        bankAccountNumber,
        bankNumber,
        onChangeBankAccountNumber,
        onChangeBankNumber,
        setBankAccountNumber,
        setBankNumber,
      });
      break;
    case "NZ":
      regionSpecificFields = getNewZealandAttributes({
        bankAccountNumber,
        onChangeBankAccountNumber,
        setBankAccountNumber,
      });
      break;
  }

  const inputForm = [...commonFields, ...regionSpecificFields];

  return (
    <div>
      {inputForm.map((row, index) => (
        <div key={`row=${index}`} className={commonStyles.row}>
          {row.map(inputProps => {
            const { label, name, styleClass } = inputProps;

            return (
              <div key={label} className={commonStyles[styleClass]}>
                <UncontrolledTextInput
                  id={name}
                  defaultValue={application.attributes[name]}
                  error={get(errors, `${name}.message`, "")}
                  inputRef={inputRef}
                  {...inputProps}
                />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}

function Conditions(props) {
  return (
    <div className={styles.terms_block}>
      <span className={styles.terms}>{props.application.attributes.terms}</span>
    </div>
  );
}

function JointSignatory(props) {
  const { index, errors, inputRef, defaultValue } = props;
  const inputForm = [
    { label: "First name", name: "first_name", required: true },
    { label: "Last name", name: "last_name", required: true },
    { label: "Email", name: "email", required: true },
  ];

  return (
    <div className={styles.signatory_block}>
      <div className={styles.signatory_title}>{`Signatory ${index + 2}`}</div>
      <div className={commonStyles.row}>
        {inputForm.map(input => (
          <div key={input.label} className={commonStyles.one_third_col}>
            <UncontrolledTextInput
              id={`signatories[${index}]${input.name}`}
              label={input.label}
              error={get(
                errors,
                `signatories[${index}]${input.name}.message`,
                "",
              )}
              inputRef={inputRef}
              defaultValue={(defaultValue || {})[input.name]}
            />
          </div>
        ))}
      </div>
      <div className={styles.signatory_button}>
        {props.showDelete && <Button text="Delete" onClick={props.delete} />}
        {props.showAdd && <Button text="Add" onClick={props.add} />}
      </div>
    </div>
  );
}

function JointSignatories(props) {
  const { application } = props;
  const signatories = application.attributes.signatories || [];

  const [requireJointSignatories, toggleRequireJointSignatories] = useState(
    false,
  );
  const [jointSignatoriesNumber, setJointSignatoriesNumber] = useState(0);

  const requireJointSignatoriesOnChange = () => {
    toggleRequireJointSignatories(!requireJointSignatories);
    if (jointSignatoriesNumber === 0) {
      setJointSignatoriesNumber(1);
    }
  };

  const addOneSignatory = () => {
    setJointSignatoriesNumber(jointSignatoriesNumber + 1);
  };

  const deleteOneSignatory = index => {
    props.deleteSignatory(index);
    setJointSignatoriesNumber(jointSignatoriesNumber - 1);
  };

  useEffect(() => {
    toggleRequireJointSignatories(application.attributes.require_signatories);
    setJointSignatoriesNumber(signatories.length);
  }, []);
  const jointSignatoriesElement = Array(jointSignatoriesNumber)
    .fill(0)
    .map((_, index) => (
      <JointSignatory
        key={`JointSignatory-${index}`}
        inputRef={props.inputRef}
        errors={props.errors}
        index={index}
        add={addOneSignatory}
        delete={() => {
          deleteOneSignatory(index);
        }}
        showAdd={jointSignatoriesNumber === index + 1}
        showDelete={jointSignatoriesNumber > 1}
        defaultValue={signatories[index]}
      />
    ));

  return (
    <div>
      <div
        className={commonStyles.row}
        onClick={requireJointSignatoriesOnChange}
      >
        <SquareCheckbox
          label={"Does this account require more than one signature?"}
          checked={requireJointSignatories}
          inputRef={props.inputRef}
          checkboxName={"require_signatories"}
        />
      </div>
      {requireJointSignatories && jointSignatoriesElement}
    </div>
  );
}

const getRegionSpecificValidationSchema = region => {
  switch (region) {
    case "AU":
      return australiaValidationSchema;
    case "NZ":
      return newZealandValidationSchema;
  }
};

export default function Details(props) {
  const { location, paperlessApplication } = props;

  const regionSpecificValidationSchema = getRegionSpecificValidationSchema(
    paperlessApplication.supplierRegion,
  );
  const validationSchema = yup.object().shape({
    ...commonValidationSchema,
    ...regionSpecificValidationSchema,
  });

  const { errors, getValues, handleSubmit, setValue, register } = useForm({
    mode: "onBlur",
    validationSchema,
  });

  useEffect(() => {
    mixpanel.identify(paperlessApplication.userInformationId);
    mixpanel.track("DDM started", {
      distinct_id: paperlessApplication.userInformationId,
    });
  }, []);

  // Location only get updated after Details component is mounted
  useEffect(() => {
    if (get(location, "query.email", false)) {
      mixpanel.people.set({
        $email: location.query.email,
      });
    }
  }, [location]);

  const deleteSignatory = index => {
    const values = getValues();
    Object.keys(values).forEach(k => {
      if (k.includes("signatories[")) {
        const [_, i, name] = k.split(/\[|\]/);
        const indexOfSignatory = parseInt(i);
        if (indexOfSignatory >= index) {
          setValue(k, values[`signatories[${indexOfSignatory + 1}]${name}`]);
        }
      }
    });
  };

  const onSubmit = data => {
    paperlessApplication.setAttributes(data);
    (async() => {
      const result = await paperlessApplication.saveDetails(props.supplierId);
      if (result.status === 200) {
        mixpanel.people.set({
          $first_name: data.first_name,
          $last_name: data.last_name,
        });
        paperlessApplication.setAttributes(result.data);
        props.toNextSection();
      }
    })();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={commonStyles.container}>
          <section className={commonStyles.section}>
            <div className={commonStyles.row}>
              <div className={commonStyles.panel}>
                <PanelTitle text={"Details"} />
              </div>
            </div>
            <ApplicantForm
              application={paperlessApplication}
              inputRef={register}
              errors={errors}
            />
            <Conditions application={paperlessApplication} />
            <JointSignatories
              application={paperlessApplication}
              inputRef={register}
              errors={errors}
              deleteSignatory={deleteSignatory}
            />
            <div className={commonStyles.flow_buttons}>
              <Button
                text="Next"
                type="submit"
                loading={paperlessApplication.isLoading}
              />
            </div>
          </section>
        </div>
      </form>
    </div>
  );
}
