import { takeEvery } from "redux-saga";
import {
  createTradeReferenceCheck,
  updateTradeReferenceCheck,
} from "./modules/trade-references/sagas";
import fetchCurrentProfile from "./modules/profile/sagas";

import {
  TRADE_REFERENCES_CREATE_CHECK,
  TRADE_REFERENCES_UPDATE_CHECK_START,
} from "./modules/trade-references/constants";

import { MANAGE_PROFILE_LOAD_CURRENT_ENTITY_START } from "./modules/profile/constants";

function* rootSaga() {
  yield takeEvery(
    MANAGE_PROFILE_LOAD_CURRENT_ENTITY_START,
    fetchCurrentProfile,
  );
  yield takeEvery(TRADE_REFERENCES_CREATE_CHECK, createTradeReferenceCheck);
  yield takeEvery(
    TRADE_REFERENCES_UPDATE_CHECK_START,
    updateTradeReferenceCheck,
  );
}

export default rootSaga;
