import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#supplier-terms-creating-a-supplier-terms-ruleset
 */
let AuthorityDeclarations = function(config) {
  this.axios = axios(config);
  this.type = "authority_declarations";
};

AuthorityDeclarations.prototype.createAuthorityDeclarationForEntity = function(
  entityId,
  attributes,
  success,
  error,
) {
  return this.createAuthorityDeclaration(
    entityId,
    "entities",
    attributes,
    success,
    error,
  );
};

AuthorityDeclarations.prototype.createAuthorityDeclarationForApplication = function(
  applicationId,
  attributes,
  success,
  error,
) {
  return this.createAuthorityDeclaration(
    applicationId,
    "applications",
    attributes,
    success,
    error,
  );
};

AuthorityDeclarations.prototype.createAuthorityDeclaration = function(
  authorisableId,
  type,
  attributes,
  success,
  error,
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
        relationships: {
          authorisable: {
            data: {
              type: type,
              id: authorisableId,
            },
          },
        },
      },
    })
    .then(success)
    .catch(error);
};

export default AuthorityDeclarations;
