import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import get from "lodash.get";
import {
  componentComplete,
  lookupCompanyName,
  resetCompanyNameLookup,
  selectCompanyName,
} from "modules/consumer-invite/actions/invite";
import AutoSuggest, {
  SearchInput,
} from "modules/shared/components/widgets/interactive/consumer_name_search/AutoSuggest";
import PopperTooltip from "modules/shared/components/widgets/interactive/PopperToolTip";
import React, { Component } from "react";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import styles from "./css/InviteCompanyName.css";

class InviteCompanyName extends Component {
  constructor() {
    super();
    this.lookupCompanyName = debounce(500, false, this.lookupCompanyName);
    this.state = {
      hasSearchStarted: false,
      showTooltip: false,
    };
  }

  onClearSearchResult = () => {
    const { dispatch } = this.props;

    dispatch(resetCompanyNameLookup());
  };

  handleClick = value => {
    const { dispatch } = this.props;
    dispatch(componentComplete("company", true));
    dispatch(selectCompanyName(value));
  };

  handleChange = event => {
    const { dispatch } = this.props;

    if (event.target.value.length > 1) {
      this.setState({ hasSearchStarted: true });
      this.lookupCompanyName(event.target.value);
    } else {
      this.setState({ hasSearchStarted: false });
      dispatch(resetCompanyNameLookup());
    }
  };

  lookupCompanyName(name) {
    const { currentRegion, dispatch } = this.props;
    dispatch(lookupCompanyName(name, { region: currentRegion }));
    dispatch(componentComplete("company", false));
  }

  renderHint() {
    return (
      <ul className={styles.hint_text}>
        <li>Click on parent to send application to an existing 1Centre user</li>
        <li>Click on edit to change recipient</li>
        <li>
          Your customer will be able to add new &apos;child&apos; accounts and
          amend existing if applicable when applying for their account
        </li>
      </ul>
    );
  }

  render() {
    const { next_path, loading, companyAutosuggestList } = this.props;
    const { hasSearchStarted } = this.state;

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.panel}>
            <span className={styles.title}>
              <div className="has-text-weight-normal">
                <span className="has-text-danger has-text-weight-normal">
                  Important: First search legal entity name
                </span>
                {" to see if your customer exists on the 1Centre database. "}
                <span className="has-text-danger has-text-weight-normal">
                  Please ensure the name is accurate.
                </span>
              </div>
              <div style={{ marginLeft: "1em" }}>
                <MuiThemeProvider theme={theme}>
                  <PopperTooltip
                    title={this.renderHint()}
                    placement={"top"}
                    noArrow={true}
                  >
                    <HelpIcon className={styles.help_icon} />
                  </PopperTooltip>
                </MuiThemeProvider>
              </div>
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.panel}>
            <SearchInput loading={loading} handleChange={this.handleChange} />
            <AutoSuggest
              id="company_name"
              context="companySearch"
              options={companyAutosuggestList}
              handleSelect={this.handleClick}
              nextPath={next_path}
              loading={loading}
              onCannotFindResult={this.onClearSearchResult}
              hasSearchStarted={hasSearchStarted}
            />
          </div>
        </div>
      </section>
    );
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        minWidth: "400px",
      },
    },
  },
});

export default connect(state => {
  return {
    companyAutosuggestList: state.cns_invite.company_autosuggest_list,
    currentRegion: get(
      state,
      "current_user.current_entity.attributes.region",
      "NZ",
    ),
    loading: state.cns_invite.company_autosuggest_lookup_loading,
  };
})(InviteCompanyName);
