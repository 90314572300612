import store from "stores/store";

export function storeNameAlias() {
  return (
    store.getState().current_user.current_entity.attributes.store_name_alias ||
    ""
  );
}

export function isSupplier() {
  return (
    store.getState().current_user.current_entity.attributes.supplier || false
  );
}
