/* eslint-disable max-lines */
/* eslint-disable no-negated-condition */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undefined */
/* eslint-disable quote-props */
/* eslint-disable camelcase */
import mixpanel from "mixpanel-browser";
import UserModel from "models/UserModel";
import { removeMessageByText } from "modules/messages/actions";
import { INCORRECT_USERNAME_PASSWORD_MESSAGE } from "modules/messages/reducers";
import { loadUserProfile } from "modules/profile/actions";
import { setToken } from "modules/shared/auth/actions";
import {
  getSubject,
  getTokenOrigin,
  jwtPayload,
  tokenIsValid,
} from "modules/shared/auth/helpers";
import { dashboardRedirect } from "modules/shared/helpers/dashboardRedirect";
import { browserHistory } from "react-router";
import { setChurnZeroAttributes, setChurnZeroLogout } from "utils/churnZero";
import isPresent from "utils/isPresent";
import { hideLiveChat } from "utils/liveChat.js";

import api from "../../api";
import {
  getCurrentPricingPlan,
  showPricingPlan,
} from "../pricing-plans/actions";
import EntityService from "../shared/auth/EntityService";
import {
  EMAIL_LOCKED_ERROR,
  USER_ADD_ENTITY_ERROR,
  USER_ADD_ENTITY_START,
  USER_ADD_ENTITY_SUCCESS,
  USER_AUTHENTICATED,
  USER_CHECK_EMAIL_ERROR,
  USER_CHECK_EMAIL_START,
  USER_CHECK_EMAIL_SUCCESS,
  USER_CHECK_VOUCHER_CODE_ERROR,
  USER_CHECK_VOUCHER_CODE_START,
  USER_CHECK_VOUCHER_CODE_SUCCESS,
  USER_CURRENT_ENTITY_LEAD_NAME_SET,
  USER_DELETE_CURRENT_USER_POPUP_ERROR,
  USER_DELETE_CURRENT_USER_POPUP_START,
  USER_DELETE_CURRENT_USER_POPUP_SUCCESS,
  USER_FORGOT_PASSWORD_FORM_SET_EMAIL,
  USER_LOAD_CURRENT_ENTITY_ERROR,
  USER_LOAD_CURRENT_ENTITY_START,
  USER_LOAD_CURRENT_ENTITY_SUCCESS,
  USER_LOAD_CURRENT_USER_BEGIN,
  USER_LOAD_CURRENT_USER_DEACTIVATED_ERROR,
  USER_LOAD_CURRENT_USER_ERROR,
  USER_LOAD_CURRENT_USER_POPUPS_ERROR,
  USER_LOAD_CURRENT_USER_POPUPS_START,
  USER_LOAD_CURRENT_USER_POPUPS_SUCCESS,
  USER_LOAD_CURRENT_USER_SUCCESS,
  USER_LOAD_SUCCESS,
  USER_LOAD_USER_HEADQUARTER_LINK_ERROR,
  USER_LOAD_USER_HEADQUARTER_LINK_START,
  USER_LOAD_USER_HEADQUARTER_LINK_SUCCESS,
  USER_LOGIN_BEGIN,
  USER_LOGIN_CREDENTIALS_ERROR,
  USER_LOGIN_FORM_SET_EMAIL,
  USER_LOGIN_FORM_SET_PASSWORD,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGOUT_ERROR,
  USER_NETWORK_BREAK,
  USER_PASSWORD_RESET_ERROR,
  USER_PASSWORD_RESET_START,
  USER_PASSWORD_RESET_SUCCESS,
  USER_REGISTER_CREATE_REGISTRATION_ERROR,
  USER_REGISTER_CREATE_REGISTRATION_START,
  USER_REGISTER_CREATE_REGISTRATION_SUCCESS,
  USER_REGISTER_FORM_SET_EMAIL,
  USER_REGISTER_FORM_SET_FIRST_NAME,
  USER_REGISTER_FORM_SET_LAST_NAME,
  USER_REGISTER_FORM_SET_PASSWORD,
  USER_REGISTER_FORM_SET_PASSWORD_CONFIRM,
  USER_REGISTER_FORM_SET_PHONE_NUMBER,
  USER_REGISTER_FORM_SET_REGION,
  USER_REGISTER_FORM_SET_STATE,
  USER_REGISTER_FORM_SET_STEP,
  USER_REGISTER_FORM_SET_STRENGTH,
  USER_REGISTER_FORM_SET_VOUCHER_CODE,
  USER_REGISTER_FORM_SET_VOUCHER_CODE_VALID,
  USER_REGISTER_RESET,
  USER_SEND_PASSWORD_RESET_BEGIN,
  USER_SEND_PASSWORD_RESET_ERROR,
  USER_SEND_PASSWORD_RESET_SUCCESS,
  USER_SET_CURRENT_ENTITY_STORE_NAME_ALIAS,
  USER_TOKEN_AUTH_SET_QUERY_PARAMS,
  USER_UPDATE_FIRST_LAST_NAME_FROM_SIGNATURE_SUCCESS,
  USER_UPDATE_PREFERENCE_ERROR,
  USER_UPDATE_PREFERENCE_START,
  USER_UPDATE_PREFERENCE_SUCCESS,
} from "./constants";
import {ROLE_TYPE_CODES} from "../applications/components/credit-checks/australia/equifax/helpers/codeTableConstants";

export function setCurrentEntityStoreNameAlias(params) {
  return {
    payload: params,
    type: USER_SET_CURRENT_ENTITY_STORE_NAME_ALIAS,
  };
}

export function setQueryParamsForTokenAuth(params) {
  return {
    payload: params,
    type: USER_TOKEN_AUTH_SET_QUERY_PARAMS,
  };
}

export function setRegistrationStep(step) {
  return {
    payload: step,
    type: USER_REGISTER_FORM_SET_STEP,
  };
}

export function setPasswordStrength(strength) {
  return {
    payload: strength,
    type: USER_REGISTER_FORM_SET_STRENGTH,
  };
}

export function checkIfEmailExists(email_address, success_callback) {
  return (dispatch, getState) => {
    const email = api(
      "user_exists_email",
      getState().current_user.access_token,
    );

    dispatch({ type: USER_CHECK_EMAIL_START });

    email.checkEmailValid(
      email_address,
      success => {
        dispatch({
          payload: success.data.data.attributes.user_exists,
          type: USER_CHECK_EMAIL_SUCCESS,
        });

        if (success_callback) {
          success_callback();
        }
      },
      error => {
        dispatch({
          payload: error,
          type: USER_CHECK_EMAIL_ERROR,
        });
      },
    );
  };
}

export function validateVoucherCode(voucher_code) {
  return async(dispatch, getState) => {
    const voucherCodes = api(
      "voucher_codes",
      getState().current_user.access_token,
    );
    dispatch({ type: USER_CHECK_VOUCHER_CODE_START });
    try {
      const response = await voucherCodes.validateVoucherCode({
        params: { voucher_code },
      });
      dispatch({
        payload: response.data,
        type: USER_CHECK_VOUCHER_CODE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: USER_CHECK_VOUCHER_CODE_ERROR,
      });
    }
  };
}

export function setRegisterFormField(type, value) {
  switch (type) {
    case "first_name":
      return {
        payload: value,
        type: USER_REGISTER_FORM_SET_FIRST_NAME,
      };
    case "last_name":
      return {
        payload: value,
        type: USER_REGISTER_FORM_SET_LAST_NAME,
      };
    case "phone_number":
      return {
        payload: value,
        type: USER_REGISTER_FORM_SET_PHONE_NUMBER,
      };
    case "email_address":
      return {
        payload: value,
        type: USER_REGISTER_FORM_SET_EMAIL,
      };
    case "password":
      return {
        payload: value,
        type: USER_REGISTER_FORM_SET_PASSWORD,
      };
    case "confirm_password":
      return {
        payload: value,
        type: USER_REGISTER_FORM_SET_PASSWORD_CONFIRM,
      };
    case "region":
      return {
        payload: value,
        type: USER_REGISTER_FORM_SET_REGION,
      };
    case "state":
      return {
        payload: value,
        type: USER_REGISTER_FORM_SET_STATE,
      };
    case "voucher_code":
      return {
        payload: value,
        type: USER_REGISTER_FORM_SET_VOUCHER_CODE,
      };
    case "voucher_code_valid":
      return {
        payload: value,
        type: USER_REGISTER_FORM_SET_VOUCHER_CODE_VALID,
      };
  }
}

export function userLogout({
  postLogoutCallback,
  referrer = null,
  userTriggered = false,
}) {
  return async(dispatch, getState) => {
    // Invalidate jwt in /logout endpoint
    // Clear client side data
    await api("auth")
      .logout()
      .then(success => {
        const entityService = new EntityService();
        entityService.delEntity();
        hideLiveChat();
        // ChurnZero
        setChurnZeroLogout();
        // remove from state
        Object.entries(localStorage)
          .map(item => item[0])
          .filter(item => item.substring(0, 12) === "searchParams")
          .map(item => localStorage.removeItem(item));
        // remove filters
        const current_user = getState().current_user;

        dispatch({
          meta: {
            mixpanel: {
              event: "Log out",
              props: {
                "Entity ID":
                  current_user.current_entity && current_user.current_entity.id,
                distinct_id:
                  current_user.data.data && current_user.data.data.id,
                referrer,
                user_triggered: userTriggered,
              },
            },
          },
          type: USER_LOGOUT,
        });

        if (postLogoutCallback) {
          postLogoutCallback();
        } else {
          browserHistory.push("/");
        }
      })
      .catch(e => {
        browserHistory.push("/");
      });
  };
}

// eslint-disable-next-line max-params
export function userLogin(
  email,
  password,
  redirect,
  success_callback,
  entity_id = null,
  referrer = null,
) {
  return dispatch => {
    const tokens = api("tokens");
    dispatch({ type: USER_LOGIN_BEGIN });

    tokens.requestToken(
      {
        auth: {
          email,
          password,
          referrer,
        },
      },
      result => {
        const token = result.data.jwt;
        setToken(token);

        const id = getSubject(token);

        // Update state
        dispatch({
          meta: {
            mixpanel: {
              event: "Log in",
              props: { distinct_id: id },
            },
          },
          payload: result.data,
          type: USER_LOGIN_SUCCESS,
        });

        dispatch(removeMessageByText(INCORRECT_USERNAME_PASSWORD_MESSAGE));

        // Load user data
        dispatch(
          getUserData(
            result.data.jwt,
            redirect,
            success_callback,
            entity_id,
            referrer,
          ),
        );
      },
      error => {
        const status =
          error.status || (error.response && error.response.status);
        const notFound = 404;
        const forbidden = 403;

        if (status === notFound) {
          dispatch({
            payload: error,
            type: USER_LOGIN_CREDENTIALS_ERROR,
          });
        } else if (status === forbidden) {
          dispatch({
            payload: error,
            type: EMAIL_LOCKED_ERROR,
          });
        } else {
          console.error("something went wrong... please refresh your screen");
        }
      },
    );
  };
}

export function getUserData(
  token,
  redirect,
  success_callback,
  entity_id,
  referrer = null,
) {
  return (dispatch, getState) => {
    // Allow this to be used to refresh existing user data
    if (!token) {
      // eslint-disable-next-line no-param-reassign
      token = getState().current_user.access_token;
    }

    if (!tokenIsValid(token)) {
      dispatch(userLogout());
      return;
    }

    // Allow guarantors to stay in their area.
    const tokenType = jwtPayload(token).api_token_type;
    const path = getState().routing.locationBeforeTransitions.pathname;
    const query = getState().routing.locationBeforeTransitions.query;

    if (tokenType === "guarantor" && path.startsWith("/guarantor")) {
      return;
    }

    // Allow token-approaches. This may need refinement by path later.
    if (query.t && !redirect) {
      return;
    }

    // Attempt to get the current user details with this token
    // before we set this in state, normally API calls would
    // be made with the token located at getState().current_user.access_token
    // However since we are checking here if we should set this token,
    // we can't use it in this instance.
    const users = api("users", token);
    const subject = getSubject(token);
    const origin = getTokenOrigin(token);

    dispatch({
      payload: subject,
      type: USER_LOAD_CURRENT_USER_BEGIN,
    });

    users.getUser(
      subject,
      response => {
        dispatch({
          payload: { data: response.data, token_origin: origin },
          type: USER_LOAD_CURRENT_USER_SUCCESS,
        });

        if (!response.data.data.attributes.trm) {
          dispatch(loadCurrentEntity(entity_id, null, success_callback));
        } else {
          dispatch({ type: USER_LOAD_SUCCESS });
        }

        const user = response.data.data;
        mixpanel.identify(user.id);
        mixpanel.register({
          "User ID": user.id,
          email: user.attributes.email,
        });
        mixpanel.people.set({
          $email: user.attributes.email,
          $first_name: user.attributes.first_name,
          $last_name: user.attributes.last_name,
          Phone: user.attributes.contact_phone_number,
          Role: user.attributes.role_types && user.attributes.role_types.join(),
        });

        if (redirect === "/dashboard") {
          browserHistory.push(dashboardRedirect(redirect, response));
        } else if (
          isPresent(redirect) &&
          redirect.indexOf("/websitebutton") > -1 &&
          response.data.included[0].attributes.entity_legal_type
        ) {
          let redirectPath = redirect;
          const redirectArray = redirect.split("/");
          redirectArray[redirectArray.length - 1] = "other-details";
          redirectPath = redirectArray.join("/");
          browserHistory.push(redirectPath);
        } else if (isPresent(redirect)) {
          let redirectPath = redirect;
          if (isPresent(referrer)) {
            redirectPath = `${redirectPath}?redirect=${referrer}`;
          }
          browserHistory.push(redirectPath);
        }
      },
      error => {
        // Gross, TODO: This better.
        // eslint-disable-next-line no-magic-numbers
        if (error.status === 401) {
          // Log the user out
          dispatch(userLogout());

          dispatch({
            payload: error,
            type: USER_LOAD_CURRENT_USER_ERROR,
          });
        } else {
          console.error(error);
        }
      },
      { params: { include: "user_entity_links" } },
    );
  };
}

const retrieveSupplierEntityAsDefault = entities => {
  const supplierEntity = entities.find(
    entity => entity.attributes.entity_supplier,
  );

  if (supplierEntity) {
    return supplierEntity.attributes.entity_id;
  }

  return entities[0].attributes.entity_id;
};

export function loadCurrentEntity(id, reload, success_callback) {
  return (dispatch, getState) => {
    dispatch({
      payload: { entity_id: id, name: "loadCurrentEntity" },
      type: USER_LOAD_CURRENT_ENTITY_START,
    });
    /*Set/Get Entity in local storage */
    const entityService = new EntityService();
    if (!id) {
      if (entityService.getEntity()) {
        id = entityService.getEntity();
      } else {
        id = retrieveSupplierEntityAsDefault(
          getState().current_user.data.included,
        );
        entityService.setEntity(id);
      }
    } else {
      entityService.setEntity(id);
    }
    const entities = api("entities", getState().current_user.access_token);
    const currentUser = UserModel.fromCurrentUser(getState().current_user);
    entities.getEntity(
      id,
      success => {
        if (success.data.data.attributes.subscription_state === "deactivated") {
          dispatch({ type: USER_LOAD_CURRENT_USER_DEACTIVATED_ERROR });
          return;
        }

        // eslint-disable-next-line max-len
        const current_user_entity_link = getState().current_user.data.included.find(
          x => x.attributes.entity_id === id,
        );
        if (!current_user_entity_link) {
          dispatch(_loadHeadquarterUserEntityLink(id, success.data.data));
        } else {
          dispatch({
            payload: {
              current_entity: success.data.data,
              current_user_entity_link,
            },
            type: USER_LOAD_CURRENT_ENTITY_SUCCESS,
          });
          dispatch(getUserPopup(current_user_entity_link.id));
        }
        dispatch(
          getCurrentPricingPlan(success.data.data.id, function(plan) {
            if (
              plan &&
              (plan.attributes.plan_no !== 0 &&
                plan.attributes.application_tally >
                  plan.attributes.application_max)
            ) {
              dispatch(showPricingPlan(true, "application"));
            } else if (
              plan &&
              (plan.attributes.plan_no !== 0 &&
                plan.attributes.connection_tally >
                  plan.attributes.connection_max)
            ) {
              dispatch(showPricingPlan(true, "connection"));
            }
          }),
        );

        mixpanel.people.set({
          Applicant: !!success.data.data.attributes.applicant,
          "Company Credit Checks Australia":
            success.data.data.attributes.selected_credit_check_rules
              .au_rule_selection_name || "-",
          "Company Credit Checks New Zealand":
            success.data.data.attributes.selected_credit_check_rules
              .nz_rule_selection_name || "-",
          "Credit checks": !!success.data.data.attributes.requires_credit_check,
          Entity: success.data.data.attributes.trading_name,
          "Entity ID": success.data.data.id,
          "Legal type": success.data.data.attributes.legal_type,
          "Personal Credit Checks Australia":
            success.data.data.attributes.selected_credit_check_rules
              .au_personal_rule_selection_name || "-",
          "Personal Credit Checks New Zealand":
            success.data.data.attributes.selected_credit_check_rules
              .nz_personal_rule_selection_name || "-",
          "Staff count": success.data.data.attributes.staff_count,
          Subscription: success.data.data.attributes.subscription_state,
          Supplier: !!success.data.data.attributes.supplier,
        });

        // ChurnZero
        if (success.data.data.attributes.supplier) {
          setChurnZeroAttributes(success.data.data.id, getState);
        }

        if (reload) {
          window.location.href = "/";
        }

        dispatch(loadUserProfile(currentUser.id, success.data.data.id));

        if (success_callback) {
          success_callback(dispatch, getState);
        }
      },
      error => {
        dispatch({
          payload: error,
          type: USER_LOAD_CURRENT_ENTITY_ERROR,
        });
      },
    );
  };
}

function _loadHeadquarterUserEntityLink(entity_id, entity_data) {
  return (dispatch, getState) => {
    dispatch({
      payload: entity_id,
      type: USER_LOAD_USER_HEADQUARTER_LINK_START,
    });

    const entities = api("entities", getState().current_user.access_token);
    entities.getUserHeadquarterLink(
      entity_id,
      success => {
        dispatch({ type: USER_LOAD_USER_HEADQUARTER_LINK_SUCCESS });
        dispatch({
          payload: {
            current_entity: entity_data,
            current_user_entity_link: success.data.data,
          },
          type: USER_LOAD_CURRENT_ENTITY_SUCCESS,
        });
      },
      error => {
        dispatch({
          payload: error,
          type: USER_LOAD_USER_HEADQUARTER_LINK_ERROR,
        });
      },
    );
  };
}

export function createRegistration(attributes, redirect) {
  return dispatch => {
    // Add role_type
    attributes.role_type = "admin";

    dispatch({
      payload: attributes,
      type: USER_REGISTER_CREATE_REGISTRATION_START,
    });

    const users = api("users");
    let redirectPath = "/dashboard";

    users.createUser(
      attributes,
      result => {
        mixpanel.identify(result.data.data.id);
        mixpanel.register({
          "User ID": result.data.data.id,
          email: attributes.email,
        });
        mixpanel.people.set({ "Self-registered": true });

        if (redirect) {
          redirectPath = redirect;
        }

        dispatch(
          userLogin(attributes.email, attributes.password, redirectPath),
        );

        dispatch({
          meta: {
            mixpanel: {
              event: "Self-registration",
              props: { distinct_id: result.data.data.id },
            },
          },
          payload: attributes,
          type: USER_REGISTER_CREATE_REGISTRATION_SUCCESS,
        });

        dispatch({ type: USER_REGISTER_RESET });
      },
      error => {
        dispatch({
          payload: error,
          type: USER_REGISTER_CREATE_REGISTRATION_ERROR,
        });
      },
    );
  };
}

export function setLoginFormField(type, value) {
  switch (type) {
    case "email_address":
      return {
        payload: value.toLowerCase(),
        type: USER_LOGIN_FORM_SET_EMAIL,
      };
    case "password":
      return {
        payload: value,
        type: USER_LOGIN_FORM_SET_PASSWORD,
      };
  }
}

export function setForgotPasswordEmail(value) {
  return {
    payload: value,
    type: USER_FORGOT_PASSWORD_FORM_SET_EMAIL,
  };
}

export function setTokenState(token) {
  return {
    payload: token,
    type: USER_AUTHENTICATED,
  };
}

export function sendPasswordReset(email, referrer, callback) {
  return dispatch => {
    const resets = api("password_resets");
    dispatch({ type: USER_SEND_PASSWORD_RESET_BEGIN });
    resets.createReset(
      { email, referrer },
      result => {
        dispatch({
          meta: {
            mixpanel: {
              event: "Request password reset",
              props: { email },
            },
          },
          payload: result,
          type: USER_SEND_PASSWORD_RESET_SUCCESS,
        });

        if (callback) {
          callback();
        }
      },
      error => {
        dispatch({
          payload: error,
          type: USER_SEND_PASSWORD_RESET_ERROR,
        });
      },
    );
  };
}

export function updatePassword({
  params: { currentPassword, password },
  successCallback,
  errorCallback,
  requestEndCallback,
}) {
  return (dispatch, getState) => {
    const currentUser = UserModel.fromCurrentUser(getState().current_user);
    const { id, currentEntity } = currentUser;
    const users = api("users", currentUser.accessToken);

    dispatch({ type: USER_PASSWORD_RESET_START });

    users.updatePassword({
      error: error => {
        if (error && error.response.status !== 401 && !errorCallback) {
          dispatch({
            payload: error,
            type: USER_PASSWORD_RESET_ERROR,
          });
        }

        if (errorCallback) {
          errorCallback(error);
        }
      },
      id,
      options: {
        current_password: currentPassword,
        password,
      },
      requestEnd: () => {
        if (requestEndCallback) {
          requestEndCallback();
        }
      },
      success: success => {
        dispatch({
          meta: {
            mixpanel: {
              event: "Update password",
              props: {
                "Entity ID": currentEntity.id,
                distinct_id: id,
              },
            },
          },
          payload: success,
          type: USER_PASSWORD_RESET_SUCCESS,
        });

        if (successCallback) {
          successCallback(success);
        }
      },
    });
  };
}

export function forgotPasswordUpdateUserPassword(
  password,
  success_callback,
  error_callback,
) {
  return (dispatch, getState) => {
    const users = api("users", getState().current_user.access_token);

    dispatch({ type: USER_PASSWORD_RESET_START });

    users.updateUser(
      getState().current_user.data.data.id,
      { password },
      success => {
        dispatch({
          meta: {
            mixpanel: {
              event: "Update password",
              props: {
                "Entity ID": getState().current_user.current_entity.id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: USER_PASSWORD_RESET_SUCCESS,
        });

        if (success_callback) {
          success_callback();
        }
      },
      error => {
        dispatch({
          payload: error,
          type: USER_PASSWORD_RESET_ERROR,
        });

        if (error_callback) {
          error_callback();
        }
      },
    );
  };
}

export function addNewEntityFromBusinessToggle(e_attrs, callback) {
  return async(dispatch, getState) => {
    dispatch({ type: USER_ADD_ENTITY_START });
    const userId = getState().current_user.data.data.id;
    const entityId = getState().current_user.current_entity.id;
    const accessToken = getState().current_user.access_token;
    const users = api("users", accessToken, entityId);

    try {
      const response = await users.createNewEntity(userId, e_attrs);
      if (callback) {
        callback(response.data.entity_id);
      }
      dispatch({
        meta: {
          mixpanel: {
            event: "Added Business toggle",
            props: {
              "Entity ID": response.data.entity_id,
              "New company name": e_attrs.trading_name,
              distinct_id: userId,
            },
          },
        },
        type: USER_ADD_ENTITY_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: USER_ADD_ENTITY_ERROR,
      });
    }
  };
}

export function getUserPopup(user_entity_link_id) {
  return (dispatch, getState) => {
    dispatch({ type: USER_LOAD_CURRENT_USER_POPUPS_START });
    const user_entity_links = api(
      "user_entity_links",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    user_entity_links.getPopup(
      user_entity_link_id,
      result => {
        dispatch({
          payload: result.data.data,
          type: USER_LOAD_CURRENT_USER_POPUPS_SUCCESS,
        });
      },
      error => {
        dispatch({
          payload: error,
          type: USER_LOAD_CURRENT_USER_POPUPS_ERROR,
        });
      },
    );
  };
}

export function deleteUserPopup(id) {
  return (dispatch, getState) => {
    dispatch({ type: USER_DELETE_CURRENT_USER_POPUP_START });
    const user_pop_ups = api(
      "user_pop_ups",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    user_pop_ups.delete(
      id,
      result => {
        dispatch({
          payload: result.data.data,
          type: USER_DELETE_CURRENT_USER_POPUP_SUCCESS,
        });
        dispatch(
          getUserPopup(getState().current_user.current_user_entity_link.id),
        );
      },
      error => {
        dispatch({
          payload: error,
          type: USER_DELETE_CURRENT_USER_POPUP_ERROR,
        });
      },
    );
  };
}

export function setCurrentEntityLeadName(value) {
  return {
    payload: value,
    type: USER_CURRENT_ENTITY_LEAD_NAME_SET,
  };
}

export function updateUserFirstNameLastNameFromSignature(data) {
  return {
    payload: data,
    type: USER_UPDATE_FIRST_LAST_NAME_FROM_SIGNATURE_SUCCESS,
  };
}

export function setUserPerference(configs) {
  return async(dispatch, getState) => {
    dispatch({ type: USER_UPDATE_PREFERENCE_START });

    const currentUser = getState().current_user;
    const accessToken = currentUser.access_token;
    const entityId = currentUser.current_entity.id;

    const userAPI = api("users", accessToken, entityId);

    try {
      await userAPI.updatePreference(currentUser.data.data.id, {
        params: configs,
      });
      dispatch({
        payload: configs,
        type: USER_UPDATE_PREFERENCE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: USER_UPDATE_PREFERENCE_ERROR,
      });
    }
  };
}
