/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import isPseudoRole from "modules/shared/helpers/user/isPseudoRole";

/* Import components */
import CircleModal from "modules/shared/components/widgets/static/CircleModal";
import Button from "modules/shared/components/inputs/Button";

/* Import CSS */
import styles from "./css/PricingPlanModal.css";

export class PricingPlanModal extends React.Component {
  constructor(props) {
    super(props);
  }

  _createContent(type) {
    const { current_pricing_plan } = this.props;
    switch (type) {
      case "application":
        return `Your account has met the maximum number of ${current_pricing_plan.attributes.application_max} applications for the plan you are on. Click below to upgrade your plan.`;
        break;
      case "connection":
        return `Your account has met the maximum number of ${current_pricing_plan.attributes.connection_max} connections for the plan you are on. Click below to upgrade your plan.`;
        break;
      case "others":
        return "Your plan does not include this feature. Click below to upgrade your plan.";
        break;
    }
  }

  _upgrade(onCancel) {
    onCancel();
    browserHistory.push("/dashboard/pricing-plans");
  }

  render() {
    const { isAccountUser, type, roles, onCancel } = this.props;
    let content;
    if (isAccountUser && roles.includes("admin")) {
      content = (
        <div>
          <p>{this._createContent(type)}</p>
          <Button
            text={"upgrade"}
            handleClick={() => this._upgrade(onCancel)}
          />
        </div>
      );
    } else {
      content = (
        <div>
          <p>
            Your account needs to be upgraded to continue. Please contact your
            administrator to upgrade your account.
          </p>
          <Button text={"close"} handleClick={onCancel} />
        </div>
      );
    }
    return (
      <CircleModal title={"Plan upgrade needed"} dismissHandler={onCancel}>
        <div>{content}</div>
      </CircleModal>
    );
  }
}

export default connect((state, ownProps) => ({
  roles: state.current_user.current_user_entity_link.attributes.role_types,
  current_pricing_plan: state.pricing_plans.current_pricing_plan,
  isAccountUser: !isPseudoRole(),
}))(PricingPlanModal);
