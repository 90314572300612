import React from "react";
import ReactTimeout from "react-timeout";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import { debounce } from "throttle-debounce";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import { componentFactory } from "../components";
import {
  inviteComplete,
  clearState,
  getUserPermission,
} from "../actions/invite";
import {
  loadCurrentEntity,
  loadCard,
  loadCreditCheckLookup,
  loadIdentificationCheckLookup,
} from "modules/profile/actions";
import TopRight from "containers/position/TopRight";
import MiddleRight from "containers/position/MiddleRight";
import BottomRight from "containers/position/BottomRight";
import Button from "modules/shared/components/inputs/Button";
import ProgressDots from "modules/shared/components/widgets/static/ProgressDots";
import WorkflowOverlay from "modules/shared/components/top/WorkflowOverlay";
import ApplicationOverlay from "modules/shared/components/top/ApplicationOverlay";
import ArrowButton from "modules/shared/components/widgets/interactive/ArrowButton";
import HelpButton from "modules/shared/components/inputs/HelpButton";
import Loader from "modules/shared/components/widgets/static/Loader";
import { getUserData } from "modules/user/actions";
import styles from "./css/ConsumerInvite.css";
import { isMobile } from "modules/shared/helpers/mobileDetect";
import { isIUFSearchSendEnabled, isIUFSearchSendCompleted, isIUFApplicationType, hasActiveIUFSearchSendConfig } from "modules/shared/helpers/internalUseFieldsHelper";
import { loadInternalUseFieldsAddon } from "modules/addons/actions";

class ConsumerInvite extends React.Component {
  state = {
    animation: "scroll-down",
    showLoader: true,
  };

  isSectionComplete = () => {
    var links = this.getSectionLinks();
    var complete = true;
    links.forEach((item, index) => {
      if (item.required === true && item.complete !== true) {
        complete = false;
      }
    });

    return complete;
  }

  /**
   * Helper function used to format the path to this section/component
   */
  formatLink = (section, component) => {
    const { application_type } = this.props;
    var link = `/dashboard/invite/${application_type}`;
    if (component) {
      link = `${link}/${component}`;
    }
    return link;
  }

  /**
   * Returns the properties for the current section and component.
   */
  getComponentProps = () => {
    const {
      sections,
      section,
      component,
      completed,
      sections_complete,
      application_type,
      permissions,
    } = this.props;
    const flow = this.getSectionLinkArray();
    const index = flow.indexOf(this.props.location.pathname);
    let complete = false;

    if (completed.hasOwnProperty(component)) {
      complete = completed[component];
    }

    if (component === "company") {
      return {
        ...sections[section],
        section: section,
        component: component,
        complete: complete,
        next_path: flow[index + 1],
        application_type: application_type,
        permissions: permissions,
        skip_next_path: flow[index + 2],
      };
    }

    return {
      ...sections[section],
      section: section,
      component: component,
      complete: complete,
      next_path: flow[index + 1],
      application_type: application_type,
      permissions: permissions,
    };
  }

  /**
   * Helper function that returns links and buttons for the given
   * section/component.
   */
  getArrowLinks = () => {
    const { sections, section, component, completed } = this.props;

    const links = [];
    const flow = this.getSectionLinks();

    // Lower level component pages.
    flow.forEach((item, index) => {
      if (component === item.component && section === item.section) {
        if (item.required && !item.complete) {
          let redirectPath = "/dashboard/home";
          let buttonLabel = "cancel";

          if (section === "invite" && component === "new") {
            redirectPath = "/dashboard/invite/credit/company";
            buttonLabel = "back";
          }

          links.push(
            <div key="more-info">
              <Button
                style={{ marginRight: "10px" }}
                text={buttonLabel}
                handleClick={() => browserHistory.push(redirectPath)}
              />
              <Button
                disabled={true}
                text="more info required"
                css_style="button_disabled"
                link="."
              />
            </div>,
          );
        } else if (index === flow.length - 1) {
          if (flow.length > 1) {
            links.push(
              <div key="next">
                <Button
                  style={{ marginRight: "10px" }}
                  text={"cancel"}
                  handleClick={() => {
                    browserHistory.push("/dashboard/home");
                  }}
                />
                <Button text="next" link={this.formatLink(section)} />
              </div>,
            );
          } else {
            links.push(
              <div key="disabled-next">
                <Button
                  style={{ marginRight: "10px" }}
                  text={"cancel"}
                  handleClick={() => {
                    browserHistory.push("/dashboard/home");
                  }}
                />
                <Button
                  disabled={true}
                  text="next"
                  css_style="button_disabled"
                  link={null}
                />
              </div>,
            );
          }
        } else {
          const text =
            index + 1 === flow.length - 1 ? "Send application" : "next";
          links.push(
            <div key="send-app">
              <Button
                style={{ marginRight: "10px" }}
                text={"cancel"}
                handleClick={() => {
                  browserHistory.push("/dashboard/home");
                }}
              />
              <Button
                text={text}
                link={this.formatLink(section, flow[index + 1].component)}
              />
            </div>,
          );
        }
      }
    });

    return (
      <div className={styles.bottom_actions_container}>
        <div className={styles.button_container}>
          <div className={styles.section}>
            <div className={styles.row}>
              <div className={styles.panel}>
                <div className={styles.bottom_actions}>{links}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * A callback function to determine if the component is completed.
   */
  isComponentComplete = (section, component) => {
    const { completed } = this.props;
    if (completed.hasOwnProperty(component)) {
      return completed[component];
    }
    return false;
  }

  getSectionLinkArray = () => {
    const links = this.getSectionLinks();
    return links.map(item => this.formatLink(item.section, item.component));
  }

  /**
   * Get the button links for this section and flow.
   */
  getSectionLinks = () => {
    const {
      existing_entity,
      component,
      section,
      permissions,
      application_type,
      showPermission,
    } = this.props;

    const links = [];

    function getIUFSection() {
      if (!hasActiveIUFSearchSendConfig(application_type)) {
        return;
      }

      return links.push({
        required: true,
        section: section,
        component: "internal-fields",
        complete: isIUFSearchSendCompleted(),
      })
    }

    if (existing_entity && component !== "new") {
      links.push({
        required: true,
        section: section,
        component: "company",
        complete: this.isComponentComplete(section, "company"),
      });
      // Send email for application
      if (
        permissions === "m" &&
        application_type === "credit" &&
        showPermission
      ) {
        links.push({
          required: false,
          section: section,
          component: "account-rules",
          complete: false,
        });
      }
      getIUFSection();

      // Send email for existing user
      links.push({
        required: false,
        section: section,
        component: "invite-existing",
        complete: this.isComponentComplete(section, "invite-existing"),
      });
    } else {
      links.push({
        required: true,
        section: section,
        component: "company",
        complete: this.isComponentComplete(section, "company"),
      });
      links.push({
        required: true,
        section: section,
        component: "new",
        complete: this.isComponentComplete(section, "new"),
      });
      if (
        permissions === "m" &&
        application_type === "credit" &&
        showPermission
      ) {
        links.push({
          required: false,
          section: section,
          component: "account-rules",
          complete: false,
        });
      }
      getIUFSection();

      // Send email for user signup
      links.push({
        required: false,
        section: section,
        component: "invite-new",
        complete: this.isComponentComplete(section, "invite-new"),
      });
    }
    return links;
  }

  /**
   * Helper function to get the dots along the top or the right.
   *
   * TODO: Make this check for completion.
   */
  componentGetDots = () => {
    const { section, component } = this.props;
    var section_index = 0;
    const links = [];
    var flow = this.getSectionLinks();

    flow.forEach((item, index) => {
      if (component === item.component && section === item.section) {
        section_index = index;
      }
      links.push({
        complete: item.complete,
        path: this.formatLink(item.section, item.component),
      });
    });

    if (links.length > 1) {
      return (
        <MiddleRight>
          <ProgressDots type="vertical" index={section_index} links={links} />
        </MiddleRight>
      );
    }
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    const { location } = this.props;
    if (location.pathname != newProps.location.pathname) {
      var current_location = location.pathname;
      var new_location = newProps.location.pathname;
      var links = this.getSectionLinkArray();

      if (links.indexOf(current_location) > links.indexOf(new_location)) {
        this.setState({
          animation: "scroll-up",
        });
      } else {
        this.setState({
          animation: "scroll-down",
        });
      }
    }
  }

  scroll = (e) => {
    const { section, component, completed } = this.props;

    var current_location = location.pathname;
    var links = this.getSectionLinkArray();
    var flow = this.getSectionLinks();
    var current_index = links.indexOf(current_location);

    if (e.deltaY > 0) {
      // scrolldown
      if (
        flow[current_index] &&
        flow[current_index].required &&
        !flow[current_index].complete
      ) {
        return;
      }
      if (current_index < links.length - 1 && links.length >= 1) {
        this.scrollToRoute(links[current_index + 1]);
      }
    } else {
      // scrollup
      if (current_index > 0) {
        this.scrollToRoute(links[current_index - 1]);
      }
    }
  }

  scrollToRoute = (route) => {
    browserHistory.push(route);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(loadCurrentEntity());
    dispatch(loadCreditCheckLookup());
    dispatch(loadIdentificationCheckLookup());
    dispatch(loadCard());
    this.scrollToRoute = debounce(200, true, this.scrollToRoute);
  }

  UNSAFE_componentWillMount() {
    const { dispatch, component, application_type } = this.props;
    dispatch(getUserPermission());
    dispatch(loadInternalUseFieldsAddon());
    if (component !== "company") {
      var link = `/dashboard/invite/${application_type}/company`;
      browserHistory.push(link);
    }
  }

  loadingComplete = () => {
    this.setState({
      showLoader: false,
    });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearState());
  }

  /**
   * React render
   */
  render() {
    const {
      sections,
      return_path,
      section,
      component,
      config,
      state_loading,
      application_type,
      current_entity,
      current_pricing_plan,
    } = this.props;
    const { animation, showLoader } = this.state;

    var enter,
      enterActive,
      leave,
      leaveActive,
      scroll = false;

    if (!current_entity && component === "company") {
      //showLoader) {
      return <Loader message="Please wait while we load your progress." />;
    }

    switch (animation) {
      case "scroll-up":
        enter = "enterScrollUp";
        enterActive = "enterActiveScrollUp";
        leave = "leaveScrollUp";
        leaveActive = "leaveActiveScrollUp";
        break;
      case "scroll-down":
      default:
        enter = "enterScrollDown";
        enterActive = "enterActiveScrollDown";
        leave = "leaveScrollDown";
        leaveActive = "leaveActiveScrollDown";
        break;
    }

    var props = this.getComponentProps();
    var child_component = componentFactory(section, component, props);
    var links = this.getArrowLinks();
    var dots = this.componentGetDots();
    var position = "top";
    var position_absolute = false;
    var workflow_label = sections[section].title;

    var overlay, logo_url;
    if (current_entity) {
      if (current_entity.logo) {
        logo_url = current_entity.logo.url;
      }
      overlay = (
        <ApplicationOverlay
          label={
            application_type === "credit" ? "Credit account" : "Cash account"
          }
          trading_name={current_entity.trading_name}
          logo_url={logo_url}
        />
      );
    }
    if (["invite-new", "invite-existing"].indexOf(component) > -1) {
      overlay = dots = links = scroll = null;
    }

    var sectionHeadings = <div>{overlay}</div>;

    if (isMobile()) {
      dots = null;
    }

    let content = null;

    if (component !== "account-rules") {
      content = (
        <div className={styles.outer}>
          <div className="is-hidden-mobile is-hidden-tablet-only">
            <CloseButton
              handleClick={() => browserHistory.push("/dashboard/home")}
              css_class="preview_button_close"
            />
          </div>
          <div className={styles.flow}>
            <div className={styles.flow_wrap_slide}>
              <ReactCSSTransitionGroup
                transitionName={{
                  enter: styles[enter],
                  enterActive: styles[enterActive],
                  leave: styles[leave],
                  leaveActive: styles[leaveActive],
                }}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={600}
              >
                <div
                  className={styles.pageFlex}
                  key={this.props.location.pathname}
                >
                  <div className={styles.container}>
                    <div className={styles.content}>{child_component}</div>
                  </div>
                </div>
              </ReactCSSTransitionGroup>
            </div>
          </div>
          <div className={styles.controls}>
            {sectionHeadings}
            {/*dots*/}
            {links}
          </div>
        </div>
      );
    } else {
      scroll = false;
      content = (
        <div>
          <div className={styles.page} key={this.props.location.pathname}>
            <div className={styles.container}>
              <div className={styles.application_content}>
                {child_component}
              </div>
              {links}
            </div>
          </div>
          <div className={styles.controls}>
            {sectionHeadings}
            {/*dots*/}
          </div>
        </div>
      );
    }
    return <div onWheel={scroll === true ? this.scroll : null}>{content}</div>;
  }
}

const config = {
  title: "Consumer onboarding",
  sections: {
    invite: {
      number: 1,
      title: "It's business time",
      complete_title: "You've taken care of business",
      description: "Your businesses details live here",
      complete_description: "Business section completed",
    },
  },
};

export default connect((state, ownProps) => {
  var component = null;
  var selected_type = null,
    application_type = null,
    current_entity;
  var ent_requires_credit_check = false;
  var ent_requires_identification_check = false;
  var ent_requires_guarantees = false;
  var ent_requires_trade_reference_check = false;
  var ent_minimum_trade_references = 0;

  if (state.cns_invite.entity_type !== null) {
    selected_type = state.cns_invite.entity_type;
  }

  if (ownProps.params.component !== undefined) {
    component = ownProps.params.component;
  }
  if (ownProps.params.application_type !== undefined) {
    application_type = ownProps.params.application_type;
  }
  if (state.manage_profile.current_entity) {
    current_entity = state.manage_profile.current_entity.attributes;
    ent_requires_credit_check = current_entity.requires_credit_check || false;
    ent_requires_identification_check =
      current_entity.requires_applicant_identification_check ||
      current_entity.requires_guarantees_identification_check ||
      false;
    ent_requires_guarantees = current_entity.requires_guarantees || false;
    ent_requires_trade_reference_check =
      current_entity.requires_trade_reference_check || false;
    ent_minimum_trade_references = current_entity.minimum_trade_references || 0;
  }

  var showPermission =
    ent_requires_credit_check ||
    ent_requires_identification_check ||
    ent_requires_guarantees ||
    ent_minimum_trade_references > 0 ||
    false;

  return {
    existing_entity:
      state.cns_invite.company_autosuggest_selected_details.entityId || null,
    invite_type: state.cns_invite.invite_type || "on_the_spot",
    return_path: state.cns_invite.return_path,
    config: config,
    selected_type: selected_type,
    sections: config.sections,
    section: "invite",
    completed: state.cns_invite.completed,
    sections_complete: {
      invite: false,
    },
    component: component || "company",
    application_type: application_type,
    permissions: state.cns_invite.user_permissions,
    current_entity: current_entity,
    existing_user: state.cns_invite.company_autosuggest_selected_user || null,
    showPermission: showPermission,
  };
})(ConsumerInvite);
