/* Import libraries */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

/* Import actions */
import {
  createUser,
  checkIfEmailExists,
  clearEmailValidation,
} from "../actions";
import { loadCard } from "../../profile/actions";

/* Import CSS */
import styles from "./css/CreateUser.css";

/* Import components */
import TextInput from "modules/shared/components/inputs/TextInput";
import EmailInput from "../../shared/components/inputs/EmailInput";
import CircleModal from "modules/shared/components/widgets/static/CircleModal";
import CircleIconButton from "modules/shared/components/widgets/interactive/CircleIconButton";
import Button from "modules/shared/components/inputs/Button";
import NewUserForm from "../forms/NewUserForm";
import CreditCardModal from "../../profile/components/CreditCardModal";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
import { EMAIL_REGEX } from "constants";
import get from "lodash.get";
import isPseudoRole from "modules/shared/helpers/user/isPseudoRole";

class CreateUser extends React.Component {
  state = {
    form_values: {
      user_first_name: "",
      user_last_name: "",
      user_type: "standard",
      user_level: null,
      user_email: "",
      user_contact_phone_number: "",
      update_loading: false,
      existing_email: "",
    },
    form_loading: {},
    form_errors: {},
    show_member_type: true,
    member_type: "",
    isTooltipActive: true,
    isUserTypeTooltipActive: true,
  };

  submit = () => {
    const { onSubmit, entity_id, exists_user } = this.props;
    const { form_values, member_type } = this.state;

    let attributes = null;
    if (member_type === "existing_member") {
      attributes = {
        user_id: exists_user.id,
        entity_id: entity_id,
        role_types: [form_values.user_type], // TODO: Think what's going on here. For now will get the first
        level: form_values.user_level,
      };
    } else {
      attributes = {
        first_name: form_values.user_first_name,
        last_name: form_values.user_last_name,
        email: form_values.user_email,
        role_types: [form_values.user_type], // TODO: Think what's going on here. For now will get the first
        level: form_values.user_level,
        contact_phone_number: form_values.user_contact_phone_number,
      };
    }

    onSubmit(attributes, member_type === "existing_member");
  };

  showMemberType = () => {
    const { show_member_type } = this.state;
    this.setState({ show_member_type: !show_member_type });
  };

  checkEmail = email_address => {
    const { dispatch } = this.props;
    let that = this;
    dispatch(checkIfEmailExists(email_address));
  };

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(clearEmailValidation());
  }

  _onEmailChangeHandler = (value, isValid) => {
    const { form_values, form_errors } = this.state;

    form_values["user_email"] = value;
    this.setState({ form_values });

    if (isValid) {
      const { user_email, ...rest } = form_errors;
      this.setState({
        form_errors: { ...rest },
      });

      this.checkEmail(value);
    } else {
      this.setState({
        form_errors: { ...form_errors, user_email: true },
      });
    }
  };

  _onExistingmailChangeHandler = (value, isValid) => {
    const { form_values, form_errors } = this.state;

    form_values["existing_email"] = value;
    this.setState({ form_values });

    if (isValid) {
      const { existing_email, ...rest } = form_errors;
      this.setState({
        form_errors: { ...rest },
      });

      this.checkEmail(value);
    } else {
      this.setState({
        form_errors: { ...form_errors, existing_email: true },
      });
    }
  };

  handleChange = e => {
    let { form_values } = this.state;
    if (!e) {
      return;
    }
    if (e.hasOwnProperty("target")) {
      e = e.target;
      this.checkValid(e);
    }

    form_values[e.id] = e.value;
    if (form_values.user_type == "standard") {
      form_values.user_level = null;
    }

    this.setState({
      form_values,
    });
  };

  handleBlur = event => {
    if (event.target) {
      this.checkValid(event.target);
    }
  };

  isValid = () => {
    const { form_values, form_errors } = this.state;

    if (form_values["user_first_name"].length < 1) {
      return false;
    }
    if (form_values["user_last_name"].length < 1) {
      return false;
    }
    if (form_values["user_type"] === null) {
      return false;
    }
    if (form_values["user_contact_phone_number"].length < 1) {
      return false;
    }

    if (form_errors.hasOwnProperty("user_email") && form_errors.user_email) {
      return false;
    }

    return true;
  };

  checkValid = target => {
    const { handleComplete } = this.props;
    switch (target.id) {
      case "user_first_name":
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              user_first_name: "You must enter a first name",
            },
          });
        } else {
          var { user_first_name, ...rest_errors } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case "user_type":
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              user_type: "You must select a user type",
            },
          });
        } else {
          var { user_type, ...rest_errors } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case "user_contact_phone_number":
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              user_contact_phone_number: "You must enter a phone number",
            },
          });
        } else {
          var {
            user_contact_phone_number,
            ...rest_errors
          } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case "user_last_name":
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...this.state.form_errors,
              user_last_name: "You must enter a last name",
            },
          });
        } else {
          var { user_last_name, ...rest_errors } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
    }
  };

  selectMemberType = event => {
    if (event === "existing_member") {
      this.setState({ member_type: "existing_member" });
    }
    if (event === "new_member") {
      this.setState({ member_type: "new_member" });
    }
  };

  cancelCardInfo = () => {
    this.setState({ showCreditInfoModal: false });
  };

  saveCard = response => {
    const { dispatch } = this.props;
    this.setState({ showCreditInfoModal: false });
    if (response) {
      dispatch(loadCard());
    }
  };

  addCardUnfo = addCardInfo => {
    if (addCardInfo) {
      this.setState({ showCreditInfoModal: true });
    }
  };

  hideTooltip = () => {
    this.setState({ isTooltipActive: false });
  };

  hideUserTypeTooltip = () => {
    this.setState({ isUserTypeTooltipActive: false });
  };

  render() {
    const {
      canAddUser,
      card,
      current_pricing_plan,
      email_valid,
      email_validating,
      exists_user,
      onCancel,
      onSubmit,
      options,
      submitting,
      title,
    } = this.props;
    const {
      form_errors,
      form_values,
      form_loading,
      member_type,
      show_member_type,
      showCreditInfoModal,
      isTooltipActive,
      isUserTypeTooltipActive,
    } = this.state;

    let content;
    const components = [];
    const buttons = [];
    let existing_email_textinput = null;
    let tool_tip;
    let user_type_tool_tip;
    let email_error = form_errors["user_email"];
    let existing_email_error = form_errors["existing_email"];

    if (
      !email_error &&
      email_valid === false &&
      !email_validating &&
      form_values["user_email"].length > 0
    ) {
      email_error = "Email already in use. Please use another";
    }

    if (!existing_email_error && email_valid === true && !email_validating) {
      existing_email_error = "Could not find this member. Please use another";
    }

    const errors = { ...form_errors, user_email: email_error };
    let loading = form_loading;
    if (email_validating) {
      loading = { ...loading, user_email: true };
    }
    const valid = this.isValid() && email_valid && !email_validating;
    if (show_member_type && current_pricing_plan.attributes.entity_toggle) {
      Object.keys(options).forEach((key, index) => {
        let selected = false;
        if (member_type === options[key].value) {
          selected = true;
        }

        components.push(
          <CircleIconButton
            key={`button-${index}`}
            value={options[key].value}
            type={key}
            selected={selected}
            text={options[key].title}
            icon={options[key].icon}
            handleClick={this.selectMemberType}
          />,
        );
      });

      if (member_type === "existing_member") {
        existing_email_textinput = (
          <EmailInput
            id={"existing_email"}
            handleChange={this._onExistingmailChangeHandler}
            handleBlur={this.handleBlur}
            label={"Email"}
            value={form_values["existing_email"]}
            customError={existing_email_error}
            required={true}
          />
        );
      }

      content = (
        <div className={styles.field_center}>
          {components}
          <div className={styles.fieldset}>{existing_email_textinput}</div>
        </div>
      );
      if (card || current_pricing_plan.attributes.plan_no == 0) {
        if (
          (member_type === "existing_member" &&
            EMAIL_REGEX.test(form_values["existing_email"]) &&
            email_valid === false &&
            !email_validating) ||
          member_type === "new_member"
        ) {
          buttons.push(
            <Button
              key={"next"}
              text={"next"}
              handleClick={this.showMemberType}
            />,
          );
        } else {
          buttons.push(
            <Button
              key={"required"}
              text={"more info required"}
              disabled={true}
            />,
          );
        }
      } else {
        buttons.push(
          <Button text="Add Card" handleClick={() => this.addCardUnfo(true)} />,
        );
      }
    } else {
      content = (
        <div className={styles.fieldset}>
          <NewUserForm
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            hideUserTypeTooltip={this.hideUserTypeTooltip}
            onEmailChangeHandler={this._onEmailChangeHandler}
            onEmailBlurHandler={this._onEmailChangeHandler}
            form_loading={loading}
            form_values={form_values}
            form_errors={errors}
            existing_member={member_type === "existing_member"}
            exists_user={exists_user}
            isUserTypeTooltipActive={isUserTypeTooltipActive}
          />
        </div>
      );
      buttons.push(
        <Button key={"back"} text={"back"} handleClick={this.showMemberType} />,
      );
      buttons.push(
        <Button
          key={"add"}
          text={
            valid || member_type === "existing_member"
              ? "add team member"
              : "more info required"
          }
          loading_text={"saving"}
          loading={submitting}
          disabled={!valid && member_type === "new_member"}
          disableOnLoading={true}
          handleClick={this.submit}
        />,
      );
      if (current_pricing_plan) {
        if (
          isTooltipActive &&
          current_pricing_plan.attributes.entity_toggle &&
          (member_type === "existing_member" ||
            (valid && member_type === "new_member"))
        ) {
          tool_tip = (
            <ToolTip
              tip_name="AddNewTeamMember"
              css_style="add_new_team_member"
              dismissHandler={this.hideTooltip}
            />
          );
        }
      }
    }

    const noPermissionTitle = "Oops! That is not allowed.";

    if (showCreditInfoModal) {
      return (
        <CreditCardModal
          onSave={this.saveCard}
          onCancel={this.cancelCardInfo}
        />
      );
    }

    return (
      <CircleModal
        size="large"
        title={canAddUser ? title : noPermissionTitle}
        dismissHandler={onCancel}
      >
        {!canAddUser && (
          <p>
            Sorry, you do not have permission to add a user under this account.
          </p>
        )}
        {canAddUser && (
          <Fragment>
            {content}
            <div className={styles.button}>
              {buttons}
              {tool_tip}
            </div>
          </Fragment>
        )}
      </CircleModal>
    );
  }
}

const defaults = {
  title: "Add new team member?",
  options: {
    existing_member: {
      icon: "people_friends",
      title: "Existing member",
      value: "existing_member",
    },
    new_member: {
      icon: "email",
      title: "New member",
      value: "new_member",
    },
  },
};

export default connect(state => {
  const s = state.manage_users;
  const canAddUser = !isPseudoRole();

  return {
    canAddUser,
    card: state.manage_profile.current_entity_card,
    current_pricing_plan: state.pricing_plans.current_pricing_plan,
    email_valid: s.exists_user ? !s.exists_user.user_exists : false,
    email_validating: s.email_validating,
    entity_id: state.current_user.current_entity.id,
    exists_user: s.exists_user,
    options: defaults.options,
    title: defaults.title,
  };
})(CreateUser);
