import Webhook from "models/webhooks/Webhook";
import React from "react";
import { connect } from "react-redux";

import WebhookForm from "../forms/WebhookForm";

class EditWebhook extends React.Component {
  constructor(props) {
    super(props);
    Webhook.find(props.params.webhookId);
  }

  render() {
    const { webhook } = this.props;

    if (webhook) {
      return <WebhookForm webhook={webhook} />;
    }

    return null;
  }
}

module.exports = connect((state, ownProps) => {
  const id = ownProps.params.webhookId;
  const webhook = state.models.webhooks.webhooks.find(
    webhook => webhook.attributes.id === id
  );

  return {
    webhook,
  };
})(EditWebhook);
