import styled, { css } from "styled-components";
import { FORM_SPACING } from "variables/spacing";
import { THEME_COLORS } from "variables/theme";

export const TextAreaElement = styled.textarea`
  padding: ${FORM_SPACING.textareaPadding};

  ${props => props.disableThemeOverride && css`
    border-color: ${THEME_COLORS.primary};

    &:hover {
      border-color: ${THEME_COLORS.primary};
    }
  `}
`;
