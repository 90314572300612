/* Import libraries */
import React from "react";
import Time from "react-time";
import { connect } from "react-redux";
import { checkPopupExist, getPopup } from "utils/tipHelper.js";

//import { browserHistory, Link } from 'react-router';
import moment from "moment";
import UserAvatar from "react-user-avatar";
import { DateRangePicker } from "react-dates";
import AdminTitle from "../../shared/components/widgets/static/AdminTitle";
import TextInput from "modules/shared/components/inputs/TextInput";
import Switch from "modules/shared/components/widgets/interactive/Switch";
import "!style-loader!css-loader!./css/react-dates.css";
import { loadConnections } from "../actions";

/* Import components */
import Clickable from "modules/shared/components/widgets/interactive/Clickable";
import Pagination from "modules/shared/components/widgets/interactive/Pagination";
import RegionStatesDropdown from "modules/shared/components/widgets/static/RegionStatesDropdown";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";

import { setLocation } from "../actions";
import { deleteUserPopup } from "../../user/actions";
import { getAllConnectedApplications } from "modules/applications/actions";
import { isHeadquarter } from "modules/shared/helpers/headquarterDetect";

/* Import styles */
import styles from "./css/ConnectionsList.css";

var ConnectionsList = createClass({
  getInitialState: function() {
    return {
      search: "",
      show_dashboard: "consumer",
      currentPage: 1,
      todosPerPage: options.viewPage[0].value,
    };
  },

  componentDidMount: function() {
    const { dispatch, entity } = this.props;
    if (!entity.attributes.supplier) {
      this.setState({
        show_dashboard: "supplier",
      });
      dispatch(loadConnections({ entity_role: "supplier" }));
    } else {
      dispatch(loadConnections({ entity_role: "consumer" }));
    }
  },

  componentWillUpdate: function(nextProps, nextState) {
    if (
      nextState.search != this.state.search ||
      nextState.show_dashboard != this.state.show_dashboard ||
      nextState.startDate != this.state.startDate ||
      nextState.endDate != this.state.endDate
    ) {
      this.setState({
        currentPage: 1,
      });
    }
  },

  _getExportFilterParams: function() {
    return {
      start_date: this.state.startDate
        ? this.state.startDate.format("YYYY-MM-DD")
        : "",
      end_date: this.state.endDate
        ? this.state.endDate.format("YYYY-MM-DD")
        : "",
      region: this.props.region,
      state: this.props.state,
      search: this.state.search,
      entity_role: this.state.show_dashboard,
    };
  },

  _getFilterParams: function(updated_params) {
    const { currentPage, todosPerPage } = this.state;
    return Object.assign(
      {},
      this._getExportFilterParams(),
      { page: currentPage, per_page: todosPerPage },
      updated_params,
    );
  },

  _reloadConnections: function(new_params) {
    const { dispatch } = this.props;
    dispatch(loadConnections(this._getFilterParams(new_params)));
  },

  searchHandleChange: function(e) {
    this.setState({ search: e.target.value });
    this._reloadConnections({ search: e.target.value });
  },

  showConsumerDashboard: function() {
    this.setState({ show_dashboard: "consumer" });
    this._reloadConnections({ entity_role: "consumer" });
  },

  showSupplierDashboard: function() {
    this.setState({ show_dashboard: "supplier" });
    this._reloadConnections({ entity_role: "supplier" });
  },

  changeCurrentPage: function(value) {
    this.setState({ currentPage: value });
    this._reloadConnections({ page: value });
  },

  changeTodosPerPage: function(value) {
    this.setState({ todosPerPage: value });
    this._reloadConnections({ per_page: value });
  },

  locationHandleChange: function(e) {
    const { dispatch } = this.props;
    if (e.id) {
      dispatch(setLocation(e.id, e.value));
    }
    var params = {};
    params[e.id] = e.value;
    this._reloadConnections(params);
  },

  exportApplications: function(optionDropdownItem) {
    const { dispatch } = this.props;
    dispatch(
      getAllConnectedApplications(
        optionDropdownItem.value,
        this._getExportFilterParams(),
      ),
    );
  },

  hideTooltip: function(popup_name) {
    const { dispatch, current_user_popups } = this.props;
    let popup = getPopup(current_user_popups, popup_name);
    if (popup) {
      dispatch(deleteUserPopup(popup.id));
    }
  },

  render: function() {
    const {
      data,
      total_count,
      region,
      state,
      current_user_popups,
    } = this.props;
    const {
      show_dashboard,
      search,
      startDate,
      endDate,
      currentPage,
      todosPerPage,
    } = this.state;

    let basePath = "/dashboard/connections/details/",
      connectionElements = [];

    // Logic for displaying todos (pagination)
    let indexOfLastTodo = currentPage * todosPerPage;
    let indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    let renderPagination = null;

    if (total_count > 0) {
      renderPagination = (
        <Pagination
          dataLength={total_count}
          currentPage={currentPage}
          todosPerPage={todosPerPage}
          optionViewPage={options.viewPage}
          handleClick={this.changeCurrentPage}
          handleClickTodosPerPage={this.changeTodosPerPage}
        />
      );
    }
    data.forEach((v, i) => {
      let tool_tip;
      if (
        i == 0 &&
        checkPopupExist(current_user_popups, "connection_list_relationship")
      ) {
        tool_tip = (
          <ToolTip
            tip_name="ConnectionListRelationship"
            css_style="connection_list_relationship"
            dismissHandler={this.hideTooltip.bind(
              null,
              "connection_list_relationship",
            )}
          />
        );
      }

      connectionElements.push(
        <li className={styles.item} key={`relationship-${i}`}>
          <h4 className={styles.item_name}>{v.trading_name}</h4>
          <h4 className={styles.item_type}>{v.attributes.application_type}</h4>
          <Time
            className={styles.date_connected}
            value={v.attributes.created_at}
            format="DD/MM/YYYY"
          />
          {isHeadquarter() && (
            <h4 className={styles.item_type}>
              {v.attributes.supplier_trading_name}
            </h4>
          )}
          <Clickable
            className={styles.url}
            target={`${basePath}${v.connection_id}/${show_dashboard}`}
          >
            view relationship
          </Clickable>
          {v.attributes.archived && (
            <h4 className={styles.archived}>archived</h4>
          )}
          {tool_tip}
        </li>,
      );
    });

    return (
      <div className={styles.myconnections}>
        <div className={styles.row}>
          <div className={styles.title_col}>
            <AdminTitle text="Connections" />
          </div>
          <div className={styles.switch}>
            <Switch
              leftOption={"consumer"}
              rightOption={"supplier"}
              leftLabel={"My customers"}
              rightLabel={"My suppliers"}
              current={show_dashboard}
              targetLeft={this.showConsumerDashboard}
              targetRight={this.showSupplierDashboard}
            />
          </div>
        </div>
        <div className={styles.search}>
          <div className={styles.search_input}>
            <TextInput
              id="search"
              value={this.state.search}
              handleChange={this.searchHandleChange}
              label={`Search ${show_dashboard} name`}
              required={true}
            />
          </div>
          <div className={styles.location}>
            <RegionStatesDropdown
              id={"Business location"}
              regionId="region"
              stateId="state"
              label={"Business location"}
              regionValue={region}
              stateValue={state}
              handleChange={this.locationHandleChange}
              isSearch={true}
              allow_all={true}
            />
          </div>
          <div className={styles.date_range}>
            <DateRangePicker
              isOutsideRange={() => false}
              displayFormat="DD/MM/YYYY"
              startDate={this.state.startDate} // momentPropTypes.momentObj or null,
              startDateId="connections_list_range_start"
              endDate={this.state.endDate} // momentPropTypes.momentObj or null,
              endDateId="connections_list_range_end"
              onDatesChange={({ startDate, endDate }) =>
                this.setState({ startDate, endDate })
              } // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              hideKeyboardShortcutsPanel={true}
            />
          </div>
          <div className={styles.export}>
            <OptionsDropdown
              id={"export_connections"}
              handleChange={this.exportApplications}
              label={this.props.exporting ? "Exporting" : "Export File"}
              options={[
                { value: "xlsx", label: "Excel/xlsx" },
                { value: "csv", label: "CSV/csv" },
              ]}
              value={""}
              tip_name={"ConnectionExportAll"}
              tip_css_style={"export_file"}
              disabled={this.props.exporting}
            />
          </div>
        </div>
        <div className={styles.results}>
          <div className={styles.results_header}>
            <h3 className={styles.results_title}>
              {show_dashboard === "consumer" ? "My customers" : "My suppliers"}
            </h3>
            <h3 className={styles.results_title}>Account type</h3>
            <h3 className={styles.results_title}>Date connected</h3>
            {isHeadquarter() && (
              <h3 className={styles.results_title}>Branch</h3>
            )}
          </div>
          <div>{<ul className={styles.list}>{connectionElements}</ul>}</div>
          {renderPagination}
        </div>
      </div>
    );
  },
});

var options = {
  viewPage: [
    {
      label: "25",
      value: 25,
    },
    {
      label: "50",
      value: 50,
    },
  ],
};

module.exports = connect((state, ownProps) => {
  let loading = state.connections.all_connections_list_loading;
  let length = state.connections.all_connections_list.length;

  return {
    loading: loading && length < 1,
    data: state.connections.all_connections_list,
    total_count: state.connections.total_connections_count,
    entity: state.current_user.current_entity,
    region: state.connections.region,
    state: state.connections.state,
    exporting: state.applications.exporting,
    current_user_popups: state.current_user.current_user_popups,
  };
})(ConnectionsList);
