import styled from 'styled-components';
import { FORM_SPACING, SPACING } from 'variables/spacing';
import { BORDERS } from 'variables/theme';
import { FONT_SETS } from "variables/typography";

export const Title = styled.h3`
  ${FONT_SETS.sectionHeader};
  margin-bottom: ${FORM_SPACING.titleMargin};
`;

export const Section = styled.div`
  > *:not(:first-child) {
    ${Title} {
      border-top: ${BORDERS.generic};
      padding-top: ${SPACING.lg};
    }
  }
`;