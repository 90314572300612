import PPSRRegistrationIndex from "modules/ppsr_registration/components/PPSRRegistrationIndex";

const PPSRRegistrationRoute = {
  indexRoute: {
    component: PPSRRegistrationIndex,
  },
  path: "ppsr_registrations",
};

export default PPSRRegistrationRoute;
