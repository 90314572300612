import axios from "./axios";;

let Guarantors = function(config) {
  this.axios = axios(config);
  this.type = "guarantors";
};

Guarantors.prototype.createGuarantor = function(
  application_id,
  attributes,
  success,
  error,
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
        relationships: {
          application: {
            data: {
              type: "applications",
              id: application_id,
            },
          },
        },
      },
    })
    .then(success)
    .catch(error);
};

Guarantors.prototype.updateGuarantor = function(
  id,
  attributes,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

Guarantors.prototype.delete = function(id, success, error, options = {}) {
  return this.axios
    .delete(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

Guarantors.prototype.getGuarantor = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

export default Guarantors;
