import BottomRight from "containers/position/BottomRight";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
/* Import libraries */
import React from "react";
import { connect } from "react-redux";

import styles from "./css/CreditCheckModal.css";

/* Import components */
const Modal = createClass({
  render() {
    const {
      title,
      bodyText,
      button,
      nzCreditCheckComponents,
      auCreditCheckComponents,
      nzPersonalCreditCheckComponents,
      auPersonalCreditCheckComponents,
      dismissHandler,
      card,
      role_types,
      close_button_style,
      update_loading,
      entity_region,
    } = this.props;

    let credit_card_message;
    if (!card && !role_types.includes("admin")) {
      credit_card_message = (
        <span className={styles.message}>
          Please ask your administrator to add credit card.
        </span>
      );
    }
    const region = entity_region.toLowerCase();
    let contents;

    switch (region) {
      case "au":
        contents = (
          <div>
            {this.renderAUCompany()}
            {this.renderAUPersonal()}
            {this.renderNZCompany()}
            {this.renderNZPersonal()}
          </div>
        );
        break;
      default:
        contents = (
          <div>
            {this.renderNZCompany()}
            {this.renderNZPersonal()}
            {this.renderAUCompany()}
            {this.renderAUPersonal()}
          </div>
        );
        break;
    }

    return (
      <div className={styles.modal}>
        {update_loading ? (
          <SimpleLoader />
        ) : (
          <div className={styles.fade}>
            <div className={styles.content}>
              <div className={styles.container}>
                <div className={styles.col}>
                  <div className={styles.body}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.titleDesc}>
                      If you are trading in both New Zealand and Australia and
                      would like Credit Reports provided, please select a credit
                      agency for each country.
                      <br />
                      After your customer has completed their application, you
                      can conduct a credit check when reviewing the application.
                      You will also be able to conduct credit checks on
                      guarantors once they have agreed to guarantee the account.
                      You will not be charged unless you have initiated the
                      checks on each application. Costs excl. GST.
                    </p>

                    {contents}
                    {credit_card_message}
                    <BottomRight>{button}</BottomRight>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <CloseButton
          text="Close"
          css_class={close_button_style}
          handleClick={dismissHandler}
        />
      </div>
    );
  },

  renderAUCompany() {
    const { auCreditCheckComponents } = this.props;
    if (auCreditCheckComponents.length > 0) {
      return (
        <div className={styles.section}>
          <div className={styles.subTitle}>Company Credit Checks Australia</div>
          {auCreditCheckComponents}
        </div>
      );
    }
    return "";
  },

  renderAUPersonal() {
    const { auPersonalCreditCheckComponents } = this.props;
    if (auPersonalCreditCheckComponents.length > 0) {
      return (
        <div className={styles.section}>
          <div className={styles.subTitle}>
            Personal Credit Checks Australia
          </div>
          {auPersonalCreditCheckComponents}
        </div>
      );
    }
    return "";
  },

  renderNZCompany() {
    const { nzCreditCheckComponents } = this.props;
    if (nzCreditCheckComponents.length > 0) {
      return (
        <div className={styles.section}>
          <div className={styles.subTitle}>
            Company Credit Checks New Zealand
          </div>
          {nzCreditCheckComponents}
        </div>
      );
    }
    return "";
  },

  renderNZPersonal() {
    const { nzPersonalCreditCheckComponents } = this.props;
    if (nzPersonalCreditCheckComponents.length > 0) {
      return (
        <div className={styles.section}>
          <div className={styles.subTitle}>
            Personal Credit Checks New Zealand
          </div>
          {nzPersonalCreditCheckComponents}
        </div>
      );
    }
    return "";
  },
});

module.exports = connect((state, ownProps) => {
  //const { onCancel} = this.props;
  return {
    card: state.manage_profile.current_entity_card,
    entity_region: state.current_user.current_entity.attributes.region || "",
    role_types: state.current_user.current_user_entity_link.attributes.role_types,
  };
})(Modal);
