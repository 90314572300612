import React, { Component } from "react";

import styles from "modules/shared/components/text/css/StandardTermsAndConditions.css";

export default class AgreementSection9 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>9.</span> Termination
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>9.1</span>{" "}
          <strong>Breach or insolvency</strong>: Either party may terminate the
          agreement and these Terms by giving written notice to the other party
          if the other party:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> breaches any provision
          of these Terms and fails to remedy such breach within 15 days after
          written notice of such breach is given to the breaching party; or
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> ceases to carry on
          business, goes into liquidation or if a receiver or receiver and
          manager, administrator or statutory manager is appointed or if the
          other party enters into a scheme of arrangement or compromise with its
          creditors or is adjudicated bankrupt.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>9.2</span>{" "}
          <strong>Consequences of termination or expiry</strong>: Upon expiry or
          termination of the agreement and these Terms for any reason:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> 1Centre shall have no
          obligation to provide the Service and the Supplier shall have no right
          to use the Service; and
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> the Supplier will
          immediately pay all outstanding invoices and for completed Services.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>9.3</span>{" "}
          <strong>Preservation of rights</strong>: Termination of the agreement
          and these Terms shall not affect:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> the rights of a party
          which accrued prior to termination; or
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> the provisions of these
          Terms which by their nature, survive termination or expiry namely
          clauses 7 (IP Ownership and Data Use), clause 8 (Confidential
          Information), this section 9 (Termination), clause 10 (Force majeure)
          and 11 (Liability).
        </p>
      </div>
    );
  }
}
