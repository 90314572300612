import React from "react";
import GridContent from "modules/shared/components/containers/GridContent";
import BorderedCalendarPicker from "modules/shared/components/inputs/BorderedCalendarPicker";
import get from "lodash.get";
import moment from "moment";
import isPresent from "utils/isPresent";

function getMinDate(expiryDate) {
  if (isPresent(expiryDate)) {
    return moment(expiryDate).toDate();
  }

  return new Date();
}

export default function NZPPSRRenewForm(props) {
  const {
    errors,
    isLoading,
    maxDate,
    onSelectDate,
    ppsrFinancingStatement,
    register,
    selectedDate,
  } = props;

  const minDate = getMinDate(ppsrFinancingStatement.expiryDate);

  return (
    <GridContent>
      <BorderedCalendarPicker
        textFieldProps={{
          error: Boolean(errors.expiryDate),
          helperText: get(errors, "expiryDate.message", " "),
          id: "renew-expiry-date-datepicker",
          inputRef: register,
          name: "expiryDate",
        }}
        isDisabled={isLoading}
        minDate={minDate}
        maxDate={maxDate}
        value={selectedDate}
        onChange={onSelectDate}
        withBottomMargin={false}
      />
    </GridContent>
  );
}
