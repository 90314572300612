import EmailVerification from "modules/shared/components/v2/Modules/EmailVerification";
import React from "react";

import Authorisation from "./components/Authorisation";
import Complete from "./components/Complete";
import Details from "./components/Details";
import Terms from "./components/Terms";

export function componentFactory(section, props = {}) {
  switch (section) {
    case "email-verification":
      return <EmailVerification {...props} application={props.cardManagementApplication} />;
    case "details":
      return <Details {...props} />;
    case "authorisation":
      return <Authorisation {...props} />;
    case "terms":
      return <Terms {...props} />;
    case "complete":
      return <Complete {...props} />;
  }
}
