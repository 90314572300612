/* Import libraries */
import React from "react";

/* Import components */
import Button from "../../inputs/Button";

var ArrowButton = createClass({
  render: function() {
    const { down, next, prev, circle, finished, ...rest } = this.props;
    var arrow;

    if (down === true && next !== true) {
      arrow = "down";
    }

    if (down === true && next === true) {
      arrow = "down";
    }

    if (next === true && down === false) {
      arrow = "next";
    }

    if (next === true && down !== true && circle === true) {
      arrow = "right_circle";
    }

    if (finished === true && down === true) {
      arrow = "finished";
    }

    if (prev === true) {
      arrow = "prev";
    }

    if (prev === true && circle === true) {
      arrow = "left_circle";
    }

    return <Button arrow={arrow} {...rest} />;
  },
});

export default ArrowButton;
