import React from "react";

import styles from "../centrix.css";

import moment from "moment";

class ConsumerInformation extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  renderName(person) {
    return (
      <span>
        {person.surname.toUpperCase()}
        {`, ${person.first_name}`}
        {person.middle_name && ` ${person.middle_name}`}
      </span>
    );
  }

  renderAddress(address) {
    return (
      <span>
        {address.address_line1}
        {address.address_line2 && `, ${address.address_line_2}`}
        {address.suburb && `, ${address.suburb}`}
        {address.city && `, ${address.city}`}
        {address.postcode && `, ${address.postcode}`}
      </span>
    );
  }

  renderAddresses(addresses) {
    let last_known = addresses.find(address => address.last_known)
    let other_known = addresses.filter(address => !address.last_known)

    return (
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Latest Known Address:</th>
              <th>Date First Loaded</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.renderAddress(last_known)}</td>
              <td>{moment(last_known.address_date).format("DD/MM/YYYY")}</td>
            </tr>
            {other_known.length > 0 && (
              <tr>
                <th>Other Known Address:</th>
                <th>Date First Loaded</th>
              </tr>
            )}
            {other_known.length > 0 && (other_known.map((address) => (
              <tr>
                <td>{this.renderAddress(address)}</td>
                <td>{moment(address.address_date).format("DD/MM/YYYY")}</td>
              </tr>
              )))}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const { data_sets } = data;
    const personal = data_sets.consumer_file.personal;
    return (
      <div>
        <div className={styles.table_container}>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>{this.renderName(personal)}</td>
              </tr>
              <tr>
                <td>Gender:</td>
                <td>
                  {personal.gender == "M"
                    ? "Male"
                    : personal.gender == "F"
                    ? "Female"
                    : "Unknown"}
                </td>
              </tr>
              <tr>
                <td>Date of Birth:</td>
                <td>{moment(personal.date_of_birth).format("DD/MM/YYYY")}</td>
              </tr>
              <tr>
                <td>Also Known As:</td>
                <td>
                  {data_sets.known_names &&
                    (data_sets.known_names.known_name.length
                      ? data_sets.known_names.known_name.map((name, i) => (
                          <div>
                            {this.renderName(name)}
                            {i > 0 && <br />}
                          </div>
                        ))
                      : this.renderName(data_sets.known_names.known_name))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {data_sets.known_names && (
          <div className={styles.table_container}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Also Known As:</th>
                  <th>Date of Birth:</th>
                </tr>
              </thead>
              <tbody>
                {data_sets.known_names.known_name.length ? (
                  data_sets.known_names.known_name.map((name, i) => (
                    <tr>
                      <td>{this.renderName(name)}</td>
                      <td>{moment(name.date_of_birth).format("DD/MM/YYYY")}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>{this.renderName(data_sets.known_names.known_name)}</td>
                    <td>
                      {moment(
                        data_sets.known_names.known_name.date_of_birth,
                      ).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {data_sets.known_addresses &&
          this.renderAddresses(data_sets.known_addresses.known_address)}
      </div>
    );
  }
}

export default ConsumerInformation;
