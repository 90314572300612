import {
  COB_ADDITIONAL_COMPONENT_COMPLETE,
  COB_ADDITIONAL_LOAD_ANSWERS,
  COB_ADDITIONAL_SET_ANSWER,
  COB_ADDITIONAL_SET_FILE,
} from "../constants/additional";

const additionalDefaults = {
  answers: { results: [] },
  completed: {},
  file: null,
};

export function consumerOnboardingAdditionalReducer(
  state = additionalDefaults,
  action,
) {
  switch (action.type) {
    case COB_ADDITIONAL_SET_ANSWER: {
      const results = state.answers.results;
      const index = results.findIndex(
        answer => answer.description === action.payload.description,
      );
      if (index === -1) {
        results.push(action.payload);
      } else {
        results[index] = action.payload;
      }
      return { ...state, answers: { results } };
    }
    case COB_ADDITIONAL_COMPONENT_COMPLETE: {
      const componentState = {};
      componentState[action.payload.component] = action.payload.state;
      return { ...state, completed: { ...state.completed, ...componentState } };
    }
    case COB_ADDITIONAL_SET_FILE:
      return {
        ...state,
        file: action.payload,
      };
    case COB_ADDITIONAL_LOAD_ANSWERS: {
      return { ...state, ...action.payload };
    }
    default:
      return { ...state };
  }
}
