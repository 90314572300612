/* Import libraries */
import React from "react";
import { connect } from "react-redux";

/* Import components */
import SquareModal from "modules/shared/components/widgets/static/SquareModal";
import Button from "modules/shared/components/inputs/Button";

var ReviewAgreeModal = createClass({
  render: function() {
    const { title, handleDismiss, okHandler, value } = this.props;
    let body;

    body = (
      <div>
        <p>
          To agree to the terms and conditions you must first view the {value}{" "}
          terms before selecting the 'I agree' checkbox
        </p>
        <div>
          <Button text={"View terms"} handleClick={okHandler} />
          <Button text={"Close"} white={true} handleClick={handleDismiss} />
        </div>
      </div>
    );

    return (
      <SquareModal title={title} size={"small"}>
        {body}
      </SquareModal>
    );
  },
});

module.exports = ReviewAgreeModal;
