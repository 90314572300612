/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

/* Import actions */
import { setPermissionsAccountRules } from "../actions";

/* Import CSS */
import styles from "./css/PermissionsAccountRules.css";

/* Import components */
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";

var PermissionsAccountRules = createClass({
  turnOnOff: function(e) {
    const {
      dispatch,
      permissions_requires_credit_check,
      permissions_requires_identification_check,
      permissions_requires_guarantees,
      permissions_requires_trade_reference_check,
    } = this.props;

    let value = false;
    switch (e.target.id) {
      case "permissions_requires_credit_check":
        value = permissions_requires_credit_check;
        break;
      case "permissions_requires_identification_check":
        value = permissions_requires_identification_check;
        break;
      case "permissions_requires_guarantees":
        value = permissions_requires_guarantees;
        break;
      case "permissions_requires_trade_reference_check":
        value = permissions_requires_trade_reference_check;
        break;
    }

    dispatch(setPermissionsAccountRules(e.target.id, !value));
  },

  hasChanges: function() {
    const {
      ent_requires_credit_check,
      ent_requires_identification_check,
      ent_requires_guarantees,
      ent_requires_trade_reference_check,
      ent_minimum_trade_references,
      permissions_requires_credit_check,
      permissions_requires_identification_check,
      permissions_requires_guarantees,
      permissions_requires_trade_reference_check,
    } = this.props;

    return (
      ent_requires_credit_check != permissions_requires_credit_check ||
      ent_requires_identification_check !=
        permissions_requires_identification_check ||
      ent_requires_guarantees != permissions_requires_guarantees ||
      !!ent_minimum_trade_references !=
        permissions_requires_trade_reference_check
    );
  },

  render: function() {
    const {
      ent_requires_credit_check,
      ent_requires_identification_check,
      ent_requires_guarantees,
      ent_requires_trade_reference_check,
      ent_minimum_trade_references,
      permissions_requires_credit_check,
      permissions_requires_identification_check,
      permissions_requires_guarantees,
      permissions_requires_trade_reference_check,
      tool_tip,
    } = this.props;

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.panel}>
            <PanelTitle text={"Confirm applications checks"} />
            <h3 className={styles.description}>
              These checks are application specific, they are currently set to
              your admin defaults.
            </h3>
            {tool_tip}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.panel}>
            <div className={styles.liner}>
              <div
                className={[
                  styles.warning,
                  this.hasChanges() && styles.warning_active,
                ].join(" ")}
              >
                This application differs from your standard application profile.
                This may cause your auto-decisioning rulesets to defer this
                application to a manual review.
              </div>
              {ent_requires_credit_check && (
                <div className={styles.row}>
                  <SquareCheckbox
                    key={"permissions_requires_credit_check"}
                    checkboxId={"permissions_requires_credit_check"}
                    checked={permissions_requires_credit_check}
                    handleChange={this.turnOnOff}
                    label={"Credit Check"}
                  />
                </div>
              )}

              {ent_requires_identification_check && (
                <div className={styles.row}>
                  <SquareCheckbox
                    key={"permissions_requires_identification_check"}
                    checkboxId={"permissions_requires_identification_check"}
                    checked={permissions_requires_identification_check}
                    handleChange={this.turnOnOff}
                    label={"Identity Check/s"}
                  />
                </div>
              )}

              {ent_requires_guarantees && (
                <div className={styles.row}>
                  <SquareCheckbox
                    key={"permissions_requires_guarantees"}
                    checkboxId={"permissions_requires_guarantees"}
                    checked={permissions_requires_guarantees}
                    handleChange={this.turnOnOff}
                    label={"Guarantee/s"}
                  />
                </div>
              )}

              {ent_minimum_trade_references > 0 && (
                <div className={styles.row}>
                  <SquareCheckbox
                    key={"permissions_requires_trade_reference_check"}
                    checkboxId={"permissions_requires_trade_reference_check"}
                    checked={permissions_requires_trade_reference_check}
                    handleChange={this.turnOnOff}
                    label={"Trade Reference Check/s"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  },
});

module.exports = connect((state, ownProps) => {
  let ent = (state.manage_profile.current_entity || {}).attributes || {};

  let ent_requires_credit_check = ent.requires_credit_check || false;
  let ent_requires_identification_check =
    ent.requires_applicant_identification_check ||
    ent.requires_guarantees_identification_check ||
    false;
  let ent_requires_guarantees = ent.requires_guarantees || false;
  let ent_requires_trade_reference_check =
    ent.requires_trade_reference_check || false;
  let ent_minimum_trade_references = ent.minimum_trade_references || 0;

  return {
    ent_requires_credit_check: ent_requires_credit_check,
    ent_requires_identification_check: ent_requires_identification_check,
    ent_requires_guarantees: ent_requires_guarantees,
    ent_requires_trade_reference_check: ent_requires_trade_reference_check,
    ent_minimum_trade_references: ent_minimum_trade_references,
    permissions_requires_credit_check:
      state.manage_profile.permissions_requires_credit_check,
    permissions_requires_identification_check:
      state.manage_profile.permissions_requires_identification_check,
    permissions_requires_guarantees:
      state.manage_profile.permissions_requires_guarantees,
    permissions_requires_trade_reference_check:
      state.manage_profile.permissions_requires_trade_reference_check,
  };
})(PermissionsAccountRules);
