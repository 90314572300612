import camelCase from "lodash.camelcase";
import isPlainObject from "lodash.isplainobject";

export default function objectKeysToCamelCase<T>(object: T): T | { [key: string]: T } {
  if (!isPlainObject(object)) {
    return object;
  }

  const keys = Object.keys(object);
  const newObject = {};

  for (const key of keys) {
    const value = object[key];

    let newValue = value;

    if (isPlainObject(value)) {
      newValue = objectKeysToCamelCase(value);
    }

    newObject[camelCase(key)] = newValue;
  }

  return newObject;
}
