import get from "lodash.get";
import PpsrFinancingStatementModel from "models/PpsrFinancingStatementModel";
import { getAlertErrorMessage } from "modules/new-applications/components/application-actions/PPSRRegister";
import { FinancingStatementPin } from "modules/new-applications/components/application-actions/PPSRRegister/PPSRRegisterForm";
import styles from "modules/new-applications/css/PPSRRegister/PPSRRegisterDischarge.css";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import FixedContent from "modules/shared/components/containers/FixedContent";
import Button from "modules/shared/components/inputs/Button";
import Modal from "modules/shared/components/v2/Modal";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const DISCHARGE_SCHEMA = yup.object().shape({
  financingStatementPin: yup
    .string()
    .required("Please provide financing statement pin to discharge."),
});

function ConfirmModal(props) {
  const { isLoading, isVisible, onClose, onConfirm } = props;

  if (!isVisible) {
    return null;
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }

    onConfirm();
  };

  return (
    <Modal
      onClose={onClose}
      footer={
        <div className={`is-flex ${styles.modal_footer_contents}`}>
          <Button
            red
            handleClick={onClick}
            text="Yes, discharge PPSR"
            type="button"
            loading={isLoading}
            disabled={isLoading}
          />
        </div>
      }
    >
      <div className="has-text-weight-normal has-text-centered">
        Discharge means the financing statement will expire immediately and will
        no longer appear as a current registration on the PPSR. Please confirm
        you&apos;d like to discharge.
      </div>
    </Modal>
  );
}

export default function PPSRRegisterDischarge(props) {
  const {
    application,
    currentUser,
    onClickBackToDetails,
    onSetAlert,
    onSetDischargedPPSRFinancingStatement,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
  } = props;
  const { isLoading, setIsLoading } = useIsLoadingState();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { errors, getValues, handleSubmit, register, setError } = useForm({
    validationSchema: DISCHARGE_SCHEMA,
  });

  const onConfirm = () => {
    const financingStatementPin = getValues("financingStatementPin");

    setIsLoading(true);

    ppsrFinancingStatement.discharge({
      currentUser,
      financingStatementPin,
      onErrorCallback: error => {
        onSetAlert({ message: getAlertErrorMessage(error), type: "error" });

        const responsePPSRFinancingStatement = get(
          error,
          "response.data.ppsr_financing_statement",
        );
        if (responsePPSRFinancingStatement) {
          const newPpsrFinancingStatement = new PpsrFinancingStatementModel(
            responsePPSRFinancingStatement.data,
            responsePPSRFinancingStatement.included,
          );
          onSetPPSRFinancingStatement(newPpsrFinancingStatement);
        }

        setIsLoading(false);
      },
      onSuccessCallback: newPpsrFinancingStatement => {
        setIsLoading(false);
        onSetDischargedPPSRFinancingStatement(newPpsrFinancingStatement);
        onSetAlert({ message: "Successfully discharged.", type: "success" });
        onClickBackToDetails();
      },
    });
  };

  const onSubmit = event => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    handleSubmit(data => {
      const { financingStatementPin } = data;

      if (
        financingStatementPin !== ppsrFinancingStatement.financingStatementPin
      ) {
        setError(
          "financingStatementPin",
          "required",
          "Please provide correct financing statement PIN.",
        );
        return;
      }

      setIsModalVisible(true);
    })();
  };

  return (
    <Fragment>
      <ConfirmModal
        isLoading={isLoading}
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onConfirm={onConfirm}
      />
      <FixedContent>
        <form onSubmit={onSubmit}>
          <FinancingStatementPin
            actionText="discharge"
            errors={errors}
            region={application.region}
            register={register}
          />
          <div className="field is-grouped">
            <div className="control">
              <Button white text="Back" handleClick={onClickBackToDetails} />
            </div>
            <div className="control">
              <Button red type="submit" text="Discharge" disabled={isLoading} />
            </div>
          </div>
        </form>
      </FixedContent>
    </Fragment>
  );
}
