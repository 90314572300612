import React from "react";
import { connect } from "react-redux";

/* Import constants */
import { EMAIL_REGEX } from "constants";

import styles from "./css/TradeReference.css";

import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import LabeledInformation from "modules/shared/components/widgets/static/LabeledInformation";

import SubSectionRefereeDetails from "./SubSectionRefereeDetails";

import TextInput from "modules/shared/components/inputs/TextInput";
import EmailInput from "modules/shared/components/inputs/EmailInput";
import CountryCodeDropdown from "modules/shared/components/widgets/static/CountryCodeDropdown";

import { setRefereeData } from "../../actions/trade-reference";
import PageHeader from "modules/shared/components/v2/PageHeader";

var Referees = createClass({
  componentWillReceiveProps: function(nextProps) {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete(nextProps.form_values));

    if (nextProps.page_validation_start) {
      this.checkValidAll();
    }
  },

  checkValidAll: function() {
    const { form_values, setPageValidationStartFinish } = this.props;

    [
      "first_name",
      "last_name",
      "business_name",
      "contact_phone",
      "contact_country_code",
      "email",
    ].forEach((value, index) => {
      let target = {
        id: value,
        value: form_values[value],
      };
      setTimeout(() => {
        if (value === "email") {
          this.refs.email._onBlur();
        } else {
          this.checkFormErrors(target);
        }
      }, 500);
    });

    setPageValidationStartFinish();
  },

  isComplete: function(form_values) {
    const { item_count } = this.props;
    var re = EMAIL_REGEX;

    if (!form_values) {
      var { form_values } = this.props;
    }

    if (
      this.state.form_errors.hasOwnProperty("email") ||
      (form_values.email &&
        form_values.email.length > 0 &&
        !re.test(form_values.email))
    ) {
      return false;
    }

    if (
      form_values.business_name &&
      form_values.contact_phone &&
      form_values.contact_country_code &&
      form_values.first_name
    ) {
      return true;
    }
    return false;
  },

  componentDidMount: function() {
    const { dispatch, handleComplete } = this.props;
    handleComplete(this.isComplete());
  },

  handleChange: function(event) {
    const { dispatch, component_index } = this.props;
    if (event.target) {
      this.checkFormErrors(event.target);
    }

    dispatch(
      setRefereeData(component_index, event.target.name, event.target.value),
    );
  },

  _emailChangeCallback() {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete());
  },

  _emailChangeHandler(value, isValid) {
    const { dispatch, component_index } = this.props;
    const { form_errors } = this.state;

    dispatch(setRefereeData(component_index, "email", value));

    this.setState({ isEmailInvalid: !isValid });

    if (isValid) {
      const { email, ...rest } = form_errors;
      this.setState(
        {
          form_errors: { ...rest },
        },
        this._emailChangeCallback,
      );
    } else {
      this.setState(
        {
          form_errors: { ...form_errors, email: true },
        },
        this._emailChangeCallback,
      );
    }
  },

  handleBlur: function(event) {
    this.checkFormErrors(event.target);
  },

  countryCodeHandleChange(target) {
    const { dispatch, component_index } = this.props;

    if (target) {
      this.checkFormErrors(target);
    }
    dispatch(setRefereeData(component_index, target.name, target.value));
  },

  checkFormErrors: function(target) {
    const { form_errors, error_messages } = this.state;
    switch (target.id) {
      case "first_name":
        if (!target.value || target.value.length < 1) {
          var new_form_errors = {
            ...form_errors,
            [target.id]: error_messages[target.id],
          };
          this.setState({
            form_errors: new_form_errors,
          });
        } else {
          var errors = Object.assign({}, this.state.form_errors);
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case "business_name":
        if (!target.value || target.value.length < 1) {
          var new_form_errors = {
            ...form_errors,
            [target.id]: error_messages[target.id],
          };
          this.setState({
            form_errors: new_form_errors,
          });
        } else {
          var errors = Object.assign({}, this.state.form_errors);
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case "contact_phone":
        var new_form_errors = form_errors;
        if (!target.value || target.value.length < 1) {
          var new_form_errors = {
            ...form_errors,
            [target.id]: error_messages[target.id],
          };
          this.setState({
            form_errors: new_form_errors,
          });
        } else {
          var errors = Object.assign({}, this.state.form_errors);
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case "contact_country_code":
        var new_form_errors = form_errors;
        if (!target.value || target.value.length < 1) {
          var new_form_errors = {
            ...form_errors,
            [target.id]: error_messages[target.id],
          };
          this.setState({
            form_errors: new_form_errors,
          });
        } else {
          var errors = Object.assign({}, this.state.form_errors);
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
    }
  },

  getInitialState: function() {
    return {
      form_errors: [{}],
      error_messages: {
        first_name: "You need to enter a first name.",
        contact_phone: "You need to enter a phone number.",
        contact_country_code: "You need to enter a country code.",
        business_name: "You need to enter a business name.",
      },
    };
  },

  render: function() {
    const {
      component_index,
      form_values,
      complete,
      excluded_application_sources,
      minimum_trade_references,
      supplier_name,
    } = this.props;
    const { form_errors } = this.state;

    var sub_pages = new Array();

    sub_pages.push(
      <SubSectionRefereeDetails
        key="details"
        ref="details"
        handleChange={this.handleChange}
        emailChangeHandler={this._emailChangeHandler}
        countryCodeHandleChange={this.countryCodeHandleChange}
        handleBlur={this.handleBlur}
        errors={form_errors}
        values={form_values}
      />,
    );

    let sectionComplete = complete;
    let digits = ["", "one", "two", "three", "four"];

    return (
      <section className={styles.section}>
        <PageHeader title="Trade Reference">
          <span className={styles.emphasize}>
            Do not nominate yourself, employer, ex-employer or any other
            person connected to your business as a referee.&nbsp;
          </span>
          If you're a brand new business please input accountant's contact
          details for all references.
        </PageHeader>
        <div className={styles.row}>
          <div className={styles.full_col}>
            <PanelTitle
              text={"Reference " + digits[component_index + 1]}
              margin_bottom="1em"
            />
          </div>

          <div className={styles.referees_col}>
            {/* <SubSectionContainer inactiveButtons={false} size='large' subPages={sub_pages} subsection_index={0} position_off={true} /> */}
            <div className={styles.flow_styles}>
              <div className={styles.row}>
                <div className={styles.input_col}>
                  <TextInput
                    id="first_name"
                    name="first_name"
                    error={form_errors["first_name"]}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                    label="First name"
                    required={true}
                    value={form_values["first_name"]}
                  />
                </div>
                <div className={styles.input_col}>
                  <TextInput
                    id="last_name"
                    name="last_name"
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                    label="Last name"
                    required={false}
                    value={form_values["last_name"]}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.input_col}>
                  <TextInput
                    id="business_name"
                    name="business_name"
                    error={form_errors["business_name"]}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                    label="Business name"
                    required={true}
                    value={form_values["business_name"]}
                  />
                </div>
                <div className={styles.input_col}>
                  <EmailInput
                    id="email"
                    name="email"
                    ref="email"
                    handleChange={this._emailChangeHandler}
                    handleBlur={this._emailChangeHandler}
                    label="Email"
                    required={false}
                    value={form_values["email"]}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.country_code}>
                  <CountryCodeDropdown
                    id={"contact_country_code"}
                    countryCodeId="contact_country_code"
                    label={"Country"}
                    countryCodeError={form_errors["contact_country_code"]}
                    countryCodeValue={form_values["contact_country_code"]}
                    required={true}
                    handleChange={this.countryCodeHandleChange}
                    value={form_values["contact_country_code"]}
                  />
                </div>
                <div className={styles.contact_phone}>
                  <TextInput
                    id="contact_phone"
                    name="contact_phone"
                    type="tel"
                    error={form_errors["contact_phone"]}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                    label="Phone number"
                    required={true}
                    value={form_values["contact_phone"]}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.full_col}>
                  <TextInput
                    id="previous_business_name"
                    name="previous_business_name"
                    error={form_errors["previous_business_name"]}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                    label="Previous business name"
                    required={false}
                    value={form_values["previous_business_name"]}
                    helper_text="If this referee is from a previous business please state"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.referees_col}>
            <LabeledInformation
              label={"Excluded Referees"}
              label_css={"label"}
              data={excluded_application_sources.join(", ")}
            />
          </div>
        </div>
      </section>
    );
  },
});

module.exports = connect((state, ownProps) => {
  var values = {};
  var index = ownProps.component_index;
  if (index < state.cob_traderef.referees.length) {
    values = state.cob_traderef.referees[index];
  }
  var container_index = 0;

  if (state.cob_traderef.refrees_details_container_index !== null) {
    container_index = state.cob_traderef.refrees_details_container_index;
  }
  return {
    complete: state.cob_traderef.completed[`referees-${index}`],
    form_values: values,
    excluded_application_sources:
      state.cob_section.supplier.attributes.excluded_application_sources || [],
    minimum_trade_references:
      state.cob_section.application.attributes.minimum_trade_references,
    supplier_name: state.cob_section.application.attributes.supplier_name,
  };
})(Referees);
