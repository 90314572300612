/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { formatMoney } from "utils/formatting";

/** import comonents **/
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import InfoBox from "modules/shared/components/widgets/static/InfoBox";

import styles from "./css/TotalOverview.css";

var TotalOverview = createClass({
  render: function() {
    const { data } = this.props;

    var values = {};
    if (data) {
      values = data;
    }

    var content = [];
    Object.keys(forms).forEach(v => {
      var value = 0;
      if (values.hasOwnProperty(v)) {
        value =
          v == "total_limit" ? formatMoney(parseFloat(values[v])) : values[v];
      }
      content.push(
        <div className={styles.panel} key={`totalinfobox-${v}`}>
          <InfoBox
            title={forms[v].title}
            value={value}
            prefix={forms[v].prefix}
            color={forms[v].color}
            key={v}
          />
        </div>,
      );
    });

    return <div className={styles.content}>{content}</div>;
  },
});

var forms = {
  total_started: {
    key: "total_started",
    title: "Total Started",
    color: "grey",
  },
  approved: {
    key: "approved",
    title: "Approved",
    color: "blue",
  },
  declined: {
    key: "declined",
    title: "Declined",
    color: "red",
  },
  total_limit: {
    key: "total_limit",
    title: "Approved Limits",
    color: "white",
    prefix: "$",
  },
};

module.exports = TotalOverview;
