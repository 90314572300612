import get from "lodash.get";
import isPresent from "utils/isPresent";
import objectToArray from "utils/objectToArray";

function formatAddress(address) {
  const { line1, line2, line3, locality, postcode, state } = address;
  const fullAddress = [line1, line2, line3, locality, postcode, state]
    .filter(part => isPresent(part))
    .join(" ");

  return {
    fullAddress,
    rawAddress: address,
  };
}

export default class AURetrieveSecurityGroupDetails {
  static onRetrieveSecurityGroupDetails(data) {
    const securedPartyGroup = get(
      data,
      "body.retrieve_secured_party_group_response_message.retrieve_secured_party_group_response.secured_party_group",
      {},
    );
    const securedParties = objectToArray(
      securedPartyGroup.secured_parties || {},
    );
    const securedParty = securedParties[0] || {};
    const securedPartyWithVerification =
      securedParty.secured_party_with_verification;
    const contactName = securedPartyWithVerification.contact_name;
    const nameParts = contactName.split(" ");
    const lastName = nameParts.pop();

    return {
      address: formatAddress(securedPartyWithVerification.mailing_address),
      organisationEmail: securedPartyWithVerification.email_address,
      organisationName: get(
        securedPartyWithVerification,
        "organisation.organisation_name",
      ),
      organisationPhone: securedPartyWithVerification.contact_phone,
      personFirstName: nameParts.join(" "),
      personLastName: lastName,
    };
  }
}
