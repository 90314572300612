import api from "api";
import get from "lodash.get";
import BaseModel from "models/BaseModel";

export default class AddonVersionModel extends BaseModel {
  static async createAddonVersion({
    attributes,
    currentUser,
    onErrorCallback,
    onSuccessCallback,
  }) {
    // DO something
    const addonVersionAPI = api(
      "addon_versions",
      currentUser.accessToken,
      get(currentUser, "currentEntity.id"),
    );

    try {
      const result = await addonVersionAPI.createAddonVersion(attributes);
      onSuccessCallback(result);
    } catch (error) {
      onErrorCallback(error);
      console.error(error);
    }
  }

  get config() {
    return this.attributes.config || {};
  }

  save({ addonConfig, currentUser, onErrorCallback, onSuccessCallback }) {
    const successCallback = result => {
      const newAddonVersion = new AddonVersionModel(
        get(result, "data.data"),
        get(result, "data.included"),
      );

      addonConfig.addVersion(newAddonVersion);
      onSuccessCallback(newAddonVersion);
    };

    // Always create a new version
    AddonVersionModel.createAddonVersion({
      attributes: this.attributes,
      currentUser,
      onErrorCallback,
      onSuccessCallback: successCallback,
    });
  }
}
