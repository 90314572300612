import "react-table/react-table.css";

import Button from "modules/shared/components/inputs/Button";
import React from "react";
import ReactTable from "react-table";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import TableCell from "modules/shared/components/widgets/static/TableCell";
import logo from "../../../images/logo-equifax.png";
import scoreStyles from "./credit-checks/australia/equifax/css/Score.css";
/* Import CSS */
import styles from "./css/CommonReviewBusiness.css";

const DEFAULT_PAGE_SIZE = 10;

function MatchTypeCreate(props) {
  const { person } = props;

  return (
    <div>
      <ReviewContainer
        subHeading="Equifax Score Plus"
        content_class="content_wide"
        css_class="block_noborder"
        fontSize="large"
      >
        <p>
          Your credit check for {person.name} has been deferred due to issues
          with the integrity of the credit report. Please contact{" "}
          <a href="mailto:support@1centre.com">support@1centre.com</a>.
        </p>
      </ReviewContainer>
    </div>
  );
}

const getHasError = ({ attributes, data }) => {
  if (attributes.failed) {
    return true;
  }

  return !data && ["completed", "errored"].includes(attributes.status);
};

var VedaConsumerCheck = createClass({
  getScoreCardName: function() {
    const { data } = this.props;
    switch (data.score.card_name) {
      case "UND30":
        return "For Consumer 30 Years Old and Under";
      case "OVR30":
        return "For Consumer Over 30 Years Old";
      case "TELCO":
        return "Telecommunications Industry Clients Scorecard";
      case "VALAD":
        return "Value Add";
    }
    return data.score.card_name;
  },

  renderScore: function() {
    const { data } = this.props;
    if (!data.score) return null;

    const header = `Scorecard - ${this.getScoreCardName()}`,
      maxValue = 1000;
    let vedaScore = parseFloat(data.score.risk_odds),
      valuePosition = 0;

    if (vedaScore < 0) vedaScore = 0;
    valuePosition = ((vedaScore / maxValue) * 100).toString() + "%";

    return (
      <ReviewContainer
        subHeading={header}
        content_class="content_wide"
        css_class="block_noborder"
        fontSize="medium"
      >
        <div className={styles.overview}>
          <div className={styles.overview_rating}>
            <div className={styles.liner}>
              <div className={styles.overview_rating_graph}>
                <div
                  className={styles.overview_rating_indicator}
                  style={{ left: valuePosition }}
                >
                  {vedaScore}
                </div>
                <div className={scoreStyles.overview_rating_graph_bg_reverse} />
                <span className={styles.overview_rating_graph_0}>0</span>
                <span className={styles.overview_rating_graph_30}>250</span>
                <span className={styles.overview_rating_graph_60}>500</span>
                <span className={styles.overview_rating_graph_90}>750</span>
                <span className={styles.overview_rating_graph_120}>1000</span>
              </div>
            </div>
          </div>
          <div className={styles.overview_score_details}>
            <div className={styles.overview_score_details_content}>
              <div>
                <strong>Equifax Score is:</strong>
              </div>
              <div>{vedaScore}</div>
            </div>
            <div className={styles.overview_score_details_content}>
              <div>
                <strong>Relative Risk is:</strong>
              </div>
              <div>{parseFloat(data.score.relative_risk)}</div>
            </div>
          </div>
        </div>
      </ReviewContainer>
    );
  },

  renderCompanyOfficerDetails: function() {
    const { data } = this.props;

    if (!data.company_officer_details) {
      return null;
    }

    const companyOfficerColumns = [
      {
        header: "Date",
        accessor: "date",
      },
      {
        header: "Company",
        accessor: "company",
      },
      {
        header: "Company No.",
        accessor: "company_no",
      },
      {
        header: "Status",
        accessor: "status",
      },
    ];

    let paginate =
      data.company_officer_details.length > DEFAULT_PAGE_SIZE ? true : false;

    return (
      <ReviewContainer
        subHeading="Company Officer Details"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <ReactTable
            data={data.company_officer_details}
            columns={companyOfficerColumns}
            minRows={data.company_officer_details.length}
            showPagination={paginate}
            showPageSizeOptions={paginate}
            defaultPageSize={DEFAULT_PAGE_SIZE}
          />
        </div>
      </ReviewContainer>
    );
  },

  renderJudgements: function() {
    const { data } = this.props;
    if (!data.judgements) {
      return null;
    }

    const courtJudgmentsColumns = [
      {
        header: "Date",
        accessor: "date",
      },
      {
        header: "Creditor",
        accessor: "creditor",
      },
      {
        header: "Amount",
        accessor: "amount",
        render: rowInfo => <span>{parseInt(rowInfo.row.amount)}</span>,
      },
      {
        header: "Type",
        accessor: "type",
      },
      {
        header: "Status",
        accessor: "status",
      },
      {
        header: "Paid?",
        accessor: "paid_date",
        render: rowInfo => (
          <span>
            {rowInfo.row.paid_date != null ? "Yes" : "No data returned"}
          </span>
        ),
      },
    ];

    let paginate = data.judgements.length > DEFAULT_PAGE_SIZE ? true : false;
    return (
      <ReviewContainer
        subHeading="Court Judgments"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
        fontSize="medium"
      >
        <ReactTable
          data={data.judgements}
          columns={courtJudgmentsColumns}
          minRows={data.judgements.length}
          showPagination={paginate}
          showPageSizeOptions={paginate}
          defaultPageSize={DEFAULT_PAGE_SIZE}
        />
      </ReviewContainer>
    );
  },

  getDefaultsColumns: function() {
    return [
      {
        header: "Default Date",
        accessor: "default_date",
      },
      {
        header: "Creditor",
        accessor: "creditor",
      },
      {
        header: "Amount",
        accessor: "amount",
        render: rowInfo => <span>{parseInt(rowInfo.row.amount)}</span>,
      },
      {
        header: "Balance",
        accessor: "balance",
        render: rowInfo => <span>{parseInt(rowInfo.row.balance)}</span>,
      },
      {
        header: "Status",
        accessor: "status",
      },
      {
        header: "Paid?",
        accessor: "balance",
        render: rowInfo => (
          <span>{parseInt(rowInfo.row.balance) === 0 ? "Yes" : ""}</span>
        ),
      },
    ];
  },

  renderDefaultsCollectionAgency: function() {
    const { data } = this.props;
    if (!data.defaults_collection_agency) {
      return null;
    }

    let paginate =
      data.defaults_collection_agency.length > DEFAULT_PAGE_SIZE ? true : false;

    return (
      <ReviewContainer
        subHeading="Defaults - Collection Agency"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
        fontSize="medium"
      >
        <ReactTable
          data={data.defaults_collection_agency}
          columns={this.getDefaultsColumns()}
          minRows={data.defaults_collection_agency.length}
          showPagination={paginate}
          showPageSizeOptions={paginate}
          defaultPageSize={DEFAULT_PAGE_SIZE}
        />
      </ReviewContainer>
    );
  },

  renderDefaults: function() {
    const { data } = this.props;
    if (!data.defaults) {
      return null;
    }

    let paginate = data.defaults.length > DEFAULT_PAGE_SIZE ? true : false;

    return (
      <ReviewContainer
        subHeading="Defaults"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
        fontSize="medium"
      >
        <ReactTable
          data={data.defaults}
          columns={this.getDefaultsColumns()}
          minRows={data.defaults.length}
          showPagination={paginate}
          showPageSizeOptions={paginate}
          defaultPageSize={DEFAULT_PAGE_SIZE}
        />
      </ReviewContainer>
    );
  },

  renderEnquiries: function() {
    const { data } = this.props;
    if (!data.enquiries) {
      return null;
    }

    const enquiriesColumns = [
      {
        header: "Date",
        accessor: "date",
      },
      {
        header: "Inquirer",
        accessor: "inquirer",
      },
      {
        header: "Account Type",
        accessor: "account_type",
      },
      {
        header: "Inquiry Amount",
        accessor: "inquiry_amount",
        render: rowInfo => <span>{parseInt(rowInfo.row.inquiry_amount)}</span>,
      },
    ];

    let paginate = false;
    if (data.enquiries.length > DEFAULT_PAGE_SIZE) {
      paginate = true;
    }

    return (
      <ReviewContainer
        subHeading="Previous Inquiries"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.state.isAllExpanded}
        fontSize="medium"
      >
        <ReactTable
          data={data.enquiries}
          columns={enquiriesColumns}
          minRows={data.enquiries.length}
          showPagination={paginate}
          showPageSizeOptions={paginate}
          defaultPageSize={DEFAULT_PAGE_SIZE}
        />
      </ReviewContainer>
    );
  },

  renderConsumerDetails: function() {
    const { data } = this.props;
    if (data.consumer_details === null) {
      return (
        <div>
          <p>No credit information for this entity.</p>
        </div>
      );
    }

    let previousAddresses = [];
    if (data.consumer_details.previous_addresses) {
      data.consumer_details.previous_addresses.forEach((v, i) => {
        previousAddresses.push(
          <TableCell
            key={i}
            label={`Previous Address ${i + 1}`}
            data={v}
            showEmpty={false}
            table_layout="col_1"
            th_position="left"
          />,
        );
      });
    }

    return (
      <ReviewContainer
        subHeading="Subject details"
        content_class="content_wide"
        css_class="block_noborder"
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <div className={styles.table}>
            <TableCell
              label="Surname"
              data={data.consumer_details.last_name}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="First Names"
              data={data.consumer_details.first_name}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Date of Birth"
              data={data.consumer_details.date_of_birth}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Gender"
              data={data.consumer_details.gender}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Current Address"
              data={data.consumer_details.current_address}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            {previousAddresses}
          </div>
        </div>
      </ReviewContainer>
    );
  },

  getSummaryValue: function(key) {
    const { data } = this.props;
    let value = data.summary.find(item => {
      if (item.name === key) {
        return item.value;
      }
    });

    if (value) {
      return value.value;
    }

    return "No data returned";
  },

  getNegativesValue: function(key) {
    const { data } = this.props;
    let value = data.negatives.find(item => {
      if (item.name === key) {
        return item.value;
      }
    });

    if (value) {
      return value.value;
    }

    return "No data returned";
  },

  renderSummary: function() {
    const { data } = this.props;
    if (!data.summary) {
      return null;
    }

    var age_of_file_text = this.getNegativesValue("age-of-oldest-file");
    if (!isNaN(age_of_file_text)) {
      age_of_file_text > 1
        ? (age_of_file_text += " months")
        : (age_of_file_text += " month");
    }

    return (
      <ReviewContainer
        subHeading="Summary"
        content_class="content_wide"
        css_class="block_noborder"
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <div className={styles.table}>
            <TableCell
              label="Cross References"
              data={this.getSummaryValue("cross-reference")}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="ID Verification"
              data={this.getSummaryValue("crossreference")}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Lost/Stolen ID"
              data={this.getSummaryValue("lost-or-stolen-id")}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Insolvencies"
              data={this.getSummaryValue("insolvency-count")}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Judgments"
              data={this.getSummaryValue("judgement-count")}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Defaults"
              data={this.getSummaryValue("defaults")}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Defaults - Collection Agency"
              data={this.getSummaryValue("defaults-collection-agency")}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Public Notices"
              data={this.getSummaryValue("public-notice")}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Inquiries"
              data={this.getSummaryValue("total-enquiries")}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Directorships"
              data={this.getSummaryValue("identified-company-officer")}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Age of Credit File"
              data={age_of_file_text}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
          </div>
        </div>
      </ReviewContainer>
    );
  },

  renderSearchCriteria: function() {
    const { data } = this.props;

    if (!data.details) {
      return null;
    }

    return (
      <ReviewContainer
        subHeading="Search Criteria"
        content_class="content_wide"
        css_class="block_noborder"
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <div className={styles.table}>
            <TableCell
              label="Subject Name"
              data={`${data.details.first_name}, ${data.details.last_name}`}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="DOB"
              data={data.details.dob}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Gender"
              data={data.details.gender}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
            <TableCell
              label="Address 1"
              data={data.details.address}
              showEmpty={true}
              table_layout="col_1"
              th_position="left"
            />
          </div>
        </div>
      </ReviewContainer>
    );
  },

  getInitialState() {
    return {
      isAllExpanded: false,
    };
  },

  _expandAll() {
    this.setState({ isAllExpanded: !this.state.isAllExpanded });
  },

  _expandAllButton() {
    let message = "+ expand all";
    if (this.state.isAllExpanded) message = "- collapse all";

    return (
      <Button
        small
        text={message}
        style={{ fontSize: "10px", margin: "1em 0 1em 66px" }}
        handleClick={this._expandAll}
      />
    );
  },

  render: function() {
    const { check_attributes, data, person } = this.props;

    let errorMessage =
      "Oops! Something went wrong, please contact 1Centre for more details.";
    const hasError = getHasError({ attributes: check_attributes, data });
    if (hasError) {
      if (check_attributes.status == "errored" && check_attributes.raw_data) {
        errorMessage = check_attributes.raw_data;
      }

      return (
        <div>
          <ReviewContainer
            subHeading="Equifax Score Plus"
            content_class="content_wide"
            css_class="block_noborder"
            fontSize="large"
          >
            <p>{errorMessage}</p>
            <p>
              Contact{" "}
              <a href="mailto:support@1centre.com">support@1centre.com</a> or
              live chat with us.
            </p>
          </ReviewContainer>
        </div>
      );
    } else if (!data) {
      return null;
    }

    if (check_attributes.file_match_type_create) {
      return <MatchTypeCreate person={person} />;
    }

    return (
      <div>
        <img
          className={styles.veda_logo}
          src={logo}
          alt="Equifax"
          width="277.9"
          height="79.1"
        />
        <h2 className={styles.heading_large}>Equifax Score Plus</h2>
        {this.renderScore()}
        {this.renderSearchCriteria()}
        {this.renderSummary()}
        {this.renderConsumerDetails()}
        {this._expandAllButton()}
        {this.renderJudgements()}
        {this.renderDefaults()}
        {this.renderDefaultsCollectionAgency()}
        {this.renderEnquiries()}
        {this.renderCompanyOfficerDetails()}
        {this._expandAllButton()}
      </div>
    );
  },
});

export default VedaConsumerCheck;
