/* Import libraries */
import React, { Component } from "react";
import ArrowButton from "modules/shared/components/widgets/interactive/ArrowButton";
import styles from "./css/BackAndNextButtons.css";

class BackAndNextButtons extends Component {
  render() {
    const {
      additionalButtons,
      onBackClick,
      onNextClick,
      disableBack,
      disableNext,
      hideBack,
      hideNext,
      updating,
    } = this.props;
    return (
      <div className={styles.bottom_buttons}>
        {
          additionalButtons && (
            <div className={styles.bottom_additional_buttons}>
              {additionalButtons}
            </div>
          )
        }
        <div className={styles.bottom_main_buttons}>
          {!hideNext && (
            <div>
              <ArrowButton
                loading={updating}
                disabled={disableNext}
                css_style="button_small_font"
                key="Next"
                text="Next"
                handleClick={onNextClick}
              />
            </div>
          )}
          {!hideBack && (
            <div>
              <ArrowButton
                disabled={disableBack}
                css_style="button_small_font"
                key="Back"
                text="Back"
                handleClick={onBackClick}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default BackAndNextButtons;
