import React from "react";
import { connect } from "react-redux";

import {
  loadStoreNames,
  loadStoreName,
  createStoreNames,
  updateStoreName,
  deleteStoreName,
  updateStoreNameAlias,
} from "../actions";
import { setCurrentEntityStoreNameAlias } from "modules/user/actions";
import { storeNameAlias } from "modules/shared/helpers/entityAttributes";

import Input from "@material-ui/core/Input";
import TradingNameModal from "modules/websitebutton/components/TradingNameModal";
import AdminTable from "modules/shared/components/widgets/interactive/AdminTable";
import Pagination from "modules/shared/components/widgets/interactive/Pagination";
import Button from "modules/shared/components/inputs/Button";
import TextInput from "modules/shared/components/inputs/TextInput";
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";

import styles from "./css/styles.css";

class TradingNameList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalType: "",
      store_name_alias: storeNameAlias(),
      name_list: {
        currentPage: 1,
        currentColumn: "trading_name",
        namesPerPage: defaults.options.viewPage[0].value,
        ascending: { trading_name: false, hide: false },
      },
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    defaults.options.columns[0].label = storeNameAlias();
    dispatch(loadStoreNames());
  }

  add() {
    this.setState({ showModal: true, modalType: "add" });
  }

  createStoreNames(names) {
    const { dispatch } = this.props;
    dispatch(createStoreNames(names, this.reloadStoreNameList.bind(this)));
    this.modalHandleDismiss();
  }

  edit(id) {
    const { dispatch } = this.props;
    dispatch(
      loadStoreName(id, () => {
        this.setState({ showModal: true, modalType: "edit" });
      }),
    );
  }

  updateStoreName(id, attributes) {
    const { dispatch } = this.props;
    dispatch(
      updateStoreName(id, attributes, this.reloadStoreNameList.bind(this)),
    );
    this.modalHandleDismiss();
  }

  remove(id) {
    const { dispatch } = this.props;
    dispatch(
      loadStoreName(id, () => {
        this.setState({ showModal: true, modalType: "remove" });
      }),
    );
  }

  removeStoreName(id) {
    const { dispatch } = this.props;
    dispatch(deleteStoreName(id, this.reloadStoreNameList.bind(this)));
    this.modalHandleDismiss();
  }

  hideFromConsumer(id) {
    const { dispatch, storeNames } = this.props;
    var name = storeNames.find(name => {
      return name.id == id;
    });
    dispatch(
      updateStoreName(
        id,
        { hide: !name.attributes.hide },
        this.reloadStoreNameList.bind(this),
      ),
    );
  }

  paramsFormater(params) {
    return {
      page: params.currentPage,
      per_page: params.usersPerPage,
      order_by: params.currentColumn,
      asc: params.ascending[params.currentColumn],
    };
  }

  reloadStoreNameList() {
    const { dispatch } = this.props;
    const { name_list } = this.state;
    dispatch(loadStoreNames(this.paramsFormater(name_list)));
  }

  handleStoreNameAliasChange(event) {
    this.setState({ store_name_alias: event.target.value });
    defaults.options.columns[0].label = event.target.value;
  }

  handleStoreNameAliasBlur() {
    const { dispatch } = this.props;
    var name = this.state.store_name_alias;
    if (!!name) {
      dispatch(updateStoreNameAlias(name));
    }
    dispatch(setCurrentEntityStoreNameAlias(name));
  }

  changeOrder(value, sortOrder) {
    const { name_list } = this.state;
    name_list.currentColumn = defaults.options.columns[value].field;
    name_list.ascending[name_list.currentColumn] = sortOrder == "asc";
    this.setState({ name_list: name_list });
    this.reloadStoreNameList();
  }

  changeCurrentPage(value) {
    const { name_list } = this.state;
    name_list.currentPage = value;
    this.setState({ name_list: name_list });
    this.reloadStoreNameList();
  }

  changeUsersPerPage(value) {
    const { name_list } = this.state;
    name_list.namesPerPage = value;
    this.setState({ name_list: name_list });
    this.reloadStoreNameList();
  }

  modalHandleDismiss() {
    this.setState({ showModal: false });
  }

  renderStoreList() {
    const { storeNames, totalStoreNamesCount, location } = this.props;
    const { name_list } = this.state;
    var StoreListMarkup = [];
    StoreListMarkup.push(
      <AdminTable
        data={storeNames}
        config={defaults.options.columns}
        actions={{
          edit: this.edit.bind(this),
          hide: this.hideFromConsumer.bind(this),
          remove: this.remove.bind(this),
        }}
        handleSortCallback={this.changeOrder.bind(this)}
        remoteSort={true}
        location={location}
      />,
    );
    StoreListMarkup.push(
      <Pagination
        dataLength={totalStoreNamesCount}
        currentPage={name_list.currentPage}
        todosPerPage={name_list.namesPerPage}
        optionViewPage={defaults.options.viewPage}
        handleClick={this.changeCurrentPage.bind(this)}
        handleClickTodosPerPage={this.changeUsersPerPage.bind(this)}
        resourceName={"Names"}
      />,
    );
    return StoreListMarkup;
  }

  rederModal() {
    const { modalType } = this.state;
    return (
      <TradingNameModal
        actionType={this.state.modalType}
        handleCreate={this.createStoreNames.bind(this)}
        handleUpdate={this.updateStoreName.bind(this)}
        handleDelete={this.removeStoreName.bind(this)}
        handleDismiss={this.modalHandleDismiss.bind(this)}
      />
    );
  }

  render() {
    const { storeNameAlias, isLoading } = this.props;
    return (
      <div>
        <div className={styles.header_container}>
          <div>
            <Input
              style={{ fontWeight: "200", fontSize: "14px", color: "grey" }}
              value={this.state.store_name_alias}
              onChange={this.handleStoreNameAliasChange.bind(this)}
              onBlur={this.handleStoreNameAliasBlur.bind(this)}
              placeholder={"input name here"}
              required
            />
          </div>
          <Button
            style={{ fontWeight: "700" }}
            disabled={false}
            text="+ Add"
            handleClick={this.add.bind(this)}
          />
        </div>
        <section className={styles.section}>
          <div className={styles.row}>
            <div className={styles.content}>{this.renderStoreList()}</div>
          </div>
        </section>
        {isLoading && <SimpleLoader css_class={"loader_relative"} />}
        {this.state.showModal && this.rederModal()}
      </div>
    );
  }
}

var defaults = {
  options: {
    columns: [
      {
        type: "data",
        label: "",
        field: "trading_name",
        key: "attributes.trading_name",
        widthDefault: 480,
      },
      {
        type: "data",
        label: "Status",
        field: "hide",
        key: "attributes.status_str",
        widthDefault: 480,
      },
      {
        type: "action",
        label: "button_status_str",
        actionKey: "hide",
        key: "id",
      },
      {
        type: "action",
        actionKey: "edit",
        label: "edit",
        key: "id",
      },
      {
        type: "action",
        label: "remove",
        actionKey: "remove",
        key: "id",
      },
    ],
    viewPage: [
      {
        label: "10",
        value: 10,
      },
      {
        label: "20",
        value: 20,
      },
    ],
  },
};

module.exports = connect((state, ownProps) => {
  const hide_status_str = `This ${storeNameAlias().toLowerCase()} will not show on website button`;
  var store_names = state.website_button.store_names.map(v => {
    if (v.attributes.hide === true) {
      v.attributes.status_str = hide_status_str;
      v.attributes.button_status_str = "activate";
    } else {
      v.attributes.button_status_str = "deactivate";
    }
    return v;
  });

  return {
    storeNames: store_names,
    totalStoreNamesCount: state.website_button.store_names_count,
    isLoading: state.website_button.loading,
  };
})(TradingNameList);
