import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import ReviewFromRender from "modules/shared/components/widgets/interactive/form_builder/ReviewFromRender";
import styles from "./css/Review.css";

class ReviewAdditional extends React.Component {
  render() {
    const { data } = this.props;
    const addonAnswer = data.addon_answers.find(
      answer => answer.attributes.addon_module_name === moduleName,
    );
    const answers = addonAnswer ? addonAnswer.attributes.answers.results : [];
    const addonRule = data.addon_rules.find(
      rule => rule.attributes.addon_module_name === moduleName,
    );
    const addonVersion = Object.prototype.hasOwnProperty.call(
      addonRule.attributes.addon_version,
      "data",
    )
      ? addonRule.attributes.addon_version.data.attributes
      : addonRule.attributes.addon_version;

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        <ReviewContainer css_class={"block_noborder"}>
          <div className={styles.width_100}>
            <ReviewFromRender
              components={addonVersion.config.components}
              answers={answers}
            />
          </div>
        </ReviewContainer>
      </section>
    );
  }
}

const moduleName = "additional";

module.exports = ReviewAdditional;
