import React, { ReactElement } from 'react'
import { SubHeading, Wrapper } from './styles';

interface Props {
  subHeading?: string,
  children: any,
}

function Container({
  subHeading,
  children,
}: Props): ReactElement {
  return (
    <Wrapper>
      {subHeading && <SubHeading>{subHeading}</SubHeading>}
      {children}
    </Wrapper>
  )
}

export default Container
