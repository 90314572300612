import Collapse from "@material-ui/core/Collapse";
import EditButton from "modules/shared/components/inputs/EditButton";
/* Import libraries */
import React from "react";
/* Import styles */
import styles from "./css/ReviewContainer.css";

var ReviewContainer = createClass({
  getInitialState() {
    return {
      isOpened: false,
      isAllExpanded: false,
    };
  },

  componentWillReceiveProps(nextProps) {
    let willExpandAll = nextProps.isAllExpanded || false;

    if (this.state.isAllExpanded !== willExpandAll) {
      this.setState({ isOpened: willExpandAll, isAllExpanded: willExpandAll });
    }
  },

  onClick: function() {
    if (this.state.isOpened === false) {
      this.setState({ isOpened: true });
    } else {
      this.setState({ isOpened: false });
    }
  },

  getClass: function() {
    const { icon_inline } = this.props;
    var style_name = "collapsible_sub_heading";
    if (icon_inline) {
      style_name += "_inline";
    }
    if (this.state.isOpened === true) {
      style_name += "_active";
    }
    return `${styles[style_name]} ${this.getSubheadingFontClass()}`;
  },

  getSubheadingFontClass() {
    const { fontSize } = this.props;

    if (fontSize === "medium") {
      return styles.sub_heading_medium_font;
    }

    if (fontSize === "large") {
      return styles.sub_heading_large_font;
    }

    return "";
  },

  render: function() {
    const {
      collapsible,
      editPath,
      subHeading,
      css_class,
      content_class,
      hideCheck,
      no_margin_left,
    } = this.props;
    const { isOpened } = this.state;
    let block_styles = styles.block,
      content_styles = styles.content,
      sub_heading_styles = styles.sub_heading,
      edit_control,
      sub_heading,
      empty = true,
      output_content;

    if (hideCheck === true) {
      return null;
    }

    if (css_class) {
      block_styles = styles[css_class];
    }

    if (no_margin_left) {
      block_styles = `${block_styles} ${styles.no_margin_left}`;
    }

    if (editPath) {
      edit_control = (
        <div className={styles.button}>
          <EditButton to={editPath} />
        </div>
      );
    }

    if (content_class) {
      content_styles = styles[content_class];
    }

    if (subHeading) {
      if (collapsible) {
        sub_heading_styles = this.getClass();
        content_styles = `${styles.content_collapsible} ${content_class}`;
        sub_heading = (
          <div>
            <h4
              className={sub_heading_styles}
              dangerouslySetInnerHTML={{ __html: subHeading }}
              onClick={this.onClick}
            ></h4>
          </div>
        );
      } else {
        sub_heading = (
          <div>
            <h4
              className={`${sub_heading_styles} ${this.getSubheadingFontClass()}`}
              dangerouslySetInnerHTML={{ __html: subHeading }}
            ></h4>
          </div>
        );
      }
    }

    if (collapsible) {
      output_content = (
        <Collapse in={isOpened}>
          <div className={content_styles}>{this.props.children}</div>
        </Collapse>
      );
    } else {
      output_content = (
        <div className={content_styles}>{this.props.children}</div>
      );
    }

    return (
      <div className={block_styles}>
        {edit_control}
        {sub_heading}
        {output_content}
      </div>
    );
  },
});

export default ReviewContainer;
