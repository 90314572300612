import React, { ReactElement } from 'react'
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
// TODO - Replace with styled component
import styles from '../css/material-select.css';

interface Props {
  error: string,
  handleChange: () => void,
  label: string,
  value: string,
}

function ApplicaitonTypeDropdown({
  error,
  handleChange,
  label,
  value,
}: Props): ReactElement {
  return (
    <div className={styles.material_container} style={{ display: "flex" }}>
      <div className={styles.xs_12}>
        <FormControl className={styles.material_form_control}>
          <InputLabel
            htmlFor="region-select"
            classes={{
              root: styles.material_label,
              shrink: styles.material_label_shrink,
            }}
          >
            {label}
          </InputLabel>

          <Select
            value={value}
            className={styles.material_select}
            startAdornment={<span />}
            onChange={handleChange}
          >
            <MenuItem value="cash">
              <div className={styles.material_menuitem}>
                Cash
              </div>
            </MenuItem>
            <MenuItem value="credit">
              <div className={styles.material_menuitem}>
                Credit
              </div>
            </MenuItem>
          </Select>
          { error && <p>Error</p> }
        </FormControl>
      </div>
    </div>
  )
}

export default ApplicaitonTypeDropdown
