/* Import libraries */
/* Import components */
import get from "lodash.get";
import Icon from "modules/shared/components/svg/Icon";
import Clickable from "modules/shared/components/widgets/interactive/Clickable";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { intentLink } from "utils/intentLink";
import { intentText } from "utils/intentText";

/* Import actions */
import { markNotificationActioned } from "../actions";
/* Import CSS */
import styles from "./css/Notification.css";

const Notification = createClass({
  action() {
    const { dispatch, notification, user, toggleHandler } = this.props;

    const linkPath = intentLink(notification, user);

    if (linkPath) {
      if (toggleHandler) {
        toggleHandler();
      }

      if (!notification.attributes.actioned) {
        dispatch(markNotificationActioned(notification.id));
      }

      browserHistory.push(intentLink(notification, user));
    }
  },

  componentWillUnmount() {
    const { dispatch, notification } = this.props;
    if (!notification.attributes.actioned) {
      dispatch(markNotificationActioned(notification.id));
    }
  },

  render() {
    const { layout, notification, user } = this.props;
    let actioned;
    let css_class = styles.notification;

    if (layout == "full") {
      css_class = styles.notification_full;
    }

    if (!notification.attributes.actioned) {
      // Use this to distinguish clicked from unclicked
      if (layout == "full") {
        css_class = styles.notification_full_unactioned;
      } else {
        css_class = styles.notification_unactioned;
      }
    }

    return (
      <Clickable target={this.action}>
        <li className={css_class}>
          <div className={styles.liner}>
            <div className={styles.text}>
              <p
                dangerouslySetInnerHTML={{
                  __html: get(notification, "attributes.text", ""),
                }}
              />
              <span className={styles.link}>{intentText(notification)}</span>
            </div>
          </div>
        </li>
      </Clickable>
    );
  },
});

export default connect((state, ownProps) => {
  return {
    user: state.current_user.data.data,
  };
})(Notification);
