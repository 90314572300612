import { LoggedIn } from "modules/shared/auth";
import V2DocumentationContainer from "modules/webhooks/v2/containers";
import V2Documentation from "modules/webhooks/v2/components/V2Documentation";

export default {
  component: LoggedIn(V2DocumentationContainer),
  indexRoute: {
    component: V2Documentation,
  },
  path: "webhook_documentation/v2",
};
