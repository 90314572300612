/* Import libraries */
import React from "react";
import { Link } from "react-router";

/* Import styles */
import styles from "./css/Badge.css";

const Badge = props => {
  const { link, theme, text, type, handleClick, ...rest } = props;
  var style = styles.badge;

  if (theme === "white") {
    style = styles.badge_white;
  }

  if (handleClick) {
    return (
      <button className={style} onClick={handleClick} {...rest}>
        {text}
      </button>
    );
  } else if (type === "submit" || type === "button") {
    return (
      <button className={style} type={type} {...rest}>
        {text}
      </button>
    );
  } else {
    return (
      <span className={style} to={link} {...rest}>
        {text}
      </span>
    );
  }
};

export default Badge;
