import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#cards-creating-credit-card
 */
let Cards = function(config) {
  this.axios = axios(config);
  this.type = "cards";
};

Cards.prototype.createCard = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

Cards.prototype.updateCard = function(id, attributes, success, error) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

Cards.prototype.getCard = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

export default Cards;
