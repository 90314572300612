import { useEffect } from 'react'
import api from 'api';
import { Props } from '../../Panel/model';
import useParamsCompare from 'modules/dashboard/hooks/useParamsCompare';
import useRequestQuery from 'modules/dashboard/hooks/useRequestQuery';

function useComparisonFilterStates({
  accessToken,
  filterState: params,
  entityId,
}: Props) {
  const apiKey = 'summary';

  const apiAction = () => api(
    'reporting',
    accessToken,
    entityId
  ).get({
    api: apiKey,
    params
  });

  const {
    data,
    error,
    forceUpdate,
    loading,
  } = useRequestQuery(apiAction);

  const paramString = JSON.stringify(params);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
    }
  }, [forceUpdate, oldParams, params, paramString, setOldParams])


  return {
    data,
    error,
    forceUpdate,
    params,
    loading,
  };
}

export default useComparisonFilterStates
