import get from "lodash.get";
import { useCallback, useEffect, useState } from "react";

type Request = () => Promise<any>;
type DataObject = string|null;

function useRequestQuery(
  request: Request,
  dataObject: DataObject = "data.data"
): any {
  const [data, setData] = useState<any>(null);
  const [update, updateState] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const forceUpdate = useCallback(() => updateState({}), []);

  const apiRequest = async() => {
    if (!loading) {
      setLoading(true);
      await request()
        .then(data => {
          const processedData = dataObject ? get(data, dataObject) : data;
          setData(processedData);
          setError(false);
        })
        .catch(() => {
          console.error("Error");
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!error && request && !data) {
      apiRequest();
    }
  }, [error, request, data]);

  useEffect(() => {
    apiRequest();
  }, [update]);

  return {
    data,
    error,
    forceUpdate,
    loading,
  };
}

export default useRequestQuery;
