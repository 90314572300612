import get from "lodash.get";

export function userPagePermissions(currentUser) {
  if (currentUser.superAdmin) {
    return {
      can_access_tokenised_document: true,
      can_review_credit_checks: true,
      can_review_virtual_credit_file: true,
    };
  }

  return get(currentUser, "currentUserEntityLink.attributes.page_permissions", {});
}

export function canUserReviewCreditChecks(currentUser) {
  return userPagePermissions(currentUser).can_review_credit_checks;
}

export function canUserReviewVirtualCreditFile(currentUser) {
  return userPagePermissions(currentUser).can_review_virtual_credit_file;
}

export function canUserAccessTokenisedDocument(currentUser, application) {
  const applicationStatus = application.status;
  const userAccess = userPagePermissions(currentUser).can_access_tokenised_document;

  return !["accepted", "declined"].includes(applicationStatus) && userAccess;
}
