import api from "api";
import IconStopCreditRed from "images/svgs/icon-stop-credit-red.svg";
import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import { STOP_CREDIT_OPTIONS } from "models/StopCreditModel";
import { AddNoteButton } from "modules/new-applications/components/application-actions/Notes";
import RecordHistory from "modules/new-applications/components/RecordHistory";
import { TOOLTIP_TEXT } from "modules/new-applications/constants";
import styles from "modules/new-applications/css/CreditControl.css";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import ContentWithFooter from "modules/shared/components/containers/ContentWithFooter";
import FixedContent from "modules/shared/components/containers/FixedContent";
import GridContent from "modules/shared/components/containers/GridContent";
import ScrollableContent from "modules/shared/components/containers/ScrollableContent";
import BorderedAutocomplete from "modules/shared/components/inputs/BorderedAutocomplete";
import BorderedCalendarPicker from "modules/shared/components/inputs/BorderedCalendarPicker";
import Button from "modules/shared/components/inputs/Button";
import FieldWithLabel from "modules/shared/components/inputs/FieldWithLabel";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import isBlank from "utils/isBlank";
import * as yup from "yup";

const reviewDateFormSchema = yup.object().shape({
  reviewDate: yup.string(),
});

const stopCreditFormSchema = yup.object().shape({
  delinquencyPeriod: yup
    .string()
    .required("Please select a delinquency period"),
});

function ReviewDateForm(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;
  const {
    clearError,
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    mode: "onBlur",
    validationSchema: reviewDateFormSchema,
  });
  const { dirtyFields } = formState;
  const [selectedDate, setSelectedDate] = useState(application.reviewDate);
  const { isLoading, setIsLoading } = useIsLoadingState();
  const isDisabled = currentUser.isStandard;

  useEffect(() => {
    setSelectedDate(application.reviewDate);
  }, [application.reviewDate]);

  const onSuccessCallback = () => {
    setIsLoading(false);
    reset();
    onFetchApplicationRecord();
  };

  const onSubmit = event => {
    event.preventDefault();

    if (isDisabled) {
      return;
    }

    if (!dirtyFields.has("reviewDate")) {
      setError("reviewDate", "required", "Please pick a new date");
      return;
    }

    handleSubmit(data => {
      setIsLoading(true);

      application.update({
        attributes: {
          review_date: moment(data.reviewDate, "DD/MM/YYYY").toDate(),
        },
        currentUser,
        onSuccessCallback,
      });
    })();
  };

  const onSelectDate = date => {
    clearError("reviewDate");

    setSelectedDate(date);
    setValue("reviewDate", date);
  };

  return (
    <FixedContent header="Review date" toolTip={TOOLTIP_TEXT.reviewDate}>
      <GridContent gridColumnTemplate="two_thirds">
        <form onSubmit={onSubmit}>
          <BorderedCalendarPicker
            textFieldProps={{
              disabled: isDisabled,
              error: Boolean(errors.reviewDate),
              helperText: get(errors, "reviewDate.message", " "),
              id: "review-date-datepicker",
              inputRef: register,
              name: "reviewDate",
            }}
            isDisabled={isDisabled}
            minDate={new Date()}
            value={selectedDate}
            onChange={onSelectDate}
          />

          <div>
            <Button
              disabled={isDisabled}
              disableOnLoading={true}
              loading={isLoading}
              text="Save"
              type="submit"
              style={{ marginRight: 60 }}
            />
            <AddNoteButton noteCategory="review_date" {...props} />
          </div>
        </form>
      </GridContent>
    </FixedContent>
  );
}

function useSelectedDelinquencyPeriodState() {
  const [selectedDelinquencyPeriod, setSelectedDelinquencyPeriod] = useState();

  const selectedOption =
    STOP_CREDIT_OPTIONS.find(
      option => option.value === selectedDelinquencyPeriod,
    ) || {};

  return {
    selectedDelinquencyPeriod,
    selectedOption,
    setSelectedDelinquencyPeriod,
  };
}

function applyStopCredit({
  applicationId,
  attributes,
  currentUser,
  onSuccessCallback,
}) {
  const stopCreditAPI = api(
    "stop_credits",
    currentUser.accessToken,
    get(currentUser, "currentEntity.id"),
  );

  stopCreditAPI.createStopCredit(
    applicationId,
    attributes,
    onSuccessCallback,
    error => console.error(error),
  );
}

function StopCreditForm(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;

  const {
    selectedOption,
    setSelectedDelinquencyPeriod,
  } = useSelectedDelinquencyPeriodState();

  const {
    clearError,
    errors,
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
    validationSchema: stopCreditFormSchema,
  });

  const { isLoading, setIsLoading } = useIsLoadingState();
  const isDisabled = currentUser.isStandard;

  const onSuccessCallback = () => {
    mixpanel.track("Apply stop credit", {
      "Application ID": application.id,
      "Entity ID": get(currentUser, "currentEntity.id"),
      distinct_id: currentUser.id,
    });

    setIsLoading(false);
    reset();
    onFetchApplicationRecord();
  };

  const onSubmit = event => {
    event.preventDefault();

    handleSubmit(data => {
      setIsLoading(true);

      applyStopCredit({
        applicationId: application.id,
        attributes: {
          delinquency_period: data.delinquencyPeriod,
        },
        currentUser,
        onSuccessCallback,
      });
    })();
  };

  const onSelectDelinquencyPeriod = (_, value) => {
    const selectedValue = (value || {}).value;

    clearError("delinquencyPeriod");

    setSelectedDelinquencyPeriod(selectedValue);
    setValue("delinquencyPeriod", selectedValue);
  };

  useEffect(() => {
    register({ name: "delinquencyPeriod" });
  }, [register]);

  return (
    <GridContent gridColumnTemplate="two_thirds">
      <form onSubmit={onSubmit}>
        <FieldWithLabel
          htmlFor="delinquency-period-autocomplete"
          label="Alerts"
        >
          <BorderedAutocomplete
            textFieldProps={{
              disabled: isDisabled,
              error: Boolean(errors.delinquencyPeriod),
              helperText: get(errors, "delinquencyPeriod.message", " "),
              label: "",
              name: "delinquencyPeriod",
            }}
            id="users-autocomplete"
            name="users-autocomplete"
            options={STOP_CREDIT_OPTIONS}
            value={selectedOption}
            onChange={onSelectDelinquencyPeriod}
          />
        </FieldWithLabel>

        <div>
          <Button
            disabled={isDisabled}
            disableOnLoading={true}
            loading={isLoading}
            text="Save"
            type="submit"
            style={{
              marginRight: 60,
            }}
          />
          <AddNoteButton noteCategory="alert" {...props} />
        </div>
      </form>
    </GridContent>
  );
}

function cancelStopCredit({ currentUser, stopCredit, onSuccessCallback }) {
  const stopCreditAPI = api(
    "stop_credits",
    currentUser.accessToken,
    get(currentUser, "currentEntity.id"),
  );

  stopCreditAPI.updateStopCredit(
    stopCredit.id,
    { active: false },
    onSuccessCallback,
    error => console.error(error),
  );
}

function CancelStopCredit(props) {
  const {
    application,
    currentUser,
    onFetchApplicationRecord,
    stopCredit,
  } = props;

  const { isLoading, setIsLoading } = useIsLoadingState();
  const isDisabled = currentUser.isStandard;

  if (isBlank(stopCredit)) {
    return null;
  }

  const onSuccessCallback = () => {
    mixpanel.track("Remove stop credit", {
      "Application ID": application.id,
      "Entity ID": get(currentUser, "currentEntity.id"),
      distinct_id: currentUser.id,
    });

    setIsLoading(false);
    onFetchApplicationRecord();
  };

  const onCancelStopCredit = () => {
    if (isDisabled || isLoading) {
      return;
    }

    setIsLoading(true);
    cancelStopCredit({ currentUser, onSuccessCallback, stopCredit });
  };

  return (
    <div className={styles.cancel_stop_credit_button_container}>
      <div
        className={styles.cancel_stop_credit_button}
        onClick={onCancelStopCredit}
      >
        <IconStopCreditRed width={30} height={30} />
        Cancel
      </div>
      <AddNoteButton noteCategory="alert" {...props} />
    </div>
  );
}

function StopCredit(props) {
  const { application } = props;
  const { activeStopCredit } = application;
  const isStopCreditActive = activeStopCredit.active || false;

  let content = <StopCreditForm {...props} />;
  if (isStopCreditActive) {
    content = <CancelStopCredit stopCredit={activeStopCredit} {...props} />;
  }

  return <FixedContent header="Alert notifications">{content}</FixedContent>;
}

export default function CreditControl(props) {
  const { application } = props;
  const { creditControlHistories } = application;

  const content = (
    <Fragment>
      <ReviewDateForm {...props} />
      <StopCredit {...props} />
    </Fragment>
  );

  if (creditControlHistories.length > 0) {
    return (
      <ContentWithFooter
        footer={<RecordHistory histories={creditControlHistories} />}
      >
        {content}
      </ContentWithFooter>
    );
  }

  return <ScrollableContent>{content}</ScrollableContent>;
}
