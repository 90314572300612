import React, { Component } from "react";
import _ from "lodash";

import { formatMoney } from "utils/formatting";

import styles from "./css/Paginator.css";

class Paginator extends Component {
  render() {
    return (
      <div className={styles.paginator}>
        <div>{"<<"}</div>
        <div>{"<"}</div>
        <div>{"1"}</div>
        <div>{"2"}</div>
        <div>{"3"}</div>
        <div>{">"}</div>
        <div>{">>"}</div>
      </div>
    );
  }
}

export default Paginator;
