import React, { Component } from "react";

import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import commonStyles from "../css/consumer-risk-score/ConsumerRiskScoreComponent.css";
import { extractText } from "../ConsumerRiskScoreComponent";
import get from "lodash.get";

export default class Appendix extends Component {
  get requestSummary() {
    return get(this.props.response, "request_summary", {});
  }

  renderDisclosureNotice() {
    return (
      <div>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Disclosure notice</p>
        </div>
        <p>
          The information in this file may not be sold, assigned, reproduced or
          copied in any form whatsoever without the prior written consent of
          DBCC Pty Ltd and/or illion New Zealand Limited (together,
          &#34;we&#34;, &#34;our&#34;, &#34;us&#34;). The information is
          protected by copyright, all rights reserved.
        </p>

        <p>By accepting this File you acknowledge:</p>
        <ul>
          <li>
            the information in this file may in whole or in part represent or be
            based on information provided to us from third parties, public
            registers or publicly available information sources;
          </li>
          <li>
            information in this file may not be all the information we hold on
            the relevant individual;
          </li>
          <li>
            we do not and cannot guarantee or warrant the correctness,
            completeness, merchantability or fitness for a particular purpose of
            any information in this file. Such information is to be considered
            current within our established procedures for updating information
            and usually is not the product of independent investigation prompted
            by any enquiry (whether by you or another person); and
          </li>
          <li>
            that every business decision, to some degree or another, represents
            an assumption of risk and that we, in providing information do not
            and cannot underwrite your risk in any manner whatsoever.
          </li>
        </ul>
        <p>
          This file is provided on terms and conditions in the agreement between
          you and us or our related bodies corporate under which this file is
          supplied. Without limiting those terms and conditions, this means that
          you agree to comply with all privacy laws including any determination,
          code or guideline issued under those laws in the relevant territory,
          namely Australia and/or New Zealand.
        </p>
      </div>
    );
  }

  renderClientServices() {
    return (
      <div>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Client services</p>
        </div>
        <table className={`${commonStyles.table} ${commonStyles.full_width}`}>
          <tbody>
            <tr>
              <td>CCB internal reference</td>
              <td>
                {extractText(this.requestSummary, "internal_reference", "-")}
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>clientservices@illion.com.au</td>
            </tr>
            <tr>
              <td>Telephone</td>
              <td>(03) 9828 3308 or (03) 9828 3539</td>
            </tr>
            <tr>
              <td>Fax</td>
              <td>+61 3 9828 3447</td>
            </tr>
            <tr>
              <td>Website</td>
              <td>illion.com.au</td>
            </tr>
          </tbody>
        </table>
        <p>
          Whilst illion attempts to ensure that the information provided is
          accurate and complete by reason of the immense quantity of detailed
          matter dealt within compiling the information and the fact that some
          of the data are supplied from sources not controlled by illion which
          cannot always be verified, including information provided direct from
          the subject of enquiry as well as the possibility of negligence and
          mistake, illion does not guarantee the correctness or the effective
          delivery of the information and will not be held responsible for any
          errors or omissions therein.
        </p>
      </div>
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Appendix"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        {this.renderDisclosureNotice()}
        {this.renderClientServices()}
      </ReviewContainer>
    );
  }
}
