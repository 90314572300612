import React from "react";
import { connect } from "react-redux";
import { animateScroll as scroll } from "react-scroll";
import { setValue, toggleEntityType } from "../actions";
import {
  createLimitConflictMessage,
  findLimitBreakpoints,
  findLimitConflicts,
} from "modules/addons/helpers";
import styles from "./css/AutoDecisioning.css";
import AMLVerification from "./AMLVerification";
import Financials from "./Financials";
import Paperless from "./Paperless";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import Button from "modules/shared/components/inputs/Button";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import LegalTypeCheckbox from "../../addons_form/components/LegalTypeCheckbox";
import MaxLimit from "./MaxLimit";
import TimeInBusiness from "./TimeInBusiness";
import CreditChecks from "./CreditChecks";
import TradeReferences from "./TradeReferences";
import Guarantors from "./Guarantors";
import Signatory from "./Signatory";
import isPresent from "utils/isPresent";
import isBlank from "utils/isBlank";
import AntiFraud from "./AntiFraud";

const isGuarantorOrSignatoryRequired = attributes => {
  const {
    credit_checks_required,
    nz_personal_credit_indicator,
    au_personal_credit_indicator,
  } = attributes;

  const hasPersonalCreditIndicator =
    isPresent(nz_personal_credit_indicator) ||
    isPresent(au_personal_credit_indicator);

  return credit_checks_required && hasPersonalCreditIndicator;
};

// If personal credit check is ON, guarantor rules should be enabled
const isGuarantorsFieldValid = attributes => {
  if (!isGuarantorOrSignatoryRequired(attributes)) {
    return true;
  }

  const { guarantors } = attributes;

  return guarantors;
};

// If personal credit check is ON, guarantor rules should be enabled and
// requires at least 1 guarantor
const isGuarantorsCountFieldValid = attributes => {
  if (!isGuarantorOrSignatoryRequired(attributes)) {
    return true;
  }

  const { guarantors_count } = attributes;

  return guarantors_count || 0 >= 1;
};

// If personal credit check is ON, signatory rules should be enabled
const isSignatoriesFieldValid = attributes => {
  if (!isGuarantorOrSignatoryRequired(attributes)) {
    return true;
  }

  const { signatories } = attributes;

  return signatories;
};

// If personal credit check is ON, signatory rules should be enabled and
// requires at least 1 signatory
const isSignatoriesCountFieldValid = attributes => {
  if (!isGuarantorOrSignatoryRequired(attributes)) {
    return true;
  }

  const { signatories_count } = attributes;

  return signatories_count || 0 >= 1;
};

class AutoDecisioningForm extends React.Component {
  state = { errors: {} };

  selectType(type) {
    const { dispatch } = this.props;
    dispatch(toggleEntityType(type));
  }

  submit() {
    const { rule_set, handleSubmit } = this.props;
    let errors = this.checkValid();
    if (Object.keys(errors).length) {
      this.setState({ errors });
      scroll.scrollToTop();
    } else {
      handleSubmit(rule_set);
    }
  }

  handleNameChange(e) {
    const { dispatch } = this.props;
    dispatch(setValue("name", e.target.value));
  }

  checkValid() {
    const {
      auto_decisions,
      rule_set,
      current_entity,
      isOnlyPersonal,
    } = this.props;
    let errors = {};
    let att = rule_set.attributes;

    if (
      att.max_credit_value <= att.min_credit_value &&
      !(att.min_credit_value === 0 && att.max_credit_value === 0)
    ) {
      errors.credit_limit = "Max credit value must be greater than the minimum";
    }
    if (!att.max_credit_value && att.max_credit_value != 0) {
      errors.credit_limit = "Please select a max credit value";
    }
    if (!att.time_in_business && !isOnlyPersonal) {
      errors.time_in_business = "Please select a time in business";
    }
    if (!att.hasOwnProperty("require_anti_fraud")) {
      errors.require_anti_fraud = "Please select a 1CAF option";
    }
    if (!att.hasOwnProperty("credit_checks_required")) {
      errors = {
        ...errors,
        credit_checks: { required: "Please select a credit check option" },
      };
    }
    if (!att.hasOwnProperty("guarantors")) {
      errors.guarantors = "Please select a guarantor option";
    }
    if (!att.hasOwnProperty("signatories")) {
      errors.signatories = "Please select a signatory option";
    }
    if (!att.hasOwnProperty("trade_reference")) {
      errors.trade_reference = "Please select a trade reference option";
    }
    if (!att.legal_types.length) {
      errors.legal_types = "Please select at least one legal type";
    }

    if (att.credit_checks_required) {
      if (!att.hasOwnProperty("judgements")) {
        errors.credit_checks = {
          ...errors.credit_checks,
          judgements: "Please select a judgements option",
        };
      }
      if (!att.hasOwnProperty("defaults")) {
        errors.credit_checks = {
          ...errors.credit_checks,
          defaults: "Please select a defaults option",
        };
      }
      if (
        current_entity.selected_credit_check_rule_ids.nz_rule_selection_id &&
        att.nz_business_credit_indicator === undefined &&
        !isOnlyPersonal
      ) {
        errors.credit_checks = {
          ...errors.credit_checks,
          nz_business_credit_indicator: "Please set a credit risk limit",
        };
      }
      if (
        current_entity.selected_credit_check_rule_ids
          .nz_personal_rule_selection_id &&
        att.nz_personal_credit_indicator === undefined
      ) {
        errors.credit_checks = {
          ...errors.credit_checks,
          nz_personal_credit_indicator: "Please set a credit risk limit",
        };
      }
      if (
        current_entity.selected_credit_check_rule_ids.au_rule_selection_id &&
        att.au_business_credit_indicator === undefined &&
        !isOnlyPersonal
      ) {
        errors.credit_checks = {
          ...errors.credit_checks,
          au_business_credit_indicator: "Please set a credit risk limit",
        };
      }
    }

    if (att.guarantors) {
      if (
        !att.guarantors_count ||
        att.guarantors_count > current_entity.minimum_guarantees
      ) {
        errors.guarantors_count = "Please select an option";
      }
    }

    if (att.trade_reference) {
      if (
        !att.trade_reference_count ||
        att.trade_reference_count > current_entity.minimum_trade_references
      ) {
        errors.trade_reference_count = "Please select an option";
      }
    }
    if (att.signatories && !att.signatories_count) {
      errors.signatories_count = "Please select an option";
    }

    if (!isGuarantorsFieldValid(att)) {
      errors.guarantors =
        "Guarantors is required when personal credit check is enabled";
    }

    if (!isGuarantorsCountFieldValid(att)) {
      errors.guarantors_count =
        "At least one guarantor is required when personal credit check is enabled";
    }

    if (!isSignatoriesFieldValid(att)) {
      errors.signatories =
        "Signatories is required when personal credit check is enabled";
    }

    if (!isSignatoriesCountFieldValid(att)) {
      errors.signatories_count =
        "At least one signatory is required when personal credit check is enabled";
    }

    const limitBreakpoints = findLimitBreakpoints(auto_decisions, rule_set);
    const limitConflicts = findLimitConflicts(limitBreakpoints, rule_set);
    const conflictError = createLimitConflictMessage(limitConflicts);
    if (isPresent(conflictError) && isBlank(errors.credit_limit)) {
      errors.credit_limit = conflictError;
    }

    return errors;
  }

  render() {
    const {
      rule_set,
      updating,
      handleDiscard,
      submitButtonText,
      has_approval_hierarchy,
      current_entity,
      read_only,
      show_version,
      isOnlyPersonal,
      auto_decisions,
    } = this.props;
    let limit_breakpoints = findLimitBreakpoints(auto_decisions, rule_set);
    let limit_conflicts = findLimitConflicts(limit_breakpoints, rule_set);
    let errors = this.state.errors;
    if (limit_conflicts) {
      errors = {
        ...this.state.errors,
        credit_limit: createLimitConflictMessage(limit_conflicts),
      };
    }
    return (
      <section className={styles.container}>
        <div className={styles.header_row}>
          {rule_set.attributes.serial_number && (
            <span className={styles.serial}>
              #{rule_set.attributes.serial_number}
            </span>
          )}
          <Input
            classes={{ input: styles.name_text, root: styles.input_parent }}
            value={
              show_version
                ? `${rule_set.attributes.name} (Version ${rule_set.attributes.current_index})`
                : rule_set.attributes.name || ""
            }
            placeholder={"Name your ruleset here - eg: Company"}
            onChange={this.handleNameChange.bind(this)}
            required
          />
          <CloseButton handleClick={handleDiscard} />
        </div>
        <div className={styles.section}>
          {read_only || [
            !!Object.keys(errors).length && (
              <div className={styles.error}>
                Error submitting application, please check all required fields
                are filled
              </div>
            ),
            rule_set.attributes.on_hold && (
              <div className={styles.error}>
                {has_approval_hierarchy
                  ? "You recently changed your settings in your profile which put this ruleset on hold. \
                  If you'd like to reactivate please change your ruleset as indicated below."
                  : "You need to setup approval hierarchy before activate auto decisioning."}
              </div>
            ),
          ]}
          <div className={styles.row}>
            <div className={styles.info}>{defaults.description_text}</div>
          </div>
        </div>
        <LegalTypeCheckbox
          selected={rule_set.attributes.legal_types}
          handleChange={e => {
            this.selectType(e.target.id);
          }}
          error={errors.legal_types}
          readOnly={read_only}
        />
        <MaxLimit
          rule_set={rule_set.attributes}
          current_entity={current_entity}
          error={errors.credit_limit}
          read_only={read_only}
        />
        {!isOnlyPersonal && (
          <TimeInBusiness
            rule_set={rule_set.attributes}
            error={errors.time_in_business}
            read_only={read_only}
            limit_breakpoints={limit_breakpoints}
          />
        )}
        <CreditChecks
          rule_set={rule_set.attributes}
          current_entity={current_entity}
          handleDiscard={handleDiscard}
          errors={errors.credit_checks}
          read_only={read_only}
          isOnlyPersonal={isOnlyPersonal}
        />
        <AntiFraud
          rule_set={rule_set.attributes}
          current_entity={current_entity}
          error={errors.require_anti_fraud}
          read_only={read_only}
        />
        <AMLVerification
          rule_set={rule_set.attributes}
          current_entity={current_entity}
          error={errors.require_anti_fraud}
          read_only={read_only}
        />
        <Financials
          rule_set={rule_set.attributes}
          current_entity={current_entity}
          error={errors.require_anti_fraud}
          read_only={read_only}
        />
        <Paperless
          rule_set={rule_set.attributes}
          current_entity={current_entity}
          error={errors.require_anti_fraud}
          read_only={read_only}
        />
        <TradeReferences
          rule_set={rule_set.attributes}
          current_entity={current_entity}
          error={errors.trade_reference}
          count_error={errors.trade_reference_count}
          read_only={read_only}
        />
        <Guarantors
          rule_set={rule_set.attributes}
          current_entity={current_entity}
          error={errors.guarantors}
          count_error={errors.guarantors_count}
          read_only={read_only}
        />
        <Signatory
          rule_set={rule_set.attributes}
          current_entity={current_entity}
          error={errors.signatories}
          count_error={errors.signatories_count}
          read_only={read_only}
        />
        {!read_only && (
          <div>
            <div className={styles.footer_text}>
              By clicking "{submitButtonText}" you acknowledge that all
              applications that fall within your rule criteria will be
              automatically approved.
            </div>
            <div className={styles.button_container}>
              <Button
                text={"discard"}
                handleClick={handleDiscard}
                disabled={updating}
              />
              {updating && <span className={styles.loading}></span>}
              <Button
                text={
                  updating ? "saving" : submitButtonText || "create ruleset"
                }
                handleClick={this.submit.bind(this)}
                disabled={updating}
              />
            </div>
          </div>
        )}
      </section>
    );
  }
}

const defaults = {
  description_text:
    "The 1Centre Auto Decisioning (1CAD) tool, is a rules based system that decides whether an application will\
                     be approved or deferred based on parameters you set for your business. The system can provide real time approvals\
                     preventing lag times and improving efficiencies within your business. Applications that don't meet your ruleset will\
                     be deferred for you to manually fate, you will receive a notification if this occurs. For added comfort turn on time\
                     delay in the approval notification (profile tab), to give you time to load your new customer in your CRM system before\
                     they are notified, if not fully integrated.",
  ruleset: {
    name: "New Auto Decisioning Ruleset",
  },
};

export default connect((state, ownProps) => {
  const selected =
    state.auto_decisions.current_rule_set.attributes.legal_types || [];
  const isOnlyPersonal = selected.length === 1 && selected.includes("personal");

  let rule_set = state.auto_decisions.current_rule_set || defaults.ruleset;
  const has_approval_hierarchy =
    !ownProps.current_entity.supplier_onboarding_checklist ||
    ownProps.current_entity.supplier_onboarding_checklist.approval_hierarchy;
  return {
    updating: state.auto_decisions.updating,
    rule_set,
    handleDiscard: ownProps.handleDiscard,
    handleSubmit: ownProps.handleSubmit,
    submitButtonText: ownProps.submitButtonText,
    current_entity: ownProps.current_entity,
    isOnlyPersonal: isOnlyPersonal,
    auto_decisions: state.add_ons.auto_decisions,
    has_approval_hierarchy: has_approval_hierarchy,
  };
})(withStyles(styles)(AutoDecisioningForm));
