import React from "react";
import moment from "moment";
import styles from "./centrix.css";

class EquiryDetails extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  renderName(person) {
    return (
      <span>
        {person.surname.toUpperCase()}
        {`, ${person.first_name}`}
        {person.middle_name && ` ${person.middle_name}`}
      </span>
    );
  }

  render() {
    const { data } = this.props;
    const personal = data.data_sets.consumer_file.personal;

    return (
      <div>
        <h4 className={styles.report_name}>Comprehensive and Adverse Report</h4>
        <div style={{ marginBottom: "1em" }}>
          <b>{this.renderName(personal)}</b>
        </div>
        <div>Subscriber Reference:</div>
        <div>Enquiry Number: {data.response_details.enquiry_number}</div>
        <div>
          Issue Date:{" "}
          {moment(data.response_details.enquiry_date).format("DD/MM/YYYY")}
        </div>
      </div>
    );
  }
}

export default EquiryDetails;
