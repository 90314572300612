/* Import libraries */
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import LabeledInformation from "modules/shared/components/widgets/static/LabeledInformation";
import React, { Component } from "react";
import { connect } from "react-redux";

import styles from "./css/Review.css";

class ReviewTradingDetails extends Component {
  renderTradingName(tradingName) {
    const items = Object.keys(labels).map(key => {
      let data = tradingName[key];
      if (key === "delivery_address_details") {
        data = tradingName[key].full_address;
      }
      return (
        <div className={styles.float_col} key={key}>
          <LabeledInformation
            label={labels[key]}
            data={data || "-"}
            noHumanize={true}
          />
        </div>
      );
    });

    return (
      <ReviewContainer
        key={tradingName.trading_name}
        css_class={"block_noborder"}
        subHeading={tradingName.trading_name}
        collapsible={true}
        content_class="content_wide"
      >
        {items}
      </ReviewContainer>
    );
  }

  render() {
    const { tradingNames } = this.props;
    const items = tradingNames.map(tradingName => this.renderTradingName(tradingName));

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        <ReviewContainer css_class={"block_noborder"}></ReviewContainer>
        {items}
      </section>
    );
  }
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const labels = {
  contact_name: "Contact person",
  contact_email: "Contact email address",
  contact_phone_number: "Contact phone number",
  delivery_address_details: "Delivery address",
  delivery_instruction: "Delivery instruction",
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

module.exports = connect((state, ownProps) => {
  const selectedTradingNameIds = ownProps.data.selected_trading_names;
  const tradingNameOptions = ownProps.data.trading_names;
  const tradingNames = selectedTradingNameIds.map(id => tradingNameOptions[id]);

  return {
    tradingNames,
  };
})(ReviewTradingDetails);
