import unleashed from "images/integrations/unleashed2.png";
import xero from "images/integrations/xero2.png";
import { store } from "index";
import moment from "moment";

import {
  connectIntegration,
  deleteIntegration,
  getIntegrations,
  saveIntegration,
  updateIntegrationAttributes,
} from "./actions";

class Integration {
  constructor(data) {
    const _data = Object.assign(this.defaults(), data);

    this.attributes = { id: _data.id, ..._data.attributes };
    this.errors = {};
    this.loading = true;
    this.betaList = ["xero"];
  }

  defaults() {
    return {
      attributes: {
        authenticated: false,
        service: "",
      },
      id: null,
    };
  }

  logoSrc() {
    switch (this.attributes.service) {
      case "xero":
        return xero;
      case "unleashed":
        return unleashed;
    }
  }

  connected() {
    return !!this.attributes.id && this.authorised();
  }

  authorised() {
    return !!this.attributes.authorised;
  }

  connectedDate() {
    return moment(this.attributes.created_at).fromNow();
  }

  update(attributes) {
    this.attributes = { ...this.attributes, ...attributes };
  }

  updateAttributeState(attributes) {
    this.update(attributes);
    store.dispatch(updateIntegrationAttributes(this));
  }

  isBeta() {
    return this.betaList.indexOf(this.attributes.service) >= 0;
  }

  isValid() {
    return Object.keys(this.errors).length === 0;
  }

  // TODO: Review the use of this method
  /* eslint-disable no-empty-function */
  validate() {}
  /* eslint-enable no-empty-function */

  save(success) {
    store.dispatch(saveIntegration(this, success));
  }

  // TODO: Review usage of `success` variable and remove `eslint-disable no-unused-vars`
  /* eslint-disable no-unused-vars */
  delete(success) {
    store.dispatch(deleteIntegration(this));
  }
  /* eslint-enable no-unused-vars */

  connect() {
    if (this.connected()) {
      store.dispatch(deleteIntegration(this));
    } else {
      store.dispatch(
        connectIntegration(this.attributes.service, this.authenticate),
      );
    }
  }

  authenticate(url) {
    window.location = url;
  }
}

Integration.parseData = data => {
  const integrations = [];

  let list = data.data.data;

  if (!Array.isArray(list)) {
    list = [list];
  }
  for (let i = 0, len = list.length; i < len; i += 1) {
    integrations.push(new Integration(list[i]));
  }
  return integrations;
};

Integration.loadAll = () => {
  store.dispatch(getIntegrations());
};

// TODO: Review usage of `find` and remove `eslint-disable no-unused-vars`
/* eslint-disable no-unused-vars */
Integration.find = id => {
  // store.dispatch(getWebhook(id));
};
/* eslint-enable no-unused-vars */

export default Integration;
