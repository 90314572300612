import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#users-creating-a-user
 */
export default class Invites {
  constructor(config) {
    this.axios = axios(config);
    this.type = "invites";
  }

  createForConsumer(attributes, success, error) {
    return this.create(
      { ...attributes, action_type: "application" },
      success,
      error,
    );
  }

  createForNonConsumer(attributes, success, error) {
    return this.create(
      { ...attributes, action_type: "signup_and_application" },
      success,
      error,
    );
  }

  resendInvite(attributes, success, error) {
    return this.create(
      { ...attributes, action_type: "resend_application" },
      success,
      error,
    );
  }

  resendAuthorisationInvite(attributes, success, error) {
    return this.create(
      { ...attributes, action_type: "resend_authorisation_emails" },
      success,
      error,
    );
  }

  create(attributes, success, error) {
    return this.axios
      .post(`/${this.type}`, {
        data: {
          attributes,
          type: this.type,
        },
      })
      .then(success)
      .catch(error);
  }

  createForConsumerAndUser(attributes, success, error) {
    return this.create(
      { ...attributes, action_type: "application_to_user" },
      success,
      error,
    );
  }

  createForConsumerAndNewUser(attributes, success, error) {
    return this.create(
      { ...attributes, action_type: "application_to_new_user" },
      success,
      error,
    );
  }
}
