/* Import libraries */
import React from "react";

import AdminTitle from "../widgets/static/AdminTitle";

import styles from "./css/Container.css";

class Container extends React.Component {
  renderHeader() {
    const { title } = this.props;

    if (!title) {
      return null;
    }

    return (
      <div className={styles.header_row}>
        <div className={styles.header}>
          <AdminTitle className={styles.heading} text={title} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <section className={styles.section}>
        {this.renderHeader()}
        {this.props.children}
      </section>
    );
  }
}

export default Container;
