type ENTITY_TYPES =
  | "company"
  | "other"
  | "partnership"
  | "personal"
  | "sole_trader"
  | "trust";

const abnEntityTypes: { [key: string]: ENTITY_TYPES } = {
  ADF: "other", // Approved Deposit Fund
  ARF: "other", // APRA Regulated Fund (Fund Type Unknown)
  CCB: "company", // Commonwealth Government Public Company
  CCC: "company", // Commonwealth Government Co-operative
  CCL: "partnership", // Commonwealth Government Limited Partnership
  CCN: "other", // Commonwealth Government Other Unincorporated Entity
  CCO: "other", // Commonwealth Government Other Incorporated Entity
  CCP: "other", // Commonwealth Government Pooled Development Fund
  CCR: "company", // Commonwealth Government Private Company
  CCS: "other", // Commonwealth Government Strata Title
  CCT: "trust", // Commonwealth Government Public Trading Trust
  CCU: "trust", // Commonwealth Government Corporate Unit Trust
  CGA: "other", // Commonwealth Government Statutory Authority
  CGC: "company", // Commonwealth Government Company
  CGE: "other", // Commonwealth Government Entity
  CGP: "partnership", // Commonwealth Government Partnership
  CGS: "other", // Commonwealth Government Super Fund
  CGT: "trust", // Commonwealth Government Trust
  CMT: "trust", // Cash Management Trust
  COP: "other", // Co-operative
  CSA: "other", // Commonwealth Government APRA Regulated Public Sector Fund
  CSP: "other", // Commonwealth Government APRA Regulated Public Sector Scheme
  CSS: "other", // Commonwealth Government Non-Regulated Super Fund
  CTC: "trust", // Commonwealth Government Cash Management Trust
  CTD: "trust", // Commonwealth Government Discretionary Services Management Trust
  CTF: "trust", // Commonwealth Government Fixed Trust
  CTH: "trust", // Commonwealth Government Hybrid Trust
  CTI: "trust", // Commonwealth Government Discretionary Investment Trust
  CTL: "trust", // Commonwealth Government Listed Public Unit Trust
  CTQ: "trust", // Commonwealth Government Unlisted Public Unit Trust
  CTT: "trust", // Commonwealth Government Discretionary Trading Trust
  CTU: "trust", // Commonwealth Government Fixed Unit Trust
  CUT: "trust", // Corporate Unit Trust
  DES: "other", // Deceased Estate
  DIP: "other", // Diplomatic/Consulate Body or High Commissioner
  DIT: "trust", // Discretionary Investment Trust
  DST: "trust", // Discretionary Services Management Trust
  DTT: "trust", // Discretionary Trading Trust
  FHS: "trust", // First Home Saver Accounts Trust
  FPT: "partnership", // Family Partnership
  FUT: "trust", // Fixed Unit Trust
  FXT: "trust", // Fixed Trust
  HYT: "trust", // Hybrid Trust
  IND: "sole_trader", // Trader Individual/Sole Trader
  LCB: "company", // Local Government Public Company
  LCC: "company", // Local Government Co-operative
  LCL: "partnership", // Local Government Limited Partnership
  LCN: "other", // Local Government Other Unincorporated Entity
  LCO: "company", // Local Government Other Incorporated Entity
  LCP: "other", // Local Government Pooled Development Fund
  LCR: "company", // Local Government Private Company
  LCS: "other", // Local Government Strata Title
  LCT: "trust", // Local Government Public Trading Trust
  LCU: "trust", // Local Government Corporate Unit Trust
  LGA: "other", // Local Government Statutory Authority
  LGC: "company", // Local Government Company
  LGE: "other", // Local Government Entity
  LGP: "partnership", // Local Government Partnership
  LGT: "trust", // Local Government Trust
  LPT: "partnership", // Limited Partnership
  LSA: "other", // Local Government APRA Regulated Public Sector Fund
  LSP: "other", // Local Government APRA Regulated Public Sector Scheme
  LSS: "other", // Local Government Non-Regulated Super Fund
  LTC: "trust", // Local Government Cash Management Trust
  LTD: "trust", // Local Government Discretionary Services Management Trust
  LTF: "trust", // Local Government Fixed Trust
  LTH: "trust", // Local Government Hybrid Trust
  LTI: "trust", // Local Government Discretionary Investment Trust
  LTL: "trust", // Local Government Listed Public Unit Trust
  LTQ: "trust", // Local Government Unlisted Public Unit Trust
  LTT: "trust", // Local Government Discretionary Trading Trust
  LTU: "trust", // Local Government Fixed Unit Trust
  NPF: "other", // APRA Regulated Non-Public Offer Fund
  NRF: "other", // Non-Regulated Superannuation Fund
  OIE: "other", // Other Incorporated Entity
  PDF: "other", // Pooled Development Fund
  POF: "other", // APRA Regulated Public Offer Fund
  PQT: "trust", // Unlisted Public Unit Trust
  PRV: "company", // Australian Private Company
  PST: "trust", // Pooled Superannuation Trust
  PTR: "partnership", // Other Partnership
  PTT: "trust", // Public Trading trust
  PUB: "company", // Australian Public Company
  PUT: "trust", // Listed Public Unit Trust
  SAF: "other", // Small APRA Regulated Fund
  SCB: "company", // State Government Public Company
  SCC: "company", // State Government Co-operative
  SCL: "partnership", // State Government Limited Partnership
  SCN: "other", // State Government Other Unincorporated Entity
  SCO: "other", // State Government Other Incorporated Entity
  SCP: "other", // State Government Pooled Development Fund
  SCR: "company", // State Government Private Company
  SCS: "other", // State Government Strata Title
  SCT: "trust", // State Government Public Trading Trust
  SCU: "trust", // State Government Corporate Unit Trust
  SGA: "other", // State Government Statutory Authority
  SGC: "company", // State Government Company
  SGE: "other", // State Government Entity
  SGP: "partnership", // State Government Partnership
  SGT: "trust", // State Government Trust
  SMF: "other", // ATO Regulated Self-Managed Superannuation Fund
  SSA: "other", // State Government APRA Regulated Public Sector Fund
  SSP: "other", // State Government APRA Regulated Public Sector Scheme
  SSS: "other", // State Government Non-Regulated Super Fund
  STC: "trust", // State Government Cash Management Trust
  STD: "trust", // State Government Discretionary Services Management Trust
  STF: "trust", // State Government Fixed Trust
  STH: "trust", // State Government Hybrid Trust
  STI: "trust", // State Government Discretionary Investment Trust
  STL: "trust", // State Government Listed Public Unit Trust
  STQ: "trust", // State Government Unlisted Public Unit Trust
  STR: "other", // Strata-title
  STT: "trust", // State Government Discretionary Trading Trust
  STU: "trust", // State Government Fixed Unit Trust
  SUP: "other", // Super Fund
  TCB: "company", // Territory Government Public Company
  TCC: "company", // Territory Government Co-operative
  TCL: "partnership", // Territory Government Limited Partnership
  TCN: "other", // Territory Government Other Unincorporated Entity
  TCO: "company", // Territory Government Other Incorporated Entity
  TCP: "other", // Territory Government Pooled Development Fund
  TCR: "company", // Territory Government Private Company
  TCS: "other", // Territory Government Strata Title
  TCT: "trust", // Territory Government Public Trading Trust
  TCU: "trust", // Territory Government Corporate Unit Trust
  TGA: "other", // Territory Government Statutory Authority
  TGE: "other", // Territory Government Entity
  TGP: "partnership", // Territory Government Partnership
  TGT: "trust", // Territory Government Trust
  TRT: "trust", // Other trust
  TSA: "other", // Territory Government APRA Regulated Public Sector Fund
  TSP: "other", // Territory Government APRA Regulated Public Sector Scheme
  TSS: "other", // Territory Government Non-Regulated Super Fund
  TTC: "trust", // Territory Government Cash Management Trust
  TTD: "trust", // Territory Government Discretionary Services Management Trust
  TTF: "trust", // Territory Government Fixed Trust
  TTH: "trust", // Territory Government Hybrid Trust
  TTI: "trust", // Territory Government Discretionary Investment Trust
  TTL: "trust", // Territory Government Listed Public Unit Trust
  TTQ: "trust", // Territory Government Unlisted Public Unit Trust
  TTT: "trust", // Territory Government Discretionary Trading Trust
  TTU: "trust", // Territory Government Fixed Unit Trust
  UIE: "other", // Other Unincorporated Entity
};

export default abnEntityTypes;
