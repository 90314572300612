import React, { Component } from "react";

import styles from "modules/shared/components/text/css/StandardTermsAndConditions.css";

export default class AgreementSection4 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>4.</span> The Supplier’s
            responsibilities
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>4.1</span> The Supplier shall, at
          its own expense:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> be responsible for
          ensuring that it has all necessary rights (or appropriate licences) to
          supply and use data and information that is used with the Service;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> work co-operatively
          with 1Centre to prevent unauthorised access to the Service, including
          any security and protection mechanisms recommended by 1Centre;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>c.</span> comply with all
          applicable laws, including provisions of the Privacy Act 1998 (Cth)
          that may apply to personal information which is disclosed or processed
          through the Platform;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>d.</span> provide all
          information, decisions, assistance, and cooperation that 1Centre may
          reasonably require to perform the Services and comply with these Terms
          and the Privacy Act 1998 (Cth);
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>e.</span> provide 1Centre with
          information about any changes in the Supplier’s use of the Services;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>f.</span> be responsible for
          ensuring that the Supplier Terms of Trade (to be agreed by Business
          Customers) are in compliance with laws and appropriate to the
          Supplier’s business (even if the Supplier obtains such Supplier Terms
          of Trade from 1Centre); and
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>g.</span> not introduce into
          1Centre’s systems or environment any viruses, worms and other
          malicious software devices.
        </p>
      </div>
    );
  }
}
