import styled from "styled-components";
import { FONT_SIZES } from 'variables/typography';

export const SubHeading = styled.h3`
  font-weight: bold;
  font-size: ${FONT_SIZES.title};
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const InnerSubHeading = styled.h4`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const InnerContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Wrapper = styled.div`
  @media (min-width: 768px) {
    margin-left: 66px;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;
