/* Routes */
import Authorisation from "./routes/Authorisation";
import Cardholder from "./routes/Cardholder";
import CardManagement from "./routes/CardManagement";
import CardManagementAuthorisation from "./routes/CardManagementAuthorisation";
import Dashboard from "./routes/Dashboard";
import Guarantor from "./routes/Guarantor";
import InvalidAccount from "./routes/InvalidAccount";
import Paperless from "./routes/Paperless";
import PaperlessAuthorisation from "./routes/PaperlessAuthorisation";
import Register from "./routes/Register";
import ShareTheLove from "./routes/ShareTheLove";
import Trm from "./routes/Trm";
import User from "./routes/User";
import WebhookDocumentation from "./routes/WebhookDocumentation";
import WebhookV2DocumentationRoute from "./routes/WebhookV2DocumentationRoute";
import WebsiteButton from "./routes/WebsiteButton";
import Home from "modules/shared/Home";

const Application = {
  childRoutes: [
    {
      childRoutes: [
        Authorisation,
        Cardholder,
        CardManagement,
        CardManagementAuthorisation,
        Dashboard,
        Guarantor,
        InvalidAccount,
        Paperless,
        PaperlessAuthorisation,
        Register,
        ShareTheLove,
        Trm,
        User,
        WebhookDocumentation,
        WebhookV2DocumentationRoute,
        WebsiteButton,
      ],
      component: require("./containers/Layout"),
      indexRoute: {
        component: Home,
      },
      path: "/",
    },
  ],
};

export default Application;
