import AustraliaBankAccountDetails from "modules/paperless-authorisation/components/Abstract/BankAccountDetailsByRegion/AustraliaBankAccountDetails";
import NewZealandBankAccountDetails from "modules/paperless-authorisation/components/Abstract/BankAccountDetailsByRegion/NewZealandBankAccountDetails";
import React, { ReactElement } from "react";
import REGION from "types/region";

const BankAccountDetailsByRegion = ({
  bankAccountName,
  bankAccountNumber,
  bankNumber,
  region,
}: {
  bankAccountName: string;
  bankAccountNumber: string;
  bankNumber: string;
  region: REGION | null | undefined;
}): ReactElement | null => {
  switch (region) {
    case "AU":
      return (
        <AustraliaBankAccountDetails
          bankAccountName={bankAccountName}
          bankAccountNumber={bankAccountNumber}
          bankNumber={bankNumber}
        />
      );
    case "NZ":
      return (
        <NewZealandBankAccountDetails
          bankAccountName={bankAccountName}
          bankAccountNumber={bankAccountNumber}
        />
      );
  }

  return null;
};

export default BankAccountDetailsByRegion;
