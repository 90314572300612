/* eslint-disabled max-lines */
import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import PreviewSaveButtons from "modules/addons/addons_form/components/PreviewSaveButtons";
import {
  DigitalOnboardingContext,
} from "modules/addons/components//DigitalOnboarding";
import styles from "modules/addons/components/css/DirectDebitModuleComponents.css";
import { DigitalOnboardingItem } from "modules/addons/components/DigitalOnboarding";
import FormCheckBox from "modules/shared/components/inputs/FormCheckBox";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import ContentContainer from "modules/shared/components/v2/ContentContainer";
import Header from "./PPSRRegistration/forms/Header";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import Button from "modules/shared/components/inputs/Button";

export const alertTypes = [
  { label: "Deregistered", value: "deregistered" },
  { label: "Liquidation", value: "liquidation"},
  { label: "Receivership", value: "receivership", disabled: true},
  { label: "Voluntary Administration", value: "voluntary_administration", disabled: true},
  { label: "Insolvency", value: "insolvency", disabled: true},
];

function Item(props) {
  const {
    addonConfig,
    currentUser,
    onUpdateModuleItem,
  } = props;

  const { onSetAddonToEdit } = useContext(DigitalOnboardingContext);
  const onClickEdit = () => onSetAddonToEdit(addonConfig);

  const onSuccessCallback = updatedAddonConfig => {
    mixpanel.track("Update Alerts module ruleset", {
      "Entity ID": get(currentUser, "currentEntity.id"),
      Ruleset: updatedAddonConfig.data,
      "Ruleset ID": updatedAddonConfig.id,
      distinct_id: currentUser.id,
    });

    onUpdateModuleItem(updatedAddonConfig);
  };

  const onClickToggleState = () => {
    addonConfig.setAttribute("active", !addonConfig.active);
    addonConfig.update({ currentUser, onSuccessCallback });
  };

  return (
    <DigitalOnboardingItem
      activeState={addonConfig.active}
      areActionButtonsVisible={addonConfig.isPersisted}
      body="Set up the alerts module to monitor credit changes of your existing customers."
      formattedState={addonConfig.formattedActiveState}
      header="Alerts module"
      isConfigured={addonConfig.isConfigured}
      isEnabled
      onClickEdit={onClickEdit}
      onClickToggleState={onClickToggleState}
    />
  );
}

function getNewVersionAttribute(addonConfig, data) {
  const attributes = {
    config: {
      alert_types: data.alertTypes,
    },
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = "Alerts Module";
    attributes.addon_module_name = "alert_module";
  }

  return attributes;
}

function Edit(props) {
  const {
    addonConfig,
    currentUser,
    onRefreshDigitalOnboardingModules,
  } = props;

  const { onClickBack } = useContext(DigitalOnboardingContext);
  const {
    errors,
    setValue,
    control,
    handleSubmit,
    register,
    watch,
  } = useForm({
    mode: "onBlur",
  });

  const newVersion = addonConfig.generateNewVersion();
  const versionConfig = newVersion.config;

  const onSuccessCallback = addonVersion => {
    mixpanel.track("Configure Alerts module", {
      "Entity ID": get(currentUser, "currentEntity.id"),
      Ruleset: addonVersion.data,
      distinct_id: currentUser.id,
    });

    onRefreshDigitalOnboardingModules();
    onClickBack();
  };

  const onClickCloseButton = () => {
    onClickBack();
  };

  const onSubmit = data => {
    const attributes = getNewVersionAttribute(addonConfig, data);
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser,
      onSuccessCallback,
    });
  };

  const handleChange = (callback, openModalCallback) => {
    // TODO - better way to handle passing data to preview, hack to improve performance
    (
      handleSubmit(data => {
        const newUnsavedAttributes = getNewVersionAttribute(addonConfig, data).config;
        callback(newUnsavedAttributes);
        openModalCallback();
      }
      ))()
  }

  return (
    <div className={styles.container}>
      <Header addonModuleName="alert_module" />
      <CloseButton handleClick={onClickCloseButton} />
      <form onSubmit={handleSubmit(onSubmit)}>
          <ContentContainer>
          <div className={styles.content_header}>
          Step 1: Please select the alerts you want to see. Only these will display in your Alerts pipeline, and will connect to the relevant customer credit file.
          </div>
            {alertTypes.map(({
              disabled,
              label,
              value,
            }) => (
              <div
                className={styles.checkbox_row}
                key={`alertTypes[${value}]`}
              >
                <FormCheckBox
                  id={`alertTypes[${value}]`}
                  label={label}
                  control={control}
                  disabled={disabled}
                  value={value}
                  register={register}
                  hideHelperText
                  defaultValue={
                    (versionConfig.alert_types || {})[value] ||
                    false
                  }
                />
              </div>
            ))}
            <div className={styles.error}>
              {get(errors, "alertTypes.message")}
            </div>
        </ContentContainer>
        <div>
          <Button text="save" type="submit" />
        </div>
      </form>
    </div>
  );
}

const AlertModuleComponents = {
  Edit,
  Item,
};

export default AlertModuleComponents;

connect(state => {
  return {
    currentCardsAddonConfig: get(
      state,
      "add_ons.cards[0].attributes.history_version.data[0].attributes.config",
      {
        additional_fields: [],
        additional_fields_general: [],
      },
    ),
  };
})(Edit);
