import React from "react";

import styles from "./css/ConnectionKeyContact.css";

import Integration from "models/integrations/Integration";

import Clickable from "modules/shared/components/widgets/interactive/Clickable";

class ConnectionIntegrations extends React.Component {
  connect(url) {
    window.open(url, "_blank");
  }

  render() {
    const { connection, role } = this.props;

    if (role !== "admin") {
      return null;
    }

    let xeroButton;

    if (connection.attributes.xero_id) {
      let integration = new Integration({ attributes: { service: "xero" } });
      xeroButton = (
        <Clickable
          target={this.connect.bind(this, connection.attributes.xero_deep_link)}
        >
          <span className={styles.integration_link}>
            <span className={styles.icon}>
              <img
                src={integration.logoSrc()}
                alt={integration.attributes.service}
                className={styles.logo}
              />
            </span>{" "}
            view in Xero
          </span>
        </Clickable>
      );
    }

    if (xeroButton == null) {
      return null;
    }

    return (
      <div className={styles.contacts_block}>
        <h2 className={styles.heading}>Integrations</h2>
        {xeroButton}
      </div>
    );
  }
}

module.exports = ConnectionIntegrations;
