/* eslint-disable array-element-newline */
import ConnectedApps from "modules/connected-apps/components";
import EditProfile from "modules/profile/components/EditProfile/index.js";
import SupplierList from "modules/super-admin/components/SupplierList";
import UserProfile from "modules/user/components/UserProfile";
import EditWebhook from "modules/webhooks/components/EditWebhook";
import NewWebhook from "modules/webhooks/components/NewWebhook";
import WebhookList from "modules/webhooks/components/WebhookList";
import CardManagementApplicationRoute from "routes/CardManagementApplicationRoute";
import ApplicationRoute from "routes/Dashboard/ApplicationRoute";
import DirectDebitAuthorityRoute from "routes/DirectDebitAuthorityRoute";
import PPSRRegistrationRoute from "routes/PPSRRegistrationRoute";

import AccountSettings from "../modules/account-settings/components/AccountSettings";
import AddOns from "../modules/addons/components/AddOns";
import BranchInvite from "../modules/branch-invite/components/BranchInvite";
import Webhooks from "../modules/connected-apps/components/Webhooks";
import AllConnectionsList from "../modules/connections/components/AllConnectionsList";
import Connection from "../modules/connections/components/Connection";
import SelectKeyContact from "../modules/connections/components/SelectKeyContact";
import ConsumerInvite from "../modules/consumer-invite/containers/ConsumerInvite";
import Home from "../modules/dashboard/components/Home";
import DashboardContainer from "../modules/dashboard/containers/DashboardContainer";
import CreateLead from "../modules/leads/components/CreateLead";
import LeadCompleted from "../modules/leads/components/LeadCompleted";
import LeadWrapper from "../modules/leads/components/LeadWrapper";
import LeadReview from "../modules/leads/components/Review";
import CreateUser from "../modules/manage-users/components/CreateUser";
import RemoveUser from "../modules/manage-users/components/RemoveUser";
import UpdateUser from "../modules/manage-users/components/UpdateUser";
import Mapping from "../modules/mapping/components/Mapping";
import AllNotifications from "../modules/notifications/components/AllNotifications";
import Payment from "../modules/payment/components/Payment";
import PricingPlans from "../modules/pricing-plans/components/PricingPlans";
import Reporting from "../modules/reporting/components/Reporting";
import {
  IsActiveAccount,
  IsAdmin,
  IsSuperAdmin,
  IsSupplier,
  LoggedIn,
} from "../modules/shared/auth";
import AlertApplicationRoute from "./Alerts";

const UsersRoute = {
  childRoutes: [
    {
      component: CreateUser,
      path: "create",
    },
    {
      component: UpdateUser,
      path: "update/:userId",
    },
    {
      component: RemoveUser,
      path: "remove/:userId",
    },
    {
      component: UserProfile,
      path: "profile",
    },
  ],
  path: "users",
};

const SuperRoute = {
  component: IsSuperAdmin(SupplierList),
  path: "supplier_list",
};

const NotificationsRoute = {
  component: AllNotifications,
  path: "notifications",
};

const DashboardHomeRoute = {
  component: Home,
  path: "home",
};

const ConnectionRoute = {
  childRoutes: [
    {
      component: AllConnectionsList,
      path: "all",
    },
    {
      childRoutes: [
        {
          component: SelectKeyContact,
          path: "contact",
        },
      ],
      component: Connection,
      path: "details/:connectionId/:relationType",
    },
  ],
  path: "connections",
};

const ConnectedAppsRoute = {
  childRoutes: [
    {
      component: NewWebhook,
      path: "webhooks/new",
    },
    {
      component: EditWebhook,
      path: "webhooks/:webhookId",
    },
    {
      component: Webhooks,
      indexRoute: { component: WebhookList },
      path: "webhooks",
    },
  ],
  component: IsAdmin(ConnectedApps),
  path: "connected-apps",
};

const ProfileRoute = {
  childRoutes: [],
  component: IsAdmin(IsSupplier(EditProfile)),
  path: "profile",
};

const AddOnsRoute = {
  childRoutes: [],
  component: IsAdmin(IsSupplier(AddOns)),
  path: "addons",
};

const MappingRoute = {
  childRoutes: [],
  component: IsSupplier(Mapping),
  path: "map",
};

const ReportingRoute = {
  childRoutes: [{ path: ":current" }],
  component: Reporting,
  path: "reporting",
};

const PaymentRoute = {
  childRoutes: [],
  component: IsAdmin(Payment),
  path: "payment",
};

const AccountSettingsRoute = {
  childRoutes: [],
  component: IsAdmin(AccountSettings),
  path: "account-settings",
};

const ConsumerInviteRoute = {
  component: ConsumerInvite,
  path: "invite/:application_type(/:component)",
};

const PricingPlansRoute = {
  childRoutes: [],
  component: IsAdmin(PricingPlans),
  path: "pricing-plans",
};

const BranchInviteRoute = {
  component: BranchInvite,
  path: "branch-invite/:headquarter_id",
};

const LeadsRoute = {
  childRoutes: [
    {
      component: IsSupplier(LeadWrapper),
      path: "list",
    },
    {
      component: IsSupplier(CreateLead),
      path: "create",
    },
    {
      component: IsSupplier(LeadCompleted),
      path: "complete",
    },
    {
      component: IsSupplier(LeadReview),
      path: ":leadId",
    },
  ],
  path: "leads",
};

module.exports = {
  childRoutes: [
    AlertApplicationRoute,
    DashboardHomeRoute,
    UsersRoute,
    SuperRoute,
    ApplicationRoute,
    ConnectionRoute,
    ProfileRoute,
    AddOnsRoute,
    NotificationsRoute,
    MappingRoute,
    ReportingRoute,
    PaymentRoute,
    AccountSettingsRoute,
    ConnectedAppsRoute,
    ConsumerInviteRoute,
    PricingPlansRoute,
    BranchInviteRoute,
    LeadsRoute,
    DirectDebitAuthorityRoute,
    CardManagementApplicationRoute,
    PPSRRegistrationRoute,
  ],
  component: LoggedIn(IsActiveAccount(DashboardContainer)),
  path: "dashboard",
};
