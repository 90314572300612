import React, { ReactElement, useState } from "react";
import V1Documentation from "modules/connected-apps/components/Webhooks/Documentation/v1-documentation";
import V2Documentation from "modules/connected-apps/components/Webhooks/Documentation/v2-documentation";
import BorderedSelect from "modules/shared/components/inputs/BorderedSelect.js";
import { VersionSelectionContainer } from "modules/connected-apps/components/Webhooks/Documentation/v2-documentation/styles";
import get from "lodash.get";

const VERSION_OPTIONS = [
  { label: "Version 1", value: "v1" },
  { label: "Version 2", value: "v2" },
];

const Documentation = (): ReactElement => {
  const [selectedVersion, setSelectedVersion] = useState("v1");

  let DocumentVersionComponent = V1Documentation;
  if (selectedVersion === "v2") {
    DocumentVersionComponent = V2Documentation;
  }

  return (
    <div className="is-flex mt-2">
      <VersionSelectionContainer>
        <BorderedSelect
          textFieldProps={{
            label: "Version",
          }}
          label="Version"
          value={selectedVersion}
          options={VERSION_OPTIONS}
          onChange={event => setSelectedVersion(get(event, "target.value"))}
          formControlCustomProps={{
            withBottomMargin: false,
          }}
        />
      </VersionSelectionContainer>
      <DocumentVersionComponent />
    </div>
  );
};

export default Documentation;
