import api from "api";
import debounce from "debounce";
import capitalize from "lodash.capitalize";
import get from "lodash.get";
import { DEFAULT_DEBTOR_REFERENCE } from "models/PpsrFinancingStatementDebtorModel";
import { COLLATERAL_TYPE_OPTIONS } from "models/PpsrFinancingStatementModel";
import styles from "modules/new-applications/css/PPSRRegister.css";
import GridContent from "modules/shared/components/containers/GridContent";
import BorderedAutocomplete from "modules/shared/components/inputs/BorderedAutocomplete";
import BorderedCalendarPicker from "modules/shared/components/inputs/BorderedCalendarPicker";
import BorderedTextField from "modules/shared/components/inputs/BorderedTextField";
import LabeledInputContent from "modules/shared/components/widgets/static/LabeledInputContent";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import isBlank from "utils/isBlank";

const DEBOUNCE_INTERVAL = 500;

function getPossibleDebtors({ authorisations, fields }) {
  const selectedAuthorisationIds = fields.map(field => field.authorisationId);

  return authorisations.filter(
    authorisation =>
      authorisation.isPpsrEligible &&
      !selectedAuthorisationIds.includes(authorisation.id),
  );
}

export function getSelectedDebtorTypeOption(debtorTypeOptions, debtorType) {
  return debtorTypeOptions.find(
    debtorTypeOption => debtorTypeOption.value === debtorType,
  );
}

export function AddDebtor(props) {
  const { append, application, fields, label } = props;
  const { authorisations } = application;

  const possibleDebtors = getPossibleDebtors({ authorisations, fields });

  if (isBlank(possibleDebtors)) {
    return null;
  }

  const possibleDebtorsOptions = possibleDebtors.map(authorisation => {
    return { label: authorisation.fullName, value: authorisation.id };
  });

  const [selectionError, setSelectionError] = useState("");
  const [selectedAuthorisation, setSelectedAuthorisation] = useState(null);
  const onSelectDebtorToAdd = (_, value) => {
    setSelectionError("");
    setSelectedAuthorisation(value);
  };

  const addDebtor = () => {
    if (isBlank(selectedAuthorisation)) {
      setSelectionError(`Please select ${label} to add.`);
      return;
    }

    const authorisationRecord = authorisations.find(
      authorisation => authorisation.id === selectedAuthorisation.value,
    );

    const newDebtorAttributes = {
      authorisationId: authorisationRecord.id,
      debtorAddress: {},
      debtorEmail: authorisationRecord.email,
      debtorPhone: "",
      debtorReference: DEFAULT_DEBTOR_REFERENCE,
      debtorType: "person",
      id: "",
      personFirstName: authorisationRecord.firstName,
      personLastName: authorisationRecord.lastName,
    };

    const { signature } = authorisationRecord;

    if (signature.dob) {
      newDebtorAttributes["personDateOfBirth"] = moment(signature.dob).toDate();
    }

    if (signature.rawAddress) {
      const rawAddress = signature.rawAddress;

      newDebtorAttributes["debtorAddress"] = {
        fullAddress: rawAddress.full_address,
        rawData: rawAddress,
      };
    }

    append(newDebtorAttributes);
    setSelectedAuthorisation(null);
  };

  return (
    <GridContent>
      <BorderedAutocomplete
        textFieldProps={{
          error: Boolean(selectionError),
          helperText: selectionError || " ",
          label: `${capitalize(label)} to add`,
          name: "selectDebtor",
        }}
        options={possibleDebtorsOptions}
        value={selectedAuthorisation}
        onChange={onSelectDebtorToAdd}
      />
      <div className={styles.add_collateral_action} onClick={addDebtor}>
        + Add {label}
      </div>
    </GridContent>
  );
}

AddDebtor.defaultProps = {
  label: "debtor",
};

export function AddressFinder(props) {
  const {
    currentUser,
    errors,
    fieldName,
    rawAddress,
    region,
    setRawAddress,
  } = props;

  const [addressOptions, setAddressOptions] = useState([rawAddress]);
  const [inputValue, setInputValue] = useState("");
  const addressSearchAPI = api("address_search", currentUser.accessToken);
  const onSearchSuccessCallback = result => {
    const addressSearchResult = get(result, "data.data");

    setAddressOptions(addressSearchResult);
  };

  const searchAddress = params => {
    addressSearchAPI.addressSearch(
      onSearchSuccessCallback,
      error => console.error(error),
      { params },
    );
  };
  const debouncedSearchAddress = useRef(
    debounce(searchAddress, DEBOUNCE_INTERVAL),
  ).current;

  const onClick = (_event, newValue) => {
    setAddressOptions(
      newValue ? [newValue, ...addressOptions] : addressOptions,
    );

    setRawAddress(newValue);
  };

  useEffect(() => {
    if (inputValue === "") {
      setAddressOptions(rawAddress ? [rawAddress] : []);
      return;
    }

    debouncedSearchAddress({
      address: inputValue,
      region,
      type: "All",
    });
  }, [inputValue]);

  return (
    <BorderedAutocomplete
      textFieldProps={{
        error: Boolean(errors.debtorAddress),
        helperText: get(errors, "debtorAddress.message", " "),
        label: "Debtor address",
        name: fieldName,
      }}
      getOptionLabel={option => {
        if (typeof option === "string") {
          return option;
        }

        return option.full_address;
      }}
      getOptionSelected={option => option}
      filterOptions={option => option}
      options={addressOptions}
      value={rawAddress}
      onChange={onClick}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
    />
  );
}

export function PersonDateOfBirthForm(props) {
  const {
    errors,
    defaultValue,
    fieldName,
    isDisabled,
    isVisible,
    register,
    setValue,
  } = props;

  if (!isVisible) {
    return null;
  }

  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(defaultValue);
  const onSelectDateOfBirth = date => {
    setSelectedDateOfBirth(date);
    setValue(fieldName, date);
  };

  return (
    <LabeledInputContent label="Date of birth">
      <BorderedCalendarPicker
        textFieldProps={{
          error: Boolean(errors.personDateOfBirth),
          helperText: get(errors, "personDateOfBirth.message", " "),
          id: "date-of-birth-datepicker",
          inputRef: register(),
          name: fieldName,
        }}
        isDisabled={isDisabled}
        value={selectedDateOfBirth}
        onChange={onSelectDateOfBirth}
      />
    </LabeledInputContent>
  );
}

PersonDateOfBirthForm.defaultProps = {
  isDisabled: false,
};

export function OrganisationNameForm(props) {
  const { errors, fieldName, isVisible, defaultValue, register } = props;

  if (!isVisible) {
    // Return empty div to show correct layout in the grid
    return <div></div>;
  }

  return (
    <BorderedTextField
      label="Organisation name"
      placeholder="Organisation name"
      defaultValue={defaultValue}
      disabled={true}
      inputRef={register()}
      name={fieldName}
      error={Boolean(errors.organisationName)}
      helperText={get(errors, "organisationName.message", " ")}
    />
  );
}

export function useSelectedCollateralTypeState({ defaultValue, region }) {
  const [selectedCollateralType, setSelectedCollateralType] = useState(
    defaultValue,
  );
  const selectedCollateralTypeOption =
    COLLATERAL_TYPE_OPTIONS.find(
      collateralTypeOption =>
        collateralTypeOption.region === region &&
        collateralTypeOption.value === selectedCollateralType,
    ) || {};

  return {
    selectedCollateralType,
    selectedCollateralTypeOption,
    setSelectedCollateralType,
  };
}

export function getAvailableCollateralTypeOptions({ config, region }) {
  const collateralPreset = config.collateral_preset || {};
  const keys = Object.keys(collateralPreset);
  const activeKeys = keys.filter(key => collateralPreset[key].active);

  return COLLATERAL_TYPE_OPTIONS.filter(
    option => option.region === region && activeKeys.includes(option.value),
  ).map(option => {
    return {
      presetDescription: collateralPreset[option.value].description,
      ...option,
    };
  });
}
