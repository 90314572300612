/* Import libraries */
import React from "react";
import { ReactElement } from "react";
import styles from "modules/shared/components/widgets/static/css/Arrow.css";

type Props = {
  theme: string,
}

function Arrow ({
  theme,
}: Props): ReactElement {
  const color = theme == "activate" ? "blue" : "grey";

  return (
    <div className={styles.arrow}>
      <div className={styles[`tail_${color}`]}></div>
      <div className={styles[`head_${color}`]}></div>
    </div>
  );
}

export default Arrow;
