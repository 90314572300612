import React, { useState } from "react";
import { connect } from "react-redux";
import { sendPasswordReset } from "../actions";
import get from "lodash.get";
import { Header } from "modules/new-applications/components/NewLogin/index.js";
import { Link } from "react-router";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const REQUEST_RESET_PASSWORD_FORM_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter email address."),
});

function getButtonClasses({ isLoading }) {
  const classes = ["button", "is-primary", "is-rounded"];

  if (isLoading) {
    classes.push("is-loading");
  }

  return classes.join(" ");
}

function Form(props) {
  const {
    dispatch,
    loading: isLoading,
    location,
    setEmail,
    setIsRequestSent,
  } = props;
  const { errors, handleSubmit, register } = useForm({
    mode: "onBlur",
    validationSchema: REQUEST_RESET_PASSWORD_FORM_SCHEMA,
  });

  const onSubmit = data => {
    const { email } = data;
    const redirectPath = get(location, "query.redirect");

    dispatch(
      sendPasswordReset(email, redirectPath, () => {
        setIsRequestSent(true);
        setEmail(email);
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="field px-4">
        <div className="has-text-left has-text-weight-normal mt-4">
          Enter your email below. You will receive an email with a link to reset
          your password.
        </div>
        <div className="control mt-5 has-icons-left">
          <span className="icon is-small is-left ">
            <i className="fas fa-envelope"></i>
          </span>
          <input
            className="input is-primary is-focused has-text-weight-normal is-lowercase"
            name="email"
            type="email"
            placeholder="Email"
            required
            ref={register}
            disabled={isLoading}
          />
          <p className="has-text-left mt-4 has-text-weight-normal has-text-danger is-size-small">
            {get(errors, "email.message", <span>&nbsp;</span>)}
          </p>
        </div>
      </div>
      <div className="field mt-4">
        <div className="control">
          <button
            className={getButtonClasses({ isLoading })}
            type="submit"
            disabled={isLoading}
          >
            Send link
          </button>
        </div>
      </div>
    </form>
  );
}

function Content(props) {
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [email, setEmail] = useState("");

  let content = null;
  if (isRequestSent) {
    content = (
      <div className="has-text-centered has-text-weight-normal mt-4 mb-4">
        We've sent a link to reset your password to {email}
      </div>
    );
  } else {
    content = (
      <Form
        setEmail={setEmail}
        setIsRequestSent={setIsRequestSent}
        {...props}
      />
    );
  }

  return (
    <div className="container has-text-centered mt-100">
      <div className="has-text-centered columns pt-1">
        <div className="column container has-text-centered box mb-5 is-two-thirds-tablet is-one-quarter-desktop">
          <div className="has-text-grey-darker mt-4 is-size-large has-text-weight-normal">
            Reset password
          </div>
          {content}
          <div className="field is-vcentered">
            <div className="column">
              <Link
                to="/"
                className="is-primary has-text-centered has-text-weight-normal"
              >
                Go Back
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ForgotPassword(props) {
  return (
    <div className="hero">
      <div className="hero-body">
        <div className="container is-widescreen">
          <Header />
          <Content {...props} />
        </div>
      </div>
    </div>
  );
}

export default connect((state, ownProps) => {
  return {
    email: state.login.forgot_password_email,
    loading: state.current_user.password_reset_loading,
  };
})(ForgotPassword);
