import api from "api";
import get from "lodash.get";
import AddonVersionModel from "models/AddonVersionModel";
import BaseModel from "models/BaseModel";
import isPresent from "utils/isPresent";

export const PPSR_REGION = {
  ppsr_australia_module: "AU",
  ppsr_module: "NZ",
};

export const PPSR_MODULE_NAME = {
  ppsr_australia_module: "Australia PPSR module",
  ppsr_module: "New Zealand PPSR module",
};

export const DIGITAL_ONBOARDING_MODULES = [
  "direct_debit_module",
  "card_management_module",
  "ppsr_module",
  "ppsr_australia_module",
  "internal_use_fields",
  "alert_module",
];

const READABLE_NAME = {
  anti_fraud_module: "1Centre Anti Fraud Module",
  card_management_module: "Card Management Module",
  direct_debit_module: "Direct Debit Module",
  ppsr_module: "PPSR Module",
  internal_use_fields: "Internal Use Fields",
  alert_module: "Alerts Module",
};

export default class AddonConfigModel extends BaseModel {
  static async fetchAddonConfigByAddonType({
    accessToken,
    addonType,
    entityId,
  }) {
    const addonConfigAPI = api("addon_configs", accessToken, entityId);

    try {
      const result = await addonConfigAPI.getAddonConfigs({
        params: { addon_module_name: addonType },
      });

      const addons = get(result, "data.data", []);
      const included = get(result, "data.included", []);

      return addons.map(addon => new AddonConfigModel(addon, included));
    } catch (error) {
      console.error(error);
    }
  }

  static async updateAddonConfig({
    accessToken,
    attributes,
    addonConfig,
    entityId,
    onSuccessCallback,
  }) {
    const addonConfigAPI = api("addon_configs", accessToken, entityId);

    try {
      const result = await addonConfigAPI.updateRuleSet(
        addonConfig.id,
        attributes,
      );

      const updatedAddonConfig = new AddonConfigModel(
        get(result, "data.data"),
        get(result, "data.included"),
      );

      onSuccessCallback(updatedAddonConfig);
    } catch (error) {
      console.error(error);
    }
  }

  get isConfigured() {
    return Boolean(this.id);
  }

  get latestVersion() {
    return this.addonVersions.sort((a, b) => b.version - a.version)[0];
  }

  get formattedActiveState() {
    return this.active ? "Active" : "Paused";
  }

  get humanReadableName() {
    return READABLE_NAME[this.addonModuleName] || this.addonModuleName;
  }

  generateNewVersion() {
    const latestVersion = this.latestVersion;
    const newVersion = new AddonVersionModel({});
    if (isPresent(latestVersion)) {
      newVersion.setAttributes(latestVersion.attributes);
    }

    return newVersion;
  }

  addVersion(version) {
    this.addonVersions.push(version);
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  update({ currentUser, onSuccessCallback }) {
    AddonConfigModel.updateAddonConfig({
      accessToken: currentUser.accessToken,
      addonConfig: this,
      attributes: this.attributes,
      entityId: get(currentUser, "currentEntity.id"),
      onSuccessCallback,
    });
  }

  /** Private functions */

  assignRelationships() {
    this.assignManyRelationship({
      key: "addon_versions",
      model: AddonVersionModel,
    });
  }
}
