import LabeledInformation from "modules/shared/components/widgets/static/LabeledInformation";
import PdfReader from "modules/shared/components/widgets/static/pdfReader";
import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import ReviewFromRender from "modules/shared/components/widgets/interactive/form_builder/ReviewFromRender";
import { connect } from "react-redux";
import styles from "./css/Review.css";

class ReviewFinancials extends React.Component {
  renderAdditionalFields(config, answers, key) {
    return (
      <ReviewFromRender
        components={config.additional_fields[key]}
        answers={answers[key]}
      />
    );
  }

  renderTotalField(attr, key) {
    const cols = [];
    let label;

    switch (key) {
      case "total_assets":
        label = "Total assets";
        break;
      case "total_liabilities":
        label = "Total liabilities";
        break;
      case "net_worth":
        label = "Net worth (Assets - Liabilities)";
        break;
      case "total_income":
        label = "Total income";
        break;
      case "total_expenses":
        label = "Total expenses";
        break;
      case "surplus":
        label = "Surplus (Income - Expenses)";
        break;
      default:
        label = key;
    }
    cols.push(<div className={styles.half_width}></div>);
    cols.push(
      <div className={styles.half_width}>
        <LabeledInformation
          key={key}
          label={label}
          data={`$${Number(attr[key]).toLocaleString()}`}
          noHumanize
        />
      </div>,
    );
    return cols;
  }

  renderFile(addonAnswer) {
    const fileSrc = addonAnswer.attributes.file;
    if (!fileSrc) return null;

    return (
      <ReviewContainer
        key={"file"}
        css_class={"block_noborder"}
        subHeading={"Financial attachment"}
        collapsible={true}
        content_class="content_wide"
      >
        <PdfReader url={fileSrc.url} />
      </ReviewContainer>
    );
  }

  renderAssetsLiabilities(addonVersion, answers) {
    const contents = [];
    const config = addonVersion.config;

    contents.push(
      <ReviewContainer
        key={"Assets"}
        css_class={"block_noborder"}
        subHeading={"Assets"}
        no_margin_left={true}
      >
        <div className={styles.width_100}>
          {this.renderAdditionalFields(config, answers, "assets")}
          {this.renderTotalField(answers, "total_assets")}
        </div>
      </ReviewContainer>,
    );

    contents.push(
      <ReviewContainer
        key={"Liabilities"}
        css_class={"block_noborder"}
        subHeading={"Liabilities"}
        no_margin_left={true}
      >
        <div className={styles.width_100}>
          {this.renderAdditionalFields(config, answers, "liabilities")}
          {this.renderTotalField(answers, "total_liabilities")}
          {this.renderTotalField(answers, "net_worth")}
        </div>
      </ReviewContainer>,
    );

    return (
      <ReviewContainer
        key={"assets_liabilities"}
        css_class={"block_noborder"}
        subHeading={"Assets & Liabilities"}
        collapsible={true}
        content_class="content_wide"
      >
        <div className={styles.width_100}>{contents}</div>
      </ReviewContainer>
    );
  }

  renderIncomeExpenses(addonVersion, answers) {
    const contents = [];
    const config = addonVersion.config;

    contents.push(
      <ReviewContainer
        key={"Income"}
        css_class={"block_noborder"}
        subHeading={"Income (monthly)"}
        no_margin_left={true}
      >
        <div className={styles.width_100}>
          {this.renderAdditionalFields(config, answers, "income")}
          {this.renderTotalField(answers, "total_income")}
        </div>
      </ReviewContainer>,
    );

    contents.push(
      <ReviewContainer
        key={"Expenses"}
        css_class={"block_noborder"}
        subHeading={"Expenses (monthly)"}
        no_margin_left={true}
      >
        <div className={styles.width_100}>
          {this.renderAdditionalFields(config, answers, "expenses")}
          {this.renderTotalField(answers, "total_expenses")}
          {this.renderTotalField(answers, "surplus")}
        </div>
      </ReviewContainer>,
    );

    return (
      <ReviewContainer
        key={"income_expenses"}
        css_class={"block_noborder"}
        subHeading={"Income & Expenses"}
        collapsible={true}
        content_class="content_wide"
      >
        <div className={styles.width_100}>{contents}</div>
      </ReviewContainer>
    );
  }

  render() {
    const { data } = this.props;
    const addonAnswer = data.addon_answers.find(
      answer => answer.attributes.addon_module_name === moduleName,
    ) || { attributes: {} };
    const answers = addonAnswer.attributes.answers || {};
    const addonRule = data.addon_rules.find(
      rule => rule.attributes.addon_module_name === moduleName,
    );
    const addonVersion = Object.prototype.hasOwnProperty.call(
      addonRule.attributes.addon_version,
      "data",
    )
      ? addonRule.attributes.addon_version.data.attributes
      : addonRule.attributes.addon_version;

    const contents = [];

    if (addonVersion.config.mandatory_fields.includes("assets_liabilities")) {
      contents.push(this.renderAssetsLiabilities(addonVersion, answers));
    }

    if (addonVersion.config.mandatory_fields.includes("income_expenses")) {
      contents.push(this.renderIncomeExpenses(addonVersion, answers));
    }

    if (addonVersion.config.mandatory_fields.includes("file")) {
      contents.push(this.renderFile(addonAnswer));
    }

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        {contents}
      </section>
    );
  }
}

const moduleName = "financials";

module.exports = connect(state => {
  return {
    addonAnswerFileUpdating: state.applications.addonAnswerFileUpdating,
  };
})(ReviewFinancials);
