import isBlank from "utils/isBlank";

interface ICardholderAttributes {
  firstName: string;
  lastName: string;
  middleName: string | null | undefined;
  email: string;
}

const sanitizeAttribute = (attribute: string | null | undefined): string => {
  if (isBlank(attribute)) {
    return "";
  }

  return (attribute as string).toLowerCase().trim();
};

const isEqual = (
  attributeA: string | null | undefined,
  attributeB: string | null | undefined,
): boolean => sanitizeAttribute(attributeA) === sanitizeAttribute(attributeB);

const areCardholdersEqual = (
  cardholderA: ICardholderAttributes,
  cardholderB: ICardholderAttributes,
): boolean =>
  isEqual(cardholderA.firstName, cardholderB.firstName) &&
  isEqual(cardholderA.lastName, cardholderB.lastName) &&
  isEqual(cardholderA.middleName, cardholderB.middleName) &&
  isEqual(cardholderA.email, cardholderB.email);

export default areCardholdersEqual;
