/* Import components */
import CloseButton from "modules/shared/components/inputs/CloseButton";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { setNewLeadFields, setNewLeadValues } from "../actions";
import CreateLeadBusinessDetails from "./CreateLeadBusinessDetails";
import CreateLeadLegalType from "./CreateLeadLegalType";
/* Import CSS */
import styles from "./css/CreateLead.css";

function CreateLead(props) {
  const { dismissHandler, dispatch, editableLead } = props;

  useEffect(() => {
    if (editableLead) {
      dispatch(
        setNewLeadValues({
          business_number: editableLead.businessNumber,
          company_name: editableLead.companyName,
          company_number: editableLead.companyNumber,
          contact_phone_number: editableLead.contactPhoneNumber,
          delivery_instruction: editableLead.deliveryInstruction,
          id: editableLead.id,
          internal_field1: editableLead.internalField1,
          internal_field2: editableLead.internalField2,
          legal_type: editableLead.consumerLegalType,
          internal_field3: editableLead.internalField3,
          physical_address: {
            api_id: editableLead.apiId,
            full_address: editableLead.fullAddress,
            api_provider: editableLead.apiProvider,
            raw_data: editableLead.rawData,
          },
          internal_field4: editableLead.internalField4,
          region: editableLead.consumerRegion,
          key_contact_email: editableLead.consumerContactEmail,
          trading_name: editableLead.tradingName,
          key_contact_first_name: editableLead.keyContactFirstName,
          key_contact_last_name: editableLead.keyContactLastName,
        }),
      );
    }
  }, []);

  function setRequiredFieldList(region, legal_type) {
    if (legal_type && region) {
      dispatch(
        setNewLeadFields(required_fields_lookup[`${region}-${legal_type}`]),
      );
    }
  }

  return (
    <div className={styles.modal}>
      <div className={styles.mobile_close_button}>
        <CloseButton
          text="Close"
          css_class={"mandatory_button_close"}
          handleClick={dismissHandler}
        />
      </div>
      <div className={styles.fade}>
        <div className={styles.content}>
          <div className={styles.container}>
            {/* <CreateLeadLegalType
              editableLead={editableLead}
              setRequiredFields={setRequiredFieldList}
            /> */}
            <CreateLeadBusinessDetails
              editableLead={editableLead}
              dismissHandler={dismissHandler}
              setRequiredFields={setRequiredFieldList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const region_field = ["region"];
const other_fields = [
  "trading_name",
  "key_contact_first_name",
  "key_contact_last_name",
  "physical_address",
  "delivery_instruction",
  "contact_phone_number",
  "key_contact_email",
];

const internal_fields = [
  "internal_field1",
  "internal_field2",
  "internal_field3",
  "internal_field4",
];

const company_basical_fields = region_field
  .concat(["company_name"])
  .concat(other_fields)
  .concat(internal_fields);
const nz_basical_fields = region_field
  .concat(other_fields)
  .concat(internal_fields);
const au_basical_fields = region_field
  .concat(other_fields)
  .concat(["business_number"])
  .concat(internal_fields);

const required_fields_lookup = {
  "AU-company": company_basical_fields,
  "AU-other": au_basical_fields,
  "AU-partnership": au_basical_fields,
  "AU-sole_trader": au_basical_fields,
  "AU-trust": au_basical_fields,
  "NZ-company": company_basical_fields,
  "NZ-other": nz_basical_fields,
  "NZ-partnership": nz_basical_fields,
  "NZ-sole_trader": nz_basical_fields,
  "NZ-trust": nz_basical_fields,
};

export default connect((state, ownProps) => {
  return {};
})(CreateLead);
