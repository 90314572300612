import React, { ReactElement } from 'react'
import { Actions, Wrapper } from './styles';

interface Props {
  actions: React.ReactNode,
  children: React.ReactNode,
}

function Header({
  actions,
  children,
}: Props): ReactElement {
  return (
    <Wrapper>
      {children}
      {
        actions && (
          <Actions>
            {actions}
          </Actions>
        )
      }
    </Wrapper>
  )
}

export default Header
