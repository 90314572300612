/* Import libraries */
import React from "react";

/* Import Styles */
import styles from "./css/InfoBox.css";

var InfoBox = createClass({
  render: function() {
    const { title, value, color, prefix } = this.props;

    var box_style = styles.box;
    var title_style = styles.title;
    var value_style = styles.value;

    if (color) {
      box_style = styles["box_" + color];
      title_style = styles["title_" + color];
    }

    var value_string = value;
    if (prefix) {
      var value_string = prefix + value;
    }

    return (
      <div className={box_style}>
        <div className={title_style}>{title}</div>
        <div className={value_style}>{value_string}</div>
      </div>
    );
  },
});

export default InfoBox;
