import {
  useBranchesState,
  useEntityUsersState,
  useSelectedUserState,
} from "modules/new-applications/hooks/useBranchUserStates";
import GridContent from "modules/shared/components/containers/GridContent";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";
import React, { Fragment, useEffect, useState } from "react";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

function useSelectedBranchState(props) {
  const { branchId, branches } = props;

  const [selectedBranchId, setSelectedBranchId] = useState(branchId);
  const selectedOption =
    branches.find(branch => branch.value === selectedBranchId) || {};

  return { selectedBranchId, selectedOption, setSelectedBranchId };
}

function BranchDropdown(props) {
  const {
    branches,
    error,
    isVisible,
    onSelectBranch,
    selectedBranchId,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div className="column is-6">
      <OptionsDropdown
        id="branch"
        error={error}
        name="branchId"
        value={selectedBranchId}
        label="Reallocate to"
        options={branches}
        handleChange={onSelectBranch}
      />
    </div>
  );
}

function Form(props) {
  const {
    assignKeyContact,
    assignKeySupplier,
    branches,
    currentUser,
    users,
    onFetchUsers,
  } = props;

  const {
    selectedBranchId,
    selectedOption: selectedBranchOption,
    setSelectedBranchId,
  } = useSelectedBranchState({ branches });

  const {
    selectedOption: selectedUserOption,
    selectedUserId,
    setSelectedUserId,
  } = useSelectedUserState({ users });

  const defaultValues = { userId: selectedUserId };
  if (isPresent(branches)) {
    defaultValues["branchId"] = selectedBranchId;
  }

  const isReallocateFieldVisible =
    isPresent(branches) && !currentUser.isStandard;
  const isDisabled =
    isBlank(users) || currentUser.isStandard;

  const onSelectBranch = selection => {
    const selectedValue = (selection || {}).value;

    setSelectedBranchId(selectedValue);
    assignKeySupplier(selection)
    onFetchUsers(selectedValue);
  };

  const onSelectUser = selection => {
    const selectedValue = selection || {};

    setSelectedUserId(selectedValue);
    assignKeyContact(selectedValue);
  };

  const hasUsers = users.length > 0;

  return (
    <GridContent className="my-4" gridColumnTemplate="two_thirds">
      <form className="columns">
        <BranchDropdown
          branches={branches}
          isVisible={isReallocateFieldVisible}
          onSelectBranch={onSelectBranch}
          selectedBranchOption={selectedBranchOption}
          selectedBranchId={selectedBranchId}
          error={!hasUsers && "There is no user under this branch."}
        />
        {
          hasUsers && (
            <div className="column is-6">
              <OptionsDropdown
                id="users"
                name="userId"
                value={selectedUserOption}
                label="Reassign to"
                options={users}
                disabled={isDisabled}
                handleChange={onSelectUser}
              />
            </div>
          )
        }
      </form>
    </GridContent>
  );
}

export default function useBulkReassignForm(props) {
  const { supplierId } = props;
  const { formattedBranches, onFetchBranches } = useBranchesState(props);
  const { formattedUsers, onFetchUsers } = useEntityUsersState(props);

  useEffect(() => {
    onFetchBranches();
  }, []);

  useEffect(() => {
    onFetchUsers(supplierId);
  }, [supplierId]);

  return {
    branches: formattedBranches,
    component: <Fragment>
      <Form
        branches={formattedBranches}
        onFetchUsers={onFetchUsers}
        {...props}
        users={formattedUsers}
      />
    </Fragment>,
  };
}
