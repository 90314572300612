import api from "api";
import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import React, { Fragment, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";

import Button from "../shared/components/inputs/Button";
import Input from "../shared/components/inputs/Input/Input";
import TextAreaBulma from "../shared/components/inputs/TextareaBulma/TextAreaBulma";
import formSchema from "./formSchema";
import { Form, Logo } from "./styles";
import Success from "./Success/Success";

const ShareTheLoveForm = ({
  tradingName,
  distinctId,
  entityId,
  fullName,
  source,
  userIsLoggedIn,
}) => {
  const [userData] = useState({ sender_business_name: tradingName, sender_name: fullName });
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const genericErrorText = "Ops! Failed to send email. Please try again and contact us if the problem persists."

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: formSchema,
  });

  function getRecipientError() {
    const { recipient_emails } = errors;

    if (!recipient_emails) {
      return;
    }

    return recipient_emails.map(({ message }) => (
      <p key={message} className="help is-danger">{message}</p>
    ))
  }

  const submitHandler = async data => {
    const referralsAPI = api("referrals");
    const recaptchaAPI = api("recaptcha");

    setSending(true);

    // Set user data in state to prepopulate it later when resend
    Object.keys(userData).forEach(attribute => {
      if (userData[attribute]) {
        return;
      }

      userData[attribute] = data[attribute];
    })

    try {
      const responseToken = await executeRecaptcha("share_the_love");

      if (!responseToken) {
        setError(genericErrorText);
        throw new Error("Error getting token");
      }

      const recaptchaResponse = await recaptchaAPI.verify(responseToken);
      const { success, error } = recaptchaResponse.data;

      if (!success || !!error) {
        setError(genericErrorText);
        throw new Error("Captcha not passed");
      }

      let processedData = data;
      let { message } = processedData;
      message = message.replace(/(<([^>]+)>)/gi, "");
      message = message.replace(/(\r\n|\r|\n)/g, "<br>");
      processedData = { ...processedData, message };

      const response = await referralsAPI.create(processedData);

      if (response.data.error_message) {
        setError(genericErrorText);
        throw new Error("Referral email error");
      }

      if (distinctId) {
        mixpanel.track("Share the love - Sent", {
          "Entity ID": entityId,
          distinct_id: distinctId,
        });
      }
      setSent(true);
      setSending(false);
    } catch (e) {
      setSending(false);
      setError(genericErrorText);
    }
  };

  if (sent) {
    return (
      <Success
        setSent={setSent}
        userIsLoggedIn={userIsLoggedIn}
      />
    );
  }

  return (
    <Fragment>
      <Logo />
      { error &&
        (
          <div className="notification is-danger is-light">
            <button
              className="delete"
              onClick={() => setError(null)}
            />
            {error}
          </div>
        )
      }
      <Form
        disableThemeOverride
        onSubmit={handleSubmit(submitHandler)}
      >
        <input
          type="hidden"
          name="source"
          value={source}
          ref={register}
        />

        <div className="field">
          <div className="field-body">
            <Input
              defaultValue={userData.sender_name}
              disabled={sending}
              error={get(errors, "sender_name.message")}
              inputRef={register}
              placeholder="Full name *"
              name="sender_name"
              required
              type="text"
            />
            <Input
              defaultValue={userData.sender_business_name}
              error={get(errors, "sender_business_name.message")}
              disabled={sending}
              inputRef={register}
              placeholder="Business name *"
              name="sender_business_name"
              required
              type="text"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <TextAreaBulma
              customError={getRecipientError()}
              disabled={sending}
              placeholder="Enter your friends' emails, separated each entry by comma *"
              name="recipient_emails"
              required
              inputRef={register}
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <TextAreaBulma
              defaultValue={
                `Hi,

We’re using a fantastic customer onboarding tool that streamlines the onboarding of trade customers. I strongly recommend that you check it out.

They are generally 10x faster than current systems and you’ll see an increase of at least 50% traffic immediately. It also tracks NPS for all users and has exceptionally high ratings.

They know I’m referring you and will follow up, but I know you’ll really benefit.`}
              disabled={sending}
              error={get(errors, "message.message")}
              name="message"
              placeholder="Enter your message *"
              required
              inputRef={register}
              rows={15}
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <Button
              type="submit"
              text="Send"
              disabled={sending}
              handleClick={null}
              disableOnLoading={true}
              loading_text="Sending"
              loading={sending}
            />
          </div>
        </div>
      </Form>
    </Fragment>
  )
}

export default ShareTheLoveForm;
