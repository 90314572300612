import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { isCompany } from "./helpers/reportHelpers";
import styles from "../../../css/CommonReviewBusiness.css";
import { valueByKey } from "./helpers/summary";

var AboutTheScore = createClass({
  _companySection1() {
    return (
      <div>
        <p>
          Bureau scores use available bureau data to calculate a risk estimate.
          The primary purpose of this score is to predict the likelihood of a
          future adverse
        </p>
        <p>
          event being recorded on the company's bureau file or that the company
          will go into receivership or external administration in the next 12
          months. This
        </p>
        <p>
          bureau score incorporates information available on the company's
          bureau file as well as information available on the bureau files of
          the company
        </p>
        <p>
          directors, whose bureau files forms part of this report. Where you do
          not have consent to see the consumer credit file of all directors, the
          score uses
        </p>
        <p>
          only commercial &amp; public record information on all directors'
          files.
        </p>
        <p>
          Please note that trade payment information is not used in the
          calculation of this score.
        </p>
      </div>
    );
  },

  _businessSection1() {
    return (
      <div>
        <p>
          Bureau scores use available bureau data to calculate a risk estimate.
          The primary purpose of this score is to predict the likelihood of a
          future adverse
        </p>
        <p>
          event being recorded on the Business' bureau file in the next 12
          months. This bureau score incorporates information available on the
          business' bureau
        </p>
        <p>
          file as well as information available on the bureau files of the
          proprietors (companies and/or individuals) whose bureau files form
          part of this report.
        </p>
        <p>
          Please note that trade payment information is not used in the
          calculation of this score.
        </p>
      </div>
    );
  },

  _section1() {
    const { entityType } = this.props;

    if (isCompany(entityType)) return this._companySection1();

    return this._businessSection1();
  },

  _companySection2() {
    return (
      <div>
        <p>
          <sup>1</sup>Probability of Adverse - is the probability expressed as a
          percentage that an adverse event (e.g. an external administration,
          petition to wind up, court
        </p>
        <p>
          judgement, court writ, payment default, or mercantile enquiry) will be
          lodged on the bureau file of the company within the next 12 months.
        </p>
      </div>
    );
  },

  _businessSection2() {
    return (
      <div>
        <p>
          <sup>1</sup>Probability of Adverse - is the probability expressed as a
          percentage that an adverse event (e.g. court judgement, court writ,
          payment default, or
        </p>
        <p>
          mercantile enquiry) will be lodged on the bureau file of the company
          within the next 12 months or that an adverse event (e.g. an external
          administration,
        </p>
        <p>
          petition to wind up, court judgement, court writ, payment default, or
          mercantile enquiry) will be lodged on the bureau file of the company
          proprietor
        </p>
        <p>
          within the next 12 months or that an adverse event (e.g. insolvency,
          court judgement, court writ, commercial payment default, consumer
          payment
        </p>
        <p>
          default or mercantile enquiry) will be lodged on the bureau file of
          the individual proprietor within the next 12 months.
        </p>
      </div>
    );
  },

  _section2() {
    const { entityType } = this.props;

    if (isCompany(entityType)) return this._companySection2();

    return this._businessSection2();
  },

  _section3() {
    return (
      <p>
        <sup>2</sup>Key contributing factors - are the factors that contribute
        most in either a positive or a negative way to the overall score.
      </p>
    );
  },

  _disclaimer() {
    return (
      <div>
        <p>
          DISCLAIMER: The score is a statistical rank ordering tool only and as
          such should always be used in conjunction with your organisation's
          credit policies
        </p>
        <p>
          and procedures and other relevant information you may have about the
          company or business. The score should not be and is not intended to be
          the
        </p>
        <p>
          sole basis for making a decision about whether or not to deal with a
          particular individual, company or business.
        </p>
      </div>
    );
  },

  render() {
    const { isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="About the Score"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        {this._section1()}
        <hr />
        {this._section2()}
        <hr />
        {this._section3()}
        <hr />
        {this._disclaimer()}
        <hr />
      </ReviewContainer>
    );
  },
});

export default AboutTheScore;
