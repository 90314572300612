import { connect } from "react-redux";
import Notes from "./Notes";
/* Import actions */
import { addNote, getNotes } from "../actions";

module.exports = connect((state, ownProps) => {
  return {
    notes: state.connections.all_notes_list,
    termMemberList: state.connections.team_members_list,
    loading: state.connections.note_updating,
    addNote,
    getNotes,
  };
})(Notes);
