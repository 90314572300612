import React, { Component } from "react";

import styles from "modules/shared/components/text/css/StandardTermsAndConditions.css";

export default class AgreementSection7 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>7.</span> IP Ownership and Data
            Use
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>7.1</span>{" "}
          <strong>Property rights</strong>: All Intellectual Property rights and
          all other proprietary rights that may subsist in the Service, Platform
          and any underlying systems, software, or processes, and all
          Confidential Information relevant to the Service, Platform or the
          system or business processes used to provide the Service (including
          all improvements made by any person thereto) (together, the ‘
          <strong>IP Rights</strong>’) belong solely to 1Centre or its licensors
          (as the case may be). The Supplier agrees not to challenge 1Centre’s
          claim to ownership of the IP Rights or to permit any act that is
          inconsistent with 1Centre’s ownership of the IP Rights at any time,
          including after termination or expiry of these Terms.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>7.2</span>{" "}
          <strong>New Intellectual Property</strong>: All Intellectual Property
          and other proprietary rights in works created by or on behalf of
          1Centre in its provision of the Service (such as documents, code or
          specifications) and in intangibles (such as ideas, know how, designs
          and trade secrets) will be owned exclusively by 1Centre.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>7.3</span>{" "}
          <strong>Data Use</strong>: Notwithstanding any other provision of
          these Terms, including clause 8 (Confidential Information), 1Centre
          may use for its business purposes:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> all information
          provided by Business Customers through or in relation to the Platform
          (including in the Trade Account Application) for the purposes of
          providing services to other suppliers through the Platform; and
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> any aggregated or
          statistical data derived from the Service or the operation of the
          Service that have been rendered anonymous for any purpose.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>7.4</span>{" "}
          <strong>Business Customer’s Profiles</strong> The Supplier warrants
          that all information it provides to 1Centre is current, complete and
          accurate, and the Supplier agrees to maintain and update the
          information as required to keep it so. Information provided about a
          Supplier may be used and published by 1 Centre as permitted by these
          Terms and by law. Notwithstanding any termination of this agreement,
          1Centre may keep the profile of, and information provided by, a
          Business Customer introduced by or otherwise related to the Supplier
          on the Platform after such termination.
        </p>
      </div>
    );
  }
}
