export function getDevice(usr_ag) {
  let device = "Desktop";

  if (!usr_ag) usr_ag = navigator.userAgent;

  if (usr_ag.match(/Android/i)) {
    device = "Android";
  } else if (usr_ag.match(/BlackBerry/i)) {
    device = "BlackBerry";
  } else if (usr_ag.match(/iPhone|iPad|iPod/i)) {
    device = "iOS";
  } else if (usr_ag.match(/Opera Mini/i)) {
    device = "Opera";
  } else if (usr_ag.match(/IEMobile/i)) {
    device = "Mobile Windows";
  }

  return device;
}
