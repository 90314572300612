import React from "react";
import isPresent from "utils/isPresent";
import AMLCheckOutcome from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v1/AMLCheckOutcome";
import IdentificationCheckOutcome from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v1/IdentificationCheckOutcome";
import VedaCheckOutcome from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v1/VedaCheckOutcome";
import ManualCheckOutcome from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v1/ManualCheckOutcome";

const V1 = props => {
  const {
    application,
    identificationCheck,
    isErrorsVisible,
    signature,
  } = props;

  if (isPresent(identificationCheck)) {
    if (identificationCheck.isAML) {
      return (
        <AMLCheckOutcome
          identificationCheck={identificationCheck}
          isErrorsVisible={isErrorsVisible}
          {...props}
        />
      );
    }

    if (identificationCheck.isIdentification) {
      return (
        <IdentificationCheckOutcome
          identificationCheck={identificationCheck}
          isErrorsVisible={isErrorsVisible}
          {...props}
        />
      );
    }

    if (identificationCheck.modelType.includes("veda_check")) {
      return (
        <VedaCheckOutcome
          identificationCheck={identificationCheck}
          isErrorsVisible={isErrorsVisible}
          {...props}
        />
      );
    }
  }

  if (application.isIdentificationCheckRequired) {
    return <ManualCheckOutcome signature={signature} />;
  }

  return null;
};

export default V1;
