export const STATE_LOAD_STATE_START = "STATE_LOAD_STATE_START";
export const STATE_LOAD_STATE_SUCCESS = "STATE_LOAD_STATE_SUCCESS";
export const STATE_LOAD_STATE_ERROR = "STATE_LOAD_STATE_ERROR";

export const STATE_SAVE_STATE_START = "STATE_SAVE_STATE_START";
export const STATE_SAVE_STATE_ERROR = "STATE_SAVE_STATE_ERROR";

export const STATE_CREATE_STATE_START = "STATE_CREATE_STATE_START";
export const STATE_CREATE_STATE_SUCCESS = "STATE_CREATE_STATE_SUCCESS";
export const STATE_CREATE_STATE_ERROR = "STATE_CREATE_STATE_ERROR";

export const STATE_UPDATE_STATE_START = "STATE_UPDATE_STATE_START";
export const STATE_UPDATE_STATE_SUCCESS = "STATE_UPDATE_STATE_SUCCESS";
export const STATE_UPDATE_STATE_ERROR = "STATE_UPDATE_STATE_ERROR";
