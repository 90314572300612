import Card from "modules/shared/components/widgets/static/Card";
import React from "react";

import { SAMPLE_PAYLOAD } from "../constants/alert_notification.js";
import { stringifyJSON } from "../containers/WebhookDocumentationContainer";
import WebhookCodeBlock from "./WebhookCodeBlock";

export default function WebhookCashDocumentation(props) {
  const { schemaDefinitions } = props;

  return (
    <div>
      <Card>
        This page contains the schema definition and samples of 1Centre&apos;s
        alert noticication webhook payload.
      </Card>

      <WebhookCodeBlock
        id="trust"
        key="trust"
        label="Trust"
        schema={stringifyJSON(schemaDefinitions.alert_notification_definition)}
        payload={SAMPLE_PAYLOAD}
        withMargin={false}
      />
    </div>
  );
}
