import humps from "humps";
import isBlank from "utils/isBlank";

// Transform the response from underscored keys (Rails convention) to
// camelized keys (JS convention)
export function transformResponse(data) {
  if (isBlank(data)) {
    return {};
  }

  return humps.camelizeKeys(JSON.parse(data));
}

// Transform the request from camelized keys (JS convention) to
// underscored keys (Rails convention)
export function transformRequest(data) {
  const request = humps.decamelizeKeys(data);
  return JSON.stringify(request);
}
