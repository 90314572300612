import NumberTextBox from "modules/shared/components/widgets/interactive/NumberTextBox";
import React from "react";
import { connect } from "react-redux";

import styles from "../../components/css/AddOnsDetails.css";

class LimitRange extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  updateMinValue(context, event) {
    const { handleChange } = this.props;
    handleChange("min_credit_value", event.target.value);
  }

  updateMaxValue(context, event) {
    const { handleChange } = this.props;
    handleChange("max_credit_value", event.target.value);
  }

  render() {
    const {
      addOn,
      error,
      readOnly,
      title,
      defaults,
      currentEntity,
      moduleName,
    } = this.props;

    return (
      <div className={styles.section}>
        <h3>{title || defaults.title}</h3>
        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.info}>
          The {moduleName} module will only display in the customer application flow where
          the applicant applies for credit between the min - max range you select here.
        </div>
        <div className={styles.inputs_container}>
          <NumberTextBox
            value={parseInt(addOn.min_credit_value)}
            previewMessage=""
            prefix={"$"}
            disabled={readOnly}
            handleChange={(context, event) => {
              this.updateMinValue(context, event);
            }}
            error_msgs={{ min_reached: "Min credit limit is: " }}
            hide_preview={true}
            inline_style={true}
          />
          <span className={styles.limit_hyphen}>-</span>
          <NumberTextBox
            value={parseInt(addOn.max_credit_value)}
            max={currentEntity.trade_account_limit}
            previewMessage=""
            prefix={"$"}
            disabled={readOnly}
            handleChange={(context, event) => {
              this.updateMaxValue(context, event);
            }}
            error_msgs={{ max_reached: "Max credit limit is: " }}
            hide_preview={true}
            inline_style={true}
          />
        </div>
      </div>
    );
  }
}

const defaults = { title: "Limit Range" };

export default connect(state => {
  return {
    currentEntity: state.current_user.current_entity.attributes,
    defaults,
  };
})(LimitRange);
