import logo from "images/1centre-blue.png";
import { Header, Logo, TopBar } from "modules/webhooks/v2/containers/styles";
import React, { Fragment, ReactElement } from "react";
import Helmet from "react-helmet";

const V2Container = (props): ReactElement => (
  <Fragment>
    <TopBar>
      <Helmet title="Webhook V2 Documentation" />
      <Logo src={logo} alt="1Centre" />
    </TopBar>

    <div className="container">
      <Header>Version 2 Webhook Documentation</Header>
      {props.children}
    </div>
  </Fragment>
);

export default V2Container;
