import styled from "styled-components";
import { FONT_SETS, FONT_SIZES } from "variables/typography";

export const Wrapper = styled.div`
  margin-bottom: 3rem;
`;

export const Header = styled.h1`
  ${FONT_SETS.pageHeader};
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

export const Description = styled.div`
  ${FONT_SETS.description};
`;
