import React, { Component } from "react";

import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import commonStyles from "../css/payment-predictor/PaymentPredictorComponent.css";
import { displayEmpty } from "utils/formatting";
import { formatDateFromObject } from "utils/dateFormatter";
import objectToArray from "utils/objectToArray";
import styles from "../css/payment-predictor/RegisteredCharges.css";

export default class RegisteredCharges extends Component {
  get registeredCharges() {
    return objectToArray(
      (this.props.response.registered_charges || {}).registered_charge,
    );
  }

  renderRegisteredCharges() {
    const charges = this.registeredCharges.map((charge, i) =>
      this.renderRegisteredCharge(charge, i),
    );

    if (charges.length === 0) {
      return <p>There are no registered charges for this entity.</p>;
    }

    return charges;
  }

  renderRegisteredCharge(charge, i) {
    const securedParty = (charge.secured_parties || {}).secured_party || {};

    return (
      <table
        key={`charge-${i}`}
        className={`${commonStyles.table} ${styles.registered_charges_table}`}
      >
        <tbody>
          <tr>
            <td>ASIC Charge Number</td>
            <td className={commonStyles.highlight_cell}>{charge.file_num}</td>
            <td>Charge Status</td>
            <td className={commonStyles.highlight_cell}>{charge.status}</td>
          </tr>
          <tr>
            <td>Date registered</td>
            <td className={commonStyles.highlight_cell}>
              {formatDateFromObject(charge.date_registered)}
            </td>
            <td>Time registered</td>
            <td className={commonStyles.highlight_cell}>00:01:00</td>
          </tr>
          <tr>
            <td>Date created</td>
            <td className={commonStyles.highlight_cell}>
              {formatDateFromObject(charge.date_created)}
            </td>
            <td>Expiry date</td>
            <td className={commonStyles.highlight_cell}>-</td>
          </tr>
          <tr>
            <td>Pre-ASIC Charge Number</td>
            <td className={commonStyles.highlight_cell}></td>
            <td>Charge Type</td>
            <td className={commonStyles.highlight_cell}>
              {displayEmpty(charge.type)}
            </td>
          </tr>
          <tr>
            <td colSpan={4}>Chargee Details</td>
          </tr>
          <tr>
            <td colSpan={2}>Chargee organisation name</td>
            <td colSpan={2}>{displayEmpty(securedParty.organisation_name)}</td>
          </tr>
          <tr>
            <td colSpan={2}>Chargee organisation number</td>
            <td colSpan={2}>{displayEmpty(securedParty.company_num)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderNotes() {
    return (
      <div>
        <div>Note</div>
        <div>
          The details of current charges will only be available from the PPS
          Register and the details of satisfied charges (as at 30th January
          2012) can be obtained from ASIC.
        </div>
      </div>
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Registered Charges"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        {this.renderRegisteredCharges()}
        {this.renderNotes()}
      </ReviewContainer>
    );
  }
}
