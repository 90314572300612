import useBodyScroll from "hooks/useBodyScroll";
import Details from "modules/card-management-onboarding/components/Details";
import IUFEdit from "modules/new-applications/components/application-sections/IUF/IUFEdit";
/* Import components */
import CloseButton from "modules/shared/components/inputs/CloseButton";
import SupplierFormRender from "modules/shared/components/widgets/interactive/form_builder/SupplierFormRender";
/* Import libraries */
import React from "react";
import { connect } from "react-redux";

import AdditionalDetails from "../../../consumer-onboarding/components/additional/AdditionalDetails";
import CardsDetails from "../../../consumer-onboarding/components/cards/CardsDetails";
import FinancialsDetails from "../../../consumer-onboarding/components/financials/FinancialsDetails";
import PaperlessDetails from "../../../consumer-onboarding/components/paperless/PaperlessDetails";
import styles from "./css/PreviewModal.css";
import BCFlow from "./Layout/BCFlow";
import VirtualCreditFile from "./Layout/VirtualCreditFile";

function PreviewModal(props) {
  const {
    config,
    dismissHandler,
    moduleName,
  } = props;

  useBodyScroll();

  const activeModule = config.modules[moduleName];
  const activeLayout = activeModule.layout ? activeModule.layout : "bcFlow";

  const layout = {
    bcFlow: BCFlow,
    vcf: VirtualCreditFile,
  };

  const cssClass = activeLayout === "vcf" ?
    "button_position_top20x20" :
    "preview_button_close";

  const Content = layout[activeLayout];

  return (
    <div className={styles.modal}>
      <CloseButton
        handleClick={dismissHandler}
        css_class={cssClass}
      />
      <Content
        {...props}
        activeModule={activeModule}
      />
    </div>
  );
}

export default connect((state, ownProps) => {
  const currentEntity = state.current_user.current_entity.attributes;

  const config = {
    title: "Application",
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    modules: {
      cards: {
        title: "Cards",
        childComponent: <CardsDetails previewAddon={ownProps.previewAddon} />,
        style: {
          marginTop: "12rem",
        },
      },
      financials: {
        title: "Financials",
        childComponent: (
          <FinancialsDetails previewAddon={ownProps.previewAddon} />
        ),
      },
      additional: {
        title: "Additional",
        childComponent: (
          <AdditionalDetails previewAddon={ownProps.previewAddon} />
        ),
        validationFlagsKey: "additional",
      },
      paperless: {
        title: "Direct debit",
        childComponent: (
          <PaperlessDetails
            previewAddon={ownProps.previewAddon}
            region={currentEntity.region}
          />
        ),
      },
      card_management_module: {
        title: "Card management",
        childComponent: <Details previewAddon={ownProps.previewAddon} />,
        hideProgress: true,
        hideOnboardingButtons: true,
        overridePreviewTitle: true,
      },
      internal_use_fields: {
        title: "Internal Use Fields",
        childComponent: (
          <IUFEdit
            owner={ownProps.previewAddon.application}
            isCompany
          />
        ),
        layout: "vcf",
      },
    },
    /* eslint-enable sort-keys-fix/sort-keys-fix */
    sections: {
      business: { title: "Details" },
      extras: { title: "Questions" },
      identity: { title: "Applicant" },
      internal_use_fields: { title: "Internal Use Fields" },
      section: { title: "section" },
      "trade-reference": { title: "Checks" },
    },
  };

  return {
    config,
    currentEntity,
    sharedData: state.shared_data,
    totalPage: ownProps.totalPage || 1,
  };
})(PreviewModal);
