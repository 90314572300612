import { useState } from 'react'

function useParamsCompare(params: any): any {
  const [oldParams, setOldParams] = useState(params);

  return {
    oldParams,
    setOldParams,
  }
}

export default useParamsCompare
