import {
  COB_TRADE_REFERENCE_SET_REFEREE_DATA,
  COB_TRADE_REFERENCE_PRESET_REFEREE_DATA,
  COB_TRADE_REFERENCE_SET_REFEREE_DETAILS_INDEX,
  COB_TRADE_REFERENCE_SECTION_COMPLETE,
  COB_TRADE_REFERENCE_COMPONENT_COMPLETE,
} from "../constants/trade-reference";

import { STATE_LOAD_STATE_SUCCESS } from "../../state/constants.js";

import { COB_TRADEACCOUNT_CLEAR_DATA } from "../constants/section";

const traderef_defaults = {
  referees: [],
  section_complete: false,
  section_data: {},
  completed: {},
  refrees_details_container_index: 0,
  origin_referees: [],
};

export function consumerOnboardingTraderefReducer(
  state = traderef_defaults,
  action,
) {
  switch (action.type) {
    case COB_TRADEACCOUNT_CLEAR_DATA:
      return { ...traderef_defaults };
    case COB_TRADE_REFERENCE_SET_REFEREE_DATA:
      var referees = state.referees.slice(0);
      var new_referee = Object.assign({}, state.referees[action.payload.index]);

      new_referee[action.payload.field] = action.payload.value;
      referees[action.payload.index] = new_referee;

      return { ...state, referees: referees };

    case COB_TRADE_REFERENCE_PRESET_REFEREE_DATA:
      return {
        ...state,
        referees: action.payload,
        origin_referees: action.payload,
      };

    case COB_TRADE_REFERENCE_SET_REFEREE_DETAILS_INDEX:
      return { ...state, refrees_details_container_index: action.payload };

    case COB_TRADE_REFERENCE_COMPONENT_COMPLETE:
      var component_state = new Object();
      component_state[action.payload.component] = action.payload.state;
      return {
        ...state,
        completed: { ...state.completed, ...component_state },
      };

    case COB_TRADE_REFERENCE_SECTION_COMPLETE:
      return {
        ...state,
        section_data: action.payload.data,
        section_complete: action.payload.complete,
      };

    case STATE_LOAD_STATE_SUCCESS:
      if (action.payload["cob_traderef"]) {
        return { ...state, ...action.payload.cob_traderef };
      }

    default:
      return { ...state };
  }
}
