import api from "../../api";

import {
  TRADE_REFERENCES_LOAD_APPLICATIONS_START,
  TRADE_REFERENCES_LOAD_APPLICATIONS_SUCCESS,
  TRADE_REFERENCES_LOAD_APPLICATIONS_ERROR,
  TRADE_REFERENCES_CREATE_CHECK_START,
  TRADE_REFERENCES_CREATE_CHECK_SUCCESS,
  TRADE_REFERENCES_CREATE_CHECK_ERROR,
  TRADE_REFERENCES_UPDATE_CHECK_START,
  TRADE_REFERENCES_UPDATE_CHECK_SUCCESS,
  TRADE_REFERENCES_UPDATE_CHECK_ERROR,
  TRADE_REFERENCES_CREATE_CHECK,
  TRADE_REFERENCES_CREATE_NOTES_START,
  TRADE_REFERENCES_CREATE_NOTES_SUCCESS,
  TRADE_REFERENCES_CREATE_NOTES_ERROR,
  TRADE_REFERENCES_UPDATE_NOTES_START,
  TRADE_REFERENCES_UPDATE_NOTES_SUCCESS,
  TRADE_REFERENCES_UPDATE_NOTES_ERROR,
  TRADE_REFERENCES_LOAD_TRADE_REFERENCE_START,
  TRADE_REFERENCES_LOAD_TRADE_REFERENCE_SUCCESS,
  TRADE_REFERENCES_LOAD_TRADE_REFERENCE_ERROR,
  TRADE_REFERENCES_CLEAR_REFERENCE,
  TRADE_REFERENCES_CLEAR_APPLICATIONS,
} from "./constants";

export function clearTrm() {
  return {
    type: TRADE_REFERENCES_CLEAR_REFERENCE,
  };
}

export function clearTrmApplications() {
  return {
    type: TRADE_REFERENCES_CLEAR_APPLICATIONS,
  };
}

export function updateTradeReferenceCheck(
  id,
  checkAttributes,
  newNotes,
  deleteNotes,
  success,
) {
  return (dispatch, getState) => {
    dispatch({
      type: TRADE_REFERENCES_UPDATE_CHECK_START,
      payload: {
        id,
        checkAttributes,
        newNotes,
        deleteNotes,
      },
      meta: {
        mixpanel: {
          event: "Update TR check",
          props: {
            distinct_id: getState().current_user.data.data.id,
          },
        },
      },
    });
  };
}

export function createTradeReferenceCheck(
  tradeReferenceId,
  checkAttributes,
  notes,
  success,
) {
  return (dispatch, getState) => {
    dispatch({
      type: TRADE_REFERENCES_CREATE_CHECK,
      payload: {
        tradeReferenceId,
        checkAttributes,
        notes,
      },
      meta: {
        mixpanel: {
          event: "Create TR check",
          props: {
            distinct_id: getState().current_user.data.data.id,
          },
        },
      },
    });
  };
}

export function loadTrm(id) {
  return (dispatch, getState) => {
    dispatch({
      type: TRADE_REFERENCES_LOAD_TRADE_REFERENCE_START,
    });

    var tr = api("trade_references", getState().current_user.access_token),
      data = {},
      included = [];

    tr.getTradeReference(
      id,
      result => {
        if (result.data.data) {
          data = result.data.data;
          included = result.data.included;
          if (included) {
            data.application = included.find(inc => {
              return inc.id === data.relationships.application.data.id;
            });
            data.check = included.find(inc => {
              if (data.relationships.trade_reference_check) {
                return (
                  inc.id === data.relationships.trade_reference_check.data.id
                );
              }
            });
            let supplier = included.find(inc => {
              return inc.id === data.application.relationships.supplier.data.id;
            });
            if (supplier) {
              data.application.attributes = {
                ...data.application.attributes,
                excluded_application_sources:
                  supplier.attributes.excluded_application_sources,
              };
            }
            data.consumer = included.find(inc => {
              return inc.id === data.application.relationships.consumer.data.id;
            });
            data.notes = included.filter(inc => {
              return inc.type === "notes";
            });
          }
        }

        dispatch({
          type: TRADE_REFERENCES_LOAD_TRADE_REFERENCE_SUCCESS,
          payload: data,
          meta: {
            mixpanel: {
              event: "View TR details",
              props: {
                distinct_id: getState().current_user.data.data.id,
                "Trade reference": id,
              },
            },
          },
        });
      },
      error => {
        dispatch({
          type: TRADE_REFERENCES_LOAD_TRADE_REFERENCE_ERROR,
          payload: error,
        });
      },
      {
        params: {
          include:
            "trade_reference_check,application,application.supplier,application.consumer,trade_reference_check.notes",
        },
      },
    );
  };
}

export function loadTrmApplications(params) {
  return (dispatch, getState) => {
    dispatch({
      type: TRADE_REFERENCES_LOAD_APPLICATIONS_START,
    });

    var trms = api("trm", getState().current_user.access_token, null, {
        headers: { Accept: "application/json" },
      }),
      data = [];

    trms.getTrms(
      result => {
        data = result.data || { meta: { total_count: 0 } };

        dispatch({
          type: TRADE_REFERENCES_LOAD_APPLICATIONS_SUCCESS,
          payload: data,
        });
      },
      error => {
        dispatch({
          type: TRADE_REFERENCES_LOAD_APPLICATIONS_ERROR,
          payload: error,
        });
      },
      { params: params },
    );
  };
}
