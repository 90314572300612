import styled from "styled-components";

export const Actions = styled.div`
  margin: 20px 0 0 auto;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  > *:first-child {
    margin-right: auto;
  }
`;
