/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { isMobile } from "modules/shared/helpers/mobileDetect";
import mixpanel from "mixpanel-browser";
import styles from "./css/Business.css";
import { selectEntityType, setEntityFormValue } from "../../actions/onboarding";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import CircleIconButton from "modules/shared/components/widgets/interactive/CircleIconButton";
import get from "lodash.get";

var BusinessEntityOtherType = createClass({
  /**
   * Lifecycle method used to check validity
   *
   * @param prevProps
   * @param prevState
   */
  componentWillReceiveProps: function(nextProps) {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete(nextProps.selected_type));
  },

  componentDidMount: function() {
    const {
      application,
      currentUser,
      dispatch,
      handleComplete,
      selected_type,
      entity_region,
    } = this.props;

    mixpanel.track(
      "Consumer Onboarding: Business Other Entity Type page loaded",
      {
        Application: application.id,
        Component: "BusinessEntityOtherType",
        "Entity ID": get(currentUser, "currentEntity.id"),
        distinct_id: get(currentUser, "id"),
      },
    );

    if (!entity_region) {
      dispatch(setEntityFormValue("entity_region", "NZ"));
    }
    handleComplete(this.isComplete(selected_type));
  },

  isComplete: function(entity_legal_type) {
    const { setPageValidationStartFinish } = this.props;
    if (entity_legal_type) {
      setPageValidationStartFinish();
      return true;
    }
    return false;
  },

  /**
   * Sets the selected entity state.
   */
  selectItem: function(type) {
    const { dispatch, options, application } = this.props;
    dispatch(selectEntityType(type, options[type].title));
    if (application) {
      var link = `/register/consumer/${application.id}/business/other-details`;
      browserHistory.push(link);
    }
  },

  render: function() {
    const { title, options } = this.props;

    // Create the button list
    var button_components = new Array();
    Object.keys(options).forEach((item, index) => {
      var current_button = options[item];

      button_components.push(
        <CircleIconButton
          key={index}
          value={item}
          type={item}
          text={current_button.title}
          icon={current_button.icon}
          handleClick={this.selectItem}
          small={isMobile() ? true : false}
          tip_name={current_button.title}
        />,
      );
    });

    return (
      <section className={styles.section}>
        <div className={`${styles.row} ${styles.mobile_margin}`}>
          <div className={styles.full_col}>
            <PanelTitle text={title} margin_top={isMobile() ? "-40px" : null} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.options}>{button_components}</div>
        </div>
      </section>
    );
  },
});

var defaults = {
  title: "Select your entity type",
  options: {
    education: {
      title: "Education",
      icon: "entity_x3plus",
    },
    society: {
      title: "Society",
      icon: "entity_x2",
    },
    association: {
      title: "Association",
      icon: "entity_x3",
    },
    club: {
      title: "Club",
      icon: "entity_x1",
    },
    government: {
      title: "Government",
      icon: "entity_x2",
    },
    other: {
      title: "Other",
      icon: "entity_x3",
    },
  },
};

export default connect(state => {
  let selected_type = null;

  if (state.cob_business.entity_type !== null) {
    selected_type = state.cob_business.entity_type;
  }

  return {
    currentUser: state.currentUser,
    entity_region: state.cob_business.entity_region,
    options: defaults.options,
    selected_type: selected_type,
    title: defaults.title,
  };
})(BusinessEntityOtherType);
