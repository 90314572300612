import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#users-creating-a-user
 */
let EmailValidation = function(config) {
  this.axios = axios(config);
  this.type = "user_exists_email";
};

EmailValidation.prototype.checkEmailValid = function(
  email_address,
  success,
  error,
) {
  return this.axios
    .get(`/${this.type}`, { params: { email: email_address } })
    .then(success)
    .catch(error);
};

export default EmailValidation;
