import snakeCase from "lodash.snakecase";
import isPlainObject from "lodash.isplainobject";

export default function objectKeysToSnakeCase<T>(
  object: T,
): T | { [key: string]: T } {
  if (!isPlainObject(object)) {
    return object;
  }

  const keys = Object.keys(object);
  const newObject = {};

  for (const key of keys) {
    const value = object[key];

    let newValue = value;

    if (isPlainObject(value)) {
      newValue = objectKeysToSnakeCase(value);
    }

    newObject[snakeCase(key)] = newValue;
  }

  return newObject;
}
