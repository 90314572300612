/* Import libraries */
import React from "react";

import ApprovalLevelCircle from "modules/shared/components/widgets/static/ApprovalFlowChart/ApprovalLevelCircle";
import Arrow from "modules/shared/components/widgets/static/ApprovalFlowChart/Arrow";
import { ReactElement } from "react";

type ApprovalLevels = {
  credit_limit: number,
  min_credit_limit: number,
  minimum_approvers: number,
}

type Props = {
  approval_levels: ApprovalLevels[],
  display_level: number,
  theme: string,
}

function ApprovalFlowChart({
  theme,
  display_level,
  approval_levels,
}: Props): ReactElement {
  const approval_chain: ReactElement[] = [];

  approval_levels.slice(0, display_level).forEach((approval_level, level) => {
    const {
      credit_limit,
      min_credit_limit,
      minimum_approvers,
    } = approval_level;

    approval_chain.push(
      <ApprovalLevelCircle
        key={`approval_level_circle_${level}`}
        theme={theme}
        title={`Level ${level + 1} check`}
        max_amount={credit_limit}
        min_amount={level == 0 ? min_credit_limit : approval_levels[level - 1].credit_limit}
        approvers={minimum_approvers}
      />,
    );

    if (level + 2 <= display_level) {
      approval_chain.push(
        <Arrow key={`approval_arrow_${level}`} theme={theme} />,
      );
    }
  });

  return <div>{approval_chain}</div>;
};

export default ApprovalFlowChart;
