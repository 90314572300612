import React from "react";

import Position from "./Position";

var MiddleRight = createClass({
  render: function() {
    const { children, absolute } = this.props;
    return (
      <Position absolute={absolute} position="middle_right">
        {children}
      </Position>
    );
  },
});

module.exports = MiddleRight;
