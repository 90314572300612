/* Import actions */
import {
  getCompanyDetails,
  lookupAddress,
  lookupCompanyName,
  manualSetCompany,
  selectCompanyName,
  selectEntityType,
  selectPhysicalAddress,
  selectPostalAddress,
  setCompanyDetails,
  setCompanyName,
  setDefaultEntityDetails,
  setEntityFormValue,
  setPhysicalAddress,
  setPostalAddress,
  uploadLegalEntityAgreement,
} from "../../actions/onboarding";
import moment from "moment";
import AutoSuggest from "modules/shared/components/widgets/interactive/AutoSuggest";
import BusinessCompanyExists from "./BusinessCompanyExists";
import LegalEntityAgreementUploader from "modules/legal-entity-agreement-uploader/components/LegalEntityAgreementUploader";
import React from "react";
import TextInput from "modules/shared/components/inputs/TextInput";
import { connect } from "react-redux";
import emojiRegex from "emoji-regex";
import {
  getEntityNameFieldLabel,
  getTradingNameTitleByRegion,
} from "utils/entityTypeTitle";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import styles from "./css/Business.css";
import { throttle } from "throttle-debounce";
import PageHeader from "modules/shared/components/v2/PageHeader";
import BorderedCalendarPicker from "modules/shared/components/inputs/BorderedCalendarPicker";
import { COB_ENTITY_TYPE_MISSING } from "modules/consumer-onboarding/constants/onboarding";

const MAX_YEARS = 200;

function getMinimumDate() {
  return new Date(new Date().getFullYear() - MAX_YEARS, 0, 1);
}

function convertDateComponentToDate(dateComponent) {
  if (isBlank(dateComponent)) {
    return null;
  }

  const date = new Date(
    dateComponent.year,
    dateComponent.month - 1,
    dateComponent.day,
  );

  return moment(date).format("DD/MM/YYYY");
}

var BusinessOtherDetails = createClass({
  componentWillReceiveProps: function(nextProps) {
    const { handleComplete, section } = this.props;
    handleComplete(this.isComplete(nextProps), section);
    if (nextProps.page_validation_start) {
      this.checkValidAll();
    }
  },

  checkValidAll: function() {
    const {
      form_inputs,
      form_values,
      setPageValidationStartFinish,
    } = this.props;

    Object.keys(form_inputs).forEach((value, index) => {
      let target = {
        id: value,
        value: form_values[value],
      };
      setTimeout(() => {
        this.checkValid(target);
      }, 500);
    });
    setTimeout(() => {
      this.validateLegalEntityAgreement();
    }, 500);

    // When the entity type is incorrectly set to an empty string, null, or
    // undefined, then do not allow the user to proceed
    const { dispatch, entity_type } = this.props;
    if (isBlank(entity_type)) {
      dispatch({ type: COB_ENTITY_TYPE_MISSING });
    }

    setPageValidationStartFinish();
  },

  validateLegalEntityAgreement() {
    const { entity_type, requires_trust_deed, form_values } = this.props;
    const { form_errors } = this.state;

    if (this.isLegalEntityAgreementValid()) {
      const { legal_entity_agreement, ...rest } = form_errors;

      this.setState({
        form_errors: { ...rest },
      });
    } else {
      this.setState({
        form_errors: {
          ...form_errors,
          legal_entity_agreement: "Trust deed is required.",
        },
      });
    }
  },

  isLegalEntityAgreementValid() {
    const { entity_type, requires_trust_deed, form_values } = this.props;

    if (entity_type !== "trust" || !requires_trust_deed) {
      return true;
    }

    return isPresent((form_values.legal_entity_agreement || {}).url);
  },

  isComplete: function(nextProps) {
    const {
      application_type,
      consumer_account,
      entity_region,
      entity_type,
      showEntityPartyCount,
    } = this.props;

    if (isBlank(entity_type)) {
      return false;
    }

    var { form_values, company_nzbn_details_loading } = this.props;

    const { form_errors } = this.state;

    if (nextProps !== null && nextProps !== undefined) {
      form_values = nextProps.form_values;
      company_nzbn_details_loading = nextProps.company_nzbn_details_loading;
    }

    if (
      [
        "trust",
        "partnership",
        "sole_trader",
        "education",
        "society",
        "association",
        "club",
        "government",
        "other",
      ].includes(entity_type) &&
      ((form_errors.registered_at &&
        Object.keys(form_errors.registered_at).length > 0) ||
        !form_values.registered_at ||
        !form_values.registered_at.year ||
        !form_values.registered_at.month ||
        !form_values.registered_at.day)
    ) {
      return false;
    }

    if (
      form_values.postal_address.full_address.length > 0 &&
      form_values.postal_api_id === null &&
      entity_type !== "personal"
    ) {
      return false;
    }

    if (
      entity_type === "company" &&
      entity_region === "AU" &&
      application_type !== "cash" &&
      !form_values.entity_party_count
    ) {
      return false;
    }

    if (company_nzbn_details_loading) {
      return false;
    }

    if (!this.isLegalEntityAgreementValid()) {
      return false;
    }

    // if it is company then must fill company name
    var company_name_filled =
      (entity_type === "company" &&
        (form_values.company_selected_name || consumer_account)) ||
      entity_type !== "company";

    // phone and address must be there unless is a personal appliation
    var phone_address_filled =
      entity_type === "personal" ||
      (entity_type !== "personal" &&
        form_values.entity_phone &&
        form_values.entity_phone.length > 0 &&
        form_values.physical_api_id);

    var entity_staff_count_filled =
      entity_type === "personal" ||
      application_type === "cash" ||
      parseInt(form_values.entity_staff_count) > 0;

    var entity_party_count_filled = showEntityPartyCount
      ? form_values.entity_party_count ==
          parseInt(form_values.entity_party_count, 10) &&
        parseInt(form_values.entity_party_count) > 0 &&
        !form_values.entity_party_count.toString().includes(".")
      : true;

    if (
      company_name_filled &&
      (entity_type === "company" || form_values.entity_name.length > 0) &&
      phone_address_filled &&
      entity_staff_count_filled &&
      entity_party_count_filled
    ) {
      return true;
    }
    return false;
  },

  componentDidMount: function() {
    const {
      dispatch,
      handleComplete,
      entity_type,
      hasOneApplicableLegalTypes,
      applicableLegalTypes,
      showEntityPartyCount,
    } = this.props;
    handleComplete(this.isComplete());
    dispatch(setDefaultEntityDetails());
    if (hasOneApplicableLegalTypes && !entity_type) {
      const entityType =
        applicableLegalTypes.attributes.addon_version.legal_types[0];
      const entityPartyCount =
        applicableLegalTypes.attributes.addon_version.config
          .entity_party_count || "";
      dispatch(selectEntityType(entityType, entityType));
      dispatch(setEntityFormValue("entity_party_count", entityPartyCount));
    }
    if (
      hasOneApplicableLegalTypes &&
      applicableLegalTypes &&
      applicableLegalTypes.attributes.addon_version.legal_types.includes(
        entity_type,
      ) &&
      applicableLegalTypes.attributes.addon_version.config["entity_party_count"]
    ) {
      dispatch(
        setEntityFormValue(
          "entity_party_count",
          applicableLegalTypes.attributes.addon_version.config[
            "entity_party_count"
          ],
        ),
      );
    }
    if (entity_type === "personal") {
      this.setState({
        error_messages: {
          entity_name: "You must create an account name",
          entity_name_with_emoji: "Your account name must be valid",
        },
      });
    }
  },

  //Click Events
  handleCompanyClick: function(value) {
    const { dispatch, auto_suggest_data, entity_region } = this.props;

    if (auto_suggest_data.hasOwnProperty(value)) {
      // If the company/entity already exists then continue
      if (auto_suggest_data[value].exists === true) {
        this.showExists();
        return;
      }
      dispatch(selectCompanyName(value));

      // To get a jump on the data-load for the next page.
      if (entity_region === "AU") {
        dispatch(setCompanyDetails(value));
      } else {
        dispatch(getCompanyDetails(value, "NZ"));
      }
    }
  },

  handlePhysicalClick: function(value) {
    const { dispatch } = this.props;
    dispatch(selectPhysicalAddress(value));
    this.checkValid({ id: "physical_api_id", value: value });
  },

  handlePostalClick: function(value) {
    const { dispatch } = this.props;
    dispatch(selectPostalAddress(value));
  },

  //Change events
  handleCompanyChange: function(event) {
    const { dispatch } = this.props;
    dispatch(setCompanyName(event.target.value));
    if (event.target.value.length > 2) {
      event.persist();
      this.lookupCompanyName(event.target.value);
    }
  },

  handleManulCompanyChange: function(event) {
    const { dispatch } = this.props;
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
    }
    dispatch(manualSetCompany(event.target.name, event.target.value));
  },

  handlePhysicalChange: function(event) {
    const { dispatch } = this.props;
    dispatch(setPhysicalAddress({ full_address: event.target.value }));
    if (event.target.value.length > 0) {
      event.persist();
      this.lookupPhysicalAddress(event.target.value);
    }
  },

  onSelectRegisteredAtDate: function(selectedDate) {
    const { dispatch } = this.props;

    let newDate = selectedDate;
    if (!moment.isMoment(newDate)) {
      newDate = moment(newDate, "DD/MM/YYYY").toDate();
    }

    const dateComponents = {
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    };

    this.checkValid({ id: "registered_at", value: dateComponents });

    dispatch(setEntityFormValue("registered_at", dateComponents));
  },

  handleChange: function(event) {
    const { dispatch } = this.props;
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
    }

    dispatch(setEntityFormValue(event.target.name, event.target.value));
  },
  handlePostalChange: function(event) {
    const { dispatch } = this.props;
    dispatch(setPostalAddress({ full_address: event.target.value }));
    if (event.target.value.length > 0) {
      event.persist();
      this.lookupPostalAddress(event.target.value);
    }
  },
  ///Look up
  lookupCompanyName: function(name) {
    const { dispatch, form_values, entity_region } = this.props;
    dispatch(lookupCompanyName(name, entity_region));
  },

  lookupPhysicalAddress: function(name) {
    const { dispatch, form_values, entity_region } = this.props;
    dispatch(lookupAddress(name, "Physical", entity_region));
  },

  lookupPostalAddress: function(name) {
    const { dispatch, form_values, entity_region } = this.props;
    dispatch(lookupAddress(name, "Postal", entity_region));
  },

  handleBlur: function(event) {
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
    }
  },

  checkValid: function(target) {
    const {
      form_values,
      entity_region,
      party_type,
      entity_type,
      showEntityPartyCount,
    } = this.props;
    const { form_errors, error_messages } = this.state;
    switch (target.id) {
      case "company_name":
        if (!form_values.company_selected_name) {
          this.setState({
            form_errors: {
              ...form_errors,
              company_name: "You must enter your company name",
            },
          });
        } else if (
          form_values.company_selected_name !== form_values.company_name
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              company_name: "You must select your company name",
            },
          });
        } else {
          const { company_name, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case "entity_name":
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              entity_name: error_messages["entity_name"],
            },
          });
        } else {
          if (
            target.value.trim().length < 1 ||
            emojiRegex().test(target.value)
          ) {
            this.setState({
              form_errors: {
                ...form_errors,
                entity_name: error_messages["entity_name_with_emoji"],
              },
            });
          } else {
            const { entity_name, ...rest } = this.state.form_errors;
            this.setState({
              form_errors: { ...rest },
            });
          }
        }
        break;
      case "entity_staff_count":
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              entity_staff_count: "You must enter number of staff",
            },
          });
        } else {
          if (!isNaN(parseFloat(target.value)) && isFinite(target.value)) {
            var { entity_staff_count, ...rest_errors } = this.state.form_errors;
            this.setState({
              form_errors: { ...rest_errors },
            });
          } else {
            this.setState({
              form_errors: {
                ...this.state.form_errors,
                entity_staff_count:
                  "Your staff count must be a number (ie 10 or 2.5)",
              },
            });
          }
        }
        break;
      case "entity_phone":
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              entity_phone: "You must enter a phone number",
            },
          });
        } else {
          const { entity_phone, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case "physical_address":
        if (form_values.physical_api_id === null) {
          this.setState({
            form_errors: {
              ...form_errors,
              physical_address: "You must select a valid physical address.",
            },
          });
        } else {
          const { physical_address, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case "physical_api_id":
        if (!target.value) {
          this.setState({
            form_errors: {
              ...form_errors,
              physical_address: "You must select a valid physical address.",
            },
          });
        } else {
          const { physical_address, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case "postal_address":
        // If a Postal Address has been entered but without API ID
        if (
          form_values.postal_api_id === null &&
          form_values.postal_address.full_address.length > 0
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              postal_address: "You must select a valid postal address.",
            },
          });
        } else {
          const { postal_address, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case "entity_party_count":
        if (showEntityPartyCount) {
          const value = parseInt(target.value);
          if (value === undefined || Number.isNaN(value)) {
            this.setState({
              form_errors: {
                ...form_errors,
                entity_party_count: `You must enter number of ${party_type}`,
              },
            });
          } else if (value <= 0 || target.value.toString().includes(".")) {
            this.setState({
              form_errors: {
                ...this.state.form_errors,
                entity_party_count: "Must be an number (ie 1 or 2)",
              },
            });
          } else if (entity_type === "personal" && value > 2) {
            this.setState({
              form_errors: {
                ...this.state.form_errors,
                entity_party_count:
                  "Maximum of 2 is allowed for personal account.",
              },
            });
          } else {
            const {
              entity_party_count,
              ...rest_errors
            } = this.state.form_errors;

            this.setState({
              form_errors: { ...rest_errors },
            });
          }
        }
        break;
      case "registered_at":
        if (
          [
            "education",
            "society",
            "association",
            "club",
            "government",
            "other",
          ].includes(entity_type)
        ) {
          if (
            !target.value ||
            target.value.length < 1 ||
            parseInt(target.value) < 0
          ) {
            this.setState({
              form_errors: {
                ...form_errors,
                registered_at:
                  "You must enter positive number of years in operation",
              },
            });
          } else {
            let current_year = new Date().getFullYear();
            let years =
              target.value && target.value.year
                ? current_year - target.value.year
                : target.value;
            if (
              years == parseInt(years, 10) &&
              isFinite(years) &&
              parseInt(years) >= 0
            ) {
              var { registered_at, ...rest_errors } = this.state.form_errors;
              this.setState({
                form_errors: { ...rest_errors },
              });
            } else {
              this.setState({
                form_errors: {
                  ...this.state.form_errors,
                  registered_at: "Must be an number (ie 1 or 2)",
                },
              });
            }
          }
        } else {
          this.setDateErrors(target.value);
        }
        break;
    }
  },

  setDateErrors: function(dateComponent) {
    const { form_errors } = this.state;

    if (isBlank(dateComponent)) {
      this.setState({
        form_errors: {
          ...form_errors,
          registered_at: "Please enter a valid date.",
        },
      });

      return;
    }

    const newDate = moment({
      day: dateComponent.day,
      month: dateComponent.month - 1,
      year: dateComponent.year,
    });

    if (newDate.toDate().getTime() < getMinimumDate().getTime()) {
      this.setState({
        form_errors: {
          ...form_errors,
          registered_at: "Please enter a date not more than 200 years ago.",
        },
      });

      return;
    }

    const currentDate = new Date();
    if (newDate.toDate().getTime() > currentDate.getTime()) {
      this.setState({
        form_errors: {
          ...form_errors,
          registered_at: "Future date is not allowed.",
        },
      });

      return;
    }

    this.setState({
      form_errors: {
        ...form_errors,
        registered_at: "",
      },
    });
  },

  componentWillMount: function() {
    const { dispatch, form_values } = this.props;

    this.lookupPhysicalAddress = throttle(
      500,
      false,
      this.lookupPhysicalAddress,
    );
    this.lookupPostalAddress = throttle(500, false, this.lookupPostalAddress);
    this.lookupCompanyName = throttle(500, false, this.lookupCompanyName);
  },

  getInitialState: function() {
    return {
      form_errors: {},
      showExistsModal: false,
      error_messages: {
        entity_name: "You must enter your trading name",
        entity_name_with_emoji: "Your trading name must be valid",
      },
    };
  },

  hideExists: function() {
    this.setState({
      showExistsModal: false,
    });
  },

  showExists: function() {
    this.setState({
      showExistsModal: true,
    });
  },

  handleYearsChange: function(event) {
    const { dispatch } = this.props;
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
    }
    let current_year = new Date().getFullYear();
    let registered_year = event.target.value
      ? current_year - event.target.value
      : "";
    dispatch(
      setEntityFormValue(event.target.name, {
        month: 1,
        day: 1,
        year: registered_year,
      }),
    );
  },

  onUploadLegalEntityAgreement(data) {
    const { dispatch } = this.props;
    const { legal_entity_agreement, ...rest } = this.state.form_errors;

    dispatch(uploadLegalEntityAgreement({ url: data }));

    this.setState({ form_errors: { ...rest } });
  },

  renderLegalEntityAgreementUploader() {
    const {
      entity_type,
      form_values,
      partnership_agreement_upload_visible,
      trust_deed_upload_visible,
    } = this.props;

    return (
      <div className={styles.legal_entity_agreement_container}>
        <div className={styles.clearfix} />
        <div className={styles.col_12}>
          <LegalEntityAgreementUploader
            entityType={entity_type}
            partnershipAgreementUploadVisible={
              partnership_agreement_upload_visible
            }
            trustDeedUploadVisible={trust_deed_upload_visible}
            legalEntityAgreement={form_values.legal_entity_agreement}
            error={this.state.form_errors.legal_entity_agreement}
            disabled={false}
            onUpload={this.onUploadLegalEntityAgreement}
          />
        </div>
      </div>
    );
  },

  render: function() {
    const {
      title,
      entity_type,
      form_inputs,
      form_values,
      company_loading,
      company_autosuggest_list,
      postal_address,
      physical_address,
      postal_address_list,
      physical_address_list,
      postal_address_loading,
      physical_address_loading,
      consumer_account,
      entity_region,
      party_type,
      company_nzbn_details_loading,
      showEntityPartyCount,
    } = this.props;
    const { form_errors, showExistsModal } = this.state;

    let help;
    if (showExistsModal) {
      help = <BusinessCompanyExists handleDismiss={this.hideExists} />;
    }
    var left_elements = new Array();
    var right_elements = new Array();
    Object.keys(form_inputs).forEach((value, index) => {
      var form_elements = new Array();
      switch (value) {
        case "company_name":
          if (entity_type === "company" || entity_region === "AU") {
            if (consumer_account || entity_region === "AU") {
              form_elements.push(
                <div className={styles.left_col} key={index}>
                  <div className={styles.form}>
                    <TextInput
                      key={index}
                      id={value}
                      type={form_inputs[value].type}
                      label={form_inputs[value].label}
                      value={form_values[value]}
                      required={form_inputs[value].required}
                      disabled={true}
                    />
                  </div>
                </div>,
              );
            } else {
              let isLoading = company_loading || company_nzbn_details_loading,
                label = form_inputs[value].label;

              form_elements.push(
                <div className={styles.left_col} key={index}>
                  <div className={styles.form}>
                    <AutoSuggest
                      key={index}
                      id={value}
                      label={label}
                      value={form_values[value]}
                      loading={isLoading}
                      error={form_errors[value]}
                      selected_item={form_values.company_selected_name}
                      suggest_items={company_autosuggest_list}
                      required={form_inputs[value].required}
                      handleChange={this.handleCompanyChange}
                      handleClick={this.handleCompanyClick}
                      handleBlur={this.handleBlur}
                      force_focus={true}
                    />
                  </div>
                </div>,
              );
            }
          }
          break;
        case "physical_address":
          form_elements.push(
            <div className={styles.left_col} key={index}>
              <div className={styles.form}>
                <AutoSuggest
                  key={index}
                  id={value}
                  label={form_inputs[value].label}
                  value={form_values[value].full_address}
                  loading={physical_address_loading}
                  suggest_items={physical_address_list}
                  error={form_errors[value]}
                  handleChange={this.handlePhysicalChange}
                  handleClick={this.handlePhysicalClick}
                  handleBlur={this.handleBlur}
                  required={form_inputs[value].required}
                  css_class="address_lookup"
                  selected_item={
                    form_values.physical_api_id
                      ? form_values[value].full_address
                      : ""
                  }
                  force_focus={true}
                />
              </div>
            </div>,
          );
          break;
        case "postal_address":
          let label = form_inputs[value].label;
          if (entity_type === "company") {
            label = "Postal address";
          }

          form_elements.push(
            <div className={styles.left_col} key={index}>
              <div className={styles.form}>
                <AutoSuggest
                  key={index}
                  id={value}
                  label={label}
                  value={form_values[value].full_address}
                  loading={postal_address_loading}
                  suggest_items={postal_address_list}
                  error={form_errors[value]}
                  handleChange={this.handlePostalChange}
                  handleClick={this.handlePostalClick}
                  handleBlur={this.handleBlur}
                  required={form_inputs[value].required}
                  css_class="address_lookup"
                  helper_text={form_inputs[value].helper_text}
                />
              </div>
            </div>,
          );
          break;
        case "entity_party_count":
          if (showEntityPartyCount) {
            form_elements.push(
              <div className={styles.left_col} key={index}>
                <div className={styles.form}>
                  <TextInput
                    key={index}
                    id={value}
                    type={form_inputs[value].type}
                    error={form_errors[value]}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                    label={form_inputs[value].label + party_type}
                    required={form_inputs[value].required}
                    value={form_values[value]}
                  />
                </div>
              </div>,
            );
          }
          break;
        case "registered_at":
          if (
            [
              "education",
              "society",
              "association",
              "club",
              "government",
              "other",
            ].includes(entity_type)
          ) {
            let current_year = new Date().getFullYear();
            let years =
              form_values[value] && form_values[value].year
                ? current_year - form_values[value].year
                : "";
            form_elements.push(
              <div className={styles.left_col} key={index}>
                <div className={styles.form}>
                  <TextInput
                    key={index}
                    id={value}
                    type={form_inputs[value].type}
                    error={form_errors[value]}
                    handleChange={this.handleYearsChange}
                    handleBlur={this.handleBlur}
                    label={form_inputs[value].label}
                    required={form_inputs[value].required}
                    value={years}
                  />
                </div>
              </div>,
            );
          } else if (entity_type !== "company" && entity_type !== "personal") {
            form_elements.push(
              <div className={styles.left_col} key={index}>
                <BorderedCalendarPicker
                  key={index}
                  textFieldProps={{
                    error: Boolean(!!form_errors[value]),
                    helperText: form_errors[value],
                    id: value,
                    name: value,
                    required: true.valueOf,
                  }}
                  isStyledUnderlined
                  minDate={getMinimumDate()}
                  maxDate={moment().toDate()}
                  value={convertDateComponentToDate(form_values[value])}
                  label={form_inputs[value].label}
                  onChange={this.onSelectRegisteredAtDate}
                  isStyledUnderlined
                />
              </div>,
            );
          }
          break;
        case "incorporated_number":
          if (
            [
              "education",
              "society",
              "association",
              "club",
              "government",
              "other",
            ].includes(entity_type)
          ) {
            form_elements.push(
              <div className={styles.left_col} key={index}>
                <div className={styles.form}>
                  <TextInput
                    key={index}
                    id={value}
                    type={form_inputs[value].type}
                    error={form_errors[value]}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                    label={form_inputs[value].label}
                    required={form_inputs[value].required}
                    value={form_values[value]}
                  />
                </div>
              </div>,
            );
          }
          break;
        case "entity_name":
          if (entity_type === "company") {
            break;
          }
        case "delivery_instruction":
          if (entity_type === "company") {
            break;
          }
        default:
          form_elements.push(
            <div className={styles.left_col} key={index}>
              <div className={styles.form}>
                <TextInput
                  key={index}
                  id={value}
                  type={form_inputs[value].type}
                  error={form_errors[value]}
                  handleChange={this.handleChange}
                  handleBlur={this.handleBlur}
                  label={form_inputs[value].label}
                  required={form_inputs[value].required}
                  value={form_values[value]}
                />
              </div>
            </div>,
          );
          break;
      }

      left_elements.push(form_elements);
    });

    return (
      <section className={styles.section}>
        <PageHeader title={title} />
        <div className={styles.row}>
          {left_elements}
          {this.renderLegalEntityAgreementUploader()}
        </div>
        {help}
      </section>
    );
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */
var defaults = {
  title: "Business details",
  form_inputs: {
    company_name: {
      label: "Company/Business name",
      required: true,
      type: "text",
    },
    entity_name: {
      label: "Trading name",
      required: true,
      type: "text",
    },
    physical_address: {
      label: "Business address",
      required: true,
    },
    postal_address: {
      label: "Postal/Delivery address",
      required: false,
      helper_text: "If different from business address",
    },
    delivery_instruction: {
      label: "Add delivery instructions",
      required: false,
      type: "text",
    },
    entity_phone: {
      label: "Business phone number",
      required: true,
      type: "tel",
    },
    entity_staff_count: {
      label: "Number of staff",
      required: true,
      type: "number",
    },
    entity_party_count: {
      label: "Number of ",
      required: true,
      type: "number",
    },
    registered_at: {
      label: "Business start date",
      required: true,
      type: "date",
    },
    incorporated_number: {
      label: "Incorporation/Registration number",
      required: false,
      type: "text",
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

module.exports = connect((state, ownProps) => {
  let auto_suggest_list = {};
  let lookup;
  let party_type;
  let application_type;

  const entity_type = state.cob_business.entity_type;
  const entity_region = state.cob_business.entity_region;
  const applicationAttributes = state.cob_section.application.attributes;

  if (Object.keys(state.cob_business.company_autosuggest_list).length > 0) {
    auto_suggest_list = new Object();

    Object.keys(state.cob_business.company_autosuggest_list).forEach(item => {
      auto_suggest_list[item] =
        state.cob_business.company_autosuggest_list[item].name;
    });

    lookup = true;
  }

  let title = defaults.title;
  let form_inputs = Object.assign({}, defaults.form_inputs);

  form_inputs.company_name.label = getEntityNameFieldLabel(entity_region);
  form_inputs.entity_name.label = getTradingNameTitleByRegion({
    entityType: state.cob_business.entity_type,
    region: entity_region,
  });

  if (entity_type === "company" && entity_region === "AU") {
    party_type = "directors";
  }

  if (entity_type === "partnership") party_type = "partners";
  if (entity_type === "trust") party_type = "trustees";
  if (entity_type === "personal") {
    title = "Customer details";
    party_type = "individuals";

    delete form_inputs.company_name;
    delete form_inputs.physical_address;
    delete form_inputs.postal_address;
    delete form_inputs.entity_phone;
    delete form_inputs.entity_staff_count;
    delete form_inputs.delivery_instruction;
  }

  if (
    [
      "education",
      "society",
      "association",
      "club",
      "government",
      "other",
    ].includes(state.cob_business.entity_type)
  ) {
    form_inputs.registered_at.label = "Years in operation";
    form_inputs.registered_at.type = "tel";
  }

  if (state.cob_section.application) {
    application_type = applicationAttributes.application_type;
    if (application_type === "cash") {
      delete form_inputs.entity_staff_count;
    }
  }

  let companyDetails = state.cob_business.company_details || {};

  let showEntityPartyCount =
    ownProps.hasOneApplicableLegalTypes && !ownProps.consumer_account
      ? false
      : application_type === "cash"
      ? entity_type === "personal"
      : (entity_type === "company" && entity_region === "AU") ||
        entity_type === "partnership" ||
        entity_type === "trust" ||
        entity_type === "personal";

  let companyName = "";
  if (ownProps.consumer_account || entity_region === "AU") {
    companyName = companyDetails.name;
  } else {
    companyName = state.cob_business.company_autosuggest_field_value;
  }

  let companySelectedName = "";
  if (entity_region === "AU") {
    companySelectedName = companyName;

    if (
      !companyDetails.registered_at ||
      isNaN(companyDetails.registered_at.day)
    ) {
      try {
        const registeredAtMoment = moment(
          state.cob_business.businessApiDetails.abn_status_effective_from,
        );
        companyDetails.registered_at = {
          day: registeredAtMoment.day(),
          month: registeredAtMoment.month(),
          year: registeredAtMoment.year(),
        };
      } catch (_e) {
        companyDetails.registered_at = null;
      }
    }
  } else {
    companySelectedName = state.cob_business.company_autosuggest_selected_name;
  }

  return {
    applicableLegalTypes: state.cob_section.applicableLegalTypes,
    title: title,
    entity_type: entity_type,
    form_inputs: form_inputs,
    form_values: {
      company_name: companyName,
      company_selected_name: companySelectedName,
      entity_name: state.cob_business.entity_name,
      entity_staff_count: state.cob_business.entity_staff_count,
      entity_phone: state.cob_business.entity_phone,
      physical_address: state.cob_business.physical_address,
      physical_api_id: state.cob_business.physical_api_id,
      postal_api_id: state.cob_business.postal_api_id,
      postal_address: state.cob_business.postal_address,
      entity_party_count:
        state.cob_business.entity_party_count == 0
          ? ""
          : state.cob_business.entity_party_count,
      registered_at: companyDetails.registered_at,
      delivery_instruction: state.cob_business.delivery_instruction,
      incorporated_number: state.cob_business.incorporated_number,
      legal_entity_agreement: state.cob_business.legal_entity_agreement,
    },
    physical_address_loading: state.cob_business.physical_address_loading,
    physical_address_list: state.cob_business.physical_address_list,
    postal_address_loading: state.cob_business.postal_address_loading,
    postal_address_list: state.cob_business.postal_address_list,
    auto_suggest_data: state.cob_business.company_autosuggest_list,
    company_autosuggest_list: auto_suggest_list,
    company_loading: state.cob_business.company_autosuggest_lookup_loading,
    lookup: lookup,
    entity_region,
    party_type: party_type,
    company_nzbn_details_loading:
      state.cob_business.company_nzbn_details_loading,
    application_type: application_type,
    showEntityPartyCount: showEntityPartyCount,
    partnership_agreement_upload_visible:
      applicationAttributes.partnership_agreement_upload_visible,
    requires_trust_deed: applicationAttributes.requires_trust_deed,
    trust_deed_upload_visible: applicationAttributes.trust_deed_upload_visible,
  };
})(BusinessOtherDetails);
