import "c3/c3.css";

import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import React, { Component } from "react";
import C3Chart from "react-c3js";

class LeadBarGraph extends Component {
  render() {
    const { data, loading } = this.props;
    const xColumns = ["x"];
    const xValues = ["total"];

    if (loading) {
      return <SimpleLoader css_class={"loader_relative"} />;
    }

    data.forEach(value => {
      xColumns.push(value.date);
      xValues.push(value.total);
    });

    const chartData = {
      colors: { total: "var(--main-color)" },
      columns: [xColumns, xValues],
      type: "bar",
      x: "x",
    };

    const axis = {
      x: { type: "category" },
      y: {
        label: { position: "outer-middle" },
        min: 0,
        padding: {
          bottom: 0,
          top: 20,
        },
        tick: {
          format: x => (Number.isInteger(x) ? x : ""),
        },
      },
    };

    const legend = { position: "right" };
    const chartSize = { height: 200 };

    return (
      <div>
        <C3Chart
          data={chartData}
          axis={axis}
          legend={legend}
          size={chartSize}
        />
      </div>
    );
  }
}

export default LeadBarGraph;
