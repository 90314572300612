import React from "react";
import styles from "./css/SectionCircle.css";

var SectionCircle = createClass({
  render: function() {
    const { title } = this.props;
    let circle_title = null;

    if (title) {
      circle_title = <h1 className={styles.title}>{title}</h1>;
    }

    return (
      <div className={styles.circle}>
        <div className={styles.content}>
          <div className={styles.body}>
            {circle_title}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  },
});

export default SectionCircle;
