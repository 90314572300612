import ApplicationsVolume from "./ApplicationsVolume";
import ComingSoon from "./ComingSoon";
import ConversionRateMobile from "./ConversionRateMobile";
import ConversionRateTime from "./ConversionRateTime";
import CustomerFeedback from "./CustomerFeedback";
import NeedActions from "./NeedActions";
import OmniChannelPanel from "./OmniChannelPanel";
import ReadyForApproval from "./ReadyForApproval";
import StartApplicationPanel from "./StartApplicationPanel";
import SummaryPanel from "./SummaryPanel";

export const desktopPanels = {
  row1: [
    {
      childComponents: [
        {
          component: SummaryPanel,
          phase: 1,
        },
        {
          component: OmniChannelPanel,
          phase: 1,
        },
      ],
    },
    {
      component: NeedActions,
      permission: ["approver", "admin"],
      phase: 1,
    },
    {
      component: ReadyForApproval,
      permission: ["approver", "admin"],
      phase: 1,
    },
    {
      childComponents: [
        {
          component: NeedActions,
          permission: ["standard"],
          phase: 1,

        },
        {
          component: ReadyForApproval,
          permission: ["standard"],
          phase: 1,

        },
      ],
    },
    {
      comingSoon: true,
      component: CustomerFeedback,
      permission: ["standard"],
      phase: 1,
    },
  ],
  row2: [
    // {
    //   component: ApplicationsVolume,
    //   permission: ["standard"],
    //   phase: 2,
    //   comingSoon: true,
    // },
    {
      comingSoon: true,
      component: ConversionRateTime,
      permission: ["approver", "admin"],
      phase: 1,
    },
    {
      comingSoon: true,
      component: CustomerFeedback,
      permission: ["approver", "admin"],
      phase: 1,
    },
  ],
}

export const tabletPanels = {
  row1: [
    {
      component: NeedActions,
      phase: 1,
    },
    {
      component: ReadyForApproval,
      phase: 1,
    },
  ],
  row2: [
    {
      component: SummaryPanel,
      permission: ["admin", "approver"],
      phase: 1,
    },
    {
      comingSoon: true,
      component: CustomerFeedback,
      permission: ["admin", "approver", "standard"],
      phase: 1,
    },
    {
      comingSoon: true,
      component: ComingSoon,
      permission: ["standard"],
      phase: 1,
      sizeOverrides: ["is-12"],
    },
  ],
}

export const mobilePanels = {
  Applications: [
    {
      component: NeedActions,
      permission: ["standard"],
      phase: 1,
    },
  ],
  Effectiveness: [
    {
      component: ReadyForApproval,
      permission: ["admin", "approver"],
      phase: 1,
    },
    // {
    //   component: SummaryPanel,
    //   permission: ["standard"],
    //   comingSoon: true,
    //   phase: 1,
    // },
  ],
  Efficiency: [
    {
      comingSoon: true,
      component: ConversionRateMobile,
      permission: ["approver", "admin"],
      phase: 1,
    },
  ],
  Experience: [
    {
      component: CustomerFeedback,
      permission: ["approver", "admin", "standard"],
      phase: 1,
    },
  ],
  "QR Code": [
    {
      comingSoon: true,
      component: StartApplicationPanel,
      permission: ["standard"],
      phase: 1,
    },
  ],
};
