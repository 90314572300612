export const NOTIFICATIONS_LOAD_NOTIFICATIONS_START =
  "NOTIFICATIONS_LOAD_NOTIFICATIONS_START";
export const NOTIFICATIONS_LOAD_NOTIFICATIONS_SUCCESS =
  "NOTIFICATIONS_LOAD_NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_LOAD_NOTIFICATIONS_ERROR =
  "NOTIFICATIONS_LOAD_NOTIFICATIONS_ERROR";

export const NOTIFICATIONS_MARK_ACTIONED_START =
  "NOTIFICATIONS_MARK_ACTIONED_START";
export const NOTIFICATIONS_MARK_ACTIONED_SUCCESS =
  "NOTIFICATIONS_MARK_ACTIONED_SUCCESS";
export const NOTIFICATIONS_MARK_ACTIONED_ERROR =
  "NOTIFICATIONS_MARK_ACTIONED_ERROR";

export const NOTIFICATIONS_PUBLISH_MESSAGE_START =
  "NOTIFICATIONS_PUBLISH_MESSAGE_START";
export const NOTIFICATIONS_PUBLISH_MESSAGE_SUCCESS =
  "NOTIFICATIONS_PUBLISH_MESSAGE_SUCCESS";
export const NOTIFICATIONS_PUBLISH_MESSAGE_ERROR =
  "NOTIFICATIONS_PUBLISH_MESSAGE_ERROR";
