import SignatureDetails from "modules/new-applications/components/application-sections/IdentificationCheck/SignatureDetails";
import React, { Fragment, ReactElement } from "react";

interface IVerificationDetailsProps {
  dobVerified: "Pass" | "Fail" | undefined | null;
  idVerified: "Pass" | "Fail" | "Unknown";
  nameVerified: "Pass" | "Fail" | undefined | null;
}

const VerificationDetails = ({
  dobVerified,
  idType,
  idVerified,
  nameVerified,
}: {
  dobVerified: "Pass" | "Fail" | undefined | null;
  idType: "passport" | "driver_licence";
  idVerified: "Pass" | "Fail" | "Unknown";
  nameVerified: "Pass" | "Fail" | undefined | null;
}): ReactElement | null => {
  if (idVerified === "Unknown") {
    return null;
  }

  const idTypeLabel = {
    driver_licence: "Driver licence",
    passport: "Passport",
  }

  return (
    <Fragment>
      <SignatureDetails
        label={`${idTypeLabel[idType]} verification`}
        content={idVerified}
      />
      <SignatureDetails label="Name verification" content={nameVerified} />
      <SignatureDetails label="DOB verification" content={dobVerified} />
    </Fragment>
  );
};

export default VerificationDetails;
