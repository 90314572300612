import React, { Component } from "react";

import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import commonStyles from "../css/payment-predictor/PaymentPredictorComponent.css";
import styles from "../css/payment-predictor/Appendix.css";

export default class Appendix extends Component {
  renderInformationSources() {
    return (
      <div>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Information sources</p>
        </div>
        <p>
          Dun &amp; Bradstreet takes great care to verify the accuracy of the
          information contained in every report. The table below lists the main
          information sources used to compile our reports
        </p>
        {this.renderReportContent()}
        {this.renderASICData()}
        {this.renderNotes()}
      </div>
    );
  }

  renderReportContent() {
    return (
      <table className={`${commonStyles.table} ${styles.appendix_table}`}>
        <thead>
          <tr>
            <th>Report content</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Company Details</td>
            <td className={commonStyles.highlight_cell}>
              D&amp;B Company Research and information derived from the ASIC
              database
            </td>
          </tr>
          <tr>
            <td>Court</td>
            <td className={commonStyles.highlight_cell}>
              D&amp;B Automated Court Data Feed
            </td>
          </tr>
          <tr>
            <td>Collections</td>
            <td className={commonStyles.highlight_cell}>
              Extracted Daily from Milton Graham
            </td>
          </tr>
          <tr>
            <td>Trade Payment Analysis</td>
            <td className={commonStyles.highlight_cell}>
              D&amp;B Scoring and Analytical Department Monthly
            </td>
          </tr>
          <tr>
            <td>Trade Payments</td>
            <td className={commonStyles.highlight_cell}>
              D&amp;B Trade Information Program
            </td>
          </tr>
          <tr>
            <td>
              D&amp;B Risk of Failure Score &amp; D&amp;B Risk of Late Payment
              Score
            </td>
            <td className={commonStyles.highlight_cell}>
              D&amp;B Scoring and Analytical Department Daily
            </td>
          </tr>
          <tr>
            <td>Company News</td>
            <td className={commonStyles.highlight_cell}>
              ABIX Australian Business Intelligence*
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderASICData() {
    return (
      <table className={`${commonStyles.table} ${styles.appendix_table}`}>
        <thead>
          <tr>
            <th>ASIC data**</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Financial Statement</td>
            <td className={commonStyles.highlight_cell}>
              D&amp;B Company Research and online from the ASIC database
            </td>
          </tr>
          <tr>
            <td>Registered Charges</td>
            <td className={commonStyles.highlight_cell}>
              Online from the ASIC database
            </td>
          </tr>
          <tr>
            <td>Directors and Shareholders</td>
            <td className={commonStyles.highlight_cell}>
              Online from the ASIC database
            </td>
          </tr>
          <tr>
            <td>ASIC Company Extract</td>
            <td className={commonStyles.highlight_cell}>
              Online from the ASIC database
            </td>
          </tr>
          <tr>
            <td>ASIC Documents</td>
            <td className={commonStyles.highlight_cell}>
              Online from the ASIC database
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderNotes() {
    return (
      <div>
        <p className={commonStyles.emphasize}>NOTES</p>
        <ul className={commonStyles.list}>
          <li>
            * Copyright 2006 ABIX/LexisNexis Australia All Rights Reserved
          </li>
          <li>
            ** Contains information derived from the ASIC database, either from
            documents lodged with the ASIC and processed as at the stated date
            of the extract, or from records supplied by previous state and/or
            territory systems.
          </li>
          <li>
            D&amp;B is a broker of information from the ASIC national corporate
            database, ASCOT.
          </li>
          <li>
            Please advise the ASIC promptly of any error or omission which you
            may find so that they can correct it. The ASIC Help line is +6135177
            if88.
          </li>
        </ul>
      </div>
    );
  }

  renderReportExplanations() {
    return (
      <div>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Report explanations</p>
        </div>
        <p className={commonStyles.emphasize}>
          Dun &amp; Bradstreet Trade Program
        </p>
        <p>
          Trade references within this report are collected through the Dun
          &amp; Bradstreet trade program. Companies participating in this
          program supply Dun &amp; Bradstreet with their monthly debtors ledgers
          which are converted into individual trade references and added to the
          reports on a daily basis. Such references provide evidence of
          organisations payment habits and due to their objectivity are
          generally more accurate than those obtained from suppliers provided as
          references by the subject during the interview.
        </p>
        <p>
          <span className={commonStyles.emphasize}>Note</span> : In some
          instances, payment beyond terms can be the result of disputes over
          merchandise, lost invoices, etc. In certain industries such as the
          building and construction industry, slow payments may often be the
          norm. These circumstances should be given due consideration when
          interpreting the subject&apos;s payment habits.
        </p>
        <p className={commonStyles.emphasize}>Business analyst</p>
        <p>This D&amp;B Report compiled by Robelyn Abrina, Business Analyst</p>
        <p className={commonStyles.emphasize}>Currency</p>
        <p>
          All monetary amounts quoted in this report are shown in Australian
          dollars unless otherwise stated.
        </p>
        <p className={commonStyles.emphasize}>Collection status description</p>
        {this.renderCollectionStatusDescription()}
        <p className={commonStyles.emphasize}>Default status description</p>
        {this.renderDefaultStatusDescription()}
      </div>
    );
  }

  renderCollectionStatusDescription() {
    return (
      <table className={`${commonStyles.table} ${styles.appendix_table}`}>
        <tbody>
          <tr>
            <td>Paid in full</td>
            <td className={commonStyles.highlight_cell}>
              Account paid in full Closed
            </td>
          </tr>
          <tr>
            <td>Partial Paid</td>
            <td className={commonStyles.highlight_cell}>
              Debt is partially paid
            </td>
          </tr>
          <tr>
            <td>Legal Action</td>
            <td className={commonStyles.highlight_cell}>
              Account is in legal action
            </td>
          </tr>
          <tr>
            <td>Dispute</td>
            <td className={commonStyles.highlight_cell}>
              Debtor is in dispute with creditor
            </td>
          </tr>
          <tr>
            <td>Closed</td>
            <td className={commonStyles.highlight_cell}>
              Collection service ceased
            </td>
          </tr>
          <tr>
            <td>In progress</td>
            <td className={commonStyles.highlight_cell}>
              Collection is in progress
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderDefaultStatusDescription() {
    return (
      <table className={`${commonStyles.table} ${styles.appendix_table}`}>
        <tbody>
          <tr>
            <td>Outstanding</td>
            <td className={commonStyles.highlight_cell}>
              The payment is still outstanding
            </td>
          </tr>
          <tr>
            <td>Paid in full</td>
            <td className={commonStyles.highlight_cell}>
              The whole account has been paid in full
            </td>
          </tr>
          <tr>
            <td>Settled</td>
            <td className={commonStyles.highlight_cell}>
              Partial payment of the debt has been accepted by the creditor
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderDnBRatingInterpretationTable() {
    return (
      <div>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>
            D&amp;B Rating interpretation table
          </p>
        </div>
        <p>
          The D&amp;B Rating Interpretation Table shows our global rating system
          comprising of a financial strength rating and credit appraisal
        </p>
        <ul className={commonStyles.list}>
          <li>
            The financial strength rating &apos;5A&apos; through &apos;G&apos;
            reflects the strength of an entity base on its tangible net worth.
          </li>
          <li>
            A financial strength rating of &apos;N&apos; denotes a negative
            tangible net worth
          </li>
          <li>
            A financial strength rating of &apos;O&apos; denotes that the net
            worth is undetermined.
          </li>
          <li>
            A financial rating of &apos;NQ&apos; represents no scores are
            available.
          </li>
          <li>
            Credit appraisal represents the risk associated with an entity
            experiencing financial distress in the next 12 months.
          </li>
          <li>
            A credit appraisal of &apos;1&apos; represents a minimal risk of
            financial distress and a composite credit appraisal of &apos;4&apos;
            represents a severe risk of business failure.
          </li>
        </ul>
        <p className={commonStyles.emphasize}>Financial Strength</p>
        {this.renderFinancialStrength()}
        <p className={commonStyles.emphasize}>Credit appraisal</p>
        {this.renderCreditAppraisal()}
      </div>
    );
  }

  renderFinancialStrength() {
    return (
      <table className={`${commonStyles.table} ${styles.appendix_table}`}>
        <thead>
          <tr>
            <th>Rating</th>
            <th>$AUD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>5A</td>
            <td className={commonStyles.highlight_cell}>50,000,000 and over</td>
          </tr>
          <tr>
            <td>4A</td>
            <td className={commonStyles.highlight_cell}>
              10,000,000 to 49,999,999
            </td>
          </tr>
          <tr>
            <td>3A</td>
            <td className={commonStyles.highlight_cell}>
              5,000,000 to 9,999,999
            </td>
          </tr>
          <tr>
            <td>2A</td>
            <td className={commonStyles.highlight_cell}>
              1,000,000 to 4,999,999
            </td>
          </tr>
          <tr>
            <td>A</td>
            <td className={commonStyles.highlight_cell}>600,000 to 999,999</td>
          </tr>
          <tr>
            <td>B</td>
            <td className={commonStyles.highlight_cell}>300,000 to 599,999</td>
          </tr>
          <tr>
            <td>C</td>
            <td className={commonStyles.highlight_cell}>100,000 to 299,999</td>
          </tr>
          <tr>
            <td>D</td>
            <td className={commonStyles.highlight_cell}>40,000 to 99,999</td>
          </tr>
          <tr>
            <td>E</td>
            <td className={commonStyles.highlight_cell}>12,000 to 39,999</td>
          </tr>
          <tr>
            <td>F</td>
            <td className={commonStyles.highlight_cell}>4,000 to 11,999</td>
          </tr>
          <tr>
            <td>G</td>
            <td className={commonStyles.highlight_cell}>up to 3,999</td>
          </tr>
          <tr>
            <td>O</td>
            <td className={commonStyles.highlight_cell}>
              Net worth undetermined. Accounts unavailable or older than 18
              months.
            </td>
          </tr>
          <tr>
            <td>N</td>
            <td className={commonStyles.highlight_cell}>
              Negative tangible net worth. Negative balance of equity after
              deduction of intangibles
            </td>
          </tr>
          <tr>
            <td>NQ</td>
            <td className={commonStyles.highlight_cell}>
              Certain businesses do not lend themselves to a D&amp;B Rating, and
              the letters &quot;NQ&quot; on any D&amp;B Business Information
              Report mean &quot;Not Quoted&quot;. Information on these names,
              however, continues to be stored and updated in the D&mp;B Business
              Information file, and Reports are available. Government entities
              are rated NQ
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderCreditAppraisal() {
    return (
      <table className={`${commonStyles.table} ${styles.appendix_table}`}>
        <thead>
          <tr>
            <th>Risk indicator</th>
            <th>Condition</th>
            <th>Level of risk</th>
            <th>Guide to interpretation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Strong</td>
            <td>Minimal Risk</td>
            <td className={commonStyles.highlight_cell}>
              Proceed with transaction offer extended terms if required
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Good</td>
            <td>Low Risk</td>
            <td className={commonStyles.highlight_cell}>
              Proceed with transaction
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Fair</td>
            <td>Moderate Risk</td>
            <td className={commonStyles.highlight_cell}>
              Proceed with transaction but monitor closely
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Limited</td>
            <td>Severe Risk</td>
            <td className={commonStyles.highlight_cell}>
              Review each case before extending credit and obtain more
              information. Take suitable assurances before extending credit,
              guarantees may be needed
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderClientServices() {
    return (
      <div>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Client services</p>
        </div>
        <p className={commonStyles.emphasize}>
          Client service contact information
        </p>
        {this.renderClientServiceContactInformation()}
        <p>
          Whilst D&amp;B attempts to ensure that the information provided is
          accurate and complete by reason of the immense quantity of detailed
          matter dealt within compiling the information and the fact that some
          of the data are supplied from sources not controlled by D&amp;B which
          cannot always be verified, including information provided direct from
          the subject of enquiry as well as the possibility of negligence and
          mistake, D&amp;B does not guarantee the correctness or the effective
          delivery of the information and will not be held responsible for any
          errors or omissions therein.
        </p>
      </div>
    );
  }

  renderClientServiceContactInformation() {
    return (
      <table className={`${commonStyles.table} ${styles.appendix_table}`}>
        <tbody>
          <tr>
            <td>Email</td>
            <td className={commonStyles.highlight_cell}>
              clientservices@illion.com.au
            </td>
          </tr>
          <tr>
            <td rowSpan={2}>Telephone</td>
            <td className={commonStyles.highlight_cell}>
              13 23 33 (within Australia)
            </td>
          </tr>
          <tr>
            <td className={commonStyles.highlight_cell}>
              +61 3 9828 3444 (other countries)
            </td>
          </tr>
          <tr>
            <td>Fax</td>
            <td className={commonStyles.highlight_cell}>+61 3 9828 3447</td>
          </tr>
          <tr>
            <td>Website</td>
            <td className={commonStyles.highlight_cell}>illion.com.au</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Appendix"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        {this.renderInformationSources()}
        {this.renderReportExplanations()}
        {this.renderDnBRatingInterpretationTable()}
        {this.renderClientServices()}
      </ReviewContainer>
    );
  }
}
