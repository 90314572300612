import axios from "./axios";;

const UserInformations = function(config) {
  this.axios = axios(config);
  this.type = "user_informations";
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
};

//
// Await-able functions
// The following functions allows the use of async/await
//
UserInformations.prototype.sendVerificationCode = function(options) {
  return this.axios.post(`/${this.type}/send_verification_code`, options);
};

UserInformations.prototype.validateVerificationCode = function(options) {
  return this.axios.get(`/${this.type}/validate_verification_code`, options);
};

export default UserInformations;
