import api from "api";
import logo from "images/1centre-blue.png";
import Container from "modules/shared/components/layouts/Container";
import Tabs from "modules/shared/components/widgets/interactive/Tabs";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";

import styles from "../styles/WebhookExampleStyle";

const TABS = [
  {
    path: "/credit",
    title: "credit",
  },
  {
    path: "/cash",
    title: "cash",
  },
  {
    path: "/alert_notification",
    title: "alert notification",
  },
];

export function stringifyJSON(json) {
  return JSON.stringify(json, null, 2);
}

class WebhookExample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schemaDefinitions: {},
    };
  }

  async componentDidMount() {
    const webhookAPI = api("webhook_schemas", this.props.currentUser);

    const response = await webhookAPI.getSchemas();
    this.setState({ schemaDefinitions: response.data });
  }

  renderChildren() {
    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        schemaDefinitions: this.state.schemaDefinitions,
      }),
    );
  }

  render() {
    return (
      <div>
        <header className={styles.header}>
          <Helmet title="Webhook Documentation" />
          <img src={logo} alt="1Centre" className={styles.logo} />
        </header>

        <Container title="Webhook Documentation">
          <Tabs
            tab_list={TABS}
            base_path="/webhook_documentation"
            active_path={location.pathname}
          />

          {this.renderChildren()}
        </Container>
      </div>
    );
  }
}

export default connect(state => {
  return { currentUser: state.current_user };
})(WebhookExample);
