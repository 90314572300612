import React from "react";

type ModalProps = {
  children: React.ReactNode | undefined;
  footer: React.ReactNode | undefined;
  onClose: () => void | undefined;
  title: string | undefined;
};

type HeaderCloseButtonProps = {
  onClose: () => void | undefined;
};

function HeaderCloseButton(
  props: HeaderCloseButtonProps,
): React.ReactElement<HeaderCloseButtonProps> | null {
  const { onClose } = props;

  if (onClose) {
    return <button className="delete" aria-label="close" onClick={onClose} />;
  }

  return null;
}

export default function Modal(
  props: ModalProps,
): React.ReactElement<ModalProps> {
  const { children, footer, onClose, title } = props;

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <HeaderCloseButton onClose={onClose} />
        </header>
        <section className="modal-card-body">{children}</section>
        <div className="modal-card-foot">{footer}</div>
      </div>
    </div>
  );
}
