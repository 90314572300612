import {
  lookupPersonPostalAddress,
  lookupResidentialAddress,
  removeEntityPartyDetails,
  setEntityPartyDetailsData,
  setEntityPartyDetailsIndex,
  toggleIsApplicant,
} from "../../actions/onboarding";

import BusinessPartyDetailsForm from "./BusinessPartyDetailsForm";
import { EMAIL_REGEX } from "constants";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import React, { Fragment } from "react";
import { component } from "react-router/lib/PropTypes";
import { connect } from "react-redux";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import { requiresSignatories } from "modules/shared/helpers/applicationDetectInBCFlow";
import styles from "./css/Business.css";
import SignatorySwitchModal from "../guarantors/SignatorySwitchModal";
import {
  displayDataLostWarning,
  getAddonRules,
  getAffectedPages,
  setAffectedPages,
} from "modules/consumer-onboarding/actions/section";
import get from "lodash.get";

class BusinessPartyDetails extends React.Component {
  state = {
    form_errors: {},
    error_messages: {
      contact_country_code: "You need to enter a country code.",
      contact_phone_number: "You need to enter a phone number.",
      first_name: "Please enter a first name",
      is_applicant: "Please select yes or no if this is you",
      last_name: "Please enter a last name",
      person_postal_address: "You must select a valid postal address",
      residential_address: "You must select a valid physical address",
      residential_type: "Please select a residential type",
      title_invalid: "Titles (such as Lady or Sir) are not valid first names",
    },
    modal: {
      show: false,
    },
  };

  componentWillReceiveProps(nextProps) {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete(nextProps.form_values));
    if (nextProps.page_validation_start) {
      this.checkValidAll();
    }
  }

  checkValidAll = () => {
    const {
      form_values,
      setPageValidationStartFinish,
      entity_type,
    } = this.props;

    let inputs = ["first_name", "last_name", "is_applicant"];

    if (entity_type !== "company") {
      inputs.push("email");
    }

    if (entity_type == "personal") {
      const personal_inputs = [
        "contact_country_code",
        "contact_phone_number",
        "person_postal_address",
        "residential_address",
        "residential_type",
        "is_applicant",
      ];
      inputs = inputs.concat(personal_inputs);
    }

    inputs.forEach(value => {
      let target = {
        id: value,
        name: value,
        value: form_values[value],
        processedValue: form_values[value],
      };
      setTimeout(() => {
        if (value === "email") {
          this.refs.party_details.refs.email._onBlur();
        } else {
          this.checkFormValid(target);
        }
      }, 500);
    });

    setPageValidationStartFinish();
  };

  componentDidMount() {
    const { dispatch, component_index, handleComplete } = this.props;

    if (component_index === 0) {
      dispatch(getAddonRules());
    }

    handleComplete(this.isComplete());
    this.removeEntityPartyDetails();

    this.assignIsApplicant();
  }

  assignIsApplicant = () => {
    const {
      application,
      component_index,
      dispatch,
      entity_type,
      party_count,
    } = this.props;

    if (entity_type !== "personal" || party_count > 1) {
      return;
    }

    dispatch(toggleIsApplicant({ index: component_index, isApplicant: true }));
    const applicantEmail = get(
      application,
      "attributes.consumer_contact_email",
      "",
    );
    this._emailChangeHandler(applicantEmail, true);
  };

  isComplete = form_values => {
    const { entity_type, canSelectSignatoryApplicant } = this.props;
    const { form_errors } = this.state;
    var re = EMAIL_REGEX;

    if (!form_values) {
      var form_values = this.props.form_values;
    }

    if (form_errors.hasOwnProperty("email") && form_errors.email) return false;
    // NOTES: I do not want to confuse myself with the existing condition
    // hence wrapping the whole thing with the `entity_type` condition instead.
    // Not ideal and the condition needs to be tidied up. Better yet if the
    // entire BC flow gets rewritten.
    if (entity_type !== "company") {
      if (
        !form_values.email ||
        !this.refs.party_details.isEmailValid() ||
        (form_values.email &&
          form_values.email.length > 0 &&
          !re.test(form_values.email))
      ) {
        return false;
      }
    }

    if (canSelectSignatoryApplicant && isBlank(form_values.is_applicant)) {
      return false;
    }

    if (
      form_values.first_name &&
      form_values.first_name.length > 0 &&
      form_values.last_name &&
      form_values.last_name.length > 0 &&
      this.isPersonalFormValuesValid(entity_type, form_values)
    ) {
      return true;
    }
    return false;
  };

  isPersonalFormValuesValid = (entityType, formValues) => {
    const { hasToBeApplicant, form_values } = this.props;
    const { form_errors } = this.state;

    if (entityType !== "personal") {
      return true;
    }

    if (
      isBlank(formValues.person_postal_address_api_id) &&
      isPresent(formValues.person_postal_address) &&
      (formValues.person_postal_address.full_address || "").length > 0
    ) {
      return false;
    }

    if (hasToBeApplicant && !formValues.is_applicant) return false;

    return (
      formValues.residential_api_id &&
      formValues.residential_type &&
      formValues.contact_phone_number &&
      formValues.contact_country_code
    );
  };

  checkFormValid = target => {
    const { form_errors, error_messages } = this.state;
    const { form_values, hasToBeApplicant } = this.props;
    const errors = Object.assign({}, this.state.form_errors);

    switch (target.id || target.name) {
      case "first_name":
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: error_messages[target.id],
            },
          });
        } else if (
          ["sir", "lord", "princess", "lady"].includes(
            target.value.toLowerCase(),
          )
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: error_messages["title_invalid"],
            },
          });
        } else {
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case "last_name":
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: error_messages[target.id],
            },
          });
        } else {
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case "residential_address":
        if (
          !form_values ||
          !form_values.residential_api_id ||
          form_values.residential_api_id === null
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: error_messages[target.id],
            },
          });
        } else {
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case "residential_api_id":
        if (!target.value) {
          this.setState({
            form_errors: {
              ...form_errors,
              ["residential_address"]: error_messages["residential_address"],
            },
          });
        } else {
          delete errors["residential_address"];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case "residential_type":
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              ["residential_type"]: error_messages["residential_type"],
            },
          });
        } else {
          delete errors["residential_type"];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case "contact_phone_number":
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              ["contact_phone_number"]: error_messages["contact_phone_number"],
            },
          });
        } else {
          delete errors["contact_phone_number"];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case "contact_country_code":
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              ["contact_country_code"]: error_messages["contact_country_code"],
            },
          });
        } else {
          delete errors["contact_country_code"];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case "person_postal_address":
        if (
          isBlank(form_values.person_postal_address_api_id) &&
          isPresent(form_values.person_postal_address) &&
          (form_values.person_postal_address.full_address || "").length > 0
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              person_postal_address: error_messages["person_postal_address"],
            },
          });
        } else {
          delete errors["person_postal_address"];
          this.setState({ form_errors: errors });
        }
        break;
      case "is_applicant":
        const value = target.processedValue;
        if (isBlank(value)) {
          this.setState({
            form_errors: {
              ...form_errors,
              is_applicant: error_messages.is_applicant,
            },
          });
        } else {
          delete errors.is_applicant;
          this.setState({ form_errors: errors });
        }
        if (hasToBeApplicant) {
          if (!value) {
            this.setState({
              form_errors: {
                ...form_errors,
                is_applicant:
                  "Please select at least one person as the applicant",
              },
            });
          } else {
            delete errors["is_applicant"];
            this.setState({ form_errors: errors });
          }
        }
        break;
    }
  };

  handleChange = event => {
    const { dispatch, component_index } = this.props;
    if (event.hasOwnProperty("target")) {
      this.checkFormValid(event.target);
    }
    dispatch(
      setEntityPartyDetailsData(
        component_index,
        event.target.name,
        event.target.value,
      ),
    );
  };

  removeEntityPartyDetails = () => {
    const { dispatch, party_count, values_count, form_values } = this.props;
    if (values_count > party_count) {
      dispatch(removeEntityPartyDetails(party_count));
    }
  };

  handleBlur = event => {
    const { dispatch, component_index } = this.props;

    if (
      event.target.name !== "residential_address" &&
      event.target.name !== "person_postal_address"
    ) {
      dispatch(
        setEntityPartyDetailsData(
          component_index,
          event.target.name,
          event.target.value && event.target.value.trim(),
        ),
      );
    }
    this.checkFormValid(event.target);
  };

  _emailChangeCallback = () => {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete());
  };

  _emailChangeHandler = (value, isValid) => {
    const { dispatch, component_index } = this.props;
    const { form_errors } = this.state;

    dispatch(setEntityPartyDetailsData(component_index, "email", value));

    if (isValid) {
      const { email, ...rest } = form_errors;
      this.setState(
        {
          form_errors: { ...rest },
        },
        this._emailChangeCallback,
      );
    } else {
      this.setState(
        {
          form_errors: { ...form_errors, email: true },
        },
        this._emailChangeCallback,
      );
    }
  };

  handleResidentialChange = event => {
    const { dispatch, component_index, entity_region } = this.props;
    dispatch(
      setEntityPartyDetailsData(component_index, "residential_address", {
        full_address: event.target.value,
      }),
    );
    if (event.target.value.length > 0) {
      event.persist();
      dispatch(
        lookupResidentialAddress(
          component_index,
          event.target.value,
          entity_region,
        ),
      );
    }
  };

  handleResidentialClick = value => {
    const { dispatch, component_index } = this.props;
    dispatch(
      setEntityPartyDetailsData(component_index, "residential_api_id", value),
    );
    this.checkFormValid({ id: "residential_api_id", value: value });
  };

  handleResidentialTypeChange = event => {
    const { dispatch, component_index } = this.props;
    if (event.hasOwnProperty("target")) {
      this.checkFormValid(event.target);
    }
    dispatch(
      setEntityPartyDetailsData(
        component_index,
        "residential_type",
        event.target.value.trim(),
      ),
    );
  };

  handleIsApplicantChange = event => {
    const { dispatch } = this.props;
    const value = event.target.value;

    const willAffectPages = dispatch(displayDataLostWarning());
    if (willAffectPages) {
      return this.setState({
        modal: {
          display: true,
          value,
        },
      });
    }

    this.dispatchIsApplicantChange(value);
  };

  dispatchIsApplicantChange = (value, isAffecting = false) => {
    const { application, dispatch, component_index } = this.props;
    const affectedPages = dispatch(getAffectedPages());

    const processedValue = value === "Yes";
    this.checkFormValid({ id: "is_applicant", processedValue });

    let applicantEmail = "";
    if (processedValue) {
      applicantEmail = get(
        application,
        "attributes.consumer_contact_email",
        "",
      );
    }
    this._emailChangeHandler(applicantEmail, true);

    dispatch(
      toggleIsApplicant({
        index: component_index,
        isApplicant: processedValue,
      }),
    );

    if (isAffecting && affectedPages.length > 0) {
      dispatch(setAffectedPages(affectedPages));
    }
  };

  handlePostalAddressChange = event => {
    const { dispatch, component_index, entity_region } = this.props;
    const value = event.target.value;

    dispatch(
      setEntityPartyDetailsData(component_index, "person_postal_address", {
        full_address: value,
      }),
    );

    if (event.target.value.length > 0) {
      event.persist();
      dispatch(
        lookupPersonPostalAddress(component_index, value, entity_region),
      );
    }
  };

  handlePostalAddressClick = value => {
    const { dispatch, component_index } = this.props;

    dispatch(
      setEntityPartyDetailsData(
        component_index,
        "person_postal_address_api_id",
        value,
      ),
    );
    this.checkFormValid({ id: "person_postal_address_api_id", value });
  };

  countryCodeHandleChange = target => {
    const { dispatch, component_index } = this.props;
    if (target) {
      this.checkFormValid(target);
    }
    dispatch(
      setEntityPartyDetailsData(
        component_index,
        "contact_country_code",
        target.value,
      ),
    );
  };

  render() {
    const {
      application,
      dispatch,
      canSelectSignatoryApplicant,
      entity_type,
      form_values,
      title,
    } = this.props;

    const { form_errors, modal } = this.state;
    const addonRuleKeys = dispatch(getAffectedPages());

    return (
      <Fragment>
        <section className={styles.section}>
          <div className={styles.row}>
            <div className={styles.full_col}>
              <PanelTitle text={title} margin_bottom="1em" />
            </div>
          </div>
          <div className={styles.row}>
            <BusinessPartyDetailsForm
              key="party_details"
              ref="party_details"
              application={application}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              emailChangeHandler={this._emailChangeHandler}
              values={form_values}
              errors={form_errors}
              entity_type={entity_type}
              canSelectSignatoryApplicant={canSelectSignatoryApplicant}
              handleResidentialChange={this.handleResidentialChange}
              handleResidentialClick={this.handleResidentialClick}
              handleResidentialTypeChange={this.handleResidentialTypeChange}
              handleIsApplicantChange={this.handleIsApplicantChange}
              handlePostalAddressChange={this.handlePostalAddressChange}
              handlePostalAddressClick={this.handlePostalAddressClick}
              onCountryCodeChangeHandler={this.countryCodeHandleChange}
            />
          </div>
        </section>
        {modal.display && (
          <SignatorySwitchModal
            affectedPages={addonRuleKeys}
            closeModal={() => this.setState({ modal: { show: false } })}
            handleSubmit={() =>
              this.dispatchIsApplicantChange(modal.value, true)
            }
          />
        )}
      </Fragment>
    );
  }
}

const defaults = {
  title: "A few details on index of total entity_type",
  title_trust: "A few details on each trustee",
};

export default connect((state, ownProps) => {
  const index = ownProps.component_index;
  let title = "";
  let values = {};
  let hasToBeApplicant = false;

  if (index < state.cob_business.entity_party_details_values.length) {
    values = state.cob_business.entity_party_details_values[index];
  }
  if (state.cob_business.entity_type !== null) {
    if (
      state.cob_business.entity_type === "company" &&
      state.cob_business.entity_region === "AU"
    ) {
      title = defaults.title.replace("entity_type", "director/s");
    }
    if (state.cob_business.entity_type === "partnership") {
      title = defaults.title.replace("entity_type", "partner/s");
    }
    if (state.cob_business.entity_type === "trust") {
      title = defaults.title.replace("entity_type", "trustee/s");
    }
    if (state.cob_business.entity_type === "personal") {
      title = defaults.title.replace("entity_type", "individual/s");
    }
    title = title.replace("index", index + 1);
    title = title.replace("total", state.cob_business.entity_party_count);
  }

  const signatoryApplicantIndex = state.cob_business.entity_party_details_values.findIndex(
    person => person.is_applicant,
  );

  if (state.cob_business.entity_type === "personal") {
    const lastPerson = index + 1 == state.cob_business.entity_party_count;
    hasToBeApplicant =
      lastPerson &&
      (signatoryApplicantIndex === -1 || signatoryApplicantIndex === index);
  }
  const canSelectSignatoryApplicant =
    requiresSignatories() &&
    (signatoryApplicantIndex === -1 || signatoryApplicantIndex === index);
  return {
    canSelectSignatoryApplicant,
    entity_region: state.cob_business.entity_region,
    entity_type: state.cob_business.entity_type,
    form_values: values,
    hasToBeApplicant,
    party_count: state.cob_business.entity_party_count || 5,
    title,
    values_count: state.cob_business.entity_party_details_values.length,
  };
})(BusinessPartyDetails);
