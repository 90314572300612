import Integration from "models/integrations/Integration";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

import styles from "../css/Integrations.css";
import IntegrationItem from "./IntegrationItem";

class IntegrationList extends React.Component {
  blurb() {
    return (
      <div>
        <p>
          Connecting apps will enable 1Centre to automatically create contact
          information within the external application you choose. Each time a
          new trade account is approved, 1Centre will automatically send the
          applicants details to each of your connected apps.
        </p>
        <p>
          To connect apps with 1Centre select the app below that you would like
          to connect with and follow the instructions.  If you’d like 1Centre to
          look at connecting with other apps please let us know and we’ll see what
          we can do, email us at - <a href="mailto:support@1centre.com">support@1centre.com</a>
        </p>
      </div>
    );
  }

  componentDidMount() {
    Integration.loadAll();
  }

  connect(service) {}

  integrationItems() {
    const { integrations } = this.props;

    const list = [];

    for (let i = 0, l = integrations.length; i < l; i++) {
      const integration = integrations[i];
      list.push(<IntegrationItem key={i} integration={integration} />);
    }

    return list;
  }

  render() {
    const { loading, integrations, options, ...rest } = this.props;

    return (
      <div>
        <div className={styles.inner_header_container}>
          <span>
            <span className={styles.inner_header}>
              {"Connected apps"}
            </span>
          </span>
        </div>
        <div className={styles.body_container}>
          <div className={styles.info_body}>
            {this.blurb()}
          </div>
          <p>Your connected apps</p>
          <div style={{ display: "flex", flexWrap: "wrap" }} >
            {this.integrationItems()}
          </div>
        </div>
      </div>
    );
  }
}

module.exports = connect((state, ownProps) => {
  const integration_list = state.models.integrations.config;
  const integrations = [];

  for (let i = 0, l = integration_list.length; i < l; i++) {
    const integration =
      state.models.integrations[integration_list[i]] ||
      new Integration({ attributes: { service: integration_list[i] } });
    integrations.push(integration);
  }

  return {
    integrations,
  };
})(IntegrationList);
