/* Import libraries */
/* Import components */
import Button from "modules/shared/components/inputs/Button";
import VideoModal from "modules/shared/components/widgets/interactive/VideoModal";
import Loader from "modules/shared/components/widgets/static/Loader";
import SectionBadge from "modules/shared/components/widgets/static/SectionBadge";
import SectionDescription from "modules/shared/components/widgets/static/SectionDescription";
import SectionTitle from "modules/shared/components/widgets/static/SectionTitle";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

import {
  getCurrentPricingPlan,
  showPricingPlan,
} from "../../../pricing-plans/actions";
/* Import actions */
import {
  createApplicationForExistingConsumer,
  createApplicationForExistingConsumerWithNewEmail,
} from "../../actions/invite";
/* Import CSS */
import styles from "./css/InviteSend.css";

class InviteExistingSend extends React.Component {
  state = {
    showLoader: true,
  };

  UNSAFE_componentWillMount() {
    const {
      dispatch,
      consumerEntity,
      application_type,
      permissions,
      iufAnswers,
    } = this.props;

    dispatch(
      getCurrentPricingPlan(null, data => {
        if (
          data.attributes.plan_no === 0 ||
          data.attributes.application_tally < data.attributes.application_max
        ) {
          const hasNewUser = !!consumerEntity.newUser;
          const attributes = {
            application_type,
            consumer_id: consumerEntity.entityId,
            iufAnswers,
            permissions,
          };

          if (hasNewUser) {
            attributes["consumer_user"] = consumerEntity.newUser;
            dispatch(
              createApplicationForExistingConsumerWithNewEmail(attributes),
            );
          } else {
            attributes["consumer_user"] = consumerEntity.user;
            dispatch(
              createApplicationForExistingConsumer(attributes),
            );
          }
          this.setState({
            email: attributes["consumer_user"].email,
          });
        } else {
          dispatch(showPricingPlan(true, "application", "/dashboard/home"));
        }
      }),
    );
  }

  loadingComplete = () => {
    this.setState({
      showLoader: false,
    });
  }

  render() {
    const {
      title,
      return_path,
      saving,
      consumerEntity,
      application_type,
    } = this.props;
    const { showLoader } = this.state;
    const videlName = `${application_type}_flow`;

    if (showLoader) {
      return <Loader loading={saving} handleComplete={this.loadingComplete} />;
    }

    let modal_markup;

    return (
      <div className={styles.containerFlex}>
        <section className={styles.blue_section}>
          <div className={styles.row}>
            <div className={styles.panel}>
              <SectionTitle white={true} text={title} />
              <SectionBadge icon="email" />
            </div>
          </div>
          <div className={styles.description_row}>
            <div className={styles.description}>
              <SectionDescription
                text={`An application email has been sent to ${consumerEntity.name} at ${this.state.email}`}
              />
              <div style={{ fontSize: "10px" }}>
                Click <VideoModal videoName={videlName} color={"inherit"} /> to
                see how your customer will complete the trade account.
              </div>
              <div className={styles.button_outer}>
                <Button
                  css_style="button_white_outer"
                  text="ok"
                  handleClick={e => {
                    browserHistory.push(return_path);
                  }}
                />
              </div>
            </div>
          </div>
          {modal_markup}
        </section>
      </div>
    );
  }
}

const defaults = {
  title: "Application invite sent!",
};

export default connect(state => {
  return {
    consumerEntity: state.cns_invite.company_autosuggest_selected_details,
    iufAnswers: state.cns_invite.iuf_answers,
    return_path: state.cns_invite.return_path,
    saving: state.cns_invite.saving,
    title: defaults.title,
  };
})(InviteExistingSend);
