import api from "../../api";

import {
  LOAD_STORE_NAMES_START,
  LOAD_STORE_NAMES_SUCCESS,
  LOAD_STORE_NAMES_ERROR,
  LOAD_STORE_NAME_START,
  LOAD_STORE_NAME_SUCCESS,
  LOAD_STORE_NAME_ERROR,
  UPDATE_STORE_NAME_START,
  UPDATE_STORE_NAME_SUCCESS,
  UPDATE_STORE_NAME_ERROR,
  DELETE_STORE_NAME_START,
  DELETE_STORE_NAME_SUCCESS,
  DELETE_STORE_NAME_ERROR,
  CREATE_STORE_NAMES_START,
  CREATE_STORE_NAMES_SUCCESS,
  CREATE_STORE_NAMES_ERROR,
  UPDATE_STORE_NAME_ALIAS_START,
  UPDATE_STORE_NAME_ALIAS_SUCCESS,
  UPDATE_STORE_NAME_ALIAS_ERROR,
} from "./constants";

export function loadStoreNames(options) {
  return (dispatch, getState) => {
    dispatch({
      type: LOAD_STORE_NAMES_START,
    });

    var trading_names = api(
      "trading_names",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    trading_names.getTradingNames(
      result => {
        dispatch({
          type: LOAD_STORE_NAMES_SUCCESS,
          payload: result,
        });
      },
      error => {
        dispatch({
          type: LOAD_STORE_NAMES_ERROR,
          payload: error,
        });
      },
      { params: options },
    );
  };
}

export function loadStoreName(id, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: LOAD_STORE_NAME_START,
    });

    var trading_names = api(
      "trading_names",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    trading_names.getTradingName(
      id,
      result => {
        dispatch({
          type: LOAD_STORE_NAME_SUCCESS,
          payload: result,
        });
        if (callback) {
          dispatch(callback);
        }
      },
      error => {
        dispatch({
          type: LOAD_STORE_NAME_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function updateStoreName(id, attributes, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_STORE_NAME_START,
    });

    var trading_names = api(
      "trading_names",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    trading_names.updateTradingName(
      id,
      result => {
        dispatch({ type: UPDATE_STORE_NAME_SUCCESS });
        callback && dispatch(callback);
      },
      error => {
        dispatch({
          type: UPDATE_STORE_NAME_ERROR,
          payload: error,
        });
      },
      { params: attributes },
    );
  };
}

export function deleteStoreName(id, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_STORE_NAME_START,
    });

    var trading_names = api(
      "trading_names",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    trading_names.deleteTradingName(
      id,
      result => {
        dispatch({
          type: DELETE_STORE_NAME_SUCCESS,
        });
        callback && dispatch(callback);
      },
      error => {
        dispatch({
          type: DELETE_STORE_NAME_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function createStoreNames(names, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_STORE_NAMES_START,
    });

    var trading_names = api(
      "trading_names",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    trading_names.batchCreateTradingName(
      result => {
        dispatch({
          type: CREATE_STORE_NAMES_SUCCESS,
          meta: {
            mixpanel: {
              event: "Store selector - adding stores",
              props: {
                distinct_id: getState().current_user.data.data.id,
                "Entity ID": getState().current_user.current_entity.id,
                "name list": names,
              },
            },
          },
        });
        callback && dispatch(callback);
      },
      error => {
        dispatch({
          type: CREATE_STORE_NAMES_ERROR,
          payload: error,
        });
      },
      { params: names },
    );
  };
}

export function isTradingNameExist(new_name, callback) {
  return (dispatch, getState) => {
    var entities = api(
      "trading_names",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    entities.isTradingNameExist(
      result => {
        callback(result.data.data.existence);
      },
      error => {},
      { params: { trading_name: new_name } },
    );
  };
}

export function updateStoreNameAlias(new_name) {
  return (dispatch, getState) => {
    var entity_id = getState().current_user.current_entity.id;
    var entities = api(
      "entities",
      getState().current_user.access_token,
      entity_id,
    );
    dispatch({ type: UPDATE_STORE_NAME_ALIAS_START });
    entities.updateEntity(
      entity_id,
      { store_name_alias: new_name },
      result => {
        dispatch({
          type: UPDATE_STORE_NAME_ALIAS_SUCCESS,
          meta: {
            mixpanel: {
              event: "Store selector - set up name",
              props: {
                distinct_id: getState().current_user.data.data.id,
                "Entity ID": getState().current_user.current_entity.id,
                "store name": new_name,
              },
            },
          },
        });
      },
      error => {
        dispatch({ type: UPDATE_STORE_NAME_ALIAS_ERROR, payload: error });
      },
    );
  };
}
