/* eslint-disable max-classes-per-file */
export function humanize(string, proper = true) {
  if (typeof string !== "string") return string;

  let capitalizeRegex = /(^[a-z]|\W[a-z])/g;

  if (proper) capitalizeRegex = /^[a-z]/g;

  return string
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .trim()
    .replace(/\b[A-Z][a-z]+\b/g, word => word.toLowerCase())
    .replace(capitalizeRegex, first => first.toUpperCase());
}
/* eslint-enable max-classes-per-file */
