import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";

import CreditCardInfo from "./CreditCardInfo";
import AccountInvoices from "./AccountInvoices";

import styles from "./css/Payment.css";

import * as paymentActions from "../actions";
import { isRegular } from "modules/shared/helpers/headquarterDetect";

class Payment extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(paymentActions.getUsers());
    dispatch(paymentActions.getCard());
    dispatch(paymentActions.getSubscription());
    //dispatch(paymentActions.getTransactions())
    dispatch(paymentActions.getInvoices());
  }
  render() {
    const { cardLoading, usersLoading, invoicesLoading } = this.props.payment;
    if (usersLoading || invoicesLoading) {
      return <SimpleLoader />;
    }
    return (
      <div>
        {isRegular() && <CreditCardInfo />}
        {!!this.props.payment.invoices.length && (
          <AccountInvoices transactions={this.props.payment.invoices} />
        )}
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    payment: state.payment,
    entityId: state.current_user.current_entityid,
  }),
  dispatch => ({
    paymentActions: bindActionCreators(paymentActions, dispatch),
    dispatch,
  }),
)(Payment);
