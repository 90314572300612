import React from "react";
import Select from "react-select";

import styles from "./css/RoundedDropdown.css";

var RoundedDropdown = createClass({
  render: function() {
    const {
      minimum,
      maximum,
      value,
      defaultValue,
      handleChange,
      label,
      list,
      wide,
      disabled,
    } = this.props;
    let labelElement = null,
      wrapperStyles = styles.selectable;

    if (label) {
      labelElement = <p className={styles.label}>{label}</p>;
    }

    if (wide) {
      wrapperStyles = styles.wideSelectable;
    }

    let options = [];

    if (list) {
      options = list;
    } else {
      for (let i = minimum; i <= maximum; i++) {
        options.push({ label: i, value: i });
      }
    }
    return (
      <div className={wrapperStyles}>
        <Select
          searchable={false}
          clearable={false}
          value={value || defaultValue}
          options={options}
          onChange={handleChange}
          disabled={disabled}
        />
        {labelElement}
      </div>
    );
  },
});

export default RoundedDropdown;
