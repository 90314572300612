/* Import libraries */
import React from "react";
import { Motion, spring } from "react-motion";

/* Import CSS */
import styles from "./css/IllustrationDoor.css";

var IllustrationDoor = createClass({
  render: function() {
    const { percentage } = this.props;
    var percentage_val = 100 - percentage;

    return (
      <div className={styles.outer}>
        <Motion defaultStyle={{ x: 0 }} style={{ x: spring(percentage_val) }}>
          {value => (
            <svg
              className={styles.icon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 179 185"
            >
              <defs>
                <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" className={styles.white} />
                  <stop offset={value.x + "%"} className={styles.white} />
                  <stop offset={value.x + "%"} className={styles.blue} />
                  <stop offset="100%" className={styles.blue} />
                </linearGradient>
              </defs>
              <g fill="url(#gradient)">
                <path
                  d="M289.58,208.23v-61a1.5,1.5,0,0,0-1.5-1.5H219.63a1.5,1.5,0,0,0-1.5,1.5v61a1.5,1.5,0,0,0,1.5,1.5h68.45A1.5,1.5,0,0,0,289.58,208.23Zm-68.45-59.51h65.45v58H221.13ZM289.58,287.6V218.42a1.5,1.5,0,0,0-1.5-1.5H219.63a1.5,1.5,0,0,0-1.5,1.5h0V287.6a1.5,1.5,0,0,0,1.5,1.5h68.45A1.5,1.5,0,0,0,289.58,287.6Zm-68.45-67.68h65.45V286.1H221.13Zm59.42,11.19a4.94,4.94,0,1,0-5.1,4.78h.16a4.87,4.87,0,0,0,5-4.78Zm-3,0a2,2,0,1,1-2.11-1.77h.17a1.87,1.87,0,0,1,2,1.78Zm66,89.86h0a1.49,1.49,0,0,0-1.49-1.49H313.53V298.15a1.5,1.5,0,0,0-1.5-1.5H195.68a1.5,1.5,0,0,0-1.5,1.5l.3,21.22-28.91.2a1.41,1.41,0,0,0-1.4,1.41h0a1.5,1.5,0,0,0,1.5,1.5H342a1.5,1.5,0,0,0,1.53-1.47h0ZM197.18,299.65H310.53v19.82H197.18Zm99.76-6a1.5,1.5,0,0,1-1.5-1.5v-152H212.27v152a1.5,1.5,0,0,1-3,0V138.69a1.5,1.5,0,0,1,1.5-1.5H297a1.5,1.5,0,0,1,1.5,1.5V292.15a1.5,1.5,0,0,1-1.5,1.5ZM236,182.77c0,2.69,1.46,4.36,4.49,4.36,2.84,0,4.45-1.71,4.45-4.36s-1.51-4.36-4.45-4.36a4.12,4.12,0,0,0-4.49,4.36Zm4.48,2.78c-1.28,0-1.81-.9-1.81-2.78s.59-2.8,1.81-2.8c1.06,0,1.81.94,1.81,2.8s-.57,2.78-1.79,2.78Zm9.19-1.3c2.16,0,3.94-.74,3.94-2.93,0-2-1.61-2.79-3.72-2.79h-3.45V187h2.45v-2.71Zm-.78-1.6v-2.57h1c.84,0,1.33.36,1.33,1.27a1.18,1.18,0,0,1-1.34,1.3ZM255,187h6.31v-1.61H257.4v-1.78H261V182h-3.6v-1.82h3.73v-1.63H255Zm7.9,0H265v-5.31h0a8.83,8.83,0,0,0,.83,1.53l2.5,3.78h2.44v-8.43h-2.2v5.12h0a7.85,7.85,0,0,0-.77-1.48l-2.38-3.65h-2.59Zm15,7.48V172a1.5,1.5,0,0,0-1.5-1.5h-45a1.5,1.5,0,0,0-1.5,1.5v22.43a1.5,1.5,0,0,0,1.5,1.5h44.92a1.5,1.5,0,0,0,1.51-1.49h0ZM233,173.55H274.9V193h-42Zm23-15.38a2.14,2.14,0,1,1-2.14-2.14h0A2.14,2.14,0,0,1,256,158.17Zm21,12.29L257.53,157v2c0,2.3,1.08,2.43,2.86,3.68l15.22,10.62a1.44,1.44,0,0,0,2-.58l0-.09a1.7,1.7,0,0,0-.61-2.18Zm-27-13.34-19.35,13.34a1.7,1.7,0,0,0-.62,2.2,1.49,1.49,0,0,0,1.33.86,1.4,1.4,0,0,0,.7-.19l17.08-11.53c1.78-1.24.86-2.38.86-4.67Z"
                  transform="translate(-164.17 -137.19)"
                />
              </g>
            </svg>
          )}
        </Motion>
      </div>
    );
  },
});

module.exports = IllustrationDoor;
