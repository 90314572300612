import React, { ReactElement, ReactNode } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Actions, Wrapper } from './styles';
import { THEME_COLORS } from 'variables/theme';

type Props = {
  actions?: ReactNode,
  faProps?: FontAwesomeIconProps,
  message: ReactNode | string,
}

function Message({
  actions,
  faProps,
  message,
}: Props): ReactElement {
  const defaultFaProps = {
    color: THEME_COLORS.primaryLight,
    size: '4x',
  } as FontAwesomeIconProps;

  return (
    <Wrapper>
      { faProps && <FontAwesomeIcon {...defaultFaProps} {...faProps} /> }
      {
        typeof message === 'string'
        ? <p>{message}</p>
        : message
      }
      {
        actions && (
          <Actions>
            {actions}
          </Actions>
        )
      }
    </Wrapper>
  )
}

export default Message
