import { isMobile } from "modules/shared/helpers/mobileDetect";
import PropTypes from "prop-types"
import React from "react"

import { Wrapper } from "./styles";

function Actions({
  cameraOnly,
  open,
  setCameraMode,
}) {
  const displayCameraButton = cameraOnly ? cameraOnly : !isMobile();

  return (
    <Wrapper>
      {
        !cameraOnly && (
          <button
            className="button is-primary is-rounded"
            type="button"
            onClick={open}
            tabIndex={0}
          >
            Browse
          </button>
        )
      }
      {
        displayCameraButton && (
          <button
            className="button is-primary is-rounded"
            type="button"
            onClick={() => setCameraMode(true)}
            tabIndex={0}
          >
            Take photo
          </button>
        )
      }
    </Wrapper>
  )
}

Actions.propTypes = {
  open: PropTypes.func.isRequired,
  setCameraMode: PropTypes.func.isRequired,
}

export default Actions

