import {
  AUTO_DECISIONS_SET_COMPANYS,
  AUTO_DECISIONS_SET_TIME_IN_BUSINESS,
  AUTO_DECISIONS_SET_VALUE,
  AUTO_DECISIONS_CREATE_RULESET,
  AUTO_DECISIONS_CREATE_RULESET_SUCCESS,
  AUTO_DECISIONS_CREATE_RULESET_ERROR,
  AUTO_DECISIONS_UPDATE_RULESET,
  AUTO_DECISIONS_UPDATE_RULESET_SUCCESS,
  AUTO_DECISIONS_UPDATE_RULESET_ERROR,
  AUTO_DECISIONS_SET_RULESET,
  AUTO_DECISIONS_RESET_CURRENT,
} from "./constants";

const auto_decisions_defaults = {
  current_rule_set: {
    attributes: {
      legal_types: [],
      trade_reference_count: 1,
      guarantors_count: 1,
      min_credit_value: 0,
      max_credit_value: 0,
    },
  },
};

export function autoDecisionReducer(state = auto_decisions_defaults, action) {
  switch (action.type) {
    case AUTO_DECISIONS_SET_COMPANYS:
      return {
        ...state,
        current_rule_set: {
          ...state.current_rule_set,
          attributes: {
            ...state.current_rule_set.attributes,
            legal_types: action.payload,
          },
        },
      };
    case AUTO_DECISIONS_SET_TIME_IN_BUSINESS:
      return {
        ...state,
        current_rule_set: {
          ...state.current_rule_set,
          attributes: {
            ...state.current_rule_set.attributes,
            time_in_business: action.payload,
          },
        },
      };
    case AUTO_DECISIONS_SET_VALUE:
      return {
        ...state,
        current_rule_set: {
          ...state.current_rule_set,
          attributes: {
            ...state.current_rule_set.attributes,
            [action.payload.property]: action.payload.value,
          },
        },
      };
    case AUTO_DECISIONS_SET_RULESET:
      return { ...state, current_rule_set: action.payload };
    case AUTO_DECISIONS_CREATE_RULESET:
      return { ...state, updating: true };
    case AUTO_DECISIONS_CREATE_RULESET_SUCCESS:
      return { ...state, updating: false };
    case AUTO_DECISIONS_CREATE_RULESET_ERROR:
      return { ...state, updating: false, errors: action.payload };
    case AUTO_DECISIONS_UPDATE_RULESET:
      return { ...state, updating: true };
    case AUTO_DECISIONS_UPDATE_RULESET_SUCCESS:
      return { ...state, updating: false };
    case AUTO_DECISIONS_UPDATE_RULESET_ERROR:
      return { ...state, updating: false, errors: action.payload };
    case AUTO_DECISIONS_RESET_CURRENT:
      return {
        ...state,
        current_rule_set: auto_decisions_defaults.current_rule_set,
      };
    default:
      return { ...state };
  }
}
