/* Import libraries */
import React from "react";
import { Link } from "react-router";

/* Import styles */
import styles from "./css/EditButton.css";

import Icon from "modules/shared/components/svg/Icon";

var EditButton = createClass({
  render: function() {
    const { link, ...rest } = this.props;
    var style = styles.button;

    return (
      <Link className={style} to={link} {...rest}>
        <Icon icon="edit" />
      </Link>
    );
  },
});

module.exports = EditButton;
