import React, { Component } from "react";
import { connect } from "react-redux";

import styles from "./css/PricingPlans.css";

export class Plan extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      plan,
      selectedPlan,
      currentPricingPlanNo,
      handleClick,
    } = this.props;
    var features = new Array(),
      price_text;
    var join_text =
      plan.name === "Enterprise Unlimited" ? "Email us now" : "Join us now";

    for (var i = 0; i < plan.features.length; i++) {
      features.push(<li key={i}>{plan.features[i]}</li>);
    }

    if (plan.name === "Pro Plan") {
      price_text = (
        <div className={styles.price_right}>
          <div>+GST</div>
          <div>Per user per month</div>
        </div>
      );
    }

    return (
      <div
        className={styles.pricing_plan_wrapper}
        onClick={handleClick.bind(null, plan)}
      >
        <div
          className={
            currentPricingPlanNo === plan.no
              ? styles.pricing_plan
              : styles.pricing_plan + " " + styles.plan_inactive
          }
        >
          <div
            className={
              currentPricingPlanNo === plan.no
                ? styles.plan_header
                : styles.plan_header + " " + styles.header_inactive
            }
          >
            <div className={styles.plan_name}>{plan.name}</div>
            <p className={styles.plan_description}>{plan.description}</p>
            <div className={styles.plan_price}>
              <div className={styles.price_left}>{plan.price}</div>
              {price_text}
            </div>
          </div>
          <div className={styles.plan_body}>
            <span className={styles.join_btn}>{join_text}</span>
            <p className={styles.features_title}>Features:</p>
            <ul className={styles.features}>{features}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state, ownProps) => ({}))(Plan);
