import REGION from "types/region";

const AU_BANK_NUMBER_LENGTH = 6;

const validateAustraliaBankNumber = (bankNumber: string): boolean =>
  bankNumber.length === AU_BANK_NUMBER_LENGTH;

const validateBankNumber = (
  region: "au" | "nz" | REGION,
  bankNumber: string | null | undefined,
): boolean => {
  let isValid = true;

  switch (region.toUpperCase()) {
    case "NZ":
      isValid = true;
      break;
    case "AU":
      isValid = validateAustraliaBankNumber(bankNumber || "");
      break;
  }

  return isValid;
};

export default validateBankNumber;
