/* Import libraries */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";

/* Import CSS */
import styles from "./css/Check.css";

/* Import actions */
import {
  loadTrm,
  createTradeReferenceCheck,
  updateTradeReferenceCheck,
} from "../actions";

/* Import components */
import AdminInnerTitle from "modules/shared/components/widgets/static/AdminInnerTitle";
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import HomeButton from "modules/shared/components/inputs/HomeButton";
import PlusButton from "modules/shared/components/inputs/PlusButton";
import LabeledInformation from "modules/shared/components/widgets/static/LabeledInformation";
import Button from "modules/shared/components/inputs/Button";
import CheckForm from "../forms/CheckForm";
import NoteModal from "./NotesModal";
import NotesTable from "../forms/NotesTable";
import AdminTitle from "modules/shared/components/widgets/static/AdminTitle";

var Check = createClass({
  componentWillMount: function() {
    const { dispatch, params } = this.props;
    dispatch(loadTrm(params.id));
  },

  getInitialState: function() {
    return {
      form_values: {},
      newNotes: [],
      notesToDelete: [],
      form_errors: {},
      prefix: "$",
      suffix: "",
    };
  },

  checkValid: function(target) {
    const { form_errors, form_values } = this.state;

    if (!target.value || target.value.length < 1) {
      this.setState({
        form_errors: {
          ...form_errors,
          [target.id]: true,
        },
      });
    } else {
      const { [target.id]: value, ...rest } = form_errors;
      this.setState({
        form_errors: { ...rest },
      });
    }
  },

  checkCompleted: function() {
    const { form_values } = this.state;
    if (
      !form_values.confirm_referee_name ||
      form_values.confirm_referee_name.length < 1 ||
      (!form_values.relationship_type ||
        form_values.relationship_type.length < 1) ||
      (!form_values.relationship_length ||
        form_values.relationship_length.length < 1) ||
      (!form_values.last_purchase || form_values.last_purchase.length < 1) ||
      (!form_values.ammount_overdue_for ||
        form_values.ammount_overdue_for.length < 1) ||
      (!form_values.payment_habits || form_values.payment_habits.length < 1)
    ) {
      return false;
    }
    return true;
  },

  componentDidMount: function() {
    this.loadFormData();
  },

  componentDidUpdate: function() {
    this.loadFormData();
  },

  loadFormData: function() {
    const { trm, loading } = this.props;
    const { form_values } = this.state;
    if (Object.keys(form_values).length === 0 && trm) {
      if (trm.check && trm.check.attributes) {
        let credit_limit, last_purchase, ammount_owed, ammount_overdue;

        if (trm.check.attributes.credit_limit) {
          credit_limit = trm.check.attributes.credit_limit.substr(
            0,
            trm.check.attributes.credit_limit.indexOf("."),
          );
        }
        if (trm.check.attributes.last_purchase) {
          last_purchase = moment(trm.check.attributes.last_purchase).format(
            "DD / MM / YYYY",
          );
        }
        this.setState({
          form_values: {
            confirm_referee_name: trm.check.attributes.referee_name,
            confirm_referee_position: trm.check.attributes.referee_position,
            relationship_type: trm.check.attributes.relationship_type,
            relationship_length: trm.check.attributes.relationship_length,
            credit_limit: credit_limit,
            last_purchase: last_purchase,
            ammount_owed: trm.check.attributes.ammount_owed,
            ammount_overdue: trm.check.attributes.ammount_overdue,
            ammount_overdue_for: trm.check.attributes.ammount_overdue_for,
            payment_habits: trm.check.attributes.payment_habits,
          },
        });
      }
    }
  },

  handleBlur: function(e) {
    if (e.hasOwnProperty("target")) {
      e = e.target;
    }
    this.checkValid(e);
  },

  handleChange: function(e) {
    let { form_values } = this.state;

    if (e.hasOwnProperty("target")) {
      e = e.target;
    }
    this.checkValid(e);
    if (
      e.name === "credit_limit" ||
      e.name === "ammount_owed" ||
      e.name === "ammount_overdue"
    ) {
      form_values[e.id] = this.integerise(e.value);
    } else {
      form_values[e.id] = e.value;
    }

    this.setState({
      form_values,
      submitted: false,
    });
  },

  showNoteModal: function() {
    this.setState({
      showNoteModal: true,
    });
  },

  cancelNoteModal: function() {
    this.setState({
      showNoteModal: false,
    });
  },

  addNote: function(data) {
    const { newNotes } = this.state;

    let note = {};
    if (data.file) {
      note = { ...note, attributes: { file: data.file } };
    }
    if (data.text) {
      note = { ...note, attributes: { ...note.attributes, text: data.text } };
    }
    if (note === {}) {
      this.setState({
        showNoteModal: false,
      });
      return;
    }

    let noteArray = newNotes.slice();
    noteArray.push(note);
    this.setState({
      newNotes: noteArray,
      showNoteModal: false,
    });
  },

  deleteNote: function(key) {
    const { newNotes, notesToDelete } = this.state;
    if (key.startsWith("new_notes_")) {
      var notes = newNotes.filter(n => {
        return n.key !== key;
      });
      this.setState({
        newNotes: notes,
      });
    } else {
      notesToDelete.push(key);
      this.setState({
        notesToDelete,
      });
    }
  },

  submit: function(outcome) {
    const { dispatch, trm } = this.props;
    const { form_values, newNotes, notesToDelete } = this.state;
    let ammount_owed, ammount_overdue, last_purchase;

    if (form_values.last_purchase) {
      last_purchase = moment(
        form_values.last_purchase,
        "DD / MM / YYYY",
      ).format("YYYY-MM-DD");
    }
    // if (form_values.ammount_owed) {
    //   ammount_owed = Number(form_values.ammount_owed.replace(/[^0-9\.]+/g,""));
    // }
    // if (form_values.ammount_overdue) {
    //   ammount_overdue = Number(form_values.ammount_overdue.replace(/[^0-9\.]+/g,""));
    // }

    let attributes = {
      referee_name: form_values.confirm_referee_name,
      referee_position: form_values.confirm_referee_position,
      relationship_type: form_values.relationship_type,
      relationship_length: form_values.relationship_length,
      credit_limit: form_values.credit_limit
        ? this.integerise(form_values.credit_limit)
        : null,
      last_purchase: last_purchase,
      ammount_owed: form_values.ammount_owed
        ? this.integerise(form_values.ammount_owed)
        : null,
      ammount_overdue: form_values.ammount_overdue
        ? this.integerise(form_values.ammount_overdue)
        : null,
      ammount_overdue_for: form_values.ammount_overdue_for,
      payment_habits: form_values.payment_habits,
      outcome: outcome,
    };

    if (trm.check) {
      dispatch(
        updateTradeReferenceCheck(
          trm.check.id,
          attributes,
          newNotes,
          notesToDelete,
        ),
      );
    } else {
      dispatch(createTradeReferenceCheck(trm.id, attributes, newNotes));
    }

    this.setState({
      submitted: true,
    });
  },

  integerise: function(value) {
    const { prefix, suffix } = this.state;
    if (!value || value == prefix) {
      value = 0;
    }
    return parseInt(value.toString().replace(/[^0-9]/g, ""), 10);
  },

  formatCommaInteger: function(number) {
    let integer = this.integerise(number);
    return integer.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },

  formatPreviewText: function(value) {
    const { prefix, suffix } = this.state;
    //regex puts commas between groups of 3 numbers
    return prefix + this.formatCommaInteger(value) + suffix;
  },

  render: function() {
    const { title, loading, trm, dispatch, notes, ...rest } = this.props;
    const {
      form_values,
      form_errors,
      confirmed,
      showNoteModal,
      newNotes,
      notesToDelete,
    } = this.state;

    if (loading) {
      return <SimpleLoader />;
    }

    let completed = this.checkCompleted();

    let frequencies = {
      rarely: "Rarely",
      sometimes: "Sometimes",
      often: "Often",
      very_often: "Very often",
    };

    let length = {
      less_than_1_year: "Less than a year",
      years_1_2: "1-2 Years",
      years_2_5: "2-5 Years",
      years_5_plus: "5+ Years",
    };

    let excluded_application_sources_text = "";
    if (trm.application.attributes.excluded_application_sources) {
      excluded_application_sources_text = trm.application.attributes.excluded_application_sources.join(
        ", ",
      );
    }

    let noteModal;
    if (showNoteModal) {
      noteModal = (
        <NoteModal
          handleSave={this.addNote}
          handleCancel={this.cancelNoteModal}
        />
      );
    }

    let notes_table;
    //Filter out to delete notes
    var all_notes = notes.filter(n => {
      return !notesToDelete.includes(n.id);
    });
    newNotes.forEach((note, index) => {
      note.key = "new_notes_" + index;
      all_notes.push(note);
    });
    if (all_notes.length > 0) {
      notes_table = (
        <NotesTable data={all_notes} handleDelete={this.deleteNote} {...rest} />
      );
    }

    return (
      <Fragment>
        <aside className={styles.aside}>
          <h2 className={styles.main_heading}>
            Trade references info given by applicant
          </h2>
          <div className={styles.scroll}>
            <div className={styles.block}>
              <LabeledInformation
                zindex="1002"
                label="Consumer Company"
                data={trm.application.attributes.trading_name}
                noHumanize={true}
              />
              <LabeledInformation
                zindex="1002"
                label="Previous Company"
                data={trm.attributes.previous_business_name}
                noHumanize={true}
              />
              <LabeledInformation
                zindex="1002"
                label="Consumer Contact"
                data={`${trm.consumer.attributes.key_contact_first_name} ${trm.consumer.attributes.key_contact_last_name}`}
                noHumanize={true}
              />
              <LabeledInformation
                zindex="1000"
                label="Referee's name"
                data={`${trm.attributes.first_name} ${trm.attributes.last_name}`}
                noHumanize={true}
              />
              <LabeledInformation
                zindex="999"
                label="Referee's business name"
                data={trm.attributes.business_name}
              />
              <LabeledInformation
                zindex="998"
                label="Referee's position"
                data={trm.attributes.position}
                noHumanize={true}
              />
              <LabeledInformation
                zindex="997"
                label="Referee's phone number"
                data={
                  trm.attributes.contact_country_code +
                  " " +
                  trm.attributes.contact_phone_number
                }
              />
              <LabeledInformation
                zindex="996"
                label="How long have you had a relationship?"
                data={length[trm.attributes.relationship_length]}
              />
              <LabeledInformation
                zindex="995"
                label="How often do you trade?"
                data={frequencies[trm.attributes.trade_frequency]}
              />
              <LabeledInformation
                zindex="994"
                label="Excluded Referees"
                data={excluded_application_sources_text}
              />
              <LabeledInformation
                zindex="993"
                label="Additional notes"
                data={trm.attributes.remarks}
              />
            </div>
          </div>
        </aside>
        <section className={styles.section}>
          <div className={styles.content}>
            <div class={styles.header}>
              <AdminTitle text={title} />
              <div className={styles.home}>
                <HomeButton link="/trm/list" />
              </div>
            </div>
            {noteModal}
            <p className={styles.sub_heading}>
              Confirm details <span className={styles.optional}>optional</span>{" "}
            </p>
            <CheckForm
              form_values={form_values}
              form_errors={form_errors}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              formatPreviewText={this.formatPreviewText}
            />
            {notes_table}
            <div className={styles.add_note_button}>
              <PlusButton handleClick={this.showNoteModal} />
            </div>
            <p className={styles.outcome_text}>
              Please choose the final outcome for this reference
            </p>

            <div className={styles.buttons}>
              <Button
                disabled={!completed}
                small={true}
                text="pass"
                grey={!completed}
                handleClick={this.submit.bind(null, "passed")}
              />
              <Button
                disabled={!completed}
                small={true}
                red={true}
                grey={!completed}
                text="fail"
                handleClick={this.submit.bind(null, "failed")}
              />
              <Button
                small={true}
                white={true}
                text="defer"
                handleClick={this.submit.bind(null, "deferred")}
              />
              <Button
                small={true}
                white={true}
                text="save as draft"
                handleClick={this.submit.bind(null, "in_progress")}
              />
            </div>
          </div>
        </section>
      </Fragment>
    );
  },
});

var defaults = {
  title: "Trade reference information",
};

module.exports = connect((state, ownProps) => {
  let trm = state.trade_references.trm,
    loading = state.trade_references.trm_loading;

  let notes = [];
  if (!loading && trm) {
    trm.notes.forEach(n => {
      notes.push({ ...n, key: n.id });
    });
  }

  return {
    location: ownProps.location,
    title: defaults.title,
    loading: loading || !trm,
    trm: trm,
    save_successful: state.trade_references.save_successful,
    notes,
  };
})(Check);
