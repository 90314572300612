import React, { ReactElement } from "react";
import { Prism } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FONT_SIZES } from "variables/typography";

const SyntaxHighlighter = ({
  children,
  language,
}: {
  children: any;
  language: string;
}): ReactElement => (
  <Prism
    language={language}
    style={atomDark}
    customStyle={{ fontSize: FONT_SIZES.base }}
  >
    {children}
  </Prism>
);

export default SyntaxHighlighter;

SyntaxHighlighter.defaultProps = {
  language: "json",
};
