import { isTheSameConfig } from "modules/addons/helpers";
import * as yup from "yup-latest";

export function findApplicationTypesConflict({
  allConfigs,
  currentConfig,
  currentApplicationTypes,
  createError,
}) {
  const otherActiveConfigs = allConfigs.filter(addonConfig => addonConfig.active &&
    !isTheSameConfig({ configToCompare: addonConfig, currentConfig }));

  if (otherActiveConfigs.length === 0) {
    return true;
  }

  const conflictedConfigs = [];

  otherActiveConfigs.forEach(({ latestVersion }) => {
    const {
      applicationTypes,
      name,
    } = latestVersion;

    const conflictedApplicationTypes = currentApplicationTypes.filter(
      currentApplicationType => applicationTypes.includes(currentApplicationType)
    );

    if (conflictedApplicationTypes.length === 0) {
      return;
    }

    conflictedConfigs.push({
      // @ts-ignore-next-line
      applicationTypes: conflictedApplicationTypes,
      // @ts-ignore-next-line
      name,
    });
  })

  if (conflictedConfigs.length === 0) {
    return true;
  }

// @ts-ignore-next-line
  const conflictedRulesetName = conflictedConfigs.map(config => config.name);

  return createError({
    message: `The selected entity types are conflicts with ruleset ${conflictedRulesetName.join(", ")}.`,
    path: 'application_types',
  })
}

function getSchema(
  data = {},
  additionalData = {
    allConfigs: [],
  },
) {
  const { allConfigs } = additionalData;
  return yup.object().shape({
    legal_types: yup.array()
      .min(1, "Please select at least 1 entity type."),
    application_types: yup.array()
      .min(1, "Please select at least 1 account type.")
      .test(
        'is-conflicted',
        (value, { createError }) => findApplicationTypesConflict({
          allConfigs,
          currentConfig: { attributes: data },
          currentApplicationTypes: value,
          createError,
        })
      ),
    config: yup.object().shape({
      account_level: yup.object().shape({
        attachment_required: yup.boolean(),
      }),
      trading_entity: yup.object().shape({
        enabled: yup.boolean(),
      }),
      pricing: yup.object().shape({
        approval_enabled: yup.boolean(),
      }),
      search_send_enabled: yup.boolean(),
    })
  })
}

export default getSchema;
