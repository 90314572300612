import { enquiryReportData, isCompany } from "./helpers/reportHelpers";

import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { displayEmpty } from "utils/formatting";
import moment from "moment";
import { stringToSimpleDate } from "utils/dateFormatter";
import styles from "../../../css/CommonReviewBusiness.css";

var Header = createClass({
  _description() {
    const { data, entityType, consumer } = this.props;

    let tradingName = consumer.attributes.trading_name,
      type = "Business",
      details = "proprietors";

    if (isCompany("company")) {
      type = "Company";
      details = "directors";
    }

    return (
      <p>
        This is a {type} Scored Enquiry on {tradingName}. It contains
        information on the {type.toLowerCase()} and its {details}.
      </p>
    );
  },

  render() {
    const { data, entityType, consumer } = this.props;

    let report = enquiryReportData(data, entityType),
      organisationHeader = report.organisation_report_header;

    return (
      <ReviewContainer
        subHeading={consumer.attributes.trading_name}
        content_class="content_wide"
        css_class="block_noborder"
        fontSize="large"
      >
        {this._description()}
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Request ID</th>
                <td>{organisationHeader.request_id}</td>
                <th>Report Created</th>
                <td>
                  {stringToSimpleDate(organisationHeader.report_create_date)}
                </td>
              </tr>
              <tr>
                <th>Charge Back No.</th>
                <td>{displayEmpty(organisationHeader.charge_back_code)}</td>
                <th>ASIC Extract Date</th>
                <td>
                  {stringToSimpleDate(
                    organisationHeader.extract_date.asic_extract_date,
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },
});

export default Header;
