/* eslint-disable complexity */
import moment from "moment";

import {
  ENTITY_LEAD_NAME_UPDATE_ERROR,
  ENTITY_LEAD_NAME_UPDATE_START,
  ENTITY_LEAD_NAME_UPDATE_SUCCESS,
  LEAD_SET_LEAD_NAME,
  LEADS_ALL_LEADS_LIST_ERROR,
  LEADS_ALL_LEADS_LIST_START,
  LEADS_ALL_LEADS_LIST_SUCCESS,
  LEADS_CLEAR_REVIEW_DATA,
  LEADS_CREATE_NOTES_ERROR,
  LEADS_CREATE_NOTES_START,
  LEADS_CREATE_NOTES_SUCCESS,
  LEADS_LOAD_NOTES_ERROR,
  LEADS_LOAD_NOTES_START,
  LEADS_LOAD_NOTES_SUCCESS,
  LEADS_LOAD_REPORT_ERROR,
  LEADS_LOAD_REPORT_START,
  LEADS_LOAD_REPORT_SUCCESS,
  LEADS_REVIEW_LOAD_ERROR,
  LEADS_REVIEW_LOAD_START,
  LEADS_REVIEW_LOAD_SUCCESS,
  LEADS_REVIEW_REFRESH,
  LEADS_SET_REGION,
  LEADS_SET_REVIEW_INDEX,
  LEADS_SET_STATE,
  LEADS_UPDATE_ERROR,
  LEADS_UPDATE_LEAD_ARCHIVE_ERROR,
  LEADS_UPDATE_LEAD_ARCHIVE_START,
  LEADS_UPDATE_LEAD_ARCHIVE_SUCCESS,
  LEADS_UPDATE_SET_DEFAULT_VALUES,
  LEADS_UPDATE_START,
  LEADS_UPDATE_SUCCESS,
  NEW_LEAD_COMPANY_NAME_LOOKUP_CLEAR,
  NEW_LEAD_COMPANY_NAME_LOOKUP_ERROR,
  NEW_LEAD_COMPANY_NAME_LOOKUP_START,
  NEW_LEAD_COMPANY_NAME_LOOKUP_SUCCESS,
  NEW_LEAD_CREATE_ERROR,
  NEW_LEAD_CREATE_START,
  NEW_LEAD_CREATE_SUCESS,
  NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
  NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_ERROR,
  NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_START,
  NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
  NEW_LEAD_POSTAL_ADDRESS_LOOKUP_CLEAR,
  NEW_LEAD_POSTAL_ADDRESS_LOOKUP_ERROR,
  NEW_LEAD_POSTAL_ADDRESS_LOOKUP_START,
  NEW_LEAD_POSTAL_ADDRESS_LOOKUP_SUCCESS,
  NEW_LEAD_RESET,
  NEW_LEAD_SET,
  NEW_LEAD_SET_ADDRESS,
  RESET,
  SET,
  SET_NEW_LEAD_FIELDS,
} from "./constants";

const leadsDefaults = {
  activeTab: "total",
  all_notes_list: [],
  branch: "All",
  entity_type: "All",
  from: moment().set({ date: 25, month: 11, year: 2016 }),
  initiator: "All",
  isLeadUpdateLoading: false,
  lead_archive_updating: false,
  lead_list: [],
  lead_name: null,
  lead_name_updating: false,
  lead_report_loading: false,
  leadsLoading: false,
  leadsMeta: {
    archived_count: 0,
    current_total_count: 0,
    in_progress_count: 0,
    loaded_in_system_count: 0,
    submitted_count: 0,
    total_count: 0,
  },
  leadsPage: 1,
  leadsPerPage: 25,
  mobilePage: 1,
  new_lead: {
    entity_type: "",
    physical_address: {},
  },
  new_lead_company_name_list: [],
  new_lead_company_name_list_loading: false,
  new_lead_creating: false,
  new_lead_physical_address_list: {},
  new_lead_physical_address_loading: false,
  new_lead_required_fields: ["region"],
  noMoreRecords: false,
  note_updating: false,
  period: { label: "All", value: "All" },
  region: "All",
  report: [],
  review_container_index: 0,
  review_lead: null,
  review_lead_loading: false,
  search: "",
  start_date: "All",
  start_date_from: moment().set({ date: 25, month: 11, year: 2016 }),
  start_date_to: moment().add(5, "d"),
  state: "All",
  to: moment().add(5, "d"),
  user: "All",
};

export function leadsReducers(state = leadsDefaults, action) {
  switch (action.type) {
    case LEADS_ALL_LEADS_LIST_START:
      return { ...state, leadsLoading: true };
    case LEADS_ALL_LEADS_LIST_SUCCESS:
      return {
        ...state,
        lead_list: action.payload.lead_list,
        leadsLoading: false,
        leadsMeta: action.payload.leadsMeta,
        mobilePage: action.payload.mobilePage,
        noMoreRecords: action.payload.noMoreRecords,
      };
    case LEADS_ALL_LEADS_LIST_ERROR:
      return { ...state, leadsLoading: false };
    case SET:
      return { ...state, ...action.payload };
    case RESET:
      return { ...leadsDefaults };
    case LEAD_SET_LEAD_NAME:
      return { ...state, lead_name: action.payload };
    case ENTITY_LEAD_NAME_UPDATE_START:
      return { ...state, lead_name_updating: true };
    case ENTITY_LEAD_NAME_UPDATE_SUCCESS:
    case ENTITY_LEAD_NAME_UPDATE_ERROR:
      return { ...state, lead_name_updating: false };
    case LEADS_LOAD_REPORT_START:
      return { ...state, lead_report_loading: true };
    case LEADS_LOAD_REPORT_SUCCESS:
      return { ...state, lead_report_loading: false, report: action.payload };
    case LEADS_LOAD_REPORT_ERROR:
      return { ...state, lead_report_loading: false };
    case LEADS_SET_REGION:
      return { ...state, region: action.payload };
    case LEADS_SET_STATE:
      return { ...state, state: action.payload };
    case LEADS_UPDATE_LEAD_ARCHIVE_START:
      return { ...state, lead_archive_updating: true };
    case LEADS_UPDATE_LEAD_ARCHIVE_SUCCESS:
    case LEADS_UPDATE_LEAD_ARCHIVE_ERROR:
      return { ...state, isLeadUpdateLoading: false };
    case LEADS_UPDATE_SET_DEFAULT_VALUES:
      return { ...state, new_lead: { ...state.new_lead, ...action.payload } };
    case LEADS_UPDATE_START:
      return { ...state, isLeadUpdateLoading: action.payload };
    case LEADS_UPDATE_SUCCESS:
    case LEADS_UPDATE_ERROR:
      return { ...state, isLeadUpdateLoading: false };
    case LEADS_CREATE_NOTES_START:
      return { ...state, note_updating: true };
    case LEADS_CREATE_NOTES_SUCCESS:
      return {
        ...state,
        all_notes_list: [...state.all_notes_list, action.payload],
        note_updating: false,
      };
    case LEADS_CREATE_NOTES_ERROR:
      return { ...state, note_updating: false };
    case LEADS_LOAD_NOTES_START:
      return { ...state, note_updating: true };
    case LEADS_LOAD_NOTES_SUCCESS:
      return { ...state, all_notes_list: action.payload, note_updating: false };
    case LEADS_LOAD_NOTES_ERROR:
      return { ...state, note_updating: false };
    case LEADS_REVIEW_LOAD_START:
      return { ...state, review_lead_loading: true };
    case LEADS_REVIEW_LOAD_SUCCESS:
      return {
        ...state,
        review_lead: action.payload,
        review_lead_loading: false,
      };
    case LEADS_REVIEW_LOAD_ERROR:
      return { ...state, review_lead_loading: false };
    case LEADS_CLEAR_REVIEW_DATA:
      return { ...state, review_lead: null, review_lead_loading: false };
    case LEADS_SET_REVIEW_INDEX:
      return { ...state, review_container_index: action.payload };
    case NEW_LEAD_SET:
      return {
        ...state,
        new_lead: {
          ...state.new_lead,
          [action.payload.key]: action.payload.value,
        },
      };
    case SET_NEW_LEAD_FIELDS:
      return { ...state, new_lead_required_fields: action.payload };
    case NEW_LEAD_SET_ADDRESS:
      return {
        ...state,
        new_lead: {
          ...state.new_lead,
          [action.payload.key]: { full_address: action.payload.value },
        },
      };
    case NEW_LEAD_COMPANY_NAME_LOOKUP_CLEAR:
      return { ...state, new_lead_company_name_list: [] };
    case NEW_LEAD_COMPANY_NAME_LOOKUP_START:
      return { ...state, new_lead_company_name_list_loading: true };
    case NEW_LEAD_COMPANY_NAME_LOOKUP_SUCCESS:
      return {
        ...state,
        new_lead_company_name_list: action.payload,
        new_lead_company_name_list_loading: false,
      };
    case NEW_LEAD_COMPANY_NAME_LOOKUP_ERROR:
      return { ...state, new_lead_company_name_list_loading: false };
    case NEW_LEAD_POSTAL_ADDRESS_LOOKUP_CLEAR:
      return { ...state, new_lead_postal_address_list: [] };
    case NEW_LEAD_POSTAL_ADDRESS_LOOKUP_START:
      return { ...state, new_lead_postal_address_loading: true };
    case NEW_LEAD_POSTAL_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        new_lead_postal_address_list: action.payload,
        new_lead_postal_address_loading: false,
      };
    case NEW_LEAD_POSTAL_ADDRESS_LOOKUP_ERROR:
      return { ...state, new_lead_postal_address_loading: false };
    case NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_CLEAR:
      return {
        ...state,
        new_lead_physical_address_list: [],
        new_lead_physical_address_loading: false,
      };
    case NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_START:
      return { ...state, new_lead_physical_address_loading: true };
    case NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        new_lead_physical_address_list: action.payload,
        new_lead_physical_address_loading: false,
      };
    case NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_ERROR:
      return { ...state, new_lead_physical_address_loading: false };
    case NEW_LEAD_CREATE_START:
      return { ...state, new_lead_creating: action.payload };
    case NEW_LEAD_CREATE_SUCESS:
      return { ...state, new_lead_creating: false };
    case NEW_LEAD_CREATE_ERROR:
      return { ...state, new_lead_creating: false };
    case LEADS_REVIEW_REFRESH:
      return { ...state, review_lead: action.payload };
    case NEW_LEAD_RESET:
      return {
        ...state,
        new_lead: { entity_type: null, physical_address: {} },
        new_lead_required_fields: ["region"],
      };
    default:
      return { ...state };
  }
}
