import React, { Component } from "react";

import styles from "modules/shared/components/text/css/StandardTermsAndConditions.css";

export default class Background extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>6.</span> Warranties
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>6.1</span>{" "}
          <strong>Australian Consumer Guarantees</strong>: The Supplier
          acknowledges and agrees that it is acquiring all goods and services
          supplied under these Terms for business purposes and that no
          guarantees provided under the Australian Consumer Law shall apply to
          any such goods or services.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>6.2</span>{" "}
          <strong>Third party services</strong>: The Supplier acknowledges that
          certain aspects of the Service may be provided by one or more third
          parties, on those third parties’ terms, including the Third Party
          Supplier Terms. Where a third party is involved in providing any
          aspect of the Service, for example, hosting the Service, or a Credit
          Reporting Agency or a Trade Reference Checking Agency, the Supplier
          acknowledges that 1Centre does not provide any warranties or
          guarantees in relation to those aspects of the Service, including in
          relation to the availability, reliability, or performance of the
          Service. Additionally the Supplier acknowledges that:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> information provided
          through the Platform relies on third party informational websites
          (such as the Australian Securities &amp; Investments Commission
          website) and these websites may not be fully up to date at any one
          time;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> whether an individual
          is in receivership, statutory management or subject to a creditors’
          compromise is not able to be detected by the Platform, so applicants
          and guarantors could be subject to these processes.
        </p>
      </div>
    );
  }
}
