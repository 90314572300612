import {
  APPLICATIONS_ACCEPT_SUBMIT_ERROR,
  APPLICATIONS_ACCEPT_SUBMIT_START,
  APPLICATIONS_ACCEPT_SUBMIT_SUCCESS,
  APPLICATIONS_CLEAR_REVIEW_DATA,
  APPLICATIONS_CONSUMER_LOAD_LIST_ERROR,
  APPLICATIONS_CONSUMER_LOAD_LIST_START,
  APPLICATIONS_CONSUMER_LOAD_LIST_SUCCESS,
  APPLICATIONS_CONSUMER_USER_LOAD_LIST_ERROR,
  APPLICATIONS_CONSUMER_USER_LOAD_LIST_START,
  APPLICATIONS_CONSUMER_USER_LOAD_LIST_SUCCESS,
  APPLICATIONS_CREATE_NOTES_ERROR,
  APPLICATIONS_CREATE_NOTES_START,
  APPLICATIONS_CREATE_NOTES_SUCCESS,
  APPLICATIONS_DECLINE_SUBMIT_ERROR,
  APPLICATIONS_DECLINE_SUBMIT_START,
  APPLICATIONS_DECLINE_SUBMIT_SUCCESS,
  APPLICATIONS_DOWNLOAD_CSV_ERROR,
  APPLICATIONS_DOWNLOAD_CSV_START,
  APPLICATIONS_DOWNLOAD_CSV_SUCCESS,
  APPLICATIONS_EXPORT_ERROR,
  APPLICATIONS_EXPORT_START,
  APPLICATIONS_EXPORT_SUCCESS,
  APPLICATIONS_GET_CONNECTION_ID_SUCCESS,
  APPLICATIONS_LOAD_APPROVAL_LEVELS_ERROR,
  APPLICATIONS_LOAD_APPROVAL_LEVELS_START,
  APPLICATIONS_LOAD_APPROVAL_LEVELS_SUCCESS,
  APPLICATIONS_LOAD_CREDIT_CHECKS_ERROR,
  APPLICATIONS_LOAD_CREDIT_CHECKS_START,
  APPLICATIONS_LOAD_CREDIT_CHECKS_SUCCESS,
  APPLICATIONS_LOAD_CREDIT_STATUS_ERROR,
  APPLICATIONS_LOAD_CREDIT_STATUS_START,
  APPLICATIONS_LOAD_CREDIT_STATUS_SUCCESS,
  APPLICATIONS_LOAD_NOTES_ERROR,
  APPLICATIONS_LOAD_NOTES_START,
  APPLICATIONS_LOAD_NOTES_SUCCESS,
  APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_ERROR,
  APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_STARTED,
  APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_SUCCESS,
  APPLICATIONS_REVIEW_BUSINESS_PEOPLE_DATA_LOAD_SUCCESS,
  APPLICATIONS_REVIEW_LOAD_ERROR,
  APPLICATIONS_REVIEW_LOAD_START,
  APPLICATIONS_REVIEW_LOAD_SUCCESS,
  APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_ERROR,
  APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_START,
  APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_SUCCESS,
  APPLICATIONS_REVIEW_SET_ACCOUNT_LIMIT,
  APPLICATIONS_REVIEW_SET_TRADE_DISCOUNT,
  APPLICATIONS_SET_GUARANTOR_ID_STATUS_ERROR,
  APPLICATIONS_SET_GUARANTOR_ID_STATUS_START,
  APPLICATIONS_SET_GUARANTOR_ID_STATUS_SUCCESS,
  APPLICATIONS_SET_REVIEW_ACCEPT_NOTES_DATA,
  APPLICATIONS_SET_REVIEW_DECLINE_NOTES_DATA,
  APPLICATIONS_SET_REVIEW_INDEX,
  APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_ERROR,
  APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_START,
  APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_SUCCESS,
  APPLICATIONS_SET_SIGNATURE_STATUS_ERROR,
  APPLICATIONS_SET_SIGNATURE_STATUS_START,
  APPLICATIONS_SET_SIGNATURE_STATUS_SUCCESS,
  APPLICATIONS_SET_TRADE_REFERENCE_STATUS_ERROR,
  APPLICATIONS_SET_TRADE_REFERENCE_STATUS_START,
  APPLICATIONS_SET_TRADE_REFERENCE_STATUS_SUCCESS,
  APPLICATIONS_SUPPLIER_LOAD_LIST_ERROR,
  APPLICATIONS_SUPPLIER_LOAD_LIST_START,
  APPLICATIONS_SUPPLIER_LOAD_LIST_SUCCESS,
  APPLICATIONS_SUPPLIER_USER_LOAD_LIST_ERROR,
  APPLICATIONS_SUPPLIER_USER_LOAD_LIST_START,
  APPLICATIONS_SUPPLIER_USER_LOAD_LIST_SUCCESS,
  APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_ERROR,
  APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_START,
  APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_SUCCESS,
  APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_ERROR,
  APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_START,
  APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_SUCCESS,
  APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_ERROR,
  APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_START,
  APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_SUCCESS,
  APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_ERROR,
  APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_START,
  APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_SUCCESS,
  APPLICATIONS_UPLOAD_ID_ERROR,
  APPLICATIONS_UPLOAD_ID_START,
  APPLICATIONS_UPLOAD_ID_SUCCESS,
  APPLICATIONS_WEBSITEBUTTON_SEND_ERROR,
  APPLICATIONS_WEBSITEBUTTON_SEND_START,
  APPLICATIONS_WEBSITEBUTTON_SEND_SUCCESS,
} from "./constants";

import Signature from "../../models/Signature";
import api from "../../api";
import { downloadCSV } from "utils/downloadCSV";
import { entityTypeTitle } from "utils/entityTypeTitle";
import { exportXlsxCSV } from "utils/exportXlsxCSV";
import { getPermissionsAccountRules } from "modules/profile/actions";
import moment from "moment";
import { sendSurvey } from "utils/askNicely";
import { setChurnZeroAttributes } from "utils/churnZero";

const GET_APPLICATION_INCLUDED =
  "consumer,physical_address,postal_address," +
  "guarantors,credit_checks,credit_check_rule," +
  "veda_checks,veda_checks.signature,trade_references," +
  "trade_references.trade_reference_check," +
  "trade_references.trade_reference_check.notes," +
  "consumer.people,addon_answers,addon_rules,cardholders," +
  "payments,authorisations,authorisations.signature," +
  "authorisations.identification_check,signatories,consumer_trading_names";

export function clearReviewData() {
  return {
    type: APPLICATIONS_CLEAR_REVIEW_DATA,
  };
}

export function setReviewTradeDiscount(limit) {
  return {
    type: APPLICATIONS_REVIEW_SET_TRADE_DISCOUNT,
    payload: limit,
  };
}

export function setReviewAccountLimit(limit) {
  return {
    type: APPLICATIONS_REVIEW_SET_ACCOUNT_LIMIT,
    payload: limit,
  };
}

export function setAcceptNoteData(val) {
  return {
    type: APPLICATIONS_SET_REVIEW_ACCEPT_NOTES_DATA,
    payload: val,
  };
}

export function setDeclineNoteData(val) {
  return {
    type: APPLICATIONS_SET_REVIEW_DECLINE_NOTES_DATA,
    payload: val,
  };
}

export function setReviewIndex(index) {
  return {
    type: APPLICATIONS_SET_REVIEW_INDEX,
    payload: index,
  };
}

export function sendDecline(attributes, applicationId, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_DECLINE_SUBMIT_START,
      payload: applicationId,
    });

    var reviews = api(
        "reviews",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = [];

    reviews.createReview(
      attributes,
      applicationId,
      result => {
        if (result.data.data) {
          data = result.data.data;
        }

        let name = getState().current_user.data.data.attributes.full_name || "";
        let email = getState().current_user.data.data.attributes.email || "";
        let supplier_name =
          getState().current_user.current_entity.attributes.company_name || "";

        sendSurvey({
          name,
          email,
          supplier: true,
          event_flow: "Review - Declined",
          supplier_name,
          access_token: getState().current_user.access_token,
          application_id: applicationId,
          supplier_id: getState().current_user.current_entity.id,
          consumer_id: null,
          consumer_name: null,
        });

        dispatch({
          type: APPLICATIONS_DECLINE_SUBMIT_SUCCESS,
          payload: data,
          meta: {
            mixpanel: {
              event: "Review Declined",
              props: {
                distinct_id: getState().current_user.data.data.id,
                Outcome: "Declined",
                "Account limit": attributes.trade_account_limit,
                "Account discount":
                  attributes.trade_account_discount_percentage,
                Application: applicationId,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });

        // ChurnZero
        if (getState().current_user.current_entity.attributes.supplier) {
          setChurnZeroAttributes(
            getState().current_user.current_entity.id,
            getState,
          );
        }

        callback();
      },
      error => {
        dispatch({
          type: APPLICATIONS_DECLINE_SUBMIT_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function sendAccept(attributes, applicationId, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_ACCEPT_SUBMIT_START,
      payload: applicationId,
    });

    var reviews = api(
        "reviews",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = [];

    reviews.createReview(
      attributes,
      applicationId,
      result => {
        if (result.data.data) {
          data = result.data.data;
        }

        let name = getState().current_user.data.data.attributes.full_name || "";
        let email = getState().current_user.data.data.attributes.email || "";
        let supplier_name =
          getState().current_user.current_entity.attributes.company_name || "";

        sendSurvey({
          name,
          email,
          supplier: true,
          event_flow: "Review - Accepted",
          supplier_name,
          access_token: getState().current_user.access_token,
          application_id: applicationId,
          supplier_id: getState().current_user.current_entity.id,
          consumer_id: null,
          consumer_name: null,
        });

        dispatch({
          type: APPLICATIONS_ACCEPT_SUBMIT_SUCCESS,
          payload: data,
          meta: {
            mixpanel: {
              event: "Review Accepted",
              props: {
                distinct_id: getState().current_user.data.data.id,
                Outcome: "Accepted",
                "Account limit": attributes.trade_account_limit,
                "Account discount":
                  attributes.trade_account_discount_percentage,
                Application: applicationId,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });

        // ChurnZero
        if (getState().current_user.current_entity.attributes.supplier) {
          setChurnZeroAttributes(
            getState().current_user.current_entity.id,
            getState,
          );
        }

        callback();
      },
      error => {
        dispatch({
          type: APPLICATIONS_ACCEPT_SUBMIT_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function loadConsumerApplications() {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_CONSUMER_LOAD_LIST_START,
    });

    var entities = api(
        "entities",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      entityId = getState().current_user.current_entity.id,
      data = [];

    entities.getEntityConsumerApplications(
      entityId,
      result => {
        if (result.data.data.length) {
          data = result.data.data;
        }
        dispatch({
          type: APPLICATIONS_CONSUMER_LOAD_LIST_SUCCESS,
          payload: data,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_CONSUMER_LOAD_LIST_ERROR,
          payload: error,
        });
      },
      { params: { include: "consumer_contact,supplier_contact" } },
    );
  };
}

export function loadUserConsumerApplications(userId) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_CONSUMER_USER_LOAD_LIST_START,
    });

    var users = api(
        "users",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = [];

    users.getUserConsumerApplications(
      userId,
      result => {
        if (result.data.data.length) {
          data = result.data.data;
        }
        dispatch({
          type: APPLICATIONS_CONSUMER_USER_LOAD_LIST_SUCCESS,
          payload: data,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_CONSUMER_USER_LOAD_LIST_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function loadSupplierApplications() {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_SUPPLIER_LOAD_LIST_START,
    });

    var entities = api(
        "entities",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      entityId = getState().current_user.current_entity.id,
      data = [];

    entities.getEntitySupplierApplications(
      entityId,
      result => {
        if (result.data.data.length) {
          data = result.data.data;
        }
        dispatch({
          type: APPLICATIONS_SUPPLIER_LOAD_LIST_SUCCESS,
          payload: data,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_SUPPLIER_LOAD_LIST_ERROR,
          payload: error,
        });
      },
      { params: { include: "consumer_contact,supplier_contact" } },
    );
  };
}

export function loadUserSupplierApplications(userId) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_SUPPLIER_USER_LOAD_LIST_START,
    });

    var users = api(
        "users",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = [];

    users.getUserSupplierApplications(
      userId,
      result => {
        if (result.data.data.length) {
          data = result.data.data;
        }
        dispatch({
          type: APPLICATIONS_SUPPLIER_USER_LOAD_LIST_SUCCESS,
          payload: data,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_SUPPLIER_USER_LOAD_LIST_ERROR,
          payload: error,
        });
      },
      {
        params: {
          "page[size]": 100,
        },
      },
    );
  };
}

export function loadApplicationDetails(id, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_REVIEW_LOAD_START,
      payload: id,
      meta: {
        mixpanel: {
          event: "Review Application",
          props: {
            distinct_id: getState().current_user.data.data.id,
            Application: id,
            "Entity ID": getState().current_user.current_entity.id,
          },
        },
      },
    });

    var apps = api(
        "applications",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = {},
      checks = api(
        "trade_reference_checks",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      );

    apps.getApplication(
      id,
      result => {
        if (result.data.data) {
          dispatch(_getApplicationApprovalLevel(id));

          data = getReviewApplication(result);
        }

        dispatch({
          type: APPLICATIONS_REVIEW_LOAD_SUCCESS,
          payload: data,
        });

        // Used in AcceptReviewNotes
        if (callback) {
          callback(data);
        }
      },
      error => {
        dispatch({
          type: APPLICATIONS_REVIEW_LOAD_ERROR,
          payload: error,
        });
      },
      {
        params: {
          include: GET_APPLICATION_INCLUDED,
        },
      },
    );
  };
}

function loadReviewBusinessDataStarted(entityId) {
  return {
    type: APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_STARTED,
    payload: entityId,
  };
}

function loadReviewBusinessDataSuccess() {
  return {
    type: APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_SUCCESS,
  };
}

function loadReviewBusinessDataError(error) {
  return {
    type: APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_ERROR,
    payload: error,
  };
}

function peopleDataLoaded(data) {
  return {
    type: APPLICATIONS_REVIEW_BUSINESS_PEOPLE_DATA_LOAD_SUCCESS,
    payload: data,
  };
}

function loadNZBNDetails(getState, nzbn) {
  let nzbnApi = api("nzbn", getState().current_user.access_token);
  return nzbnApi.entityDetails(nzbn);
}

function loadEntityPeople(getState, entityId) {
  let entities = api("entities", getState().current_user.access_token);
  return entities.getEntityPeople(entityId);
}

export function loadReviewBusinessData(entityId) {
  return (dispatch, getState) => {
    dispatch(loadReviewBusinessDataStarted(entityId));
    let peoplePromise;

    // if (nzbn && nzbn.startsWith('9')) {
    //   peoplePromise = loadNZBNDetails(getState, nzbn);
    // }
    // else {
    peoplePromise = loadEntityPeople(getState, entityId);
    //}

    peoplePromise
      .then(response => {
        dispatch(peopleDataLoaded(response.data.data));
      })
      .then(() => {
        dispatch(loadReviewBusinessDataSuccess());
      })
      .catch(error => {
        dispatch(loadReviewBusinessDataError(error));
      });
  };
}

export function loadReviews(id) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_START,
      payload: id,
    });

    var apps = api(
        "applications",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = [];

    apps.getApplicationReviews(
      id,
      result => {
        if (result.data.data.length > 0) {
          data = result.data.data;
        }
        dispatch({
          type: APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_SUCCESS,
          payload: data,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function addTradeRefNotes(note) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_START,
      payload: note.id,
    });

    var trade_references = api(
        "trade_references",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = [];

    trade_references.updateTradeRef(
      note.id,
      {
        notes: note.attributes.notes,
      },
      success => {
        dispatch({
          type: APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_SUCCESS,
          payload: note.id,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_ERROR,
          payload: note.id,
        });
      },
    );
  };
}

export function setTradeReferenceStatus(id, status, note) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_SET_TRADE_REFERENCE_STATUS_START,
      payload: { id, status, note },
    });

    var trade_reference_checks = api(
        "trade_reference_checks",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = [];

    trade_reference_checks.createCheck(
      id,
      {
        outcome: status,
      },
      success => {
        dispatch({
          type: APPLICATIONS_SET_TRADE_REFERENCE_STATUS_SUCCESS,
          payload: null,
        });
        dispatch(
          loadApplicationDetails(getState().applications.review_application.id),
        );
      },
      error => {
        dispatch({
          type: APPLICATIONS_SET_TRADE_REFERENCE_STATUS_ERROR,
          payload: null,
        });
      },
    );
  };
}

export function setSignatureStatus(id, status) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_SET_SIGNATURE_STATUS_START,
      payload: { id, manually_approved: status },
    });

    var signatures = api(
        "signatures",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = [];

    signatures.updateSignature(
      id,
      {
        manually_approved: status,
      },
      success => {
        dispatch({
          type: APPLICATIONS_SET_SIGNATURE_STATUS_SUCCESS,
          payload: null,
        });
        dispatch(
          loadApplicationDetails(getState().applications.review_application.id),
        );
      },
      error => {
        dispatch({
          type: APPLICATIONS_SET_SIGNATURE_STATUS_ERROR,
          payload: null,
        });
      },
    );
  };
}

export function setGuarantorIDStatus(id, status) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_SET_GUARANTOR_ID_STATUS_START,
      payload: { id, status },
    });

    var guarantors = api(
        "guarantors",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = [];

    guarantors.updateGuarantor(
      id,
      {
        identity_manually_approved: status,
      },
      success => {
        dispatch({
          type: APPLICATIONS_SET_GUARANTOR_ID_STATUS_SUCCESS,
          payload: null,
        });
        dispatch(
          loadApplicationDetails(getState().applications.review_application.id),
        );
      },
      error => {
        dispatch({
          type: APPLICATIONS_SET_GUARANTOR_ID_STATUS_ERROR,
          payload: null,
        });
      },
    );
  };
}

export function downloadApplicationCSV(id) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_DOWNLOAD_CSV_START,
    });

    var apps = api(
        "applications",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = {};

    apps.getApplication(
      id,
      result => {
        if (result.data.data) {
          data = result.data.data;
          if (result.data.included) {
            data.consumer = result.data.included.find(inc => {
              return inc.id === data.relationships.consumer.data.id;
            });
            data.guarantors = result.data.included.filter(inc => {
              return inc.type === "guarantors";
            });
            data.trade_references = result.data.included.filter(inc => {
              return (
                inc.type === "trade_references" && !inc.attributes.archived
              );
            });
          }
        }
        let filename = `1centre-application-data-${moment()
          .toDate()
          .toISOString()}.csv`;

        let locationDetails = "",
          company_number = "Company number",
          business_number = "Business number",
          trading_name = entityTypeTitle(
            data.consumer.attributes.legal_type,
            "trading_name",
          ),
          legal_type = "Business type";
        if (data.consumer.attributes.region === "NZ") {
          locationDetails = "New Zealand";
        } else if (data.consumer.attributes.region === "AU") {
          locationDetails = "Australia, " + data.consumer.attributes.state;
          company_number = "ACN";
          business_number = "ABN";
        }
        if (data.consumer.attributes.legal_type === "personal") {
          legal_type = "Entity type";
        }
        var application =
          data.consumer.attributes.legal_type === "personal"
            ? [
                {
                  [trading_name]: data.consumer.attributes.trading_name,
                  [legal_type]: data.consumer.attributes.legal_type,
                  "Account type": data.attributes.application_type,
                  "Key contact first name":
                    data.consumer.attributes.key_contact_first_name,
                  "Key contact last name":
                    data.consumer.attributes.key_contact_last_name,
                  "Key contact phone number":
                    data.consumer.attributes.key_contact_phone_number,
                  "Key contact email":
                    data.consumer.attributes.key_contact_email,
                },
              ]
            : [
                {
                  "Company/Business name": data.consumer.attributes.company_name
                    ? data.consumer.attributes.company_name
                    : "",
                  [trading_name]: data.consumer.attributes.trading_name,
                  [legal_type]: data.consumer.attributes.legal_type,
                  "Account type": data.attributes.application_type,
                  [company_number]: data.consumer.attributes.company_number
                    ? data.consumer.attributes.company_number
                    : "",
                  [business_number]: data.consumer.attributes.business_number
                    ? data.consumer.attributes.business_number
                    : "",
                  "Business location": locationDetails,
                  "Physical address":
                    data.consumer.attributes.full_physical_address,
                  "Postal address": data.consumer.attributes.full_postal_address
                    ? data.consumer.attributes.full_postal_address
                    : "",
                  "Delivery instructions":
                    data.consumer.attributes.delivery_instruction || "",
                  "Business phone number":
                    data.consumer.attributes.contact_phone_number,
                  "Number of staff": data.consumer.attributes.staff_count,
                  "Key contact first name":
                    data.consumer.attributes.key_contact_first_name,
                  "Key contact last name":
                    data.consumer.attributes.key_contact_last_name,
                  "Key contact phone number":
                    data.consumer.attributes.key_contact_phone_number,
                  "Key contact email":
                    data.consumer.attributes.key_contact_email,
                },
              ];

        if (
          [
            "education",
            "society",
            "association",
            "club",
            "government",
            "other",
          ].includes(data.consumer.attributes.legal_type)
        ) {
          application[0]["Incorporation/Registration number"] =
            data.attributes.incorporated_number || "";
        }

        if (data.attributes.application_type !== "cash") {
          application[0][
            "Credit limit"
          ] = data.attributes.trade_account_limit.toString();
        }

        if (
          data.trade_references.length > 0 &&
          data.consumer.attributes.legal_type !== "personal"
        ) {
          data.trade_references.forEach((t, index) => {
            application[0]["Trade reference" + (index + 1) + " business name"] =
              t.attributes.business_name;
            application[0]["Trade reference" + (index + 1) + " first name"] =
              t.attributes.first_name;
            application[0]["Trade reference" + (index + 1) + " last name"] =
              t.attributes.last_name;
            application[0]["Trade reference" + (index + 1) + " phone number"] =
              t.attributes.contact_phone_number;
          });
        }

        if (data.guarantors.length > 0) {
          data.guarantors.forEach((g, index) => {
            application[0]["Guarantor" + (index + 1) + " first name"] =
              g.attributes.first_name;
            application[0]["Guarantor" + (index + 1) + " middle name"] =
              g.attributes.middle_name;
            application[0]["Guarantor" + (index + 1) + " last name"] =
              g.attributes.last_name;
            application[0]["Guarantor" + (index + 1) + " email"] =
              g.attributes.email;
          });
        }
        if (
          data.consumer.attributes.legal_type === "company" ||
          data.consumer.attributes.legal_type === "partnership" ||
          data.consumer.attributes.legal_type === "trust" ||
          data.consumer.attributes.legal_type === "personal"
        ) {
          let entities = api(
            "entities",
            getState().current_user.access_token,
            getState().current_user.current_entity.id,
          );
          entities.getEntityPeople(data.consumer.id, response => {
            let people = response.data.data;
            if (people.length > 0) {
              if (data.consumer.attributes.legal_type === "personal") {
                application[0]["Personal type"] =
                  people.length > 1 ? "Joint" : "Individual";
              }

              people.forEach((p, index) => {
                application[0][
                  p.attributes.legal_type + " " + (index + 1) + " first name"
                ] = p.attributes.first_name;
                application[0][
                  p.attributes.legal_type + " " + (index + 1) + " middle name"
                ] = p.attributes.middle_name;
                application[0][
                  p.attributes.legal_type + " " + (index + 1) + " last name"
                ] = p.attributes.last_name;
                if (
                  data.consumer.attributes.region === "NZ" &&
                  data.consumer.attributes.legal_type === "company"
                ) {
                  if (p.attributes.appointed_at) {
                    application[0][
                      p.attributes.legal_type +
                        " " +
                        (index + 1) +
                        " appointment date"
                    ] = moment(p.attributes.appointed_at).format("DD-MMM-YYYY");
                  }
                  if (p.attributes.full_address.street) {
                    application[0][
                      p.attributes.legal_type +
                        " " +
                        (index + 1) +
                        " residential address"
                    ] =
                      p.attributes.full_address.street[0] +
                      " " +
                      p.attributes.full_address.street[1] +
                      " " +
                      p.attributes.full_address.street[2];
                  }
                } else {
                  application[0][
                    p.attributes.legal_type + " " + (index + 1) + " email"
                  ] = p.attributes.email;
                }
                if (p.attributes.legal_type === "partner") {
                  application[0][
                    p.attributes.legal_type +
                      " " +
                      (index + 1) +
                      " percentage share"
                  ] = p.attributes.percentage_share;
                }
                if (p.attributes.legal_type === "individual") {
                  application[0][
                    p.attributes.legal_type +
                      " " +
                      (index + 1) +
                      " previous last name"
                  ] = p.attributes.previous_last_name;
                  application[0][
                    p.attributes.legal_type + " " + (index + 1) + " employer"
                  ] = p.attributes.employer;
                  application[0][
                    p.attributes.legal_type + " " + (index + 1) + " position"
                  ] = p.attributes.position;
                  application[0][
                    p.attributes.legal_type +
                      " " +
                      (index + 1) +
                      " residential address"
                  ] = p.attributes.full_residential_address;
                  application[0][
                    p.attributes.legal_type +
                      " " +
                      (index + 1) +
                      " residential type"
                  ] = p.attributes.residential_type;
                }
              });

              if (
                data.consumer.attributes.legal_type === "personal" &&
                people.length == 1 &&
                data.guarantors.length > 0
              ) {
                data.guarantors.forEach((g, index) => {
                  delete application[0][
                    "Guarantor" + (index + 1) + " first name"
                  ];
                  delete application[0][
                    "Guarantor" + (index + 1) + " middle name"
                  ];
                  delete application[0][
                    "Guarantor" + (index + 1) + " last name"
                  ];
                  delete application[0]["Guarantor" + (index + 1) + " email"];
                });
              }
              downloadCSV(application, filename);
            }
          });
        } else {
          downloadCSV(application, filename);
        }
        dispatch({
          type: APPLICATIONS_DOWNLOAD_CSV_SUCCESS,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_DOWNLOAD_CSV_ERROR,
          payload: error,
        });
      },
      { params: { include: "consumer,guarantors,trade_references" } },
    );
  };
}

export function getAllConnectedApplications(fileType, attributes) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_EXPORT_START,
    });
    let filename =
      `1centre-connection-data-${moment()
        .toDate()
        .toISOString()}.` + fileType;
    var apps = api(
      "applications",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    apps.exportApplications(
      result => {
        exportXlsxCSV(result.data.data, filename);
        dispatch({
          type: APPLICATIONS_EXPORT_SUCCESS,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_EXPORT_ERROR,
          payload: error,
        });
      },
      { params: attributes },
    );
  };
}

export function sendConsumerWebistebuttonApplication(applicationId, success) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_WEBSITEBUTTON_SEND_START,
    });

    var apps = api("websitebuttons", getState().current_user.access_token);
    var data = getPermissionsAccountRules(getState);

    apps.updateApplication(
      applicationId,
      data,
      result => {
        dispatch({
          type: APPLICATIONS_WEBSITEBUTTON_SEND_SUCCESS,
          payload: result,
          meta: {
            mixpanel: {
              event: "Update website button application",
              props: {
                distinct_id: getState().current_user.data.data.id,
                "Application ID": applicationId,
                "Entity ID": getState().current_user.current_entity.id,
              },
            },
          },
        });

        // ChurnZero
        if (getState().current_user.current_entity.attributes.supplier) {
          setChurnZeroAttributes(
            getState().current_user.current_entity.id,
            getState,
          );
        }

        success(result);
      },
      error => {
        dispatch({
          type: APPLICATIONS_WEBSITEBUTTON_SEND_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function getMandatoryChecks(getState) {
  var s = getState().manage_profile,
    ent = getState().manage_profile.current_entity.attributes;

  var data = {
    settings: new Object(),
  };

  /* Add Credit Check */
  data.settings.requires_credit_check = s.permissions_requires_credit_check;
  data.settings.selected_credit_check_rules =
    ent.selected_credit_check_rule_ids;

  /* Add Identification Check */
  data.settings.requires_identity_check =
    s.permissions_requires_identification_check;
  data.settings.selected_identification_check_rules =
    ent.selected_identification_check_rules;

  if (s.permissions_requires_identification_check) {
    data.settings.requires_applicant_identification_check =
      ent.requires_applicant_identification_check || false;
    data.settings.requires_guarantees_identification_check =
      ent.requires_guarantees_identification_check || false;
  } else {
    data.settings.requires_applicant_identification_check = false;
    data.settings.requires_guarantees_identification_check = false;
  }

  /* Add Guarantor */
  data.settings.requires_guarantees = s.permissions_requires_guarantees;

  if (s.permissions_requires_guarantees) {
    data.settings.minimum_guarantees = ent.minimum_guarantees || 2;
  } else {
    data.settings.minimum_guarantees = 0;
  }

  /* Add Trade Reference */
  if (s.permissions_requires_trade_reference_check) {
    data.settings.requires_trade_reference_check =
      ent.requires_trade_reference_check || false;
    data.settings.minimum_trade_references = ent.minimum_trade_references || 0;
  } else {
    data.settings.requires_trade_reference_check = false;
    data.settings.minimum_trade_references = 0;
  }

  return data.settings;
}

export function uploadID(signature_id, image, removeNoIDReason) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_UPLOAD_ID_START,
    });
    var data = {
      identification_image: image,
    };

    if (removeNoIDReason) {
      data.no_identification_reason = null;
    }

    var signature = api("signatures", getState().current_user.access_token);

    signature.updateSignature(
      signature_id,
      data,
      result => {
        dispatch({
          type: APPLICATIONS_UPLOAD_ID_SUCCESS,
          payload: result,
        });
        dispatch(
          loadApplicationDetails(getState().applications.review_application.id),
        );
      },
      error => {
        dispatch({
          type: APPLICATIONS_UPLOAD_ID_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function updateProofOfAddress(signatureId, proofOfAddress) {
  return (dispatch, getState) => {
    dispatch({ type: APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_START });

    const data = { proof_of_address: proofOfAddress };
    const signaturesAPI = api(
      "signatures",
      getState().current_user.access_token,
    );

    signaturesAPI.updateSignature(
      signatureId,
      data,
      result => {
        dispatch({
          payload: result,
          type: APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_SUCCESS,
        });
        dispatch(
          loadApplicationDetails(getState().applications.review_application.id),
        );
      },
      error => {
        dispatch({
          payload: error,
          type: APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_ERROR,
        });
      },
    );
  };
}

export function uploadLegalEntityAgreement(
  applicationId,
  legalEntityAgreement,
) {
  return (dispatch, getState) => {
    dispatch({ type: APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_START });

    const data = { legal_entity_agreement: legalEntityAgreement.url };
    const applicationAPI = api(
      "applications",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    applicationAPI.updateApplication(
      applicationId,
      data,
      result => {
        dispatch({
          payload: result,
          type: APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_SUCCESS,
        });
        dispatch(
          loadApplicationDetails(getState().applications.review_application.id),
        );
      },
      error => {
        dispatch({
          payload: error,
          type: APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_ERROR,
        });
      },
    );
  };
}

export function updateReviewNote(review_id, data) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_START,
    });

    var reviews = api(
      "reviews",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    reviews.updateReview(
      review_id,
      data,
      result => {
        dispatch({
          type: APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_SUCCESS,
          payload: result,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function getNotes(id) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_LOAD_NOTES_START,
      payload: id,
    });

    var notes_api = api(
      "notes",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    notes_api.getNotes(
      id,
      "applications",
      result => {
        dispatch({
          type: APPLICATIONS_LOAD_NOTES_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_LOAD_NOTES_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function addNote(id, note) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_CREATE_NOTES_START,
      payload: id,
    });

    var notes_api = api(
      "notes",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    notes_api.createNote(
      id,
      note.attributes,
      "applications",
      result => {
        dispatch({
          type: APPLICATIONS_CREATE_NOTES_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_CREATE_NOTES_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function getConnectionId(application_id, success) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_LOAD_NOTES_START,
    });

    var apps = api(
      "applications",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
      { version: "/v2", headers: { "Content-Type": "application/json" } },
    );

    apps.getConnectionId(
      application_id,
      result => {
        dispatch({
          type: APPLICATIONS_GET_CONNECTION_ID_SUCCESS,
          payload: result.data.connection_id,
        });
        success(result);
      },
      error => {
        dispatch({
          type: APPLICATIONS_LOAD_NOTES_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function checkGuarantor(guarantor_id, on_success, on_fail) {
  return (dispatch, getState) => {
    const credit_checks = api(
      "credit_checks",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
      { version: "/v2", headers: { "Content-Type": "application/json" } },
    );
    const application_id = getState().applications.review_application.id;

    credit_checks.checkGuarantor(
      guarantor_id,
      () => {
        dispatch(loadApplicationDetails(application_id, on_success));
      },
      on_fail,
    );
  };
}

function _getApplicationApprovalLevel(application_id) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_LOAD_APPROVAL_LEVELS_START,
    });
    let applications = api(
      "applications",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    applications.getApprovalHierarchy(
      application_id,
      result => {
        dispatch({
          type: APPLICATIONS_LOAD_APPROVAL_LEVELS_SUCCESS,
          payload: result.data.data,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_LOAD_APPROVAL_LEVELS_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function addCreditCheck(
  type,
  application_id,
  signature_id,
  on_success,
  on_fail,
) {
  return (dispatch, getState) => {
    const credit_checks = api(
      "credit_checks",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
      { version: "/v2", headers: { "Content-Type": "application/json" } },
    );

    if (type === "company") {
      credit_checks.checkCompany(
        application_id,
        sucess => {
          if (on_success) {
            on_success();
          }
        },
        error => {
          dispatch(loadApplicationDetails(application_id));
        },
      );
    } else if (type === "person") {
      credit_checks.checkPerson(
        application_id,
        signature_id,
        sucess => {
          if (on_success) {
            on_success();
          }
        },
        on_fail,
      );
    } else if (type === "applicant") {
      credit_checks.checkApplicant(
        application_id,
        sucess => {
          if (on_success) {
            on_success();
          }
        },
        on_fail,
      );
    }
    dispatch(getApplicationCreditStatus(application_id));
  };
}

export function getApplicationCreditStatus(id) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_LOAD_CREDIT_STATUS_START,
    });
    let applications = api(
      "applications",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    applications.getApplicationCreditStatus(
      id,
      result => {
        if (result.data) {
          // if the credit check status is changed, call loadApplicationCreditChecks()
          if (
            JSON.stringify(getState().applications.credit_status) !==
            JSON.stringify(result.data)
          ) {
            dispatch(loadApplicationCreditChecks(id));
          }
        }
        dispatch({
          type: APPLICATIONS_LOAD_CREDIT_STATUS_SUCCESS,
          payload: result.data,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_LOAD_CREDIT_STATUS_ERROR,
          payload: error,
        });
      },
    );
  };
}

export function loadApplicationCreditChecks(id) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_LOAD_CREDIT_CHECKS_START,
    });

    var apps = api(
        "applications",
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
      ),
      data = {};

    apps.getApplication(
      id,
      result => {
        if (result.data.data) {
          data = getReviewApplication(result);
        }

        dispatch({
          type: APPLICATIONS_LOAD_CREDIT_CHECKS_SUCCESS,
          payload: data,
        });
      },
      error => {
        dispatch({
          type: APPLICATIONS_LOAD_CREDIT_CHECKS_ERROR,
          payload: error,
        });
      },
      {
        params: {
          include: GET_APPLICATION_INCLUDED,
        },
      },
    );
  };
}

export function uploadAddonAnswerFile(addonAnswerId, file) {
  return async (dispatch, getState) => {
    dispatch({
      type: APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_START,
    });

    const data = { file };
    const addonAnswers = api(
      "addon_answers",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    try {
      await addonAnswers.updateAddonAnswer(addonAnswerId, data);
      dispatch({
        type: APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_SUCCESS,
      });
      dispatch(
        loadApplicationDetails(getState().applications.review_application.id),
      );
    } catch (error) {
      dispatch({
        type: APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_ERROR,
        payload: error,
      });
    }
  };
}

function getReviewApplication(result) {
  const data = result.data.data;
  if (result.data.included) {
    data.consumer = result.data.included.find(inc => {
      return inc.id === data.relationships.consumer.data.id;
    });

    data.consumer_trading_names = result.data.included.filter(inc => {
      return inc.type === "trading_names";
    });
    data.guarantors = result.data.included.filter(inc => {
      return inc.type === "guarantors";
    });
    data.trade_references = result.data.included.filter(inc => {
      return inc.type === "trade_references" && !inc.attributes.archived;
    });

    const applicantAuthorisation = result.data.included.find(
      inc =>
        inc.type === "authorisations" &&
        inc.attributes.user_id &&
        inc.attributes.signature_id,
    );
    if (applicantAuthorisation) {
      data.signature = new Signature(
        result.data.included.find(inc => {
          return (
            inc.type === "signatures" &&
            inc.id === applicantAuthorisation.attributes.signature_id
          );
        }),
      );
      if (data.signature) {
        data.signature.aml = result.data.included.find(
          inc =>
            inc.type === "identification_checks" &&
            inc.attributes.check_type === "aml" &&
            inc.attributes.authorisation_id === applicantAuthorisation.id,
        );
        data.signature.veda_check = result.data.included.find(
          inc =>
            inc.type === "veda_checks" &&
            ["driver_licence", "passport"].includes(
              inc.attributes.service_type,
            ) &&
            inc.attributes.authorisation_id === applicantAuthorisation.id,
        );
        data.signature.identification_check = result.data.included.find(
          inc =>
            inc.type === "identification_checks" &&
            inc.attributes.check_type === "identification" &&
            inc.attributes.authorisation_id === applicantAuthorisation.id,
        );
      }
    }

    data.identity_check = result.data.included.find(inc => {
      let type = inc.type === "veda_checks";
      let serv = inc.attributes.service_type === "identity_plus";
      return type && serv;
    });

    data.credit_check_rule = result.data.included.find(inc => {
      return inc.type === "credit_check_rules";
    });

    //
    // TODO:
    //   Improve setting credit_check data once VedaCheck (Equifax) is
    //   merged with CreditWorks using credit_checks table
    //
    data.credit_checks = [];

    let credit_checks = result.data.included.filter(inc => {
      return inc.type === "credit_checks";
    });

    let veda_checks = result.data.included.filter(inc => {
      let type = inc.type === "veda_checks";
      let serv = inc.attributes.service_type;
      let check = serv === "veda_score" || serv === "commercial";
      return type && check;
    });

    data.credit_checks = data.credit_checks
      .concat(veda_checks)
      .concat(credit_checks);

    data.credit_checks.sort(
      (a, b) =>
        new Date(a.attributes.created_at) - new Date(b.attributes.created_at),
    );

    data.guarantors.forEach(g => {
      const guarantorAuthorisation = result.data.included.find(
        inc =>
          inc.type === "authorisations" &&
          inc.attributes.guarantor_id === g.id &&
          inc.attributes.signature_id,
      );
      if (guarantorAuthorisation) {
        g.signature = new Signature(
          result.data.included.find(inc => {
            return (
              inc.type === "signatures" &&
              inc.id === guarantorAuthorisation.attributes.signature_id
            );
          }),
        );

        if (g.signature) {
          g.signature.aml = result.data.included.find(
            inc =>
              inc.type === "identification_checks" &&
              inc.attributes.check_type === "aml" &&
              inc.attributes.authorisation_id === guarantorAuthorisation.id,
          );

          g.signature.veda_check = result.data.included.find(
            inc =>
              inc.type === "veda_checks" &&
              ["driver_licence", "passport"].includes(
                inc.attributes.service_type,
              ) &&
              inc.relationships.authorisation_id === guarantorAuthorisation.id,
          );

          g.signature.identification_check = result.data.included.find(
            inc =>
              inc.type === "identification_checks" &&
              inc.attributes.check_type === "identification" &&
              inc.attributes.authorisation_id === guarantorAuthorisation.id,
          );
        }
      }
    });

    result.data.included.forEach(o => {
      if (o.type === "trade_reference_checks") {
        data.trade_references.forEach(tr => {
          if (
            tr.relationships.trade_reference_check &&
            tr.relationships.trade_reference_check.data.id === o.id
          ) {
            tr.check = o;
            let notes = [];
            if (o.relationships.notes) {
              o.relationships.notes.data.forEach(n => {
                notes.push(
                  result.data.included.find(inc => {
                    return inc.type === "notes" && inc.id === n.id;
                  }),
                );
              });
              tr.check.notes = notes;
            }
          }
        });
      }
    });

    //
    // Addresses
    //
    let physicalAddressId = data.attributes.physical_address_id;
    data.physical_address = result.data.included.find(includedData => {
      return (
        includedData.type === "addresses" &&
        includedData.id === physicalAddressId
      );
    });

    let postalAddressId = data.attributes.postal_address_id;
    if (postalAddressId) {
      data.postal_address = result.data.included.find(includedData => {
        return (
          includedData.type === "addresses" &&
          includedData.id === postalAddressId
        );
      });
    }

    data.people = result.data.included.filter(inc => {
      return inc.type === "people";
    });

    data.addon_answers = result.data.included.filter(
      inc => inc.type === "addon_answers",
    );
    data.addon_rules = result.data.included.filter(
      inc => inc.type === "addon_rules",
    );
    data.cardholders = result.data.included.filter(
      inc => inc.type === "cardholders",
    );
    data.paperless = result.data.included.filter(
      inc => inc.type === "payments",
    );

    data.cardholders.forEach(c => {
      const cardholderAuthorisation = result.data.included.find(
        inc =>
          inc.type === "authorisations" &&
          inc.attributes.cardholder_id === c.id &&
          inc.attributes.signature_id,
      );

      if (cardholderAuthorisation) {
        c.signature = new Signature(
          result.data.included.find(inc => {
            return (
              inc.type === "signatures" &&
              inc.id === cardholderAuthorisation.attributes.signature_id
            );
          }),
        );

        if (c.signature) {
          c.signature.aml = result.data.included.find(
            inc =>
              inc.type === "identification_checks" &&
              inc.attributes.check_type === "aml" &&
              inc.attributes.authorisation_id === cardholderAuthorisation.id,
          );

          c.signature.veda_check = result.data.included.find(
            inc =>
              inc.type === "veda_checks" &&
              ["driver_licence", "passport"].includes(
                inc.attributes.service_type,
              ) &&
              inc.relationships.authorisation_id === cardholderAuthorisation.id,
          );

          c.signature.identification_check = result.data.included.find(
            inc =>
              inc.type === "identification_checks" &&
              inc.attributes.check_type === "identification" &&
              inc.attributes.authorisation_id === cardholderAuthorisation.id,
          );
        }
      }
    });

    data.signatories = result.data.included.filter(
      inc => inc.type === "signatories",
    );

    data.signatories.forEach(s => {
      const signatoryAuthorisation = result.data.included.find(
        inc =>
          inc.type === "authorisations" &&
          inc.attributes.signatory_id === s.id &&
          inc.attributes.signature_id,
      );

      if (signatoryAuthorisation) {
        s.signature = new Signature(
          result.data.included.find(inc => {
            return (
              inc.type === "signatures" &&
              inc.id === signatoryAuthorisation.attributes.signature_id
            );
          }),
        );

        if (s.signature) {
          s.signature.aml = result.data.included.find(
            inc =>
              inc.type === "identification_checks" &&
              inc.attributes.check_type === "aml" &&
              inc.attributes.authorisation_id === signatoryAuthorisation.id,
          );
          s.signature.veda_check = result.data.included.find(
            inc =>
              inc.type === "veda_checks" &&
              ["driver_licence", "passport"].includes(
                inc.attributes.service_type,
              ) &&
              inc.attributes.authorisation_id === signatoryAuthorisation.id,
          );

          s.signature.identification_check = result.data.included.find(
            inc =>
              inc.type === "identification_checks" &&
              inc.attributes.check_type === "identification" &&
              inc.attributes.authorisation_id === signatoryAuthorisation.id,
          );
        }
      }
    });
  }

  return data;
}
