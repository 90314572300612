import capitalize from "lodash.capitalize";
import BaseModel from "models/BaseModel";
import isBlank from "utils/isBlank";

export default class GuarantorModel extends BaseModel {
  get formattedPosition() {
    const position = capitalize(this.position);

    if (isBlank(this.percentageShare)) {
      return position;
    }

    return `${position} ${this.percentageShare}%`;
  }
}
