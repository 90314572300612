import AccountInformation from "./AccountInformation";
import CompanyAffiliations from "./CompanyAffiliations";
import ConsumerInformation from "./ConsumerInformation";
import CreditDefaults from "./CreditDefaults";
import FileAccess from "./FileAccess";
import Insolvencies from "./Insolvencies";
import Judgements from "./Judgements";
import PropertyOwnership from "./PropertyOwnership";
import SummaryInformation from "./SummaryInformation";

export default [
  {
    component: SummaryInformation,
    title: "Summary Information",
  },
  {
    component: ConsumerInformation,
    title: "Consumer Information",
  },
  {
    component: PropertyOwnership,
    title: "Property Ownership",
  },
  {
    component: AccountInformation,
    title: "Account Information",
  },
  {
    component: FileAccess,
    title: "File Access",
  },
  {
    component: CreditDefaults,
    title: "Credit Defaults",
  },
  {
    component: Judgements,
    title: "Judgements",
  },
  {
    component: Insolvencies,
    title: "Insolvencies",
  },
  {
    component: CompanyAffiliations,
    title: "Company Affiliation",
  },
];
