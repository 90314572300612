import axios from "./axios";;
import { transformRequest, transformResponse } from "utils/axios_transforms";

export default class Cardholders {
  constructor(config) {
    this.axios = axios(config);
    this.type = "cardholders";
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      "v1",
      "v2",
    );

    this.axios.defaults.transformResponse = [transformResponse];
    this.axios.defaults.transformRequest = [transformRequest];

    this.path = `/${this.type}`;
  }

  createCardholder(attributes) {
    return this.axios.post(this.path, {
      data: { attributes, type: this.type },
    });
  }

  updateCardholder(id, attributes) {
    return this.axios.patch(`${this.path}/${id}`, {
      data: { attributes, type: this.type },
    });
  }

  updateAndCreateCardholders({ application_id, addon_rule_id, data }) {
    return this.axios.post(
      `${this.path}/persist_records`,
      {
        addon_rule_id,
        application_id,
        data,
      }
    )
  }

  deleteCardholder(id) {
    return this.axios.delete(`${this.path}/${id}`);
  }
}
