import axios from "./axios";;

const ModuleCradholderApplicationFlows = function(config) {
  this.axios = axios(config);
  this.type = "module_cardholder_application_flows";
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
};

//
// Await-able functions
// The following functions allows the use of async/await
//
ModuleCradholderApplicationFlows.prototype.sendVerificationCode = function(options) {
  return this.axios.post(`/${this.type}/send_verification_code`, options);
};

ModuleCradholderApplicationFlows.prototype.validateVerificationCode = function(options) {
  return this.axios.get(`/${this.type}/validate_verification_code`, options);
};

ModuleCradholderApplicationFlows.prototype.getDetails = function(id, options) {
  return this.axios.get(`/${this.type}/${id}`, options);
};

ModuleCradholderApplicationFlows.prototype.update = function(id, options) {
  return this.axios.patch(`/${this.type}/${id}`, options);
};

export default ModuleCradholderApplicationFlows;
