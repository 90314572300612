import ExpandableSection from "modules/addons/auto_decisioning/components/ExpandableSection";
import React from "react";
import { connect } from "react-redux";

import styles from "./css/AutoDecisioning.css";

class Paperless extends React.Component {
  render() {
    const { requiresPaperlessCheck, error, read_only: readOnly } = this.props;

    return (
      <div className={styles.section}>
        <ExpandableSection
          id={"paperless_check"}
          title={"Paperless Direct Debit"}
          info_text={`*Please note: By setting this rule to yes, it will require your customer to complete their bank 
          account details and agreeing to the direct debit terms, for this rule to pass.`}
          disabled={readOnly}
          account_rule_on={requiresPaperlessCheck}
          hide_setting_message={readOnly}
          error={error}
        />
      </div>
    );
  }
}

module.exports = connect((state, ownProps) => {
  return {
    requiresPaperlessCheck: ownProps.current_entity.requires_paperless_check,
  };
})(Paperless);
