/* Import libs */
import React from "react";

/* Import components */
import TextInput from "modules/shared/components/inputs/TextInput";
import EmailInput from "modules/shared/components/inputs/EmailInput";
import CountryCodeDropdown from "modules/shared/components/widgets/static/CountryCodeDropdown";

/* import styles */
import styles from "./css/TradeReference.css";

var SubSectionRefereeDetails = createClass({
  render: function() {
    const {
      handleChange,
      handleBlur,
      errors,
      values,
      countryCodeHandleChange,
      emailChangeHandler,
    } = this.props;

    return (
      <div className={styles.flow_styles}>
        <div className={styles.row}>
          <div className={styles.input_col}>
            <TextInput
              id="first_name"
              name="first_name"
              error={errors["first_name"]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label="First name"
              required={true}
              value={values["first_name"]}
            />
          </div>
          <div className={styles.input_col}>
            <TextInput
              id="last_name"
              name="last_name"
              error={errors["last_name"]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label="Last name"
              required={true}
              value={values["last_name"]}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.input_col}>
            <TextInput
              id="business_name"
              name="business_name"
              error={errors["business_name"]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label="Business name"
              required={true}
              value={values["business_name"]}
            />
          </div>
          <div className={styles.input_col}>
            <EmailInput
              id="email"
              name="email"
              ref="email"
              handleChange={emailChangeHandler}
              handleBlur={emailChangeHandler}
              label="Email"
              required={false}
              value={values["email"]}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.input_col}>
            <CountryCodeDropdown
              id={"contact_country_code"}
              countryCodeId="contact_country_code"
              label={"Country"}
              countryCodeError={errors["contact_country_code"]}
              countryCodeValue={values["contact_country_code"]}
              required={true}
              handleChange={countryCodeHandleChange}
            />
          </div>
          <div className={styles.input_col}>
            <TextInput
              id="contact_phone"
              name="contact_phone"
              type="tel"
              error={errors["contact_phone"]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label="Phone number"
              required={true}
              value={values["contact_phone"]}
            />
          </div>
        </div>
        <div className={styles.row}>
          <TextInput
            id="previous_business_name"
            name="previous_business_name"
            error={errors["previous_business_name"]}
            handleChange={handleChange}
            handleBlur={handleBlur}
            label="Previous business name"
            required={false}
            value={values["previous_business_name"]}
          />
        </div>
        <p className={styles.note}>
          If this referee is from a previous business please state
        </p>
      </div>
    );
  },
});

module.exports = SubSectionRefereeDetails;
