/* Import libraries */
import React from "react";

/* Import CSS */
import styles from "./css/SectionBadge.css";

/* Import Components */
import Icon from "modules/shared/components/svg/Icon";

var SectionBadge = createClass({
  render: function() {
    const {
      state,
      css_class,
      badge_class,
      text,
      tick,
      icon,
      img,
      icon_label,
      size,
    } = this.props;
    let style = styles.item,
      badge_style = styles.badge,
      badge_text = <span className={styles.text}>{text}</span>,
      icon_item,
      badgeStyle;

    if (state === "completed") {
      style = styles.item_completed;
    }

    if (state === "section_complete") {
      style = styles.section_complete;
    }

    // if (img) {
    //   icon_item = <img src={img} />;
    // }

    if (img) {
      badgeStyle = {
        backgroundImage: "url(" + img + ")",
      };
    }

    if (icon) {
      icon_item = (
        <span className={styles.icon}>
          <Icon icon={icon} size={size} />
        </span>
      );
    }

    if (tick === true) {
      badge_style = styles.badge_tick;
    }

    if (badge_class) {
      badge_style = styles[badge_class];
      if (badge_class === "badge_grey") {
        style = styles.item;
      }
    }

    if (css_class) {
      style = styles[css_class];
    }

    return (
      <div className={style}>
        <div className={badge_style} style={badgeStyle}>
          {icon_item}
          {badge_text}
        </div>
        <div className={styles.shadow}></div>
      </div>
    );
  },
});

export default SectionBadge;
