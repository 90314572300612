import React from "react";
import { connect } from "react-redux";
import styles from "./css/Layout.css";
import { userLogin, setQueryParamsForTokenAuth } from "../actions";
import get from "lodash.get";

class Layout extends React.Component {
  UNSAFE_componentWillMount() {
    const {
      dispatch,
      location: {
        query: { t, e, redirect, referrer, ...rest },
      },
    } = this.props;

    let attributes = rest;

    const entity = get(this.props, "location.query.entity", null);

    if (e && t) {
      // Fix for spaces, since it seems react-router replaces '+' with spaces.
      // https://github.com/ReactTraining/react-router/issues/2426
      const email = e.replace(" ", "+");
      if (attributes.application) {
        attributes = { ...rest, onboarding: true };
      }
      dispatch(setQueryParamsForTokenAuth(attributes));
      dispatch(userLogin(email, t, redirect, () => null, entity, referrer));
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
};

export default connect()(Layout);
