/* Import libraries */
import React from "react";
import TermsScroller from "./TermsScroller";

import styles from "./css/SuppliersStandardTermsAndConditions.css";

var SuppliersStandardTermsAndConditions = createClass({
  componentWillMount: function() {
    const { noScroll, onScrollToBottom } = this.props;
    if (noScroll) onScrollToBottom();
  },

  render: function() {
    const { css_class, noScroll } = this.props;
    let style = styles.terms;

    if (css_class) {
      style = styles[css_class];
    }

    var verticalContainerCSS = {
      backgroundColor: "#c9c9c9",
      opacity: 1,
      width: 15,
      zIndex: 1,
      borderRadius: 3,
    };

    var verticalScrollbarCSS = {
      borderRadius: 3,
      width: 15,
      margin: 0,
      zIndex: 2,
    };

    let tnc = (
      <div>
        <h2 className={styles.copy_heading}>
          Supplier’s Standard Terms and Conditions
        </h2>
        <div className={styles.copy}>
          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>1.</span> Definitions
            </strong>
            <br />
            In these terms and conditions:
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.1</span> “Business Customer”
            means the person, firm, company, partnership, trust or entity named
            on the Trade Account Application.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.2</span> “Consumer Guarantees
            Act” means the Consumer Guarantees Act 1993.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.3</span> “Goods” means all
            goods or work products which are supplied by the Supplier under any
            contract, agreement, arrangement, or understanding to the Business
            Customer.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.4</span> “Intellectual
            Property” means any intellectual property or other property of a
            creative, branding, or innovative nature, including (as an example
            but without limitation) trademarks, designs, copyright, methods of
            business of manufacture, and confidential information.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.5</span> “PPSA” means the
            Personal Property Securities Act 1999, and in these Terms, unless
            the contrary intention appears, the terms “at risk”, “financing
            statement”, “financing change statement”, “proceeds”, “security
            interest” and “verification statement” each have the meaning given
            to that term in the PPSA.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.6</span> “Premises” means the
            physical address of the Business Customer noted on the Trade Account
            Application.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.7</span> “Services” means all
            services which are supplied by the Supplier under any contract,
            agreement, arrangement, or understanding to the Business Customer.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>1.8</span> “Supplier” means the
            Supplier named in the Trade Account Application and its successors
            and assigns. 1.9 “Terms” means these standard terms and conditions.
            1.10 “Trade Account Application” means the application for a trade
            credit account between the Supplier and the Business Customer in
            relation to the supply of Goods and/or Services.
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>2.</span> General
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.1</span> These Terms apply to
            all offers, quotations, and agreements entered into between the
            Supplier and the Business Customer for the supply of Goods and/or
            Services.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.2</span> By submitting the
            Trade Account Application or placing an order for Goods and/or
            Services, the Business Customer shall be deemed to accept these
            Terms.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.3</span> To the extent the
            Consumer Guarantees Act applies, nothing in these Terms limits the
            Business Customer’s rights under the Consumer Guarantees Act unless
            it would be fair and reasonable to do so.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.4</span> Goods are offered
            subject to availability. Where Goods are not available, the Supplier
            has no obligation to supply the Business Customer.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.5</span> Reference to any
            legislation includes its successor or amendment legislation; and the
            singular includes the plural and vices versa.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.6</span> Clauses 4, 5, 6,
            9.2(d), 9.2(e) and 10 only apply where the Supplier has supplied
            Goods to the Business Customer under these Terms.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>2.7</span> Clause 7 only
            applies where the Supplier has supplied Services to the Business
            Customer under these Terms.
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>3.</span> Payment and price
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>3.1</span> Payment for Goods
            and/or Services shall be made by the Business Customer:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> 7 days after the date
            of the Supplier’s invoice, or such other time notified by the
            Supplier; and
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> for the full amount
            stated on the Supplier’s invoice (without any kind of deduction,
            counterclaim, or set-off) and in cleared funds; but
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>c.</span> immediately, where
            there has been a default under these Terms.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>3.2</span> Where the full
            payment has not been received in accordance with this clause, the
            Business Customer agrees to pay interest to the Supplier on the
            amount owing on the due date at the rate of 2% per month, calculated
            on a daily basis, and shall accrue in the same manner until payment
            of the overdue amount and any interest is made in full. Where only
            part payment has been made, or any payment made where full payment
            has not been made for Goods and/or Services previously supplied, the
            Supplier may apply the payment received by the Business Customer
            towards any of the Goods and/or Services supplied at the Supplier’s
            sole discretion.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>3.3</span> Where the Supplier
            has supplied Goods to the Business Customer under these Terms, the
            Business Customer will pay to the Supplier on a full indemnity basis
            all costs and expenses (including costs on a solicitor and client
            basis) that the Supplier incurs in general administration, delivery
            of Goods, storage (where the Business Customer fails to take
            delivery), securing its position, PPSA matters in clauses 9 and 10,
            repossessing, and enforcing, or attempting to enforce any of the
            Supplier’s rights under these Terms.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>3.4</span> All prices are in
            New Zealand dollars and exclusive of goods and services tax and
            other government levies, unless otherwise stated, and the amount of
            goods and services tax shall be added to the price payable by the
            Business Customer.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>3.5</span> Prices are only
            valid for the time stated on any quotation, website, or price list,
            or if no time is stated, the date of the quotation, price list, or
            search of website, and is subject to change without notice.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>3.6</span> The Business
            Customer is liable for full payment of any order for Goods and/or
            Services subsequently cancelled by the Business Customer unless
            otherwise agreed in writing by the Supplier.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>3.7</span> Any grant of credit
            by the Suppler to the Business Customer is at the Supplier’s sole
            discretion, and can be cancelled at any time without notice to the
            Business Customer.
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>4.</span> Delivery and risk
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>4.1</span> The Supplier
            undertakes to use all reasonable endeavours to deliver the Goods
            within the time requested, but the time of delivery shall not be
            treated as a condition of sale. To the fullest extent permitted by
            law, and subject to clause 2.3 of these Terms, the Supplier:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> will not be liable in
            any way to the Business Customer or any other party for loss
            resulting from delay; and
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> reserves the right to
            deliver the Goods by instalments and each instalment shall be deemed
            to be a separate contract governed by these Terms.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>4.2</span> Delivery shall occur
            when the Supplier (or a third party on instruction by the Supplier)
            hands possession or control of the Goods to the Business Customer
            (or a third party on instruction by the Business Customer) at which
            point the Goods shall be at the sole risk of the Business Customer.
            The Supplier shall not be liable for any damage to the Goods once
            risk has passed.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>4.3</span> Subject to any
            returns policy expressly stated by the Supplier to the Business
            Customer, the Business Customer shall be deemed to have accepted the
            Goods unless the Business Customer notifies the Supplier of damage
            to the Goods within five (5) working days of delivery of the Goods
            to the Business Customer.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>4.4</span> Insurance, assembly,
            configuration, and installation of the Goods is the sole
            responsibility of the Business Customer.
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>5.</span> Insurance
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>5.1</span> For so long as any
            amounts are owing to the Supplier the Business Customer must insure
            and keep the Goods insured once risk has passed to the Business
            Customer for the full insurable value or such other amount or
            amounts and against such risks and contingencies and on such terms
            as the Supplier deems necessary.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>5.2</span> The Business
            Customer must notify the Supplier of the happening of any event in
            relation to Goods which are insured. Where the Business Customer
            receives any insurance proceeds for the Goods, such proceeds shall
            be paid to the Supplier in reduction of amounts owing by the
            Business Customer to the Supplier.
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>6.</span> Location and title
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>6.1</span> The Goods shall be
            held by the Business Customer at the Premises until the Goods have
            been sold in the ordinary course of the Business Customer’s
            business. The Business Customer shall not remove the Goods, or allow
            the Goods to be removed from the Premises prior to sale without the
            prior written consent of the Supplier.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>6.2</span> The Goods shall
            either be kept separate from other goods or labelled in such a way
            so that the Goods are easily identifiable as belonging to the
            Supplier. The Business Customer must keep accurate financial records
            in order to trace the proceeds of any sale or other disposition of
            the Goods until the Goods are fully paid for. The Supplier, or its
            agent(s), may on reasonable notice enter the Premises (or other
            premises to which the Business Customer has access and where the
            Goods are stored or where the Supplier reasonably believes the Goods
            are stored) at any time, and search for and inspect the Goods and/or
            the Business Customer’s financial records relating to the Goods,
            without incurring any liability to the Business Customer or any
            person claiming through the Business Customer. The Business Customer
            may not revoke the permission granted in this clause.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>6.3</span> Despite delivery of
            the Goods to the Business Customer, passing of risk, and possession
            of Goods by the Business Customer, title to the Goods is retained by
            the Supplier, and the Goods are held by the Business Customer as
            bailee only, until the Business Customer has paid the Supplier in
            full for all Goods in accordance with clause 3 of these Terms. Part
            payment of any amount for the Goods does not convey any part right,
            title, and interest in the Goods.
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>7.</span> Services
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>7.1</span> The Supplier may
            suspend provision of the Services or refuse to provide Services at
            any time. The Supplier may also sub-contract the whole or any part
            of the Services.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>7.2</span> The Business
            Customer must not:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> use the Services for
            any purpose not expressly permitted by the Supplier; or
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> resupply the Services
            or otherwise make the Services available to any person, except with
            the Supplier’s prior written consent in each instance.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>7.3</span> Where the Supplier
            is supplying Services at the Premises the Business Customer must:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> ensure that it
            provides the Supplier with reasonable access to the Premises to
            enable the Supplier to provide the Services; and
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> complies in all
            respects with the Health and Safety at Work Act 2015 (and any
            successive health and safety legislation).
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>8.</span> Representations and
              warranties
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>8.1</span> The Business
            Customer represents and warrants that:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> all information
            provided in the Trade Account Application is true, correct, and
            complete;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> the Customer is
            acquiring the Goods and Services for business purposes, and
            accordingly, subject to clause 2.3 of these Terms, to the fullest
            extent permitted by law and solely to the extent it is fair and
            reasonable to do so: the Consumer Guarantees Act 1993 does not apply
            to the supply of the Goods and/or Services; and
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>c.</span> it will notify the
            Supplier of any change in ownership, control, status, or management
            of the Business Customer.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>8.2</span> The above
            representations and warranties apply at the time of every supply of
            Goods and/or Services under these Terms.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>8.3</span> Subject to clause
            2.3 of these Terms, the only warranty given by the Supplier is the
            express warranty supplied to the Business Customer by the Supplier
            or the manufacturer of the Goods in respect of specified Goods. Any
            such warranty may include situations that void the warranty. All
            other terms, conditions, warranties and representations expressed or
            implied, whether by operation of law, statutory or otherwise are
            expressly excluded (except any which may not lawfully be excluded).
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>8.4</span> The Supplier’s
            liability to the Business Customer under the Fair Trading Act 1986
            is limited to the fullest extent permitted by law, The Business
            Customer agrees that it is fair and reasonable that the parties are
            bound by the provisions in these Terms.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>8.5</span> Subject to any
            liability that cannot be excluded by law, and clause 2.3 of these
            Terms, the Supplier’s total aggregate liability (whether in tort
            (including negligence), contract or otherwise) for any loss or
            damage or injury arising directly or indirectly from any defect in
            or non-compliance of any Goods, or any other breach of the
            Supplier’s obligations shall be limited to the price paid by the
            Business Customer for the relevant Goods and/or Services.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>8.6</span> To the fullest
            extent permitted by law, and subject to clause 2.3 of these Terms,
            the Supplier will not be liable for any loss of profits, loss of
            revenue, loss of savings or for any indirect, consequential,
            special, exemplary, or incidental damages suffered by the Business
            Customer as a result of these Terms, the Goods and/or the Services.
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>9.</span> Default
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>9.1</span> The Business
            Customer acknowledges the following shall be deemed to be a default
            under these Terms:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> if any amount payable
            by the Business Customer is overdue;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> if the Business
            Customer fails to meet any obligation under, or there is a breach of
            any provision or warranty in these Terms, or if there is a breach of
            any obligation under any other contract or deed between the Business
            Customer and the Supplier;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>c.</span> if the Business
            Customer becomes or is likely to become insolvent or an arrangement
            or compromise is made with its creditors;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>d.</span> if a receiver,
            liquidator, administrator, or statutory manager (or any other
            similar official) is appointed to the Business Customer or the
            Business Customer is adjudicated bankrupt;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>e.</span> an encumbrancer takes
            possession of any Goods or other collateral;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>f.</span> if the Business
            Customer no longer carries on business or threatens to cease
            carrying on business; or
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>g.</span> if the Supplier has
            grounds to believe the Goods are at risk.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>9.2</span> Following any
            default under these Terms, the Supplier (or its agent(s)) shall be
            entitled to do any or all of the following:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> refuse to supply any
            Goods and/or Services; and/or
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> call up all amounts
            owing by the Business Customer to the Supplier as immediately due
            and payable; and/or
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>c.</span> immediately enforce
            the security interest created under these Terms; and/or
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>d.</span> cancel all or any
            part of any contract or contracts (including these Terms) with the
            Business Customer; and/or
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>e.</span> enter the Premises
            (or any other premises to which the Business Customer has access and
            where the Goods are stored or where the Supplier reasonably believes
            the Goods are stored) to recover any of the Goods (whether they are
            affixed, attached, or stored in any way) or enter any other premises
            where the Supplier believes the Goods are stored at any time and
            without notice and to use such force as required (the Business
            Customer irrevocably authorises and indemnifies the Supplier and any
            of its agents for this purpose and may not revoke the permission
            granted in this clause), and/or
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>f.</span> appoint any person or
            persons to be receiver of all or any of the Goods. A receiver has
            (in addition to the powers conferred by the Receiverships Act 1993,
            at law or otherwise and except to the extent expressly excluded by
            his or her terms of appointment) all the powers in relation to the
            Goods to do anything the Business Customer (or a person with
            absolute ownership of the Goods and carrying on the business for its
            own benefit) could do and to exercise such powers on such terms and
            conditions as the receiver thinks fit.
          </p>

          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>9.3</span> The enforcement,
            partial enforcement, waiver of rights, invalidity, or grant of time,
            of any of the Supplier’s rights under these Terms shall not be
            deemed to be a waiver or invalidity of any other of the Supplier’s
            rights under these Terms.
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>10.</span> PPSA
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>10.1</span> The Business
            Customer grants to the Supplier a security interest in all present
            and after acquired Goods supplied by the Supplier to the Business
            Customer and all proceeds of the Goods for the purposes of the PPSA
            as security for the payment of the Goods and any amount owing by the
            Business Customer to the Supplier from time to time.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>10.2</span> The Business
            Customer acknowledges that it has received value as at the date of
            the first delivery of Goods under these Terms and that nothing in
            these Terms is an agreement that a security interest created herein
            attaches at a later time that the time specified in section 40(1) of
            the PPSA.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>10.3</span> Each security
            interest created under these Terms is a continuing security,
            notwithstanding any intermediate payments or settlements of accounts
            of anything else and is in addition to, and is not to be merged
            with, any other security or guarantee expressed or intended to be
            security for any other obligations owing by the Business Customer to
            the Supplier.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>10.4</span> On the request of
            the Supplier, the Business Customer shall promptly execute any
            documents and do anything else required by the Supplier to give
            effect to these Terms and to ensure that the security interest
            created under these Terms constitutes and remains a first ranking
            perfected security interest over the Goods and their proceeds.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>10.5</span> The Business
            Customer:
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> shall not consent to
            or enter into any agreement which permits any supplier or other
            person to register a security interest in respect of the Goods or
            their proceeds subject to the Supplier’s security interest, which
            ranks in priority to the Supplier’s rights as first-ranking
            perfected security holder in the Goods and their proceeds;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> shall notify the
            Supplier in writing of a change of its name, address or contact at
            least 14 working days prior to the date on which the change of name
            becomes effective;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>c.</span> shall provide any
            information the Supplier reasonably requires to complete a financing
            statement or a financing change statement; and
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>d.</span> waives any right to
            receive a copy of any verification statement, financing statement or
            financing change statement under the PPSA.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>10.6</span> The Supplier is not
            required to marshal, enforce or apply under any security interest,
            guarantee or other entitlement held by the Supplier at any time or
            any money or property that the Supplier at any time holds or is
            entitled to receive.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>10.7</span> The Business
            Customer agrees that nothing in sections 114(1)(a), 133 and 134 of
            the PPSA shall apply to these Terms. The Business Customer agrees
            that it has none of the rights referred to in section 107(2)(a) to
            (i) of the PPSA.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>10.8</span> The Business
            Customer agrees that its rights as debtor in sections 116, 120(2),
            121, 127, 129 and 131 of the PPSA shall not apply to these Terms.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>10.9</span> The Business
            Customer must not give to the Supplier a written demand, or allow
            any other person to give the Supplier a written demand, requiring
            the Supplier to register a financing change statement of lodge a
            change demand or allow any other person 3 to lodge a change demand,
            in each case in relation to a financing statement registered by us
            under the PPSA.
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>11.</span> Intellectual
              property rights
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>11.1</span> The Supplier owns
            all Intellectual Property in relation to the Goods and/or Services,
            and the Business Customer shall not acquire any right, title or
            interest in the Supplier’s Intellectual Property.
          </p>

          <p className={styles.list_type_1}>
            <strong>
              <span className={styles.copy_number}>12.</span> Miscellaneous
            </strong>
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>12.1</span> The Supplier shall
            be entitled to amend these Terms at its sole discretion upon 30
            days’ notice in writing to the Business Customer. Such amended terms
            shall apply to all orders placed by the Business Customer at the
            expiry of the 30 day notice period.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>12.2</span> The Business
            Customer may not assign its rights under these Terms, the Trade
            Account Application, and any other document between the Business
            Customer and the Supplier without written consent of the Supplier.
            The Supplier may assign or transfer its rights and title under these
            Terms without consent of the Business Customer.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>12.3</span> Where any provision
            of these Terms becomes illegal, invalid or unenforceable the
            remaining provisions of the Terms will be unaffected.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>12.4</span> The parties are
            independent contractors, not employees, agents or representatives of
            each other. Neither party has the right to bind the other party or
            any other party to any agreement.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>12.5</span> The Business
            Customer consents to receive notices given pursuant to these Terms
            and other communications from the Supplier electronically.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>12.6</span> Neither party will
            be liable for any delay in meeting, or failure to meet, its
            obligations (other than an obligation to pay money) where such delay
            or failure is caused by any event outside its reasonable control
            (including any delay or failure caused by any act or omission of the
            other party). If a party is prevented from meeting its obligations
            due to circumstances beyond its reasonable control it shall notify
            the other party of those circumstances as soon as reasonably
            practicable and shall use its reasonable endeavours to minimise the
            effects of its inability to perform its obligations.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>12.7</span> These Terms shall
            be governed by and construed in accordance with the laws of New
            Zealand and each of the parties submits to the non-exclusive
            jurisdiction of the courts of New Zealand.
          </p>
        </div>
      </div>
    );

    let content = null;

    if (noScroll) {
      content = tnc;
    } else {
      content = (
        <TermsScroller
          speed={0.8}
          className={styles.scroll_section}
          contentClassName="options"
          horizontal={false}
          verticalContainerStyle={verticalContainerCSS}
          verticalScrollbarStyle={verticalScrollbarCSS}
          onScrollToBottom={this.props.onScrollToBottom}
        >
          {tnc}
        </TermsScroller>
      );
    }

    return <div className={style}>{content}</div>;
  },
});

module.exports = SuppliersStandardTermsAndConditions;
