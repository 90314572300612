import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import React, { Component } from "react";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import BorderedTextField from "./BorderedTextField";

import styles from "./css/TextInput.css";

// http://emailregex.com/
const EMAIL_REG_EX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//**
//
// TODO: Find a way to inherit or re-use TextInput Component
//
//**

export function isEmailPatternValid(email) {
  return EMAIL_REG_EX.test(email);
}

function TextInput({
  disabled,
  error,
  hide_helper_text,
  helper_message,
  loading,
  onBlur,
  onChange,
  placeholder,
  id,
  label,
  value,
}) {
  return (
    <div className={styles.material_container}>
      <FormControl
        error={!!error}
        disabled={disabled}
        onBlur={onBlur}
        className={styles.material_form_control}
      >
        <InputLabel
          classes={{
            root: styles.material_label,
            shrink: styles.material_label_shrink,
          }}
          htmlFor={id}
        >
          {label}
        </InputLabel>
        <Input
          value={value}
          id={id}
          name={id}
          type="email"
          placeholder={placeholder}
          className={`${styles.material_input} is-lowercase`}
          inputProps={{ onChange }}
          startAdornment={<span />}
          endAdornment={
            loading && (
              <InputAdornment position="end">
                <CircularProgress size={18} thickness={5} />
              </InputAdornment>
            )
          }
        />
      </FormControl>
      <FormHelperText
        id="weight-helper-text"
        error={!!error}
        className={styles.material_helper_text}
        style={
          error
            ? { fontSize: 10, fontWeight: 800 }
            : { fontSize: 10, fontWeight: 400 }
        }
      >
        {hide_helper_text ? "" : error || helper_message}
      </FormHelperText>
    </div>
  )
}

class EmailInput extends Component {
  state = {
    required: false,
    triggerValidation: false,
    value: "",
  };
  // Allow parent component to show the error message by setting the
  // `triggerValidation` props to true
  static getDerivedStateFromProps(nextProps, prevState) {
    const { required, triggerValidation, value } = nextProps;
    if (triggerValidation && required && isBlank(value)) {
      return { error: "Email is required" };
    }

    if (isPresent(value) && EMAIL_REG_EX.test(value)) {
      return { error: "" };
    }

    return { error: prevState.error };
  }

  _isValueEmpty(value) {
    return value === undefined || value === null || value.length === 0;
  }

  _isValuePresent(value) {
    return !this._isValueEmpty(value);
  }

  _validate(value, no_set_change) {
    if (this._isValuePresent(value)) {
      return this._validatePattern(value, no_set_change);
    }

    return this._validateRequired();
  }

  _validatePattern(value, no_set_change) {
    let _error = null;

    if (!isEmailPatternValid(value)) {
      if (!no_set_change) {
        _error = "Please enter a valid email address";
        this.setState({ error: _error });
      }
      return false;
    }
    this.setState({ error: _error });

    return true;
  }

  _validateRequired(callback) {
    const { required } = this.props;
    let _error = null;

    if (required) {
      _error = "Email is required";
      this.setState({ error: _error });
      return false;
    }
    this.setState({ error: _error });

    return true;
  }

  _onChange(e) {
    const { handleChange } = this.props;
    const _value = e.target.value.toLowerCase();
    handleChange(_value, this._validate(_value, true));
  }

  _onBlur(e) {
    const { value, handleBlur } = this.props;
    if (handleBlur) {
      handleBlur(value, this._validate(value));
    } else {
      this._validate(value);
    }
  }

  _getClass() {
    if (this.state.focus === true || this.props.show_focused === true) {
      return styles.text_input_focus;
    }

    return styles.text_input;
  }

  render() {
    const {
      borderedStyle,
      label,
      note,
      required,
      container_class,
      label_class,
      input_class,
      handleChange,
      handleBlur,
      id,
      value,
      customError,
      placeholder,
      loading,
      show_focused,
      hidden,
      disabled,
      hide_helper_text,
      helper_text,
      ...rest
    } = this.props;

    let helper_message;
    if (helper_text) {
      helper_message = helper_text;
    } else if (!required) {
      helper_message = "This field is optional";
    }

    let emailValue = value;
    if (emailValue === null || emailValue === undefined) emailValue = "";

    let error = this.state.error;
    if (!error && customError && typeof customError === "string") {
      error = customError;
    }

    const Input = borderedStyle ? BorderedTextField : TextInput;

    return (
      hidden || (
        <Input
          disabled={disabled}
          error={error}
          helper_message={helper_message}
          helperText={error || helper_message}
          hide_helper_text={hide_helper_text}
          id={id}
          label={label}
          loading={loading}
          onChange={this._onChange.bind(this)}
          onBlur={this._onBlur.bind(this)}
          placeholder={placeholder}
          value={emailValue}
        />
      )
    )
  }
}

export default EmailInput;