import axios from "./axios";;

let Tokens = function(config) {
  this.axios = axios(config);
  this.type = "tokens";
};

Tokens.prototype.requestToken = function(data, success, error) {
  return this.axios
    .post("/tokens", data)
    .then(success)
    .catch(error);
};

export default Tokens;
