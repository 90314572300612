import historyStyles from "modules/applications/components/credit-checks/credit-works/css/History.css";
import styles from "modules/applications/components/css/CommonReviewBusiness.css";
import moment from "moment";
import React, { ReactElement } from "react";
import { SubHeading } from "modules/applications/components/credit-checks/credit-works/v2/Container/styles";

type StatusHistory = {
  endDate?: string,
  startDate: string,
  status: string,
  statusDescription: string,
}

type Props = {
  data: StatusHistory[],
}

function StatusHistory(props: Props): ReactElement|null {
  const { data } = props;

  const getStatuses = () => data.map(({
    startDate,
    statusDescription,
  }) => (
    <div key={`${startDate}-${statusDescription}`}>
      <h4>Previous Status</h4>
      <table className={styles.table_w20}>
        <tbody>
          <tr>
            <th>Status</th>
            <td>{statusDescription}</td>
          </tr>
          <tr>
            <th>Changed on</th>
            <td>{moment(startDate).format("DD-MMM-YYYY")}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ));

  if (data.length === 0) {
    return null;
  }

  return (
    <div className={historyStyles.container}>
      <SubHeading>Status History</SubHeading>
      {getStatuses()}
    </div>
  );
}

export default StatusHistory;
