import CloseButton from "../shared/components/inputs/CloseButton";
import React from "react";
import ReactTimeout from "react-timeout";
import { connect } from "react-redux";
import { removeMessage } from "./actions";
import styles from "./css/Messages.css";

var Message = ReactTimeout(
  createClass({
    componentDidMount: function() {
      this.props.setTimeout(
        this.props.closeHandler,
        this.props.timeout ? this.props.timeout : 2000,
      );
    },

    render: function() {
      const { text, type, closeHandler } = this.props;
      return (
        <div className={styles.message} onClick={closeHandler}>
          <span className={styles[type]}>{text}</span>
          <CloseButton
            css_class={"button_position_top17x44_grey"}
            handleClick={closeHandler}
            text="close"
          />
        </div>
      );
    },
  }),
);

var Messages = createClass({
  closeMessage: function(message_index, event) {
    const { dispatch } = this.props;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    dispatch(removeMessage(message_index));
  },

  render: function() {
    const { messages, theme } = this.props;
    if (messages.length === 0) {
      return null;
    }

    var messageCount = {};
    messages.forEach((item, i) => {
      var key = item.type + "-" + item.text;
      messageCount[key] = messageCount.hasOwnProperty(key)
        ? messageCount[key] + 1
        : 1;
    });

    var uniqueMessages = [];
    for (var message in messageCount) {
      var uniqueMessage = {};
      [uniqueMessage.type, uniqueMessage.text] = message.split("-", 2);
      uniqueMessage.text +=
        messageCount[message] > 1 ? " (" + messageCount[message] + ")" : "";
      uniqueMessages.push(uniqueMessage);
    }

    var message_list = new Array();
    uniqueMessages.forEach((item, i) => {
      message_list.push(
        <Message
          key={i}
          text={item.text}
          type={item.type}
          closeHandler={this.closeMessage.bind(null, i)}
          timeout={30000}
        />,
      );
    });
    return <div className={styles[theme]}>{message_list}</div>;
  },
});

module.exports = connect((state, ownProps) => {
  return {
    theme: ownProps.theme ? ownProps.theme : "messages",
    messages: state.messages.messages,
  };
})(Messages);
