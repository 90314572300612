import { get } from "lodash";
import isBlank from "utils/isBlank";

const DEFAULT_ANTI_FRAUD_CATEGORY = 1;

const getApplicableCategory = (authorisation, application) => {
  if (
    application.applicationType === "cash" ||
    isBlank(authorisation.agreements)
  ) {
    return DEFAULT_ANTI_FRAUD_CATEGORY;
  }

  const {
    config,
    legal_types,
    min_credit_value,
    max_credit_value,
  } = application.antiFraudAddonRule.formattedAddonVersion;

  const { tradeAccountLimit, legalType } = application;
  const appliedToAuthorisationTypes = get(config, "authorisation_types", []);
  const appliedEntityTypes = legal_types || [];

  if (appliedToAuthorisationTypes.includes("direct_debit")) {
    appliedToAuthorisationTypes.push("payment")
  }

  const agreedToRole = appliedToAuthorisationTypes.some(
    authorisationType => authorisation.agreements[authorisationType],
  );

  let isWithinLimit = true;

  if (min_credit_value) {
    isWithinLimit =
      tradeAccountLimit >= parseInt(min_credit_value) &&
      tradeAccountLimit <= parseInt(max_credit_value);
  }

  const isWithinEntityTypes = !legalType || appliedEntityTypes.includes(legalType);
  const retainCategory = agreedToRole && isWithinEntityTypes && isWithinLimit;

  const category = retainCategory ? config.category : DEFAULT_ANTI_FRAUD_CATEGORY;

  if (typeof category === "string") {
    return parseInt(category);
  }

  return category;
};

export default getApplicableCategory;
