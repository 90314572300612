import axios from "./axios";;

let IpGeolocation = function(config) {
  config.timeout = 20000;
  this.axios = axios(config);
};

IpGeolocation.prototype.getIpGeolocation = function(success, error) {
  return this.axios
    .get("/json/")
    .then(success)
    .catch(error);
};

export default IpGeolocation;
