import { SET, SET_PAYMENT, SET_PAYMENT_CARD, RESET } from "./constants";

var initialState = {
  showSuccessModal: false,
  cardDetails: null,
  cardLoading: false,
  cards: [],
  payment: {
    card: {
      number: "",
      name: "",
      expiry: "",
      cvc: "",
      focused: null,
    },
    errorText: "",
    totalCost: 0,
    numUsers: 1,
  },
};

export function invalidAccountReducer(state = initialState, action) {
  switch (action.type) {
    case SET:
      return { ...state, ...action.payload };
    case SET_PAYMENT:
      return { ...state, payment: { ...state.payment, ...action.payload } };
    case SET_PAYMENT_CARD:
      return {
        ...state,
        payment: {
          ...state.payment,
          card: { ...state.payment.card, ...action.payload },
        },
      };
    case RESET:
      return { ...initialState };
    default:
      return { ...state };
  }
}
