import { apply, put, select } from "redux-saga/effects";
import api from "../../api";
import { setChurnZeroAttributes } from "utils/churnZero";

import {
  MANAGE_PROFILE_LOAD_CURRENT_ENTITY_SUCCESS,
  MANAGE_PROFILE_LOAD_CURRENT_ENTITY_ERROR,
} from "./constants";

// This gets fired when MANAGE_PROFILE_LOAD_CURRENT_ENTITY_START,
// is dispatched. See rootSaga() for details.
function* fetchCurrentProfile() {
  const state = yield select();
  const entities = yield api("entities", state.current_user.access_token);
  const id = state.current_user.current_entity.id;

  try {
    const currentEntity = yield apply(
      entities, // Context
      entities.getEntity, // Promise returning function or Generator
      [
        id,
        null,
        null,
        { params: { include: "terms_documents,entity_email_templates" } },
      ],
    );

    yield put({
      type: MANAGE_PROFILE_LOAD_CURRENT_ENTITY_SUCCESS,
      payload: currentEntity.data,
    });

    // ChurnZero
    if (currentEntity.data.data.attributes.supplier) {
      return (dispatch, getState) => {
        dispatch(
          setChurnZeroAttributes(
            state.current_user.current_entity.id,
            getState,
          ),
        );
      };
    }
  } catch (e) {
    yield put({
      type: MANAGE_PROFILE_LOAD_CURRENT_ENTITY_ERROR,
      message: e.message,
    });
  }
}

export default fetchCurrentProfile;
