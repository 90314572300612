/* Import libraries */
import React, { FC, Fragment } from "react";
// import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
// @ts-ignore
import Button from "modules/shared/components/inputs/Button";
import { Beta, Header, InnerWrapper, Liner, Wrapper } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import { THEME_COLORS } from "variables/theme";
import Message from "../Message";
import Tooltip from "./Tooltip";

type PanelProps = {
  action?: React.ReactNode;
  autoHeight?: boolean;
  beta?: boolean;
  children: React.ReactNode;
  comingSoon?: boolean,
  description?: string | React.ReactNode;
  error: boolean;
  hideComingSoonLabel?: boolean,
  loading?: boolean;
  refresh?: () => void;
  size?: number;
  sizeOverrides?: string[];
  title?: string;
  tooltip?: string | React.ReactNode;
};

type RefreshButtonProps = {
  refresh?: () => void;
};

const RefreshButton: FC<RefreshButtonProps> = ({ refresh }) => (
  <Button
    type="button"
    handleClick={refresh}
    text={
      <Fragment>
        <FontAwesomeIcon icon={["fas", "sync"]} /> Refresh
      </Fragment>
    }
  />
);

const Panel: FC<PanelProps> = ({
  action,
  autoHeight,
  beta,
  children,
  comingSoon,
  description,
  error,
  hideComingSoonLabel,
  loading,
  refresh,
  size,
  title,
  tooltip,
}) => {
  let content = children;
  const classes = [
    "tile",
    "is-child",
  ];

  if (size) {
    classes.push(`is-${size}-desktop`);
  }

  if (error && !loading) {
    content = (
      <Message
        faProps={{
          color: THEME_COLORS.danger,
          icon: ["fas", "exclamation-triangle"],
          size: "4x",
        }}
        message="Ops! Something went wrong. Please refresh the widget."
        actions={<RefreshButton refresh={refresh} />}
      />
    );
  }

  if (!comingSoon && loading) {
    content = <Skeleton count={5} />;
  }

  return (
    <Wrapper className={classes.join(" ")} autoHeight={autoHeight}>
      <Liner comingSoon={comingSoon}>
        {(title || description) && (
          <Header>
            {
              comingSoon && title && !hideComingSoonLabel && (
                <div>
                  <h2>{title} (Coming Soon)</h2>
                  {action}
                </div>
              )
            }
            {(!comingSoon || hideComingSoonLabel) && title && (
              <div>
                <h2>
                  {title}
                  {tooltip && <Tooltip>{tooltip}</Tooltip>}
                </h2>

                {action}
              </div>
            )}
            {!comingSoon && description &&
              (typeof description === "string" ? (
                <p>{description}</p>
              ) : (
                description
              ))}
          </Header>
        )}
        <InnerWrapper>{content}</InnerWrapper>
      </Liner>
      {beta && <Beta>beta</Beta>}
    </Wrapper>
  );
};

Panel.defaultProps = {
  action: null,
  beta: false,
  error: false,
  loading: false,
  size: 6,
};

export default Panel;
