import * as PPSRDetailsComponents from "modules/new-applications/components/application-actions/PPSRRegister/PPSRDetails/index";
import React from "react";
import isBlank from "utils/isBlank";

export default function PPSRRegisterDetails(props) {
  const {
    application,
    currentUser,
    onFetchApplicationRecord,
    onSetAlert,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
    setCurrentPage,
  } = props;

  const { region } = application;

  if (isBlank(region)) {
    return null;
  }

  const PPSRDetailsByRegionComponent =
    PPSRDetailsComponents[`${region}PPSRDetails`];

  return (
    <PPSRDetailsByRegionComponent
      application={application}
      currentUser={currentUser}
      onFetchApplicationRecord={onFetchApplicationRecord}
      onSetAlert={onSetAlert}
      onSetPPSRFinancingStatement={onSetPPSRFinancingStatement}
      ppsrFinancingStatement={ppsrFinancingStatement}
      setCurrentPage={setCurrentPage}
    />
  );
}
