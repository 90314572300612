import {
  APPLICATIONS_ACCEPT_SUBMIT_ERROR,
  APPLICATIONS_CONSUMER_LOAD_LIST_ERROR,
  APPLICATIONS_CONSUMER_USER_LOAD_LIST_ERROR,
  APPLICATIONS_DECLINE_SUBMIT_ERROR,
  APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_ERROR,
  APPLICATIONS_REVIEW_LOAD_ERROR,
  APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_ERROR,
  APPLICATIONS_SUPPLIER_LOAD_LIST_ERROR,
  APPLICATIONS_SUPPLIER_USER_LOAD_LIST_ERROR,
  APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_ERROR,
} from "../applications/constants";
import { ASSOCIATION_LOAD_RECOVERY_CANDIDATE_ERROR } from "../association/constants";
import {
  AUTHORISATION_LOAD_DATA_ERROR,
  AUTHORISATION_LOGIN_ERROR,
  AUTHORISATION_UPDATE_DATA_ERROR,
} from "../authorisation/constants";
import {
  CONNECTIONS_ALL_CONNECTIONS_LIST_ERROR,
  CONNECTIONS_CONNECTION_ERROR,
  CONNECTIONS_CONTACT_CANDIDATES_LIST_ERROR,
  CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_ERROR,
} from "../connections/constants";
import {
  CNS_INVITE_ANZSIC_CLASSES_ERROR,
  CNS_INVITE_ANZSIC_DIVISIONS_ERROR,
  CNS_INVITE_ANZSIC_GROUPS_ERROR,
  CNS_INVITE_ANZSIC_SUBDIVISIONS_ERROR,
  CNS_INVITE_COMPANY_NAME_LOOKUP_ERROR,
  CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_ERROR,
  CNS_INVITE_CREATE_FOR_NEW_CONSUMER_ERROR,
  CNS_INVITE_NZ_COMPANY_DETAILS_ERROR,
  CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_ERROR,
  CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_ERROR,
  CNS_INVITE_POSTAL_ADDRESS_LOOKUP_ERROR,
  CNS_INVITE_RESEND_APPLICATION_ERROR,
} from "../consumer-invite/constants/invite";
import { COB_CARDS_SAVE_CARDHOLDER_ERROR } from "../consumer-onboarding/constants/cards";
import {
  COB_BUSINESS_COMPANY_DETAILS_ERROR,
  COB_BUSINESS_COMPANY_NAME_LOOKUP_ERROR,
  COB_ENTITY_TYPE_MISSING,
} from "../consumer-onboarding/constants/onboarding";
import {
  COB_REVIEW_SUBMIT_DATA_ERROR,
  COB_REVIEW_SUBMIT_NETWORK_BREAK,
} from "../consumer-onboarding/constants/review";
import { COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_ERROR } from "../consumer-onboarding/constants/section";
import {
  GUARANTOR_INFO_LOAD_ERROR,
  GUARANTOR_LOGIN_ERROR,
  GUARANTOR_UPDATE_ERROR,
} from "../guarantor/constants";
import {
  IDENTITY_SELECT_NO_IMAGE_FILE,
  IDENTITY_SELECT_PDF_TOO_LONG,
} from "../identity/constants";
import {
  ENTITY_LEAD_NAME_UPDATE_ERROR,
  LEADS_ALL_LEADS_LIST_ERROR,
  LEADS_CREATE_NOTES_ERROR,
  LEADS_LOAD_NOTES_ERROR,
  LEADS_LOAD_REPORT_ERROR,
  LEADS_REVIEW_LOAD_ERROR,
  LEADS_UPDATE_LEAD_ARCHIVE_ERROR,
} from "../leads/constants";
import {
  MANAGE_USERS_CREATE_USER_ERROR,
  MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_ERROR,
  MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_ERROR,
  MANAGE_USERS_REMOVE_USER_ERROR,
  MANAGE_USERS_TEAM_LOAD_TEAM_ERROR,
  MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_ERROR,
  MANAGE_USERS_UPDATE_USER_ERROR,
  MANAGE_USERS_UPDATE_USER_LAST_ADMIN_ERROR,
} from "../manage-users/constants";
import {
  PRICING_PLAN_LIST_LOAD_ERROR,
  PRICING_PLAN_LOAD_ERROR,
  PRICING_PLAN_UPDATE_CURRENT_PLAN_ERROR,
} from "../pricing-plans/constants";
import {
  MANAGE_PROFILE_GET_EMAIL_TEMPLATE_ERROR,
  MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_ERROR,
} from "../profile/constants";
import { APPLICATIONS_REALLOCATION_ERROR } from "../reporting/constants";
import {
  STATE_CREATE_STATE_ERROR,
  STATE_LOAD_STATE_ERROR,
  STATE_SAVE_STATE_ERROR,
  STATE_UPDATE_STATE_ERROR,
} from "../state/constants";
import {
  TRADE_REFERENCES_CREATE_CHECK_ERROR,
  TRADE_REFERENCES_LOAD_APPLICATIONS_ERROR,
  TRADE_REFERENCES_LOAD_TRADE_REFERENCE_ERROR,
  TRADE_REFERENCES_UPDATE_CHECK_ERROR,
} from "../trade-references/constants";
import {
  EMAIL_LOCKED_ERROR,
  USER_GET_TOKEN_ERROR,
  USER_LOAD_CURRENT_USER_DEACTIVATED_ERROR,
  USER_LOAD_CURRENT_USER_ERROR,
  USER_LOGIN_CREDENTIALS_ERROR,
  USER_LOGIN_ERROR,
  USER_LOGOUT_ERROR,
  USER_NETWORK_BREAK,
  USER_AUTHENTICATION_ERROR,
  USER_PASSWORD_RESET_ERROR,
  USER_SEND_PASSWORD_RESET_ERROR,
} from "../user/constants";
import { MESSAGES_ADD_ADHOC, MESSAGES_REMOVE_MESSAGE } from "./constants";

const message_list = {};

export const INCORRECT_USERNAME_PASSWORD_MESSAGE =
  "Incorrect Username/Password";

message_list[APPLICATIONS_SUPPLIER_LOAD_LIST_ERROR] = {
  text: "There was an issue loading supplier applications",
  type: "error",
};
message_list[APPLICATIONS_CONSUMER_LOAD_LIST_ERROR] = {
  text: "There was an issue loading consumer applications",
  type: "error",
};
message_list[APPLICATIONS_SUPPLIER_USER_LOAD_LIST_ERROR] = {
  text: "There was an issue loading supplier user applications",
  type: "error",
};
message_list[APPLICATIONS_CONSUMER_USER_LOAD_LIST_ERROR] = {
  text: "There was an issue loading consumer user applications",
  type: "error",
};
message_list[APPLICATIONS_REVIEW_LOAD_ERROR] = {
  text: "There was an issue loading the application review",
  type: "error",
};
message_list[APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_ERROR] = {
  text: "There was an issue loading the business data for review",
  type: "error",
};
message_list[APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_ERROR] = {
  text: "There was an issue loading payment data for review",
  type: "error",
};
message_list[APPLICATIONS_DECLINE_SUBMIT_ERROR] = {
  text: "There was an issue declining the application",
  type: "error",
};
message_list[APPLICATIONS_ACCEPT_SUBMIT_ERROR] = {
  text: "There was an issue accepting the application",
  type: "error",
};
message_list[APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_ERROR] = {
  text: "There was an issue updating the file",
  type: "error",
};

message_list[CONNECTIONS_ALL_CONNECTIONS_LIST_ERROR] = {
  text: "There was an issue loading the connections",
  type: "error",
};
message_list[CONNECTIONS_CONNECTION_ERROR] = {
  text: "There was an issue loading a connection",
  type: "error",
};
message_list[CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_ERROR] = {
  text: "There was an issue updating the key contact",
  type: "error",
};
message_list[CONNECTIONS_CONTACT_CANDIDATES_LIST_ERROR] = {
  text: "There was an issue loading the key contact candidates",
  type: "error",
};

message_list[APPLICATIONS_REALLOCATION_ERROR] = {
  text: "Application reallocation failed",
  type: "error",
};

message_list[CNS_INVITE_CREATE_FOR_NEW_CONSUMER_ERROR] = {
  text: "There was an inviting the new consumer",
  type: "error",
};
message_list[CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_ERROR] = {
  text: "There was an issue inviting the existing consumer",
  type: "error",
};
message_list[CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_ERROR] = {
  text: "There was an issue looking up the company at the companies office",
  type: "error",
};
message_list[CNS_INVITE_COMPANY_NAME_LOOKUP_ERROR] = {
  text: "There was an issue finding the company in 1Centre",
  type: "error",
};
message_list[CNS_INVITE_NZ_COMPANY_DETAILS_ERROR] = {
  text:
    "There was an issue loading the company details from the companies office",
  type: "error",
};
message_list[CNS_INVITE_ANZSIC_DIVISIONS_ERROR] = {
  text: "There was an issue loading ANZSIC division codes",
  type: "error",
};
message_list[CNS_INVITE_ANZSIC_SUBDIVISIONS_ERROR] = {
  text: "There was an issue loading ANZSIC subdivision codes",
  type: "error",
};
message_list[CNS_INVITE_ANZSIC_GROUPS_ERROR] = {
  text: "There was an issue loading ANZSIC groups codes",
  type: "error",
};
message_list[CNS_INVITE_ANZSIC_CLASSES_ERROR] = {
  text: "There was an issue loading ANZSIC classses",
  type: "error",
};
message_list[CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_ERROR] = {
  text: "There was an issue finding the physical address",
  type: "error",
};
message_list[CNS_INVITE_POSTAL_ADDRESS_LOOKUP_ERROR] = {
  text: "There was an issue finding the postal address",
  type: "error",
};
message_list[CNS_INVITE_RESEND_APPLICATION_ERROR] = {
  text: "There was an issue re-sending the application",
  type: "error",
};

message_list[COB_CARDS_SAVE_CARDHOLDER_ERROR] = {
  text:
    "There was an issue saving the cardholder data, please click 'next' button to retry",
  type: "error",
};

message_list[COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_ERROR] = {
  text:
    "There was an issue saving the cardholder data, please click 'next' button to retry",
  type: "error",
};

message_list[COB_BUSINESS_COMPANY_NAME_LOOKUP_ERROR] = {
  text: "There was an issue looking up the company at the companies office",
  type: "error",
};
message_list[COB_BUSINESS_COMPANY_DETAILS_ERROR] = {
  text:
    "There was an issue loading the company details from the companies office",
  type: "error",
};

message_list[COB_REVIEW_SUBMIT_DATA_ERROR] = {
  text:
    "There was an issue saving the consumer data, please refresh the page to try again.",
  type: "error",
};
message_list[COB_REVIEW_SUBMIT_NETWORK_BREAK] = {
  text: "something went wrong... please refresh your screen",
  type: "error",
};

message_list[GUARANTOR_LOGIN_ERROR] = {
  text: "There was an issue logging into the guarantor account",
  type: "error",
};
message_list[GUARANTOR_INFO_LOAD_ERROR] = {
  text: "There was an issue loading the information for the guarantor",
  type: "error",
};
message_list[GUARANTOR_UPDATE_ERROR] = {
  text: "There was an issue updating the gurantor status",
  type: "error",
};

message_list[MANAGE_USERS_TEAM_LOAD_TEAM_ERROR] = {
  text: "There was an issue loading the team details",
  type: "error",
};
message_list[MANAGE_USERS_REMOVE_USER_ERROR] = {
  text: "There was an issue removing the user",
  type: "error",
};
message_list[MANAGE_USERS_UPDATE_USER_ERROR] = {
  text: "There was an issue updating the user",
  type: "error",
};
message_list[MANAGE_USERS_UPDATE_USER_LAST_ADMIN_ERROR] = {
  text: "This entity requires at least one admin",
  type: "error",
};
message_list[MANAGE_USERS_CREATE_USER_ERROR] = {
  text: "There was an issue creating the user",
  type: "error",
};
message_list[MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_ERROR] = {
  text: "There was an issue loading the connection key contacts",
  type: "error",
};
message_list[MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_ERROR] = {
  text: "There was an issue loading the application key contacts",
  type: "error",
};
message_list[MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_ERROR] = {
  text: "There was an issue updating connection key contacts",
  type: "error",
};

message_list[STATE_LOAD_STATE_ERROR] = {
  text: "There was an issue loading your saved state",
  type: "error",
};
message_list[STATE_SAVE_STATE_ERROR] = {
  text: "There was an issue saving the state",
  type: "error",
};
message_list[STATE_CREATE_STATE_ERROR] = {
  text: "There was an issue creating the state",
  type: "error",
};
message_list[STATE_UPDATE_STATE_ERROR] = {
  text: "There was an issue updating the state",
  type: "error",
};
message_list[TRADE_REFERENCES_LOAD_APPLICATIONS_ERROR] = {
  text: "There was an issue loading the trade reference application",
  type: "error",
};
message_list[TRADE_REFERENCES_LOAD_TRADE_REFERENCE_ERROR] = {
  text: "There was an issue loading the trade reference",
  type: "error",
};
message_list[TRADE_REFERENCES_CREATE_CHECK_ERROR] = {
  text: "There was an issue creating a trade reference check",
  type: "error",
};
message_list[TRADE_REFERENCES_UPDATE_CHECK_ERROR] = {
  text: "There was an issue updating the trade reference check",
  type: "error",
};

message_list[USER_NETWORK_BREAK] = {
  text: "something went wrong... please refresh your screen",
  type: "error",
};
message_list[USER_PASSWORD_RESET_ERROR] = {
  text: "There was an issue resetting the password",
  type: "error",
};
message_list[USER_GET_TOKEN_ERROR] = {
  text: "There was an issue retrieving the token",
  type: "error",
};
message_list[USER_AUTHENTICATION_ERROR] = {
  text: "There was an issue logging in",
  type: "error",
};
message_list[USER_LOGIN_ERROR] = {
  text: "There was an issue logging in",
  type: "error",
};
message_list[USER_LOGOUT_ERROR] = {
  text: "There was an issue logging out",
  type: "error",
};
message_list[USER_LOGIN_CREDENTIALS_ERROR] = {
  text: INCORRECT_USERNAME_PASSWORD_MESSAGE,
  type: "error",
};
message_list[EMAIL_LOCKED_ERROR] = {
  text:
    "Too many wrong login attempts. The account will be locked for 30 minutes.",
  type: "error",
};
message_list[USER_LOAD_CURRENT_USER_DEACTIVATED_ERROR] = {
  text:
    "This account has been deactivated. Please contact support@1centre.com.",
  type: "error",
};
message_list[USER_LOAD_CURRENT_USER_ERROR] = {
  text: "There was an issue loading the current user",
  type: "error",
};
message_list[USER_SEND_PASSWORD_RESET_ERROR] = {
  text: "There was an issue sending the password reset email",
  type: "error",
};

message_list[PRICING_PLAN_LIST_LOAD_ERROR] = {
  text: "There was an issue loading the pricing plans",
  type: "error",
};
message_list[PRICING_PLAN_LOAD_ERROR] = {
  text: "There was an issue loading the current plan",
  type: "error",
};
message_list[PRICING_PLAN_UPDATE_CURRENT_PLAN_ERROR] = {
  text: "There was an issue updating the current plan",
  type: "error",
};

message_list[ASSOCIATION_LOAD_RECOVERY_CANDIDATE_ERROR] = {
  text: "There was an issue loading your progress",
  type: "error",
};

message_list[IDENTITY_SELECT_NO_IMAGE_FILE] = {
  text: "The file given is not in a supported format.",
  type: "error",
};

message_list[IDENTITY_SELECT_PDF_TOO_LONG] = {
  text: "The PDF file is too long, please upload one page only.",
  type: "error",
};

message_list[MANAGE_PROFILE_GET_EMAIL_TEMPLATE_ERROR] = {
  text: "There was an issue loading default of email template",
  type: "error",
};
message_list[MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_ERROR] = {
  text: "There was an issue updating email template",
  type: "error",
};

message_list[LEADS_ALL_LEADS_LIST_ERROR] = {
  text: "There was an issue loading the leads list",
  type: "error",
};
message_list[ENTITY_LEAD_NAME_UPDATE_ERROR] = {
  text: "There was an issue updating lead name",
  type: "error",
};
message_list[LEADS_LOAD_REPORT_ERROR] = {
  text: "There was an issue loading the leads chart",
  type: "error",
};
message_list[LEADS_UPDATE_LEAD_ARCHIVE_ERROR] = {
  text: "There was an issue updating the leads archived",
  type: "error",
};
message_list[LEADS_CREATE_NOTES_ERROR] = {
  text: "There was an issue creating the leads notes",
  type: "error",
};
message_list[LEADS_LOAD_NOTES_ERROR] = {
  text: "There was an issue loading the leads notes",
  type: "error",
};
message_list[LEADS_REVIEW_LOAD_ERROR] = {
  text: "There was an issue loading the leads review",
  type: "error",
};

message_list[AUTHORISATION_UPDATE_DATA_ERROR] = {
  text: "There was an issue updating the authorisation",
  type: "error",
};
message_list[AUTHORISATION_LOGIN_ERROR] = {
  text: "There was an issue logging into the authorisation",
  type: "error",
};
message_list[AUTHORISATION_LOAD_DATA_ERROR] = {
  text: "There was an issue loading the authorisation",
  type: "error",
};

message_list[COB_ENTITY_TYPE_MISSING] = {
  text:
    "There was an issue loading the correct entity type. Please contact support@1centre.com.",
  type: "error",
};

const defaultMessagesState = {
  alerts: [
    // {
    //   text: "This is an alert",
    //   url: "/register/supplier/business",
    //   type: "error"
    // },
    // {
    //   text: "OMG!",
    //   url: "/register/supplier/business",
    //   type: "error"
    // },
    // {
    //   text: "Bad things",
    //   url: "/register/supplier/business",
    //   type: "error"
    // }
  ],
  messages: [],
};

export function messagesReducer(state = defaultMessagesState, action) {
  if (message_list.hasOwnProperty(action.type)) {
    var messages = state.messages.slice();
    messages.push(message_list[action.type]);
    return { ...state, messages };
  }
  switch (action.type) {
    case MESSAGES_REMOVE_MESSAGE:
      var messages = state.messages.slice();
      messages.splice(action.payload);
      return { ...state, messages };
    case MESSAGES_ADD_ADHOC:
      var messages = state.messages.slice();
      messages.push(action.payload);
      return { ...state, messages };
    default:
      return { ...state, messages: state.messages };
  }
}
