import React from "react";
import isBlank from "utils/isBlank";
import Button from "modules/shared/components/inputs/Button";

const AMLFile = props => {
  const { identificationCheck } = props;

  if (isBlank(identificationCheck.amlFile)) {
    return null;
  }

  const onClick = () => {
    window.open(identificationCheck.amlFile, "aml_pdf_window");
  };

  return (
    <Button
      css_style="button_white_outer"
      text="Download watchlist data"
      style={{
        marginTop: 20,
      }}
      handleClick={onClick}
    />
  );
};

export default AMLFile;
