import React from "react";

import C3Chart from "react-c3js";
import "c3/c3.css";

var AgingSummaryGraph = createClass({
  render() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      agedBalances = enquiryResult.aged_balances || {},
      summary = agedBalances.aged_balances_web_summary_ws_dto || [];

    if (summary.constructor === Object) summary = [summary];
    if (summary.length === 0) return null;

    let duplicate = summary.slice(0);
    duplicate.reverse();

    let chartData = {
      type: "bar",
      types: {
        average_dso: "line",
      },
      axes: {
        average_dso: "y2",
      },
      json: duplicate,
      keys: {
        x: "month_name",
        value: [
          "average_dso",
          "billed_this_month",
          "due_this_month",
          "overdue1_month",
          "overdue2_months",
          "overdue3_months",
        ],
      },
      groups: [
        [
          "billed_this_month",
          "due_this_month",
          "overdue1_month",
          "overdue2_months",
          "overdue3_months",
        ],
      ],
      names: {
        average_dso: "Average DSO",
        billed_this_month: "Billed This Month",
        due_this_month: "Due This Month",
        overdue1_month: "Overdue 1 Month",
        overdue2_months: "Overdue 2 Months",
        overdue3_months: "Overdue 3 Months",
      },
      order: null,
    };

    let axis = {
      x: {
        type: "category",
        tick: {
          rotate: 90,
          culling: {
            max: 5,
          },
        },
      },
      y: {
        padding: {
          top: 20,
          bottom: 0,
        },
        tick: {
          format: d => {
            return parseInt(d).toLocaleString("en");
          },
        },
        label: {
          text: "Total Debt",
          position: "outer-middle",
        },
        min: 0,
      },
      y2: {
        padding: {
          top: 20,
          bottom: 0,
        },
        show: true,
        tick: {
          count: 5,
          format: d => {
            return parseInt(d);
          },
        },
        label: {
          text: "Average DSO",
          position: "outer-middle",
        },
        min: 0,
      },
    };

    let legend = {
      position: "right",
    };

    let tooltip = {
      format: {
        value: (value, ratio, id, index) => {
          return parseFloat(value).toLocaleString("en", {
            maximumFractionDigits: 2,
          });
        },
      },
    };

    return (
      <div>
        <C3Chart
          data={chartData}
          axis={axis}
          legend={legend}
          tooltip={tooltip}
        />
      </div>
    );
  },
});

export default AgingSummaryGraph;
