import {
  ADD_ONS_ADDITIONAL_CREATE_RULESET_ERROR,
  ADD_ONS_ADDITIONAL_CREATE_RULESET_START,
  ADD_ONS_ADDITIONAL_CREATE_RULESET_SUCCESS,
  ADD_ONS_ADDITIONAL_LOAD_ERROR,
  ADD_ONS_ADDITIONAL_LOAD_START,
  ADD_ONS_ADDITIONAL_LOAD_SUCCESS,
  ADD_ONS_ADDITIONAL_RESET_CURRENT,
  ADD_ONS_ADDITIONAL_SET_CURRENT,
  ADD_ONS_ADDITIONAL_SET_VALUE,
  ADD_ONS_ADDITIONAL_UPDATE_RULESET_ERROR,
  ADD_ONS_ADDITIONAL_UPDATE_RULESET_START,
  ADD_ONS_ADDITIONAL_UPDATE_RULESET_SUCCESS,
  ADD_ONS_ANTI_FRAUD_LOAD_START,
  ADD_ONS_ANTI_FRAUD_LOAD_SUCCESS,
  ADD_ONS_ANTI_FRAUD_LOAD_ERROR,
  ADD_ONS_AUTO_DECISION_LOAD_ERROR,
  ADD_ONS_AUTO_DECISION_LOAD_START,
  ADD_ONS_AUTO_DECISION_LOAD_SUCCESS,
  ADD_ONS_AUTO_DECISION_TOGGLE_ERROR,
  ADD_ONS_AUTO_DECISION_TOGGLE_START,
  ADD_ONS_AUTO_DECISION_TOGGLE_SUCCESS,
  ADD_ONS_CARDS_CREATE_RULESET_ERROR,
  ADD_ONS_CARDS_CREATE_RULESET_START,
  ADD_ONS_CARDS_CREATE_RULESET_SUCCESS,
  ADD_ONS_CARDS_LOAD_ERROR,
  ADD_ONS_CARDS_LOAD_START,
  ADD_ONS_CARDS_LOAD_SUCCESS,
  ADD_ONS_CARDS_RESET_CURRENT,
  ADD_ONS_CARDS_SET_CURRENT,
  ADD_ONS_CARDS_SET_VALUE,
  ADD_ONS_CARDS_UPDATE_RULESET_ERROR,
  ADD_ONS_CARDS_UPDATE_RULESET_START,
  ADD_ONS_CARDS_UPDATE_RULESET_SUCCESS,
  ADD_ONS_FINANCIALS_CREATE_RULESET_ERROR,
  ADD_ONS_FINANCIALS_CREATE_RULESET_START,
  ADD_ONS_FINANCIALS_CREATE_RULESET_SUCCESS,
  ADD_ONS_FINANCIALS_LOAD_ERROR,
  ADD_ONS_FINANCIALS_LOAD_START,
  ADD_ONS_FINANCIALS_LOAD_SUCCESS,
  ADD_ONS_FINANCIALS_RESET_CURRENT,
  ADD_ONS_FINANCIALS_SET_ADDITIONAL_FIELDS,
  ADD_ONS_FINANCIALS_SET_CURRENT,
  ADD_ONS_FINANCIALS_SET_INDICATOR_FIELDS,
  ADD_ONS_FINANCIALS_SET_MANDATORY_FIELDS,
  ADD_ONS_FINANCIALS_SET_VALUE,
  ADD_ONS_FINANCIALS_UPDATE_RULESET_ERROR,
  ADD_ONS_FINANCIALS_UPDATE_RULESET_START,
  ADD_ONS_FINANCIALS_UPDATE_RULESET_SUCCESS,
  ADD_ONS_LOAD_START,
  ADD_ONS_LOAD_SUCCESS,

  ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_ERROR,
  ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_START,
  ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_SUCCESS,
  ADD_ONS_INTERNAL_USE_FIELDS_LOAD_ERROR,
  ADD_ONS_INTERNAL_USE_FIELDS_LOAD_START,
  ADD_ONS_INTERNAL_USE_FIELDS_LOAD_SUCCESS,
  ADD_ONS_INTERNAL_USE_FIELDS_SET_CONFIG_VALUE,
  ADD_ONS_INTERNAL_USE_FIELDS_SET_CURRENT,
  ADD_ONS_INTERNAL_USE_FIELDS_SET_VALUE,
  ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_ERROR,
  ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_START,
  ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_SUCCESS,
  ADD_ONS_INTERNAL_USE_FIELDS_RESET_CURRENT,

  ADD_ONS_PAPERLESS_CREATE_RULESET_ERROR,
  ADD_ONS_PAPERLESS_CREATE_RULESET_START,
  ADD_ONS_PAPERLESS_CREATE_RULESET_SUCCESS,
  ADD_ONS_PAPERLESS_LOAD_ERROR,
  ADD_ONS_PAPERLESS_LOAD_START,
  ADD_ONS_PAPERLESS_LOAD_SUCCESS,
  ADD_ONS_PAPERLESS_RESET_CURRENT,
  ADD_ONS_PAPERLESS_SET_CONFIG_VALUE,
  ADD_ONS_PAPERLESS_SET_CURRENT,
  ADD_ONS_PAPERLESS_SET_VALUE,
  ADD_ONS_PAPERLESS_UPDATE_RULESET_ERROR,
  ADD_ONS_PAPERLESS_UPDATE_RULESET_START,
  ADD_ONS_PAPERLESS_UPDATE_RULESET_SUCCESS,
  BRANCHES_LOAD_END,
  BRANCHES_LOAD_START,
  BRANCHES_LOAD_SUCCESS,
  ADD_ONS_ALERT_LOAD_START,
  ADD_ONS_ALERT_LOAD_SUCCESS,
  ADD_ONS_ALERT_LOAD_ERROR,
  ADD_ONS_ALERT_SET_CURRENT,
} from "./constants";

import api from "../../api";
import toggleArrayValue from "utils/toggleArrayValue";
import AddonConfigModel from "models/AddonConfigModel";
import get from "lodash.get";

export function loadAddOns() {
  return dispatch => {
    dispatch({
      type: ADD_ONS_LOAD_START,
    });

    dispatch({
      payload: [
        {
          description: "auto decisioning for suppliers",
          title: "Auto Decisioning",
        },
      ],
      type: ADD_ONS_LOAD_SUCCESS,
    });
  };
}

export function loadAutoDecisions() {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_ONS_AUTO_DECISION_LOAD_START,
    });
    const rulesets = api(
      "auto_decisioning_rulesets",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    rulesets.getRuleSets(
      success => {
        dispatch({
          payload: success.data.data,
          type: ADD_ONS_AUTO_DECISION_LOAD_SUCCESS,
        });
      },
      error => {
        dispatch({
          payload: error,
          type: ADD_ONS_AUTO_DECISION_LOAD_ERROR,
        });
      },
    );
  };
}

export function loadBranches(successCallback, only_agreed = true) {
  return (dispatch, getState) => {
    dispatch({
      type: BRANCHES_LOAD_START,
    });

    const headquarters = api(
      "headquarters",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    const entityId = getState().current_user.current_entity.id;

    headquarters.getBranches(
      entityId,
      result => {
        dispatch({
          payload: result.data.data,
          type: BRANCHES_LOAD_SUCCESS,
        });
        if (successCallback) {
          successCallback(result.data.data);
        }
      },
      error => {
        dispatch({
          payload: error,
          type: BRANCHES_LOAD_END,
        });
      },
      { params: { only_agreed } },
    );
  };
}

export function toggleRuleSet(id, ruleSet) {
  return (dispatch, getState) => {
    const toggledRuleSet = { ...ruleSet, paused: !ruleSet.paused };
    dispatch({
      payload: id,
      type: ADD_ONS_AUTO_DECISION_TOGGLE_START,
    });
    const decisions = getState().add_ons.auto_decisions;
    const rulesets = api(
      "auto_decisioning_rulesets",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    rulesets.updateRuleSet(
      id,
      toggledRuleSet,
      success => {
        for (const i in decisions) {
          if (decisions[i].id === id) {
            decisions[i] = success.data.data;
          }
        }
        dispatch({
          meta: {
            mixpanel: {
              event: "Update auto decisioning ruleset",
              props: {
                "Entity ID": getState().current_user.current_entity.id,
                Ruleset: success.data.data,
                "Ruleset ID": id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: decisions,
          type: ADD_ONS_AUTO_DECISION_TOGGLE_SUCCESS,
        });
      },
      error => {
        dispatch({
          payload: error,
          type: ADD_ONS_AUTO_DECISION_TOGGLE_ERROR,
        });
      },
    );
  };
}

export function setAddonEntityType(value, addonType) {
  const currentAddonReducerKey = {
    additional: "current_additional",
    cards: "current_card",
    financials: "current_financials",
    paperless: "current_paperless",
  };

  const reducerKey = currentAddonReducerKey[addonType];
  const constantName = {
    additional: ADD_ONS_ADDITIONAL_SET_VALUE,
    cards: ADD_ONS_CARDS_SET_VALUE,
    financials: ADD_ONS_FINANCIALS_SET_VALUE,
    paperless: ADD_ONS_PAPERLESS_SET_VALUE,
  }[addonType];

  return (dispatch, getState) => {
    const list = getState().add_ons[reducerKey].attributes.legal_types || [];
    const newList = toggleArrayValue(list, value);

    dispatch({
      payload: { name: "legal_types", value: newList },
      type: constantName,
    });
  };
}

export function setAddonAccountType(value, addonType) {
  const currentAddonReducerKey = {
    additional: "current_additional",
    cards: "current_card",
    financials: "current_financials",
    paperless: "current_paperless",
  };

  const reducerKey = currentAddonReducerKey[addonType];
  const constantName = {
    additional: ADD_ONS_ADDITIONAL_SET_VALUE,
    cards: ADD_ONS_CARDS_SET_VALUE,
    financials: ADD_ONS_FINANCIALS_SET_VALUE,
    paperless: ADD_ONS_PAPERLESS_SET_VALUE,
  }[addonType];

  return (dispatch, getState) => {
    const list = getState().add_ons[reducerKey].attributes.application_types || [];
    const newList = toggleArrayValue(list, value);

    dispatch({
      payload: { name: "application_types", value: newList },
      type: constantName,
    });
  };
}

export function setAddonValue(name, value, addonType) {
  const constantName = {
    additional: ADD_ONS_ADDITIONAL_SET_VALUE,
    cards: ADD_ONS_CARDS_SET_VALUE,
    financials: ADD_ONS_FINANCIALS_SET_VALUE,
    paperless: ADD_ONS_PAPERLESS_SET_VALUE,
  }[addonType];

  return dispatch => {
    dispatch({
      payload: { name, value },
      type: constantName,
    });
  };
}

export function createAddonVersion(ruleSet, addonType, onSuccess, onError = () => {}) {
  const [startConstantName, successConstantName, errorConstantName] = {
    additional: [
      ADD_ONS_ADDITIONAL_CREATE_RULESET_START,
      ADD_ONS_ADDITIONAL_CREATE_RULESET_SUCCESS,
      ADD_ONS_ADDITIONAL_CREATE_RULESET_ERROR,
    ],
    cards: [
      ADD_ONS_CARDS_CREATE_RULESET_START,
      ADD_ONS_CARDS_CREATE_RULESET_SUCCESS,
      ADD_ONS_CARDS_CREATE_RULESET_ERROR,
    ],
    financials: [
      ADD_ONS_FINANCIALS_CREATE_RULESET_START,
      ADD_ONS_FINANCIALS_CREATE_RULESET_SUCCESS,
      ADD_ONS_FINANCIALS_CREATE_RULESET_ERROR,
    ],
    paperless: [
      ADD_ONS_PAPERLESS_CREATE_RULESET_START,
      ADD_ONS_PAPERLESS_CREATE_RULESET_SUCCESS,
      ADD_ONS_PAPERLESS_CREATE_RULESET_ERROR,
    ],
    internal_use_fields: [
      ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_START,
      ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_SUCCESS,
      ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_ERROR,
    ],
  }[addonType];

  return async (dispatch, getState) => {
    dispatch({
      payload: ruleSet,
      type: startConstantName,
    });

    const entityId = getState().current_user.current_entity.id;
    const addonVersions = api("addon_versions", _, entityId, {
      headers: { Accept: "application/json" },
    });

    try {
      const response = await addonVersions.createAddonVersion(
        setRuleSetNameDefault(ruleSet, addonType),
      );

      dispatch({
        meta: {
          mixpanel: {
            event: `Create ${addonType} ruleset`,
            props: {
              "Entity ID": entityId,
              Ruleset: response.data,
              distinct_id: getState().current_user.data.data.id,
            },
          },
        },
        payload: response.data,
        type: successConstantName,
      });
      dispatch(resetCurrentAddonRuleset(addonType));
      onSuccess();
    } catch (error) {
      dispatch({ payload: error, type: errorConstantName });
      onError();
    }
  };
}

export function loadInternalUseFieldsAddon() {
  return (dispatch) => dispatch(loadAddons("internal_use_fields"));
}

export function loadAddons(
  addonType,
  successCallback = (data) => {},
  errorCallback = (error) => {},
) {
  const [startConstantName, successConstantName, errorConstantName] = {
    additional: [
      ADD_ONS_ADDITIONAL_LOAD_START,
      ADD_ONS_ADDITIONAL_LOAD_SUCCESS,
      ADD_ONS_ADDITIONAL_LOAD_ERROR,
    ],
    cards: [
      ADD_ONS_CARDS_LOAD_START,
      ADD_ONS_CARDS_LOAD_SUCCESS,
      ADD_ONS_CARDS_LOAD_ERROR,
    ],
    financials: [
      ADD_ONS_FINANCIALS_LOAD_START,
      ADD_ONS_FINANCIALS_LOAD_SUCCESS,
      ADD_ONS_FINANCIALS_LOAD_ERROR,
    ],
    paperless: [
      ADD_ONS_PAPERLESS_LOAD_START,
      ADD_ONS_PAPERLESS_LOAD_SUCCESS,
      ADD_ONS_PAPERLESS_LOAD_ERROR,
    ],
    internal_use_fields: [
      ADD_ONS_INTERNAL_USE_FIELDS_LOAD_START,
      ADD_ONS_INTERNAL_USE_FIELDS_LOAD_SUCCESS,
      ADD_ONS_INTERNAL_USE_FIELDS_LOAD_ERROR,
    ],
    alert_module: [
      ADD_ONS_ALERT_LOAD_START,
      ADD_ONS_ALERT_SET_CURRENT,
      ADD_ONS_ALERT_LOAD_ERROR,
    ],
  }[addonType];

  return async (dispatch, getState) => {
    dispatch({
      type: startConstantName,
    });

    const entityId = getState().current_user.current_entity.id;
    const addonConfigs = api("addon_configs", _, entityId);

    try {
      const result = await AddonConfigModel.fetchAddonConfigByAddonType({
        addonType: [addonType],
        entityId,
      })
      dispatch({ payload: result, type: successConstantName });
      successCallback(result);
    } catch (error) {
      dispatch({ payload: error, type: errorConstantName });
      errorCallback(error);
    }
  };
}

export function setCurrentAddonRuleset(serialNumber, version, addonType) {
  const constantName = {
    additional: ADD_ONS_ADDITIONAL_SET_CURRENT,
    cards: ADD_ONS_CARDS_SET_CURRENT,
    financials: ADD_ONS_FINANCIALS_SET_CURRENT,
    paperless: ADD_ONS_PAPERLESS_SET_CURRENT,
    internal_use_fields: ADD_ONS_INTERNAL_USE_FIELDS_SET_CURRENT,
  }[addonType];

  return dispatch => {
    version.serial_number = serialNumber;

    dispatch({
      payload: version,
      type: constantName,
    });
  };
}

export function resetCurrentAddonRuleset(addonType) {
  const constantName = {
    additional: ADD_ONS_ADDITIONAL_RESET_CURRENT,
    cards: ADD_ONS_CARDS_RESET_CURRENT,
    financials: ADD_ONS_FINANCIALS_RESET_CURRENT,
    paperless: ADD_ONS_PAPERLESS_RESET_CURRENT,
    internal_use_fields: ADD_ONS_INTERNAL_USE_FIELDS_RESET_CURRENT,
  }[addonType];

  return dispatch => {
    dispatch({
      type: constantName,
    });
  };
}

export function updateAddonRuleset(id, ruleSet, addonType, callback) {
  const reducerKey = addonType;
  const [startConstantName, successConstantName, errorConstantName] = {
    additional: [
      ADD_ONS_ADDITIONAL_UPDATE_RULESET_START,
      ADD_ONS_ADDITIONAL_UPDATE_RULESET_SUCCESS,
      ADD_ONS_ADDITIONAL_UPDATE_RULESET_ERROR,
    ],
    cards: [
      ADD_ONS_CARDS_UPDATE_RULESET_START,
      ADD_ONS_CARDS_UPDATE_RULESET_SUCCESS,
      ADD_ONS_CARDS_UPDATE_RULESET_ERROR,
    ],
    financials: [
      ADD_ONS_FINANCIALS_UPDATE_RULESET_START,
      ADD_ONS_FINANCIALS_UPDATE_RULESET_SUCCESS,
      ADD_ONS_FINANCIALS_UPDATE_RULESET_ERROR,
    ],
    paperless: [
      ADD_ONS_PAPERLESS_UPDATE_RULESET_START,
      ADD_ONS_PAPERLESS_UPDATE_RULESET_SUCCESS,
      ADD_ONS_PAPERLESS_UPDATE_RULESET_ERROR,
    ],
    internal_use_fields: [
      ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_START,
      ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_SUCCESS,
      ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_ERROR,
    ],
  }[addonType];

  return async (dispatch, getState) => {
    const toggledRuleSet = { ...ruleSet, active: !ruleSet.active };

    dispatch({
      payload: id,
      type: startConstantName,
    });

    const addons = getState().add_ons[reducerKey];
    const accessToken = getState().current_user.access_token;
    const entityId = getState().current_user.current_entity.id;
    const addonConfigs = api("addon_configs", accessToken, entityId);

    try {
      const response = await addonConfigs.updateRuleSet(id, toggledRuleSet);

      for (const i in addons) {
        if (addons[i].id === id) {
          addons[i] = response.data.data;
        }
      }

      if (callback) callback;

      dispatch({
        meta: {
          mixpanel: {
            event: `Update ${addonType} ruleset`,
            props: {
              "Entity ID": getState().current_user.current_entity.id,
              Ruleset: response.data.data,
              "Ruleset ID": id,
              distinct_id: getState().current_user.data.data.id,
            },
          },
        },
        payload: addons,
        type: successConstantName,
      });
    } catch (error) {
      dispatch({ payload: error, type: errorConstantName });
    }
  };
}

function setRuleSetNameDefault(ruleSet, addonType) {
  const defaultName = {
    additional: "Additional Template",
    cards: "Card Ruleset",
    financials: "Financials Ruleset",
    paperless: "Paperless Ruleset",
    internal_use_fields: "Internal Use Fields",
  }[addonType];

  return { ...ruleSet, name: ruleSet.name || defaultName };
}

export function setFinancialsMandatoryFields(value) {
  return (dispatch, getState) => {
    const list =
      getState().add_ons.current_financials.attributes.config
        .mandatory_fields || [];
    const newList = toggleArrayValue(list, value);
    dispatch({
      payload: newList,
      type: ADD_ONS_FINANCIALS_SET_MANDATORY_FIELDS,
    });
  };
}

export function setFinancialsIndicatorFields(value) {
  return (dispatch, getState) => {
    const list =
      getState().add_ons.current_financials.attributes.config
        .indicator_fields || [];
    const newList = toggleArrayValue(list, value);
    dispatch({
      payload: newList,
      type: ADD_ONS_FINANCIALS_SET_INDICATOR_FIELDS,
    });
  };
}

export function setFinancialsAdditionalFields(name, value) {
  return dispatch => {
    dispatch({
      payload: {
        name,
        value,
      },
      type: ADD_ONS_FINANCIALS_SET_ADDITIONAL_FIELDS,
    });
  };
}

export function setAddonConfigValue(name, value, addonType) {
  const constantName = {
    paperless: ADD_ONS_PAPERLESS_SET_CONFIG_VALUE,
  }[addonType];

  return dispatch => {
    dispatch({
      payload: { name, value },
      type: constantName,
    });
  };
}
