import debounce from "debounce";
import TextInput from "modules/shared/components/inputs/TextInput";
import AutoSuggest, {
  EmailSearchInput,
} from "modules/shared/components/widgets/interactive/consumer_name_search/AutoSuggest";
import { hasActiveIUFSearchSendConfig } from "modules/shared/helpers/internalUseFieldsHelper";
import React, { Component } from "react";
import { connect } from "react-redux";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

import {
  checkEmailisUnique,
  componentComplete,
  lookupByEmail,
  selectCompanyName,
  setNewConsumerInviteFormValue,
} from "../../actions/invite";
import styles from "./css/InviteNew.css";

function EmailLookupResultFound(props) {
  const { companyAutosuggestList } = props;

  if (isBlank(companyAutosuggestList)) {
    return null;
  }

  return (
    <div className="has-text-danger has-text-weight-normal mb-1">
      We found a business with the same email domain. Please go through the
      dropdown list to see if you can find your customer.
    </div>
  );
}

class InviteNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSearchStarted: false,
      form_errors: {},
    };

    this.debouncedLookupByEmail = debounce(this.lookupByEmail, 500);
  }

  lookupByEmail(email) {
    const { dispatch } = this.props;
    dispatch(lookupByEmail(email));
  }

  handleChange = event => {
    const { dispatch } = this.props;
    const value = event.target.value;

    dispatch(setNewConsumerInviteFormValue(event.target.name, value));
    if (Object.keys(event).includes("target")) {
      this.checkValid(event.target);
    }
  };

  _emailChangeHandler = (value, isValid) => {
    const { dispatch, email_valid, existing_entity } = this.props;
    const { form_errors } = this.state;

    dispatch(setNewConsumerInviteFormValue("consumer_email", value));

    if (isValid) {
      const { consumer_email, ...rest } = form_errors;
      this.setState({
        form_errors: { ...rest },
      });

      if (existing_entity) {
        dispatch(checkEmailisUnique(value));
      }

      this.debouncedLookupByEmail(value);
      this.setState({ hasSearchStarted: true });
    } else {
      this.setState({
        form_errors: {
          ...form_errors,
          consumer_email: "Please enter a valid email.",
        },
      });
    }
  };

  handleBlur = event => {
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
    }
  };

  handleClick = value => {
    const { dispatch } = this.props;
    dispatch(componentComplete("company", true));
    dispatch(selectCompanyName(value));
  };

  componentDidUpdate() {
    const { dispatch, complete, component } = this.props;

    const isComplete = this.isComplete();
    if (isComplete != complete) {
      dispatch(componentComplete(component, isComplete));
    }
  }

  isComplete() {
    const { form_values, loading } = this.props;
    const { form_errors: formErrors, hasSearchStarted } = this.state;

    if (loading || !hasSearchStarted) {
      return false;
    }

    if (
      form_values.consumer_email.length < 1 ||
      form_values.consumer_first_name.length < 1
    ) {
      return false;
    }

    let hasError = false;
    const errorKeys = Object.keys(formErrors);
    for (const errorKey of errorKeys) {
      if (isPresent(formErrors[errorKey])) {
        hasError = true;
      }
    }

    return !hasError;
  }

  checkEmail(props = this.props) {
    const value = props.form_values["consumer_email"];

    if (props.email_valid === false && props.email_validating === false) {
      this.setState({
        form_errors: {
          ...this.state.form_errors,
          consumer_email: "Email already exists. Please enter another email",
        },
      });
    } else {
      const { consumer_email, ...rest_errors } = this.state.form_errors;
      this.setState({
        form_errors: { ...rest_errors },
      });
    }
  }

  checkValid(target) {
    const { dispatch, email_valid, email_validating, form_values } = this.props;
    const { form_errors } = this.state;

    if (target.id === "consumer_first_name") {
      this.setState({
        form_errors: {
          ...this.state.form_errors,
          consumer_first_name: this.getConsumerFirstNameError(target.value),
        },
      });
    }
  }

  getConsumerFirstNameError(value) {
    if (isBlank(value)) {
      return "You must specify your customer's name";
    }

    return "";
  }

  render() {
    const {
      application_type,
      companyAutosuggestList,
      loading,
      next_path,
      form_values,
      percent_complete,
      email_validating,
      existing_entity,
    } = this.props;
    const { form_errors, hasSearchStarted } = this.state;

    const form_loading = { consumer_email: email_validating };

    const processedNextPath = hasActiveIUFSearchSendConfig(application_type) ?
      next_path :
      "/dashboard/invite/credit/invite-existing";

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.panel}>
            <span className="has-text-weight-normal">
              Send application to your customer here
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.panel}>
            <TextInput
              id="consumer_first_name"
              name="consumer_first_name"
              type="text"
              error={form_errors.consumer_first_name}
              loading={form_loading.consumer_first_name}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="Your customers name"
              placeholder="e.g. Joe Blogs, trading name, trust name, etc."
              required={true}
              value={form_values.consumer_first_name}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.panel}>
            <EmailLookupResultFound
              companyAutosuggestList={companyAutosuggestList}
            />
            <EmailSearchInput
              id="consumer_email"
              error={form_errors.consumer_email}
              loading={loading}
              handleChange={this._emailChangeHandler}
            />
            <AutoSuggest
              id="company_name"
              context="emailDomainSearch"
              options={companyAutosuggestList}
              handleSelect={this.handleClick}
              nextPath={processedNextPath}
              loading={loading}
              isCannotFindTextVisible={false}
              hasSearchStarted={hasSearchStarted}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default connect((state, ownProps) => {
  const existing_entity =
    state.cns_invite.company_autosuggest_selected_details.id || null;

  return {
    companyAutosuggestList: state.cns_invite.company_autosuggest_list,
    email_valid: state.cns_invite.email_valid,
    email_validating: state.cns_invite.email_validating,
    existing_entity,
    form_values: {
      consumer_email: state.cns_invite.new_consumer_email || "",
      consumer_first_name: state.cns_invite.new_consumer_first_name || "",
    },
    loading: state.cns_invite.company_autosuggest_lookup_loading,
  };
})(InviteNew);
