/* Import libraries */
import React from "react";
import { getTipText } from "utils/tipHelper.js";

/* Import CSS */
import styles from "./css/ToolTip.css";
import Button from "../../inputs/Button";

export default class Tooltip extends React.Component {
  render() {
    const {
      css_style,
      dismissHandler,
      eventText,
      eventHandler,
      tip_interactive_content,
      tip_name,
    } = this.props;
    let style = styles.tool_tip_text;
    let tip_text;

    if (css_style) {
      style = `${style} ${styles[css_style]}`;
    }

    if (tip_name) {
      tip_text = getTipText(tip_name);
    }

    return (
      <div className={styles.tool_tip}>
        <span className={style}>
          {tip_text}
          {tip_interactive_content}
          {(dismissHandler || eventHandler) && (
            <p className={styles.button}>
              {eventHandler && (
                <Button
                  text={eventText}
                  css_style={"button_tool_tip"}
                  handleClick={eventHandler}
                  small={true}
                />
              )}
              {dismissHandler && (
                <Button
                  text="Got it"
                  css_style={"button_tool_tip"}
                  handleClick={dismissHandler}
                  small={true}
                />
              )}
            </p>
          )}
        </span>
      </div>
    );
  }
}
