import isBlank from "./isBlank";

export default function isInteger(value) {
  if (
    isBlank(value) ||
    Number.isNaN(parseInt(value)) ||
    value.toString().includes(".")
  ) {
    return false;
  }

  return true;
}
