/* Import libraries */
import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

/* Import components */
import Button from "modules/shared/components/inputs/Button";
import SectionTitle from "modules/shared/components/widgets/static/SectionTitle";
import SectionBadge from "modules/shared/components/widgets/static/SectionBadge";
import SectionDescription from "modules/shared/components/widgets/static/SectionDescription";

/* Import actions */

/* Import CSS */
import styles from "./css/LeadCompleted.css";

class LeadCompleted extends Component {
  render() {
    const { title } = this.props;
    const returnPath = "/dashboard/leads/list";

    return (
      <div className={styles.outer}>
        <div className={styles.flow}>
          <div className={styles.flow_wrap_slide}>
            <div className={styles.pageFlex} key={this.props.location.pathname}>
              <div className={styles.container}>
                <div className={styles.content}>
                  <div className={styles.containerFlex}>
                    <section className={styles.blue_section}>
                      <div className={styles.row}>
                        <div className={styles.panel}>
                          <SectionTitle white={true} text={title} />
                          <SectionBadge icon="email" />
                        </div>
                      </div>
                      <div className={styles.description_row}>
                        <div className={styles.description}>
                          <SectionDescription text={""} />
                          <div className={styles.button_outer}>
                            <Button
                              css_style="button_white_outer"
                              text="ok"
                              handleClick={() => {
                                browserHistory.push(returnPath);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const defaults = { title: "lead_name added!" };

module.exports = connect(state => {
  const entityLeadName =
    state.current_user.current_entity.attributes.lead_name || "Lead";

  return { title: defaults.title.replace("lead_name", entityLeadName) };
})(LeadCompleted);
