import {
  SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_START,
  SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_SUCCESS,
  SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_ERROR,
  SHARED_DATA_SET_FORM_BUILDER_VALIDATION_RESULTS,
  SHARED_DATA_REMOVE_FORM_BUILDER_VALIDATION_RESULTS,
} from "./constants";

const sharedDataDefaults = {
  trading_names_loading: false,
  trading_names: [],
};

export function sharedDataReducer(state = sharedDataDefaults, action) {
  switch (action.type) {
    case SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_START:
      return { ...state, trading_names_loading: true };
    case SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_SUCCESS:
      return {
        ...state,
        trading_names_loading: false,
        trading_names: action.payload,
      };
    case SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_ERROR:
      return { ...state, trading_names_loading: false };
    case SHARED_DATA_SET_FORM_BUILDER_VALIDATION_RESULTS:
      return { ...state, [action.payload.key]: action.payload.value };
    case SHARED_DATA_REMOVE_FORM_BUILDER_VALIDATION_RESULTS:
      const newSharedData = { ...state };
      delete newSharedData[action.payload.key];
      return newSharedData;
    default:
      return { ...state };
  }
}
