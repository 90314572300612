import axios from "./axios";;

let CreditCheckLookup = function(config) {
  config.timeout = 30000;
  this.axios = axios(config);
  this.type = "credit_check_lookup";
};

CreditCheckLookup.prototype.getCreditCheckLookup = function(
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

export default CreditCheckLookup;
