/* Import libraries */
import React from "react";
import { connect } from "react-redux";

/* Import actions */
import { setValue } from "../actions";

/* Import CSS */
import styles from "./css/AutoDecisioning.css";
/* Import components */
import NumberTextBox from "modules/shared/components/widgets/interactive/NumberTextBox";

var MaxLimit = createClass({
  componentDidMount: function() {
    const { dispatch, max_limit, min_limit } = this.props;
    if (!max_limit) {
      dispatch(setValue("max_credit_value", 0));
    }
    if (!min_limit) {
      dispatch(setValue("min_credit_value", 0));
    }
  },

  updateMinValue: function(context, e) {
    const { dispatch, read_only } = this.props;
    if (read_only) return;
    dispatch(setValue("min_credit_value", e.target.value));
  },
  updateMaxValue: function(context, e) {
    const { dispatch, read_only } = this.props;
    if (read_only) return;
    dispatch(setValue("max_credit_value", e.target.value));
  },
  getConflictsWith: function(limit_breakpoints) {
    const { min_limit, max_limit } = this.props;
    return;
  },

  render: function() {
    const {
      loading,
      rule_set,
      current_entity,
      min_limit,
      max_limit,
      read_only,
      error,
      limit_breakpoints,
    } = this.props;

    let conflicts_with = this.getConflictsWith(limit_breakpoints);
    return (
      <div className={styles.section}>
        <h3>Limit Range</h3>
        <div>
          {error && !read_only && <div className={styles.error}>{error}</div>}
          <div className={styles.info} style={{ marginBottom: "20px" }}>
            Select the minimum and maximum limit you deem acceptable for an
            application to be auto decisioned.
          </div>
          <div className={styles.inputs_container}>
            <NumberTextBox
              value={min_limit}
              previewMessage=""
              prefix={"$"}
              disabled={read_only}
              handleChange={this.updateMinValue}
              error_msgs={{ min_reached: "Min credit limit is: " }}
              hide_preview={true}
              inline_style={true}
            />
            <span className={styles.limit_hyphen}>-</span>
            <NumberTextBox
              value={max_limit}
              max={current_entity.trade_account_limit}
              previewMessage=""
              prefix={"$"}
              disabled={read_only}
              handleChange={this.updateMaxValue}
              error_msgs={{ max_reached: "Max credit limit is: " }}
              hide_preview={true}
              inline_style={true}
            />
          </div>
        </div>
      </div>
    );
  },
});

var defaults = {
  title: "Select your business type",
};

module.exports = connect((state, ownProps) => {
  return {
    ...defaults,
    rule_set: ownProps.rule_set,
    max_limit:
      state.auto_decisions.current_rule_set.attributes.max_credit_value,
    min_limit:
      state.auto_decisions.current_rule_set.attributes.min_credit_value,
    current_entity: ownProps.current_entity,
  };
})(MaxLimit);
