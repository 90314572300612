/* Import actions */
import {
  clearSeletedCreditCheckRules,
  loadAmlCheckLookup,
  loadAntiFraud,
  loadCard,
  loadCreditCheckLookup,
  persistAmlCheckConfig,
  setAmlCheckLevels,
  setCreditCheckInitialValues,
  setCreditWorksStatus,
  setMandatoryCheck,
  setMinimumGuarantees,
  setSelectedAmlCheckRules,
  setSeletedCreditCheckRules,
  updateMandatoryChecks,
  updateMandatoryChecksCreditWorks,
} from "../actions";
import {
  isFeatureEditEnabled,
  isHeadquarter,
  isRegular,
} from "modules/shared/helpers/headquarterDetect";

import AmlCheckModal from "./AmlCheckModal";
import Button from "modules/shared/components/inputs/Button";
import Checkbox from "modules/shared/components/inputs/Checkbox";
/* Import components */
import CircleIconButton from "modules/shared/components/widgets/interactive/CircleIconButton";
import CreditCardModal from "./CreditCardModal";
import CreditCheckModal from "./CreditCheckModal";
import ExtraDescription from "modules/shared/components/text/ExtraDescription";
import PopperTooltip from "modules/shared/components/widgets/interactive/PopperToolTip.js";
/* Import libraries */
import React from "react";
import RoundedDropdown from "modules/shared/components/widgets/interactive/RoundedDropdown";
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import Tooltip from "@material-ui/core/Tooltip";
import checksStyles from "./css/MandatoryChecks.css";
import { connect } from "react-redux";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
/* Import CSS */
import styles from "./css/CommonEditProfile.css";
import toggleArrayValue from "utils/toggleArrayValue";
import AntiFraud from "./AntiFraud";
import { loadAddons } from "modules/addons/actions";
import { isEmpty } from "lodash";
import SquareModal from "modules/shared/components/widgets/static/SquareModal";

const MandatoryChecks = createClass({
  submitCreditCheck(requiresCreditCheck) {
    const {
      dispatch,
      creditCheckOptions,
      creditWorksStatus,
      selected_credit_check_rules: selectedCreditCheckRules,
      autosave,
    } = this.props;

    let _requiresCreditCheck = requiresCreditCheck || false;
    let _selectedCreditCheckRule = selectedCreditCheckRules;
    let _creditWorksStatus = creditWorksStatus;

    const creditCheckOptionNZ = creditCheckOptions.find(item => {
      return item.id == selectedCreditCheckRules.nz_rule_selection_id;
    });

    if (
      creditCheckOptionNZ &&
      creditCheckOptionNZ.requires_login &&
      creditWorksStatus === "inactive"
    ) {
      dispatch(setCreditWorksStatus("pending"));
      _creditWorksStatus = "pending";
    }

    if (
      (!selectedCreditCheckRules.nz_rule_selection_id &&
        !selectedCreditCheckRules.au_rule_selection_id &&
        !selectedCreditCheckRules.nz_personal_rule_selection_id &&
        !selectedCreditCheckRules.au_personal_rule_selection_id) ||
      !_requiresCreditCheck
    ) {
      _requiresCreditCheck = false;
      _selectedCreditCheckRule = {};
      dispatch(clearSeletedCreditCheckRules());
    }

    const attributes = {
      requires_credit_check: _requiresCreditCheck,
      selected_credit_check_rules: _selectedCreditCheckRule,
      credit_works_status: _creditWorksStatus,
    };

    dispatch(setMandatoryCheck("requires_credit_check", _requiresCreditCheck));

    if (autosave) {
      dispatch(updateMandatoryChecks(attributes));
    } else {
      dispatch(
        updateMandatoryChecksCreditWorks({
          credit_works_status: _creditWorksStatus,
        }),
      );
    }
  },

  submitAmlCheck() {
    const { autosave, dispatch } = this.props;

    if (autosave) {
      dispatch(persistAmlCheckConfig());
    }
  },

  getInitialState() {
    return {
      check: false,
      currentMandatoryCheck: null,
      amlCheckModalVisible: false,
      showAntiFraudModal: false,
      showCreditModal: false,
      showFeatureNotEnabledModal: false,
    };
  },

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(loadCreditCheckLookup());
    dispatch(loadAmlCheckLookup());
    dispatch(setCreditCheckInitialValues());
    dispatch(loadAntiFraud());
  },

  dismissCreditModal() {
    this.setState({ showCreditModal: false });
  },

  dismissAmlCheckModal() {
    this.setState({ amlCheckModalVisible: false });
  },

  dismissFeatureNotEnabledModal() {
    this.setState({ showFeatureNotEnabledModal: false });
  },

  cancelCreditModal(addCardInfo) {
    this.setState({ showCreditModal: false });

    if (addCardInfo) {
      this.setState({ showCreditInfoModal: true });
      this.setState({ currentMandatoryCheck: "requires_credit_check" });
    }
  },

  cancelAmlCheckModal(addCardInfo) {
    this.setState({ amlCheckModalVisible: false });

    if (addCardInfo) {
      this.setState({ showCreditInfoModal: true });
      this.setState({ currentMandatoryCheck: "requires_aml_check" });
    }
  },

  cancelCardInfo() {
    this.setState({ showCreditInfoModal: false });
  },

  saveCard(response) {
    const { dispatch } = this.props;
    const { currentMandatoryCheck } = this.state;
    this.setState({ showCreditInfoModal: false });
    if (response) {
      dispatch(loadCard());
      switch (currentMandatoryCheck) {
        case "requires_credit_check":
          this.submitCreditCheck(true);
          break;
        case "requires_identification_check":
          this.submitIdentityCheck(true);
          break;
      }
    }
  },

  selectCreditCheckType(creditCheck, type) {
    const { dispatch } = this.props;

    dispatch(setSeletedCreditCheckRules(creditCheck, type));
    this.submitCreditCheck(true);
  },

  toggleAmlCheckProvider(region, provider) {
    const { amlCheckRules, dispatch } = this.props;
    const regionProvider = amlCheckRules.selectedProviders[region];

    const payload = { [region]: null };
    if (isPresent(regionProvider) && regionProvider === provider) {
      payload[region] = null;
    } else {
      payload[region] = provider;
    }

    dispatch(setSelectedAmlCheckRules(payload));

    const selectedProviders = Object.values(amlCheckRules.selectedProviders);
    const isAmlCheckRequired = selectedProviders.some(provider =>
      isPresent(provider),
    );

    if (!isAmlCheckRequired) {
      dispatch(setAmlCheckLevels([]));
    }

    this.submitAmlCheck();
  },

  getHandler(key) {
    switch (key) {
      case "minimum_guarantees":
        return this.setMinimumGuarantees;
      case "identification_check_level":
        return this.setIdentificationCheckLevel;
      case "aml_check_level":
        return this.setAmlCheckLevel;
      default:
        console.error("No handler located for this key.");
    }
  },

  setAmlCheckLevel(value) {
    const {
      amlCheckRules,
      dispatch,
      // identificationCheckLevels,
      update_loading: updateLoading,
    } = this.props;

    if (updateLoading) {
      return;
    }

    const newLevels = toggleArrayValue(amlCheckRules.selectedLevels, value);
    dispatch(setAmlCheckLevels(newLevels));

    this.submitAmlCheck();

    // There should not be a cross over of levels to check between ID Checks
    // and AML Checks
    // if (identificationCheckLevels.includes(value)) {
    //   this.setIdentificationCheckLevel(value);
    // }
  },

  setMinimumGuarantees(e) {
    const {
      dispatch,
      requires_guarantees: requiresGuarantees,
      autosave,
      update_loading: updateLoading,
    } = this.props;
    if (!updateLoading) {
      dispatch(setMinimumGuarantees(e.value));

      const attributes = {
        requires_guarantees: requiresGuarantees,
        minimum_guarantees: e.value,
      };

      if (autosave) {
        dispatch(updateMandatoryChecks(attributes));
      }
    }
  },

  selectMainOption(event) {
    const {
      dispatch,
      requires_guarantees: requiresGuarantees,
      minimums,
      autosave,
      update_loading: updateLoading,
      requiresAmlCheck,
      requiresAntiFraudCheck,
    } = this.props;

    let bool = true;

    if (!updateLoading) {
      switch (event) {
        case "requires_anti_fraud_check":
          if (requiresAmlCheck) {
            this.setState({ showFeatureNotEnabledModal: true });
            break;
          }

          this.setState({ showAntiFraudModal: true });
          break;
        case "requires_credit_check":
          this.setState({ showCreditModal: true });
          break;
        case "requires_guarantees":
          bool = !requiresGuarantees;

          const attributes = {
            requires_guarantees: bool,
            minimum_guarantees: bool ? minimums["minimum_guarantees"] : 0,
          };

          dispatch(setMandatoryCheck(event, bool));
          if (autosave) {
            dispatch(updateMandatoryChecks(attributes));
          }
          break;
        case "requires_aml_check":
          if (requiresAntiFraudCheck) {
            this.setState({ showFeatureNotEnabledModal: true });
            break;
          }

          this.setState({ amlCheckModalVisible: true });
          break;
      }
    }
  },

  isCheckLevelSelected(key, value) {
    if (key === "aml_check_level") {
      return this.props.amlCheckRules.selectedLevels.includes(value);
    }

    return false;
  },

  toggleTooltip(toolTipName, flag) {
    this.setState({ [toolTipName]: flag });
  },

  checkboxOptions(field) {
    const { cardsAddons } = this.props;
    const key = field.options.key;
    const checkboxes = [];

    let list = field.options.list;
    if (cardsAddons.length === 0) {
      list = list.filter(item => item.value !== "cardholder");
    }

    list.forEach((item, index) => {
      const isChecked = this.isCheckLevelSelected(key, item.value);
      const onChangeHandler = this.getHandler(key);

      checkboxes.push(
        <PopperTooltip
          title={item.tooltip}
          open={!!this.state[`${key}-${index}`]}
          placement={"right"}
          noArrow={true}
        >
          <SquareCheckbox
            key={`${key}-${index}`}
            checkboxId={`${key}-${item.value}`}
            checkboxName={item.value}
            checked={isChecked}
            handleChange={onChangeHandler.bind(this, item.value)}
            toggleTooltip={flag => this.toggleTooltip(`${key}-${index}`, flag)}
            label={item.label}
            no_float={true}
            disabled={!isFeatureEditEnabled("Credit")}
          />
        </PopperTooltip>,
      );
    });

    return (
      <div className={checksStyles.square_checkbox_container}>{checkboxes}</div>
    );
  },

  amlCheckProviders() {
    const { amlCheckOptions, amlCheckRules, card } = this.props;

    const selectedProviders = amlCheckRules.selectedProviders;
    const divStyle = { minHeight: "50px" };
    const providers = {
      auAmlCheckProviders: [],
      nzAmlCheckProviders: [],
    };

    for (const lookup of amlCheckOptions) {
      const { attributes } = lookup;
      let isActive = attributes.active;
      let description = attributes.description;

      if (!card && isRegular() && attributes.active) {
        isActive = false;
        description = (
          <span>
            {`${description} `}
            <p className={checksStyles.requiredNotice}>
              Please add card before selecting this option.
            </p>
          </span>
        );
      }

      const isChecked =
        selectedProviders[attributes.region] === attributes.slug;

      const key = `${attributes.region.toLowerCase()}AmlCheckProviders`;
      providers[key].push(
        <div key={attributes.slug} className={checksStyles.checkbox_container}>
          <Checkbox
            key={attributes.slug}
            checkboxId={attributes.slug}
            checked={isChecked}
            handleChange={this.toggleAmlCheckProvider.bind(
              this,
              attributes.region,
              attributes.slug,
            )}
            label={attributes.name}
            description={description}
            disabled={!isActive || !isFeatureEditEnabled("Credit")}
            divStyle={divStyle}
          />
        </div>,
      );
    }

    return providers;
  },

  amlCheckModal() {
    const {
      autosave,
      card,
      role_types: roleTypes,
      update_loading: updateLoading,
    } = this.props;
    const {
      auAmlCheckProviders,
      nzAmlCheckProviders,
    } = this.amlCheckProviders();

    let button;

    if (!card && isRegular()) {
      if (roleTypes.includes("admin")) {
        button = (
          <Button
            text="Add Card"
            handleClick={this.cancelAmlCheckModal.bind(this, true)}
          />
        );
      } else {
        button = (
          <Button
            text="Cancel"
            handleClick={this.cancelAmlCheckModal.bind(this, false)}
          />
        );
      }
    } else {
      button = <Button text="OK" handleClick={this.dismissAmlCheckModal} />;
    }

    let closeButtonStyle = "invite_mandatory_button_close";
    if (autosave) closeButtonStyle = "mandatory_button_close";

    return (
      <AmlCheckModal
        title="AML Check"
        bodyText={defaults.options.aml.modalText}
        button={button}
        updateLoading={updateLoading}
        nzAmlCheckProviders={nzAmlCheckProviders}
        auAmlCheckProviders={auAmlCheckProviders}
        dismissHandler={this.cancelAmlCheckModal.bind(this, false)}
        closeButtonStyle={closeButtonStyle}
      />
    );
  },

  formatDescription(description) {
    if (isBlank(description)) {
      return;
    }

    return description.map(content => <p>{content}</p>);
  },

  render() {
    const {
      amlCheckRules,
      antiFraudSettings,
      autosave,
      card,
      company_name,
      creditCheckOptions,
      creditModalText,
      creditWorksStatus,
      minimums,
      options,
      requiresAmlCheck,
      requires_credit_check,
      requires_guarantees,
      requiresAntiFraudCheck,
      role_types,
      router,
      selected_credit_check_rules,
      title,
      update_loading,
    } = this.props;

    const {
      showAntiFraudModal,
      amlCheckModalVisible,
      showCreditInfoModal,
      showCreditModal,
      showFeatureNotEnabledModal,
    } = this.state;

    const button_components = [];

    let modalMarkup;
    let login_verification = false;

    const nzCreditCheckComponents = [];
    const auCreditCheckComponents = [];
    const nzPersonalCreditCheckComponents = [];
    const auPersonalCreditCheckComponents = [];

    const divStyle = { minHeight: "50px" };

    creditCheckOptions.forEach((item, index) => {
      const current_check = item;

      if (
        (item.requires_login &&
          item.id == selected_credit_check_rules.nz_rule_selection_id) ||
        (item.requires_login &&
          item.id == selected_credit_check_rules.au_rule_selection_id)
      ) {
        login_verification = true;
      }

      let current_check_active = current_check.active;
      let additionalDescription = "";

      const checkDescription = current_check.description || {};
      const businessCheckDescription = checkDescription.business_check;
      const formattedBusinessCheckDescription = this.formatDescription(
        businessCheckDescription,
      );
      const personalCheckDescription = checkDescription.personal_check;
      const formattedPersonalCheckDescription = this.formatDescription(
        personalCheckDescription,
      );

      if (
        current_check.requires_login &&
        creditWorksStatus === "inactive" &&
        !company_name
      ) {
        current_check_active = false;
        additionalDescription = (
          <p className={checksStyles.requiredNotice}>
            Please complete business details section before selected this
            option.
          </p>
        );
      }

      if (
        !card &&
        isRegular() &&
        !current_check.requires_login &&
        current_check.active
      ) {
        current_check_active = false;
        additionalDescription = (
          <p className={checksStyles.requiredNotice}>
            Please add card before selected this option.
          </p>
        );
      }

      if (item.region === "NZ") {
        if (item.business_check) {
          const description = (
            <span>
              {formattedBusinessCheckDescription}
              {additionalDescription}
            </span>
          );

          nzCreditCheckComponents.push(
            <div key={index} className={checksStyles.checkbox_container}>
              <Checkbox
                key={index + "_business"}
                checkboxId={current_check.id + "_business"}
                checked={
                  item.id == selected_credit_check_rules.nz_rule_selection_id
                }
                handleChange={() =>
                  this.selectCreditCheckType(current_check, "business")
                }
                label={current_check.name}
                description={description}
                disabled={
                  !current_check_active || !isFeatureEditEnabled("Credit")
                }
                divStyle={divStyle}
              />
            </div>,
          );
        }

        if (item.personal_check) {
          const description = (
            <span>
              {formattedPersonalCheckDescription}
              {additionalDescription}
            </span>
          );

          nzPersonalCreditCheckComponents.push(
            <div key={index} className={checksStyles.checkbox_container}>
              <Checkbox
                key={index + "_personal"}
                checkboxId={current_check.id + "_personal"}
                checked={
                  item.id ==
                  selected_credit_check_rules.nz_personal_rule_selection_id
                }
                handleChange={() =>
                  this.selectCreditCheckType(current_check, "personal")
                }
                label={current_check.name}
                description={description}
                disabled={
                  !current_check_active || !isFeatureEditEnabled("Credit")
                }
                divStyle={divStyle}
              />
            </div>,
          );
        }
      } else if (item.region === "AU") {
        if (item.business_check) {
          const description = (
            <span>
              {formattedBusinessCheckDescription}
              {additionalDescription}
            </span>
          );

          auCreditCheckComponents.push(
            <div key={index} className={checksStyles.checkbox_container}>
              <Checkbox
                key={index + "_business"}
                checkboxId={current_check.id + "_business"}
                checked={
                  item.id == selected_credit_check_rules.au_rule_selection_id
                }
                handleChange={() =>
                  this.selectCreditCheckType(current_check, "business")
                }
                label={current_check.name}
                description={description}
                disabled={
                  !current_check_active || !isFeatureEditEnabled("Credit")
                }
                divStyle={divStyle}
              />
            </div>,
          );
        }

        if (item.personal_check) {
          const description = (
            <span>
              {formattedPersonalCheckDescription}
              {additionalDescription}
            </span>
          );

          auPersonalCreditCheckComponents.push(
            <div key={index} className={checksStyles.checkbox_container}>
              <Checkbox
                key={index + "_personal"}
                checkboxId={current_check.id + "_personal"}
                checked={
                  item.id ==
                  selected_credit_check_rules.au_personal_rule_selection_id
                }
                handleChange={() =>
                  this.selectCreditCheckType(current_check, "personal")
                }
                label={current_check.name}
                description={description}
                disabled={
                  !current_check_active || !isFeatureEditEnabled("Credit")
                }
                divStyle={divStyle}
              />
            </div>,
          );
        }
      }
    });

    if (showAntiFraudModal) {
      modalMarkup = (
        <AntiFraud
          data={antiFraudSettings}
          closeModal={() => this.setState({ showAntiFraudModal: false })}
          router={router}
        />
      );
    }

    if (amlCheckModalVisible) {
      modalMarkup = this.amlCheckModal();
    }

    if (showCreditModal) {
      let button;

      if (!card && isRegular() && !login_verification) {
        button =
          role_types.includes("admin") ? (
            <Button
              text="Add Card"
              handleClick={() => this.cancelCreditModal(true)}
            />
          ) : (
            <Button
              text="Cancel"
              handleClick={() => this.cancelCreditModal(false)}
            />
          );
      } else {
        button = <Button text="OK" handleClick={this.dismissCreditModal} />;
      }

      modalMarkup = (
        <CreditCheckModal
          title="Credit Check"
          bodyText={creditModalText}
          button={button}
          update_loading={update_loading}
          nzCreditCheckComponents={nzCreditCheckComponents}
          nzPersonalCreditCheckComponents={nzPersonalCreditCheckComponents}
          auCreditCheckComponents={auCreditCheckComponents}
          auPersonalCreditCheckComponents={auPersonalCreditCheckComponents}
          dismissHandler={() => this.cancelCreditModal(false)}
          close_button_style={
            autosave
              ? "mandatory_button_close"
              : "invite_mandatory_button_close"
          }
        />
      );
    }

    if (showCreditInfoModal) {
      modalMarkup = (
        <CreditCardModal
          onSave={this.saveCard}
          onCancel={this.cancelCardInfo}
        />
      );
    }

    const selectedProviders = Object.values(amlCheckRules.selectedProviders);

    const checkKeys = {
      requires_credit_check: requires_credit_check,
      requires_guarantees: requires_guarantees,
      requires_aml_check: requiresAmlCheck,
      requires_anti_fraud_check: requiresAntiFraudCheck,
    };

    const mandatoryChecks = Object.keys(checkKeys).filter(
      key => checkKeys[key],
    );

    Object.keys(options).forEach((key, index) => {
      let selected = false;
      let highlighted = true;
      let dropdown = null;
      let checkboxOptions = null;
      const optionKey = options[key].value;

      if (mandatoryChecks.includes(optionKey)) {
        selected = true;
      }

      if (options[key].count && selected) {
        dropdown = (
          <RoundedDropdown
            minimum={options[key].count.minimum}
            maximum={options[key].count.maximum}
            value={minimums[options[key].count.key]}
            defaultValue={options[key].count.default_value}
            handleChange={this.getHandler(options[key].count.key)}
            disabled={!isFeatureEditEnabled("Credit")}
            label={options[key].count.label}
          />
        );
      }

      if (options[key].options && selected) {
        checkboxOptions = this.checkboxOptions(options[key]);
      }

      button_components.push(
        <div className={checksStyles.item} key={`container-${index}`}>
          <CircleIconButton
            key={`button-${index}`}
            value={options[key].value}
            type={key}
            selected={selected && highlighted}
            text={options[key].title}
            icon={options[key].icon}
            handleClick={this.selectMainOption}
            disabled={key == "guarantees" && !isFeatureEditEnabled("Credit")}
          />
          {dropdown}
          {checkboxOptions}
        </div>,
      );
    });

    let loader = null;
    let update_button_text = "update changes";
    let update_click = this.submit;
    let update_button = null;

    if (update_loading) {
      loader = <span className={styles.loading}></span>;
      update_button_text = "saving";
      update_click = event => {};
    }

    update_button = (
      <div className={styles.button}>
        <Button handleClick={update_click} text={update_button_text} />
        {loader}
      </div>
    );

    return (
      <div className={styles.row}>
        <div className={styles.block_wide}>
          <div className={styles.liner}>
            {showFeatureNotEnabledModal && (
              <SquareModal
                dismissHandler={this.dismissFeatureNotEnabledModal}
                size="small"
                title="Ops! This feature cannot be enabled."
              >
                <p>
                  Please contact{" "}
                  <a href="mailto:support@1centre.com">support@1centre.com</a>{" "}
                  or live chat with us if you want to enable this feature.
                </p>
              </SquareModal>
            )}
            <h2 className={styles.header}>
              {autosave ? title : "Mandatory checks"}
            </h2>
            <ExtraDescription
              hintName={"headquarterSettingHint"}
              show={isHeadquarter()}
            />
            {update_loading && update_button}
            <div className={checksStyles.row}>
              <div className={checksStyles.options}>{button_components}</div>
            </div>
            {modalMarkup}
          </div>
        </div>
      </div>
    );
  },
});

const defaults = {
  title: "Mandatory checks (required)",
  creditModalText: (
    <span>
      Default company checks will be determined by which agency you select
      below. You must be an <u>existing</u> 'CreditWorks' customer (NZ only) to
      use this option. For Sole Traders, Trusts & Partnerships in New Zealand,
      checks on individuals will be completed via Equifax.
    </span>
  ),
  identityModalText:
    "Verify your customer and/or guarantors through a Drivers License or Passport check. We will confirm the validity of the ID, for you to check with the photo provided by your prospect customer.",
  options: {
    credit: {
      title: "Credit checks",
      value: "requires_credit_check",
      icon: "wallet",
      count: null,
    },
    guarantees: {
      title: "Guarantors",
      value: "requires_guarantees",
      icon: "pig",
      count: {
        minimum: 1,
        default_value: 2,
        maximum: 10,
        key: "minimum_guarantees",
        label: "How many required?",
      },
    },
    anti_fraud: {
      title: "Anti-Fraud (1CAF)",
      value: "requires_anti_fraud_check",
      icon: "card",
    },
    aml: {
      title: "AML check",
      modalText:
        "Verify your customer and/or guarantors through a Drivers License or Passport check. We will confirm the validity of the ID, for you to check with the photo provided by your prospect customer.",
      value: "requires_aml_check",
      icon: "card",
      count: null,
      options: {
        key: "aml_check_level",
        label: "Who would you like to check?",
        default_value: "applicant",
        list: [
          {
            label: "Applicant",
            tooltip: "the authorised person completing application",
            value: "applicant",
          },
          {
            label: "Guarantor",
            tooltip: "the authorised person/s accepting liability for debt",
            value: "guarantor",
          },
          {
            label: "Signatory",
            tooltip: "for trustees, partners and joint applicants",
            value: "signatory",
          },
          {
            label: "Cardholder",
            tooltip: "for additional cardholders on account",
            value: "cardholder",
          },
        ],
      },
    },
  },
};

function filterIllionChecks(
  name,
  illionCodeExist,
  isManual = false,
  is_hybrid_but_include_nz = false,
  region = null,
) {
  if (isManual) {
    return true;
  }

  const isIllion = name.includes("Illion");

  if (illionCodeExist) {
    if (is_hybrid_but_include_nz) {
      return isIllion || region === "NZ";
    } else {
      return isIllion;
    }
  }

  return !isIllion;
}

export default connect((state, ownProps) => {
  const s = state.manage_profile;
  const entity = state.manage_profile.current_entity.attributes;
  const illionCodeExist = entity.illion_code_exist;

  let creditCheckOptions = s.settings_credit_check_lookup || [];
  let amlCheckOptions = s.settingsAmlCheckLookup || [];
  const is_hybrid_but_include_nz = entity.is_hybrid_but_include_nz;
  const antiFraudSettings = s.settingsAntiFraud || [];

  creditCheckOptions = creditCheckOptions.filter(
    cc =>
      cc.name &&
      filterIllionChecks(
        cc.name,
        illionCodeExist,
        false,
        is_hybrid_but_include_nz,
        cc.region,
      ),
  );
  amlCheckOptions = amlCheckOptions.filter(
    cc =>
      cc.attributes &&
      filterIllionChecks(
        cc.attributes.name,
        illionCodeExist,
        false,
        is_hybrid_but_include_nz,
        cc.attributes.region,
      ),
  );

  let requiresIdentificationCheck = s.settings_requires_identification_check;
  let requiresAntiFraudCheck = s.settingsRequiresAntiFraud;
  if (!isPresent(s.settings_requires_identification_check)) {
    const selectedIdentificationCheckRules = Object.values(
      entity.selected_identification_check_rules,
    );

    requiresIdentificationCheck = selectedIdentificationCheckRules.some(rule =>
      isPresent(rule),
    );
  }

  if (!s.settingsRequiresAntiFraud && antiFraudSettings.length > 0) {
    requiresAntiFraudCheck = antiFraudSettings.some(
      antiFraudSetting => antiFraudSetting.active,
    );
  }

  const requires_credit_check =
    s.settings_requires_credit_check != null
      ? s.settings_requires_credit_check
      : entity.requires_credit_check;
  const requires_guarantees =
    s.settings_requires_guarantees != null
      ? s.settings_requires_guarantees
      : entity.requires_guarantees;
  const minimum_guarantees =
    s.settings_minimum_guarantees != null
      ? s.settings_minimum_guarantees
      : entity.minimum_guarantees ||
        defaults.options.guarantees.count.default_value;
  const changes_made =
    s.settings_section_edited != null
      ? s.settings_section_edited["mandatory_checks"]
      : false;

  const creditWorksStatus = s.settings_credit_works_status;

  let identificationCheckLevels = s.settings_identification_check_levels;

  if (identificationCheckLevels === null) {
    identificationCheckLevels = [];

    if (entity.requires_applicant_identification_check) {
      identificationCheckLevels.push("applicant");
    }

    if (entity.requires_guarantees_identification_check) {
      identificationCheckLevels.push("guarantor");
    }

    if (entity.requires_cardholder_identification_check) {
      identificationCheckLevels.push("cardholder");
    }

    if (entity.requires_signatories_identification_check) {
      identificationCheckLevels.push("signatory");
    }
  }

  const amlCheckRules = s.settingsAmlCheckRules || {
    addonConfigActive: false,
    selectedProviders: {},
  };

  let requiresAmlCheck = false;
  if (amlCheckRules.addonConfigActive) {
    const amlProviders = amlCheckRules.selectedProviders;
    const amlProviderKeys = Object.keys(amlProviders);

    requiresAmlCheck = amlProviderKeys.length > 0;
  }

  return {
    amlCheckOptions,
    antiFraudSettings,
    amlCheckRules,
    autosave: ownProps.autosave || false,
    card: s.current_entity_card,
    cardsAddons: s.settingsCardsAddons || [],
    changes_made,
    company_name:
      s.settings_company_name != null
        ? s.settings_company_name
        : entity.company_name,
    creditCheckOptions,
    creditModalText: defaults.creditModalText,
    creditWorksStatus,
    entity: state.current_user.current_entity,
    identityModalText: defaults.identityModalText,
    illionCodeExist,
    minimums: { minimum_guarantees },
    options: defaults.options,
    requires_credit_check,
    requires_guarantees,
    requires_identification_check: requiresIdentificationCheck,
    requiresAntiFraudCheck,
    requiresAmlCheck,
    role_types: state.current_user.current_user_entity_link.attributes.role_types,
    selected_credit_check_rules: s.settings_selected_credit_check_rules,
    title: defaults.title,
    update_loading: s.settings_mandatory_checks_updating,
  };
})(MandatoryChecks);
