import * as PPSRFormComponents from "modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/index";
import React from "react";
import isBlank from "utils/isBlank";

export default function PPSRForm(props) {
  const { region } = props;

  if (isBlank(region)) {
    return null;
  }

  const FormComponent = PPSRFormComponents[`${region}PPSRForm`];

  return <FormComponent {...props} />;
}
