const prefix = "REPORTING/";
export const SET = `${prefix}SET`;
export const RESET = `${prefix}RESET`;
export const SET_REGION = "SET_REGION";
export const SET_STATE = "SET_STATE";
export const CONSUMER_APPLICATION_ARCHIVED_COMPLETE = `${prefix}CONSUMER_APPLICATION_ARCHIVED_COMPLETE`;
export const SUPPLIER_APPLICATION_ARCHIVED_COMPLETE = `${prefix}SUPPLIER_APPLICATION_ARCHIVED_COMPLETE`;
export const APPLICATIONS_DELETE_APPLICATION_START =
  "APPLICATIONS_DELETE_APPLICATION_START";
export const CONSUMER_APPLICATIONS_DELETE_APPLICATION_SUCCESS =
  "CONSUMER_APPLICATIONS_DELETE_APPLICATION_SUCCESS";
export const SUPPLIER_APPLICATIONS_DELETE_APPLICATION_SUCCESS =
  "SUPPLIER_APPLICATIONS_DELETE_APPLICATION_SUCCESS";
export const APPLICATIONS_DELETE_APPLICATION_ERROR =
  "APPLICATIONS_DELETE_APPLICATION_ERROR";

export const APPLICATIONS_REALLOCATION_ERROR =
  "APPLICATIONS_REALLOCATION_ERROR";
export const APPLICATIONS_REALLOCATION_START =
  "APPLICATIONS_REALLOCATION_START";
export const APPLICATIONS_REALLOCATION_SUCCESS =
  "APPLICATIONS_REALLOCATION_SUCCESS";

export const AUTO_DECISIONS_OPTIONS = [
  { label: "All", value: "All" },
  { label: "Waiting", value: "waiting" },
  { label: "Deferred", value: "deferred" },
  { label: "Approved", value: "approved" },
];

export const ENTITY_TYPE_OPTIONS = [
  { label: "All", value: "All" },
  { label: "Company", value: "company" },
  { label: "Partnership", value: "partnership" },
  { label: "Trust", value: "trust" },
  { label: "Sole trader", value: "sole_trader" },
  { label: "Personal", value: "personal" },
  { label: "Education", value: "education" },
  { label: "Society", value: "society" },
  { label: "Association", value: "association" },
  { label: "Club", value: "club" },
  { label: "Government", value: "government" },
  { label: "Other", value: "other" },
];

export const LEAD_ENTITY_TYPE_OPTIONS = [
  { label: "All", value: "All" },
  { label: "Company", value: "company" },
  { label: "Partnership", value: "partnership" },
  { label: "Trust", value: "trust" },
  { label: "Sole trader", value: "sole_trader" },
  { label: "Other", value: "other" },
];

export const LOADED_IN_SYSTEM_OPTIONS = [
  { label: "All", value: "All" },
  { label: "Yes", value: true },
  { label: "No", value: false },
];
