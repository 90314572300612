import GovernmentVerification from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/GovernmentVerification";
import ManualVerification from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/ManualVerification";
import getApplicableCategory from "modules/new-applications/components/application-sections/IdentificationCheck/utils/getApplicableCategory";
import React from "react";

const V2 = props => {
  const { authorisation, application } = props;
  const applicableCategory = getApplicableCategory(
    authorisation,
    application,
  );

  if (applicableCategory === 1) {
    return <ManualVerification {...props} />;
  }

  return <GovernmentVerification {...props} />;
};

export default V2;
