import get from "lodash.get";
import BaseModel from "models/BaseModel";

export default class AddonRuleModel extends BaseModel {
  get formattedRuleLabel() {
    return `#${this.serialNumber} ${this.addonVersion.name} (Version ${this.addonVersion.version})`;
  }

  get mandatoryFields() {
    return get(this.addonVersion, "config.mandatory_fields", []);
  }

  get additionalFields() {
    return get(this.addonVersion, "config.additional_fields", []);
  }

  get additionalComponents() {
    return get(this.addonVersion, "config.components", []);
  }

  get isCardLimitEnabled() {
    // TODO: Replace these by calling this.config
    return (
      get(this.addonVersion, "config.card_limit_enabled", "off") === "on" ||
      get(
        this.addonVersion,
        "data.attributes.config.card_limit_enabled",
        "off",
      ) === "on"
    );
  }

  get isCardholderSignatureRequired() {
    // TODO: Replace these by calling this.config
    return (
      get(this.addonVersion, "config.signature_required", "off") === "on" ||
      get(this.addonVersion, "data.attributes.config.signature_required") ===
        "on"
    );
  }

  get isAntiFraudAddon() {
    return this.addonModuleName.startsWith("anti_fraud");
  }

  get formattedAddonVersion() {
    return get(this.addonVersion, "data.attributes") || this.addonVersion;
  }

  get isIUFAddon() {
    return this.addonModuleName === "internal_use_fields";
  }

  get config() {
    // TODO: Fix these different approach to extract `signature_required` value
    // This is due to having v1 and v2 endpoints sending different signatures
    return get(this.addonVersion, "config") || get(this.addonVersion, "data.attributes.config") || {};
  }
}
