import Autocomplete from "@material-ui/lab/Autocomplete";
import BorderedTextField from "modules/shared/components/inputs/BorderedTextField";
import React from "react";
import styles from "modules/shared/components/inputs/css/BorderedAutocomplete.css";

export default function BorderedAutocomplete(props) {
  const { textFieldProps, withBottomMargin, ...autocompleteProps } = props;

  return (
    <Autocomplete
      getOptionLabel={option => (option || {}).label || ""}
      getOptionSelected={option => (option || {}).value}
      size="small"
      renderInput={params => {
        const textFieldParams = { ...textFieldProps, ...params };
        return (
          <BorderedTextField
            customProps={{
              withBottomMargin,
            }}
            {...textFieldParams}
            InputLabelProps={{
              ...textFieldProps.InputLabelProps,
              ...params.InputLabelProps,
            }}
          />
        );
      }}
      classes={{
        inputRoot: styles.input_root,
        listbox: styles.listbox,
        root: styles.root,
      }}
      {...autocompleteProps}
    />
  );
}

BorderedAutocomplete.defaultProps = {
  withBottomMargin: true,
};
