import React, { ReactElement } from 'react'
import Container from "modules/applications/components/credit-checks/credit-works/v2/Container";
import {
  Content,
  InnerWrapper,
  Score,
  Wrapper,
} from './styles';
import { SubHeading } from '../Container/styles';

interface Props {
  data: any,
}

function CreditScore({
  data
}: Props): ReactElement|null {
  const {
    averageScore,
    companyName,
    chanceOfDefault,
    giniCoefficient,
    medianScore,
    scoreCard,
  } = data;

  if (!scoreCard) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <SubHeading>Credit Score</SubHeading>
        <InnerWrapper>
          <Score>
            {scoreCard}
          </Score>
          <Content>
            <div>
              {companyName} fits the profile of a company with a {chanceOfDefault.toFixed(2)}%
              chance of default, receivership, or liquidation in the next 18 months.
            </div>
            <br />
            <p>
              Scores range from zero to 1000. the score is calculated from information
              valid as at the end of last month. This score was calculated with a GINI
              coefficient of {giniCoefficient}%. The average score for all companies
              is {averageScore}; the median score for all companies is {medianScore}.
            </p>
          </Content>
        </InnerWrapper>
      </Wrapper>
    </Container>
  )
}

export default CreditScore
