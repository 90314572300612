/* eslint-disable no-debugger */
import {
  MANAGE_PROFILE_ADD_ANOTHER_PERSON,
  MANAGE_PROFILE_ADD_EXCLUDED_SOURCE,
  MANAGE_PROFILE_ANZSIC_CLASSES_ERROR,
  MANAGE_PROFILE_ANZSIC_CLASSES_START,
  MANAGE_PROFILE_ANZSIC_CLASSES_SUCCESS,
  MANAGE_PROFILE_ANZSIC_DIVISIONS_ERROR,
  MANAGE_PROFILE_ANZSIC_DIVISIONS_START,
  MANAGE_PROFILE_ANZSIC_DIVISIONS_SUCCESS,
  MANAGE_PROFILE_ANZSIC_GROUPS_ERROR,
  MANAGE_PROFILE_ANZSIC_GROUPS_START,
  MANAGE_PROFILE_ANZSIC_GROUPS_SUCCESS,
  MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_ERROR,
  MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_START,
  MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_SUCCESS,
  MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_ERROR,
  MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_START,
  MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_SUCCESS,
  MANAGE_PROFILE_CLEAR_SETTINGS_BUSINESS_INFO,
  MANAGE_PROFILE_CLEAR_SETTINGS_INVOICE,
  MANAGE_PROFILE_CLEAR_SETTINGS_KEY_CONTACT,
  MANAGE_PROFILE_CLEAR_STATE,
  MANAGE_PROFILE_GET_COLOR_PALETTE_ERROR,
  MANAGE_PROFILE_GET_COLOR_PALETTE_START,
  MANAGE_PROFILE_GET_COLOR_PALETTE_SUCCESS,
  MANAGE_PROFILE_GET_EMAIL_TEMPLATE_ERROR,
  MANAGE_PROFILE_GET_EMAIL_TEMPLATE_START,
  MANAGE_PROFILE_GET_EMAIL_TEMPLATE_SUCCESS,
  MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_ERROR,
  MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_START,
  MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_SUCCESS,
  MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_ERROR,
  MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_START,
  MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_SUCCESS,
  MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_ERROR,
  MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_START,
  MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_SUCCESS,
  MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_ERROR,
  MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_START,
  MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_SUCCESS,
  MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_ERROR,
  MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_START,
  MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_SUCCESS,
  MANAGE_PROFILE_LOAD_CARD_ERROR,
  MANAGE_PROFILE_LOAD_CARD_START,
  MANAGE_PROFILE_LOAD_CARD_SUCCESS,
  MANAGE_PROFILE_LOAD_CURRENT_ENTITY_ERROR,
  MANAGE_PROFILE_LOAD_CURRENT_ENTITY_START,
  MANAGE_PROFILE_LOAD_CURRENT_ENTITY_SUCCESS,
  MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_ERROR,
  MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_START,
  MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_SUCCESS,
  MANAGE_PROFILE_LOAD_PEOPLE_ERROR,
  MANAGE_PROFILE_LOAD_PEOPLE_START,
  MANAGE_PROFILE_LOAD_PEOPLE_SUCCESS,
  MANAGE_PROFILE_LOAD_TIMEZONE_ERROR,
  MANAGE_PROFILE_LOAD_TIMEZONE_START,
  MANAGE_PROFILE_LOAD_TIMEZONE_SUCCESS,
  MANAGE_PROFILE_LOAD_USERS_ERROR,
  MANAGE_PROFILE_LOAD_USERS_START,
  MANAGE_PROFILE_LOAD_USERS_SUCCESS,
  MANAGE_PROFILE_LOAD_USER_PROFILE_SUCCESS,
  MANAGE_PROFILE_LOAD_USER_PROFILE_ERROR,
  MANAGE_PROFILE_LOAD_USER_PROFILE_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_CREDIT_CHECK_RULES,
  MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_IDENTIFICATION_CHECK_RULES,
  MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_STATE,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_AML_CHECK_LEVEL,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_INITIAL_VALUES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_PASSWORD,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_SHOW_AUTH_FIELDS,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_TYPE,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_USERNAME,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_GUARENTEES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK_LEVEL,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_MINIMUM_GUARANTEES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_MINIMUM_TRADE_REFERENCES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_AML_CHECK_PROVIDER,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_CREDIT_CHECK_RULES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_IDENTIFICATION_CHECK_RULES,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_SET_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_SUCCESS,
  MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_ERROR,
  MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_START,
  MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_SUCCESS,
  MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_ERROR,
  MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_START,
  MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_SUCCESS,
  MANAGE_PROFILE_PREVIEW_EMAIL_ERROR,
  MANAGE_PROFILE_PREVIEW_EMAIL_START,
  MANAGE_PROFILE_PREVIEW_EMAIL_SUCCESS,
  MANAGE_PROFILE_REMOVE_EXCLUDED_SOURCE,
  MANAGE_PROFILE_REMOVE_PERSON,
  MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_ERROR,
  MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_START,
  MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_SUCCESS,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_COMPANY_NAME,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_CONTACT_PHONE_NUMBER,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_PHYSICAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_POSTAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_REGION,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_STAFF_COUNT,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_START,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_STATE,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_TRADING_NAME,
  MANAGE_PROFILE_SETTINGS_BUSINESS_SET_WEBSITE_URL,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_EMAIL,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_FIRST_NAME,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_LAST_NAME,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_PHONE_NUMBER,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_START,
  MANAGE_PROFILE_SETTINGS_CONTACT_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_ERROR,
  MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_START,
  MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_SUCCESS,
  MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT,
  MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_START,
  MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_ERROR,
  MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_START,
  MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_SUCCESS,
  MANAGE_PROFILE_SETTINGS_INVOICE_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_INVOICE_SET_START,
  MANAGE_PROFILE_SETTINGS_INVOICE_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_LOGO_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_LOGO_SET_START,
  MANAGE_PROFILE_SETTINGS_LOGO_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_ERROR,
  MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_START,
  MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_SUCCESS,
  MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
  MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_ERROR,
  MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_START,
  MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
  MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_CLEAR,
  MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_ERROR,
  MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_START,
  MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_SUCCESS,
  MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_ERROR,
  MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_START,
  MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_SUCCESS,
  MANAGE_PROFILE_SETTINGS_REQUIRES_TRUST_DEED,
  MANAGE_PROFILE_SETTINGS_SELECT_PHYSICAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_SELECT_POSTAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_SET_CREDIT_WORKS_STATUS,
  MANAGE_PROFILE_SETTINGS_SET_INVOICE_DAY,
  MANAGE_PROFILE_SETTINGS_SET_INVOICE_DUE,
  MANAGE_PROFILE_SETTINGS_SET_INVOICE_FREQUENCY,
  MANAGE_PROFILE_SETTINGS_SET_MIN_APPROVERS,
  MANAGE_PROFILE_SETTINGS_SET_PARTNERSHIP_AGREEMENT_UPLOAD_VISIBLE,
  MANAGE_PROFILE_SETTINGS_SET_PHYSICAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_SET_POSTAL_ADDRESS,
  MANAGE_PROFILE_SETTINGS_SET_TRUST_DEED_UPLOAD_VISIBLE,
  MANAGE_PROFILE_SETTINGS_TEMP_LOGO,
  MANAGE_PROFILE_SET_ANZSIC_CLASS,
  MANAGE_PROFILE_SET_ANZSIC_DIVISION,
  MANAGE_PROFILE_SET_ANZSIC_GROUP,
  MANAGE_PROFILE_SET_ANZSIC_SUBDIVISION,
  MANAGE_PROFILE_SET_APPROVAL_LEVELS_ERROR,
  MANAGE_PROFILE_SET_APPROVAL_LEVELS_START,
  MANAGE_PROFILE_SET_APPROVAL_LEVELS_SUCCESS,
  MANAGE_PROFILE_SET_COLOR_PALETTE,
  MANAGE_PROFILE_SET_CUSTOM_TERMS_INTENT,
  MANAGE_PROFILE_SET_EMAIL_TEMPLATE,
  MANAGE_PROFILE_SET_EMAIL_TEMPLATE_VALUE,
  MANAGE_PROFILE_SET_EXCLUDED_SOURCES,
  MANAGE_PROFILE_SET_EXCLUDED_SOURCES_ERROR,
  MANAGE_PROFILE_SET_EXCLUDED_SOURCES_INDEX,
  MANAGE_PROFILE_SET_EXCLUDED_SOURCES_START,
  MANAGE_PROFILE_SET_EXCLUDED_SOURCES_SUCCESS,
  MANAGE_PROFILE_SET_GUARANTOR_CUSTOM_TERMS_INTENT,
  MANAGE_PROFILE_SET_GUARANTOR_TERMS_DOCUMENT,
  MANAGE_PROFILE_SET_GUARANTOR_VIEWED_TERMS,
  MANAGE_PROFILE_SET_PAPERLESS_CUSTOM_TERMS_INTENT,
  MANAGE_PROFILE_SET_PAPERLESS_TERMS_DOCUMENT,
  MANAGE_PROFILE_SET_PAPERLESS_VIEWED_TERMS,
  MANAGE_PROFILE_SET_PARTY_DETAILS,
  MANAGE_PROFILE_SET_PARTY_DETAILS_ERROR,
  MANAGE_PROFILE_SET_PARTY_DETAILS_INDEX,
  MANAGE_PROFILE_SET_PARTY_DETAILS_START,
  MANAGE_PROFILE_SET_PARTY_DETAILS_SUCCESS,
  MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_CREDIT_CHECK,
  MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_GUARANTEES,
  MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_IDENTIFICATION_CHECK,
  MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_TRADE_REFERENCE_CHECK,
  MANAGE_PROFILE_SET_QUESTION,
  MANAGE_PROFILE_SET_QUESTIONS_APPLIES_ERROR,
  MANAGE_PROFILE_SET_QUESTIONS_APPLIES_START,
  MANAGE_PROFILE_SET_QUESTIONS_APPLIES_SUCCESS,
  MANAGE_PROFILE_SET_QUESTIONS_ERROR,
  MANAGE_PROFILE_SET_QUESTIONS_START,
  MANAGE_PROFILE_SET_QUESTIONS_SUCCESS,
  MANAGE_PROFILE_SET_QUESTION_INDEX,
  MANAGE_PROFILE_SET_TERMS_DOCUMENT,
  MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_ERROR,
  MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_START,
  MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_SUCCESS,
  MANAGE_PROFILE_SET_VIEWED_TERMS,
  MANAGE_PROFILE_TERMS_DOCUMENT_SET_ERROR,
  MANAGE_PROFILE_TERMS_DOCUMENT_SET_START,
  MANAGE_PROFILE_TERMS_DOCUMENT_SET_SUCCESS,
  MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_ERROR,
  MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_MINIMUM_TRADE_REFERENCES,
  MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_START,
  MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_SUCCESS,
  MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_TRADE_REFERENCE_CHECK,
  MANAGE_PROFILE_UPDATE_COLOR_PALETTE_ERROR,
  MANAGE_PROFILE_UPDATE_COLOR_PALETTE_START,
  MANAGE_PROFILE_UPDATE_COLOR_PALETTE_SUCCESS,
  MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_ERROR,
  MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_START,
  MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_SUCCESS,
  MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_START,
  MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_SUCCESS,
  MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_ERROR,
  MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_START,
  MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_ERROR,
  MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_SUCCESS,
} from "./constants";

const manage_profile_defaults = {
  settings_billing_details_updating: false,

  settings_contact_phone_number: null,
  settings_website_url: null,
  settings_party_details_values: [],
  settings_physical_address: null,
  settings_postal_address: null,
  settings_update_loading: false,
  settings_key_contact_phone_number: null,
  settings_key_contact_first_name: null,
  settings_key_contact_last_name: null,
  settings_key_contact_email: null,
  settings_contact_update_loading: false,
  current_entity_loading: false,
  current_entity: null,
  current_user_profile: null,
  current_user_profile_loading: false,

  // current_entity_users: [],
  settings_temp_logo_src: null,
  settings_removed_people_ids: [],
  settings_business_description_altered: false,

  settings_physical_address_list: {},
  settings_postal_address_list: {},
  settings_postal_address_loading: false,
  settings_physical_address_loading: false,
  settings_physical_dpid: null,
  settings_postal_dpid: null,

  settings_minimum_approvers: null,
  settings_identification_check_levels: null,
  settings_minimum_guarantees: null,
  settings_minimum_trade_references: null,
  settings_excluded_application_sources: null,
  settings_questions: null,
  settings_questions_applies: null,

  settings_credit_limit: null,
  settings_invoice_frequency: null,
  settings_invoice_day: null,
  settings_invoice_due: null,

  settings_terms_document: null,
  settings_custom_terms: null,
  settings_terms_viewed: false,

  settings_guarantor_terms_document: null,
  settings_guarantor_custom_terms: null,
  settings_guarantor_terms_viewed: false,

  settings_terms_updating: false,
  settings_guarantor_terms_updating: false,

  settings_paperless_terms_document: null,
  settings_paperless_custom_terms: null,
  settings_paperless_terms_viewed: false,
  settings_paperless_terms_updating: false,

  business_description: null,

  settings_credit_check_type: null,

  settings_region: null,
  settings_state: null,
  settings_identification_check_type: null,

  settings_credit_check_show_auth_fields: null,
  settings_credit_check_username: null,
  settings_credit_check_password: null,
  settings_credit_check_auth_invalid: null,
  settings_credit_check_auth_invalid_detail: null,
  settings_credit_check_auth_validated: false,
  settings_credit_check_auth_loading: false,

  settings_credit_works_status: null,

  settings_selected_credit_check_rules: {},

  settings_selected_identification_check_rules: {},

  permissions_requires_credit_check: false,
  permissions_requires_identification_check: false,
  permissions_requires_guarantees: false,
  permissions_requires_trade_reference_check: false,

  held_rulesets: null,
  settings_email_template_loading: false,
  settings_email_template_updating: false,
  settings_email_template: null,
  settings_preview_email_loading: false,
  settings_preview_email: null,

  trust_deed_upload_visible: false,
  requires_trust_deed: false,
  partnership_agreement_upload_visible: false,
  settings_requires_additional_documents_loading: false,

  loading_timezones: false,
  timezone_options: [],
};

const business_description_defaults = {
  settings_anzsic_options: {
    divisions: [],
    subdivisions: [],
    groups: [],
    classes: [],
  },
};

export function manageProfileReducer(
  state = { ...manage_profile_defaults, ...business_description_defaults },
  action,
) {
  switch (action.type) {
    case MANAGE_PROFILE_SETTINGS_TEMP_LOGO:
      return {
        ...state,
        settings_temp_logo_src: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          logo: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_CONTACT_SET_PHONE_NUMBER:
      return {
        ...state,
        settings_key_contact_phone_number: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          key_contact: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_CONTACT_SET_FIRST_NAME:
      return {
        ...state,
        settings_key_contact_first_name: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          key_contact: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_CONTACT_SET_LAST_NAME:
      return {
        ...state,
        settings_key_contact_last_name: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          key_contact: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_CONTACT_SET_EMAIL:
      return {
        ...state,
        settings_key_contact_email: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          key_contact: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT:
      return {
        ...state,
        settings_credit_limit: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          credit_limit: true,
        },
      };

    case MANAGE_PROFILE_SETTINGS_SET_INVOICE_FREQUENCY:
      return {
        ...state,
        settings_invoice_frequency: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          invoice: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_SET_INVOICE_DAY:
      return {
        ...state,
        settings_invoice_day: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          invoice: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_SET_INVOICE_DUE:
      return {
        ...state,
        settings_invoice_due: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          invoice: true,
        },
      };

    case MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_START:
      return { ...state, settings_minimum_approvers_loading: true };
    case MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_ERROR:
      return {
        ...state,
        settings_minimum_approvers_loading: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          minimum_approvers: false,
        },
      };
    case MANAGE_PROFILE_SETTINGS_MIN_APPROVERS_SET_SUCCESS:
      return {
        ...state,
        settings_minimum_approvers_loading: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          minimum_approvers: false,
        },
      };

    case MANAGE_PROFILE_SETTINGS_SET_MIN_APPROVERS:
      return {
        ...state,
        settings_minimum_approvers: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          minimum_approvers: true,
        },
      };

    case MANAGE_PROFILE_SETTINGS_INVOICE_SET_START:
      return { ...state, settings_invoice_loading: true };
    case MANAGE_PROFILE_SETTINGS_INVOICE_SET_ERROR:
      return {
        ...state,
        settings_invoice_loading: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          invoice: false,
        },
      };
    case MANAGE_PROFILE_SETTINGS_INVOICE_SET_SUCCESS:
      return {
        ...state,
        settings_invoice_loading: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          invoice: false,
        },
      };

    case MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_START:
      return { ...state, current_business_description_loading: true };
    case MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_ERROR:
      return { ...state, current_business_description_loading: false };
    case MANAGE_PROFILE_LOAD_BUSINESS_DESCRIPTION_SUCCESS:
      return {
        ...state,
        current_business_description_loading: false,
        settings_business_description: action.payload,
      };

    case MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_START:
      return { ...state, current_approval_in_progress_counting: true };
    case MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_SUCCESS:
      return {
        ...state,
        current_approval_in_progress_counting: false,
        applications_approval_in_progress_count: action.payload,
      };
    case MANAGE_PROFILE_GET_IN_PROGRESS_APPLICATION_COUNT_ERROR:
      return { ...state, current_approval_in_progress_counting: false };

    case MANAGE_PROFILE_LOAD_CURRENT_ENTITY_START:
      return { ...state, current_entity_loading: true };
    case MANAGE_PROFILE_LOAD_CURRENT_ENTITY_ERROR:
      return { ...state, current_entity_loading: false };
    case MANAGE_PROFILE_LOAD_CURRENT_ENTITY_SUCCESS:
      return {
        ...state,
        ...manage_profile_defaults,
        current_entity_loading: false,
        current_entity: action.payload.data,
        entity_includes: action.payload.included || [],
        settings_credit_check_type: (action.payload.data.attributes || {})
          .credit_check_type,
        settings_credit_check_username: (action.payload.data.attributes || {})
          .credit_check_username,
        settings_credit_check_password: (action.payload.data.attributes || {})
          .credit_check_password,
        settings_selected_credit_check_rules: (
          action.payload.data.attributes || {}
        ).selected_credit_check_rule_ids,
        settings_selected_identification_check_rules: (
          action.payload.data.attributes || {}
        ).selected_identification_check_rules,
        settings_credit_works_status: (action.payload.data.attributes || {})
          .credit_works_status,
        permissions_requires_credit_check:
          (action.payload.data.attributes || {}).requires_credit_check || false,
        permissions_requires_identification_check:
          (action.payload.data.attributes || {})
            .requires_applicant_identification_check ||
          (action.payload.data.attributes || {})
            .requires_guarantees_identification_check ||
          false,
        permissions_requires_guarantees:
          (action.payload.data.attributes || {}).requires_guarantees || false,
        permissions_requires_trade_reference_check:
          ((action.payload.data.attributes || {}).minimum_trade_references ||
            0) > 0,
        requires_trust_deed: (action.payload.data.attributes || {})
          .requires_trust_deed,
        trust_deed_upload_visible: (action.payload.data.attributes || {})
          .trust_deed_upload_visible,
        partnership_agreement_upload_visible: (
          action.payload.data.attributes || {}
        ).partnership_agreement_upload_visible,
        current_user_profile: state.current_user_profile,
      };

    case MANAGE_PROFILE_LOAD_PEOPLE_START:
      return { ...state, current_entity_people_loading: true };
    case MANAGE_PROFILE_LOAD_PEOPLE_ERROR:
      return { ...state, current_entity_people_loading: false };
    case MANAGE_PROFILE_LOAD_PEOPLE_SUCCESS:
      return {
        ...state,
        current_entity_people_loading: false,
        current_entity_people: action.payload,
      };

    case MANAGE_PROFILE_LOAD_USERS_START:
      return { ...state, current_entity_users_loading: true };
    case MANAGE_PROFILE_LOAD_USERS_ERROR:
      return { ...state, current_entity_users_loading: false };
    case MANAGE_PROFILE_LOAD_USERS_SUCCESS:
      return {
        ...state,
        current_entity_users_loading: false,
        current_entity_users: action.payload,
      };

    case MANAGE_PROFILE_LOAD_USER_PROFILE_START:
      return { ...state, current_user_profile_loading: true }
    case MANAGE_PROFILE_LOAD_USER_PROFILE_ERROR:
      return {
        ...state, current_user_profile_loading: false,
        current_user_profile: {
          attributes: {
            noData: true
          },
        },
      }
    case MANAGE_PROFILE_LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        current_user_profile_loading: false,
        current_user_profile: action.payload,
      };

    case MANAGE_PROFILE_LOAD_CARD_START:
      return { ...state, current_entity_card_loading: true };
    case MANAGE_PROFILE_LOAD_CARD_ERROR:
      return { ...state, current_entity_card_loading: false };
    case MANAGE_PROFILE_LOAD_CARD_SUCCESS:
      return {
        ...state,
        current_entity_card_loading: false,
        current_entity_card: action.payload,
      };

    case MANAGE_PROFILE_SET_PARTY_DETAILS:
      return {
        ...state,
        settings_party_details_values: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          party: true,
        },
      };

    case MANAGE_PROFILE_SET_PARTY_DETAILS_START:
      return { ...state, settings_updating_party_details: true };
    case MANAGE_PROFILE_SET_PARTY_DETAILS_ERROR:
      return {
        ...state,
        settings_updating_party_details: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          party: false,
        },
      };
    case MANAGE_PROFILE_SET_PARTY_DETAILS_SUCCESS:
      return {
        ...state,
        settings_updating_party_details: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          party: false,
        },
      };

    case MANAGE_PROFILE_ADD_ANOTHER_PERSON:
      return {
        ...state,
        settings_party_details_values: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          party: true,
        },
      };

    case MANAGE_PROFILE_REMOVE_PERSON:
      return {
        ...state,
        settings_party_details_values: action.payload.form_values,
        settings_removed_people_ids: action.payload.removed_ids,
        settings_section_edited: {
          ...state.settings_section_edited,
          party: true,
        },
      };

    case MANAGE_PROFILE_SET_PARTY_DETAILS_INDEX:
      return {
        ...state,
        settings_party_details_container_index: action.payload,
      };

    case MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_START:
      return { ...state, settings_credit_limit_loading: true };
    case MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_ERROR:
      return {
        ...state,
        settings_credit_limit_loading: false,
        held_rulesets: null,
        settings_section_edited: {
          ...state.settings_section_edited,
          credit_limit: false,
        },
      };
    case MANAGE_PROFILE_SETTINGS_CREDIT_LIMIT_SET_SUCCESS:
      return {
        ...state,
        settings_credit_limit_loading: false,
        held_rulesets: action.payload.held_rulesets || state.held_rulesets,
        settings_section_edited: {
          ...state.settings_section_edited,
          credit_limit: false,
        },
      };

    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_COMPANY_NAME:
      return {
        ...state,
        settings_company_name: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_TRADING_NAME:
      return {
        ...state,
        settings_trading_name: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_CONTACT_PHONE_NUMBER:
      return {
        ...state,
        settings_contact_phone_number: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_WEBSITE_URL:
      return {
        ...state,
        settings_website_url: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_STAFF_COUNT:
      return {
        ...state,
        settings_staff_count: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_PHYSICAL_ADDRESS:
      return {
        ...state,
        settings_physical_address: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_POSTAL_ADDRESS:
      return {
        ...state,
        settings_postal_address: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_REGION:
      return {
        ...state,
        settings_region: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: true,
        },
      };
    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_STATE:
      return {
        ...state,
        settings_state: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: true,
        },
      };

    case MANAGE_PROFILE_SETTINGS_LOGO_SET_SUCCESS:
      return {
        ...state,
        settings_logo_update_loading: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          logo: false,
        },
      };
    case MANAGE_PROFILE_SETTINGS_LOGO_SET_START:
      return { ...state, settings_logo_update_loading: true };
    case MANAGE_PROFILE_SETTINGS_LOGO_SET_ERROR:
      return {
        ...state,
        settings_logo_update_loading: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          logo: false,
        },
      };

    case MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_START:
      return { ...state, settings_updating_anzsic: true };
    case MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_ERROR:
      return { ...state, settings_updating_anzsic: false };
    case MANAGE_PROFILE_BUSINESS_DESCRIPTION_SET_SUCCESS:
      return {
        ...state,
        settings_updating_anzsic: false,
        settings_business_description_altered: false,
      };

    case MANAGE_PROFILE_SETTINGS_CONTACT_SET_SUCCESS:
      return {
        ...state,
        settings_contact_update_loading: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          key_contact: false,
        },
      };
    case MANAGE_PROFILE_SETTINGS_CONTACT_SET_START:
      return { ...state, settings_contact_update_loading: true };
    case MANAGE_PROFILE_SETTINGS_CONTACT_SET_ERROR:
      return {
        ...state,
        settings_contact_update_loading: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          key_contact: false,
        },
      };

    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_SUCCESS:
      return {
        ...state,
        settings_update_loading: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: false,
        },
      };
    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_START:
      return { ...state, settings_update_loading: true };
    case MANAGE_PROFILE_SETTINGS_BUSINESS_SET_ERROR:
      return {
        ...state,
        settings_update_loading: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: false,
        },
      };

    case MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_CLEAR:
      return {
        ...state,
        settings_postal_address_list: {},
        settings_postal_dpid: null,
      };
    case MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_CLEAR:
      return {
        ...state,
        settings_physical_address_list: {},
        settings_physical_dpid: null,
      };

    case MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_START:
      return {
        ...state,
        settings_postal_address_loading: true,
        settings_postal_dpid: null,
      };
    case MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_START:
      return {
        ...state,
        settings_physical_address_loading: true,
        settings_physical_dpid: null,
      };

    case MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_ERROR:
      return { ...state, settings_postal_address_loading: false };
    case MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_ERROR:
      return { ...state, settings_physical_address_loading: false };

    case MANAGE_PROFILE_SETTINGS_POSTAL_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        settings_postal_address_loading: false,
        settings_postal_address_list: action.payload,
      };
    case MANAGE_PROFILE_SETTINGS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        settings_physical_address_loading: false,
        settings_physical_address_list: action.payload,
      };

    case MANAGE_PROFILE_SETTINGS_SELECT_POSTAL_ADDRESS:
      return {
        ...state,
        settings_postal_dpid: action.payload,
        settings_postal_address_loading: false,
        settings_postal_address_list: {},
        settings_postal_address:
          state.settings_postal_address_list[action.payload],
      };
    case MANAGE_PROFILE_SETTINGS_SELECT_PHYSICAL_ADDRESS:
      return {
        ...state,
        settings_physical_dpid: action.payload,
        settings_physical_address_loading: false,
        settings_physical_address_list: {},
        settings_physical_address:
          state.settings_physical_address_list[action.payload],
      };

    case MANAGE_PROFILE_SETTINGS_SET_PHYSICAL_ADDRESS:
      return {
        ...state,
        settings_postal_dpid: null,
        settings_physical_address: action.payload,
      };

    case MANAGE_PROFILE_SETTINGS_SET_POSTAL_ADDRESS:
      return {
        ...state,
        settings_postal_dpid: null,
        settings_postal_address: action.payload,
      };

    case MANAGE_PROFILE_SET_TERMS_DOCUMENT:
      return {
        ...state,
        settings_terms_document: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          terms: true,
        },
      };

    case MANAGE_PROFILE_SET_CUSTOM_TERMS_INTENT:
      return {
        ...state,
        settings_custom_terms: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          terms: true,
        },
      };

    case MANAGE_PROFILE_SET_VIEWED_TERMS:
      return { ...state, settings_terms_viewed: action.payload };

    case MANAGE_PROFILE_TERMS_DOCUMENT_SET_START:
      return { ...state, settings_terms_updating: true };
    case MANAGE_PROFILE_TERMS_DOCUMENT_SET_ERROR:
      return {
        ...state,
        settings_terms_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          terms: false,
        },
      };
    case MANAGE_PROFILE_TERMS_DOCUMENT_SET_SUCCESS:
      return {
        ...state,
        settings_terms_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          terms: false,
        },
      };

    case MANAGE_PROFILE_SET_GUARANTOR_TERMS_DOCUMENT:
      return {
        ...state,
        settings_guarantor_terms_document: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          guarantor_terms: true,
        },
      };
    case MANAGE_PROFILE_SET_GUARANTOR_CUSTOM_TERMS_INTENT:
      return {
        ...state,
        settings_guarantor_custom_terms: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          guarantor_terms: true,
        },
      };
    case MANAGE_PROFILE_SET_GUARANTOR_VIEWED_TERMS:
      return { ...state, settings_guarantor_terms_viewed: action.payload };

    case MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_START:
      return { ...state, settings_guarantor_terms_updating: true };
    case MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_ERROR:
      return {
        ...state,
        settings_guarantor_terms_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          guarantor_terms: false,
        },
      };
    case MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_SUCCESS:
      return {
        ...state,
        settings_guarantor_terms_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          guarantor_terms: false,
        },
      };

    case MANAGE_PROFILE_SET_QUESTION:
      var questions = action.payload.questions.slice();
      questions[action.payload.index] = action.payload.value;
      return {
        ...state,
        settings_questions: questions,
        settings_section_edited: {
          ...state.settings_section_edited,
          questions: true,
        },
      };

    case MANAGE_PROFILE_SET_QUESTION_INDEX:
      return { ...state, settings_questions_container_index: action.payload };

    case MANAGE_PROFILE_SET_QUESTIONS_START:
      return { ...state, settings_questions_updating: true };
    case MANAGE_PROFILE_SET_QUESTIONS_ERROR:
      return {
        ...state,
        settings_questions_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          questions: false,
        },
      };
    case MANAGE_PROFILE_SET_QUESTIONS_SUCCESS:
      return {
        ...state,
        settings_questions_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          questions: false,
        },
      };

    case MANAGE_PROFILE_SET_QUESTIONS_APPLIES_START:
      return {
        ...state,
        settings_questions_updating: true,
        settings_questions_applies: action.payload.additional_questions_applies,
      };
    case MANAGE_PROFILE_SET_QUESTIONS_APPLIES_ERROR:
      return { ...state, settings_questions_updating: false };
    case MANAGE_PROFILE_SET_QUESTIONS_APPLIES_SUCCESS:
      return { ...state, settings_questions_updating: false };

    case MANAGE_PROFILE_SET_EXCLUDED_SOURCES:
      var excluded_application_sources = action.payload.excluded_application_sources.slice();
      excluded_application_sources[action.payload.index] = action.payload.value;
      return {
        ...state,
        settings_excluded_application_sources: excluded_application_sources,
        settings_section_edited: {
          ...state.settings_section_edited,
          excluded_application_sources: true,
        },
      };

    case MANAGE_PROFILE_SET_EXCLUDED_SOURCES_INDEX:
      return {
        ...state,
        settings_excluded_application_sources_container_index: action.payload,
      };

    case MANAGE_PROFILE_REMOVE_EXCLUDED_SOURCE:
      var excluded_application_sources = action.payload.excluded_application_sources
        .slice(0, action.payload.index)
        .concat(
          action.payload.excluded_application_sources.slice(
            action.payload.index + 1,
          ),
        ); //removes excluded_source and rejoins array
      return {
        ...state,
        settings_excluded_application_sources: excluded_application_sources,
        settings_section_edited: {
          ...state.settings_section_edited,
          excluded_application_sources: true,
        },
      };

    case MANAGE_PROFILE_ADD_EXCLUDED_SOURCE:
      var excluded_application_sources =
        action.payload.excluded_application_sources;
      excluded_application_sources.push("");
      return {
        ...state,
        settings_excluded_application_sources: excluded_application_sources,
        settings_excluded_application_sources_container_index:
          excluded_application_sources.length - 1,
        settings_section_edited: {
          ...state.settings_section_edited,
          excluded_application_sources: true,
        },
      };

    case MANAGE_PROFILE_SET_EXCLUDED_SOURCES_START:
      return { ...state, settings_excluded_application_sources_updating: true };
    case MANAGE_PROFILE_SET_EXCLUDED_SOURCES_ERROR:
      return {
        ...state,
        settings_excluded_application_sources_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          excluded_application_sources: false,
        },
      };
    case MANAGE_PROFILE_SET_EXCLUDED_SOURCES_SUCCESS:
      return {
        ...state,
        settings_excluded_application_sources_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          excluded_application_sources: false,
        },
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK:
      return {
        ...state,
        settings_requires_identification_check: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          mandatory_checks: true,
        },
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_IDENTIFICATION_CHECK_LEVEL:
      return {
        ...state,
        settings_identification_check_levels: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          mandatory_checks: true,
        },
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_INITIAL_VALUES:
      return {
        ...state,
        settings_credit_check_type:
          state.current_entity.attributes.credit_check_type,
        settings_credit_check_username:
          state.current_entity.attributes.credit_check_username,
        settings_credit_check_password:
          state.current_entity.attributes.credit_check_password,
        settings_credit_works_status:
          state.current_entity.attributes.credit_works_status,
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK:
      return {
        ...state,
        settings_requires_credit_check: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          mandatory_checks: true,
        },
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_TYPE:
      return {
        ...state,
        settings_credit_check_type: action.payload,
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_USERNAME:
      return {
        ...state,
        settings_credit_check_username: action.payload,
        settings_credit_check_auth_validated: false,
        settings_requires_credit_check: false,
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_PASSWORD:
      return {
        ...state,
        settings_credit_check_password: action.payload,
        settings_credit_check_auth_validated: false,
        settings_requires_credit_check: false,
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_CREDIT_CHECK_SHOW_AUTH_FIELDS:
      return {
        ...state,
        settings_credit_check_show_auth_fields: action.payload,
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_START:
      return {
        ...state,
        settings_credit_check_auth_loading: true,
        settings_requires_credit_check: false,
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_SUCCESS:
      return {
        ...state,
        settings_credit_check_auth_invalid: false,
        settings_credit_check_auth_invalid_detail: null,
        settings_credit_check_auth_validated: true,
        settings_credit_check_show_auth_fields: false,
        settings_credit_check_auth_loading: false,
        settings_requires_credit_check: true,
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_VALIDATE_CREDIT_CHECK_CREDENTIALS_ERROR:
      return {
        ...state,
        settings_credit_check_auth_invalid: true,
        settings_credit_check_auth_invalid_detail: action.payload,
        settings_credit_check_auth_validated: false,
        settings_credit_check_auth_loading: false,
        settings_requires_credit_check: false,
        settings_requires_credit_check: false,
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_GUARENTEES:
      return {
        ...state,
        settings_requires_guarantees: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          mandatory_checks: true,
        },
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_MINIMUM_GUARANTEES:
      return {
        ...state,
        settings_minimum_guarantees: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          mandatory_checks: true,
        },
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_MINIMUM_TRADE_REFERENCES:
      return {
        ...state,
        settings_minimum_trade_references: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          mandatory_checks: true,
        },
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_START:
      return { ...state, settings_mandatory_checks_updating: true };
    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_ERROR:
      return {
        ...state,
        settings_mandatory_checks_updating: false,
        held_rulesets: null,
        settings_section_edited: {
          ...state.settings_section_edited,
          mandatory_checks: false,
        },
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_SUCCESS:
      return {
        ...state,
        settings_mandatory_checks_updating: false,
        held_rulesets: action.payload.held_rulesets,
        settings_section_edited: {
          ...state.settings_section_edited,
          mandatory_checks: false,
        },
        current_entity: {
          ...state.current_entity,
          attributes: {
            ...state.current_entity.attributes,
            ...action.payload.attributes,
          },
        },
      };

    case MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_MINIMUM_TRADE_REFERENCES:
      return { ...state, settings_minimum_trade_references: action.payload };
    case MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_TRADE_REFERENCE_CHECK:
      return {
        ...state,
        settings_requires_trade_reference_check: action.payload,
      };

    case MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_START:
      return { ...state, settings_trade_reference_checks_updating: true };
    case MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_ERROR:
      return {
        ...state,
        settings_trade_reference_checks_updating: false,
        held_rulesets: null,
      };
    case MANAGE_PROFILE_TRADE_REFERENCE_CHECKS_SET_SUCCESS:
      return {
        ...state,
        settings_trade_reference_checks_updating: false,
        held_rulesets: action.payload.held_rulesets,
      };

    case MANAGE_PROFILE_ANZSIC_CLASSES_START:
      return { ...state, settings_loading_anzsic_classes: true };
    case MANAGE_PROFILE_ANZSIC_CLASSES_ERROR:
      return { ...state, settings_loading_anzsic_classes: false };

    case MANAGE_PROFILE_ANZSIC_GROUPS_START:
      return { ...state, settings_loading_anzsic_groups: true };
    case MANAGE_PROFILE_ANZSIC_GROUPS_ERROR:
      return { ...state, settings_loading_anzsic_groups: false };

    case MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_START:
      return { ...state, settings_loading_anzsic_subdivisions: true };
    case MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_ERROR:
      return { ...state, settings_loading_anzsic_subdivisions: false };

    case MANAGE_PROFILE_ANZSIC_DIVISIONS_START:
      return { ...state, settings_loading_anzsic_divisions: true };
    case MANAGE_PROFILE_ANZSIC_DIVISIONS_ERROR:
      return { ...state, settings_loading_anzsic_divisions: false };

    case MANAGE_PROFILE_ANZSIC_DIVISIONS_SUCCESS:
      return {
        ...state,
        settings_anzsic_options: {
          ...state.settings_anzsic_options,
          divisions: action.payload,
        },
        settings_loading_anzsic_divisions: false,
      };

    case MANAGE_PROFILE_SET_ANZSIC_DIVISION:
      return {
        ...state,
        settings_business_description: {
          division: action.payload,
          subdivision: null,
          group: null,
          class: null,
        },
        settings_anzsic_options: {
          ...state.settings_anzsic_options,
          subdivisions: [],
          groups: [],
          classes: [],
        },
        settings_business_description_altered: true,
      };

    case MANAGE_PROFILE_ANZSIC_SUBDIVISIONS_SUCCESS:
      return {
        ...state,
        settings_anzsic_options: {
          ...state.settings_anzsic_options,
          subdivisions: action.payload,
        },
        settings_loading_anzsic_subdivisions: false,
      };

    case MANAGE_PROFILE_SET_ANZSIC_SUBDIVISION:
      return {
        ...state,
        settings_business_description: {
          ...state.settings_business_description,
          subdivision: action.payload,
          group: null,
          class: null,
        },
        settings_anzsic_options: {
          ...state.settings_anzsic_options,
          groups: [],
          classes: [],
        },
        settings_business_description_altered: true,
      };

    case MANAGE_PROFILE_ANZSIC_GROUPS_SUCCESS:
      return {
        ...state,
        settings_anzsic_options: {
          ...state.settings_anzsic_options,
          groups: action.payload,
        },
        settings_loading_anzsic_groups: false,
      };

    case MANAGE_PROFILE_SET_ANZSIC_GROUP:
      return {
        ...state,
        settings_business_description: {
          ...state.settings_business_description,
          group: action.payload,
          class: null,
        },
        settings_anzsic_options: {
          ...state.settings_anzsic_options,
          classes: [],
        },
        settings_business_description_altered: true,
      };

    case MANAGE_PROFILE_ANZSIC_CLASSES_SUCCESS:
      return {
        ...state,
        settings_anzsic_options: {
          ...state.settings_anzsic_options,
          classes: action.payload,
        },
        settings_loading_anzsic_classes: false,
      };

    case MANAGE_PROFILE_SET_ANZSIC_CLASS:
      return {
        ...state,
        settings_business_description: {
          ...state.settings_business_description,
          class: action.payload,
        },
        settings_business_description_altered: true,
      };

    case MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_START:
      return {
        ...state,
        settings_credit_check_lookup_loading: true,
      };

    case MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_SUCCESS:
      return {
        ...state,
        settings_credit_check_lookup_loading: false,
        settings_credit_check_lookup: action.payload,
      };

    case MANAGE_PROFILE_SETTINGS_CREDIT_CHECK_LOOKUP_ERROR:
      return {
        ...state,
        settings_credit_check_lookup_loading: false,
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_CREDIT_CHECK_RULES:
      return {
        ...state,
        settings_selected_credit_check_rules: action.payload,
      };

    case MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_START:
      return {
        ...state,
        settings_identification_check_lookup_loading: true,
      };

    case MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_SUCCESS:
      return {
        ...state,
        settings_identification_check_lookup_loading: false,
        settings_identification_check_lookup: action.payload,
      };

    case MANAGE_PROFILE_SETTINGS_IDENTIFICATION_CHECK_LOOKUP_ERROR:
      return {
        ...state,
        settings_identification_check_lookup_loading: false,
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_IDENTIFICATION_CHECK_RULES:
      return {
        ...state,
        settings_selected_identification_check_rules:
          action.payload.newSelectedIdentificationCheckRules,
        settings_requires_identification_check:
          action.payload.requiresIdentificationCheck,
      };

    case MANAGE_PROFILE_SETTINGS_SET_CREDIT_WORKS_STATUS:
      return {
        ...state,
        settings_credit_works_status: action.payload,
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_STATE:
      return {
        ...manage_profile_defaults,
      };
    case MANAGE_PROFILE_CLEAR_STATE:
      return { ...manage_profile_defaults };

    case MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_CREDIT_CHECK_RULES:
      return {
        ...state,
        settings_selected_credit_check_rules: {},
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_SELECTED_IDENTIFICATION_CHECK_RULES:
      return {
        ...state,
        settings_selected_identification_check_rules: {},
        settings_requires_identification_check: false,
      };

    case MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_CREDIT_CHECK:
      return {
        ...state,
        permissions_requires_credit_check: action.payload,
      };
    case MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_IDENTIFICATION_CHECK:
      return {
        ...state,
        permissions_requires_identification_check: action.payload,
      };
    case MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_GUARANTEES:
      return {
        ...state,
        permissions_requires_guarantees: action.payload,
      };
    case MANAGE_PROFILE_SET_PERMISSIONS_REQUIRES_TRADE_REFERENCE_CHECK:
      return {
        ...state,
        permissions_requires_trade_reference_check: action.payload,
      };
    case MANAGE_PROFILE_CLEAR_SETTINGS_BUSINESS_INFO:
      return {
        ...state,
        settings_trading_name: null,
        settings_company_name: null,
        settings_contact_phone_number: null,
        settings_website_url: null,
        settings_section_edited: {
          ...state.settings_section_edited,
          business_info: false,
        },
      };
    case MANAGE_PROFILE_CLEAR_SETTINGS_KEY_CONTACT:
      return {
        ...state,
        settings_key_contact_phone_number: null,
        settings_key_contact_first_name: null,
        settings_key_contact_last_name: null,
        settings_key_contact_email: null,
        settings_section_edited: {
          ...state.settings_section_edited,
          key_contact: false,
        },
      };
    case MANAGE_PROFILE_CLEAR_SETTINGS_INVOICE:
      return {
        ...state,
        settings_invoice_frequency: null,
        settings_invoice_day: null,
        settings_invoice_due: null,
        settings_section_edited: {
          ...state.settings_section_edited,
          invoice: false,
        },
      };

    case MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_START:
      return { ...state, current_entity_approval_level_loading: true };

    case MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_SUCCESS:
      return {
        ...state,
        current_entity_approval_level_loading: false,
        current_entity_approval_levels:
          action.payload.current_entity_approval_levels,
        current_entity: {
          ...state.current_entity,
          attributes: {
            ...state.current_entity.attributes,
            broker_config: action.payload.broker_config,
            enable_broker: action.payload.enable_broker,
            reviewer_enabled: action.payload.reviewer_enabled,
          },
        },
      };
    case MANAGE_PROFILE_LOAD_APPROVAL_LEVELS_ERROR:
      return { ...state, current_entity_approval_level_loading: false };

    case MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_START:
      return {
        ...state,
        current_entity_approval_hierarchy_history_loading: true,
      };
    case MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_SUCCESS:
      return {
        ...state,
        current_entity_approval_hierarchy_history_loading: false,
        current_entity_approval_hierarchy_history: action.payload,
      };
    case MANAGE_PROFILE_LOAD_APPROVAL_HIERARCHY_HISTORY_ERROR:
      return {
        ...state,
        current_entity_approval_hierarchy_history_loading: false,
      };

    case MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_START:
      return { ...state, history_approval_levels_loading: true };
    case MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_SUCCESS:
      return {
        ...state,
        history_approval_levels_loading: false,
        history_approval_levels: action.payload,
      };
    case MANAGE_PROFILE_LOAD_HISTORY_APPROVAL_LEVELS_ERROR:
      return { ...state, history_approval_levels_loading: false };

    case MANAGE_PROFILE_SET_APPROVAL_LEVELS_START:
      return { ...state, setting_entity_approval_levels: true };
    case MANAGE_PROFILE_SET_APPROVAL_LEVELS_SUCCESS:
      return { ...state, setting_entity_approval_levels: false };
    case MANAGE_PROFILE_SET_APPROVAL_LEVELS_ERROR:
      return { ...state, setting_entity_approval_levels: false };

    case MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_START:
      return { ...state, setting_user_approval_level: true };
    case MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_SUCCESS:
      return { ...state, setting_user_approval_level: false };
    case MANAGE_PROFILE_SET_USERS_APPROVAL_LEVEL_ERROR:
      return { ...state, setting_user_approval_level: false };

    case MANAGE_PROFILE_SET_EMAIL_TEMPLATE:
      return { ...state, settings_email_template: action.payload };
    case MANAGE_PROFILE_SET_EMAIL_TEMPLATE_VALUE:
      return {
        ...state,
        settings_email_template: {
          ...state.settings_email_template,
          attributes: {
            ...state.settings_email_template.attributes,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case MANAGE_PROFILE_GET_EMAIL_TEMPLATE_START:
      return { ...state, settings_email_template_loading: true };
    case MANAGE_PROFILE_GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        settings_email_template_loading: false,
        settings_email_template: {
          ...state.settings_email_template,
          attributes: action.payload,
        },
      };
    case MANAGE_PROFILE_GET_EMAIL_TEMPLATE_ERROR:
      return { ...state, settings_email_template_loading: false };
    case MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_START:
      return { ...state, settings_email_template_updating: true };
    case MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_SUCCESS:
      return { ...state, settings_email_template_updating: false };
    case MANAGE_PROFILE_UPDATE_EMAIL_TEMPLATE_ERROR:
      return { ...state, settings_email_template_updating: false };

    case MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_START:
      return { ...state, settings_billing_details_updating: true };
    case MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_ERROR:
      return { ...state, settings_billing_details_updating: false };
    case MANAGE_PROFILE_SETTINGS_BILLING_DETAILS_SUCCESS:
      return { ...state, settings_billing_details_updating: false };

    // color palette
    case MANAGE_PROFILE_GET_COLOR_PALETTE_ERROR:
      return { ...state, settings_color_palette_loading: false };
    case MANAGE_PROFILE_GET_COLOR_PALETTE_START:
      return { ...state, settings_color_palette_loading: true };
    case MANAGE_PROFILE_GET_COLOR_PALETTE_SUCCESS:
      return {
        ...state,
        settings_color_palette_loading: false,
        settings_color_palette: {
          ...state.settings_color_palette,
          ...action.payload,
        },
      };
    case MANAGE_PROFILE_UPDATE_COLOR_PALETTE_START:
      return { ...state, settings_color_palette_updating: true };
    case MANAGE_PROFILE_UPDATE_COLOR_PALETTE_SUCCESS:
      return {
        ...state,
        settings_color_palette: {
          ...state.settings_color_palette,
          ...action.payload,
        },
      };
    case MANAGE_PROFILE_UPDATE_COLOR_PALETTE_ERROR:
      return { ...state, settings_color_palette_updating: false };
    case MANAGE_PROFILE_SET_COLOR_PALETTE:
      return {
        ...state,
        settings_color_palette: {
          ...state.settings_color_palette,
          ...action.payload,
        },
      };

    case MANAGE_PROFILE_PREVIEW_EMAIL_START:
      return {
        ...state,
        settings_preview_email_loading: true,
        settings_preview_email: null,
      };
    case MANAGE_PROFILE_PREVIEW_EMAIL_SUCCESS:
      return {
        ...state,
        settings_preview_email_loading: false,
        settings_preview_email: action.payload,
      };
    case MANAGE_PROFILE_PREVIEW_EMAIL_ERROR:
      return { ...state, settings_preview_email_loading: false };

    /**
     * AML States
     */
    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_SELECTED_AML_CHECK_PROVIDER:
      return {
        ...state,
        settingsAmlCheckRules: {
          ...state.settingsAmlCheckRules,
          selectedProviders: action.payload.selectedProviders,
        },
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_SET_AML_CHECK_LEVEL:
      return {
        ...state,
        settingsAmlCheckRules: {
          ...state.settingsAmlCheckRules,
          selectedLevels: action.payload,
        },
      };

    case MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_START:
      return { ...state, settingsAmlCheckLookupLoading: true };
    case MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_ERROR:
      return { ...state, settingsAmlCheckLookupLoading: false };
    case MANAGE_PROFILE_SETTINGS_AML_CHECK_LOOKUP_SUCCESS:
      return {
        ...state,
        settingsAmlCheckLookup: action.payload,
        settingsAmlCheckLookupLoading: false,
      };
    case MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_ERROR:
      return { ...state, settings_mandatory_checks_updating: false };
    case MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_START:
      return { ...state, settings_mandatory_checks_updating: true };
    case MANAGE_PROFILE_MANDATORY_CHECKS_SUBMIT_AML_CHECK_SUCCESS:
      return {
        ...state,
        settings_mandatory_checks_updating: false,
        settingsAmlCheckRules: {
          ...state.settingsAmlCheckRules,
          addonConfigId: action.payload.attributes.addon_config_id,
          selectedProviders: {
            AU: action.payload.attributes.config["AU"],
            NZ: action.payload.attributes.config["NZ"],
          },
          selectedLevels: action.payload.attributes.config.person_to_check,
        },
      };

    case MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_ERROR:
      return { ...state, settingsAmlCheckRulesLoading: false };
    case MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_START:
      return { ...state, settingsAmlCheckRulesLoading: true };
    case MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_AML_CHECK_SUCCESS:
      // The settingsAmlCheckRules needs to be initialised with empty object
      // because a default value cannot be assigned before hand as there is
      // a reducer that resets the values to default removing all the
      // values in this state
      return {
        ...state,
        settingsAmlCheckRulesLoading: false,
        settingsAmlCheckRules: {
          ...state.settingsAmlCheckRules,
          addonConfigActive: action.payload.addonConfigActive,
          addonConfigId: action.payload.addonConfigId,
          selectedProviders: {
            ...((state.settingsAmlCheckRules || {}).selectedProviders || {}),
            ...action.payload.selectedProviders,
          },
          selectedLevels: action.payload.personToCheck,
        },
      };

    // AntiFraud
    case MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_ERROR:
      return { ...state, settingsAntiFraudLoading: false };
    case MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_START:
      return { ...state, settingsAntiFraudLoading: true };
    case MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_ANTI_FRAUD_SUCCESS:
      return {
        ...state,
        settingsAntiFraud: action.payload,
        settingsAntiFraudLoading: false,
      };

    // AntiFraud
    case MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_ERROR:
      return { ...state, settingsCustomerChecklistLoading: false };
    case MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_START:
      return { ...state, settingsCustomerChecklistLoading: true };
    case MANAGE_PROFILE_LOAD_CUSTOMER_CHECKLIST_SUCCESS:
      return {
        ...state,
        settingsCustomerChecklist: action.payload,
        settingsCustomerChecklistLoading: false,
      };

        // Cards
    case MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_ERROR:
      return { ...state, settingsCardsAddonLoading: false };
    case MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_START:
      return { ...state, settingsCardsAddonLoading: true };
    case MANAGE_PROFILE_MANDATORY_CHECKS_LOAD_CARDS_ADDON_SUCCESS:
      return {
        ...state,
        settingsCardsAddonLoading: false,
        settingsCardsAddons: action.payload,
      };
    case MANAGE_PROFILE_SET_GUARANTOR_TERMS_DOCUMENT:
      return {
        ...state,
        settings_guarantor_terms_document: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          guarantor_terms: true,
        },
      };
    case MANAGE_PROFILE_SET_GUARANTOR_CUSTOM_TERMS_INTENT:
      return {
        ...state,
        settings_guarantor_custom_terms: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          guarantor_terms: true,
        },
      };
    case MANAGE_PROFILE_SET_GUARANTOR_VIEWED_TERMS:
      return { ...state, settings_guarantor_terms_viewed: action.payload };

    case MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_START:
      return { ...state, settings_guarantor_terms_updating: true };
    case MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_ERROR:
      return {
        ...state,
        settings_guarantor_terms_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          guarantor_terms: false,
        },
      };
    case MANAGE_PROFILE_GUARANTOR_TERMS_DOCUMENT_SET_SUCCESS:
      return {
        ...state,
        settings_guarantor_terms_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          guarantor_terms: false,
        },
      };

    // Guarantor Terms
    case MANAGE_PROFILE_SET_PAPERLESS_TERMS_DOCUMENT:
      return {
        ...state,
        settings_paperless_terms_document: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          paperless_terms: true,
        },
      };
    case MANAGE_PROFILE_SET_PAPERLESS_CUSTOM_TERMS_INTENT:
      return {
        ...state,
        settings_paperless_custom_terms: action.payload,
        settings_section_edited: {
          ...state.settings_section_edited,
          paperless_terms: true,
        },
      };
    case MANAGE_PROFILE_SET_PAPERLESS_VIEWED_TERMS:
      return { ...state, settings_paperless_terms_viewed: action.payload };

    case MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_START:
      return { ...state, settings_paperless_terms_updating: true };
    case MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_ERROR:
      return {
        ...state,
        settings_paperless_terms_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          paperless_terms: false,
        },
      };
    case MANAGE_PROFILE_PAPERLESS_TERMS_DOCUMENT_SET_SUCCESS:
      return {
        ...state,
        settings_paperless_terms_updating: false,
        settings_section_edited: {
          ...state.settings_section_edited,
          paperless_terms: false,
        },
      };

    case MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_ERROR:
      return {
        ...state,
        settings_requires_additional_documents_loading: false,
      };
    case MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_START:
      return { ...state, settings_requires_additional_documents_loading: true };
    case MANAGE_PROFILE_SETTINGS_REQUIRES_ADDITIONAL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        settings_requires_additional_documents_loading: false,
      };
    case MANAGE_PROFILE_SETTINGS_REQUIRES_TRUST_DEED:
      return { ...state, requires_trust_deed: action.payload };
    case MANAGE_PROFILE_SETTINGS_SET_PARTNERSHIP_AGREEMENT_UPLOAD_VISIBLE:
      return { ...state, partnership_agreement_upload_visible: action.payload };
    case MANAGE_PROFILE_SETTINGS_SET_TRUST_DEED_UPLOAD_VISIBLE:
      return { ...state, trust_deed_upload_visible: action.payload };
    case MANAGE_PROFILE_LOAD_TIMEZONE_ERROR:
      return { ...state, loading_timezones: false };
    case MANAGE_PROFILE_LOAD_TIMEZONE_START:
      return { ...state, loading_timezones: true };
    case MANAGE_PROFILE_LOAD_TIMEZONE_SUCCESS:
      return {
        ...state,
        loading_timezones: false,
        timezone_options: action.payload,
      };
    default:
      return { ...state };
  }
}
