import * as yup from "yup";

const formSchema = yup.object().shape({
  message: yup.string().required("Message cannot be empty"),
  recipient_emails: yup.array()
    .transform(function(value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(yup.string().email(({ value }) => `${value} is not a valid email`)),
  sender_business_name: yup.string().required("Please enter your business name."),
  sender_name: yup.string().required("Please enter your full name."),
});

export default formSchema;
