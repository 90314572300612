import { Pie } from "@ant-design/charts";
import { SubHeading } from "modules/applications/components/credit-checks/credit-works/v2/Container/styles";
import React, { Fragment, ReactElement } from "react";

interface IAgedBalanceGroupIndustrySummary {
  balanceMonth: number;
  balanceYear: number;
  dso: number;
  industryShare: number;
  industryTypeDescription: string;
}

const SupplierByIndustryPieChart = ({
  data,
}: {
  data: IAgedBalanceGroupIndustrySummary[];
}): ReactElement | null => {
  if (!data || data.length === 0) {
    return null;
  }

  const pieData = data.map(datum => {
    return {
      ...datum,
      legend: `${datum.industryTypeDescription} (${datum.industryShare}%) ${datum.dso} avg. dso`,
    };
  });

  const config = {
    angleField: "industryShare",
    appendPadding: 20,
    colorField: "legend",
    data: pieData,
    height: 250,
    label: {
      formatter: datum =>
        `${datum.industryTypeDescription} (${datum.industryShare}%)`,
    },
    tooltip: {
      fields: ["dso", "industryShare", "industryTypeDescription"],
      formatter: datum => {
        return {
          name: datum.industryTypeDescription,
          value: `(${datum.industryShare}%) ${datum.dso} avg. dso`,
        };
      },
      showContent: true,
      showTitle: false,
    },

    width: 250,
  };

  return (
    <Fragment>
      <SubHeading>Suppliers by Industry</SubHeading>
      <Pie {...config} />
    </Fragment>
  );
};

export default SupplierByIndustryPieChart;
