import historyStyles from "modules/applications/components/credit-checks/credit-works/css/History.css";
import styles from "modules/applications/components/css/CommonReviewBusiness.css";
import moment from "moment";
import React, { Fragment, ReactElement } from "react";
import { InnerContainer, InnerSubHeading, SubHeading } from "modules/applications/components/credit-checks/credit-works/v2/Container/styles";

type Director = {
  address: string,
  endDate?: null,
  name: string,
  startDate: string,
}

type Props = {
  data: Director[],
}

function Directors(props: Props): ReactElement|null {
  const { data } = props;

  const getDirectorsList = () => data

  const getActiveDirectors = (): Director[] => getDirectorsList().filter(director => director.endDate === null);

  const getInactiveDirectors = (): Director[] => getDirectorsList().filter(director => director.endDate !== null)

  const getDateDeleted = (director: Director): ReactElement|null => {
    if (director.endDate === null) {
      return null;
    }

    return (
      <tr>
        <th>Date Deleted</th>
        <td>{moment(director.endDate).format("DD-MMM-YYYY")}</td>
      </tr>
    );
  }

  const getDirectorView = (directors: Director[]) => directors.map((director, index) => (
    <InnerContainer key={index}>
      <InnerSubHeading>Director</InnerSubHeading>
      <table className={styles.table_w20}>
        <tbody>
          <tr>
            <th>Name</th>
            <td>
              {director.name}
            </td>
          </tr>
          <tr>
            <th>Address</th>
            <td>{director.address}</td>
          </tr>
          <tr>
            <th>Date appointed</th>
            <td>{moment(director.startDate).format("DD-MMM-YYYY")}</td>
          </tr>
          {getDateDeleted(director)}
        </tbody>
      </table>
    </InnerContainer>
  ))

  if (data.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <div className={historyStyles.container}>
        <SubHeading>Directors</SubHeading>
        {getDirectorView(getActiveDirectors())}
      </div>
      <div className={historyStyles.container}>
        <SubHeading>Past Directors</SubHeading>
        {getDirectorView(getInactiveDirectors())}
      </div>
    </Fragment>
  );
}

export default Directors;
