import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import { AddNoteButton } from "modules/new-applications/components/application-actions/Notes";
import RecordHistory from "modules/new-applications/components/RecordHistory";
import commonStyles from "modules/new-applications/css/common.css";
import styles from "modules/new-applications/css/DeleteArchive.css";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import ContentWithFooter from "modules/shared/components/containers/ContentWithFooter";
import FixedContent from "modules/shared/components/containers/FixedContent";
import ScrollableContent from "modules/shared/components/containers/ScrollableContent";
import Button from "modules/shared/components/inputs/Button";
import SquareModal from "modules/shared/components/widgets/static/SquareModal";
import React, { Fragment, useState } from "react";

function Delete(props) {
  const {
    application,
    currentUser,
    onHideConfirmationModal,
    onShowConfirmationModal,
    router,
  } = props;

  if (application.isAccepted) {
    return null;
  }

  const isDisabled = currentUser.isStandard || !application.isDeletable;

  const onSuccessCallback = () => {
    mixpanel.track("Delete application", {
      "Application ID": application.id,
      "Entity ID": get(currentUser, "currentEntity.id"),
      distinct_id: currentUser.id,
    });

    onHideConfirmationModal();
    router.push("/dashboard/reporting");
  };

  const onSubmit = () => {
    if (isDisabled) {
      return;
    }

    application.update({
      attributes: {
        status: "deleted",
      },
      currentUser,
      onSuccessCallback,
    });
  };

  const onClickDelete = () => {
    if (isDisabled) {
      return;
    }

    onShowConfirmationModal({
      confirmButtonText: "Delete",
      confirmCallback: onSubmit,
      confirmTitle:
        "Are you sure you want to permanently delete this application?",
    });
  };

  return (
    <FixedContent header="Delete application">
      <Button
        text="Delete"
        red
        disabled={isDisabled}
        disableOnLoading={true}
        handleClick={onClickDelete}
      />
    </FixedContent>
  );
}

function Archive(props) {
  const {
    application,
    currentUser,
    onHideConfirmationModal,
    onShowConfirmationModal,
    onFetchApplicationRecord,
  } = props;

  const onSuccessCallback = () => {
    mixpanel.track("Update application archive", {
      "Application ID": application.id,
      "Entity ID": get(currentUser, "currentEntity.id"),
      distinct_id: currentUser.id,
    });

    onFetchApplicationRecord(onHideConfirmationModal);
  };

  const isDisabled = currentUser.isStandard;

  const onSubmit = () => {
    if (isDisabled) {
      return;
    }

    application.update({
      attributes: {
        archived: !application.archived,
      },
      currentUser,
      onSuccessCallback: () => onSuccessCallback(),
    });
  };

  const onClickArchive = () => {
    if (isDisabled) {
      return;
    }

    onShowConfirmationModal({
      confirmButtonText: application.archived ? "Unarchive" : "Archive",
      confirmCallback: onSubmit,
      confirmTitle: `Are you sure you want to ${
        application.archived ? "un" : ""
      }archive this application?`,
    });
  };

  return (
    <FixedContent header="Archive application">
      <div className={commonStyles.buttons}>
        <Button
          text={application.archived ? "Unarchive" : "Archive"}
          disabled={isDisabled}
          disableOnLoading={true}
          handleClick={onClickArchive}
        />
        <AddNoteButton noteCategory="archive" {...props} />
      </div>
    </FixedContent>
  );
}

function useConfirmationModalState() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalConfirmCallback, setModalConfirmCallback] = useState(null);
  const [modalConfirmButtonText, setModalConfirmButtonText] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const { isLoading, setIsLoading } = useIsLoadingState();

  const onShowConfirmationModal = ({
    confirmButtonText,
    confirmCallback,
    confirmTitle,
  }) => {
    setIsModalVisible(true);
    setModalConfirmCallback(() => confirmCallback);
    setModalConfirmButtonText(confirmButtonText);
    setModalDescription(confirmTitle);
  };

  const onHideConfirmationModal = () => {
    setIsModalVisible(false);
    setModalConfirmCallback(null);
    setModalConfirmButtonText("");
    setModalDescription("");
  };

  const onClickConfirm = () => {
    setIsLoading(true);

    if (modalConfirmCallback) {
      modalConfirmCallback();
    }
  };

  let modal = null;
  if (isModalVisible) {
    modal = (
      <SquareModal size="small">
        <div className={styles.confirmation_description}>
          {modalDescription}
        </div>
        <div className={styles.confirmation_buttons}>
          <Button
            loading={isLoading}
            text={modalConfirmButtonText}
            handleClick={onClickConfirm}
          />
          <Button white text="Cancel" handleClick={onHideConfirmationModal} />
        </div>
      </SquareModal>
    );
  }

  return { modal, onHideConfirmationModal, onShowConfirmationModal };
}

export default function DeleteArchive(props) {
  const { application, currentUser, router, onFetchApplicationRecord } = props;
  const { archivedHistories } = application;
  const {
    modal,
    onHideConfirmationModal,
    onShowConfirmationModal,
  } = useConfirmationModalState();

  const content = (
    <Fragment>
      <Delete
        application={application}
        currentUser={currentUser}
        onHideConfirmationModal={onHideConfirmationModal}
        onShowConfirmationModal={onShowConfirmationModal}
        router={router}
      />
      <Archive
        application={application}
        currentUser={currentUser}
        onFetchApplicationRecord={onFetchApplicationRecord}
        onHideConfirmationModal={onHideConfirmationModal}
        onShowConfirmationModal={onShowConfirmationModal}
        {...props}
      />
      {modal}
    </Fragment>
  );

  if (archivedHistories.length > 0) {
    return (
      <ContentWithFooter
        footer={<RecordHistory histories={archivedHistories} />}
      >
        {content}
      </ContentWithFooter>
    );
  }

  return <ScrollableContent>{content}</ScrollableContent>;
}
