import StorageService from "../storage/StorageService";

var EntityService = function() {
  this.storage = new StorageService("session");
};

EntityService.prototype.setEntity = function(value, callback) {
  this.storage.set("currentEntity", value);
  if (callback) {
    callback();
  }
};

EntityService.prototype.getEntity = function() {
  return this.storage.get("currentEntity");
};

EntityService.prototype.delEntity = function() {
  this.storage.del("currentEntity");
};

export default EntityService;
