/* Import libraries */
import AdminTable from "modules/shared/components/widgets/interactive/AdminTable";
import Pagination from "modules/shared/components/widgets/interactive/Pagination";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
/* Import components */
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import { isBranch } from "modules/shared/helpers/headquarterDetect";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { formatMoney } from "utils/formatting";

/* Import actions */
import {
  loadHeadquarterKeyContact,
  loadTeam,
  loadWebsiteButtons,
} from "../actions";
/* Import CSS */
import styles from "./css/UserList.css";

const UserList = createClass({
  changeCurrentPage(table_name, value) {
    const { dispatch } = this.props;
    const { user_list } = this.state;
    user_list.currentPage = value;
    this.setState({ user_list });
    this.reloadUserList(user_list);
  },

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loadTeam());
    if (isBranch()) {
      dispatch(loadHeadquarterKeyContact());
    }
    dispatch(loadWebsiteButtons());
  },

  changeOrder(table_name, value, sortOrder) {
    const { dispatch, options, headquarterOptions } = this.props;
    const { user_list } = this.state;
    user_list.currentColumn = options.columns[value].field;
    user_list.ascending[user_list.currentColumn] = sortOrder === "asc";
    this.setState({ user_list });
    this.reloadUserList(user_list);
  },

  create(id) {
    const { location } = this.props;
    browserHistory.push({
      pathname: "/dashboard/users/create",
      query: location.query,
    });
  },

  changeUsersPerPage(table_name, value) {
    const { dispatch } = this.props;
    const { user_list } = this.state;
    user_list.usersPerPage = value;
    this.setState({ user_list });
    this.reloadUserList(user_list);
  },

  edit(id) {
    const { location } = this.props;
    browserHistory.push({
      pathname: `/dashboard/users/update/${id}`,
      query: location.query,
    });
  },

  getInitialState() {
    return {
      isTooltipActive: true,
      user_list: {
        ascending: { last_name: false, level: false, role_types: false },
        currentColumn: "last_name",
        currentPage: 1,
        usersPerPage: defaults.options.viewPage[0].value,
      },
    };
  },

  hideTooltip() {
    this.setState({ isTooltipActive: false });
  },

  paramsFormater(params) {
    return {
      asc: params.ascending[params.currentColumn],
      order_by: params.currentColumn,
      page: params.currentPage,
      per_page: params.usersPerPage,
    };
  },

  reloadUserList(params) {
    const { dispatch } = this.props;
    dispatch(loadTeam(this.paramsFormater(params)));
  },

  remove(id) {
    const { location, onRemove } = this.props;
    onRemove(id);
  },

  render() {
    let content = null;
    if (
      !location.pathname.includes("update/") &&
      !location.pathname.includes("updated/")
    ) {
      content = (
        <section className={styles.section}>
          <div className={styles.row}>
            <div className={styles.content}>
              {this.renderUserList()}
              {isBranch() ? this.renderHeadquarterUserList() : ""}
              {this.renderWebsiteButtonList()}
              {this.renderLoading()}
            </div>
          </div>
        </section>
      );
    }

    return (
      <div>
        {content}
        {this.props.children}
      </div>
    );
  },

  renderHeadquarterUserList() {
    const { headquarter_key_contact, ...rest } = this.props;
    const userListMarkup = [];
    userListMarkup.push(
      <AdminTable
        key="user-list-hq"
        data={headquarter_key_contact}
        config={defaults.headquarterOptions.columns}
        remoteSort={true}
        {...rest}
      />,
    );
    return userListMarkup;
  },

  renderLoading() {
    const { loading } = this.props;
    return loading ? <SimpleLoader css_class={"loader_relative"} /> : "";
  },

  renderUserList() {
    const { users, num_users, options, ...rest } = this.props;
    const { user_list } = this.state;
    const userListMarkup = [];
    userListMarkup.push(
      <AdminTable
        key="user-list-admin-table"
        data={users}
        config={options.columns}
        actions={{ edit: this.edit, remove: this.remove }}
        handleSortCallback={this.changeOrder.bind(null, "user_list")}
        remoteSort={true}
        {...rest}
      />,
    );
    userListMarkup.push(
      <Pagination
        key="user-list-pagination"
        dataLength={num_users}
        currentPage={user_list.currentPage}
        todosPerPage={user_list.usersPerPage}
        optionViewPage={defaults.options.viewPage}
        handleClick={this.changeCurrentPage.bind(null, "user_list")}
        handleClickTodosPerPage={this.changeUsersPerPage.bind(
          null,
          "user_list",
        )}
        resourceName={"Users"}
      />,
    );
    return userListMarkup;
  },

  renderWebsiteButtonList() {
    const { website_buttons, websitebuttonOptions, ...rest } = this.props;
    const { isTooltipActive } = this.state;
    const websitebuttonMarkup = [];
    if (website_buttons.length > 0) {
      websitebuttonMarkup.push(
        <AdminTable
          key="user-list-wb"
          id="website_buttons"
          data={website_buttons}
          config={websitebuttonOptions.columns}
          actions={{ edit: this.edit, remove: this.remove }}
          {...rest}
        />,
      );
      if (isTooltipActive) {
        websitebuttonMarkup.push(
          <ToolTip
            key="user-list-wb-tooltip"
            css_style="reassign_account"
            tip_name="ReassignAccount"
            dismissHandler={this.hideTooltip}
          />,
        );
      }
    }
    return websitebuttonMarkup;
  },
});

var defaults = {
  consumerOptions: {
    columns: [
      {
        key: "attributes.full_name",
        label: "User",
        sortKey: "attributes.last_name",
        type: "data",
        widthDefault: 480,
      },
      {
        key: "attributes.role_type_str",
        label: "User type",
        type: "data",
        widthDefault: 480,
      },
      {
        actionKey: "edit",
        key: "id",
        label: "edit",
        type: "action",
      },
      {
        actionKey: "remove",
        key: "id",
        label: "remove",
        type: "action",
      },
    ],
  },
  headquarterOptions: {
    columns: [
      {
        field: "last_name",
        key: "attributes.full_name",
        label: "Headquarter user",
        type: "data",
        widthDefault: 480,
      },
      {
        field: "email",
        key: "attributes.email",
        label: "Email",
        type: "data",
        widthDefault: 480,
      },
    ],
  },
  options: {
    columns: [
      {
        field: "last_name",
        key: "attributes.full_name",
        label: "User",
        type: "data",
        widthDefault: 480,
      },
      {
        field: "role_types",
        key: "attributes.role_type_str",
        label: "User type",
        type: "data",
        widthDefault: 480,
      },
      {
        field: "level",
        key: "attributes.approval_level_str",
        label: "Approval level",
        type: "data",
        widthDefault: 480,
      },
      {
        actionKey: "edit",
        key: "id",
        label: "edit",
        type: "action",
      },
      {
        actionKey: "remove",
        key: "id",
        label: "remove",
        type: "action",
      },
    ],
    viewPage: [
      {
        label: "10",
        value: 10,
      },
      {
        label: "20",
        value: 20,
      },
    ],
  },
  title: "Manage your team",
  websitebuttonOptions: {
    columns: [
      {
        key: "attributes.full_name",
        label: "Website button",
        type: "data",
        widthDefault: 1160,
      },
      {
        actionKey: "edit",
        key: "id",
        label: "reassign accounts",
        type: "action",
      },
      {
        actionKey: "remove",
        key: "id",
        label: "remove",
        type: "action",
      },
    ],
  },
};

export default connect((state, ownProps) => {
  const approval_levels =
    state.manage_profile.current_entity_approval_levels || [];
  let users = state.manage_users.team_list || [];
  const currentUserId = state.current_user.data.data.id;
  users = users.map(v => {
    if (v.id === currentUserId) {
      if (!v.attributes.self) {
        v.attributes.self = true;
        v.attributes.full_name = (
          <span>
            {v.attributes.full_name} <span>(You)</span>
          </span>
        );
      }
    }
    v.attributes.approval_level_str = "N/A";
    const level = v.attributes.level;

    if (typeof level === "number") {
      if (level === 0) {
        v.attributes.approval_level_str = "Reviewer";
      }

      if (level > 0) {
        v.attributes.approval_level_str = `Level ${level} ≤ $${
          approval_levels[level - 1]
            ? formatMoney(approval_levels[level - 1].credit_limit)
            : "NA"
        }`;
      }
    }
    v.attributes.role_type_str = v.attributes.role_types
      .join(", ")
      .replace("approver", "manager")
      .replace("_", " ");
    return v;
  });

  const headquarter_user = state.manage_users.headquarter_key_contact;
  let headquarter_key_contact = [];
  if (headquarter_user) {
    headquarter_key_contact = [
      {
        attributes: {
          email: headquarter_user.key_contact_email,
          full_name: `${headquarter_user.key_contact_first_name} ${headquarter_user.key_contact_last_name}`,
        },
        id: undefined,
      },
    ];
  }

  let options = defaults.options;
  if (!state.current_user.current_entity.attributes.supplier) {
    options = defaults.consumerOptions;
  }

  return {
    headquarterOptions: defaults.headquarterOptions,
    headquarter_key_contact: headquarter_key_contact || [],
    loading:
      state.manage_users.team_list_loading ||
      state.manage_users.websitebutons_loading,
    num_headquarter_users: state.manage_users.num_headquarter_users,
    num_users: state.manage_users.num_users,
    options,
    title: defaults.title,
    users,
    website_buttons: state.manage_users.website_button_list || [],
    websitebuttonOptions: defaults.websitebuttonOptions,
  };
})(UserList);
