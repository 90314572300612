/* Import libraries */
import React, { Fragment } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";

/* Import components */
import Clickable from "modules/shared/components/widgets/interactive/Clickable";

import BasicMenu from "modules/dashboard/containers/components/BasicMenu";

/* Import CSS */
import styles from "./css/TrmContainer.css";

function TrmContainer(props) {
  const { username, current_user } = props;

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <Helmet
          defaultTitle="1Centre"
          titleTemplate="1Centre - %s"
          title="Trm"
        />
        <div className={styles.nav_container}>
          <div className={styles.brand}>
            <Clickable to={"/trm/list"}>
              <h1 className={styles.company}>Total risk management</h1>
            </Clickable>
          </div>
          <BasicMenu
            displayUsername
            username={username}
            current_user={current_user}
          />
        </div>
      </header>
      {props.children}
    </div>
  );
}

export default connect((state, ownProps) => {
  let username;
  if (state.current_user.data.hasOwnProperty("data")) {
    username = state.current_user.data.data.attributes.full_name;
  }

  return {
    username: username,
    current_user: state.current_user,
  };
})(TrmContainer);
