/* Import libraries */
import React from "react";
import { Link } from "react-router";

/* Import styles */
import styles from "./css/PlusButton.css";

var PlusButton = createClass({
  render: function() {
    const { link, text, type, handleClick, cssStyle, ...rest } = this.props;
    var style = styles.button;

    if (cssStyle) {
      style = styles[cssStyle];
    }

    if (handleClick) {
      return (
        <button className={style} onClick={handleClick} {...rest}>
          {text}
        </button>
      );
    } else if (type === "submit" || type === "button") {
      return (
        <button className={style} type={type} {...rest}>
          {text}
        </button>
      );
    } else {
      return (
        <Link className={style} to={link} {...rest}>
          {text}
        </Link>
      );
    }
  },
});

module.exports = PlusButton;
