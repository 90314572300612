/* Import libraries */
import React, { Component } from "react";
import AdditionalDetails from "../additional/AdditionalDetails";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { connect } from "react-redux";
import { reviewComponentComplete } from "../../actions/review";
import styles from "./css/Review.css";

class ReviewAdditional extends Component {
  updateReviewComponentComplete(complete) {
    const { dispatch, review_complete } = this.props;
    if (review_complete[defaults.moduleName] !== complete) {
      dispatch(reviewComponentComplete(defaults.moduleName, complete));
    }
  }

  render() {
    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        <ReviewContainer css_class={"block_noborder"}>
          <AdditionalDetails
            {...this.props}
            showAll={true}
            handleComplete={value => this.updateReviewComponentComplete(value)}
          />
        </ReviewContainer>
      </section>
    );
  }
}

const defaults = {
  moduleName: "additional",
};

module.exports = connect(() => {
  return {};
})(ReviewAdditional);
