import approvedApplicationPath from "documents/webhooks/v2/approved_application.yml";
import {
  SectionHeader,
  SubSection,
} from "modules/webhooks/v2/components/V2Documentation/Content/styles";
import PayloadStructureDescription from "modules/webhooks/v2/components/V2Documentation/PayloadStructureDescription";
import SyntaxHighlighter from "modules/webhooks/v2/components/V2Documentation/SyntaxHighlighter";
import React, { Fragment, ReactElement, useEffect, useState } from "react";

const ApprovedApplicationPayloadStructure = (): ReactElement => {
  const [payloadStructure, setPayloadStructure] = useState("loading...");

  useEffect(() => {
    fetch(approvedApplicationPath)
      .then(rawData => rawData.text())
      .then(decodedText => setPayloadStructure(decodedText));
  }, [approvedApplicationPath]);

  return (
    <Fragment>
      <SectionHeader>Approved application schema</SectionHeader>
      <SubSection>
        <PayloadStructureDescription
          fileName="1Centre-approved-application-schema.yml"
          filePath={approvedApplicationPath}
        />
        <SyntaxHighlighter language="yaml">
          {payloadStructure}
        </SyntaxHighlighter>
      </SubSection>
    </Fragment>
  );
};

export default ApprovedApplicationPayloadStructure;
