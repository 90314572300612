export const COD_CARDS_SET_APPLICANT_CARDHOLDER =
  "COD_CARDS_SET_APPLICANT_CARDHOLDER";
export const COD_CARDS_REMOVE_APPLICANT_CARDHOLDER =
  "COD_CARDS_REMOVE_APPLICANT_CARDHOLDER";
export const COB_CARDS_SET_CARDHOLDERS_COUNT =
  "COB_CARDS_SET_CARDHOLDERS_COUNT";
export const COB_CARDS_LOAD_CARDHOLDERS_ERROR =
  "COB_CARDS_LOAD_CARDHOLDERS_ERROR";
export const COB_CARDS_LOAD_CARDHOLDERS_START =
  "COB_CARDS_LOAD_CARDHOLDERS_START";
export const COB_CARDS_LOAD_CARDHOLDERS_SUCCESS =
  "COB_CARDS_LOAD_CARDHOLDERS_SUCCESS";
export const COB_CARDS_PRE_POPULATE_DETAILS = "COB_CARDS_PRE_POPULATE_DETAILS";
export const COB_CARDS_REMOVE_CARDHOLDER = "COB_CARDS_REMOVE_CARDHOLDER";
export const COB_CARDS_REMOVE_CARDHOLDER_DEPRECATED = "COB_CARDS_REMOVE_CARDHOLDER_DEPRECATED";
export const COB_CARDS_SAVE_CARDHOLDER_ERROR =
  "COB_CARDS_SAVE_CARDHOLDER_ERROR";
export const COB_CARDS_SAVE_CARDHOLDER_START =
  "COB_CARDS_SAVE_CARDHOLDER_START";
export const COB_CARDS_SAVE_CARDHOLDER_SUCCESS =
  "COB_CARDS_SAVE_CARDHOLDER_SUCCESS";
export const COB_CARDS_SAVE_CARDHOLDERS_SUCCESS =
  "COB_CARDS_SAVE_CARDHOLDERS_SUCCESS";
export const COB_CARDS_SET_CARDHOLDER_ADDON_ANSWER =
  "COB_CARDS_SET_CARDHOLDER_ADDON_ANSWER";
export const COB_CARDS_SET_CARDHOLDER_DETAIL =
  "COB_CARDS_SET_CARDHOLDER_DETAIL";
export const COB_CARDS_SET_CARDHOLDERS_COUNT_COMPLETED =
  "COB_CARDS_SET_CARDHOLDERS_COUNT_COMPLETED";
