import React from "react";
import moment from "moment";

import styles from "../centrix.css";

class CompanyAffiliations extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getArrayifiedData(data) {
    if (
      !data.data_sets.company_affiliations ||
      !data.data_sets.company_affiliations.company_affiliation
    ) {
      return [];
    }
    let company_affiliations =
      data.data_sets.company_affiliations.company_affiliation;
    if (company_affiliations.constructor !== Array) {
      return [company_affiliations];
    }
    return company_affiliations;
  }

  render() {
    const { data } = this.props;
    let arrayfied_data = this.getArrayifiedData(data);

    return (
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Last Document Date</th>
              <th>Type</th>
              <th>Company</th>
              <th>Registration Number</th>
              <th>Company Status</th>
              <th>Appointed</th>
              <th>Resigned</th>
            </tr>
          </thead>
          <tbody>
            {arrayfied_data.map(item => (
              <tr>
                <td>{moment(item.last_document_date).format("DD/MM/YYYY")}</td>
                <td>{item.affiliation_type}</td>
                <td>{item.company_name}</td>
                <td>{item.company_number}</td>
                <td>{item.company_status_description}</td>
                <td>
                  {item.date_appointed &&
                    item.date_appointed.constructor === String &&
                    moment(item.date_appointed).format("DD/MM/YYYY")}
                </td>
                <td>
                  {item.date_resigned &&
                    item.date_resigned.constructor === String &&
                    moment(item.date_resigned).format("DD/MM/YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CompanyAffiliations;
