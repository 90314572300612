import React from "react";
import moment from "moment";

import styles from "../centrix.css";

class Insolvencies extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getArrayifiedData(data) {
    if (
      !data.data_sets.insolvencies ||
      !data.data_sets.insolvencies.insolvency
    ) {
      return [];
    }
    let insolvencies = data.data_sets.insolvencies.insolvency;
    if (insolvencies.constructor !== Array) {
      return [insolvencies];
    }
    return insolvencies;
  }

  render() {
    const { data } = this.props;
    let arrayfied_data = this.getArrayifiedData(data);

    return (
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Adjudication Date</th>
              <th>Type</th>
              <th>Insolvency Reference</th>
              <th>Status</th>
              <th>Discharge Date</th>
            </tr>
          </thead>
          <tbody>
            {arrayfied_data.map(item => (
              <tr>
                <td>{moment(item.adjudication_date).format("DD/MM/YYYY")}</td>
                <td>{item.insolvency_type}</td>
                <td>{item.insolvency_number}</td>
                <td>{item.insolvency_status}</td>
                <td>
                  {item.discharge_date &&
                    item.discharge_date.constructor === String &&
                    moment(item.discharge_date).format("DD/MM/YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Insolvencies;
