const overrides = {
  uat: true,
  api: {
    protocol: "https",
    wsProtocal: "wss",
    hostname: "uat-api.cd.1centre.com",
    version: "/v1",
    ttl: 8000,
    headers: { Accept: "application/vnd.api+json" },
  },
  churnzero: "vXbp62SewDQAIK6G2_AUufCOrBX63igJ9Q1gBa02P2s",
  datadog_site: "datadoghq.com",
  datadog_token: "pubdd2261646ef970240f62066be051c4f6",
  datadog_env: "uat",
  nz_post_api: {
    protocol: "https",
    hostname: "api.nzpost.co.nz/addresschecker",
    version: "/1.0",
    ttl: 8000,
    headers: { Accept: "application/json" },
  },
  ip_geolocation_api: {
    protocol: "https",
    hostname: "geoip.1centre.com",
    ttl: 8000,
  },
  mixpanel: "bafec2e79ed69fd2a87c554514f614c9",
  stripe:
    "pk_test_51EWxzkD9ZojQt14SVvHePosaT5wt4skWYiYWbO10dB8zihA0zJcCmHHTOyoikk1bPTNrKnLzZtZSAM7xN0N4vBa500wwBHQeOf",
  google_client: "AIzaSyDKGo2WHyJkwXMXwUzQEYemMOPNRYzy2H8",
  sentry_dsn: "https://8b38409d5782472fa263a422e4a97a60@sentry.io/1298646",
  sentry_token:
    "67d745e8346f43ea99d0f51f75e08c926de3b6616ef542f4961f8041c76811d0",
  sentry_release: "uat",
  sentry_project: "1centre-app-uat",
  recaptcha_api: {
    hostname: "uat-api.cd.1centre.com/node",
    protocol: "https",
    ttl: 8000,
  },
  recaptcha_key: "6Lca1igaAAAAAPtlb8doOafzCpGDZA0lZkUjZ7Hd",
  visible_reporting_phase: [1, 2],
};
module.exports = overrides;
