import React from 'react'
import Helmet from "react-helmet";
import PopperTooltip from '../interactive/PopperToolTip';
import styles from "./css/AdminTitle.css";

function AdminTitle({
  children,
  tooltip,
  text,
  white,
}) {
  let textStyle = styles.title;

  if (white) {
    textStyle = styles.title_white;
  }

  return (
    <div className={styles.outer}>
      <Helmet title={text} />
      <h1 className={textStyle}>
        {text}
        {
          tooltip && (
            <span style={{ marginLeft: '0.5rem' }}>
              {' '}
              <PopperTooltip
                {...tooltip}
              >
                {tooltip.element}
              </PopperTooltip>
            </span>
          )
        }
      </h1>
      {children}
    </div>
  );
}

export default AdminTitle
