import { InputAdornment } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import useBodyScroll from "hooks/useBodyScroll";
import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import UserModel from "models/UserModel";
import ContentContainer from "modules/shared/components/v2/ContentContainer";
import { loadAntiFraud, loadCard, updateMandatoryChecks } from "modules/profile/actions";
import BorderedTextField from "modules/shared/components/inputs/BorderedTextField";
import Button from "modules/shared/components/inputs/Button";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import Radiobox from "modules/shared/components/inputs/Radiobox";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import { isFeatureEditEnabled, isRegular } from "modules/shared/helpers/headquarterDetect";
import React, { ReactElement, useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import CreditCardModal from "../CreditCardModal";
import CategoryRadiobox from "./CategoryRadiobox";
import { _formatMoney,
  getFormSchema,
  getNewVersionAttribute,
  getOrInitializeAddonModule,
  MAX_SCORE,
  toNumber,
  useForceUpdate } from "./helper";
import HistoryLogs from "./HistoryLogs";
import {
  Section, Tab, Tabs, Title,
} from "./styles"
import { Wrapper } from 'modules/shared/components/v2/SupplierSetup/styles';
import { AntiFraudProps, Obj, Rule } from "./types";
import EntityTypeSelectDropdown from "modules/shared/components/v2/Form/SelectDropdown/EntityTypeSelectDropdown";
import AuthorisationTypeSelectDropdown from "modules/shared/components/v2/Form/SelectDropdown/AuthorisationTypeSelectDropdown";
import UnsavedModal from "modules/shared/components/v2/UnsavedModal";
import { FormSubmissionStatus, Status } from "modules/shared/components/v2/Form/useFormSubmissionStatus";

function AntiFraud(props: AntiFraudProps): ReactElement {
  useBodyScroll();
  const SET_1CAH_PATH = '/dashboard/profile?active_tab=your_team';
  const { card, closeModal, currentUser, data, dispatch, readOnly, router, tradeAccountLimit } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("website");
  const [cardModal, setCardModal] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyPopupVisible, setIsDirtyPopupVisible] = useState(false);
  const [dirtyPopUpCallback, setDirtyPopUpCallback] = useState<null|(() => void)>(() => null);
  const [status, setStatus] = useState<Status|null>(null);
  const forceUpdate = useForceUpdate();

  const currentConfig = data.find(addonConfig => addonConfig.addonModuleName === `anti_fraud_${activeTab}`)
  const addonConfig = getOrInitializeAddonModule(currentConfig, activeTab);

  const newVersion = addonConfig.generateNewVersion();
  const {
    authorisation_types,
    auto_pass_min_score,
    category,
    tokenised,
  } = newVersion.config || {};

  const defaultValues: Rule = {
    authorisationTypes: authorisation_types || [],
    autoPassMinScore: auto_pass_min_score,
    category: category ? category.toString() : "",
    entityTypes: newVersion.legalTypes || [],
    maxCreditValue: toNumber(newVersion.maxCreditValue),
    minCreditValue: toNumber(newVersion.minCreditValue),
    tokenised: typeof tokenised === "undefined" ? "" : tokenised,
  }

  const {
    clearError,
    errors,
    formState,
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    watch,
    getValues,
  } = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onSubmit",
    validationSchema: getFormSchema({ tradeAccountLimit }),
  });

  const values = watch() as Rule;
  const { isValid } = formState;

  const isManualCategory = !values.category || values.category === "1";

  /* eslint-disable sort-keys-fix/sort-keys-fix */
  const tabs = {
    website: "Website button",
    qr: "QR code",
    sales: "Sales team",
  }
  /* eslint-enable sort-keys-fix/sort-keys-fix */

  function setDirtyState() {
    const values = getValues() as Obj;
    const isDirty = Object.keys(values).some(key => {
      const value = values[key];
      const defaultValue = defaultValues[key];

      if (typeof value !== "object") {
        return value !== defaultValue;
      }

      const stringCurrentValue = Object.entries(value || {}).toString();
      const stringDefaultValue = Object.entries(defaultValue || {}).toString();

      return stringCurrentValue !== stringDefaultValue;
    })

    setDirty(isDirty);
  }

  function submit(data: Rule) {
    if (readOnly) {
      return;
    }

    setIsLoading(true);
    const attributes = getNewVersionAttribute(addonConfig, data, activeTab);
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser,
      onErrorCallback: () => setStatus({
        message: 'Oops! Something is wrong. Please try again or contact us if the problem still persists.',
        type: 'danger',
      }),
      onSuccessCallback,
    });
  }

  function onSuccessCallback(addonVersion) {
    mixpanel.track("1CAF settings updated", {
      "Entity ID": get(currentUser, "currentEntity.id"),
      Ruleset: addonVersion.data,
      distinct_id: currentUser.id,
    });

    setDirty(false);
    setIsDirtyPopupVisible(false);
    setIsLoading(false);
    handleDiscard();
    dispatch(loadAntiFraud());
    setStatus({
      message: 'Your changes has been saved successfully.',
      type: 'success',
    });
    dispatch(updateMandatoryChecks({}));
  }

  function _setValue(key: string, value) {
    setValue(key, value);
    triggerValidation(key);
    setDirtyState();

    if (status) {
      setStatus(null);
    }
  }

  function handleDiscard() {
    setIsDirtyPopupVisible(false);

    if (dirtyPopUpCallback) {
      reset();
      dirtyPopUpCallback();
      setDirty(false);
      setDirtyPopUpCallback(null);
    }

    forceUpdate();
    clearError();
  }

  function checkDirtyBeforeAction(action: () => void) {
    if (dirty) {
      setDirtyPopUpCallback(() => action);
      return setIsDirtyPopupVisible(true);
    }

    action();
    forceUpdate();
    clearError();
  }

  function onChangeTab(tabName: string) {
    const action = () => {
      setActiveTab(tabName);
    };
    checkDirtyBeforeAction(action);
  }

  function onVisitProfilePage() {
    const action = () => {
      router.push(SET_1CAH_PATH);
    };
    checkDirtyBeforeAction(action);
  }

  function handleClose() {
    const action = () => closeModal();
    checkDirtyBeforeAction(action);
  }

  function creditCardSaveCallback(response) {
    if (response) {
      dispatch(loadCard());
      handleCloseCreditModal();
    }
  }

  function handleCloseCreditModal() {
    setCardModal(false);
  }

  function handleEdit() {
    setIsDirtyPopupVisible(false);
  }

  function handleTokenisedChange(e) {
    const value = e.target.value;
    _setValue("tokenised", value === "Yes");
  }

  function handleCategoryChange(key: string, e) {
    const value = e.target.value;
    if (value !== '1' && !card && isRegular())  {
      return setCardModal(true);
    }
    _setValue(key, value);
  }

  function handleEntityTypeChange(e: { value: string[] }) {
    _setValue("entityTypes", e.value);
  }

  function handleAuthorisationTypeChange(e: { value: string[] }) {
    _setValue("authorisationTypes", e.value);
  }

  function handleLimitChange(key: string, value: string) {
    const processedValue = toNumber(value);
    _setValue(key, processedValue);
    triggerValidation(["minCreditValue", "maxCreditValue"]);
  }

  function handleAutopassMinScoreChange(e) {
    const processedValue = toNumber(e.target.value);
    _setValue("autoPassMinScore", processedValue);
  }

  function reset() {
    setStatus(null);
    return Object.keys(defaultValues).map(key => setValue(key, defaultValues[key]), { shouldDirty: false });
  }

  useEffect(() => {
    const fieldsRegistration = [
      "authorisationTypes",
      "category",
      "entityTypes",
      "minCreditValue",
      "maxCreditValue",
      "autoPassMinScore",
      "tokenised",
    ];

    fieldsRegistration.forEach(name => register({ name }));
  }, []);

  useEffect(() => {
    reset();
  }, [activeTab]);

  return (
    <MuiThemeProvider theme={muiTheme()}>
      {cardModal && (
        <CreditCardModal
          onSave={creditCardSaveCallback}
          onCancel={handleCloseCreditModal}
        />
      )}
      { isDirtyPopupVisible && (
        <UnsavedModal
          loading={isLoading}
          handleEdit={handleEdit}
          handleDiscard={handleDiscard}
          handleSave={handleSubmit(submit)}
          isValid={isValid}
        />
      )}
      <Wrapper>
        <div className="container">
          <div className="column">
            <Section>
              <Title>1Centre Anti-Fraud (1CAF)</Title>
              <p>
                1Centre Anti-Fraud gives you the ability of setting multiple identification
                parameters that meet your personalised &apos;Identity Risk Profile&apos;.
                All of your channels will automatically default to Cat 1; from there you have
                the option to increase your checking methods (Cat1-4) by channel. Work through
                each channel below to set up the identity layers you require.
              </p>
              <CloseButton handleClick={handleClose} style={{ top: 0, right: 0 }} />

              <Tabs>
                { Object.keys(tabs).map(key => (
                  <Tab
                    type="button"
                    key={key}
                    onClick={() => onChangeTab(key)}
                    active={key === activeTab}
                    tabIndex={0}
                  >
                    {tabs[key]}
                  </Tab>
                ))}
              </Tabs>
            </Section>

            <form onSubmit={handleSubmit(submit)}>
              <ContentContainer
                header="Identity risk methods"
                description="Select the risk category for this sales channel, that best meets your business requirements.  "
              >
                <CategoryRadiobox
                  disabled={readOnly}
                  handleChange={e => handleCategoryChange("category", e)}
                  value={values.category}
                  error={!!errors.category}
                  helperText={get(errors, "category.message")}
                />
              </ContentContainer>
              <ContentContainer
                header="Entity types"
                description={`Select those entities that need to complete the\
                  identity check method you’ve selected. All other entity types\
                  will auto-default to Cat.1.
                `}
                visible={!isManualCategory}
              >
                <div className="columns">
                  <div className="column is-4 mb-2">
                    <EntityTypeSelectDropdown
                      bulkSelect
                      disabled={readOnly}
                      handleChange={handleEntityTypeChange}
                      multiple
                      value={values.entityTypes || []}
                      error={get(errors, "entityTypes.message")}
                    />
                  </div>
                </div>
              </ContentContainer>

              <ContentContainer
                header="Authorisation types"
                description={`Select those authorisations that need to complete the\
                  identity check method you’ve selected. Any others (if applicable)\
                  will default to Cat.1.
                `}
                visible={!isManualCategory}
              >
                <div className="columns">
                  <div className="column is-4 mb-2">
                    <AuthorisationTypeSelectDropdown
                      bulkSelect
                      disabled={readOnly}
                      handleChange={handleAuthorisationTypeChange}
                      multiple
                      value={values.authorisationTypes || []}
                      error={get(errors, "authorisationTypes.message")}
                    />
                  </div>
                </div>
              </ContentContainer>

              <ContentContainer
                header="Tokenisation"
                description={"\
                  Enable tokenisation will display a token as the identification\
                  in the Virtual Credit File. This will also remove any uploaded\
                  identification files from the database after the application is\
                  approved/declined.Would you like to enable tokenisation?\
                "}
              >
                <Radiobox
                  id="tokenised"
                  disabled={readOnly}
                  radioList={["Yes", "No"]}
                  display="row"
                  handleChange={handleTokenisedChange}
                  value={values.tokenised}
                  error={!!errors.tokenised}
                  helper_text={get(errors, "tokenised.message")}
                />
              </ContentContainer>

              <ContentContainer
                header="Applicable limit range (optional)"
                description={<p>
                  Where a limit range is disclosed, only applications that fall
                  within the range will be asked for ID verifications based on
                  the selected identity check method. Cat.1 will apply to all
                  applications outside the limit range.
                  {' '}
                  {!tradeAccountLimit && (
                    <span className="has-text-warning">
                      Please set your application values &amp; approval
                      levels in order to access this settings. Click
                      {' '}
                      <a onClick={onVisitProfilePage}>here</a> to set it up -
                      profile tab &gt; your team &gt; set application values & approval levels.
                    </span>
                  )}
                </p>}
                visible={!isManualCategory}
              >
                <div className="columns">
                  <div className="column is-3">
                    <BorderedTextField
                      placeholder="min"
                      customProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        ),
                      }}
                      value={_formatMoney(values.minCreditValue)}
                      onChange={e => handleLimitChange("minCreditValue", e.target.value)}
                      error={!!errors.minCreditValue}
                      helperText={get(errors, "minCreditValue.message")}
                      disabled={readOnly || !tradeAccountLimit}
                    />
                  </div>
                  <div className="column is-1 has-text-centered">
                    <span style={{ display: "inline-block", paddingTop: "0.5rem" }}>&mdash;</span>
                  </div>
                  <div className="column is-3">
                    <BorderedTextField
                      placeholder="max"
                      customProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        ),
                      }}
                      value={_formatMoney(values.maxCreditValue)}
                      onChange={e => handleLimitChange("maxCreditValue", e.target.value)}
                      error={!!errors.maxCreditValue}
                      helperText={get(errors, "maxCreditValue.message")}
                      disabled={readOnly || !tradeAccountLimit}
                    />
                  </div>
                </div>
              </ContentContainer>

              <ContentContainer
                header="Auto-pass minimum score"
                description={`Set the minimum Similarity Score required for the facial recognition\
                  imagery to pass/fail. The system will compare the ID image with the FaceMatch or\
                  Proof of life imagery, anything below the minimum score will require a manual check.\
                  If a similarity score is less than or equal to 80%, the system will prompt the customer\
                  to retake their photo.
                `}
                visible={
                  !!(values.category && ["3", "4"].includes(values.category))
                }
              >
                <div className="columns">
                  <div className="column is-3">
                    <BorderedTextField
                      disabled={readOnly}
                      placeholder="min"
                      customProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            %
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        max: MAX_SCORE,
                        type: "number",
                      }}
                      value={values.autoPassMinScore}
                      onChange={handleAutopassMinScoreChange}
                      error={!!errors.autoPassMinScore}
                      helperText={get(errors, "autoPassMinScore.message")}
                    />
                  </div>
                </div>
              </ContentContainer>
              { status && <FormSubmissionStatus {...status} />}
              <Button
                text="save"
                type="submit"
                disabled={!dirty}
                loading={isLoading}
              />
            </form>
            <HistoryLogs addonVersions={addonConfig.addonVersions} />
          </div>
        </div>
      </Wrapper>
    </MuiThemeProvider>
  )
}

export default connect(state => {
  const currentUser = UserModel.fromCurrentUser(state.current_user);
  const tradeAccountLimit = get(currentUser, "currentEntity.tradeAccountLimit");
  const card = !!state.manage_profile.current_entity_card;

  return {
    currentUser,
    tradeAccountLimit,
    card,
    readOnly: !isFeatureEditEnabled('Credit'),
  }
})(AntiFraud)
