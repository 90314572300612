import axios from "./axios";;

let Headquarters = function(config) {
  config.timeout = 30000;
  this.axios = axios(config);
  this.type = "headquarters";
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
};

Headquarters.prototype.getBranches = function(
  entity_id,
  success,
  error,
  attributes,
) {
  return this.axios
    .get(`/${this.type}/${entity_id}/branches`, attributes)
    .then(success)
    .catch(error);
};

Headquarters.prototype.sentBranchInvite = function(
  entity_id,
  success,
  error,
  attributes,
) {
  return this.axios
    .post(`/${this.type}/${entity_id}/branch_invite`, attributes)
    .then(success)
    .catch(error);
};

Headquarters.prototype.updateConfig = function(
  entity_id,
  success,
  error,
  attributes,
) {
  return this.axios
    .post(`/${this.type}/${entity_id}/update_config`, attributes)
    .then(success)
    .catch(error);
};

Headquarters.prototype.loadConfig = function(
  entity_id,
  success,
  error,
  attributes,
) {
  return this.axios
    .get(`/${this.type}/${entity_id}/load_config`, attributes)
    .then(success)
    .catch(error);
};

Headquarters.prototype.deleteEntities = function(
  entity_id,
  success,
  error,
  attributes,
) {
  return this.axios
    .delete(`/${this.type}/${entity_id}/delete_entities`, attributes)
    .then(success)
    .catch(error);
};

Headquarters.prototype.loadHistory = function(
  entity_id,
  success,
  error,
  attributes,
) {
  return this.axios
    .get(`/${this.type}/${entity_id}/load_history`, attributes)
    .then(success)
    .catch(error);
};

export default Headquarters;
