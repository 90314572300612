import Buttons from "modules/shared/components/containers/Buttons";
import Button from "modules/shared/components/inputs/Button";
import PlusButton from "modules/shared/components/inputs/PlusButton";
import Header from "modules/shared/components/text/Header";
import Card from "modules/shared/components/widgets/static/Card";
import React from "react";
import { connect } from "react-redux";
import Documentation from "modules/connected-apps/components/Webhooks/Documentation";

class Webhooks extends React.Component {
  webhookBlurb() {
    return (
      <div>
        <p>
          Add your very own web application URL endpoints here so that 1Centre
          can forward your customer&apos;s information.
        </p>
        <p>
          Everytime a new connection has been established 1Centre will forward
          the data, in JSON format, via POST requests to your designated URL.
        </p>
        <p>
          Make sure your endpoint is using HTTPS and keep the generated Signing
          Secret safe to ensure data is secured and legitimate.
        </p>
        <p>
          For further readings and instructions on how to receive the webhooks,
          click on the button below.
        </p>
        <Documentation />
      </div>
    );
  }

  render() {
    const { title, location } = this.props;

    return (
      <Card>
        <Header>{title}</Header>
        <Buttons absolute>
          <PlusButton
            text="Add"
            link="/dashboard/connected-apps/webhooks/new"
          />
        </Buttons>
        {this.webhookBlurb()}
        <Header>Your webhooks</Header>
        {this.props.children}
      </Card>
    );
  }
}

module.exports = connect((state, ownProps) => {
  return {
    title: "About webhooks",
  };
})(Webhooks);
