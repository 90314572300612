import styles from "modules/new-applications/css/common.css";
import ScrollableContent from "modules/shared/components/containers/ScrollableContent";
import React, { ReactElement, useState } from "react"

function VirtualCreditFile({
  config,
  activeModule,
}): ReactElement {
  let childComponent;
  let previewTitle = config.title;
  const [validationTrigger, setValidationTrigger] = useState(false);

  if (activeModule) {
    const {
      childComponent: activeChildComponent,
      overridePreviewTitle,
      title,
    } = activeModule;
    config.sections.section.title = title;
    childComponent = React.cloneElement(activeChildComponent, {
      componentIndex: 0,
      validationTrigger,
    });

    if (overridePreviewTitle) {
      previewTitle = title;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.sidebar} />
      <div className={styles.main}>
        <ScrollableContent>
          {childComponent}
        </ScrollableContent>
      </div>
    </div>
  )
}

export default VirtualCreditFile
