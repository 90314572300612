import styled from "styled-components";
import { THEME_COLORS } from "variables/theme";
import { FONT_SETS } from "variables/typography";

export const SectionHeader = styled.div`
  ${FONT_SETS.sectionHeader};
`;

export const SectionDescription = styled.p`
  ${FONT_SETS.description};
  line-height: 1.75;
`;

export const SubSection = styled.div`
  margin-top: 1.2em;
  margin-bottom: 1em;
  padding-bottom: 1em;

  &:not(:last-child) {
    border-bottom: 1px solid ${THEME_COLORS.borderGray};
  }
`;

export const SubSectionHeader = styled.div`
  ${FONT_SETS.sectionHeader};
  margin-bottom: 1em;
`;

export const Figure = styled.figure`
  margin-bottom: 2em;
`;

export const DefinitionList = styled.dl`
  line-height: 1.75;
`;

export const OrderedList = styled.ol`
  line-height: 1.75;
`;

export const UnorderedList = styled.ul`
  line-height: 1.75;
`;
