import Button from "modules/shared/components/inputs/Button";
import Clickable from "modules/shared/components/widgets/interactive/Clickable";
import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import equifaxStyles from "./css/EquifaxPersonalComponent.css";
import { formatDate } from "utils/dateFormatter";
import { formatMoney } from "utils/formatting";
import logo from "images/logo-equifax.png";
import moment from "moment";
import scoreStyles from "./css/Score.css";
import styles from "../../../css/CommonReviewBusiness.css";

var EquifaxPersonalComponent = createClass({
  getInitialState() {
    return {};
  },

  _renderHeader() {
    const { data } = this.props;

    let productHeader = data.response.product_header || {},
      productData = data.response.product_data || {},
      enquiryReport = productData.enquiry_report || {},
      primaryMatch = enquiryReport.primary_match || {},
      individualName = primaryMatch.individual_name || {},
      addresses = enquiryReport.addresses || {},
      address = addresses.address || {},
      summaryData = productData.summary_data || {},
      dataBlock = summaryData.data_block || {},
      characteristic = dataBlock.characteristic || [],
      numOfCrossRef = characteristic.find(obj => obj["@id"] === "NA8900"), // NA8900 NA8900_xr_cur Number of cross references
      ageOfFile = characteristic.find(obj => obj["@id"] === "NA8904"); // NA8904 NA8904_age_fle Age of primary file

    if (address.constructor === Array) {
      address = address.find(ad => ad["@type"] === "C");
    }

    let refDesc =
      numOfCrossRef && numOfCrossRef.value > 0
        ? `(+ ${numOfCrossRef.value} cross-reference)`
        : "";

    return (
      <div className={equifaxStyles.header}>
        <div className={equifaxStyles.col_7}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.header_title}>Report for:</div>
            <div className={equifaxStyles.header_value}>
              {individualName.first_given_name} {individualName.family_name}{" "}
              {refDesc}
              <br />
              D.O.B.{" "}
              {formatDate(primaryMatch.date_of_birth, "DD MMM YYYY") || "-"}
              <br />
              {address.street_number} {address.street_name}{" "}
              {address.street_type}, {address.suburb} {address.state}{" "}
              {address.postcode}
            </div>
          </div>

          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.header_title}>Age of file:</div>
            <div className={equifaxStyles.header_value}>
              {ageOfFile.value ? Math.round(ageOfFile.value) : "-"} month(s)
            </div>
          </div>

          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.header_title}>Permission type:</div>
            <div className={equifaxStyles.header_value}>
              {productHeader.permission_type}
            </div>
          </div>
        </div>
        <div className={equifaxStyles.col_5}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.header_title}>Data level:</div>
            <div className={equifaxStyles.header_value}>
              {productHeader.product_data_level}
            </div>
          </div>

          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.header_title}>Data generated:</div>
            <div className={equifaxStyles.header_value}>
              {moment(productHeader.datetime_generated)
                .parseZone()
                .format("DD MMM YYYY - HH:mm")}
            </div>
          </div>

          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.header_title}>Transaction ID:</div>
            <div className={equifaxStyles.header_value}>
              {productHeader.enquiry_id}
            </div>
          </div>

          {/* <div className={equifaxStyles.row}>
            <div className={equifaxStyles.header_title}>Charge Back No.:</div>
            <div className={equifaxStyles.header_value}></div>
          </div> */}
        </div>
      </div>
    );
  },

  _renderScore() {
    const { data } = this.props;
    if (
      !data.response.product_data ||
      !data.response.product_data.score_data ||
      !data.response.product_data.score_data.score
    )
      return <p>Information Unavailable</p>;

    return (
      <div>
        <h3>Score</h3>
        {this._scoreGraph(data.response.product_data.score_data.score)}
        {/* {this._probabilityAdverse(data.response.product_data.score_data.score)} */}
        <div className={equifaxStyles.score_desc}>
          <p>
            <div>
              <strong>About the score</strong>
            </div>
            <div>
              Bureau scores use available Equifax bureau data to calculate a
              risk estimate. The primary purpose of this score is to predict the
              likelihood of a future adverse event being recorded on the
              individual's Equifax bureau records in the next 12 months. Please
              note where Individuals Trading History has been included it is not
              able to be factored into the score at this time and should be
              reviewed separately.
            </div>
          </p>

          <p>
            <div>
              <strong>Disclaimer</strong>
            </div>
            <div>
              The score is a statistical rank ordering tool only and as such
              should always be used in conjunction with your organisation's
              credit policies and procedures and other relevant information you
              may have about the individual, company or business. The score
              should not be and is not intended to be the sole basis for making
              a decision about whether or not to deal with a particular
              individual, company or business.
            </div>
          </p>
        </div>
        {/* <div className={scoreStyles.contributing_factors_container}>
          <h3>Score key contributing factors</h3>
          {this._keyContributingFactors(data.response.product_data.score_data.score)}
        </div> */}
      </div>
    );
  },

  _scoreGraph(score) {
    if (!score.score_masterscale) return null;

    let score_masterscale = score.score_masterscale,
      maxValue = 1200,
      minValue = -200,
      valuePosition = minValue;

    if (score_masterscale < minValue) score_masterscale = minValue;
    valuePosition =
      (
        ((score_masterscale - minValue) / (maxValue - minValue)) *
        100
      ).toString() + "%";

    return (
      <div className={styles.overview}>
        <div className={styles.overview_rating_full}>
          <div className={styles.liner}>
            <div className={styles.overview_rating_graph}>
              <div
                className={styles.overview_rating_indicator}
                style={{ left: valuePosition }}
              >
                {score_masterscale}
              </div>
              <div className={scoreStyles.overview_rating_graph_bg_reverse} />
              <span className={equifaxStyles.overview_rating_graph_minus_20}>
                -200
              </span>
              <span className={equifaxStyles.overview_rating_graph_0}>0</span>
              <span className={equifaxStyles.overview_rating_graph_20}>
                200
              </span>
              <span className={equifaxStyles.overview_rating_graph_40}>
                400
              </span>
              <span className={equifaxStyles.overview_rating_graph_60}>
                600
              </span>
              <span className={equifaxStyles.overview_rating_graph_80}>
                800
              </span>
              <span className={equifaxStyles.overview_rating_graph_100}>
                1000
              </span>
              <span className={equifaxStyles.overview_rating_graph_120}>
                1200
              </span>

              <span
                className={equifaxStyles.overview_rating_graph_minus_20_label}
              >
                <strong>Increased Risk</strong>
              </span>
              <span className={equifaxStyles.overview_rating_graph_120_label}>
                <strong>Reduced Risk</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  },

  _probabilityAdverse(score) {
    if (!score.risk_odds) return null;

    let probabilityAdverse = score.risk_odds;

    return (
      <div className={equifaxStyles.risk_odds}>
        <span>
          <strong>{probabilityAdverse}%</strong> chance of adverse recorded at
          Equifax in the next 12 months
        </span>
      </div>
    );
  },

  _keyContributingFactors(score) {
    if (
      !score.key_contributing_factors ||
      !score.key_contributing_factors.contributing_factor
    )
      return null;

    let factors = score.key_contributing_factors.contributing_factor || [];

    if (factors.constructor === Object) factors = [factors];

    return factors.map((factor, index) => {
      return (
        <div key={index} className={scoreStyles.contributing_factor}>
          <div className={scoreStyles.contributing_factor_header_container}>
            <span className={scoreStyles.contributing_factor_label}>
              <strong>{factor.name}</strong>
            </span>
          </div>
          <span className={scoreStyles.contributing_factor_description}>
            {factor.description}
          </span>
        </div>
      );
    });
  },

  _renderEnquiriesDeffaults() {
    const { data } = this.props;

    let productData = data.response.product_data || {},
      summaryData = productData.summary_data || {},
      dataBlock = summaryData.data_block || {},
      characteristic = dataBlock.characteristic || [],
      timeSinceLastEnquiry =
        characteristic.find(obj => obj["@id"] === "NY8059") || {}, // NY8059 NY8059_enq_time_1 Months since last commercial enquiry
      numEnquiries3months =
        characteristic.find(obj => obj["@id"] === "NY8001") || {}, // NY8001 NY8001_enq_3m Number of commercial enquiries in the last 3 months
      totalEnquiries3months =
        characteristic.find(obj => obj["@id"] === "NY8062") || {}, // NY8062 NY8062_enq_amt_3m Total value of commercial enquiries < 3 mths
      numEnquiries5years =
        characteristic.find(obj => obj["@id"] === "NY8006") || {}, // NY8006 NY8006_enq_60m Number of commercial enquiries in the last 60 months
      totalEnquiries5years =
        characteristic.find(obj => obj["@id"] === "NY8063") || {}, // NY8063 NY8063_enq_amt_60m Total value of commercial enquiries < 60 mths
      numDefaults = characteristic.find(obj => obj["@id"] === "NY7516") || {}, // NY7516 NY7516_df_60_84m Number of commercial defaults/SCI in the last 60/84 months
      totalDefaults = characteristic.find(obj => obj["@id"] === "NY7588") || {}; // NY7588 NY7588_df_latamt_60_84m Total latest dollar amount of commercial defaults/SCI in the last 60/84 months

    if (characteristic.length < 1) return <p>Information Unavailable</p>;

    return (
      <div>
        <p>
          No. of enquiries: {numEnquiries5years.value}
          &emsp; No. of defaults: {numDefaults.value}
        </p>
        <div className={equifaxStyles.table}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.th + " " + equifaxStyles.col_4}>
              Data
            </div>
            <div className={equifaxStyles.th + " " + equifaxStyles.col_8}>
              Commercial
            </div>
          </div>
          <div className={equifaxStyles.row}>
            <div
              className={equifaxStyles.th_underline + " " + equifaxStyles.col_4}
            >
              Time since last enquiry
            </div>
            <div
              className={equifaxStyles.th_underline + " " + equifaxStyles.col_8}
            >
              {!timeSinceLastEnquiry || timeSinceLastEnquiry.value < 0
                ? "-"
                : `Less than ${timeSinceLastEnquiry.value} month(s)`}
            </div>
          </div>
        </div>

        <div className={equifaxStyles.table}>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}></div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              <strong>Number</strong>
            </div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              <strong>Total</strong>
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              Enquiries in the last 3 months
            </div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              {numEnquiries3months.value}
            </div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              {!totalEnquiries3months || totalEnquiries3months.value < 0
                ? "-"
                : `$${formatMoney(parseFloat(totalEnquiries3months.value))}`}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              Enquiries in the last 5 years
            </div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              {numEnquiries5years.value}
            </div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              {!totalEnquiries5years || totalEnquiries5years.value < 0
                ? "-"
                : `$${formatMoney(parseFloat(totalEnquiries5years.value))}`}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              Defaults
            </div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              {numDefaults.value}
            </div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              {!totalDefaults || totalDefaults.value < 0
                ? "-"
                : `$${formatMoney(parseFloat(totalDefaults.value))}`}
            </div>
          </div>
        </div>
      </div>
    );
  },

  _renderActions() {
    const { data } = this.props;

    let productData = data.response.product_data || {},
      summaryData = productData.summary_data || {},
      dataBlock = summaryData.data_block || {},
      characteristic = dataBlock.characteristic || [],
      numInsolvency = characteristic.find(obj => obj["@id"] === "NP8510") || {}, // NP8510 NP8510_inslv Number of insolvencies on file
      numDefaultJudgement =
        characteristic.find(obj => obj["@id"] === "NP7504") || {}, // NP7504 NP7504_dj_60m Number of judgements in the last 60 months
      numOtherCourtActions =
        characteristic.find(obj => obj["@id"] === "NP7511") || {}, // NP7511 NP7511_wr_60m Number of court writs in the last 60 months
      numInsolvencyValue = parseInt(numInsolvency.value) || 0,
      numDefaultJudgementValue = parseInt(numDefaultJudgement.value) || 0,
      numOtherCourtActionsValue = parseInt(numOtherCourtActions.value) || 0;

    if (characteristic.length < 1) return <p>Information Unavailable</p>;

    return (
      <div>
        <p>
          No. of actions:{" "}
          {numInsolvencyValue +
            numDefaultJudgementValue +
            numOtherCourtActionsValue}
        </p>
        <div className={equifaxStyles.table}>
          <div className={equifaxStyles.row}>
            <div className={equifaxStyles.th + " " + equifaxStyles.col_4}>
              Data
            </div>
            <div className={equifaxStyles.th + " " + equifaxStyles.col_8}>
              No. of actions
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              Personal insolvency
            </div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_8}>
              {numInsolvency.value}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              Default judgement
            </div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_8}>
              {numDefaultJudgement.value}
            </div>
          </div>
          <div className={equifaxStyles.tr}>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_4}>
              Other court actions
            </div>
            <div className={equifaxStyles.td + " " + equifaxStyles.col_8}>
              {numOtherCourtActions.value}
            </div>
          </div>
        </div>
      </div>
    );
  },

  _pdf() {
    const { pdf } = this.props;
    if (!pdf) return null;

    return (
      <Clickable target={() => this._downloadPdf(pdf)}>
        <Button css_style="button_white_outer" text="download pdf" />
      </Clickable>
    );
  },

  _downloadPdf: function(url) {
    var win = window.open(url, "equfiax_personal_pdf_window");
  },

  _faultDescription() {
    const { data } = this.props;

    let fault = data.fault || {},
      faultString = fault.faultstring,
      detail = fault.detail || {},
      error = detail.error || {};

    let errorMessage = error.description;

    if (
      errorMessage === null ||
      errorMessage === undefined ||
      errorMessage === "null"
    ) {
      errorMessage = faultString;
    }

    return <p>{errorMessage}</p>;
  },

  _errorDescription() {
    const { data } = this.props;

    let response = data.response || {},
      errors = response.errors || {},
      error = errors.error || {};

    let errorMessage = error.fault_string;

    return <p>{errorMessage}</p>;
  },

  _errorPage(type) {
    let error;
    if (type == "fault") {
      error = this._faultDescription();
    } else if (type == "errors") {
      error = this._errorDescription();
    }

    return (
      <div>
        <ReviewContainer
          subHeading={"Equifax Commercial Apply"}
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="large"
        >
          {error}
          <p>
            Contact <a href="mailto:support@1centre.com">support@1centre.com</a>{" "}
            or live chat with us.
          </p>
        </ReviewContainer>
      </div>
    );
  },

  render() {
    const { data } = this.props;
    if (!data || !data.response) {
      if (data.fault) {
        return this._errorPage("fault");
      } else {
        return null;
      }
    } else if (data.response.errors) {
      return this._errorPage("errors");
    }

    return (
      <div>
        <img
          className={styles.veda_logo}
          src={logo}
          alt="Equifax"
          width="277.9"
          height="79.1"
        />
        <h2 className={styles.heading_large}>Equifax Commercial Apply</h2>
        <ReviewContainer
          content_class="content_wide"
          css_class="block_noborder"
        >
          {this._renderHeader()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Scores"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderScore()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Credit enquiries &amp; defaults"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderEnquiriesDeffaults()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Personal insolvencies &amp; court actions"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._renderActions()}
        </ReviewContainer>
        <ReviewContainer
          content_class="content_wide"
          css_class="block_noborder"
        >
          {this._pdf()}
        </ReviewContainer>
      </div>
    );
  },
});

export default EquifaxPersonalComponent;
