import get from "lodash.get";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import React, { Component } from "react";
import { formatDateFromObject } from "utils/dateFormatter";
import { displayEmpty, formatMoney } from "utils/formatting";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import objectToArray from "utils/objectToArray";

import styles from "../css/payment-predictor/IdentificationAndOperations.css";
import commonStyles from "../css/payment-predictor/PaymentPredictorComponent.css";
import { renderAddress } from "../PaymentPredictorComponent";

export default class IdentificationAndOperations extends Component {
  get asicOrganisationDetails() {
    return get(this.props, "response.asic_organisation_details") || {};
  }

  get asicOrganisationDetailsItem() {
    return this.asicOrganisationDetails.asic_organisation_details_item || {};
  }

  get asicBasicOrganisationDetails() {
    return get(this.props, "response.asic_basic_organisation_detail") || {};
  }

  get incorporation() {
    return get(this.props, "response.incorporation") || {};
  }

  get contactDetails() {
    return get(this.props, "response.contact_details") || {};
  }

  get generalEnquiries() {
    return objectToArray((this.props.response.general_enquiries || {}).enquiry);
  }

  get headOffice() {
    return objectToArray(this.contactDetails.head_office);
  }

  get phoneNumber() {
    const phoneNumber = this.contactDetails.phone_num;

    if (isPresent(phoneNumber)) {
      return `(${phoneNumber.area_code}) ${phoneNumber.local_num}`;
    }

    return "-";
  }

  get isOtherOfficesVisible() {
    return (
      isPresent(this.contactDetails.registered_office) ||
      isPresent(this.contactDetails.principal_place_of_business) ||
      isPresent(this.contactDetails.asic_contact_address)
    );
  }

  renderLegalStructure() {
    return (
      <div className={styles.legal_structure_container}>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Legal structure</p>
        </div>
        <p className={commonStyles.emphasize}>Current organisation details</p>
        <table className={commonStyles.table}>
          <tbody>
            <tr>
              <td>Type</td>
              <td className={commonStyles.highlight_cell}>
                {displayEmpty(this.asicOrganisationDetailsItem.company_type)}
              </td>
            </tr>
            <tr>
              <td>Company Class</td>
              <td className={commonStyles.highlight_cell}>
                {displayEmpty(this.asicOrganisationDetailsItem.company_class)}
              </td>
            </tr>
            <tr>
              <td>Company Sub Class</td>
              <td className={commonStyles.highlight_cell}>
                {displayEmpty(
                  this.asicOrganisationDetailsItem.company_sub_class,
                )}
              </td>
            </tr>
            <tr>
              <td>Governance Type</td>
              <td className={commonStyles.highlight_cell}>
                {displayEmpty(
                  this.asicBasicOrganisationDetails.governance_type,
                )}
              </td>
            </tr>
            <tr>
              <td>Details start date</td>
              <td className={commonStyles.highlight_cell}>
                {formatDateFromObject(
                  this.asicOrganisationDetailsItem.start_date,
                )}
              </td>
            </tr>
            <tr>
              <td>State of registration</td>
              <td className={commonStyles.highlight_cell}>
                {displayEmpty(this.asicBasicOrganisationDetails.reg_incorp_in)}
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td className={commonStyles.highlight_cell}>
                {displayEmpty(this.asicOrganisationDetailsItem.company_status)}
              </td>
            </tr>
            <tr>
              <td>Previous state no</td>
              <td className={commonStyles.highlight_cell}>
                {displayEmpty(
                  this.asicBasicOrganisationDetails.previous_state_no,
                )}
              </td>
            </tr>
            <tr>
              <td>Date of Incorporation</td>
              <td className={commonStyles.highlight_cell}>
                {formatDateFromObject(this.incorporation.reg_incorp_date)}
              </td>
            </tr>
            <tr>
              <td>State of Incorporation</td>
              <td className={commonStyles.highlight_cell}>
                {displayEmpty(this.incorporation.reg_incorp_in)}
              </td>
            </tr>
            <tr>
              <td>ASIC review date</td>
              <td className={commonStyles.highlight_cell}>
                {formatDateFromObject(
                  this.asicBasicOrganisationDetails.review_date,
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderContactInformation() {
    return (
      <div>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Contact information</p>
        </div>
        <div>
          {this.renderHeadOfficeAddress()}
          {this.renderPostalAddress()}
          {this.renderContactNumbers()}
        </div>
        {this.renderOtherOffices()}
      </div>
    );
  }

  renderHeadOfficeAddress() {
    const address = (
      this.headOffice.find(office => !office["@mailing_address"]) || {}
    ).address;

    if (isBlank(address)) {
      return;
    }

    return (
      <div className={styles.head_office_container}>
        <p className={commonStyles.emphasize}>Head office</p>
        {renderAddress(address, { emphasize: true })}
      </div>
    );
  }

  renderPostalAddress() {
    const address = (
      this.headOffice.find(office => !office["@mailing_address"]) || {}
    ).address;

    if (isBlank(address)) {
      return;
    }

    return (
      <div className={styles.postal_address_container}>
        <p className={commonStyles.emphasize}>Postal</p>
        {renderAddress(address)}
      </div>
    );
  }

  renderContactNumbers() {
    return (
      <div className={styles.contact_numbers_container}>
        <div className={styles.contact_numbers_content}>
          <div>Telephone</div>
          <div>{this.phoneNumber}</div>

          <div>Toll free</div>
          <div>{displayEmpty(this.contactDetails.toll_free_num)}</div>

          <div>Email Address</div>
          <div>{displayEmpty(this.contactDetails.email)}</div>

          <div>Website</div>
          <div>{displayEmpty(this.contactDetails.website)}</div>
        </div>
      </div>
    );
  }

  renderOtherOffices() {
    if (!this.isOtherOfficesVisible) {
      return;
    }

    return (
      <div>
        <div className={styles.other_offices_container}>
          {this.renderOtherOffice(
            this.contactDetails.registered_office,
            "Registered office",
          )}
          {this.renderOtherOffice(
            this.contactDetails.principal_place_of_business,
            "Principal Place of Business",
          )}
          {this.renderOtherOffice(
            this.contactDetails.asic_contact_address,
            "Contact address * (For ASIC use only)",
          )}
        </div>
        <p>
          * Section 146A of the{" "}
          <span className={commonStyles.italics}>Corporations Act 2001</span>{" "}
          states{" "}
          <strong>
            &apos;A contact address is the address to which communications and
            notices are sent from ASIC to the company.&apos;
          </strong>
        </p>
      </div>
    );
  }

  renderOtherOffice(office, title) {
    if (isBlank(office)) {
      return;
    }

    return (
      <div>
        <p className={commonStyles.emphasize}>{title}</p>
        {renderAddress(office.address)}
        <p>Start date {formatDateFromObject(office.start_date)}</p>
      </div>
    );
  }

  renderPastEnquiries() {
    const enquiries = this.generalEnquiries.map((enquiry, i) =>
      this.renderPastEnquiry(enquiry, i),
    );

    return (
      <div className={styles.past_enquiries_container}>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Past enquiries</p>
        </div>
        <table
          className={`${commonStyles.table} ${styles.past_enquiries_table}`}
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Enquiring organisation</th>
              <th>Enquiry type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>{enquiries}</tbody>
        </table>
      </div>
    );
  }

  renderPastEnquiry(enquiry, i) {
    return (
      <tr key={`enquiry-${i}`}>
        <td>{formatDateFromObject(enquiry.date)}</td>
        <td className={styles.past_enquiries_enquiry_organisation}>
          {enquiry.name}
        </td>
        <td>{enquiry.type}</td>
        <td className={commonStyles.highlight_cell}>
          {this.renderEnquiryAmount(enquiry.amount)}
        </td>
      </tr>
    );
  }

  renderEnquiryAmount(amount) {
    if (isBlank(amount)) {
      return;
    }

    return formatMoney(parseFloat(amount), 2);
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Identification and operations"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        {this.renderLegalStructure()}
        {this.renderContactInformation()}
        {this.renderPastEnquiries()}
      </ReviewContainer>
    );
  }
}
