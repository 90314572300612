/* Import libraries */
import React from "react";
import { connect } from "react-redux";

import AutoSuggest from "modules/shared/components/widgets/interactive/AutoSuggest";

import { debounce } from "throttle-debounce";

import { getEntityTradingNames } from "modules/shared/actions";

import Cascader from "rc-cascader";
import "rc-cascader/assets/index.css";
import styles from "./css/TradingNameSelector.css";
import FormHelperText from "@material-ui/core/FormHelperText";

class TradingNameSelector extends React.Component {
  state = { tradingName: "", inputValue: "", value: "" };

  componentWillMount() {
    const { dispatch, entityId } = this.props;
    dispatch(getEntityTradingNames(entityId, {}, this.tradingNameClick));
    this.lookuptradingName = debounce(500, false, this.lookuptradingName);
  }

  lookupTradingName = (search_key_word) => {
    const { dispatch, entityId } = this.props;
    dispatch(getEntityTradingNames(entityId, search_key_word));
  }

  tradingNameChange = (event) => {
    const { handleClickCallback } = this.props;
    var keyWord = event.target.value;
    this.setState({ tradingName: keyWord });
    this.lookupTradingName({ key_word: keyWord });
    handleClickCallback(null);
  }

  tradingNameClick = (tradingNameId) => {
    const { dispatch, handleClickCallback, tradingNames } = this.props;
    this.setState({ tradingName: tradingNames[tradingNameId] });
    handleClickCallback(tradingNameId);
  }

  onChange = (value, selectedOptions) => {
    const { handleClickCallback } = this.props;
    if (value && value[1]) {
      handleClickCallback(value[1]);
      this.setState({
        inputValue: selectedOptions[1].label,
        value,
      });
    } else if (value && value[0]) {
      handleClickCallback(value[0]);
      this.setState({
        inputValue: selectedOptions[0].label,
        value,
      });
    }
  }

  render() {
    const {
      tradingNames,
      error,
      locationName,
    } = this.props;

    if (!tradingNames || Object.keys(tradingNames).length === 0) return null;

    const processedTradingNames = [...tradingNames.map((tradingName) => {
      const clonedTradingName = {...tradingName};
      const { children } = clonedTradingName;
      if (children && children.length === 0) {
        delete clonedTradingName['children'];
      }

      return {...clonedTradingName}
    })];

    return (
      <div className={`${styles.h80}`}>
        <p
          className={
            error
              ? `is-size-small has-text-weight-normal has-text-danger ${styles.ml13}`
              : `is-size-small has-text-weight-normal has-text-grey-dark ${styles.ml13}`
          }
        >
          {locationName || "Location"}
        </p>
        <Cascader
          options={processedTradingNames}
          value={this.state.value}
          changeOnSelect
          onChange={this.onChange}
          transitionName="slide-up"
        >
          <input
            className={`${styles.input_line}`}
            value={this.state.inputValue}
            placeholder={this.props.placeholder}
            readOnly
          />
        </Cascader>
        <FormHelperText
          id="webs-helper-text"
          error={!!error}
          className={styles.material_helper_text}
          style={
            error
              ? { fontSize: 10, fontWeight: 800 }
              : { fontSize: 10, fontWeight: 400 }
          }
        >
          {error}
        </FormHelperText>
      </div>
    );
  }
}


export default TradingNameSelector;
