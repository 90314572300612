import capitalize from "lodash.capitalize";
import BaseModel from "models/BaseModel";
import NoteModel from "models/NoteModel";
import moment from "moment";
import { formatMoney } from "utils/formatting";
import isBlank from "utils/isBlank";

const RELATIONSHIP_LENGTH = {
  less_than_1_year: "Less than a year",
  years_1_2: "1-2 Years",
  years_2_5: "2-5 Years",
  years_5_plus: "5+ Years",
};

export default class TradeReferenceCheckModel extends BaseModel {
  get formattedManualOutcome() {
    return this.outcome === "passed" ? "approved" : "declined";
  }

  get formattedTRMOutcome() {
    return capitalize(this.outcome);
  }

  get formattedReviewDate() {
    return moment(this.createdAt).format("h:mma DD/MM/YYYY");
  }

  get formattedRelationshipLength() {
    return RELATIONSHIP_LENGTH[this.relationshipLength];
  }

  get formattedLastPurchase() {
    if (isBlank(this.lastPurchase)) {
      return "";
    }

    return moment(this.lastPurchase).format("DD/MM/YYYY");
  }

  get formattedPaymentHabits() {
    return capitalize(this.paymentHabits);
  }

  get formattedCreditLimit() {
    if (isBlank(this.creditLimit)) {
      return "";
    }

    return `$${formatMoney(parseInt(this.creditLimit))}`;
  }

  get formattedAmountOwed() {
    if (isBlank(this.ammountOwed)) {
      return "";
    }

    return `$${formatMoney(parseInt(this.ammountOwed))}`;
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  assignRelationships() {
    this.assignManyRelationship({ key: "notes", model: NoteModel });
  }
}
