import React, { useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

function Icon(props) {
  const { iconProps, isOpen } = props;

  if (isOpen) {
    return <RemoveIcon {...iconProps} />;
  }

  return <AddIcon {...iconProps} />;
}

export default function useCollapsibleContentState(defaultIsOpen = false) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const onToggleIsOpen = () => setIsOpen(!isOpen);

  return {
    icon: (
      <Icon
        iconProps={{
          color: "primary",
          style: { fontSize: 12 },
        }}
        isOpen={isOpen}
      />
    ),
    isOpen,
    onToggleIsOpen,
    setIsOpen,
  };
}
