import CardOrdersIndex from "modules/card-management-applications/components/CardOrdersIndex";
import CardOrdersShow from "modules/card-management-applications/components/CardOrdersShow";

const CardManagementApplicationRoute = {
  childRoutes: [
    {
      component: CardOrdersShow,
      path: ":id",
    },
  ],
  indexRoute: {
    component: CardOrdersIndex,
  },
  path: "card_orders",
};

export default CardManagementApplicationRoute;
