import React from "react";
import { humanize } from "utils/humanize";

/* Import CSS */
import styles from "./css/LabeledInformation.css";

function LabeledInformation(props) {
  const {
    children,
    label,
    label_css,
    value_css,
    showEmpty,
    rawChildren,
    noHumanize,
  } = props;
  let { data } = props;
  let dataValues = [];
  let labelValues = [];
  let label_style = styles.label;

  if (label_css) {
    label_style = styles[label_css];
  }

  if (Array.isArray(label)) {
    label.forEach((v, i) => {
      var label_css = i == 0 ? "label_multi_first" : "label_multi";
      labelValues.push(
        <h3 className={styles[label_css]} key={`label-${i}`}>
          {humanize(v)}
        </h3>,
      );
    });
  } else {
    let value;
    if (noHumanize) {
      value = label;
    } else {
      value = humanize(label);
    }
    labelValues = <h3 className={label_style}>{value}</h3>;
  }

  if (rawChildren) {
    return (
      <div className={styles.item}>
        <h3 className={label_style}>{humanize(label)}</h3>
        {children}
      </div>
    );
  }

  if (!showEmpty && (!data || data.length == 0)) {
    return null;
  }

  // If data is a component, but the value is still null...
  if (!showEmpty && data && data.props && !data.props.value) {
    return null;
  }

  if (!data || data === "") {
    data = "-";
  }

  if (Array.isArray(data)) {
    data.forEach((v, i) => {
      dataValues.push(
        <p className={styles[value_css || "value"]} key={`dataPoint-${i}`}>
          {v}
        </p>,
      );
    });
  } else {
    let value;
    if (noHumanize) {
      value = data;
    } else {
      value = humanize(data);
    }
    dataValues = <p className={styles[value_css || "value"]}>{value}</p>;
  }

  return (
    <div className={styles.item}>
      {labelValues}
      {dataValues}
    </div>
  );
};

export default LabeledInformation;
