import React, { Component } from "react";
import PropTypes from "prop-types";

import BusinessCard from "./BusinessCard";
import ApplicationBusinessCard from "./ApplicationBusinessCard";
import styles from "./css/ControlPanel.css";

import Icon from "modules/shared/components/svg/Icon";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";
import ColorOption from "modules/shared/components/widgets/interactive/ColorOption";
import ColorValue from "modules/shared/components/widgets/interactive/ColorValue";

export default class ControlPanel extends Component {
  _renderBusinessLine(entity, idx) {
    const pointer_class = entity.attr ? " " + styles.pointer : "";
    const onClick = () => {
      if (!entity.attr) return;
      this.props.setEntity(entity);
    };
    return (
      <div
        key={idx}
        className={styles.entity_line + pointer_class}
        onClick={onClick}
      >
        <div
          className={styles.line_number}
          style={{ background: entity.color }}
        >
          {idx + 1}
        </div>
        <span className={styles.company_name}>
          {entity.application.attributes.consumer_name}
        </span>
      </div>
    );
  }
  _renderFilter() {
    const allUser = { label: "All", value: "All Users", special: "all" };
    const users = [allUser].concat(
      this.props.users.map(e => ({
        label: e.name,
        value: e.name,
        color: e.color,
      })),
    );
    return (
      <div className={styles.control_panel}>
        <div className={styles.first_dropdown}>
          <OptionsDropdown
            handleChange={this.props.setFilterType}
            label="Filter by type"
            required={true}
            value={this.props.filterType || this.props.filterTypes[0]}
            options={this.props.filterTypes}
          />
        </div>
        <div className={styles.second_dropdown}>
          <OptionsDropdown
            handleChange={this.props.setUserFilter}
            label="Filter by user"
            required={true}
            customComponents={{
              optionComponent: ColorOption,
              valueComponent: ColorValue,
            }}
            value={this.props.userFilter || users[0]}
            options={users}
          />
        </div>
        <div className={styles.business_list}>
          {this.props.entities.map((e, i) => this._renderBusinessLine(e, i))}
        </div>
      </div>
    );
  }
  _renderApplicationEntity() {
    const isSupplier = this.props.entity.type === "supplier";
    const supplierEntity = isSupplier
      ? this.props.entity.attr
      : this.props.entity.supplierEntity;
    const consumerEntity = !isSupplier
      ? this.props.entity.attr
      : this.props.entity.consumer;

    let supplierApplicationId = this.props.entity.id;
    let consumerApplicationId = this.props.entity.id;

    return (
      <div className={styles.control_panel}>
        <div className={styles.card_list}>
          <div
            className={styles.back}
            onClick={event => {
              this.props.setEntity(null);
            }}
          >
            <Icon icon="left" size="10x17" /> Back
          </div>
          <h3 className={styles.connections_title}>
            {this.props.entity.entity}
          </h3>
          <ApplicationBusinessCard
            applicationId={consumerApplicationId}
            application={this.props.entity.application}
            entity={consumerEntity}
            otherEntity={supplierEntity}
            isSupplier={false}
            contact={this.props.entity.consumer}
            tradingName={this.props.entity.application.attributes.consumer_name}
          />
          <ApplicationBusinessCard
            applicationId={supplierApplicationId}
            application={this.props.entity.application}
            entity={supplierEntity}
            otherEntity={consumerEntity}
            isSupplier={true}
            contact={this.props.entity.supplier}
            tradingName={supplierEntity.trading_name}
          />
        </div>
      </div>
    );
  }
  _renderEntity() {
    const isSupplier = this.props.entity.type === "supplier";
    const supplierEntity = isSupplier
      ? this.props.entity.attr
      : this.props.entity.supplierEntity;
    const consumerEntity = !isSupplier
      ? this.props.entity.attr
      : this.props.entity.consumer;

    let supplierConnectionId = this.props.entity.id;
    let consumerConnectionId = this.props.entity.id;

    return (
      <div className={styles.control_panel}>
        <div className={styles.card_list}>
          <div
            className={styles.back}
            onClick={event => {
              this.props.setEntity(null);
            }}
          >
            <Icon icon="left" size="10x17" /> Back
          </div>
          <h3 className={styles.connections_title}>
            {this.props.entity.entity}
          </h3>
          <BusinessCard
            connectionId={consumerConnectionId}
            connection={this.props.entity.connection}
            entity={consumerEntity}
            otherEntity={supplierEntity}
            isSupplier={false}
            contact={this.props.entity.consumer}
            tradingName={this.props.entity.application.attributes.consumer_name}
          />
          <BusinessCard
            connectionId={supplierConnectionId}
            connection={this.props.entity.connection}
            entity={supplierEntity}
            otherEntity={consumerEntity}
            isSupplier={true}
            contact={this.props.entity.supplier}
            tradingName={supplierEntity.trading_name}
          />
        </div>
      </div>
    );
  }
  render() {
    if (this.props.entity) {
      if (
        !this.props.filterType ||
        this.props.filterType.value === "Existing connections"
      ) {
        return this._renderEntity();
      } else {
        return this._renderApplicationEntity();
      }
    } else {
      return this._renderFilter();
    }
  }
}
ControlPanel.propTypes = {
  filterType: PropTypes.object,
  setFilterType: PropTypes.func.isRequired,
  userFilter: PropTypes.object,
  setUserFilter: PropTypes.func.isRequired,
  entity: PropTypes.object,
  setEntity: PropTypes.func.isRequired,
};
