import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import { AddNoteButton } from "modules/new-applications/components/application-actions/Notes";
import RecordHistory from "modules/new-applications/components/RecordHistory";
import {
  useBranchesState,
  useEntityUsersState,
  useSelectedBranchState,
  useSelectedUserState,
} from "modules/new-applications/hooks/useBranchUserStates";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import ContentWithFooter from "modules/shared/components/containers/ContentWithFooter";
import FixedContent from "modules/shared/components/containers/FixedContent";
import GridContent from "modules/shared/components/containers/GridContent";
import ScrollableContent from "modules/shared/components/containers/ScrollableContent";
import BorderedAutocomplete from "modules/shared/components/inputs/BorderedAutocomplete";
import Button from "modules/shared/components/inputs/Button";
import LabeledInputContent from "modules/shared/components/widgets/static/LabeledInputContent";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import * as yup from "yup";

const formSchema = yup.object().shape({
  branchId: yup.string().required("Please select a tier"),
  userId: yup.string().required("Please select a user"),
});

const reassignFormSchema = yup.object().shape({
  userId: yup.string().required("Please select a user"),
});

const getReallocateHelperText = ({ errorMessage, hasError, isDisabled }) => {
  if (isDisabled) {
    return "Approval process is in progress and reallocating to another tier is not allowed.";
  }

  if (hasError) {
    return errorMessage;
  }

  return " ";
};

function ReallocateDropdown(props) {
  const {
    branches,
    errors,
    isDisabled,
    isVisible,
    onSelectBranch,
    selectedBranchOption,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <LabeledInputContent label="Reallocate application to">
        <BorderedAutocomplete
          textFieldProps={{
            error: Boolean(errors.branchId),
            helperText: getReallocateHelperText({
              errorMessage: get(errors, "branchId.message", " "),
              hasError: Boolean(errors.branchId),
              isDisabled,
            }),
            label: "",
            name: "branchId",
          }}
          id="branch-autocomplete"
          disabled={isDisabled}
          options={branches}
          value={selectedBranchOption}
          onChange={onSelectBranch}
        />
      </LabeledInputContent>
    </div>
  );
}

function Form(props) {
  const {
    application,
    branches,
    currentUser,
    router,
    users,
    onFetchApplicationRecord,
    onFetchUsers,
  } = props;

  const {
    selectedBranchId,
    selectedOption: selectedBranchOption,
    setSelectedBranchId,
  } = useSelectedBranchState({ branchId: application.supplierId, branches });

  const {
    selectedOption: selectedUserOption,
    selectedUserId,
    setSelectedUserId,
  } = useSelectedUserState({ userId: application.supplierContactId, users });

  let validationSchema = reassignFormSchema;
  const defaultValues = { userId: selectedUserId };
  if (isPresent(branches)) {
    validationSchema = formSchema;
    defaultValues["branchId"] = selectedBranchId;
  }

  const {
    clearError,
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      branchId: selectedBranchId,
    },
    mode: "onBlur",
    validationSchema,
  });
  const { dirtyFields } = formState;

  const isReallocateFieldVisible =
    isPresent(branches) && application.isPersisted && !currentUser.isStandard;
  const isDisabled =
    isBlank(users) || application.isNewRecord || currentUser.isStandard;
  const { isLoading, setIsLoading } = useIsLoadingState();

  const onSuccessCallback = () => {
    mixpanel.track("Reallocate/reassign application", {
      "Application ID": application.id,
      "Entity ID": application.supplierId,
      "Target Entity ID": selectedBranchId,
      distinct_id: currentUser.id,
    });

    setIsLoading(false);
    reset();

    if (selectedBranchId === get(currentUser, "currentEntity.id")) {
      onFetchApplicationRecord();
    } else {
      router.push("/dashboard/reporting");
    }
  };

  const onSubmit = event => {
    event.preventDefault();

    let hasErrors = false;

    if (isDisabled) {
      return;
    }

    if (!dirtyFields.has("userId")) {
      setError("userId", "required", "Please select a new user");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    handleSubmit(data => {
      setIsLoading(true);

      application.reallocateAndReassign({
        attributes: {
          supplier_contact_id: data.userId,
          supplier_id: data.branchId,
        },
        currentUser,
        onSuccessCallback,
      });
    })();
  };

  const onSelectBranch = (_, value) => {
    const selectedValue = (value || {}).value;

    clearError("branchId");

    setSelectedBranchId(selectedValue);
    setValue("branchId", selectedValue);

    onFetchUsers(selectedValue);
  };

  const onSelectUser = (_, value) => {
    const selectedValue = (value || {}).value;

    clearError("userId");

    setSelectedUserId(selectedValue);
    setValue("userId", selectedValue);
  };

  useEffect(() => {
    register({ name: "branchId" });
    register({ name: "userId" });
  }, [register]);

  return (
    <GridContent gridColumnTemplate="two_thirds">
      <form onSubmit={onSubmit}>
        <ReallocateDropdown
          branches={branches}
          errors={errors}
          isDisabled={!application.canReallocate}
          isVisible={isReallocateFieldVisible}
          onSelectBranch={onSelectBranch}
          selectedBranchOption={selectedBranchOption}
        />
        <div>
          <LabeledInputContent label="Reassign application to">
            <BorderedAutocomplete
              textFieldProps={{
                error: Boolean(errors.userId),
                helperText: get(errors, "userId.message", " "),
                label: "",
                name: "userId",
              }}
              id="users-autocomplete"
              name="users-autocomplete"
              options={users}
              value={selectedUserOption}
              disabled={isDisabled}
              onChange={onSelectUser}
            />
          </LabeledInputContent>
        </div>
        <div>
          <Button
            disabled={isDisabled}
            loading={isLoading}
            disableOnLoading={true}
            text="Save"
            type="submit"
            style={{ marginRight: 60 }}
          />
          <AddNoteButton noteCategory="reallocate_reassign" {...props} />
        </div>
      </form>
    </GridContent>
  );
}

export default function ReallocateReassign(props) {
  const { application } = props;
  const { reallocateReassignHistories } = application;
  const { formattedBranches, onFetchBranches } = useBranchesState(props);
  const { formattedUsers, onFetchUsers } = useEntityUsersState(props);

  useEffect(() => {
    onFetchBranches();
  }, []);

  useEffect(() => {
    onFetchUsers(application.supplierId);
  }, [application.supplierId]);

  const content = (
    <FixedContent>
      <Form
        branches={formattedBranches}
        users={formattedUsers}
        onFetchUsers={onFetchUsers}
        {...props}
      />
    </FixedContent>
  );

  if (reallocateReassignHistories.length > 0) {
    return (
      <ContentWithFooter
        footer={<RecordHistory histories={reallocateReassignHistories} />}
      >
        {content}
      </ContentWithFooter>
    );
  }

  return <ScrollableContent>{content}</ScrollableContent>;
}
