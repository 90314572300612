import { Document as PDFDocument } from "react-pdf";
import styled, { css } from "styled-components";

export const Document = styled(PDFDocument)`
  overflow: visible;

  canvas {
    height: auto !important;
    width: 100% !important;
  }

  ${props => props.cssOverrides && css`
    ${props.cssOverrides}
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  height: 25rem;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  ${props => props.cssOverrides && css`
    ${props.cssOverrides}
  `}

  img {
    margin: auto;
    object-fit: cover;
    object-position: top;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
`;
