import api from "api";
import get from "lodash.get";
import BaseModel from "models/BaseModel";
import moment from "moment";
import { formatLocalTime } from "utils/dateFormatter";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

const IDENTIFICATION_TYPE_LABEL = {
  driver_licence: "Driver licence",
  other: "ID number",
  passport: "Passport",
};

export default class SignatureModel extends BaseModel {
  static updateSignature({
    accessToken,
    attributes,
    entityId,
    onSuccessCallback,
    signature,
  }) {
    const signaturesAPI = api("signatures", accessToken, entityId);
    const onError = error => console.error(error);

    signaturesAPI.updateSignature(
      signature.id,
      attributes,
      onSuccessCallback,
      onError,
    );
  }

  get formattedDOB() {
    if (isPresent(this.dob)) {
      return moment(this.dob).format("DD/MM/YYYY");
    }

    return null;
  }

  get formattedIdentificationExpiryDate() {
    if (isPresent(this.identificationExpiryDate)) {
      return moment(this.identificationExpiryDate).format("DD/MM/YYYY");
    }

    return null;
  }

  get timestampParts() {
    if (isBlank(this.localTimeStamp)) {
      return {};
    }

    const timestamp = moment(this.localTimeStamp);

    return {
      date: timestamp.format("DD/MM/YYYY"),
      time: timestamp.format("HH:mm"),
    };
  }

  get noIdentificationProvided() {
    return this.identificationType === "no_identification";
  }

  get identificationTypeLabel() {
    if (isBlank(this.identificationType)) {
      return IDENTIFICATION_TYPE_LABEL["other"];
    }

    return IDENTIFICATION_TYPE_LABEL[this.identificationType];
  }

  get identificationImageUrl() {
    const url = get(this, "identificationImage.url");

    if (url) {
      return url;
    }

    return null;
  }

  get proofOfAddressUrl() {
    return get(this, "proofOfAddress.url", null);
  }

  get frontFaceImageUrl() {
    return get(this, "frontFaceImage.url", null);
  }

  get isManuallyApproved() {
    // if `manually_approved` is false, it is not being returned by the API
    // so we also have to check for the timestamp
    return this.manuallyApproved || isPresent(this.manuallyApprovedAt);
  }

  get formattedManuallyApprovedAt() {
    return formatLocalTime(this.manuallyApprovedAt, "minute");
  }

  get manuallyApprovedText() {
    if (!this.isManuallyApproved) {
      return null;
    }

    const approvedText = this.manuallyApproved ? "approved" : "declined";

    return `${this.fullName}'s ID has been ${approvedText} by ${this.manuallyApprovedByName} at ${this.formattedManuallyApprovedAt}`;
  }

  saveIdentificationImage({ attributes, currentUser, onSuccessCallback }) {
    this.setAttributes(attributes);

    // eslint-disable-next-line camelcase
    const { identification_image } = this.attributes;

    this.save({
      attributes: { identification_image },
      currentUser,
      onSuccessCallback,
    });
  }

  saveProofOfAddress({ attributes, currentUser, onSuccessCallback }) {
    this.setAttributes(attributes);

    // eslint-disable-next-line camelcase
    const { proof_of_address } = this.attributes;

    this.save({
      attributes: { proof_of_address },
      currentUser,
      onSuccessCallback,
    });
  }

  saveManuallyApprove({ attributes, currentUser, onSuccessCallback }) {
    this.setAttributes(attributes);

    // eslint-disable-next-line camelcase
    const { manually_approved } = this.attributes;

    this.save({
      attributes: { manually_approved },
      currentUser,
      onSuccessCallback,
    });
  }

  save({ attributes, currentUser, onSuccessCallback }) {
    this.isLoading = true;

    const successCallback = result => {
      this.isLoading = false;
      const newSignature = new SignatureModel(
        get(result, "data.data"),
        get(result, "data.included"),
      );

      onSuccessCallback(newSignature);
    };

    SignatureModel.updateSignature({
      accessToken: currentUser.accessToken,
      attributes,
      entityId: get(currentUser, "currentEntity.id"),
      onSuccessCallback: successCallback,
      signature: this,
    });
  }
}
