/* Import libraries */
import React from "react";
import { connect } from "react-redux";

import {
  updateApplicationComplete,
  createAuthorityDeclaration,
} from "../../actions/review";

/* Import components */
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import Button from "modules/shared/components/inputs/Button";
import ReviewRejectTerms from "./ReviewRejectTerms";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
import PdfReaderModal from "modules/shared/components/widgets/static/pdfReaderModal";
import ReviewAgreeModal from "./ReviewAgreeModal";
import logo from "images/1centre-black.png";

import styles from "./css/Review.css";
import { isTokenised } from "../utils";

class ReviewTerms extends React.Component {
  constructor(props) {
    super(props);

    const agreements = [
      { id: 1, agreed: false },
      { id: 2, agreed: false },
      { id: 3, agreed: false },
      { id: 4, agreed: false },
    ];

    if (props.isTokenised) {
      agreements.push({ id: 5, agreed: false });
    }

    this.state = {
      showSorryModal: false,
      agreements,
      isAgreeTooltipActive: false,
      showPdfReaderModal: false,
      showAgreeModal: false,
    };
  }

  disagree = () => {
    this.setState({ showSorryModal: true });
  }

  agree = () => {
    const { dispatch, application, current_entity, submitting } = this.props;
    if (this.completed() && application && !submitting) {
      dispatch(createAuthorityDeclaration(application.id));
      dispatch(
        updateApplicationComplete(
          current_entity.id,
          `/register/consumer/${application.id}/review/complete`,
        ),
      );
    } else {
      this.setState({ isAgreeTooltipActive: true });
    }
  }

  toggleAgreement = (i) =>  {
    let agreements = this.state.agreements.map(function(a) {
      return {
        id: a.id,
        agreed: a.id === i ? !a.agreed : a.agreed,
      };
    });

    this.setState({ agreements });
    this.setState({ isAgreeTooltipActive: false });
  }

  agreements = () => {
    const { entity_type, isTokenised, supplierName, termsUrl } = this.props;
    let agree_text_2 =
      "I confirm that I am authorised to act on behalf of the business identified as the Business Customer and I have the authority to bind such Business Customer";
    let agree_text_1 = "individually and on behalf of the Business Customer";
    if (entity_type === "personal") {
      agree_text_2 =
        "I confirm that I am authorised to bind individually and/or jointly";
      agree_text_1 = "individually and on behalf of the applicant/s";
    }
    return (
      <div>
        <div className={styles.mainDisclaimer}>
          <img className={styles.logo} src={logo} alt="1centre" />
          <p>
            1Centre is the account management software that powers this
            platform. We facilitate the aggregation of data for your supplier to
            make a decision about forming a trading relationship with you.
            Thanks so much and happy trading!
          </p>
        </div>
        <div
          className={
            this.state.agreements[0].agreed ? styles.link_active : styles.link
          }
          onClick={() => this.toggleAgreement(1)}
        >
          I have read and agree to the{" "}
          <a href={termsUrl} target="_blank">
            1Centre Terms & Conditions
          </a>{" "}
          {agree_text_1}
        </div>
        <div
          className={
            this.state.agreements[1].agreed ? styles.link_active : styles.link
          }
          onClick={() => this.toggleAgreement(2)}
        >
          {agree_text_2}
        </div>
        <div
          className={
            this.state.agreements[2].agreed ? styles.link_active : styles.link
          }
          onClick={() => this.toggleAgreement(3)}
        >
          I acknowledge&nbsp;
          <a href={defaults.policy} target="_blank">
            1Centre’s privacy policy
          </a>
          &nbsp;and consent to receiving communications from 1Centre
          electronically
        </div>
        <div
          className={
            this.state.agreements[3].agreed ? styles.link_active : styles.link
          }
          onClick={() => this.toggleAgreement(4)}
        >
          If I give 1Centre information about another person, I confirm I have
          their consent to do this and 1Centre can communicate with them
          electronically
        </div>
        {isTokenised && (
          <div
            className={
              this.state.agreements[4].agreed ? styles.link_active : styles.link
            }
            onClick={() => this.toggleAgreement(5)}
          >
            I confirm that {supplierName} has the right to hold my ID documents on file until my identity has been confirmed.
          </div>
        )}
      </div>
    );
  }

  completed = () => {
    let agreed = true;
    for (let i = 0; i < this.state.agreements.length; i++) {
      agreed = agreed && this.state.agreements[i].agreed;
    }
    return agreed;
  }

  cancelModal = () => {
    this.setState({ showSorryModal: false });
  }

  cancelPdfModal = () => {
    this.setState({ showPdfReaderModal: false });
  }

  cancelAgreeModal = () => {
    this.setState({ showAgreeModal: false });
  }

  showPdfModal = () => {
    this.setState({ showPdfReaderModal: true });
  }

  showAgreePdfModal = () => {
    this.setState({ showAgreeModal: false });
    this.showPdfModal();
  }

  render() {
    const { entity_region, submitting, termsUrl, title } = this.props;
    const {
      haveRead,
      showSorryModal,
      isAgreeTooltipActive,
      showPdfReaderModal,
      showAgreeModal,
    } = this.state;
    let modal, terms_modal, info;

    let info_text =
      "To agree to the terms and conditions you must first view the 1Centre's terms before selecting the 'I agree' checkbox";

    if (showSorryModal) {
      modal = <ReviewRejectTerms handleDismiss={this.cancelModal} />;
    }

    if (showAgreeModal) {
      modal = (
        <ReviewAgreeModal
          handleDismiss={this.cancelAgreeModal}
          okHandler={this.showAgreePdfModal}
          title="Agree checkbox"
          value="1Centre"
        />
      );
    }

    if (showPdfReaderModal) {
      terms_modal = (
        <PdfReaderModal
          url={termsUrl}
          title="1Centre terms and conditions"
          onScrollToBottom={this.onScrollToBottom}
          dismissHandler={this.cancelPdfModal}
        />
      );
    }

    return (
      <div>
        <section className={styles.section}>
          <div className={styles.row}>
            <div className={styles.panel}>
              <PanelTitle text={title} />
              <h3 className={styles.info}>{info}</h3>
            </div>
          </div>
          <div className={styles.row_controls}>
            <div className={styles.control}>
              {this.agreements()}
              {isAgreeTooltipActive && (
                <ToolTip
                  tip_name="ReviewTermsAgree"
                  css_style="review_supplier_terms_agree"
                />
              )}
            </div>
            <div className={styles.buttons}>
              <Button text="disagree" handleClick={this.disagree} />
              <Button
                text="agree"
                loading_text="submitting"
                disableOnLoading={true}
                loading={submitting}
                handleClick={this.agree}
              />
            </div>
          </div>
        </section>
        {modal}
        {terms_modal}
      </div>
    );
  }
}

const defaults = {
  title: "1Centre T&Cs",
  t_and_c_url: {
    AU:
      "https://s3-ap-southeast-2.amazonaws.com/s3-1centre-production/pdfs/files/1centre_terms_document/20180112+FINAL+1Centre+Business+Customer+T+Cs+(00494980-2xE3943).pdf",
    NZ:
      "https://s3-1centre-production.s3-ap-southeast-2.amazonaws.com/pdfs/files/1centre_terms_document/FINAL+2021+-+1Centre+-+NZ+Business+Customer+-+Terms+of+Use.pdf",
  },
  policy: "https://www.1centre.com/Privacy",
};

export default connect((state, ownProps) => {
  const cobBusiness = state.cob_business;
  const cobSection = state.cob_section;
  const region = cobBusiness.entity_region;
  const termsUrl = defaults.t_and_c_url[region];

  return {
    current_entity: state.current_user.current_entity,
    entity_region: region,
    entity_type: cobBusiness.entity_type,
    query_params: state.current_user.query_params,
    submitting: state.cob_review.submitting,
    supplierName: cobSection.application.attributes.supplier_name,
    isTokenised: isTokenised(state),
    termsUrl,
    title: defaults.title,
  };
})(ReviewTerms);
