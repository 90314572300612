export const CNS_INVITE_SET_INVITING_MEMBER_INTENT =
  "CNS_INVITE_SET_INVITING_MEMBER_INTENT";

export const CNS_INVITE_CLEAR_STATE = "CNS_INVITE_CLEAR_STATE";

export const CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START =
  "CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START";
export const CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS =
  "CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS";
export const CNS_INVITE_CREATE_FOR_NEW_CONSUMER_ERROR =
  "CNS_INVITE_CREATE_FOR_NEW_CONSUMER_ERROR";

export const CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_START =
  "CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_START";
export const CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_SUCCESS =
  "CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_SUCCESS";
export const CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_ERROR =
  "CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_ERROR";
export const CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER =
  "CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER";

export const CNS_INVITE_SET_TYPE = "CNS_INVITE_SET_TYPE";

export const CNS_INVITE_SET_RETURN_ROUTE = "CNS_INVITE_SET_RETURN_ROUTE";

export const CNS_INVITE_NZ_COMPANY_SET_NAME = "CNS_INVITE_NZ_COMPANY_SET_NAME";
export const CNS_INVITE_SET_IUF_ANSWERS = "CNS_INVITE_SET_IUF_ANSWERS";
export const CNS_INVITE_NZ_COMPANY_NAME_SELECT =
  "CNS_INVITE_NZ_COMPANY_NAME_SELECT";
export const CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_CLEAR =
  "CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_CLEAR";

export const CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_START =
  "CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_START";
export const CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_SUCCESS =
  "CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_SUCCESS";
export const CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_ERROR =
  "CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_ERROR";

export const CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME =
  "CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME";
export const CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME =
  "CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME";
export const CNS_INVITE_SET_NEW_CONSUMER_EMAIL =
  "CNS_INVITE_SET_NEW_CONSUMER_EMAIL";
export const CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME =
  "CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME";

export const CNS_VALIDATE_UNIQUE_EMAIL_START =
  "CNS_VALIDATE_UNIQUE_EMAIL_START";
export const CNS_VALIDATE_UNIQUE_EMAIL_SUCCESS =
  "CNS_VALIDATE_UNIQUE_EMAIL_SUCCESS";
export const CNS_VALIDATE_UNIQUE_EMAIL_ERROR =
  "CNS_VALIDATE_UNIQUE_EMAIL_ERROR";

/* Supplier onboarding business section constants */
export const CNS_INVITE_SECTION_COMPLETE = "CNS_INVITE_SECTION_COMPLETE";

export const CNS_INVITE_SELECT_ENTITY_TYPE = "CNS_INVITE_SELECT_ENTITY_TYPE";

export const CNS_INVITE_SELECT_ENTITY_PARTY_COUNT =
  "CNS_INVITE_SELECT_ENTITY_PARTY_COUNT";
export const CNS_INVITE_SET_ENTITY_PARTY_DETAILS =
  "CNS_INVITE_SET_ENTITY_PARTY_DETAILS";
export const CNS_INVITE_SET_ENTITY_PARTY_DETAILS_INDEX =
  "CNS_INVITE_SET_ENTITY_PARTY_DETAILS_INDEX";
export const CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_NAME =
  "CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_NAME";
export const CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL =
  "CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL";
export const CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE =
  "CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE";

export const CNS_INVITE_SET_ENTITY_NAME = "CNS_INVITE_SET_ENTITY_NAME";
export const CNS_INVITE_SET_ENTITY_LOGO = "CNS_INVITE_SET_ENTITY_LOGO";
export const CNS_INVITE_SET_STAFF_COUNT = "CNS_INVITE_SET_STAFF_COUNT";
export const CNS_INVITE_SET_PHONE = "CNS_INVITE_SET_PHONE";
export const CNS_INVITE_SET_WEBSITE = "CNS_INVITE_SET_WEBSITE";
export const CNS_INVITE_SET_ADDRESS = "CNS_INVITE_SET_ADDRESS";
export const CNS_INVITE_SET_POSTAL = "CNS_INVITE_SET_POSTAL";

export const CNS_INVITE_COMPONENT_COMPLETE = "CNS_INVITE_COMPONENT_COMPLETE";

export const CNS_INVITE_UPDATE_OTHER_DETAILS_PERCENTAGE =
  "CNS_INVITE_UPDATE_OTHER_DETAILS_PERCENTAGE";

export const CNS_INVITE_SET_SECTION_INDEX = "CNS_INVITE_UPDATE_SECTION";
export const CNS_INVITE_COMPANY_SET_NAME = "CNS_INVITE_COMPANY_SET_NAME";
export const CNS_INVITE_COMPANY_NAME_SELECT = "CNS_INVITE_COMPANY_NAME_SELECT";
export const CNS_INVITE_COMPANY_NAME_LOOKUP_CLEAR =
  "CNS_INVITE_COMPANY_NAME_LOOKUP_CLEAR";

export const CNS_INVITE_COMPANY_NAME_LOOKUP_START =
  "CNS_INVITE_COMPANY_NAME_LOOKUP_START";
export const CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS =
  "CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS";
export const CNS_INVITE_COMPANY_NAME_LOOKUP_ERROR =
  "CNS_INVITE_COMPANY_NAME_LOOKUP_ERROR";
export const CNS_INVITE_RESET_COMPANY_NAME_LOOKUP =
  "CNS_INVITE_RESET_COMPANY_NAME_LOOKUP";

export const CNS_INVITE_NZ_COMPANY_DETAILS_START =
  "CNS_INVITE_NZ_COMPANY_DETAILS_START";
export const CNS_INVITE_NZ_COMPANY_DETAILS_SUCCESS =
  "CNS_INVITE_NZ_COMPANY_DETAILS_SUCCESS";
export const CNS_INVITE_NZ_COMPANY_DETAILS_ERROR =
  "CNS_INVITE_NZ_COMPANY_DETAILS_ERROR";

export const CNS_INVITE_SET_DIRECTORS_CONTAINER_INDEX =
  "CNS_INVITE_SET_DIRECTORS_CONTAINER_INDEX";

export const CNS_INVITE_SET_KEY_CONTACT_FIRST_NAME =
  "CNS_INVITE_SET_KEY_CONTACT_FIRST_NAME";
export const CNS_INVITE_SET_KEY_CONTACT_LAST_NAME =
  "CNS_INVITE_SET_KEY_CONTACT_LAST_NAME";
export const CNS_INVITE_SET_KEY_CONTACT_PHONE =
  "CNS_INVITE_SET_KEY_CONTACT_PHONE";
export const CNS_INVITE_SET_KEY_CONTACT_EMAIL =
  "CNS_INVITE_SET_KEY_CONTACT_EMAIL";

export const CNS_INVITE_UPDATE_KEY_CONTACT_PERCENTAGE =
  "CNS_INVITE_UPDATE_KEY_CONTACT_PERCENTAGE";

export const CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_CLEAR =
  "CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_CLEAR";
export const CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_START =
  "CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_START";
export const CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_SUCCESS =
  "CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_SUCCESS";
export const CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_ERROR =
  "CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_ERROR";

export const CNS_INVITE_POSTAL_ADDRESS_LOOKUP_CLEAR =
  "CNS_INVITE_POSTAL_ADDRESS_LOOKUP_CLEAR";
export const CNS_INVITE_POSTAL_ADDRESS_LOOKUP_START =
  "CNS_INVITE_POSTAL_ADDRESS_LOOKUP_START";
export const CNS_INVITE_POSTAL_ADDRESS_LOOKUP_SUCCESS =
  "CNS_INVITE_POSTAL_ADDRESS_LOOKUP_SUCCESS";
export const CNS_INVITE_POSTAL_ADDRESS_LOOKUP_ERROR =
  "CNS_INVITE_POSTAL_ADDRESS_LOOKUP_ERROR";

export const CNS_INVITE_SET_POSTAL_ADDRESS = "CNS_INVITE_SET_POSTAL_ADDRESS";
export const CNS_INVITE_SET_PHYSICAL_ADDRESS =
  "CNS_INVITE_SET_PHYSICAL_ADDRESS";

export const CNS_INVITE_SELECT_POSTAL_ADDRESS =
  "CNS_INVITE_SELECT_POSTAL_ADDRESS";
export const CNS_INVITE_SELECT_PHYSICAL_ADDRESS =
  "CNS_INVITE_SELECT_PHYSICAL_ADDRESS";

export const CNS_INVITE_GET_USER_PERMISSSION_START =
  "CNS_INVITE_GET_USER_PERMISSSION_START";
export const CNS_INVITE_GET_USER_PERMISSSION_SUCCESS =
  "CNS_INVITE_GET_USER_PERMISSSION_SUCCESS";
export const CNS_INVITE_GET_USER_PERMISSSION_ERROR =
  "CNS_INVITE_GET_USER_PERMISSSION_ERROR";

export const CNS_INVITE_COMPANY_EMAIL_SELECT =
  "CNS_INVITE_COMPANY_EMAIL_SELECT";

export const CNS_INVITE_RESEND_APPLICATION_START =
  "CNS_INVITE_RESEND_APPLICATION_START";
export const CNS_INVITE_RESEND_APPLICATION_SUCCESS =
  "CNS_INVITE_RESEND_APPLICATION_SUCCESS";
export const CNS_INVITE_RESEND_APPLICATION_ERROR =
  "CNS_INVITE_RESEND_APPLICATION_ERROR";

export const CNS_INVITE_RESEND_AUTHORISATION_START =
  "CNS_INVITE_RESEND_AUTHORISATION_START";
export const CNS_INVITE_RESEND_AUTHORISATION_SUCCESS =
  "CNS_INVITE_RESEND_AUTHORISATION_SUCCESS";
export const CNS_INVITE_RESEND_AUTHORISATION_ERROR =
  "CNS_INVITE_RESEND_AUTHORISATION_ERROR";

export const CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START =
  "CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START";
export const CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS =
  "CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS";
export const CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR =
  "CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR";
