export const MANAGE_USERS_TEAM_LOAD_TEAM_START =
  "MANAGE_USERS_TEAM_LOAD_TEAM_START";
export const MANAGE_USERS_TEAM_LOAD_TEAM_SUCCESS =
  "MANAGE_USERS_TEAM_LOAD_TEAM_SUCCESS";
export const MANAGE_USERS_TEAM_LOAD_TEAM_ERROR =
  "MANAGE_USERS_TEAM_LOAD_TEAM_ERROR";

export const MANAGE_USERS_REMOVE_USER_START = "MANAGE_USERS_REMOVE_USER_START";
export const MANAGE_USERS_REMOVE_USER_SUCCESS =
  "MANAGE_USERS_REMOVE_USER_SUCCESS";
export const MANAGE_USERS_REMOVE_USER_ERROR = "MANAGE_USERS_REMOVE_USER_ERROR";

export const MANAGE_USERS_CHECK_EMAIL_START = "MANAGE_USERS_CHECK_EMAIL_START";
export const MANAGE_USERS_CHECK_EMAIL_SUCCESS =
  "MANAGE_USERS_CHECK_EMAIL_SUCCESS";
export const MANAGE_USERS_CHECK_EMAIL_ERROR = "MANAGE_USERS_CHECK_EMAIL_ERROR";

export const MANAGE_USERS_UPDATE_USER_START = "MANAGE_USERS_UPDATE_USER_START";
export const MANAGE_USERS_UPDATE_USER_SUCCESS =
  "MANAGE_USERS_UPDATE_USER_SUCCESS";
export const MANAGE_USERS_UPDATE_USER_ERROR = "MANAGE_USERS_UPDATE_USER_ERROR";
export const MANAGE_USERS_UPDATE_USER_LAST_ADMIN_ERROR = "MANAGE_USERS_UPDATE_USER_LAST_ADMIN_ERROR";

export const MANAGE_USERS_CREATE_USER_START = "MANAGE_USERS_CREATE_USER_START";
export const MANAGE_USERS_CREATE_USER_SUCCESS =
  "MANAGE_USERS_CREATE_USER_SUCCESS";
export const MANAGE_USERS_CREATE_USER_ERROR = "MANAGE_USERS_CREATE_USER_ERROR";

export const MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_START =
  "MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_START";
export const MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_SUCCESS =
  "MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_SUCCESS";
export const MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_ERROR =
  "MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_ERROR";

export const MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_START =
  "MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_START";
export const MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_ERROR =
  "MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_ERROR";
export const MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_SUCCESS =
  "MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_SUCCESS";

export const MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_START =
  "MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_START";
export const MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_SUCCESS =
  "MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_SUCCESS";
export const MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_ERROR =
  "MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_ERROR";

export const MANAGE_USERS_CLEAR_USER_CONTACTS =
  "MANAGE_USERS_CLEAR_USER_CONTACTS";
export const MANAGE_USERS_CLEAR_EMAIL_VALIDATION =
  "MANAGE_USERS_CLEAR_EMAIL_VALIDATION";

export const MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_START =
  "MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_START";
export const MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS =
  "MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS";
export const MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_ERROR =
  "MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_ERROR";

export const MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_START =
  "MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_START";
export const MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_SUCCESS =
  "MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_SUCCESS";
export const MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_ERROR =
  "MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_ERROR";

export const MANAGE_USERS_LOAD_USER_START = "MANAGE_USERS_LOAD_USER_START";
export const MANAGE_USERS_LOAD_USER_SUCCESS = "MANAGE_USERS_LOAD_USER_SUCCESS";
export const MANAGE_USERS_LOAD_USER_ERROR = "MANAGE_USERS_LOAD_USER_ERROR";

export const MANAGE_USERS_LOAD_USERS_BY_ENTITY_START =
  "MANAGE_USERS_LOAD_USERS_BY_ENTITY_START";
export const MANAGE_USERS_LOAD_USERS_BY_ENTITY_SUCCESS =
  "MANAGE_USERS_LOAD_USERS_BY_ENTITY_SUCCESS";
export const MANAGE_USERS_LOAD_USERS_BY_ENTITY_ERROR =
  "MANAGE_USERS_LOAD_USERS_BY_ENTITY_ERROR";

export const MANAGE_USERS_TEAM_LOAD_HEADQUARTER_KEY_CONTACT =
  "MANAGE_USERS_TEAM_LOAD_HEADQUARTER_KEY_CONTACT";
