import React from "react";

/* Import CSS */
import styles from "./css/Review.css";

import LabeledInformation from "../../shared/components/widgets/static/LabeledInformation";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";

var ReviewTradeReferences = createClass({
  render: function() {
    const { data } = this.props;
    let tradeReferences = data.trade_references;
    let tradeReferenceElms = [];
    let last_style = "block_border",
      val;

    tradeReferences.forEach((v, i) => {
      val = i + 1;
      if (tradeReferences.length === val) {
        last_style = "block_last";
      }

      tradeReferenceElms.push(
        <div key={i} className={last_style}>
          <ReviewContainer
            css_class="block_noborder"
            subHeading={"Referee " + (i + 1) + " info (Applicant supplied)"}
          >
            <div className={styles.col}>
              <LabeledInformation
                label="Name"
                data={`${v.attributes.first_name} ${
                  v.attributes.last_name ? v.attributes.last_name : "-"
                }`}
              />
              <LabeledInformation
                label="Referee's business name"
                data={v.attributes.business_name}
              />
              <LabeledInformation
                label="How long have you had a relationship"
                data={v.attributes.relationship_length}
              />
            </div>
            <div className={styles.col}>
              <LabeledInformation
                label="Referee's position"
                data={v.attributes.position}
              />
              <LabeledInformation
                label="Referee's phone number"
                data={
                  v.attributes.contact_country_code +
                  " " +
                  v.attributes.contact_phone_number
                }
              />
              <LabeledInformation
                label="Referee's email"
                data={v.attributes.email}
              />
              <LabeledInformation
                label="How often do you trade with them"
                data={v.attributes.trade_frequency}
              />
            </div>
          </ReviewContainer>
          <ReviewContainer css_class={last_style}>
            <LabeledInformation
              label="Additional comments from applicant?"
              data={v.attributes.notes}
            />
          </ReviewContainer>
        </div>,
      );
    });

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        {tradeReferenceElms}
      </section>
    );
  },
});

module.exports = ReviewTradeReferences;
