import axios from "./axios";;

export default class UserRoles {
  constructor(config) {
    this.axios = axios(config);
    this.type = "user_roles";
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      "v1",
      "v2",
    );
    this.path = `/${this.type}`;
  }

  getSuperAdminAuthorisationLink(id) {
    return this.axios.get(`${this.path}/${id}/super_admin_authorisation_link`);
  }

  resend(id) {
    return this.axios.post(`${this.path}/${id}/resend`);
  }

  updateFilename(id, params) {
    return this.axios.patch(`${this.path}/${id}/update_filename`, params);
  }
}
