import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import React, { useState } from "react";

function Icon(props) {
  const { iconProps, isOpen } = props;

  if (isOpen) {
    return <KeyboardArrowDownIcon {...iconProps} />;
  }

  return <KeyboardArrowRightIcon {...iconProps} />;
}

export default function useCollapsibleTableRowState() {
  const [isOpen, setIsOpen] = useState(false);

  const onToggleIsOpen = () => setIsOpen(!isOpen);

  return {
    icon: (
      <Icon
        iconProps={{
          color: "primary",
          style: { fontSize: 24 },
        }}
        isOpen={isOpen}
      />
    ),
    isOpen,
    onToggleIsOpen,
  };
}
