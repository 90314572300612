import React from "react";
import styles from "./css/ProgressDots.css";

import Clickable from "../interactive/Clickable";

function ProgressDot(props) {
  const { style, section } = props;
  return <li className={styles[style]}>{section}</li>;
};

function ProgressDots(props) {
  const { links, index, type, section } = props;
  const dots = [];

  for (var i = 0; i < links.length; i++) {
    if (i === index) {
      dots.push(<ProgressDot key={i} style="current" />);
    } else {
      var style = "default";
      if (links[i].complete === true) {
        style = "complete";
        if (links[i].hasOwnProperty("clickHandler")) {
          dots.push(
            <Clickable key={i} target={links[i].clickHandler.bind(null, i)}>
              <ProgressDot style={style} section={section} />
            </Clickable>,
          );
        } else {
          dots.push(
            <Clickable key={i} target={links[i].path}>
              <ProgressDot style={style} section={section} />
            </Clickable>,
          );
        }
      } else {
        dots.push(<ProgressDot key={i} style={style} section={section} />);
      }
    }
  }
  return <ul className={styles[type]}>{dots}</ul>;
}

export default ProgressDots;
