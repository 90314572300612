import FinancialsDetails from "modules/addons/financials/components/FinancialsDetails";
import Button from "modules/shared/components/inputs/Button";
import VideoModal from "modules/shared/components/widgets/interactive/VideoModal";
import { isFeatureEditEnabled } from "modules/shared/helpers/headquarterDetect";
import React, { Component } from "react";
import { connect } from "react-redux";

import { loadAddons, setCurrentAddonRuleset } from "../actions";
import { sortedAddonList } from "./../helpers";
import AddonSkeleton from "./AddonSkeleton";
import AddOnsListItem from "./AddOnsListItem";
import commonStyles from "./css/CommonAddOns.css";

class FinancialList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetailPage: false,
      viewing: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadAddons(moduleName));
  }

  startCreate(serialNumber, version) {
    const { dispatch } = this.props;
    if (version) {
      dispatch(setCurrentAddonRuleset(serialNumber, version, moduleName));
    }
    this.setState({ showDetailPage: true });
  }

  handleDiscard() {
    this.setState({
      showDetailPage: false,
      viewing: false,
    });
  }

  startViewing(serialNumber, version) {
    const { dispatch } = this.props;
    if (version) {
      dispatch(setCurrentAddonRuleset(serialNumber, version, moduleName));
    }
    this.setState({
      showDetailPage: true,
      viewing: true,
    });
  }

  renderList() {
    const { configs, activeUpdatingId } = this.props;
    const items = [];

    configs.forEach((config, index) => {
      items.push(
        <AddOnsListItem
          key={`${config.attributes.addon_module_name}_${index}`}
          addonVersions={config.attributes.history_version.data}
          currentAddonData={config}
          onEdit={(serialNumber, currentConfig) => {
            this.startCreate(serialNumber, currentConfig);
          }}
          viewHistory={(serialNumber, version) => {
            this.startViewing(serialNumber, version);
          }}
          updating={config.id === activeUpdatingId}
          disabled={!isFeatureEditEnabled("Financial")}
          addonType={moduleName}
        />,
      );
    });

    return (
      <div>
        <div className={commonStyles.header_container}>
          <span>
            <span className={commonStyles.header}>Set your rules here</span>
            <VideoModal videoName={"financials"} />
          </span>
          {isFeatureEditEnabled("Financial") && (
            <Button
              style={{ fontWeight: "700" }}
              disabled={false}
              text="+ Add"
              handleClick={() => {
                this.startCreate();
              }}
            />
          )}
        </div>
        <div className={commonStyles.container}>
          <ul className={commonStyles.list}>{items}</ul>
        </div>
      </div>
    );
  }

  renderDetails() {
    return (
      <FinancialsDetails
        handleDiscard={() => {
          this.handleDiscard();
        }}
        readOnly={this.state.viewing || !isFeatureEditEnabled("Financial")}
      />
    );
  }

  render() {
    const { loading } = this.props;
    const { showDetailPage } = this.state;
    const contents = showDetailPage ? this.renderDetails() : this.renderList();

    if (loading) {
      return <AddonSkeleton />;
    }

    return (
      <div className={commonStyles.row}>
        <div className={commonStyles.block_wide}>{contents}</div>
      </div>
    );
  }
}

const moduleName = "financials";

export default connect(state => {
  const configs = sortedAddonList(state, moduleName);

  return {
    activeUpdatingId: state.add_ons.financials_active_updating_id,
    configs,
    loading: state.add_ons.financials_loading,
  };
})(FinancialList);
