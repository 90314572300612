import Webhook from "models/webhooks/Webhook";
import Buttons from "modules/shared/components/containers/Buttons";
import Button from "modules/shared/components/inputs/Button";
import EmailInput from "modules/shared/components/inputs/EmailInput";
import TextInput from "modules/shared/components/inputs/TextInput";
import Header from "modules/shared/components/text/Header";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";
import Card from "modules/shared/components/widgets/static/Card";
import CircleModal from "modules/shared/components/widgets/static/CircleModal";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

import styles from "../styles.css";

const EVENT_TYPE_OPTIONS = [
  { label: "Application approved", value: "application_approved" },
  { label: "Alert notification", value: "alert_notification" },
];

class WebhookForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemoveModal: false,
      showRemoveSecret: false,
      webhook: props.webhook,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeWebhook = this.removeWebhook.bind(this);
    this.sendTest = this.sendTest.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this)
    this.onChangeEventType = this.onChangeEventType.bind(this)
    this.onChangeApiVersion = this.onChangeApiVersion.bind(this)
  }

  onChangeEmail(email) {
    const { webhook } = this.state;

    webhook.update({ email });
    this.setState({ webhook });
  }

  onChangeEventType(target) {
    const { webhook } = this.state;

    webhook.update({ event_type: target.value });
    this.setState({ webhook });
  }

  onChangeApiVersion(target) {
    const { webhook } = this.state;

    webhook.update({ api_version: target.value });
    this.setState({ webhook });
  }

  handleChange(e) {
    const data = {};
    const { webhook } = this.state;

    data[e.target.id] = e.target.value;
    webhook.update(data);
    this.setState({ webhook });
  }

  handleBlur() {
    const { webhook } = this.state;
    webhook.validate();
    this.setState({ webhook });
  }

  handleSubmit() {
    const { webhook } = this.state;
    webhook.validate();
    if (webhook.isValid()) {
      webhook.save(this.handleCommit);
    } else {
      this.setState({ webhook });
    }
  }

  removeWebhook() {
    const { webhook } = this.state;
    webhook.delete(this.handleCommit);
  }

  handleCommit() {
    browserHistory.push("/dashboard/connected-apps/webhooks");
  }

  showRemoveModal(showRemoveModal) {
    this.setState({ showRemoveModal });
  }

  removeModal() {
    const { showRemoveModal } = this.state;
    if (showRemoveModal) {
      return (
        <CircleModal
          title="Remove webhook"
          dismissHandler={this.showRemoveModal.bind(this, false)}
        >
          <p>
            By deleting this web hook no further information will be sent to
            this app.
          </p>
          <Button small blue text="remove" handleClick={this.removeWebhook} />
        </CircleModal>
      );
    }
  }

  removeButton() {
    const { webhook } = this.state;
    if (webhook.isNew()) {
      return null;
    }
    return (
      <Button
        small
        red
        text="remove"
        handleClick={this.showRemoveModal.bind(this, true)}
      />
    );
  }

  sendTest() {
    const { webhook } = this.state;
    webhook.sendTest();
  }

  existingButtons() {
    const { webhook, testing } = this.state;
    if (webhook.isNew()) {
      return null;
    }

    return (
      <Buttons>
        <Button
          small
          grey
          text="send a test message"
          loading={testing}
          disableOnLoading
          loading_text="testing"
          handleClick={this.sendTest}
        />
      </Buttons>
    );
  }

  render() {
    const { webhook } = this.state;
    const { updating } = this.props;

    let title = "Update webhook";
    let saveBtnLabel = "update";
    let backBtnLabel = "back";

    if (webhook.isNew()) {
      title = "Create webhook";
      saveBtnLabel = "save";
      backBtnLabel = "cancel";
    }

    return (
      <Card>
        <div className={styles.row}>
          <div className={styles.header_col}>
            <Header>{title}</Header>
          </div>
          <div className={styles.header_btn_col}>
            <Buttons>
              <Button
                small
                text={saveBtnLabel}
                loading_text="saving"
                loading={updating}
                disableOnLoading
                handleClick={this.handleSubmit}
              />
              <Button
                small
                grey
                text={backBtnLabel}
                link="/dashboard/connected-apps/webhooks"
              />
              {this.removeButton()}
            </Buttons>
          </div>
        </div>
        <form
          className={styles.two_column_form}
          onSubmit={e => e.preventDefault()}
        >
          <TextInput
            id="url"
            error={webhook.errors.url}
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            label="App URL"
            required={true}
            value={webhook.attributes.url}
          />
          <TextInput
            id="name"
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            label="App name"
            required={true}
            value={webhook.attributes.name}
          />
          <OptionsDropdown
            id="event_type"
            name="event_type"
            label="Event type"
            error={webhook.errors.event_type}
            value={webhook.attributes.event_type}
            handleChange={this.onChangeEventType}
            handleBlur={this.handleBlur}
            required={true}
            options={EVENT_TYPE_OPTIONS}
          />
          <OptionsDropdown
            id="api_version"
            name="api_version"
            label="API version"
            error={webhook.errors.api_version}
            value={webhook.attributes.api_version}
            handleChange={this.onChangeApiVersion}
            handleBlur={this.handleBlur}
            required={true}
            options={Webhook.API_VERSIONS}
          />
          <EmailInput
            id="email"
            label="Email"
            name="email"
            type="email"
            value={webhook.attributes.email}
            helper_text="Exception notifications will be sent to this email. (This field is optional)"
            handleChange={this.onChangeEmail}
            handleBlur={this.handleBlur}
          />
          <TextInput id="secret" label="Signing secret" readOnly value={webhook.attributes.secret} type={"password"} />
          <TextInput
            id="hook_user_name"
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            label="X-Hook-Username"
            required={false}
            value={webhook.attributes.hook_user_name}
          />
          <TextInput
            type={"password"}
            id="hook_user_password"
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            label="X-Hook-Password"
            required={false}
            value={webhook.attributes.hook_user_password}
          />

          {this.existingButtons() }
        </form>
        {this.removeModal()}
      </Card>
    );
  }
}

module.exports = connect(state => {
  return {
    testing: state.models.webhooks.testing,
    updating: state.models.webhooks.updating,
  };
})(WebhookForm);
