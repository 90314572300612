import Abstract from "../modules/paperless-authorisation/components/Abstract";
import Authorisation from "../modules/paperless-authorisation/components/Authorisation";
import Complete from "../modules/paperless-authorisation/components/Complete";
import PaperlessAuthorisation from "../modules/paperless-authorisation/components/PaperlessAuthorisation";
import Layout from "../modules/paperless-authorisation/container/Layout";

const PaperlessAuthorisationRoutes = [
  {
    component: Abstract,
    path: "abstract",
  },
  {
    component: Authorisation,
    path: "authorisation",
  },
  {
    component: Complete,
    path: "complete",
  },

  {
    component: PaperlessAuthorisation,
    path: ":paperless_authorisation_id/:component(/**)",
  },
];

module.exports = {
  childRoutes: PaperlessAuthorisationRoutes,
  component: Layout,
  path: "paperless-authorisation",
};
