import axios from "./axios";;

let TradeReferences = function(config) {
  this.axios = axios(config);
  this.type = "trade_references";
};

TradeReferences.prototype.createTradeRef = function(
  application_id,
  attributes,
  success,
  error,
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
        relationships: {
          application: {
            data: {
              type: "applications",
              id: application_id,
            },
          },
        },
      },
    })
    .then(success)
    .catch(error);
};

TradeReferences.prototype.updateTradeRef = function(
  id,
  attributes,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

TradeReferences.prototype.getTradeReference = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

TradeReferences.prototype.delete = function(id, success, error, options = {}) {
  return this.axios
    .delete(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

export default TradeReferences;
