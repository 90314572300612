import axios from "./axios";
import { transformRequest, transformResponse } from "utils/axios_transforms";
import { requestInterceptor } from "utils/axios_interceptors";

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#applications-creating-an-application
 */
const Applications = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = "applications";

  this.axios.interceptors.request.use(
    request => requestInterceptor("Applications API", request),
    error => Promise.reject(error),
  );
};

Applications.prototype.createApplication = function(
  attributes,
  success,
  error,
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        attributes,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Applications.prototype.updateApplication = function(
  id,
  attributes,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        attributes,
        id,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Applications.prototype.getApplicationReviews = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}/reviews`, options)
    .then(success)
    .catch(error);
};

Applications.prototype.getApplication = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

Applications.prototype.getApplications = function(
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

//
// Await-able functions
// The following function allows the use of async/await
//
Applications.prototype.awaitableGetApplication = function(id, options = {}) {
  return this.axios.get(`/${this.type}/${id}`, options);
};

Applications.prototype.setSupplierContact = function(
  id,
  contactId,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        id,
        relationships: {
          supplier_contact: {
            data: {
              id: contactId,
              type: "users",
            },
          },
        },
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Applications.prototype.setConsumerContact = function(
  id,
  contactId,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        id,
        relationships: {
          consumer_contact: {
            data: {
              id: contactId,
              type: "users",
            },
          },
        },
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Applications.prototype.getApplicationPDF = function(id, success, error) {
  return this.axios
    .get(`/${this.type}/${id}/pdf`)
    .then(success)
    .catch(error);
};

Applications.prototype.deleteApplication = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

Applications.prototype.getConnectionId = function(id, success, error) {
  return this.axios
    .get(`/${this.type}/${id}/connection_id`)
    .then(success)
    .catch(error);
};

Applications.prototype.exportApplications = function(
  success,
  error,
  options = {},
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .get(`/${this.type}/export`, options)
    .then(success)
    .catch(error);
};

Applications.prototype.getApprovalInProgressCount = function(success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .get(`/${this.type}/approval_in_progress`)
    .then(success)
    .catch(error);
};

Applications.prototype.getApprovalHierarchy = function(id, success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .get(`/${this.type}/${id}/approval_hierarchy`)
    .then(success)
    .catch(error);
};

Applications.prototype.businessConsumerSubmission = function(
  id,
  success,
  error,
  options = {},
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .patch(`/${this.type}/${id}/business_consumer_submission`, options)
    .then(success)
    .catch(error);
};

Applications.prototype.getApplicationCreditStatus = function(
  id,
  success,
  error,
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .get(`/${this.type}/${id}/credit_status`)
    .then(success)
    .catch(error);
};

Applications.prototype.updateApplicationAndGetAddonRules = function(
  id,
  options = {},
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios.patch(
    `/${this.type}/${id}/update_application_get_addon_rules`,
    options,
  );
};

Applications.prototype.getCardholders = function(id) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  this.axios.defaults.transformResponse = [transformResponse];
  this.axios.defaults.transformRequest = [transformRequest];

  return this.axios.get(`/${this.type}/${id}/cardholders`);
};

Applications.prototype.getAppColorPalette = function(id) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios.get(`${this.type}/${id}/color_palette`);
};

Applications.prototype.getPendingFromSupplier = function(options) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  const params = `?email=${options.email}&consumer_id=${options.consumerId}&supplier_id=${options.supplierId}`;
  return this.axios.get(`${this.type}/pending_from_supplier${params}`);
};

Applications.prototype.reallocation = function(id, params) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios.post(`${this.type}/${id}/reallocation`, params);
};

Applications.prototype.reallocateAndReassign = function(id, params) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios.patch(`${this.type}/${id}/reallocate_and_reassign`, params);
};

Applications.prototype.patchConsumerTradingNames = function(id, params) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios.patch(`${this.type}/${id}/consumer_trading_names`, params);
};

Applications.prototype.getSuperAdminApplicationLink = function(id) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");

  return this.axios.get(`${this.type}/${id}/super_admin_application_link`);
};

Applications.prototype.toInProgress = function(id) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");

  return this.axios.patch(`${this.type}/${id}/to_in_progress`);
};

export default Applications;
