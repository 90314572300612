export const CONNECTIONS_ALL_CONNECTIONS_LIST_START =
  "CONNECTIONS_ALL_CONNECTIONS_LIST_START";
export const CONNECTIONS_ALL_CONNECTIONS_LIST_SUCCESS =
  "CONNECTIONS_ALL_CONNECTIONS_LIST_SUCCESS";
export const CONNECTIONS_ALL_CONNECTIONS_LIST_ERROR =
  "CONNECTIONS_ALL_CONNECTIONS_LIST_ERROR";

export const CONNECTIONS_CONNECTION_START = "CONNECTIONS_CONNECTION_START";
export const CONNECTIONS_CONNECTION_SUCCESS = "CONNECTIONS_CONNECTION_SUCCESS";
export const CONNECTIONS_CONNECTION_ERROR = "CONNECTIONS_CONNECTION_ERROR";

export const CONNECTIONS_CREATE_STOP_CREDIT_START =
  "CONNECTIONS_CREATE_STOP_CREDIT_START";
export const CONNECTIONS_CREATE_STOP_CREDIT_SUCCESS =
  "CONNECTIONS_CREATE_STOP_CREDIT_SUCCESS";
export const CONNECTIONS_CREATE_STOP_CREDIT_ERROR =
  "CONNECTIONS_CREATE_STOP_CREDIT_ERROR";

export const CONNECTIONS_CHECK_FOR_STOP_CREDIT_START =
  "CONNECTIONS_CHECK_FOR_STOP_CREDIT_START";
export const CONNECTIONS_CHECK_FOR_STOP_CREDIT_SUCCESS =
  "CONNECTIONS_CHECK_FOR_STOP_CREDIT_SUCCESS";
export const CONNECTIONS_CHECK_FOR_STOP_CREDIT_ERROR =
  "CONNECTIONS_CHECK_FOR_STOP_CREDIT_ERROR";

export const CONNECTIONS_REMOVE_STOP_CREDIT_START =
  "CONNECTIONS_REMOVE_STOP_CREDIT_START";
export const CONNECTIONS_REMOVE_STOP_CREDIT_SUCCESS =
  "CONNECTIONS_REMOVE_STOP_CREDIT_SUCCESS";
export const CONNECTIONS_REMOVE_STOP_CREDIT_ERROR =
  "CONNECTIONS_REMOVE_STOP_CREDIT_ERROR";

export const CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_START =
  "CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_START";
export const CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS =
  "CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS";
export const CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_ERROR =
  "CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_ERROR";

export const CONNECTIONS_CONTACT_CANDIDATES_LIST_START =
  "CONNECTIONS_CONTACT_CANDIDATES_LIST_START";
export const CONNECTIONS_CONTACT_CANDIDATES_LIST_SUCCESS =
  "CONNECTIONS_CONTACT_CANDIDATES_LIST_SUCCESS";
export const CONNECTIONS_CONTACT_CANDIDATES_LIST_ERROR =
  "CONNECTIONS_CONTACT_CANDIDATES_LIST_ERROR";

export const CONNECTIONS_CREATE_NOTES_START = "CONNECTIONS_CREATE_NOTES_START";
export const CONNECTIONS_CREATE_NOTES_SUCCESS =
  "CONNECTIONS_CREATE_NOTES_SUCCESS";
export const CONNECTIONS_CREATE_NOTES_ERROR = "CONNECTIONS_CREATE_NOTES_ERROR";

export const CONNECTIONS_LOAD_NOTES_START = "CONNECTIONS_LOAD_NOTES_START";
export const CONNECTIONS_LOAD_NOTES_SUCCESS = "CONNECTIONS_LOAD_NOTES_SUCCESS";
export const CONNECTIONS_LOAD_NOTES_ERROR = "CONNECTIONS_LOAD_NOTES_ERROR";

export const CONNECTIONS_SET_REGION = "CONNECTIONS_SET_REGION";
export const CONNECTIONS_SET_STATE = "CONNECTIONS_SET_STATE";

export const CONNECTIONS_CONNECTION_APPLICATIONS_LIST_START =
  "CONNECTIONS_CONNECTION_APPLICATIONS_LIST_START";
export const CONNECTIONS_CONNECTION_APPLICATIONS_LIST_SUCCESS =
  "CONNECTIONS_CONNECTION_APPLICATIONS_LIST_SUCCESS";
export const CONNECTIONS_CONNECTION_APPLICATIONS_LIST_ERROR =
  "CONNECTIONS_CONNECTION_APPLICATIONS_LIST_ERROR";
