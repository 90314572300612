import { FontAwesomeIcon as OriFA } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { FONT_SIZES } from "variables/typography";

export const FontAwesomeIcon = styled(OriFA)`
  color: var(--main-color);
`

export const Status = styled.span`
  font-size: ${FONT_SIZES.base};
`;