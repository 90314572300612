import get from "lodash.get";
import isEmpty from "lodash.isempty";
import TradeReferenceModel from "models/TradeReferenceModel";
import commonStyles from "modules/new-applications/css/common.css";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import FixedContent from "modules/shared/components/containers/FixedContent";
import GridContent from "modules/shared/components/containers/GridContent";
import ScrollableContent from "modules/shared/components/containers/ScrollableContent";
import BorderedTextArea from "modules/shared/components/inputs/BorderedTextArea";
import Button from "modules/shared/components/inputs/Button";
import LabeledContent from "modules/shared/components/widgets/static/LabeledContent";
import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import * as yup from "yup";

const manualTradeReferenceCheckFormSchema = yup.object().shape({
  note: yup.string().required("Please add a note"),
});

function isCheckComplete(isFated, checkOutcome) {
  return isFated || isPresent(checkOutcome);
}

function Outcome(props) {
  const { check } = props;

  if (isBlank(check.outcome)) {
    return null;
  }

  return `This trade reference has been ${check.formattedManualOutcome} by ${check.reviewerName} at ${check.formattedReviewDate}`;
}

function ApplicantSupplied(props) {
  const { tradeReference } = props;

  return (
    <GridContent>
      <div>
        <LabeledContent label="Name" content={tradeReference.formattedName} />
        <LabeledContent
          label="Referee's business name"
          content={tradeReference.businessName}
        />
      </div>
      <div>
        <LabeledContent
          label="Referee's phone number"
          content={tradeReference.formattedPhoneNumber}
        />
        <LabeledContent
          label="Referee's email"
          content={tradeReference.email}
        />
      </div>
    </GridContent>
  );
}

function ManualTradeReference(props) {
  const {
    index,
    application,
    currentUser,
    tradeReference,
    onFetchApplicationRecord,
  } = props;
  const check = tradeReference.tradeReferenceCheck;
  const isComplete = isCheckComplete(application.isFated, check.outcome);
  const {
    isLoading: isFailButtonLoading,
    setIsLoading: setIsFailbuttonLoading,
  } = useIsLoadingState();

  const {
    isLoading: isPassButtonLoading,
    setIsLoading: setIsPassbuttonLoading,
  } = useIsLoadingState();

  const { errors, handleSubmit, register } = useForm({
    defaultValues: {
      note: tradeReference.notes,
    },
    mode: "onBlur",
    validationSchema: manualTradeReferenceCheckFormSchema,
  });

  const onSuccessCallback = () => {
    setIsFailbuttonLoading(false);
    setIsPassbuttonLoading(false);

    onFetchApplicationRecord();
  };

  const onSubmit = (outcome, setLoadingCallback) => {
    handleSubmit(data => {
      setLoadingCallback(true);

      tradeReference.updateTradeReferenceStatus({
        attributes: {
          notes: data.note,
          outcome,
        },
        currentUser,
        onSuccessCallback,
      });
    })();
  };

  const onClickFail = () => {
    onSubmit("failed", setIsFailbuttonLoading);
  };
  const onClickPass = () => {
    onSubmit("passed", setIsPassbuttonLoading);
  };

  const isDisabled =
    isFailButtonLoading ||
    isPassButtonLoading ||
    application.isFated ||
    !application.isSubmitted;

  let buttons = null;
  if (!isComplete) {
    buttons = (
      <div className={commonStyles.buttons}>
        <Button
          loading={isFailButtonLoading}
          disabled={isDisabled}
          grey
          text="Fail"
          handleClick={onClickFail}
          style={{
            marginRight: 60,
          }}
        />
        <Button
          loading={isPassButtonLoading}
          disabled={isDisabled}
          text="Pass"
          handleClick={onClickPass}
        />
      </div>
    );
  }

  return (
    <FixedContent header={`Referee ${index}`}>
      <ApplicantSupplied tradeReference={tradeReference} />
      <GridContent>
        <div>
          <BorderedTextArea
            inputRef={register}
            name="note"
            disabled={isComplete || isDisabled}
            label="Notes"
            placeholder="Notes"
            error={Boolean(errors.note)}
            helperText={get(errors, "note.message", " ")}
          />
          {buttons}
          <Outcome check={check} />
        </div>
      </GridContent>
    </FixedContent>
  );
}

/** TODO: attachment */
function Notes(props) {
  const { notes } = props;

  return notes.map((note, index) => (
    <div key={`note-${index}`}>
      {note.text} ({note.formattedCreatedAt})
    </div>
  ));
}

function TRMTradeRefernce(props) {
  const { index, tradeReference } = props;
  const tradeReferenceCheck = tradeReference.tradeReferenceCheck;

  return (
    <Fragment>
      <FixedContent
        header={`Referee ${index} (applicant supplied)`}
        withBottomSeparator={false}
      >
        <ApplicantSupplied tradeReference={tradeReference} />
      </FixedContent>
      <FixedContent
        header={`Referee ${index} (referee supplied)`}
        withBottomSeparator={false}
      >
        <GridContent>
          <div>
            <LabeledContent
              label="Referee name"
              content={tradeReferenceCheck.refereeName}
            />
            <LabeledContent
              label="Relationship to applicant"
              content={tradeReferenceCheck.relationshipType}
            />
            <LabeledContent
              label="Credit limit was extended to the applicant"
              content={tradeReferenceCheck.formattedCreditLimit}
            />
            <LabeledContent
              label="How much is currently owed to you by applicant?"
              content={tradeReferenceCheck.formattedAmountOwed}
            />
          </div>
          <div>
            <LabeledContent
              label="How long have you had a relationship"
              content={tradeReferenceCheck.formattedRelationshipLength}
            />
            <LabeledContent
              label="What date was the last purchase made by the applicant"
              content={tradeReferenceCheck.formattedLastPurchase}
            />
            <LabeledContent
              label="What are the applicant's payment habits"
              content={tradeReferenceCheck.formattedPaymentHabits}
            />
          </div>
        </GridContent>
      </FixedContent>
      <FixedContent header="Outcome">
        <GridContent>
          <LabeledContent
            label="Reference outcome"
            content={tradeReferenceCheck.formattedTRMOutcome}
          />
          <LabeledContent label="Notes">
            <Notes notes={tradeReferenceCheck.notes} />
          </LabeledContent>
        </GridContent>
      </FixedContent>
    </Fragment>
  );
}

export default function TradeReferences(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;
  if (!application && !application.id) {
    return;
  }

  let TradeReferenceComponent = ManualTradeReference;

  if (application.requiresTradeReferenceCheck) {
    TradeReferenceComponent = TRMTradeRefernce;
  }

  let tradeReferences = application.activeTradeReferences;

  if (isEmpty(tradeReferences)) {
    tradeReferences = Array.from(
      { length: application.minimumTradeReferences },
      () => new TradeReferenceModel(),
    );
  }

  const tradeReferenceComponents = tradeReferences.map((tradeReference, i) => (
    <TradeReferenceComponent
      key={`trade-reference-${tradeReference.id}`}
      index={i + 1}
      application={application}
      currentUser={currentUser}
      tradeReference={tradeReference}
      onFetchApplicationRecord={onFetchApplicationRecord}
    />
  ));

  return <ScrollableContent>{tradeReferenceComponents}</ScrollableContent>;
}
