import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#subscriptions-creating-subscriptions
 */
let Subscriptions = function(config) {
  this.axios = axios(config);
  this.type = "subscriptions";
};

Subscriptions.prototype.getStripeSubscription = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

export default Subscriptions;
