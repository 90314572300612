import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#connections-viewing-consumer-connections-where-user-is-key-contact
 */
let Connections = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = "connections";
};

Connections.prototype.getConnection = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

Connections.prototype.getConnections = function(success, error, options = {}) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

Connections.prototype.setSupplierContact = function(
  id,
  contactId,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        relationships: {
          supplier_contact: {
            data: {
              type: "users",
              id: contactId,
            },
          },
        },
      },
    })
    .then(success)
    .catch(error);
};

Connections.prototype.setConsumerContact = function(
  id,
  contactId,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        relationships: {
          consumer_contact: {
            data: {
              type: "users",
              id: contactId,
            },
          },
        },
      },
    })
    .then(success)
    .catch(error);
};

export default Connections;
