export const APPLICATIONS_SUPPLIER_LOAD_LIST_START =
  "APPLICATIONS_SUPPLIER_LOAD_LIST_START";
export const APPLICATIONS_SUPPLIER_LOAD_LIST_SUCCESS =
  "APPLICATIONS_SUPPLIER_LOAD_LIST_SUCCESS";
export const APPLICATIONS_SUPPLIER_LOAD_LIST_ERROR =
  "APPLICATIONS_SUPPLIER_LOAD_LIST_ERROR";

export const APPLICATIONS_CONSUMER_LOAD_LIST_START =
  "APPLICATIONS_CONSUMER_LOAD_LIST_START";
export const APPLICATIONS_CONSUMER_LOAD_LIST_SUCCESS =
  "APPLICATIONS_CONSUMER_LOAD_LIST_SUCCESS";
export const APPLICATIONS_CONSUMER_LOAD_LIST_ERROR =
  "APPLICATIONS_CONSUMER_LOAD_LIST_ERROR";

export const APPLICATIONS_SUPPLIER_USER_LOAD_LIST_START =
  "APPLICATIONS_SUPPLIER_USER_LOAD_LIST_START";
export const APPLICATIONS_SUPPLIER_USER_LOAD_LIST_SUCCESS =
  "APPLICATIONS_SUPPLIER_USER_LOAD_LIST_SUCCESS";
export const APPLICATIONS_SUPPLIER_USER_LOAD_LIST_ERROR =
  "APPLICATIONS_SUPPLIER_USER_LOAD_LIST_ERROR";

export const APPLICATIONS_CONSUMER_USER_LOAD_LIST_START =
  "APPLICATIONS_CONSUMER_USER_LOAD_LIST_START";
export const APPLICATIONS_CONSUMER_USER_LOAD_LIST_SUCCESS =
  "APPLICATIONS_CONSUMER_USER_LOAD_LIST_SUCCESS";
export const APPLICATIONS_CONSUMER_USER_LOAD_LIST_ERROR =
  "APPLICATIONS_CONSUMER_USER_LOAD_LIST_ERROR";

export const APPLICATIONS_REVIEW_LOAD_START = "APPLICATIONS_REVIEW_LOAD_START";
export const APPLICATIONS_REVIEW_LOAD_SUCCESS =
  "APPLICATIONS_REVIEW_LOAD_SUCCESS";
export const APPLICATIONS_REVIEW_LOAD_ERROR = "APPLICATIONS_REVIEW_LOAD_ERROR";

export const APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_STARTED =
  "APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_STARTED";
export const APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_SUCCESS =
  "APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_SUCCESS";
export const APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_ERROR =
  "APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_ERROR";
export const APPLICATIONS_REVIEW_BUSINESS_PEOPLE_DATA_LOAD_SUCCESS =
  "APPLICATIONS_REVIEW_BUSINESS_PEOPLE_DATA_LOAD_SUCCESS";

export const APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_START =
  "APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_START";
export const APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_SUCCESS =
  "APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_SUCCESS";
export const APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_ERROR =
  "APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_ERROR";

export const APPLICATIONS_CLEAR_REVIEW_DATA = "APPLICATIONS_CLEAR_REVIEW_DATA";

export const APPLICATIONS_SET_REVIEW_INDEX = "APPLICATIONS_SET_REVIEW_INDEX";

export const APPLICATIONS_SET_REVIEW_DECLINE_NOTES_DATA =
  "APPLICATIONS_SET_REVIEW_DECLINE_NOTES_DATA";
export const APPLICATIONS_SET_REVIEW_ACCEPT_NOTES_DATA =
  "APPLICATIONS_SET_REVIEW_ACCEPT_NOTES_DATA";

export const APPLICATIONS_DECLINE_SUBMIT_START =
  "APPLICATIONS_DECLINE_SUBMIT_START";
export const APPLICATIONS_DECLINE_SUBMIT_SUCCESS =
  "APPLICATIONS_DECLINE_SUBMIT_SUCCESS";
export const APPLICATIONS_DECLINE_SUBMIT_ERROR =
  "APPLICATIONS_DECLINE_SUBMIT_ERROR";

export const APPLICATIONS_ACCEPT_SUBMIT_START =
  "APPLICATIONS_ACCEPT_SUBMIT_START";
export const APPLICATIONS_ACCEPT_SUBMIT_SUCCESS =
  "APPLICATIONS_ACCEPT_SUBMIT_SUCCESS";
export const APPLICATIONS_ACCEPT_SUBMIT_ERROR =
  "APPLICATIONS_ACCEPT_SUBMIT_ERROR";

export const APPLICATIONS_REVIEW_SET_ACCOUNT_LIMIT =
  "APPLICATIONS_REVIEW_SET_ACCOUNT_LIMIT";
export const APPLICATIONS_REVIEW_SET_TRADE_DISCOUNT =
  "APPLICATIONS_REVIEW_SET_TRADE_DISCOUNT";

export const APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_START =
  "APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_START";
export const APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_SUCCESS =
  "APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_SUCCESS";
export const APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_ERROR =
  "APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_ERROR";

export const APPLICATIONS_DOWNLOAD_CSV_START =
  "APPLICATIONS_DOWNLOAD_CSV_START";
export const APPLICATIONS_DOWNLOAD_CSV_SUCCESS =
  "APPLICATIONS_DOWNLOAD_CSV_SUCCESS";
export const APPLICATIONS_DOWNLOAD_CSV_ERROR =
  "APPLICATIONS_DOWNLOAD_CSV_ERROR";

export const APPLICATIONS_EXPORT_START = "APPLICATIONS_EXPORT_START";
export const APPLICATIONS_EXPORT_SUCCESS = "APPLICATIONS_EXPORT_SUCCESS";
export const APPLICATIONS_EXPORT_ERROR = "APPLICATIONS_EXPORT_ERROR";

export const APPLICATIONS_WEBSITEBUTTON_SEND_START =
  "APPLICATIONS_WEBSITEBUTTON_SEND_START";
export const APPLICATIONS_WEBSITEBUTTON_SEND_SUCCESS =
  "APPLICATIONS_WEBSITEBUTTON_SEND_SUCCESS";
export const APPLICATIONS_WEBSITEBUTTON_SEND_ERROR =
  "APPLICATIONS_WEBSITEBUTTON_SEND_ERROR";

export const APPLICATIONS_UPLOAD_ID_START = "APPLICATIONS_UPLOAD_ID_START";
export const APPLICATIONS_UPLOAD_ID_SUCCESS = "APPLICATIONS_UPLOAD_ID_SUCCESS";
export const APPLICATIONS_UPLOAD_ID_ERROR = "APPLICATIONS_UPLOAD_ID_ERROR";

export const APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_START =
  "APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_START";
export const APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_SUCCESS =
  "APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_SUCCESS";
export const APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_ERROR =
  "APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_ERROR";

export const APPLICATIONS_CREATE_NOTES_START =
  "APPLICATIONS_CREATE_NOTES_START";
export const APPLICATIONS_CREATE_NOTES_SUCCESS =
  "APPLICATIONS_CREATE_NOTES_SUCCESS";
export const APPLICATIONS_CREATE_NOTES_ERROR =
  "APPLICATIONS_CREATE_NOTES_ERROR";

export const APPLICATIONS_LOAD_NOTES_START = "APPLICATIONS_LOAD_NOTES_START";
export const APPLICATIONS_LOAD_NOTES_SUCCESS =
  "APPLICATIONS_LOAD_NOTES_SUCCESS";
export const APPLICATIONS_LOAD_NOTES_ERROR = "APPLICATIONS_LOAD_NOTES_ERROR";

export const APPLICATIONS_GET_CONNECTION_ID_SUCCESS =
  "APPLICATIONS_GET_CONNECTION_ID_SUCCESS";
export const APPLICATIONS_REVIEW_UPDATE_CREDIT_CHECKS =
  "APPLICATIONS_REVIEW_UPDATE_CREDIT_CHECKS";

export const APPLICATIONS_SET_TRADE_REFERENCE_STATUS_START =
  "APPLICATIONS_SET_TRADE_REFERENCE_STATUS_START";
export const APPLICATIONS_SET_TRADE_REFERENCE_STATUS_SUCCESS =
  "APPLICATIONS_SET_TRADE_REFERENCE_STATUS_SUCCESS";
export const APPLICATIONS_SET_TRADE_REFERENCE_STATUS_ERROR =
  "APPLICATIONS_SET_TRADE_REFERENCE_STATUS_ERROR";

export const APPLICATIONS_SET_SIGNATURE_STATUS_START =
  "APPLICATIONS_SET_SIGNATURE_STATUS_START";
export const APPLICATIONS_SET_SIGNATURE_STATUS_SUCCESS =
  "APPLICATIONS_SET_SIGNATURE_STATUS_SUCCESS";
export const APPLICATIONS_SET_SIGNATURE_STATUS_ERROR =
  "APPLICATIONS_SET_SIGNATURE_STATUS_ERROR";

export const APPLICATIONS_SET_GUARANTOR_ID_STATUS_START =
  "APPLICATIONS_SET_GUARANTOR_ID_STATUS_START";
export const APPLICATIONS_SET_GUARANTOR_ID_STATUS_SUCCESS =
  "APPLICATIONS_SET_GUARANTOR_ID_STATUS_SUCCESS";
export const APPLICATIONS_SET_GUARANTOR_ID_STATUS_ERROR =
  "APPLICATIONS_SET_GUARANTOR_ID_STATUS_ERROR";
export const APPLICATIONS_LOAD_APPROVAL_LEVELS_START =
  "APPLICATIONS_LOAD_APPROVAL_LEVELS_START";
export const APPLICATIONS_LOAD_APPROVAL_LEVELS_SUCCESS =
  "APPLICATIONS_LOAD_APPROVAL_LEVELS_SUCCESS";
export const APPLICATIONS_LOAD_APPROVAL_LEVELS_ERROR =
  "APPLICATIONS_LOAD_APPROVAL_LEVELS_ERROR";

export const APPLICATIONS_LOAD_CREDIT_STATUS_START =
  "APPLICATIONS_LOAD_CREDIT_STATUS_START";
export const APPLICATIONS_LOAD_CREDIT_STATUS_SUCCESS =
  "APPLICATIONS_LOAD_CREDIT_STATUS_SUCCESS";
export const APPLICATIONS_LOAD_CREDIT_STATUS_ERROR =
  "APPLICATIONS_LOAD_CREDIT_STATUS_ERROR";
export const APPLICATIONS_LOAD_CREDIT_CHECKS_START =
  "APPLICATIONS_LOAD_CREDIT_CHECKS_START";
export const APPLICATIONS_LOAD_CREDIT_CHECKS_SUCCESS =
  "APPLICATIONS_LOAD_CREDIT_CHECKS_SUCCESS";
export const APPLICATIONS_LOAD_CREDIT_CHECKS_ERROR =
  "APPLICATIONS_LOAD_CREDIT_CHECKS_ERROR";

export const APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_START =
  "APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_START";
export const APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_SUCCESS =
  "APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_SUCCESS";
export const APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_ERROR =
  "APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_ERROR";

export const APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_START =
  "APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_START";
export const APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_SUCCESS =
  "APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_SUCCESS";
export const APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_ERROR =
  "APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_ERROR";

export const APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_START =
  "APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_START";
export const APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_SUCCESS =
  "APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_SUCCESS";
export const APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_ERROR =
  "APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_ERROR";
