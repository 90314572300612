/* Import libraries */
import * as Sentry from "@sentry/browser";
import UserModel from "models/UserModel";
import InformBusinessModal from "modules/manage-users/components/InformBusinessModal";
import useBulkReassignForm from "modules/manage-users/forms/useBulkReassignForm/";
import Button from "modules/shared/components/inputs/Button";
/* Import Components */
import AdminTitle from "modules/shared/components/widgets/static/AdminTitle";
import React, { Fragment, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";

/* Import actions */
import {
  bulkReassignKeyContacts,
  loadUserApplicationsCount,
  loadUsersByEntity,
} from "../actions";
/* Import CSS */
import styles from "./css/ManageUserKeyContacts.css";

const ERROR_MESSAGE = `\
  Oops! There was an error while trying to reassign,\
  please refresh this page and try again or contact the\
  support team if the problem still persist.
`

function ManageUserKeyContacts(props) {
  const {
    dispatch,
    loading,
    saving,
    total_applications,
    user_id,
    currentUser,
    userProcessing,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [processing, setProcessing] = useState(userProcessing);
  const [error, setError] = useState(null);

  const { branches, component: bulkReassignForm } = useBulkReassignForm({
    ...props,
    assignKeyContact,
    assignKeySupplier,
  })

  const isDisabled = !selectedUser || (branches.length > 0 && !selectedSupplier);

  useEffect(() => {
    dispatch(loadUserApplicationsCount(user_id));
    dispatch(loadUsersByEntity());
  }, [])

  function errorCallback(error) {
    setError(ERROR_MESSAGE);
    Sentry.captureException(error);
  }

  function successCallback() {
    setProcessing(true);
    setError(null);
  }

  function updateAssignKeyContact() {
    if (isDisabled) {
      return handleModalDismiss();
    }

    const newSupplierId = (selectedSupplier && selectedSupplier.value) || currentUser.currentEntity.id;

    dispatch(
      bulkReassignKeyContacts({
        errorCallback,
        initiatorId: currentUser.id,
        newSupplierId: newSupplierId,
        newUserId: selectedUser.value,
        oldUserId: user_id,
        successCallback,
      })
    );

    handleModalDismiss();
  }

  function tryUpdate() {
    if (selectedUser) {
      setShowModal(true);
    }
  }

  function assignKeyContact(userId) {
    setSelectedUser(userId);
  }

  function assignKeySupplier(supplierId) {
    setSelectedSupplier(supplierId)
  }

  function handleModalDismiss() {
    setShowModal(false);
  }

  function handleModalYes() {
    updateAssignKeyContact();
  }

  function handleModalNo() {
    handleModalDismiss()
  }

  const hasApplications = total_applications > 0;
  const isPlural = total_applications > 1;
  const showDescription = !loading && !error;

  return (
    <div className={styles.row}>
      { showModal && (
        <InformBusinessModal
            handleDismiss={handleModalDismiss}
            handleYes={handleModalYes}
            handleNo={handleModalNo}
            count={total_applications}
            selectedUser={selectedUser && selectedUser.label}
            selectedSupplier={selectedSupplier && selectedSupplier.label}
        />
      )}
      <div className={styles.columns}>
        <AdminTitle text="Bulk reassign" />
        <h3 className={styles.title}>
          You are able to reassign all (Bulk) applications to another user,
          and if applicable reallocate them to another Tier in your organisation.
          Once a bulk transfer is completed you will be able to remove this user
          if required.
        </h3>
        { loading && <Skeleton count={2} /> }
        {
          !processing &&
          showDescription && (
            <p>This user is a key contact of {total_applications} application{isPlural && "s"}.</p>
          )}
        { processing &&
          showDescription && (
          <p>
            Applications under this user are being reassign to a different user,
            the initiator will be notified via email when this process is completed.
          </p>
        )}
        { error && <p className="has-text-danger">{error}</p> }
        { !processing && hasApplications && (
          <Fragment>
            { bulkReassignForm }
            <span className={styles.controls}>
              <Button
                  small={true}
                  text="update"
                  handleClick={tryUpdate}
                  disableOnLoading={true}
                  loading_text={"Saving"}
                  disabled={isDisabled}
                  loading={saving}
              />
            </span>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default connect(state => {
  const users = state.manage_users.user_list_for_assign_key_contact.map(
    user => {
      return { label: user.attributes.full_name, value: user.id };
    },
  );

  return {
    applications: state.manage_users.user_applications,
    connections: state.manage_users.user_connections,
    currentUser: UserModel.fromCurrentUser(state.current_user),
    loading:
      state.manage_users.user_applications_loading ||
      state.manage_users.user_connections_loading,
    saving: state.manage_users.saving,
    supplierId: state.current_user.current_entity.id,
    total_applications: state.manage_users.total_user_applications,
    userProcessing: state.manage_users.user_processing,
    users,
  };
})(ManageUserKeyContacts);
