import axios from "./axios";;

let EmailTemplates = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = "email_templates";
};

EmailTemplates.prototype.getEmailTemplates = function(
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

EmailTemplates.prototype.getEmailTemplate = function(
  template_no,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${template_no}`, options)
    .then(success)
    .catch(error);
};

export default EmailTemplates;
