import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
import React, { useState } from "react";

/* Import styles */
import styles from "./css/SquareCheckbox.css";

const SquareCheckbox = props => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const {
    checkboxId,
    checked,
    handleChange,
    label,
    numOfRow,
    no_float,
    description,
    disabled,
    divStyle,
    checkboxName,
    tip_name,
    tip_css_style,
    needBorder,
    inputRef,
    toggleTooltip,
  } = props;

  const showTooltip = () => {
    toggleTooltip && toggleTooltip(true);
    setIsTooltipActive(true);
  };

  const hideTooltip = () => {
    toggleTooltip && toggleTooltip(false);
    setIsTooltipActive(false);
  };

  let tool_tip;
  if (tip_name && isTooltipActive) {
    tool_tip = <ToolTip tip_name={tip_name} css_style={tip_css_style} />;
  }

  let numOfRow_style = null;
  let no_float_style = null;
  const border_style = null;
  let label_content;
  if (numOfRow) {
    numOfRow_style = styles[numOfRow];
  }

  if (no_float) {
    no_float_style = styles.no_float;
  }
  if (description) {
    label_content = (
      <label className={styles.label} htmlFor={checkboxId}>
        {label}
        <span className={styles.description}>{description}</span>
      </label>
    );
  } else {
    label_content = (
      <label className={styles.label} htmlFor={checkboxId}>
        {label}
      </label>
    );
  }
  return (
    <React.Fragment>
      <div
        className={`${styles.position_relative} ${numOfRow_style} ${no_float_style}`}
        style={divStyle}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <input
          className={styles.custom_checkbox}
          id={checkboxId}
          name={checkboxName}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          ref={inputRef}
        />
        {label_content}
        {tool_tip}
      </div>
      {props.children}
    </React.Fragment>
  );
};

export default SquareCheckbox;
