import mixpanel from "mixpanel-browser";
import styles from "modules/card-management-onboarding/css/Complete.css";
import SectionBadge from "modules/shared/components/widgets/static/SectionBadge";
import SectionDescription from "modules/shared/components/widgets/static/SectionDescription";
import SectionTitle from "modules/shared/components/widgets/static/SectionTitle";
import React, { useEffect } from "react";

export default function Complete(props) {
  const { cardManagementApplication } = props;

  useEffect(() => {
    mixpanel.track(
      "CMM completed",
      { distinct_id: cardManagementApplication.applicantId },
    );
  }, []);

  return (
    <div className={styles.containerFlex}>
      <section className={styles.blue_section}>
        <div className={styles.row}>
          <div className={styles.panel}>
            <SectionTitle white={true} text={"Application sent"} />
            <SectionBadge icon="email" />
          </div>
        </div>
        <div className={styles.description_row}>
          <div className={styles.description}>
            <SectionDescription
              text={`Application sent to ${cardManagementApplication.attributes.supplier_name}  and will be processed once any required signatures are received.`}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
