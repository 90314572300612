import React from "react";

import styles from "../centrix.css";

import moment from "moment";

class SummaryInformation extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { data } = this.props;
    const { data_sets } = data;

    return (
      <div>
        <div className={styles.table_container}>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Age of File:</td>
                <td>
                  {moment(data_sets.consumer_file.age_of_file).format(
                    "DD/MM/YYYY",
                  )}
                </td>
              </tr>
              <tr>
                <td>Judgements:</td>
                <td>
                  {data_sets.judgments
                    ? data_sets.judgments.judgement.length || 1
                    : 0}
                </td>
              </tr>
              <tr>
                <td>Insolvency Notices:</td>
                <td>
                  {data_sets.insolvencies
                    ? data_sets.insolvencies.insolvency.length || 1
                    : 0}
                </td>
              </tr>
              <tr>
                <td>Credit Defaults:</td>
                <td>
                  {data_sets.defaults
                    ? data_sets.defaults.default.length || 1
                    : 0}
                </td>
              </tr>
              <tr>
                <td>Account Arrears:</td>
                <td>{data_sets.red_arrears_month_list ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Company Affiliations:</td>
                <td>
                  {data_sets.company_affiliations
                    ? data_sets.company_affiliations.company_affiliation
                        .length || 1
                    : 0}
                </td>
              </tr>
              <tr>
                <td>File Access:</td>
                <td>
                  {data_sets.previous_enquiries
                    ? data_sets.previous_enquiries.previous_enquiry.length || 1
                    : 0}
                </td>
              </tr>
              <tr>
                <td>Score:</td>
                <td>
                  {
                    data_sets.extra_data_items.extra_data_item.name_value_pairs.name_value_pair.find(
                      obj => obj.name == "Score",
                    ).value
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default SummaryInformation;
