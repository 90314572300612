import React from "react";

/* Import CSS */
import styles from "./css/Review.css";

import LabeledInformation from "../../shared/components/widgets/static/LabeledInformation";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";

var ReviewQuestions = createClass({
  render: function() {
    const { data } = this.props;
    let questions = [];

    if (data.application.attributes.additional_application_questions) {
      data.application.attributes.additional_application_questions.forEach(
        (v, i) => {
          if (v.trim().length > 0) {
            questions.push(
              <ReviewContainer key={`qa-${i}`} content_class="content_full">
                <LabeledInformation
                  key={i}
                  label={v}
                  data={
                    data.application.attributes.answers
                      ? data.application.attributes.answers[i]
                      : null
                  }
                  showEmpty={true}
                />
              </ReviewContainer>,
            );
          }
        },
      );
    } else {
      questions = (
        <span>No additional questions where required by this supplier.</span>
      );
    }

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        {questions}
      </section>
    );
  },
});

module.exports = ReviewQuestions;
