import get from "lodash.get";
import commonStyles from "modules/addons/components/css/PPSRRegistration/EditComponent.css";
import TextInput from "modules/shared/components/inputs/TextInput";
import UncontrolledTextInput from "modules/shared/components/inputs/UncontrolledTextInput";
import FormCheckbox from "modules/shared/components/v2/FormCheckbox";
import React, { useEffect, useState } from "react";

export function useCollateralOptions({
  clearError,
  collateralTypes,
  register,
  setValue,
  versionConfig,
}) {
  const [collateralTypeOptions, setCollateralTypeOptions] = useState([]);

  useEffect(() => {
    const contents = collateralTypes.map(option => (
      <CollateralPreset
        key={option.value}
        clearError={clearError}
        collateralType={option.value}
        label={option.label}
        register={register}
        setValue={setValue}
        versionConfig={versionConfig}
      />
    ));

    setCollateralTypeOptions(contents);
  }, [collateralTypes, register]);

  return collateralTypeOptions;
}

export default function CollateralPreset(props) {
  const {
    clearError,
    collateralType,
    label,
    register,
    setValue,
    versionConfig,
  } = props;

  const checkboxId = `collateralPreset.${collateralType}.active`;
  const [isChecked, setIsChecked] = useState(
    get(versionConfig, `collateral_preset.${collateralType}.active`, false),
  );

  const onChangeActive = event => {
    const checkedValue = get(event, "target.checked", false);
    setIsChecked(checkedValue);
    setValue(checkboxId, checkedValue);

    clearError("collateralPreset");
  };

  const descriptionId = `collateralPreset.${collateralType}.description`;
  const [presetDescription, setPresetDescription] = useState(
    get(versionConfig, `collateral_preset.${collateralType}.description`, ""),
  );

  const onChangeDescription = event => {
    const description = get(event, "target.value", "");

    setValue(descriptionId, description);
    setPresetDescription(description);
  };

  const onBlurDescription = event => {
    const description = get(event, "target.value", "").trim();

    setValue(descriptionId, description);
    setPresetDescription(description);
  };

  return (
    <div className={commonStyles.collateral_preset_row}>
      <div>
        <FormCheckbox
          checkboxProps={{
            checked: isChecked,
            inputProps: { name: checkboxId },
            inputRef: register,
            onChange: onChangeActive,
          }}
          formControlLabelProps={{
            label,
          }}
        />
      </div>
      <TextInput
        id={descriptionId}
        label={`${label} preset description`}
        inputRef={register}
        value={presetDescription}
        handleBlur={onBlurDescription}
        handleChange={onChangeDescription}
        disabled={!isChecked}
      />
    </div>
  );
}
