import {
  COB_BUSINESS_PENDING_APPLICATION_ERROR,
  COB_BUSINESS_PENDING_APPLICATION_START,
  COB_BUSINESS_PENDING_APPLICATION_SUCCESS,
} from "../constants/onboarding";

import api from "api";

export function retrievePendingApplications(email, consumerId, supplierId) {
  return async (dispatch, getState) => {
    const applicationAPI = api(
      "applications",
      getState().current_user.access_token,
    );

    dispatch({ type: COB_BUSINESS_PENDING_APPLICATION_START });

    try {
      const results = await applicationAPI.getPendingFromSupplier({
        consumerId,
        email,
        supplierId,
      });

      dispatch({
        payload: results.data,
        type: COB_BUSINESS_PENDING_APPLICATION_SUCCESS,
      });
    } catch (e) {
      dispatch({ type: COB_BUSINESS_PENDING_APPLICATION_ERROR });
    }
  };
}
