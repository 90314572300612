import { LegalType } from "modules/shared/types/application";
import { isValidBusinessNumber } from "utils/companyAndBusinessNumberValidator";
import * as yup from "yup";

export function getFormSchema(data) {
  const { legal_type, region } = data;

  const businessNumberErrorMessage = legal_type === "company" ?
    "Please select a valid company name" :
    "Please input a valid business number";

  return yup.object().shape({
    business_number: yup.string()
      .nullable()
      .when(
        ["legal_type", "region"], {
          is: (legal_type: LegalType, region: "AU"|"NZ") => (
          // Always validate in AU
          // Validate only company in NZ
            region !== "NZ" || legal_type === "company"
          ),
          otherwise: schema => schema,
          then: schema => schema
            .test(
              "valid-business-number",
              businessNumberErrorMessage,
              value => isValidBusinessNumber(value, region),
            ),
        })
      .typeError(businessNumberErrorMessage),
    contact_phone_number: yup.string()
      .required("Please input phone number")
      .typeError("Please input phone number"),
    key_contact_email: yup.string().email("Please input a valid email address")
      .required("Please input email")
      .typeError("Please input email"),
    key_contact_first_name: yup
      .string()
      .required("Please input first name")
      .typeError("Please input first name"),
    key_contact_last_name: yup
      .string()
      .required("Please input last name")
      .typeError("Please input last name"),
    physical_address: yup.object().shape({
      api_id: yup.string(),
      full_address: yup.string(),
    })
      .test(
        "invalid-address",
        "Please select a valid address",
        ({ api_id, full_address }) => !!api_id && !!full_address,
      ),
    trading_name: yup
      .string()
      .required("Please input trading name")
      .typeError("Please input trading name"),
  });
}
