import "react-table/react-table.css";

import React from "react";
import ReactTable from "react-table";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import SupplierByIndustry from "./SupplierByIndustry";
import moment from "moment";
import styles from "../../css/CommonReviewBusiness.css";

var CreditDetails = createClass({
  _directors() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyDetails = enquiryResult.company_details,
      companyDirectors = companyDetails.company_directors || {},
      directors = companyDirectors.company_director_ws_dto || [];

    if (directors.constructor === Object) {
      directors = [directors];
    }

    let activeDirectors = directors.filter(director => {
      return director.date_deleted === null;
    });

    let names = activeDirectors.map(director => {
      return director.first_names + " " + director.surname;
    });

    return names.join(", ");
  },

  render() {
    const { data, isAllExpanded, sequence_date } = this.props;

    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyDetails = enquiryResult.company_details,
      creditDetails = enquiryResult.credit_details;

    let sequenceDate = sequence_date,
      checkDate = moment(sequenceDate),
      lastMonth = moment(sequenceDate).subtract(1, "months"),
      priorMonth = moment(sequenceDate).subtract(2, "months");

    return (
      <ReviewContainer
        subHeading="Credit Details"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Legal Name</th>
                <td>{companyDetails.company.company_name}</td>
              </tr>
              <tr>
                <th>NZBN</th>
                <td>{creditDetails.nzbn}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{creditDetails.address}</td>
              </tr>
              <tr>
                <th>Company number</th>
                <td>{companyDetails.company.company_number}</td>
              </tr>
              <tr>
                <th>Company Status</th>
                <td>{creditDetails.company_status_description}</td>
              </tr>
              <tr>
                <th>Directors</th>
                <td>{this._directors()}</td>
              </tr>
              <tr>
                <th>Combined Credit Limit</th>
                <td>
                  {parseInt(creditDetails.combined_credit_limit).toLocaleString(
                    "en",
                    { maximumFractionDigits: 2 },
                  )}
                </td>
              </tr>
              <tr>
                <th>Associations</th>
                <td></td>
              </tr>
              <tr>
                <th>Current number of suppliers</th>
                <td>{creditDetails.vendor_count}</td>
              </tr>
              <tr>
                <th>DSO {checkDate.format("MMMM")} (to date)</th>
                <td>{creditDetails.average_dso}</td>
              </tr>
              <tr>
                <th>DSO {lastMonth.format("MMMM")}</th>
                <td>{creditDetails.average_dso_last}</td>
              </tr>
              <tr>
                <th>DSO {priorMonth.format("MMMM")}</th>
                <td>{creditDetails.average_dso_prior}</td>
              </tr>
              <tr>
                <th>Suppliers by Industry</th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <SupplierByIndustry data={data} />
      </ReviewContainer>
    );
  },
});

export default CreditDetails;
