import { get } from "lodash";

function isSignatureRequired(state, configSignatureRequired) {
  const cobSection = state.cob_section;
  const { application, amlCheck } = cobSection;
  const manageProfile = state.manage_profile;
  const settingsAmlCheckRules = manageProfile.settingsAmlCheckRules;
  const deprecatedIdCheckOn = get(application, "attributes.requires_cardholder_identification_check");

  if (deprecatedIdCheckOn) {
    return true;
  }

  let signatureRequired = configSignatureRequired;

  if (amlCheck) {
    const AMLPersonToCheck = get(amlCheck, "attributes.addon_version.config.person_to_check", []);
    signatureRequired = configSignatureRequired && AMLPersonToCheck.includes("cardholder");
  }

  if (settingsAmlCheckRules && settingsAmlCheckRules.selectedLevels) {
    signatureRequired = configSignatureRequired && settingsAmlCheckRules.selectedLevels.includes("cardholder");
  }

  return signatureRequired;
}

export default isSignatureRequired;
