import {
  accountType,
  paymentStatus,
  reportCode,
  role,
} from "./helpers/codeTable";
import { amountTypeValue, countTypeValue } from "./helpers/summary";

import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { displayEmpty } from "utils/formatting";
import { enquiryResponseData } from "./helpers/reportHelpers";
import { stringToSimpleDate } from "utils/dateFormatter";
import styles from "../../../css/CommonReviewBusiness.css";

var PaymentDefaults = createClass({
  _defaults() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      paymentDefaultList =
        response.organisation_credit_history.payment_default_list || {},
      paymentDefaults = paymentDefaultList.payment_defaults || [];

    if (paymentDefaults.constructor === Object)
      paymentDefaults = [paymentDefaults];

    return paymentDefaults.map((paymentDefault, index) => {
      return (
        <div className={styles.table_wrapper} key={index}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th colSpan={2}>Original Account Details</th>
                <th colSpan={2}>Update Account Details</th>
              </tr>
              <tr>
                <th>Credit Provider</th>
                <td>{paymentDefault.original_provider}</td>
                <th>Credit Provider</th>
                <td>{paymentDefault.provider}</td>
              </tr>
              <tr>
                <th>Date of Listing</th>
                <td>
                  {stringToSimpleDate(paymentDefault.original_default_date)}
                </td>
                <th>Date of Listing</th>
                <td>{stringToSimpleDate(paymentDefault.default_date)}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>{paymentDefault.original_amount}</td>
                <th>Amount</th>
                <td>{paymentDefault.amount}</td>
              </tr>
              <tr>
                <th>Reason to Report</th>
                <td>
                  {reportCode(
                    (paymentDefault.original_report_reason || {}).type,
                  )}
                </td>
                <th>Reason to Report</th>
                <td>{reportCode((paymentDefault.report_reason || {}).type)}</td>
              </tr>
              <tr>
                <th>Account Number</th>
                <td>{paymentDefault.account_number}</td>
                <th>Status Date</th>
                <td>{stringToSimpleDate(paymentDefault.status_date)}</td>
              </tr>
              <tr>
                <th>Account Type</th>
                <td>{accountType((paymentDefault.account_type || {}).type)}</td>
                <th>Role</th>
                <td>{role((paymentDefault.role || {}).type)}</td>
              </tr>
              <tr>
                <th>Default Status</th>
                <td>
                  {paymentStatus((paymentDefault.payment_status || {}).type)}
                </td>
                <th>Co-Borrower</th>
                <td>{displayEmpty(paymentDefault.co_borrower)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Payment Defaults"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>Total: {countTypeValue(summaryEntry, "Defaults")}</strong>
        </p>
        <p>
          <strong>
            Amount: {amountTypeValue(summaryEntry, "Defaults-Value")}
          </strong>
        </p>
        {this._defaults()}
      </ReviewContainer>
    );
  },
});

export default PaymentDefaults;
