import loadImage from "blueimp-load-image";
import moment from "moment";
import isBlank from "utils/isBlank";
import sleep from "utils/sleep";

import api from "../../api";
import {
  IDENTITY_ADDRESS_LOOKUP_CLEAR,
  IDENTITY_ADDRESS_LOOKUP_ERROR,
  IDENTITY_ADDRESS_LOOKUP_START,
  IDENTITY_ADDRESS_LOOKUP_SUCCESS,
  IDENTITY_CLEAR_STATE,
  IDENTITY_CLICK_EVENT,
  IDENTITY_COMPONENT_COMPLETE,
  IDENTITY_SCRAPE_IMAGE_END,
  IDENTITY_SCRAPE_IMAGE_START,
  IDENTITY_SECTION_COMPLETE,
  IDENTITY_SELECT_ADDRESS,
  IDENTITY_SELECT_NO_IMAGE_FILE,
  IDENTITY_SELECT_PDF_TOO_LONG,
  IDENTITY_SET_ADDRESS,
  IDENTITY_SET_CAMERA,
  IDENTITY_SET_DOB,
  IDENTITY_SET_DRIVER_LICENCE_VERSION,
  IDENTITY_SET_IDENTIFICATION_EXP_DATE,
  IDENTITY_SET_DRIVER_LICENCE_STATE,
  IDENTITY_SET_FIRSTNAME,
  IDENTITY_SET_FRONT_FACE_IMAGE,
  IDENTITY_SET_IMAGE_64,
  IDENTITY_SET_IMAGE_FILE_DETAILS,
  IDENTITY_SET_LASTNAME,
  IDENTITY_SET_MIDDLENAME,
  IDENTITY_SET_MOCK_PERCENTAGE,
  IDENTITY_SET_NAME,
  IDENTITY_SET_NAMES_LOCKED,
  IDENTITY_SET_NOIDENTIFICATION,
  IDENTITY_SET_NOIDENTIFICATION_REASON,
  IDENTITY_SET_NUMBER,
  IDENTITY_SET_OTHER_REGION,
  IDENTITY_SET_PROOF_OF_ADDRESS,
  IDENTITY_SET_REGION,
  IDENTITY_SET_SCRAPE_DETAILS_MODAL_VISIBILITY,
  IDENTITY_SET_TYPE,
  SET_IDENTITY,
} from "./constants";

function _converPdfToPng(retry = 10) {
  const imageInCanvas = document.getElementsByClassName(
    "react-pdf__Page__canvas",
  )[0];
  if (imageInCanvas) {
    return imageInCanvas.toDataURL();
  }
  if (retry > 0) {
    sleep(500);
    return _converPdfToPng(retry - 1);
  }
}

function _submitImage(
  authorisation_id,
  image_base_64,
  callbacks,
  ignore_orientation,
) {
  return async (dispatch, getState) => {
    let convertedBase64 = image_base_64;
    if (image_base_64.slice(0, 20) === "data:application/pdf") {
      convertedBase64 = _converPdfToPng();
      dispatch({
        payload: convertedBase64,
        type: IDENTITY_SCRAPE_IMAGE_START,
      });
    }
    if (authorisation_id === null) {
      dispatch({ type: IDENTITY_SCRAPE_IMAGE_END });
      return;
    }
    const authorisationAPI = api("authorisations");
    try {
      const response = await authorisationAPI.uploadIdentificationImage(
        authorisation_id,
        convertedBase64,
      );
      dispatch({ payload: response.data, type: IDENTITY_SCRAPE_IMAGE_END });
      dispatch({
        payload: true,
        type: IDENTITY_SET_SCRAPE_DETAILS_MODAL_VISIBILITY,
      });
    } catch (error) {
      console.error(error);
      dispatch({ payload: {}, type: IDENTITY_SCRAPE_IMAGE_END });
    }
  };
}

export function scrapeImage(
  authorisation_id,
  image_base_64,
  callbacks,
  ignore_orientation,
) {
  return async (dispatch, getState) => {
    dispatch({
      payload: "",
      type: IDENTITY_SCRAPE_IMAGE_START,
    });

    if (!ignore_orientation) {
      loadImage(
        image_base_64,
        img => {
          if (img.type === "error") {
            console.error(`Error loading image ${image_base_64}`);
          } else {
            dispatch({
              payload: img.toDataURL(),
              type: IDENTITY_SCRAPE_IMAGE_START,
            });
          }
        },
        {
          canvas: true,
          orientation: true,
        },
      );
    } else {
      dispatch({
        payload: image_base_64,
        type: IDENTITY_SCRAPE_IMAGE_START,
      });
    }

    setTimeout(() => {
      dispatch(
        _submitImage(
          authorisation_id,
          image_base_64,
          callbacks,
          ignore_orientation,
        ),
      );
    }, 500);
  };
}

export function setImageFileDetails(file_name, file_size) {
  return {
    payload: {
      file_name,
      file_size,
    },
    type: IDENTITY_SET_IMAGE_FILE_DETAILS,
  };
}

export function clearIdentityState() {
  return {
    type: IDENTITY_CLEAR_STATE,
  };
}

function _getUserId(getState) {
  if (getState().current_user.data.data) {
    return getState().current_user.data.data.id;
  }
  return getState().authorisation.data.authorisation.id;
}

export function identityComponentComplete(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state === true) {
      meta = {
        mixpanel: {
          event: "Identity Check",
          props: {
            Component: component,
            Section: "identity",
            distinct_id: _getUserId(getState),
          },
        },
      };
    }
    dispatch({
      meta,
      payload: {
        component,
        state,
      },
      type: IDENTITY_COMPONENT_COMPLETE,
    });
  };
}

export function clickEvent(eventName, distinctId) {
  return (dispatch, getState) => {
    dispatch({
      meta: {
        mixpanel: {
          event: `Click ${eventName}`,
          props: {
            Section: "identity",
            distinct_id: distinctId || _getUserId(getState),
          },
        },
      },
      type: IDENTITY_CLICK_EVENT,
    });
  };
}

export function identityComplete(complete) {
  return (dispatch, getState) => {
    const section_state = getState().identity;

    const data = {
      entity: new Object(),
    };

    data.entity.firstname = section_state.firstname;
    data.entity.lastname = section_state.lastname;
    data.entity.dob = section_state.dob;
    data.entity.type = section_state.type;
    data.entity.number = section_state.number;
    data.entity.driver_licence_version = section_state.driver_licence_version;
    data.entity.identification_exp_date = section_state.identification_exp_date;
    data.entity.image = section_state.image;
    data.entity.noIdentification = section_state.noIdentification;
    data.entity.region = section_state.region;
    data.entity.other_region = section_state.other_region;

    dispatch({
      payload: {
        complete,
        data,
      },
      type: IDENTITY_SECTION_COMPLETE,
    });
  };
}

export function setIdentityValue(key, value) {
  let type;
  switch (key) {
    case "names_locked":
      type = IDENTITY_SET_NAMES_LOCKED;
      break;
    case "name":
      type = IDENTITY_SET_NAME;
      break;
    case "first_name":
      type = IDENTITY_SET_FIRSTNAME;
      break;
    case "last_name":
      type = IDENTITY_SET_LASTNAME;
      break;
    case "middle_name":
      type = IDENTITY_SET_MIDDLENAME;
      break;
    case "dob":
      type = IDENTITY_SET_DOB;
      break;
    case "type":
      type = IDENTITY_SET_TYPE;
      break;
    case "number":
      type = IDENTITY_SET_NUMBER;
      break;
    case "driver_licence_version":
      type = IDENTITY_SET_DRIVER_LICENCE_VERSION;
      break;
    case "identification_exp_date":
      type = IDENTITY_SET_IDENTIFICATION_EXP_DATE;
      break;
    case "driver_licence_state":
      type = IDENTITY_SET_DRIVER_LICENCE_STATE;
      break;
    case "image_64":
      type = IDENTITY_SET_IMAGE_64;
      break;
    case "noIdentification":
      type = IDENTITY_SET_NOIDENTIFICATION;
      break;
    case "noCamera":
      type = IDENTITY_SET_CAMERA;
      break;
    case "noIdentificationReason":
      type = IDENTITY_SET_NOIDENTIFICATION_REASON;
      break;
    case "region":
      type = IDENTITY_SET_REGION;
      break;
    case "other_region":
      type = IDENTITY_SET_OTHER_REGION;
      break;
    case "mock_percentage":
      type = IDENTITY_SET_MOCK_PERCENTAGE;
      break;
    case "proof_of_address":
      type = IDENTITY_SET_PROOF_OF_ADDRESS;
      break;
    case "front_face_image":
      type = IDENTITY_SET_FRONT_FACE_IMAGE;
      break;
  }

  return {
    payload: value,
    type,
  };
}

export function setAddress(value) {
  return {
    payload: value,
    type: IDENTITY_SET_ADDRESS,
  };
}

export function selectAddress(api_id) {
  return {
    payload: api_id,
    type: IDENTITY_SELECT_ADDRESS,
  };
}

export function lookupAddress(string, region) {
  return (dispatch, getState) => {
    // If the string is empty, then zero out the lookup list.
    if (string.length === 0) {
      dispatch({
        type: IDENTITY_ADDRESS_LOOKUP_CLEAR,
      });
    } else {
      dispatch({
        payload: string,
        type: IDENTITY_ADDRESS_LOOKUP_START,
      });

      const addressSearch = api(
        "address_search",
        getState().current_user.access_token,
      );
      let data = {};
      const rawData = {};
      const dropdownData = {};

      addressSearch.addressSearch(
        result => {
          for (let i = 0; i < result.data.data.length; i++) {
            const address = result.data.data[i];

            rawData[address.api_id] = address;
            dropdownData[address.api_id] = address.full_address;
          }

          data = { dropdownData, rawData };

          dispatch({
            payload: data,
            type: IDENTITY_ADDRESS_LOOKUP_SUCCESS,
          });
        },
        error => {
          dispatch({
            payload: error,
            type: IDENTITY_ADDRESS_LOOKUP_ERROR,
          });
        },
        {
          params: {
            address: string,
            region,
            type: "Physical",
          },
        },
      );
    }
  };
}

function getAuthorisationRegion(getState) {
  const authorisation = getState().authorisation;
  if (
    isBlank(authorisation) ||
    isBlank(authorisation.data) ||
    isBlank(authorisation.data.application) ||
    isBlank(authorisation.data.application.attributes)
  ) {
    return;
  }

  return authorisation.data.application.attributes.region;
}

export function setProofOfAddress(proofOfAddress) {
  return {
    payload: proofOfAddress,
    type: IDENTITY_SET_PROOF_OF_ADDRESS,
  };
}

export function selectNoImageFile() {
  return {
    type: IDENTITY_SELECT_NO_IMAGE_FILE,
  };
}

export function selectPDFTooLong() {
  return {
    type: IDENTITY_SELECT_PDF_TOO_LONG,
  };
}

export function setIdentity(params) {
  return dispatch => {
    dispatch({ payload: params, type: SET_IDENTITY });
  };
}

export function resetIdentityDetails({
  dispatch,
  identity,
  isApplicant,
  person,
}) {
  if (isApplicant) {
    return;
  }

  const {
    first_name: personFirstName = "",
    middle_name: personMiddleName = "",
    last_name: personLastName = "",
  } = person;

  const {
    first_name: identityFirstName = "",
    middle_name: identityMiddleName = "",
    last_name: identityLastName = "",
  } = identity;

  if (
    personFirstName === identityFirstName &&
    personLastName === identityLastName &&
    personMiddleName === identityMiddleName
  ) {
    dispatch(setIdentityValue("name", ""));
    dispatch(setIdentityValue("first_name", ""));
    dispatch(setIdentityValue("last_name", ""));
    dispatch(setIdentityValue("middle_name", ""));
  }
}

export function onCloseScrapeDetailsModal() {
  return { payload: false, type: IDENTITY_SET_SCRAPE_DETAILS_MODAL_VISIBILITY };
}
