import styled, { css } from "styled-components";
import { COLORS, FONT_COLORS, THEME_COLORS } from "variables/theme";
import { FONT_SIZES } from "variables/typography";

import GraphicPeople from "../../images/svgs/graphic-share-the-love-walks-of-life.svg";
import IconBuddy from "../../images/svgs/icon-buddy.svg";
import Logo1Centre from "../../images/svgs/logo-1centre.svg";

export const Buddy = styled(IconBuddy)`
  margin: auto;
  max-width: 50%;

  circle,
  path {
    stroke: ${COLORS.white};
  }
`;

export const Columns = styled.div`
  flex: 1;
`;

export const Description = styled.p`
  font-size: ${FONT_SIZES.heading};
  margin: 2rem auto;
  max-width: 44rem;
`;

export const Form = styled.form`
  max-width: 50rem;
  width: 100%;

  ${props => props.disableThemeOverride && css`
    input,
    textarea {
      border-color: ${THEME_COLORS.primary};

      &:hover,
      &:focus,
      &:active {
        border-color: ${THEME_COLORS.primary};
      }
    }

    [type="submit"] {
      background: ${THEME_COLORS.primary};
      border-color: ${THEME_COLORS.primary};
    }
  `}

  button {
    display: block;
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const LeftColumn = styled.div`
  align-items: center;
  background: ${THEME_COLORS.primary};
  color: ${FONT_COLORS.light};
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 0;
  text-align: center;
`;

export const RightColumn = styled.div`
  align-items: center;
  background: ${COLORS.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
`;

export const Logo = styled(Logo1Centre)`
  margin: 2rem auto;
  max-height: 12.5rem;
  width: 100%;
`;

export const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const People = styled(GraphicPeople)`
  margin-top: 2rem;

  circle,
  path {
    fill: ${COLORS.white};
  }
`;

export const ShareTheLoveBtn = styled.button`
  background: transparent;
  border: unset;
  bottom: 0;
  left: 6rem;
  padding: 0;
  position: fixed;

  img {
    height: 5.8rem;
    width: auto;
  }
`;
