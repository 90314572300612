import axios from './axios';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export default class Vehicle {
  axios: AxiosInstance;

  type: string;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);
    this.type = "vehicles";
    this.axios.defaults.baseURL = this!.axios!.defaults!.baseURL!.replace(
      "v1",
      "v2",
    );
  }

  getVehicle({
    region,
    vehicleId,
    vehicleIdType,
  }: {
    region: "au" | "nz";
    vehicleId: string;
    vehicleIdType: "plate" | "vin";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<AxiosResponse<any>> {
    return this.axios.get(`${region}/${this.type}/${vehicleId}`, {
      params: { vehicle_id_type: vehicleIdType },
    });
  }
}
