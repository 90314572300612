import React, { ReactElement } from "react"

import Message from "../Message";
import Panel from "../Panel"
import { Props } from "../Panel/model";

function ComingSoon(props: Props): ReactElement {
  return (
    <Panel
      {...props}
      beta
      error={false}
      title="Coming Soon"
      hideComingSoonLabel
    >
      <Message
        faProps={{
          icon: ["fas", "smile-wink"],
        }}
        message="This widget is coming soon."
      />
    </Panel>
  )
}

export default ComingSoon
