import styles from "modules/shared/components/inputs/css/FieldWithLabel.css";
import React from "react";

function containerClassName(props) {
  const { withBottomMargin } = props;
  const defaultClassNames = [styles.container];

  if (withBottomMargin) {
    defaultClassNames.push(styles.with_bottom_margin);
  }

  return defaultClassNames.join(" ");
}

export default function FieldWithLabel(props) {
  const { children, htmlFor, label } = props;

  return (
    <div className={containerClassName(props)}>
      <label htmlFor={htmlFor} className={styles.input_label}>
        {label}
      </label>
      <div className={styles.input}>{children}</div>
    </div>
  );
}

FieldWithLabel.defaultProps = {
  withBottomMargin: false,
};
