/* eslint-disable max-lines */
import * as Sentry from "@sentry/browser";
import api from "api";
import abnEntityTypes from "constants/abnEntityTypes";
import debounce from "debounce";
import get from "lodash.get";
import UserModel from "models/UserModel";
import {
  clearBusinessApiDetails,
  manualSetCompany,
  preSetCompanyDetails,
  selectEntityType,
  setBusinessApiDetails,
  setEntityFormValue,
} from "modules/consumer-onboarding/actions/onboarding";
import styles from "modules/consumer-onboarding/components/onboarding/css/Business.css";
import TextInput from "modules/shared/components/inputs/TextInput";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import trimAllSpaces from "utils/trimAllSpaces";
import * as yup from "yup";

const ABN_LENGTH = 11;
const ACN_LENGTH = 9;
const NUMBER_ERROR_MESSAGE = "Please input number only";

const numberOptions = {
  excludeEmptyString: true,
  message: NUMBER_ERROR_MESSAGE,
};

const ENTITY_TYPE_OPTIONS = [
  { label: "Company", value: "company" },
  { label: "Partnership", value: "partnership" },
  { label: "Trust", value: "trust" },
  { label: "Sole trader", value: "sole_trader" },
  { label: "Personal", value: "personal" },
  { label: "Other", value: "other" },
];

const hasBusinessApiDetailsForCompany = yupOptions => {
  const asicDetailsFromNZBN = get(
    this.options,
    "context.businessApiDetails.asic_details",
  );

  if (isPresent(asicDetailsFromNZBN)) {
    return true;
  }

  const asicDetails = get(this.options, "context.businessApiDetails", {});

  const { company_number: companyNumber, name: companyName } = asicDetails;

  return isPresent(companyNumber) && isPresent(companyName);
};

const DEFAULT_SCHEMA = yup.object().shape({
  businessNumber: yup
    .string()
    .matches(/^[0-9]+$/, numberOptions)
    .notRequired(),
  companyNumber: yup
    .string()
    .matches(/^[0-9]+$/, numberOptions)
    .notRequired(),
  entityName: yup.string().notRequired(),
  entityType: yup
    .string()
    .required("Please select an entity type")
    .oneOf(
      ["company", "other", "partnership", "personal", "sole_trader", "trust"],
      "Please select an entity type",
    ),
});

const COMPANY_SCHEMA = yup.object().shape({
  businessNumber: yup
    .string()
    .matches(/^[0-9]+$/, numberOptions)
    .notRequired(),
  companyNumber: yup
    .string()
    .matches(/^[0-9]+$/, numberOptions)
    .required("Please enter a valid ACN")
    .length(ACN_LENGTH, "Please enter a valid ACN")
    .test(
      "asic-details",
      "Unable to verify ACN via ASIC. Contact support@1centre.com.",
      function test(companyNumber) {
        if (isBlank(companyNumber)) {
          // If companyNumber is not available, let the other validation handle
          // it to show the correct error message.
          return true;
        }

        return hasBusinessApiDetailsForCompany(this.options);
      },
    ),
  entityName: yup.string().required(),
  entityType: yup
    .string()
    .required("Please select an entity type")
    .oneOf(["company", "Please select an entity type"]),
});

const BUSINESS_SCHEMA = yup.object().shape({
  businessNumber: yup
    .string()
    .matches(/^[0-9]+$/, numberOptions)
    .required("Please enter a valid ABN")
    .length(ABN_LENGTH, "Please enter a valid ABN"),
  companyNumber: yup
    .string()
    .test("acn-not-required", "ACN is for company types only.", function test(
      companyNumber,
    ) {
      // If the company number length matches the required length, verify
      // instead that the ACN is valid via the ASIC API which is handled in the
      // other validation.
      return isBlank(companyNumber) || companyNumber.length === ACN_LENGTH;
    })
    .test(
      "acn-not-valid",
      "Unable to verify ACN via ASIC. Contact support@1centre.com.",
      function test(companyNumber) {
        if (isBlank(companyNumber) || companyNumber.length !== ACN_LENGTH) {
          return true;
        }

        return hasBusinessApiDetailsForCompany(this.options);
      },
    ),
  entityName: yup.string().required(),
  entityType: yup
    .string()
    .required("Please select an entity type")
    .oneOf(
      ["other", "partnership", "sole_trader", "trust"],
      "Please select an entity type",
    ),
});

const NON_BUSINESS_SCHEMA = yup.object().shape({
  businessNumber: yup
    .string()
    .matches(/^[0-9]+$/, numberOptions)
    .notRequired(),
  companyNumber: yup
    .string()
    .matches(/^[0-9]+$/, numberOptions)
    .notRequired(),
  entityName: yup.string().notRequired(),
  entityType: yup
    .string()
    .required()
    .oneOf(["personal"], "Please select an entity type"),
});

const VALIDATION_SCHEMA_BY_ENTITY_TYPE = {
  company: COMPANY_SCHEMA,
  other: BUSINESS_SCHEMA,
  partnership: BUSINESS_SCHEMA,
  personal: NON_BUSINESS_SCHEMA,
  sole_trader: BUSINESS_SCHEMA,
  trust: BUSINESS_SCHEMA,
};

const buildErrors = ({
  businessNumberError,
  companyNumberError,
  entityNameError,
  entityTypeError,
}) => {
  const errors = {};

  if (isPresent(businessNumberError)) {
    errors["businessNumber"] = businessNumberError;
  }

  if (isPresent(companyNumberError)) {
    errors["companyNumber"] = companyNumberError;
  }

  if (isPresent(entityNameError)) {
    errors["entityName"] = entityNameError;
  }

  if (isPresent(entityTypeError)) {
    errors["entityType"] = entityTypeError;
  }

  return errors;
};

const useFieldErrorsState = () => {
  // Separating the errors for each field since it has become difficult to
  // manage due to async updates and redux state changes
  const [businessNumberError, setBusinessNumberError] = useState(null);
  const [companyNumberError, setCompanyNumberError] = useState(null);
  const [entityNameError, setEntityNameError] = useState(null);
  const [entityTypeError, setEntityTypeError] = useState(null);

  const errors = buildErrors({
    businessNumberError,
    companyNumberError,
    entityNameError,
    entityTypeError,
  });

  const setErrorByField = (fieldName, errorMessage) => {
    switch (fieldName) {
      case "businessNumber":
        setBusinessNumberError(errorMessage);
        break;
      case "companyNumber":
        setCompanyNumberError(errorMessage);
        break;
      case "entityName":
        setEntityNameError(errorMessage);
        break;
      case "entityType":
        setEntityTypeError(errorMessage);
        break;
      default:
        console.error(`Unknown field: ${fieldName}`);
        Sentry.captureException(`Unknown field: ${fieldName}`);
    }
  };

  const setErrors = fieldErrors => {
    const keys = [
      "businessNumber",
      "companyNumber",
      "entityName",
      "entityType",
    ];

    for (const key of keys) {
      setErrorByField(key, fieldErrors[key]);
    }
  };

  return { errors, setErrorByField, setErrors };
};

const determineIsEntityTaken = ({ currentEntityId, registeredEntityIds }) => {
  if (isBlank(registeredEntityIds)) {
    return false;
  }

  return !registeredEntityIds.includes(currentEntityId);
};

const getEntityTypeFromAbnDetails = entityTypeCode => {
  const entityType = abnEntityTypes[entityTypeCode];
  const entityTypeTitle = (
    ENTITY_TYPE_OPTIONS.find(option => option.value === entityType) || {}
  ).label;

  return { entityType, entityTypeTitle };
};

const setEntityDetailsFromASIC = ({ asicDetails, dispatch }) => {
  if (isBlank(asicDetails)) {
    return;
  }

  dispatch(selectEntityType("company", "Company"));
  dispatch(preSetCompanyDetails(asicDetails));
  dispatch(manualSetCompany("company_number", asicDetails.company_number));
  dispatch(manualSetCompany("company_name", asicDetails.name));

  setEntityDetailsFromABN({ abnDetails: asicDetails.abn_details, dispatch });
};

const getASICDetails = async({
  acn,
  currentUser,
  dispatch,
  setErrorByField,
}) => {
  const acnAPI = api("company_search", currentUser.accessToken);

  try {
    const response = await acnAPI.getAsicDetails(acn);
    const asicDetails = response.data;

    if (asicDetails.business_number === "0") {
      delete asicDetails["business_number"];
    }

    if (isBlank(asicDetails)) {
      setErrorByField(
        "companyNumber",
        "Unable to verify ACN via ASIC. Contact support@1centre.com.",
      );
      dispatch(clearBusinessApiDetails());
      return;
    }

    const isEntityTaken = determineIsEntityTaken({
      currentEntityId: get(currentUser, "currentEntity.id"),
      registeredEntityIds: get(asicDetails, "relationships.entity_ids", []),
    });

    if (isEntityTaken) {
      setErrorByField(
        "companyNumber",
        "ACN is already registered with 1Centre.",
      );
      return;
    }

    dispatch(setBusinessApiDetails(asicDetails));
    setEntityDetailsFromASIC({ asicDetails, dispatch });
    setErrorByField("companyNumber", null);
  } catch (e) {
    console.error(e);
  }
};

const setEntityDetailsFromABN = ({ abnDetails, dispatch }) => {
  if (isBlank(abnDetails)) {
    return;
  }

  dispatch(manualSetCompany("company_name", abnDetails.entity_name));
  setEntityDetailsFromASIC({ asicDetails: abnDetails.asic_details, dispatch });

  const { entityType, entityTypeTitle } = getEntityTypeFromAbnDetails(
    abnDetails.entity_type_code,
  );
  dispatch(selectEntityType(entityType, entityTypeTitle));

  const firstBusinessName = abnDetails.business_name[0];
  if (isPresent(firstBusinessName)) {
    dispatch(setEntityFormValue("entity_name", firstBusinessName));
  }
};

const getABNDetails = async({
  abn,
  currentUser,
  dispatch,
  setErrorByField,
}) => {
  const abnAPI = api("abn", currentUser.accessToken);

  try {
    const response = await abnAPI.getDetails(abn);
    const abnDetails = response.data;

    if (isBlank(abnDetails.abn)) {
      setErrorByField("businessNumber", abnDetails.message || "ABN is invalid");
      dispatch(clearBusinessApiDetails());
      return;
    }

    const isEntityTaken = determineIsEntityTaken({
      currentEntityId: get(currentUser, "currentEntity.id"),
      registeredEntityIds: get(abnDetails, "relationships.entity_ids", []),
    });

    if (isEntityTaken) {
      setErrorByField(
        "businessNumber",
        "ABN is already registered with 1Centre.",
      );
      return;
    }

    dispatch(setBusinessApiDetails(abnDetails));
    setEntityDetailsFromABN({ abnDetails, dispatch });
    setErrorByField("businessNumber", null);
  } catch (e) {
    console.error(e);
  }
};

const BusinessNumber = props => {
  const {
    businessNumber,
    currentUser,
    dispatch,
    error,
    isDisabled,
    setErrorByField,
  } = props;

  const onChange = event => {
    const abn = trimAllSpaces(get(event, "target.value", ""));

    dispatch(manualSetCompany("business_number", abn));

    if (abn.length === ABN_LENGTH) {
      debounce(
        getABNDetails({
          abn,
          currentUser,
          dispatch,
          setErrorByField,
        }),
        500,
      );
    }
  };

  return (
    <div className={styles.left_col}>
      <div className={styles.form}>
        <TextInput
          disabled={isDisabled}
          error={error}
          handleBlur={onChange}
          handleChange={onChange}
          id="business_number"
          label="ABN"
          required={true}
          value={businessNumber}
        />
      </div>
    </div>
  );
};

const CompanyNumber = props => {
  const {
    companyNumber,
    currentUser,
    dispatch,
    error,
    isDisabled,
    setErrorByField,
  } = props;

  const onChange = event => {
    const acn = trimAllSpaces(get(event, "target.value", ""));

    dispatch(manualSetCompany("company_number", acn));

    if (acn.length === ACN_LENGTH) {
      debounce(
        getASICDetails({
          acn,
          currentUser,
          dispatch,
          setErrorByField,
        }),
      );
    }
  };

  return (
    <div className={styles.left_col}>
      <div className={styles.form}>
        <TextInput
          disabled={isDisabled}
          error={error}
          handleBlur={onChange}
          handleChange={onChange}
          id="company_number"
          label="ACN"
          required={true}
          value={companyNumber}
        />
      </div>
    </div>
  );
};

const validateSection = ({
  businessApiDetails,
  businessNumber,
  companyNumber,
  entityName,
  entityType,
  errors,
  validationCallback,
}) => {
  const validationSchema =
    VALIDATION_SCHEMA_BY_ENTITY_TYPE[entityType] || DEFAULT_SCHEMA;

  try {
    validationSchema.validateSync(
      {
        businessNumber,
        companyNumber,
        entityName,
        entityType,
      },
      {
        abortEarly: false,
        context: { businessApiDetails },
      },
    );

    validationCallback({});
  } catch (error) {
    const formErrors = {};

    if (error instanceof yup.ValidationError) {
      for (const innerError of error.inner) {
        formErrors[innerError.path] = innerError.message;
      }
    }

    validationCallback(formErrors);
  }
};

const BusinessOverview = props => {
  const {
    businessApiDetails,
    businessNumber,
    companyNumber,
    currentUser,
    dispatch,
    entityName,
    entityType,
    handleComplete,
    page_validation_start: shouldValidateSection,
    section,
    setPageValidationStartFinish: endValidation,
  } = props;

  const isEntitySelectDisabled = !!(businessNumber || companyNumber);
  const isDisabled = get(
    currentUser,
    "currentEntity.consumerDetailsLocked",
    false,
  );
  const { errors, setErrorByField, setErrors } = useFieldErrorsState();

  const onSelectEntityType = event => {
    dispatch(selectEntityType(event.value, event.label));
    setErrorByField("entityType", null);
  };

  useEffect(() => {
    if (shouldValidateSection) {
      validateSection({
        businessApiDetails,
        businessNumber,
        companyNumber,
        entityName,
        entityType,
        errors,
        validationCallback: yupErrors => setErrors(yupErrors),
      });

      endValidation();
    }
  }, [shouldValidateSection]);

  useEffect(() => {
    validateSection({
      businessApiDetails,
      businessNumber,
      companyNumber,
      entityName,
      entityType,
      errors,
      validationCallback: yupErrors => {
        if (shouldValidateSection) {
          setErrors(yupErrors);
        }

        handleComplete(isBlank(yupErrors), section);
      },
    });
  }, [
    businessNumber,
    companyNumber,
    entityName,
    entityType,
    shouldValidateSection,
  ]);

  return (
    <section className={styles.section}>
      <div className={`${styles.row} ${styles.mobile_margin}`}>
        <div className={styles.full_col}>
          <PanelTitle text="Overview" margin_bottom="20px" />
        </div>
      </div>
      <div className={styles.row}>
        <BusinessNumber
          businessNumber={businessNumber}
          currentUser={currentUser}
          dispatch={dispatch}
          error={errors.businessNumber}
          isDisabled={isDisabled}
          setErrorByField={setErrorByField}
        />

        <CompanyNumber
          companyNumber={companyNumber}
          currentUser={currentUser}
          dispatch={dispatch}
          error={errors.companyNumber}
          isDisabled={isDisabled}
          setErrorByField={setErrorByField}
        />

        <div className={styles.left_col}>
          <div className={styles.form}>
            <OptionsDropdown
              disabled={isDisabled || isEntitySelectDisabled}
              error={errors.entityType}
              handleChange={onSelectEntityType}
              id="entity_type"
              label="Entity type"
              name="entity_type"
              options={ENTITY_TYPE_OPTIONS}
              required={true}
              value={entityType}
            />
          </div>
        </div>

        <div className={styles.left_col}>
          <div className={styles.form}>
            <TextInput
              disabled={true}
              id="entity_name"
              label="Entity name"
              required={true}
              value={entityName}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect(state => {
  const cobBusiness = state.cob_business;

  return {
    businessApiDetails: get(cobBusiness, "businessApiDetails", {}),
    businessNumber: get(cobBusiness, "company_details.business_number", ""),
    companyNumber: get(cobBusiness, "company_details.company_number", ""),
    currentUser: UserModel.fromCurrentUser(state.current_user),
    entityName: get(cobBusiness, "company_details.name", ""),
    entityType: cobBusiness.entity_type,
  };
})(BusinessOverview);
