import store from "stores/store";

export function isTokenised() {
  const state = store.getState();
  const authorisation = state.authorisation;
  const cobSection = state.cob_section;
  const antiFraud = cobSection.antiFraud || authorisation.antiFraud;

  return antiFraud && antiFraud.config.tokenised;
}
