/* eslint-disable max-lines */
import Collapse from "@material-ui/core/Collapse";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import debounce from "debounce";
import get from "lodash.get";
import ReportApplicationModel from "models/ReportApplicationModel";
import UserModel from "models/UserModel";
import { loadAddons } from "modules/addons/actions";
import NumApplicationsBlock from "modules/reporting/components/NumApplicationsBlock";
import useFilterState from "modules/reporting/hooks/useFilterState";
import BorderedTextField from "modules/shared/components/inputs/BorderedTextField";
import Button from "modules/shared/components/inputs/Button";
import TableListSkeleton from "modules/shared/components/v2/Skeletons/TableListSkeleton";
import Pagination from "modules/shared/components/widgets/interactive/Pagination";
import PopperTooltip from "modules/shared/components/widgets/interactive/PopperToolTip.js";
import Switch from "modules/shared/components/widgets/interactive/Switch";
import AdminInnerTitle from "modules/shared/components/widgets/static/AdminInnerTitle";
import SquareModal from "modules/shared/components/widgets/static/SquareModal";
import StatusIndicator from "modules/shared/components/widgets/static/StatusIndicator.js";
import arrayFromNumber from "modules/shared/helpers/arrayFromNumber";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import { canUserReviewVirtualCreditFile } from "modules/shared/helpers/currentUserHelper";
import { isHeadquarter } from "modules/shared/helpers/headquarterDetect";
import useCollapsibleTableRowState from "modules/shared/hooks/useCollapsibleTableRowState";
import { USER_UPDATE_PREFERENCE_SUCCESS } from "modules/user/constants";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { capitalizeSentence, formatMoney } from "utils/formatting";

import styles from "./css/Reporting.css";

const DEFAULT_PER_PAGE = 25;
const DEBOUNCE_INTERVAL = 500;

function ExportApplicationPopup(props) {
  return (
    <SquareModal title="Export" size="small">
      <div class_name="has-text-centered">
        {`A link to the CSV file will be sent to ${props.email} in few minutes.`}
      </div>
      <div>
        <Button text="Ok" handleClick={props.closePopup} />
      </div>
    </SquareModal>
  );
}

function ConsumerSupplierSwitch(props) {
  if (!props.isVisible) {
    return null;
  }

  const [currentRole, setCurrentRole] = useState("supplier");
  const handleChange = value => {
    props.updateSearchParams({ acting_as_supplier: value === "supplier" });
    setCurrentRole(value);
  };

  const isLeftOptionActive = true;
  const isRightOptionActive = true;

  return (
    <Switch
      leftOption="supplier"
      rightOption="consumer"
      leftLabel="My customers"
      rightLabel="My suppliers"
      current={currentRole}
      handleChange={handleChange}
      isLeftOptionActive={isLeftOptionActive}
      isRightOptionActive={isRightOptionActive}
    />
  );
}

// current_user.current_user_entity_link.attributes.role_types.includes("admin")
function StatisticsBlock(props) {
  const { activeBlock, meta, onClickBlock } = props;
  const blockSettings = [
    { color: "blue", key: "started", name: "Started" },
    { color: "grey", key: "incomplete", name: "Incomplete" },
    { color: "light-blue", key: "pending", name: "Pending" },
    { color: "blue", key: "approved", name: "Approved" },
    { color: "medium-grey", key: "declined", name: "Declined" },
    { color: "medium-grey", key: "archive", name: "Archive" },
  ];
  const onClick = blockKey => onClickBlock({ page: 1, status: blockKey });

  return (
    <div className={styles.statistics_block}>
      {blockSettings.map(block => (
        <NumApplicationsBlock
          key={block.key}
          color={block.color}
          title={block.name}
          value={meta[block.key] || 0}
          active={activeBlock === block.key}
          isCompact={true}
          onClick={() => onClick(block.key)}
        />
      ))}
      <NumApplicationsBlock
        color={"blue"}
        title={"Limits"}
        value={`$${formatMoney(parseFloat(meta.limits || 0))}`}
        active={false}
        isCompact={true}
        onClick={() => null}
      />
    </div>
  );
}

function MobileStatisticsBlocks(props) {
  const { activeBlock, meta, onClickBlock } = props;
  const blockSettings = [
    { key: "started", name: "Started" },
    { key: "incomplete", name: "Incomplete" },
    { key: "pending", name: "Pending" },
    { key: "approved", name: "Approved" },
  ];

  const onClick = blockKey => onClickBlock({ page: 1, status: blockKey });
  return (
    <div className={styles.mobilie_statistics_blocks}>
      {blockSettings.map(block => (
        <MobileStatisticsBlock
          key={block.key}
          name={block.name}
          value={meta[block.key] || 0}
          active={activeBlock === block.key}
          onClick={() => onClick(block.key)}
        />
      ))}
    </div>
  );
}

export function MobileStatisticsBlock(props) {
  const { name, value, active, onClick } = props;

  return (
    <div
      className={
        active ?
          styles.mobilie_statistics_block_active :
          styles.mobilie_statistics_block
      }
      onClick={onClick}
    >
      <div className={styles.mobilie_statistics_block_text}>
        <span>{name}</span>
        <br />
        <span>{value}</span>
      </div>
    </div>
  );
}

function Search(props) {
  if (!props.isVisible) {
    return null;
  }

  const {
    searchParams,
    updateSearchParams,
    dynamicFilterOptions,
    exportApplications,
    currentUser,
  } = props;
  const { filter, filterParams, onToggleIsOpen } = useFilterState(searchParams);
  const [showExportPopup, setShowExportPopup] = useState(false);

  const onChangeSearchKeyWord = event => {
    const value = get(event, "target.value");
    updateSearchParams({ key_word: value, page: 1 });
  };

  const onToggleShowMyApplication = () => {
    const value = !searchParams.only_current_user;
    updateSearchParams({ only_current_user: value });
  };

  const applicationFilter = React.cloneElement(filter, {
    dynamicFilterOptions,
  });

  const onExportApplications = () => {
    setShowExportPopup(true);
    exportApplications(searchParams);
  };

  useEffect(() => {
    updateSearchParams(filterParams);
  }, [filterParams]);

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div className={styles.search_container}>
        <div className={styles.search_input}>
          <BorderedTextField
            id="search"
            label="Search"
            value={searchParams.key_word}
            onChange={onChangeSearchKeyWord}
          />
        </div>
        <div className={styles.search_items}>
          <span
            className={`${styles.search_item} ${styles.clickable} ${styles.mobile_invisible}`}
            onClick={onToggleIsOpen}
            style={{ width: "30%" }}
          >
            Advanced&nbsp;
            <u>
              <b>search</b>
            </u>
          </span>
          <PopperTooltip
            title="View your own application list here if you are either the owner or tagged approver."
            placement="bottom"
            isVisibleOnMobile={false}
          >
            <span
              className={`${styles.search_item} ${styles.clickable} ${styles.mobile_invisible}`}
              onClick={onToggleShowMyApplication}
              style={{ width: "40%" }}
            >
              Show&nbsp;
              <u>
                <b>{`${searchParams.only_current_user ? "all" : "my"}`}</b>
              </u>
              &nbsp;applications
            </span>
          </PopperTooltip>
          {currentUser.isAdmin && (
            <span
              className={`${styles.search_item} ${styles.clickable} ${styles.mobile_invisible}`}
              onClick={onExportApplications}
              style={{ width: "30%" }}
            >
              Download&nbsp;
              <u>
                <b>report</b>
              </u>
            </span>
          )}
        </div>
        {showExportPopup && (
          <ExportApplicationPopup
            email={currentUser.email}
            closePopup={() => {
              setShowExportPopup(false);
            }}
          />
        )}
        <div className={styles.mobile_invisible}>{applicationFilter}</div>
      </div>
    </MuiThemeProvider>
  );
}

function ApplicationTableHeader(props) {
  return (
    <div
      className={`${styles.grid_table} ${styles.table_header} ${styles.mobile_invisible}`}
    >
      <div>{props.isSupplier ? "Customer" : "Supplier"}</div>
      <div>Status</div>
      <div>Limit</div>
      <div>Started</div>
      <div>Completed</div>
      <div>{isHeadquarter() ? "Tier" : "Owner"}</div>
    </div>
  );
}

function keyStatusName(statusName) {
  return statusName.toLowerCase().replace(/ /g, "-");
}

function ApplicationStatusIndicators({ id, statuses }) {
  return statuses.map(status => (
    <StatusIndicator
      status={status.status}
      withStatusName={false}
      key={`indicator-${keyStatusName(status.name)}-${id}`}
    />
  ));
}

function ApplicationStatusDetails(props) {
  const { applicationId, canReview, isSupplier, statuses } = props;

  const applicationStatus =
    statuses.find(({ name }) => name === "Application") || {};
  const applicationIncomplete = ["not_started", "in_progress"].includes(
    applicationStatus.status,
  );
  const buttonText = applicationIncomplete ? "Track" : "Review";

  function renderStatus() {
    return statuses.map(status => (
      <div
        className={styles.status_details_grid_table}
        key={`details-${keyStatusName(status.name)}-${applicationId}`}
      >
        <div className={styles.status_details_name}>{status.name}</div>
        <div>
          <StatusIndicator status={status.status} withStatusName={true} />
        </div>
        <div className={styles.mobile_invisible}>{status.started_on}</div>
        <div className={styles.mobile_invisible}>{status.completed_on}</div>
        <div className={styles.mobile_invisible}>{status.other}</div>
      </div>
    ));
  }

  return (
    <Fragment>
      {renderStatus()}
      {/**
       * At the
       * moment, `isSupplier` is a string equivalent of a boolean value.
       * The `toString()` will future-proof this variable just in case later on
       * it will become a mixture of an actual boolean value and a string
       **/}
      {isSupplier.toString() === "true" && (
        <div className={styles.review_button}>
          <Button
            text={buttonText}
            link={
              canReview ? `/dashboard/applications/${props.applicationId}` : ""
            }
            disabled={!canReview}
          />
          {!canReview && (
            <p className="mt-3">
              Please contact your team admin for authorising access to review
              virtual credit files.
            </p>
          )}
        </div>
      )}
    </Fragment>
  );
}

function getApplicationLimitColumn({ application, filterLimitType }) {
  if (filterLimitType === "approved_limits") {
    return application.formattedAcceptedLimit;
  }

  return application.formattedRequestedLimit;
}

function ApplicationRow(props) {
  const {
    application,
    current,
    index,
    isSupplier,
    searchParams,
    setCurrentIndex,
  } = props;
  const { icon, isOpen, onToggleIsOpen } = useCollapsibleTableRowState();

  const onClick = () => {
    setCurrentIndex(index);
    onToggleIsOpen();
  };


  const isLiquidated = application.alertType === "liquidation"
  const isDeregistered = application.alertType === "deregistered"

  const wrapperClassName = [styles.grid_table];

  if (isDeregistered || isLiquidated) {
    wrapperClassName.push(styles.grid_table_alert);
  }

  useEffect(() => {
    if (!current && isOpen) onToggleIsOpen();
  }, [current]);

  const limit = getApplicationLimitColumn({
    application,
    filterLimitType: searchParams.limit_type,
  });

  return (
    <div>
      <div className={wrapperClassName.join(" ")}>
        <div className={styles.trading_name_col} onClick={onClick}>
          <div>{icon}</div>
          <div className={styles.trading_name}>
            {capitalizeSentence(application.name)}
          </div>
        </div>
        <div className={styles.cell}>
          <ApplicationStatusIndicators
            statuses={application.statusesFormatted}
            id={application.id}
          />
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {limit}
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {application.startedOn}
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {application.completedOn}
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {application.branchOrOwner}
        </div>
      </div>
      <Collapse in={current && isOpen}>
        <div className={styles.details}>
          {application.formattedAlertType && (
            <div className={styles.alert_deregistered_text}>
              {`Alert - ${application.formattedAlertType}`}
            </div>
          )}
          <ApplicationStatusDetails
            applicationId={application.id}
            canReview={application.canReview}
            isSupplier={isSupplier}
            statuses={application.statusesFormatted}
          />
        </div>
      </Collapse>
    </div>
  );
}

function ApplicationTable(props) {
  const {
    applications,
    isSupplier,
    loading,
    loadingMore,
    meta,
    noMoreApplications,
    onFetchMoreApplicationsOnMobile,
    canReviewVirtualCreditFile,
    searchParams,
  } = props;
  const [currentIndex, setCurrentIndex] = useState();

  if (loading) {
    return <TableListSkeleton />
  }
  const hintOnMobile = noMoreApplications ?
    "No more records" :
    "Click to get more";

  return (
    <div className={styles.table_content}>
      <ApplicationTableHeader meta={meta} isSupplier={isSupplier} />
      {applications.map((application, index) => (
        <ApplicationRow
          application={application}
          current={currentIndex === index}
          index={index}
          isSupplier={isSupplier}
          key={`application-${index}`}
          searchParams={searchParams}
          setCurrentIndex={setCurrentIndex}
        />
      ))}
      <div
        className={`${styles.mobile_visible} ${styles.bottom_loader}`}
        onClick={
          !loadingMore && !noMoreApplications && onFetchMoreApplicationsOnMobile
        }
      >
        {loadingMore ? (
          <TableListSkeleton css_class="loader_relative" />
        ) : (
          <span>{hintOnMobile}</span>
        )}
      </div>
    </div>
  );
}

function useApplicationsState(props) {
  const { currentUser, location } = props;
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [noMoreApplications, setNoMoreApplications] = useState(false);
  const [pageOfMobile, setPageOfMobile] = useState(1);
  const [applications, setApplications] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [meta, setMeta] = useState({});
  const entityId = get(currentUser, "currentEntity.id");
  const [searchParams, setSearchParams] = useState({
    ...{
      acting_as_supplier: currentUser.currentEntity.supplier || false,
      page: 1,
      per: get(
        currentUser,
        "preferenceByCurrentEntity.reportApplicationPerPage",
        DEFAULT_PER_PAGE,
      ),
      status: "started",
    },
    ...location.query,
    ...(JSON.parse(localStorage.getItem(`searchParams-${entityId}`)) || {}),
  });

  const onFetchFilterOptions = () => {
    (async() => {
      const options = await ReportApplicationModel.loadApplicationFilterOptions(
        {
          accessToken: currentUser.accessToken,
          entityId,
        },
      );
      setFilterOptions(options);
    })();
  };

  const onFetchApplications = params => {
    (async() => {
      setLoading(true);
      const {
        applications,
        meta,
      } = await ReportApplicationModel.loadApplications({
        accessToken: currentUser.accessToken,
        entityId,
        params,
      });
      browserHistory.push({
        pathname: "/dashboard/reporting",
        query: params,
        state: get(location, "state"),
      });
      setNoMoreApplications(applications.length < DEFAULT_PER_PAGE);
      setApplications(applications);
      setMeta(meta);
      setLoading(false);
    })();
  };

  const onFetchMoreApplicationsOnMobile = () => {
    const existingApplications = applications;
    const page = pageOfMobile + 1;
    const params = { ...searchParams, page };

    (async() => {
      setLoadingMore(true);
      const {
        applications,
        meta,
      } = await ReportApplicationModel.loadApplications({
        accessToken: currentUser.accessToken,
        entityId,
        params,
      });
      setNoMoreApplications(applications.length < DEFAULT_PER_PAGE);
      setApplications(existingApplications.concat(applications));
      setPageOfMobile(page);
      setMeta(meta);
      setLoadingMore(false);
    })();
  };

  const exportApplications = params => {
    (async() => {
      await ReportApplicationModel.exportApplications({
        accessToken: currentUser.accessToken,
        entityId,
        params,
      });
    })();
  };

  const resetMobilePage = () => {
    setPageOfMobile(1);
    setNoMoreApplications(false);
  };

  return {
    applications,
    exportApplications,
    filterOptions,
    loading,
    loadingMore,
    meta,
    noMoreApplications,
    onFetchApplications,
    onFetchFilterOptions,
    onFetchMoreApplicationsOnMobile,
    resetMobilePage,
    searchParams,
    setSearchParams,
  };
}

function Reporting(props) {
  const { currentUser, dispatch } = props;
  const {
    applications,
    exportApplications,
    filterOptions,
    loading,
    loadingMore,
    meta,
    noMoreApplications,
    onFetchApplications,
    onFetchFilterOptions,
    onFetchMoreApplicationsOnMobile,
    resetMobilePage,
    searchParams,
    setSearchParams,
  } = useApplicationsState(props);

  const fetchApplication = useRef(
    debounce(onFetchApplications, DEBOUNCE_INTERVAL),
  ).current;

  useEffect(() => {
    onFetchFilterOptions();
    dispatch(loadAddons("alert_module"));
  }, []);

  useEffect(() => {
    fetchApplication(searchParams);
    resetMobilePage();
  }, [searchParams]);

  const canReviewVirtualCreditFile = canUserReviewVirtualCreditFile(
    currentUser,
  );

  const onClickPage = page => {
    updateSearchParams({ page });
  };

  const onClickPerPage = per => {
    updateSearchParams({ page: 1, per });
    currentUser.updatePreferences({ reportApplicationPerPage: per });
    dispatch({
      payload: currentUser.attributes.preference,
      type: USER_UPDATE_PREFERENCE_SUCCESS,
    });
  };

  const updateSearchParams = params => {
    const updatedParams = { ...searchParams, ...params };
    const newSearchParams = {};
    Object.keys(updatedParams).forEach(key => {
      if (![null, "", "All"].includes(updatedParams[key])) {
        newSearchParams[key] = updatedParams[key];
      }
    });
    if (!newSearchParams.start_date) {
      delete newSearchParams.start_date_from;
      delete newSearchParams.start_date_to;
    }
    if (!newSearchParams.review_date) {
      delete newSearchParams.review_date_from;
      delete newSearchParams.review_date_to;
    }
    setSearchParams(newSearchParams);
    localStorage.setItem(
      `searchParams-${get(currentUser, "currentEntity.id")}`,
      JSON.stringify(newSearchParams),
    );
  };

  let headerTitle = "Applications";
  if (searchParams.acting_as_supplier) {
    headerTitle = "Trade sales pipeline";
  }

  const canShowConsumerSupplierSwitch = () => {
    const roles = currentUser.currentUserEntityLink.roleTypes;
    const result = currentUser.currentEntity.supplier ?
      roles.includes("admin") ||
        ((roles.includes("approver") || roles.includes("standard")) &&
          roles.includes("consumer_manager")) :
      false;
    return result;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <div className={styles.section}>
          <div className={styles.header_title}>
            <AdminInnerTitle text={headerTitle} isNormalFont={true} />
            <div className={styles.switch}>
              <ConsumerSupplierSwitch
                isVisible={canShowConsumerSupplierSwitch()}
                updateSearchParams={updateSearchParams}
              />
            </div>
          </div>
          <StatisticsBlock
            meta={meta}
            activeBlock={searchParams.status}
            onClickBlock={updateSearchParams}
          />
          <Search
            searchParams={searchParams}
            updateSearchParams={updateSearchParams}
            dynamicFilterOptions={filterOptions}
            exportApplications={exportApplications}
            isVisible={searchParams.acting_as_supplier}
            currentUser={currentUser}
          />
        </div>
      </div>

      <div className={styles.table_container}>
        <div className={styles.section}>
          <ApplicationTable
            applications={applications}
            isSupplier={searchParams.acting_as_supplier}
            loading={loading}
            loadingMore={loadingMore}
            meta={meta}
            noMoreApplications={noMoreApplications}
            onFetchMoreApplicationsOnMobile={onFetchMoreApplicationsOnMobile}
            searchParams={searchParams}
            canReviewVirtualCreditFile={canReviewVirtualCreditFile}
          />
          <div className={styles.mobile_invisible}>
            <Pagination
              dataLength={meta.total}
              currentPage={searchParams.page}
              todosPerPage={searchParams.per}
              resourceName="Applications"
              optionViewPage={[
                { label: "25", value: DEFAULT_PER_PAGE },
                { label: "50", value: 50 },
              ]}
              handleClick={onClickPage}
              handleClickTodosPerPage={onClickPerPage}
            />
          </div>
        </div>
      </div>

      <div className={styles.footer_container}>
        <MobileStatisticsBlocks
          meta={meta}
          activeBlock={searchParams.status}
          onClickBlock={updateSearchParams}
        />
      </div>
    </div>
  );
}

export default connect(state => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(Reporting);
