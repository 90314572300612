import React, { ReactElement } from "react"

import ConsumerType from "./ConsumerType"
import { Wrapper } from "./styles";

interface Props {
  className: string,
  handleCheck: () => void,
  isManager: boolean,
}

function UserTypes({
  className,
  handleCheck,
  isManager,
}: Props): ReactElement {
  return (
    <Wrapper className={className}>
      <ConsumerType
        handleCheck={handleCheck}
        isChecked={isManager}
      />
    </Wrapper>
  )
}

export default UserTypes
