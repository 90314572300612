export const LOAD_STORE_NAMES_START = "LOAD_STORE_NAMES_START";
export const LOAD_STORE_NAMES_SUCCESS = "LOAD_STORE_NAMES_SUCCESS";
export const LOAD_STORE_NAMES_ERROR = "LOAD_STORE_NAMES_ERROR";

export const LOAD_STORE_NAME_START = "LOAD_STORE_NAME_START";
export const LOAD_STORE_NAME_SUCCESS = "LOAD_STORE_NAME_SUCCESS";
export const LOAD_STORE_NAME_ERROR = "LOAD_STORE_NAME_ERROR";

export const UPDATE_STORE_NAME_START = "UPDATE_STORE_NAME_START";
export const UPDATE_STORE_NAME_SUCCESS = "UPDATE_STORE_NAME_SUCCESS";
export const UPDATE_STORE_NAME_ERROR = "UPDATE_STORE_NAME_ERROR";

export const DELETE_STORE_NAME_START = "DELETE_STORE_NAME_START";
export const DELETE_STORE_NAME_SUCCESS = "DELETE_STORE_NAME_SUCCESS";
export const DELETE_STORE_NAME_ERROR = "DELETE_STORE_NAME_ERROR";

export const CREATE_STORE_NAMES_START = "CREATE_STORE_NAMES_START";
export const CREATE_STORE_NAMES_SUCCESS = "CREATE_STORE_NAMES_SUCCESS";
export const CREATE_STORE_NAMES_ERROR = "CREATE_STORE_NAMES_ERROR";

export const UPDATE_STORE_NAME_ALIAS_START = "UPDATE_STORE_NAME_ALIAS_START";
export const UPDATE_STORE_NAME_ALIAS_SUCCESS =
  "UPDATE_STORE_NAME_ALIAS_SUCCESS";
export const UPDATE_STORE_NAME_ALIAS_ERROR = "UPDATE_STORE_NAME_ALIAS_ERROR";
