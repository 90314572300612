/**
 * Defines child componenets as clickable
 *
 * Takes one main property, target which can either be a function reference or
 * a string path to link to.
 */

/* Import libraries */
import React, { useState } from "react";
import { Link } from "react-router";

import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";

/* Impot styles */
import styles from "./css/Clickable.css";

function Clickable(props) {
  const {
    buttonCssOverrides,
    children,
    css_style,
    target,
    inline,
    tip_name,
    tip_css_style,
    ...rest
  } = props;

  const [isTooltipActive, setIsTooltipActive] = useState(false);

  function showTooltip() {
    setIsTooltipActive(true);
  }

  function hideTooltip() {
    setIsTooltipActive(false);
  }

  let tool_tip;

  if (tip_name && isTooltipActive) {
    tool_tip = <ToolTip tip_name={tip_name} css_style={tip_css_style} />;
  }

  if (typeof target === "function") {
    if (inline) {
      return (
        <span
          className={styles[css_style || "targetClickable"]}
          onClick={target}
          {...rest}
          style={buttonCssOverrides}
        >
          {children}
        </span>
      );
    } else {
      return (
        <div
          className={styles[css_style || "targetClickable"]}
          onClick={target}
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
          {...rest}
          style={buttonCssOverrides}
        >
          {children}
          {tool_tip}
        </div>
      );
    }
  } else {
    return (
      <Link
        className={styles[css_style || "linkClickable"]}
        to={target}
        {...rest}
        style={buttonCssOverrides}
      >
        {children}
      </Link>
    );
  }
};

export default Clickable;
