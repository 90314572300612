/* Import libraries */
/* Import components */
import Button from "modules/shared/components/inputs/Button";
import VideoModal from "modules/shared/components/widgets/interactive/VideoModal";
import Loader from "modules/shared/components/widgets/static/Loader";
import SectionBadge from "modules/shared/components/widgets/static/SectionBadge";
import SectionDescription from "modules/shared/components/widgets/static/SectionDescription";
import SectionTitle from "modules/shared/components/widgets/static/SectionTitle";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

import {
  getCurrentPricingPlan,
  showPricingPlan,
} from "../../../pricing-plans/actions";
import {
  clearState, createApplicationForNewConsumer,
} from "../../actions/invite";
/* Import CSS */
import styles from "./css/InviteSend.css";

class InviteNewSend extends React.Component {
  state = {
    showLoader: true,
  };

  UNSAFE_componentWillMount() {
    const {
      dispatch,
      newConsumer,
      application_type,
      permissions,
      iufAnswers,
    } = this.props;

    dispatch(
      getCurrentPricingPlan(null, function(data) {
        if (
          data.attributes.plan_no === 0 ||
          data.attributes.application_tally < data.attributes.application_max
        ) {
          dispatch(
            createApplicationForNewConsumer({
              ...newConsumer,
              application_type,
              iufAnswers,
              permissions,
            }),
          );
        } else {
          dispatch(showPricingPlan(true, "application", "/dashboard/home"));
        }
      }),
    );
  }

  loadingComplete = () => {
    const { dispatch, newConsumer } = this.props;
    this.setState({
      showLoader: false,
      ...newConsumer,
    });
    dispatch(clearState());
  }

  render() {
    const {
      title,
      return_path,
      saving,
      application_type,
    } = this.props;
    const { company_name, email, first_name, last_name, showLoader } = this.state;
    const videlName = `${application_type}_flow`;

    const consumer_name = company_name ?
      company_name :
      [first_name, last_name].join(" ");

    if (showLoader) {
      return <Loader loading={saving} handleComplete={this.loadingComplete} />;
    }

    return (
      <div className={styles.containerFlex}>
        <section className={styles.blue_section}>
          <div className={styles.row}>
            <div className={styles.panel}>
              <SectionTitle white={true} text={title} />
              <SectionBadge icon="email" />
            </div>
          </div>
          <div className={styles.description_row}>
            <div className={styles.description}>
              <SectionDescription
                text={`An application email has been sent to ${consumer_name} at ${email}`}
              />
              <div style={{ fontSize: "10px" }}>
                Click <VideoModal videoName={videlName} color={"inherit"} /> to
                see how your customer will complete the trade account.
              </div>
              <div className={styles.button_outer}>
                <Button
                  css_style="button_white_outer"
                  text="ok"
                  handleClick={e => {
                    browserHistory.push(return_path);
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const defaults = {
  title: "Application invite sent!",
};

export default connect((state, ownProps) => {
  return {
    iufAnswers: state.cns_invite.iuf_answers,
    newConsumer: {
      company_name: state.cns_invite.new_consumer_company_name,
      email: state.cns_invite.new_consumer_email,
      first_name: state.cns_invite.new_consumer_first_name,
      last_name: state.cns_invite.new_consumer_last_name,
    },
    return_path: state.cns_invite.return_path,
    saving: state.cns_invite.saving,
    title: defaults.title,
  };
})(InviteNewSend);
