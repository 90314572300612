import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { enquiryResponseData } from "./helpers/reportHelpers";
import styles from "../../../css/CommonReviewBusiness.css";
import { valueByKey } from "./helpers/summary";

var Documents = createClass({
  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    let documents = valueByKey(summaryEntry, "ASIC_Documents"),
      documentLabel = "Documents";

    if (documents === 1) documentLabel = "Document";

    return (
      <ReviewContainer
        subHeading="Documents"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <div>
          <strong>
            ASIC Documents ({documents} {documentLabel})
          </strong>
        </div>
        Not present on file
      </ReviewContainer>
    );
  },
});

export default Documents;
