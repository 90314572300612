import React from "react";
import moment from "moment";

import styles from "../centrix.css";

import PaymentHistory from "./shared/PaymentHistory";

class AccountInformation extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  formatSummaryGraphData(data) {
    let graph_data = data.data_sets.summary_items.summary_item.find(
      item => item.summary_item_type === "PaymentHistoryAggregated",
    );

    return graph_data
      ? graph_data.name_value_pairs.name_value_pair.map(pair => {
          let split_name = pair.name.split("-");
          return {
            year: split_name[0],
            month: split_name[1],
            status: pair.value,
          };
        })
      : false;
  }

  formatIndividualGraphData(data) {
    return data.map(obj => ({
      year: obj.year,
      month: obj.month,
      status: obj.payment_status,
    }));
  }

  formatHistorySummary(data) {
    let history_data = data.data_sets.summary_items.summary_item.find(
      item => item.summary_item_type === "PaymentHistory",
    );

    if (!history_data) {
      return {};
    }

    let result = {};
    history_data.name_value_pairs.name_value_pair.forEach(pair => {
      result[pair.name] = pair.value;
    });
    return result;
  }

  arrayifyPaymentHistory(payment_history) {
    if (!payment_history || !payment_history.accounts) {
      return [0];
    }
    let formatted_payment_history = payment_history.accounts.account;
    if (formatted_payment_history.constructor !== Array) {
      formatted_payment_history = [formatted_payment_history];
    }
    return formatted_payment_history;
  }

  getOpenCredit(formatted_payment_history) {
    const active_statuses = ["A", "U"];
    let result = formatted_payment_history.reduce((amount, obj) => {
      if (active_statuses.includes(obj.account_status)) {
        return amount + parseInt(obj.credit_limit);
      } else {
        return amount;
      }
    }, 0);
    return result;
  }

  getOverduesReported(data, months) {
    const overdue_payment_statuses = ["1", "2", "3", "4", "5", "6", "X", "H"];
    return [...data]
      .reverse()
      .slice(0, months)
      .reduce((overdues, payment) => {
        if (overdue_payment_statuses.includes(payment.status)) {
          return overdues + 1;
        } else {
          return overdues;
        }
      }, 0);
  }

  renderAccount(account) {
    let payment_history =
      account.payment_profile && account.payment_profile.payment_period;
    return (
      <div>
        <h4>Account reference: {account.account_id}</h4>
        <h4>Supplier: {account.supplier}</h4>
        {payment_history && (
          <div className={styles.clearfix}>
            <PaymentHistory
              data={this.formatIndividualGraphData(payment_history)}
            />
          </div>
        )}
        <div className={styles.table_container}>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Account Type</td>
                <td>{account.account_type_description}</td>
              </tr>
              <tr>
                <td>Account Status</td>
                <td>{account.account_status_description}</td>
              </tr>
              <tr>
                <td>Last Account Status Change</td>
                <td>
                  {moment(account.account_status_date).format("DD/MM/YYYY")}
                </td>
              </tr>
              <tr>
                <td>Industry Type</td>
                <td>{account.industry_type_description}</td>
              </tr>
              <tr>
                <td>Customer Start Date</td>
                <td>
                  {moment(account.customer_start_date).format("DD/MM/YYYY")}
                </td>
              </tr>
              <tr>
                <td>Customer Closed Date</td>
                <td>
                  {account.customer_cease_date &&
                    account.customer_cease_date.constructor === String &&
                    moment(account.customer_cease_date).format("DD/MM/YYYY")}
                </td>
              </tr>
              <tr>
                <td>Account Relationship</td>
                <td>{account.account_relationship_description}</td>
              </tr>
              <tr>
                <td>Term</td>
                <td>{account.term_of_loan}</td>
              </tr>
              <tr>
                <td>Credit Limit</td>
                <td>{account.credit_limit}</td>
              </tr>
              <tr>
                <td>Credit Type</td>
                <td>{account.credit_type_description}</td>
              </tr>
              <tr>
                <td>Commercial/Consumer Credit</td>
                <td>{account.credit_purpose_description}</td>
              </tr>
              <tr>
                <td>Secured Credit Type</td>
                <td>{account.secured_credit_type_description}</td>
              </tr>
              <tr>
                <td>Payment Frequency</td>
                <td>{account.payment_frequency_description}</td>
              </tr>
              <tr>
                <td>Payment Type</td>
                <td>{account.payment_type_description}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    let graph_data = this.formatSummaryGraphData(data);
    let history_summary = this.formatHistorySummary(data);
    let arrayified_payment_history = this.arrayifyPaymentHistory(
      data.data_sets.payment_history,
    );
    return (
      <div>
        <h3>Aggregated Payment Performance</h3>
        {graph_data && (
          <div className={styles.clearfix}>
            <PaymentHistory data={graph_data} />
          </div>
        )}
        <div className={styles.table_container}>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Extended Credit - Open:</td>
                <td>{this.getOpenCredit(arrayified_payment_history)}</td>
              </tr>
              <tr>
                <td>Number of Accounts Open:</td>
                <td>{history_summary["TotalActiveAccounts"]}</td>
              </tr>
              <tr>
                <td>Number of Accounts Reported:</td>
                <td>{arrayified_payment_history.length}</td>
              </tr>
              <tr>
                <td>Overdue reported last 6 months:</td>
                <td>{this.getOverduesReported(graph_data, 6)}</td>
              </tr>
              <tr>
                <td>Overdue reported last 12 months:</td>
                <td>{this.getOverduesReported(graph_data, 12)}</td>
              </tr>
              <tr>
                <td>Overdue reported last 24 months:</td>
                <td>{this.getOverduesReported(graph_data, 24)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {arrayified_payment_history.map(history_item =>
          this.renderAccount(history_item),
        )}
      </div>
    );
  }
}

export default AccountInformation;
