import api from "api";
import BaseModel from "models/BaseModel";
import { setTheme } from "modules/shared/helpers/colorPalettes";

export default class ModulePaperlessAuthorisationFlowModel extends BaseModel {
  async load(paperlessAuthorisationId) {
    const paperlessAuthorisationAPI = api(
      "module_paperless_authorisation_flows",
    );
    try {
      this.isLoading = true;
      const result = await paperlessAuthorisationAPI.getDetails(
        paperlessAuthorisationId,
      );
      this.setAttributes(result.data);
      if (result.data.theme.main_color) {
        setTheme(result.data.theme);
      }
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  async saveDecision(paperlessAuthorisationId) {
    const paperlessAuthorisationAPI = api(
      "module_paperless_authorisation_flows",
    );
    const params = { approved: this.attributes.approved };
    try {
      this.isLoading = true;
      const result = await paperlessAuthorisationAPI.update(
        paperlessAuthorisationId,
        params,
      );
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  async saveIDphoto(paperlessAuthorisationId) {
    const paperlessAuthorisationAPI = api(
      "module_paperless_authorisation_flows",
    );
    const params = {
      approved: this.attributes.approved,
      image_64: this.attributes.image_64,
    };
    try {
      this.isLoading = true;
      const result = await paperlessAuthorisationAPI.update(
        paperlessAuthorisationId,
        params,
      );
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }
}
