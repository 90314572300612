import BaseModel from "models/BaseModel";
import { Address } from "modules/shared/components/v2/Forms/type";

export interface ITradingName {
  contactEmail: string;
  contactName: string;
  contactPhoneNumber: string;
  createdAt: string;
  deliveryAddressDetails: Address;
  editable: boolean;
  entityId: string;
  id: string,
  tradingName: string;
  updatedAt: string;
}

export default class TradingNameModel extends BaseModel {}

