import {
  LOAD_CONFIG_ERROR,
  LOAD_CONFIG_START,
  LOAD_CONFIG_SUCCESS,
  SENT_BRANCH_INVITE_ERROR,
  SENT_BRANCH_INVITE_START,
  SENT_BRANCH_INVITE_SUCCESS,
} from "./constants";

const entity_structure_defaults = {
  // searching_company:  false,
  // auto_suggest_company: [],
  loadingConfig: false,
};

export function entityStructureReducer(
  state = entity_structure_defaults,
  action,
) {
  switch (action.type) {
    case SENT_BRANCH_INVITE_START:
      return { ...state };
    case SENT_BRANCH_INVITE_SUCCESS:
      return { ...state };
    case SENT_BRANCH_INVITE_ERROR:
      return { ...state };
    case LOAD_CONFIG_START:
      return { ...state, loadingConfig: true }
    case LOAD_CONFIG_SUCCESS:
      return { ...state, loadingConfig: false }
    case LOAD_CONFIG_ERROR:
      return { ...state, loadingConfig: false }
    default:
      return { ...state };
  }
}
