import React from "react";
import moment from "moment";

import styles from "../../css/CommonReviewBusiness.css";

var PPSRFinanceStatement = createClass({
  // '29-12-2007 06:01:46'
  _parseDate(date) {
    return moment(date, "DD-MM-YYYY HH:mm:ss").format("DD-MMM-YYYY");
  },

  _financeStatement() {
    const { data } = this.props;

    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      statementSummaries = enquiryResult.ppsr_finance_statement_summaries || {},
      statementSummary =
        statementSummaries.ppsr_finance_statement_summary || [];

    if (statementSummary.constructor === Object)
      statementSummary = [statementSummary];

    return statementSummary.map((summary, index) => {
      return (
        <tr key={index}>
          <td>{summary.fs_number}</td>
          <td>{this._parseDate(summary.registration_date)}</td>
          <td>{summary.secured_party}</td>
          <td>{summary.collateral}</td>
        </tr>
      );
    });
  },

  render() {
    return (
      <table className={styles.table_w20}>
        <thead>
          <tr>
            <th>Number</th>
            <th>Date</th>
            <th>Secured Party</th>
            <th>Collateral</th>
          </tr>
        </thead>
        <tbody>{this._financeStatement()}</tbody>
      </table>
    );
  },
});

export default PPSRFinanceStatement;
