import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import React from "react";
import InputMask from "react-input-mask";

import styles from "./css/TextInput.css";

export default function UncontrolledTextInput(props) {
  const {
    autocomplete,
    defaultValue,
    disabled,
    endAdornment,
    error,
    formControlProps,
    id,
    inputRef,
    label,
    mask,
    maskInput,
    multiline,
    onBlur,
    onChange,
    onClick,
    rows,
    shrinkLabel,
    type,
    value,
  } = props;

  return (
    <div className={styles.material_container}>
      <FormControl
        error={!!error}
        className={styles.material_form_control}
        disabled={disabled}
        {...formControlProps}
      >
        <InputLabel
          htmlFor={id}
          classes={{
            root: styles.material_label,
            shrink: styles.material_label_shrink,
          }}
          shrink={shrinkLabel}
        >
          {label}
        </InputLabel>
        <Input
          className={styles.material_input}
          id={id}
          name={id}
          inputRef={inputRef}
          defaultValue={defaultValue}
          multiline={multiline}
          rows={rows}
          endAdornment={endAdornment}
          type={type}
          autoComplete={autocomplete}
          inputProps={{
            mask,
            onBlur,
            onChange,
            onClick,
          }}
          inputComponent={maskInput && maskInput}
          value={value}
        />
      </FormControl>
      <FormHelperText
        error={!!error}
        className={styles.material_helper_text}
        style={
          error ?
            { fontSize: 10, fontWeight: 800 } :
            { fontSize: 10, fontWeight: 400 }
        }
      >
        {error}
      </FormHelperText>
    </div>
  );
}

UncontrolledTextInput.defaultProps = {
  disabled: false,
  formControlProps: {},
  type: "text",
};
