import get from "lodash.get";
import ApplicationModel from "models/ApplicationModel";
import ApplicationTradingNameLinkModel from "models/ApplicationTradingNameLinkModel";
import FixedContent from "modules/shared/components/containers/FixedContent";
import React, { ReactElement } from "react";

import AddonAnswers from "../../AddonAnswer";

type Props = {
  owner: any,
}

function TradingNamesIUFInformation({
  owner,
}: Props): ReactElement | null {
  if (!(owner instanceof ApplicationModel)) {
    return null;
  }

  const components = get(owner, "iufAddonRule.config.trading_entity.components", []);
  const applicationTradingNameLinks = get(owner, "applicationTradingNameLinks", []);

  return applicationTradingNameLinks.map((applicationTradingNameLink: ApplicationTradingNameLinkModel) => {
    const tradingName = get(applicationTradingNameLink, "tradingName")
    const addonAnswers = get(applicationTradingNameLink, "addonAnswer.answers.results", []);

    if (addonAnswers.length === 0) {
      return null;
    }

    return (
      <FixedContent header={tradingName.tradingName} key={applicationTradingNameLink.id}>
        <AddonAnswers
          answers={addonAnswers}
          components={components}
        />
      </FixedContent>
    );
  })
}

export default TradingNamesIUFInformation;
