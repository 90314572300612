/* eslint-disable max-lines */
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SendIcon from "@material-ui/icons/Send";
import api from "api";
import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import { MobileScreenContext } from "modules/new-applications/components/Application";
import IdentificationImage from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage";
import IdentificationOutcome from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome";
import SignatureDetails from "modules/new-applications/components/application-sections/IdentificationCheck/SignatureDetails";
// import getIsAntiFraudVerified from "modules/new-applications/components/application-sections/IdentificationCheck/utils/getIsAntiFraudVerified";
import getIsAntiFraudManualVerificationRequired from "modules/new-applications/components/application-sections/IdentificationCheck/utils/getIsAntiFraudManualVerificationRequired";
import RecordHistory from "modules/new-applications/components/RecordHistory";
import StatusIndicator from "modules/new-applications/components/StatusIndicator";
import commonStyles from "modules/new-applications/css/common.css";
import styles from "modules/new-applications/css/IdentificationChecks.css";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import ContentWithFooter from "modules/shared/components/containers/ContentWithFooter";
import FixedContent from "modules/shared/components/containers/FixedContent";
import ScrollableContent from "modules/shared/components/containers/ScrollableContent";
import BorderedTextField from "modules/shared/components/inputs/BorderedTextField";
import Button from "modules/shared/components/inputs/Button";
import FileWidgetButton from "modules/shared/components/widgets/interactive/FileWidgetButton";
import ImageModal from "modules/shared/components/widgets/static/ImageModal";
import PdfThumbnail from "modules/shared/components/widgets/static/PdfThumbnail";
import {
  isDecentralize1CAHBranch,
  isDifferentBranchApplication,
} from "modules/shared/helpers/headquarterDetect";
import useCollapsibleContentState from "modules/shared/hooks/useCollapsibleContentState";
import React, { Fragment, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import isBlank from "utils/isBlank";
import isPDF from "utils/isPDF";
import isPresent from "utils/isPresent";
import * as yup from "yup";

import isAntiFraudIdFailed from "./IdentificationCheck/utils/isAntiFraudIdFailed";

const DEFAULT_INTERVAL = 1000;

const ACTING_AS_LABEL = {
  applicant: "Applicant",
  cardholder: "Cardholder",
  guarantor: "Guarantor",
  payment: "Direct debit",
  signatory: "Signatory",
};

const emailSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email"),
});

export function ActionIcon(props) {
  const { Icon, color, title } = props;

  const style = { fontSize: 15 };

  if (isPresent(color)) {
    style["color"] = color;
  }

  return (
    <Tooltip title={title}>
      <Icon style={style} color="primary" />
    </Tooltip>
  );
}

function AuthorisationCheckbox(props) {
  const { color, label } = props;

  return (
    <FormControlLabel
      classes={{
        label: styles[`${color}_checkbox_label`],
        root: styles.label_root,
      }}
      disabled
      control={
        <Checkbox
          checked={true}
          checkedIcon={<CheckBoxIcon style={{ fontSize: 16 }} />}
        />
      }
      label={label}
    />
  );
}

function AgreedCheckbox() {
  return <AuthorisationCheckbox color="green" label="Agreed" />;
}

function DeclinedCheckbox() {
  return <AuthorisationCheckbox color="red" label="Declined" />;
}

function GreyCheckbox() {
  return <AuthorisationCheckbox color="grey" />;
}

function EmptyCheckbox() {
  return (
    <FormControlLabel
      classes={{
        label: styles.authorisation_checkbox_label,
        root: styles.label_root,
      }}
      disabled
      control={
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 16 }} />}
          checked={false}
        />
      }
    />
  );
}

export function ActingAsCheckbox(props) {
  const { authorisation, actingAs } = props;
  const agreement = get(authorisation, `agreements.${actingAs}`);

  if (typeof agreement === "undefined") {
    return <EmptyCheckbox />;
  }

  if (authorisation.status !== "complete") {
    return <GreyCheckbox />;
  }

  if (agreement) {
    return <AgreedCheckbox />;
  }

  return <DeclinedCheckbox />;
}

function ProofOfAddress(props) {
  const {
    application,
    authorisation,
    currentUser,
    isViewOnly,
    signature,
    onShowImageModal,
    onFetchApplicationRecord,
  } = props;
  const proofOfAddressUrl = signature.proofOfAddressUrl;
  const isAMLCheckRequired = application.isAMLCheckRequired({
    actingAs: authorisation.actingAs,
    proofOfAddressUrl,
    region: signature.region,
  });

  if (!isAMLCheckRequired) {
    return null;
  }

  let poaWidget = null;
  const onClickThumbnail = () => onShowImageModal(proofOfAddressUrl);

  if (isPDF(proofOfAddressUrl)) {
    poaWidget = (
      <div className={styles.signature_pdf} onClick={onClickThumbnail}>
        <PdfThumbnail url={proofOfAddressUrl} />
      </div>
    );
  } else {
    poaWidget = (
      <div className={styles.signature_image_container}>
        <img
          src={proofOfAddressUrl}
          alt="proof-of-address"
          className={styles.signature_image}
          onClick={onClickThumbnail}
        />
      </div>
    );
  }

  const { isLoading, setIsLoading } = useIsLoadingState();

  const onSuccessCallback = newSignature => {
    authorisation.assignSignature = newSignature;
    onFetchApplicationRecord();
    setIsLoading(false);
  };

  const onClickUpload = data => {
    setIsLoading(true);

    signature.saveProofOfAddress({
      attributes: { proof_of_address: data },
      currentUser,
      onSuccessCallback,
    });
  };

  let actionButtons = null;
  if (!isViewOnly) {
    actionButtons = (
      <div className={styles.e_signature_buttons}>
        <FileWidgetButton
          disabled={isViewOnly || isLoading}
          inputId={`change-poa-${signature.id}`}
          isCompact={true}
          handleFileCallback={onClickUpload}
          edit_text="Change"
          text="Change"
          accept=".jpg,.jpeg,.png,.pdf"
        />
      </div>
    );
  }

  return (
    <Fragment>
      <div className={`${styles.e_signature_header} ${styles.text_centered}`}>
        <span className="underlined">Proof of address</span>
      </div>
      <div className={styles.e_signature_attachment_content}>
        {poaWidget}
        {actionButtons}
      </div>
    </Fragment>
  );
}

ProofOfAddress.defaultProps = {
  isViewOnly: true,
};

export function DigitalSignature(props) {
  const { application, authorisation, signature } = props;

  if (signature.isNewRecord) {
    return (
      <div className={styles.e_signature_container}>
        <div>
          <div className={styles.e_signature_header}>
            Signature not available
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.e_signature_container}>
      <div>
        <div className={styles.e_signature_header}>
          <span className="underlined">eSignature</span>
        </div>
        <SignatureDetails label="IP" content={signature.ipAddress} />
        <SignatureDetails
          label={signature.identificationTypeLabel}
          content={signature.identificationNumber}
        />
        <SignatureDetails
          label="Version number"
          content={signature.identificationVersion}
        />
        <SignatureDetails
          label="Expiry date"
          content={signature.formattedIdentificationExpiryDate}
        />
        <SignatureDetails label="DOB" content={signature.formattedDOB} />

        <div className={`${styles.e_signature_header} mt-3`}>
          <span className="underlined">App details</span>
        </div>
        <SignatureDetails
          label="Date"
          content={get(signature, "timestampParts.date")}
        />
        <SignatureDetails
          label="Time"
          content={get(signature, "timestampParts.time")}
        />
        <SignatureDetails label="Timezone" content={signature.timezone} />
        <SignatureDetails label="Location" content={signature.location} />
        <SignatureDetails
          label="Address"
          content={signature.fullResidentialAddress}
        />
      </div>
      <div>
        <IdentificationOutcome {...props} />
      </div>
      <div>
        <IdentificationImage
          isViewOnly={application.isViewOnly}
          antiFraudCheck={authorisation.antiFraudCheck}
          {...props}
        />
      </div>
      <div>
        <ProofOfAddress isViewOnly={application.isViewOnly} {...props} />
      </div>
    </div>
  );
}

function isPassFailActionHidden({
  application,
  authorisation,
  identificationCheck,
  signature,
}) {
  if (
    application.isViewOnly ||
    !application.isSubmitted ||
    !authorisation.isComplete ||
    signature.isNewRecord ||
    signature.isManuallyApproved
  ) {
    return true;
  }

  if (application.antiFraudEnabled) {
    const isAntiFraudManualVerificationRequired = getIsAntiFraudManualVerificationRequired(
      {
        application,
        authorisation,
        identificationCheck,
        signature,
      },
    );

    return !isAntiFraudManualVerificationRequired;
  }

  return (
    !application.isIdentificationCheckRequired ||
    identificationCheck.isIdentificationCheckSuccess
  );
}

function isResendApplicationVisible({ application, currentUser, isApplicant }) {
  if (application.isViewOnly || application.isReviewed) {
    return false;
  }

  if (!isApplicant && !application.isSubmitted) {
    return false;
  }

  if (
    application.modelType === "application" &&
    isDecentralize1CAHBranch() &&
    isDifferentBranchApplication({ application, currentUser })
  ) {
    return false;
  }

  return true;
}

export const ACTION_COMPONENTS = {
  desktop: {
    CopyLinkAction: DesktopCopyLinkAction,
    PassAction: DesktopPassAction,
    ResendAction: DesktopResendAction,
  },
  mobile: {
    CopyLinkAction: MobileCopyLinkAction,
    PassAction: MobilePassAction,
    ResendAction: MobileResendAction,
  },
};

function DesktopPassAction(props) {
  const { onClick } = props;

  return (
    <div className={styles.pass_action} onClick={onClick}>
      Pass
    </div>
  );
}

function DesktopResendAction(props) {
  const { isSending, label, onClick } = props;

  if (isSending) {
    return (
      <div>
        <ActionIcon Icon={CheckIcon} title={label} color="green" />
      </div>
    );
  }

  return (
    <div className={styles.resend_action} onClick={onClick}>
      <ActionIcon Icon={SendIcon} title={label} />
    </div>
  );
}

function MobilePassAction(props) {
  const { isLoading, onClick } = props;

  return <Button white loading={isLoading} text="Pass" handleClick={onClick} />;
}

function MobileResendAction(props) {
  const { isSending, label, onClick } = props;

  return <Button loading={isSending} text={label} handleClick={onClick} />;
}

export function PassFailAction(props) {
  const {
    PassActionComponent,
    application,
    authorisation,
    currentUser,
    identificationCheck,
    onFetchApplicationRecord,
  } = props;
  const { signature } = authorisation;
  const { isLoading, setIsLoading } = useIsLoadingState();

  if (
    isPassFailActionHidden({
      application,
      authorisation,
      identificationCheck,
      signature,
    })
  ) {
    return null;
  }

  const onSuccessCallback = newSignature => {
    authorisation.assignSignature = newSignature;
    onFetchApplicationRecord();
    setIsLoading(false);

    if (application.antiFraudEnabled) {
      mixpanel.track("1CAF ID Manual PASS", {
        "Application ID": application.id,
        "Entity ID": get(currentUser, "currentEntity.id"),
        distinct_id: currentUser.id,
      });
    }
  };

  const onClickManuallyApprove = manuallyApproved => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    signature.saveManuallyApprove({
      attributes: { manually_approved: manuallyApproved },
      currentUser,
      onSuccessCallback,
    });
  };

  const onClickPass = () => onClickManuallyApprove(true);

  return <PassActionComponent isLoading={isLoading} onClick={onClickPass} />;
}

export function getResendLabel({ isApplicant, isMobileScreen, isSending }) {
  if (isSending) {
    return "Sending";
  }

  if (isMobileScreen) {
    return "Resend";
  }

  if (isApplicant) {
    return "Resend application";
  }

  return "Resend authorisation";
}

function ResendAction(props) {
  const {
    ResendActionComponent,
    application,
    currentUser,
    isApplicant,
    isSending,
    onClick,
  } = props;
  const { isMobileScreen } = useContext(MobileScreenContext);
  const label = getResendLabel({ isApplicant, isMobileScreen, isSending });

  if (isResendApplicationVisible({ application, currentUser, isApplicant })) {
    return (
      <ResendActionComponent
        isSending={isSending}
        label={label}
        onClick={onClick}
      />
    );
  }

  return null;
}

async function superAdminCopyApplicationLinkOnClick({
  application,
  currentUser,
  onSetCopiedState,
  setIsCopied,
}) {
  const applicationAPI = api(
    "applications",
    currentUser.accessToken,
    get(currentUser, "currentEntity.id"),
  );

  try {
    const response = await applicationAPI.getSuperAdminApplicationLink(
      application.id,
    );

    await navigator.clipboard.writeText(get(response, "data.link", ""));
    onSetCopiedState();
  } catch (error) {
    setIsCopied(false);
    console.error(error);
  }
}

async function superAdminCopyAuthorisationOnClick({
  authorisation,
  currentUser,
  onSetCopiedState,
  setIsCopied,
}) {
  const authorisationAPI = api(
    "authorisations",
    currentUser.accessToken,
    get(currentUser, "currentEntity.id"),
  );

  try {
    const response = await authorisationAPI.getSuperAdminAuthorisationLink(
      authorisation.id,
    );

    await navigator.clipboard.writeText(get(response, "data.link", ""));
    onSetCopiedState();
  } catch (error) {
    setIsCopied(false);
    console.error(error);
  }
}

export function SuperAdminCopyLink(props) {
  const {
    application,
    authorisation,
    currentUser,
    isApplicant,
    isVisible,
  } = props;

  if (!isVisible) {
    return null;
  }

  if (!currentUser.superAdmin) {
    return null;
  }

  if (application.isViewOnly) {
    return null;
  }

  const [isCopied, setIsCopied] = useState(false);
  const onSetCopiedState = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), DEFAULT_INTERVAL);
  };

  const onClick = () => {
    if (isApplicant) {
      superAdminCopyApplicationLinkOnClick({
        application,
        currentUser,
        onSetCopiedState,
        setIsCopied,
      });
    } else {
      superAdminCopyAuthorisationOnClick({
        authorisation,
        currentUser,
        onSetCopiedState,
        setIsCopied,
      });
    }
  };

  if (isCopied) {
    return (
      <div>
        <ActionIcon
          Icon={AssignmentTurnedInIcon}
          title="Copied"
          color="green"
        />
      </div>
    );
  }

  return (
    <div className={styles.clickable} onClick={onClick}>
      <ActionIcon
        Icon={AssignmentLateIcon}
        title="Copy link with token"
        color="orange"
      />
    </div>
  );
}

SuperAdminCopyLink.defaultProps = {
  isVisible: true,
};

function DesktopCopyLinkAction(props) {
  const { isCopied, label, onClick } = props;

  if (isCopied) {
    return (
      <div>
        <ActionIcon Icon={CheckIcon} title={label} color="green" />
      </div>
    );
  }

  return (
    <div className={styles.clickable} onClick={onClick}>
      <ActionIcon Icon={FileCopyIcon} title={label} />
    </div>
  );
}

function MobileCopyLinkAction(props) {
  const { isCopied, label, onClick } = props;

  return <Button loading={isCopied} text={label} handleClick={onClick} />;
}

function CopyLink(props) {
  const { CopyLinkActionComponent, application, isApplicant } = props;

  if (!isApplicant || application.isViewOnly || application.isReviewed) {
    return null;
  }

  const [isCopied, setIsCopied] = useState(false);

  const onCopyToClipboard = async() => {
    try {
      await navigator.clipboard.writeText(application.consumerApplicationLink);
      setIsCopied(true);

      setTimeout(() => setIsCopied(false), DEFAULT_INTERVAL);
    } catch (error) {
      setIsCopied(false);
      console.error(error);
    }
  };

  const label = isCopied ? "Copied!" : "Copy link";

  return (
    <CopyLinkActionComponent
      isCopied={isCopied}
      label={label}
      onClick={onCopyToClipboard}
    />
  );
}

function SignatureTableRow(props) {
  const {
    application,
    authorisation,
    currentUser,
    errors,
    icon,
    identificationCheck,
    isApplicant,
    isEmailDisabled,
    isOpen,
    isSending,
    onResend,
    onToggleIsOpen,
    register,
    signature,
  } = props;

  const actionComponents = ACTION_COMPONENTS.desktop;

  return (
    <div className={styles.signature_table_row}>
      <div className={styles.grid_table}>
        <div
          className={`${styles.first_column} ${styles.grid_table_column}`}
          onClick={onToggleIsOpen}
        >
          {icon}
          <div className={styles.authorisation_name}>
            {authorisation.fullName}
          </div>
        </div>
        <div className={styles.email_field}>
          <BorderedTextField
            label=""
            placeholder=""
            defaultValue={authorisation.email}
            disabled={isEmailDisabled}
            name="email"
            inputRef={register}
            customProps={{
              isCompact: true,
              withBottomMargin: false,
            }}
            error={Boolean(errors.email)}
            helperText={get(errors, "email.message", " ")}
          />
        </div>
        <div className={styles.grid_table_column}>
          <ActingAsCheckbox
            authorisation={authorisation}
            actingAs="applicant"
          />
        </div>
        <div className={styles.grid_table_column}>
          <ActingAsCheckbox
            authorisation={authorisation}
            actingAs="signatory"
          />
        </div>
        <div className={styles.grid_table_column}>
          <ActingAsCheckbox
            authorisation={authorisation}
            actingAs="guarantor"
          />
        </div>
        <div className={styles.grid_table_column}>
          <ActingAsCheckbox
            authorisation={authorisation}
            actingAs="cardholder"
          />
        </div>
        <div className={styles.grid_table_column}>
          <ActingAsCheckbox authorisation={authorisation} actingAs="payment" />
        </div>
        <div className={styles.grid_table_column}>
          <StatusIndicator
            withStatusName={true}
            status={authorisation.status}
          />
        </div>
        <div className={`${styles.actions_column} ${styles.grid_table_column}`}>
          <ResendAction
            ResendActionComponent={actionComponents.ResendAction}
            application={application}
            currentUser={currentUser}
            isApplicant={isApplicant}
            isSending={isSending}
            onClick={onResend}
          />
          <CopyLink
            CopyLinkActionComponent={actionComponents.CopyLinkAction}
            application={application}
            currentUser={currentUser}
            isApplicant={isApplicant}
          />
          <SuperAdminCopyLink
            application={application}
            authorisation={authorisation}
            currentUser={currentUser}
            isApplicant={isApplicant}
          />
          <PassFailAction
            PassActionComponent={actionComponents.PassAction}
            identificationCheck={identificationCheck}
            {...props}
          />
        </div>
      </div>

      <Collapse in={isOpen}>
        <DigitalSignature
          signature={signature}
          identificationCheck={identificationCheck}
          {...props}
        />
      </Collapse>
    </div>
  );
}

function MobileActions(props) {
  const {
    application,
    authorisation,
    currentUser,
    identificationCheck,
    isApplicant,
    isEmailDisabled,
    isSending,
    signature,
    onResend,
  } = props;

  if (
    isPassFailActionHidden({
      application,
      authorisation,
      identificationCheck,
      signature,
    }) &&
    isEmailDisabled
  ) {
    return null;
  }

  const actionComponents = ACTION_COMPONENTS.mobile;

  return (
    <Fragment>
      <div className={`${commonStyles.buttons} ${styles.mobile_actions}`}>
        <ResendAction
          ResendActionComponent={actionComponents.ResendAction}
          application={application}
          currentUser={currentUser}
          isApplicant={isApplicant}
          isSending={isSending}
          onClick={onResend}
        />
        <CopyLink
          CopyLinkActionComponent={actionComponents.CopyLinkAction}
          application={application}
          currentUser={currentUser}
          isApplicant={isApplicant}
        />
      </div>
      <div className={`${commonStyles.buttons} ${styles.mobile_actions}`}>
        <PassFailAction
          PassActionComponent={actionComponents.PassAction}
          identificationCheck={identificationCheck}
          {...props}
        />
      </div>
    </Fragment>
  );
}

function AgreedValue(props) {
  const { authorisation, isAgreed } = props;

  if (authorisation.status === "not_started") {
    return <span className={styles.grey_label}>Not started</span>;
  }

  if (authorisation.status === "in_progress") {
    return <span className={styles.grey_label}>In progress</span>;
  }

  if (isAgreed) {
    return <span className={styles.agreed_label}>Agreed</span>;
  }

  return <span className={styles.declined_label}>Declined</span>;
}

function MobileActingAsContent(props) {
  const { authorisation } = props;
  const actingAsList = Object.keys(authorisation.agreements);

  return actingAsList.map((actingAs, i) => {
    const agreement = get(authorisation, `agreements.${actingAs}`);

    return (
      <div
        className={styles.mobile_acting_as}
        key={`mobile-acting-as-${i + 1}`}
      >
        {ACTING_AS_LABEL[actingAs]}:{" "}
        <AgreedValue authorisation={authorisation} isAgreed={agreement} />
      </div>
    );
  });
}

function MobileIdentificationOutcome(props) {
  const { identificationCheck } = props;

  if (isBlank(identificationCheck) || identificationCheck.isNewRecord) {
    return null;
  }

  return (
    <FixedContent>
      <IdentificationOutcome isErrorsVisible={false} {...props} />
    </FixedContent>
  );
}

function MobileSignatureRow(props) {
  const {
    application,
    authorisation,
    errors,
    isEmailDisabled,
    register,
  } = props;

  return (
    <FixedContent header={authorisation.fullName}>
      <BorderedTextField
        label=""
        placeholder=""
        customProps={{
          withBottomMargin: false,
        }}
        defaultValue={authorisation.email}
        disabled={isEmailDisabled}
        name="email"
        inputRef={register}
        error={Boolean(errors.email)}
        helperText={get(errors, "email.message", " ")}
      />
      <FixedContent>
        <div className={styles.mobile_status_indicator}>
          <StatusIndicator
            withStatusName={true}
            status={authorisation.status}
          />
        </div>
        <MobileActingAsContent authorisation={authorisation} />
      </FixedContent>
      <MobileIdentificationOutcome {...props} />
      <FixedContent>
        <IdentificationImage
          isViewOnly={application.isViewOnly}
          isVisibleWhenEmpty={false}
          {...props}
        />
        <ProofOfAddress isViewOnly={application.isViewOnly} {...props} />
        <MobileActions {...props} />
      </FixedContent>
    </FixedContent>
  );
}

function SignatureRow(props) {
  const {
    RowComponent,
    application,
    authorisation,
    currentUser,
    onFetchApplicationRecord,
    onSetAlert,
    index,
  } = props;
  const { isApplicant, antiFraudCheck, signature } = authorisation;
  const { icon, isOpen, onToggleIsOpen } = useCollapsibleContentState(
    index === 1,
  );
  const {
    isLoading: isSending,
    setIsLoading: setIsSending,
  } = useIsLoadingState();
  const { errors, getValues, register } = useForm({
    mode: "onBlur",
    validationSchema: emailSchema,
  });

  const identificationCheck = authorisation.getIdentificationCheck(application);
  const onResendCallback = () => {
    onFetchApplicationRecord();
    setIsSending(false);
  };

  const onFailCallback = () => {
    onSetAlert({
      message:
        "The user already exists, please send another application instead of modifying the email.",
      type: "error",
    });
  };

  const onResend = () => {
    const errorMessage = get(errors, "email.message");
    if (isPresent(errorMessage)) {
      return;
    }

    setIsSending(true);

    authorisation.updateEmailAndResend({
      currentUser,
      email: getValues("email"),
      onFailCallback,
      onSuccessCallback: onResendCallback,
    });
  };

  let isEmailDisabled = false;
  if (isApplicant) {
    isEmailDisabled =
      application.isSubmitted ||
      application.consumerHasOtherSubmittedApplications;
  } else {
    isEmailDisabled =
      application.isViewOnly ||
      identificationCheck.isIdentificationCheckSuccess ||
      signature.isManuallyApproved;
  }

  return (
    <RowComponent
      authorisation={authorisation}
      errors={errors}
      icon={icon}
      identificationCheck={identificationCheck}
      isApplicant={isApplicant}
      isEmailDisabled={isEmailDisabled}
      isOpen={isOpen}
      isSending={isSending}
      onResend={onResend}
      onToggleIsOpen={onToggleIsOpen}
      register={register}
      signature={signature}
      antiFraudCheck={antiFraudCheck}
      {...props}
    />
  );
}

export function useImageModalState() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalSrc, setModalSrc] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);

  const onShowImageModal = (src, title = null) => {
    setIsModalVisible(true);
    setModalSrc(src);
    setModalTitle(title);
  };

  const onHideImageModal = () => {
    setIsModalVisible(false);
    setModalSrc(null);
    setModalTitle(null);
  };

  let modal = null;
  if (isModalVisible) {
    modal = (
      <ImageModal
        dismissHandler={onHideImageModal}
        src={modalSrc}
        title={modalTitle}
      />
    );
  }

  return { modal, onShowImageModal };
}

function SignatureTable(props) {
  const { rows } = props;

  return (
    <div className={styles.signatures_table}>
      <FixedContent header="eSignatures">
        <div className={`${styles.grid_table} ${styles.header}`}>
          <div></div>
          <div></div>
          <div>Applicant</div>
          <div>Signatory</div>
          <div>Guarantor</div>
          <div>Cardholder</div>
          <div>Direct Debit</div>
          <div></div>
        </div>
        {rows}
      </FixedContent>
    </div>
  );
}

function MobileSignatureView(props) {
  return <div className={styles.mobile_signature}>{props.rows}</div>;
}

export default function IdentificationChecks(props) {
  const { application } = props;
  const { authorisationHistories, visibleAuthorisations } = application;
  const { modal, onShowImageModal } = useImageModalState();
  const { isMobileScreen } = useContext(MobileScreenContext);

  const desktopRows = [];
  const mobileRows = [];

  visibleAuthorisations.forEach((authorisation, i) => {
    const index = i + 1;
    const authId = authorisation.id;

    desktopRows.push(
      <SignatureRow
        key={`desktop-signature-row-${authId}`}
        index={index}
        RowComponent={SignatureTableRow}
        authorisation={authorisation}
        onShowImageModal={onShowImageModal}
        {...props}
      />,
    );

    mobileRows.push(
      <SignatureRow
        key={`desktop-signature-row-${authId}`}
        index={index}
        RowComponent={MobileSignatureRow}
        authorisation={authorisation}
        onShowImageModal={onShowImageModal}
        {...props}
      />,
    );
  });

  let content = null;
  if (isMobileScreen) {
    content = <MobileSignatureView rows={mobileRows} />;
  } else {
    content = <SignatureTable rows={desktopRows} />;
  }

  if (authorisationHistories.length > 0) {
    return (
      <ContentWithFooter
        footer={<RecordHistory histories={authorisationHistories} />}
      >
        {content}
        {modal}
      </ContentWithFooter>
    );
  }

  return (
    <ScrollableContent>
      {content}
      {modal}
    </ScrollableContent>
  );
}
