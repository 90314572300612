import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { get, snakeCase } from "lodash";
import AddressFinder from "modules/shared/components/v2/Forms/AddressFinder";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import React, { ReactElement, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form-latest";
import { REGION_FULL_NAMES } from "utils/region";
import Input from "./Input";
import { FontAwesomeIcon, Status } from "./styles";
import * as yup from "yup";
import { isFeatureEditEnabled } from "modules/shared/helpers/headquarterDetect";

import styles from "../css/LicenceInfo.css";
import { updateEntityBillingDetails } from "modules/profile/actions";
import { getAddressFinderSchema } from "modules/shared/components/v2/Forms/schema";
import { Address } from "modules/shared/components/v2/Forms/type";
import useYupValidationResolver from "modules/shared/hooks/useYupValidationResolver";

type CurrentEntity = {
  billingAddressDetails: Address;
  billingCountry: string;
  billingEmail: string;
  billingEntityName: string;
  region: string;
};

interface Props {
  currentUser: {
    currentEntity: CurrentEntity;
  };
  dispatch: (dispatchFunction: () => void) => void;
  loading: boolean;
}

const STATUS_CLEAR_DURATION = 3000;
const ERROR_STATUS_CLEAR_DURATION = 5000;

function getFormSchema({ billingAddress }) {
  return yup.object().shape({
    billingEmail: yup
      .string()
      .email("Please insert a valid email address.")
      .required("Contact email is required."),
    billingEntityName: yup.string().required("Entity name is required."),
    billingAddress: getAddressFinderSchema({ value: billingAddress }),
  });
}

const STATUS = {
  success: {
    icon: "check-circle",
    type: "success",
  },
  error: {
    icon: "times-circle",
    type: "danger",
  },
};

function BillingDetails({
  currentUser,
  dispatch,
  loading,
}: Props): ReactElement | null {
  if (!isFeatureEditEnabled("Invoice")) {
    return null;
  }

  const currentEntity = get(currentUser, "currentEntity");
  const {
    billingAddressDetails,
    billingCountry,
    billingEmail,
    billingEntityName,
  } = currentEntity;
  const [rawAddress, setRawAddress] = useState(billingAddressDetails);
  const [status, setStatus] = useState<{
    message: string;
    type: string;
  } | null>(null);

  const {
    control,
    resetField,
    formState: { dirtyFields, errors },
    register,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      billingCountry,
      billingEmail,
      billingEntityName,
      billingAddress: billingAddressDetails,
    },
    mode: "onBlur",
    resolver: useYupValidationResolver(() =>
      getFormSchema({ billingAddress: rawAddress }),
    ),
  });

  const { onChange: addressOnChange, onBlur: addressOnblur } = register(
    // @ts-ignore-next-line
    "billingAddress",
  );

  async function submit(fieldName, value) {
    const isValid = await trigger(fieldName);

    if (!isValid || !dirtyFields[fieldName]) {
      return;
    }

    const processedFieldName = snakeCase(fieldName);

    dispatch(
      updateEntityBillingDetails(
        { [processedFieldName]: value },
        () => {
          displaySuccess();
          // @ts-ignore-next-line
          resetField(fieldName, { defaultValue: value });
        },
        () => displayError(),
      ),
    );
  }

  function clearStatus(duration = STATUS_CLEAR_DURATION) {
    clearTimeout();

    setTimeout(() => {
      setStatus(null);
    }, duration);
  }

  function displaySuccess() {
    setStatus({ type: "success", message: "Saved" });
    clearStatus();
  }

  function displayError() {
    setStatus({ type: "error", message: "Error" });
    clearStatus(ERROR_STATUS_CLEAR_DURATION);
  }

  function handleEntityNameBlur(e) {
    const { name, value } = e.target;
    submit(name, value);
  }

  function handleEmailBlur(e) {
    const { name, value } = e.target;
    submit(name, value);
  }

  function handleAddressChange(_, value) {
    addressOnChange({ target: { value } });
    // @ts-ignore-next-line
    setValue("billingAddress", value, { shouldDirty: true });
    setRawAddress(value);
  }

  function handleAddressBlur(e) {
    addressOnblur(e);
  }

  useEffect(() => {
    if (!!rawAddress) {
      submit("billingAddress", rawAddress);
    }
  }, [rawAddress]);

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div>
        <article className={styles.block}>
          <div className={styles.liner}>
            <h2 className={styles.header}>
              Billings details{" "}
              {loading && <FontAwesomeIcon icon="circle-notch" spin />}
              {status && (
                <Status className={`has-text-${STATUS[status.type].type}`}>
                  <i className={`fas fa-${STATUS[status.type].icon}`} />{" "}
                  {status.message}
                </Status>
              )}
            </h2>
            <section className={styles.main_text}>
              <div className="columns">
                <form className="column is-8">
                  <div className="columns is-multiline">
                    <div className="column is-6">
                      <Input
                        controllerProps={{
                          control,
                          name: "billingEntityName",
                        }}
                        inputProps={{
                          label: "Legal entity name",
                          InputLabelProps: {
                            shrink: true,
                          },
                          disabled: !isFeatureEditEnabled("Invoice"),
                          error: get(errors, "billingEntityName"),
                          helperText: get(errors, "billingEntityName.message"),
                          onBlur: handleEntityNameBlur,
                        }}
                      />
                    </div>
                    <div className="column is-6">
                      <Input
                        controllerProps={{
                          control,
                          name: "billingEmail",
                        }}
                        inputProps={{
                          label: "Contact email",
                          InputLabelProps: {
                            shrink: true,
                          },
                          disabled: !isFeatureEditEnabled("Invoice"),
                          error: get(errors, "billingEmail"),
                          helperText: get(errors, "billingEmail.message"),
                          onBlur: handleEmailBlur,
                        }}
                      />
                    </div>
                    <div className="column is-6">
                      <Input
                        controllerProps={{
                          control,
                          name: "billingCountry",
                        }}
                        inputProps={{
                          label: "Country",
                          InputLabelProps: {
                            shrink: true,
                          },
                          disabled: true,
                          value:
                            billingCountry && REGION_FULL_NAMES[billingCountry],
                          error: get(errors, "billingCountry"),
                          helperText: get(errors, "billingCountry.message"),
                        }}
                      />
                    </div>
                    <div className="column is-12">
                      <AddressFinder
                        currentUser={currentUser}
                        disabled={!isFeatureEditEnabled("Invoice")}
                        fieldName="billingAddress"
                        rawAddress={rawAddress}
                        region={billingCountry}
                        onChange={handleAddressChange}
                        onBlur={handleAddressBlur}
                        errors={errors}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </article>
      </div>
    </MuiThemeProvider>
  );
}

export default connect(state => {
  const loading = get(
    state,
    "manage_profile.settings_billing_details_updating",
  );

  return {
    loading,
  };
})(BillingDetails);
