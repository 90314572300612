import get from "lodash.get";
import { COLLATERAL_TYPE_OPTIONS } from "models/PpsrFinancingStatementModel";
import CollateralIItems from "modules/new-applications/components/application-actions/PPSRRegister/PPSRDetails/NZPPSRDetails/CollateralIItems";
import {
  DateOfBirth,
  ExpiryDate,
  FinancingStatementPin,
  Navigation,
  OrganisationName,
  PDFFile,
} from "modules/new-applications/components/application-actions/PPSRRegister/shared/RegisterDetails";
import styles from "modules/new-applications/css/PPSRRegister.css";
import FixedContent from "modules/shared/components/containers/FixedContent";
import GridContent from "modules/shared/components/containers/GridContent";
import LabeledContent from "modules/shared/components/widgets/static/LabeledContent";
import React, { Fragment } from "react";

function Debtor(props) {
  const { debtor, index } = props;

  return (
    <Fragment>
      <div className={styles.sub_header}>Debtor {index}</div>
      <GridContent>
        <LabeledContent label="Type" content={debtor.formattedDebtorType} />
        <LabeledContent
          label="Debtor reference"
          content={debtor.debtorReference}
        />
      </GridContent>
      <GridContent>
        <OrganisationName debtor={debtor} />
        <LabeledContent
          label="Person acting on behalf of Debtor (applicant) name"
          content={debtor.personFullName}
        />
        <LabeledContent
          label="Business address"
          content={get(debtor, "debtorAddress.fullAddress", "")}
        />
        <LabeledContent
          label="Person acting on behalf of Debtor (applicant) email"
          content={debtor.debtorEmail}
        />
        <LabeledContent label="Phone" content={debtor.debtorPhone} />
        <DateOfBirth debtor={debtor} />
      </GridContent>
    </Fragment>
  );
}

function Collateral(props) {
  const { collateral, index } = props;

  const label = (
    COLLATERAL_TYPE_OPTIONS.find(
      option => option.value === collateral.collateralType,
    ) || {}
  ).label;

  return (
    <Fragment>
      <div className={styles.sub_header}>Collateral {index}</div>
      <GridContent>
        <LabeledContent label="Type" content={label} />
        {collateral.hasDescription && (
          <LabeledContent
            label="Description"
            content={collateral.collateralDescription}
          />
        )}
        <CollateralIItems collateral={collateral} />
      </GridContent>
    </Fragment>
  );
}

export default function NZPPSRDetails(props) {
  const {
    application,
    currentUser,
    ppsrFinancingStatement,
    setCurrentPage,
  } = props;

  const debtors = (
    ppsrFinancingStatement.ppsrFinancingStatementDebtors || []
  ).map((debtor, index) => (
    <Debtor key={`debtor-${debtor.id}`} index={index + 1} debtor={debtor} />
  ));

  const collaterals = (
    ppsrFinancingStatement.ppsrFinancingStatementCollaterals || []
  ).map((collateral, index) => (
    <Collateral
      key={`collateral-${collateral.id}`}
      index={index + 1}
      collateral={collateral}
    />
  ));

  return (
    <Fragment>
      <Navigation
        application={application}
        ppsrFinancingStatement={ppsrFinancingStatement}
        setCurrentPage={setCurrentPage}
      />
      <FixedContent>
        <GridContent>
          <FinancingStatementPin
            ppsrFinancingStatement={ppsrFinancingStatement}
          />
        </GridContent>
        <GridContent>
          <LabeledContent
            label="Financing statement registration number"
            content={ppsrFinancingStatement.financingStatementKey}
          />
          <ExpiryDate ppsrFinancingStatement={ppsrFinancingStatement} />
        </GridContent>
      </FixedContent>
      <FixedContent>
        <div className={styles.sub_header}>Debtor details</div>
        {debtors}
      </FixedContent>
      <FixedContent>
        <div className={styles.sub_header}>Collateral details</div>
        {collaterals}
      </FixedContent>
      <PDFFile currentUser={currentUser} pdf={ppsrFinancingStatement.pdf} />
    </Fragment>
  );
}
