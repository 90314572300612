/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

/* Import actions */
import { acceptInvite, declineInvite } from "../actions";

/* Import components */
import CircleModal from "modules/shared/components/widgets/static/CircleModal";
import Button from "modules/shared/components/inputs/Button";

/* Import CSS */
// import styles from './css/UpdateUser.css';

var BranchInvite = createClass({
  getInitialState: function() {
    return {
      decision: "",
    };
  },

  accept() {
    const { dispatch, headquarter_id } = this.props;
    dispatch(acceptInvite(headquarter_id));
    this.setState({ decision: "accepted" });
  },

  decline() {
    const { dispatch, headquarter_id } = this.props;
    dispatch(declineInvite(headquarter_id));
    this.setState({ decision: "declined" });
  },

  redirect() {
    browserHistory.push({
      pathname: `/dashboard/home`,
    });
  },

  render() {
    const { decision } = this.state;
    var content;
    if (decision == "accepted") {
      content = (
        <div>
          <p>You have accepted the invite.</p>
          <Button text={"Ok"} handleClick={this.redirect} />
        </div>
      );
    } else if (decision == "declined") {
      content = (
        <div>
          <p>You have declined the invite.</p>
          <Button text={"Ok"} handleClick={this.redirect} />
        </div>
      );
    } else {
      content = (
        <div>
          <p>Do you wish accept the invite?</p>
          <Button text={"Yes"} handleClick={this.accept} />
          <Button text={"No"} handleClick={this.decline} />
        </div>
      );
    }

    return (
      <CircleModal title={"Branch invite"} dismissHandler={this.cancel}>
        {content}
      </CircleModal>
    );
  },
});

module.exports = connect((state, ownProps) => {
  const headquarter_id = ownProps.params.headquarter_id;
  return {
    headquarter_id: headquarter_id,
    title: "test",
  };
})(BranchInvite);
