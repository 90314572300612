import React from "react";

import C3Chart from "react-c3js";
import "c3/c3.css";

var SupplierByIndustry = createClass({
  render() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      supplierByIndustry = enquiryResult.suppliers_by_industry || {},
      industries = supplierByIndustry.vendors_by_industry_ws_dto || [];

    if (industries.constructor === Object) industries = [industries];
    if (industries.length === 0) return null;

    let columns = industries.map(industry => {
      return [industry.industry, industry.percentage_of_total];
    });

    let chartData = {
      columns: columns,
      type: "pie",
    };

    let pie = {
      expand: true,
      label: {
        show: false,
      },
    };

    let legend = {
      position: "right",
    };

    return (
      <div>
        <C3Chart data={chartData} pie={pie} legend={legend} />
      </div>
    );
  },
});

export default SupplierByIndustry;
