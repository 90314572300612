import Collapse from "@material-ui/core/Collapse";
import React from "react";
import { animateScroll as scroll } from "react-scroll";

import styles from "./css/AccordianContainer.css";

class AccordianContainer extends React.Component {
  componentDidUpdate(prevProps) {
    const { subsection_index, scrollTo } = this.props;

    if (
      scrollTo &&
      subsection_index &&
      subsection_index !== prevProps.subsection_index
    ) {
      // The css styles are injected after componentDidUpdate called
      // so need time to inject style
      setTimeout(() => {
        const element = document.getElementById(
          `accordian-switch-${subsection_index}`,
        );
        scroll.scrollTo(element.offsetTop);
      }, 200);
    }
  }

  render() {
    const { type, subPages, subsection_index, handleClick } = this.props;

    const accordions = [];

    subPages.forEach((page, index) => {
      let style = styles.accordion;
      const label =
          subPages[index].props["data-accordianLabel"] ||
          subPages[index].props.label;
      let opened = false;
      let shadow = styles.shadow;
      let container = styles.container;
      let button_text = "edit";
      let button;
      let style_row = styles.row;
      if (type) {
        style = styles[`${type}_accordion`];

        if (type === "review_edit") {
          style_row = styles.review_edit_row;
        }
      }

      if (index === subsection_index) {
        if (type) {
          style = styles[`${type}_expanded`];
          shadow = styles.shadow_visible;
          container = styles.container_visible;
        } else {
          style = styles.expanded;
        }
        opened = true;
        button_text = "close";
      }

      accordions.push(
        <div key={`accordian-${index}`} className={style_row}>
          <h3
            className={style}
            onClick={handleClick.bind(null, index)}
            key={index}
            id={`accordian-switch-${index}`}
          >
            {label}
            <span className={styles.required}>
              {subPages[index].props.decision_required ?
                " (decision required)" :
                ""}
            </span>
          </h3>
          {button}
          <div className={container}>
            <span className={shadow} />
            <Collapse in={opened}>
              <div>{subPages[index]}</div>
            </Collapse>
          </div>
        </div>,
      );
    });

    return <div>{accordions}</div>;
  }
}

export default AccordianContainer;
