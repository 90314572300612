/* Import libraries */
import React from "react";
import { connect } from "react-redux";

/* Import actions*/

/* Import CSS */
import styles from "./css/AutoDecisioning.css";

/* Import components */
import { withStyles } from "@material-ui/core/styles";
import AutoDecisioningForm from "./AutoDecisioningForm";

var ViewAutoDecisioning = createClass({
  render: function() {
    const { handleDiscard, current_entity } = this.props;
    return (
      <AutoDecisioningForm
        handleDiscard={handleDiscard}
        current_entity={current_entity}
        read_only={true}
        show_version={true}
      />
    );
  },
});

var defaults = {
  ruleset: {
    name: "New Auto Decisioning Ruleset",
  },
};

module.exports = connect((state, ownProps) => {
  return {
    // rule_sets: state.addons.auto_decisioning.rule_sets || null,
    updating: state.auto_decisions.updating,
    rule_set: state.auto_decisions.current_rule_set || defaults.ruleset,
    handleDiscard: ownProps.handleDiscard,
    current_entity: state.current_user.current_entity.attributes,
  };
})(withStyles(styles)(ViewAutoDecisioning));
