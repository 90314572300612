import React from "react";
import AgedBalances from "../AgedBalances";
import Button from "modules/shared/components/inputs/Button";
import CreditDetails from "../CreditDetails";
import CreditRiskIndicator from "../CreditRiskIndicator";
import DebtorExposureAgingSummary from "../DebtorExposureAgingSummary";
import DirectorAffiliations from "../DirectorAffiliations";
import ExecutiveSummary from "../ExecutiveSummary";
import History from "../History";
import Notifications from "../Notifications";
import PPSRFinanceStatementsCollateralSummary from "../PPSRFinanceStatementsCollateralSummary";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import logo from "images/credit-works-logo.png";
import styles from "../../../css/CommonReviewBusiness.css";

var CreditWorksComponent = createClass({
  _errorPage(enquiryResult) {
    const { data, consumer } = this.props;

    if (enquiryResult.message === null || enquiryResult.message === undefined)
      return null;

    return (
      <div>
        <ReviewContainer
          subHeading={consumer.attributes.trading_name}
          content_class="content_wide"
          css_class="block_noborder"
        >
          <p>{enquiryResult.message}</p>
        </ReviewContainer>
      </div>
    );
  },

  getInitialState() {
    return {
      isAllExpanded: false,
    };
  },

  _expandAll() {
    this.setState({ isAllExpanded: !this.state.isAllExpanded });
  },

  _expandAllButton() {
    let message = "+ expand all";
    if (this.state.isAllExpanded) message = "- collapse all";

    return (
      <Button
        small
        text={message}
        style={{ fontSize: "10px", margin: "1em 0 1em 66px" }}
        handleClick={this._expandAll}
      />
    );
  },

  render() {
    const { data, consumer, sequence_date } = this.props;

    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result;

    if (!enquiryResult.success) return this._errorPage(enquiryResult);
    if (!enquiryResult.company_details || !enquiryResult.credit_details)
      return this._errorPage(enquiryResult);

    return (
      <div>
        <img className={styles.veda_logo} src={logo} alt="CreditWorks" />
        <h2 className={styles.heading_large}>CREDIT REPORT</h2>
        <ReviewContainer
          subHeading={enquiryResult.company_details.company.company_name}
          content_class="content_wide"
          css_class="block_noborder"
        />
        <ExecutiveSummary data={data} consumer={consumer} />
        {this._expandAllButton()}
        <CreditRiskIndicator
          data={data}
          isAllExpanded={this.state.isAllExpanded}
        />
        <DebtorExposureAgingSummary
          data={data}
          isAllExpanded={this.state.isAllExpanded}
        />
        <CreditDetails
          data={data}
          isAllExpanded={this.state.isAllExpanded}
          sequence_date={sequence_date}
        />
        <PPSRFinanceStatementsCollateralSummary
          data={data}
          isAllExpanded={this.state.isAllExpanded}
        />
        <Notifications data={data} isAllExpanded={this.state.isAllExpanded} />
        <History data={data} isAllExpanded={this.state.isAllExpanded} />
        <DirectorAffiliations
          data={data}
          isAllExpanded={this.state.isAllExpanded}
        />
        <AgedBalances data={data} isAllExpanded={this.state.isAllExpanded} />
        {this._expandAllButton()}
      </div>
    );
  },
});

export default CreditWorksComponent;
