import styles from "modules/new-applications/css/IdentificationChecks.css";
import React, { ReactElement } from "react";

const ManualVerification = ({
  signature,
}): ReactElement|null => {
  const pass = signature.manuallyApproved;

  if (!pass) {
    return null;
  }

  return (
    <div className={styles.e_signature_header}>
      <span className="underlined">ID check outcome:</span>
      <span style={{ color: "green" }}>&nbsp;Pass</span>
      <span>&nbsp;(Manual)</span>
    </div>
  );
};

export default ManualVerification;
