import axios from "./axios";;

let RecoveryCandidate = function(config) {
  this.axios = axios(config);
  this.type = "recovery_candidate";
};

RecoveryCandidate.prototype.updateRecoveryCandidateSelf = function(
  id,
  attributes,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      token: attributes.token,
      self_approval: attributes.self_approval,
    })
    .then(success)
    .catch(error);
};

RecoveryCandidate.prototype.updateRecoveryCandidateAdmin = function(
  id,
  attributes,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      admin_approval: attributes.admin_approval,
    })
    .then(success)
    .catch(error);
};

RecoveryCandidate.prototype.updateRecoveryCandidate = function(
  id,
  attributes,
  success,
  error,
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      token: attributes.token,
      access_retrieval: attributes.access_retrieval,
    })
    .then(success)
    .catch(error);
};

RecoveryCandidate.prototype.getRecoveryCandidate = function(
  id,
  success,
  error,
  options,
) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

RecoveryCandidate.prototype.destroyCandidate = function(
  id,
  success,
  error,
  options,
) {
  return this.axios
    .delete(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

export default RecoveryCandidate;
