import {
  NOTIFICATIONS_LOAD_NOTIFICATIONS_START,
  NOTIFICATIONS_LOAD_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_LOAD_NOTIFICATIONS_ERROR,
  NOTIFICATIONS_MARK_ACTIONED_START,
  NOTIFICATIONS_MARK_ACTIONED_SUCCESS,
  NOTIFICATIONS_MARK_ACTIONED_ERROR,
  NOTIFICATIONS_PUBLISH_MESSAGE_START,
  NOTIFICATIONS_PUBLISH_MESSAGE_SUCCESS,
  NOTIFICATIONS_PUBLISH_MESSAGE_ERROR,
} from "./constants";

var notifications_defaults = {
  loading: false,
  notifications: [],
  actioning: false,
  messaging: false,
};

export function notificationsReducer(state = notifications_defaults, action) {
  switch (action.type) {
    case NOTIFICATIONS_PUBLISH_MESSAGE_START:
      return { ...state, messaging: true };
    case NOTIFICATIONS_PUBLISH_MESSAGE_SUCCESS:
      return { ...state, messaging: false };
    case NOTIFICATIONS_PUBLISH_MESSAGE_ERROR:
      return { ...state, messaging: false };

    case NOTIFICATIONS_LOAD_NOTIFICATIONS_START:
      return { ...state, loading: true };
    case NOTIFICATIONS_LOAD_NOTIFICATIONS_SUCCESS:
      return { ...state, loading: false, notifications: action.payload };
    case NOTIFICATIONS_LOAD_NOTIFICATIONS_ERROR:
      return { ...state, loading: false };

    case NOTIFICATIONS_MARK_ACTIONED_START:
      return { ...state, actioning: true };
    case NOTIFICATIONS_MARK_ACTIONED_SUCCESS:
      return { ...state, actioning: true };
    case NOTIFICATIONS_MARK_ACTIONED_ERROR:
      return { ...state, actioning: true };

    default:
      return { ...state };
  }
}
