import isBlank from "./isBlank";

export default function objectToArray(value) {
  if (isBlank(value)) {
    return [];
  }

  if (value.constructor === Object) {
    return [value];
  }

  return value;
}
