/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

import styles from "./css/StopCredit.css";

/* Import actions */
import {
  createStopCredit,
  checkForStopCredit,
  removeStopCredit,
} from "../actions";

/* Import components */
import CircleModal from "../../shared/components/widgets/static/CircleModal";
import Button from "../../shared/components/inputs/Button";
import Icon from "modules/shared/components/svg/Icon";
import SimpleLoader from "../../shared/components/widgets/static/SimpleLoader";
import OptionsDropdown from "../../shared/components/widgets/interactive/OptionsDropdown";

import Clickable from "modules/shared/components/widgets/interactive/Clickable";

var StopCredit = createClass({
  // On load, get the details of this stop credit
  componentWillMount: function() {
    const { dispatch, connection } = this.props;
    dispatch(checkForStopCredit(connection.id));
  },

  getInitialState: function() {
    return {
      selectedHoldType: {
        value: "days_60",
        label: "60 Days",
      },
      showModal: false,
    };
  },

  createStopCredit: function() {
    const { dispatch, connection } = this.props;
    const { selectedHoldType } = this.state;
    var that = this;

    dispatch(
      createStopCredit(
        connection.id,
        {
          delinquency_period: selectedHoldType.value,
        },
        () => {
          that.cancelModal();
        },
      ),
    );
  },

  removeStopCredit: function() {
    const { dispatch, stop_credit } = this.props;
    var that = this;

    dispatch(
      removeStopCredit(stop_credit.id, () => {
        that.cancelModal();
      }),
    );
  },

  startModal: function() {
    this.setState({
      showModal: true,
    });
  },

  cancelModal: function() {
    this.setState({
      showModal: false,
    });
  },

  handleChange: function(v) {
    this.setState({ selectedHoldType: v });
  },

  formatPeriod: function(stop_credit) {
    const { stopCreditOptions } = this.props;
    let period = stop_credit.attributes.delinquency_period;
    if (period === "null") {
      return "";
    }
    let option = stopCreditOptions.find(o => {
      if (o.value === period) {
        return true;
      }
    });
    if (!option) {
      return null;
    }
    return ` - ${option.hasOwnProperty("label") ? option.label : null}`;
  },

  render: function() {
    const {
      titles,
      loading,
      connection,
      stopCreditOptions,
      checking,
      stop_credit,
    } = this.props;

    const { showModal, selectedHoldType } = this.state;

    if (checking) {
      return null;
    }

    if (showModal) {
      if (stop_credit) {
        return (
          <CircleModal title={titles.remove} dismissHandler={this.cancelModal}>
            <div className={styles.button}>
              <Button
                text="Cancel stop credit"
                loading={loading}
                handleClick={this.removeStopCredit}
              />
            </div>
          </CircleModal>
        );
      } else {
        return (
          <CircleModal title={titles.add} dismissHandler={this.cancelModal}>
            <div className={styles.fieldset}>
              <OptionsDropdown
                handleChange={this.handleChange}
                label="Delinquency period"
                value={selectedHoldType}
                required={true}
                options={stopCreditOptions}
              />
            </div>
            <div className={styles.button}>
              <Button
                text="Apply stop credit"
                loading={loading}
                handleClick={this.createStopCredit}
              />
            </div>
          </CircleModal>
        );
      }
    }

    if (stop_credit) {
      return (
        <span className={styles.component_remove}>
          <span className={styles.copy}>
            Stop Credit Active {this.formatPeriod(stop_credit)}
          </span>

          <Clickable target={this.startModal}>
            <span className={styles.stop_credit_remove}>
              <span className={styles.icon}>
                <Icon icon="stop" />
              </span>{" "}
              cancel
            </span>
          </Clickable>
        </span>
      );
    }

    return (
      <span className={styles.component}>
        <Clickable target={this.startModal}>
          <span className={styles.stop_credit}>
            <span className={styles.icon}>
              <Icon icon="stop" />
            </span>{" "}
            apply stop credit
          </span>
        </Clickable>
      </span>
    );
  },
});

var defaults = {
  titles: {
    add: "Apply a stop credit to this customer?",
    remove: "Cancel stop credit for this customer?",
  },
};

module.exports = connect((state, ownProps) => {
  return {
    titles: defaults.titles,
    loading: state.connections.stop_credit_apply_loading,
    stop_credit: state.connections.stop_credit,
    checking: state.connections.stop_credit_check_loading,
    stopCreditOptions: [
      {
        value: "days_60",
        label: "60 Days",
      },
      {
        value: "days_90",
        label: "90 Days",
      },
      {
        value: "days_120",
        label: "120 Days",
      },
      {
        value: "days_120_plus",
        label: "120 Days +",
      },
      {
        value: "null",
        label: "No period is applicable",
      },
    ],
  };
})(StopCredit);
