import PDFWidget from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/PDFWidget";
import styles from "modules/new-applications/css/IdentificationChecks.css";
import PdfThumbnail from "modules/shared/components/widgets/static/PdfThumbnail";
import React, { ReactElement } from "react";
import isBlank from "utils/isBlank";
import isPDF from "utils/isPDF";

const IdentificationImageWidget = ({
  signature,
  onSetPDFError,
  onShowImageModal,
}: {
  signature: any;
  onSetPDFError: () => void | null;
  onShowImageModal: (src: string, title?: string) => void;
}): {
  idWidget: ReactElement | null;
  isEmpty: boolean;
} => {
  const identificationImageUrl = signature.identificationImageUrl;

  if (isBlank(identificationImageUrl)) {
    return {
      idWidget: <div>No ID provided</div>,
      isEmpty: true,
    };
  }

  let idWidget: ReactElement | null = null;

  const onClickThumbnail = () => onShowImageModal(identificationImageUrl);

  if (isPDF(identificationImageUrl)) {
    idWidget = (
      <div className={styles.signature_pdf} onClick={onClickThumbnail}>
        <PdfThumbnail url={identificationImageUrl} onError={onSetPDFError} />
      </div>
    );
  } else {
    idWidget = (
      <div className={styles.signature_image_container}>
        <img
          src={identificationImageUrl}
          alt="identification-image"
          className={styles.signature_image}
          onClick={onClickThumbnail}
        />
      </div>
    );
  }

  return { idWidget, isEmpty: false };
};

export default IdentificationImageWidget;
