/* Import libraries */
import React from "react";

import styles from "./css/FormBuilder.css";

import FormBuilderComponentRender from "./FormBuilderComponentRender";

function FormBuilderComponentGridWrapper(props) {
  const { borderedStyle, formParams, ...rest } = props;
  const width = formParams.width || 'full';
  const style = styles[`${width}_width`];

  const classNames = [
    styles.form_builder_wrapper,
    style,
    'mb-4',
  ];

  if (borderedStyle) {
    classNames.push(styles.form_builder_wrapper_bordered);
  }

  return (
    <div className={classNames.join(' ')}>
      <FormBuilderComponentRender
        formParams={formParams}
        borderedStyle={borderedStyle}
        {...rest}
      />
    </div>
  );
}

export default FormBuilderComponentGridWrapper;
