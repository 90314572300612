import axios from "./axios";;

let CreditChecks = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = "credit_checks";
};

CreditChecks.prototype.checkPerson = function(
  application_id,
  signature_id,
  success,
  error,
) {
  return this.create(
    { application_id, signature_id, action_type: "person" },
    success,
    error,
  );
};

CreditChecks.prototype.checkCompany = function(application_id, success, error) {
  return this.create(
    { application_id, action_type: "company" },
    success,
    error,
  );
};

CreditChecks.prototype.checkApplicant = function(
  application_id,
  success,
  error,
) {
  return this.create(
    { application_id, action_type: "applicant" },
    success,
    error,
  );
};

CreditChecks.prototype.create = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

export default CreditChecks;
