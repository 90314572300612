import Button from "modules/shared/components/inputs/Button";
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import ExtraDescription from "modules/shared/components/text/ExtraDescription";
import {
  isFeatureEditEnabled,
  isHeadquarter,
} from "modules/shared/helpers/headquarterDetect";
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  setPartnershipAgreementUploadVisible,
  setRequiresTrustDeed,
  setTrustDeedUploadVisible,
  updateRequiredAdditionalDocuments,
} from "../actions";
import styles from "./css/CommonEditProfile.css";
import checksStyles from "./css/MandatoryChecks.css";

class AdditionalDocuments extends Component {
  onHandleChange(requiredDocument) {
    const {
      dispatch,
      partnership_agreement_upload_visible,
      trust_deed_upload_visible,
    } = this.props;

    if (requiredDocument === "trust_deed_upload_visible") {
      const newTrustDeedUploadVisible = !trust_deed_upload_visible;

      dispatch(setTrustDeedUploadVisible(newTrustDeedUploadVisible));

      if (!newTrustDeedUploadVisible) {
        dispatch(setRequiresTrustDeed(false));
      }
    }

    if (requiredDocument === "partnership_agreement_upload_visible") {
      dispatch(
        setPartnershipAgreementUploadVisible(
          !partnership_agreement_upload_visible,
        ),
      );
    }

    this.saveSettings();
  }

  onHandleChangeRequiresTrustDeed() {
    const { dispatch, requires_trust_deed } = this.props;

    dispatch(setRequiresTrustDeed(!requires_trust_deed));
    this.saveSettings();
  }

  saveSettings() {
    const { dispatch } = this.props;

    dispatch(updateRequiredAdditionalDocuments());
  }

  renderUpdateButton() {
    const {
      settings_requires_additional_documents_loading: isLoading,
    } = this.props;

    if (!isLoading) {
      return;
    }

    return (
      <div className={styles.button}>
        <Button handleClick={() => null} text="saving" />
        <span className={styles.loading}></span>
      </div>
    );
  }

  renderTrustDeedUploadCheckbox() {
    const { trust_deed_upload_visible } = this.props;

    return (
      <div className={checksStyles.options}>
        <SquareCheckbox
          key="trust_deed_upload_visible"
          checkboxId="trust_deed_upload_visible"
          checkboxName="trust_deed_upload_visible"
          checked={trust_deed_upload_visible}
          disabled={!isFeatureEditEnabled("Credit")}
          handleChange={this.onHandleChange.bind(
            this,
            "trust_deed_upload_visible",
          )}
          label="Application from a Trust entity: Copy of Trust Deed"
        />
        {this.renderRequireTrustDeedCheckbox()}
      </div>
    );
  }

  renderRequireTrustDeedCheckbox() {
    const { trust_deed_upload_visible, requires_trust_deed } = this.props;

    if (!trust_deed_upload_visible) {
      return;
    }

    return (
      <div className={checksStyles.row}>
        <div className={checksStyles.requires_trust_deed_checkbox_container}>
          <SquareCheckbox
            key="requires_trust_deed"
            checkboxId="requires_trust_deed"
            checkboxName="requires_trust_deed"
            checked={requires_trust_deed}
            handleChange={this.onHandleChangeRequiresTrustDeed.bind(this)}
            disabled={!isFeatureEditEnabled("Credit")}
            label="Is this mandatory?"
          />
        </div>
      </div>
    );
  }

  renderPartnershipAgreementUploadVisible() {
    const { partnership_agreement_upload_visible } = this.props;

    return (
      <div className={checksStyles.options}>
        <SquareCheckbox
          key="partnership_agreement_upload_visible"
          checkboxId="partnership_agreement_upload_visible"
          checkboxName="partnership_agreement_upload_visible"
          checked={partnership_agreement_upload_visible}
          disabled={!isFeatureEditEnabled("Credit")}
          handleChange={this.onHandleChange.bind(
            this,
            "partnership_agreement_upload_visible",
          )}
          label="Application from a Partnership entity: Copy of Partnership Agreement"
        />
      </div>
    );
  }

  render() {
    return (
      <div className={styles.row}>
        <div className={styles.block_wide}>
          <div className={styles.liner}>
            <h2 className={styles.header}>
              Do you require copies of either of the following documentation
            </h2>
            <ExtraDescription
              hintName="headquarterSettingHint"
              show={isHeadquarter()}
            />
            {this.renderUpdateButton()}
            <div className={checksStyles.row}>
              {this.renderTrustDeedUploadCheckbox()}
              {this.renderPartnershipAgreementUploadVisible()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state, ownProps) => {
  const profileState = state.manage_profile;

  return {
    autosave: ownProps.autosave || false,
    requires_trust_deed: profileState.requires_trust_deed,
    trust_deed_upload_visible: profileState.trust_deed_upload_visible,
    partnership_agreement_upload_visible:
      profileState.partnership_agreement_upload_visible,
    settings_requires_additional_documents_loading:
      profileState.settings_requires_additional_documents_loading,
  };
})(AdditionalDocuments);
