import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#users-creating-a-user
 */
const Users = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = "users";
};

function useV2(model) {
  model.axios.defaults.baseURL = model.axios.defaults.baseURL.replace("v1", "v2");
  return model;
}

Users.prototype.createUser = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        attributes,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Users.prototype.getUser = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

Users.prototype.getUserEntity = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}/entity`, options)
    .then(success)
    .catch(error);
};

Users.prototype.getUserConnections = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}/connections`, options)
    .then(success)
    .catch(error);
};

Users.prototype.getUserApplications = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}/applications`, options)
    .then(success)
    .catch(error);
};

Users.prototype.getUserSupplierApplications = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}/supplier_applications`, options)
    .then(success)
    .catch(error);
};

Users.prototype.getUserConsumerApplications = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}/consumer_applications`, options)
    .then(success)
    .catch(error);
};

Users.prototype.getUserNotifications = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}/notifications`, options)
    .then(success)
    .catch(error);
};

Users.prototype.updateUser = function(id, attributes, success, error) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        attributes,
        id,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

Users.prototype.deleteUser = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

Users.prototype.getUsers = function(success, error, options = {}) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

Users.prototype.get = function(url, success, error, options) {
  return this.axios
    .get(url, options)
    .then(success)
    .catch(error);
};

Users.prototype.getUserInfo = function(id, success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .get(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

Users.prototype.getApplicationsByKeyContact = function(
  id,
  success,
  error,
  options = {},
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .get(`/${this.type}/${id}/applications`, options)
    .then(success)
    .catch(error);
};

Users.prototype.getUserApplicationsCount = function(
  id,
  success,
  error,
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .get(`/${this.type}/${id}/applications_count`)
    .then(success)
    .catch(error);
}

Users.prototype.getConnectionsByKeyContact = function(
  id,
  success,
  error,
  options = {},
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .get(`/${this.type}/${id}/connections`, options)
    .then(success)
    .catch(error);
};

Users.prototype.getIndex = function(success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .get(`/${this.type}/`)
    .then(success)
    .catch(error);
};

Users.prototype.createNewEntity = function(id, options) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios.post(`/${this.type}/${id}/new_entity`, options);
};

Users.prototype.updatePreference = function(id, options) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios.patch(`/${this.type}/${id}/update_preference`, options);
};

Users.prototype.updateUserAndEntityRegion = function(id, options, success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");
  return this.axios
    .patch(`/${this.type}/${id}/update_user_and_entity_region`, options)
    .then(success)
    .catch(error);
};

Users.prototype.bulkReassignKeyContacts = function(id, options, success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace("v1", "v2");

  return this.axios
    .post(`/${this.type}/${id}/bulk_reassign`, options)
    .then(success)
    .catch(error);
}

Users.prototype.updatePassword = function({ id, options, success, error, requestEnd }) {
  const axios = useV2(this).axios;
  return axios
    .patch(`/${this.type}/${id}/update_password`, options)
    .then(success)
    .catch(error)
    .finally(requestEnd)
}

export default Users;
