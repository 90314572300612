import {
  SET,
  RESET,
  TEAM_LOAD_TEAM_START,
  TEAM_LOAD_TEAM_SUCCESS,
  TEAM_LOAD_TEAM_ERROR,
} from "./constants";

var initialState = {
  showSuccessCard: false,
};

export function accountSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET:
      return { ...state, ...action.payload };
    case RESET:
      return { ...initialState };
    case TEAM_LOAD_TEAM_START:
      return { ...state, team_list_loading: true };
    case TEAM_LOAD_TEAM_ERROR:
      return { ...state, team_list_loading: false };
    case TEAM_LOAD_TEAM_SUCCESS:
      return {
        ...state,
        team_list: action.payload.team_list,
        num_users: action.payload.num_users,
        team_list_loading: false,
      };
    default:
      return { ...state };
  }
}
