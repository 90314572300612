/* Import libraries */
import { loadNotifications } from "modules/notifications/actions.js";
import React, { useEffect } from "react";
import { connect } from "react-redux";

/* Import CSS */
import styles from "./css/NotificationPanel.css";
/* Import components */
import { getRangeNotifications, NotificationItem } from "./NotificationItem.js";

const NotificationPanel = props => {
  const { allHandler, dispatch, notifications } = props;

  useEffect(() => {
    dispatch(loadNotifications());
  }, []);

  return (
    <section className={`${styles.aside} ${styles.reset_line_height}`}>
      <div className={styles.scroll}>
        <div className="block mb-0 has-background-primary-light ">
          <div className="columns">
            <div className="column is-12 ">
              <NotificationItem
                notifications={getRangeNotifications(notifications, 0, 1)}
                title="Today"
              />
              <NotificationItem
                notifications={getRangeNotifications(notifications, -1, 1)}
                title="Yesterday"
              />
              <NotificationItem
                notifications={getRangeNotifications(notifications, -2, -1)}
                title="Earlier"
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.block_button}>
        <button
          onClick={allHandler}
          className="button is-rounded is-primary is-size-medium has-text-weight-medium"
        >
          View all
        </button>
      </div>
    </section>
  );
};

export default connect(state => {
  const notifications = state.notifications.notifications;
  return {
    notifications,
  };
})(NotificationPanel);
