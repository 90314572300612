import axios from "./axios";;

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#user_pop_ups
 */
let UserPopUps = function(config) {
  config.timeout = 30000;
  this.axios = axios(config);
  this.type = "user_pop_ups";
};

UserPopUps.prototype.delete = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

export default UserPopUps;
