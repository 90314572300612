import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "api";
import React, { ReactElement, useEffect } from "react";
import { formatMoney } from "utils/formatting";
import { THEME_COLORS } from "variables/theme";

import useParamsCompare from "../../hooks/useParamsCompare";
import useRequestQuery from "../../hooks/useRequestQuery";
import Panel from "../Panel";
import { Props } from "../Panel/model";
import useComparisonFilterStates from "./hooks/useComparisonFilterStates";
import {
  LineItem,
  Trend,
  Value,
  Wrapper,
} from "./styles";

function SummaryPanel(props: Props): ReactElement {
  const {
    accessToken,
    currentRoleTypes,
    entityId,
    filterState: params,
  } = props;

  const apiKey = "summary";
  const apiAction = () =>
    api("reporting", accessToken, entityId).get({
      api: apiKey,
      params,
    });

  const { data, error, forceUpdate, loading } = useRequestQuery(apiAction);

  const {
    data: comparisonData,
    error: comparisonError,
    forceUpdate: comparisonForceUpdate,
    loading: comparisonLoading,
  } = useComparisonFilterStates({
    ...props,
    filterState: params.compare,
  });

  const isStandardUser = currentRoleTypes.includes("standard");

  const paramString = JSON.stringify(params);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
      comparisonForceUpdate();
    }
  }, [
    comparisonForceUpdate,
    forceUpdate,
    oldParams,
    params,
    paramString,
    setOldParams,
  ]);

  /* eslint-disable */
  const lineItems = {
    total_started: {
      label: "Started",
    },
    total_approved: {
      label: "Approved",
    },
    total_declined: {
      label: "Declined",
    },
    total_requested_limits: {
      isCurrency: true,
      label: "Requested",
      prefix: "$",
    },
    total_approved_limits: {
      isCurrency: true,
      label: "Approved Value",
      prefix: "$",
    },
    total_personal_credit_checks: {
      label: "Pers. Credit Checks",
    },
    total_business_credit_checks: {
      label: "Bus. Credit Checks",
    },
    total_identification_checks: {
      label: "ID Checks",
    },
    total_aml_checks: {
      label: "AML Checks",
    },
    total_ppsr: {
      label: "PPSR",
    },
  };
  /* eslint-enable */

  const getComparisonState = (currentData: number, pastData: number) => {
    if (currentData > pastData) return 1;
    if (currentData < pastData) return -1;
    if (currentData === pastData) return 0;

    return null;
  };

  const getTrendIcon = (trendValue: number | null): React.ReactNode | null => {
    if (!trendValue) {
      return null;
    }

    if (trendValue === 0) {
      return "-";
    }

    const isUp = trendValue === 1;
    const color = isUp ? THEME_COLORS.trendUp : THEME_COLORS.trendDown;
    const direction = (isUp ? "caret-up" : "caret-down") as IconName;

    return (
      <FontAwesomeIcon color={color} icon={["fas", direction]} size="lg" />
    );
  };

  return (
    <Panel
      {...props}
      beta
      error={error || comparisonError}
      loading={loading || comparisonLoading}
      refresh={forceUpdate}
      title="Summary"
    >
      <Wrapper>
        {data &&
          Object.keys(lineItems).map(lineItem => {
            const { label, isCurrency, prefix } = lineItems[lineItem];
            const value = data[lineItem];
            const trendValue = comparisonData ?
              getComparisonState(data[lineItem], comparisonData[lineItem]) :
              null;
            const formattedValue = isCurrency ?
              formatMoney(parseFloat(value), null, null, null, null) :
              value;
            if (!formattedValue) {
              return [];
            }

            return (
              <LineItem key={lineItem}>
                <span>
                  {label}:
                </span>
                <Value>
                  {prefix}
                  {formattedValue}
                </Value>
                <Trend>{getTrendIcon(trendValue)}</Trend>
              </LineItem>
            );
          })}
      </Wrapper>
    </Panel>
  );
}

export default SummaryPanel;
