import React from "react";
import moment from "moment";

import styles from "../centrix.css";

class Judgements extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getArrayifiedData(data) {
    if (!data.data_sets.judgments || !data.data_sets.judgments.judgement) {
      return [];
    }
    let judgements = data.data_sets.judgments.judgement;
    if (judgements.constructor !== Array) {
      return [judgements];
    }
    return judgements;
  }

  getPlaintiffsString(plaintiffs) {
    if (!plaintiffs || !plaintiffs.plaintiff) {
      return "";
    }

    let plaintiffs_safe = plaintiffs.plaintiff;
    if (plaintiffs_safe.constructor === Array) {
      plaintiffs_safe.reduce((str, current, i) => {
        if (i > 0) {
          return str + ", " + current.name;
        }
        return current.name;
      }, "");
    }
    return plaintiffs_safe.name;
  }

  render() {
    const { data } = this.props;
    let arrayfied_data = this.getArrayifiedData(data);

    return (
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Judgement Date</th>
              <th>Type</th>
              <th>Plaintiff</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {arrayfied_data.map(item => (
              <tr>
                <td>{moment(item.date_of_judgement).format("DD/MM/YYYY")}</td>
                <td>{item.judgement_type}</td>
                <td>{this.getPlaintiffsString(item.plaintiffs)}</td>
                <td>
                  {item.amount &&
                    item.amount.constructor === String &&
                    "$" + item.amount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Judgements;
