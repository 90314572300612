/* Import libraries */
import React from "react";
import { connect } from "react-redux";

/* Import components */

import Button from "modules/shared/components/inputs/Button";
import CircleModal from "modules/shared/components/widgets/static/CircleModal";

var Modal = createClass({
  render: function() {
    const { held_rulesets, dismissHandler } = this.props;

    let link = `/dashboard/addons?active_tab=auto_decisioning`;
    if (held_rulesets && held_rulesets.length === 1) {
      link += "&task=edit-auto&id=" + held_rulesets[0];
    }
    return (
      <CircleModal
        title="Auto Decisioning Conflicts"
        dismissHandler={dismissHandler}
      >
        <p>
          By changing your supplier profile settings your auto-decisioning
          ruleset(s) need to be reviewed. Any current rule sets will be paused
          until this review has occured. Would you like to review these now?
        </p>
        <Button text="take me to the rulset settings" to={link} />
      </CircleModal>
    );
  },
});

module.exports = connect((state, ownProps) => {
  //const { onCancel} = this.props;
  return {
    held_rulesets: ownProps.held_rulesets,
    dismissHandler: ownProps.dismissHandler,
  };
})(Modal);
