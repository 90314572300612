import {
  MANAGE_USERS_CHECK_EMAIL_ERROR,
  MANAGE_USERS_CHECK_EMAIL_START,
  MANAGE_USERS_CHECK_EMAIL_SUCCESS,
  MANAGE_USERS_CLEAR_EMAIL_VALIDATION,
  MANAGE_USERS_CLEAR_USER_CONTACTS,
  MANAGE_USERS_CREATE_USER_ERROR,
  MANAGE_USERS_CREATE_USER_START,
  MANAGE_USERS_CREATE_USER_SUCCESS,
  MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_ERROR,
  MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_START,
  MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_SUCCESS,
  MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_ERROR,
  MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_START,
  MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_SUCCESS,
  MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_ERROR,
  MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_START,
  MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_SUCCESS,
  MANAGE_USERS_LOAD_USER_ERROR,
  MANAGE_USERS_LOAD_USER_START,
  MANAGE_USERS_LOAD_USER_SUCCESS,
  MANAGE_USERS_LOAD_USERS_BY_ENTITY_ERROR,
  MANAGE_USERS_LOAD_USERS_BY_ENTITY_START,
  MANAGE_USERS_LOAD_USERS_BY_ENTITY_SUCCESS,
  MANAGE_USERS_REMOVE_USER_ERROR,
  MANAGE_USERS_REMOVE_USER_START,
  MANAGE_USERS_REMOVE_USER_SUCCESS,
  MANAGE_USERS_TEAM_LOAD_HEADQUARTER_KEY_CONTACT,
  MANAGE_USERS_TEAM_LOAD_TEAM_ERROR,
  MANAGE_USERS_TEAM_LOAD_TEAM_START,
  MANAGE_USERS_TEAM_LOAD_TEAM_SUCCESS,
  MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_ERROR,
  MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_START,
  MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_SUCCESS,
  MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_ERROR,
  MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_START,
  MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS,
  MANAGE_USERS_UPDATE_USER_ERROR,
  MANAGE_USERS_UPDATE_USER_LAST_ADMIN_ERROR,
  MANAGE_USERS_UPDATE_USER_START,
  MANAGE_USERS_UPDATE_USER_SUCCESS,
} from "./constants";

const manage_users_defaults = {
  num_users: 0,
  saving: false,
  team_list: [],
  team_list_loading: false,
  total_user_applications: 0,
  total_user_connections: 0,
  user_applications: [],
  user_applications_loading: false,
  user_connections: [],
  user_connections_loading: false,
  user_list_for_assign_key_contact: [],
  user_list_loading: false,
  user_processing: false,
  websitebutons_loading: false,
};

export function manageUsersReducer(state = manage_users_defaults, action) {
  switch (action.type) {
    case MANAGE_USERS_CHECK_EMAIL_START:
      return { ...state, email_validating: true };
    case MANAGE_USERS_CHECK_EMAIL_ERROR:
      return { ...state, email_validating: false };
    case MANAGE_USERS_CHECK_EMAIL_SUCCESS:
      return { ...state, email_validating: false, exists_user: action.payload };

    case MANAGE_USERS_CLEAR_USER_CONTACTS:
      return {
        ...state,
        total_user_applications: 0,
        total_user_connections: 0,
        user_applications: [],
        user_connections: [],
      };
    case MANAGE_USERS_CLEAR_EMAIL_VALIDATION:
      return { ...state, email_validating: false, exists_user: null };

    case MANAGE_USERS_TEAM_LOAD_TEAM_START:
      return { ...state, team_list_loading: true };
    case MANAGE_USERS_TEAM_LOAD_TEAM_SUCCESS:
      return {
        ...state,
        num_users: action.payload.num_users,
        team_list: action.payload.team_list,
        team_list_loading: false,
      };
    case MANAGE_USERS_TEAM_LOAD_TEAM_ERROR:
      return { ...state, team_list_loading: false };
    case MANAGE_USERS_TEAM_LOAD_HEADQUARTER_KEY_CONTACT:
      return { ...state, headquarter_key_contact: action.payload };

    case MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_START:
      return { ...state, websitebutons_loading: true };
    case MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_SUCCESS:
      return {
        ...state,
        website_button_list: action.payload,
        websitebutons_loading: false,
      };
    case MANAGE_USERS_TEAM_LOAD_WEBSITBUTTONS_ERROR:
      return { ...state, websitebutons_loading: false };

    case MANAGE_USERS_LOAD_USER_START:
      return { ...state, user_loading: false };
    case MANAGE_USERS_LOAD_USER_SUCCESS:
      return { ...state, edit_user: action.payload, user_loading: false };
    case MANAGE_USERS_LOAD_USER_ERROR:
      return { ...state, user_loading: false };

    case MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_START:
      return { ...state, saving: true };
    case MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS:
      return { ...state, saving: false };
    case MANAGE_USERS_UPDATE_CONNECTION_KEY_CONTACT_ERROR:
      return { ...state, saving: false };
    case MANAGE_USERS_CREATE_USER_START:
      return { ...state, saving: true };
    case MANAGE_USERS_CREATE_USER_SUCCESS:
      return { ...state, saving: false };
    case MANAGE_USERS_CREATE_USER_ERROR:
      return { ...state, saving: false };
    case MANAGE_USERS_UPDATE_USER_START:
      return { ...state, saving: true };
    case MANAGE_USERS_UPDATE_USER_SUCCESS:
      return { ...state, saving: false };
    case MANAGE_USERS_UPDATE_USER_ERROR:
      return { ...state, saving: false };
    case MANAGE_USERS_UPDATE_USER_LAST_ADMIN_ERROR:
      return { ...state, saving: false };
    case MANAGE_USERS_REMOVE_USER_START:
      return { ...state, saving: true };
    case MANAGE_USERS_REMOVE_USER_SUCCESS:
      return { ...state, saving: false };
    case MANAGE_USERS_REMOVE_USER_ERROR:
      return { ...state, saving: false };

    case MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_START:
      return { ...state, user_applications_loading: true };
    case MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_START:
      return { ...state, user_applications_loading: true };
    case MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_SUCCESS:
      return {
        ...state,
        total_user_applications: action.payload.total,
        user_applications: action.payload.data,
        user_applications_loading: false,
      };
    case MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        total_user_applications: action.payload.total,
        user_applications_loading: false,
        user_processing: action.payload.processing,
      };
    case MANAGE_USERS_LOAD_USER_APPLICATIONS_COUNT_ERROR:
      return { ...state, user_applications_loading: false };
    case MANAGE_USERS_LOAD_USER_APPLICATION_KEY_CONTACTS_ERROR:
      return { ...state, user_applications_loading: false };

    case MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_START:
      return { ...state, user_connections_loading: true };
    case MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_SUCCESS:
      return {
        ...state,
        total_user_connections: action.payload.total,
        user_connections: action.payload.data,
        user_connections_loading: false,
      };
    case MANAGE_USERS_LOAD_USER_CONNECTION_KEY_CONTACTS_ERROR:
      return { ...state, user_applications_loading: false };

    case MANAGE_USERS_LOAD_USERS_BY_ENTITY_START:
      return { ...state, user_list_loading: true };
    case MANAGE_USERS_LOAD_USERS_BY_ENTITY_SUCCESS:
      return {
        ...state,
        user_list_for_assign_key_contact: action.payload,
        user_list_loading: false,
      };
    case MANAGE_USERS_LOAD_USERS_BY_ENTITY_ERROR:
      return { ...state, user_list_loading: false };

    default:
      return { ...state };
  }
}
