import PropTypes from "prop-types"
import React from "react"
import { Page } from "react-pdf";

import { Document, Wrapper } from "./styles";

function Preview({
  file,
  cssOverrides,
}) {
  const { preview, type } = file;
  const imagePattern = ["base64", "blob", ".jpeg", ".png", ".jpg", ".gif"];
  const isImage = imagePattern.some(pattern => preview.includes(pattern));

  let renderPreview = <img src={preview} alt="File preview" />;

  if (!isImage) {
    renderPreview = (
      <Document
        file={preview}
        renderMode="canvas"
      >
        <Page pageNumber={1} />
      </Document>
    );
  }

  return (
    <Wrapper
      cssOverrides={cssOverrides}
    >
      {renderPreview}
    </Wrapper>
  )
}

Preview.propTypes = {
  file: PropTypes.shape({}),
  cssOverrides: PropTypes.string,
}

Preview.defaultProps = {
  file: null,
  maxHeight: null,
};

export default Preview

