import React, { ReactElement, ReactNode } from "react"

import PopperTooltip from "../../widgets/interactive/PopperToolTip";
import { Description, Header, Heading, Wrapper } from "./styles";

interface Props {
  children: ReactNode,
  description?: ReactNode | string,
  header?: string | ReactNode,
  listType?: "none" | "number",
  tooltip?: {
    element: ReactNode,
    extraComponent: ReactNode,
  },
  visible?: boolean,
}

function ContentContainer({
  children,
  description,
  header,
  listType = "number",
  visible = true,
  tooltip,
}: Props): ReactElement|null {
  if (!visible) {
    return null;
  }

  return (
    <Wrapper>
      <Header>
        <Heading header={header} listType={listType}>
          {header}
          {tooltip && (
            <span>
              {" "}
              <PopperTooltip
                {...tooltip}
              >
                {tooltip.element}
              </PopperTooltip>
            </span>
          )}
        </Heading>
        { description && <Description>{description}</Description> }
      </Header>
      {children}
    </Wrapper>
  )
}

export default ContentContainer
