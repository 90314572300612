import React from "react";

import ReactTimeout from "react-timeout";
import { connect } from "react-redux";

import styles from "./css/Loader.css";

class Loader extends React.Component {
  state = {
    ui_state: "loading",
  };

  checkCompleted = () => {
    const { loading } = this.props;
    if (loading === false) {
      // Loading is complete, remove interval timer, add a timeout to
      // set loader as done in 1750 ms to fire the callback to remove
      // the loader from the parent.
      this.props.clearInterval(this.checkCompleted);
      this.props.setTimeout(this.animationComplete, 1750);

      this.setState({
        ui_state: "complete",
      });
    }
  }

  animationComplete = () => {
    const { handleComplete } = this.props;
    // Remove the animationComplete Timeout, and then call the
    // handleComplete callback.
    this.props.clearTimeout(this.animationComplete);
    handleComplete();
  }

  componentDidMount() {
    // Check if loadng is completed every 1750 ms
    this.props.setInterval(this.checkCompleted, 1750);
  }

  render() {
    const { message, loading, css_class } = this.props;
    const { ui_state } = this.state;

    let loader_title = null;
    let loader_styles = styles.loader;
    let loading_styles = styles.loading;

    if (css_class) {
      loader_styles = styles[css_class];
    }

    if (ui_state === "complete") {
      loading_styles = styles.loading_complete;
    }

    if (message) {
      loader_title = <h1 className={styles.title}>{message}</h1>;
    }

    return (
      <div className={loader_styles}>
        {loader_title}
        <div className={loading_styles}>
          <div className={styles.icon}></div>
          <div className={styles.circle_first}></div>
          <div className={styles.circle_second}></div>
        </div>
      </div>
    );
  }
};

export default ReactTimeout(Loader);
