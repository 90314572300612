export const screenSizes = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const max = (breakpoint: number): number => {
  return breakpoint - 1;
}

export const breakpointMax = (screenSize: string): string => {
  return `@media (max-width: ${max(screenSizes[screenSize])}px)`;
}

export const breakpointMin = (screenSize: string): string => {
  return `@media (min-width: ${screenSizes[screenSize]}px)`;
}

