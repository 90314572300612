import Checkbox from "@material-ui/core/Checkbox";
import React, { useState, useRef, useEffect } from "react";
import selfDefineStyles from "./css/MulitSelectDropdown.css";
import styles from "../css/material-select.css";
import toggleArrayValue from "utils/toggleArrayValue";
import Popper from "@material-ui/core/Popper";
import { VariableSizeList as List } from "react-window";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Switch2 from "./Switch2";
import BorderedTextField from "modules/shared/components/inputs/BorderedTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TextInput from "../../inputs/TextInput";

function useOutsideClick(ref, callback) {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    }
  })
}

function getInitialValues(value, multiple, hasMainOption) {
  if (value && hasMainOption) {
    return {
      main: value.main,
      other: value.other || [],
    };
  }
  if (value && multiple) {
    return { main: null, other: value || [] };
  }
  if (value) {
    return { main: null, other: [value] };
  }
  return { main: null, other: [] };
}

function useSelectedOptionsState(props) {
  const {
    hasMainOption,
    multiple,
    onChange,
    options,
    value,
  } = props;
  const initialValues = getInitialValues(value, multiple, hasMainOption);

  const [showOptions, setShowOptions] = useState(false);
  const [mainValue, setMainValue] = useState(initialValues.main);
  const [otherValue, setOtherValue] = useState(initialValues.other);

  const resetValue = () => {
    setMainValue(initialValues.main);
    setOtherValue(initialValues.other);
  }

  const handleBulkSelect = () => {
    const allValues = options.map(option => option.value);
    if (otherValue.length === allValues.length) {
      setOtherValue([]);
      onChange({ value: [] });
      return;
    }

    setOtherValue(allValues);
    onChange({ value: allValues });
  }

  const handleSingleClick = value => {
    let newMainValue;
    let newOtherValue;

    if (!multiple) {
      onChange({ value });
      setOtherValue([value]);
      setShowOptions(false);
      return;
    }

    if (!hasMainOption) {
      newOtherValue = toggleArrayValue(otherValue, value);
      onChange({ value: newOtherValue });
      setOtherValue(newOtherValue);
      return;
    }

    if (value === mainValue) {
      newMainValue = null;
      newOtherValue = otherValue;
    } else {
      newMainValue = mainValue;
      newOtherValue = toggleArrayValue(otherValue, value);
    }
    onChange({
      value: {
        main: newMainValue,
        other: newOtherValue,
      },
    });
    setMainValue(newMainValue);
    setOtherValue(newOtherValue);
  };

  const renderValues = () => {
    if (hasMainOption) {
      return `${mainValue ? mainValue + ", " : ""}${otherValue.join(", ")}`;
    }
    return otherValue.join(", ");
  };

  return {
    mainValue,
    otherValue,
    showOptions,
    setShowOptions,
    handleSingleClick,
    handleBulkSelect,
    renderValues,
    resetValue,
    setOtherValue,
  };
}

function OptionList(props) {
  const {
    bulkSelect,
    mainValue,
    otherValue,
    multiple,
    options,
    onClick,
    onClickAll,
    onDoubleClick,
    hasMainOption,
    width,
  } = props;
  let totalHeight = 1;
  const rows = [];
  const selectedAll = options.length === otherValue.length;

  const rowHeights = [];

  if (bulkSelect && multiple) {
    const title = selectedAll ? "Unselect all" : "Select all";
    totalHeight = totalHeight + 35;
    rowHeights.push(35);
    rows.push({
      brief: title,
      label: title,
      selected: selectedAll,
      value: "all",
    })
  }

  options.forEach(option => {
    const optionHeight = option.secondary_text ? 96 : 35;
    totalHeight = totalHeight + optionHeight;
    if (option.value === mainValue) {
      rows.push({
        ...option,
        selected: true,
        main: true,
        label: `${option.label} (main option)`,
      });
    } else if (otherValue && otherValue.includes(option.value)) {
      rows.push({ ...option, selected: true });
    } else {
      rows.push(option);
    }
    rowHeights.push(optionHeight);
  });
  const getItemSize = index => rowHeights[index];
  const height = totalHeight > 500 ? 500 : totalHeight;

  return (
    <List
      height={height}
      width={width ? parseInt(width) : window.innerWidth * 0.85}
      itemSize={getItemSize}
      itemCount={rows.length}
      itemData={{
        bulkSelect,
        multiple,
        rows,
        onClick,
        onClickAll,
        onDoubleClick,
        mainValue,
        otherValue,
        hasMainOption,
      }}
    >
      {Options}
    </List>
  );
}

function Options(props) {
  const { data, index, style } = props;
  const {
    multiple,
    rows,
    onClick,
    onClickAll,
    onDoubleClick,
    mainValue,
    hasMainOption,
  } = data;

  const value = rows[index].value;

  return (
    <div style={style}>
      <ListItem
        button
        onClick={() => {
          if (value === "all") {
            return onClickAll();
          }

          onClick(value);
        }}
        style={{ height: `${style.height}px`, fontWeight: 200 }}
      >
        {multiple && <Checkbox checked={!!rows[index].selected} />}
        <ListItemText
          className={!!rows[index].main ? selfDefineStyles.main_option : ""}
          primary={rows[index].label}
          secondary={rows[index].secondary_text}
        />

        {rows[index].selected && hasMainOption && (
          <div>
            <Switch2
              label="Main Option"
              checked={mainValue === rows[index].value}
              callback={e => onDoubleClick(e, rows[index].value)}
              prevent="true"
            />
          </div>
        )}
      </ListItem>
    </div>
  );
}

export default function SimpleMultiSelectDropdown(props) {
  const {
    bulkSelect,
    clearFunc,
    disabled,
    error,
    id,
    label,
    multiple,
    options,
    placeholder,
    required,
    value,
    variant,
    width,
  } = props;

  const {
    mainValue,
    otherValue,
    showOptions,
    setShowOptions,
    handleBulkSelect,
    handleSingleClick,
    resetValue,
    setOtherValue,
  } = useSelectedOptionsState(props);

  const inputEl = useRef(null);

  useOutsideClick(inputEl, () => {
    setShowOptions(false);
  })

  useEffect(() => {
    if (inputEl && inputEl.current) {
      setWid(inputEl.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWid(inputEl.current.offsetWidth);
    };
    window.addEventListener("resize", handleResize);
    return _ => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // TODO - Properly fix this patch
  useEffect(() => {
    resetValue();
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [wid, setWid] = useState(0);
  const rootRef = useRef();

  const getValues = () => {
    let values = Array.from(otherValue);
    if (mainValue) {
      values.push(mainValue);
    }

    let res = [];
    values.forEach(i => {
      res.push({ label: i, value: i });
    });
    return res;
  };

  const helperMessage = [];

  let Input = BorderedTextField;
  let inputProps = {
    error: !!error,
  };

  if (variant === 'underlined') {
    Input = TextInput;
    inputProps = {
      ...inputProps,
      error,
      type: 'dropdown',
      label,
    }
  }

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <Input
        autoComplete="off"
        id={id}
        placeholder={placeholder}
        type={"select"}
        inputRef={inputEl}
        onClick={event => {
          if (event.nativeEvent.srcElement.nodeName === "INPUT") {
            setShowOptions(!showOptions);

            setAnchorEl(event.currentTarget);
            event.preventDefault();
          }
        }}
        value={options
          .filter(x => value.includes(x.value))
          .map(x => x.brief)}
        disabled={disabled || false}
        error={!!error}
        customProps={{
          isCompact: false,
          withBottomMargin: false,
          endAdornment: (
            <InputAdornment position="end">
              {clearFunc && otherValue && otherValue.length > 0 && (
                <ClearIcon
                  edge="end"
                  onClick={() => {
                    clearFunc();
                    setOtherValue([]);
                  }}
                />
              )}
              <ArrowDropDownIcon
                style={{
                  marginBottom: 6,
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={event => {
                  if (event.nativeEvent.srcElement.nodeName === "svg") {
                    setShowOptions(!showOptions);
                    setAnchorEl(
                      event.target.parentNode.parentNode.childNodes[0],
                    );
                  }

                  event.preventDefault();
                }}
                edge="end"
              />
            </InputAdornment>
          ),
        }}
        helperText={error}
        required={required}
        {...inputProps}
      />

      {!props.disabled && (
        <Popper
          id={id}
          className={`${styles.popper}`}
          open={showOptions}
          anchorEl={anchorEl}
          placement="bottom-start"
          popperOptions={{ positionFixed: true }}
          modifiers={{
            flip: {
              enabled: false,
            },
          }}
        >
          <OptionList
            bulkSelect={bulkSelect}
            disabled={props.disabled || false}
            onClickAll={handleBulkSelect}
            otherValue={props.value}
            multiple={multiple}
            options={options}
            onClick={handleSingleClick}
            width={width ? width : wid}
          />
        </Popper>
      )}
    </MuiThemeProvider>
  );
}
