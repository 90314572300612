/* Import actions */
import ReviewIdentity from "modules/identity/components/review/ReviewIdentity";
import AccordianContainer from "modules/shared/components/containers/AccordianContainer";
/* Import components */
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import { isMobile } from "modules/shared/helpers/mobileDetect";
/* eslint-disable camelcase */
/* Import libraries */
import React from "react";
import { connect } from "react-redux";

import { readyForReview, setReviewIndex } from "../../actions/review";
import { loadCurrentPeople } from "../../actions/section";
/* Import CSS */
import styles from "./css/Review.css";
import ReviewAdditional from "./ReviewAdditional";
import ReviewBusiness from "./ReviewBusiness";
import ReviewCards from "./ReviewCards";
import ReviewExtras from "./ReviewExtras";
import ReviewFinancials from "./ReviewFinancials";
import ReviewPaperless from "./ReviewPaperless";
import ReviewTradeReferences from "./ReviewTradeReferences";
import ReviewTradingDetails from "./ReviewTradingDetails";

// eslint-disable-next-line no-undef
class ReviewSummary extends React.Component {
  componentDidMount() {
    const { dispatch, current_entity } = this.props;
    dispatch(readyForReview());
    dispatch(loadCurrentPeople(current_entity.id));
  }

  updateContainerIndex = index => {
    const { dispatch, container_index } = this.props;
    let i = index;
    if (container_index === i) {
      i = null;
    }
    dispatch(setReviewIndex(i));
    dispatch(readyForReview());
  }

  render() {
    const {
      addonRules,
      title,
      options,
      review_data,
      container_index,
      application,
      people,
      shareHolders,
      guarantorList,
      isconsumer,
      dispatch,
      review_complete,
      setPageValidationStartFinish,
      page_validation_start,
      entity_type,
      party_count,
    } = this.props;

    const sections = [];
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    const components = {
      business: ReviewBusiness,
      trading_names: ReviewTradingDetails,
      trade_references: ReviewTradeReferences,
      extras: ReviewExtras,
      identity: ReviewIdentity,
      cards: ReviewCards,
      financials: ReviewFinancials,
      additional: ReviewAdditional,
      paperless: ReviewPaperless,
    };
    /* eslint-enable sort-keys-fix/sort-keys-fix */

    const processedOptions = { ...options };

    if (application) {
      if (application.attributes.application_type === "cash") {
        delete processedOptions.trade_references;
        delete processedOptions.financials;
        delete processedOptions.cards;
        delete processedOptions.paperless;
        if (
          application.attributes.additional_questions_applies === "credit" ||
          !application.attributes.additional_application_questions ||
          application.attributes.additional_application_questions.length === 0
        ) {
          delete processedOptions.extras;
        }
      } else {
        const no_required_gtors = entity_type !== "company";
        if (
          (application.attributes.minimum_trade_references === 0 ||
            entity_type === "personal") &&
          (!application.attributes.requires_guarantees || no_required_gtors)
        ) {
          delete processedOptions.trade_references;
        }
      }

      const addonModuleNames = [];
      addonRules.forEach(rule => {
        addonModuleNames.push(rule.attributes.addon_module_name);
      });
      Object.keys(processedOptions).forEach(section => {
        if (!processedOptions[section].addon) return;
        const hasAddonRule = addonModuleNames.includes(section);
        if (!hasAddonRule) {
          delete processedOptions[section];
        }
      });

      if (entity_type !== "company") {
        delete processedOptions["trading_names"];
      }
    }

    if (review_data.cards && review_data.cards.cardholders.length === 0) {
      delete processedOptions.cards;
    }

    Object.keys(processedOptions).forEach(function(key) {
      sections.push(
        React.createElement(components[key], {
          application,
          data: review_data[key],
          "data-accordianLabel": processedOptions[key].label,
          dispatch,
          guarantorList,
          id: key,
          isconsumer,
          key,
          people,
          review_complete,
          setPageValidationStartFinish,
          shareHolders,
          summary_validation_start: page_validation_start,
        }),
      );
    });

    return (
      <section className={styles.summary_section}>
        <div className={styles.row}>
          <div className={styles.panel}>
            <PanelTitle text={title} />
            <p className={styles.info}>
              You will receive a copy of your completed application upon
              approval.
            </p>
          </div>
          <div className={styles.panel}>
            <AccordianContainer
              type="review"
              subPages={sections}
              subsection_index={container_index}
              handleClick={this.updateContainerIndex}
              scrollTo={isMobile()}
            />
          </div>
        </div>
      </section>
    );
  }
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  options: {
    business: { label: "Business details" },
    trading_names: { label: "Trading details" },
    trade_references: { label: "Checks" },
    extras: { label: "Limit" },
    cards: {
      addon: true,
      label: "Cards",
    },
    financials: {
      addon: true,
      label: "Financials",
    },
    additional: {
      addon: true,
      label: "Additional",
    },
    paperless: {
      addon: true,
      label: "Direct debit",
    },
    identity: { label: "Authorised applicant" },
  },
  title: "Final review",
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export default connect(state => {
  const people = state.cob_section.people;
  const companyDetails = state.cob_business.company_details || {};
  const shareHolders = companyDetails.shareholders || [];
  const entity_type = state.cob_business.entity_type;
  const party_count = state.cob_business.entity_party_count;
  const entity_region = state.cob_business.entity_region;
  const peopleWithoutShareholding = [];
  const addonRules = state.cob_section.addonRules || null;

  people.forEach(p => {
    const index = shareHolders.findIndex(
      s => s.first_name === p.first_name && s.last_name === p.last_name,
    );
    if (index === -1) {
      peopleWithoutShareholding.push(p);
    }
  });

  let guarantorList = people;
  if (entity_type === "company" && entity_region === "NZ") {
    guarantorList = peopleWithoutShareholding.concat(shareHolders);
  }

  if (state.cob_business.entity_type === "personal") {
    defaults.options.business.label = "Customer details";
  } else {
    defaults.options.business.label = "Business details";
  }

  return {
    addonRules,
    application: state.cob_section.application,
    container_index: state.cob_review.review_container_index,
    current_entity: state.current_user.current_entity,
    entity_type,
    guarantorList,
    isconsumer: true,
    options: defaults.options,
    params: state.current_user.query_params,
    party_count,
    people,
    review_complete: state.cob_review.completed,
    review_data: {
      additional: state.cob_additional,
      business: state.cob_business,
      cards: state.cob_cards,
      extras: Object.assign({}, state.cob_money, state.cob_extras),
      financials: state.cob_financials,
      identity: state.identity,
      money: state.cob_money,
      paperless: state.cob_paperless,
      trade_references: Object.assign(
        {},
        state.cob_traderef,
        state.cob_guarantors,
      ),
      trading_names: state.cob_section,
    },
    shareHolders,
    submitting: state.cob_review.submitting,
    supplier: state.cob_section.supplier,
    title: defaults.title,
  };
})(ReviewSummary);
