import IdentificationIcon from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/IdentificationIcon";
import IdentificationImageV1 from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v1";
import IdentificationImageV2 from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2";
import Header from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/Header";
import Button from "modules/shared/components/inputs/Button";
import FileWidgetSmall from "modules/shared/components/widgets/interactive/FileWidgetSmall";
import PDFLogo from "modules/shared/components/widgets/static/PDFLogo";
import React, { Fragment, useState } from "react";

const IdentificationImage = props => {
  const { application, signature } = props;

  const [hasPDFError, setHasPDFError] = useState(false);
  const onSetPDFError = () => setHasPDFError(true);

  if (application.antiFraudEnabled) {
    return (
      <IdentificationImageV2
        hasPDFError={hasPDFError}
        onSetPDFError={onSetPDFError}
        {...props}
      />
    );
  }

  return (
    <IdentificationImageV1
      hasPDFError={hasPDFError}
      onSetPDFError={onSetPDFError}
      {...props}
    />
  );
};

IdentificationImage.defaultProps = {
  isViewOnly: true,
  isVisibleWhenEmpty: true,
};

export default IdentificationImage;
