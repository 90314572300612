import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import { displayEmpty } from "utils/formatting";
import { enquiryResponseData } from "./helpers/reportHelpers";
import moment from "moment";
import { stringToSimpleDate } from "utils/dateFormatter";
import styles from "../../../css/CommonReviewBusiness.css";

var PPSRReportInformation = createClass({
  _expiryDate(searchDate) {
    return moment(searchDate).add(12, "months");
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      ppsrRegistrations = response.ppsr_registrations || {},
      report_information = ppsrRegistrations.report_information || {},
      grantor_search_criteria =
        report_information.grantor_search_criteria || {},
      registration_summary = ppsrRegistrations.registration_summary || {};

    return (
      <ReviewContainer
        subHeading="PPSR Report Information"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <h3>
            Grantor Search Results for{" "}
            {grantor_search_criteria.organisation_type}{" "}
            {grantor_search_criteria.organisation_number}
          </h3>
          <table className={styles.table_w20}>
            <thead>
              <tr>
                <th>Indentifier</th>
                <th>Search Number</th>
                <th>Search Date</th>
                <th>Expiry Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{grantor_search_criteria.organisation_number_type}</td>
                <td>{report_information.search_number}</td>
                <td>{stringToSimpleDate(report_information.search_date)}</td>
                <td>
                  {stringToSimpleDate(
                    this._expiryDate(report_information.search_date),
                  )}
                </td>
                <td>
                  {displayEmpty(registration_summary.total_registrations, "0")}{" "}
                  Registrations Found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },
});

export default PPSRReportInformation;
