/* Import libraries */
import React from "react";
import { connect } from "react-redux";

import { storeNameAlias } from "modules/shared/helpers/entityAttributes";
import { isTradingNameExist } from "../actions";
import { debounce } from "throttle-debounce";

/* Import components */
import SquareModal from "modules/shared/components/widgets/static/SquareModal";
import Button from "modules/shared/components/inputs/Button";
import TextInput from "modules/shared/components/inputs/TextInput";
import TextArea from "modules/shared/components/inputs/TextArea";

import styles from "./css/styles.css";

class TradingNameModal extends React.Component {
  constructor(props) {
    super(props);
    const { storeName } = this.props;
    this.state = {
      trading_name: null,
      trading_name_list: "",
      is_trading_name_duplicate: false,
    };
  }

  componentWillMount() {
    this.isNameDuplicate = debounce(500, false, this.isNameDuplicate);
  }

  isNameDuplicate(name) {
    const { dispatch, storeName } = this.props;
    if (name !== storeName.attributes.trading_name) {
      dispatch(
        isTradingNameExist(name, p => {
          this.setState({ is_trading_name_duplicate: p });
        }),
      );
    } else {
      this.setState({ is_trading_name_duplicate: false });
    }
  }

  handleNameListChange(event) {
    this.setState({ trading_name_list: event.target.value });
  }

  handleNameChange(event) {
    this.setState({ trading_name: event.target.value });
    this.isNameDuplicate(event.target.value);
  }

  renderDescription() {
    const { actionType, storeName } = this.props;
    switch (actionType) {
      case "add":
        return (
          `Add ${storeNameAlias().toLowerCase()} here. When your customer completes their application via your website, ` +
          `they will be presented with the list of ${storeNameAlias().toLowerCase()}'s to select from, as determined by you.`
        );
      case "edit":
        return storeName.attributes.editable
          ? "Please input the new name."
          : "You can’t edit this name as applications in progress belong to it. " +
              "If you don’t want to use it anymore, you can click the ‘deactivate’ button to hide it from future customers. " +
              "This will not impact active applications.";
      case "remove":
        return storeName.attributes.editable
          ? `Please confirm you would like to remove ‘${storeName.attributes.trading_name}‘ from your list.`
          : "This cannot be removed as some applications are active. " +
              "If you don’t want to use it anymore, you can click ‘deactivate’ which will hide it from future customers.";
    }
    return <p> {text} </p>;
  }

  renderInput() {
    const { actionType, storeName } = this.props;
    switch (actionType) {
      case "add":
        return (
          <TextArea
            handleChange={e => this.handleNameListChange(e)}
            placeholder={`Arrange ${storeNameAlias()} names like:\r\n${storeNameAlias()} one\r\n${storeNameAlias()} two\r\n${storeNameAlias()} three`}
          />
        );
      case "edit":
        return (
          <TextInput
            disabled={!storeName.attributes.editable}
            required={true}
            value={
              this.state.trading_name === null
                ? storeName.attributes.trading_name
                : this.state.trading_name
            }
            handleChange={e => this.handleNameChange(e)}
            error={this.state.is_trading_name_duplicate && "duplicate name"}
          />
        );
    }
  }

  renderButtons() {
    const {
      actionType,
      storeName,
      handleCreate,
      handleUpdate,
      handleDelete,
      handleDismiss,
    } = this.props;
    var buttons = [];
    switch (actionType) {
      case "add":
        buttons.push(
          <Button
            text={"Create"}
            disabled={!this.state.trading_name_list}
            handleClick={() =>
              handleCreate({ trading_names: this.state.trading_name_list })
            }
          />,
        );
        buttons.push(<Button text={"Cancel"} handleClick={handleDismiss} />);
        break;
      case "edit":
        if (storeName.attributes.editable) {
          buttons.push(
            <Button
              text={"Update"}
              disabled={
                this.state.is_trading_name_duplicate ||
                this.state.trading_name === ""
              }
              handleClick={() =>
                handleUpdate(storeName.id, {
                  trading_name: this.state.trading_name,
                })
              }
            />,
          );
          buttons.push(<Button text={"Cancel"} handleClick={handleDismiss} />);
        } else {
          buttons.push(<Button text={"ok"} handleClick={handleDismiss} />);
        }
        break;
      case "remove":
        if (storeName.attributes.editable) {
          buttons.push(
            <Button
              text={"Confirm"}
              handleClick={() => handleDelete(storeName.id)}
            />,
          );
          buttons.push(<Button text={"Cancel"} handleClick={handleDismiss} />);
        } else {
          buttons.push(<Button text={"ok"} handleClick={handleDismiss} />);
        }
        break;
    }
    return buttons;
  }

  render() {
    const { title, actionType } = this.props;

    return (
      <SquareModal
        title={actionType.replace(/\b\w/g, l => l.toUpperCase())}
        size={"small"}
      >
        <p>{this.renderDescription()}</p>
        {this.renderInput()}
        <div>{this.renderButtons()}</div>
      </SquareModal>
    );
  }
}

module.exports = connect((state, ownProps) => {
  return {
    storeName: state.website_button.store_name,
  };
})(TradingNameModal);
