import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import EmailInput from "modules/shared/components/inputs/EmailInput";
import TextInput from "modules/shared/components/inputs/TextInput";
import React, { Component, useState } from "react";
import { browserHistory } from "react-router";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import LinearProgress from "@material-ui/core/LinearProgress";
import CompanyDetails from "./CompanyDetails";
import styles from "./css/ConsumerNameSearch.css";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { muiTheme } from "modules/shared/helpers/colorPalettes";

export function SearchInput(props) {
  const { handleChange, loading } = props;
  const [searchKeyword, setSearchKeyword] = useState("");

  const onChange = event => {
    setSearchKeyword(event.target.value);

    if (handleChange) {
      handleChange(event);
    }
  };

  return (
    <div className={styles.search_input}>
      <TextInput
        value={searchKeyword}
        required={true}
        label="Enter entity name"
        autocomplete="off"
        handleChange={onChange}
        showSearchIcon={true}
      />
    </div>
  );
}

export function EmailSearchInput(props) {
  const { error, handleChange, id, loading } = props;
  const [emailSearchKeyword, setEmailSearchKeyword] = useState("");

  const onChange = (value, isValid) => {
    setEmailSearchKeyword(value);

    if (handleChange) {
      handleChange(value, isValid);
    }
  };

  const divClassName = isBlank(error) ? styles.search_input : null;

  return (
    <div className={divClassName}>
      <EmailInput
        id={id}
        customError={error}
        handleChange={onChange}
        label="Email"
        required={true}
        value={emailSearchKeyword}
      />
    </div>
  );
}

function LoadingIndicator(props) {
  const { isLoading } = props;

  if (!isLoading) {
    return null;
  }

  return (
    <div className="mt-4 mr-4 mb-4">
      <LinearProgress />
    </div>
  );
}

function ListNoticeBottomNotice(props) {
  const { options } = props;

  if (isBlank(options)) {
    return null;
  }

  return (
    <div className="has-text-primary mt-2">
      If you can&apos;t find the business/person scroll to the bottom.
    </div>
  );
}

export default class AutoSuggest extends Component {
  constructor() {
    super();
    this.state = {
      entityId: null,
      newUser: null,
      user: null,
    };
  }

  setValues = values => {
    const { handleSelect } = this.props;
    const outputs = {
      entityId: values.entityId,
      name: values.name,
      newUser: values.newContact,
      user: values.currentContact,
    };
    this.setState(outputs);
    handleSelect(outputs);
  };

  goToInviteNew = () => {
    const { nextPath, handleSelect, onCannotFindResult } = this.props;
    const outputs = {
      entityId: null,
      name: null,
      newUser: null,
      user: null,
    };
    this.setState(outputs);
    handleSelect(outputs);

    onCannotFindResult();

    const pathFragment = nextPath.split("/");
    pathFragment[pathFragment.length - 1] = "new";
    browserHistory.push(pathFragment.join("/"));
  };

  renderCannotFindText() {
    const { isCannotFindTextVisible, loading } = this.props;

    if (!isCannotFindTextVisible || loading) {
      return null;
    }

    return (
      <div>
        <Divider className={styles.divider} component="li" />
        <ListItem className={styles.company_text}>
          <a onClick={this.goToInviteNew}>
            If you can&apos;t find the business/person{" "}
            <span className="has-text-danger">click here</span>.
          </a>
        </ListItem>
      </div>
    );
  }

  renderCompanyList() {
    const { options, nextPath } = this.props;
    const { entityId } = this.state;

    if (isBlank(options)) {
      return null;
    }
    const expandTheFirst = entityId === null;
    const companyList = [];
    Object.keys(options).forEach((key, index) =>
      companyList.push(
        <CompanyDetails
          key={key}
          selected={key === entityId}
          index={index}
          expend={expandTheFirst && index === 0}
          onSelect={values => this.setValues({ entityId: key, ...values })}
          onCloseModal={values => this.setValues({ entityId: null, ...values })}
          companyData={options[key]}
          nextPath={nextPath}
        />,
      ),
    );

    return companyList;
  }

  render() {
    const {
      context,
      hasSearchStarted,
      isCannotFindTextVisible,
      loading,
      options,
    } = this.props;

    if (!hasSearchStarted) {
      return null;
    }

    let className = styles.company_list;
    if (context === "emailDomainSearch") {
      className = styles.email_domain_search_list;
    }

    if (isCannotFindTextVisible) {
      return (
        <MuiThemeProvider theme={muiTheme()}>
          <List className={className}>
            {this.renderCompanyList()}
            <LoadingIndicator isLoading={loading} />
            {this.renderCannotFindText()}
          </List>
          <ListNoticeBottomNotice options={options} />
        </MuiThemeProvider>
      );
    }

    if (isPresent(options) || loading) {
      return (
        <MuiThemeProvider theme={muiTheme()}>
          <List className={className}>
            {this.renderCompanyList()}
            <LoadingIndicator isLoading={loading} />
          </List>
        </MuiThemeProvider>
      );
    }

    return null;
  }
}

AutoSuggest.defaultProps = {
  hasSearchStarted: false,
  isCannotFindTextVisible: true,
};
