/* eslint-disable max-lines */
import { Collapse } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import IconApplicationActive from "images/svgs/icon-application-active.svg";
import IconApplication from "images/svgs/icon-application.svg";
import IconDeleteArchiveActive from "images/svgs/icon-delete-archive-active.svg";
import IconDeleteArchive from "images/svgs/icon-delete-archive.svg";
import IconNotesActive from "images/svgs/icon-notes-active.svg";
import IconNotes from "images/svgs/icon-notes.svg";
import get from "lodash.get";
import mixpanel from "mixpanel-browser";
import LeadModel from "models/LeadModel";
import UserModel from "models/UserModel";
import Notes from "modules/leads/components/Notes";
import { MobileScreenContext } from "modules/new-applications/components/Application";
import IUF from "modules/new-applications/components/application-sections/IUF";
import StatusIndicator from "modules/new-applications/components/StatusIndicator";
import { TOOLTIP_TEXT } from "modules/new-applications/constants";
// import styles from "./css/Review.css";
import styles from "modules/new-applications/css/common.css";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import useIsMobileScreenState, { MEDIA_QUERIES } from "modules/new-applications/hooks/useIsMobileScreenState";
import FixedContent from "modules/shared/components/containers/FixedContent";
import GridContent from "modules/shared/components/containers/GridContent";
import ScrollableContent from "modules/shared/components/containers/ScrollableContent";
import TopbarContent from "modules/shared/components/containers/TopbarContent";
import Button from "modules/shared/components/inputs/Button";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import PopperTooltip from "modules/shared/components/widgets/interactive/PopperToolTip";
import LabeledContent from "modules/shared/components/widgets/static/LabeledContent";
import SquareModal from "modules/shared/components/widgets/static/SquareModal";
import arrayFromNumber from "modules/shared/helpers/arrayFromNumber";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import useCollapsibleContentState from "modules/shared/hooks/useCollapsibleContentState";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Link } from "react-router-dom";
import { formatLocalTime } from "utils/dateFormatter";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

import { loadLeadDetails, updateLeadArchive } from "../actions";

function Topbar(props) {
  const { visibleSection, setVisibleSection } = props;

  const topbarActions = [
    {
      Icon: IconNotes,
      isActive: visibleSection === "notes",
      label: "Notes",
      onClick: () => setVisibleSection("notes"),
    },
    {
      Icon: IconDeleteArchive,
      isActive: visibleSection === "archive",
      label: "Archive",
      onClick: () => setVisibleSection("archive"),
    },
  ];

  return (
    <div className={styles.topbar}>
      <div className={styles.topbar_content}>
        <div className={styles.action_container}>
          <TopbarContent topbarActions={topbarActions} />
        </div>
      </div>
    </div>
  )
}

function notesLinkProperties(props) {
  return linkProperties(props, "/notes");
}

function archiveLinkProperties(props) {
  return linkProperties(props, "/archive");
}

function CompanyInformation(props) {
  const { lead } = props;

  return (
    <GridContent>
      <div>
        <LabeledContent
          label="Company/Business name"
          content={lead.companyName}
        />
        <LabeledContent
          label="Business type"
          content={lead.formattedLegalType}
        />
        <LabeledContent
          label={lead.companyNumberLabel}
          content={lead.companyNumber}
        />
        <LabeledContent
          label="Business location"
          content={lead.formattedRegion}
        />
      </div>
      <div>
        <LabeledContent label="Trading name" content={lead.tradingName} />
        <LabeledContent
          label="Business phone number"
          content={lead.contactPhoneNumber}
        />
        <LabeledContent
          label={lead.businessNumberLabel}
          content={lead.businessNumber}
        />
      </div>
    </GridContent>
  );
}

function NonCompanyInformation(props) {
  const { lead } = props;

  return (
    <GridContent>
      <div>
        <LabeledContent label="Trading name" content={lead.tradingName} />
        <LabeledContent
          label="Business phone number"
          content={lead.contactPhoneNumber}
        />
        <LabeledContent
          label={lead.businessNumberLabel}
          content={lead.businessNumber}
          isVisibleWhenEmpty={lead.region === "AU"}
        />
      </div>
      <div>
        <LabeledContent
          label="Business type"
          content={lead.formattedLegalType}
        />
        <LabeledContent
          label="Business location"
          content={lead.formattedRegion}
        />
        <LabeledContent
          isVisibleWhenEmpty={lead.region === "AU"}
          label={lead.companyNumberLabel}
          content={lead.companyNumber}
        />
      </div>
      <div></div>
    </GridContent>
  );
}

function BusinessInformation(props) {
  const { lead } = props;

  if (lead.legalType === "company") {
    return <CompanyInformation {...props} />;
  }

  return <NonCompanyInformation {...props} />;
}

function formatDirectorAddress(address) {
  const { country, postcode, street } = address;

  return `${street.join(" ")} ${country} ${postcode}`;
}

function Director(props) {
  const { director, index } = props;

  const fullName = [
    director.first_name,
    director.middle_name,
    director.last_name,
  ]
    .filter(name => isPresent(name))
    .join(" ");
  const appointedAt = moment(director.valid_from).format("DD/MM/YYYY");

  return (
    <div>
      <LabeledContent label={`Director ${index}`} content={fullName} />
      <LabeledContent label="Appointment date" content={appointedAt} />
      <LabeledContent
        label="Residential address"
        content={formatDirectorAddress(director.address)}
      />
    </div>
  );
}

function Directors(props) {
  const { lead } = props;
  const directors = lead.directors || [];

  if (lead.legalType !== "company" || isBlank(directors)) {
    return null;
  }

  const directorComponents = directors.map((director, index) => (
    <Director
      key={`director-${index + 1}`}
      director={director}
      index={index + 1}
    />
  ));

  return (
    <FixedContent header="Director">
      <div className={styles.directors}>{directorComponents}</div>
    </FixedContent>
  );
}

function Address(props) {
  const { lead } = props;

  return (
    <FixedContent header="Address">
      <GridContent>
        <LabeledContent
          label="Business address"
          content={lead.fullPhysicalAddress}
        />
        <LabeledContent
          label="Delivery instructions"
          content={lead.deliveryInstruction}
        />
      </GridContent>
    </FixedContent>
  );
}

function KeyContactInfo(props) {
  const { lead } = props;

  return (
    <FixedContent header="Key contact">
      <GridContent>
        <div>
          <LabeledContent
            label="First name"
            content={lead.keyContactFirstName}
          />
          <LabeledContent label="email" content={lead.keyContactEmail} />
        </div>
        <div>
          <LabeledContent label="Last name" content={lead.keyContactLastName} />
        </div>
      </GridContent>
    </FixedContent>
  );
}

// Old fixed internal fields
// superseded by new AddonAnswer IUF
function InternalFieldsDeprecated(props) {
  const { lead } = props;

  const internalFields = arrayFromNumber(4, number => lead[`internalField${number}`]);
  const noValues = internalFields.every(internalField => !internalField);

  if (lead.hasIUFAddonRule || noValues) {
    return null;
  }

  return (
    <FixedContent header="Internal fields">
      <GridContent>
        <div>
          <LabeledContent
            label="1. Internal field"
            content={lead.internalField1}
          />
          <LabeledContent
            label="3. Internal field"
            content={lead.internalField3}
          />
        </div>
        <div>
          <LabeledContent
            label="2. Internal field"
            content={lead.internalField2}
          />
          <LabeledContent
            label="4. Internal field"
            content={lead.internalField4}
          />
        </div>
      </GridContent>
    </FixedContent>
  );
}

function LoadedInSystemCheckbox(props) {
  const { isLoadedInSystem, onLoadedInSystem } = props;

  if (typeof isLoadedInSystem === "undefined") {
    return null;
  }

  return (
    <Checkbox
      size="small"
      checked={isLoadedInSystem}
      disabled={isLoadedInSystem}
      onChange={onLoadedInSystem}
    />
  );
}

function LoadedInSystemBy(props) {
  const { lead } = props;

  if (!lead.isLoadedInSystem) {
    return null;
  }

  let formattedLoadedInSystemAt = "-";
  if (isPresent(lead.loadedInSystemAt)) {
    formattedLoadedInSystemAt = formatLocalTime(
      lead.loadedInSystemAt,
      "minute",
    );
  }

  return (
    <div className="is-size-5">
      (Loaded in system by {lead.loadedInSystemByFullName} on{" "}
      {formattedLoadedInSystemAt})
    </div>
  );
}

function LoadedInSystem(props) {
  const { currentUser, dispatch, lead, onFetchApplicationRecord } = props;
  const [checked, setChecked] = useState(false);

  const onSuccessCallback = () => onFetchApplicationRecord();
  const onErrorCallback = () => {
    setChecked(false);
    onFetchApplicationRecord();
  };

  const onLoadedInSystem = () => {
    setChecked(true);
    lead.onLoadedInSystem({ currentUser, onErrorCallback, onSuccessCallback });
  };

  return (
    <div className={`${styles.loaded_in_system_container} is-flex is-flex-wrap is-flex-direction-column`}>
      <FormControlLabel
        control={
          <LoadedInSystemCheckbox
            isLoadedInSystem={lead.isLoadedInSystem || checked}
            onLoadedInSystem={onLoadedInSystem}
          />
        }
        disabled={lead.isLoadedInSystem || lead.isLoading || checked}
        label="Reviewed and loaded in system?"
      />
      <LoadedInSystemBy lead={lead} />
    </div>
  );
}

function Details(props) {
  const { currentUser, lead, router } = props;
  const leadName = get(currentUser, "currentEntity.leadName", "lead");

  return (
    <ScrollableContent>
      <FixedContent header="Business information">
        <BusinessInformation {...props} />
      </FixedContent>
      <Address {...props} />
      <Directors {...props} />
      <KeyContactInfo {...props} />
    </ScrollableContent>
  );
}

function ArchiveConfirmationModal(props) {
  const { dispatch, isVisible, lead, onHideModal, router } = props;

  if (!isVisible) {
    return null;
  }

  const { isLoading, setIsLoading } = useIsLoadingState();

  const onClickConfirm = () => {
    setIsLoading(true);

    dispatch(updateLeadArchive(lead.id));

    setIsLoading(false);
    onHideModal();
    router.push("/dashboard/leads/list");
  };

  return (
    <SquareModal size="small">
      <div className={styles.confirmation_description}>
        Are you sure you want to archive this application?
      </div>
      <div className={styles.confirmation_buttons}>
        <Button
          loading={isLoading}
          text="Archive"
          handleClick={onClickConfirm}
        />
        <Button white text="Cancel" handleClick={onHideModal} />
      </div>
    </SquareModal>
  );
}

function Archive(props) {
  const { lead, setSelectedNoteCategory, setVisibleSection, router } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onClickArchive = () => setIsModalVisible(true);
  const onHideModal = () => setIsModalVisible(false);
  const onAddNote = () => {
    setSelectedNoteCategory("archive");
    setVisibleSection("notes");
  };

  const onClickCloseButton = () => router.push("/dashboard/leads/list");

  return (
    <Fragment>
      <FixedContent header="Archive application">
        <CloseButton
          handleClick={onClickCloseButton}
          style={{ right: 10, top: 10 }}
        />
        <div className={styles.buttons}>
          <Button
            text="Archive"
            disabled={lead.archived}
            disableOnLoading={true}
            handleClick={onClickArchive}
          />
          <Button white text="Add note" onClick={onAddNote} />
        </div>
      </FixedContent>
      <ArchiveConfirmationModal
        isVisible={isModalVisible}
        lead={lead}
        onHideModal={onHideModal}
        {...props}
      />
    </Fragment>
  );
}

function MobileMainBody(props) {
  const { lead, content, isLoading, visibleSection, router } = props;
  const { isLinkActive: isNotesPageActive } = notesLinkProperties(props);
  const { isLinkActive: isArchivePageActive } = archiveLinkProperties(props);
  const onClickCloseButton = () => router.push("/dashboard/leads/list");

  if (isLoading || isNotesPageActive || isArchivePageActive) {
    return <div className={styles.mobile_main}>{content}</div>;
  }

  const { isOpen, onToggleIsOpen, setIsOpen } = useCollapsibleContentState(
    false,
  );

  const onClickSidebarLink = nextSection => {
    if (visibleSection === nextSection) {
      onToggleIsOpen();
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.mobile_main}>
      <CloseButton
        handleClick={onClickCloseButton}
        style={{ right: 10, top: 10 }}
      />
      <FixedContent
        header={`Review progress for ${lead.formattedBusinessName}`}
      >
        <SidebarLinks
          content={content}
          isLoading={isLoading}
          isOpen={isOpen}
          onClickSidebarLink={onClickSidebarLink}
          {...props}
        />
      </FixedContent>
    </div>
  );
}

function VisibleComponent(props) {
  const { isMobileScreen } = useContext(MobileScreenContext);
  const {
    lead,
    isLoading,
    selectedNoteCategory,
    setSelectedNoteCategory,
    visibleSection,
  } = props;

  let content;

  if (visibleSection === "details") {
    content = <Details {...props} />;
  }

  if (visibleSection === "internal_use_fields") {
    content = (
      <Fragment>
        <InternalFieldsDeprecated {...props} />
        { lead.hasIUFAddonRule ? <IUF {...props} /> : ""}
      </Fragment>
    );
  }

  if (visibleSection === "loaded_in_system") {
    content = <LoadedInSystem {...props} />;
  }

  if (visibleSection === "notes") {
    content = (
      <Notes
        selectedNoteCategory={selectedNoteCategory}
        setSelectedNoteCategory={setSelectedNoteCategory}
        {...props}
      />
    );
  }

  if (visibleSection === "archive") {
    content = (
      <Archive setSelectedNoteCategory={setSelectedNoteCategory} {...props} />
    );
  }

  if (isLoading) {
    content = arrayFromNumber(6, number => (
      <Skeleton
        key={`vcf-content-skeleton-${number}`}
        animation="wave"
        className={`mb-4 ${!isMobileScreen && "mr-6"}`}
        height={25}
        variant="rect"
      />
    ));
  }

  if (isMobileScreen) {
    return (
      <MobileMainBody
        content={content}
        isLoading={isLoading}
        {...props}
      />
    )
  }

  return <div className={styles.main}>{content}</div>
}

function extractApplicationIdFromProps(props) {
  return get(props, "children.props.params.application_id", "");
}

function linkProperties(props, path) {
  const { visibleSection } = props;
  const applicationId = extractApplicationIdFromProps(props);
  const pathname = get(props, "children.props.location.pathname", "");
  const linkTo = `/dashboard/leads/${applicationId}${path}`;

  return {
    currentPath: pathname,
    isLinkActive: path.includes(visibleSection),
    linkTo,
  };
}

function SidebarContent(props) {
  const { content, isLoading, isVisible = true, prefix } = props;

  if (isLoading || !isVisible) {
    return null;
  }

  if (isLoading) {
    return (
      <Skeleton
        className="mb-4"
        animation="wave"
        variant="rect"
        height="25"
      />
    )
  }

  if (isBlank(content)) {
    return null;
  }

  if (isPresent(prefix)) {
    return <div>{`${prefix}: ${content}`}</div>;
  }

  return <div>{content}</div>;
}

function SidebarLink(props) {
  const {
    content,
    currentUser,
    isLoading,
    isOpen,
    isVisible,
    label,
    path,
    onClickSidebarLink,
    status,
    tooltip,
    setVisibleSection,
  } = props;
  const { isLinkActive } = linkProperties(props, path);

  if (!isVisible) {
    return null;
  }

  if (isLoading) {
    return (
      <Skeleton
        className="mb-4 mr-4"
        animation="wave"
        variant="rect"
        height="25"
        style={{ marginLeft: "30px" }}
      />
    )
  }

  let sectionClassName = styles.sidebar_menu_application_section;
  if (isLinkActive) {
    sectionClassName += ` ${styles.sidebar_menu_application_section_active}`;
  }

  if (isLinkActive && isOpen) {
    sectionClassName += ` ${styles.sidebar_menu_application_section_open}`;
  }

  let visibleContent = null;
  if (isLinkActive && isOpen && isPresent(content)) {
    visibleContent = (
      <div className={styles.sidebar_menu_application_content}>{content}</div>
    );
  }

  const onClickLink = () => {
    mixpanel.track(`Sidebar navigate to ${label}`, {
      "Entity ID": get(currentUser, "currentEntity.id"),
      distinct_id: currentUser.id,
    });

    const activeSection = path.replace("/", "");

    setVisibleSection(activeSection);

    if (onClickSidebarLink) {
      onClickSidebarLink(activeSection);
    }
  };

  let statusAndLabel = (
    <div className={styles.sidebar_menu_application_status}>
      <div className={styles.sidebar_menu_application_status_content}>
        <StatusIndicator status={status} />
        <span className={styles.sidebar_menu_application_section_link}>
          <a onClick={onClickLink}>
            {label}
          </a>
        </span>
      </div>
    </div>
  );

  if (tooltip) {
    statusAndLabel = (
      <PopperTooltip
        title={tooltip}
        placement="bottom"
        isVisibleOnMobile={false}
      >
        {statusAndLabel}
      </PopperTooltip>
    );
  }

  return (
    <div className={sectionClassName}>
      {statusAndLabel}
      {visibleContent}
    </div>
  );
}

function SidebarLinks(props) {
  const { lead, isLoading } = props;

  let businessDetailsLabel = "Business details";
  if (lead.legalType === "personal") {
    businessDetailsLabel = "Customer details";
  }

  return (
    <Fragment>
      <SidebarLink
        path="/details"
        status="complete"
        label={businessDetailsLabel}
        isVisible
        isLoading={isLoading}
        {...props}
      />
      <SidebarLink
        path="/internal_use_fields"
        status={lead.internalUseFieldsStatus}
        label="IUF"
        isVisible={lead.isIUFVisible}
        isLoading={isLoading}
        {...props}
      />

      <SidebarLink
        path="/loaded_in_system"
        status={lead.loadedInSystemStatus}
        label="Loaded in system"
        isVisible
        isLoading={isLoading}
        {...props}
      />
    </Fragment>
  );
}

function SidebarApplicationSections(props) {
  const { isLoading } = props;

  return (
    <div className={styles.sidebar_menu}>
      <SidebarLinks
        isLoading={isLoading}
        {...props}
      />
    </div>
  );
}

function SidebarApplicationInfo(props) {
  const { lead, isLoading } = props;
  const { isOpen, onToggleIsOpen } = useCollapsibleContentState(true);

  return (
    <div className={styles.sidebar_menu}>
      <PopperTooltip
        title={TOOLTIP_TEXT.applicationInfo}
        placement="bottom"
        isVisibleOnMobile={false}
      >
        <div className={styles.sidebar_menu_header} onClick={onToggleIsOpen}>
          Lead info
        </div>
      </PopperTooltip>
      <Collapse in={isOpen}>
        <SidebarContent
          content={lead.formattedBusinessName}
          isLoading={isLoading}
        />
        <SidebarContent
          content={lead.tradingName}
          isLoading={isLoading}
          prefix="Trading name"
        />
        <SidebarContent
          content={lead.initiatorName}
          isLoading={isLoading}
          prefix="Initiated by"
        />
        <SidebarContent
          content={lead.iufApproval.approverName}
          isLoading={isLoading}
          prefix="IUF Approver"
        />
      </Collapse>
    </div>
  );
}

function MobileBottomMenuLink(props) {
  const {
    currentUser,
    icon,
    iconActive,
    iconProps,
    label,
    section,
    setVisibleSection,
    visibleSection,
  } = props;

  let Icon = icon;
  let linkClassName = styles.mobile_bottom_menu_action;
  const isLinkActive = section === "lead" ?
    !["notes", "archive"].includes(visibleSection) :
    visibleSection === section;

  if (isLinkActive) {
    Icon = iconActive;
    linkClassName += ` ${styles.mobile_bottom_menu_action_active}`;
  }

  const onClickLink = () => {
    mixpanel.track(`Mobile bottom menu navigate to ${label}`, {
      "Entity ID": get(currentUser, "currentEntity.id"),
      distinct_id: currentUser.id,
    });

    setVisibleSection(section);
  };

  return (
    <a className={linkClassName} onClick={onClickLink}>
      <Icon
        width={iconProps.width}
        height={iconProps.height}
        style={{
          margin: iconProps.margin,
        }}
      />
      <div>{label}</div>
    </a>
  );
}

MobileBottomMenuLink.defaultProps = {
  iconProps: {
    height: 50,
    margin: 0,
    width: 50,
  },
};

function MobileBottomMenu(props) {
  const { children, currentUser } = props;

  return (
    <div className={styles.mobile_bottom_menu}>
      <MobileBottomMenuLink
        currentUser={currentUser}
        icon={IconApplication}
        iconActive={IconApplicationActive}
        iconProps={{
          height: 32,
          margin: 10,
          width: 32,
        }}
        label="Lead"
        section="lead"
        {...props}
      >
        {children}
      </MobileBottomMenuLink>

      <MobileBottomMenuLink
        currentUser={currentUser}
        icon={IconNotes}
        iconActive={IconNotesActive}
        label="Notes"
        section="notes"
        {...props}
      >
        {children}
      </MobileBottomMenuLink>

      <MobileBottomMenuLink
        currentUser={currentUser}
        icon={IconDeleteArchive}
        iconActive={IconDeleteArchiveActive}
        label="Archive"
        section="archive"
        {...props}
      >
        {children}
      </MobileBottomMenuLink>
    </div>
  );
}

function Review(props) {
  const { dispatch, lead, isLoading, params } = props;
  const [selectedNoteCategory, setSelectedNoteCategory] = useState("customer");
  const [visibleSection, setVisibleSection] = useState("details");
  const { isMobileScreen } = useIsMobileScreenState(
    MEDIA_QUERIES.max_width_991,
  );

  function onFetchLeadDetails() {
    return dispatch(loadLeadDetails(params.leadId));
  }

  useEffect(onFetchLeadDetails, [params.leadId]);

  const processedProps = {
    ...props,
    onFetchApplicationRecord: onFetchLeadDetails,
  }

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <MobileScreenContext.Provider value={{ isMobileScreen }}>
        <BrowserRouter>
          <div className={styles.container}>
            <Topbar
              visibleSection={visibleSection}
              setVisibleSection={setVisibleSection}
            />

            <div className={styles.sidebar}>
              <SidebarApplicationInfo
                isLoading={isLoading}
                {...processedProps}
              />
              <SidebarApplicationSections
                isLoading={isLoading}
                visibleSection={visibleSection}
                setVisibleSection={setVisibleSection}
                {...processedProps}
              />
            </div>
            <VisibleComponent
              isLoading={isLoading}
              lead={lead}
              selectedNoteCategory={selectedNoteCategory}
              setSelectedNoteCategory={setSelectedNoteCategory}
              visibleSection={visibleSection}
              setVisibleSection={setVisibleSection}
              {...processedProps}
            />
          </div>
          <MobileBottomMenu
            setVisibleSection={setVisibleSection}
            visibleSection={visibleSection}
            {...props}
          />
        </BrowserRouter>
      </MobileScreenContext.Provider>
    </MuiThemeProvider>
  );
}

export default connect(state => {
  const reviewLead = state.leads.review_lead || {};
  const lead = new LeadModel(reviewLead.data || {}, reviewLead.included || []);

  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
    isLoading: state.leads.review_lead_loading || !lead,
    lead,
  };
})(Review);
