import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Button from "modules/shared/components/inputs/Button";
import ExtraDescription from "modules/shared/components/text/ExtraDescription";
import EditableFileWidget from "modules/shared/components/widgets/interactive/EditableFileWidget";
import CircleLogo from "modules/shared/components/widgets/static/CircleLogo";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import {
  isFeatureEditEnabled,
  isHeadquarter,
} from "modules/shared/helpers/headquarterDetect";
import React, { Component } from "react";
import { connect } from "react-redux";

import { showPricingPlan } from "../../pricing-plans/actions";
import { setEntitySettingsTempLogo, updateEntityLogoData } from "../actions";
import styles from "./css/CommonEditProfile.css";

class Logo extends Component {
  submit = () => {
    const { dispatch, tmp_logo } = this.props;
    dispatch(updateEntityLogoData({ logo: tmp_logo }));
  };

  handleChange = event => {
    const { dispatch } = this.props;
    dispatch(setEntitySettingsFormValue(event.target.name, event.target.value));
  };

  logoReady = data => {
    const { dispatch, current_pricing_plan } = this.props;
    if (current_pricing_plan) {
      if (current_pricing_plan.attributes.personalised_branding) {
        dispatch(setEntitySettingsTempLogo(data));
        dispatch(updateEntityLogoData({ logo: data }));
        // this.submit();
      }
    }
  };

  handlePricingPlan = () => {
    const { dispatch, current_pricing_plan } = this.props;
    if (current_pricing_plan) {
      if (!current_pricing_plan.attributes.personalised_branding) {
        dispatch(showPricingPlan(true, "others"));
      }
    }
  };

  render() {
    const {
      title,
      update_text,
      help_text,
      logo_src,
      tmp_logo,
      changes_made,
      current_pricing_plan,
      isBranch,
    } = this.props;

    let completeWidget = null;
    let showPricingPlan = false;

    if (tmp_logo || logo_src) {
      completeWidget = (
        <CircleLogo
          src={tmp_logo || logo_src}
          alt="Your logo"
          layout="default_background_position"
        />
      );
    }

    let loader = null;
    let update_button_text = update_text;
    let update_click = this.submit;

    if (this.props.update_loading) {
      loader = <span className={styles.loading}></span>;
      update_button_text = "saving";
      update_click = event => {};
    }

    let update_button = null;
    if (changes_made) {
      update_button = (
        <div className={styles.button}>
          <Button handleClick={update_click} text={update_button_text} />
          {loader}
        </div>
      );
    }

    if (current_pricing_plan) {
      if (!current_pricing_plan.attributes.personalised_branding) {
        showPricingPlan = true;
      }
    }

    return (
      <MuiThemeProvider theme={muiTheme()}>
        <div className={styles.row}>
          <div className={styles.block}>
            <div className={styles.liner}>
              <h2 className={styles.header}>{title}</h2>
              <ExtraDescription
                hintName={"headquarterSettingHint"}
                show={isHeadquarter()}
              />
              {isFeatureEditEnabled("Business") && update_button}
              <div className={styles.itemsWide}>
                <EditableFileWidget
                  completeWidget={completeWidget}
                  disableChangeButton={!isFeatureEditEnabled("Business")}
                  fileSrc={tmp_logo || logo_src}
                  text={
                    isBranch
                      ? "Not allowed to upload/edit logo here"
                      : "Drag logo here"
                  }
                  isEditable={true}
                  handleFileCallback={this.logoReady}
                  HandlePricingPlan={this.handlePricingPlan}
                  showPricingPlan={showPricingPlan}
                />
              </div>
            </div>
          </div>
          <div className={styles.help}>
            <div
              className={styles.help_info}
              dangerouslySetInnerHTML={{ __html: help_text }}
            ></div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const defaults = {
  help_text: "Ideally a minimum of 210 px by 210 px",
  title: "Logo",
  update_text: "upload new logo",
};

export default connect(state => {
  const ent = state.manage_profile.current_entity.attributes;
  const s = state.manage_profile;
  const logo_src = s.settings_logo_src || (ent.logo ? ent.logo.url : null);
  const tmp_logo = s.settings_temp_logo_src || (ent.logo ? ent.logo.url : null);
  const changes_made =
    s.settings_section_edited != null
      ? s.settings_section_edited["logo"]
      : false;

  return {
    changes_made,
    current_pricing_plan: state.pricing_plans.current_pricing_plan,
    help_text: defaults.help_text,
    logo_src,
    title: defaults.title,
    tmp_logo,
    update_loading: s.settings_logo_update_loading,
    update_text: defaults.update_text,
    isBranch: ent.type === "Branch",
  };
})(Logo);
