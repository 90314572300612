import React from "react";

import styles from "./css/ConnectionInformation.css";

import Button from "../../shared/components/inputs/Button";

var ConnectionInformation = createClass({
  render: function() {
    const { connection, isSupplier, consumerRelationType } = this.props;
    let business_location,
      physical_address,
      contact_phone_number,
      postal_address,
      col_1,
      col_2,
      error;

    if (connection.application.attributes.region) {
      let locationDetails = "";
      if (connection.application.attributes.region === "NZ") {
        locationDetails = "New Zealand";
      } else if (connection.application.attributes.region === "AU") {
        locationDetails =
          "Australia, " + connection.application.attributes.state;
      }

      business_location = (
        <div>
          <h2 className={styles.heading}>Business location</h2>
          <p className={styles.details}>{locationDetails}</p>
        </div>
      );
    }

    if (connection.application.attributes.full_physical_address) {
      physical_address = (
        <div>
          <h2 className={styles.heading}>Physical address</h2>
          <p className={styles.details}>
            {connection.application.attributes.full_physical_address}
          </p>
        </div>
      );
    }

    if (connection.application.attributes.contact_phone_number) {
      contact_phone_number = (
        <div>
          <h2 className={styles.heading}>Business Phone number</h2>
          <p className={styles.details}>
            {connection.application.attributes.contact_phone_number}
          </p>
        </div>
      );
    }

    if (business_location || physical_address || contact_phone_number) {
      col_1 = (
        <div className={styles.col}>
          {business_location}
          {physical_address}
          {contact_phone_number}
        </div>
      );
    }

    if (connection.application.attributes.full_postal_address) {
      postal_address = (
        <div>
          <h2 className={styles.heading}>Postal address</h2>
          <p className={styles.details}>
            {connection.application.attributes.full_postal_address}
          </p>
        </div>
      );
    }

    if (postal_address) {
      col_2 = <div className={styles.col}>{postal_address}</div>;
    }

    if (col_1 == null && col_2 == null) {
      error = (
        <div className={styles.error}>
          No {consumerRelationType ? "consumer" : "supplier"} information
          available.
        </div>
      );
    }

    return (
      <div className={styles.tab_content}>
        {col_1}
        {col_2}
        {error}
      </div>
    );
  },
});

module.exports = ConnectionInformation;
