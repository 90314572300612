import * as CollateralAdditionalForms from "modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/NZPPSRCollateralAdditionalForms/CollateralsAdditionalForms";
import React, { ReactElement } from "react";

const NZPPSRCollateralAdditionalForms = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}): ReactElement | null => {
  const { collateralType } = props;

  const FormComponent =
    CollateralAdditionalForms[`${collateralType}CollateralDetailsForm`];

  if (FormComponent) {
    return <FormComponent {...props} />;
  }

  return null;
};

export default NZPPSRCollateralAdditionalForms;
