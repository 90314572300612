/* Import libraries */
import React from "react";

import styles from "./css/DonutChart.css";

var DonutChart = createClass({
  getInitialState: function() {
    return {
      valuelabel: "Overall total", // label for the chart
      size: 174, // diameter of chart
      strokewidth: 20, // width of chart line
    };
  },

  render: function() {
    const { parties } = this.props;

    const halfsize = this.state.size * 0.5;
    const radius = halfsize - this.state.strokewidth * 0.5;
    const circumference = 2 * Math.PI * radius;

    const trackstyle = { strokeWidth: this.state.strokewidth };
    const rotateval = "rotate(-90 " + halfsize + "," + halfsize + ")";

    var children = new Array();
    var total_percent = 0;

    Object.keys(parties).forEach((item, index) => {
      var percent = "";
      if (
        parties[item].hasOwnProperty("percentage_share") &&
        parties[item].percentage_share
      ) {
        percent = parties[item].percentage_share;

        const strokeval = (percent * circumference) / 100;
        const dashval = strokeval + " " + circumference;
        const indicatorstyle = {
          strokeWidth: this.state.strokewidth,
          strokeDasharray: dashval,
          stroke: parties[item].color,
        };
        const rotate_start = -90 + (total_percent * 360) / 100;
        const parties_rotateval =
          "rotate(" + rotate_start + " " + halfsize + "," + halfsize + ")";

        children.push(
          <circle
            key={`parties-${index}`}
            r={radius}
            cx={halfsize}
            cy={halfsize}
            transform={parties_rotateval}
            style={indicatorstyle}
            className={styles.donutchart_indicator}
          />,
        );

        total_percent += parseInt(percent);
      }
    });

    var percentage_styles = styles.donutchart_text_val;

    if (total_percent < 100) {
      percentage_styles = styles.donutchart_text_val_low;
    } else if (total_percent > 100) {
      percentage_styles = styles.donutchart_text_val_error;
    }

    return (
      <svg
        width={this.state.size}
        height={this.state.size}
        className={styles.donutchart}
      >
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={trackstyle}
          className={styles.donutchart_track}
        />
        {children}
        <text
          className={styles.donutchart_text}
          x={halfsize}
          y={halfsize + 10}
          style={{ textAnchor: "middle" }}
        >
          <tspan className={percentage_styles}>{total_percent}</tspan>
          <tspan className={styles.donutchart_text_percent}>%</tspan>
          <tspan
            className={styles.donutchart_text_label}
            x={halfsize}
            y={halfsize + 30}
          >
            {this.state.valuelabel}
          </tspan>
        </text>
      </svg>
    );
  },
});

export default DonutChart;
