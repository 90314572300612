/* Components */
import { useEffect } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

import { userLogout } from "../actions";

function Logout(props) {
  useEffect(() => {
    const { dispatch, location: { state: locationState = {} } } = props;

    dispatch(
      userLogout({
        postLogoutCallback: () => {
          browserHistory.push({
            pathname: "/user/login",
            query: location.query,
            state: {
              referrer: "/user/logout",
            },
          });
        },
        ...locationState,
      }),
    );

    localStorage.setItem("clearSessionStorage", true);
    localStorage.removeItem("clearSessionStorage");
  }, []);

  return null;
}

export default connect()(Logout);
