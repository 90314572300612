/* Import libraries */
import React from "react";
import { Link } from "react-router";

/* Import styles */
import styles from "./css/HelpButton.css";

import Icon from "modules/shared/components/svg/Icon";

var HelpButton = createClass({
  render: function() {
    const { link, text, type, handleClick, ...rest } = this.props;
    var style = styles.button;

    if (handleClick) {
      return (
        <div className={styles.break}>
          <button className={style} onClick={handleClick} {...rest}>
            <Icon icon="help" />
          </button>
        </div>
      );
    } else if (type === "submit" || type === "button") {
      return (
        <div className={styles.break}>
          <button className={style} type={type} {...rest}>
            <Icon icon="help" />
          </button>
        </div>
      );
    } else {
      return (
        <div className={styles.break}>
          <Link className={style} to={link} {...rest}>
            <Icon icon="help" />
          </Link>
        </div>
      );
    }
  },
});

module.exports = HelpButton;
