/* Import libraries */
import React from "react";

/* Import components */
import Clickable from "modules/shared/components/widgets/interactive/Clickable";
/* Import CSS */
import styles from "../css/DashboardContainer.css";

var SubMenuItem = createClass({
  render: function() {
    const {
      clickHandler,
      title,
      id,
      target,
      css_style,
      visible,
      hiddenxs,
      tip_name,
      tip_css_style,
    } = this.props;
    if (visible === false) {
      return null;
    }
    let style = styles.menu_link;

    if (css_style) {
      style = styles[css_style];
    }

    if (hiddenxs) {
      style = `${style} ${styles.hidden_xs}`;
    }
    return (
      <Clickable
        key={id}
        target={target}
        tip_name={tip_name}
        tip_css_style={tip_css_style}
      >
        <div className={style} id={id}>
          {title}
        </div>
      </Clickable>
    );
  },
});

module.exports = SubMenuItem;
