import Radiobox from "modules/shared/components/inputs/Radiobox";
import React, { ReactElement } from "react"

interface Props {
  disabled?: boolean,
  error?: boolean,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  helperText?: string,
  value?: number|string,
}

const categoryOptions = [
  {
    description: "The system will prompt your customer to take a photo of\
      their drivers licence or passport, where you will have the ability to\
      manually verify. The IP address and email of the user will be captured\
      in the eSignature section of the virtual credit file.",
    label: "Cat.1 Manual verification, eSignature (free)",
    value: "1",
  },
  {
    description: "The system will prompt your customer to take a photo of\
      their drivers licence or passport, which will be validated through the\
      appropriate local governing body. The IP address and email of the user\
      will be captured in the eSignature section of the Virtual Credit File.",
    label: "Cat.2 Digital verification, eSignature ($2.45 per signatory)",
    value: "2",
  },
  {
    description: "In addition to Cat.2, the system will prompt your customer\
      to take a front-facing selfie, which will be compared to the verified ID,\
      which will then produce a 'Similarity Score' that will display in the\
      Virtual Credit File.",
    label: "Cat.3 Digital verification, eSignature, FaceMatch ($3.10 per signatory)",
    value: "3",
  },
  {
    description: "In addition to Cat.2, the system will prompt your customer\
      to perform three different facial movements for proof of life analysis,\
      e.g. blinking(partial biometrics). The information captured will be\
      compared to the verified ID, then produce a 'Similarity Score' that will\
      display in the Virtual Credit File.",
    disabled: true,
    label: "Cat.4 Digital verification, eSignature, Proof of life ($3.90 per signatory) (Coming soon)",
    value: "4",
  },
]

function CategoryRadiobox({
  disabled,
  error,
  handleChange,
  helperText,
  value,
}: Props): ReactElement {
  return (
    <Radiobox
      id="category"
      error={error}
      radioList={categoryOptions}
      disabled={disabled}
      handleChange={handleChange}
      display="row"
      helper_text={helperText}
      value={value}
    />
  )
}

export default CategoryRadiobox
