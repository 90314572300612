/* Import libraries */
import React from "react";
import Helmet from "react-helmet";
import { hot } from "react-hot-loader";
import { isMobile } from "modules/shared/helpers/mobileDetect";
import ErrorBoundary from "containers/ErrorBoundary";

import Loader from "../modules/loader";
import Messages from "../modules/messages/Messages";

/* Import CSS */
import styles from "./css/Layout.css";
import ShareTheLoveModal from "modules/ShareTheLove/modal";

let Layout = createClass({
  render: function() {
    const { children, location } = this.props;

    return (
      <ErrorBoundary>
        <div className={styles.layout}>
          <Helmet
            defaultTitle="1Centre"
            titleTemplate="1Centre - %s"
            title="Welcome"
          />
          <Messages />
          {children}
        </div>

        {!isMobile() && location.pathname !== "/share-the-love" && (
          <ShareTheLoveModal />
        )}
      </ErrorBoundary>
    );
  },
});

module.exports = hot(module)(Layout);
