import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import TopLeft from "containers/position/TopLeft";
import BottomLeft from "containers/position/BottomLeft";

import CircleLogo from "modules/shared/components/widgets/static/CircleLogo";

/* Import CSS */
import styles from "./css/ApplicationOverlay.css";

const ApplicationOverlay = props => {
  const { section_title, trading_name, logo_url, label, color } = props;
  var header_style = styles.header;
  if (label) {
    var main_section = (
      <li
        className={
          color === "white"
            ? styles.main_section + " " + styles.white
            : styles.main_section
        }
      >
        {label}
      </li>
    );
  }

  if (section_title) {
    var sub_section = <li className={styles.sub_section}>{section_title}</li>;
  }
  if (logo_url) {
    var logo = (
      <div className={styles.logo}>
        <CircleLogo layout="application" src={logo_url} alt="Your logo" />
      </div>
    );
  }

  if (color === "white") {
    header_style = styles.header_white;
  }

  if (color === "grey") {
    header_style = styles.header_grey;
  }

  if (color === "xlight") {
    header_style = styles.header_xlight;
  }

  return (
    <div className={header_style}>
      <div
        className={
          color === "white" ? styles.navbar + " " + styles.white : styles.navbar
        }
        role="navigation"
      >
        <div className={styles.nav}>
          {logo}
          <div className={styles.content}>
            <ul>
              <li className={styles.company_name}>{trading_name}</li>
              {main_section}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationOverlay;
