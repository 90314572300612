import styled from "styled-components";
import { FONT_SETS, FONT_SIZES } from "variables/typography";

export const Wrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

export const Header = styled.h2`
  ${FONT_SETS.sectionHeader};
  margin-bottom: 0.875rem;
  margin-top: 2.5rem;
`;

export const Description = styled.div`
  ${FONT_SETS.description};
`;
