import React from "react";
import ReviewFormComponentRender from "./ReviewFormComponentRender";
import { connect } from "react-redux";
import styles from "./css/FormBuilder.css";

class ReviewFromRender extends React.Component {
  render() {
    const { answers, components } = this.props;
    const elements = [];
    components.forEach((component, index) => {
      const answer = (answers || []).find(answer => {
        if (component.field_description) {
          return answer.description === component.field_description;
        }
        return (
          answer.description_1 === component.field_description_1 &&
          answer.description_2 === component.field_description_2
        );
      });
      const style = ["pair_question", "section_title"].includes(
        component.component_type,
      )
        ? "full_width"
        : "half_width";
      elements.push(
        <div className={styles[style]}>
          <ReviewFormComponentRender
            key={`component-${index}`}
            name={`component-${index}`}
            formParams={component}
            answer={answer}
          />
        </div>,
      );
    });

    return <div>{elements}</div>;
  }
}

module.exports = connect(() => {
  return {};
})(ReviewFromRender);
