import capitalize from "lodash.capitalize";
import styles from "modules/new-applications/css/StatusIndicator.css";
import React from "react";
import isPresent from "utils/isPresent";

function getStatusDisplayName({ status, statusName }) {
  if (isPresent(statusName)) {
    return statusName;
  }

  return capitalize(status ? status.split("_").join(" ") : "")
}

function StatusName(props) {
  const { status, statusName, withStatusName } = props;

  if (!withStatusName) {
    return null;
  }

  if (status === "not_started") {
    return <span className={styles.status_name}>Not started</span>;
  }

  return (
    <span className={styles.status_name}>
      {getStatusDisplayName({ status, statusName })}
    </span>
  );
}

function dotStyle(props) {
  const { status, withStatusName } = props;

  if (withStatusName) {
    return styles[status];
  }

  return styles[`${status}_simple`];
}

export default function StatusIndicator(props) {
  return (
    <span className={dotStyle(props)}>
      <span className={styles.dot}></span> <StatusName {...props} />
    </span>
  );
}

StatusIndicator.defaultProps = {
  withStatusName: false,
};
