/* eslint-disable max-lines */
import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import CancelIcon from "@material-ui/icons/Cancel";
import { PENDING_STATUS } from "modules/new-applications/components/Application";
import {
  AUTO_DECISIONS_OPTIONS,
  ENTITY_TYPE_OPTIONS,
  LEAD_ENTITY_TYPE_OPTIONS,
  LOADED_IN_SYSTEM_OPTIONS,
} from "modules/reporting/constants";
import BorderedAutocomplete from "modules/shared/components/inputs/BorderedAutocomplete";
import BorderedPeriodSelect from "modules/shared/components/inputs/BorderedPeriodSelect";
import BorderedRegionStatesSelect from "modules/shared/components/inputs/BorderedRegionStatesSelect";
import BorderedSelect from "modules/shared/components/inputs/BorderedSelect";
import BorderedTextField from "modules/shared/components/inputs/BorderedTextField";
import Switch from "modules/shared/components/widgets/interactive/Switch";
import { isHeadquarter } from "modules/shared/helpers/headquarterDetect";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";

import styles from "./css/useFilterState.css";

export const drawerStyles = makeStyles(() => {
  return {
    paper: {
      paddingTop: "66px",
      width: "270px",
    },
  };
});

const INITIATOR_HQ_OPTIONS = [
  { label: "All", value: "All" },
  { label: "Website button", value: "website_button" },
  { label: "QR code", value: "qr_code" },
  { label: "Supplier user", value: "supplier_user" },
];

function AutocompleteFilter(props) {
  const { label, name, options, onChangeFilterParams, value } = props;
  const selectedOption = options.find(option => option.value === value);

  const onChange = (_, selected) => {
    onChangeFilterParams({ [name]: selected.value });
  };

  return (
    <BorderedAutocomplete
      textFieldProps={{
        label,
      }}
      options={options}
      value={selectedOption}
      onChange={onChange}
    />
  );
}

export function Filter(props) {
  const {
    filterParams,
    isStatesOptionsVisible,
    onChangeFilterParams,
    name,
  } = props;
  const { label, options, rawOptions, type, visible } = props.filterConfig;

  if (!visible) {
    return <Fragment />;
  }

  switch (type) {
    case "autocomplete":
      return (
        <AutocompleteFilter
          label={label}
          name={name}
          onChangeFilterParams={onChangeFilterParams}
          options={options}
          value={filterParams[name]}
        />
      );
    case "dropdown":
      return (
        <BorderedSelect
          label={label}
          name={name}
          value={filterParams[name]}
          options={options}
          rawOptions={rawOptions}
          onChange={res => onChangeFilterParams({ [name]: res.target.value })}
          MenuProps={{
            style: {
              maxHeight: 300,
            },
          }}
        />
      );
    case "date_picker":
      return (
        <BorderedPeriodSelect
          label={label}
          name={name}
          MenuProps={{
            style: {
              maxHeight: 300,
            },
          }}
          periodValue={filterParams[name]}
          isFutureDate={props.filterConfig.isFutureDate}
          periodFromToValues={{
            from: filterParams[`${name}_from`],
            to: filterParams[`${name}_to`],
          }}
          handleChange={res => {
            onChangeFilterParams({
              [name]: res.value,
              [`${name}_from`]: res.from,
              [`${name}_to`]: res.to,
            });
          }}
        />
      );
    case "input":
      return (
        <BorderedTextField
          name={name}
          label={label}
          value={filterParams[name]}
          onChange={event =>
            onChangeFilterParams({ [name]: event.target.value })
          }
        />
      );
    case "region_select":
      return (
        <BorderedRegionStatesSelect
          label={"Business location"}
          values={{ region: filterParams.region, state: filterParams.state }}
          isStatesOptionsVisible={isStatesOptionsVisible}
          handleChange={res => onChangeFilterParams(res)}
        />
      );
  }
}

function LimitSwitch(props) {
  const { filterParams, filterType, onChangeFilterParams } = props;

  if (filterType === "application") {
    return (
      <Switch
        leftOption="requested_limits"
        rightOption="approved_limits"
        leftLabel="Requested limits"
        rightLabel="Approved limits"
        current={filterParams.limit_type}
        handleChange={value => onChangeFilterParams({ limit_type: value })}
        grey={true}
      />
    );
  }

  return null;
}

function LimitRange(props) {
  const { filterParams, filterType, onChangeFilterParams } = props;

  if (filterType === "application") {
    return (
      <div className={styles.limits}>
        <span className={styles.limit_text}>Limit range</span>
        <div className={styles.limit_inputs}>
          <BorderedTextField
            label={"min"}
            value={filterParams.limit_min}
            onChange={event =>
              onChangeFilterParams({ limit_min: event.target.value })
            }
          />
        </div>
        <span className={styles.limit_text}>to</span>
        <div className={styles.limit_inputs}>
          <BorderedTextField
            label={"max"}
            value={filterParams.limit_max}
            onChange={event =>
              onChangeFilterParams({ limit_max: event.target.value })
            }
          />
        </div>
      </div>
    );
  }

  return null;
}

function getFiltersByType({
  alertTypeOptions,
  approverOptions,
  branchOptions,
  filterType,
  pendingStatusOptions,
  untaggedApplicationOptions,
  userOptions,
}) {
  if (filterType === "application") {
    return [
      {
        key: "start_date",
        label: "Start date",
        type: "date_picker",
        visible: true,
      },
      {
        key: "pending_state",
        label: "Pending status",
        options: pendingStatusOptions,
        type: "dropdown",
        visible: true,
      },
      {
        key: "branch",
        label: "Tier",
        options: branchOptions,
        type: "dropdown",
        visible: isHeadquarter(),
      },
      {
        key: "initiator_hq",
        label: "Initiated by",
        options: INITIATOR_HQ_OPTIONS,
        type: "dropdown",
        visible: isHeadquarter(),
      },
      {
        key: "initiator",
        label: "Initiated by",
        options: userOptions,
        type: "dropdown",
        visible: !isHeadquarter(),
      },
      {
        key: "owner",
        label: "Owner",
        options: userOptions,
        type: "dropdown",
        visible: !isHeadquarter(),
      },
      {
        key: "approver",
        label: "Approver",
        options: approverOptions,
        type: "dropdown",
        visible: true,
      },
      {
        key: "auto_decision",
        label: "Auto decisions",
        options: AUTO_DECISIONS_OPTIONS,
        type: "dropdown",
        visible: true,
      },
      {
        key: "untagged",
        label: "Untagged application",
        options: untaggedApplicationOptions,
        type: "dropdown",
        visible: true,
      },
      {
        isFutureDate: true,
        key: "review_date",
        label: "Review date",
        type: "date_picker",
        visible: true,
      },
      {
        key: "entity_type",
        label: "Entity type",
        options: ENTITY_TYPE_OPTIONS,
        type: "dropdown",
        visible: true,
      },
      {
        key: "location",
        label: "Business location",
        type: "region_select",
        visible: true,
      },
      {
        key: "loaded_in_system",
        label: "Loaded in system",
        options: LOADED_IN_SYSTEM_OPTIONS,
        type: "dropdown",
        visible: true,
      },
    ];
  }

  if (filterType === "lead") {
    return [
      {
        key: "start_date",
        label: "Start date",
        type: "date_picker",
        visible: true,
      },
      {
        key: isHeadquarter() ? "branch" : "initiator",
        label: "Initiated by",
        options: isHeadquarter() ? branchOptions : userOptions,
        type: "dropdown",
        visible: true,
      },
      {
        key: "entity_type",
        label: "Entity type",
        options: LEAD_ENTITY_TYPE_OPTIONS,
        type: "dropdown",
        visible: true,
      },
      {
        key: "location",
        label: "Business location",
        type: "region_select",
        visible: true,
      },
    ];
  }

  // TODO - Refer to task/7277 to utilize passedFilters props
  // instead adding more conditional rendering statements
  if (filterType === "alerts") {
    return [
      {
        key: "alert_type",
        label: "Alert",
        options: alertTypeOptions,
        type: "dropdown",
        visible: true,
      },
      {
        key: "alert_date",
        label: "Alert date",
        type: "date_picker",
        visible: true,
      },
    ];
  }

  return [];
}

function FilterList(props) {
  const {
    dynamicFilterOptions,
    filterParams,
    filterType,
    isOpen,
    isStatesOptionsVisible,
    onChangeFilterParams,
    onToggleIsOpen,
    // TODO - We will refactor the rest of the filters eventually to adapt passFilter
    passedFilters,
    reset,
  } = props;

  const approverOptions = [{ label: "All", value: "All" }].concat(
    dynamicFilterOptions.approver_options || [],
  );

  const branchOptions = [{ label: "All", value: "All" }].concat(
    dynamicFilterOptions.branch_options || [],
  );

  const untaggedApplicationOptions = [{ label: "All", value: "All" }].concat(
    dynamicFilterOptions.approval_hierarchy_levels || [],
  );

  const alertTypeOptions = [{ label: "All", value: "All" }].concat(
    dynamicFilterOptions.alert_type || [],
  );

  const pendingStatusOptions = [
    { label: "All", value: "All" },
    { label: "No pending status", value: "no_pending" },
    ...PENDING_STATUS,
  ];

  const userOptions = [{ label: "All", value: "All" }].concat(
    dynamicFilterOptions.user_options || [],
  );

  const filters = passedFilters || getFiltersByType({
    alertTypeOptions,
    approverOptions,
    branchOptions,
    filterType,
    pendingStatusOptions,
    untaggedApplicationOptions,
    userOptions,
  });

  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      variant="persistent"
      classes={drawerStyles()}
    >
      <div className={styles.header}>
        <CancelIcon className={styles.cancel_icon} onClick={onToggleIsOpen} />
        <div className={styles.reset_button} onClick={reset}>
          reset
        </div>
      </div>
      <div className={styles.filters}>
        <LimitSwitch
          filterParams={filterParams}
          filterType={filterType}
          onChangeFilterParams={onChangeFilterParams}
        />
        <LimitRange
          filterParams={filterParams}
          filterType={filterType}
          onChangeFilterParams={onChangeFilterParams}
        />
        {filters.map(filter => (
          <Filter
            key={filter.key}
            name={filter.key}
            filterConfig={filter}
            filterParams={filterParams}
            isStatesOptionsVisible={isStatesOptionsVisible}
            onChangeFilterParams={onChangeFilterParams}
          />
        ))}
      </div>
    </Drawer>
  );
}

FilterList.defaultProps = {
  filterType: "application",
  isStatesOptionsVisible: true,
};

FilterList.propTypes = {
  filterType: PropTypes.oneOf(["application", "lead", "alerts"]),
};

const defaultValues = {
  approver: "All",
  auto_decision: "All",
  branch: "All",
  collateral_type: "All",
  entity_type: "All",
  expiry_date: "All",
  initiator: "All",
  initiator_hq: "All",
  limit_max: "",
  limit_min: "",
  limit_type: "requested_limits",
  loaded_in_system: "All",
  owner: "All",
  pending_state: "All",
  region: "All",
  review_date: "All",
  start_date: "All",
  state: "All",
  untagged: "All",
};

const defaultAlertsValues = {
  alert_date: "All",
  alert_type: "All",
  status: "started",
}

// TODO: This file needs to be updated to use consider
// different defaultValues from different type of index
function getDefaultValues(filterType) {
  if (filterType === "alerts") {
    return defaultAlertsValues;
  }

  return defaultValues;
}

const validKeys = [
  "start_date_from",
  "start_date_to",
  "review_date_from",
  "review_date_to",
  ...Object.keys(defaultValues),
];

function getValidKeys(filterType) {
  if (filterType === "alerts") {
    return [...Object.keys(defaultAlertsValues)]
  }

  return validKeys;
}

const keysFilter = params => {
  const res = {};
  Object.keys(params).forEach(key => {
    if (getValidKeys(params.source_page).includes(key)) {
      res[key] = params[key];
    }
  });
  return res;
};

export default function useFilterState(searchParams) {
  const [isOpen, setIsOpen] = useState(false);
  const [filterParams, setfilterParams] = useState({
    ...getDefaultValues(searchParams.source_page),
    ...keysFilter(searchParams),
  });

  const onToggleIsOpen = () => setIsOpen(!isOpen);
  const onChangeFilterParams = params => {
    const newParams = { ...filterParams, ...params };
    setfilterParams(newParams);
  };

  const resetFilterParams = () => {
    setfilterParams(getDefaultValues(searchParams.source_page));
  };

  return {
    filter: (
      <FilterList
        isOpen={isOpen}
        reset={resetFilterParams}
        onChangeFilterParams={onChangeFilterParams}
        onToggleIsOpen={onToggleIsOpen}
        filterParams={filterParams}
      />
    ),
    filterParams,
    isOpen,
    onToggleIsOpen,
  };
}
