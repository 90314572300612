import { store } from "index";
import isBlank from "utils/isBlank";

import {
  deleteWebhook,
  getWebhook,
  getWebhooks,
  saveWebhook,
  sendTest,
} from "./actions";

class Webhook {
  constructor(data) {
    const _data = data || this.defaults();
    this.attributes = { id: _data.id, ..._data.attributes };
    this.errors = {};
  }

  defaults() {
    return {
      attributes: {
        api_version: "V2",
        email: "",
        event_type: "application_approved",
        name: "",
        url: "https://",
      },
      id: null,
    };
  }

  isNew() {
    return !this.attributes.id;
  }

  update(attributes) {
    this.attributes = { ...this.attributes, ...attributes };
  }

  isValid() {
    return Object.keys(this.errors).length === 0;
  }

  validate() {
    this.validateUrl();
    this.validateRequiredFields();
  }

  validateUrl() {
    if (!/^(ftp|http|https):\/\/[^ "]+$/.test(this.attributes.url)) {
      this.errors = {
        ...this.errors,
        url: "The app url needs to be a valid url",
      };
      // TODO: refactor this part to remove disabling of eslint
      // eslint-disable-next-line no-negated-condition
    } else if (
      this.attributes.url.match(/^(ftp|http|https):\/\/[^ "]+$/)[1] !== "https"
    ) {
      this.errors = {
        ...this.errors,
        url: "The app url needs to use the https protocol",
      };
    } else {
      const { url, ...errors } = this.errors;
      this.errors = { ...errors };
    }
  }

  validateRequiredFields() {
    if (isBlank(this.attributes.event_type)) {
      this.errors = {
        ...this.errors,
        event_type: "Event type is required",
      };
    } else {
      const { event_type, ...errors } = this.errors;
      this.errors = { ...errors };
    }
  }

  save(success) {
    store.dispatch(saveWebhook(this, success));
  }

  delete(success) {
    store.dispatch(deleteWebhook(this, success));
  }

  sendTest() {
    store.dispatch(sendTest(this));
  }
}

Webhook.parseData = data => {
  const webhooks = [];
  let list = data.data.data;
  if (!Array.isArray(list)) {
    list = [list];
  }
  for (let i = 0, len = list.length; i < len; i++) {
    webhooks.push(new Webhook(list[i]));
  }
  return webhooks;
};

Webhook.loadAll = () => {
  store.dispatch(getWebhooks());
};

Webhook.find = id => {
  store.dispatch(getWebhook(id));
};

Webhook.API_VERSIONS = [
  { label: "1.0.0", value: "V1" },
  { label: "2.0.0", value: "V2" },
]

export default Webhook;
