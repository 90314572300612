import ConsumerWebsitebuttonApplication from "modules/consumer-onboarding/containers/ConsumerWebsitebuttonApplication";
import Layout from "modules/user/containers/Layout";

const ConsumerWebsitebuttonRoute = {
  path: ":entity_id/start",
  component: ConsumerWebsitebuttonApplication,
};

module.exports = {
  path: "websitebutton",
  component: Layout,
  childRoutes: [ConsumerWebsitebuttonRoute],
};
