import AccountLevelForm from 'modules/new-applications/components/application-sections/IUF/IUFEdit/AccountLevelForm'
import SupplierForm from 'modules/new-applications/components/application-sections/IUF/IUFEdit/SupplierForm'
import { FormBuilderRenderParams } from 'modules/shared/components/widgets/interactive/form_builder/types'
import arrayFromNumber from "modules/shared/helpers/arrayFromNumber"
import React, { ReactElement } from 'react'
import { InnerWrapper } from './styles'
import get from 'lodash.get'
import { Skeleton } from "@material-ui/lab";

type Props = {
  answers?: FormBuilderRenderParams[];
  activeConfig?: any;
  onChange: (key: string, params: FormBuilderRenderParams) => void;
  formProps: any;
  loading: boolean;
  visible: boolean;
}

function IUFEdit({
  answers,
  activeConfig,
  onChange,
  formProps,
  loading,
  visible,
}: Props): ReactElement|null {
  if (!visible) {
    return null;
  }

  const { account_level, pricing } = activeConfig;
  const accountLevelAnswers = get(answers, 'account_level.answers', []);

  return (
    <div className="column is-12">
      <InnerWrapper>
        { loading && arrayFromNumber(6, (number) => (
          <Skeleton
            key={`addon-skeleton-${number}`}
            animation="wave"
            className="mb-4"
            height={40}
            variant="rect"
          />
        ))}
        { !loading && <>
          <AccountLevelForm
            answers={accountLevelAnswers}
            attachmentConfig={{}}
            data={account_level}
            onChange={(params) => onChange('account_level', params)}
            file={{}}
            fileError={false}
            fileSrc=""
            handleFileCallback={() => {}}
            {...formProps}
          />
          <SupplierForm
            answers={get(answers, 'pricing.answers', [])}
            label="Pricing"
            data={pricing}
            onChange={(params) => onChange('pricing', params)}
            reduxKey="iufPricing"
            {...formProps}
          />
        </>
      }
      </InnerWrapper>
    </div>
  )
}

export default IUFEdit