import React, { Component } from "react";

import FileWidgetSmall from "modules/shared/components/widgets/interactive/FileWidgetSmall";
import FormHelperText from "@material-ui/core/FormHelperText";
import PDFLogo from "modules/shared/components/widgets/static/PDFLogo";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import styles from "../css/LegalEntityAgreementUploader.css";

const AGREEMENT_FILENAME = {
  partnership: "partnership_agreement.pdf",
  trust: "trust_deed.pdf",
};

const ENABLED_UPLOADER_TITLE = {
  partnership: "Please upload your partnership agreement.",
  trust: "Please upload your trust deed.",
};

const DISABLED_UPLOADER_TITLE = {
  partnership: "Partnership agreement",
  trust: "Trust deed",
};

export function isLegalEntityAgreementUploaderVisible({
  entityType,
  partnershipAgreementUploadVisible,
  trustDeedUploadVisible,
}) {
  return (
    (entityType === "partnership" && partnershipAgreementUploadVisible) ||
    (entityType === "trust" && trustDeedUploadVisible)
  );
}

export default class LegalEntityAgreementUploader extends Component {
  get isVisible() {
    const {
      entityType,
      partnershipAgreementUploadVisible,
      trustDeedUploadVisible,
    } = this.props;

    return isLegalEntityAgreementUploaderVisible({
      entityType,
      partnershipAgreementUploadVisible,
      trustDeedUploadVisible,
    });
  }

  get filename() {
    return AGREEMENT_FILENAME[this.props.entityType];
  }

  get title() {
    if (this.props.disabled) {
      return DISABLED_UPLOADER_TITLE[this.props.entityType];
    }

    return `${ENABLED_UPLOADER_TITLE[this.props.entityType]} (PDF only)`;
  }

  renderCompleteWidget() {
    const { legalEntityAgreement } = this.props;

    if (legalEntityAgreement && legalEntityAgreement.url) {
      return <PDFLogo alt="PDF Upload" logoStyle="small" />;
    }
  }

  renderError() {
    const { error } = this.props;

    if (isBlank(error)) {
      return;
    }

    return (
      <FormHelperText error={true} className={styles.error}>
        {error}
      </FormHelperText>
    );
  }

  render() {
    const { disabled, isLoading, legalEntityAgreement, onUpload } = this.props;

    if (!this.isVisible) {
      return null;
    }

    return (
      <div>
        <FileWidgetSmall
          accept=".pdf"
          completeWidget={this.renderCompleteWidget()}
          filename={this.filename}
          id="legal-entity-agreement"
          key="legal-entity-agreement"
          text="Drag and drop"
          title={this.title}
          loading={isLoading}
          viewSrc={legalEntityAgreement}
          handleFileCallback={onUpload}
          disabled={disabled}
        />
        {this.renderError()}
      </div>
    );
  }
}
