import React from "react";
import IconPDF from "images/svgs/icon-pdf.svg";
import styles from "./css/CircleLogo.css";

function PDFLogo(props) {
  const {
    alt,
    css_style,
    iconSize,
    link,
    logoStyle
  } = props;

  let style = styles.default;

  if (css_style) {
    style = css_style;
  }

  if (logoStyle) {
    style = styles[logoStyle];
  }

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <IconPDF {...iconSize} />
    </a>
  );
}

PDFLogo.defaultProps = {
  iconSize: {
    width: 120,
    height: 150,
  }
}

export default PDFLogo;