import BaseModel from "models/BaseModel";
import objectKeysToCamelCase from "utils/objectKeysToCamelCase";

const COLLATERAL_TYPES_WITH_ITEMS = ["MV"];
const COLLATERAL_TYPES_WITHOUT_DESCRIPTION = ["MV"];

export function collateralTypeHasItems(collateralType) {
  return COLLATERAL_TYPES_WITH_ITEMS.includes(collateralType);
}

function collateralTypeHasNoDescription(collateralType) {
  return COLLATERAL_TYPES_WITHOUT_DESCRIPTION.includes(collateralType);
}

export function collateralTypeHasDescription(collateralType) {
  return !collateralTypeHasNoDescription(collateralType);
}

export default class PpsrFinancingStatementCollateralModel extends BaseModel {
  get formAdditionalDetails() {
    return objectKeysToCamelCase(this.additionalDetails);
  }

  get formValues() {
    return {
      additionalDetails: this.formAdditionalDetails,
      collateralDescription: this.collateralDescription,
      collateralPropertyType: this.collateralPropertyType,
      collateralType: this.collateralType,
      id: this.id,
    };
  }

  get hasCollateralItems() {
    return collateralTypeHasItems(this.collateralType);
  }

  get hasDescription() {
    return collateralTypeHasDescription(this.collateralType);
  }
}
