import api from "api";
import get from "lodash.get";
import BaseModel from "models/BaseModel";
import isBlank from "utils/isBlank";

const VERSION_2 = 2;

export default class VedaCheckModel extends BaseModel {
  static runCreditCheck({ application, creditCheckType, currentUser, person }) {
    let serviceType = "commercial";
    if (creditCheckType === "person") {
      serviceType = "veda_score";
    }

    const attributes = {
      service_type: serviceType,
      status: "created",
    };

    if (serviceType === "veda_score") {
      attributes.authorisation_id = person.authorisationId;
      attributes.signature_id = person.signatureId;
    }

    const creditCheck = new VedaCheckModel({
      attributes,
      type: "veda_checks",
    });

    creditCheck.initiating();
    VedaCheckModel.create({
      application,
      creditCheckType,
      currentUser,
      person,
    });

    return creditCheck;
  }

  static create({ application, creditCheckType, currentUser, person }) {
    const creditCheckAPI = api(
      "credit_checks",
      currentUser.accessToken,
      get(currentUser, "currentEntity.id"),
      { headers: { "Content-Type": "application/json" }, version: "/v2" },
    );

    const attributes = {
      action_type: creditCheckType,
      application_id: application.id,
    };

    if (creditCheckType === "person") {
      attributes.signature_id = person.signatureId;
    }

    creditCheckAPI.create(attributes);
  }

  get isVersion2() {
    return (
      this.version === VERSION_2 ||
      parseInt(get(this.reportData, "version")) === VERSION_2
    );
  }

  get canBeRendered() {
    return ["completed", "errored"].includes(this.status);
  }

  get reportData() {
    return this.data || {};
  }

  get isCompanyCheck() {
    return this.serviceType === "commercial";
  }

  get isLoading() {
    return this.status === "started" || this.isInitiating;
  }

  get isIdentificationCheckSuccess() {
    return isBlank(this.failed) && isBlank(this.identificationCheckErrorFields);
  }

  get identificationCheckOutcome() {
    if (this.isIdentificationCheckSuccess) {
      return "Pass";
    }

    return "Fail";
  }

  get identificationCheckErrorFields() {
    const verifications = this.data.verifications || {};
    const verificationErrors = Object.keys(verifications).filter(key => {
      const verification = verifications[key];

      return verification !== "yes" && verification !== "y";
    });

    if (verificationErrors.length > 0) {
      return verificationErrors;
    }

    if (isBlank(verifications)) {
      return [this.serviceType, "first_name", "family_name", "dob"];
    }

    return [];
  }

  initiating() {
    this.isInitiating = true;
  }

  constructor(data = {}, included = []) {
    super(data, included);
    this.isInitiating = false;
  }
}
