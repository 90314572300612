import Abstract from "./components/Abstract";
import Complete from "./components/Complete";
import Confirm from "./components/Confirm";
import IdentityDetails from "./components/IdentityDetails";
import React from "react";
import Review from "./components/Review";
import Terms from "./components/Terms";

export function componentFactory(section, props = {}) {
  switch (section) {
    case "abstract":
      return <Abstract {...props} />;
    case "review":
      return <Review {...props} />;
    case "identity_details":
      return <IdentityDetails {...props} />;
    case "terms":
      return <Terms {...props} />;
    case "confirm":
      return <Confirm {...props} />;
    case "complete":
      return <Complete {...props} />;
  }
}
