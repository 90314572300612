const trimAllSpaces = (
  str: string | null | undefined,
): string | null | undefined => {
  if (typeof str === "string") {
    return str.replace(/\s/g, "");
  }

  return str;
};

export default trimAllSpaces;
