/* Import libraries */
/* Import components */
import Button from "modules/shared/components/inputs/Button";
import TextInput from "modules/shared/components/inputs/TextInput";
import ApplicationOverlay from "modules/shared/components/top/ApplicationOverlay";
import AutoSuggest from "modules/shared/components/widgets/interactive/AutoSuggest";
import DateField from "modules/shared/components/widgets/interactive/DateField";
import ApplicationProgressDots from "modules/shared/components/widgets/static/ApplicationProgressDots";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import { isMobile } from "modules/shared/helpers/mobileDetect";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { isSanitizedStringEqual } from "utils/sanitizeName";

import {
  lookupAddress,
  selectAddress,
  setAddress,
} from "../../identity/actions";
/* Import actions */
import {
  guarantorLogin,
  loadGuarantorInfo,
  setApproval,
  submitIdentification,
  updateGuarantor,
} from "../actions";
/* Import CSS */
import styles from "./css/Review.css";

const Confirm = createClass({
  approve(bool) {
    const { dispatch, location, need_extra_info, guarantor } = this.props;
    const nextPath = "/guarantor/complete";
    if (this.isComplete()) {
      dispatch(setApproval(bool));

      const updateCall = () => {
        dispatch(
          updateGuarantor(location.query.g, bool, () => {
            browserHistory.push({
              pathname: nextPath,
              query: location.query,
            });
          }),
        );
      };

      if (need_extra_info) {
        const attributes = {
          dob: this.state.dob,
          first_name: this.state.first_name,
          identification_type: "no_identification",
          last_name: this.state.last_name,
          no_identification_reason: "not_required",
        };
        dispatch(submitIdentification(guarantor.id, updateCall, attributes));
      } else {
        updateCall();
      }
    }
  },

  checkValid(id, value, target) {
    const { firstname, lastname, address_api_id } = this.props;
    const { errors } = this.state;

    //This is just a workaround to make dob validation code work.
    const form_values = { dob: this.state.dob };

    switch (id) {
      case "firstname":
        if (!value) {
          this.setState({
            errors: { ...errors, firstname: "You must enter your first name." },
          });
        } else {
          const { ...rest } = errors;
          if (!isSanitizedStringEqual(firstname, value)) {
            this.setState({
              errors: { ...errors, firstname: "Incorrect first name." },
            });
          } else {
            this.setState({
              errors: { ...errors, firstname: "" },
            });
          }
        }
        break;
      case "lastname":
        if (!value) {
          this.setState({
            errors: { ...errors, lastname: "You must enter your last name." },
          });
        } else {
          const { ...rest } = errors;
          if (!isSanitizedStringEqual(lastname, value)) {
            this.setState({
              errors: { ...errors, lastname: "Incorrect last name." },
            });
          } else {
            this.setState({
              errors: { ...errors, lastname: "" },
            });
          }
        }
        break;
      case "personal_address":
        if (!address_api_id) {
          this.setState({
            errors: {
              ...errors,
              personal_address: "Please select a valid address.",
            },
          });
        } else {
          const { personal_address, ...rest } = errors;
          this.setState({
            errors: { ...rest },
          });
        }
        break;
      case "address_api_id":
        if (!address_api_id) {
          this.setState({
            errors: {
              ...errors,
              personal_address: "Please select a valid address.",
            },
          });
        } else {
          const { personal_address, ...rest } = errors;
          this.setState({
            errors: { ...rest },
          });
        }
        break;
      case "dob":
        var d = new Date();
        d.setFullYear(new Date().getFullYear() - 18);

        switch (target.field) {
          case "month":
            if (target.value.month.length < 1 || target.value.month == "0") {
              var dob_errors = errors.dob || {};
              var { month, main, ...dob_errors } = dob_errors;
              this.setState({
                errors: {
                  ...errors,
                  dob: {
                    ...dob_errors,
                    main: "Please enter valid date of birth",
                    month: "Invalid month",
                  },
                },
              });
              return false;
            } else {
              const { dob, ...rest } = errors;
              const { month, day, year, main, ...dob_errors } = dob || {};

              if (
                target.value.month > 0 &&
                form_values.dob.day.length == 2 &&
                form_values.dob.day !== "00" &&
                form_values.dob.year.length == 4 &&
                form_values.dob.year >= new Date().getFullYear() - 100 &&
                form_values.dob.year <= new Date().getFullYear() - 18
              ) {
                if (
                  new Date(
                    form_values.dob.year,
                    target.value.month - 1,
                    form_values.dob.day,
                  ) > d
                ) {
                  this.setState({
                    errors: {
                      ...rest,
                      dob: {
                        day: "Invalid day",
                        main:
                          "Please enter valid date of birth. You Must be over 18.",
                        month: "Invalid month",
                        year: "Invalid year",
                      },
                    },
                  });
                  return false;
                }
                this.setState({
                  errors: { ...rest, dob: { ...dob_errors } },
                });
              } else {
                this.setState({
                  errors: { ...rest, dob: { day, year, ...dob_errors } },
                });
              }
            }
            return true;
          case "day":
            if (target.value.day.length !== 2 || target.value.day == "00") {
              var dob_errors = errors.dob || {};
              var { day, main, ...dob_errors } = dob_errors;
              this.setState({
                errors: {
                  ...errors,
                  dob: {
                    ...dob_errors,
                    day: "Invalid day",
                    main: "Please enter valid date of birth",
                  },
                },
              });
              return false;
            } else {
              const { dob, ...rest } = errors;
              const { month, day, year, main, ...dob_errors } = dob || {};

              if (
                target.value.month > 0 &&
                form_values.dob.day.length == 2 &&
                form_values.dob.day !== "00" &&
                form_values.dob.year.length == 4 &&
                form_values.dob.year >= new Date().getFullYear() - 100 &&
                form_values.dob.year <= new Date().getFullYear() - 18
              ) {
                if (
                  new Date(
                    form_values.dob.year,
                    target.value.month - 1,
                    form_values.dob.day,
                  ) > d
                ) {
                  this.setState({
                    errors: {
                      ...rest,
                      dob: {
                        day: "Invalid day",
                        main:
                          "Please enter valid date of birth. You Must be over 18.",
                        month: "Invalid month",
                        year: "Invalid year",
                      },
                    },
                  });
                  return false;
                }
                this.setState({
                  errors: { ...rest, dob: { ...dob_errors } },
                });
              } else {
                this.setState({
                  errors: { ...rest, dob: { month, year, ...dob_errors } },
                });
              }
            }
            return true;
          case "year":
            if (
              target.value.year.length !== 4 ||
              target.value.year < new Date().getFullYear() - 100 ||
              target.value.year > new Date().getFullYear() - 18
            ) {
              var dob_errors = errors.dob || {};
              var { year, main, ...dob_errors } = dob_errors;
              this.setState({
                errors: {
                  ...errors,
                  dob: {
                    ...dob_errors,
                    main:
                      "Please enter valid date of birth. You Must be over 18.",
                    year: "Invalid year",
                  },
                },
              });
              return false;
            } else {
              const { dob, ...rest } = errors;
              const { month, day, year, main, ...dob_errors } = dob || {};

              if (
                target.value.month > 0 &&
                form_values.dob.day.length == 2 &&
                form_values.dob.day !== "00" &&
                form_values.dob.year.length == 4 &&
                form_values.dob.year >= new Date().getFullYear() - 100 &&
                form_values.dob.year <= new Date().getFullYear() - 18
              ) {
                if (
                  new Date(
                    form_values.dob.year,
                    target.value.month - 1,
                    form_values.dob.day,
                  ) > d
                ) {
                  this.setState({
                    errors: {
                      ...rest,
                      dob: {
                        day: "Invalid day",
                        main:
                          "Please enter valid date of birth. You Must be over 18.",
                        month: "Invalid month",
                        year: "Invalid year",
                      },
                    },
                  });
                  return false;
                }
                this.setState({
                  errors: { ...rest, dob: { ...dob_errors } },
                });
              } else {
                this.setState({
                  errors: { ...rest, dob: { day, month, ...dob_errors } },
                });
              }
            }
            return true;
        }
    }
  },

  componentGetDots() {
    const { guarantor } = this.props;
    if (isMobile() && guarantor) {
      const id_flow = guarantor.application.attributes
        .requires_guarantees_identification_check
        ? 2
        : 0; // guarantor id section
      const mobile_index = 2 + id_flow + 1; // default is review, id_flow and terms
      const mobile_pages = 3 + id_flow; // default 3 is review, terms and confirm

      return (
        <ApplicationProgressDots
          type="horizontal"
          mobile_pages={mobile_pages}
          mobile_index={mobile_index}
        />
      );
    }
  },

  componentWillMount() {
    const {
      dispatch,
      location: {
        query: { g, t },
      },
    } = this.props;
    if (g && t) {
      dispatch(
        guarantorLogin(g, t, () => {
          dispatch(loadGuarantorInfo(g));
        }),
      );
    }
  },

  getInitialState() {
    return {
      dob: {
        day: "",
        month: "",
        year: "",
      },
      errors: {},
      first_name: "",
      last_name: "",
    };
  },

  handleAddressChange(event) {
    const { dispatch } = this.props;
    dispatch(setAddress({ full_address: event.target.value }));
    if (event.target.value.length > 0) {
      event.persist();
      dispatch(lookupAddress(event.target.value));
    }
  },

  handleAddressClick(value) {
    const { dispatch } = this.props;
    dispatch(selectAddress(value));
    this.checkValid("address_api_id", value);
  },

  handleBlur(event) {
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target.id, event.target.value, event.target);
    }
  },

  handleChange(event) {
    const { dispatch } = this.props;
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target.id, event.target.value, event.target);

      switch (event.target.id) {
        case "firstname":
          this.setState({ first_name: event.target.value });
          break;
        case "lastname":
          this.setState({ last_name: event.target.value });
          break;
        case "dob":
          this.setState({ dob: event.target.value });
          break;
      }
    }
  },

  isComplete() {
    const { firstname, lastname, need_extra_info, address_api_id } = this.props;
    const { first_name, last_name, dob } = this.state;
    if (
      firstname.toLowerCase().trim() === first_name.toLowerCase().trim() &&
      lastname.toLowerCase().trim() === last_name.toLowerCase().trim()
    ) {
      if (need_extra_info) {
        const d = new Date();
        d.setFullYear(new Date().getFullYear() - 18);
        if (
          moment(`${dob.month}/${dob.day}/${dob.year}`, "MM/DD/YYYY").format(
            "YYYY-MM-DD",
          ) === "Invalid date" ||
          dob.month.length < 1 ||
          dob.month == 0 ||
          dob.day.length !== 2 ||
          dob.day == "00" ||
          dob.year.length !== 4 ||
          dob.year < new Date().getFullYear() - 100 ||
          dob.year > new Date().getFullYear() - 18 ||
          new Date(dob.year, dob.month - 1, dob.day) > d ||
          !address_api_id
        ) {
          return false;
        }
      }
      return true;
    }
    return false;
  },

  render() {
    const {
      title,
      location: {
        query: { g, t },
      },
      trading_name,
      supplier_logo_url,
      need_extra_info,
      personal_address,
      personal_address_list,
      personal_address_loading,
      address_api_id,
    } = this.props;
    const { errors, first_name, last_name, dob, address } = this.state;

    const acknowledgedElm = (
      <div className={styles.final}>
        <div className={styles.row}>
          <h3 className={styles.confirm_info}>
            If you agree please fill out your first and last name in the fields
            below and press confirm.
          </h3>
          <div className={styles.noflex_col}>
            <TextInput
              id="firstname"
              name="firstname"
              error={errors["firstname"]}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="First name"
              value={first_name}
              required={true}
            />
          </div>
          <div className={styles.noflex_col}>
            <TextInput
              id="lastname"
              name="lastname"
              error={errors["lastname"]}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="Last name"
              value={last_name}
              required={true}
            />
          </div>
        </div>
        <div className={styles.row}>
          {need_extra_info && (
            <div>
              <div className={styles.noflex_col}>
                <DateField
                  id={"dob"}
                  name={"dob"}
                  label="Date of birth"
                  form_values={dob}
                  errors={errors["dob"]}
                  handleChange={this.handleChange}
                  handleBlur={this.handleBlur}
                  hide_label={true}
                  hint_left_bottom={true}
                />
              </div>
              <div className={styles.noflex_col}>
                <AutoSuggest
                  id="personal_address"
                  label="Personal address"
                  value={personal_address}
                  loading={personal_address_loading}
                  suggest_items={personal_address_list}
                  handleChange={this.handleAddressChange}
                  handleClick={this.handleAddressClick}
                  handleBlur={this.handleBlur}
                  error={errors["personal_address"]}
                  required={true}
                  css_class="address_lookup"
                  selected_item={address_api_id ? personal_address : ""}
                  force_focus={true}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.final_buttons}>
          <Button text="no" handleClick={this.approve.bind(null, false)} />
          <Button text="yes" handleClick={this.approve.bind(null, true)} />
        </div>
      </div>
    );

    const color = isMobile() ? "white" : "grey";
    const overlay = (
      <ApplicationOverlay
        logo_url={supplier_logo_url}
        trading_name={trading_name}
        label={"Guarantor approval"}
        color={color}
      />
    );
    const dots = this.componentGetDots();

    return (
      <div className={styles.wrapper}>
        <header className={styles.header}>
          {overlay}
          {dots}
        </header>
        <div className={styles.container}>
          <section className={styles.section}>
            <div className={styles.row}>
              <div className={styles.panel}>
                <PanelTitle text={title} margin_bottom="1em" />
                <h3 className={styles.confirm_info}>
                  In electronically signing the guarantee, you should assume
                  that this is a liability you will be called upon to honour,
                  and you should satisfy yourself that you have the financial
                  means to meet this liability, and are willing to do so. If you
                  are not comfortable with that assumption, you should not
                  consent to the guarantee.
                </h3>
                <h3 className={styles.confirm_info}>
                  We also encourage you to view our 1Centre{" "}
                  <a
                    target="_blank"
                    href="http://www.1centre.com/1Centre%20Privacy%20Policy/"
                  >
                    privacy policy
                  </a>
                  .
                </h3>
              </div>
            </div>
            <div className={styles.row}>
              <div className={`${styles.control} ${styles.pad_bot_60}`}>
                {acknowledgedElm}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  },
});

const defaults = {
  title: "Electronic signature",
};

module.exports = connect((state, ownProps) => {
  let application;
  let identification_check;
  let identity;
  let trading_name;
  let logo_url;
  let region;
  let guarantor;
  identity = state.identity;
  if (state.guarantor.data) {
    guarantor = state.guarantor.data;
    application = guarantor.application;
    trading_name = guarantor.application.attributes.supplier_name;
    if (guarantor.application.attributes.supplier_logo) {
      logo_url = guarantor.application.attributes.supplier_logo.logo.url;
    }
    region = guarantor.supplier.attributes.region;
  }

  return {
    address_api_id: identity.address_api_id,
    application,
    firstname: state.guarantor.data
      ? state.guarantor.data.attributes.first_name
      : "",
    guarantor,
    identification_check:
      application &&
      application.attributes.requires_applicant_identification_check,
    lastname: state.guarantor.data
      ? state.guarantor.data.attributes.last_name
      : "",
    need_extra_info:
      application &&
      !application.attributes.requires_guarantees_identification_check &&
      application.attributes.requires_personal_credit_check,
    personal_address: (identity.address && identity.address.full_address) || "",
    personal_address_list: identity.address_list,
    personal_address_loading: identity.address_loading,
    region,
    requires_credit_check:
      application && application.attributes.requires_credit_check,
    supplier_logo_url: logo_url,
    title: defaults.title,
    trading_name,
  };
})(Confirm);
