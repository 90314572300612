/* Import libraries */
import api from "api";
import { clearNotifications } from "modules/notifications/actions.js";
import NotificationIndicator from "modules/notifications/components/NotificationIndicator";
import Badge from "modules/shared/components/inputs/Badge";
import Icon from "modules/shared/components/svg/Icon";
/* Import components */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import store from "stores/store";

/* Import CSS */
import styles from "../css/DashboardContainer.css";

const MenuItemIndicator = props => {
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);

  // speed test
  //! TODO: replaced with websocket in the future
  const POLL_SPEED = 30000;
  const { dispatch } = props;

  async function getCount() {
    const notifications = api(
      "notifications",
      store.getState().current_user.access_token,
      store.getState().current_user.current_entity.id,
    );
    const result = await notifications.unreads();
    setCount(result.data.count);
  }

  getCount();

  useEffect(() => {
    const interval = setInterval(() => {
      getCount();
    }, POLL_SPEED);

    return () => clearInterval(interval);
  }, []);

  const { title, icon, visible } = props;

  if (visible === false) {
    return null;
  }

  return (
    <li key={`${title}-menu`}>
      <div
        className={styles.link}
        onClick={() => {
          setShow(!show);
          dispatch(clearNotifications());
          getCount();
        }}
      >
        <Icon icon={icon} />
        {parseInt(count) > 0 && <Badge theme="white" text={count} />}
        <span className={styles.link_label}>{title}</span>
        {show && <NotificationIndicator />}
      </div>
    </li>
  );
};

export default connect()(MenuItemIndicator);
