import {
  COMMON_ORGANISATION_VALIDATION_SCHEMA,
  COMMON_PERSON_VALIDATION_SCHEMA,
  validateOnRegister,
} from "modules/new-applications/components/application-actions/PPSRRegister/validationSchema/commonValidationSchema";
import isPresent from "utils/isPresent";
import * as yup from "yup";

// NOTE: These constants are duplicated in the backend as well
export const COLLATERAL_PROCEEDS_TO_BE_CLAIMED_MAX_CHARACTERS = 500;
export const COLLATERAL_TYPE_WITHOUT_DESCRIPTION = ["AllPapNoExcept"];

const ORGANISATION_VALIDATION_SCHEMA = {
  organisationName: COMMON_ORGANISATION_VALIDATION_SCHEMA.organisationName,
  organisationNumber: COMMON_ORGANISATION_VALIDATION_SCHEMA.organisationNumber,
};

const PERSON_VALIDATION_SCHEMA = {
  personDateOfBirth: COMMON_PERSON_VALIDATION_SCHEMA.personDateOfBirth,
  personFirstName: COMMON_PERSON_VALIDATION_SCHEMA.personFirstName,
  personLastName: COMMON_PERSON_VALIDATION_SCHEMA.personLastName,
};

const MAX_EXPIRY_YEARS = 25;
const MAX_CONSUMER_EXPIRY_YEARS = 7;

const getMaximumExpiryDate = numberOfYears => new Date(
  new Date().setFullYear(new Date().getFullYear() + numberOfYears),
);

export function getSevenYearsFromNow() {
  return getMaximumExpiryDate(MAX_CONSUMER_EXPIRY_YEARS);
}

export function getTwentyFiveYearsFromNow() {
  return getMaximumExpiryDate(MAX_EXPIRY_YEARS);
}

function validateExpiryDate(value) {
  const { autoRenew, expiryDuration, status } = this.parent;

  if (autoRenew || status === "draft") {
    return true;
  }

  if (expiryDuration !== "custom") {
    return true;
  }

  return isPresent(value);
}

function validateToBeClaimedDescription(value) {
  const { toBeClaimed } = this.parent;

  if (!toBeClaimed) {
    return true;
  }

  return isPresent(value);
}

function validateCollateralDescription(value) {
  const { collateralType } = this.parent;

  if (COLLATERAL_TYPE_WITHOUT_DESCRIPTION.includes(collateralType)) {
    return true;
  }

  return isPresent(value);
}

function validateExpiryDurationOnRenew(value) {
  if (value === "indefinite") {
    return false;
  }

  return isPresent(value);
}

export default class AUValidationSchema {
  static getFinancingStatementValidationSchema() {
    return {
      autoRenew: yup.boolean(),
      expiryDate: yup
        .string()
        .test("expiry-date", "Please enter expiry date", validateExpiryDate),
      expiryDuration: yup
        .string()
        .test("expiry-duration", "Please enter duration", validateOnRegister),
      registrationReference: yup.string().trim(),
    };
  }

  static getDebtorDetailsValidationSchema(debtorType) {
    if (debtorType === "organisation") {
      return ORGANISATION_VALIDATION_SCHEMA;
    }

    return PERSON_VALIDATION_SCHEMA;
  }

  static getCollateralDetailsValidationSchema() {
    return {
      collateralAdditionalDetails: yup.object().shape({
        inventory: yup.boolean(),
        purchaseMoney: yup.boolean(),
        subjectToControl: yup.boolean(),
        subordinate: yup.boolean(),
        toBeClaimed: yup.boolean(),
        toBeClaimedDescription: yup
          .string()
          .test(
            "to-be-claimed-description",
            "Please enter a description",
            validateToBeClaimedDescription,
          )
          .max(
            COLLATERAL_PROCEEDS_TO_BE_CLAIMED_MAX_CHARACTERS,
            "Please enter a description not more than 500 characters.",
          ),
      }),
      collateralDescription: yup
        .string()
        .trim()
        .test(
          "collateral-description",
          "Please enter a description",
          validateCollateralDescription,
        ),
      collateralPropertyType: yup
        .string()
        .trim()
        .required("Please enter a collateral type."),
      collateralType: yup
        .string()
        .trim()
        .required("Please select a collateral class."),
    };
  }

  static getRenewFinancingStatementValidationSchema() {
    return {
      expiryDate: yup
        .string()
        .test("expiry-date", "Please enter expiry date", validateExpiryDate),
      financingStatementPin: yup
        .string()
        .required("Please provide financing statement pin to renew."),
    };
  }

  static getModifyFinancingStatementValidationSchema() {
    return {
      autoRenew: yup.boolean(),
      expiryDate: yup
        .string()
        .test("expiry-date", "Please enter expiry date.", validateExpiryDate),
    };
  }
}
