import React, { ReactElement, useState } from "react";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";

type User = {
  label: string,
  value: string,
}

type Props = {
  assignKeyContact: (
    recordId: string,
    userId: string,
    role: string,
  ) => void,
  recordId: string,
  role: string,
  users: User[],
}

function ReassignKeyContactForm(props: Props): ReactElement {
  const {
    assignKeyContact,
    recordId,
    role,
    users,
  } = props;

  const [selectedContact, selectContact] = useState("");

  const handleChange = (v: any) => {
    selectContact(v);
    assignKeyContact(recordId, v.value, role);
  };

  return (
    <OptionsDropdown
      handleChange={handleChange}
      label="Select team member"
      value={selectedContact}
      required={true}
      options={users}
      admin={true}
      label_display_blur={true}
    />
  );
}

export default ReassignKeyContactForm;
