import api from "../api";

export function loadingLiveChat(entityType, userAttributes = {}) {
  const isMobile = {
    Android() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any() {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    },
  };
  if (isMobile.any()) {
    return;
  }
  if (userAttributes.id) {
    _rebuildLiveChat(userAttributes, entityType);
  }
  if (window.fcWidget) {
    _setLiveChatDisplay();
  }
}

function _rebuildLiveChat(userAttributes, entityType) {
  if (window.fcWidget) {
    window.fcWidget.destroy();
  }
  window.fcWidget.init({
    token: "b8d0883c-851a-4d49-bced-3e3696ca86c0",
    host: "https://wchat.freshchat.com",
    firstName: userAttributes.firstName,
    lastName: userAttributes.lastName,
    email: userAttributes.email,
    externalId: userAttributes.id,
    restoreId: userAttributes.restoreId,
    config: {
      headerProperty: {
        hideChatButton: true,
      },
    },
    onInit() {
      window.fcWidget.on("widget:loaded", function() {
        window.fcWidget.on("unreadCount:notify", function(resp) {
          if (resp.count > 0) {
            document.getElementById("notify").style.visibility = "visible";
          } else {
            document.getElementById("notify").style.visibility = "hidden";
          }
        });
        window.fcWidget.on("widget:closed", function() {
          document.getElementById("fc_frame").style.display = "none";
          document.getElementById("custom_fc_button").style.visibility =
            "visible";
        });
        window.fcWidget.on("widget:opened", function() {
          document.getElementById("fc_frame").style.display = "block";
          document.getElementById("custom_fc_button").style.visibility =
            "hidden";
        });
      });
    },
  });
  window.fcWidget.setFaqTags({ tags: [entityType], filterType: "category" });
  if (!userAttributes.restoreId) _setRestoreId(userAttributes.id, entityType);
}

function _setLiveChatDisplay(entityType) {
  if (window.fcWidget.isLoaded() === true) {
    document.getElementById("fc_frame").style.display = "none";
    document.getElementById("custom_fc_button").style.visibility = "visible";
  } else {
    window.fcWidget.on("widget:loaded", function() {
      document.getElementById("fc_frame").style.display = "none";
      document.getElementById("custom_fc_button").style.visibility = "visible";
    });
  }
}

function _setRestoreId(id, entityType) {
  window.fcWidget.on("user:created", function(resp) {
    const status = resp && resp.status;
    const data = resp && resp.data;
    if (status === 200) {
      if (data.restoreId) {
        const freshchatIds = api("freshchat_ids");
        const params = {
          freshchat_restore_id: data.restoreId,
        };
        if (entityType === "authorisation") {
          params.authorisation_id = id;
        } else {
          params.user_id = id;
        }
        freshchatIds.createFreshchatId({ ...params }); // Update restoreId in your database
      }
    }
  });
}

export function hideLiveChat() {
  let fc_frame = document.getElementById("fc_frame");
  if (fc_frame) {
    document.getElementById("fc_frame").style.display = "none";
  }

  let custom_fc_button = document.getElementById("custom_fc_button");
  if (custom_fc_button) {
    document.getElementById("custom_fc_button").style.visibility = "hidden";
  }
}
