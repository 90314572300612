import ParentCompanies from "./ParentCompanies";
import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import styles from "../../css/CommonReviewBusiness.css";

var DirectorAffiliations = createClass({
  _directors() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyDetails = enquiryResult.company_details,
      companyDirectors = companyDetails.company_directors || {},
      directors = companyDirectors.company_director_ws_dto || [];

    if (directors.constructor === Object) {
      directors = [directors];
    }

    let activeDirectors = directors.filter(director => {
      return director.date_deleted === null;
    });

    return activeDirectors.map((director, index) => {
      return (
        <div key={index}>
          <p>
            {director.first_names} {director.surname}
          </p>
          <table className={styles.table_w20}>
            <thead>
              <tr>
                <th>Company name</th>
                <th>Number</th>
                <th>Status</th>
                <th>Credit Limit</th>
                <th>Suppliers</th>
                <th>DSO</th>
              </tr>
            </thead>
            <tbody>{this._directorCompanies(director)}</tbody>
          </table>
        </div>
      );
    });
  },

  _directorCompanies(director) {
    let directorships = director.directorships || {},
      companies = directorships.company2_ws_dto || [];

    if (companies.constructor === Object) {
      companies = [companies];
    }

    return companies.map((company, index) => {
      return (
        <tr key={index}>
          <td>{company.company_name}</td>
          <td>{company.company_number}</td>
          <td></td>
          <td>
            {parseFloat(company.combined_credit_limit).toLocaleString("en", {
              maximumFractionDigits: 2,
            })}
          </td>
          <td>{company.number_of_suppliers}</td>
          <td>{company.dso}</td>
        </tr>
      );
    });
  },

  render() {
    const { data, isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="Directory Affiliations"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          This information was obtained from the New Zealand Companies Office.
          Please note that director searches use the directors' names and may
          not detect incorrect or alternative spellings. There may be more than
          one director with the same name, in which case all directors that
          match the search criteria will be retrieved. CreditWorks is unable to
          verify that the information presented is complete, or that all entries
          for a specified name actually refer to the same person. CreditWorks
          recommends that you verify the information independently.
        </p>
        <div className={styles.table_wrapper}>{this._directors()}</div>
        <ParentCompanies data={data} />
      </ReviewContainer>
    );
  },
});

export default DirectorAffiliations;
