import {
  CNS_INVITE_SET_TYPE,
  CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START,
  CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS,
  CNS_INVITE_CREATE_FOR_NEW_CONSUMER_ERROR,
  CNS_INVITE_COMPONENT_COMPLETE,
  CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_START,
  CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_SUCCESS,
  CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_ERROR,
  CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER,
  CNS_INVITE_SET_RETURN_ROUTE,
  CNS_INVITE_NZ_COMPANY_SET_NAME,
  CNS_INVITE_NZ_COMPANY_NAME_SELECT,
  CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_CLEAR,
  CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_START,
  CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_SUCCESS,
  CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_ERROR,
  CNS_INVITE_COMPANY_SET_NAME,
  CNS_INVITE_COMPANY_NAME_SELECT,
  CNS_INVITE_COMPANY_NAME_LOOKUP_CLEAR,
  CNS_INVITE_COMPANY_NAME_LOOKUP_START,
  CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS,
  CNS_INVITE_RESET_COMPANY_NAME_LOOKUP,
  CNS_INVITE_COMPANY_NAME_LOOKUP_ERROR,
  CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME,
  CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME,
  CNS_INVITE_SET_NEW_CONSUMER_EMAIL,
  CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME,
  CNS_INVITE_SET_IUF_ANSWERS,
  CNS_INVITE_SET_DIRECTORS_CONTAINER_INDEX,
  CNS_INVITE_SET_KEY_CONTACT_FIRST_NAME,
  CNS_INVITE_SET_KEY_CONTACT_LAST_NAME,
  CNS_INVITE_SET_KEY_CONTACT_PHONE,
  CNS_INVITE_SET_KEY_CONTACT_EMAIL,
  CNS_VALIDATE_UNIQUE_EMAIL_START,
  CNS_VALIDATE_UNIQUE_EMAIL_SUCCESS,
  CNS_VALIDATE_UNIQUE_EMAIL_ERROR,
  CNS_INVITE_UPDATE_KEY_CONTACT_PERCENTAGE,
  CNS_INVITE_SECTION_COMPLETE,
  CNS_INVITE_CLEAR_STATE,
  CNS_INVITE_SET_SECTION_INDEX,
  CNS_INVITE_SELECT_ENTITY_PARTY_COUNT,
  CNS_INVITE_SET_ENTITY_NAME,
  CNS_INVITE_SET_ENTITY_LOGO,
  CNS_INVITE_SET_STAFF_COUNT,
  CNS_INVITE_SET_PHONE,
  CNS_INVITE_SET_WEBSITE,
  CNS_INVITE_SET_ADDRESS,
  CNS_INVITE_SET_POSTAL,
  CNS_INVITE_SET_ENTITY_PARTY_DETAILS_INDEX,
  CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_NAME,
  CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL,
  CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE,
  CNS_INVITE_SELECT_ENTITY_TYPE,
  CNS_INVITE_NZ_COMPANY_DETAILS_START,
  CNS_INVITE_NZ_COMPANY_DETAILS_SUCCESS,
  CNS_INVITE_NZ_COMPANY_DETAILS_ERROR,
  CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
  CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_START,
  CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
  CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_ERROR,
  CNS_INVITE_POSTAL_ADDRESS_LOOKUP_CLEAR,
  CNS_INVITE_POSTAL_ADDRESS_LOOKUP_START,
  CNS_INVITE_POSTAL_ADDRESS_LOOKUP_SUCCESS,
  CNS_INVITE_POSTAL_ADDRESS_LOOKUP_ERROR,
  CNS_INVITE_SET_POSTAL_ADDRESS,
  CNS_INVITE_SET_PHYSICAL_ADDRESS,
  CNS_INVITE_SELECT_POSTAL_ADDRESS,
  CNS_INVITE_SELECT_PHYSICAL_ADDRESS,
  CNS_INVITE_GET_USER_PERMISSSION_START,
  CNS_INVITE_GET_USER_PERMISSSION_SUCCESS,
  CNS_INVITE_GET_USER_PERMISSSION_ERROR,
  CNS_INVITE_COMPANY_EMAIL_SELECT,
  CNS_INVITE_RESEND_APPLICATION_START,
  CNS_INVITE_RESEND_APPLICATION_SUCCESS,
  CNS_INVITE_RESEND_APPLICATION_ERROR,
  CNS_INVITE_RESEND_AUTHORISATION_START,
  CNS_INVITE_RESEND_AUTHORISATION_SUCCESS,
  CNS_INVITE_RESEND_AUTHORISATION_ERROR,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR,
} from "../constants/invite";

var cns_invite_default = {
  invite_type: null,
  return_path: "/dashboard/home",

  company_autosuggest_field_value: "",
  company_autosuggest_lookup_loading: false,
  company_autosuggest_list: {},
  company_autosuggest_selected_name: "",
  company_autosuggest_selected_name_with_address: "",
  company_autosuggest_selected_details: {},
  company_autosuggest_selected_user: null,

  new_consumer_first_name: null,
  new_consumer_last_name: null,
  new_consumer_email: null,
  new_consumer_company_name: null,

  iuf_answers: null,

  section_index: 0,

  section_data: {},
  section_complete: false,
  completed: {},

  entity_type: null,
  entity_type_title: null,
  entity_name: "",

  logo: "",

  saving: false,

  entity_staff_count: "",
  entity_phone: "",
  entity_website_url: "",
  entity_address: "",
  entity_postal: "",
  entity_party_count: 0,
  entity_party_details_total_percent: 0,
  entity_party_details_values: [],
  entity_party_details_container_index: 0,

  key_contact_percentage: 0,
  key_contact_first_name: "",
  key_contact_last_name: "",
  key_contact_phone: "",
  key_contact_email: "",

  company_nzbn_details: {},

  nz_company_autosuggest_field_value: "",
  nz_company_autosuggest_lookup_loading: false,
  nz_company_autosuggest_list: {},
  nz_company_autosuggest_selected_name: "",
  nz_company_autosuggest_selected_details: {},

  nz_company_nzbn_details_loading: false,
  company_directors_container_index: 0,

  physical_address_list: {},
  postal_address_list: {},
  postal_address: "",
  physical_address: "",
  postal_dpid: null,
  physical_dpid: null,
  postal_address_loading: false,
  physical_address_loading: false,

  email_valid: null,
  email_validating: false,
  user_permissions: "",

  invite_resending: false,
};

export function consumerInviteReducer(state = cns_invite_default, action) {
  switch (action.type) {
    case CNS_INVITE_SET_POSTAL_ADDRESS:
      return { ...state, postal_address: action.payload, postal_dpid: null };
    case CNS_INVITE_SET_PHYSICAL_ADDRESS:
      return {
        ...state,
        physical_address: action.payload,
        physical_dpid: null,
      };

    case CNS_INVITE_POSTAL_ADDRESS_LOOKUP_CLEAR:
      return { ...state, postal_address_list: {}, postal_dpid: null };
    case CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_CLEAR:
      return { ...state, physical_address_list: {}, physical_dpid: null };

    case CNS_INVITE_POSTAL_ADDRESS_LOOKUP_START:
      return { ...state, postal_address_loading: true, postal_dpid: null };
    case CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_START:
      return { ...state, physical_address_loading: true, physical_dpid: null };

    case CNS_INVITE_POSTAL_ADDRESS_LOOKUP_ERROR:
      return { ...state, postal_address_loading: false };
    case CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_ERROR:
      return { ...state, physical_address_loading: false };

    case CNS_INVITE_POSTAL_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        postal_address_loading: false,
        postal_address_list: action.payload,
      };
    case CNS_INVITE_PHYSICAL_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        physical_address_loading: false,
        physical_address_list: action.payload,
      };

    case CNS_VALIDATE_UNIQUE_EMAIL_START:
      return { ...state, email_validating: true };
    case CNS_VALIDATE_UNIQUE_EMAIL_ERROR:
      return { ...state, email_validating: false };
    case CNS_VALIDATE_UNIQUE_EMAIL_SUCCESS:
      return {
        ...state,
        email_valid: !action.payload.user_exists || !action.payload.has_entity,
        email_validating: false,
      };

    case CNS_INVITE_SELECT_POSTAL_ADDRESS:
      return {
        ...state,
        postal_dpid: action.payload,
        postal_address_loading: false,
        postal_address_list: {},
        postal_address: state.postal_address_list[action.payload],
      };
    case CNS_INVITE_SELECT_PHYSICAL_ADDRESS:
      return {
        ...state,
        physical_dpid: action.payload,
        physical_address_loading: false,
        physical_address_list: {},
        physical_address: state.physical_address_list[action.payload],
      };

    case CNS_INVITE_SECTION_COMPLETE:
      return {
        ...state,
        section_data: action.payload.data,
        section_complete: action.payload.complete,
      };
    case CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START:
    case CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_START:
    case CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START:
      return { ...state, saving: true };
    case CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS:
      return { ...state, saving: false };
    case CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_SUCCESS:
    case CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS:
      return { ...state, saving: false };
    case CNS_INVITE_CREATE_FOR_NEW_CONSUMER_ERROR:
    case CNS_INVITE_CREATE_FOR_EXISTING_CONSUMER_ERROR:
    case CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR:
      return { ...state, saving: false };
    case CNS_INVITE_CLEAR_STATE:
      return { ...cns_invite_default, return_path: state.return_path };
    case CNS_INVITE_SET_RETURN_ROUTE:
      return { ...state, return_path: action.payload };
    case CNS_INVITE_SET_TYPE:
      return { ...state, invite_type: action.payload };

    case CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_START:
      return {
        ...state,
        nz_company_autosuggest_selected_details: action.payload,
        nz_company_autosuggest_lookup_loading: true,
      };

    case CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_SUCCESS:
      return {
        ...state,
        nz_company_autosuggest_list: action.payload,
        nz_company_autosuggest_lookup_loading: false,
      };

    case CNS_INVITE_NZ_COMPANY_SET_NAME:
      return { ...state, nz_company_autosuggest_field_value: action.payload };

    case CNS_INVITE_NZ_COMPANY_NAME_SELECT:
      return {
        ...state,
        company_nzbn_details: {},
        nz_company_autosuggest_selected_details: action.payload,
        nz_company_autosuggest_field_value: action.payload.name,
        nz_company_autosuggest_selected_name: action.payload.name,
      };

    case CNS_INVITE_NZ_COMPANY_NAME_LOOKUP_CLEAR:
      return {
        ...state,
        nz_company_autosuggest_list: [],
        nz_company_autosuggest_selected_details: null,
      };

    case CNS_INVITE_COMPANY_NAME_LOOKUP_START:
      return {
        ...state,
        company_autosuggest_selected_details: action.payload,
        company_autosuggest_lookup_loading: true,
      };
    case CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS:
      return {
        ...state,
        company_autosuggest_list: action.payload,
        company_autosuggest_lookup_loading: false,
      };
    case CNS_INVITE_RESET_COMPANY_NAME_LOOKUP:
      return {
        ...state,
        company_autosuggest_list: {},
        company_autosuggest_lookup_loading: false,
      };
    case CNS_INVITE_COMPANY_SET_NAME:
      return { ...state, company_autosuggest_field_value: action.payload };
    case CNS_INVITE_COMPANY_NAME_SELECT:
      return { ...state, company_autosuggest_selected_details: action.payload };
    case CNS_INVITE_COMPANY_NAME_LOOKUP_CLEAR:
      return {
        ...state,
        company_autosuggest_list: {},
        company_autosuggest_selected_details: null,
      };
    case CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME:
      return { ...state, new_consumer_first_name: action.payload };
    case CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME:
      return { ...state, new_consumer_last_name: action.payload };
    case CNS_INVITE_SET_IUF_ANSWERS:
      return { ...state, iuf_answers: action.payload };
    case CNS_INVITE_SET_NEW_CONSUMER_EMAIL:
      return { ...state, new_consumer_email: action.payload };
    case CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME:
      return { ...state, new_consumer_company_name: action.payload };

    case CNS_INVITE_UPDATE_KEY_CONTACT_PERCENTAGE:
      return { ...state, key_contact_percentage: action.payload };
    case CNS_INVITE_SET_KEY_CONTACT_FIRST_NAME:
      return { ...state, key_contact_first_name: action.payload };
    case CNS_INVITE_SET_KEY_CONTACT_LAST_NAME:
      return { ...state, key_contact_last_name: action.payload };
    case CNS_INVITE_SET_KEY_CONTACT_PHONE:
      return { ...state, key_contact_phone: action.payload };
    case CNS_INVITE_SET_KEY_CONTACT_EMAIL:
      return { ...state, key_contact_email: action.payload };

    case CNS_INVITE_COMPONENT_COMPLETE:
      var component_state = new Object();
      component_state[action.payload.component] = action.payload.state;
      return {
        ...state,
        completed: { ...state.completed, ...component_state },
      };

    case CNS_INVITE_SET_ENTITY_LOGO:
      return { ...state, logo: action.payload };

    case CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_NAME:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          name: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          name: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          email: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          email: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case CNS_INVITE_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      var total_percent = 0;
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          percent: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          percent: action.payload.value,
        };
      }

      // Work out the total percent
      for (var i = 0; i < form_values.length; i++) {
        if (form_values[i].hasOwnProperty("percent")) {
          total_percent += parseInt(form_values[i].percent);
        }
      }

      return {
        ...state,
        entity_party_details_values: form_values,
        entity_party_details_total_percent: total_percent,
      };

    case CNS_INVITE_SET_ENTITY_PARTY_DETAILS_INDEX:
      return { ...state, entity_party_details_container_index: action.payload };

    case CNS_INVITE_SET_ENTITY_NAME:
      return { ...state, entity_name: action.payload };

    case CNS_INVITE_SET_STAFF_COUNT:
      return { ...state, entity_staff_count: action.payload };

    case CNS_INVITE_SET_PHONE:
      return { ...state, entity_phone: action.payload };

    case CNS_INVITE_SET_WEBSITE:
      return { ...state, entity_website_url: action.payload };

    case CNS_INVITE_SET_ADDRESS:
      return { ...state, entity_address: action.payload };

    case CNS_INVITE_SET_POSTAL:
      return { ...state, entity_postal: action.payload };

    case CNS_INVITE_SET_SECTION_INDEX:
      return { ...state, section_index: action.payload };

    case CNS_INVITE_SELECT_ENTITY_TYPE:
      return {
        ...state,
        entity_type: action.payload.type,
        entity_type_title: action.payload.title,
      };

    case CNS_INVITE_SELECT_ENTITY_PARTY_COUNT:
      return { ...state, entity_party_count: action.payload };

    case CNS_INVITE_SET_DIRECTORS_CONTAINER_INDEX:
      return { ...state, company_directors_container_index: action.payload };

    case CNS_INVITE_NZ_COMPANY_DETAILS_START:
      return { ...state, nz_company_nzbn_details_loading: true };

    case CNS_INVITE_NZ_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        nz_company_nzbn_details_loading: false,
        nz_company_nzbn_details: action.payload,
      };

    //
    // case STATE_LOAD_STATE_SUCCESS:
    //   if (action.payload['cns_invite']) {
    //     return {...state, ...action.payload.cns_invite };
    //   }
    case CNS_INVITE_GET_USER_PERMISSSION_START:
      return { ...state };
    case CNS_INVITE_GET_USER_PERMISSSION_SUCCESS:
      return { ...state, user_permissions: action.payload };
    case CNS_INVITE_GET_USER_PERMISSSION_ERROR:
      return { ...state };

    case CNS_INVITE_COMPANY_EMAIL_SELECT:
      return { ...state, company_autosuggest_selected_user: action.payload };

    case CNS_INVITE_RESEND_APPLICATION_START:
    case CNS_INVITE_RESEND_AUTHORISATION_START:
      return { ...state, invite_resending: true };
    case CNS_INVITE_RESEND_APPLICATION_SUCCESS:
    case CNS_INVITE_RESEND_AUTHORISATION_SUCCESS:
      return { ...state, invite_resending: false };
    case CNS_INVITE_RESEND_APPLICATION_ERROR:
    case CNS_INVITE_RESEND_AUTHORISATION_ERROR:
      return { ...state, invite_resending: false };

    default:
      return { ...state };
  }
}
