import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";
import ImageEditor from "modules/shared/components/widgets/interactive/ImageEditor";
import PopperToolTip from "modules/shared/components/widgets/interactive/PopperToolTip";
import PropTypes from "prop-types";
import isBlank from "utils/isBlank";
import styles from "./css/EditableFileWidget.css";

const ACCEPTED_TYPES = ".jpg,.jpeg,.png";

export default class FileWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      btnText: "or browse",
      isAcceptType: false,
      isDrag: false,
      isInEditMode: false,
      isLoaded: false,
      isLoading: false,
      text: this.props.text || "",
    };
  }

  setDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isDrag: true,
      text: "Let it go, let it go",
    });
  };

  setDragOut = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState(this.getDefaultState());
  };

  fileDrop = event => {
    const { showPricingPlan, HandlePricingPlan } = this.props;
    event.preventDefault();
    event.stopPropagation();
    if (showPricingPlan) {
      HandlePricingPlan();
      this.setState({
        isDrag: false,
        isLoading: false,
        text: "Drag logo here",
      });
    } else {
      this.setState({
        isDrag: false,
        isLoading: true,
        text: "Let it go, let it go",
      });
      this.processFile(event.dataTransfer.files);
    }
  };

  fileAdded = event => {
    const { showPricingPlan, HandlePricingPlan } = this.props;
    event.preventDefault();
    event.stopPropagation();
    if (showPricingPlan) {
      HandlePricingPlan();
      event.target.value = null;
    } else {
      this.setState({
        isLoading: true,
      });
      this.processFile(event.target.files);
    }
  };

  fileReady = data => {
    const { handleFileCallback } = this.props;

    this.setState({
      btnText: "change",
      isLoaded: true,
      isLoading: false,
    });

    handleFileCallback(data.target.result);
  };

  processFile(files) {
    if (files && files[0]) {
      const file = files[0];
      if (this.checkValid(file)) {
        const reader = new FileReader();
        reader.onload = this.fileReady;
        reader.readAsDataURL(file);
      }
    }
  }

  checkValid(file) {
    const isValid =
      ACCEPTED_TYPES.indexOf(
        file.name
          .split(".")
          .pop()
          .toLowerCase(),
      ) > 0;

    this.setState({ isAcceptType: !isValid });

    return isValid;
  }

  renderUploader() {
    const { completeWidget, theme } = this.props;
    const { isDrag, isLoaded, text } = this.state;

    let themeStyle = styles;

    if (theme) {
      themeStyle = theme;
    }

    // Allows for drag hover styles.
    let containerStyles = themeStyle.container;

    if (isDrag) {
      containerStyles = themeStyle.dragover;
    }

    if (isLoaded) {
      containerStyles = themeStyle.completed;
    }

    // Allows for is loading styles
    const innerStyles = themeStyle.inner;

    // Allows a parent to define the completed Widget, ie logo image or file icon etc.
    let widget = <p className={themeStyle.text}>{text}</p>;
    if (completeWidget) {
      widget = completeWidget;
      containerStyles = themeStyle.completed;
    }

    return (
      <div
        className={containerStyles}
        onDragLeave={this.setDragOut}
        onDragExit={this.setDragOut}
        onDragOver={this.setDragOver}
        onDragEnter={this.setDragOver}
        onDrop={this.fileDrop}
      >
        <div className={innerStyles}>
          <div className={`${styles.content} ${themeStyle.dots}`}>
            <div className={styles.widget}>{widget}</div>
            <div className={styles.buttons}>
              {this.renderUploadWidget()}
              {this.renderImageEditButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  get fileInputId() {
    const { id } = this.props;

    if (id) {
      return id;
    }

    return "uploadFile";
  }

  renderUploadWidget() {
    return (
      <div>
        <input
          onChange={this.fileAdded}
          type="file"
          name="logoFile"
          id={this.fileInputId}
          className={styles.file}
          accept={ACCEPTED_TYPES}
        />
        {this.renderUploadButton()}
        {this.renderErrorMessage()}
      </div>
    );
  }

  renderUploadButton() {
    const { completeWidget, disableChangeButton, loading } = this.props;
    const { btnText } = this.state;

    if (disableChangeButton) {
      return;
    }

    let labelText = btnText;
    if (loading) {
      labelText = "saving";
    }

    if (completeWidget) {
      labelText = "upload";
    }

    return (
      <label htmlFor={this.fileInputId}>
        <PopperToolTip
          isVisibleOnMobile={false}
          title="Upload your logo. Accepted file formats - JPEG, PNG and one page PDF"
        >
          <Button color="primary" component="span">
            {labelText}
            <CloudUploadIcon fontSize="small" className={styles.button_icon} />
          </Button>
        </PopperToolTip>
      </label>
    );
  }

  renderErrorMessage() {
    const { isAcceptType } = this.state;

    if (!isAcceptType) {
      return;
    }

    return <p className={styles.error}>Please upload {ACCEPTED_TYPES} only.</p>;
  }

  onEnableEditMode = () => {
    this.setState({ isInEditMode: true });
  };

  onStopEdit = () => {
    this.setState({ isInEditMode: false });
  };

  onCropImage = croppedImage => {
    this.props.handleFileCallback(croppedImage);
  };

  renderImageEditButton() {
    const { fileSrc, disableChangeButton } = this.props;

    if (disableChangeButton) {
      return;
    }

    if (isBlank(fileSrc)) {
      return;
    }

    return (
      <PopperToolTip isVisibleOnMobile={false} title="Crop or rotate the image">
        <Button color="primary" onClick={this.onEnableEditMode}>
          Edit
          <EditIcon fontSize="small" className={styles.button_icon} />
        </Button>
      </PopperToolTip>
    );
  }

  renderImageEditor() {
    const { fileSrc } = this.props;

    return (
      <ImageEditor
        cropperOptions={{
          autoCrop: true,
          crossOrigin: true,
          dragMode: "move",
        }}
        autoEnableEdit={true}
        imageMaxHeight="300px"
        imageName="logo"
        imageSrc={fileSrc}
        onCropImage={this.onCropImage}
        onStopEdit={this.onStopEdit}
      />
    );
  }

  render() {
    if (this.state.isInEditMode) {
      return this.renderImageEditor();
    }

    return this.renderUploader();
  }
}

FileWidget.propTypes = {
  fileSrc: PropTypes.string,
  isEditable: PropTypes.bool,
};

FileWidget.defaultProps = {
  isEditable: false,
};
