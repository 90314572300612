import { ContentContainer } from "modules/addons/components//DigitalOnboarding";
import commonStyles from "modules/addons/components/css/PPSRRegistration/EditComponent.css";
import React from "react";

export default function NZDebtorDetails(): React.ReactElement {
  return (
    <ContentContainer header="Step 5: Debtor details.">
      <div className={commonStyles.sub_header}>
        These details will be automatically pre-populated onto your
        registration from the application details.
      </div>
      <div>Type: person/organisation</div>
      <div>Name (Organisation name/first, last name)</div>
      <div>Business address</div>
      <div>Person acting on behalf&apos;s details (applicant)</div>
    </ContentContainer>
  )
}
