import { Pie } from "@ant-design/charts";
import api from "api";
import React, { ReactElement, useEffect } from "react";

import useParamsCompare from "../../hooks/useParamsCompare";
import useRequestQuery from "../../hooks/useRequestQuery";
import Message from "../Message";
import Panel from "../Panel";
import { Props } from "../Panel/model";

function OmniChannelPanel(props: Props): ReactElement {
  const {
    accessToken,
    entityId,
    filterState: params,
  } = props;
  const apiKey = "omni_channel";
  const apiAction = () =>
    api("reporting", accessToken, entityId).get({ api: apiKey, params });
  const { data: queryData, error, forceUpdate, loading } = useRequestQuery(
    apiAction,
  );

  const paramString = JSON.stringify(params);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
    }
  }, [params]);

  const noData =
    queryData && Object.keys(queryData).every(key => queryData[key] === 0);

  const getPercentage = (key: string) => {
    if (!queryData) {
      return 0;
    }

    return parseInt((queryData[key] * 100).toFixed());
  };

  const data = [
    {
      type: "website",
      value: getPercentage("initiated_by_website_button"),
    },
    {
      type: "QR",
      value: getPercentage("initiated_by_qr_code"),
    },
    {
      type: "users",
      value: getPercentage("initiated_by_users"),
    },
  ];

  const config = {
    angleField: "value",
    colorField: "type",
    data,
    height: 225,
    isPercent: true,
    label: {
      content: _ref => {
        const { value } = _ref;
        return `${value}%`;
      },
      fontSize: 12,
      labelHeight: 40,
      // labelWidth: 100,
      type: "outer",
    },
  };

  return (
    <Panel
      {...props}
      beta
      error={error}
      loading={loading}
      refresh={forceUpdate}
      title="Omni channel"
      tooltip={
        <span>
          This section tells you where all of your applications are originating
          from (www/QR code/reps). Use your filters to view by specific time
          period or by different Tiers if applicable.
        </span>
      }
    >
      {!loading && !noData && (
        <Pie
          {...config}
          legend={{
            position: "bottom",
          }}
          // tooltip={{ fields: false }}
          color={[
            "#00afef", // website
            "#7fcef3", // qr
            "#39b54a", // users
          ]}
        />
      )}
      {!loading && !error && noData && (
        <Message
          faProps={{
            icon: ["fas", "frown"],
          }}
          message="No data found."
        />
      )}
    </Panel>
  );
}

export default OmniChannelPanel;
