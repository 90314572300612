import React, { Fragment } from "react";
import get from "lodash.get";
import getIdentificationCheckOutcomeDescription from "modules/new-applications/components/application-sections/IdentificationCheck/utils/getIdentificationCheckOutcomeDescription";
import styles from "modules/new-applications/css/IdentificationChecks.css";
import SignatureDetails from "modules/new-applications/components/application-sections/IdentificationCheck/SignatureDetails";
import AMLFile from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v1/AMLCheckOutcome/AMLFile";

const getVerificationStatus = (identificationCheck, identification) => {
  const dataSourceMatchResult = get(
    identificationCheck,
    "data.data_sets.smart_id.data_source_match_results.data_source_match_result",
  );

  if (!dataSourceMatchResult) {
    return null;
  }

  const idVerificationKey = Object.keys(dataSourceMatchResult).filter(
    key =>
      dataSourceMatchResult[key].data_source_name === identification.source,
  )[0];
  const idVerification = dataSourceMatchResult[idVerificationKey];

  return !Object.keys(idVerification).some(
    key => idVerification[key] === "NoMatch",
  );
};

const AMLCheckOutcome = props => {
  const { authorisation, identificationCheck } = props;

  const result = getIdentificationCheckOutcomeDescription(
    get(identificationCheck, "data.data_sets.smart_id.is_verified", false),
  );

  const color = result === "Pass" ? "green" : "red";

  const idVerificationTypes = {
    driver_licence: {
      label: "Driver licence verification",
      source: "NZTADriverLicence",
    },
    passport: {
      label: "Passport verification",
      source: "DIAPassport",
    },
  };

  const idType = get(authorisation, "signature.attributes.identification_type");

  const identification = idVerificationTypes[idType];

  return (
    <Fragment>
      <div className={styles.e_signature_header}>
        <span className="underlined">AML outcome</span>:
        <span style={{ color: `${color}` }}>{` ${result}`}</span>
      </div>

      {identification && (
        <SignatureDetails
          label={identification.label}
          content={getIdentificationCheckOutcomeDescription(
            getVerificationStatus(identificationCheck, identification),
          )}
        />
      )}
      <SignatureDetails
        label="Name verification"
        content={getIdentificationCheckOutcomeDescription(
          get(
            identificationCheck,
            "data.data_sets.smart_id.is_name_verified",
            false,
          ),
        )}
      />
      <SignatureDetails
        label="DOB verification"
        content={getIdentificationCheckOutcomeDescription(
          get(
            identificationCheck,
            "data.data_sets.smart_id.is_date_of_birth_verified",
            false,
          ),
        )}
      />
      <SignatureDetails
        label="Address verification"
        content={getIdentificationCheckOutcomeDescription(
          get(
            identificationCheck,
            "data.data_sets.smart_id.is_address_verified",
            false,
          ),
        )}
      />
      <SignatureDetails
        label="PEP watchlist"
        content={identificationCheck.amlPEPWatchlistOutcome}
      />
      <AMLFile identificationCheck={identificationCheck} />
    </Fragment>
  );
};

export default AMLCheckOutcome;
