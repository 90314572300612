import capitalize from "lodash.capitalize";
import AddressModel from "models/AddressModel";
import BaseModel from "models/BaseModel";
import moment from "moment";
import isPresent from "utils/isPresent";

export const DEFAULT_DEBTOR_REFERENCE = "Created from 1Centre API";

export default class PpsrFinancingStatementDebtorModel extends BaseModel {
  get formattedDebtorName() {
    if (this.debtorType === "person") {
      return this.personFullName;
    }

    return this.organisationName;
  }

  get personFullName() {
    return [this.personFirstName, this.personLastName]
      .filter(name => isPresent(name))
      .join(" ");
  }

  get formattedDebtorType() {
    return capitalize(this.debtorType);
  }

  get formattedDateOfBirth() {
    if (isPresent(this.personDateOfBirth)) {
      return moment(this.personDateOfBirth).format("DD/MM/YYYY");
    }

    return null;
  }

  get formValues() {
    const address = this.debtorAddress || {};

    return {
      authorisationId: this.authorisationId,
      debtorAddress: {
        fullAddress: address.fullAddress,
        rawData: {
          alpha2: address.alpha2,
          api_id: address.apiId,
          api_provider: address.apiProvider,
          full_address: address.fullAddress,
          raw_data: address.rawData,
        },
      },
      debtorEmail: this.debtorEmail,
      debtorPhone: this.debtorPhone,
      debtorReference: this.debtorReference,
      debtorType: this.debtorType,
      id: this.id,
      organisationName: this.organisationName,
      organisationNumber: this.organisationNumber,
      personDateOfBirth: this.formattedDateOfBirth,
      personFirstName: this.personFirstName,
      personLastName: this.personLastName,
    };
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  /** Private functions */

  assignRelationships() {
    this.assignSingleRelationship({
      key: "debtor_address",
      model: AddressModel,
    });
  }
}
