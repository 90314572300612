export const TRADE_REFERENCES_LOAD_APPLICATIONS_START =
  "TRADE_REFERENCES_LOAD_APPLICATIONS_START";
export const TRADE_REFERENCES_LOAD_APPLICATIONS_SUCCESS =
  "TRADE_REFERENCES_LOAD_APPLICATIONS_SUCCESS";
export const TRADE_REFERENCES_LOAD_APPLICATIONS_ERROR =
  "TRADE_REFERENCES_LOAD_APPLICATIONS_ERROR";

export const TRADE_REFERENCES_LOAD_TRADE_REFERENCE_START =
  "TRADE_REFERENCES_LOAD_TRADE_REFERENCE_START";
export const TRADE_REFERENCES_LOAD_TRADE_REFERENCE_SUCCESS =
  "TRADE_REFERENCES_LOAD_TRADE_REFERENCE_SUCCESS";
export const TRADE_REFERENCES_LOAD_TRADE_REFERENCE_ERROR =
  "TRADE_REFERENCES_LOAD_TRADE_REFERENCE_ERROR";

export const TRADE_REFERENCES_CREATE_CHECK = "TRADE_REFERENCES_CREATE_CHECK";
export const TRADE_REFERENCES_CREATE_CHECK_START =
  "TRADE_REFERENCES_CREATE_CHECK_START";
export const TRADE_REFERENCES_CREATE_CHECK_SUCCESS =
  "TRADE_REFERENCES_CREATE_CHECK_SUCCESS";
export const TRADE_REFERENCES_CREATE_CHECK_ERROR =
  "TRADE_REFERENCES_CREATE_CHECK_ERROR";

export const TRADE_REFERENCES_UPDATE_CHECK_START =
  "TRADE_REFERENCES_UPDATE_CHECK_START";
export const TRADE_REFERENCES_UPDATE_CHECK_SUCCESS =
  "TRADE_REFERENCES_UPDATE_CHECK_SUCCESS";
export const TRADE_REFERENCES_UPDATE_CHECK_ERROR =
  "TRADE_REFERENCES_UPDATE_CHECK_ERROR";

export const TRADE_REFERENCES_CREATE_NOTES_START =
  "TRADE_REFERENCES_CREATE_NOTES_START";
export const TRADE_REFERENCES_CREATE_NOTES_SUCCESS =
  "TRADE_REFERENCES_CREATE_NOTES_SUCCESS";
export const TRADE_REFERENCES_CREATE_NOTES_ERROR =
  "TRADE_REFERENCES_CREATE_NOTES_ERROR";

export const TRADE_REFERENCES_UPDATE_NOTES_START =
  "TRADE_REFERENCES_UPDATE_NOTES_START";
export const TRADE_REFERENCES_UPDATE_NOTES_SUCCESS =
  "TRADE_REFERENCES_UPDATE_NOTES_SUCCESS";
export const TRADE_REFERENCES_UPDATE_NOTES_ERROR =
  "TRADE_REFERENCES_UPDATE_NOTES_ERROR";

export const TRADE_REFERENCES_CLEAR_REFERENCE =
  "TRADE_REFERENCES_CLEAR_REFERENCE";
export const TRADE_REFERENCES_CLEAR_APPLICATIONS =
  "TRADE_REFERENCES_CLEAR_APPLICATIONS";
