import AutoSuggest from "modules/shared/components/widgets/interactive/AutoSuggest";
import CountryCodeDropdown from "modules/shared/components/widgets/static/CountryCodeDropdown";
import DateString from "modules/shared/components/widgets/static/DateString";
import EmailInput from "modules/shared/components/inputs/EmailInput";
import LabeledInformation from "modules/shared/components/widgets/static/LabeledInformation";
import Radiobox from "modules/shared/components/inputs/Radiobox";
import React from "react";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import TextInput from "modules/shared/components/inputs/TextInput";
import styles from "./css/Review.css";

var ReviewPartyDetailsForm = createClass({
  isEmailValid() {
    const { data } = this.props;
    return this.refs.email._validate(data.email);
  },

  _onChangeHandler(e) {
    const { index, onChangeHandler } = this.props;
    onChangeHandler(index, e);
  },

  _onEmailChangeHandler(value, isValid) {
    const { index, onChangeEmailHandler } = this.props;
    onChangeEmailHandler(index, value, isValid);
  },

  _onBlurHandler(e) {
    const { index, onBlurHandler } = this.props;
    onBlurHandler(index, e);
  },

  _handleResidentialChange(e) {
    const { index, handleResidentialChange } = this.props;
    handleResidentialChange(index, e);
  },

  _handleResidentialClick(e) {
    const { index, handleResidentialClick } = this.props;
    handleResidentialClick(index, e);
  },

  _handleResidentialTypeChange(e) {
    const { index, handleResidentialTypeChange } = this.props;
    handleResidentialTypeChange(index, e);
  },

  _countryCodeChangeHandler(e) {
    const { index, onCountryCodeChangeHandler } = this.props;
    onCountryCodeChangeHandler(index, e);
  },

  render() {
    const {
      index,
      type,
      data,
      form_error,
      css_class,
      labelString,
    } = this.props;

    let subHeading = labelString;

    return (
      <div>
        <ReviewContainer
          hideCheck={type.includes("sole_trader")}
          css_class={css_class}
          subHeading={subHeading}
        >
          {type != "personal" && (
            <div className={styles.col}>
              <TextInput
                id="name"
                name="name"
                label={`${labelString} ${index + 1}`}
                required={true}
                disabled={true}
                value={[data.first_name, data.middle_name, data.last_name]
                  .filter(Boolean)
                  .join(" ")}
              />
            </div>
          )}
          {type == "personal" && (
            <div className={styles.col}>
              <LabeledInformation
                label={`${labelString} ${index + 1}`}
                data={[data.first_name, data.middle_name, data.last_name]
                  .filter(Boolean)
                  .join(" ")}
              />
            </div>
          )}
          <div className={styles.col}>
            <EmailInput
              id="email"
              name="email"
              ref="email"
              handleChange={this._onEmailChangeHandler}
              handleBlur={this._onEmailChangeHandler}
              label={`${labelString} ${index + 1} email`}
              required={true}
              value={data.email}
              disabled={data.is_applicant}
            />
          </div>

          <LabeledInformation
            label="Appointment date"
            data={<DateString value={data.appointed_at} />}
          />
        </ReviewContainer>
        <ReviewContainer
          hideCheck={!type.includes("personal")}
          css_class={css_class}
        >
          <div className={styles.col}>
            <TextInput
              id="previous_last_name"
              name="previous_last_name"
              error={form_error.previous_last_name}
              handleChange={this._onChangeHandler}
              handleBlur={this._onBlurHandler}
              label="Previous Last name"
              required={false}
              helper_text={"If married"}
              value={data.previous_last_name}
            />
          </div>
          <div className={styles.col}>
            <AutoSuggest
              id="residential_address"
              label="Residential Address"
              value={
                (data.residential_address || { full_address: "" }).full_address
              }
              loading={data.residential_address_loading}
              suggest_items={data.residential_address_list || {}}
              error={form_error.residential_address}
              handleChange={this._handleResidentialChange}
              handleClick={this._handleResidentialClick}
              handleBlur={this._onBlurHandler}
              required={true}
              css_class="address_lookup"
              selected_item={
                data.residential_api_id
                  ? (data.residential_address || { full_address: "" })
                      .full_address
                  : ""
              }
              force_focus={true}
            />
          </div>
        </ReviewContainer>
        <ReviewContainer
          hideCheck={!type.includes("personal")}
          css_class={css_class}
        >
          <div className={styles.col}>
            <div className={styles.half_input}>
              <TextInput
                id="employer"
                name="employer"
                error={form_error.employer}
                handleChange={this._onChangeHandler}
                handleBlur={this._onBlurHandler}
                label="Employer"
                required={false}
                helper_text={"If applicable"}
                value={data.employer}
              />
            </div>
            <div className={styles.half_input + " " + styles.right_input}>
              <TextInput
                id="position"
                name="position"
                error={form_error.position}
                handleChange={this._onChangeHandler}
                handleBlur={this._onBlurHandler}
                label="Position"
                required={false}
                helper_text={"If applicable"}
                value={data.position}
              />
            </div>
          </div>
          <div className={styles.col}>
            <Radiobox
              id="residential_type"
              name="residential_type"
              error={form_error.residential_type}
              handleChange={this._handleResidentialTypeChange}
              label="Residental Type"
              value={data.residential_type}
              radioList={["rented", "owned"]}
            />
          </div>
        </ReviewContainer>
        <ReviewContainer
          hideCheck={!type.includes("personal")}
          css_class={css_class}
        >
          <div className={styles.col}>
            <div className={styles.half_input}>
              <CountryCodeDropdown
                key="contact_country_code"
                countryCodeId={`contact_country_code${index}`}
                label={"Country"}
                countryCodeError={form_error.contact_country_code}
                countryCodeValue={data.contact_country_code}
                required={true}
                handleChange={this._countryCodeChangeHandler}
              />
            </div>
            <div className={styles.half_input + " " + styles.right_input}>
              <TextInput
                key="contact_phone_number"
                id="contact_phone_number"
                name="contact_phone_number"
                type="tel"
                error={form_error.contact_phone_number}
                handleChange={this._onChangeHandler}
                handleBlur={this._onBlurHandler}
                label="Phone number"
                required={true}
                value={data.contact_phone_number}
              />
            </div>
          </div>
          <div className={styles.col}>
            <LabeledInformation
              label="Postal Address"
              data={(data.person_postal_address || {}).full_address}
            />
          </div>
        </ReviewContainer>
      </div>
    );
  },
});

export default ReviewPartyDetailsForm;
