/* Import libraries */
import React from "react";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import { checkPopupExist, getPopup } from "utils/tipHelper.js";

/* Import actions */
import { deleteUserPopup } from "../../user/actions";

/** import comonents **/
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import SimpleTable from "modules/shared/components/widgets/interactive/SimpleTable";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";

import styles from "./css/ApplicationList.css";

var SupplierApplicationList = createClass({
  hideTooltip: function(popup_name) {
    const { dispatch, current_user_popups } = this.props;
    let popup = getPopup(current_user_popups, popup_name);
    if (popup) {
      dispatch(deleteUserPopup(popup.id));
    }
  },

  linkToApplication: function(id) {
    const { data } = this.props;
    let application = data.find(a => a.id == id);
    if (application && application.attributes.status === "review_request") {
      browserHistory.push(`/dashboard/applications/${id}/request`);
    } else {
      browserHistory.push(`/dashboard/applications/${id}`);
    }
  },

  render: function() {
    const { data, total, isAuthorised, current_user_popups } = this.props;

    var actions = {
      linkToApplication: this.linkToApplication,
    };

    let countOfInProgress = data.filter(
      item => item.attributes.status === "in_progress",
    );
    let tool_tip, tool_tip1;
    if (
      isAuthorised &&
      checkPopupExist(
        current_user_popups,
        "dashboard_application_list_website_button",
      )
    ) {
      tool_tip = (
        <ToolTip
          key={"dashboard_application_list_websitebutton"}
          tip_name="DashboardApplicationListWebsiteButton"
          css_style="dashboard_application_list_websitebutton"
          dismissHandler={this.hideTooltip.bind(
            null,
            "dashboard_application_list_website_button",
          )}
        />
      );
    }

    if (
      countOfInProgress.length &&
      isAuthorised &&
      checkPopupExist(current_user_popups, "dashboard_application_list_review")
    ) {
      tool_tip1 = (
        <ToolTip
          key={"dashboard_application_list_review"}
          tip_name="DashboardApplicationListReview"
          css_style="dashboard_application_list_review"
          dismissHandler={this.hideTooltip.bind(
            null,
            "dashboard_application_list_review",
          )}
        />
      );
    }

    var content = (
      <SimpleTable
        data={data.slice(0, Math.min(data.length, 6))}
        actions={actions}
        config={options.config}
      />
    );

    return (
      <div className={styles.liner}>
        <hr />
        <div className={styles.content}>
          <h3 className={styles.count}>{total}</h3>
          <h3 className={styles.heading}>{options.title}</h3>
        </div>
        {tool_tip}
        {content}
        {tool_tip1}
      </div>
    );
  },
});

var options = {
  title: "Applications that need action",
  config: [
    {
      type: "data",
      key: "attributes.consumer_name",
      actionKey: "linkToApplication",
      actionParameter: "id",
      cellClass: "link",
    },
    {
      type: "data",
      key: "attributes.updated_at",
      cellClass: "date",
      timestamp: true,
    },
  ],
};

module.exports = connect((state, ownProps) => {
  return {
    isAuthorised:
      state.current_user.current_user_entity_link.attributes.role_types.includes("admin") ||
      state.current_user.current_user_entity_link.attributes.role_types.includes("approver"),
    current_user_popups: state.current_user.current_user_popups,
  };
})(SupplierApplicationList);
