/* eslint-disable max-classes-per-file */
export function intentLink(item, user) {
  const intent = item.intent;
  const target = item.intent_target;
  const userId = (user || {}).id;
  const intentToLink = {
    view_consumer_applications: `/dashboard/applications/user/${userId}/customers`,
    view_supplier_applications: `/dashboard/applications/user/${userId}/suppliers`,
    view_applications: `/dashboard/applications/user/${userId}/customers`,
    view_application: `/dashboard/applications/${target}`,
    view_connections: "/dashboard/connections/all",
    view_connection: `/dashboard/connections/details/${target}`,
    view_supplier_connection: `/dashboard/connections/details/${target}/supplier`,
    view_consumer_connection: `/dashboard/connections/details/${target}/consumer`,
    review_application: `/dashboard/applications/${target}`,
    view_user: `/dashboard/users/update/${target}`,
    view_subscription: "/dashboard/account-settings",
    set_mandatory_checks: `/dashboard/applications/${target}/request`,
    application_sent: null,
  };

  if (intentToLink.hasOwnProperty(intent)) return intentToLink[intent];

  return null;
}
/* eslint-enable max-classes-per-file */
