import { EMAIL_REGEX } from "constants";
import Button from "modules/shared/components/inputs/Button";
import TextInput from "modules/shared/components/inputs/TextInput";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";
import TradingNameSelector from "modules/shared/components/widgets/interactive/TradingNameSelector";
import CircleLogo from "modules/shared/components/widgets/static/CircleLogo";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import RegionStatesDropdown from "modules/shared/components/widgets/static/RegionStatesDropdown";
import SectionCircle from "modules/shared/components/widgets/static/SectionCircle";
import SectionDescription from "modules/shared/components/widgets/static/SectionDescription";
import { isMobile } from "modules/shared/helpers/mobileDetect";
import { userLogin } from "modules/user/actions";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { countryCodeByAlpha2 } from "utils/countryCodes";
import isPresent from "utils/isPresent";
import { checkPassStrength } from "../../../utils/password";
import {
  checkEmailisUnique,
  setEntityFormValue,
  setKeyContactFormValue,
} from "../actions/onboarding";
import { submitWebsitebuttonApplication } from "../actions/review";
import { retrievePendingApplications } from "../actions/website_button";
import styles from "./css/ApplicationStart.css";
import ApplicaitonTypeDropdown from "modules/shared/components/widgets/static/ApplicaitonTypeDropdown";

class ConsumerWebsitebuttonStart extends Component {
  state = {
    form_errors: {},
    isPendingApplicationLockedModalVisible: false,
    branchTradingnames: {},
    regionTradingnames: {},
  };

  isComplete(form_values) {
    let errors = {};
    var re = EMAIL_REGEX;
    if (!form_values) {
      var { form_values } = this.props;
    }
    var re = EMAIL_REGEX;
    let isEmailValid = true;
    if (this.props.admin_entities.length > 0 && !form_values.entity_id) {
      isEmailValid = false;
    }

    if (this.props.email_validating) {
      isEmailValid = false;
    }

    if (
      re.test(form_values.key_contact_email) &&
      !this.props.supplier_users.some(
        u => u.email == form_values.key_contact_email,
      ) &&
      form_values.key_contact_password !== "" &&
      form_values.entity_region.length > 0 &&
      (form_values.entity_region === "NZ" ||
        (form_values.entity_region === "AU" &&
          form_values.entity_state &&
          form_values.entity_state.length > 0)) &&
      isEmailValid &&
      (!this.props.need_supplier_trading_name_id ||
        !!this.props.supplier_trading_name_id)
    ) {
      return true;
    }
    if (form_values.entity_region === "") {
      errors = {
        ...errors,
        entity_region: "You must enter your company location",
      };
    } else {
      if (form_values.entity_state === "") {
        errors = { ...errors, entity_state: "State" };
      }
    }
    if (form_values.key_contact_password === "") {
      errors = {
        ...errors,
        key_contact_password: "You must enter your password",
      };
    }
    if (!re.test(form_values.key_contact_email)) {
      errors = { ...errors, key_contact_email: "You must enter a valid email" };
    }
    if (
      this.props.supplier_users.some(
        u => u.email === form_values.key_contact_email,
      )
    ) {
      errors = {
        ...errors,
        key_contact_email:
          "Oops! Internal trade account invites aren't allowed. Use a different email & try again.",
      };
    }
    if (
      this.props.email_valid === false &&
      this.props.email_validating === false &&
      this.props.admin_entities.length &&
      !form_values.entity_id
    ) {
      errors = { ...errors, entity_id: "You must enter your entity" };
    }
    if (
      this.props.need_supplier_trading_name_id &&
      !form_values.supplier_trading_name_id
    ) {
      errors = {
        ...errors,
        supplier_trading_name_id: "You must select a region of supplier",
      };
    }
    this.setState({
      form_errors: errors,
    });
    return false;
  }

  handleChange = event => {
    const { dispatch } = this.props;
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
      if (event.target.id === "key_contact_email") {
        dispatch(setEntityFormValue("entity_id", null));
        const re = EMAIL_REGEX;
        if (re.test(event.target.value) && event.target.value.length > 0) {
          dispatch(checkEmailisUnique(event.target.value));
        }
      }
    }
    dispatch(setKeyContactFormValue(event.target.name, event.target.value));
  };

  handleBlur = event => {
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
      if (event.target.id === "key_contact_email") {
        this.checkEmail();
      }
    }
  };

  applicationTypeHandleChange = event => {
    const { dispatch } = this.props;
    const processedValue =
      typeof event === "object" ? event.target.value : event;

    dispatch(setEntityFormValue("application_type", processedValue));
  };

  regionStateHandleChange = target => {
    const { dispatch } = this.props;
    if (target) {
      this.checkValid(target);
    }
    dispatch(setEntityFormValue(target.name, target.value));

    if (target.name === "entity_region") {
      const _countryCode = countryCodeByAlpha2(target.value);
      dispatch(
        setKeyContactFormValue("key_contact_country_code", _countryCode),
      );
    }
  };

  countryCodeHandleChange = target => {
    const { dispatch } = this.props;

    if (target) {
      this.checkValid(target);
    }
    dispatch(setKeyContactFormValue(target.name, target.value));
  };

  entityHandleChange = target => {
    const { dispatch, form_values, params } = this.props;
    if (target) {
      this.checkValid(target);
    }
    dispatch(setEntityFormValue(target.name, target.value));
    dispatch(
      retrievePendingApplications(
        form_values.key_contact_email,
        target.value,
        params.entity_id,
      ),
    );
  };

  supplierTradingNameClickCallback = supplier_trading_name_id => {
    const {
      dispatch,
      form_values,
      consumer_id,
      supplier_trading_names,
    } = this.props;

    const eid = supplier_trading_names.find(
      x => x.id === supplier_trading_name_id,
    ).entity_id;

    dispatch(
      setEntityFormValue("supplier_trading_name_id", supplier_trading_name_id),
    );

    dispatch(
      retrievePendingApplications(
        form_values.key_contact_email,
        consumer_id,
        eid,
      ),
    );
  };

  submit = () => {
    const {
      dispatch,
      params,
      pendingApplication,
      supplier_trading_name_id,
    } = this.props;

    if (!this.isComplete()) {
      return;
    }

    let qr_mode = window.location.href.includes("channel=qr") ? true : false;

    if (isPresent(pendingApplication) && isPresent(pendingApplication.id)) {
      this.redirectUserToPendingApplication();
    } else {
      dispatch(
        submitWebsitebuttonApplication(
          params.entity_id,
          supplier_trading_name_id,
          qr_mode,
        ),
      );
    }
  };

  redirectUserToPendingApplication() {
    const { dispatch, form_values, pendingApplication } = this.props;

    if (!pendingApplication.is_consumer_contact) {
      this.setState({ isPendingApplicationLockedModalVisible: true });
      return;
    }

    if (this.isPendingApplicationLocked()) {
      dispatch(
        userLogin(
          form_values.key_contact_email,
          form_values.key_contact_password,
          undefined,
          () => {
            this.setState({ isPendingApplicationLockedModalVisible: true });
          },
          pendingApplication.consumer_id,
        ),
      );

      return;
    }

    const redirectUrl = `/register/consumer/${pendingApplication.id}/start`;

    dispatch(
      userLogin(
        form_values.key_contact_email,
        form_values.key_contact_password,
        redirectUrl,
        null,
        pendingApplication.consumer_id,
      ),
    );
  }

  isPendingApplicationLocked() {
    const { pendingApplication } = this.props;
    const completeStatus = ["accepted", "declined"];

    return (
      pendingApplication.final_approval_status !== "not_started" ||
      completeStatus.includes(pendingApplication.status)
    );
  }

  showPendingApplicationSelection = () => {
    this.setState({ isPendingApplicationSelectionVisible: true });
  };

  checkEmail(props = this.props) {
    const value = props.form_values["key_contact_email"];
    const re = EMAIL_REGEX;
    if (!re.test(value)) {
      this.setState({
        form_errors: {
          ...this.state.form_errors,
          key_contact_email: "You must enter a valid email",
        },
      });
    } else if (props.supplier_users.some(u => u.email == value)) {
      this.setState({
        form_errors: {
          ...this.state.form_errors,
          key_contact_email:
            "Oops! Internal trade account invites aren't allowed. Use a different email & try again.",
        },
      });
    } else {
      const { key_contact_email, ...rest_errors } = this.state.form_errors;
      this.setState({
        form_errors: { ...rest_errors },
      });
    }
  }

  checkValid(target) {
    // validate, update progress
    const { form_errors } = this.state;

    switch (target.name) {
      case "entity_region":
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              entity_region: "You must enter your company location",
            },
          });
        } else {
          const { entity_region, ...rest_errors } = form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case "entity_state":
        if (target.value.length < 1) {
          this.setState({
            form_errors: { ...form_errors, entity_state: "State" },
          });
        } else {
          const { entity_state, ...rest_errors } = form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case "key_contact_password":
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              key_contact_password: "You must enter your password",
            },
          });
        } else {
          const { key_contact_password, ...rest_errors } = form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case "entity_id":
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              entity_id: "You must enter your entity",
            },
          });
        } else {
          const { entity_id, ...rest_errors } = form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
    }
  }

  renderForm() {
    const {
      applicationTypes,
      admin_entities,
      email_valid,
      email_validating,
      forgotPasswordRedirectPath,
      form_inputs,
      form_values,
      need_supplier_trading_name_id,
      supplier_logo_url,
      supplier_store_name_alias,
      title,
      websitebutton_saving,
      tradingNames,
      dispatch,
      locationName,
      params,
      is_regular_supplier,
    } = this.props;
    const { form_errors } = this.state;
    const form_elements = [];
    const form_loading = { key_contact_email: email_validating };
    const isExistingUser = admin_entities.length;

    if (supplier_store_name_alias) {
      defaults.form_inputs.supplier_trading_name_id.label = `Pick your ${supplier_store_name_alias ||
        "branch"}`;
    }

    if (applicationTypes.length < 2) {
      delete defaults.form_inputs.application_type;

      if (
        applicationTypes.length === 1 &&
        form_values["application_type"] !== applicationTypes[0]
      ) {
        this.applicationTypeHandleChange(applicationTypes[0]);
      }
    }

    const passwordLabel = isExistingUser ? "Enter password" : "Create password";

    Object.keys(form_inputs).forEach((value, index) => {
      switch (value) {
        case "application_type":
          form_elements.push(
            <ApplicaitonTypeDropdown
              key={index}
              id={value}
              error={form_errors[value]}
              handleChange={this.applicationTypeHandleChange}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
            />,
          );
          break;
        case "key_contact_password":
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              type="password"
              error={form_errors[value]}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label={passwordLabel || form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
            />,
          );
          break;
        case "key_contact_email":
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              type={form_inputs[value].type}
              error={form_errors[value]}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value].toLowerCase()}
            />,
          );
          break;
        case "entity_region":
          form_elements.push(
            <RegionStatesDropdown
              key={index}
              id={value}
              regionId="entity_region"
              stateId="entity_state"
              label={form_inputs[value].label}
              regionError={form_errors["entity_region"]}
              stateError={form_errors["entity_state"]}
              regionValue={form_values["entity_region"]}
              stateValue={form_values["entity_state"]}
              required={form_inputs[value].required}
              handleChange={this.regionStateHandleChange}
            />,
          );
          break;
        case "entity_id":
          if (!email_validating && isExistingUser) {
            form_elements.push(
              <OptionsDropdown
                id={value}
                key={index}
                handleChange={this.entityHandleChange}
                label={form_inputs[value].label}
                value={form_values[value] || ""}
                error={form_errors[value]}
                required={true}
                options={admin_entities}
              />,
            );
          }
          break;
        case "supplier_trading_name_id":
          if (need_supplier_trading_name_id) {
            let _options = [];

            if (tradingNames && !is_regular_supplier) {
              tradingNames
                .filter(x => x.type === "Region")
                .forEach(region => {
                  let _item = {};
                  _item["label"] = region.trading_name;
                  _item["value"] = region.id;

                  _item["children"] = [];

                  tradingNames
                    .filter(
                      x =>
                        x.type === "Branch" && x.parent_id === region.entity_id,
                    )
                    .forEach(item => {
                      _item["children"].push({
                        label: item.trading_name,
                        value: item.id,
                      });
                    });

                  // _item["children"].push({
                  //   label: region.trading_name,
                  //   value: region.id,
                  // });

                  _options.push(_item);
                });
              // hq
              // tradingNames
              //   .filter(x => x.type === "Headquarter")
              //   .forEach(hq => {
              //     let _item = {};
              //     _item["label"] = hq.trading_name;
              //     _item["value"] = hq.id;

              //     _item["children"] = [];
              //     _options.push(_item);
              //   });
            }

            if (tradingNames && is_regular_supplier) {
              tradingNames
                .filter(x => !x.hide && x.name_type === "store_name")
                .forEach(trading_name => {
                  let _item = {};
                  _item["label"] = trading_name.trading_name;
                  _item["value"] = trading_name.id;

                  _options.push(_item);
                });
            }

            form_elements.push(
              <TradingNameSelector
                id={value}
                key={index}
                handleClickCallback={this.supplierTradingNameClickCallback}
                label={form_inputs[value].label}
                error={form_errors[value]}
                required={true}
                entityId={this.props.params.entity_id}
                placeholder="Start typing any part of the location name here."
                tradingNames={_options}
                dispatch={dispatch}
                locationName={locationName}
                // show={true}
              />,
            );
          }
          break;
        default:
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              type={form_inputs[value].type}
              error={form_errors[value]}
              loading={form_loading[value]}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
            />,
          );
          break;
      }
    });

    let password_strength = <div className={styles.strength}>&nbsp;</div>;
    let strength = 0;
    if (form_values.key_contact_password.length > 0) {
      const indicators = [];
      strength = checkPassStrength(form_values.key_contact_password);

      for (let i = 0; i < 3; i++) {
        if (parseInt(strength) <= i) {
          indicators.push(
            <div className={styles.indicator_off} key={`indicator-${i}`}></div>,
          );
        } else {
          indicators.push(
            <div className={styles.indicator_on} key={`indicator-${i}`}></div>,
          );
        }
      }
      password_strength = (
        <div className={styles.strength} key="strength">
          <span className={styles.label}>Password strength</span>{" "}
          <span className={styles.indicators}>{indicators}</span>
        </div>
      );
    }

    let forgotPasswordPath = "/user/forgot-password";
    if (isPresent(forgotPasswordRedirectPath)) {
      forgotPasswordPath = `${forgotPasswordPath}?redirect=${forgotPasswordRedirectPath}`;
    }

    let content_style = styles.content;

    let heading = (
      <div className="is-flex is-flex-direction-column is-justify-content-flex-end is-flex-grow-1">
        {supplier_logo_url && (
          <div className="is-flex is-justify-content-center">
            <div className={styles.nav_logo_mw}>
              <img
                src={supplier_logo_url}
                alt="Your logo"
                className={styles.logo}
              />
            </div>
          </div>
        )}

        <div
          className={`is-flex is-justify-content-center ${styles.heading_text}`}
        >
          {title}
        </div>
      </div>
    );

    if (isMobile()) {
      heading = (
        <div
          className={`hero-head has-background-primary has-text-centered ${styles.heading_height}`}
        >
          {supplier_logo_url && (
            <img
              className={`${styles.mobile_logo} px-1 py-1`}
              src={supplier_logo_url}
              alt="Your logo"
            />
          )}
          <div
            className={`is-size-normal has-text-left has-text-weight-normal ${styles.mobile_heading_title}`}
          >
            {title}
          </div>
        </div>
      );

      content_style = styles.mobile_content;
    }

    let footer = (
      <div className="hero-foot has-text-centered has-text-weight-normal">
        <div className="context is-size-small my-5 has-text-black">
          <div className="">Powered by 1Centre</div>
          <div className="mt-4 mb-6">
            Please refer to 1Centre's{" "}
            <a
              target="_blank"
              href="https://www.1centre.com/Privacy/index.html"
              className="has-text-link"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
            .
          </div>
        </div>
      </div>
    );

    if (isMobile()) {
      footer = (
        <div
          className={`${styles.hero_footer} has-text-centered has-text-weight-normal has-background-white`}
        >
          <div className="context is-size-small my-5 ">
            <div className="">Powered by 1Centre</div>
            <div className="my-4">
              Please refer to 1Centre's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.1centre.com/Privacy/index.html"
                className={` ${styles.underline}`}
              >
                privacy policy
              </a>
              .
            </div>
          </div>
        </div>
      );
    }

    let showContent = (
      <div className={`hero-body has-text-left px-8 py-0 ${styles.mt120}`}>
        {/* <div className="container">{content}</div> */}
        <div className={styles.full_col}>
          <div className={styles.form}>
            {form_elements}
            {password_strength}
          </div>
          <div className={styles.forgot_password}>
            <Link className={styles.link} to={forgotPasswordPath}>
              Forgotten your password?
            </Link>
          </div>
          <div
            className={`${styles.buttons} ${styles.float_none} has-text-centered`}
          >
            <Button
              text="get started"
              handleClick={this.submit}
              loading_text="submitting"
              disableOnLoading={true}
              loading={websitebutton_saving}
            />
          </div>
        </div>
      </div>
    );

    if (isMobile()) {
      showContent = (
        <div
          className={`${styles.mt60} ${styles.px60} has-text-left has-text-centered  py-0`}
        >
          {/* <div className="container mt-6">{content}</div> */}
          <div className={styles.full_col}>
            <div className={styles.form}>
              {form_elements}
              {password_strength}
            </div>
            <div className={styles.forgot_password}>
              <Link className={styles.link} to={forgotPasswordPath}>
                Forgotten your password?
              </Link>
            </div>
            <div
              className={`${styles.buttons} ${styles.float_none} has-text-centered`}
            >
              <Button
                text="get started"
                handleClick={this.submit}
                loading_text="submitting"
                disableOnLoading={true}
                loading={websitebutton_saving}
              />
            </div>
          </div>
        </div>
      );
    }

    if (isMobile()) {
      return (
        <React.Fragment>
          <div className="columns my-0">
            <div className="column px-0 py-0">
              <section
                className={`hero ${styles.mobile_fullheight} has-background-white`}
              >
                {heading}
                {showContent}
                {footer}
              </section>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="columns my-0">
          <div className="column is-3 is-hidden-touch px-0 py-0">
            <section className={`hero is-primary is-fullheight `} />
          </div>
          <div className="column px-0 py-0">
            <section className="is-flex is-flex-direction-column is-justify-content-space-around	is-full-height has-background-white">
              {heading}
              {showContent}
              {footer}
            </section>
          </div>
          <div className="column is-3 is-hidden-touch px-0 py-0">
            <section className="hero is-primary is-fullheight" />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderPendingApplicationModal() {
    const { pendingApplication, supplier_trading_name } = this.props;

    let text = `You already have an application with ${supplier_trading_name} and is currently being reviewed.`;

    if (!pendingApplication.is_consumer_contact) {
      text = `An application has already been submitted to ${supplier_trading_name} by ${pendingApplication.consumer_contact_name}.`;
    }

    return (
      <SectionCircle>
        <SectionDescription text={text} />
      </SectionCircle>
    );
  }

  render() {
    if (this.state.isPendingApplicationLockedModalVisible) {
      return this.renderPendingApplicationModal();
    }

    return this.renderForm();
  }
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  title: "Start your application with Application_supplier here.",
  header_title: "Digital trade credit application",
  form_inputs: {
    application_type: {
      label: "Application type",
      required: true,
      type: "text",
    },
    entity_region: {
      label: "Country",
      required: true,
      type: "text",
    },
    key_contact_email: {
      label: "Email",
      required: true,
      type: "email",
    },
    entity_id: {
      label: "Your entity name",
      required: true,
    },
    supplier_trading_name_id: {
      label: "Location",
      required: true,
    },
    key_contact_password: {
      label: "Create password",
      required: true,
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

function sortEntitiesByName(entities) {
  return entities.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }

    if (a.label > b.label) {
      return 1;
    }

    return 0;
  });
}

export default connect((state, ownProps) => {
  let admin_entities = state.cob_business.user_entity_links || [];
  admin_entities = admin_entities
    .filter(entity => entity.role_types.includes("admin"))
    .map(entity => {
      return {
        id: "entity_id",
        label: entity.entity_name,
        value: entity.entity_id,
      };
    });

  const entitiesByDomain = state.cob_business.entitiesByDomain.map(entity => {
    return { id: "entity_id", label: entity.name, value: entity.id };
  });

  const isWebsitebuttonCentralize =
    ownProps.supplierWebsitebuttonMode !== "decentralize";

  const supplier_company_name =
    state.cob_section.supplier.attributes.title ||
    state.cob_section.supplier.attributes.trading_name;

  // only supplier_store_name_alias and decentralize region and centralize needs the dropdown
  let need_supplier_trading_name_id = false;

  if (!!ownProps.supplier_store_name_alias)
    need_supplier_trading_name_id = true;

  if (ownProps.is_hq_supplier) need_supplier_trading_name_id = true;

  if (
    isWebsitebuttonCentralize &&
    (ownProps.is_branch_supplier || ownProps.is_region_supplier)
  )
    need_supplier_trading_name_id = true;

  // decentralize region
  if (!isWebsitebuttonCentralize && ownProps.is_region_supplier)
    need_supplier_trading_name_id = true;

  return {
    admin_entities: sortEntitiesByName([
      ...admin_entities,
      ...entitiesByDomain,
    ]),
    consumer_id: state.cob_business.entity_id,
    email_valid: state.cob_business.email_valid,
    email_validating: state.cob_business.email_validating,
    form_inputs: defaults.form_inputs,
    form_values: {
      entity_id: state.cob_business.entity_id,
      entity_region: state.cob_business.entity_region,
      entity_state: state.cob_business.entity_state,
      key_contact_email: state.cob_business.key_contact_email,
      key_contact_password: state.cob_business.key_contact_password,
      supplier_trading_name_id: state.cob_business.supplier_trading_name_id,
      application_type: state.cob_business.application_type,
    },
    is_regular_supplier: ownProps.is_regular_supplier,
    need_supplier_trading_name_id: need_supplier_trading_name_id,
    pendingApplication: state.cob_business.pendingApplication,
    supplier_trading_name_id: state.cob_business.supplier_trading_name_id,
    supplier_trading_names: state.shared_data.trading_names,
    title: defaults.title.replace(
      "Application_supplier",
      supplier_company_name,
    ),
    websitebutton_saving: state.cob_review.websitebutton_saving,
    supplier_company_name,
    tradingNames: ownProps.supplier_store_name_alias
      ? state.cob_section.supplier.attributes.store_names
      : state.shared_data.trading_names,
    locationName: state.cob_section.supplier.attributes.location,
  };
})(ConsumerWebsitebuttonStart);
