/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

/* Import actions */
import {
  loadUserApplicationContacts,
  loadUserConnectionContacts,
} from "../actions";

/* Import components */
import CircleModal from "modules/shared/components/widgets/static/CircleModal";
import Button from "modules/shared/components/inputs/Button";
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";

/* Import CSS */
import styles from "./css/UpdateUser.css";

var RemoveUser = createClass({
  componentWillMount: function() {
    const { dispatch, user } = this.props;
    dispatch(loadUserApplicationContacts(user.id));
    dispatch(loadUserConnectionContacts(user.id));
  },

  remove: function() {
    const { dispatch, user, onRemove } = this.props;
    this.setState({
      removing: true,
    });
    onRemove(user);
  },

  edit: function() {
    const { location, user } = this.props;
    browserHistory.push({
      pathname: `/dashboard/users/update/${user.id}`,
      //query: location.query
    });
  },

  cancel: function() {
    const { onCancel } = this.props;
    onCancel();
  },

  getInitialState: function() {
    return {
      removing: false,
    };
  },

  render: function() {
    const {
      title,
      user,
      total_applications,
      total_connections,
      loading,
      removing,
    } = this.props;

    if (!user) {
      return <div />;
    }
    const total = total_applications + total_connections;
    let content;
    if (loading) {
      content = <SimpleLoader css_class="loader_relative" />;
    } else if (total < 1) {
      content = (
        <div>
          <p>Once removed a user cannot be retrieved.</p>
          <Button
            text={"remove"}
            loading_text={"removing"}
            handleClick={this.remove}
            loading={removing || this.state.removing}
            disableOnLoading={true}
          />
        </div>
      );
    } else {
      content = (
        <div>
          <p>Once removed a user cannot be retrieved.</p>
          <p>
            {user.attributes.full_name} is key contact for {total} existing
            trade account(s).
          </p>
          <p>Please reallocate these account before trying to remove.</p>
          <Button text={"edit user"} handleClick={this.edit} />
        </div>
      );
    }

    return (
      <CircleModal title={title} dismissHandler={this.cancel}>
        {content}
      </CircleModal>
    );
  },
});

var defaults = {
  title: "Remove user?",
};

module.exports = connect((state, ownProps) => {
  let applications = state.manage_users.user_applications,
    connections = state.manage_users.user_connections;

  applications = applications.filter(application => {
    return application.attributes.status !== "deleted";
  });

  let loading =
    state.manage_users.user_applications_loading ||
    state.manage_users.user_connections_loading;

  return {
    title: defaults.title,
    loading,
    total_applications: state.manage_users.total_user_applications,
    total_connections: state.manage_users.total_user_connections,
  };
})(RemoveUser);
