/* Import libraries */
import React, { Component } from "react";
import {
  setAdditionalFields,
  setAnswer,
  setFinancialsFile,
  removeAdditonalFiels
} from "modules/consumer-onboarding/actions/financials";
import FinancialsForm from "modules/shared/components/widgets/interactive/FinancialsForm";
import { connect } from "react-redux";
import styles from "./css/FinancialsDetails.css";

class FinancialsDetails extends Component {
  constructor() {
    super();
    this.state = {
      triggerValidation: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      handleComplete,
      validationTrigger,
      page_validation_start,
      setPageValidationStartFinish,
      summary_validation_start,
    } = props;
    const isComplete = FinancialsDetails.isComplete(props);
    if (handleComplete) handleComplete(isComplete, "financials");
    if (isComplete && (page_validation_start || summary_validation_start)) {
      setPageValidationStartFinish();
    }
    return {
      triggerValidation:
        state.triggerValidation ||
        page_validation_start ||
        summary_validation_start ||
        validationTrigger,
    };
  }

  static isComplete(props) {
    const { config, financials, sharedDataFinancails } = props;

    let invalid = Object.keys(sharedDataFinancails).some(
      key =>
        key.indexOf(defaults.moduleName) === 0 &&
        sharedDataFinancails[key].includes(false),
    );

    if (
      config.mandatory_fields.includes("file") &&
      (!financials.file ||
        !Object.prototype.hasOwnProperty.call(financials.file, "url"))
    ) {
      invalid = true;
    }

    return !invalid;
  }

  componentDidMount() {
    this.resetComponentValues("assets")
    this.resetComponentValues("liabilities")
    this.resetComponentValues("income")
    this.resetComponentValues("expenses")
  }

  resetComponentValues(key) {
    const { config, financials, dispatch } = this.props;
    const additionalFields = config.additional_fields
    if (!financials.answers || !financials.answers[key]) return;
    const questions = (additionalFields || {})[key];
    // Because the forEach loop will remove elements in answers,
    // so we need a deep copy here
    const answers = [...financials.answers[key]];

    answers.forEach(item => {
      const validAnswer = questions.find(q => q.field_description_1 === item.description_1 && q.field_description_2 === item.description_2)
      if (!validAnswer) {
        dispatch(removeAdditonalFiels(key, item))
      }
    })
    this.calculateTotal(key)
  }

  setComponentValue(key, params) {
    const { dispatch } = this.props;
    dispatch(setAdditionalFields(key, params)).then(() => {
      this.calculateTotal(key);
    });
  }

  calculateTotal(key) {
    const { financials, dispatch } = this.props;
    let total = 0;
    financials.answers[key].forEach(item => {
      total += parseInt(item.value_2 || 0);
    });
    dispatch(setAnswer(`total_${key}`, total)).then(() => {
      this.calculateNetWorthAndSurplus(key);
    });
  }

  calculateNetWorthAndSurplus(key) {
    const { financials, dispatch } = this.props;
    switch (key) {
      case "assets":
      case "liabilities":
        dispatch(
          setAnswer(
            "net_worth",
            financials.answers.total_assets -
              financials.answers.total_liabilities,
          ),
        );
        break;
      case "income":
      case "expenses":
        dispatch(
          setAnswer(
            "surplus",
            financials.answers.total_income - financials.answers.total_expenses,
          ),
        );
        break;
    }
  }

  setFinancialsFile(data) {
    const { dispatch } = this.props;

    dispatch(setFinancialsFile({ url: data }));

    this.setState({ fileErrorMessage: "" });
  }

  render() {
    const {
      collapsible,
      config,
      financials,
      page_validation_start,
    } = this.props;

    return (
      <section className={styles.section}>
        <FinancialsForm
          selected={config.mandatory_fields}
          fileSrc={financials.file}
          components={config.additional_fields}
          handleChange={(key, params) => {
            this.setComponentValue(key, params);
          }}
          fileHandleChange={data => {
            this.setFinancialsFile(data);
          }}
          answers={financials.answers}
          validationTrigger={this.state.triggerValidation}
          page_validation_start={page_validation_start}
          collapsible={collapsible}
        />
      </section>
    );
  }
}

const defaults = {
  moduleName: "financials",
};

module.exports = connect((state, ownProps) => {
  const previewAddon = ownProps.previewAddon;
  const addon = state.cob_section.addonRules.find(
    rule => rule.attributes.addon_module_name === defaults.moduleName,
  );
  const sharedDataFinancails = Object.keys(state.shared_data)
    .filter(key => key.indexOf(defaults.moduleName) === 0)
    .reduce((obj, key) => {
      obj[key] = state.shared_data[key];
      return obj;
    }, {});
  return {
    config: previewAddon
      ? previewAddon.config
      : addon.attributes.addon_version.data.attributes.config,
    financials: state.cob_financials,
    sharedDataFinancails,
  };
})(FinancialsDetails);
