/* eslint-disable max-len */
import get from "lodash.get";
import {
  ADD_ONS_ADDITIONAL_CREATE_RULESET_ERROR,
  ADD_ONS_ADDITIONAL_CREATE_RULESET_START,
  ADD_ONS_ADDITIONAL_CREATE_RULESET_SUCCESS,
  ADD_ONS_ADDITIONAL_LOAD_ERROR,
  ADD_ONS_ADDITIONAL_LOAD_START,
  ADD_ONS_ADDITIONAL_LOAD_SUCCESS,
  ADD_ONS_ADDITIONAL_RESET_CURRENT,
  ADD_ONS_ADDITIONAL_SET_CURRENT,
  ADD_ONS_ADDITIONAL_SET_VALUE,
  ADD_ONS_ADDITIONAL_UPDATE_RULESET_ERROR,
  ADD_ONS_ADDITIONAL_UPDATE_RULESET_START,
  ADD_ONS_ADDITIONAL_UPDATE_RULESET_SUCCESS,
  ADD_ONS_ALERT_CREATE_RULESET_ERROR,
  ADD_ONS_ALERT_CREATE_RULESET_START,
  ADD_ONS_ALERT_CREATE_RULESET_SUCCESS,
  ADD_ONS_ALERT_LOAD_ERROR,
  ADD_ONS_ALERT_LOAD_START,
  ADD_ONS_ALERT_LOAD_SUCCESS,
  ADD_ONS_ALERT_RESET_CURRENT,
  ADD_ONS_ALERT_SET_CONFIG_VALUE,
  ADD_ONS_ALERT_SET_CURRENT,
  ADD_ONS_ALERT_SET_VALUE,
  ADD_ONS_ALERT_UPDATE_RULESET_ERROR,
  ADD_ONS_ALERT_UPDATE_RULESET_START,
  ADD_ONS_ALERT_UPDATE_RULESET_SUCCESS,
  ADD_ONS_AUTO_DECISION_LOAD_ERROR,
  ADD_ONS_AUTO_DECISION_LOAD_START,
  ADD_ONS_AUTO_DECISION_LOAD_SUCCESS,
  ADD_ONS_AUTO_DECISION_TOGGLE_ERROR,
  ADD_ONS_AUTO_DECISION_TOGGLE_START,
  ADD_ONS_AUTO_DECISION_TOGGLE_SUCCESS,
  ADD_ONS_CARDS_CREATE_RULESET_ERROR,
  ADD_ONS_CARDS_CREATE_RULESET_START,
  ADD_ONS_CARDS_CREATE_RULESET_SUCCESS,
  ADD_ONS_CARDS_LOAD_ERROR,
  ADD_ONS_CARDS_LOAD_START,
  ADD_ONS_CARDS_LOAD_SUCCESS,
  ADD_ONS_CARDS_RESET_CURRENT,
  ADD_ONS_CARDS_SET_CURRENT,
  ADD_ONS_CARDS_SET_VALUE,
  ADD_ONS_CARDS_UPDATE_RULESET_ERROR,
  ADD_ONS_CARDS_UPDATE_RULESET_START,
  ADD_ONS_CARDS_UPDATE_RULESET_SUCCESS,
  ADD_ONS_FINANCIALS_CREATE_RULESET_ERROR,
  ADD_ONS_FINANCIALS_CREATE_RULESET_START,
  ADD_ONS_FINANCIALS_CREATE_RULESET_SUCCESS,
  ADD_ONS_FINANCIALS_LOAD_ERROR,
  ADD_ONS_FINANCIALS_LOAD_START,
  ADD_ONS_FINANCIALS_LOAD_SUCCESS,
  ADD_ONS_FINANCIALS_RESET_CURRENT,
  ADD_ONS_FINANCIALS_SET_ADDITIONAL_FIELDS,
  ADD_ONS_FINANCIALS_SET_CURRENT,
  ADD_ONS_FINANCIALS_SET_ENTITY_TYPE,
  ADD_ONS_FINANCIALS_SET_INDICATOR_FIELDS,
  ADD_ONS_FINANCIALS_SET_MANDATORY_FIELDS,
  ADD_ONS_FINANCIALS_SET_VALUE,
  ADD_ONS_FINANCIALS_UPDATE_RULESET_ERROR,
  ADD_ONS_FINANCIALS_UPDATE_RULESET_START,
  ADD_ONS_FINANCIALS_UPDATE_RULESET_SUCCESS,
  ADD_ONS_LOAD_ERROR,
  ADD_ONS_LOAD_START,
  ADD_ONS_LOAD_SUCCESS,

  ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_ERROR,
  ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_START,
  ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_SUCCESS,
  ADD_ONS_INTERNAL_USE_FIELDS_LOAD_ERROR,
  ADD_ONS_INTERNAL_USE_FIELDS_LOAD_START,
  ADD_ONS_INTERNAL_USE_FIELDS_LOAD_SUCCESS,
  ADD_ONS_INTERNAL_USE_FIELDS_RESET_CURRENT,
  ADD_ONS_INTERNAL_USE_FIELDS_SET_CONFIG_VALUE,
  ADD_ONS_INTERNAL_USE_FIELDS_SET_CURRENT,
  ADD_ONS_INTERNAL_USE_FIELDS_SET_VALUE,
  ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_ERROR,
  ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_START,
  ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_SUCCESS,

  ADD_ONS_PAPERLESS_CREATE_RULESET_ERROR,
  ADD_ONS_PAPERLESS_CREATE_RULESET_START,
  ADD_ONS_PAPERLESS_CREATE_RULESET_SUCCESS,
  ADD_ONS_PAPERLESS_LOAD_ERROR,
  ADD_ONS_PAPERLESS_LOAD_START,
  ADD_ONS_PAPERLESS_LOAD_SUCCESS,
  ADD_ONS_PAPERLESS_RESET_CURRENT,
  ADD_ONS_PAPERLESS_SET_CONFIG_VALUE,
  ADD_ONS_PAPERLESS_SET_CURRENT,
  ADD_ONS_PAPERLESS_SET_VALUE,
  ADD_ONS_PAPERLESS_UPDATE_RULESET_ERROR,
  ADD_ONS_PAPERLESS_UPDATE_RULESET_START,
  ADD_ONS_PAPERLESS_UPDATE_RULESET_SUCCESS,
  BRANCHES_LOAD_END,
  BRANCHES_LOAD_START,
  BRANCHES_LOAD_SUCCESS,
} from "./constants";

const CARDS_DEFAULT_CONFIG = {
  additional_fields: [],
  mandatory: true,
};

const CARDS_DEFAULT_SETTINGS = {
  cardIdToUpdate: null,
  cards: [],
  current_card: {
    attributes: {
      addon_module_name: "cards",
      config: { ...CARDS_DEFAULT_CONFIG },
      legal_types: [],
      max_credit_value: 0,
      min_credit_value: 0,
      name: "",
    },
    serial_number: null,
  },
  isCardCurrentlyUpdating: false,
  isCardsLoading: false,
};

const ALERT_DEFAULT_SETTINGS = {
  current_alert: {
    attributes: {
      addon_module_name: "alert_module",
      config: {},
      alert_types: [],
    },
  },
};

const addOnsDefaults = {
  add_ons_errors: null,
  add_ons_loading: false,
  add_ons_types: {
    auto_decisioning: {
      title: "Auto Decisioning",
    },
  },
  additional: [],
  additional_active_updating: false,
  additional_active_updating_id: null,
  additional_loading: false,
  additional_updating: false,
  auto_decisioning: {
    current_rule_set: null,
  },
  auto_decisions: [],
  auto_decisions_enabling_loading: null,
  auto_decisions_errors: null,
  auto_decisions_loading: false,
  branches: [],
  current_additional: {
    attributes: {
      addon_module_name: "additional",
      config: { components: [] },
      legal_types: [],
      application_types: [],
      max_credit_value: 0,
      min_credit_value: 0,
      name: null,
    },
    serial_number: null,
  },
  current_financials: {
    attributes: {
      addon_module_name: "financials",
      config: {
        additional_fields: {
          assets: [
            {
              component_type: "pair_question",
              field_description_1: "Address of property ownership",
              field_description_2: "Property valuation",
              indicator: false,
              mandatory: true,
            },
          ],
          expenses: [],
          income: [],
          liabilities: [
            {
              component_type: "pair_question",
              field_description_1: "Name of mortgagee - bank/lender",
              field_description_2: "Outstanding mortgage owed",
              indicator: false,
              mandatory: true,
            },
          ],
        },
        indicator_fields: [],
        mandatory_fields: [],
      },
      legal_types: [],
      max_credit_value: 0,
      min_credit_value: 0,
      name: null,
    },
    serial_number: null,
  },
  current_paperless: {
    attributes: {
      addon_module_name: "paperless",
      config: {
        mandatory: false,
        terms: "",
      },
      legal_types: [],
      max_credit_value: 0,
      min_credit_value: 0,
      name: null,
    },
    serial_number: null,
  },
  current_internal_use_fields: null,
  financials: [],
  financials_active_updating: false,
  financials_active_updating_id: null,
  financials_loading: false,
  financials_updating: false,
  internal_use_fields: [],
  internal_use_fields_active_updating: false,
  internal_use_fields_active_updating_id: null,
  internal_use_fields_loading: false,
  internal_use_fields_updating: false,
  loading_branches: false,
  paperless: [],
  paperless_active_updating: false,
  paperless_active_updating_id: null,
  paperless_loading: false,
  paperless_updating: false,
  ...CARDS_DEFAULT_SETTINGS,
  ...ALERT_DEFAULT_SETTINGS,
};

function setCurrentAlert(data) {
  if(!data || data.length === 0) {
    return addOnsDefaults.current_alert;
  }
  return get(data[0], "attributes");
};

// eslint-disable-next-line complexity
export function addOnsReducer(state = addOnsDefaults, action) {
  switch (action.type) {
    case ADD_ONS_LOAD_START:
      return { ...state, add_ons_loading: true };
    case ADD_ONS_LOAD_SUCCESS:
      return {
        ...state,
        add_ons_loading: false,
        add_ons_types: action.payload,
      };
    case ADD_ONS_LOAD_ERROR:
      return {
        ...state,
        add_ons_loading: false,
        add_ons_types_erros: action.payload,
      };
    case ADD_ONS_AUTO_DECISION_LOAD_START:
      return { ...state, auto_decisions_loading: true };
    case ADD_ONS_AUTO_DECISION_LOAD_SUCCESS:
      return {
        ...state,
        auto_decisions: action.payload,
        auto_decisions_loading: false,
      };
    case ADD_ONS_AUTO_DECISION_LOAD_ERROR:
      return {
        ...state,
        auto_decisions_errors: action.payload,
        auto_decisions_loading: false,
      };
    case ADD_ONS_AUTO_DECISION_TOGGLE_START:
      return { ...state, auto_decisions_enabling_loading: action.payload };
    case ADD_ONS_AUTO_DECISION_TOGGLE_SUCCESS:
      return {
        ...state,
        auto_decisions: action.payload,
        auto_decisions_enabling_loading: null,
      };
    case ADD_ONS_AUTO_DECISION_TOGGLE_ERROR:
      return {
        ...state,
        auto_decisions_enabling_loading: null,
        auto_decisions_errors: action.payload,
      };
    case BRANCHES_LOAD_START:
      return { ...state, loading_branches: true };
    case BRANCHES_LOAD_SUCCESS:
      return { ...state, branches: action.payload, loading_branches: false };
    case BRANCHES_LOAD_END:
      return { ...state, loading_branches: false };

    //
    // Financials Add-ons reducers
    //
    case ADD_ONS_FINANCIALS_SET_ENTITY_TYPE:
      return {
        ...state,
        current_financials: {
          ...state.current_financials,
          attributes: {
            ...state.current_financials.attributes,
            legal_types: action.payload,
          },
        },
      };
    case ADD_ONS_FINANCIALS_SET_MANDATORY_FIELDS:
      return {
        ...state,
        current_financials: {
          ...state.current_financials,
          attributes: {
            ...state.current_financials.attributes,
            config: {
              ...state.current_financials.attributes.config,
              mandatory_fields: action.payload,
            },
          },
        },
      };
    case ADD_ONS_FINANCIALS_SET_INDICATOR_FIELDS:
      return {
        ...state,
        current_financials: {
          ...state.current_financials,
          attributes: {
            ...state.current_financials.attributes,
            config: {
              ...state.current_financials.attributes.config,
              indicator_fields: action.payload,
            },
          },
        },
      };
    case ADD_ONS_FINANCIALS_SET_VALUE:
      return {
        ...state,
        current_financials: {
          ...state.current_financials,
          attributes: {
            ...state.current_financials.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_FINANCIALS_CREATE_RULESET_START:
      return { ...state, financials_updating: true };
    case ADD_ONS_FINANCIALS_CREATE_RULESET_SUCCESS:
    case ADD_ONS_FINANCIALS_CREATE_RULESET_ERROR:
      return { ...state, financials_updating: false };
    case ADD_ONS_FINANCIALS_RESET_CURRENT:
      return {
        ...state,
        current_financials: addOnsDefaults.current_financials,
      };
    case ADD_ONS_FINANCIALS_LOAD_START:
      return { ...state, financials_loading: true };
    case ADD_ONS_FINANCIALS_LOAD_SUCCESS:
      return {
        ...state,
        financials: action.payload,
        financials_loading: false,
      };
    case ADD_ONS_FINANCIALS_LOAD_ERROR:
      return { ...state, financials_loading: false };
    case ADD_ONS_FINANCIALS_SET_CURRENT:
      return { ...state, current_financials: action.payload };
    case ADD_ONS_FINANCIALS_UPDATE_RULESET_START:
      return {
        ...state,
        financials_active_updating: true,
        financials_active_updating_id: action.payload,
      };
    case ADD_ONS_FINANCIALS_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        financials: action.payload,
        financials_active_updating: false,
        financials_active_updating_id: null,
      };
    case ADD_ONS_FINANCIALS_UPDATE_RULESET_ERROR:
      return {
        ...state,
        financials_active_updating: false,
        financials_active_updating_id: null,
      };

    //
    // Additional Section Add-ons reducers
    //
    case ADD_ONS_ADDITIONAL_SET_VALUE:
      return {
        ...state,
        current_additional: {
          ...state.current_additional,
          attributes: {
            ...state.current_additional.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_ADDITIONAL_CREATE_RULESET_START:
      return { ...state, additional_updating: true };
    case ADD_ONS_ADDITIONAL_CREATE_RULESET_SUCCESS:
    case ADD_ONS_ADDITIONAL_CREATE_RULESET_ERROR:
      return { ...state, additional_updating: false };
    case ADD_ONS_ADDITIONAL_LOAD_START:
      return { ...state, additional_loading: true };
    case ADD_ONS_ADDITIONAL_LOAD_SUCCESS:
      return {
        ...state,
        additional: action.payload,
        additional_loading: false,
      };
    case ADD_ONS_ADDITIONAL_LOAD_ERROR:
      return { ...state, additional_loading: false };
    case ADD_ONS_ADDITIONAL_SET_CURRENT:
      return { ...state, current_additional: action.payload };
    case ADD_ONS_ADDITIONAL_RESET_CURRENT:
      return {
        ...state,
        current_additional: addOnsDefaults.current_additional,
      };
    case ADD_ONS_ADDITIONAL_UPDATE_RULESET_START:
      return {
        ...state,
        additional_active_updating: true,
        additional_active_updating_id: action.payload,
      };
    case ADD_ONS_ADDITIONAL_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        additional: action.payload,
        additional_active_updating: false,
        additional_active_updating_id: null,
      };
    case ADD_ONS_ADDITIONAL_UPDATE_RULESET_ERROR:
      return {
        ...state,
        additional_active_updating: false,
        additional_active_updating_id: null,
      };
    case ADD_ONS_FINANCIALS_SET_ADDITIONAL_FIELDS:
      return {
        ...state,
        current_financials: {
          ...state.current_financials,
          attributes: {
            ...state.current_financials.attributes,
            config: {
              ...state.current_financials.attributes.config,
              additional_fields: {
                ...state.current_financials.attributes.config.additional_fields,
                [action.payload.name]: action.payload.value,
              },
            },
          },
        },
      };

    //
    // Cards Add-ons reducers
    //
    case ADD_ONS_CARDS_SET_VALUE:
      return {
        ...state,
        current_card: {
          ...state.current_card,
          attributes: {
            ...state.current_card.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_CARDS_CREATE_RULESET_START:
      return { ...state, cards_updating: true };
    case ADD_ONS_CARDS_CREATE_RULESET_SUCCESS:
    case ADD_ONS_CARDS_CREATE_RULESET_ERROR:
      return { ...state, cards_updating: false };
    case ADD_ONS_CARDS_RESET_CURRENT:
      return {
        ...state,
        current_card: {
          ...CARDS_DEFAULT_SETTINGS.current_card,
          attributes: {
            ...CARDS_DEFAULT_SETTINGS.current_card.attributes,
            config: {
              additional_fields: [],
            },
          },
        },
      };
    case ADD_ONS_CARDS_SET_CURRENT:
      return { ...state, current_card: action.payload };
    case ADD_ONS_CARDS_LOAD_START:
      return { ...state, isCardsLoading: true };
    case ADD_ONS_CARDS_LOAD_SUCCESS:
      return {
        ...state,
        cards: action.payload,
        isCardsLoading: false,
      };
    case ADD_ONS_CARDS_LOAD_ERROR:
      return { ...state, isCardsLoading: false };
    case ADD_ONS_CARDS_UPDATE_RULESET_START:
      return {
        ...state,
        cardIdToUpdate: action.payload,
        isCardCurrentlyUpdating: true,
      };
    case ADD_ONS_CARDS_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        cardIdToUpdate: null,
        cards: action.payload,
        isCardCurrentlyUpdating: false,
      };
    case ADD_ONS_CARDS_UPDATE_RULESET_ERROR:
      return {
        ...state,
        cardIdToUpdate: null,
        isCardCurrentlyUpdating: false,
      };


    //
    // Internal Use Fields Add-ons reducers
    //
    case ADD_ONS_INTERNAL_USE_FIELDS_LOAD_START:
      return { ...state, internal_use_fields_loading: true };
    case ADD_ONS_INTERNAL_USE_FIELDS_LOAD_SUCCESS:
      return {
        ...state,
        internal_use_fields: action.payload,
        internal_use_fields_loading: false,
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_LOAD_ERROR:
      return { ...state, internal_use_fields_loading: false };
    case ADD_ONS_INTERNAL_USE_FIELDS_RESET_CURRENT:
      return {
        ...state,
        current_internal_use_fields: addOnsDefaults.current_internal_use_fields,
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_START:
      return { ...state, internal_use_fields_updating: true };
    case ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_SUCCESS:
    case ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_ERROR:
      return { ...state, internal_use_fields_updating: false };
    case ADD_ONS_INTERNAL_USE_FIELDS_SET_VALUE:
      return {
        ...state,
        current_internal_use_fields: {
          ...state.current_internal_use_fields,
          attributes: {
            ...state.current_internal_use_fields.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_SET_CONFIG_VALUE:
      return {
        ...state,
        current_internal_use_fields: {
          ...state.current_internal_use_fields,
          attributes: {
            ...state.current_internal_use_fields.attributes,
            config: {
              ...state.current_internal_use_fields.attributes.config,
              [action.payload.name]: action.payload.value,
            },
          },
        },
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_SET_CURRENT:
      return { ...state, current_internal_use_fields: action.payload };
    case ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_START:
      return {
        ...state,
        internal_use_fields_active_updating: true,
        internal_use_fields_active_updating_id: action.payload,
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        internal_use_fields: action.payload,
        internal_use_fields_active_updating: false,
        internal_use_fields_active_updating_id: null,
      };
    case ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_ERROR:
      return {
        ...state,
        internal_use_fields_active_updating: false,
        internal_use_fields_active_updating_id: null,
      };

    //
    // Paperless Add-ons reducers
    //
    case ADD_ONS_PAPERLESS_LOAD_START:
      return { ...state, paperless_loading: true };
    case ADD_ONS_PAPERLESS_LOAD_SUCCESS:
      return {
        ...state,
        paperless: action.payload,
        paperless_loading: false,
      };
    case ADD_ONS_PAPERLESS_LOAD_ERROR:
      return { ...state, paperless_loading: false };
    case ADD_ONS_PAPERLESS_RESET_CURRENT:
      return {
        ...state,
        current_paperless: addOnsDefaults.current_paperless,
      };
    case ADD_ONS_PAPERLESS_CREATE_RULESET_START:
      return { ...state, paperless_updating: true };
    case ADD_ONS_PAPERLESS_CREATE_RULESET_SUCCESS:
    case ADD_ONS_PAPERLESS_CREATE_RULESET_ERROR:
      return { ...state, paperless_updating: false };
    case ADD_ONS_PAPERLESS_SET_VALUE:
      return {
        ...state,
        current_paperless: {
          ...state.current_paperless,
          attributes: {
            ...state.current_paperless.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_PAPERLESS_SET_CONFIG_VALUE:
      return {
        ...state,
        current_paperless: {
          ...state.current_paperless,
          attributes: {
            ...state.current_paperless.attributes,
            config: {
              ...state.current_paperless.attributes.config,
              [action.payload.name]: action.payload.value,
            },
          },
        },
      };
    case ADD_ONS_PAPERLESS_SET_CURRENT:
      return { ...state, current_paperless: action.payload };
    case ADD_ONS_PAPERLESS_UPDATE_RULESET_START:
      return {
        ...state,
        paperless_active_updating: true,
        paperless_active_updating_id: action.payload,
      };
    case ADD_ONS_PAPERLESS_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        paperless: action.payload,
        paperless_active_updating: false,
        paperless_active_updating_id: null,
      };
    case ADD_ONS_PAPERLESS_UPDATE_RULESET_ERROR:
      return {
        ...state,
        paperless_active_updating: false,
        paperless_active_updating_id: null,
      };


    //
    // Alerts module Add-ons reducers
    //
    case ADD_ONS_ALERT_LOAD_START:
      return { ...state, alert_loading: true };
    case ADD_ONS_ALERT_LOAD_SUCCESS:
      return {
        ...state,
        alert: action.payload,
        alert_loading: false,
      };
    case ADD_ONS_ALERT_LOAD_ERROR:
      return { ...state, alert_loading: false };
    case ADD_ONS_ALERT_RESET_CURRENT:
      return {
        ...state,
        current_alert: addOnsDefaults.current_alert,
      };
    case ADD_ONS_ALERT_CREATE_RULESET_START:
      return { ...state, alert_updating: true };
    case ADD_ONS_ALERT_CREATE_RULESET_SUCCESS:
    case ADD_ONS_ALERT_CREATE_RULESET_ERROR:
      return { ...state, alert_updating: false };
    case ADD_ONS_ALERT_SET_VALUE:
      return {
        ...state,
        current_alert: {
          ...state.current_alert,
          attributes: {
            ...state.current_alert.attributes,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case ADD_ONS_ALERT_SET_CONFIG_VALUE:
      return {
        ...state,
        current_alert: {
          ...state.current_alert,
          attributes: {
            ...state.current_alert.attributes,
            config: {
              ...state.current_alert.attributes.config,
              [action.payload.name]: action.payload.value,
            },
          },
        },
      };
    case ADD_ONS_ALERT_SET_CURRENT:
      return { ...state, current_alert: setCurrentAlert(action.payload) };
    case ADD_ONS_ALERT_UPDATE_RULESET_START:
      return {
        ...state,
        alert_active_updating: true,
        alert_active_updating_id: action.payload,
      };
    case ADD_ONS_ALERT_UPDATE_RULESET_SUCCESS:
      return {
        ...state,
        alert: action.payload,
        alert_active_updating: false,
        alert_active_updating_id: null,
      };
    case ADD_ONS_ALERT_UPDATE_RULESET_ERROR:
      return {
        ...state,
        alert_active_updating: false,
        alert_active_updating_id: null,
      };
    default:
      return { ...state };
  }
}
