import React from "react";
import { DigitalOnboardingHeader } from "modules/addons/components//DigitalOnboarding";
import styles from "modules/addons/components/css/PPSRRegistration/forms/Header.css";

type HeaderProps = {
  addonModuleName: "ppsr_module"
    | "ppsr_australia_module"
    | "card_management_module"
    | "internal_use_fields"
    | "alert_module";
};

const HEADER = {
  ppsr_australia_module: "Australia PPSR module",
  ppsr_module: "PPSR module",
  card_management_module: "Card management",
  internal_use_fields: "Internal use fields (IUFs)",
  alert_module: "Alerts module"
};

const CONTENT = {
  ppsr_australia_module: `1Centre is directly integrated with the Australian
    PPSR enabling you to register and perform certain functions with relation
    to a PPSR (Personal property securities registration). By enabling
    this module in your account, we will either be creating an SPG
    (secured party group) on your behalf which you can attach to your
    account if you create a PPSR Organisation with the Australian PPSR, or,
    we will connect to your existing SPG and we'll add all of your PPSR requests
    in here.`,
  ppsr_module: `1Centre is directly integrated with the New Zealand companies
    office enabling you to register and perform certain functions with relation
    to a PPSR (Personal property securities registration). By enabling this
    module in your account, we will either be creating an SPG (secured party
    group) on  your behalf which you can attach to your account if you create a
    PPSR Organisation in the NZ Companies office, or, we will connect to your
    existing SPG and we'll add all of your PPSR requests in here.`,
  card_management_module: `The card management module (CMM) allows you to manage
    the card requirements of your existing customers. Firstly, follow the below
    steps to dynamically curate questions, card requirements and signatories needed
    for your business.  By attaching the below embed link to your website or other
    digital interface, or using your dynamic QR code imagery, your customers will be
    able to easily navigate through their cardholder user experience. Follow the below
    steps to set up your system.
    `,
  internal_use_fields: `
    Internal Use Fields (IUFs) are for internal communication. You can customise your
    own IUFs which will display on the Virtual Credit File for easy reference, including
    enabling pricing approvals. Where applications are received via your website or
    store networks, assigning an owner (typically a rep), will trigger a notification
    to this person to complete any required IUFs.
  `,
  alert_module:  `Welcome to your (BETA) ‘Alerts Module’. We are really excited to kick this off with a couple of key Alerts.  We will add to these over time.  Alerts will display in two places.  1) In your own personalised Alerts pipeline which you will be able to access via your Credit control tab in your main menu (refresh your screen, once you've set this up) - the Alerts will also link to your customers credit file. 2) In your Sales Pipeline.  When this module moves out of BETA, a nominal cost will apply - we will notify you before this happens.`,
};

export default function Header(
  props: HeaderProps,
): React.ReactElement<HeaderProps> {
  const { addonModuleName } = props;

  return (
    <DigitalOnboardingHeader>
      {HEADER[addonModuleName]}
      <div className={`${styles.sub_header} is-size-normal`}>
        {CONTENT[addonModuleName]}
      </div>
    </DigitalOnboardingHeader>
  );
}
