import Abstract from "../modules/card-management-authorisation/components/Abstract";
import Authorisation from "../modules/card-management-authorisation/components/Authorisation";
import CardManagementAuthorisation from "../modules/card-management-authorisation/components/CardManagementAuthorisation";
import Complete from "../modules/card-management-authorisation/components/Complete";
import Terms from "../modules/card-management-authorisation/components/Terms";
import Layout from "../modules/card-management-authorisation/container/Layout";

const CardManagementAuthorisationRoutes = [
  {
    component: Authorisation,
    path: "authorisation",
  },
  {
    component: Complete,
    path: "complete",
  },
  {
    component: Abstract,
    path: "abstract",
  },
  {
    component: Terms,
    path: "terms",
  },
  {
    component: CardManagementAuthorisation,
    path: ":cardholder_authorisation_id/:component(/**)",
  },
];

module.exports = {
  childRoutes: CardManagementAuthorisationRoutes,
  component: Layout,
  path: "card-management-authorisation",
};
