import React from "react";

import StatusIndicator from "../../applications/components/StatusIndicator";

var StatusIndicators = createClass({
  render: function() {
    const { application } = this.props;
    let elms = [];

    (application.attributes.checks || []).forEach((t, i) => {
      let status = "pending";

      if (t.outcome_text) status = t.outcome_text;

      elms.push(<StatusIndicator key={i} status={status} />);
    });

    return <span>{elms}</span>;
  },
});

module.exports = StatusIndicators;
