import React, { ReactElement, useEffect, useState } from "react";
import api from "api";
import Panel from "modules/dashboard/components/Panel";
import { Props } from "modules/dashboard/components/Panel/model";
// @ts-ignore
import { browserHistory } from "react-router";
import { Table } from "modules/dashboard/components/ActionsPanel/styles";
import useRequestQuery from "modules/dashboard/hooks/useRequestQuery";
import useParamsCompare from "modules/dashboard/hooks/useParamsCompare";
import Message from "modules/dashboard/components/Message";
import ActionSwitch from "modules/dashboard/components/ActionsPanel/ActionSwitch";
import { loadFilterValues, set } from "modules/dashboard/utils";
import { Header } from "../Panel/styles";
import Tooltip from "../Panel/Tooltip";
import { isMobileNew } from "modules/shared/helpers/mobileDetect";
import isBlank from "utils/isBlank";
import { isReviewerLevel } from "models/ReviewModel.js";

const getSecondColumnContent = ({
  approvalLevel,
  reviewerFirstName,
  reviewerLevel,
}) => {
  const firstName = reviewerFirstName || "Unassigned";

  if (isBlank(reviewerLevel)) {
    let levelDisplayed = `L${approvalLevel}`;
    if (isReviewerLevel(approvalLevel)) {
      levelDisplayed = "R";
    }

    return `${firstName} - ${levelDisplayed}`;
  }

  if (isReviewerLevel(reviewerLevel)) {
    return `${firstName} - R`;
  }

  return `${firstName} - L${reviewerLevel}`;
};

function ReadyForApproval(props: Props): ReactElement {
  const { accessToken, currentRoleTypes, entityId, filterState } = props;
  const isStandardUser = currentRoleTypes.includes("standard");
  const apiKey = "ready_for_approval";
  const savedCurrent = loadFilterValues(apiKey, entityId);
  const [current, setCurrent] = useState(
    isStandardUser ? "owner" : savedCurrent || "all",
  );

  const params = {
    ...filterState,
    user_scope: current,
  };

  const apiAction = () =>
    api("reporting", accessToken, entityId).get({
      api: apiKey,
      params,
    });
  const { data, error, forceUpdate, loading } = useRequestQuery(apiAction);

  const paramString = JSON.stringify(params);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
    }
  }, [params]);

  const handleSwitchChange = (value: string) => {
    set(apiKey, value, entityId);
    setCurrent(value);
    forceUpdate();
  };

  const displayFilter = isMobileNew() ? false : !isStandardUser;
  const displaySecondColumn = !isMobileNew();

  return (
    <Panel {...props} error={error} loading={loading} refresh={forceUpdate}>
      <div>
        <Table>
          <thead>
            <tr>
              <th>
                <Header>
                  <div>
                    <h2>
                      Ready for approval
                      <Tooltip>
                        <span>
                          This column means either your customer has completed
                          everything they need to, and is ready for your
                          approvers to action, OR your L1 approver has approved
                          this at their level, and it’s already moving through
                          your approval hierarchy. Click on the application name
                          which will take you through to the credit file so you
                          can see which approver has this application.
                        </span>
                      </Tooltip>
                    </h2>
                  </div>
                </Header>
              </th>
              <th>
                {displayFilter && (
                  <ActionSwitch
                    current={current}
                    handleChange={handleSwitchChange}
                  />
                )}
              </th>
            </tr>
          </thead>
          {data && data.slice(0, 20).length > 0 && (
            <tbody>
              {data.map(item => {
                const {
                  approval_level,
                  consumer_name,
                  id,
                  reviewer_first_name,
                  reviewer_level,
                  reviewer_id,
                } = item;
                return (
                  <tr key={`ready-for-approval-${id}${reviewer_id && `${reviewer_id}`}`}>
                    <td>
                      <a
                        onClick={() =>
                          browserHistory.push(`/dashboard/applications/${id}`)
                        }
                      >
                        {consumer_name}
                      </a>
                    </td>
                    {displaySecondColumn && (
                      <td>
                        {getSecondColumnContent({
                          approvalLevel: approval_level,
                          reviewerFirstName: reviewer_first_name,
                          reviewerLevel: reviewer_level,
                        })}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </div>
      {data && Array.isArray(data) && data.length === 0 && !loading && !error && (
        <Message
          faProps={{
            icon: ["fas", "check-circle"],
          }}
          message="No pending approvals."
        />
      )}
    </Panel>
  );
}

export default ReadyForApproval;
