import {
  LOAD_STORE_NAMES_START,
  LOAD_STORE_NAMES_SUCCESS,
  LOAD_STORE_NAMES_ERROR,
  LOAD_STORE_NAME_START,
  LOAD_STORE_NAME_SUCCESS,
  LOAD_STORE_NAME_ERROR,
  UPDATE_STORE_NAME_START,
  UPDATE_STORE_NAME_SUCCESS,
  UPDATE_STORE_NAME_ERROR,
  DELETE_STORE_NAME_START,
  DELETE_STORE_NAME_SUCCESS,
  DELETE_STORE_NAME_ERROR,
  CREATE_STORE_NAMES_START,
  CREATE_STORE_NAMES_SUCCESS,
  CREATE_STORE_NAMES_ERROR,
  UPDATE_STORE_NAME_ALIAS_START,
  UPDATE_STORE_NAME_ALIAS_SUCCESS,
  UPDATE_STORE_NAME_ALIAS_ERROR,
} from "./constants";

var website_button_defaults = {
  loading: false,
  store_names: [],
  store_names_count: 0,
  store_name: {},
};

export function websiteButtonReducer(state = website_button_defaults, action) {
  switch (action.type) {
    case LOAD_STORE_NAMES_START:
      return { ...state, loading: true };
    case LOAD_STORE_NAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        store_names: action.payload.data.data,
        store_names_count: action.payload.data.total_names_count,
      };
    case LOAD_STORE_NAMES_ERROR:
      return { ...state, loading: false };

    case LOAD_STORE_NAME_START:
      return { ...state, loading: true };
    case LOAD_STORE_NAME_SUCCESS:
      return { ...state, loading: false, store_name: action.payload.data.data };
    case LOAD_STORE_NAME_ERROR:
      return { ...state, loading: false };

    case UPDATE_STORE_NAME_START:
      return { ...state, loading: true };
    case UPDATE_STORE_NAME_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_STORE_NAME_ERROR:
      return { ...state, loading: false };

    case DELETE_STORE_NAME_START:
      return { ...state, loading: true };
    case DELETE_STORE_NAME_SUCCESS:
      return { ...state, loading: false };
    case DELETE_STORE_NAME_ERROR:
      return { ...state, loading: false };

    case CREATE_STORE_NAMES_START:
      return { ...state, loading: true };
    case CREATE_STORE_NAMES_SUCCESS:
      return { ...state, loading: false };
    case CREATE_STORE_NAMES_ERROR:
      return { ...state, loading: false };

    case UPDATE_STORE_NAME_ALIAS_START:
      return { ...state, loading: true };
    case UPDATE_STORE_NAME_ALIAS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_STORE_NAME_ALIAS_ERROR:
      return { ...state, loading: false };

    default:
      return { ...state };
  }
}
