/* Components */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";

import { browserHistory } from "react-router";

/* Import CSS */
import styles from "./css/CompleteApplication.css";

/* Actions */
import {} from "../actions";

/* Import components */
import SectionRect from "modules/shared/components/widgets/static/SectionRect";
import SectionBadge from "modules/shared/components/widgets/static/SectionBadge";

import WorkflowOverlay from "modules/shared/components/top/WorkflowOverlay";

import LoginForm from "../forms/LoginForm";

/* Login */
var CompleteApplication = createClass({
  // /**
  //  * Redirect
  //  */
  redirect: function() {
    browserHistory.push("/dashboard/applications/all/customers");
  },

  handleClick: function(event) {},

  /**
   * Render
   *
   * @returns {XML}
   */
  render: function() {
    return (
      <div>
        <WorkflowOverlay
          position="top"
          section_title="Trade account application"
        />
        <section className={styles.section}>
          <div className={styles.row}>
            <div className={styles.panel}>
              <div className={styles.liner}>
                <SectionRect title="First trade application with 1Centre?">
                  <div className={styles.badge}>
                    <SectionBadge
                      css_class="item_icon_text"
                      handleClick={this.handleClick}
                      icon="application"
                      text="start"
                      size="51x60"
                    />
                  </div>
                  <Link className={styles.link} to="/user/login">
                    Learn more
                  </Link>
                </SectionRect>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  },
});

export default connect((state, ownProps) => {
  return {};
})(CompleteApplication);
