import { COMMON_VALIDATION_SCHEMA } from "modules/addons/components/digital-onboarding/PPSRRegistration/forms/validationSchema/commonValidationSchema";
import * as yup from "yup";

const FUNCTION_VALIDATION_SCHEMA = {
  functionRegister: yup
    .boolean()
    .test("function-register", "Please select register", value => value),
};

const NZValidationSchema = {
  ...COMMON_VALIDATION_SCHEMA,
  ...FUNCTION_VALIDATION_SCHEMA,
};

export default NZValidationSchema;
