import styled, { css } from "styled-components";
import { COLORS } from "variables/theme";
// import { breakpointMin } from 'variables/responsive';

type TabProps = {
  readonly active?: boolean,
}

export const Tab = styled.button<TabProps>`
  background: ${COLORS.lightGrey};
  border: 0;
  color: var(--main-color);
  flex: 1;
  height: 60px;
  outline: none;
  padding: 1rem;

  ${props => props.active && css`
    color: white;
    background: var(--main-color);
  `}
`;

export const Wrapper = styled.div`
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 100;

  ${Tab}:not(:last-child) {
    border-right: 2px solid white;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
