import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBodyScroll from "hooks/useBodyScroll";
import { get } from "lodash";
import { loadTeam } from "modules/manage-users/actions";
import { updateApprovalLevels } from "modules/profile/actions";
import Button from "modules/shared/components/inputs/Button";
import ContentContainer from "modules/shared/components/v2/ContentContainer";
import { FormSubmissionStatus, Status } from "modules/shared/components/v2/Form/useFormSubmissionStatus";
import useYupValidationResolver from "modules/shared/hooks/useYupValidationResolver";
import React, { Fragment, ReactElement, useEffect, useState } from "react"
import { useForm } from "react-hook-form-latest";
import ApprovalHierarchyChangedModal from "../../ApprovalHierarchyChangedModal";

import NumberDropdown from "../NumberDropdown";
import BorderedTextField from "../ReactHookFormInputs/BorderedTextField";
import SquareCheckbox from "../ReactHookFormInputs/SquareCheckbox";
import Switch from "../ReactHookFormInputs/Switch";
import { getFormSchema, _formatMoney } from "./helper";
import { Icon } from "./styles";
import { ApprovalLevel, Props, Values } from './types';

function Form({
  approvalLevels,
  approval_hierarchy_version,
  brokerConfig,
  brokerEnabled,
  disabled,
  dismissHandler,
  dispatch,
  reviewerEnabled,
}: Props): ReactElement {
  useBodyScroll();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [handleModalOk, setHandleModalOk] = useState<(() => void)>(() => null);
  const [isRemoveReviewerNoticeVisible, setIRemoveReviewerNoticeVisible] = useState(false);
  const [status, setStatus] = useState<Status|null>(null);

  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      approvalLevelsCount: approvalLevels.length,
      approvalLevels,
      brokerConfig,
      reviewerEnabled,
    },
    mode: "onBlur",
    resolver: useYupValidationResolver(getFormSchema),
  });

  const values = watch() as Values;

  const displayBrokerForm = brokerEnabled && values.brokerConfig && values.brokerConfig.onOff;

  const {
    errors,
    isDirty,
  } = formState;

  useEffect(() => {
    const updatedApprovalLevels = filteredApprovalLevels();

    if (!updatedApprovalLevels) {
      return;
    }
    // @ts-ignore-next-line
    setValue("approvalLevels", updatedApprovalLevels)
  })

  function filteredApprovalLevels(): ApprovalLevel[]|undefined  {
    const { approvalLevelsCount, approvalLevels } = values;
    if (approvalLevelsCount >= approvalLevels.length) {
      return;
    }

    return approvalLevels.filter((_, index) => (index + 1) <= approvalLevelsCount);
  }

  function preSubmit(data) {
    const processedData = {...data};
    const { approvalLevelsCount, approvalLevels } = processedData;
    if (approvalLevelsCount < approvalLevels.length) {
      processedData['approvalLevels'] = approvalLevels.filter((_, index) => (index + 1) <= approvalLevelsCount);
    }

    const isValid = validateCreditLimit(processedData.approvalLevels);

    if (!isValid) {
      return;
    }

    setIRemoveReviewerNoticeVisible(willRemoveReviewer());
    submit(processedData)
  }

  function submit(data) {
    if (disabled) {
      return;
    }

    const { approvalLevels, brokerConfig, reviewerEnabled } = data;

    const payload = {
      approval_levels: approvalLevels,
      reviewer_enabled: reviewerEnabled,
    };

    if (brokerConfig.onOff) {
      payload['broker_config'] = {
        ...brokerConfig,
        insurerMin: brokerConfig.brokerMax + 1,
      };
    }

    try {
      setLoading(true);
      dispatch(
        updateApprovalLevels({
          attributes: { ...payload },
          callback: {
            success: () => {
              setLoading(false);
              const action = () => {
                setShowModal(false);
                dispatch(loadTeam());
                dismissHandler();
              };
              setHandleModalOk(() => action);
              setShowModal(true);
            },
            error: (error) => {
              let message = `Oops! Something went wrong.
                Please try again or contact 1Centre for more details.`;
              if (get(error, 'response.status') === 401) {
                message = `Oops! You are not authorised to save the changes.`
              }
              setStatus({ type: 'danger', message })
              setLoading(false);
            },
          },
          version: approval_hierarchy_version,
        }),
      );
    } finally {() => null}
  }

  function willRemoveReviewer(): boolean {
    if (!reviewerEnabled) {
      return false;
    }

    return !values.reviewerEnabled;
  }

  const reviewerTooltip = {
    element: <Icon><FontAwesomeIcon icon="question-circle" /></Icon>,
    extraComponent: (
      <p className="has-text-left">
        A reviewer doesn&apos;t have any credit limit approval but is
        responsible for ensuring all elements of the credit file
        are complete prior to it going to the approvers.
      </p>
    ),
    placement: "top",
  }

  // TODO - Investigate how yup can replace the manual validation
  function validateCreditLimit(approvalLevels): boolean {
    const approvalLevelsCountValue = approvalLevels || filteredApprovalLevels() || values.approvalLevels;

    const isValid = approvalLevelsCountValue
      .map((approvalLevel, index) => {
        let valid = false;
        const { credit_limit, min_credit_limit } = approvalLevel;
        const targetCreditLimit = `approvalLevels.${index}.credit_limit`;

        const isFirst = index === 0;
        const previousLevel = index - 1;
        const previousTarget = approvalLevelsCountValue[previousLevel];
        const creditLimitToCompare = isFirst ?
          min_credit_limit :
          previousTarget?.credit_limit || 0 ;

        if (credit_limit <= creditLimitToCompare) {
          // @ts-ignore-next-line
          setError(targetCreditLimit, {
            type: 'manual',
            message: `Limit must be greater than ${_formatMoney(creditLimitToCompare)}`
          });
        } else {
          // @ts-ignore-next-line
          clearErrors(targetCreditLimit);
          valid = true;
        }

        return valid;
      })

    return isValid.every(valid => valid);
  }

  return (
    <Fragment>
      { showModal && (
        <ApprovalHierarchyChangedModal
          okHandler={handleModalOk}
          willRemoveReviewer={isRemoveReviewerNoticeVisible}
        />
      )}
      <form onSubmit={handleSubmit(preSubmit, () => { validateCreditLimit(false) })}>
        <ContentContainer
          header="Minimum credit limit"
          description={"Set the minimum credit limit a customer\
            can apply for in your business."}
        >
          <div className="columns">
            <div className="column is-4">
              <BorderedTextField
                placeholder="min"
                control={control}
                name="approvalLevels.0.min_credit_limit"
                error={errors['approvalLevels[0]']}
                readOnly={disabled}
                isMoney
              />
            </div>
          </div>
        </ContentContainer>
        <ContentContainer
          header="Application reviewer (optional)"
          tooltip={reviewerTooltip}
          description={`When this is selected, you can appoint team members\
            as reviewers in the user profile tab. Reviewers don’t have any\
            credit limit approval but is responsible to ensure all elements\
            of the credit file are complete prior to it going to the approvers.
          `}
        >
          <div className="columns">
            <div className="column is-4">
              <SquareCheckbox
                label="Enable application reviewer function"
                control={control}
                name="reviewerEnabled"
                disabled={disabled}
              />
            </div>
          </div>
        </ContentContainer>
        <ContentContainer
          header="Approval levels"
          description={`Set the number of approval levels you have in your\
            business below. The maximum credit limit of the last approver\
            is the maximum a customer will be able to apply for when\
            completing their digital application.
          `}
        >
          <div className="columns">
            <div className="column is-4">
              <NumberDropdown
                id="approval-levels"
                name="approvalLevelsCount"
                control={control}
                count={5}
                placeholder="No. of approval levels"
                disabled={disabled}
              />
            </div>
          </div>
        </ContentContainer>
        <ContentContainer
          header="Approvers"
          description={`You can have between 1-5 approval levels and can\
            have multiple approvers at each level - the approval hierarchy is\
            linear, each approver will be notified when/if they are required to\
            approve an application.
          `}
        >
          { [...Array(values.approvalLevelsCount)].map((_, index) => {
            const level = index + 1;
            const targetErrorLevel = `approvalLevels[${index}]`;
            const approverNumError = errors[`${targetErrorLevel}.minimum_approvers`];
            const limitError = errors[`${targetErrorLevel}.credit_limit`];
            const targetLevel = `approvalLevels.${index}`;

            return (
              <div className="columns is-multiline" key={`approver-level-${level}`}>
                <div className="column is-12">
                  <p>Level {level} approvers</p>
                </div>
                <div className="column is-4">
                  <BorderedTextField
                    placeholder="Max credit limit"
                    control={control}
                    name={`${targetLevel}.credit_limit`}
                    error={limitError}
                    onBlur={() => validateCreditLimit(false)}
                    readOnly={disabled}
                    isMoney
                  />
                </div>
                <div className="column is-4">
                  <NumberDropdown
                    id="minimum_approvers"
                    name={`${targetLevel}.minimum_approvers`}
                    control={control}
                    count={10}
                    placeholder={`No. of Lv${level} approvers`}
                    disabled={disabled}
                    error={approverNumError}
                  />
                </div>
              </div>
            )
          })}
        </ContentContainer>
        <ContentContainer
          header="Broker approvals"
        >
          {brokerEnabled && (
            <Switch
              control={control}
              name="brokerConfig.onOff"
              disabled={disabled}
            />
          )}
          {!brokerEnabled && (
            <p>
              Please contact <a href="mailto:success@1centre.com">success@1centre.com</a>
              to enable this function. This allows you to set up excess limit/discretionary
              limit for broker approvals.
            </p>
          )}
          {displayBrokerForm && (
            <Fragment>
              <div className="mb-4">Set the credit limit for an application to be sent to a broker/insurer.</div>
              <div className="columns">
                <div className="column is-4">
                  <BorderedTextField
                    placeholder="Broker email"
                    control={control}
                    name="brokerConfig.brokerEmail"
                    readOnly={disabled}
                  />
                </div>
                <div className="column is-4">
                  <BorderedTextField
                    placeholder="min"
                    control={control}
                    name="brokerConfig.brokerMin"
                    readOnly={disabled}
                    isMoney
                  />
                </div>
                <div className="column is-4">
                  <BorderedTextField
                    placeholder="max"
                    control={control}
                    name="brokerConfig.brokerMax"
                    readOnly={disabled}
                    isMoney
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column is-4">
                  <BorderedTextField
                    placeholder="Insurer email"
                    control={control}
                    name="brokerConfig.insurerEmail"
                    readOnly={disabled}
                  />
                </div>
                <div className="column is-4">
                  <BorderedTextField
                    placeholder="min"
                    control={control}
                    name="brokerConfig.insurerMin"
                    value={values.brokerConfig && values.brokerConfig.brokerMax && values.brokerConfig.brokerMax + 1}
                    readOnly
                    isMoney
                  />
                </div>
                <div className="column is-4">
                  <BorderedTextField
                    placeholder="max"
                    control={control}
                    name="brokerConfig.insurerMax"
                    readOnly={disabled}
                    isMoney
                  />
                </div>
              </div>
            </Fragment>
          )}
        </ContentContainer>
        { status && <FormSubmissionStatus {...status} />}
        <Button
          text="save"
          type="submit"
          disabled={disabled || !isDirty}
          loading={loading}
        />
      </form>
    </Fragment>
  )
}

export default Form;
