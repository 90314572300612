import React, { Component } from "react";

import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import TradingNameSearchList from "./TradingNameSearchList";
import Typography from "@material-ui/core/Typography";
import { capitalizeSentence } from "utils/formatting";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import styles from "./css/ConsumerNameSearch.css";

export default class TradingNameList extends Component {
  constructor(props) {
    super(props);
    const tradingNameList = (props.tradingNames || []).map(tradingName =>
      this.formateTradingName(tradingName),
    );

    this.state = {
      showFulllist: false,
      tradingNameList,
    };
  }

  showFullList = () => {
    this.setState({ showFulllist: true });
  };

  hideFullList = () => {
    this.setState({ showFulllist: false });
  };

  formateTradingName(tradingName) {
    const { firstAdmin, defaultAddress } = this.props;
    const defaultContact = firstAdmin || {};
    const fullName =
      tradingName.contact_name ||
      [defaultContact.first_name, defaultContact.last_name].join(" ");
    // const phone = tradingName.phone_number || defaultContact.contact_phone_number || ""
    tradingName.trading_name = capitalizeSentence(tradingName.trading_name);
    tradingName["contact"] = `${capitalizeSentence(fullName)}`;
    tradingName.full_address = tradingName.full_address || defaultAddress;
    return tradingName;
  }

  renderFirstThreeTradingName() {
    const { tradingNameList } = this.state;
    const firstThree = tradingNameList.slice(0, 3);
    const items = [];
    firstThree.forEach((tradingName, index) =>
      items.push(
        <div key={tradingName.id}>
          {index > 0 && <Divider component="li" />}
          <ListItem className={styles.trading_name_item}>
            <ListItemText
              primary={tradingName.trading_name}
              secondary={
                <React.Fragment>
                  <Typography component="span">
                    {tradingName.contact}
                  </Typography>
                  {tradingName.full_address}
                </React.Fragment>
              }
            />
          </ListItem>
        </div>,
      ),
    );
    return <List>{items}</List>;
  }

  renderFullTradingNameListLink() {
    const { tradingNameList } = this.state;
    if (tradingNameList.length > 3) {
      return (
        <div>
          <Divider component="li" />
          <ListItem className={styles.trading_name_item}>
            <ListItemText
              primary={
                <a onClick={this.showFullList}>
                  Click to see full list of child accounts
                </a>
              }
            />
          </ListItem>
        </div>
      );
    }
    return null;
  }

  render() {
    const { open } = this.props;

    return (
      <MuiThemeProvider theme={muiTheme()}>
        <Collapse in={open}>
          <List className={styles.trading_name_list}>
            <div className={styles.trading_name_list_background}>
              {this.renderFirstThreeTradingName()}
              {this.renderFullTradingNameListLink()}
              {
                <TradingNameSearchList
                  open={this.state.showFulllist}
                  hide={this.hideFullList}
                  tradingNames={this.state.tradingNameList}
                />
              }
            </div>
          </List>
        </Collapse>
      </MuiThemeProvider>
    );
  }
}
