import React, { ReactElement } from "react";
import Button from "modules/shared/components/inputs/Button";
import { ButtonContainer } from "modules/connected-apps/components/Webhooks/Documentation/v1-documentation/styles";

const V1Documentation = (): ReactElement => (
  <ButtonContainer>
    <Button
      small
      grey
      text="webhook instructions"
      link="https://d1izq46b2lizj7.cloudfront.net/WebhookInstructions.pdf"
      target="_blank"
    />
    <Button
      small
      grey
      text="webhook payload samples"
      link="/webhook_documentation/credit"
      target="_blank"
    />
  </ButtonContainer>
);

export default V1Documentation;
