import camelcase from "lodash.camelcase";
import get from "lodash.get";
import snakeCase from "lodash.snakecase";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import { v4 as uuidv4 } from "uuid";

export default class BaseModel {
  get isNewRecord() {
    return !this.isPersisted;
  }

  get isPersisted() {
    return isPresent(this.id);
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  get originalData() {
    return this.data;
  }

  get objectId() {
    return this._objectId;
  }

  setAttributes(attributes) {
    const keys = Object.keys(attributes);
    for (const key of keys) {
      this.setAttribute(key, get(attributes, key));
    }
  }

  setAttribute(key, value) {
    const camelCasedKey = camelcase(key);

    const descriptor = Object.getOwnPropertyDescriptor(this, camelCasedKey);
    if (isBlank(descriptor)) {
      this.defineGetterAndSetter(key);
    }

    this[camelCasedKey] = value;
  }

  setId(newId) {
    this.id = newId;
  }

  /**
   * Copy the following line in the model that needs to clone a record to be
   * used when updating a state.
   * `return Object.assign(Object.create(Object.getPrototypeOf(this)), this);`
   */
  cloneRecord() {
    console.error(
      "Copy the following line in the relevant model: `return Object.assign(Object.create(Object.getPrototypeOf(this)), this);`",
    );
  }

  constructor(data = {}, included = []) {
    this.data = data;
    this.id = get(data, "id");
    this.modelType = get(data, "type");
    this.attributes = get(data, "attributes", {});
    this.relationships = get(data, "relationships", {});
    this.included = included;
    this._isLoading = false;
    this._objectId = this.id || uuidv4();

    this.assignPropertiesFromAttributes();
  }

  /** Private functions */

  defineGetterAndSetter(key) {
    const snakedCaseKey = snakeCase(key);
    Object.defineProperty(this, camelcase(key), {
      get: () => this.attributes[snakedCaseKey],
      set: newValue => {
        this.attributes[snakedCaseKey] = newValue;
      },
    });
  }

  assignPropertiesFromAttributes() {
    this.setAttributes(this.attributes);
  }

  assignSingleRelationship({ included = [], key, model, overrideKey }) {
    const relationship = get(this.relationships, key, {});

    if (isBlank(relationship)) {
      this[camelcase(key)] = new model({});
      return;
    }

    const { id, type } = get(relationship, "data", {}) || {};
    if (isBlank(id)) {
      this[camelcase(key)] = new model({});
      return;
    }

    const attributes = this.getIncludedAttributes({ id, type });

    if (isPresent(attributes)) {
      this[this.getRelationshipAttributeKey(key, overrideKey)] = new model(
        attributes,
        included,
      );
    }
  }

  assignManyRelationship({ filter, included = [], key, model, overrideKey }) {
    const relationship = get(this.relationships, key, {});
    const attributeKey = this.getRelationshipAttributeKey(key, overrideKey);
    this[attributeKey] = this[attributeKey] || [];

    if (isBlank(relationship)) {
      return;
    }

    const data = get(relationship, "data", []);
    for (const datum of data) {
      const { id, type } = datum;
      const attributes = this.getIncludedAttributes({ filter, id, type });

      if (isPresent(attributes)) {
        this[attributeKey].push(new model(attributes, included));
      }
    }
  }

  getRelationshipAttributeKey(key, overrideKey) {
    const attributeKey = isPresent(overrideKey) ? overrideKey : key;

    return camelcase(attributeKey);
  }

  getIncludedAttributes({ filter, id, type }) {
    let includedRecords = [];

    if (filter) {
      includedRecords = this.included.filter(filter);
    } else {
      includedRecords = this.included;
    }

    return (
      includedRecords.find(
        included => included.id === id && included.type === type,
      ) || {}
    );
  }
}
