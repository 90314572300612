import React, { Fragment } from "react";
import styles from "modules/new-applications/css/IdentificationChecks.css";
import SignatureDetails from "modules/new-applications/components/application-sections/IdentificationCheck/SignatureDetails";
import VedaCheckErrors from "modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v1/VedaCheckOutcome/VedaCheckErrors";

const VedaCheckOutcome = props => {
  const { identificationCheck, isErrorsVisible } = props;

  return (
    <Fragment>
      <div className={styles.e_signature_header}>
        <span className="underlined">Identification check (Equifax)</span>
      </div>
      <SignatureDetails
        label="Outcome"
        content={identificationCheck.identificationCheckOutcome}
      />
      <VedaCheckErrors
        identificationCheck={identificationCheck}
        isErrorsVisible={isErrorsVisible}
      />
    </Fragment>
  );
};

export default VedaCheckOutcome;
