import get from "lodash.get";
import { MAXIMUM_RETRY_ATTEMPTS } from "models/IdentificationCheckModel";
import getIsAntiFraudVerified from "modules/new-applications/components/application-sections/IdentificationCheck/utils/getIsAntiFraudVerified";
import AntiFraudCategoryRule from "utils/AntiFraudCategoryRule";

export default function getIsAntiFraudManualVerificationRequired({
  application,
  authorisation,
  identificationCheck,
  signature,
}): boolean {
  if (signature.isManuallyApproved) {
    return false;
  }

  const antiFraudConfig = get(application, "antiFraudAddonRule.config", {});
  const applicableCategory = authorisation.getApplicableAntiFraudCategory(
    antiFraudConfig,
  );
  const antiFraudCategoryRules = new AntiFraudCategoryRule(applicableCategory);

  if (
    antiFraudCategoryRules.isGovernmentVerificationRequired &&
    identificationCheck.status === "errored"
  ) {
    return identificationCheck.retryAttempts >= MAXIMUM_RETRY_ATTEMPTS;
  }

  const isAntiFraudVerified = getIsAntiFraudVerified({
    application,
    authorisation,
    identificationCheck,
  });

  return !isAntiFraudVerified;
}
