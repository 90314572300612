import styled, { css } from "styled-components";
import { THEME_COLORS } from "variables/theme";

export const IconWrapper = styled.svg<{canAccess: boolean}>`
  height: 100%;
  margin-left: -0.9rem;
  margin-top: -1.5rem;
  max-height: 140px;
  max-width: 160px;
  width: 100%;

  ${props => props.canAccess && css`
    svg {
      cursor: pointer;
    }
  `}

  ${props => !props.canAccess && css`
    svg {
      rect {
        fill: ${THEME_COLORS.tokenDisabled};
      }
    }
  `}
`;
