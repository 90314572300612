/* eslint-disable max-classes-per-file */
import ModelReducer from "models/reducers";
import { USER_LOGOUT } from "modules/user/constants";
import { routerReducer as routing } from "react-router-redux";
import { combineReducers } from "redux";

import { accountSettingsReducer } from "./account-settings/reducers";
import { autoDecisionReducer } from "./addons/auto_decisioning/reducers";
import { entityStructureReducer } from "./addons/entity_structure/reducers";
import { addOnsReducer } from "./addons/reducers";
import { applicationsReducer } from "./applications/reducers";
import { associationReducer } from "./association/reducers";
import { authorisationReducer } from "./authorisation/reducers";
import { branchInviteReducers } from "./branch-invite/reducers";
import { connectionsReducer } from "./connections/reducers";
import { consumerInviteReducer } from "./consumer-invite/reducers/invite";
import { consumerOnboardingAdditionalReducer } from "./consumer-onboarding/reducers/additional";
import { consumerOnboardingCardsReducer } from "./consumer-onboarding/reducers/cards";
import { consumerOnboardingExtrasReducer } from "./consumer-onboarding/reducers/extras";
import { consumerOnboardingFinancialsReducer } from "./consumer-onboarding/reducers/financials";
import { consumerOnboardingGuarantorsReducer } from "./consumer-onboarding/reducers/guarantors";
import { consumerOnboardingMoneyReducer } from "./consumer-onboarding/reducers/money";
import { consumerOnboardingBusinessReducer } from "./consumer-onboarding/reducers/onboarding";
import { consumerOnboardingPaperlessReducer } from "./consumer-onboarding/reducers/paperless";
import { consumerOnboardingReviewReducer } from "./consumer-onboarding/reducers/review";
import { consumerOnbardingSectionReducer } from "./consumer-onboarding/reducers/section";
import { consumerOnboardingTraderefReducer } from "./consumer-onboarding/reducers/trade-reference";
import { dashboardReducer } from "./dashboard/reducers";
import { guarantorReducer } from "./guarantor/reducers";
import { identityReducer } from "./identity/reducers";
import { invalidAccountReducer } from "./invalid-account/reducers";
import { ipGeolocationReducer } from "./ip-geolocation/reducers";
import { leadsReducers } from "./leads/reducers";
import { loaderReducer } from "./loader/reducer";
import { manageUsersReducer } from "./manage-users/reducers";
import { mappingReducer } from "./mapping/reducers";
import { messagesReducer } from "./messages/reducers";
import { notificationsReducer } from "./notifications/reducers";
import { paymentReducer } from "./payment/reducers";
import { pricingPlansReducer } from "./pricing-plans/reducers";
import { manageProfileReducer } from "./profile/reducers";
import { reducer as idleReducer } from "./redux-idle-monitor";
import { reportingReducer } from "./reporting/reducers";
import { authDataReducers } from "./shared/auth/reducers";
import { sharedDataReducer } from "./shared/reducers";
import { stateReducer } from "./state/reducers";
import { tradeReferencesReducer } from "./trade-references/reducers";
import {
  loginReducer,
  registrationReducer,
  userAuthReducer,
  userRoutesReducer,
} from "./user/reducers";
import { websiteButtonReducer } from "./websitebutton/reducers";

const ApplicationReducer = combineReducers({
  account_settings: accountSettingsReducer,
  add_ons: addOnsReducer,
  applications: applicationsReducer,
  association: associationReducer,
  auto_decisions: autoDecisionReducer,
  authorisation: authorisationReducer,
  auth: authDataReducers,
  branch_invite: branchInviteReducers,
  cns_invite: consumerInviteReducer,
  cob_business: consumerOnboardingBusinessReducer,
  cob_cards: consumerOnboardingCardsReducer,
  cob_additional: consumerOnboardingAdditionalReducer,
  cob_extras: consumerOnboardingExtrasReducer,
  cob_guarantors: consumerOnboardingGuarantorsReducer,
  cob_financials: consumerOnboardingFinancialsReducer,
  cob_money: consumerOnboardingMoneyReducer,
  cob_paperless: consumerOnboardingPaperlessReducer,
  cob_review: consumerOnboardingReviewReducer,
  cob_section: consumerOnbardingSectionReducer,
  cob_traderef: consumerOnboardingTraderefReducer,
  connections: connectionsReducer,
  current_user: userAuthReducer,
  dashboard: dashboardReducer,
  entity_structure: entityStructureReducer,
  guarantor: guarantorReducer,
  identity: identityReducer,
  idle: idleReducer,
  invalid_account: invalidAccountReducer,
  ip_geolocation: ipGeolocationReducer,
  leads: leadsReducers,
  loader: loaderReducer,
  login: loginReducer,
  manage_profile: manageProfileReducer,
  manage_users: manageUsersReducer,
  mapping: mappingReducer,
  messages: messagesReducer,
  models: ModelReducer,
  notifications: notificationsReducer,
  payment: paymentReducer,
  pricing_plans: pricingPlansReducer,
  register: registrationReducer,
  routing,
  reporting: reportingReducer,
  shared_data: sharedDataReducer,
  state: stateReducer,
  trade_references: tradeReferencesReducer,
  user_routes: userRoutesReducer,
  website_button: websiteButtonReducer,
});

/**
 * Reset the reducer states when user logouts
 * Reference: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
 */
const ROOT_REDUCER = (state, action) => {
  let newState = state;

  if (action.type === USER_LOGOUT) {
    const { routing } = state;
    newState = { routing };
  }

  return ApplicationReducer(newState, action);
};

export default ROOT_REDUCER;
/* eslint-enable max-classes-per-file */
