export const ADD_ONS_LOAD_START = "ADD_ONS_LOAD_START";
export const ADD_ONS_LOAD_SUCCESS = "ADD_ONS_LOAD_SUCCESS";
export const ADD_ONS_LOAD_ERROR = "ADD_ONS_LOAD_ERROR";
export const ADD_ONS_AUTO_DECISION_LOAD_START =
  "ADD_ONS_AUTO_DECISION_LOAD_START";
export const ADD_ONS_AUTO_DECISION_LOAD_SUCCESS =
  "ADD_ONS_AUTO_DECISION_LOAD_SUCCESS";
export const ADD_ONS_AUTO_DECISION_LOAD_ERROR =
  "ADD_ONS_AUTO_DECISION_LOAD_ERROR";
export const ADD_ONS_AUTO_DECISION_TOGGLE_START =
  "ADD_ONS_AUTO_DECISION_TOGGLE_START";
export const ADD_ONS_AUTO_DECISION_TOGGLE_SUCCESS =
  "ADD_ONS_AUTO_DECISION_TOGGLE_SUCCESS";
export const ADD_ONS_AUTO_DECISION_TOGGLE_ERROR =
  "ADD_ONS_AUTO_DECISION_TOGGLE_ERROR";
export const BRANCHES_LOAD_START = "BRANCHES_LOAD_START";
export const BRANCHES_LOAD_SUCCESS = "BRANCHES_LOAD_SUCCESS";
export const BRANCHES_LOAD_END = "BRANCHES_LOAD_END";

//
// Internal Use Fields Add-ons constants
//
export const ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_ERROR =
  "ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_ERROR";
export const ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_START =
  "ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_START";
export const ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_SUCCESS =
  "ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_SUCCESS";
export const ADD_ONS_INTERNAL_USE_FIELDS_LOAD_ERROR =
  "ADD_ONS_INTERNAL_USE_FIELDS_LOAD_ERROR";
export const ADD_ONS_INTERNAL_USE_FIELDS_LOAD_START =
  "ADD_ONS_INTERNAL_USE_FIELDS_LOAD_START";
export const ADD_ONS_INTERNAL_USE_FIELDS_LOAD_SUCCESS =
  "ADD_ONS_INTERNAL_USE_FIELDS_LOAD_SUCCESS";
export const ADD_ONS_INTERNAL_USE_FIELDS_SET_CONFIG_VALUE =
  "ADD_ONS_INTERNAL_USE_FIELDS_SET_CONFIG_VALUE";
export const ADD_ONS_INTERNAL_USE_FIELDS_SET_CURRENT =
  "ADD_ONS_INTERNAL_USE_FIELDS_SET_CURRENT";
export const ADD_ONS_INTERNAL_USE_FIELDS_SET_VALUE =
  "ADD_ONS_INTERNAL_USE_FIELDS_SET_VALUE";
export const ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_ERROR =
  "ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_ERROR";
export const ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_START =
  "ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_START";
export const ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_SUCCESS =
  "ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_SUCCESS";
export const ADD_ONS_INTERNAL_USE_FIELDS_RESET_CURRENT =
  "ADD_ONS_INTERNAL_USE_FIELDS_RESET_CURRENT";

//
// Financials Add-ons constants
//
export const ADD_ONS_FINANCIALS_CREATE_RULESET_ERROR =
  "ADD_ONS_FINANCIALS_CREATE_RULESET_ERROR";
export const ADD_ONS_FINANCIALS_CREATE_RULESET_START =
  "ADD_ONS_FINANCIALS_CREATE_RULESET_START";
export const ADD_ONS_FINANCIALS_CREATE_RULESET_SUCCESS =
  "ADD_ONS_FINANCIALS_CREATE_RULESET_SUCCESS";
export const ADD_ONS_FINANCIALS_LOAD_ERROR = "ADD_ONS_FINANCIALS_LOAD_ERROR";
export const ADD_ONS_FINANCIALS_LOAD_START = "ADD_ONS_FINANCIALS_LOAD_START";
export const ADD_ONS_FINANCIALS_LOAD_SUCCESS =
  "ADD_ONS_FINANCIALS_LOAD_SUCCESS";
export const ADD_ONS_FINANCIALS_RESET_CURRENT =
  "ADD_ONS_FINANCIALS_RESET_CURRENT";
export const ADD_ONS_FINANCIALS_SET_CURRENT = "ADD_ONS_FINANCIALS_SET_CURRENT";
export const ADD_ONS_FINANCIALS_SET_MANDATORY_FIELDS =
  "ADD_ONS_FINANCIALS_SET_MANDATORY_FIELDS";
export const ADD_ONS_FINANCIALS_SET_INDICATOR_FIELDS =
  "ADD_ONS_FINANCIALS_SET_INDICATOR_FIELDS";
export const ADD_ONS_FINANCIALS_SET_VALUE = "ADD_ONS_FINANCIALS_SET_VALUE";
export const ADD_ONS_FINANCIALS_UPDATE_RULESET_ERROR =
  "ADD_ONS_FINANCIALS_UPDATE_RULESET_ERROR";
export const ADD_ONS_FINANCIALS_UPDATE_RULESET_START =
  "ADD_ONS_FINANCIALS_UPDATE_RULESET_START";
export const ADD_ONS_FINANCIALS_UPDATE_RULESET_SUCCESS =
  "ADD_ONS_FINANCIALS_UPDATE_RULESET_SUCCESS";

//
// Additional Section Add-ons constants
//
export const ADD_ONS_ADDITIONAL_CREATE_RULESET_ERROR =
  "ADD_ONS_ADDITIONAL_CREATE_RULESET_ERROR";
export const ADD_ONS_ADDITIONAL_CREATE_RULESET_START =
  "ADD_ONS_ADDITIONAL_CREATE_RULESET_START";
export const ADD_ONS_ADDITIONAL_CREATE_RULESET_SUCCESS =
  "ADD_ONS_ADDITIONAL_CREATE_RULESET_SUCCESS";
export const ADD_ONS_ADDITIONAL_LOAD_ERROR = "ADD_ONS_ADDITIONAL_LOAD_ERROR";
export const ADD_ONS_ADDITIONAL_LOAD_START = "ADD_ONS_ADDITIONAL_LOAD_START";
export const ADD_ONS_ADDITIONAL_LOAD_SUCCESS =
  "ADD_ONS_ADDITIONAL_LOAD_SUCCESS";
export const ADD_ONS_ADDITIONAL_RESET_CURRENT =
  "ADD_ONS_ADDITIONAL_RESET_CURRENT";
export const ADD_ONS_ADDITIONAL_SET_CURRENT = "ADD_ONS_ADDITIONAL_SET_CURRENT";
export const ADD_ONS_ADDITIONAL_SET_VALUE = "ADD_ONS_ADDITIONAL_SET_VALUE";
export const ADD_ONS_ADDITIONAL_UPDATE_RULESET_ERROR =
  "ADD_ONS_ADDITIONAL_UPDATE_RULESET_ERROR";
export const ADD_ONS_ADDITIONAL_UPDATE_RULESET_START =
  "ADD_ONS_ADDITIONAL_UPDATE_RULESET_START";
export const ADD_ONS_ADDITIONAL_UPDATE_RULESET_SUCCESS =
  "ADD_ONS_ADDITIONAL_UPDATE_RULESET_SUCCESS";
export const ADD_ONS_FINANCIALS_SET_ADDITIONAL_FIELDS =
  "ADD_ONS_FINANCIALS_SET_ADDITIONAL_FIELDS";

//
// Anti Fraud Add-ons constants
//
export const ADD_ONS_ANTI_FRAUD_LOAD_START = "ADD_ONS_ANTI_FRAUD_LOAD_START"
export const ADD_ONS_ANTI_FRAUD_LOAD_SUCCESS = "ADD_ONS_ANTI_FRAUD_LOAD_SUCCESS"
export const ADD_ONS_ANTI_FRAUD_LOAD_ERROR = "ADD_ONS_ANTI_FRAUD_LOAD_ERROR"

//
// Cards Add-ons constants
//
export const ADD_ONS_CARDS_CREATE_RULESET_ERROR =
  "ADD_ONS_CARDS_CREATE_RULESET_ERROR";
export const ADD_ONS_CARDS_CREATE_RULESET_START =
  "ADD_ONS_CARDS_CREATE_RULESET_START";
export const ADD_ONS_CARDS_CREATE_RULESET_SUCCESS =
  "ADD_ONS_CARDS_CREATE_RULESET_SUCCESS";
export const ADD_ONS_CARDS_LOAD_ERROR = "ADD_ONS_CARDS_LOAD_ERROR";
export const ADD_ONS_CARDS_LOAD_START = "ADD_ONS_CARDS_LOAD_START";
export const ADD_ONS_CARDS_LOAD_SUCCESS = "ADD_ONS_CARDS_LOAD_SUCCESS";
export const ADD_ONS_CARDS_RESET_CURRENT = "ADD_ONS_CARDS_RESET_CURRENT";
export const ADD_ONS_CARDS_SET_CURRENT = "ADD_ONS_CARDS_SET_CURRENT";
export const ADD_ONS_CARDS_SET_VALUE = "ADD_ONS_CARDS_SET_VALUE";
export const ADD_ONS_CARDS_UPDATE_RULESET_ERROR =
  "ADD_ONS_CARDS_UPDATE_RULESET_ERROR";
export const ADD_ONS_CARDS_UPDATE_RULESET_START =
  "ADD_ONS_CARDS_UPDATE_RULESET_START";
export const ADD_ONS_CARDS_UPDATE_RULESET_SUCCESS =
  "ADD_ONS_CARDS_UPDATE_RULESET_SUCCESS";
export const ADD_ONS_PAPERLESS_LOAD_ERROR = "ADD_ONS_PAPERLESS_LOAD_ERROR";
export const ADD_ONS_PAPERLESS_LOAD_START = "ADD_ONS_PAPERLESS_LOAD_START";
export const ADD_ONS_PAPERLESS_LOAD_SUCCESS = "ADD_ONS_PAPERLESS_LOAD_SUCCESS";
export const ADD_ONS_PAPERLESS_RESET_CURRENT =
  "ADD_ONS_PAPERLESS_RESET_CURRENT";
export const ADD_ONS_PAPERLESS_CREATE_RULESET_START =
  "ADD_ONS_PAPERLESS_CREATE_RULESET_START";
export const ADD_ONS_PAPERLESS_CREATE_RULESET_SUCCESS =
  "ADD_ONS_PAPERLESS_CREATE_RULESET_SUCCESS";
export const ADD_ONS_PAPERLESS_CREATE_RULESET_ERROR =
  "ADD_ONS_PAPERLESS_CREATE_RULESET_ERROR";
export const ADD_ONS_PAPERLESS_SET_VALUE = "ADD_ONS_PAPERLESS_SET_VALUE";
export const ADD_ONS_PAPERLESS_SET_CONFIG_VALUE =
  "ADD_ONS_PAPERLESS_SET_CONFIG_VALUE";
export const ADD_ONS_PAPERLESS_SET_CURRENT = "ADD_ONS_PAPERLESS_SET_CURRENT";
export const ADD_ONS_PAPERLESS_UPDATE_RULESET_ERROR =
  "ADD_ONS_PAPERLESS_UPDATE_RULESET_ERROR";
export const ADD_ONS_PAPERLESS_UPDATE_RULESET_START =
  "ADD_ONS_PAPERLESS_UPDATE_RULESET_START";
export const ADD_ONS_PAPERLESS_UPDATE_RULESET_SUCCESS =
  "ADD_ONS_PAPERLESS_UPDATE_RULESET_SUCCESS";
// alert constant
  export const ADD_ONS_ALERT_LOAD_ERROR = "ADD_ONS_ALERT_LOAD_ERROR";
  export const ADD_ONS_ALERT_LOAD_START = "ADD_ONS_ALERT_LOAD_START";
  export const ADD_ONS_ALERT_LOAD_SUCCESS = "ADD_ONS_ALERT_LOAD_SUCCESS";
  export const ADD_ONS_ALERT_RESET_CURRENT =
    "ADD_ONS_ALERT_RESET_CURRENT";
  export const ADD_ONS_ALERT_CREATE_RULESET_START =
    "ADD_ONS_ALERT_CREATE_RULESET_START";
  export const ADD_ONS_ALERT_CREATE_RULESET_SUCCESS =
    "ADD_ONS_ALERT_CREATE_RULESET_SUCCESS";
  export const ADD_ONS_ALERT_CREATE_RULESET_ERROR =
    "ADD_ONS_ALERT_CREATE_RULESET_ERROR";
  export const ADD_ONS_ALERT_SET_VALUE = "ADD_ONS_ALERT_SET_VALUE";
  export const ADD_ONS_ALERT_SET_CONFIG_VALUE =
    "ADD_ONS_ALERT_SET_CONFIG_VALUE";
  export const ADD_ONS_ALERT_SET_CURRENT = "ADD_ONS_ALERT_SET_CURRENT";
  export const ADD_ONS_ALERT_UPDATE_RULESET_ERROR =
    "ADD_ONS_ALERT_UPDATE_RULESET_ERROR";
  export const ADD_ONS_ALERT_UPDATE_RULESET_START =
    "ADD_ONS_ALERT_UPDATE_RULESET_START";
  export const ADD_ONS_ALERT_UPDATE_RULESET_SUCCESS =
    "ADD_ONS_ALERT_UPDATE_RULESET_SUCCESS";