import React, { Component } from "react";

import styles from "modules/shared/components/text/css/StandardTermsAndConditions.css";

export default class Background extends Component {
  render() {
    return (
      <div>
        <p className={styles.section_heading}>
          <strong>Background</strong>
        </p>
        <div className={styles.copy}>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>A.</span> 1Centre Pty Limited
            (ACN 623 450 215) (‘
            <strong>1Centre</strong>’) is the wholly-owned subsidiary of 1Centre
            Limited (a company incorporated in New Zealand) and has the
            exclusive Australian licence to a software platform known as the
            1Centre Trade Account Application Platform System (‘
            <strong>Platform</strong>’) that can be provided as a service for
            trade credit applications via the Internet.
          </p>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>B.</span> The Supplier wishes
            to use that service and 1Centre has agreed to provide the agreed
            services upon these 1Centre Standard terms and conditions (‘
            <strong>Terms</strong>’).
          </p>
        </div>
      </div>
    );
  }
}
