import Abstract from "./components/Abstract";
import Authorisation from "./components/Authorisation";
import Complete from "./components/Complete";
import React from "react";

export function componentFactory(section, props = {}) {
  switch (section) {
    case "abstract":
      return <Abstract {...props} />;
    case "authorisation":
      return <Authorisation {...props} />;
    case "complete":
      return <Complete {...props} />;
  }
}
