import React, { Component } from "react";
import {
  verticalContainerCSS,
  verticalScrollbarCSS,
} from "modules/shared/components/text/StandardTermsAndConditions";

import AgreementSection1 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection1";
import AgreementSection10 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection10";
import AgreementSection11 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection11";
import AgreementSection12 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection12";
import AgreementSection2 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection2";
import AgreementSection3 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection3";
import AgreementSection4 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection4";
import AgreementSection5 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection5";
import AgreementSection6 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection6";
import AgreementSection7 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection7";
import AgreementSection8 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection8";
import AgreementSection9 from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/AgreementSection9";
import Background from "modules/shared/components/text/standard_terms_and_conditions/new_zealand/Background";
import SupplierAcknowledgements from "modules/shared/components/text/SupplierAcknowledgements";
import TermsScroller from "modules/shared/components/text/TermsScroller";
import styles from "modules/shared/components/text/css/StandardTermsAndConditions.css";

export default class StandardTermsAndConditions extends Component {
  render() {
    const { css_class, region } = this.props;
    let style = styles.terms;

    if (css_class) {
      style = styles[css_class];
    }

    return (
      <div className={style}>
        <TermsScroller
          speed={0.8}
          className={styles.scroll_section}
          contentClassName="options"
          horizontal={false}
          verticalContainerStyle={verticalContainerCSS}
          verticalScrollbarStyle={verticalScrollbarCSS}
          onScrollToBottom={this.props.onScrollToBottom}
        >
          <Background />
          <p className={styles.section_heading}>
            <strong>Agreement</strong>
          </p>
          <div className={styles.copy}>
            <AgreementSection1 />
            <AgreementSection2 />
            <AgreementSection3 />
            <AgreementSection4 />
            <SupplierAcknowledgements region={region} />
            <AgreementSection5 />
            <AgreementSection6 />
            <AgreementSection7 />
            <AgreementSection8 />
            <AgreementSection9 />
            <AgreementSection10 />
            <AgreementSection11 />
            <AgreementSection12 />
          </div>
        </TermsScroller>
      </div>
    );
  }
}
