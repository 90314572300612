import { formatMoney } from "utils/formatting";
import * as yup from "yup";

import { Values } from "./types";

const EMAIL_ERROR = "Please enter a valid email address";
const EMAIL_REQUIRED = "Email address is required";
const MAX_REQUIRED = "Maximum credit limit is required";
const MIN_REQUIRED = "Minimum credit limit is required";

export function _formatMoney(money: number): string|null {
  if (typeof money !== "number" && !money) {
    return null;
  }

  return formatMoney(money, null, null, null, null);
}

export function getFormSchema(data: Values) {
  const { approvalLevels } = data;
  const minCreditValue = approvalLevels[0].min_credit_limit;
  const maxCreditValue = approvalLevels[approvalLevels.length - 1].credit_limit;

  return yup.object().shape({
    approvalLevels: yup.array().of(
      yup.object().shape({
        credit_limit: yup.number()
          .typeError(MAX_REQUIRED)
          .required(MAX_REQUIRED),
        level: yup.number(),
        minimum_approvers: yup.number()
          .required("Number of approvers is required")
          .min(1, "Number of approvers is required"),
      })
        .test({
          exclusive: true,
          message: MIN_REQUIRED,
          name: "min credit limit",
          test(val) {
            return !(this.path === "approvalLevels[0]" && !val.min_credit_limit);
          },
        })),
    brokerConfig: yup.object().shape({
      brokerEmail: yup.string()
        .when("onOff", {
          is: true,
          then: yup
            .string()
            .email(EMAIL_ERROR)
            .required(EMAIL_REQUIRED),
        }),
      brokerMax: yup.number()
        .when(["onOff", "brokerMin"], {
          is: onOff => onOff,
          then: yup
            .number()
            .typeError(MAX_REQUIRED)
            .max(maxCreditValue, "Exceeds max credit limit")
            .required(MAX_REQUIRED)
            .test({
              exclusive: true,
              message:
                  "Broker max credit limit must be greater than broker min credit limit.",
              name: "min",
              test(val) {
                const brokerMin = this.parent.brokerMin || 0;
                if (!val) return true;
                return val > brokerMin;
              },
            }),
        }),
      brokerMin: yup.number()
        .when("onOff", {
          is: true,
          then: yup
            .number()
            .typeError(MIN_REQUIRED)
            .min(minCreditValue, `Minimum credit limit is ${_formatMoney(minCreditValue)}`)
            .max(maxCreditValue, "Exceeds max credit limit")
            .required(MIN_REQUIRED),
        }),
      insurerEmail: yup.string()
        .when("onOff", {
          is: true,
          then: yup.string().email(EMAIL_ERROR)
            .required(EMAIL_REQUIRED),
        }),
      insurerMax: yup.number()
        .when(["onOff", "brokerMax"], {
          is: onOff => onOff,
          then: yup
            .number()
            .typeError(MAX_REQUIRED)
            .required(MAX_REQUIRED)
            .max(maxCreditValue, "Exceeds max credit limit")
            .test({
              exclusive: true,
              message:
                  "Insurer max credit limit must greater than insurer min credit limit.",
              name: "min",
              test(val) {
                const brokerMax = this.parent.brokerMax || 0;
                if (!val) return true;
                return val && (val > (brokerMax + 1));
              },
            }),
        }),
    }, ["onOff", "insurerMin", "insurerMax", "brokerMax", "brokerMin", "approvalLevels"]),
  })
    .required();
}
