import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faChevronDown,
  faExclamationCircle,
  faSpinner,
  faSync,
} from "@fortawesome/free-solid-svg-icons";

function useFontawesome(props) {
  return library.add(
    faCheckCircle,
    faChevronDown,
    faExclamationCircle,
    faSpinner,
    faSync,
  )
}

export default useFontawesome

