import React from "react";

import styles from "./css/NumberTextBox.css";

var NumberTextBox = createClass({
  getInitialState() {
    return {
      value: this.props.value > 0 ? this.props.value.toString() : "",
      errors: "",
      prefix: this.props.prefix ? this.props.prefix.toString() : "",
      suffix: this.props.suffix ? this.props.suffix.toString() : "",
      error_msgs: this.props.error_msgs || {
        max_reached: "Maximum number is ",
      },
    };
  },

  handleChange(ev) {
    const updateValue = this.props.handleChange;
    const { max, min, disabled } = this.props;
    if (disabled) return;
    var val = this.integerise(ev.target.value);
    if (max != undefined && val > max) {
      this.setState({
        errors: this.state.error_msgs.max_reached + this.formatPreviewText(max),
      });
    } else if (min != undefined && val < min) {
      this.setState({
        errors: this.state.error_msgs.min_reached + this.formatPreviewText(min),
      });
    } else {
      this.setState({ value: val, errors: "" });
      // Formatting as if it was an event:
      updateValue("NumberTextBox", { target: { value: val } });
    }
  },

  integerise(value) {
    if (!value) {
      value = 0;
    }
    return parseInt(value.toString().replace(/[^0-9]/g, ""), 10);
  },

  formatCommaInteger(number) {
    let integer = this.integerise(number);
    return integer.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },

  formatPreviewText(value) {
    const { prefix, suffix } = this.state;
    //regex puts commas between groups of 3 numbers
    value = this.integerise(value);
    return value === ""
      ? prefix + "0" + suffix
      : prefix + this.formatCommaInteger(value) + suffix;
  },

  render() {
    const { small, disabled, hide_preview, inline_style, error } = this.props;
    var input_style = this.state.suffix ? styles.input_suffix : styles.input;

    if (small) {
      input_style = this.state.suffix
        ? styles.input_suffix_small
        : styles.input_small;
    }

    return (
      <div
        className={
          inline_style
            ? styles.inlineBoxContainer
            : styles.numberTextBoxContainer
        }
      >
        <div className={inline_style ? styles.inlineErrors : styles.errors}>
          {error || this.state.errors}
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.prefix}>{this.state.prefix}</div>
          <input
            disabled={disabled}
            type="text"
            className={input_style}
            placeholder="0"
            onChange={this.handleChange}
            value={this.formatCommaInteger(this.props.value)}
          />
          <div className={styles.suffix}>{this.state.suffix}</div>
        </div>
        {hide_preview || (
          <div className={styles.previewText}>
            {this.props.previewMessage}
            {this.formatPreviewText(this.state.value)}
          </div>
        )}
      </div>
    );
  },
});

export default NumberTextBox;
