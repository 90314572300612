import Container from "modules/applications/components/credit-checks/credit-works/v2/Container";
import React, { ReactElement } from "react";

import Graph, { DataProps } from "./graph";

type Props = {
  data: DataProps[];
};

function AgingSummaryGraph(props: Props): ReactElement | null {
  const { data } = props;

  if (data.length === 0) {
    return null;
  }

  return (
    <Container subHeading="Aged Balances Trend">
      <Graph data={data} />
    </Container>
  );
}

export default AgingSummaryGraph;
