import { GridContainer } from "modules/consumer-onboarding/components/review/Cardholder/styles";
import AddonAnswer from "modules/new-applications/components/AddonAnswer";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import LabeledInformation from "modules/shared/components/widgets/static/LabeledInformation";
import React, { ReactElement } from "react";
import { formatMoney } from "utils/formatting";
import isPresent from "utils/isPresent";

const getFullName = ({
  firstName,
  middleName,
  lastName,
}: {
  firstName: string;
  middleName: string;
  lastName: string;
}) =>
  [firstName, middleName, lastName].filter(name => isPresent(name)).join(" ");

const Cardholder = ({
  cardholder,
  config,
  index,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardholder: { [key: string]: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: { [key: string]: any };
  index: number;
}): ReactElement => {
  const subHeading = `Card ${index + 1}`;
  const {
    answers,
    cardLimit,
    email,
    firstName,
    lastName,
    middleName,
    mobile,
  } = cardholder.attributes;

  const {
    additional_fields: additionalFields,
    card_limit_enabled: cardLimitEnabled,
  } = config;

  return (
    <ReviewContainer
      css_class="block_noborder"
      content_class="content_wide"
      collapsible={true}
      subHeading={subHeading}
    >
      <GridContainer>
        <div>
          <LabeledInformation
            label="Name to appear on card"
            data={getFullName({
              firstName,
              lastName,
              middleName,
            })}
            noHumanize
          />
        </div>
        {cardLimitEnabled === "on" && (
          <div>
            <LabeledInformation label="Monthly card limit" data={formatMoney(parseInt(cardLimit), 2, null, null, null)} />
          </div>
        )}
        {isPresent(email) && (
          <div>
            <LabeledInformation label="Email" data={email} />
          </div>
        )}
        {isPresent(mobile) && (
          <div>
            <LabeledInformation label="Phone" data={mobile} />
          </div>
        )}
        <AddonAnswer
          isContained={false}
          answers={answers}
          components={additionalFields}
        />
      </GridContainer>
    </ReviewContainer>
  );
};

export default Cardholder;
