import get from "lodash.get";
import UserEntityLinkModel from "models/UserEntityLinkModel";
import formatUsers, {
  IFormattedUser,
} from "modules/new-applications/utils/formatUsers";
import { useState } from "react";

interface ICurrentUser {
  accessToken: string;
  currentEntity: {
    id: string;
  };
}

interface IApplicationApproverState {
  formattedUsers: IFormattedUser[];
  onFetchApprovers: () => void;
}

const useApplicationApproversState = ({
  applicationId,
  currentUser,
}: {
  applicationId: string;
  currentUser: ICurrentUser;
}): IApplicationApproverState => {
  const [users, setUsers] = useState([]);

  const onFetchApprovers = () => {
    (async() => {
      const users = await UserEntityLinkModel.fetchApplicationApprovers({
        accessToken: currentUser.accessToken,
        applicationId,
        entityId: get(currentUser, "currentEntity.id"),
      });

      setUsers(users);
    })();
  };

  const formattedUsers = formatUsers(users);

  return { formattedUsers, onFetchApprovers };
};

export default useApplicationApproversState;
