import get from "lodash.get";
import CollateralItemTextAreaField from "modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/NZPPSRCollateralAdditionalForms/CollateralsAdditionalForms/MVCollateralDetailsForm/CollateralItemTextAreaField";
import CollateralItemTextField from "modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/NZPPSRCollateralAdditionalForms/CollateralsAdditionalForms/MVCollateralDetailsForm/CollateralItemTextField";
import styles from "modules/new-applications/css/PPSRRegister.css";
import React, { ReactElement } from "react";
import { Field } from "react-hook-form";

const VehicleDetails = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: { [key: string]: any };
  field: Field;
  fieldNamePrefix: string;
  isVisible: boolean;
  register: () => null;
}): ReactElement => {
  const { errors, field, fieldNamePrefix, isVisible, register } = props;

  const visibilityClass = isVisible ? "" : "is-display-none";

  return (
    <div className={`${styles.collateral_detail_item_row} ${visibilityClass}`}>
      <CollateralItemTextField
        error={get(errors, "additionalDetails.vin")}
        field={field}
        fieldName={`${fieldNamePrefix}.vin`}
        label="VIN"
        placeholder="VIN"
        register={register}
      />
      <CollateralItemTextField
        error={get(errors, "additionalDetails.registrationPlate")}
        field={field}
        fieldName={`${fieldNamePrefix}.registrationPlate`}
        label="Registration number"
        placeholder="Registration number"
        register={register}
      />
      <CollateralItemTextField
        error={get(errors, "additionalDetails.make")}
        field={field}
        fieldName={`${fieldNamePrefix}.make`}
        label="Make"
        placeholder="Make"
        register={register}
      />
      <CollateralItemTextField
        error={get(errors, "additionalDetails.model")}
        field={field}
        fieldName={`${fieldNamePrefix}.model`}
        label="Model"
        placeholder="Model"
        register={register}
      />
      <CollateralItemTextField
        error={get(errors, "additionalDetails.year")}
        field={field}
        fieldName={`${fieldNamePrefix}.year`}
        label="Year"
        placeholder="Year"
        register={register}
      />
      <CollateralItemTextField
        error={get(errors, "additionalDetails.chassis")}
        field={field}
        fieldName={`${fieldNamePrefix}.chassis`}
        label="Chassis number"
        placeholder="Chassis number"
        register={register}
      />
      <CollateralItemTextField
        error={get(errors, "additionalDetails.colour")}
        field={field}
        fieldName={`${fieldNamePrefix}.colour`}
        label="Colour"
        placeholder="Colour"
        register={register}
      />
      <CollateralItemTextAreaField
        error={get(errors, "additionalDetails.description")}
        field={field}
        fieldName={`${fieldNamePrefix}.description`}
        label="Item Description"
        placeholder="Item Description"
        register={register}
      />
    </div>
  );
};

export default VehicleDetails;
