import { checkPopupExist, getPopup } from "utils/tipHelper.js";

import Button from "modules/shared/components/inputs/Button";
/* Import libraries */
import React from "react";
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import ToolTip from "modules/shared/components/widgets/interactive/ToolTip";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import { deleteUserPopup } from "../../../../user/actions";
import { formatLocalTime } from "utils/dateFormatter";
import { formatMoney } from "utils/formatting";
import styles from "./css/AdminTable.css";

class AdminTable extends React.Component {
  componentDidMount() {
    const {
      openRow,
      showFirstApplicationToolTip,
      indexOfFirstInProgress,
      current_user_popups,
    } = this.props;

    if (showFirstApplicationToolTip) this.expand(0);
    if (
      openRow == null &&
      indexOfFirstInProgress > -1 &&
      checkPopupExist(current_user_popups, "applications_list_review")
    )
      this.expand(indexOfFirstInProgress);
  }

  getKeyValue = (column, value, keyName = "key") => {
    const { config, admin } = this.props;
    return config[column][keyName].split(".").reduce((a, b) => a[b], value);
  }

  // Handler for expandable rows.
  // Need to pass the expandable property
  // For this to be bound to rows.
  expand = (i) => {
    const { location } = this.props;
    let { openRow } = this.props;

    if (openRow == i) {
      openRow = null;
    } else {
      openRow = i;
    }

    browserHistory.push({
      pathname: location.pathname,
      query: Object.assign(location.query, { open: openRow }),
    });
  }

  // Runs every render, sorting rows.
  sort = (data) => {
    const { config, activeSortColumn, sortOrder, open } = this.props;

    if (config[activeSortColumn]) {
      // Use a 'sortKey' key if one exists.
      let valueKey;
      if (config[activeSortColumn].hasOwnProperty("sortKey")) {
        valueKey = "sortKey";
      }

      let sorted = data.sort((a, b) => {
        let stringA = this.getKeyValue(activeSortColumn, a, valueKey) || "",
          stringB = this.getKeyValue(activeSortColumn, b, valueKey) || "";

        if (stringA < stringB) {
          return -1;
        }
        if (stringA > stringB) {
          return 1;
        }
        return 0;
      });

      // If the state of this column is ASC, reverse the sort.
      if (sortOrder === "asc") {
        sorted.reverse();
      }
      return sorted;
    }

    return data;
  }

  // Bound to each data column header.
  handleSort = (column_index) => {
    const {
      config,
      location,
      handleSortCallback,
      handleRemoteSort,
    } = this.props;
    let { sortOrder } = this.props;

    sortOrder = sortOrder == "desc" ? "asc" : "desc";
    if (handleSortCallback) {
      handleSortCallback(column_index, sortOrder);
    }
    browserHistory.push({
      pathname: location.pathname,
      query: Object.assign(location.query, {
        sort: column_index,
        order: sortOrder,
        open: "-1",
      }),
    });

    handleRemoteSort && handleRemoteSort();
  }
  // Bound to each 'action' button - calls the function in the
  // 'actions' prop mapped by the column's actionKey property.
  handleAction = (actionKey, value) => {
    const { actions } = this.props;
    let action = actions[actionKey];
    action(value);
  }

  hideTooltip = (popup_name) => {
    const { dispatch, current_user_popups } = this.props;
    let popup = getPopup(current_user_popups, popup_name);
    if (popup) {
      dispatch(deleteUserPopup(popup.id));
    }
  }

  render() {
    const {
      data,
      config,
      expandable,
      sortOrder,
      activeSortColumn,
      openRow,
      admin,
      current_user_popups,
      remoteSort,
      loading,
    } = this.props;

    var tool_tip;

    // We sort every render.
    let sortedData = remoteSort ? data : this.sort(data),
      column_headers = [],
      rows = [];

    // Set up the headers from the column config prop.
    config.forEach((col, i) => {
      let th = null,
        directionClass = "desc";

      if (i == activeSortColumn) {
        directionClass = sortOrder;
      }
      if (col.type === "data") {
        th = (
          <th
            key={i}
            className={styles["sort_" + directionClass]}
            width={admin ? col.widthAdmin : col.widthDefault}
            onClick={this.handleSort.bind(null, i)}
          >
            {col.label}{" "}
            <span className={styles["icon_" + directionClass]}></span>
          </th>
        );
      }
      if (col.type === "action") {
        th = <th key={i} />;
      }
      column_headers.push(th);
    });

    // Set up the rows.
    sortedData.forEach((rowData, i) => {
      let tds = [];

      // Add content for table cells via the column config.
      config.forEach((col, i) => {
        let td = null;

        // Fetch value
        let keyValue = this.getKeyValue(i, rowData);
        if (col.onTransformValue) {
          keyValue = col.onTransformValue(keyValue, col, i, rowData);
        }
        // Formatting for regular data values.
        if (col.type === "data") {
          let col_data = keyValue;
          if (col.currency) {
            let amount = parseInt(keyValue);
            if (isNaN(amount) || amount === 0) {
              col_data = "-";
            } else {
              col_data = `$${formatMoney(amount)}`;
            }
          }
          if (col.timestamp) {
            col_data = formatLocalTime(col_data, "date");
          }
          td = (
            <td key={i} className={styles[col.cellClass]}>
              {col_data}
            </td>
          );
        }
        // Formatting for buttons
        if (col.type === "action") {
          let button = (
            <Button
              small={true}
              red={true}
              text={col.label}
              handleClick={this.handleAction.bind(
                null,
                col.actionKey,
                keyValue,
              )}
            />
          );

          if (col.actionKey === "edit" || col.actionKey === "details") {
            button = (
              <Button
                small={true}
                grey={true}
                text={col.label}
                handleClick={this.handleAction.bind(
                  null,
                  col.actionKey,
                  keyValue,
                )}
              />
            );
          }
          if (col.actionKey === "hide") {
            button = (
              <Button
                small={true}
                grey={true}
                text={rowData.attributes[col.label]}
                handleClick={this.handleAction.bind(
                  null,
                  col.actionKey,
                  keyValue,
                )}
              />
            );
          }
          if (rowData.attributes.self && col.actionKey !== "edit") {
            button = "";
          }

          td = (
            <td key={i} width={admin ? col.widthAdmin : col.widthDefault}>
              {button}
            </td>
          );
        }

        tds.push(td);
      });

      // Format the rows - if an expandable prop has been passed in,
      // we bind the expand function to each row, and show the
      // expandable content based on the openRow prop (?open=i)
      if (expandable) {
        let detailsMarkup;
        let row_state;

        if (openRow == i) {
          row_state = styles.expanded;
        }

        // Push the bound row.
        rows.push(
          <tr
            key={i}
            onClick={this.expand.bind(null, i)}
            className={[styles.expandable, row_state].join(" ")}
          >
            {tds}
          </tr>,
        );

        // If selected, push the expanded content too.
        if (openRow == i) {
          if (
            rowData.attributes.status == "in_progress" &&
            checkPopupExist(current_user_popups, "applications_list_review")
          ) {
            tool_tip = (
              <ToolTip
                tip_name="ApplicationsListReview"
                css_style="applications_list_review"
                dismissHandler={this.hideTooltip.bind(
                  null,
                  "applications_list_review",
                )}
              />
            );
          }

          let detailsMarkup = expandable.key
            .split(".")
            .reduce((a, b) => a[b], rowData);
          rows.push(
            <tr key={`expand-${i}`}>
              <td colSpan={config.length} className={styles.details}>
                {detailsMarkup}
                {tool_tip}
              </td>
            </tr>,
          );
        }
      }

      // If this table doesn't have expandable prop,
      // Just push a generic row on.
      else {
        rows.push(<tr key={i}>{tds}</tr>);
      }
    });

    return (
      <table className={styles.adminTable}>
        <thead>
          <tr>{column_headers}</tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={6}>
                <SimpleLoader css_class="loader_all_relative" />
              </td>
            </tr>
          ) : (
            rows
          )}
        </tbody>
      </table>
    );
  }
}

export default connect((state, ownProps) => {
  return {
    location: ownProps.location,
    activeSortColumn:
      ownProps.location.query.sort ||
      (ownProps.activeSortColumn ? ownProps.activeSortColumn : 0),
    sortOrder:
      ownProps.location.query.order ||
      (ownProps.sortOrder ? ownProps.sortOrder : "desc"),
    openRow: ownProps.location.query.open || null,
    expandable: ownProps.expandable,
    showFirstApplicationToolTip: ownProps.showFirstApplicationToolTip,
    current_user_popups: state.current_user.current_user_popups,
  };
})(AdminTable);
