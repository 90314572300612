import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import useBodyScroll from "hooks/useBodyScroll";
import AddonConfigModel from "models/AddonConfigModel";
import UserModel from "models/UserModel";
import { loadCustomerChecklist } from "modules/profile/actions";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import useFormSubmissionStatus from "modules/shared/components/v2/Form/useFormSubmissionStatus";
import { Section, Title, Wrapper } from "modules/shared/components/v2/SupplierSetup/styles";
import UnsavedModal from "modules/shared/components/v2/UnsavedModal";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form-latest";
import isPresent from "utils/isPresent";
import { yupResolver } from "utils/yupResolver/yupResolver";

import { formSchema, getNewVersionAttribute } from "./helper";
import SupplierChecklistForm from "./SupplierChecklistForm";
import SupplierChecklistPreview from "./SupplierChecklistPreview";
import { FormValues } from "./types";

type Props = {
  addonConfig: AddonConfigModel;
  closeModal: () => void;
  currentUser: UserModel;
  dispatch: (dispatchFunction: () => void) => void;
}

function SupplierChecklistSetup({
  addonConfig,
  closeModal,
  currentUser,
  dispatch,
}: Props): ReactElement {
  useBodyScroll();
  const newVersion = addonConfig.generateNewVersion();
  const versionConfig = newVersion.config;

  const [showPreview, setShowPreview] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    clearStatus,
    FormSubmissionStatus,
    setSuccessStatus,
    setErrorStatus,
  } = useFormSubmissionStatus("right");

  const defaultValues = isPresent(versionConfig) ?
    versionConfig :
    {
      checklist: [{ label: "", value: "" }],
      customisedMessage: "",
      links: [],
    };

  const {
    control,
    formState: {
      isValid,
      isDirty,
    },
    reset,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(formSchema),
  })

  const values = watch() as FormValues;

  function displayUnsavedModal() {
    setShowUnsavedModal(true);
  }

  function hideUnsavedModal() {
    setShowUnsavedModal(false);
  }

  function handleClose() {
    if (isDirty) {
      return displayUnsavedModal();
    }

    closeModal();
  }

  function onSuccessCallback() {
    dispatch(loadCustomerChecklist());

    if (showUnsavedModal) {
      return closeModal();
    }

    hidePreview();
    setLoading(false);
    setSuccessStatus();
    // Reset dirty state to prevent re-submission
    reset(values, {
      keepIsSubmitted: true,
    });
  }

  function onErrorCallback() {
    hidePreview();
    hideUnsavedModal();
    setLoading(false);
    setErrorStatus();
  }

  function submit(data) {
    if (!isDirty) {
      return;
    }

    setLoading(true);
    newVersion.setAttributes(getNewVersionAttribute(addonConfig, data));
    newVersion.save({
      addonConfig,
      currentUser,
      onErrorCallback,
      onSuccessCallback,
    });
  }

  function displayPreview() {
    if (!isValid) {
      return;
    }

    setShowPreview(true);
  }

  function hidePreview() {
    setShowPreview(false);
  }

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <Wrapper>
        <div className="container">
          <div className="column">
            <Section>
              <Title>Customer checklist</Title>
              <p>
                Add a customised message and checklist in the beginning of the
                credit applications to help your customers better understand
                the application requirements.
              </p>
              <CloseButton handleClick={handleClose} style={{ right: 0, top: 0 }} />
            </Section>

            { showPreview ?
              <SupplierChecklistPreview {...{ hidePreview, values }} /> :
              <SupplierChecklistForm
                {...{
                  clearStatus,
                  control,
                  displayPreview,
                  handleSubmit,
                  isDirty,
                  isValid,
                  loading,
                  submit,
                  values,
                }}
                status={FormSubmissionStatus}
              />
            }
          </div>
        </div>
        { showUnsavedModal ?
          (
            <UnsavedModal
              loading={loading}
              handleSave={() => submit(values)}
              handleDiscard={closeModal}
              handleEdit={hideUnsavedModal}
              isValid={isValid}
            />
          ) :
          ""
        }
      </Wrapper>
    </MuiThemeProvider>
  )
}

export default SupplierChecklistSetup;
