import React, { Component } from "react";
import styles from "./css/Marker.css";

export default class Marker extends Component {
  render() {
    const cn = [
      styles.line_number,
      this.props.selected && styles.bigger,
      this.props.onClick && styles.pointer,
    ]
      .filter(f => f)
      .join(" ");
    return (
      <div
        className={cn}
        onClick={() => this.props.onClick && this.props.onClick()}
        style={{ background: this.props.color }}
      >
        {this.props.text}
      </div>
    );
  }
}
