import React, { Component } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import TextInput from "modules/shared/components/inputs/TextInput";
import Typography from "@material-ui/core/Typography";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import styles from "./css/ConsumerNameSearch.css";

export default class TradingNameSearchList extends Component {
  constructor() {
    super();
    this.state = {
      keyWord: "",
    };
  }

  onChange = event => {
    this.setState({ keyWord: event.target.value });
  };

  filteredTradingName() {
    const { keyWord } = this.state;
    const { tradingNames } = this.props;
    return tradingNames.filter(tradingName => {
      return (
        tradingName.trading_name
          .toLowerCase()
          .includes(keyWord.toLowerCase()) ||
        tradingName.contact.toLowerCase().includes(keyWord.toLowerCase()) ||
        tradingName.full_address.toLowerCase().includes(keyWord.toLowerCase())
      );
    });
  }

  renderTradingName() {
    const filteredTradingName = this.filteredTradingName();
    if (filteredTradingName.length < 1) {
      return (
        <ListItem>
          <ListItemText
            className={styles.trading_name_full_list_no_result}
            primary={"Can't find child account"}
          />
        </ListItem>
      );
    }
    const items = this.filteredTradingName().map(tradingName => (
      <div key={tradingName.id}>
        <ListItem>
          <ListItemText
            primary={
              <div className={styles.company_text}>
                {tradingName.trading_name}
              </div>
            }
            secondary={
              <React.Fragment>
                <Typography component="span">
                  <div className={styles.company_text}>
                    {tradingName.contact}
                  </div>
                </Typography>
                {tradingName.full_address}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider component="li" />
      </div>
    ));
    return <List>{items}</List>;
  }

  render() {
    const { open, hide } = this.props;

    return (
      <MuiThemeProvider theme={muiTheme()}>
        <Dialog open={open} className={styles.trading_name_full_list}>
          <DialogTitle className={styles.modal_title}>
            {"Full list of child accounts"}
            <HighlightOffIcon
              className={styles.modal_close_button}
              onClick={hide}
            />
          </DialogTitle>
          <DialogContent>
            <TextInput
              value={this.state.keyWord}
              required={true}
              label={"Enter child account name/address"}
              autocomplete={"off"}
              handleChange={this.onChange}
              showSearchIcon={true}
            />
            {this.renderTradingName()}
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}
