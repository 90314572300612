import IdentityCapture from "modules/identity/components/IdentityCapture/IdentityCapture";
import React, { Component } from "react";

import styles from "./css/SignatureContainer.css";
import SignatureContainer from "./SignatureContainer";

export default class SignatureIdentityCapture extends Component {
  render() {
    const {
      backButton,
      handleComplete,
      nextButton,
      supplierLogo,
      supplierTradingName,
    } = this.props;

    return (
      <SignatureContainer
        supplierLogo={supplierLogo}
        supplierTradingName={supplierTradingName}
      >
        <div className={styles.sectionContainer}>
          <div className={styles.content}>
            <IdentityCapture handleComplete={handleComplete} />
          </div>
          <div className={styles.buttonsContainer}>
            {backButton}
            {nextButton}
          </div>
        </div>
      </SignatureContainer>
    );
  }
}
