import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

/* Import CSS */
import styles from "./css/SubSectionContainer.css";

/* Import components */
import Clickable from "modules/shared/components/widgets/interactive/Clickable";
import Icon from "modules/shared/components/svg/Icon";

var SubSectionContainer = createClass({
  getInitialState: function() {
    return { animation: "down" };
  },

  componentWillReceiveProps: function(nextProps) {
    if (nextProps.subsection_index > this.props.subsection_index) {
      this.setState({ animation: "down" });
    } else {
      this.setState({ animation: "up" });
    }
  },

  render: function() {
    const {
      size,
      optional,
      subPages,
      width,
      subsection_index,
      inactiveButtons,
      handleClick,
      position_off,
    } = this.props;
    const position =
      subPages.length > 0
        ? `${String(subsection_index + 1)} of ${String(subPages.length)}`
        : "";

    var prev, next, prevIndex, nextIndex, optional_field;
    let flow_style = styles.flow_item,
      outer_style = styles.flow_outer;

    if (width) {
      flow_style = styles["flow_item_" + width];
    }

    if (size == "large") {
      outer_style = styles.flow_outer_large;
    }

    if (size == "medium") {
      outer_style = styles.flow_outer_medium;
    }

    if (optional == true) {
      optional_field = <p className={styles.optional}>optional</p>;
    }

    var enter, enterActive, leave, leaveActive;

    if (this.state.animation === "down") {
      enter = "enterDown";
      enterActive = "enterActiveDown";
      leave = "leaveDown";
      leaveActive = "leaveActiveDown";
    } else {
      enter = "enterUp";
      enterActive = "enterActiveUp";
      leave = "leaveUp";
      leaveActive = "leaveActiveUp";
    }

    // We're at the start.
    if (subsection_index === 0 && !inactiveButtons) {
      prev = null;
    } else if (subsection_index === 0 && inactiveButtons) {
      prev = (
        <div className={styles.prev_inactive}>
          <Icon icon={"up"} color={"grey"} />
        </div>
      );
    } else {
      prevIndex = subsection_index - 1;
      prev = (
        <div className={styles.prev}>
          <Clickable target={handleClick.bind(null, prevIndex)}>
            <Icon icon={"up"} />
          </Clickable>
        </div>
      );
    }

    // We're at the end.
    if (subsection_index + 1 == subPages.length && !inactiveButtons) {
      next = null;
    } else if (subsection_index + 1 == subPages.length && inactiveButtons) {
      next = (
        <div className={styles.next_inactive}>
          <Icon icon={"down"} color={"grey"} />
        </div>
      );
    } else {
      nextIndex = subsection_index + 1;
      next = (
        <div className={styles.next}>
          <Clickable target={handleClick.bind(null, nextIndex)}>
            <Icon icon={"down"} />
          </Clickable>
        </div>
      );
    }

    return (
      <div className={outer_style}>
        <div className={styles.children}>{this.props.children}</div>
        <ReactCSSTransitionGroup
          className="flow"
          component="div"
          transitionName={{
            enter: styles[enter],
            enterActive: styles[enterActive],
            leave: styles[leave],
            leaveActive: styles[leaveActive],
          }}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          <div key={"sub" + subsection_index} className={flow_style}>
            {subPages[subsection_index]}
          </div>
        </ReactCSSTransitionGroup>
        {optional_field}
        {prev}
        {next}
        {!position_off && (
          <div className={styles.flow_position}>{position}</div>
        )}
      </div>
    );
  },
});

module.exports = SubSectionContainer;
