export function getErrorMessage(error) {
  const blockedMessage = "We weren't able to access your camera. Please update your browser permissions and allow my.1centre.com to access your camera. Once it is all set, click \"Take photo\"";
  const notFoundMessage = "We couldn't find any cameras attached to your computer.";

  const messages = {
    0: blockedMessage,
    1: blockedMessage,
    2: blockedMessage,
    3: blockedMessage,
    4: blockedMessage,
    8: notFoundMessage,
  }

  return messages[error.code];
}
