import get from "lodash.get";
import {
  addApplicantCardholder,
  addCardholders,
  removeApplicantCardholder,
} from "modules/consumer-onboarding/actions/cards";
import Radiobox from "modules/shared/components/inputs/Radiobox";
import TextInput from "modules/shared/components/inputs/TextInput";
import SectionHeader from "modules/shared/components/v2/SectionHeader";
import PanelTitle from "modules/shared/components/widgets/static/PanelTitle";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getCardsAddonConfig, getCardsAddonRule } from "utils/cardsAddon";
import isBlank from "utils/isBlank";
import isInteger from "utils/isInteger";
import isPresent from "utils/isPresent";

import { getCardholderDetailsErrorMessages } from "../CardholderFormWrapper";
import CardsDetails from "../CardsDetails";
import styles from "../css/Cards";
import isSignatureRequired from "../helpers/isSignatureRequired";
import { Wrapper } from "./styles";

const convertRadioValue = value => {
  if (typeof value === "undefined") {
    return null;
  }

  return value ? "Yes" : "No";
};

const getCardholdersAreValid = (cardholders, config) => {
  if (cardholders.length === 0 && !config.mandatory) {
    return true;
  }

  return cardholders.every(cardholder => {
    const errorMessages = getCardholderDetailsErrorMessages(
      cardholder,
      cardholders,
      config,
    );
    return Object.keys(errorMessages).every(key => errorMessages[key] === "");
  });
};

function getCardholdersCountErrorMessage(value, isMandatory) {
  if (isMandatory && (!isInteger(value) || value <= 0)) {
    return "Please enter a valid number for cards requested";
  }

  return "";
}

class CardOrderForm extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      addonsQuestionCompleted,
      cardholders,
      config,
      handleComplete,
      isPreview,
      page_validation_start: pageValidationStart,
      setPageValidationStartFinish,
    } = nextProps;

    if (isPreview) {
      return;
    }

    const cardholdersNoErrors = getCardholdersAreValid(cardholders, config);

    const formErrors = { ...prevState.formErrors };

    const cardholdersCountErrorMessage = getCardholdersCountErrorMessage(
      cardholders.length,
      config.mandatory,
    );
    formErrors["cardholdersCount"] = cardholdersCountErrorMessage;

    if (
      config.signatureRequired &&
      config.mandatory &&
      typeof prevState.cardholderApplicant === "undefined"
    ) {
      formErrors["cardholderApplicant"] = "Please select Yes or No";
    }

    const sectionHasNoErrors =
      isBlank(cardholdersCountErrorMessage) &&
      cardholdersNoErrors &&
      addonsQuestionCompleted;

    handleComplete(sectionHasNoErrors, "cards");

    if (pageValidationStart) {
      return { formErrors, triggerValidation: true };
    }

    setPageValidationStartFinish();

    return { formErrors: prevState.formErrors };
  }

  static isComplete(formErrors) {
    const hasErrors = Object.values(formErrors).some(error => error.length > 0);

    return !hasErrors;
  }

  constructor(props) {
    super(props);

    this.state = {
      cardholderApplicant: this.cardholderApplicant(),
      formErrors: {
        cardholdersCount: "",
      },
    };
  }

  onHandleChangeCount(event) {
    const { config, dispatch } = this.props;
    const value = parseInt(event.target.value);
    dispatch(addCardholders(value, config));
  }

  onHandleChangeValue(key, event) {
    this.props.onHandleChangeValue(key, event.target.value);
  }

  onHandleChangeIsApplicant(event) {
    const value = event.target.value;
    const { dispatch, guarantorApplicant, signatoryApplicant } = this.props;

    if (value === "Yes") {
      const targetApplicant = guarantorApplicant || signatoryApplicant;
      const fieldsToMap = {
        email: "email",
        first_name: "firstName",
        last_name: "lastName",
        middle_name: "middleName",
      };

      const applicantAttributes = {};
      if (targetApplicant) {
        Object.keys(targetApplicant).forEach(key => {
          if (fieldsToMap[key]) {
            applicantAttributes[fieldsToMap[key]] = targetApplicant[key];
          }
        });
      }
      dispatch(addApplicantCardholder(applicantAttributes));
      this.setState({ cardholderApplicant: true });
    }

    if (value === "No") {
      dispatch(removeApplicantCardholder());
      this.setState({ cardholderApplicant: false });
    }
  }

  onHandleBlur() {
    this.validateCardholderCountChange();
  }

  cardholderApplicant = () => {
    const { cardholders } = this.props;

    if (!cardholders || cardholders.length === 0) {
      return;
    }

    return cardholders.find(({ attributes }) => attributes.isApplicant);
  };

  otherCardholders() {
    const { cardholders } = this.props;
    return cardholders.filter(({ attributes }) => !attributes.isApplicant);
  }

  validateCardholderCountChange() {
    const { config } = this.props;

    if (this.cardholderApplicant()) {
      return this.setState({
        formErrors: {
          ...this.state.formErrors,
          cardholdersCount: "",
        },
      });
    }

    const cardholdersCountErrorMessage = getCardholdersCountErrorMessage(
      this.otherCardholders().length,
      config.mandatory,
    );

    this.setState({
      formErrors: {
        ...this.state.formErrors,
        cardholdersCount: cardholdersCountErrorMessage,
      },
    });
  }

  render() {
    const { application, config } = this.props;
    const { formErrors, triggerValidation } = this.state;
    const cardholderApplicant = this.cardholderApplicant();
    const otherCardholders = this.otherCardholders();

    const numberOfCardOrderTitle = cardholderApplicant ?
      "No. of additional cards required (excl. yourself)" :
      "No. of cards requested";

    return (
      <Wrapper className={styles.section}>
        <div className={styles.row}>
          <div className={styles.full_width}>
            <PanelTitle text="Card order" margin_bottom="1em" />
          </div>
        </div>
        {config.signatureRequired && (
          <div className={styles.row}>
            <div className={styles.half_width}>
              <SectionHeader title="I will be one of the cardholders" />
              <Radiobox
                id="isApplicant"
                name="isApplicant"
                radioList={["Yes", "No"]}
                value={convertRadioValue(this.state.cardholderApplicant)}
                handleChange={this.onHandleChangeIsApplicant.bind(this)}
                error={formErrors.cardholderApplicant}
                disabled={
                  get(application, "attributes.submission_status", "") ===
                  "complete"
                }
              />
            </div>
          </div>
        )}
        {cardholderApplicant && (
          <CardsDetails
            key={cardholderApplicant.attributes.id || "cardholder-applicant"}
            cardholder={cardholderApplicant}
            {...this.props}
            triggerValidation={triggerValidation}
            index={0}
            title="Please enter details for your card"
          />
        )}
        <div style={{ paddingTop: "1rem" }}>
          <div className={styles.row}>
            <div className={styles.half_width}>
              <SectionHeader title={numberOfCardOrderTitle} />
              <TextInput
                key="cardholdersCount"
                id="cardholdersCount"
                name="cardholdersCount"
                error={formErrors.cardholdersCount}
                handleChange={this.onHandleChangeCount.bind(this)}
                handleBlur={this.onHandleBlur.bind(this)}
                number_only={true}
                type="number"
                required={true}
                value={otherCardholders.length}
              />
            </div>
          </div>
        </div>
        {otherCardholders.map((cardholder, index) => (
          <CardsDetails
            key={cardholder.attributes.id || `cardholder-${index}`}
            cardholder={cardholder}
            displayDeleteBtn
            {...this.props}
            triggerValidation={triggerValidation}
            title={`Card ${index + 1}`}
            onHandleChangeValue={this.onHandleChangeValue}
            index={cardholderApplicant ? index + 1 : index}
          />
        ))}
      </Wrapper>
    );
  }
}

export default connect((state, ownProps) => {
  const previewAddon = ownProps.previewAddon;
  const sharedData = state.shared_data;
  const guarantors = state.cob_guarantors.form_values || [];
  const signatories = state.cob_business.entity_party_details_values || [];
  const cardholdersAddonQuestionKeys = Object.keys(sharedData).filter(key =>
    key.includes("cardholder_"),
  );
  let config;

  const cobSection = state.cob_section;

  const addonsQuestionCompleted = cardholdersAddonQuestionKeys.every(key =>
    sharedData[key].flat().every(status => status),
  );

  if (isPresent(previewAddon)) {
    config = previewAddon.config;
  } else {
    const addonRule = getCardsAddonRule(cobSection.addonRules);
    config = getCardsAddonConfig(addonRule);
  }

  const guarantorApplicant = guarantors.find(
    guarantor => guarantor.is_applicant,
  );
  const signatoryApplicant = signatories.find(
    signatory => signatory.is_applicant,
  );

  const configSignatureRequired = get(config, "signature_required") === "on";
  const signatureRequired = isSignatureRequired(state, configSignatureRequired);

  return {
    addonsQuestionCompleted,
    cardholders: state.cob_cards.cardholders,
    cardholdersCount:
      state.cob_section.application.attributes.cardholders_count,
    config: {
      ...config,
      cardLimitEnabled: config.card_limit_enabled === "on",
      signatureRequired,
    },
    guarantorApplicant,
    signatoryApplicant,
  };
})(CardOrderForm);
