import React from "react";
import moment from "moment";

import styles from "../centrix.css";

class CreditDefaults extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getArrayifiedData(data) {
    if (!data.data_sets.defaults || !data.data_sets.defaults.default) {
      return [];
    }
    let defaults = data.data_sets.defaults.default;
    if (defaults.constructor !== Array) {
      return [defaults];
    }
    return defaults;
  }

  render() {
    const { data } = this.props;
    let arrayified_data = this.getArrayifiedData(data);

    return (
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Account Type</th>
              <th>Credit Provider</th>
              <th>Reference</th>
              <th>Original Amount</th>
              <th>Current Balance</th>
              <th>Status</th>
              <th>Status Date</th>
            </tr>
          </thead>
          <tbody>
            {arrayified_data.map(item => (
              <tr>
                <td>{moment(item.date_of_default).format("DD/MM/YYYY")}</td>
                <td>{item.account_type_description}</td>
                <td>{item.credit_provider}</td>
                <td>{item.reference}</td>
                <td>
                  {item.original_default_amount &&
                    item.original_default_amount.constructor === String &&
                    item.original_default_amount}
                </td>
                <td>
                  {item.current_default_balance &&
                    item.current_default_balance.constructor === String &&
                    "$" + item.current_default_balance}
                </td>
                <td>{item.default_status_description}</td>
                <td>{moment(item.status_date).format("DD/MM/YYYY")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CreditDefaults;
