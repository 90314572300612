import styled from "styled-components";
import { BORDERS } from "variables/theme";

export const Wrapper = styled.div`
  > *:first-child {
    margin-bottom: -2rem;
  }

  > *:not(:first-child, :nth-child(2)) {
    border-top: ${BORDERS.generic};
    padding-top: 2rem;
  }

  > * {
    padding-bottom: 2rem;
  }
`;
