/* Components */
import React from "react";
import { connect } from "react-redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import { browserHistory } from "react-router";

/* Import CSS */
import styles from "./css/LoginApplication.css";

/* Actions */
import { userLogin, setLoginFormField } from "../actions";

/* Import components */
import Loader from "modules/shared/components/widgets/static/Loader";
import SectionCircle from "modules/shared/components/widgets/static/SectionCircle";
import WorkflowOverlay from "modules/shared/components/top/WorkflowOverlay";

import LoginForm from "../forms/LoginForm";

/* Login */
var LoginApplication = createClass({
  // /**
  //  * Redirect
  //  */
  redirect: function() {
    browserHistory.push("/dashboard/applications/all/customers");
  },

  handleChange: function(event) {
    const { dispatch } = this.props;
    dispatch(setLoginFormField(event.target.name, event.target.value));
  },

  /**
   * Submit handler
   *
   * @param data
   */
  login: function(event) {
    event.preventDefault();
    const {
      email,
      password,
      dispatch,
      location: {
        query: { redirect },
      },
    } = this.props;
    let redirectPath = "/dashboard";
    if (redirect) {
      redirectPath = redirect;
    }
    dispatch(userLogin(email, password, redirectPath));
  },

  /**
   * Render
   *
   * @returns {XML}
   */
  render: function() {
    const { email, password } = this.props;

    return (
      <div>
        <WorkflowOverlay
          position="top"
          section_title="Trade account application"
        />
        <SectionCircle title="Log in to complete your application.">
          <ReactCSSTransitionGroup
            transitionName={{
              appear: styles.appear,
              appearActive: styles.appearActive,
            }}
            transitionAppear={true}
            transitionAppearTimeout={200}
          >
            <LoginForm
              submitHandler={this.login}
              changeHandler={this.handleChange}
              blurHandler={this.handleChange}
              password={password}
              email_address={email}
              button_label="enter"
            />
          </ReactCSSTransitionGroup>
        </SectionCircle>
      </div>
    );
  },
});

export default connect((state, ownProps) => {
  return {
    email: state.login.email,
    password: state.login.password,
  };
})(LoginApplication);
