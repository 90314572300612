import {
  SET,
  RESET,
  SET_REGION,
  SET_STATE,
  CONSUMER_APPLICATION_ARCHIVED_COMPLETE,
  SUPPLIER_APPLICATION_ARCHIVED_COMPLETE,
  APPLICATIONS_DELETE_APPLICATION_START,
  CONSUMER_APPLICATIONS_DELETE_APPLICATION_SUCCESS,
  SUPPLIER_APPLICATIONS_DELETE_APPLICATION_SUCCESS,
  APPLICATIONS_DELETE_APPLICATION_ERROR,
  APPLICATIONS_REALLOCATION_ERROR,
  APPLICATIONS_REALLOCATION_START,
  APPLICATIONS_REALLOCATION_SUCCESS,
} from "./constants";

import moment from "moment";

const initialState = {
  account_type: "All",
  activeTab: "total",
  applicationDeleting: false,
  approved_limit_max: null,
  approved_limit_min: null,
  auto_decisions: "All",
  branch: "All",
  connectionApplications: [],
  connections: [],
  consumerApplications: [],
  consumerApplicationsMeta: {
    approved_count: 0,
    archived_count: 0,
    current_total_count: 0,
    declined_count: 0,
    incomplete_count: 0,
    pending_count: 0,
    total_count: 0,
    total_limit: 0,
  },
  consumerApplicationsPage: 1,
  consumerApplicationsPerPage: 25,
  entity_type: "All",
  from: moment().set({ date: 25, month: 11, year: 2016 }),
  level: "All",
  period: { label: "All", value: "All" },
  region: "All",
  requested_limit_max: null,
  requested_limit_min: null,
  search: {},
  show_approved_limit: false,
  state: "All",
  supplierApplications: [],
  supplierApplicationsLoading: true,
  supplierApplicationsMeta: {
    approved_count: 0,
    archived_count: 0,
    current_total_count: 0,
    declined_count: 0,
    incomplete_count: 0,
    pending_count: 0,
    total_count: 0,
    total_limit: 0,
  },
  supplierApplicationsPage: 1,
  supplierApplicationsPerPage: 25,
  to: moment().add(5, "d"),
  user: "All",
};

export function reportingReducer(state = initialState, action) {
  switch (action.type) {
    case SET:
      return { ...state, ...action.payload };
    case RESET:
      return { ...initialState, search: {} };
    case SET_REGION:
      return { ...state, region: action.payload };
    case SET_STATE:
      return { ...state, state: action.payload };
    case CONSUMER_APPLICATION_ARCHIVED_COMPLETE:
      var application = state.consumerApplications.find(
        a => a.id === action.payload.applicationId,
      );
      application.attributes.archived = true;
      application.attributes.archived_note = action.payload.archivedNote;
      application.attributes.is_accepted = false;
      application.attributes.is_declined = false;
      application.attributes.is_incomplete = false;
      application.attributes.is_pending = false;
      return { ...state };
    case SUPPLIER_APPLICATION_ARCHIVED_COMPLETE:
      var application = state.supplierApplications.find(
        a => a.id === action.payload.applicationId,
      );
      application.attributes.archived = true;
      application.attributes.archived_note = action.payload.archivedNote;
      application.attributes.is_accepted = false;
      application.attributes.is_declined = false;
      application.attributes.is_incomplete = false;
      application.attributes.is_pending = false;
      return { ...state };
    case APPLICATIONS_DELETE_APPLICATION_START:
      return { ...state, applicationDeleting: true };
    case CONSUMER_APPLICATIONS_DELETE_APPLICATION_SUCCESS:
      var application = state.consumerApplications.filter(
        a => a.id !== action.payload.applicationId,
      );
      return {
        ...state,
        consumerApplications: application,
        applicationDeleting: false,
      };
    case SUPPLIER_APPLICATIONS_DELETE_APPLICATION_SUCCESS:
      var application = state.supplierApplications.filter(
        a => a.id !== action.payload.applicationId,
      );
      return {
        ...state,
        supplierApplications: application,
        applicationDeleting: false,
      };
    case APPLICATIONS_DELETE_APPLICATION_ERROR:
      return {
        ...state,
        applicationDeleting: false,
        applicationDeletingError: action.payload,
      };
    case APPLICATIONS_REALLOCATION_START:
      return { ...state, reallocating: true };
    case APPLICATIONS_REALLOCATION_ERROR:
    case APPLICATIONS_REALLOCATION_SUCCESS:
      return { ...state, reallocating: false };
    default:
      return { ...state };
  }
}
