/* Import libraries */
import React from "react";
import { browserHistory } from "react-router";
import { connect } from "react-redux";

/** import comonents **/
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import SimpleTable from "modules/shared/components/widgets/interactive/SimpleTable";

import styles from "./css/ApplicationList.css";

var ConsumerPendingApplicationsList = createClass({
  render: function() {
    const { data, total } = this.props;

    var content = (
      <SimpleTable
        data={data.slice(0, Math.min(data.length, 6))}
        config={options.config}
      />
    );

    return (
      <div className={styles.liner}>
        <hr />
        <div className={styles.content}>
          <h3 className={styles.count}>{total}</h3>
          <h3 className={styles.heading}>{options.title}</h3>
        </div>
        {content}
      </div>
    );
  },
});

var options = {
  title: "Applications pending approval from supplier",
  config: [
    {
      type: "data",
      key: "attributes.supplier_name",
      widthDefault: 300,
    },
    {
      type: "data",
      key: "attributes.status",
      widthDefault: 150,
    },
    {
      type: "data",
      key: "attributes.updated_at",
      widthDefault: 100,
      timestamp: true,
    },
  ],
};

module.exports = ConsumerPendingApplicationsList;
