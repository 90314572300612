import AlertNotificationPayloadStructure from "modules/webhooks/v2/components/V2Documentation/Content/AlertNotificationPayloadStructure";
import ApprovedApplicationPayloadStructure from "modules/webhooks/v2/components/V2Documentation/Content/ApprovedApplicationPayloadStructure";
import Connecting from "modules/webhooks/v2/components/V2Documentation/Content/Connecting";
import Events from "modules/webhooks/v2/components/V2Documentation/Content/Events";
import Introduction from "modules/webhooks/v2/components/V2Documentation/Content/Introduction";
import React, { ReactElement } from "react";

const Content = ({
  activeSection,
}: {
  activeSection: string;
}): ReactElement => {
  let ContentComponent = Introduction;

  switch (activeSection) {
    case "connecting":
      ContentComponent = Connecting;
      break;
    case "events":
      ContentComponent = Events;
      break;
    case "approved-application-payload-structure":
      ContentComponent = ApprovedApplicationPayloadStructure;
      break;
    case "alert-notification-payload-structure":
      ContentComponent = AlertNotificationPayloadStructure;
      break;
    default:
      ContentComponent = Introduction;
  }

  return <ContentComponent />;
};

export default Content;
