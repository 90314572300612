import ExpandableSection from "./ExpandableSection";
import React from "react";
import { connect } from "react-redux";
import styles from "./css/AutoDecisioning.css";

function AMLVerification(props) {
  const { requiresAMLCheck, error, read_only: readOnly } = props;

  return (
    <div className={styles.section}>
      <ExpandableSection
        id={"aml_verification"}
        title={defaults.title}
        info_text={"Do you require AML Verification for auto decisioning?"}
        disabled={readOnly}
        account_rule_on={requiresAMLCheck}
        hide_setting_message={readOnly}
        error={error}
      />
    </div>
  );
}

const defaults = {
  title: "AML Verification",
};

export default connect((_, ownProps) => {
  return {
    requiresAMLCheck: ownProps.current_entity.requires_aml_check,
  };
})(AMLVerification);
