import Modal from "modules/dashboard/containers/components/Modal";
import React, { ReactElement } from "react";

import { ButtonWrapper } from "./styles";

interface Props {
  activeCardIndex: number,
  closeModal: () => void,
  deleteCardholder: (index: number) => void,
  setActiveCardIndex: (index: number|null) => void,
  zIndex?: number,
}

function CardRemoveModal({
  activeCardIndex,
  closeModal,
  deleteCardholder,
  setActiveCardIndex,
  zIndex,
}: Props): ReactElement {
  return (
    <Modal
      content={
        <div className="has-text-centered">
          <div>Please confirm you would like to remove this card.</div>
          <ButtonWrapper>
            <button
              type="button"
              className="button is-danger is-rounded"
              onClick={() => {
                deleteCardholder(activeCardIndex)
                setActiveCardIndex(null);
              }}
            >
              Remove
            </button>
            <button
              type="button"
              className="button is-primary is-outlined is-rounded"
              onClick={closeModal}
            >
              Cancel
            </button>
          </ButtonWrapper>
        </div>
      }
      onClose={closeModal}
      zIndex={zIndex}
    />
  )
}

export default CardRemoveModal
