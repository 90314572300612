import styled, { css } from "styled-components";
import { COLORS, THEME_COLORS } from "variables/theme";
import { FONT_SIZES } from "variables/typography";
import { GLOBAL_TRANSITION } from "variables/utils";

type ButtonProps = {
  active: boolean,
}

export const Section = styled.div`
  border-bottom: 1px solid ${THEME_COLORS.sectionBorder};
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  > p {
    max-width: 90%;
  }
`;

export const Tab = styled.button<ButtonProps>`
  ${GLOBAL_TRANSITION};

  background: transparent;
  border: none;
  color: var(--main-color);
  border-radius: 3rem;
  padding: 1rem 2rem;

  ${props => props.active && css`
    background-color: var(--main-color);
    color: ${COLORS.white}
  `}
`;

export const Tabs = styled.div`
  margin-top: 2rem;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const Title = styled.h1`
  font-size: ${FONT_SIZES.heading};
  margin-bottom: 0.5rem;
`;

export const Wrapper = styled.div`
  background: ${THEME_COLORS.bodyBackground};
  bottom: 0;
  display: block;
  left: 0;
  height: auto;
  overflow-y: scroll;
  padding: 8.5rem 0 3rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000 !important;
`;
