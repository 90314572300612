import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { isMobile } from "modules/shared/helpers/mobileDetect";
import styles from "./css/PopperTooltip.css";

function PopperTooltip(props) {
  const {
    children,
    extraComponent,
    isVisibleOnMobile,
    noArrow,
    open,
    title,
    ...rest
  } = props;

  const setArrowRef = (ref) => {
    if (ref) {
      const left = Math.ceil(ref.offsetWidth / 2) - 2;
      ref.style.left = `${left}em`;
    }
  }

  if (!isVisibleOnMobile && isMobile()) {
    return children;
  }

  return (
    <Tooltip
      classes={{
        tooltip: styles.tooltip,
      }}
      title={
        <React.Fragment>
          <span className={styles.title}>{title}</span>
          <div className={styles.extra_component}>{extraComponent}</div>
        </React.Fragment>
      }
      open={open}
      enterTouchDelay={200}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}

PopperTooltip.defaultProps = {
  isVisibleOnMobile: true,
};


export default PopperTooltip;