import SectionBadge from "modules/shared/components/widgets/static/SectionBadge";
import SectionDescription from "modules/shared/components/widgets/static/SectionDescription";
import SectionTitle from "modules/shared/components/widgets/static/SectionTitle";
import React, { Component } from "react";
import { connect } from "react-redux";

import styles from "./css/Section.css";

class Complete extends Component {
  render() {
    const { approved, supplierName } = this.props;

    let title = "Authorisation complete!";
    let secondaryTitle = null;
    let subtext = "";
    let icon = "thumbsup";
    if (!approved) {
      title = "Thanks for letting us know.";
      secondaryTitle = `We will notify ${supplierName} of your decision.`;
      subtext =
        "If required, you can click back on your original link to change the outcome.";
      icon = "hands";
    }

    return (
      <div className={styles.page}>
        <div className={styles.containerFlex}>
          <section className={styles.blue_section}>
            <div className={styles.row}>
              <div className={styles.blue_panel}>
                <SectionTitle white={true} text={title} />
                {secondaryTitle && (
                  <SectionTitle white={true} text={secondaryTitle} />
                )}
                <SectionBadge icon={icon} state="section_complete" />
              </div>
            </div>
            <div className={styles.description_row}>
              <div className={styles.description}>
                <SectionDescription text={subtext} />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  const approved =
    state.authorisation.guarantorApproved ||
    state.authorisation.cardholderApproved ||
    state.authorisation.paymentApproved ||
    state.authorisation.signatoryApproved;

  return {
    approved,
  };
})(Complete);
