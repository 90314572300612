import get from "lodash.get";
import BankNumber from "modules/authorisation/components/ReviewPaperless/BankNumber";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import LabeledInformation from "modules/shared/components/widgets/static/LabeledInformation";
import React from "react";

import styles from "./css/Review.css";

class ReviewPaperless extends React.Component {
  paymentContent({ application, index, payment }) {
    const attributes = payment.attributes;
    const paymentName = [
      attributes.first_name,
      attributes.middle_name,
      attributes.last_name,
    ]
      .filter(Boolean)
      .join(" ");
    const subHeading = `Signatory ${index + 1}: ${paymentName}`;

    return (
      <ReviewContainer
        css_class="block_noborder"
        content_class="content_wide"
        collapsible={true}
        key={`review-paperless-${index}`}
        subHeading={subHeading}
      >
        <div className={styles.width_100}>
          <div className={styles.half_width}>
            <LabeledInformation
              key={"name"}
              label={"Name"}
              data={paymentName}
              noHumanize
              showEmpty={true}
            />
          </div>
          <div className={styles.half_width}>
            <LabeledInformation
              key={"email"}
              label={"Email"}
              data={attributes.email}
              noHumanize
              showEmpty={true}
            />
          </div>
          <div className={styles.half_width}>
            <LabeledInformation
              key={"debit_account_name"}
              label={"Account to be debited"}
              data={attributes.debit_account_name}
              noHumanize
              showEmpty={true}
            />
          </div>
          <BankNumber
            bankNumber={get(payment, "attributes.bank_number")}
            region={get(application, "attributes.region")}
          />
          <div className={styles.half_width}>
            <LabeledInformation
              key={"bank_account_number"}
              label={"Bank account number"}
              data={attributes.bank_account_number}
              noHumanize
              showEmpty={true}
            />
          </div>
        </div>
      </ReviewContainer>
    );
  }

  paymentsContent() {
    const {
      data: { application, payments },
    } = this.props;

    if (payments.length === 0) {
      payments.push(paperlessDefault);
    }

    return payments.map((payment, index) =>
      this.paymentContent({ application, index, payment }),
    );
  }

  render() {
    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        {this.paymentsContent()}
      </section>
    );
  }
}

const paperlessDefault = {
  attributes: {
    first_name: "",
    last_name: "",
  },
};

module.exports = ReviewPaperless;
