import axios from "./axios";;

export default class PpsrFinancingStatements {
  constructor(config) {
    config.timeout = 5 * 60 * 1000;

    this.axios = axios(config);
    this.type = "ppsr_financing_statements";
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      "v1",
      "v2",
    );
    this.path = `/${this.type}`;
  }

  fetchAll(params) {
    return this.axios.get(this.path, { params });
  }

  create(attributes) {
    return this.axios.post(this.path, {
      data: { attributes, type: this.type },
    });
  }

  /** Update action is to "update" a non-registered financing statement */
  update(id, attributes) {
    return this.axios.patch(`${this.path}/${id}`, {
      data: { attributes, type: this.type },
    });
  }

  /** Modify is to update already registered financing statement */
  modify({ attributes, financingStatementPin, id }) {
    return this.axios.patch(`${this.path}/${id}/modify`, {
      data: { attributes, type: this.type },
      financing_statement_pin: financingStatementPin,
    });
  }

  renew(id, attributes) {
    return this.axios.post(`${this.path}/${id}/renew`, {
      data: { attributes, type: this.type },
    });
  }

  discharge(id, attributes) {
    return this.axios.post(`${this.path}/${id}/discharge`, {
      data: { attributes, type: this.type },
    });
  }

  updatePin(id, financingStatementPin) {
    return this.axios.patch(`${this.path}/${id}/update_pin`, {
      financing_statement_pin: financingStatementPin,
    });
  }

  // TODO - consider moving method to Entity
  fetchBranchOptions() {
    return this.axios.get(`${this.path}/filter_options`);
  }
}
