//
// Add other Credit Check components into this list
// Naming convention "[credit_check_type]Component"
//   * credit_check_type
//     - this is the `type` field from Rails, i.e. CreditWorks: CreditWorksComponent
//

import AustraliaEquifaxComponent from "./australia/equifax/EquifaxComponent";
import AustraliaEquifaxPersonalComponent from "./australia/equifax/EquifaxPersonalComponent";
import AustraliaIllionConsumerRiskScoreComponent from "./australia/illion/ConsumerRiskScoreComponent";
import AustraliaIllionPaymentPredictorComponent from "./australia/illion/PaymentPredictorComponent";
import CentrixComponent from "./centrix/CentrixComponent";
import CreditWorksComponent from "./credit-works/CreditWorksComponent";

export { AustraliaEquifaxComponent };
export { AustraliaEquifaxPersonalComponent };
export { AustraliaIllionConsumerRiskScoreComponent };
export { AustraliaIllionPaymentPredictorComponent };
export { CentrixComponent };
export { CreditWorksComponent };
