import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#business-descriptions-retrieving-all-industry-divisions
 */
let BusinessDescriptions = function(config) {
  this.axios = axios(config);
  this.type = "business_descriptions";
};

BusinessDescriptions.prototype.getBusinessDescriptions = function(
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

BusinessDescriptions.prototype.getBusinessDescription = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

BusinessDescriptions.prototype.getParentBusinessDescription = function(
  id,
  success,
  error,
  options = {},
) {
  return this.axios
    .get(`/${this.type}/${id}/parent`, options)
    .then(success)
    .catch(error);
};

export default BusinessDescriptions;
