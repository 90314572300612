import api from "api";
import get from "lodash.get";
import BaseModel from "models/BaseModel";
import EntityModel from "models/EntityModel";
import UserEntityLinkModel from "models/UserEntityLinkModel";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";

export default class UserModel extends BaseModel {
  static fromCurrentUser(user) {
    const { data, ...otherAttributes } = user;

    return new UserModel(
      get(data, "data"),
      get(data, "included"),
      otherAttributes,
    );
  }

  static async updatePreference({ accessToken, attributes, entityId, user }) {
    const userAPI = api("users", accessToken, entityId);

    try {
      await userAPI.updatePreference(user.id, { params: attributes });
    } catch (error) {
      console.error(error);
    }
  }

  get accessToken() {
    return get(this, "otherAttributes.access_token");
  }

  get preferenceByCurrentEntity() {
    const preference = this.preference || {};
    const entityId = (this.currentEntity || {}).id;

    if (isBlank(entityId)) {
      return {};
    }

    return get(preference, entityId, {});
  }

  get isAdmin() {
    return get(this.currentUserEntityLink, "roleTypes", []).includes("admin");
  }

  get isApprover() {
    return get(this.currentUserEntityLink, "roleTypes", []).includes("approver");
  }

  get isStandard() {
    return get(this.currentUserEntityLink, "roleTypes", []).includes("standard");
  }

  constructor(data = {}, included = [], otherAttributes = {}) {
    super(data, included);

    this.otherAttributes = otherAttributes;
    this.assignCurrentUserEntityLink();
    this.assignCurrentEntity();
  }

  updatePreferences(newPreference) {
    const currentEntityId = this.currentEntity.id;
    const currentPreference = this.preference || {};
    const preferenceByEntity = get(currentPreference, currentEntityId, {});
    const preference = { ...preferenceByEntity, ...newPreference };
    currentPreference[currentEntityId] = preference;

    this.setAttribute("preference", currentPreference);

    UserModel.updatePreference({
      accessToken: this.accessToken,
      attributes: { [currentEntityId]: newPreference },
      entityId: currentEntityId,
      user: this,
    });
  }

  /** Private functions */

  assignCurrentUserEntityLink() {
    if (isPresent(this.otherAttributes)) {
      this.currentUserEntityLink = new UserEntityLinkModel(
        get(this.otherAttributes, "current_user_entity_link", {}),
      );
    }
  }

  assignCurrentEntity() {
    if (isPresent(this.otherAttributes)) {
      this.currentEntity = new EntityModel(
        get(this.otherAttributes, "current_entity", {}),
      );
    }
  }
}
