/* Import actions */
import {
  componentComplete,
  lookupAddress,
  lookupResidentialAddress,
  selectPhysicalAddress,
  selectPostalAddress,
  setEntityFormValue,
  setEntityPartyDetailsData,
  setEntityPartyDetailsIndex,
  setPhysicalAddress,
  setPostalAddress,
} from "../../actions/onboarding";

import AutoSuggest from "modules/shared/components/widgets/interactive/AutoSuggest";
import CircleLogo from "modules/shared/components/widgets/static/CircleLogo";
import DateString from "modules/shared/components/widgets/static/DateString";
import LabeledInformation from "modules/shared/components/widgets/static/LabeledInformation";
import LegalEntityAgreementUploader from "modules/legal-entity-agreement-uploader/components/LegalEntityAgreementUploader";
import React from "react";
import RegionStatesDropdown from "modules/shared/components/widgets/static/RegionStatesDropdown";
import ReviewContainer from "modules/shared/components/containers/ReviewContainer";
import ReviewPartyDetailsForm from "./ReviewPartyDetailsForm";
import TextInput from "modules/shared/components/inputs/TextInput";
import { debounce } from "throttle-debounce";
import { entityTypeTitle } from "utils/entityTypeTitle";
/* Import components */
import isBlank from "utils/isBlank";
import moment from "moment";
import { reviewComponentComplete } from "../../actions/review";
import { setPeopleData } from "../../actions/section";
/* Import CSS */
import styles from "./css/Review.css";
import { throttle } from "throttle-debounce";
import get from "lodash.get";
import { getTradingNameTitleByRegion } from "utils/entityTypeTitle";

var ReviewBusiness = createClass({
  componentWillReceiveProps: function(nextProps) {
    const { dispatch, review_complete } = this.props;
    let complete = this.isComplete(
      nextProps.data,
      nextProps.application,
      nextProps.people,
    );
    if (review_complete.business !== complete) {
      dispatch(reviewComponentComplete("business", complete));
    }

    if (nextProps.summary_validation_start) {
      this.checkValidAll();
    }
  },

  checkValidAll: function() {
    const { data, setPageValidationStartFinish } = this.props;

    var forms = [
      "entity_name",
      "physical_address",
      "postal_address",
      "entity_staff_count",
      "entity_phone",
    ];
    var partyDetailForms = ["first_name", "last_name", "email"];

    if (data.entity_type === "personal") {
      forms = ["entity_name"];
      let personal_forms = [
        "residential_address",
        "residential_type",
        "contact_phone_number",
        "contact_country_code",
      ];
      partyDetailForms = partyDetailForms.concat(personal_forms);
    }

    for (var i = 0; i < data.entity_party_details_values.length; i++) {
      let entity_party_details_values = data.entity_party_details_values[i];
      entity_party_details_values.index = i;
      forms.push(entity_party_details_values);
    }

    forms.forEach((value, index) => {
      setTimeout(() => {
        if (
          value === "entity_name" ||
          value === "physical_address" ||
          value === "postal_address" ||
          value === "entity_staff_count" ||
          value === "entity_phone"
        ) {
          var target = {
            id: value,
            value: data[value],
          };
          this.detailCheckValid(target);
        } else {
          partyDetailForms.forEach(v => {
            setTimeout(() => {
              if (v === "firstname") {
                var target = {
                  id: "firstname",
                  value:
                    data.entity_party_details_values[value.index].firstname ||
                    data.entity_party_details_values[value.index].first_name,
                };
                this.checkFormValid(value.index, target);
              } else if (v === "lastname") {
                var target = {
                  id: "lastname",
                  value:
                    data.entity_party_details_values[value.index].lastname ||
                    data.entity_party_details_values[value.index].last_name,
                };
                this.checkFormValid(value.index, target);
              } else if (v === "residential_address") {
                var target = {
                  id: "residential_address",
                  value:
                    data.entity_party_details_values[value.index]
                      .residential_address ||
                    data.entity_party_details_values[value.index]
                      .residential_address,
                };
                this.checkFormValid(value.index, target);
              } else if (v === "residential_type") {
                var target = {
                  id: "residential_type",
                  value:
                    data.entity_party_details_values[value.index]
                      .residential_type ||
                    data.entity_party_details_values[value.index]
                      .residential_type,
                };
                this.checkFormValid(value.index, target);
              } else if (v === "contact_phone_number") {
                var target = {
                  id: "contact_phone_number",
                  value:
                    data.entity_party_details_values[value.index]
                      .contact_phone_number ||
                    data.entity_party_details_values[value.index]
                      .contact_phone_number,
                };
                this.checkFormValid(value.index, target);
              } else {
                if (this.refs[`email-${value.index}`])
                  this.refs[`email-${value.index}`].refs.email._onBlur();
              }
            }, 300);
          });
        }
      }, 500);
    });

    setPageValidationStartFinish();
  },

  isComplete: function(data, application) {
    const { form_errors } = this.state;

    if (!data) {
      var { data } = this.props;
    }

    if (!application) {
      var { application } = this.props;
    }

    const isPhysicalAddressValid = data.physical_api_id !== null;

    const postalAddress = get(data, "postal_address.full_address", "");
    const isPostalAddressValid =
      postalAddress.length === 0 ||
      (postalAddress.length > 0 && data.postal_api_id !== null);

    const isPhoneValid = data.entity_phone.length > 0;
    let isTradingNameValid = data.entity_name.length < 1;

    // Trading details have its own section for companies and are no longer
    //available in the Business details.
    if (data.entity_type === "company") {
      isTradingNameValid = true;
    }

    /** Check Trading name, Address, Phone number for non personal*/
    if (
      data.entity_type !== "personal" &&
      !isTradingNameValid &&
      !isPhysicalAddressValid &&
      !isPostalAddressValid &&
      !isPhoneValid
    ) {
      return false;
    }

    /** Check Staff count for cash application or non personal*/
    if (
      !(
        data.entity_type === "personal" ||
        application.attributes.application_type === "cash"
      ) &&
      data.entity_staff_count.length < 1
    ) {
      return false;
    }

    var invalidCompletePartyDetails = false;
    /** Check People if partnership or trust or personal*/
    if (
      data.entity_type === "partnership" ||
      data.entity_type === "trust" ||
      data.entity_type === "personal"
    ) {
      invalidCompletePartyDetails = data.entity_party_details_values.find(
        (v, i) => {
          return (
            v.first_name.length <= 0 ||
            v.last_name.length <= 0 ||
            (data.entity_type === "personal" &&
              (!v.residential_api_id ||
                !v.residential_type ||
                !v.contact_phone_number ||
                !v.contact_country_code))
          );
        },
      );
    }

    if (invalidCompletePartyDetails) return false;

    /** Check People's email if partnership or trust or personal*/
    for (let i = 0; i < form_errors.length; i++) {
      let error = form_errors[i];
      if (error.hasOwnProperty("email") && error.email) {
        return false;
      }
    }

    return true;
  },

  componentDidMount: function() {
    const { dispatch, data } = this.props;
    dispatch(reviewComponentComplete("business", this.isComplete()));

    if (data.entity_type === "personal") {
      this.setState({
        error_messages: {
          entity_name: "You must create an account name",
          entity_name_with_emoji: "Your account name must be valid",
        },
      });
    }
  },

  handlePhysicalClick: function(value) {
    const { dispatch } = this.props;
    dispatch(selectPhysicalAddress(value));
    this.detailCheckValid({ id: "physical_api_id", value: value });
  },

  handlePostalClick: function(value) {
    const { dispatch } = this.props;
    dispatch(selectPostalAddress(value));
  },

  handlePhysicalChange: function(event) {
    const { dispatch } = this.props;
    dispatch(setPhysicalAddress({ full_address: event.target.value }));
    if (event.target.value.length > 0) {
      event.persist();
      this.lookupPhysicalAddress(event.target.value);
    }
  },

  handlePostalChange: function(event) {
    const { dispatch } = this.props;
    dispatch(setPostalAddress({ full_address: event.target.value }));
    if (event.target.value.length > 0) {
      event.persist();
      this.lookupPostalAddress(event.target.value);
    }
  },

  lookupPhysicalAddress: function(name) {
    const { dispatch, data } = this.props;
    dispatch(lookupAddress(name, "Physical", data.entity_region));
  },

  lookupPostalAddress: function(name) {
    const { dispatch, data } = this.props;
    dispatch(lookupAddress(name, "Postal", data.entity_region));
  },

  handleChange: function(index, event) {
    const { dispatch } = this.props;

    if (event.hasOwnProperty("target")) {
      this.checkFormValid(index, event.target);
    }
    dispatch(
      setEntityPartyDetailsData(index, event.target.name, event.target.value),
    );
    // if (['firstname', 'first_name', 'lastname', 'last_name'].includes(event.target.name)) {
    //   dispatch(setPeopleData(index, event.target.name, event.target.value.trim()));
    // }
  },

  _emailChangeHandler(index, value, isValid) {
    const { dispatch } = this.props;
    const { form_errors } = this.state;

    let new_errors = form_errors;

    dispatch(setEntityPartyDetailsData(index, "email", value));

    if (isValid) {
      const { email, ...rest } = form_errors[index];
      new_errors[index] = { ...rest };
    } else {
      new_errors[index] = { ...new_errors[index], email: true };
    }

    this.setState({
      form_errors: new_errors,
    });
  },

  checkFormValid: function(index, target) {
    const { form_errors, error_messages } = this.state;
    const { data } = this.props;

    switch (target.id || target.name) {
      case "firstname":
        var new_form_errors = form_errors;
        if (!target.value || target.value.length < 1) {
          new_form_errors[index] = {
            ...new_form_errors[index],
            firstname: error_messages["firstname"],
          };
        } else {
          const { firstname, ...rest } = form_errors[index];
          new_form_errors[index] = { ...rest };
        }
        this.setState({
          form_errors: new_form_errors,
        });
        break;
      case "lastname":
        var new_form_errors = form_errors;
        if (!target.value || target.value.length < 1) {
          new_form_errors[index] = {
            ...new_form_errors[index],
            lastname: error_messages["lastname"],
          };
        } else {
          const { lastname, ...rest } = form_errors[index];
          new_form_errors[index] = { ...rest };
        }
        this.setState({
          form_errors: new_form_errors,
        });
        break;
      case "residential_address":
        var new_form_errors = form_errors;
        if (
          !data.entity_party_details_values[index] ||
          !data.entity_party_details_values[index].residential_api_id ||
          data.entity_party_details_values[index].residential_api_id === null
        ) {
          new_form_errors[index] = {
            ...new_form_errors[index],
            residential_address: error_messages["residential_address"],
          };
        } else {
          const { residential_address, ...rest } = form_errors[index];
          new_form_errors[index] = { ...rest };
        }
        this.setState({
          form_errors: new_form_errors,
        });
        break;
      case "residential_api_id":
        var new_form_errors = form_errors;
        if (!target.value) {
          new_form_errors[index] = {
            ...new_form_errors[index],
            residential_address: error_messages["residential_address"],
          };
        } else {
          const { residential_address, ...rest } = form_errors[index];
          new_form_errors[index] = { ...rest };
        }
        this.setState({
          form_errors: new_form_errors,
        });
        break;
      case "residential_type":
        var new_form_errors = form_errors;
        if (!target.value || target.value.length < 1) {
          new_form_errors[index] = {
            ...new_form_errors[index],
            residential_type: error_messages["residential_type"],
          };
        } else {
          const { residential_type, ...rest } = form_errors[index];
          new_form_errors[index] = { ...rest };
        }
        this.setState({
          form_errors: new_form_errors,
        });
        break;
      case "contact_phone_number":
        var new_form_errors = form_errors;
        if (!target.value || target.value.length < 1) {
          new_form_errors[index] = {
            ...new_form_errors[index],
            contact_phone_number: error_messages["contact_phone_number"],
          };
        } else {
          const { contact_phone_number, ...rest } = form_errors[index];
          new_form_errors[index] = { ...rest };
        }
        this.setState({
          form_errors: new_form_errors,
        });
        break;
      case "contact_country_code":
        var new_form_errors = form_errors;
        if (!target.value || target.value.length < 1) {
          new_form_errors[index] = {
            ...new_form_errors[index],
            contact_country_code: error_messages["contact_country_code"],
          };
        } else {
          const { contact_country_code, ...rest } = form_errors[index];
          new_form_errors[index] = { ...rest };
        }
        this.setState({
          form_errors: new_form_errors,
        });
        break;
    }
  },

  regionStateHandleChange: function(target) {
    const { dispatch } = this.props;
    this.isComplete();
    dispatch(setEntityFormValue(target.name, target.value));
  },

  detailHandleChange: function(event) {
    const { dispatch } = this.props;
    if (event.hasOwnProperty("target")) {
      this.detailCheckValid(event.target);
    }
    this.isComplete();
    dispatch(setEntityFormValue(event.target.name, event.target.value));
  },

  detailHandleBlur: function(event) {
    if (event.hasOwnProperty("target")) {
      this.detailCheckValid(event.target);
    }
  },

  detailCheckValid: function(target) {
    const { data } = this.props;
    const { errors, error_messages } = this.state;
    switch (target.id) {
      case "company_name":
        if (!data.company_selected_name) {
          this.setState({
            errors: {
              ...errors,
              company_name: "You must enter your company name",
            },
          });
        } else {
          const { company_name, ...rest } = this.state.errors;
          this.setState({
            errors: { ...rest },
          });
        }
        break;
      case "entity_name":
        if (target.value.length < 1) {
          this.setState({
            errors: { ...errors, entity_name: error_messages["entity_name"] },
          });
        } else {
          const { entity_name, ...rest } = this.state.errors;
          this.setState({
            errors: { ...rest },
          });
        }
        break;
      case "entity_staff_count":
        if (target.value.length < 1) {
          this.setState({
            errors: {
              ...errors,
              entity_staff_count: "You must enter number of staff",
            },
          });
        } else {
          if (!isNaN(parseFloat(target.value)) && isFinite(target.value)) {
            var { entity_staff_count, ...rest_errors } = this.state.errors;
            this.setState({
              errors: { ...rest_errors },
            });
          } else {
            this.setState({
              errors: {
                ...this.state.errors,
                entity_staff_count:
                  "Your staff count must be a number (ie 10 or 2.5)",
              },
            });
          }
        }
        break;
      case "entity_phone":
        if (target.value.length < 1) {
          this.setState({
            errors: {
              ...errors,
              entity_phone: "You must enter a phone number",
            },
          });
        } else {
          const { entity_phone, ...rest } = this.state.errors;
          this.setState({
            errors: { ...rest },
          });
        }
        break;
      case "physical_address":
        if (data.physical_api_id === null) {
          this.setState({
            errors: {
              ...errors,
              physical_address: "You must select a valid physical address.",
            },
          });
        } else {
          const { physical_address, ...rest } = this.state.errors;
          this.setState({
            errors: { ...rest },
          });
        }
        break;
      case "physical_api_id":
        if (!target.value) {
          this.setState({
            errors: {
              ...errors,
              physical_address: "You must select a valid physical address.",
            },
          });
        } else {
          const { physical_address, ...rest } = this.state.errors;
          this.setState({
            errors: { ...rest },
          });
        }
        break;
      case "postal_address":
        // If a Postal Address has been entered but without API ID
        if (data.postal_api_id === null && data.postal_address.length > 0) {
          this.setState({
            errors: {
              ...errors,
              postal_address: "You must select a valid postal address.",
            },
          });
        } else {
          const { postal_address, ...rest } = this.state.errors;
          this.setState({
            errors: { ...rest },
          });
        }
        break;
    }
  },

  componentWillMount: function() {
    this.lookupPhysicalAddress = throttle(
      500,
      false,
      this.lookupPhysicalAddress,
    );
    this.lookupPostalAddress = throttle(500, false, this.lookupPostalAddress);
    this.lookupCompanyName = debounce(500, false, this.lookupCompanyName);
  },

  getInitialState: function() {
    const { data } = this.props;
    var form_errors = new Array();

    data.entity_party_details_values.forEach((v, i) => {
      form_errors.push({ firstname: "", lastname: "", email: false });
    });

    return {
      errors: {},
      form_errors: form_errors,
      error_messages: {
        firstname: "Please enter a first name",
        lastname: "Please enter a last name",
        email: "Please enter a valid email",
        residential_address: "You must select a valid physical address",
        residential_type: "Please select a residential type",
        entity_name: "You must enter your trading name",
        contact_country_code: "You need to enter a country code.",
        contact_phone_number: "You need to enter a phone number.",
      },
    };
  },

  handleResidentialChange: function(index, event) {
    const { dispatch, entity_region } = this.props;
    dispatch(
      setEntityPartyDetailsData(index, "residential_address", {
        full_address: event.target.value,
      }),
    );
    if (event.target.value.length > 0) {
      event.persist();
      dispatch(
        lookupResidentialAddress(index, event.target.value, entity_region),
      );
    }
  },

  handleResidentialClick: function(index, value) {
    const { dispatch } = this.props;
    dispatch(setEntityPartyDetailsData(index, "residential_api_id", value));
    this.checkFormValid({ id: "residential_api_id", value: value });
  },

  handleResidentialTypeChange: function(index, event) {
    const { dispatch } = this.props;
    if (event.hasOwnProperty("target")) {
      this.checkFormValid(index, event.target);
    }
    dispatch(
      setEntityPartyDetailsData(
        index,
        "residential_type",
        event.target.value.trim(),
      ),
    );
  },

  partyDetailHandleBlur: function(index, event) {
    const { dispatch } = this.props;
    if (event.target.name !== "residential_address")
      dispatch(
        setEntityPartyDetailsData(
          index,
          event.target.name,
          event.target.value.trim(),
        ),
      );
    if (event.hasOwnProperty("target")) {
      this.checkFormValid(index, event.target);
    }
  },

  countryCodeHandleChange(index, target) {
    const { dispatch } = this.props;
    if (target) {
      this.checkFormValid(index, target);
    }
    dispatch(
      setEntityPartyDetailsData(index, "contact_country_code", target.value),
    );
  },

  renderLegalEntityAgreementUploader() {
    const { application, data } = this.props;
    const { entity_type, legal_entity_agreement } = data;
    const {
      partnership_agreement_upload_visible,
      trust_deed_upload_visible,
    } = application.attributes;

    return (
      <ReviewContainer key="legal-entity-agreement">
        <LegalEntityAgreementUploader
          entityType={entity_type}
          partnershipAgreementUploadVisible={
            partnership_agreement_upload_visible
          }
          trustDeedUploadVisible={trust_deed_upload_visible}
          legalEntityAgreement={legal_entity_agreement}
          disabled={true}
          onUpload={() => null}
        />
      </ReviewContainer>
    );
  },

  render: function() {
    const { data, application } = this.props;
    const { errors, form_errors, error_messages } = this.state;

    let directors = [],
      directors_col = [],
      company_info = [],
      registration_date;
    let type = data.entity_type;

    let physical_address_loading = data.physical_address_loading;
    let physical_address_list = data.physical_address_list;
    let postal_address_loading = data.postal_address_loading;
    let postal_address_list = data.postal_address_list;

    let trading_name_label = getTradingNameTitleByRegion({
      entityType: data.entity_type,
      region: data.entity_region,
    });

    let labelString = "Director";

    if (type === "partnership") {
      labelString = "Partner";
    }

    if (type === "trust") {
      labelString = "Trustee";
    }

    if (type === "personal") {
      labelString = "Individual";
    }

    let locationDetails = "";
    if (data.entity_region === "NZ") {
      locationDetails = "New Zealand";
    } else if (data.entity_region === "AU") {
      locationDetails = "Australia, " + data.entity_state;
    }

    switch (type) {
      case "company":
        registration_date = moment(
          data.company_details.registration_date,
        ).format("DD/MM/YYYY");
        let company_number_label = "Company number",
          business_number_label = "Business number";

        if (data.entity_region === "AU") {
          (company_number_label = "ACN"), (business_number_label = "ABN");
          registration_date = data.company_details.registration_date;
        }

        company_info.push(
          <ReviewContainer
            key={`business_name`}
            css_class={`block_noborder`}
            subHeading={"Business information"}
          >
            <div className={styles.col}>
              <LabeledInformation
                label="Company/Business name"
                data={type === "company" ? data.company_details.name : "-"}
                key={`business`}
              />
            </div>
          </ReviewContainer>,
        );

        company_info.push(
          <ReviewContainer key={`business_type`} css_class={`block_noborder`}>
            <div className={styles.col}>
              <LabeledInformation
                label="Business type"
                data={data.entity_type}
              />
            </div>
            <div className={styles.col}>
              <LabeledInformation
                label="Account type"
                data={application.attributes.application_type}
              />
            </div>
          </ReviewContainer>,
        );

        company_info.push(
          <ReviewContainer key={`business_number`} css_class={`block_noborder`}>
            <div className={styles.col}>
              <LabeledInformation
                label={company_number_label}
                data={data.company_details.company_number}
              />
            </div>
            <div className={styles.col}>
              <LabeledInformation
                label={business_number_label}
                data={data.company_details.business_number || " "}
              />
            </div>
          </ReviewContainer>,
        );
        company_info.push(
          <ReviewContainer
            key={`business_location`}
            css_class={`block_noborder`}
          >
            <div className={styles.col}>
              <LabeledInformation
                label="Business location"
                data={locationDetails}
              />
            </div>
            <div className={styles.col}>
              <LabeledInformation
                label="Business start date"
                data={registration_date}
              />
            </div>
          </ReviewContainer>,
        );

        if (data.entity_region === "NZ") {
          if (data.company_details.people) {
            var director_count = 0;
            data.company_details.people.forEach((v, i) => {
              if (v.status === "ACTIVE") {
                var css_class = "block_noborder";
                directors_col.push(
                  <div key={`director-${i}`} className={styles.people_col}>
                    <LabeledInformation
                      label={`${labelString} ${i + 1}`}
                      data={v.name}
                    />
                    <LabeledInformation
                      label="Appointment date"
                      data={<DateString value={v.valid_from} />}
                    />
                    <LabeledInformation
                      label="Residential address"
                      data={v.address.street}
                    />
                  </div>,
                );
                director_count++;
              }
            });
            directors.push(
              <ReviewContainer
                key={`director`}
                css_class={"block_noborder"}
                content_class={"content_people"}
                subHeading={labelString + "s"}
              >
                {directors_col}
              </ReviewContainer>,
            );
          }
        } else {
          var director_count = 0;
          data.entity_party_details_values.forEach((v, i) => {
            var css_class = "block_noborder";
            directors_col.push(
              <div key={`director-${i}`} className={styles.col}>
                <LabeledInformation
                  label={`${labelString} ${i + 1}`}
                  data={v.first_name + " " + v.last_name}
                />
                <LabeledInformation
                  label="Email"
                  data={v.email}
                  noHumanize={true}
                />
              </div>,
            );
            director_count++;
          });
          directors.push(
            <ReviewContainer
              key={`director`}
              css_class={"block_noborder"}
              subHeading={labelString + "s"}
            >
              {directors_col}
            </ReviewContainer>,
          );
        }

        break;
      default:
        if (type === "personal") {
          company_info.push(
            <ReviewContainer
              key={`business_name`}
              css_class={`block_noborder`}
              subHeading={"Customer information"}
            >
              <div className={styles.col}>
                <LabeledInformation
                  label="Entity type"
                  data={data.entity_type}
                />
              </div>
              <div className={styles.col}>
                <LabeledInformation
                  label="Personal type"
                  data={
                    data.entity_party_details_values &&
                    data.entity_party_details_values.length > 1
                      ? "Joint"
                      : "Individual"
                  }
                />
              </div>
            </ReviewContainer>,
          );

          company_info.push(
            <ReviewContainer key={`business_type`} css_class={`block_noborder`}>
              <div className={styles.col}>
                <TextInput
                  id="entity_name"
                  error={errors["entity_name"]}
                  handleChange={this.detailHandleChange}
                  handleBlur={this.detailHandleBlur}
                  label={trading_name_label}
                  required={true}
                  value={data.entity_name}
                />
              </div>
              <div className={styles.col}>
                <LabeledInformation
                  label="Account type"
                  data={application.attributes.application_type}
                />
              </div>
            </ReviewContainer>,
          );
        } else {
          // TODO: Refactor this. This is not scalable!
          if (data.entity_region === "AU") {
            company_info.push(
              <ReviewContainer
                key="entity_name"
                css_class="block_noborder"
                subHeading="Business information"
              >
                <div className={styles.full_input}>
                  <LabeledInformation
                    label="Entity name"
                    data={data.company_details.name}
                    key="business"
                  />
                </div>
              </ReviewContainer>,
            );
            company_info.push(
              <ReviewContainer
                key={`business_name`}
                css_class={`block_noborder`}
              >
                <div className={styles.col}>
                  <TextInput
                    id="entity_name"
                    error={errors["entity_name"]}
                    handleChange={this.detailHandleChange}
                    handleBlur={this.detailHandleBlur}
                    label={trading_name_label}
                    required={true}
                    value={data.entity_name}
                  />
                </div>
                <div className={styles.col}>
                  <LabeledInformation
                    label="Business type"
                    data={data.entity_type}
                  />
                </div>
              </ReviewContainer>,
            );
          } else {
            company_info.push(
              <ReviewContainer
                key={`business_name`}
                css_class={`block_noborder`}
                subHeading={"Business information"}
              >
                <div className={styles.col}>
                  <TextInput
                    id="entity_name"
                    error={errors["entity_name"]}
                    handleChange={this.detailHandleChange}
                    handleBlur={this.detailHandleBlur}
                    label={trading_name_label}
                    required={true}
                    value={data.entity_name}
                  />
                </div>
                <div className={styles.col}>
                  <LabeledInformation
                    label="Business type"
                    data={data.entity_type}
                  />
                </div>
              </ReviewContainer>,
            );
          }

          let registration_date_label = "Business start date";
          let date = data.company_details.registered_at;
          registration_date = moment(
            date.month + "/" + date.day + "/" + date.year,
            "MM/DD/YYYY",
          ).format("DD/MM/YYYY");
          if (
            [
              "education",
              "society",
              "association",
              "club",
              "government",
              "other",
            ].includes(data.entity_type)
          ) {
            let current_year = new Date().getFullYear();
            registration_date = "" + current_year - date.year + "";
            registration_date_label = "Years in operation";
          }

          company_info.push(
            <ReviewContainer key={`business_type`} css_class={`block_noborder`}>
              <div className={styles.col}>
                <LabeledInformation
                  label="Account type"
                  data={application.attributes.application_type}
                />
              </div>
              <div className={styles.col}>
                <LabeledInformation
                  label={registration_date_label}
                  data={registration_date}
                  showEmpty={true}
                />
              </div>
            </ReviewContainer>,
          );
        }
        if (data.entity_region === "AU" && type !== "personal") {
          company_info.push(
            <ReviewContainer
              key={`business_number`}
              css_class={`block_noborder`}
            >
              <div className={styles.col}>
                <LabeledInformation
                  label="ACN"
                  data={data.company_details.company_number || "-"}
                />
              </div>
              <div className={styles.col}>
                <LabeledInformation
                  label="ABN"
                  data={data.company_details.business_number || "-"}
                />
              </div>
            </ReviewContainer>,
          );
        }

        if (data.entity_party_details_values) {
          if (data.entity_party_details_values.length > 1)
            labelString = labelString + "s";
          data.entity_party_details_values.forEach((v, i) => {
            var css_class = "block_noborder";
            directors.push(
              <ReviewPartyDetailsForm
                key={i}
                ref={`email-` + i}
                index={i}
                type={type}
                data={v}
                form_error={form_errors[i]}
                css_class={css_class}
                labelString={labelString}
                onChangeHandler={this.handleChange}
                onChangeEmailHandler={this._emailChangeHandler}
                onBlurHandler={this.partyDetailHandleBlur}
                handleResidentialChange={this.handleResidentialChange}
                handleResidentialClick={this.handleResidentialClick}
                handleResidentialTypeChange={this.handleResidentialTypeChange}
                onCountryCodeChangeHandler={this.countryCodeHandleChange}
              />,
            );
          });
        }
        break;
    }

    let postal_address_label = "Postal/delivery address if different";
    if (type === "company") {
      postal_address_label = "Postal address if different";
    }

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        {company_info}
        <ReviewContainer
          css_class={`block_noborder`}
          hideCheck={type.includes("personal")}
        >
          <div className={styles.col}>
            <AutoSuggest
              id="physical_address"
              label="Business address"
              value={data.physical_address.full_address}
              loading={physical_address_loading}
              suggest_items={physical_address_list}
              error={errors["physical_address"]}
              handleChange={this.handlePhysicalChange}
              handleClick={this.handlePhysicalClick}
              handleBlur={this.detailHandleBlur}
              css_class="address_lookup"
              required={true}
              selected_item={
                data.physical_api_id ? data.physical_address.full_address : ""
              }
              force_focus={true}
            />
          </div>
          <div className={styles.col}>
            <AutoSuggest
              id="postal_address"
              label={postal_address_label}
              value={data.postal_address.full_address}
              loading={postal_address_loading}
              suggest_items={postal_address_list}
              error={errors["postal_address"]}
              handleChange={this.handlePostalChange}
              handleClick={this.handlePostalClick}
              handleBlur={this.detailHandleBlur}
              css_class="address_lookup"
              required={false}
            />
          </div>
        </ReviewContainer>
        <ReviewContainer
          css_class={`block_noborder`}
          hideCheck={type.includes("personal")}
        >
          <div className={styles.col}>
            <TextInput
              id="entity_phone"
              error={errors["entity_phone"]}
              handleChange={this.detailHandleChange}
              handleBlur={this.detailHandleBlur}
              label="Business phone number"
              required={true}
              value={data.entity_phone}
            />
          </div>
          {type !== "company" && (
            <div className={styles.col}>
              <TextInput
                id="delivery_instruction"
                error={errors["delivery_instruction"]}
                handleChange={this.detailHandleChange}
                handleBlur={this.detailHandleBlur}
                label="Add delivery instructions"
                required={false}
                value={data.delivery_instruction}
              />
            </div>
          )}
          {type === "company" && (
            <div className={styles.col}>
              <TextInput
                id="entity_staff_count"
                type="tel"
                error={errors["entity_staff_count"]}
                handleChange={this.detailHandleChange}
                handleBlur={this.detailHandleBlur}
                label="Number of staff"
                required={true}
                value={data.entity_staff_count}
              />
            </div>
          )}
        </ReviewContainer>
        <ReviewContainer
          css_class={`block_noborder`}
          hideCheck={
            [
              "company",
              "personal",
              "education",
              "society",
              "association",
              "club",
              "government",
              "other",
            ].includes(type) ||
            application.attributes.application_type.includes("cash")
          }
        >
          <div className={styles.col}>
            <TextInput
              id="entity_staff_count"
              type="tel"
              error={errors["entity_staff_count"]}
              handleChange={this.detailHandleChange}
              handleBlur={this.detailHandleBlur}
              label="Number of staff"
              required={true}
              value={data.entity_staff_count}
            />
          </div>
          <div className={styles.col}></div>
        </ReviewContainer>
        <ReviewContainer
          css_class={`block_noborder`}
          hideCheck={
            ![
              "education",
              "society",
              "association",
              "club",
              "government",
              "other",
            ].includes(type)
          }
        >
          <div className={styles.col}>
            <TextInput
              id="incorporated_number"
              error={errors["incorporated_number"]}
              handleChange={this.detailHandleChange}
              handleBlur={this.detailHandleBlur}
              label="Incorporation/Registration number"
              required={false}
              value={data.incorporated_number}
            />
          </div>
          <div className={styles.col}>
            {!application.attributes.application_type.includes("cash") && (
              <TextInput
                id="entity_staff_count"
                type="tel"
                error={errors["entity_staff_count"]}
                handleChange={this.detailHandleChange}
                handleBlur={this.detailHandleBlur}
                label="Number of staff"
                required={true}
                value={data.entity_staff_count}
              />
            )}
          </div>
        </ReviewContainer>
        {this.renderLegalEntityAgreementUploader()}
        {directors.length > 1 && directors}
      </section>
    );
  },
});

module.exports = ReviewBusiness;
