import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'
import SquareCheckbox from "modules/shared/components/inputs/SquareCheckbox";
import { Error, Title, Wrapper } from './styles.ts';

import styles from "modules/addons/components/css/AddOnsDetails.css";

const typeOptions = {
  cash: { label: 'Cash application' },
  credit: { label: 'Credit application' },
};

type Props = {
  description?: string,
  error: string|null,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  label: string,
  readOnly?: boolean,
  selected: string[],
}

function AccountTypeCheckbox({
  description,
  error,
  handleChange,
  label,
  readOnly,
  selected,
}: Props): ReactElement {
  return (
    <Wrapper className={styles.section}>
      <Title>{label}</Title>
      <p>{description}</p>
      { error && (
        <Error className="notification is-danger is-light">{error}</Error>
      ) }
      <div className={styles.row}>
        { Object.keys(typeOptions).map(key => (
          <div className={styles.col} key={`checkbox-${key}`}>
            <SquareCheckbox
              key={key}
              checkboxId={key}
              checkboxName={key}
              checked={selected.includes(key)}
              handleChange={handleChange}
              label={typeOptions[key].label}
              disabled={readOnly}
            />
          </div>
        )) }
      </div>
    </Wrapper>
  )
}

AccountTypeCheckbox.propTypes = {
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  selected: PropTypes.array,
}

AccountTypeCheckbox.defaultProps = {
  selected: [],
}

export default AccountTypeCheckbox;

