import api from "api";
import get from "lodash.get";
import BaseModel from "models/BaseModel";
import TradeReferenceCheckModel from "models/TradeReferenceCheckModel";
import isPresent from "utils/isPresent";

export default class TradeReferenceModel extends BaseModel {
  get formattedName() {
    return [this.firstName, this.lastName]
      .filter(name => isPresent(name))
      .join(" ");
  }

  get formattedPhoneNumber() {
    return [this.contactCountryCode, this.contactPhoneNumber]
      .filter(number => isPresent(number))
      .join(" ");
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  updateTradeReferenceStatus({ attributes, currentUser, onSuccessCallback }) {
    const { notes, outcome } = attributes;
    this.setAttributes({ notes });

    const tradeReferenceAPI = api(
      "trade_references",
      currentUser.accessToken,
      get(currentUser, "currentEntity.id"),
    );

    const tradeReferenceUpdateSuccessCallback = () => {
      this.createTradeReferenceCheck({
        attributes: { outcome },
        currentUser,
        onSuccessCallback,
      });
    };

    tradeReferenceAPI.updateTradeRef(
      this.id,
      { notes },
      tradeReferenceUpdateSuccessCallback,
      error => console.error(error),
    );
  }

  createTradeReferenceCheck({ attributes, currentUser, onSuccessCallback }) {
    const { outcome } = attributes;
    const tradeReferenceCheckAPI = api(
      "trade_reference_checks",
      currentUser.accessToken,
      get(currentUser, "currentEntity.id"),
    );

    tradeReferenceCheckAPI.createCheck(
      this.id,
      { outcome },
      onSuccessCallback,
      error => console.error(error),
    );
  }

  /** Private functions */

  assignRelationships() {
    this.assignSingleRelationship({
      included: this.included,
      key: "trade_reference_check",
      model: TradeReferenceCheckModel,
    });
  }
}
