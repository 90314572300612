import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import { loadCurrentEntity } from "modules/user/actions";
import { defaultTimeZone } from "utils/dateFormatter";

import api from "../../api";
import {
  DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_RESET,
  DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_START,
  DASHBOARD_GET_MONTHLY_REPORT_ERROR,
  DASHBOARD_GET_MONTHLY_REPORT_START,
  DASHBOARD_GET_MONTHLY_REPORT_SUCCESS,
  DASHBOARD_GET_REPORT_ERROR,
  DASHBOARD_GET_REPORT_START,
  DASHBOARD_GET_REPORT_SUCCESS,
  DASHBOARD_LOAD_CURRENT_ENTITY_ERROR,
  DASHBOARD_LOAD_CURRENT_ENTITY_START,
  DASHBOARD_LOAD_CURRENT_ENTITY_SUCCESS,
  DASHBOARD_SET_CONSUMER_AS_SUPPLIER_ERROR,
  DASHBOARD_SET_CONSUMER_AS_SUPPLIER_START,
  DASHBOARD_SET_CONSUMER_AS_SUPPLIER_SUCCESS,
  DASHBOARD_SET_PERIOD,
  DASHBOARD_SET_TIER,
} from "./constants";

function getEntityId(state) {
  return get(state, 'current_user.current_entity.id');
}

export function failedApplicationStart() {
  return dispatch => {
    dispatch({
      type: DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_START,
    });
    setTimeout(() => {
      dispatch(failedApplicationReset());
    }, 1000);
  };
}

export function failedApplicationReset() {
  return {
    type: DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_RESET,
  };
}

export function getReport(user_id, entity_type) {
  return (dispatch, getState) => {
    dispatch({
      type: DASHBOARD_GET_REPORT_START,
    });

    const reports = api(
      "application_summary_report",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    reports.getReport(
      user_id,
      entity_type,
      success => {
        dispatch({
          payload: success.data.data.attributes,
          type: DASHBOARD_GET_REPORT_SUCCESS,
        });
      },
      error => {
        dispatch({
          payload: error,
          type: DASHBOARD_GET_REPORT_ERROR,
        });
      },
    );
  };
}

export function getMonthlyReport(user_id, entity_type, date) {
  return (dispatch, getState) => {
    dispatch({
      type: DASHBOARD_GET_MONTHLY_REPORT_START,
    });
    date.timezone = defaultTimeZone();
    const reports = api(
      "application_monthly_report",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );
    reports.getMonthlyReport(
      user_id,
      entity_type,
      date,
      success => {
        dispatch({
          payload: success.data.data.attributes,
          type: DASHBOARD_GET_MONTHLY_REPORT_SUCCESS,
        });
      },
      error => {
        dispatch({
          payload: error,
          type: DASHBOARD_GET_MONTHLY_REPORT_ERROR,
        });
      },
    );
  };
}

export function entityBecomeSupplier(entity) {
  return (dispatch, getState) => {
    dispatch({
      type: DASHBOARD_SET_CONSUMER_AS_SUPPLIER_START,
    });

    const entities = api(
      "entities",
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
    );

    entities.becomeSupplier(
      entity.id,
      success => {
        const userId = getState().current_user.data.data.id;

        mixpanel.identify(userId);
        mixpanel.register({
          "User ID": userId,
          email: getState().current_user.data.data.attributes.email,
        });
        mixpanel.people.set({
          "Consumer to Supplier": true,
          "Entity ID": success.data.data.id,
          Supplier: success.data.data.attributes.supplier,
        });

        dispatch({
          meta: {
            mixpanel: {
              event: "Consumer to Supplier",
              props: {
                Entity: success.data.data.attributes.trading_name,
                "Entity ID": success.data.data.id,
                distinct_id: userId,
              },
            },
          },
          payload: success.data.data,
          type: DASHBOARD_SET_CONSUMER_AS_SUPPLIER_SUCCESS,
        });
        dispatch(loadCurrentEntity());
      },
      error => {
        dispatch({
          payload: error,
          type: DASHBOARD_SET_CONSUMER_AS_SUPPLIER_ERROR,
        });
      },
    );
  };
}

export function getEntityCard(entity_id, callback) {
  return (dispatch, getState) => {
    const entities = api(
      "entities",
      getState().current_user.access_token,
      entity_id,
    );
    entities.getEntityCards(
      entity_id,
      result => {
        if (callback) {
          callback(result.data.data);
        }
      },
      error => {},
    );
  };
}

export function setReportingPeriod(period, persistInLocalStorage = true) {
  return (dispatch, getState) => {
    dispatch({
      type: DASHBOARD_SET_PERIOD,
      payload: {
        period,
        currentUserEntityId: getEntityId(getState()),
        persistInLocalStorage,
      }
    })
  }
}

export function setReportingTier(entityId, persistInLocalStorage = true) {
  return (dispatch, getState) => {
    dispatch({
      type: DASHBOARD_SET_TIER,
      payload: {
        tier: entityId,
        currentUserEntityId: getEntityId(getState()),
        persistInLocalStorage,
      }
    })
  }
}
