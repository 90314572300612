/* Import libraries */
import React from "react";
import { connect } from "react-redux";
import { formatMoney } from "utils/formatting";

/** import comonents **/
import SimpleLoader from "modules/shared/components/widgets/static/SimpleLoader";
import InfoBox from "modules/shared/components/widgets/static/InfoBox";
import Clickable from "modules/shared/components/widgets/interactive/Clickable";
import Icon from "modules/shared/components/svg/Icon";

import styles from "./css/MonthlyOverview.css";

var MonthlyOverview = createClass({
  render: function() {
    const {
      data,
      date,
      handleNext,
      handlePrev,
      nextEnabled,
      prevEnabled,
      loading,
    } = this.props;

    var values = {};
    if (data) {
      values = data;
    }

    var loader = null;
    if (loading) {
      loader = <SimpleLoader />;
    }

    var content = [];
    Object.keys(options.forms).forEach(v => {
      var value = 0;
      if (values.hasOwnProperty(v)) {
        value =
          v == "total_limit" ? formatMoney(parseFloat(values[v])) : values[v];
      }
      content.push(
        <div className={styles.panel} key={`infobox-${v}`}>
          <InfoBox
            title={options.forms[v].title}
            value={value}
            prefix={options.forms[v].prefix}
            color={options.forms[v].color}
            key={options.forms[v].key}
          />
        </div>,
      );
    });

    var next = (
      <span className={styles.next}>
        <Clickable target={handleNext}>
          <Icon icon={"right"} size={"14x24"} />
        </Clickable>
      </span>
    );
    if (
      new Date().getMonth() === date.month &&
      new Date().getFullYear() === date.year
    ) {
      next = (
        <span className={styles.next_disable}>
          <Icon icon={"right"} color={"light-grey"} size={"14x24"} />
        </span>
      );
    }

    var controls = [];
    controls.push(
      <div className={styles.date_container} key={"date"}>
        <span className={styles.prev}>
          <Clickable target={handlePrev}>
            <Icon icon={"left"} size={"14x24"} />
          </Clickable>
        </span>
        <span className={styles.date}>
          {months[date.month] + " " + date.year}
        </span>
        {next}
      </div>,
    );

    return (
      <div>
        <div className={styles.content}>{content}</div>
        {controls}
      </div>
    );
  },
});

var options = {
  forms: {
    total_started: {
      key: "total_started",
      title: "Total Started",
      color: "grey",
    },
    incomplete: {
      key: "incomplete",
      title: "Incomplete",
      color: "grey_medium",
    },
    pending: {
      key: "pending",
      title: "Pending",
      color: "blue_light",
    },
    approved: {
      key: "approved",
      title: "Approved",
      color: "blue",
    },
    declined: {
      key: "declined",
      title: "Declined",
      color: "red",
    },
    total_limit: {
      key: "total_limit",
      title: "Approved Limits",
      color: "white",
      prefix: "$",
    },
  },
};

var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

module.exports = MonthlyOverview;
