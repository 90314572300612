/* Import libraries */
import { get } from "lodash";
import CardRemoveModal from "modules/card-management-onboarding/components/Details/components/CardRemoveModal";
import {
  deleteCardholder,
} from "modules/consumer-onboarding/actions/cards";
import withCardholderForm, {
  getCardholderApplicant,
  getCardholderDetailsErrorMessages,
  getPeopleAutosuggestList,
  isComplete,
} from "modules/consumer-onboarding/components/cards/CardholderFormWrapper";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getCardsAddonConfig,
  getCardsAddonRule,
} from "utils/cardsAddon";
import isPresent from "utils/isPresent";

import CardholderDetailsForm from "../CardholderDetailsForm";
import styles from "../css/Cards";
import isSignatureRequired from "../helpers/isSignatureRequired";
import { CardholderTitle, DeleteBtn, Wrapper } from "./styles";

function getFormErrorState(nextProps, prevState) {
  const {
    cardholder,
    cardholders,
    config,
    handleComplete,
    isPreview,
    setPageValidationStartFinish,
    validationFlags,
  } = nextProps;

  if (isPreview) {
    return;
  }

  const errorMessages = getCardholderDetailsErrorMessages(
    cardholder,
    cardholders,
    config,
  );
  const formErrors = { ...prevState.formErrors, ...errorMessages };

  handleComplete(isComplete(formErrors, validationFlags), "cards");
  setPageValidationStartFinish();

  return { formErrors: prevState.formErrors };
}

function CardsDetails(props) {
  const {
    cardholder,
    cardholderApplicant,
    cardholders,
    config,
    displayDeleteBtn,
    dispatch,
    guarantorApplicant,
    handleComplete,
    index,
    onHandleBlur,
    onHandleChangeAddonAnswer,
    onHandleChangeEmail,
    onHandleChangeValue,
    onHandleSelectSuggestion,
    page_validation_start: pageValidationStart,
    personAutoSuggestList,
    signatoryApplicant,
    setPageValidationStartFinish,
    title,
    triggerValidation,
    validationFlags,
  } = props;

  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const handleFormErrors = () => {
    if (pageValidationStart) {
      const newFormErrors = getCardholderDetailsErrorMessages(
        cardholder,
        cardholders,
        config,
      );

      handleComplete(isComplete(newFormErrors, validationFlags), "cards");
      return setFormErrors(newFormErrors);
    }

    setPageValidationStartFinish();
  }

  useEffect(() => {
    if (pageValidationStart) {
      handleFormErrors();
    }
  }, [pageValidationStart])

  const handleBlur = () => {
    handleFormErrors();
    onHandleBlur();
  }

  const handleEmailChange = (value, isValid) => {
    onHandleChangeEmail(value, isValid);
    handleFormErrors();
  }

  const handleChange = (name, value) => {
    onHandleChangeValue(name, value);
    handleFormErrors();
  }

  const closeModal = () => {
    setActiveCardIndex(null);
  }

  const removeCardholder = index => {
    dispatch(deleteCardholder(index));
  }

  const additionalFields = config.additional_fields || [];

  return (
    <Wrapper>
      <div className={styles.full_col}>
        <CardholderTitle>
          { title || "Card" }
          {
            displayDeleteBtn && (
              <DeleteBtn
                type="button"
                className="delete is-medium"
                onClick={() => setActiveCardIndex(index)}
              />
            )
          }
        </CardholderTitle>
      </div>
      {/* {`Cardholder ${index}`} */}
      <CardholderDetailsForm
        cardholder={cardholder}
        cardholderApplicant={cardholderApplicant}
        cardholders={cardholders}
        components={additionalFields}
        config={config}
        formErrors={formErrors}
        guarantorApplicant={guarantorApplicant}
        index={index}
        pageValidationStart={pageValidationStart}
        personAutoSuggestList={personAutoSuggestList}
        signatoryApplicant={signatoryApplicant}
        triggerValidation={triggerValidation}
        onHandleBlur={handleBlur}
        onHandleChangeAddonAnswer={onHandleChangeAddonAnswer}
        onHandleChangeEmail={handleEmailChange}
        onHandleChangeValue={handleChange}
        onHandleSelectSuggestion={onHandleSelectSuggestion}
      />
      {
        activeCardIndex !== null && (
          <CardRemoveModal
            activeCardIndex={activeCardIndex}
            closeModal={closeModal}
            deleteCardholder={removeCardholder}
            setActiveCardIndex={setActiveCardIndex}
            zIndex={100000}
          />
        )
      }
    </Wrapper>
  );
}

export default connect((state, ownProps) => {
  const previewAddon = ownProps.previewAddon;
  const cobSection = state.cob_section;
  const cobCards = state.cob_cards;
  const { application } = cobSection;
  const guarantors = state.cob_guarantors.form_values || [];
  const signatories = state.cob_business.entity_party_details_values || [];
  const index = ownProps.component_index || 0;
  const validationFlags = state.shared_data[`cardholder_${index}`] || [];

  let addonRule;
  // let cardholder = { attributes: CARDHOLDER_DEFAULT_FIELDS };
  let cardholderApplicant;
  let config;
  let guarantorApplicant;
  let signatoryApplicant;
  let isPreview = false;
  isPreview = isPresent(previewAddon);

  if (isPreview) {
    config = previewAddon.config;
  } else {
    addonRule = getCardsAddonRule(cobSection.addonRules);
    config = getCardsAddonConfig(addonRule);
    cardholderApplicant = getCardholderApplicant(state.cob_cards, index);
    guarantorApplicant = guarantors.find(guarantor => guarantor.is_applicant);
    signatoryApplicant = signatories.find(signatory => signatory.is_applicant);
    if (signatories.length === 1) {
      signatoryApplicant = signatories[0];
      signatoryApplicant.is_applicant = true;
    }
  }

  const configSignatureRequired = get(config, "signature_required") === "on";
  const signatureRequired = isSignatureRequired(state, configSignatureRequired);

  const personAutoSuggestList = getPeopleAutosuggestList(
    cobSection.people || [],
    guarantors,
  );

  const cardholders = cobCards.cardholders;

  return {
    addonRule,
    application,
    cardholder: cardholders[ownProps.index] || { attributes: {} },
    cardholderApplicant,
    cardholders: cobCards.cardholders,
    config: {
      ...config,
      cardLimitEnabled: config.card_limit_enabled === "on",
      signatureRequired,
    },
    guarantorApplicant,
    isPreview,
    personAutoSuggestList,
    signatoryApplicant,
    validationFlags,
  };
})(withCardholderForm(CardsDetails, getFormErrorState));
