import React, { Component, Fragment } from "react";
import {
  lookupAddress,
  selectAddress,
  setAddress,
  setIdentityValue,
} from "modules/identity/actions";
import AutoSuggest from "modules/shared/components/widgets/interactive/AutoSuggest";
import OptionsDropdown from "modules/shared/components/widgets/interactive/OptionsDropdown";
import TextInput from "modules/shared/components/inputs/TextInput";
import { connect } from "react-redux";
import get from "lodash.get";
import isBlank from "utils/isBlank";
import moment from "moment";
import styles from "modules/identity/components/css/IdentityConfirm.css";
import SectionHeader from "modules/shared/components/v2/SectionHeader";
import BorderedCalendarPicker from "modules/shared/components/inputs/BorderedCalendarPicker";
import {
  getEighteenYearsAgo,
  get100YearsAgo,
} from "modules/card-management-shared/utils";
import StateDropdown from "./StateDropdown";

const MAX_FILE_SIZE = 5000000;

function isDateOfBirthValid(dob) {
  const dobMomentObject = moment(dob, "DD/MM/YYYY");

  if (dobMomentObject.format("YYYY-MM-DD") === "Invalid date") {
    return false;
  }

  if (dobMomentObject.toDate().getTime() < get100YearsAgo().getTime()) {
    return false;
  }

  if (dobMomentObject.toDate().getTime() > getEighteenYearsAgo().getTime()) {
    return false;
  }

  return true;
}

function isExpiryDateValid(expiryDate) {
  const dobMomentObject = moment(expiryDate, "DD/MM/YYYY");

  if (dobMomentObject.format("YYYY-MM-DD") === "Invalid date") {
    return false;
  }

  if (dobMomentObject.toDate().getTime() <= new Date().getTime) {
    return false;
  }

  return true;
}

function AddressCountryDropdown(props) {
  const { addressRegion, onChange, inputFrozen, isAuthorisationPage } = props;

  if (!isAuthorisationPage) {
    return null;
  }

  const regionOptions = [
    { value: "AU", label: "Australia" },
    { value: "NZ", label: "New Zealand" },
  ];

  return (
    <div className={styles.col_4}>
      <OptionsDropdown
        id="addressRegion"
        name="addressRegion"
        handleChange={onChange}
        label="Region"
        value={addressRegion}
        required={true}
        options={regionOptions}
        disabled={inputFrozen}
      />
    </div>
  );
}

function AddressPicker(props) {
  const {
    addressRegion,
    form_errors,
    form_values,
    handleAddressChange,
    handleAddressClick,
    handleBlur,
    onChangeAddressRegion,
    inputFrozen,
    isAuthorisationPage,
    personal_address_list,
    personal_address_loading,
  } = props;

  let selectedItem = "";
  if (form_values.address_api_id) {
    selectedItem = form_values.personal_address;
  }

  const autoSuggestClassName = isAuthorisationPage ? "col_8" : "col_12";

  return (
    <div className={styles.form_row}>
      <AddressCountryDropdown
        addressRegion={addressRegion}
        onChange={onChangeAddressRegion}
        inputFrozen={inputFrozen}
        isAuthorisationPage={isAuthorisationPage}
      />
      <div className={styles[autoSuggestClassName]}>
        <AutoSuggest
          id="personal_address"
          label="Personal address"
          value={form_values.personal_address}
          loading={personal_address_loading}
          suggest_items={personal_address_list}
          handleChange={handleAddressChange}
          handleClick={handleAddressClick}
          handleBlur={handleBlur}
          error={form_errors.personal_address}
          required={true}
          css_class="address_lookup"
          selected_item={selectedItem}
          disabled={inputFrozen}
        />
      </div>
    </div>
  );
}

class IdentityConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressRegion: props.addressRegion,
      form_errors: {},
    };
  }

  componentDidMount() {
    const {
      applicant_guarantor,
      applicant_signature,
      applicantCardholder,
      applicantSignatory,
      dispatch,
      full_version,
      guarantor,
      handleComplete,
      isGuarantor,
      need_dob_address,
      form_values,
    } = this.props;

    if (full_version) {
      dispatch(setIdentityValue("noIdentification", false));
      dispatch(setIdentityValue("noIdentificationReason", null));
    } else {
      dispatch(setIdentityValue("noIdentification", true));
      dispatch(setIdentityValue("noIdentificationReason", "not_required"));
    }

    if (isGuarantor) {
      dispatch(setIdentityValue("name", guarantor.attributes.name));
      dispatch(setIdentityValue("first_name", guarantor.attributes.first_name));
      dispatch(setIdentityValue("last_name", guarantor.attributes.last_name));
      dispatch(
        setIdentityValue("middle_name", guarantor.attributes.middle_name),
      );
    } else if (applicant_guarantor) {
      dispatch(
        setIdentityValue(
          "name",
          `${applicant_guarantor.first_name} ${applicant_guarantor.last_name}`,
        ),
      );
      dispatch(setIdentityValue("first_name", applicant_guarantor.first_name));
      dispatch(setIdentityValue("last_name", applicant_guarantor.last_name));
      dispatch(
        setIdentityValue("middle_name", applicant_guarantor.middle_name),
      );
      if (applicant_guarantor.address && need_dob_address) {
        dispatch(setAddress(applicant_guarantor.address));
      }
    } else if (applicantCardholder) {
      const attributes = applicantCardholder.attributes;

      dispatch(
        setIdentityValue(
          "name",
          `${attributes.firstName} ${attributes.lastName}`,
        ),
      );
      dispatch(setIdentityValue("first_name", attributes.firstName));
      dispatch(setIdentityValue("last_name", attributes.lastName));
      dispatch(setIdentityValue("middle_name", attributes.middleName));
    } else if (applicantSignatory) {
      dispatch(
        setIdentityValue(
          "name",
          `${applicantSignatory.first_name} ${applicantSignatory.last_name}`,
        ),
      );
      dispatch(setIdentityValue("first_name", applicantSignatory.first_name));
      dispatch(setIdentityValue("last_name", applicantSignatory.last_name));
      dispatch(setIdentityValue("middle_name", applicantSignatory.middle_name));

      if (applicantSignatory.residential_address) {
        dispatch(setAddress(applicantSignatory.residential_address));
      }
    } else {
      if (applicant_signature && applicant_signature.attributes) {
        dispatch(
          setIdentityValue(
            "first_name",
            applicant_signature.attributes.first_name,
          ),
        );
        dispatch(
          setIdentityValue(
            "last_name",
            applicant_signature.attributes.last_name,
          ),
        );
        dispatch(
          setIdentityValue(
            "middle_name",
            applicant_signature.attributes.middle_name,
          ),
        );
      }
    }

    handleComplete(this.isComplete(form_values));
  }

  componentDidUpdate() {
    const {
      handleComplete,
      page_validation_start,
      setPageValidationStartFinish,
      form_values,
    } = this.props;

    const isComplete = this.isComplete(form_values);
    handleComplete(isComplete);

    if (page_validation_start) {
      this.checkValidAll();
      setPageValidationStartFinish();
    }
  }

  checkValidAll() {
    const { full_version, need_dob_address } = this.props;

    let fields = ["name", "first_name", "last_name"];
    if (need_dob_address) {
      fields = fields.concat(["personal_address", "dob",]);
    }

    if (full_version) {
      fields = fields.concat([
        "type",
        "region",
        "number",
        "driver_licence_version",
        "driver_licence_state",
        "identification_exp_date",
      ]);
    }

    this.checkFieldsValid(fields);
  }

  checkValidLicence() {
    const fields = [
      "driver_licence_version",
      "driver_licence_state",
      "number",
      "identification_exp_date",
    ];

    this.checkFieldsValid(fields);
  }

  checkFieldsValid(fields) {
    const { form_values } = this.props;
    fields.forEach(value => {
      setTimeout(() => {
        this.checkValid({
          id: value,
          value: form_values[value],
        });
      }, 50);
    });
  }

  handleChange(event) {
    const { dispatch, form_values } = this.props;
    const { region, type } = form_values;
    const { form_errors } = this.state;
    if (event.hasOwnProperty("target")) {
      this.checkValid(event.target);
      dispatch(setIdentityValue(event.target.id, event.target.value));
    } else {
      this.checkValid(event);
      dispatch(setIdentityValue(event.id, event.value));
    }
    if (event.id === "region") {
      if (event.value === "Other") {
        dispatch(setIdentityValue(event.id, ""));
        dispatch(setIdentityValue("other_region", true));
      } else {
        dispatch(setIdentityValue("other_region", false));
      }
    }
    if (["type", "region"].includes(event.id) &&
      (type || region) &&
      Object.keys(form_errors).length > 0
    ) {
      this.checkValidLicence();
    }
  }

  handleBlur(event) {
    if (event.target) {
      this.checkValid(event.target);
    } else {
      this.checkValid(event);
    }
  }

  checkValid(target) {
    const {
      applicantSignatory,
      driver_licence_number_regex,
      driver_licence_version_regex,
      form_values,
      isGuarantor,
      legal_type,
      name_dropdown_values,
      passport_number_regex,
    } = this.props;
    const { form_errors } = this.state;
    const value = target.value;
    const { type, region } = form_values;
    const isAuDriverLicence = type === "driver_licence" && region === "AU";

    switch (target.id) {
      case "name":
        const { name, ...rest } = form_errors;
        this.setState({
          form_errors: { ...rest },
        });
        break;
      case "first_name":
        if (!value || value.length === 0) {
          this.setState({
            form_errors: {
              ...form_errors,
              first_name: "Please enter your first name.",
            },
          });
          break;
        } else if (
          (legal_type === "sole_trader" || isGuarantor) &&
          ["sir", "lord", "princess", "lady"].includes(
            value.toLowerCase(),
          )
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]:
                "Titles (such as Lady or Sir) are not valid first names",
            },
          });
          break;
        } else if (
          applicantSignatory &&
          applicantSignatory.first_name.toLowerCase() !== value.toLowerCase()
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              first_name: "First name doesn't match the selected signatory.",
            },
          });
          break;
        } else {
          const { first_name, ...rest } = form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case "last_name":
        if (!value || value.length === 0) {
          this.setState({
            form_errors: {
              ...form_errors,
              last_name: "Please enter your last name.",
            },
          });
        } else {
          const { last_name, ...rest } = form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case "personal_address":
        if (!form_values.address_api_id) {
          this.setState({
            form_errors: {
              ...form_errors,
              personal_address: "Please select a valid address.",
            },
          });
        } else {
          const { personal_address, ...rest } = form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case "address_api_id":
        if (!target.value) {
          this.setState({
            form_errors: {
              ...form_errors,
              personal_address: "Please select a valid address.",
            },
          });
        } else {
          const { personal_address, ...rest } = form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case "dob":
        if (isDateOfBirthValid(target.value)) {
          const { dob, ...rest } = form_errors;
          this.setState({ form_errors: { ...rest } });
        } else {
          this.setState({
            form_errors: {
              ...form_errors,
              dob:
                "Please enter valid date of birth. You must be between 18 to 80 years old.",
            },
          });
        }
        break;
      case "number":
        if (!target.value || target.value.length < 1) {
          var error;
          if (form_values.type === "driver_licence") {
            error = "Please enter your driver licence No.";
          } else if (form_values.type === "passport") {
            error = "Please enter your passport No.";
          } else {
            error = "Please enter your identification number";
          }
          this.setState({
            form_errors: { ...form_errors, number: error },
          });
        } else {
          const { number, ...rest } = form_errors;
          if (form_values.type === "driver_licence") {
            if (
              form_values.region === "NZ" &&
              !driver_licence_number_regex.test(target.value)
            ) {
              this.setState({
                form_errors: {
                  ...form_errors,
                  number: "Please enter a valid driver licence No.",
                },
              });
            } else {
              this.setState({
                form_errors: { ...rest },
              });
            }
          } else if (form_values.type === "passport") {
            if (
              form_values.region === "NZ" &&
              !passport_number_regex.test(target.value)
            ) {
              this.setState({
                form_errors: {
                  ...form_errors,
                  number: "Please enter a valid passport No.",
                },
              });
            } else {
              this.setState({
                form_errors: { ...rest },
              });
            }
          } else {
            this.setState({ form_errors: { ...rest } });
          }
        }
        break;
      case "driver_licence_version":
        // TODO: Refactor this validation
        if (type !== "driver_licence" || (type === "driver_licence" && region !== "NZ")) {
          const { driver_licence_version, ...rest } = form_errors;
          this.setState({
            form_errors: { ...rest },
          });

          break;
        }

        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              driver_licence_version:
                "Please enter your driver licence version.",
            },
          });
        } else {
          if (driver_licence_version_regex.test(target.value)) {
            const { driver_licence_version, ...rest } = form_errors;
            this.setState({
              form_errors: { ...rest },
            });
          } else {
            this.setState({
              form_errors: {
                ...form_errors,
                driver_licence_version: "Please enter a valid licence version.",
              },
            });
          }
        }
        break;
      case "identification_exp_date":
        // TODO: Refactor this validation
        if (isExpiryDateValid(target.value) || !isAuDriverLicence) {
          const { identification_exp_date, ...rest } = form_errors;
          this.setState({ form_errors: { ...rest } });
        } else {
          this.setState({
            form_errors: {
              ...form_errors,
              identification_exp_date: "Please enter valid expiry date.",
            },
          });
        }

        break;
      case "driver_licence_state":
        if (!isAuDriverLicence) {
          const { driver_licence_state, ...rest } = form_errors;
          this.setState({ form_errors: { ...rest } });

          break;
        }

        if (!target.value) {
          this.setState({
            form_errors: {
              ...form_errors,
              driver_licence_state: "Please select a state.",
            },
          });
        } else {
          const { driver_licence_state, ...rest } = form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }

        break;
      case "type":
        if (
          !target.value ||
          target.value.length < 1 ||
          target.value === "no_identification"
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              type: "You need to enter a identification type.",
            },
          });
        } else {
          const { type, ...rest } = form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case "region":
        if (
          target.value !== "Other" &&
          (!target.value || target.value.length < 1)
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              region: "You need to enter a country of identification.",
            },
          });
        } else {
          const { region, ...rest } = form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;

      //Todo more fields
    }
  }

  isComplete(form_values) {
    if (!form_values) {
      var { form_values } = this.props;
    }

    const { form_errors } = this.state;

    if (Object.keys(form_errors).length > 0) {
      return false;
    }

    const {
      driver_licence_number_regex,
      driver_licence_version_regex,
      full_version,
      isGuarantor,
      legal_type,
      name_dropdown_values,
      need_dob_address,
      passport_number_regex,
    } = this.props;

    // Allowing last name length of 1 since there are records from the company
    // register with directors not having last names and we are replacing them
    // with `.`
    const {
      address_api_id,
      dob,
      driver_licence_version,
      first_name,
      identification_exp_date,
      driver_licence_state,
      last_name,
      number,
      region,
      type,
    } = form_values;
    if (
      (first_name || "").length <= 0 ||
      (last_name || "").length <= 0
    ) {
      return false;
    }

    if (
      (legal_type === "sole_trader" || isGuarantor) &&
      ["sir", "lord", "princess", "lady"].includes(
        first_name.toLowerCase(),
      )
    ) {
      return false;
    }

    if (need_dob_address) {
      if (!isDateOfBirthValid(dob)) {
        return false;
      }

      if (!address_api_id) {
        return false;
      }
    }

    if (full_version) {
      if (
        (type || "").length < 1 ||
        type === "no_identification"
      ) {
        return false;
      }

      if (!region || region.length < 1) {
        return false;
      }

      if (!number || number.length < 1) {
        return false;
      } else {
        if (
          type === "driver_licence" &&
          region === "NZ" &&
          !driver_licence_number_regex.test(number)
        ) {
          return false;
        }

        if (
          form_values.type === "passport" &&
          form_values.region === "NZ" &&
          !passport_number_regex.test(form_values.number)
        ) {
          return false;
        }
      }

      if (
        type === "driver_licence" &&
        region === "NZ"
      ) {
        if (
          isBlank(driver_licence_version) ||
          !driver_licence_version_regex.test(driver_licence_version)
        ) {
          return false;
        }
      }

      if (
        type === "passport" &&
        !isExpiryDateValid(identification_exp_date)
      ) {
        return false;
      }

      if (
        type === "driver_licence" &&
        region === "AU" && (
          !isExpiryDateValid(identification_exp_date) ||
          !driver_licence_state
        )
      ) {
        return false;
      }
    }

    return true;
  }

  onSelectDate = (id, value) => {
    const { dispatch } = this.props;

    this.checkValid({ id, value });
    dispatch(setIdentityValue(id, value));
  };

  handleAddressChange(event) {
    const { dispatch } = this.props;
    dispatch(setAddress({ full_address: event.target.value }));
    if (event.target.value.length > 0) {
      event.persist();
      dispatch(lookupAddress(event.target.value, this.state.addressRegion));
    }
  }

  handleAddressClick(value) {
    const { dispatch } = this.props;
    dispatch(selectAddress(value));
    this.checkValid({ id: "address_api_id", value: value });
  }

  onChangeAddressRegion = selectedOption => {
    this.setState({ addressRegion: selectedOption.value });
  };

  _onChangeNameSelection(event) {
    const { dispatch, need_dob_address, name_dropdown_values } = this.props;
    const { form_errors } = this.state;

    this.setState({
      form_errors: { ...form_errors, name: "" },
    });

    const selectedPerson = name_dropdown_values.find(
      v => v.value === event.value,
    );

    dispatch(setIdentityValue("first_name", selectedPerson.first_name));
    dispatch(setIdentityValue("last_name", selectedPerson.last_name));
    dispatch(setIdentityValue("middle_name", selectedPerson.middle_name));

    if (need_dob_address) {
      dispatch(setAddress(event.address));
    }
  }

  description() {
    const { consumerName, supplierName } = this.props;
    return `You are certifying that you are an authorised party for the application between ${consumerName} and ${supplierName}`;
  }

  identityNumberLabel(type) {
    if (type === "passport") {
      return "Passport number";
    }

    if (type === "driver_licence") {
      return "Driver licence number";
    }

    return "Identification number";
  }

  render() {
    const { addressRegion, form_errors } = this.state;
    const {
      form_values,
      full_version,
      identityRegions,
      identityTypes,
      isAuthorisationPage,
      isGuarantor,
      legal_type,
      name_dropdown_values,
      need_dob_address,
      personal_address_list,
      personal_address_loading,
      scraping,
    } = this.props;

    const inputFrozen = scraping;
    const idMinDate = moment().toDate();
    const idMaxDate = moment()
      .add(50, "y")
      .endOf("year")
      .toDate();

    let name_component = [];

    name_component.push(
      <div
        className={styles.name_input}
        key="id-confirm-first-name"
      >
        <TextInput
          key="first_name"
          id="first_name"
          error={form_errors.first_name}
          value={form_values.first_name}
          label="Legal first name"
          handleBlur={this.handleBlur.bind(this)}
          handleChange={this.handleChange.bind(this)}
          required={true}
          disabled={inputFrozen}
        />
      </div>,
    );
    name_component.push(
      <div
        className={styles.name_input}
        key="id-confirm-middle-name"
      >
        <TextInput
          key="middle_name"
          id="middle_name"
          error={form_errors.middle_name}
          value={form_values.middle_name}
          label="Middle name"
          handleBlur={this.handleBlur.bind(this)}
          handleChange={this.handleChange.bind(this)}
          required={false}
          disabled={inputFrozen}
        />
      </div>,
    );
    name_component.push(
      <div
        className={styles.name_input}
        key="id-confirm-last-name"
      >
        <TextInput
          key="last_name"
          id="last_name"
          error={form_errors.last_name}
          value={form_values.last_name}
          label="Last name"
          handleBlur={this.handleBlur.bind(this)}
          handleChange={this.handleChange.bind(this)}
          required={true}
          disabled={inputFrozen}
        />
      </div>,
    );

    return (
      <section className={styles.section}>
        <div className={styles.page_container}>
          <SectionHeader title="Confirm your details" />
          <div className={styles.form_wrapper}>
            <div className={styles.form_row}>{name_component}</div>
            {need_dob_address && (
              <div className={styles.form_row}>
                <div className={styles.dob_input}>
                  <BorderedCalendarPicker
                    textFieldProps={{
                      error: Boolean(form_errors.dob),
                      helperText: get(form_errors, "dob"),
                      id: "dob",
                      name: "dob",
                      required: true,
                    }}
                    isDisabled={inputFrozen}
                    minDate={get100YearsAgo()}
                    maxDate={getEighteenYearsAgo()}
                    value={form_values.dob}
                    label="Date of birth"
                    onChange={(value) => this.onSelectDate('dob', value)}
                    isStyledUnderlined
                  />
                </div>
              </div>
            )}
            {full_version && (
              <div className={styles.form_group}>
                <div className={styles.col_4}>
                  <OptionsDropdown
                    id="region"
                    name="region"
                    handleChange={this.handleChange.bind(this)}
                    handleBlur={this.handleBlur.bind(this)}
                    error={form_errors["region"]}
                    label="Country of identification"
                    value={form_values["region"]}
                    required
                    options={identityRegions}
                    disabled={inputFrozen}
                  />
                </div>
                <div className={styles.col_4}>
                  <OptionsDropdown
                    id="type"
                    name="type"
                    handleChange={this.handleChange.bind(this)}
                    handleBlur={this.handleBlur.bind(this)}
                    error={form_errors["type"]}
                    label="Identification type"
                    value={form_values["type"] || ""}
                    required
                    options={identityTypes}
                    disabled={inputFrozen}
                  />
                </div>
                { form_values["type"] === "driver_licence" &&
                  form_values["region"] === "AU" && (
                    <Fragment>
                      <div className={styles.col_4}>
                        <StateDropdown
                          handleChange={this.handleChange.bind(this)}
                          handleBlur={this.handleBlur.bind(this)}
                          error={form_errors["driver_licence_state"]}
                          value={form_values["driver_licence_state"] || ""}
                          required
                          disabled={inputFrozen}
                        />
                      </div>
                      <div className={styles.col_4}>
                        <BorderedCalendarPicker
                          textFieldProps={{
                            error: Boolean(form_errors.identification_exp_date),
                            helperText: get(form_errors, "identification_exp_date"),
                            id: "identification_exp_date",
                            name: "identification_exp_date",
                            required: true,
                          }}
                          isDisabled={inputFrozen}
                          minDate={idMinDate}
                          maxDate={idMaxDate}
                          value={form_values.identification_exp_date}
                          label="Licence expiry date"
                          onChange={(value) => this.onSelectDate("identification_exp_date", value)}
                          isStyledUnderlined
                        />
                      </div>
                    </Fragment>
                  )
                }
                <div className={styles.col_4}>
                  <TextInput
                    key="number"
                    id="number"
                    type="text"
                    error={form_errors["number"]}
                    handleChange={this.handleChange.bind(this)}
                    handleBlur={this.handleBlur.bind(this)}
                    label={this.identityNumberLabel(form_values["type"])}
                    required={true}
                    value={form_values["number"]}
                    disabled={inputFrozen}
                  />
                </div>
                {form_values["type"] === "passport" ? (
                  <div className={styles.col_4}>
                    <BorderedCalendarPicker
                      textFieldProps={{
                        error: Boolean(form_errors.identification_exp_date),
                        helperText: get(form_errors, "identification_exp_date"),
                        id: "identification_exp_date",
                        name: "identification_exp_date",
                        required: true,
                      }}
                      isDisabled={inputFrozen}
                      minDate={idMinDate}
                      maxDate={idMaxDate}
                      value={form_values.identification_exp_date}
                      label="Passport expiry date"
                      onChange={(value) => this.onSelectDate("identification_exp_date", value)}
                      isStyledUnderlined
                    />
                  </div>
                ) : (
                  form_values["type"] === "driver_licence" &&
                  form_values["region"] === "NZ" && (
                    <div className={styles.col_4}>
                      <TextInput
                        key="driver_licence_version"
                        id="driver_licence_version"
                        type="tel"
                        error={form_errors["driver_licence_version"]}
                        handleChange={this.handleChange.bind(this)}
                        handleBlur={this.handleBlur.bind(this)}
                        label={"Driver licence version"}
                        required={true}
                        value={form_values["driver_licence_version"]}
                        disabled={inputFrozen}
                      />
                    </div>
                  )
                )}
              </div>
            )}
            {need_dob_address && (
              <div>
                <AddressPicker
                  addressRegion={addressRegion}
                  form_errors={form_errors}
                  form_values={form_values}
                  handleAddressChange={this.handleAddressChange.bind(this)}
                  handleAddressClick={this.handleAddressClick.bind(this)}
                  handleBlur={this.handleBlur.bind(this)}
                  inputFrozen={inputFrozen}
                  isAuthorisationPage={isAuthorisationPage}
                  onChangeAddressRegion={this.onChangeAddressRegion}
                  personal_address_list={personal_address_list}
                  personal_address_loading={personal_address_loading}
                />
                <div className={styles.clearfix} />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default connect((state, ownProps) => {
  const { isGuarantor, guarantor, applicant_guarantor, applicantSignatory } = ownProps;
  const identity = state.identity;
  const cobSection = state.cob_section;

  let form_values = {
    name: [identity.first_name, identity.middle_name, identity.last_name]
      .filter(Boolean)
      .join(" "),
    first_name: identity.first_name,
    middle_name: identity.middle_name,
    last_name: identity.last_name,
    personal_address: identity.address && identity.address.full_address,
    address_api_id: identity.address_api_id,
    dob: identity.dob,
    region: identity.other_region
      ? "Other"
      : identity.region && identity.region.length
      ? identity.region
      : "",
    type: identity.type,
    number: identity.number,
    driver_licence_version: identity.driver_licence_version,
    driver_licence_state: identity.driver_licence_state,
    identification_exp_date: identity.identification_exp_date,
  };

  let requires_applicant_identification_check = false;
  let requires_guarantees_identification_check = false;
  let requires_cardholder_identification_check = false;
  let requires_signatories_identification_check = false;
  let requires_personal_credit_check = false;
  let legal_type = "sole_trader";
  let application_type;

  const baseRegion = get(state, "cob_business.entity_region", "NZ");
  const applicationRegion = get(
    state,
    "authorisation.data.application.attributes.region",
    baseRegion,
  );
  const addressRegion = get(identity, "address.alpha2", applicationRegion);

  if (state.cob_section.application) {
    requires_applicant_identification_check =
      state.cob_section.application.attributes
        .requires_applicant_identification_check;
    requires_guarantees_identification_check =
      state.cob_section.application.attributes
        .requires_guarantees_identification_check;
    requires_cardholder_identification_check =
      state.cob_section.application.attributes
        .requires_cardholder_identification_check;
    requires_signatories_identification_check =
      state.cob_section.application.attributes
        .requires_signatories_identification_check;
    requires_personal_credit_check =
      state.cob_section.application.attributes.requires_personal_credit_check;
      application_type =
        state.cob_section.application.attributes.application_type;
  }

  if (guarantor && guarantor.application) {
    requires_applicant_identification_check =
      guarantor.application.attributes.requires_guarantees_identification_check;
    requires_guarantees_identification_check =
      guarantor.application.attributes.requires_guarantees_identification_check;
    requires_personal_credit_check =
      guarantor.application.attributes.requires_personal_credit_check;
      application_type = guarantor.application.attributes.application_type;
  }

  if (state.cob_business) {
    legal_type = state.cob_business.entity_type;
  }

  const name_dropdown_values = [];
  let people_list = state.cob_section.people;

  if (people_list && people_list.length > 0) {
    people_list.forEach((person) => {
      let name = `${person.name}`;
      let address = person.residential_address;
      let label = name;

      name_dropdown_values.push({
        id: "name",
        label: label,
        value: name,
        address: address,
        first_name: person.first_name,
        middle_name: person.middle_name,
        last_name: person.last_name,
      });
    });
  }

  let applicant_is_guarantor = false;
  if (applicant_guarantor) {
    const the_person = people_list.find(p => {
      return (
        p.first_name === applicant_guarantor.first_name &&
        p.last_name === applicant_guarantor.last_name &&
        p.middle_name === applicant_guarantor.middle_name
      );
    });
    if (the_person) {
      applicant_guarantor.address = the_person.residential_address;
    }
    applicant_is_guarantor = true;
  }

  let applicantIsCardholder = false;
  const applicantCardholder = state.cob_cards.cardholders.find(
    c => c.attributes && c.attributes.isApplicant,
  );

  if (applicantCardholder) {
    applicantIsCardholder = true;
  }

  let applicantIsSignatory = false;

  if (applicantSignatory) {
    applicantIsSignatory = true;
  }

  const requiresID = ownProps.requiresID;
  let full_version = false;

  if (isGuarantor) {
    full_version = guarantor && requiresID;
  } else if (applicant_is_guarantor) {
    full_version =
      requires_guarantees_identification_check ||
      requires_applicant_identification_check ||
      requiresID;
  } else {
    full_version = requires_applicant_identification_check || requiresID;
  }

  if (applicantIsCardholder || applicantSignatory) {
    full_version = requiresID;
  }

  let need_dob_address =
    full_version ||
    ((["sole_trader", "personal"].includes(legal_type) ||
      applicant_is_guarantor ||
      guarantor ||
      applicantIsSignatory) &&
      requires_personal_credit_check);

  if (application_type == "cash") {
    full_version = false;
    need_dob_address = false;
  }

  let applicant_signature = state.cob_section.signature;

  if (cobSection.antiFraud) {
    full_version = true;
    need_dob_address = true;
  }

  return {
    addressRegion,
    form_values,
    personal_address_list: identity.address_list,
    identityTypes: [
      {
        value: "driver_licence",
        label: "Driver Licence",
      },
      {
        value: "passport",
        label: "Passport",
      },
      {
        value: "other",
        label: "Other",
      },
    ],
    identityRegions: [
      {
        value: "NZ",
        label: "New Zealand",
      },
      {
        value: "AU",
        label: "Australia",
      },
      {
        value: "Other",
        label: "Other",
      },
    ],
    personal_address_loading: identity.address_loading,
    image_64: identity.image_64,
    scraping: identity.scraping,
    mock_percentage: identity.mock_percentage,
    driver_licence_version_regex: /^\d{3}$/,
    driver_licence_number_regex: /^[a-zA-Z]{2}\d{6}$/,
    passport_number_regex: /^[a-zA-Z]{1,2}\d{6}$/,
    file_name: identity.file_name,
    file_size: identity.file_size,
    requires_applicant_identification_check: requires_applicant_identification_check,
    applicant_signature: applicant_signature,
    legal_type,
    name_dropdown_values,
    applicant_guarantor,
    names_locked: identity.names_locked,
    need_dob_address: ownProps.needDobAddress || need_dob_address,
    full_version: ownProps.needIdentification || full_version,
    applicantCardholder,
    applicantSignatory,
  };
})(IdentityConfirm);
