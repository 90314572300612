import * as yup from "yup";
import React, { useEffect, useState } from "react";
import BorderedTextArea from "modules/shared/components/inputs/BorderedTextArea";
import Button from "modules/shared/components/inputs/Button";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import Checkbox from "@material-ui/core/Checkbox";
import CloseButton from "modules/shared/components/inputs/CloseButton";
import FixedContent from "modules/shared/components/containers/FixedContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContent from "modules/shared/components/containers/GridContent";
import IconDeleteArchive from "images/svgs/icon-delete-archive.svg";
import IconPDF from "images/svgs/icon-pdf.svg";
import LabeledContent from "modules/shared/components/widgets/static/LabeledContent";
import ModulePaperlessApplicationModel from "models/ModulePaperlessApplicationModel";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import StatusIndicator from "modules/applications/components/StatusIndicator";
import UserModel from "models/UserModel";
import { connect } from "react-redux";
import { formatLocalTime } from "utils/dateFormatter";
import get from "lodash.get";
import isBlank from "utils/isBlank";
import isPresent from "utils/isPresent";
import mixpanel from "mixpanel-browser";
import { muiTheme } from "modules/shared/helpers/colorPalettes";
import styles from "modules/direct-debit-authority/css/DirectDebitAuthorityShow.css";
import { useForm } from "react-hook-form";
import {
  ACTION_COMPONENTS,
  ActionIcon,
  getResendLabel,
} from "modules/new-applications/components/application-sections/IdentificationChecks";
import { getDirectDebitModuleLink } from "modules/addons/helpers";
import api from "api";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ContentWithFooter from "modules/shared/components/containers/ContentWithFooter";
import Preview from "modules/FileUpload/UploadedFile/Preview";
import BankNumber from "modules/new-applications/components/application-sections/DirectDebit/BankNumber";

const DEFAULT_INTERVAL = 1000;

const formSchema = yup.object().shape({
  note: yup.string().required("Note is required."),
});

function TopbarIconLink(props) {
  const {
    application,
    Icon,
    label,
    section,
    setVisibleSection,
    visibleSection,
  } = props;

  const onClick = () => {
    if (section === "pdf_authorisation") {
      const url = application.attributes.pdf.url;
      if (url) {
        window.open(
          application.attributes.pdf.url,
          "paperless_auth_pdf_window",
        );
      }
    } else {
      setVisibleSection(section);
    }
  };
  let iconBarClassName = styles.icon_bar;

  if (visibleSection === section) {
    iconBarClassName = styles.icon_bar_active;
  }

  return (
    <div className={styles.icon_link}>
      <div onClick={onClick}>
        <Icon className={styles.icon} width={40} height={40} />
        <div className={styles.icon_text}>{label}</div>
      </div>
      <div className={iconBarClassName} />
    </div>
  );
}

function Topbar(props) {
  const { application } = props;
  const status = application.archived
    ? "Archived"
    : application.status === "completed"
    ? "complete"
    : application.status;

  return (
    <div className={styles.topbar}>
      <div className={styles.topbar_icons}>
        <TopbarIconLink
          Icon={IconDeleteArchive}
          label={`Delete / ${application.archived ? "Unarchive" : "Archive"}`}
          section="delete_archive"
          {...props}
        />

        <TopbarIconLink
          Icon={IconPDF}
          label="PDF authorisation"
          section="pdf_authorisation"
          {...props}
        />
      </div>
      <div className={styles.status_indicator}>
        <StatusIndicator status={status} long={true} />
      </div>
    </div>
  );
}

function DeleteSection(props) {
  const { application, currentUser, onSubmit } = props;

  if (application.status === "completed") {
    return null;
  }

  const { errors, handleSubmit, register } = useForm({
    mode: "onBlur",
    validationSchema: formSchema,
  });

  const onSubmitForm = data => {
    mixpanel.track("Delete direct debit authorisation", {
      "Direct debit auth ID": application.id,
      "Entity ID": get(currentUser, "currentEntity.id"),
      distinct_id: currentUser.id,
    });

    onSubmit({
      delete_note: data.note,
      status: "deleted",
    });
  };

  return (
    <FixedContent header="Delete application" withSideMargin={true}>
      <GridContent gridColumnTemplate="two_thirds">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <BorderedTextArea
            label="Note"
            name="note"
            inputRef={register}
            placeholder="Note"
            error={Boolean(errors.note)}
            helperText={get(errors, "note.message", " ")}
          />
          <Button text="Delete" type="submit" red />
        </form>
      </GridContent>
    </FixedContent>
  );
}

function ArchiveSection(props) {
  const { application, currentUser, onSubmit } = props;
  const { errors, handleSubmit, register } = useForm({
    mode: "onBlur",
  });

  const onSubmitForm = data => {
    mixpanel.track(
      `${
        application.archived ? "Unarchive" : "Archive"
      } direct debit authorisation`,
      {
        "Direct debit auth ID": application.id,
        "Entity ID": get(currentUser, "currentEntity.id"),
        distinct_id: currentUser.id,
      },
    );

    onSubmit({
      archive_note: data.note,
      archived: !application.archived,
    });
  };

  return (
    <FixedContent
      header={`${application.archived ? "Unarchive" : "Archive"} application`}
      withSideMargin={true}
    >
      <GridContent gridColumnTemplate="two_thirds">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Button
            text={application.archived ? "Unarchive" : "Archive"}
            type="submit"
            // disabled={application.archived}
          />
        </form>
      </GridContent>
    </FixedContent>
  );
}

function DeleteArchive(props) {
  const { application, currentUser, router, setVisibleSection } = props;

  const onClickCloseButton = () => setVisibleSection("show");
  const onSuccessCallback = () =>
    router.push("/dashboard/direct_debit_authorisations");
  const onSubmit = attributes => {
    application.setAttributes(attributes);
    application.update({ currentUser, onSuccessCallback });
  };

  return (
    <div className={styles.details_container}>
      <CloseButton
        handleClick={onClickCloseButton}
        style={{ right: 10, top: 10 }}
      />
      <DeleteSection onSubmit={onSubmit} {...props} />
      <ArchiveSection onSubmit={onSubmit} {...props} />
    </div>
  );
}

function DirectDebitDetails(props) {
  const { application, meta } = props;

  return (
    <FixedContent header="Direct debit details" withSideMargin={true}>
      <GridContent>
        <LabeledContent
          label={meta.account_number_label}
          content={application.accountNumber}
        />
        <LabeledContent
          label={meta.account_name_label}
          content={application.accountName}
        />
        <LabeledContent
          label="Bank account name to be debited"
          content={application.bankAccountName}
        />
        <BankNumber
          region={application.supplierRegion}
          bankNumber={application.bankNumber}
        />
        <LabeledContent
          label="Bank account number"
          content={application.bankAccountNumber}
        />
        <LabeledContent
          label="Customer email"
          content={get(application, "applicant.userInformation.email")}
        />
      </GridContent>
    </FixedContent>
  );
}

function IdentificationImage(props) {
  const { signature } = props;
  const identificationImage = get(signature, "identificationImage.url");

  if (isBlank(identificationImage)) {
    return null;
  }

  return (
    <div style={{ width: "25rem" }}>
      <Preview
        file={{ preview: identificationImage }}
        cssOverrides={`
          height: auto;
          max-height: 14rem;

          &:hover {
            overflow-y: scroll;
          }
        `}
      />
    </div>
  );
}

function SignatureDetails(props) {
  const { content, label } = props;

  if (isBlank(content)) {
    return null;
  }

  return (
    <p>
      {label}: {content}
    </p>
  );
}

function isResendVisible({ application, isApplicant }) {
  if (application.isViewOnly || application.isComplete) {
    return false;
  }

  if (isApplicant) {
    return !application.isSubmitted;
  }

  return application.isSubmitted;
}

function ResendAction(props) {
  const { application, isApplicant, isSending, userRole, onClick } = props;

  if (!isResendVisible({ application, isApplicant })) {
    return null;
  }

  const label = getResendLabel({
    isApplicant,
    isMobileScreen: false,
    isSending,
  });
  const ResendActionComponent = ACTION_COMPONENTS.desktop.ResendAction;

  return (
    <ResendActionComponent
      isSending={isSending}
      label={label}
      onClick={onClick}
    />
  );
}

function CopyLink(props) {
  const { application, currentUser, isApplicant } = props;

  if (
    !isApplicant ||
    currentUser.isStandard ||
    application.isViewOnly ||
    application.isComplete
  ) {
    return null;
  }

  const [isCopied, setIsCopied] = useState(false);
  const link = getDirectDebitModuleLink(
    get(currentUser, "currentEntity.id", ""),
  );

  const onCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), DEFAULT_INTERVAL);
    } catch (error) {
      setIsCopied(false);
      console.error(error);
    }
  };

  const label = isCopied ? "Copied!" : "Copy link";
  const CopyLinkActionComponent = ACTION_COMPONENTS.desktop.CopyLinkAction;

  return (
    <CopyLinkActionComponent
      isCopied={isCopied}
      label={label}
      onClick={onCopyToClipboard}
    />
  );
}

function isSuperAdminLinkVisible({ application, isApplicant }) {
  if (isApplicant) {
    return false;
  }

  return application.isSubmitted;
}

function SuperAdminCopyLink(props) {
  const { application, currentUser, isApplicant, userRole } = props;

  if (!currentUser.superAdmin) {
    return null;
  }

  if (isApplicant) {
    return null;
  }

  const isVisible = isSuperAdminLinkVisible({ application, isApplicant });
  if (!isVisible) {
    return null;
  }

  const [isCopied, setIsCopied] = useState(false);
  const onSetCopiedState = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), DEFAULT_INTERVAL);
  };

  const onClick = async () => {
    const userRoleAPI = api(
      "user_roles",
      currentUser.accessToken,
      get(currentUser, "currentEntity.id"),
    );

    try {
      const response = await userRoleAPI.getSuperAdminAuthorisationLink(
        userRole.id,
      );

      await navigator.clipboard.writeText(get(response, "data.link", ""));
      onSetCopiedState();
    } catch (error) {
      setIsCopied(false);
      console.error(error);
    }
  };

  if (isCopied) {
    return (
      <div>
        <ActionIcon
          Icon={AssignmentTurnedInIcon}
          title="Copied"
          color="green"
        />
      </div>
    );
  }

  return (
    <div className="cursor-pointer" onClick={onClick}>
      <ActionIcon
        Icon={AssignmentLateIcon}
        title="Copy link with token"
        color="orange"
      />
    </div>
  );
}

function Signatory(props) {
  const {
    application,
    currentUser,
    index,
    onFetchModulePaperlessApplication,
    userRole,
  } = props;
  const signature = userRole.signature || {};
  const {
    isLoading: isSending,
    setIsLoading: setIsSending,
  } = useIsLoadingState();
  let approvedString = userRole.approvedString;
  if (approvedString === "No") {
    approvedString = (
      <span className={styles.decision_deny}>{approvedString}</span>
    );
  }
  const isApplicant = userRole.isApplicant;

  const onResendCallback = () => {
    onFetchModulePaperlessApplication();
    setIsSending(false);
  };

  const onResend = () => {
    setIsSending(true);

    userRole.resend({
      currentUser,
      onSuccessCallback: onResendCallback,
    });
  };

  const header = (
    <div className={styles.header_container}>
      <div className="has-text-weight-normal pr-4">eSignature {index}</div>
      <div className={styles.header_actions}>
        <ResendAction
          application={application}
          currentUser={currentUser}
          isApplicant={isApplicant}
          isSending={isSending}
          onClick={onResend}
        />
        <CopyLink
          application={application}
          currentUser={currentUser}
          isApplicant={isApplicant}
        />
        <SuperAdminCopyLink
          application={application}
          currentUser={currentUser}
          isApplicant={isApplicant}
          userRole={userRole}
        />
      </div>
    </div>
  );

  return (
    <FixedContent header={header} withSideMargin={true}>
      <GridContent gridColumnTemplate="one_third">
        <div>
          <IdentificationImage signature={signature} />
        </div>
        <div>
          <SignatureDetails label="Name" content={userRole.fullName} />
          <SignatureDetails label="Approved" content={approvedString} />
          <SignatureDetails label="IP" content={signature.ipAddress} />
          <SignatureDetails
            label={signature.identificationTypeLabel}
            content={signature.identificationNumber}
          />
          <SignatureDetails
            label="Version"
            content={signature.identificationVersion}
          />
          <SignatureDetails
            label="Expiry date"
            content={signature.formattedIdentificationExpiryDate}
          />
        </div>
        <div>
          <SignatureDetails label="DOB" content={signature.formattedDOB} />
          <SignatureDetails
            label="Date"
            content={get(signature, "timestampParts.date")}
          />
          <SignatureDetails
            label="Time"
            content={get(signature, "timestampParts.time")}
          />
          <SignatureDetails label="Timezone" content={signature.timezone} />
          <SignatureDetails label="Location" content={signature.location} />
        </div>
      </GridContent>
    </FixedContent>
  );
}

function FormatHistory(history) {
  let formattedDate = "-";
  if (isPresent(history.updated_at)) {
    formattedDate = formatLocalTime(history.updated_at, "minute");
  }

  return (
    <div className={styles.reviewed_by} key={history.id}>
      {`${history.content} on ${formattedDate} .`}
    </div>
  );
}

function ReviewedBy(props) {
  const { application } = props;

  if (!application.reviewed) {
    return null;
  }

  let formattedReviewedDate = "-";
  if (isPresent(application.reviewedAt)) {
    formattedReviewedDate = formatLocalTime(application.reviewedAt, "minute");
  }

  return (
    <div className={styles.reviewed_by}>
      (Reviewed by {get(application, "reviewedBy.fullName")} on{" "}
      {formattedReviewedDate})
    </div>
  );
}

function ReviewCheckbox(props) {
  const { isReviewed, onReview } = props;

  if (typeof isReviewed === "undefined") {
    return null;
  }

  return (
    <Checkbox
      size="small"
      checked={isReviewed}
      disabled={isReviewed}
      onChange={onReview}
    />
  );
}

function Review(props) {
  const {
    application,
    currentUser,
    setIsNextButtonDisabled,
    onRefreshModulePaperlessApplicationState,
  } = props;

  if (!application.isComplete || application.isLocked) {
    return null;
  }

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(application.reviewed);
  }, [application.id, application.reviewed]);

  const onSuccessCallback = ({ meta, modulePaperlessApplication }) => {
    mixpanel.track("Review direct debit authorisation", {
      "Direct debit auth ID": modulePaperlessApplication.id,
      "Entity ID": get(currentUser, "currentEntity.id"),
      distinct_id: currentUser.id,
    });

    onRefreshModulePaperlessApplicationState({
      meta,
      modulePaperlessApplication,
    });

    setIsNextButtonDisabled(false);
  };

  const onReview = () => {
    setChecked(true);
    setIsNextButtonDisabled(true);

    application.onReview({ currentUser, onSuccessCallback });
  };

  return (
    <FixedContent withSideMargin={true}>
      <div className={styles.reviewed_container}>
        <FormControlLabel
          classes={{
            label: styles.review_checkbox_label,
          }}
          control={
            <ReviewCheckbox
              isReviewed={application.reviewed || checked}
              onReview={onReview}
            />
          }
          disabled={application.reviewed || application.isLoading || checked}
          label="Reviewed and loaded in system?"
          labelPlacement="start"
        />
        <ReviewedBy application={application} />
      </div>
    </FixedContent>
  );
}

function Details(props) {
  const {
    application,
    currentUser,
    onFetchModulePaperlessApplication,
    router,
  } = props;
  const userRoleHistories = application.dduHistories || [];

  const onClickCloseButton = () =>
    router.push("/dashboard/direct_debit_authorisations");

  const signatories = application.userRoles
    .sort((u1, u2) => {
      if ("applicant" in u1.attributes.acting_as) {
        return -1;
      }
      return 1;
    })
    .map((userRole, index) => (
      <Signatory
        key={`signatory-${index + 1}`}
        index={index + 1}
        application={application}
        currentUser={currentUser}
        onFetchModulePaperlessApplication={onFetchModulePaperlessApplication}
        userRole={userRole}
      />
    ));

  const content = (
    <div className={styles.details_container}>
      <CloseButton
        handleClick={onClickCloseButton}
        style={{ right: 10, top: 10 }}
      />
      <DirectDebitDetails {...props} />
      {signatories}
      <Review {...props} />
    </div>
  );

  if (userRoleHistories.length > 0) {
    return (
      <ContentWithFooter
        withBodyRightPadding={false}
        withBottomPadding={true}
        withLeftPadding={true}
        footer={userRoleHistories.map((item, index) => (
          <div key={`h-${index}`}>{FormatHistory(item)}</div>
        ))}
      >
        {content}
      </ContentWithFooter>
    );
  }

  return content;
}

function Navigation(props) {
  const { isNextButtonDisabled, meta, router } = props;
  const next = meta.next;

  if (isBlank(next)) {
    return null;
  }

  const path = `/dashboard/direct_debit_authorisations/${next}`;

  const onClick = event => {
    event.preventDefault();
    router.push(path);
  };

  return (
    <div className={styles.navigations}>
      <Button
        href={path}
        text="Next"
        disabled={isNextButtonDisabled}
        onClick={onClick}
      />
    </div>
  );
}

function useApplicationState(props) {
  const { currentUser } = props;
  const id = get(props, "params.id");
  const [application, setApplication] = useState(
    new ModulePaperlessApplicationModel(),
  );
  const [meta, setMeta] = useState({});

  const onFetchModulePaperlessApplication = () => {
    (async () => {
      const {
        meta,
        modulePaperlessApplication,
      } = await ModulePaperlessApplicationModel.fetchModulePaperlessApplication(
        {
          accessToken: currentUser.accessToken,
          entityId: get(currentUser, "currentEntity.id"),
          id,
        },
      );

      setApplication(modulePaperlessApplication);
      setMeta(meta);
    })();
  };

  const onRefreshModulePaperlessApplicationState = ({
    meta,
    modulePaperlessApplication,
  }) => {
    setApplication(modulePaperlessApplication);
    setMeta(meta);
  };

  return {
    application,
    meta,
    onFetchModulePaperlessApplication,
    onRefreshModulePaperlessApplicationState,
  };
}

function VisibleComponent(props) {
  const { visibleSection } = props;

  if (visibleSection === "show") {
    return <Details {...props} />;
  }

  if (visibleSection === "delete_archive") {
    return <DeleteArchive {...props} />;
  }
}

function DirectDebitAuthorityShow(props) {
  const {
    application,
    meta,
    onFetchModulePaperlessApplication,
    onRefreshModulePaperlessApplicationState,
  } = useApplicationState(props);
  const [visibleSection, setVisibleSection] = useState("show");
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  useEffect(() => {
    onFetchModulePaperlessApplication();
  }, [get(props, "params.id")]);

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div className={styles.section}>
        <div className={styles.content}>
          <Topbar
            application={application}
            visibleSection={visibleSection}
            setVisibleSection={setVisibleSection}
          />
          <VisibleComponent
            application={application}
            visibleSection={visibleSection}
            setVisibleSection={setVisibleSection}
            setIsNextButtonDisabled={setIsNextButtonDisabled}
            onFetchModulePaperlessApplication={
              onFetchModulePaperlessApplication
            }
            onRefreshModulePaperlessApplicationState={
              onRefreshModulePaperlessApplicationState
            }
            meta={meta}
            {...props}
          />
          <Navigation
            isNextButtonDisabled={isNextButtonDisabled}
            meta={meta}
            router={props.router}
          />
        </div>
      </div>
    </MuiThemeProvider>
  );
}

export default connect(state => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(DirectDebitAuthorityShow);
