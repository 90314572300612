export const COLORS = {
  black: "#000000",
  cerulean: "#00afef",
  codGray: "#0f0f0f",
  crail: "#c35347",
  darkGrey: "#5e5e5e",
  lightBlue: "#7fcef3",
  lightGrey: "#f6f6f6",
  mantis: "#70bd67",
  mecury: "#e9e9e9",
  mediumBlue: "#00afef",
  mediumGrey: "#c9c9c9",
  pomegranate: "#f44335",
  white: "#ffffff",
};

export const THEME_COLORS = {
  bodyBackground: COLORS.lightGrey,
  borderGray: COLORS.mecury,
  danger: COLORS.pomegranate,
  disabled: COLORS.mediumGrey,
  overlayBackground: "rgba(0,0,0, 0.1)",
  overlayBackgroundLight: "rgba(246, 246, 246, 0.95)",
  panelBg: COLORS.white,
  panelTitleBg: COLORS.mecury,
  primary: COLORS.mediumBlue,
  primaryLight: COLORS.lightBlue,
  secondary: COLORS.black,
  sectionBorder: COLORS.mediumGrey,
  tokenDisabled: COLORS.lightGrey,
  trendDown: COLORS.mediumGrey,
  trendUp: COLORS.cerulean,
};

export const CREDITWORKS_COLORS = {
  blue: "#2432fa",
  camerone: "#00681c",
  cinnabar: "#e75234",
  fernGreen: "#567b3c",
  gorse: "#fefc48",
  gray: "#929292",
  olivine: "#97c471",
  pastelGreen: "#89ed9c",
  redOrange: "#ff2723",
  white: "#ffffff",
  yellowOrange: "#ffac35",
};

export const FONT_COLORS = {
  default: COLORS.black,
  gray: COLORS.codGray,
  light: COLORS.white,
  link: THEME_COLORS,
};

export const BORDERS = {
  generic: `1px solid ${COLORS.mediumGrey}`,
};
