import React, { ReactElement } from 'react'
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import { SelectDropdownProps } from './types';

const entityOptions = [
  { label: 'Company', value: 'company', brief: 'Company' },
  { label: 'Partnership', value: 'partnership', brief: 'Partnership' },
  { label: 'Trust', value: 'trust', brief: 'Trust' },
  { label: 'Sole trader', value: 'sole_trader', brief: 'Sole trader' },
  { label: 'Personal', value: 'personal', brief: 'Personal' },
  { label: 'Education', value: 'education', brief: 'Education' },
  { label: 'Society', value: 'society', brief: 'Society' },
  { label: 'Association', value: 'association', brief: 'Association' },
  { label: 'Club', value: 'club', brief: 'Club' },
  { label: 'Government', value: 'government', brief: 'Government' },
  { label: 'Other', value: 'other', brief: 'Other' },
];


function EntityTypeSelectDropdown(props: SelectDropdownProps): ReactElement {
  const { handleChange } = props;

  return (
    <SimpleMultiSelectDropdown
      options={entityOptions}
      onChange={handleChange}
      id="entity-type-select"
      {...props}
    />
  )
}

export default EntityTypeSelectDropdown
