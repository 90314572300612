import BaseModel from "models/BaseModel";
import NoteModel from "models/NoteModel";

export default class ConnectionModel extends BaseModel {
  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  assignRelationships() {
    this.assignManyRelationship({
      key: "notes",
      model: NoteModel,
    });
  }
}
